import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import DashboardWrapper from 'modules/groupDashboards/DashboardWrapper'
import NoDashboard from 'modules/groupDashboards/DashboardMessages/NoDashboard'
import UnsupportedDashboard from 'modules/groupDashboards/DashboardMessages/UnsupportedDashboard'
import LoadingDashboard from 'modules/groupDashboards/DashboardMessages/LoadingDashboard'

import AddGroupDashboard from 'modules/groupDashboards/Modals/AddGroupDashboard'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { withRouter } from 'react-router-dom'

const styles = () => ({
  bar: {
    backgroundColor: props => props.dashboardBarColor,
    color: props => props.fontColor
  },
  button: {
    color: props => props.fontColor
  }
})
class GroupDashboards extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      tabValue: 0,
      incompatible: false,
      addGroupDashboardModal: false,
      loading: true,
      tabsDisabled: true
    }
  }

  componentDidMount() {
    if (this.props.groupId && this.props.getGroupDashboards.length > 0) {
      if (this.props.match.params.groupDashboardId && this.props.getGroupDashboards.length > 0) {
        const actualDashboard = this.props.getGroupDashboards.find(
          dashboard => dashboard.uuid === this.props.match.params.groupDashboardId
        )
        let hashId = undefined
        if (actualDashboard) {
          hashId = actualDashboard.hashId
        }
        this.getGroupDashboards(hashId)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.getGroupDashboards.length === 0 &&
      this.props.match.params.groupDashboardId &&
      this.props.getGroupDashboards.length > 0
    ) {
      const actualDashboard = this.props.getGroupDashboards.find(
        dashboard => dashboard.uuid === this.props.match.params.groupDashboardId
      )
      let hashId = undefined
      if (actualDashboard) {
        hashId = actualDashboard.hashId
        this.getGroupDashboards(hashId)
      } else {
        this.getGroupDashboards()
      }
    }

    if (this.props.getGroupDashboards.length === 0) {
      this.props.history.push('/dashboards')
    }

    if (prevProps.match.params.groupDashboardId !== this.props.match.params.groupDashboardId) {
      const { hashId } = this.props.getGroupDashboards.find(
        dashboard => dashboard.uuid === this.props.match.params.groupDashboardId
      )
      this.getGroupDashboards(hashId)
    }
  }

  componentWillUnmount() {
    if (this.props.history.location.pathname !== this.props.location.pathname && this.props.isEditing) {
      this.props.finishEditDashboard()
    }
  }

  updateNodeDashboards = hashId => {
    this.setState(
      {
        loading: true
      },
      () => {
        this.getGroupDashboards(hashId)
        this.setState({
          incompatible: false,
          loading: false
        })
      }
    )
  }

  getGroupDashboards = hashId => {
    let tabSelected = this.state.tabValue
    const dashboards = this.props.getGroupDashboards
    const dashboardIds = dashboards.map(dashboard => dashboard.hashId)

    const selectedTab = dashboardIds.indexOf(hashId)
    if (selectedTab === -1) {
      tabSelected = 0
    } else {
      tabSelected = selectedTab
    }

    this.setState({
      tabValue: tabSelected,
      incompatible: false,
      loading: false
    })
  }

  openAddGroupDashboardModal = () => {
    this.setState({
      addGroupDashboardModal: true
    })
  }
  closeAddGroupDashboardModal = () => {
    this.setState({
      addGroupDashboardModal: false
    })
  }

  showToolbar = () => {
    const { classes } = this.props
    return (
      <Toolbar className='dashboards-toolbar' classes={{ root: classes.bar }}>
        {this.showActualDashboardToolbar()}

        <div className='dashboards-toolbar_title' />

        {this.state.incompatible || !this.props.canCreateGroupDashboards ? null : (
          <Button
            className='add-dashboard-button'
            classes={{ root: classes.button }}
            onClick={this.openAddGroupDashboardModal}
          >
            {this.formatMessage(messages.addGroupDashboard)}
          </Button>
        )}
      </Toolbar>
    )
  }

  showActualDashboardToolbar = () => (
    <div className='dashboards-toolbar_title'>
      {this.props.settings && this.props.settings.name ? <span>{this.props.settings.name}</span> : <span>...</span>}
    </div>
  )

  showEditingToolbar = () => {
    const { classes } = this.props
    return (
      <Toolbar className='dashboards-toolbar' classes={{ root: classes.bar }}>
        <div className='dashboards-toolbar_title'>
          <span>{this.formatMessage(messages.editingDashboard)}</span>
          &nbsp;
          {this.props.settings && this.props.settings.name ? <span>{this.props.settings.name}</span> : <span>...</span>}
        </div>
      </Toolbar>
    )
  }

  render() {
    const dashboardIds = this.props.getGroupDashboards.map(dashboard => dashboard.hashId)
    const { classes } = this.props
    return (
      <React.Fragment>
        <div className='dashboards'>
          <AppBar className='dashboards-appbar' position='static'>
            {this.props.isEditing ? this.showEditingToolbar() : this.showToolbar()}
          </AppBar>

          {this.state.loading ? (
            <LoadingDashboard />
          ) : this.state.incompatible ? (
            <UnsupportedDashboard />
          ) : this.props.getGroupDashboards.length === 0 ? (
            <NoDashboard />
          ) : (
            <DashboardWrapper
              key={
                this.props.getGroupDashboards[this.state.tabValue]
                  ? this.props.getGroupDashboards[this.state.tabValue].uuid
                  : 'x7r'
              }
              dashboardData={this.props.getGroupDashboards[this.state.tabValue]}
              dashboardIds={dashboardIds}
              groupId={this.props.groupId}
              refresh={this.updateNodeDashboards}
            />
          )}
        </div>

        {this.state.addGroupDashboardModal && this.props.canCreateGroupDashboards && (
          <AddGroupDashboard
            closeAddGroupDashboardModal={this.closeAddGroupDashboardModal}
            selectedGroup={this.props.selectedGroup}
          />
        )}
      </React.Fragment>
    )
  }
}

GroupDashboards.propTypes = {
  canCreateGroupDashboards: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  dashboardBarColor: PropTypes.string.isRequired,
  finishEditDashboard: PropTypes.func.isRequired,
  fontColor: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired
}

GroupDashboards.defaultProps = {
  groupId: ''
}

export default withStyles(styles)(withRouter(injectIntl(GroupDashboards)))
