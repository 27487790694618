import { defineMessages } from 'react-intl'

const messages = defineMessages({
  cancel: {
    id: 'Modals.cancel',
    description: 'Cancel button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'Modals.save',
    description: 'Save button text',
    defaultMessage: 'Save'
  },
  delete: {
    id: 'Modals.delete',
    description: 'Deleting confirming button text',
    defaultMessage: 'Delete'
  },
  fieldMustHaveValue: {
    id: 'Modals.fieldMustHaveValue',
    description: 'Field must have value message',
    defaultMessage: 'Must have a value.'
  },
  newDashboard: {
    id: 'AddDashboard.newDashboard',
    description: 'New dashboard title',
    defaultMessage: 'NEW DASHBOARD'
  },
  name: {
    id: 'AddDashboard.name',
    description: 'Dashboard name field',
    defaultMessage: 'Dashboard name'
  },
  description: {
    id: 'AddDashboard.description',
    description: 'Dashboard description field',
    defaultMessage: 'Dashboard description'
  },
  deleteDashboard: {
    id: 'Modals.deleteDashboard',
    description: 'Delete dashboard',
    defaultMessage: 'DELETE DASHBOARD'
  },

  mustHaveAValue: {
    id: 'Modals.mustHaveAValue',
    description: 'Error message :: Input field it\'s empty',
    defaultMessage: 'Must have a value.'
  },
  maximum50Characters: {
    id: 'Modals.maximum50Characters',
    description:
      'Error message :: The name can have a maximum of 50 characters. It has been trimmed.',
    defaultMessage: 'Must have a value.'
  },
  maxCharacters: {
    id: 'Modals.maxCharacters',
    description: 'Info message :: 50 characters maximum',
    defaultMessage: '50 characters maximum.'
  }
})

export default messages
