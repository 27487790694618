import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

import Alert from 'components/Alert'

import messages from './messages'

const styles = theme => ({
  dialogPaper: {
    minHeight: 400,
    height: '55vh',
    maxHeight: 470
  },
  dialogPaperWithMd: {
    maxWidth: 750
  }
})

const Feedback = ({
  activationErrorMessage,
  alertMessages,
  alertMessagesText,
  alertMessagesTitle,
  alertMessagesType,
  classes,
  deviceType,
  eid,
  handleClose,
  handleExited,
  intl,
  name,
  open,
  regCode,
  serialNumber,
  status
}) => {
  const { formatMessage } = intl

  let image
  switch (deviceType) {
    case 10:
      image = 'cs10.png'
      break
    case 100:
      image = 'cs100.png'
      break
    case 500:
      image = 'cs500.jpg'
      break
    default:
      image = 'cs100.png'
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper, paperWidthMd: classes.dialogPaperWithMd }}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth="md"
      onExited={handleExited}
      open={open}
    >
      <DialogTitle>
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 3,
            top: 3
          }}
        >
          <CloseIcon />
        </IconButton>
        {formatMessage(messages.activationPhaseTitle)}
      </DialogTitle>
      <DialogContent>
        {alertMessages ? (
          <Alert
            alertType={alertMessagesType}
            messageText={alertMessagesText}
            messageTitle={alertMessagesTitle}
            showAlert={alertMessages}
            style={{ marginBottom: 0 }}
          />
        ) : (
          <Grid alignItems="flex-start" container={true} justify="center" spacing={3}>
            <Grid item={true} sm={6} xs={12}>
              <label className="add-device-label">{formatMessage(messages.csType)}:</label>
              <div className="add-device-label">{'CS' + deviceType}</div>
              <label className="add-device-label">{formatMessage(messages.name)}:</label>
              <div className="add-device-label">{name}</div>
              <label className="add-device-label">{formatMessage(messages.eid)}:</label>
              <div className="add-device-label">{eid}</div>
              {deviceType !== 10 ? (
                <label className="add-device-label">{formatMessage(messages.serialNumber)}:</label>
              ) : null}
              {deviceType !== 10 ? <span className="add-device-label">{serialNumber}</span> : null}
              {deviceType === 10 ? (
                <label className="add-device-label">{formatMessage(messages.regCode)}:</label>
              ) : null}
              {deviceType === 10 ? <span className="add-device-label">{regCode}</span> : null}
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              {activationErrorMessage === '' ? (
                <div className="add-device-div">
                  {formatMessage(messages.csType)}: {deviceType}
                  <br />
                  {formatMessage(messages.status)}: <span style={{ color: 'green' }}>{status}</span>
                </div>
              ) : (
                <div className="add-device-error-div">
                  <span style={{ color: 'red' }}>{activationErrorMessage}</span>
                </div>
              )}
              <br />
              {activationErrorMessage === '' && (
                <div style={{ height: 140 }}>
                  <img
                    alt={formatMessage(messages.deviceImage)}
                    className="add-device-img"
                    src={process.env.PUBLIC_URL + '/images/' + image}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button className="cancel-button" onClick={handleClose}>
          {formatMessage(messages.cancel)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Feedback.propTypes = {
  activationErrorMessage: PropTypes.string.isRequired,
  alertMessages: PropTypes.bool.isRequired,
  alertMessagesText: PropTypes.array.isRequired,
  alertMessagesTitle: PropTypes.string.isRequired,
  alertMessagesType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  deviceType: PropTypes.number.isRequired,
  eid: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExited: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  regCode: PropTypes.string.isRequired,
  serialNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(Feedback))
