import React from 'react'
import { Map } from './wrapper'

import Marker from '../../mapView/CustomizedMarker'

import { LocationViewerProps } from '../props'
import { injectIntl } from 'react-intl'

const LocationViewer = ({
  position,
  label = '',
  size = 40,
  url = undefined,
  ...other
}) => {
  if (!position) {
    return null
  }

  return (
    <Map defaultCenter={position} zoom={10} {...other}>
      <Marker position={position} label={label} size={size} url={url} />
    </Map>
  )
}

LocationViewer.propTypes = LocationViewerProps

export default injectIntl(LocationViewer)
