import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'EditUser.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error404Message: {
    id: 'EditUser.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'EditUser.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'EditUser.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'EditUser.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error403Message: {
    id: 'EditUser.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  return: {
    id: 'EditUser.return',
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: 'EditUser.thisFieldIsRequired',
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  selectARole: {
    id: 'EditUser.selectARole',
    description: 'Select a role text',
    defaultMessage: 'Select a role.'
  },
  notAWellFormedEmailAddress: {
    id: 'EditUser.notAWellFormedEmailAddress',
    description: 'Not a well formed email address text',
    defaultMessage: 'Not a well-formed email address'
  },
  bothPasswordsMustBeEqual: {
    id: 'EditUser.bothPasswordsMustBeEqual',
    description: 'Both passwords must be equal message',
    defaultMessage: '\'Password\' and \'Repeat password\' must be equal.'
  },
  selectAGroup: {
    id: 'EditUser.selectAGroup',
    description: 'Select a group text',
    defaultMessage: 'Select a group.'
  },
  otherError404Message: {
    id: 'EditUser.otherError404Message',
    description: 'Other error 404 message',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  error409Message: {
    id: 'EditUser.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Conflict.'
  },
  error422Message: {
    id: 'EditUser.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Field validation error.'
  },
  error502Message: {
    id: 'EditUser.error502Message',
    description: 'Error 502 message',
    defaultMessage: 'Bad gateway'
  },
  firstName: {
    id: 'EditUser.firstName',
    description: 'First name floating label text',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'EditUser.lastName',
    description: 'Last name floating label text',
    defaultMessage: 'Last name'
  },
  email: {
    id: 'EditUser.email',
    description: 'Email floating label text',
    defaultMessage: 'Email (login)'
  },
  password: {
    id: 'EditUser.password',
    description: 'Password floating label text',
    defaultMessage: 'Password *'
  },
  repeatPassword: {
    id: 'EditUser.repeatPassword',
    description: 'Repeat password floating label text',
    defaultMessage: 'Repeat password *'
  },
  enabled: {
    id: 'EditUser.enabled',
    description: 'Enabled label text',
    defaultMessage: 'Enabled'
  },
  notify: {
    id: 'EditUser.notify',
    description: 'Notify option label',
    defaultMessage: 'Notify **'
  },
  createdAt: {
    id: 'EditUser.createdAt',
    description: 'Created at label',
    defaultMessage: 'Created at:'
  },
  modifiedAt: {
    id: 'EditUser.modifiedAt',
    description: 'Modified at label',
    defaultMessage: 'Modified at:'
  },
  selectRole: {
    id: 'EditUser.selectRole',
    description: 'Select role label',
    defaultMessage: 'Select role'
  },
  selectGroup: {
    id: 'EditUser.selectGroup',
    description: 'Select group option label',
    defaultMessage: 'Select group'
  },
  remarks: {
    id: 'EditUser.remarks',
    description: 'Remarks floating label text',
    defaultMessage: 'Remarks (optional)'
  },
  dontInsert: {
    id: 'EditUser.dontInsert',
    description: 'Don\'t insert the password condition text',
    defaultMessage: '* Don\'t insert the password if you don\'t want to change it.'
  },
  validPasswordRules: {
    id: 'EditUser.validPasswordRules',
    description: 'Valid password rules text',
    defaultMessage: '* Valid password rules:'
  },
  lengthBetween: {
    id: 'EditUser.lengthBetween',
    description: 'Length between condition text',
    defaultMessage: 'Length between 8 and 30 characters'
  },
  atLeastOneUppercase: {
    id: 'EditUser.atLeastOneUppercase',
    description: 'At least one uppercase condition text',
    defaultMessage: 'At least one uppercase character.'
  },
  atLeastOneLowercase: {
    id: 'EditUser.atLeastOneLowercase',
    description: 'At least one lowercase condition text',
    defaultMessage: 'At least one lowercase character.'
  },
  atLeastOneNumber: {
    id: 'EditUser.atLeastOneNumber',
    description: 'At least one number condition text',
    defaultMessage: 'At least one number.'
  },
  whiteSpaceNotAllowed: {
    id: 'EditUser.whiteSpaceNotAllowed',
    description: 'White spaces not allowed condition text',
    defaultMessage: 'White spaces not allowed.'
  },
  specialCharactersNotAllowed: {
    id: 'EditUser.specialCharactersNotAllowed',
    description: 'Special characters not allowed condition text',
    defaultMessage: 'Special characters (-, _, !, #, %, etc) not allowed.'
  },
  systemWillNotify: {
    id: 'EditUser.systemWillNotify',
    description: 'System will notify text',
    defaultMessage: ' ** The system will notify the user of the notifications to\n which it has been added.'
  },
  save: {
    id: 'EditUser.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  error: {
    id: 'EditUser.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'EditUser.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  language: {
    id: 'EditUser.language',
    description: 'Language input label',
    defaultMessage: 'Language'
  },
  group: {
    id: 'EditUser.group',
    description: 'Group',
    defaultMessage: 'Group'
  },
  role: {
    id: 'EditUser.role',
    description: 'Role',
    defaultMessage: 'Role'
  },
  editUserTitle: {
    id: 'EditUser.editUserTitle',
    description: 'Edit user title',
    defaultMessage: 'Edit user: {user}'
  }
})

export default messages
