import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Machines from './Machines'
import { BASE_URL } from './urls'

const MachinesHome = ({ canReadWriteNodes = true }) => (
  <Switch>{canReadWriteNodes && <Route component={Machines} path={BASE_URL} />}</Switch>
)

export default MachinesHome
