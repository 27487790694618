const createHttpAction = (type, request, client = 'default') => ({
  type,
  payload: {
    request,
    client
  }
})

const createCacheableHttpAction = (type, request, toCache) => {
  const ret = createHttpAction(type, request)
  ret.meta = {
    cache: toCache
  }
  return ret
}

// eslint-disable-next-line max-params
const createRequestAction = (type, url, method = 'get', data = null, client = 'default') =>
  createHttpAction(type, { url, method, data, maxRedirects: 0 }, client)

/**
 * cachingOptions might have the following attributes:
 *   - ttl: Time-to-live for this request's response in the cache.
 */
const createCachedRequestAction = (type, url, cachingOptions = {}) =>
  createCacheableHttpAction(type, { url, method: 'get', maxRedirects: 0 }, cachingOptions)

export { createRequestAction, createCachedRequestAction }
