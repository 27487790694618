import moment from 'moment'
import { saveAs } from 'file-saver'
import { client, logError } from 'utils/http'

const composeLocalQuery = (
  filter,
  filterStatus,
  filterArchived,
  filterDates,
  secondaryGlobalQuery,
  page,
  pageLength
  // eslint-disable-next-line max-params
) => {
  let query = ''
  let filterQuery = ''
  let separator = ''
  let statusQuery = ''
  let statusSeparator = ''

  if (secondaryGlobalQuery !== '') {
    filterQuery = secondaryGlobalQuery
    separator = ';'
  }

  /* FILTER BY DATES */
  if (filterDates.from && filterDates.from !== null && filterDates.to && filterDates.to !== null) {
    filterQuery =
      filterQuery +
      separator +
      '(creationDate>' +
      moment(filterDates.from).valueOf() +
      ');(creationDate<' +
      moment(filterDates.to).valueOf() +
      ')'
    separator = ';'
  }

  /* FILTER BY STATUS */
  if (filterStatus.waiting) {
    statusQuery = statusQuery + statusSeparator + 'status==waiting'
    statusSeparator = ','
  }
  if (filterStatus.executing) {
    statusQuery = statusQuery + statusSeparator + 'status==executing'
    statusSeparator = ','
  }
  if (filterStatus.completed) {
    statusQuery = statusQuery + statusSeparator + 'status==completed'
    statusSeparator = ','
  }
  if (filterStatus.failed) {
    statusQuery = statusQuery + statusSeparator + 'status==failed'
    statusSeparator = ','
  }
  if (filterStatus.canceled) {
    statusQuery = statusQuery + statusSeparator + 'status==canceled'
    statusSeparator = ','
  }

  if (statusQuery !== '') {
    filterQuery = filterQuery + separator + '(' + statusQuery + ')'
    separator = ';'
  }

  /* FILTER BY ARCHIVED */
  //Si selecciona "mostrar archivadas" mostramos todas archivadas y no por eso en el filtro
  //aparece "archived==false" y después otro "archived==XX" que puede ser true o false.
  if (filterArchived !== undefined) {
    filterQuery = filterQuery + separator + '(archived==false,archived==' + filterArchived + ')'
    separator = ';'
  }

  /* FILTER BY NODE NAME */
  if (filter.hashId !== undefined) {
    filterQuery = filterQuery + separator + '(nodeName=="*' + filter.hashId.value + '*")'
    separator = ';'
  }

  /* FILTER BY TASK DESCRIPTION */
  if (filter.actionDescription !== undefined) {
    filterQuery = filterQuery + separator + '(actionDescription=="*' + filter.actionDescription.value + '*")'
    separator = ';'
  }

  /* FILTER BY TASK CREATOR */
  if (filter.userEmail !== undefined) {
    filterQuery = filterQuery + separator + '(userEmail=="*' + filter.userEmail.value + '*")'
  }

  if (filterQuery !== '') {
    query = '?' + query + 'search=' + filterQuery
  }

  if (query === '') {
    query = query + '?'
  } else {
    query = query + '&'
  }

  query = query + 'page=' + (page - 1) + '&size=' + pageLength

  return query
}

const handleDownloadFile = task => {
  const downloadFile = () => {
    let content = null
    client
      .downloadTask(task.hashId)
      .then(response => {
        content = response.data
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
        let extension = '.cfg'
        if (task.actionName === 'READ_MEMORY') {
          extension = '.eep.xml'
        }

        saveAs(blob, moment(task.createdDate).format('L HH.mm.ss') + ' - ' + task.nodeName + extension, true)
      })
      .catch(response => {
        logError(response)
      })
  }

  return downloadFile
}

const handleDownloadTaskFile = task => {
  const downloadTaskFile = () => {
    let extension = '000'

    switch (task.actionName) {
      case 'UPDATE_FIRMWARE':
        extension = 'hex'
        break
      case 'WRITE_CONFIGURATION':
        extension = 'cfg'
        break
      case 'WRITE_MEMORY':
        extension = 'eep.xml'
        break
      case 'UPDATE_FIRMWARE_CS':
        extension = 'bin'
        break
      default:
        extension = '000'
    }

    client
      .downloadTaskFile(task)
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' })
        saveAs(blob, moment(task.createdDate).format('L HH.mm.ss') + ' - ' + task.nodeName + '.' + extension, true)
      })
      .catch(response => {
        logError(response)
      })
  }

  return downloadTaskFile
}

export { composeLocalQuery, handleDownloadFile, handleDownloadTaskFile }
