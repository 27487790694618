const GaugeTemplate = {
  id: '',
  width: 300,
  height: 300,
  x: 20,
  y: 20,
  minHeight: 100,
  minWidth: 100,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'gauge',
    params: {
      data: '',
      minValue: 0,
      maxValue: 100,
      numberOfDecimals: 0,
      valueType: '',
      value: {
        timestamp: '',
        value: ''
      }
    }
  }
}

export default GaugeTemplate
