import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from '@material-ui/core/SvgIcon'
import { Marker } from 'components/Map'

/*
// https://openclipart.org/download/263177/Crane-Truck.svg
// https://openclipart.org/download/214750/cyberscooty_-_raspberry_pi_no_logo.svg

<path
  d="M 12.23,23.79 7.02,18.76 c 3.43,-1.31 6.33,-1.54 10.113,0 -2.41,2.61 -2.40,2.53 -4.9,5.06 z"
  style={{
    stroke: '#000',
    strokeWidth: 1,
    strokeOpacity: 1
  }}
/>
<circle
  r="4"
  cy="19"
  cx="19"
  style={{
    stroke: '#000',
    strokeWidth: 1,
    strokeOpacity: 1
  }}
/>
*/

const CustomizedMarker = ({ url, ...markerProps }) => (
  <Marker {...markerProps}>
    {url && (
      <SvgIcon>
        <image x="0" y="0" width="100%" height="100%" href={url} xlinkHref={url} />
        <circle
          r="4"
          cy="5"
          cx="19"
          style={{
            stroke: '#000',
            strokeWidth: 1,
            strokeOpacity: 1
          }}
        />
      </SvgIcon>
    )}
  </Marker>
)

CustomizedMarker.propTypes = {
  url: PropTypes.string
}

export default CustomizedMarker
