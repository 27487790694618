import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as reports from 'modules/reports/actions'
import * as reportsUtils from 'modules/reports/reducer'
import csNode from 'modules/csNode'
import { actions as cs500Actions } from 'ducks/configurationsCS500'
import { actions as geotrackingActions } from 'ducks/geotracking'
import { utils as groupUtils } from 'ducks/groups'
import { utils as template } from 'ducks/template'
import { actions as advancedSignalActions } from 'ducks/advancedSignals'

import ChartView from './ChartView'

const mapStateToProps = state => ({
  groupId: groupUtils.getSelectedGroup(state).id,
  isAdvancedSignalsLoading: reportsUtils.getIsAdvancedSignalsLoading(state),
  isAggregatedGpsTrackingsLoading: reportsUtils.getIsAggregatedGpsTrackingsLoading(state),
  isAggregatedSignalsLoading: reportsUtils.getIsAggregatedSignalsLoading(state),
  isGpsTrackingsLoading: reportsUtils.getIsGpsTrackingsLoading(state),
  isSidebarCollapsed: template.isCollapsed(state),
  isSignalsLoading: reportsUtils.getIsSignalsLoading(state),
  queryStatus: reportsUtils.getQueryStatus(state)
})

const mapDispatchToProps = dispatch => ({
  clearChart: () => dispatch(reports.clearReportChart()),
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  fetchDevice: (deviceId, groupId) => dispatch(csNode.actions.fetchCSNode(deviceId, groupId)),
  getAssets: (groupId, limit, offset, deviceFields) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields)),
  // eslint-disable-next-line max-params
  getChartTimeSeries: (nodeId, start, end, filters, agg, bucket) =>
    dispatch(reports.getChartTimeSeries(nodeId, start, end, filters, agg, bucket)),
  // eslint-disable-next-line max-params
  getChartTimeSeriesToExport: (nodeId, start, end, filters, agg, bucket) => {
    const { promise } = dispatch(reports.getChartTimeSeriesToExport(nodeId, start, end, filters, agg, bucket))
    return promise
  },
  // eslint-disable-next-line max-params
  getGPSTrackingTimeSeries: (deviceEid, start, end, page, sortOrder) => {
    const { promise } = dispatch(
      geotrackingActions.getGPSTrackingTimeSeriesForCharts(deviceEid, start, end, page, sortOrder)
    )
    return promise
  },
  // eslint-disable-next-line max-params
  getGPSTrackingTimeSeriesToExport: (deviceEid, start, end, page, sortOrder) => {
    const { promise } = dispatch(
      geotrackingActions.getGPSTrackingTimeSeriesToExport(deviceEid, start, end, page, sortOrder)
    )
    return promise
  },
  // eslint-disable-next-line max-params
  getAggregatedGPSTrackingTimeSeries: (deviceEid, deviceType, groupId, start, end, size, page, sortOrder, agg) => {
    const { promise } = dispatch(
      geotrackingActions.getAggregatedGPSTrackingTimeSeriesForCharts(
        deviceEid,
        deviceType,
        groupId,
        start,
        end,
        size,
        page,
        sortOrder,
        agg
      )
    )
    return promise
  },
  // eslint-disable-next-line max-params
  getAggregatedGPSTrackingTimeSeriesToExport: (
    deviceEid,
    deviceType,
    groupId,
    start,
    end,
    size,
    page,
    sortOrder,
    agg
    // eslint-disable-next-line max-params
  ) => {
    const { promise } = dispatch(
      geotrackingActions.getAggregatedGPSTrackingTimeSeriesToExport(
        deviceEid,
        deviceType,
        groupId,
        start,
        end,
        size,
        page,
        sortOrder,
        agg
      )
    )
    return promise
  },
  getAdvancedSignals: (groupId, deviceEid) => dispatch(advancedSignalActions.getMeasurements(groupId, deviceEid)),
  // eslint-disable-next-line max-params
  getAdvancedSignalResults: (deviceHashId, groupId, min, max, data, page, size) =>
    dispatch(advancedSignalActions.getMeasurementResults(deviceHashId, groupId, min, max, data, page, size)),
  setSearchFilters: (deviceId, filters) => dispatch(reports.setSearchFilters(deviceId, filters)),
  // eslint-disable-next-line max-params
  getAdvancedSignalResultsToExport: (deviceHashId, groupId, min, max, data, page, size) => {
    const { promise } = dispatch(
      advancedSignalActions.getMeasurementResultsToExport(deviceHashId, groupId, min, max, data, page, size)
    )
    return promise
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartView))
