import { createSelector } from 'reselect'
import { bindActionCreators } from 'redux'

// Utils is an specific instantiation of selectors from reducer.
const createMapStateToProps = utils =>
  function (state) {
    const responseMetadata = utils.getResponseMetadata(state)
    return {
      title: utils.getTitle(state),
      config: utils.getConfig(state),
      minRequestDate: utils.getMinDate(state),
      maxRequestDate: utils.getMaxDate(state),
      isPlotting: utils.isPlotting(state),
      requestStatus: utils.getRequestStatus(state),
      minResponseDate: responseMetadata ? responseMetadata.minDate : null,
      maxResponseDate: responseMetadata ? responseMetadata.maxDate : null,
      previousResponseSet: responseMetadata ? responseMetadata.previous : null,
      nextResponseSet: responseMetadata ? responseMetadata.next : null,
      navigator: utils.isNavigatorEnabled(state),
      lowerXAxisLimit: utils.getLowerLimit(state),
      upperXAxisLimit: utils.getUpperLimit(state),
      realTime: utils.isOnRealTimeMode(state),
      forcedZoom: utils.getForcedZoom(state),
      canUnzoom: utils.canUnzoom(state), // If history is empty, we cannot unzoom
      predefinedZoomLevels: utils.getZoomLevels(state),
      selectedZoomLevel: utils.getSelectedZoomLevel(state)
    }
  }

const getDeviceId = ({ deviceId }) => deviceId
const getSearchHistoric = ({ searchHistoric }) => searchHistoric
const getConfigureRTSeries = ({ configureRTSeries }) => configureRTSeries
const getActions = ({ actions }) => actions
const getToDispatch = ({
  toDispatch = [
    'onLoad',
    'requestHistoricData',
    'onPreviousHistoricSet',
    'onNextHistoricSet',
    'toggleChartMode',
    'setSearchRange',
    'toggleNavigatorVisibility',
    'toogleChartMode',
    'zoomOut',
    'onSerieVisibilityChange',
    'onZoomSelected',
    'setSearchRange'
  ]
}) => toDispatch

/* eslint-disable max-params */
const filterDispatcheableActions = createSelector(
  getDeviceId,
  getSearchHistoric,
  getConfigureRTSeries,
  getActions,
  getToDispatch,
  (deviceId, searchHistoric, configureRTSeries, actions, toDispatch) => {
    const ret = []
    let act
    toDispatch.forEach(actionName => {
      switch (actionName) {
        case 'onLoad':
          act = actions.clearSeries
          break

        case 'zoomOut':
          act = actions.unzoomAction
          break

        case 'onZoomSelected':
          act = actions.selectZoomLevel
          break

        case 'requestHistoricData':
          act = () => dispatch => {
            dispatch(actions.historicSearch(deviceId, searchHistoric))
          }
          break

        case 'onPreviousHistoricSet':
          act = previousAnchor => dispatch => {
            dispatch(actions.previousHistoricSearch(deviceId, searchHistoric, previousAnchor))
          }
          break

        case 'onNextHistoricSet':
          act = nextAnchor => dispatch => {
            dispatch(actions.nextHistoricSearch(deviceId, searchHistoric, nextAnchor))
          }
          break

        case 'toggleChartMode':
          act = () => dispatch => {
            dispatch(actions.toggleMode(deviceId, configureRTSeries))
          }
          break

        case 'setSearchRange':
          act = (from, to) => dispatch => {
            dispatch(actions.setSearchRange(from, to, deviceId, searchHistoric))
          }
          break

        case 'onSerieVisibilityChange':
          act = (serieId, isVisible) => dispatch => {
            if (isVisible) {
              dispatch(actions.showSerie(serieId))
            } else {
              dispatch(actions.hideSerie(serieId))
            }
          }
          break

        default:
          // some like toggleNavigatorVisibility stay the same
          act = actions[actionName]
      }
      ret[actionName] = act
    })

    return ret
  }
)
/* eslint-enable */

/* eslint-disable max-params, no-shadow */
const createMapDispatchToProps =
  (actions, getDeviceId, searchHistoric, configureRTSeries, toDispatch) => (dispatch, ownProps) => {
    if (actions) {
      const deviceId = getDeviceId(ownProps)
      const pseudoState = {
        deviceId,
        searchHistoric,
        configureRTSeries,
        actions,
        toDispatch
      }
      return bindActionCreators(filterDispatcheableActions(pseudoState), dispatch)
    }
    return {}
  }
/* eslint-enable */

export { createMapStateToProps, createMapDispatchToProps }
