import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions as cs500Actions } from 'ducks/configurationsCS500'
import { actions as geotrackingActions } from 'ducks/geotracking'
import { utils as groupUtils } from 'ducks/groups'
import { utils } from 'ducks/login'

import { actions } from 'ducks/reports'
import * as reports from '../../actions'

import * as reportsUtils from '../../reducer'
import { getReportsUrl } from '../../url'
import EditReport from './EditReport'

import csNode from 'modules/csNode'

const mapStateToProps = (state, { history, match }) => ({
  groupId: groupUtils.getSelectedGroup(state).id,
  onEditReport: () => {
    history.push(getReportsUrl(), 'update')
  },
  plus1ConnectUserId: utils.getLoggedUserId(state),
  queryStatus: reportsUtils.getQueryStatus(state),
  reportId: match.params.reportId,
  reportsUrl: getReportsUrl(),
  isAggregatedGpsTrackingsLoading: reportsUtils.getIsAggregatedGpsTrackingsLoading(state),
  isAggregatedSignalsLoading: reportsUtils.getIsAggregatedSignalsLoading(state)
})

const mapDispatchToProps = dispatch => ({
  clearChart: () => dispatch(reports.clearReportChart()),
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  getAssets: (groupId, limit, offset, deviceFields) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields)),
  // eslint-disable-next-line max-params
  getChartTimeSeries: (nodeId, start, end, filters, agg, bucket) =>
    dispatch(reports.getChartTimeSeries(nodeId, start, end, filters, agg, bucket)),
  // eslint-disable-next-line max-params
  getAggregatedGPSTrackingTimeSeries: (deviceEid, deviceType, groupId, start, end, size, page, sortOrder, agg) => {
    const { promise } = dispatch(
      geotrackingActions.getAggregatedGPSTrackingTimeSeriesForCharts(
        deviceEid,
        deviceType,
        groupId,
        start,
        end,
        size,
        page,
        sortOrder,
        agg
      )
    )
    return promise
  },
  getSchedule: (scheduleId, groupId) => dispatch(actions.getSchedule(scheduleId, groupId)),
  setSearchFilters: (deviceId, filters) => dispatch(reports.setSearchFilters(deviceId, filters)),
  updateSchedule: (scheduleId, schedule, groupId) => dispatch(actions.updateSchedule(scheduleId, schedule, groupId)),
  updateScheduleAWS: (schedule, groupId) => dispatch(actions.createScheduleAWS(schedule, groupId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditReport))
