import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import ChartMetric from './ChartMetric'
import messages from '../../messages'

class ChartMetrics extends Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  // eslint-disable-next-line max-params
  getDefaultSensorValues = (value, unit, signalId, multiplier, offset, operationType) => {
    return {
      name: value,
      unit,
      signalId,
      multiplier,
      offset,
      aggregationType: 'none',
      bucket: 'raw',
      lineStyle: operationType ? 'column' : 'line',
      lineColor:
        '#' +
        Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0'),
      display: {
        table: false,
        chart: true
      },
      valueType: 'last',
      operationType
    }
  }

  handleDeviceChange = (metricIdx, deviceId, deviceEid) => {
    const { getDeviceConfiguration, getNewMetric, metrics, onMetricsUpdate } = this.props
    if (deviceId && metrics[metricIdx].device !== deviceId) {
      getDeviceConfiguration(deviceId)
      metrics[metricIdx] = getNewMetric({ device: deviceId, deviceEid })
      onMetricsUpdate(metrics)
    }
  }

  handleSignalUpdate = (metricIdx, sensor, sensorsOptions) => {
    const { metrics, onMetricsUpdate } = this.props
    if (sensor) {
      const toRemove = metrics[metricIdx].selectedSensorsIds.filter(x => !sensor.includes(x))
      const toAdd = sensor.filter(x => !metrics[metricIdx].selectedSensorsIds.includes(x))
      toAdd.forEach(sensorToAdd => {
        const newSensor = sensorsOptions.find(sOp => sOp.signalId === sensorToAdd)
        metrics[metricIdx].sensors.push(
          this.getDefaultSensorValues(
            newSensor.label,
            newSensor.unit,
            newSensor.signalId,
            newSensor.multiplier,
            newSensor.offset,
            newSensor.operationType
          )
        )
      })
      metrics[metricIdx].sensors = metrics[metricIdx].sensors.filter(x => !toRemove.includes(x.signalId))

      metrics[metricIdx].selectedSensorsIds = sensor
    }
    onMetricsUpdate(metrics)
  }

  handleDeleteMetric = metricIdx => {
    const { metrics, onMetricsUpdate } = this.props
    metrics.splice(metricIdx, 1)
    onMetricsUpdate(metrics)
  }

  handleDeleteSignal = (metricIdx, signalIdx) => {
    const { metrics, onMetricsUpdate } = this.props
    const deletedSignal = metrics[metricIdx].sensors.splice(signalIdx, 1)[0]
    const countSignal = metrics[metricIdx].sensors.filter(i => i.signalId === deletedSignal.signalId).length

    if (countSignal === 0) {
      metrics[metricIdx].selectedSensorsIds = metrics[metricIdx].selectedSensorsIds.filter(
        i => i !== deletedSignal.signalId
      )
    }
    onMetricsUpdate(metrics)
  }

  handleDuplicateSignal = (metricIdx, signalIdx) => {
    const { metrics, onMetricsUpdate } = this.props
    const duplicateSignal = {
      ...metrics[metricIdx].sensors[signalIdx],
      display: {
        ...metrics[metricIdx].sensors[signalIdx].display
      }
    }
    metrics[metricIdx].sensors.splice(signalIdx + 1, 0, duplicateSignal)

    onMetricsUpdate(metrics)
  }

  handleSignalColorChanged = (metricIdx, signalIdx, color) => {
    const { metrics, onMetricsUpdate } = this.props
    metrics[metricIdx].sensors[signalIdx].lineColor = color

    onMetricsUpdate(metrics)
  }

  handleSignalLinestyleChanged = (metricIdx, signalIdx, style) => {
    const { metrics, onMetricsUpdate } = this.props
    metrics[metricIdx].sensors[signalIdx].lineStyle = style

    onMetricsUpdate(metrics)
  }

  handleSignalValueTypeChanged = (metricIdx, signalIdx, valueType) => {
    const { metrics, onMetricsUpdate } = this.props
    metrics[metricIdx].sensors[signalIdx].valueType = valueType
    onMetricsUpdate(metrics)
  }

  handleSignalAggregationChanged = (metricIdx, signalIdx, aggr) => {
    const { metrics, onMetricsUpdate } = this.props
    metrics[metricIdx].sensors[signalIdx].aggregationType = aggr
    if (aggr === 'none') {
      metrics[metricIdx].sensors[signalIdx].bucket = 'raw'
    } else {
      metrics[metricIdx].sensors[signalIdx].bucket = 'hour'
    }
    onMetricsUpdate(metrics)
  }

  handleSignalBucketChanged = (metricIdx, signalIdx, buck) => {
    const { metrics, onMetricsUpdate } = this.props
    metrics[metricIdx].sensors[signalIdx].bucket = buck
    if (buck === 'raw') {
      metrics[metricIdx].sensors[signalIdx].aggregationType = 'none'
    }
    onMetricsUpdate(metrics)
  }

  render() {
    const { devices, getGpsSignals, metrics, isConfigurationLoading } = this.props

    if (devices.length === 0 || metrics.length === 0) {
      return <Typography>{this.formatMessage(messages.noMachinesOrMetric)}</Typography>
    } else {
      return (
        devices.length !== 0 &&
        metrics.map((metric, metricIndex) => {
          const isOnlyOne = metrics.length === 1
          const selectedDevices = metrics.map(m => m.device)

          return (
            <ChartMetric
              key={metricIndex}
              devices={devices}
              getGpsSignals={getGpsSignals}
              isConfigurationLoading={isConfigurationLoading}
              isOnlyOne={isOnlyOne}
              metric={metric}
              onDeleteMetric={() => this.handleDeleteMetric(metricIndex)}
              onDeleteSignal={(...args) => this.handleDeleteSignal(metricIndex, ...args)}
              onDeviceChange={(...args) => this.handleDeviceChange(metricIndex, ...args)}
              onDuplicateSignal={(...args) => this.handleDuplicateSignal(metricIndex, ...args)}
              onSignalAggregationChanged={(...args) => this.handleSignalAggregationChanged(metricIndex, ...args)}
              onSignalBucketChanged={(...args) => this.handleSignalBucketChanged(metricIndex, ...args)}
              onSignalColorChanged={(...args) => this.handleSignalColorChanged(metricIndex, ...args)}
              onSignalLinestyleChanged={(...args) => this.handleSignalLinestyleChanged(metricIndex, ...args)}
              onSignalUpdate={(...args) => this.handleSignalUpdate(metricIndex, ...args)}
              onSignalValueTypeChanged={(...args) => this.handleSignalValueTypeChanged(metricIndex, ...args)}
              selectedDevices={selectedDevices}
            />
          )
        })
      )
    }
  }
}

ChartMetrics.propTypes = {
  devices: PropTypes.array.isRequired,
  getDeviceConfiguration: PropTypes.func.isRequired,
  getGpsSignals: PropTypes.func.isRequired,
  getNewMetric: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isConfigurationLoading: PropTypes.bool.isRequired,
  metrics: PropTypes.array.isRequired,
  onMetricsUpdate: PropTypes.func.isRequired
}

export default injectIntl(ChartMetrics)
