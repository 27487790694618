import React from 'react'
import PropTypes from 'prop-types'

import { FullscreenControl } from 'react-map-gl'
import { controlsContainer, controlGroup } from './style.module.css'

const EditionPanel = ({ controls, containerRef, children }) => (
  <div className={controlsContainer}>
    <FullscreenControl container={containerRef} />
    <div className={`mapboxgl-ctrl mapboxgl-ctrl-group ${controlGroup}`}>{controls}</div>
  </div>
)

EditionPanel.propTypes = {
  children: PropTypes.node,
  containerRef: PropTypes.object,
  controls: PropTypes.node
}

export default EditionPanel
export { default as MenuItem } from './MenuItem'
