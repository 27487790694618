export const FIELDS = {
  address: 'address',
  canId: 'canId',
  canPort: 'canPort',
  frequency: 'frequency',
  idMask: 'idMask',
  lengthOfBits: 'lengthOfBits',
  multiplier: 'multiplier',
  name: 'name',
  offset: 'offset',
  pgn: 'pgn',
  pgnName: 'pgnName',
  priority: 'priority',
  signalId: 'signalId',
  signed: 'signed',
  sourceAddress: 'sourceAddress',
  spn: 'spn',
  spnName: 'spnName',
  startBit: 'startBit',
  unit: 'unit'
}

export const MFIO_FIELDS = {
  averageValue: 'averageValue',
  bias: 'bias',
  decimals: 'decimals',
  defaultPowerUp: 'defaultPowerUp',
  digThreshHigh: 'digThreshHigh',
  digThreshLow: 'digThreshLow',
  inputRange: 'inputRange',
  inputSignal: 'inputSignal',
  lastValue: 'lastValue',
  logCycle: 'logCycle',
  maxValue: 'maxValue',
  minValue: 'minValue',
  multiplier: 'multiplier',
  name: 'name',
  offset: 'offset',
  pinFunction: 'pinFunction',
  unit: 'unit'
}

export const PROTOCOL_TYPES = {
  COMMON: 'common',
  CUSTOM: 'custom',
  J1939: 'J1939',
  OWN: 'own'
}
