import { actionTypes as wt } from 'middlewares/websocket'
import { actionTypes as t } from 'ducks/geotracking'
import { isDeviceDashboardLocation, isGroupDashboardLocation } from 'utils/location'
import { newGpsPoints } from 'ducks/dashboards'
import { newGpsPoints as newGPSPoints } from 'ducks/groupDashboards'

//import moment from 'moment'

//message example
//{"latitude":2.5329597E9,"longitude":2.0801989E9,"altitude":20824.0,"speed":0.0,"heading":35524.0,"timestamp":1579181681}

const createMiddleware = isRealtimeGeolocationTopic => {
  const middleware = store => {
    const dispatch = store.dispatch
    return next => action => {
      switch (action.type) {
        case wt.MESSAGE_RECEIVED:
        case wt.GROUPED_MESSAGES_RECEIVED:
          if (isRealtimeGeolocationTopic(action.meta.topic)) {
            const points = action.payload
              .map(entry => JSON.parse(entry))
              .map(point => ({ ...point, timestamp: point.timestamp * 1000 }))
              .sort((a, b) => a.timestamp - b.timestamp)
            points.forEach(point => {
              if (point.latitude !== null && point.logitude !== null)
                dispatch({
                  type: t.NEW_GPS_POINT,
                  payload: {
                    latitude: point.latitude,
                    longitude: point.longitude,
                    altitude: point.altitude,
                    speed: point.speed,
                    heading: point.heading,
                    timestamp: point.timestamp
                  },
                  meta: action.meta
                })
            })
            if (isDeviceDashboardLocation()) dispatch(newGpsPoints(action.meta, points))
            else if (isGroupDashboardLocation()) dispatch(newGPSPoints(action.meta, points))
          }

          break

        default:
      }
      return next(action)
    }
  }
  return middleware
}

export default createMiddleware
