import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'
import { utils as groupUtils } from 'ducks/groups'

import ActionsState from './ActionsState'

const mapStateToProps = state => {
  return {
    privileges: {
      canViewActions: resolvePermissions(state, c.EVENTSHISTORY_VIEW),
      canEditActions: resolvePermissions(state, c.EVENTSHISTORY_EDIT)
    },
    secondaryGlobalQuery: '',
    groupId: groupUtils.getSelectedGroup(state).id,
    loadingGroup: groupUtils.getLoadingGroup(state)
  }
}

export default connect(mapStateToProps)(ActionsState)
