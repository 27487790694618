import * as t from '../actionTypes'
import { utils as httpUtils } from 'utils/http'

const loadable = (state = [], action) => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case t.SET_REQUEST:
      if (Array.isArray(action.payload)) {
        return action.payload
      }
      return [action.payload]

    default:
  }

  return state
}

export default loadable
export const MOUNTED_AT = 'request'

// Selectors
const getAssociatedRequestIds = substate => substate[MOUNTED_AT]
const getRequestStatus = (state, substate) =>
  httpUtils.getRequestStatus(state, getAssociatedRequestIds(substate))

// FIXME redo this to consider multiple requests!
const getResponseMetadata = (state, substate) => {
  const requestIds = getAssociatedRequestIds(substate)
  if (requestIds.length > 0) {
    return httpUtils.getResponseMetadata(state, requestIds[0])
  }
}

export { getRequestStatus, getResponseMetadata }
