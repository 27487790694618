import { defineMessages } from 'react-intl'

const messages = defineMessages({
  roleName: {
    id: 'Roles.roleName',
    description: 'Role name label',
    defaultMessage: 'Role name'
  },
  users: {
    id: 'Roles.users',
    description: 'Users label',
    defaultMessage: 'users'
  },
  createdAt: {
    id: 'Roles.createdAt',
    description: 'Created at label',
    defaultMessage: 'Created at'
  },
  modifiedAt: {
    id: 'Roles.modifiedAt',
    description: 'Modified at label',
    defaultMessage: 'Modified at'
  },
  actions: {
    id: 'Roles.actions',
    description: 'Actions label',
    defaultMessage: 'Actions'
  },
})

export default messages