import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'NewRole.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'NewRole.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'NewRole.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'NewRole.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'NewRole.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'NewRole.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  invalidRoleName: {
    id: 'NewRole.invalidRoleName',
    description: 'Error 400 message',
    defaultMessage: 'A role with the specified name already exists'
  },
  title: {
    id: 'NewRole.title',
    description: 'New role title',
    defaultMessage: 'New role'
  },
  return: {
    id: 'NewRole.return',
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: 'NewRole.thisFieldIsRequired',
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  selectAParentRole: {
    id: 'NewRole.selectAParentRole',
    description: 'Select a parent role text',
    defaultMessage: 'Select a parent role.'
  },
  rootElement: {
    id: 'NewRole.rootElement',
    description: 'Root element option text',
    defaultMessage: 'Root element'
  },
  selectRootElement: {
    id: 'NewRole.selectRootElement',
    description: 'Select root element option text',
    defaultMessage: 'Select parent role ("Root element" if no parent).'
  },
  error409Message: {
    id: 'NewRole.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation\n Role name it\'s already in use'
  },
  error415Message: {
    id: 'NewRole.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'NewRole.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  permissions: {
    id: 'NewRole.permissions',
    description: 'Permissions title text',
    defaultMessage: 'Permissions'
  },
  name: {
    id: 'NewRole.name',
    description: 'Name floating label text',
    defaultMessage: 'Name'
  },
  selectParentRole: {
    id: 'NewRole.selectParentRole',
    description: 'Select parent role text',
    defaultMessage: 'Select parent role'
  },
  save: {
    id: 'NewRole.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  error: {
    id: 'NewRole.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'NewRole.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error500Message: {
    id: 'NewRole.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  selectAnExampleRole: {
    id: 'NewRole.selectAnExampleRole',
    description: 'Select an example role',
    defaultMessage: 'Select an example role'
  }
})

export default messages
