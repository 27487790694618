import * as t from '../actionTypes'
import * as c from '../constants'

import { getNotificationCriticalityKeys } from 'utils/notificationCriticality'
import { parseMarker } from '../utils'

const initialState = {
  isMounted: false,
  clickedMarkerId: undefined,
  clickedOn: 0,
  selectedGroup: undefined,
  mapLeft: 220,
  currentZoom: 1,
  currentFilters: getNotificationCriticalityKeys(),
  currentMapCenter: {
    latitude: 43.599336,
    longitude: -2.93884
  },
  isFilterOpened: false,
  searchInput: '',
  hideResults: true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.SET_SHOWN:
      return {
        ...state,
        isMounted: action.payload,
        // if isMounted then unselect, otherwise it is already null
        clickedMarkerId: undefined
      }

    case t.SET_ZOOM_LEVEL:
      return { ...state, currentZoom: action.payload + Math.random() / 10000 }

    case t.SET_CENTER:
      return {
        ...state,
        currentMapCenter: { ...action.payload}
      }

    case t.ADD_FILTER:
      return {
        ...state,
        currentFilters: [...state.currentFilters, action.payload]
      }

    case t.REMOVE_FILTER:
      return {
        ...state,
        currentFilters: state.currentFilters
          .slice(0, state.currentFilters.indexOf(action.payload))
          .concat(state.currentFilters.slice(state.currentFilters.indexOf(action.payload) + 1))
      }

    case t.FILTER_NODE_BY_NAME:
      return {
        ...state,
        searchInput: action.payload,
        hideResults: false
      }

    case t.RESET_SEARCH_FIELD:
      return {
        ...state,
        searchInput: '',
        hideResults: true
      }

    case t.RESET_SEARCH_FILTER:
      return {
        ...state,
        currentFilters: []
      }

    case t.SELECT_NODE:
      return {
        ...state,
        clickedMarkerId: action.payload.id,
        clickedOn: action.payload.time
      }

    case t.UNSELECT_NODE:
      // Mobile device propagates click event to map too and we must ignore
      // that second click after the marker selection.
      // https://github.com/tomchentw/react-google-maps/issues/84
      if (state.clickedOn + 1000 < action.payload) {
        return {
          ...state,
          clickedMarkerId: undefined,
          clickedOn: action.payload
        }
      }
      break

    case t.SELECT_GROUP:
      return {
        ...state,
        selectedGroup: action.payload
        //currentZoom: 12
      }

    case t.UNSELECT_GROUP:
      return {
        ...state,
        selectedGroup: undefined
        //currentZoom: initialState.currentZoom
      }

    default:
  }

  return state
}

// Internal selectors
const getMapSelector = state => state[c.NAME]
const getIsMounted = state => getMapSelector(state).isMounted
const getSearchInput = state => getMapSelector(state).searchInput
const getCurrentFilters = state => getMapSelector(state).currentFilters
const getCurrentZoom = state => getMapSelector(state).currentZoom
const getCurrentMapCenter = state => getMapSelector(state).currentMapCenter
const getSelectedMarkerId = state => getMapSelector(state).clickedMarkerId
const isNodeMarkerClicked = state => parseMarker(getSelectedMarkerId(state)).isNode
const getSelectedGroup = state => getMapSelector(state).selectedGroup

export default reducer
export {
  getIsMounted,
  getSearchInput,
  getCurrentFilters,
  getCurrentZoom,
  getCurrentMapCenter,
  getSelectedMarkerId,
  isNodeMarkerClicked,
  getSelectedGroup
}
