import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import RealTimeValueTemplate from './RealTimeValueTemplate'
import messages from './messages'
import {
  getValueTypes,
  generatePartialStateFromProps,
  withDefaultValues,
  getDecimals,
  manageWSSubscriptions
} from '../utils'




const styles = theme => ({
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
})
class RealTimeValueSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const widgetData = withDefaultValues(props.data, RealTimeValueTemplate.content.params)
    const { size, bold, align, numberOfDecimals } = widgetData

    this.state = {
      ...partialState,
      size,
      bold,
      align,
      numberOfDecimals,
      numberOfDecimalsErrorText: '',
      numberOfDecimalsDisabled: Number.isInteger(getDecimals(props.dinamicData, partialState.dinamicData))
    }
  }

  handleChange = name => event => {
    let change = { [name]: event.target.value }
    if (name === 'numberOfDecimals') change = { ...change, [name + 'ErrorText']: '' }
    this.setState(change)
  }

  handleDinamicChange = (event, value) => {
    const { signalId = '' } = value || {}
    this.setState(state => {
      const { numberOfDecimals, numberOfDecimalsErrorText, valueType } = state
      const { dinamicData, staticData } = this.props
      let decimals = numberOfDecimals
      let decimalsDisabled = false
      let decimalsErrorText = numberOfDecimalsErrorText
      const valueTypes = getValueTypes(dinamicData, staticData, signalId)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      if (Number.isInteger(getDecimals(dinamicData, signalId))) {
        decimals = getDecimals(dinamicData, signalId)
        decimalsDisabled = true
        decimalsErrorText = ''
      }
      const change = {
        dinamicData: signalId,
        numberOfDecimalsDisabled: decimalsDisabled,
        numberOfDecimals: decimals,
        numberOfDecimalsErrorText: decimalsErrorText,
        valueTypes,
        valueType: newValueType
      }
      return change
    })
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleSaveSettings = () => {
    let error = false

    const { numberOfDecimals } = this.state

    if (numberOfDecimals === '') {
      error = true
      this.setState({
        numberOfDecimalsErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    } else {
      if (numberOfDecimals < 0) {
        error = true
        this.setState({
          numberOfDecimalsErrorText: this.formatMessage(messages.onlyZeroOrHigherIsAllowed)
        })
      }
    }

    if (!error) {
      let value = ''

      const { dinamicData, wsSubscribedData, valueType } = this.state
      const { data: propsData, saveSettings } = this.props

      if (
        propsData.value &&
        dinamicData !== '' &&
        dinamicData === wsSubscribedData &&
        propsData.valueType === valueType
      ) {
        value = { ...propsData.value }
      } else {
        value = {
          timestamp: '',
          value: ''
        }
      }

      const { size, bold, align } = this.state
      const data = {
        data: dinamicData,
        size,
        bold,
        align,
        numberOfDecimals,
        valueType,
        value
      }

      saveSettings(data)

      const { eid, nodeCredentials, subscribeWidgetToWS, unsubscribeWidgetFromWS } = this.props

      manageWSSubscriptions(wsSubscribedData, data, eid, nodeCredentials, subscribeWidgetToWS, unsubscribeWidgetFromWS)

      this.setState({
        wsSubscribedData: data.data
      })
    }
  }

  render() {
    const { dinamicData: signals, classes } = this.props
    const {
      dinamicData,
      valueTypes,
      valueType,
      size,
      bold,
      align,
      numberOfDecimalsDisabled,
      numberOfDecimalsErrorText,
      numberOfDecimals
    } = this.state
    const needsValueType = valueTypes.length > 0
    const options = signals.map(signal => {
      let signalType = this.formatMessage(messages.canBusSignals)
      if (signal.isGPS) signalType = this.formatMessage(messages.gpsSignals)
      else if (signal.isMFIO) signalType = this.formatMessage(messages.mfioSignals)
      return { ...signal, signalType }
    })

    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.realTimeValueWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.realTimeValueWidgetSettings)}
            </span>
          </DialogContentText>
          {options.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  classes={{ groupLabel: classes.groupLabel }}
                  fullWidth
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.signalId === value.signalId}
                  groupBy={option => option.signalType}
                  id='grouped-signals'
                  onChange={this.handleDinamicChange}
                  options={options}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={options.find(signal => signal.signalId === dinamicData) || null}
                />
              </Grid>
              {needsValueType && (
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='valueType-label-placeholder' shrink>
                      {this.formatMessage(messages.valueType)}
                    </InputLabel>
                    <Select onChange={this.handleChange('valueType')} value={valueType}>
                      {valueTypes.map(type => {
                        return (
                          <MenuItem key={type} value={type}>
                            {this.formatMessage(messages[type])}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DialogContentText id='alert-dialog-slide-description'>
                  <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                    {this.formatMessage(messages.notSupportedMachine)}
                  </span>
                </DialogContentText>
              </Grid>
            </Grid>
          )}

          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherValueWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='size-label-placeholder' shrink>
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  autoWidth
                  displayEmpty
                  input={<Input id='size-label-placeholder' name='size' />}
                  name='size'
                  onChange={this.handleChange('size')}
                  value={size}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='bold-label' shrink>
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='weight-label-placeholder' name='weight' />}
                  name='weight'
                  onChange={this.handleChange('bold')}
                  value={bold}
                >
                  <MenuItem value='bold'>{this.formatMessage(messages.bold)}</MenuItem>
                  <MenuItem value='normal'>{this.formatMessage(messages.normal)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='align-label' shrink>
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='align-label-placeholder' name='align' />}
                  name='align'
                  onChange={this.handleChange('align')}
                  value={align}
                >
                  <MenuItem value='left'>{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value='center'>{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value='right'>{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                key='numberOfDecimals'
                disabled={numberOfDecimalsDisabled}
                error={numberOfDecimalsErrorText !== ''}
                fullWidth
                helperText={numberOfDecimalsErrorText}
                id='numberOfDecimals'
                label={this.formatMessage(messages.numberOfDecimals)}
                onChange={this.handleChange('numberOfDecimals')}
                required
                //InputLabelProps={{ shrink: true }}
                type='number'
                value={numberOfDecimals}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

RealTimeValueSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  nodeCredentials: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  staticData: PropTypes.array.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(RealTimeValueSettings))
