import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'react-map-gl'
import formatcoords from 'formatcoords'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

import DateLabel from 'components/DateLabel'

const styles = {
  popupContainer: {
    '& .mapboxgl-popup-tip': {
      marginTop: '-1px'
    },
    '& .mapboxgl-popup-content': {
      borderRadius: '6px',
      padding: 0
    }
  },
  chip: {
    height: '24px',
    width: '100%'
  },
  dataLine: {
    marginBottom: '7px'
  },
  icon: {
    color: '#212121',
    fontSize: '14px',
    textAlign: 'center',
    verticalAlign: 'text-top',
    width: '17px'
  }
}

const GpsInfoPopup = props => {
  const { classes, popupInfo, onPopupClose } = props

  var coords = formatcoords(popupInfo.position.latitude, popupInfo.position.longitude).format({
    latLonSeparator: '-'
  })
  const [formattedLatitude, formattedLongitude] = coords.split('-')

  const hideSpeedInfo = popupInfo.position.speed === null || popupInfo.position.speed === 'NaN'
  const hideAltitudeInfo = popupInfo.position.altitude === null || popupInfo.position.altitude === 'NaN'

  return (
    <Popup
      anchor='top'
      className={classes.popupContainer}
      closeButton={false}
      closeOnClick
      latitude={popupInfo.position.latitude}
      longitude={popupInfo.position.longitude}
      onClose={onPopupClose}
      tipSize={10}
    >
      <Grid
        container
        style={{
          marginTop: '10px',
          borderTop: '5px solid ' + popupInfo.color,
          minWidth: '240px'
        }}
      >
        <Grid
          item
          style={{
            padding: '10px 25px 20px 25px'
          }}
          xs={12}
        >
          <Grid alignItems='center' container style={{ marginBottom: '20px' }}>
            <Grid item xs={10}>
              <Typography
                style={{
                  paddingRight: '20px'
                }}
                variant='h5'
              >
                {popupInfo.name}
              </Typography>
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <IconButton onClick={onPopupClose} style={{ padding: '3px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid classes={{ container: classes.dataLine }} container>
            <Grid item xs={2}>
              <Icon className='fa fa-calendar' classes={{ root: classes.icon }} />
            </Grid>
            <Grid item xs={10}>
              <DateLabel date={popupInfo.position.timestamp} format='DD-MMM-YYYY' />
            </Grid>
          </Grid>

          <Grid classes={{ container: classes.dataLine }} container>
            <Grid item xs={2}>
              <Icon className='fas fa-clock' classes={{ root: classes.icon }} />
            </Grid>
            <Grid item xs={10}>
              <DateLabel date={popupInfo.position.timestamp} format='HH:mm:ss' />
            </Grid>
          </Grid>

          <Grid classes={{ container: classes.dataLine }} container>
            <Grid item xs={2}>
              <Icon className='fa fa-grip-lines' classes={{ root: classes.icon }} />
            </Grid>
            <Grid item xs={10}>
              <span>{formattedLatitude}</span>
            </Grid>
          </Grid>

          <Grid classes={{ container: classes.dataLine }} container>
            <Grid item xs={2}>
              <Icon className='fa fa-grip-lines-vertical' classes={{ root: classes.icon }} />
            </Grid>
            <Grid item xs={10}>
              <span>{formattedLongitude}</span>
            </Grid>
          </Grid>

          {hideAltitudeInfo ? null : (
            <Grid classes={{ container: classes.dataLine }} container>
              <Grid item xs={2}>
                <Icon className='fas fa-mountain' classes={{ root: classes.icon }} />
              </Grid>
              <Grid item xs={10}>
                <span>{popupInfo.position.altitude} m</span>
              </Grid>
            </Grid>
          )}

          {hideSpeedInfo ? null : (
            <Grid classes={{ container: classes.dataLine }} container>
              <Grid item xs={2}>
                <Icon className='fas fa-tachometer-alt' classes={{ root: classes.icon }} />
              </Grid>
              <Grid item xs={10}>
                <span>{popupInfo.position.speed} km/h</span>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Popup>
  )
}

GpsInfoPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  onPopupClose: PropTypes.func.isRequired,
  popupInfo: PropTypes.object.isRequired
}

export default withStyles(styles)(GpsInfoPopup)
