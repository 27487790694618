import { defineMessages } from 'react-intl'

const messages = defineMessages({
  selectColor: {
    id: 'ColorPicker.selectColor',
    description: 'Select color',
    defaultMessage: 'Select color'
  },
  apply: {
  	id: 'ColorPicker.apply',
    description: 'apply',
    defaultMessage: 'Apply'
  }
})

export default messages