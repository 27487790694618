import { defineMessages } from 'react-intl'

const namespace = 'MapSettings'

const messages = defineMessages({
  mapWidget: {
    id: `${namespace}.mapWidget`,
    description: 'Map widget',
    defaultMessage: 'MAP WIDGET'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  youMustSelectAMachine: {
    id: `${namespace}.youMustSelectAMachine`,
    description: 'You must select a machine',
    defaultMessage: 'You must select a machine'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noLocationAvailable: {
    id: 'WeatherSettings.noLocationAvailable',
    description: 'No location available',
    defaultMessage: 'No location available'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  }
})

export default messages
