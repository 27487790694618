import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils as groupUtils } from 'ducks/groups'

import { getEditNotificationAssignUsersUrl } from '../url'
import AssignUsersToNotification from './AssignUsersToNotification'

const mapStateToProps = (state, { match, history }) => ({
  editNotificationAssignUsersUrl: getEditNotificationAssignUsersUrl(match.params.notificationId),
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId),
  notificationId: match.params.notificationId,
  onNotificationAssignUsersEdited: () => {
    history.push(getEditNotificationAssignUsersUrl(match.params.notificationId), 'usersAssigned')
  }
})

const mapDispatchToProps = dispatch => ({
  getGroupUsers: (groupId, limit, offset) => dispatch(actions.getDetailedUsersOfGroup(groupId, limit, offset)),
  getUsersDetail: (groupId, usersEmails) => dispatch(actions.getUsersOfGroupDetails(groupId, usersEmails))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignUsersToNotification))
