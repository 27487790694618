import React from 'react'
import PropTypes from 'prop-types'
import _cloneDeep from 'lodash/cloneDeep'
import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import _isEqual from 'lodash/isEqual'

class BasicChart extends React.Component {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
  }

  componentDidMount() {
    const { isChartLoading } = this.props

    if (isChartLoading) {
      this.chartRef.current.chart.showLoading()
    }
    if (!isChartLoading) {
      this.chartRef.current.chart.hideLoading()
    }
  }

  shouldComponentUpdate(nextProps) {
    const { config, isChartLoading } = this.props
    if (!_isEqual(nextProps.config, config) || !_isEqual(nextProps.isChartLoading, isChartLoading)) {
      return true
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps) {
    const { config, isChartLoading } = this.props
    if (!_isEqual(prevProps.config, config)) {
      const { series } = config

      let [min] = series[0]?.data?.at(0) || []
      let [max] = series[0]?.data?.at(-1) || []
      config.series.slice(1).forEach(serie => {
        const [serieMin] = serie?.data?.at(0) || []
        const [serieMax] = serie?.data?.at(-1) || []
        min = Math.min(min || Infinity, serieMin || Infinity)
        max = Math.max(max || -Infinity, serieMax || -Infinity)
      })
      this.chartRef.current.chart.xAxis[0].setExtremes(min, max)
    }
    if (isChartLoading) {
      this.chartRef.current.chart.showLoading()
    }
    if (!isChartLoading) {
      this.chartRef.current.chart.hideLoading()
    }
  }

  render() {
    const { config } = this.props

    return (
      <HighchartsReact
        ref={this.chartRef}
        constructorType={config.chart.highstock ? 'stockChart' : 'chart'}
        containerProps={{
          className: 'col-md-12',
          style: { height: '100%', offsetWidth: '100%', width: '100%' }
        }}
        highcharts={config.chart.highstock ? Highstock : Highcharts}
        options={_cloneDeep(config)}
      />
    )
  }
}

BasicChart.propTypes = {
  config: PropTypes.object.isRequired,
  isChartLoading: PropTypes.bool.isRequired
}

export { BasicChart }
