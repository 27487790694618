import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class LineSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const { type, width, style, color } = this.props.data

    this.state = {
      type,
      width,
      style,
      color
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    const error = false

    if (!error) {
      const data = {
        type: this.state.type,
        width: this.state.width,
        style: this.state.style,
        color: this.state.color
      }

      this.props.saveSettings(data)
    }
  }

  render() {
    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        fullWidth={true}
        keepMounted={true}
        maxWidth="xl"
        onClose={this.closeSettings}
        open={true}
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.lineWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.lineWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="type-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.type)}
                </InputLabel>
                <Select
                  autoWidth={true}
                  displayEmpty={true}
                  input={<Input id="type-label-placeholder" name="radius" />}
                  name="type"
                  onChange={this.handleChange('type')}
                  value={this.state.type}
                >
                  <MenuItem value="horizontal">{this.formatMessage(messages.horizontal)}</MenuItem>
                  <MenuItem value="vertical">{this.formatMessage(messages.vertical)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectType)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="width-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.width)}
                </InputLabel>
                <Select
                  autoWidth={true}
                  displayEmpty={true}
                  input={<Input id="width-label-placeholder" name="width" />}
                  name="width"
                  onChange={this.handleChange('width')}
                  value={this.state.width}
                >
                  <MenuItem value="thin">{this.formatMessage(messages.thin)}</MenuItem>
                  <MenuItem value="medium">{this.formatMessage(messages.medium)}</MenuItem>
                  <MenuItem value="thick">{this.formatMessage(messages.thick)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectBorderWidth)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="style-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.style)}
                </InputLabel>
                <Select
                  autoWidth={true}
                  displayEmpty={true}
                  input={<Input id="style-label-placeholder" name="style" />}
                  name="style"
                  onChange={this.handleChange('style')}
                  value={this.state.style}
                >
                  <MenuItem value="solid">{this.formatMessage(messages.solid)}</MenuItem>
                  <MenuItem value="dotted">{this.formatMessage(messages.dotted)}</MenuItem>
                  <MenuItem value="dashed">{this.formatMessage(messages.dashed)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectBorderStyle)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="color-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.color)}
                </InputLabel>
                <Select
                  autoWidth={true}
                  displayEmpty={true}
                  input={<Input id="style-label-placeholder" name="color" />}
                  name="color"
                  onChange={this.handleChange('color')}
                  value={this.state.color}
                >
                  <MenuItem value="default">{this.formatMessage(messages.default)}</MenuItem>
                  <MenuItem value="lightGrey">{this.formatMessage(messages.lightGrey)}</MenuItem>
                  <MenuItem value="grey">{this.formatMessage(messages.grey)}</MenuItem>
                  <MenuItem value="darkGrey">{this.formatMessage(messages.darkGrey)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectBorderColor)}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

LineSettings.propTypes = {
  closeSettings: PropTypes.func,
  data: PropTypes.shape({
    type: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string
  }),
  saveSettings: PropTypes.func
}

LineSettings.defaultProps = {
  closeSettings: i => i,
  data: {
    type: 'horizontal',
    width: 'thin',
    style: 'solid',
    color: 'default'
  },
  saveSettings: i => i
}

export default injectIntl(LineSettings)
