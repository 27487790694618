import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import * as ReportConstants from '../../constants'
import Metric from './Metric'
import * as fromConfig from './config'
import messages from './messages'

const styles = {
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
}

class Metrics extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const { metrics, onMetricsUpdate } = this.props
    onMetricsUpdate(metrics)
  }

  handleAssetUpdate = (metricId, asset) => {
    const { metrics, onMetricsUpdate } = this.props
    if (asset && metrics[metricId].asset !== asset) {
      metrics[metricId] = fromConfig.createMetric({
        pageType: metrics[metricId].pageType,
        tableType: metrics[metricId]?.tableType
      })
      metrics[metricId].asset = asset
      onMetricsUpdate(metrics)
    } else if (!asset && metrics[metricId].asset === ReportConstants.ALL_DEVICES_IN_GROUP) {
      metrics[metricId] = fromConfig.createMetric({
        pageType: metrics[metricId].pageType,
        tableType: metrics[metricId]?.tableType
      })
      metrics[metricId].asset = asset
      onMetricsUpdate(metrics)
    }
  }

  handleSignalUpdate = (metricIndex, sensor, sensorsOptions) => {
    const { metrics, onMetricsUpdate } = this.props

    if (sensor) {
      const toRemove = metrics[metricIndex].selectedSensorsIds.filter(x => !sensor.includes(x))

      const toAdd = sensor.filter(x => !metrics[metricIndex].selectedSensorsIds.includes(x))
      toAdd.forEach(sensorToAdd => {
        const newSensor = sensorsOptions.find(sOp => sOp.signalId === sensorToAdd)
        metrics[metricIndex].sensors.push(
          this.defaultSensorValues(
            newSensor.label,
            newSensor.unit,
            newSensor.signalId,
            newSensor.multiplier,
            newSensor.offset,
            newSensor.divider,
            newSensor.signed,
            newSensor.lengthOfBits
          )
        )
      })

      metrics[metricIndex].sensors = metrics[metricIndex].sensors.filter(x => !toRemove.includes(x.signalId))
      metrics[metricIndex].selectedSensorsIds = sensor
    }

    onMetricsUpdate(metrics)
  }

  handleDeleteMetric = metricIndex => {
    const { metrics, onMetricsUpdate } = this.props

    metrics.splice(metricIndex, 1)
    onMetricsUpdate(metrics)
  }

  handleDeleteSignal = (metricIdx, signalIdx) => {
    const { metrics, onMetricsUpdate } = this.props

    const deletedSignal = metrics[metricIdx].sensors.splice(signalIdx, 1)[0]
    const countSignal = metrics[metricIdx].sensors.filter(i => i.signalId === deletedSignal.signalId).length

    if (countSignal === 0) {
      metrics[metricIdx].selectedSensorsIds = metrics[metricIdx].selectedSensorsIds.filter(
        i => i !== deletedSignal.signalId
      )
    }

    onMetricsUpdate(metrics)
  }

  handleDuplicateSignal = (metricIdx, signalIdx) => {
    const { metrics, onMetricsUpdate } = this.props

    const duplicateSignal = {
      ...metrics[metricIdx].sensors[signalIdx],
      display: {
        ...metrics[metricIdx].sensors[signalIdx].display
      }
    }
    metrics[metricIdx].sensors.splice(signalIdx + 1, 0, duplicateSignal)

    onMetricsUpdate(metrics)
  }

  handleSignalColorChange = (metricIdx, signalIdx, color) => {
    const { metrics, onMetricsUpdate } = this.props

    metrics[metricIdx].sensors[signalIdx].lineColor = color
    onMetricsUpdate(metrics)
  }

  handleSignalLinestyleChange = (metricIdx, signalIdx, style) => {
    const { metrics, onMetricsUpdate } = this.props

    metrics[metricIdx].sensors[signalIdx].lineStyle = style
    onMetricsUpdate(metrics)
  }

  handleSignalAggregationChange = (metricIdx, signalIdx, aggr) => {
    const { metrics, onMetricsUpdate } = this.props

    metrics[metricIdx].sensors[signalIdx].aggregationType = aggr
    onMetricsUpdate(metrics)
  }

  // eslint-disable-next-line max-params
  defaultSensorValues = (value, unit, signalId, multiplier, offset, divider, signed, lengthOfBits) => {
    return {
      name: value,
      unit,
      signalId,
      multiplier,
      offset,
      divider,
      signed,
      lengthOfBits,
      aggregationType: 'average',
      lineStyle: 'line',
      lineColor:
        '#' +
        Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, '0'),
      display: {
        table: false,
        chart: true
      }
    }
  }

  render() {
    const { assets, metrics, pageType, tableType, classes, isAllDevicesChecked, onAllDevicesCheckboxChange } =
      this.props

    const isTableTypePage = pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE
    const isTableTypeAndDM1LastKnownValues =
      isTableTypePage && tableType === ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE

    if (assets.length === 0 || metrics.length === 0) {
      return <Typography>{this.formatMessage(messages.noMachinesOrMetrics)}</Typography>
    } else {
      return (
        <React.Fragment>
          {isTableTypeAndDM1LastKnownValues && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllDevicesChecked}
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                  onChange={onAllDevicesCheckboxChange}
                />
              }
              label={this.formatMessage(messages.allMachines)}
            />
          )}
          {isAllDevicesChecked ? (
            <TextField
              disabled
              fullWidth
              helperText={this.formatMessage(messages.machinesHelperText)}
              label={this.formatMessage(messages.machines)}
              value={this.formatMessage(messages.allMachinesSelected)}
            />
          ) : 
            assets.length !== 0 &&
            metrics.map((metric, metricIndex) => {
              const isOnlyOne = metrics.length === 1
              const selectedDevices = metrics.map(m => m.asset)

              return (
                <Metric
                  key={metricIndex}
                  assets={assets}
                  isAllDevicesChecked={isAllDevicesChecked}
                  isOnlyOne={isOnlyOne}
                  metric={metric}
                  onAssetUpdate={(...args) => this.handleAssetUpdate(metricIndex, ...args)}
                  onDeleteMetric={() => this.handleDeleteMetric(metricIndex)}
                  onDeleteSignal={(...args) => this.handleDeleteSignal(metricIndex, ...args)}
                  onDuplicateSignal={(...args) => this.handleDuplicateSignal(metricIndex, ...args)}
                  onSignalAggregationChanged={(...args) => this.handleSignalAggregationChange(metricIndex, ...args)}
                  onSignalColorChanged={(...args) => this.handleSignalColorChange(metricIndex, ...args)}
                  onSignalLinestyleChanged={(...args) => this.handleSignalLinestyleChange(metricIndex, ...args)}
                  onSignalUpdate={(...args) => this.handleSignalUpdate(metricIndex, ...args)}
                  pageType={pageType}
                  selectedDevices={selectedDevices}
                  tableType={tableType}
                />
              )
            })
          }
        </React.Fragment>
      )
    }
  }
}

Metrics.propTypes = {
  assets: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isAllDevicesChecked: PropTypes.bool.isRequired,
  metrics: PropTypes.array.isRequired,
  onAllDevicesCheckboxChange: PropTypes.func.isRequired,
  onMetricsUpdate: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  tableType: PropTypes.string.isRequired
}

export default injectIntl(withStyles(styles)(Metrics))
