import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Reports, NewReport, EditReport } from './components'

import { BASE_URL, NEW_REPORT, EDIT_REPORT } from './url'

const ReportsHome = ({
  canCreateReports = false,
  canEditReports = false,
  canReadReports = false

}) => (
  <Switch>
    {canCreateReports && <Route path={NEW_REPORT} component={NewReport} />}
    {canEditReports && <Route path={EDIT_REPORT} component={EditReport} />}
    {canReadReports && <Route path={BASE_URL} component={Reports} />}
  </Switch>
)

export default ReportsHome
