import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles'

import PageTitle from 'components/PageTitle'
import client from 'utils/http/client'

import { getAdaptedOneMaintenanceData, getAdaptedInspectionsData } from '../apiAdapters'
import { getMappedFrequencyType, getMappedSeverity } from '../apiMappings'
import {
  FREQUENCY_TYPES,
  INSPECTION_STATES,
  MAINTENANCE_CONFIGURATION_MODES,
  MAINTENANCE_CONFIGURATION_STEPS,
  MAINTENANCE_CONFIGURATION_SUB_STEPS,
  SEVERITY_LEVELS,
  TIME_UNITS
} from '../constants'
import { getFormattedFrequencyUnit } from '../helpers'
import messages from '../messages'
import { getMaintenancesUrl } from '../urls'
import GeneralSettingsStep from './GeneralSettingsStep'
import DevicesSettingsStep from './MachinesSettingsStep'
import NotificationSettingsStep from './NotificationSettingsStep'
import SignalSettingsStep from './SignalSettingsStep'
import SummaryStep from './SummaryStep'

const styles = {
  stepperRoot: {
    backgroundColor: 'transparent',
    width: '750px'
  },
  stepConnectorLine: {
    borderColor: '#5d5d5d'
  },
  stepLabel: {
    color: '#5d5d5d',
    fontSize: '18px'
  },
  stepIcon: {
    height: '28px',
    width: '28px'
  },
  stepIconText: {
    fontSize: '14px'
  }
}

class MaintenanceConfiguration extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS,
      currentSubStep: null,
      isMaintenanceLoading: true,
      isSavingLoading: false,
      isMaintenanceSaved: false,

      isApiFetchError: false,
      isApiSendError: false,

      name: '',
      description: '',
      isEnabled: true,

      isAssignedDevicesEidsLoading: false,
      assignedDevicesEids: [],
      availableAdvancedSignals: [],
      advancedSignalHashId: '',
      advancedSignalConfigHashId: '',
      advancedSignalName: '',
      advancedSignalUnit: '',

      frequencyType: FREQUENCY_TYPES.TIME_BASED,
      initialDate: moment().utc().hours(0).minutes(0).seconds(0).valueOf(),
      initialValue: 0,
      frequencyValue: 1,
      frequencyUnit: TIME_UNITS.MONTHS,
      isInspectionReschedulingEnabled: false,
      originalFrequencyValue: 1,
      originalFrequencyUnit: TIME_UNITS.MONTHS,

      severity: SEVERITY_LEVELS.LOW,
      assignedUsers: []
    }
  }

  componentDidMount() {
    const { location, mode } = this.props

    if (Object.values(MAINTENANCE_CONFIGURATION_STEPS).includes(location?.state?.step)) {
      this.setState({ currentStep: location?.state?.step })
    }

    if (mode === MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION) {
      this.setState({ isMaintenanceLoading: false })
    }

    if (mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION) {
      this.fetchMaintenanceData().then(this.fetchAssignedDevicesEids)
    }
  }

  fetchMaintenanceData = () => {
    const {
      match: {
        params: { maintenanceId }
      },
      selectedGroup: { id: groupId }
    } = this.props

    this.setState({ isMaintenanceLoading: true, isApiFetchError: false })
    return client
      .getOneMaintenance(groupId, maintenanceId)
      .then(response => {
        const maintenanceData = getAdaptedOneMaintenanceData(response.data)

        const {
          advancedSignalConfigHashId,
          advancedSignalHashId,
          advancedSignalName,
          advancedSignalUnit,
          assignedUsers,
          description,
          frequencyType,
          frequencyUnit,
          frequencyValue,
          initialDate,
          initialValue,
          isEnabled,
          name,
          severity
        } = maintenanceData
        this.setState({
          advancedSignalConfigHashId,
          advancedSignalHashId,
          advancedSignalName,
          advancedSignalUnit,
          assignedUsers,
          description,
          frequencyType,
          frequencyUnit,
          frequencyValue,
          initialDate,
          initialValue,
          isEnabled,
          name,
          originalFrequencyUnit: frequencyUnit,
          originalFrequencyValue: frequencyValue,
          severity
        })
      })
      .catch(() => {
        this.setState({ isApiFetchError: true })
        return true
      })
      .finally((isApiFetchError = false) => {
        this.setState({ isMaintenanceLoading: false })
        return isApiFetchError
      })
  }

  fetchAssignedDevicesEids = (isApiFetchError = false) => {
    const {
      selectedGroup: { id: groupId },
      match: {
        params: { maintenanceId }
      }
    } = this.props

    if (isApiFetchError) return

    this.setState({ isAssignedDevicesEidsLoading: true, isApiFetchError: false })
    client
      .getOneMaintenanceInspectionsLimited({ groupId, maintenanceId })
      .then(response => {
        const latestInspectionsData = getAdaptedInspectionsData(response.data)
        const assignedDevicesEids = latestInspectionsData
          .filter(item => item.state === INSPECTION_STATES.ACTIVE || item.state === INSPECTION_STATES.PENDING)
          .map(item => item.deviceEid)
          .filter((deviceEid, index, self) => self.findIndex(item => item === deviceEid) === index)

        this.setState({ assignedDevicesEids })
      })
      .catch(() => {
        this.setState({ isApiFetchError: true })
      })
      .finally(() => {
        this.setState({ isAssignedDevicesEidsLoading: false })
      })
  }

  getStepIndex = step => {
    return Object.values(MAINTENANCE_CONFIGURATION_STEPS).findIndex(item => item === step)
  }

  getIsStepComplete = step => {
    const { isMaintenanceLoading, isAssignedDevicesEidsLoading } = this.state

    let isStepComplete = false
    switch (step) {
      case MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS:
        isStepComplete = this.getIsGeneralSettingsStepComplete()
        break
      case MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS:
        isStepComplete = this.getIsDevicesSettingsStepComplete()
        break
      case MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS:
        isStepComplete = this.getIsSignalSettingsStepComplete()
        break
      case MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS:
        isStepComplete = this.getIsNotificationsSettingsStepComplete()
        break
    }

    return !isMaintenanceLoading && !isAssignedDevicesEidsLoading && isStepComplete
  }

  getIsGeneralSettingsStepComplete = () => {
    const { name, description } = this.state

    return Boolean(name && description)
  }

  getIsDevicesSettingsStepComplete = () => {
    const { assignedDevicesEids } = this.state
    return assignedDevicesEids.length > 0
  }

  getIsSignalSettingsStepComplete = () => {
    const { advancedSignalHashId, frequencyType, initialDate, initialValue, frequencyValue, frequencyUnit } = this.state

    const isInitialDateOrValueValid =
      frequencyType === FREQUENCY_TYPES.TIME_BASED && Boolean(initialDate) ||
      frequencyType === FREQUENCY_TYPES.VALUE_BASED && (Boolean(initialValue) || initialValue === 0)
    const isFrequencyUnitValid =
      frequencyType === FREQUENCY_TYPES.TIME_BASED && Boolean(frequencyUnit) ||
      frequencyType === FREQUENCY_TYPES.VALUE_BASED

    return (
      Boolean(advancedSignalHashId) &&
      Boolean(frequencyType) &&
      isInitialDateOrValueValid &&
      Boolean(frequencyValue) &&
      isFrequencyUnitValid
    )
  }

  getIsNotificationsSettingsStepComplete = () => {
    const { severity, assignedUsers } = this.state

    return assignedUsers.length === 0 || assignedUsers.length > 0 && Boolean(severity)
  }

  getFrequencyClarificationSentence = () => {
    const { intl } = this.props
    const { frequencyType, frequencyUnit, frequencyValue, initialDate, initialValue } = this.state

    let frequencyClarificationSentence = ''

    const formattedFrequencyUnit = getFormattedFrequencyUnit({ intl, frequencyType, frequencyValue, frequencyUnit })

    if (frequencyType === FREQUENCY_TYPES.TIME_BASED) {
      let formattedInitialDate

      switch (frequencyUnit) {
        case TIME_UNITS.DAYS:
          formattedInitialDate = ''
          break
        case TIME_UNITS.WEEKS:
          formattedInitialDate = intl.formatMessage(messages.formattedInitialDate, {
            initialDate: moment(initialDate).format('dddd'),
            hasWeekdayPrefix: false
          })
          break
        case TIME_UNITS.MONTHS:
          formattedInitialDate = intl.formatMessage(messages.formattedInitialDate, {
            initialDate: moment(initialDate).format('Do'),
            hasWeekdayPrefix: true
          })
          break
        case TIME_UNITS.YEARS:
          formattedInitialDate = intl.formatMessage(messages.formattedInitialDate, {
            initialDate: moment(initialDate).format('MMMM Do'),
            hasWeekdayPrefix: false
          })
          break
      }

      frequencyClarificationSentence = intl.formatMessage(messages.frequencyClarificationTimeBased, {
        frequencyValue,
        formattedTimeUnit: formattedFrequencyUnit,
        formattedInitialDate,
        hasDatePrecision: frequencyUnit !== TIME_UNITS.DAYS,
        b: text => <b>{text}</b>
      })
    } else {
      frequencyClarificationSentence = intl.formatMessage(messages.frequencyClarificationValueBased, {
        frequencyValue,
        formattedUnit: formattedFrequencyUnit,
        initialValue,
        b: text => <b>{text}</b>
      })
    }

    return frequencyClarificationSentence
  }

  handleStepButtonClick = step => {
    const { isApiFetchError, isMaintenanceSaved, isSavingLoading } = this.state
    if (!isMaintenanceSaved && !isSavingLoading && !isApiFetchError) {
      this.setState({ currentStep: step, currentSubStep: null })
    }
  }

  handleSubStepEnterClick = subStep => {
    this.setState({ currentSubStep: subStep })
  }

  handleSubStepExitClick = () => {
    this.setState({ currentSubStep: null })
  }

  handleBackClick = () => {
    const { currentStep } = this.state
    const currentStepIndex = this.getStepIndex(currentStep)
    const previousStep = Object.values(MAINTENANCE_CONFIGURATION_STEPS)[currentStepIndex - 1]
    this.setState({ currentStep: previousStep })
  }

  handleNextClick = () => {
    const { currentStep } = this.state
    const currentStepIndex = this.getStepIndex(currentStep)
    const nextStep = Object.values(MAINTENANCE_CONFIGURATION_STEPS)[currentStepIndex + 1]
    this.setState({ currentStep: nextStep })
  }

  handleGoToSummaryAndSaveClick = () => {
    this.setState({ currentStep: MAINTENANCE_CONFIGURATION_STEPS.SUMMARY }, this.handleSaveClick)
  }

  handleSaveClick = () => {
    const {
      match: {
        params: { maintenanceId }
      },
      mode,
      selectedGroup: { id: groupId }
    } = this.props
    const {
      advancedSignalConfigHashId,
      advancedSignalHashId,
      assignedDevicesEids,
      assignedUsers,
      description,
      frequencyType,
      frequencyUnit,
      frequencyValue,
      initialDate,
      initialValue,
      isEnabled,
      isInspectionReschedulingEnabled,
      name,
      severity
    } = this.state

    const commonPayload = {
      groupId,
      name,
      description,
      notificationEnabled: isEnabled,
      notes: '',
      measurementDevices: assignedDevicesEids.map(eid => ({ deviceEid: eid })),
      measurementConfig: {
        hashId: advancedSignalHashId,
        configHashId: advancedSignalConfigHashId
      },
      serviceThresholdType: getMappedFrequencyType(frequencyType, true),
      ...frequencyType === FREQUENCY_TYPES.TIME_BASED
        ? { serviceThresholdDate: moment(initialDate).valueOf() }
        : { serviceThreshold: Number(initialValue) },
      recurringThresholdIncrement: Number(frequencyValue),
      recurringThresholdIncrementUnit: frequencyUnit,
      severity: getMappedSeverity(severity, true),
      notificationUsersAlarms: assignedUsers.map(user => ({
        notificationUser: { userId: user.id, email: user.email },
        notificationUserActions: user.notifications.map(notification => ({ hashId: notification.hashId }))
      }))
    }

    this.setState({ isSavingLoading: true, isApiSendError: false })
    let maintenanceSavePromise = null
    if (mode === MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION) {
      const payload = {
        ...commonPayload
      }
      maintenanceSavePromise = client.newMaintenance(groupId, payload)
    } else {
      const payload = {
        ...commonPayload,
        hashId: maintenanceId
      }
      maintenanceSavePromise = client.updateOneMaintenance({
        groupId,
        maintenanceId,
        maintenanceData: payload,
        rescheduleInspections: isInspectionReschedulingEnabled
      })
    }

    maintenanceSavePromise
      .then(() => {
        this.setState({ isMaintenanceSaved: true })
      })
      .catch(() => {
        this.setState({ isApiSendError: true })
      })
      .finally(() => {
        this.setState({ isSavingLoading: false })
      })
  }

  handleExitClick = () => {
    const { history } = this.props
    history.push(getMaintenancesUrl())
  }

  handleFieldChange = (fieldName, value) => {
    this.setState({ [fieldName]: value })
  }

  render() {
    const {
      classes,
      intl,
      mode,
      selectedGroup: { name: groupName }
    } = this.props
    const {
      advancedSignalConfigHashId,
      advancedSignalHashId,
      advancedSignalName,
      advancedSignalUnit,
      assignedDevicesEids,
      assignedUsers,
      availableAdvancedSignals,
      currentStep,
      currentSubStep,
      description,
      frequencyType,
      frequencyUnit,
      frequencyValue,
      initialDate,
      initialValue,
      isApiFetchError,
      isApiSendError,
      isAssignedDevicesEidsLoading,
      isAutoSave,
      isEnabled,
      isInspectionReschedulingEnabled,
      isMaintenanceLoading,
      isMaintenanceSaved,
      isSavingLoading,
      name,
      originalFrequencyUnit,
      originalFrequencyValue,
      severity
    } = this.state

    const isLoading = isMaintenanceLoading || isAssignedDevicesEidsLoading

    const currentStepIndex = this.getStepIndex(currentStep)
    const isFirstStep = currentStepIndex === 0
    const isLastStep = currentStepIndex === Object.values(MAINTENANCE_CONFIGURATION_STEPS).length - 1
    const isSubStep = currentSubStep !== null
    const isCurrentStepComplete = this.getIsStepComplete(currentStep)
    const isAllStepsComplete = Object.values(MAINTENANCE_CONFIGURATION_STEPS)
      .filter(step => step !== MAINTENANCE_CONFIGURATION_STEPS.SUMMARY)
      .every(step => this.getIsStepComplete(step))

    const isBackButtonShown = !isFirstStep && !isMaintenanceSaved
    const isNextButtonShown = !isLastStep && !isMaintenanceSaved
    const isSaveButtonShown =
      isLastStep && !isMaintenanceSaved ||
      mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION && !isMaintenanceSaved
    const isExitButtonShown = isLastStep && isMaintenanceSaved

    const isBackButtonDisabled = isSubStep || isSavingLoading || isApiFetchError
    const isNextButtonDisabled = isSubStep || !isCurrentStepComplete || isApiFetchError
    const isSaveButtonDisabled = !isAllStepsComplete || isSubStep || isSavingLoading || isApiFetchError
    const isGoToSummaryAndSaveEnabled =
      mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION && !isLastStep

    let pageTitle = ''
    let isStepperNonLinear = false

    if (mode === MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION) {
      pageTitle = intl.formatMessage(messages.newMaintenance)
      isStepperNonLinear = false
    } else if (mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION) {
      pageTitle = intl.formatMessage(messages.editMaintenance)
      isStepperNonLinear = true
    }

    const frequencyClarificationSentence = this.getFrequencyClarificationSentence()

    return (
      <div style={{ margin: '20px 25px 20px 20px' }}>
        <div className='container-fluid'>
          <PageTitle back={getMaintenancesUrl()} title={pageTitle} />
        </div>
        <div className='row' style={{ margin: '20px 0 0 0' }}>
          <div className='container-fluid'>
            <Grid container spacing={3}>
              <Grid container item justifyContent='center' xs={12}>
                <Stepper
                  activeStep={currentStepIndex}
                  classes={{ root: classes.stepperRoot }}
                  connector={<StepConnector classes={{ line: classes.stepConnectorLine }} />}
                  nonLinear={isStepperNonLinear}
                >
                  <Step
                    active={currentStep === MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS}
                    completed={this.getIsStepComplete(MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS)}
                  >
                    <StepButton
                      onClick={() => this.handleStepButtonClick(MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS)}
                    >
                      <StepLabel
                        StepIconProps={{ classes: { root: classes.stepIcon, text: classes.stepIconText } }}
                        classes={{ label: classes.stepLabel }}
                      >
                        {intl.formatMessage(messages.general)}
                      </StepLabel>
                    </StepButton>
                  </Step>
                  <Step
                    active={currentStep === MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS}
                    completed={this.getIsStepComplete(MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS)}
                  >
                    <StepButton
                      onClick={() => this.handleStepButtonClick(MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS)}
                    >
                      <StepLabel
                        StepIconProps={{ classes: { root: classes.stepIcon, text: classes.stepIconText } }}
                        classes={{ label: classes.stepLabel }}
                      >
                        {intl.formatMessage(messages.machines)}
                      </StepLabel>
                    </StepButton>
                  </Step>
                  <Step
                    active={currentStep === MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS}
                    completed={this.getIsStepComplete(MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS)}
                  >
                    <StepButton
                      onClick={() => this.handleStepButtonClick(MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS)}
                    >
                      <StepLabel
                        StepIconProps={{ classes: { root: classes.stepIcon, text: classes.stepIconText } }}
                        classes={{ label: classes.stepLabel }}
                      >
                        {intl.formatMessage(messages.signal)}
                      </StepLabel>
                    </StepButton>
                  </Step>
                  <Step
                    active={currentStep === MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS}
                    completed={this.getIsStepComplete(MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS)}
                  >
                    <StepButton
                      onClick={() => this.handleStepButtonClick(MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS)}
                    >
                      <StepLabel
                        StepIconProps={{ classes: { root: classes.stepIcon, text: classes.stepIconText } }}
                        classes={{ label: classes.stepLabel }}
                      >
                        {intl.formatMessage(messages.notifications)}
                      </StepLabel>
                    </StepButton>
                  </Step>
                </Stepper>
              </Grid>
              {isLoading ? (
                <Grid container item justifyContent='center' xs={12}>
                  <CircularProgress />
                </Grid>
              ) : (
                <React.Fragment>
                  {currentStep === MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS && (
                    <GeneralSettingsStep
                      description={description}
                      groupName={groupName}
                      isApiFetchError={isApiFetchError}
                      isEnabled={isEnabled}
                      name={name}
                      onDescriptionChange={value => this.handleFieldChange('description', value)}
                      onIsEnabledChange={value => this.handleFieldChange('isEnabled', value)}
                      onNameChange={value => this.handleFieldChange('name', value)}
                    />
                  )}
                  {currentStep === MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS && (
                    <DevicesSettingsStep
                      advancedSignalConfigHashId={advancedSignalConfigHashId}
                      advancedSignalHashId={advancedSignalHashId}
                      advancedSignalName={advancedSignalName}
                      assignedDevicesEids={assignedDevicesEids}
                      availableAdvancedSignals={availableAdvancedSignals}
                      isApiFetchError={isApiFetchError}
                      isSubStep={currentSubStep === MAINTENANCE_CONFIGURATION_SUB_STEPS.MACHINES_SETTINGS_EDIT}
                      mode={mode}
                      onAdvancedSignalChange={value => {
                        this.handleFieldChange('advancedSignalConfigHashId', value.configHashId || '')
                        this.handleFieldChange('advancedSignalHashId', value.hashId || '')
                        this.handleFieldChange('advancedSignalName', value.name || '')
                        this.handleFieldChange('advancedSignalUnit', value.unit || '')
                      }}
                      onAssignedDevicesEidsChange={value => this.handleFieldChange('assignedDevicesEids', value)}
                      onAvailableAdvancedSignalsChange={value =>
                        this.handleFieldChange('availableAdvancedSignals', value)
                      }
                      onSubStepEnterClick={() =>
                        this.handleSubStepEnterClick(MAINTENANCE_CONFIGURATION_SUB_STEPS.MACHINES_SETTINGS_EDIT)
                      }
                      onSubStepExitClick={this.handleSubStepExitClick}
                    />
                  )}
                  {currentStep === MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS && (
                    <SignalSettingsStep
                      advancedSignalConfigHashId={advancedSignalConfigHashId}
                      advancedSignalHashId={advancedSignalHashId}
                      advancedSignalUnit={advancedSignalUnit}
                      assignedDevicesEids={assignedDevicesEids}
                      availableAdvancedSignals={availableAdvancedSignals}
                      frequencyClarificationSentence={frequencyClarificationSentence}
                      frequencyType={frequencyType}
                      frequencyUnit={frequencyUnit}
                      frequencyValue={frequencyValue}
                      initialDate={initialDate}
                      initialValue={initialValue}
                      isApiFetchError={isApiFetchError}
                      isInspectionReschedulingEnabled={isInspectionReschedulingEnabled}
                      mode={mode}
                      onAdvancedSignalChange={value => {
                        this.handleFieldChange('advancedSignalConfigHashId', value.configHashId)
                        this.handleFieldChange('advancedSignalHashId', value.hashId)
                        this.handleFieldChange('advancedSignalName', value.name)
                        this.handleFieldChange('advancedSignalUnit', value.unit)
                      }}
                      onFrequencyTypeChange={value => this.handleFieldChange('frequencyType', value)}
                      onFrequencyUnitChange={value => this.handleFieldChange('frequencyUnit', value)}
                      onFrequencyValueChange={value => this.handleFieldChange('frequencyValue', value)}
                      onInitialDateChange={value => this.handleFieldChange('initialDate', value)}
                      onInitialValueChange={value => this.handleFieldChange('initialValue', value)}
                      onIsInspectionReschedulingEnabledChange={value =>
                        this.handleFieldChange('isInspectionReschedulingEnabled', value)
                      }
                      originalFrequencyUnit={originalFrequencyUnit}
                      originalFrequencyValue={originalFrequencyValue}
                    />
                  )}
                  {currentStep === MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS && (
                    <NotificationSettingsStep
                      assignedUsers={assignedUsers}
                      isApiFetchError={isApiFetchError}
                      isSubStep={currentSubStep === MAINTENANCE_CONFIGURATION_SUB_STEPS.NOTIFICATIONS_SETTINGS_EDIT}
                      onAssignedUsersChange={value => this.handleFieldChange('assignedUsers', value)}
                      onSeverityChange={value => this.handleFieldChange('severity', value)}
                      onSubStepEnterClick={() =>
                        this.handleSubStepEnterClick(MAINTENANCE_CONFIGURATION_SUB_STEPS.NOTIFICATIONS_SETTINGS_EDIT)
                      }
                      onSubStepExitClick={this.handleSubStepExitClick}
                      severity={severity}
                    />
                  )}
                  {currentStep === MAINTENANCE_CONFIGURATION_STEPS.SUMMARY && (
                    <SummaryStep
                      advancedSignalName={advancedSignalName}
                      assignedDevicesEids={assignedDevicesEids}
                      assignedUsers={assignedUsers}
                      description={description}
                      frequencyClarificationSentence={frequencyClarificationSentence}
                      isApiFetchError={isApiFetchError}
                      isApiSendError={isApiSendError}
                      isAutoSave={isAutoSave}
                      isInspectionReschedulingEnabled={isInspectionReschedulingEnabled}
                      isMaintenanceSaved={isMaintenanceSaved}
                      isSavingLoading={isSavingLoading}
                      name={name}
                      onStepChange={this.handleStepButtonClick}
                      severity={severity}
                      steps={MAINTENANCE_CONFIGURATION_STEPS}
                    />
                  )}

                  <Grid item xs={12}>
                    <Grid container justifyContent='flex-end' spacing={1}>
                      {isBackButtonShown && (
                        <Grid item>
                          <Button disabled={isBackButtonDisabled} onClick={this.handleBackClick}>
                            {intl.formatMessage(messages.back)}
                          </Button>
                        </Grid>
                      )}
                      {isNextButtonShown && (
                        <Grid item>
                          <Button
                            className={isSaveButtonShown ? 'secondary-action-button' : 'primary-action-button'}
                            disabled={isNextButtonDisabled}
                            onClick={this.handleNextClick}
                          >
                            {intl.formatMessage(messages.next)}
                          </Button>
                        </Grid>
                      )}
                      {isSaveButtonShown && (
                        <Grid item>
                          <Button
                            className='primary-action-button'
                            disabled={isSaveButtonDisabled}
                            onClick={
                              isGoToSummaryAndSaveEnabled ? this.handleGoToSummaryAndSaveClick : this.handleSaveClick
                            }
                          >
                            {isSavingLoading ? <CircularProgress size={20} /> : intl.formatMessage(messages.save)}
                          </Button>
                        </Grid>
                      )}
                      {isExitButtonShown && (
                        <Grid item>
                          <Button className='primary-action-button' disabled={isSubStep} onClick={this.handleExitClick}>
                            {intl.formatMessage(messages.exit)}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

MaintenanceConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.oneOf([
    MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION,
    MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
  ]).isRequired,
  selectedGroup: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(MaintenanceConfiguration))
