import { defineMessages } from 'react-intl'

const messages = defineMessages({
  historicWidget: {
    id: 'HistoricSettings.historicWidget',
    description: 'Historic chart widget',
    defaultMessage: 'HISTORIC WIDGET'
  },
  historicWidgetSettings: {
    id: 'HistoricSettings.historicWidgetSettings',
    description: 'Historic widget settings',
    defaultMessage: 'Historic widget settings.'
  },
  dinamicValue: {
    id: 'HistoricSettings.dinamicValue',
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: 'HistoricSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  defaultTimeRange: {
    id: 'HistoricSettings.defaultTimeRange',
    description: 'Default time range',
    defaultMessage: 'Default time range'
  },
  mustHaveAValue: {
    id: 'HistoricSettings.mustHaveAValue',
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  cancel: {
    id: 'HistoricSettings.cancel',
    description: 'Historic widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'HistoricSettings.save',
    description: 'Historic widget settings save button text',
    defaultMessage: 'Save'
  },
  notSupportedMachine: {
    id: 'HistoricSettings.notSupportedMachine',
    description: '',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: 'HistoricSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noDataAvailable: {
    id: 'HistoricSettings.noDataAvailable',
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  noSignal: {
    id: 'HistoricSettings.noSignal',
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  otherHistoricWidgetSettings: {
    id: 'HistoricSettings.otherHistoricWidgetSettings',
    description: 'Historic widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  numberOfDecimals: {
    id: 'HistoricSettings.numberOfDecimals',
    description: 'Number of decimals',
    defaultMessage: 'No. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: 'HistoricSettings.onlyZeroOrHigherIsAllowed',
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: 'HistoricSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  lastOneHour: {
    id: 'HistoricSettings.lastOneHour',
    description: 'Last one hour',
    defaultMessage: 'Last 1 hour'
  },
  lastThreeHours: {
    id: 'HistoricSettings.lastThreeHours',
    description: 'Last three hours',
    defaultMessage: 'Last 3 hours'
  },
  lastSixHours: {
    id: 'HistoricSettings.lastSixHours',
    description: 'Last six hours',
    defaultMessage: 'Last 6 hours'
  },
  lastTwelveHours: {
    id: 'HistoricSettings.lastTwelveHours',
    description: 'Last twelve hours',
    defaultMessage: 'Last 12 hours'
  },
  lastTwentyFourHours: {
    id: 'HistoricSettings.lastTwentyFourHours',
    description: 'Last twenty four hours',
    defaultMessage: 'Last 24 hours'
  },
  selectedDinamicValues: {
    id: 'HistoricSettings.selectedDinamicValues',
    description: 'Selected dinamic values:',
    defaultMessage: 'Selected dinamic values:'
  },
  loadingDataFromServer: {
    id: 'HistoricSettings.loadingDataFromServer',
    description: 'Loading data from server...',
    defaultMessage: 'Loading data from server...'
  },
  aggregation: {
    id: 'HistoricSettings.aggregation',
    description: 'Aggregation',
    defaultMessage: 'Aggregation'
  },
  none: {
    id: 'HistoricSettings.none',
    description: 'None aggregation type',
    defaultMessage: 'None'
  },
  avg: {
    id: 'HistoricSettings.avg',
    description: 'Average aggregation type',
    defaultMessage: 'Average'
  },
  min: {
    id: 'HistoricSettings.min',
    description: 'Minimum aggregation type',
    defaultMessage: 'Minimum'
  },
  max: {
    id: 'HistoricSettings.max',
    description: 'Maximum aggregation type',
    defaultMessage: 'Maximum'
  },
  refreshData: {
    id: 'HistoricSettings.refreshData',
    description: 'Refresh data',
    defaultMessage: 'Refresh data'
  },
  valueType: {
    id: 'HistoricSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  valuenone: {
    id: 'HistoricSettings.valuenone',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueavg: {
    id: 'HistoricSettings.valueavg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valuemin: {
    id: 'HistoricSettings.valuemin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valuemax: {
    id: 'HistoricSettings.valuemax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  gpsSignals: {
    id: 'HistoricSettings.gpsSignals',
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: 'HistoricSettings.canBusSignals',
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: 'HistoricSettings.mfioSignals',
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: 'HistoricSettings.signals',
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  basic: {
    id: 'HistoricSettings.basic',
    description: 'Basic tab label',
    defaultMessage: 'Basic signals chart'
  },
  advanced: {
    id: 'HistoricSettings.advanced',
    description: 'Advanced tab label',
    defaultMessage: 'Advanced signals chart'
  }
})

export default messages
