import { defineMessages } from 'react-intl'

const messages = defineMessages({
  roles: {
    id: 'Users.roles',
    description: 'Roles tab title',
    defaultMessage: 'Roles'
  },
  groups: {
    id: 'Users.groups',
    description: 'Groups tab title',
    defaultMessage: 'Groups'
  },
  userUpdatedSuccessfully: {
    id: 'Users.userUpdatedSuccessfully',
    description: 'User updated successfully text',
    defaultMessage: 'User updated successfully.'
  },
  userCreatedSuccessfully: {
    id: 'Users.userCreatedSuccessfully',
    description: 'User created successfully text',
    defaultMessage: 'User created successfully.'
  },
  error404Message: {
    id: 'Users.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  errorUndefinedTitle: {
    id: 'Users.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'Users.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  cannotDeleteOwner: {
    id: 'Users.cannotDeleteOwner',
    description: 'You cannot delete the owner of the group',
    defaultMessage: 'You cannot delete the owner of the group'
  },
  userDeletedSuccessfully: {
    id: 'Users.userDeletedSuccessfully',
    description: 'User deleted successfully text',
    defaultMessage: 'User deleted successfully.'
  },
  error400Message: {
    id: 'Users.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Incorrect user version. Try it later.'
  },
  error428Message: {
    id: 'Users.error428Message',
    description: 'Error 428 message',
    defaultMessage: '\'if-match\' header required.'
  },
  error502Message: {
    id: 'Users.error502Message',
    description: 'Error 502 message',
    defaultMessage: 'Bad Gateway.'
  },
  yes: {
    id: 'Users.yes',
    description: 'Yes text',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'Users.no',
    description: 'No text',
    defaultMessage: 'No'
  },
  actions: {
    id: 'Users.actions',
    description: 'Actions table header column',
    defaultMessage: 'Actions'
  },
  showingRows: {
    id: 'Users.showingRows',
    description: 'Users table total text showing rows',
    defaultMessage: 'Showing users'
  },
  to: {
    id: 'Users.to',
    description: 'Users table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'Users.of',
    description: 'Users table total text of',
    defaultMessage: 'of'
  },
  cancel: {
    id: 'Users.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'Users.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  noUsersMeetThisFilters: {
    id: 'Users.noUsersMeetThisFilters',
    description: 'No users meet this filters message',
    defaultMessage: 'No users meet this filters'
  },
  prePage: {
    id: 'Users.prePage',
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'Users.nextPage',
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'Users.firstPage',
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'Users.lastPage',
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  filterByOrderableColumns: {
    id: 'Users.filterByOrderableColumns',
    description: 'Filter by orderable columns text',
    defaultMessage: 'Filter by orderable columns'
  },
  firstName: {
    id: 'Users.firstName',
    description: 'First name table header column label',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'Users.lastName',
    description: 'Last name table header column label',
    defaultMessage: 'Last name'
  },
  enabled: {
    id: 'Users.enabled',
    description: 'Enabled table header column label',
    defaultMessage: 'Enabled'
  },
  role: {
    id: 'Users.role',
    description: 'Role table header column label',
    defaultMessage: 'Role'
  },
  group: {
    id: 'Users.group',
    description: 'Group table header column label',
    defaultMessage: 'Group'
  },
  remarks: {
    id: 'Users.remarks',
    description: 'Remarks table header column label',
    defaultMessage: 'Remarks'
  },
  confirmUserToDelete: {
    id: 'Users.confirmUserToDelete',
    description: 'Confirm user to delete message',
    defaultMessage: 'Confirm user to delete'
  },
  title: {
    id: 'Users.title',
    description: 'Users title',
    defaultMessage: 'Users management'
  },
  addUser: {
    id: 'Users.addUser',
    description: 'Add user button label',
    defaultMessage: 'Add user'
  },
  newUser: {
    id: 'Users.newUser',
    description: 'New user button label',
    defaultMessage: 'New user'
  },
  error: {
    id: 'Users.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  textPlaceholder: {
    id: 'Users.textPlaceholder',
    description: 'Text placeholder',
    defaultMessage: 'Enter'
  },
  selectPlaceholder: {
    id: 'Users.selectPlaceholder',
    description: 'Select placeholder',
    defaultMessage: 'Select'
  },
  editUser: {
    id: 'Users.editUser',
    description: 'Edit user',
    defaultMessage: 'Edit user'
  },
  deleteUser: {
    id: 'Users.deleteUser',
    description: 'Delete user',
    defaultMessage: 'Delete user'
  },
  email: {
    id: 'Users.email',
    description: 'Email',
    defaultMessage: 'Email'
  },
  smsService: {
    id: 'AssignUsersToNotification.smsService',
    defaultMessage: 'SMS Service'
  }
})

export default messages
