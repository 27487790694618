import { actions } from 'utils/http'

const fetchSchedules = (groupId, limit, offset) => dispatch => {
  const { promise } = dispatch(actions.getSchedules(groupId, limit, offset))
  return promise
}

const getSchedule = (scheduleId, groupId) => dispatch => {
  const { promise } = dispatch(actions.getSchedule(scheduleId, groupId))
  return promise
}

const updateSchedule = (scheduleId, schedule, groupId) => dispatch => {
  const { promise } = dispatch(actions.updateSchedule(scheduleId, schedule, groupId))
  return promise
}

const createSchedule = (schedule, groupId) => dispatch => {
  const { promise } = dispatch(actions.createSchedule(schedule, groupId))
  return promise
}

const deleteSchedule = (scheduleId, groupId) => dispatch => {
  const { promise } = dispatch(actions.deleteSchedule(scheduleId, groupId))
  return promise
}

const createScheduleAWS = (schedule, groupId) => dispatch => {
  const { promise } = dispatch(actions.createScheduleAWS(schedule, groupId))
  return promise
}

const deleteScheduleAWS = (scheduleId, groupId) => dispatch => {
  const { promise } = dispatch(actions.deleteScheduleAWS(scheduleId, groupId))
  return promise
}

const getAssets = (groupId, limit, offset) => dispatch => {
  const { promise } = dispatch(actions.plus1Connect.getAllDevices(groupId, limit, offset))
  return promise
}

export {
  createSchedule,
  fetchSchedules,
  getSchedule,
  updateSchedule,
  deleteSchedule,
  getAssets,
  createScheduleAWS,
  deleteScheduleAWS
}
