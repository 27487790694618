import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import csNode from 'modules/csNode'
import { utils as groupUtils } from 'ducks/groups'

import NewRegistration from './NewRegistration'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = state => ({
  canCreateDevices: resolvePermissions(state, c.DEVICE_CREATE),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activateDevice: csNode.actions.activateDevice,
      getDevice: csNode.actions.fetchCSNode
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NewRegistration)
