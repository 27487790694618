import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import ServerErrorAlert from '../../components/alerts/ServerErrorAlert'
import messages from '../../messages'
import { paperStyles } from '../../styles'

const styles = {
  ...paperStyles
}

class GeneralSettingsStep extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleNameChange = e => {
    const { onNameChange } = this.props
    const value = e.target.value

    onNameChange(value)
  }

  handleDescriptionChange = e => {
    const { onDescriptionChange } = this.props
    const value = e.target.value

    onDescriptionChange(value)
  }

  handleEnableChange = e => {
    const { onIsEnabledChange } = this.props
    const value = e.target.checked

    onIsEnabledChange(value)
  }

  render() {
    const { classes, description, groupName, intl, isApiFetchError, isEnabled, name } = this.props

    return (
      <Grid item xs={12}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Grid container spacing={3}>
            {isApiFetchError && (
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography component='h2' variant='h5'>
                {intl.formatMessage(messages.generalSettings)}
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={isApiFetchError}
                fullWidth
                label={intl.formatMessage(messages.maintenanceName)}
                onChange={this.handleNameChange}
                value={name}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={isApiFetchError}
                fullWidth
                label={intl.formatMessage(messages.description)}
                onChange={this.handleDescriptionChange}
                value={description}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={<Switch checked={isEnabled} disabled={isApiFetchError} onChange={this.handleEnableChange} />}
                label={isEnabled ? intl.formatMessage(messages.enabled) : intl.formatMessage(messages.disabled)}
              />
              <p className='small' style={{ margin: '0 0 5px 0' }}>
                {intl.formatMessage(messages.disabledMaintenance)}
              </p>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField disabled fullWidth label={intl.formatMessage(messages.group)} value={groupName} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

GeneralSettingsStep.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isApiFetchError: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onIsEnabledChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(GeneralSettingsStep))
