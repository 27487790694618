import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import { getUsersUrl } from '../url'
import { logError } from 'utils/http'

import { mapCSToRCTUser } from './utils'
import { mapRolesProperties, mapToHierarchicalRoles } from 'components/roles/utils'

import EditUserForm from './EditUserForm'
import EditUserFormTitle from './EditUserFormTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

class EditUser extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        remarks: ''
      },
      roleId: props.roleId,
      roles: [],
      rolesHierarchy: [],

      loadingUser: true,
      loadingRoles: true,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    const { userId, email, getUsersOfGroupDetails, group, getDetailedUsers, intl, getRoles } = this.props

    const limit = 10
    let offset = 0
    let getUser
    let params

    if (email) {
      getUser = getUsersOfGroupDetails
      params = [group.id, [email]]
    } else {
      getUser = getDetailedUsers
      params = [group.id, limit, offset]
    }

    getUser(...params)
      .then(async response => {
        let user = response.data

        if (email) {
          user = response.data.users[0]
        } else {
          const roleUsers = response.data.groupUsers.users
          let roleUser = roleUsers.find(usr => usr.userId === userId)
          while (!roleUser) {
            const roleUsersResponse = await getDetailedUsers(group.id, limit, offset)
            roleUser = roleUsersResponse.data.groupUsers.users.find(usr => usr.userId === userId)
            if (!roleUser) offset += limit
          }
          if (
            roleUser.userProperties &&
            Array.isArray(roleUser.userProperties.GroupRoles) &&
            roleUser.userProperties.GroupRoles.length > 0
          ) {
            this.setState({
              roleId: roleUser.userProperties.GroupRoles[0].id
            })
          }
          const userOfGroupResponse = await getUsersOfGroupDetails(group.id, [roleUser.email])
          user = userOfGroupResponse.data.users[0]
        }

        this.setState({
          user,
          loadingUser: false
        })
      })
      .catch(response => {
        const error = response.error

        switch (error.response.status) {
          case 400:
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '400'
              }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401:
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '401'
              }),
              alertMessagesText: [message]
            })
            break
          case 403:
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '403'
              }),
              alertMessagesText: [error.response.data.message]
            })
            break
          case 404:
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '404'
              }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406:
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '406'
              }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500:
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '500'
              }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loadingUser: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })

    getRoles(group.id)
      .then(response => {
        const roles = mapRolesProperties(response.data.groupRoles)
        this.setState({
          roles,
          rolesHierarchy: mapToHierarchicalRoles(response.data.groupRoles),
          loadingRoles: false
        })
      })
      .catch(response => {
        const error = response.error

        switch (error.response.status) {
          case 400:
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '400'
              }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401:
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '401'
              }),
              alertMessagesText: [message]
            })
            break
          case 403:
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '403'
              }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404:
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '404'
              }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406:
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '406'
              }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500:
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '500'
              }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loadingRoles: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  setUserTitle = (firstName, lastName, email) => {
    if (firstName && lastName && email) {
      const userFullName = firstName + ' ' + lastName + ' (' + email + ')'
      return this.formatMessage(messages.editUserTitle, { user: userFullName })
    } else {
      return '...'
    }
  }

  renderTableLoadingAndError = () => {
    const { loadingUser, loadingRoles, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } =
      this.state
    if (loadingUser || loadingRoles) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { onUserUpdate, assignRoleToUser, group, loggedUser } = this.props
    const { user, roleId, roles, loadingUser, loadingRoles, alertMessages, rolesHierarchy } = this.state
    if (loadingUser || loadingRoles || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      const userWithRole = mapCSToRCTUser(user, roleId, roles)

      return (
        <EditUserForm
          action={onUserUpdate}
          assignRoleToUser={assignRoleToUser}
          group={group}
          loggedUser={loggedUser}
          roles={roles}
          rolesHierarchy={rolesHierarchy}
          user={userWithRole}
        />
      )
    }
  }

  render() {
    const { user } = this.state
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <EditUserFormTitle
              title={this.setUserTitle(user.firstName, user.lastName, user.email)}
              usersUrl={getUsersUrl()}
            />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

EditUser.propTypes = {
  assignRoleToUser: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  getDetailedUsers: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUsersOfGroupDetails: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loggedUser: PropTypes.string.isRequired,
  onUserUpdate: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
}

export default injectIntl(EditUser)
