import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import { transformValuesFromAPI } from '../config'

import ViewConfigurationForm from './ViewConfigurationForm'
import ViewConfigurationFormTitle from './ViewConfigurationFormTitle'
import messages from './messages'

import download from 'utils/fileHandler/download'
import { logError } from 'utils/http'
import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'

class ViewConfiguration extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      alertMessagesConfiguration: false,
      alertMessagesTextConfiguration: [''],
      alertMessagesTitleConfiguration: '',
      alertMessagesTypeConfiguration: 'danger',
      ancestorConfigurations: [],
      configuration: null,
      createdDate: '',
      loading: true,
      title: '...'
    }
  }

  componentDidMount = () => {
    const { groupId } = this.props

    if (groupId) {
      this.getConfiguration()
    }
  }

  componentDidUpdate = prevProps => {
    const { groupId } = this.props

    if (groupId && prevProps.groupId !== groupId) {
      this.getConfiguration()
    }
  }

  componentWillUnmount() {
    const { history, location, resetLocalConfiguration } = this.props

    if (history.location.pathname !== location.pathname) {
      resetLocalConfiguration()
    }
  }

  getConfiguration = async () => {
    const { configurationId, getConfigurations, groupId, intl, setLocalConfiguration } = this.props

    const limit = Infinity
    const numMaxOfConfigurations = Math.min(10, limit)

    let configurations = []

    let count = 0
    let total = 0
    let internalOffset = 0
    let next = true

    let sameNamedConfigurations = []
    let ancestorConfigurations = []

    while (next) {
      try {
        const configurationsResponse = await getConfigurations(groupId, numMaxOfConfigurations, internalOffset)
          .then(response => response)
          .catch(response => {
            const { error } = { ...response }
            if (error && error.response) {
              switch (error.response.status) {
                case 400:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '400'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error400Message)]
                  })
                  break
                case 401:
                  let message
                  if (intl.locale === 'en') message = error.response.message
                  else message = this.formatMessage(messages.error401Message)
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '401'
                    }),
                    alertMessagesTextConfiguration: [message]
                  })
                  break
                case 403:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '403'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error403Message)]
                  })
                  break
                case 404:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '404'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error404Message)]
                  })
                  break
                case 406:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '406'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error406Message)]
                  })
                  break
                case 409:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '409'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error409Message)]
                  })
                  break
                case 415:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '415'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error415Message)]
                  })
                  break
                case 422:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '422'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error422Message)]
                  })
                  break
                case 500:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                      number: '500'
                    }),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.error500Message)]
                  })
                  break
                default:
                  this.setState({
                    loading: false,
                    alertMessagesConfiguration: true,
                    alertMessagesTypeConfiguration: 'danger',
                    alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
                    alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
                  })
                  logError(error)
              }
            } else {
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
            }
          })

        configurations = configurations.concat(configurationsResponse.data.configurations)
        count += configurationsResponse.data.count
        internalOffset += configurationsResponse.data.count
        total = configurationsResponse.data.total
        next =
          configurationsResponse.data.count === numMaxOfConfigurations && configurations.length < Math.min(limit, total)

        if (!next) {
          const configuration = configurations.find(config => config.id === configurationId)

          sameNamedConfigurations = sameNamedConfigurations.concat(
            configurations.filter(config => config.name === configuration.name)
          )

          if (configuration.parentId !== null)
            ancestorConfigurations = ancestorConfigurations.concat(
              this.getAncestorConfigurations(configuration.parentId, sameNamedConfigurations, [])
            )
          let title = configuration.name
          if (parseInt(configuration.versionNumber) > 0) title = title + ' v.' + configuration.versionNumber
          this.setState(
            {
              loading: false,
              title,
              configuration,
              createdDate: utcTimeToBrowserLocalShort(configuration.createdAt),
              ancestorConfigurations
            },
            () => {
              setLocalConfiguration(transformValuesFromAPI(configuration))
            }
          )
        }
      } catch (response) {
        return Promise.reject(response)
      }
    }
    return Promise.resolve({ data: { count, configurations, total } })
  }

  getAncestorConfigurations(configurationId, configs, configArray) {
    const configuration = configs.find(config => config.id === configurationId)
    if (!configuration) return configArray
    else if (configuration.parentId === null) return [configuration].concat(configArray)
    else return this.getAncestorConfigurations(configuration.parentId, configs, [configuration].concat(configArray))
  }

  downloadConfiguration = () => {
    const { configuration } = this.state

    const fileName = configuration.name + ' v.' + configuration.versionNumber + '.json'
    const blob = new Blob([JSON.stringify(configuration, null, 2)], { type: 'application/json' })
    download(blob, fileName, 'application/json')
  }

  renderViewConfigurationLoadingAndError = () => {
    const {
      alertMessagesConfiguration,
      alertMessagesTextConfiguration,
      alertMessagesTitleConfiguration,
      alertMessagesTypeConfiguration,
      loading
    } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessagesConfiguration) {
      return (
        <Alert
          alertType={alertMessagesTypeConfiguration}
          messageText={alertMessagesTextConfiguration}
          messageTitle={alertMessagesTitleConfiguration}
          showAlert={alertMessagesConfiguration}
        />
      )
    }
  }

  render() {
    const { configState, configurationsUrl } = this.props
    const {
      alertMessagesConfiguration,
      ancestorConfigurations,
      configuration,
      createdDate,
      loading,
      title
    } = this.state

    return (
      <div className="content-container" id="content">
        <div style={{ margin: '20px' }}>
          <div className="container-fluid">
            <ViewConfigurationFormTitle configurationsUrl={configurationsUrl} title={title} />
          </div>
          {loading || alertMessagesConfiguration ? (
            <div className="container-fluid" style={{ marginTop: 20 }}>
              {this.renderViewConfigurationLoadingAndError()}
            </div>
          ) : (
            <ViewConfigurationForm
              ancestorConfigurations={ancestorConfigurations}
              configForDataEstimation={configuration}
              configuration={configState}
              configurationsUrl={configurationsUrl}
              createdDate={createdDate}
              downloadConfiguration={this.downloadConfiguration}
              loadingConfiguration={loading}
            />
          )}
        </div>
      </div>
    )
  }
}

ViewConfiguration.propTypes = {
  configState: PropTypes.object.isRequired,
  configurationId: PropTypes.string.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  getConfigurations: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  setLocalConfiguration: PropTypes.func.isRequired
}

export default withRouter(injectIntl(ViewConfiguration))
