import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils } from 'ducks/login'
import { actions, utils as configUtils } from 'ducks/configurations'
import { utils as groupUtils } from 'ducks/groups'

import DbcFileImportFromPC from './DbcFileImportFromPC'

const mapStateToProps = state => ({
  groupId: groupUtils.getSelectedGroup(state).id,
  isProtocolListLoading: configUtils.getIsProtocolListLoading(state),
  loggedUser: utils.getLoggedUserEmail(state),
  protocolList: configUtils.getProtocolList(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteProtocol: actions.deleteCanProtocol,
      getProtocols: actions.getCanProtocolList,
      uploadDbcFile: actions.uploadDbcFile
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DbcFileImportFromPC)
