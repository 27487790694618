import { defineMessages } from 'react-intl'

const messages = defineMessages({
  activateDSP: {
    id: 'Actions.activateDSP',
    description: 'Activate digital service plan',
    defaultMessage: 'Activate the Digital Service Plan'
  },
  activateAutoRenewal: {
    id: 'Actions.activateAutoRenewal',
    description: 'Activate auto-renewal of the subscription',
    defaultMessage: 'Activate auto-renewal of the subscription'
  },
  deactivateAutoRenewal: {
    id: 'Actions.deactivateAutoRenewal',
    description: 'Deactivate auto-renewal of the subscription',
    defaultMessage: 'Deactivate auto-renewal of the subscription'
  }
})

export default messages
