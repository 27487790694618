import moment from 'moment'
import { createSelector } from 'reselect'

import { selectors as cs500Selectors } from 'ducks/configurationsCS500'
import { actionTypes as gt } from 'ducks/geotracking'
import csNodes from 'modules/csNode'
import { twosComplementToDecimal } from 'utils/binary'
import { actions } from 'utils/http'

import * as t from './actionTypes'
import * as c from './constants'

const { types: ht } = actions

const transformChartData = timeseries => {
  const result = timeseries.reduce((ret, resulLine) => {
    const dataVars = Object.keys(resulLine.azureData)

    dataVars.forEach(dVar => {
      if (dVar !== 'timestamp') {
        const newVarId = dVar.split('-')[1]
        const valueType = dVar.split('-')[2]
        const newId = `${newVarId}${valueType ? '-' + valueType : ''}`
        if (!(newId in ret)) ret[newId] = [] // eslint-disable-line no-param-reassign
        const timestamp = resulLine.azureData.timestamp
        ret[newId].push([moment(timestamp).valueOf(), Number(resulLine.azureData[dVar])])
      }
    })

    return ret
  }, {})
  return result
}
// eslint-disable-next-line max-params
const transformChartAggData = (timeseries, qFilters, qAgg, qBucket, minRequestTimestamp, maxRequestTimestamp) => {
  const filters = qFilters.split(',')
  const agg = qAgg.split(',')
  const result = filters.reduce((ret, dVar, index) => {
    const newVarId = dVar.split('-')[1]
    const valueType = dVar.split('-')[2]
    const dAgg = agg[index]
    const newId = `${newVarId}-${dAgg}-${qBucket}${valueType ? '-' + valueType : ''}`
    if (timeseries[dVar]) {
      // eslint-disable-next-line no-param-reassign
      ret[newId] = timeseries[dVar]
        .map(resulLine => [moment(resulLine.timestamp).valueOf(), Number(resulLine[dAgg])])
        .filter(point => point[0] >= minRequestTimestamp && point[0] <= maxRequestTimestamp)
    }
    return ret
  }, {})

  return result
}

const transformGpsTrackingsData = timeseries => {
  const result = timeseries.reduce((ret, gpsPoint) => {
    ret.push({
      timestamp: gpsPoint.timestamp,
      altitude: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude * 0.125 - 2500 : gpsPoint.altitude,
      speed: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed / 256 : gpsPoint.speed * 0.01 * 3.6
    })

    return ret
  }, [])

  return result
}

const transformGpsTrackingsAggData = (timeseries, minRequestTimestamp, maxRequestTimestamp) => {
  const result = timeseries
    .reduce((ret, gpsPoint) => {
      ret.push({
        altitude: {
          min: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude.min * 0.125 - 2500 : gpsPoint.altitude.min,
          max: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude.max * 0.125 - 2500 : gpsPoint.altitude.max,
          avg: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude.avg * 0.125 - 2500 : gpsPoint.altitude.avg
        },
        speed: {
          min: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed.min / 256 : gpsPoint.speed.min * 0.01 * 3.6,
          max: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed.max / 256 : gpsPoint.speed.max * 0.01 * 3.6,
          avg: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed.avg / 256 : gpsPoint.speed.avg * 0.01 * 3.6
        },
        timestamp: gpsPoint.timestamp
      })
      return ret
    }, [])
    .filter(gpsPoint => gpsPoint.timestamp >= minRequestTimestamp && gpsPoint.timestamp <= maxRequestTimestamp)

  return result
}

const transformAdvancedSignalsData = (measurements, operationType, minRequestTimestamp, maxRequestTimestamp) => {
  const result = measurements
    .reduce((ret, measurement) => {
      const timestamp = moment(measurement.signalEndTime).utc().startOf('day').valueOf()
      const value =
        operationType === '1' ? getHoursFromSeconds(measurement.calculatedValue) : measurement.calculatedValue
      ret.push([timestamp, value])
      return ret
    }, [])
    .filter(measurement => measurement[0] >= minRequestTimestamp && measurement[0] <= maxRequestTimestamp)
  return result
}

const getHoursFromSeconds = seconds => {
  const num = parseFloat('1e' + 2)
  return Math.round((seconds / 3600 + Number.EPSILON) * num) / num
}

const extractStatus = errorMess => {
  if (errorMess) {
    const errorInfo = errorMess.split('status code ')
    const status = parseInt(errorInfo[1])
    return status
  }
}

const getSeriesType = type => {
  switch (type) {
    case 'point':
      return {
        type: 'line',
        lineWidth: 0,
        marker: { enabled: true, radius: 4 }
      }
    case 'line':
      return {
        type: 'line',
        lineWidth: 2,
        marker: { enabled: false }
      }
    default:
      return {
        type
      }
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case t.SET_CHART_QUERY_TYPE:
      return {
        ...state,
        chartQueryType: action.payload.chartQueryType,
        minRequestTimestamp: action.payload.minRequestTimestamp,
        maxRequestTimestamp: action.payload.maxRequestTimestamp
      }

      // CAN and MFIO SIGNALS

    case t.GET_NODE_DATA_TIMESERIES:
      if (action.payload) {
        const EID = action.payload.request.data.nodeId
        const ret = { ...state, isSignalsLoading: true }
        if (ret.chartData && ret.chartData[EID]) {
          delete ret.chartData[EID]
        }
        if (ret.queryStatus && ret.queryStatus.chartDataQueryStatus) {
          delete ret.queryStatus.chartDataQueryStatus
        }
        return ret
      }
      break

    case actions.types.fail(t.GET_NODE_DATA_TIMESERIES):
      return {
        ...state,
        queryStatus: {
          ...state.queryStatus,
          chartDataQueryStatus: action.error
            ? extractStatus(action.error.message)
            : state?.queryStatus?.chartDataQueryStatus
        },
        isSignalsLoading: false
      }

    case actions.types.success(t.GET_NODE_DATA_TIMESERIES):
      if (action.payload && action.payload.status === 204) {
        const EID = action.meta.previousAction.payload.request.data.nodeId
        const queryData = action.meta.previousAction.payload.request.data
        return {
          ...state,
          isSignalsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            chartDataQueryStatus: action.payload.status
          },
          chartData: {
            ...state.chartData,
            [EID]: undefined
          },
          chartQuery: {
            ...state.chartQuery,
            [EID]: {
              ...queryData,
              next: undefined,
              prevs: []
            }
          }
        }
      } else if (
        action.payload &&
        action.payload.status === 200 &&
        action.payload.data &&
        action.payload.data.content
      ) {
        const timeseries = action.payload.data.content
        const EID = action.meta.previousAction.payload.request.data.nodeId
        const nextData = action.payload.data.next
        const queryData = action.meta.previousAction.payload.request.data
        return {
          ...state,
          isSignalsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            chartDataQueryStatus: action.payload.status
          },
          chartData: {
            ...state.chartData,
            [EID]: timeseries ? transformChartData(timeseries) : undefined
          },
          chartQuery: {
            ...state.chartQuery,
            [EID]: {
              ...queryData,
              next: nextData ? moment(nextData).valueOf() : undefined,
              prevs: []
            }
          }
        }
      }
      break

      // CAN and MFIO AGGREGATED SIGNALS

    case t.GET_NODE_DATA_AGG_TIMESERIES:
      if (action.payload) {
        const EID = action.payload.request.data.nodeId
        const bucketSize = action.payload.request.data.bucket
        const ret = { ...state, isAggregatedSignalsLoading: true }
        if (ret.chartAggData && ret.chartAggData[EID]) {
          const dataKeysWithTheSameBucketSize = Object.keys(ret.chartAggData[EID]).filter(key =>
            key.includes(bucketSize)
          )
          dataKeysWithTheSameBucketSize.forEach(dataKey => delete ret.chartAggData[EID][dataKey])
        }
        if (ret.queryStatus && ret.queryStatus.chartAggDataQueryStatus) {
          delete ret.queryStatus.chartAggDataQueryStatus
        }
        return ret
      }
      break

    case actions.types.fail(t.GET_NODE_DATA_AGG_TIMESERIES):
      return {
        ...state,
        queryStatus: {
          ...state.queryStatus,
          chartDataAggQueryStatus: action.error
            ? extractStatus(action.error.message)
            : state?.queryStatus?.chartDataAggQueryStatus
        },
        isAggregatedSignalsLoading: false
      }

    case actions.types.success(t.GET_NODE_DATA_AGG_TIMESERIES):
      if (action.payload && action.payload.status === 204) {
        const EID = action.meta.previousAction.payload.request.data.nodeId
        const bucketSize = action.meta.previousAction.payload.request.data.bucket
        const newChartAggDataByEid = state.chartAggData?.[EID] || {}
        const dataKeysWithTheSameBucketSize = Object.keys(newChartAggDataByEid).filter(key => key.includes(bucketSize))
        dataKeysWithTheSameBucketSize.forEach(dataKey => delete newChartAggDataByEid[dataKey])

        return {
          ...state,
          isAggregatedSignalsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            chartDataAggQueryStatus: action.payload.status
          },
          chartAggData: {
            ...state.chartAggData,
            [EID]: newChartAggDataByEid
          }
        }
      } else if (action.payload && action.payload.status === 200 && action.payload.data && action.payload.data.data) {
        const timeseries = action.payload.data.data
        const EID = action.meta.previousAction.payload.request.data.nodeId
        const queryData = action.meta.previousAction.payload.request.data

        return {
          ...state,
          isAggregatedSignalsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            chartDataAggQueryStatus: action.payload.status
          },
          chartAggData: {
            ...state.chartAggData,
            [EID]: {
              ...state.chartAggData ? state.chartAggData[EID] : undefined,
              ...timeseries
                ? transformChartAggData(
                  timeseries,
                  queryData.filters,
                  queryData.agg,
                  queryData.bucket,
                  queryData.min,
                  queryData.max
                )
                : undefined
            }
          }
        }
      }
      break

      // ALTITUDE AND SPEED SIGNALS

    case gt.GET_GPS_TRACKINGS_FOR_CHARTS:
      if (action.payload) {
        const EID = action.payload.request.data.deviceEid
        const ret = { ...state, isGpsTrackingsLoading: true }
        if (ret.gpsTrackingsData && ret.gpsTrackingsData[EID]) {
          delete ret.gpsTrackingsData[EID]
        }
        if (ret.queryStatus && ret.queryStatus.gpsTrackingsDataQueryStatus) {
          delete ret.queryStatus.gpsTrackingsDataQueryStatus
        }
        return ret
      }
      break
    case ht.fail(gt.GET_GPS_TRACKINGS_FOR_CHARTS):
      return {
        ...state,
        queryStatus: {
          ...state.queryStatus,
          gpsTrackingsDataQueryStatus: action.error
            ? extractStatus(action.error.message)
            : state?.queryStatus?.gpsTrackingsDataQueryStatus
        },
        isGpsTrackingsLoading: false
      }
    case ht.success(gt.GET_GPS_TRACKINGS_FOR_CHARTS):
      if (action.payload && action.payload.status === 204) {
        const EID = action.meta.previousAction.payload.request.data.deviceEid
        return {
          ...state,
          isGpsTrackingsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            gpsTrackingsDataQueryStatus: action.payload.status
          },
          gpsTrackingsData: {
            ...state.gpsTrackingsData,
            [EID]: undefined
          }
        }
      } else if (
        action.payload &&
        action.payload.status === 200 &&
        action.payload.data &&
        action.payload.data.content
      ) {
        const timeseries = action.payload.data.content
        const EID = action.meta.previousAction.payload.request.data.deviceEid

        return {
          ...state,
          isGpsTrackingsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            gpsTrackingsDataQueryStatus: action.payload.status
          },
          gpsTrackingsData: {
            ...state.gpsTrackingsData,
            [EID]: timeseries ? transformGpsTrackingsData(timeseries) : undefined
          }
        }
      }
      break

      // ALTITUDE AND SPEED AGGREGATED SIGNALS

    case gt.GET_AGGREGATED_GPS_TRACKINGS_FOR_CHARTS:
      if (action.payload) {
        const EID = action.payload.request.data.deviceEid
        const aggLevel = action.payload.request.data.aggLevel
        const ret = { ...state, isAggregatedGpsTrackingsLoading: true }
        if (ret.gpsTrackingsAggData && ret.gpsTrackingsAggData[EID]) {
          const dataKeysWithTheSameAggLevel = Object.keys(ret.gpsTrackingsAggData[EID]).filter(key =>
            key.includes(aggLevel)
          )
          dataKeysWithTheSameAggLevel.forEach(dataKey => delete ret.gpsTrackingsAggData[EID][dataKey])
        }
        if (ret.queryStatus && ret.queryStatus.gpsTrackingsAggDataQueryStatus) {
          delete ret.queryStatus.gpsTrackingsAggDataQueryStatus
        }
        return ret
      }
      break
    case ht.fail(gt.GET_AGGREGATED_GPS_TRACKINGS_FOR_CHARTS):
      return {
        ...state,
        queryStatus: {
          ...state.queryStatus,
          gpsTrackingsAggDataQueryStatus: action.error
            ? extractStatus(action.error.message)
            : state?.queryStatus?.gpsTrackingsAggDataQueryStatus
        },
        isAggregatedGpsTrackingsLoading: false
      }
    case ht.success(gt.GET_AGGREGATED_GPS_TRACKINGS_FOR_CHARTS):
      if (action.payload && action.payload.status === 204) {
        const EID = action.meta.previousAction.payload.request.data.deviceEid
        const aggLevel = action.meta.previousAction.payload.request.data.aggLevel

        const newGpsTrackingsAggDataByEid = state.gpsTrackingsAggData?.[EID] || []
        const dataKeysWithTheSameAggLevel = Object.keys(newGpsTrackingsAggDataByEid).filter(key =>
          key.includes(aggLevel)
        )
        dataKeysWithTheSameAggLevel.forEach(dataKey => delete newGpsTrackingsAggDataByEid[dataKey])

        return {
          ...state,
          isAggregatedGpsTrackingsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            gpsTrackingsAggDataQueryStatus: action.payload.status
          },
          gpsTrackingsAggData: {
            ...state.gpsTrackingsAggData,
            [EID]: newGpsTrackingsAggDataByEid
          }
        }
      } else if (
        action.payload &&
        action.payload.status === 200 &&
        action.payload.data &&
        action.payload.data.content
      ) {
        const timeseries = action.payload.data.content
        const EID = action.meta.previousAction.payload.request.data.deviceEid
        const aggLevel = action.meta.previousAction.payload.request.data.aggLevel
        const minRequestTimestamp = action.meta.previousAction.payload.request.data.start
        const maxRequestTimestamp = action.meta.previousAction.payload.request.data.end

        return {
          ...state,
          isAggregatedGpsTrackingsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            gpsTrackingsAggDataQueryStatus: action.payload.status
          },
          gpsTrackingsAggData: {
            ...state.gpsTrackingsAggData,
            [EID]: {
              ...state.gpsTrackingsAggData ? state.gpsTrackingsAggData[EID] : undefined,
              [aggLevel]: timeseries
                ? transformGpsTrackingsAggData(timeseries, minRequestTimestamp, maxRequestTimestamp)
                : undefined
            }
          }
        }
      }
      break

      // ADVANCED SIGNALS

    case ht.GET_MEASUREMENT_RESULTS:
      if (action.payload) {
        const EID = action.payload.request.data.deviceEid
        const deviceHashId = action.payload.request.data.deviceHashId
        const ret = { ...state, isAdvancedSignalsLoading: true }
        if (ret.advancedSignalsData && ret.advancedSignalsData[EID] && ret.advancedSignalsData[EID][deviceHashId]) {
          delete ret.advancedSignalsData[EID][deviceHashId]
        }
        if (ret.queryStatus && ret.queryStatus.advancedSignalsDataQueryStatus) {
          delete ret.queryStatus.advancedSignalsDataQueryStatus
        }
        return ret
      }
      break

    case ht.fail(ht.GET_MEASUREMENT_RESULTS):
      return {
        ...state,
        queryStatus: {
          ...state.queryStatus,
          advancedSignalsDataQueryStatus: action.error
            ? extractStatus(action.error.message)
            : state?.queryStatus?.advancedSignalsDataQueryStatus
        },
        isAdvancedSignalsLoading: false
      }

    case ht.success(ht.GET_MEASUREMENT_RESULTS):
      if (
        action.payload && action.payload.status === 204 ||
        action.payload && action.payload.status === 200 && action.payload.data && action.payload.data.length === 0
      ) {
        const EID = action.meta.previousAction.payload.request.data.deviceEid
        const deviceHashId = action.meta.previousAction.payload.request.data.deviceHashId

        const newAdvancedSignalsDataByEid = state.advancedSignalsData?.[EID] || []
        if (newAdvancedSignalsDataByEid && newAdvancedSignalsDataByEid[deviceHashId]) {
          delete newAdvancedSignalsDataByEid[deviceHashId]
        }

        return {
          ...state,
          isAdvancedSignalsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            advancedSignalsDataQueryStatus: action.payload.status
          },
          advancedSignalsData: {
            ...state.advancedSignalsData,
            [EID]: newAdvancedSignalsDataByEid
          }
        }
      } else if (
        action.payload &&
        action.payload.status === 200 &&
        action.payload.data &&
        action.payload.data.length > 0
      ) {
        const measurements = action.payload.data

        const queryData = action.meta.previousAction.payload.request.data
        const EID = queryData.deviceEid
        const measurementName = queryData.measurementName
        const measurementUnit = queryData.unit
        const operationType = queryData.operationType
        const deviceHashId = queryData.deviceHashId
        const minRequestTimestamp = queryData.min
        const maxRequestTimestamp = queryData.max

        return {
          ...state,
          isAdvancedSignalsLoading: false,
          queryStatus: {
            ...state.queryStatus,
            advancedSignalsDataQueryStatus: action.payload.status
          },
          advancedSignalsData: {
            ...state.advancedSignalsData,
            [EID]: {
              ...state.advancedSignalsData?.[EID],
              [deviceHashId]: {
                name: measurementName,
                unit: measurementUnit,
                data: transformAdvancedSignalsData(
                  measurements,
                  operationType,
                  minRequestTimestamp,
                  maxRequestTimestamp
                )
              }
            }
          }
        }
      }
      break

    case t.RESET_REPORTS:
      return {}
    case t.SET_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.deviceId]: action.payload.filters
        }
      }

    default:
  }
  return state
}

const getReports = state => (state[c.NAME] === null ? null : state[c.NAME])

const getChartQueryTime = state =>
  getReports(state) ? getReports(state).chartQueryType ? getReports(state).chartQueryType : {} : null

const getMinRequestTimestamp = state =>
  getReports(state) ? getReports(state).minRequestTimestamp ? getReports(state).minRequestTimestamp : {} : null

const getMaxRequestTimestamp = state =>
  getReports(state) ? getReports(state).maxRequestTimestamp ? getReports(state).maxRequestTimestamp : {} : null

const getChartData = state =>
  getReports(state) ? getReports(state).chartData ? getReports(state).chartData : {} : null

const getChartAggData = state =>
  getReports(state) ? getReports(state).chartAggData ? getReports(state).chartAggData : {} : null

const getGpsTrackingsData = state =>
  getReports(state) ? getReports(state).gpsTrackingsData ? getReports(state).gpsTrackingsData : {} : null

const getGpsTrackingsAggData = state =>
  getReports(state) ? getReports(state).gpsTrackingsAggData ? getReports(state).gpsTrackingsAggData : {} : null

const getAdvancedSignalsData = state =>
  getReports(state) ? getReports(state).advancedSignalsData ? getReports(state).advancedSignalsData : {} : null

const getChartQuery = state =>
  getReports(state) ? getReports(state).chartQuery ? getReports(state).chartQuery : {} : null

const getQueryStatus = state => (getReports(state) ? getReports(state).queryStatus : undefined)

const getFormatedChartData = (state, colors) => getFormatedChartDataSelector(state, colors)

const getColors = (state, colors) => colors

const getFilters = state => (getReports(state).filters ? getReports(state).filters : {})

const isDataToShow = state => {
  return getReports(state).chartQuery && getReports(state).chartData || false
}

const getIsSignalsLoading = state => {
  return getReports(state).isSignalsLoading || false
}

const getIsAggregatedSignalsLoading = state => {
  return getReports(state).isAggregatedSignalsLoading || false
}

const getIsGpsTrackingsLoading = state => {
  return getReports(state).isGpsTrackingsLoading || false
}

const getIsAggregatedGpsTrackingsLoading = state => {
  return getReports(state).isAggregatedGpsTrackingsLoading || false
}

const getIsAdvancedSignalsLoading = state => {
  return getReports(state).isAdvancedSignalsLoading || false
}

/* eslint-disable max-params */
const getFormatedChartDataSelector = createSelector(
  getChartQueryTime,
  getMinRequestTimestamp,
  getMaxRequestTimestamp,
  getColors,
  getChartData,
  getChartAggData,
  getGpsTrackingsData,
  getGpsTrackingsAggData,
  getAdvancedSignalsData,
  csNodes.utils.getAllCSNodes,
  cs500Selectors.getAllDevicesConfigurations,
  getFilters,
  (
    chartQueryType,
    minRequestTimestamp,
    maxRequestTimestamp,
    colors,
    chartData,
    chartAggData,
    gpsTrackingsData,
    gpsTrackingsAggData,
    advancedSignalsData,
    devices,
    cs500DevicesConfigurations,
    filters
  ) => {
    // eslint-disable-next-line no-param-reassign
    devices = devices.map(device => {
      const newDevice = { ...device }
      if (device.deviceType === 'CS500' && device.EID in cs500DevicesConfigurations) {
        newDevice.deviceConfiguration = cs500DevicesConfigurations[device.EID].deviceConfiguration
      }
      return newDevice
    })

    const devicesWithChartData = Object.keys(chartData).filter(device => chartData[device])
    const devicesWithChartAggData = Object.keys(chartAggData).filter(device => chartAggData[device])
    const devicesWithGpsTrackingsData = Object.keys(gpsTrackingsData).filter(device => gpsTrackingsData[device])
    const devicesWithGpsTrackingsAggData = Object.keys(gpsTrackingsAggData).filter(
      device => gpsTrackingsAggData[device]
    )
    const devicesWithAdvancedSignalsData = Object.keys(advancedSignalsData).filter(
      device => advancedSignalsData[device]
    )

    const getMinTimestamp = () => {
      const chartDataMinTimestamp = devicesWithChartData.reduce((ret, device) => {
        const varIDs = Object.keys(chartData[device])
        varIDs.forEach(varId => {
          if (chartData[device][varId].length > 0) {
            const firstDataPoint = chartData[device][varId][0]
            const firstTS = firstDataPoint[0]
            ret = ret ? ret > firstTS ? firstTS : ret : firstTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, 0)

      const chartAggDataMinTimestamp = devicesWithChartAggData.reduce((ret, device) => {
        const varIDs = Object.keys(chartAggData[device])
        varIDs.forEach(varId => {
          if (chartAggData[device][varId].length > 0) {
            const firstDataPoint = chartAggData[device][varId][0]
            const firstTS = firstDataPoint[0]
            ret = ret ? ret > firstTS ? firstTS : ret : firstTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, 0)

      const gpsTrackingsDataMinTimestamp = devicesWithGpsTrackingsData.reduce((ret, device) => {
        if (gpsTrackingsData[device].length > 0) {
          const firstDataPoint = gpsTrackingsData[device][0]
          const firstTS = firstDataPoint.timestamp
          ret = ret ? ret > firstTS ? firstTS : ret : firstTS // eslint-disable-line no-param-reassign
        }

        return ret
      }, 0)

      const gpsTrackingsAggDataMinTimestamp = devicesWithGpsTrackingsAggData.reduce((ret, device) => {
        const varIDs = Object.keys(gpsTrackingsAggData[device])
        varIDs.forEach(varId => {
          if (gpsTrackingsAggData[device][varId].length > 0) {
            const firstDataPoint = gpsTrackingsAggData[device][varId][0]
            const firstTS = firstDataPoint.timestamp
            ret = ret ? ret > firstTS ? firstTS : ret : firstTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, 0)

      const advancedSignalsDataMinTimestamp = devicesWithAdvancedSignalsData.reduce((ret, device) => {
        const varIDs = Object.keys(advancedSignalsData[device])
        varIDs.forEach(varId => {
          if (advancedSignalsData[device][varId].data.length > 0) {
            const firstDataPoint = advancedSignalsData[device][varId].data[0]
            const firstTS = firstDataPoint[0]
            ret = ret ? ret > firstTS ? firstTS : ret : firstTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, 0)

      const minTimestampsArray = [
        chartDataMinTimestamp,
        chartAggDataMinTimestamp,
        gpsTrackingsDataMinTimestamp,
        gpsTrackingsAggDataMinTimestamp,
        advancedSignalsDataMinTimestamp
      ].filter(ts => ts > 0)

      if (minTimestampsArray.length > 0) {
        return Math.min(...minTimestampsArray)
      } else {
        return 0
      }
    }

    const getMinCommonTimestamp = () => {
      const chartDataMinCommonTimestamp = devicesWithChartData.reduce((ret, device) => {
        const varIDs = Object.keys(chartData[device])
        varIDs.forEach(varId => {
          if (chartData[device][varId].length > 1) {
            const lastDataPoint = chartData[device][varId][chartData[device][varId].length - 1]
            const lastTS = lastDataPoint[0]
            ret = ret ? ret > lastTS ? lastTS : ret : lastTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, Number.MAX_VALUE)

      const chartAggDataMinCommonTimestamp = devicesWithChartAggData.reduce((ret, device) => {
        const varIDs = Object.keys(chartAggData[device])
        varIDs.forEach(varId => {
          if (chartAggData[device][varId].length > 1) {
            const lastDataPoint = chartAggData[device][varId][chartAggData[device][varId].length - 1]
            const lastTS = lastDataPoint[0]
            ret = ret ? ret > lastTS ? lastTS : ret : lastTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, Number.MAX_VALUE)

      const gpsTrackingsDataMinCommonTimestamp = devicesWithGpsTrackingsData.reduce((ret, device) => {
        if (gpsTrackingsData[device].length > 1) {
          const lastDataPoint = gpsTrackingsData[device][gpsTrackingsData[device].length - 1]
          const lastTS = lastDataPoint.timestamp
          ret = ret ? ret > lastTS ? lastTS : ret : lastTS // eslint-disable-line no-param-reassign
        }
        return ret
      }, Number.MAX_VALUE)

      const gpsTrackingsAggDataMinCommonTimestamp = devicesWithGpsTrackingsAggData.reduce((ret, device) => {
        const varIDs = Object.keys(gpsTrackingsAggData[device])
        varIDs.forEach(varId => {
          if (gpsTrackingsAggData[device][varId].length > 1) {
            const lastDataPoint = gpsTrackingsAggData[device][varId][gpsTrackingsAggData[device][varId].length - 1]
            const lastTS = lastDataPoint.timestamp
            ret = ret ? ret > lastTS ? lastTS : ret : lastTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, Number.MAX_VALUE)

      const advancedSignalsDataMinCommonTimestamp = devicesWithAdvancedSignalsData.reduce((ret, device) => {
        const varIDs = Object.keys(advancedSignalsData[device])
        varIDs.forEach(varId => {
          if (advancedSignalsData[device][varId].data.length > 1) {
            const lastDataPoint =
              advancedSignalsData[device][varId].data[advancedSignalsData[device][varId].data.length - 1]
            const lastTS = lastDataPoint[0]
            ret = ret ? ret > lastTS ? lastTS : ret : lastTS // eslint-disable-line no-param-reassign
          }
        })
        return ret
      }, Number.MAX_VALUE)

      const minCommonTimestampsArray = [
        chartDataMinCommonTimestamp,
        chartAggDataMinCommonTimestamp,
        gpsTrackingsDataMinCommonTimestamp,
        gpsTrackingsAggDataMinCommonTimestamp,
        advancedSignalsDataMinCommonTimestamp
      ].filter(ts => ts < Number.MAX_VALUE)

      if (minCommonTimestampsArray.length > 0) {
        return Math.min(...minCommonTimestampsArray)
      } else {
        return Number.MAX_VALUE
      }
    }

    var yAxis = []
    const minTimestamp = chartQueryType === 'PREV' ? minRequestTimestamp : getMinTimestamp()
    const minCommonTimestamp = chartQueryType === 'PREV' ? maxRequestTimestamp : getMinCommonTimestamp()

    const transformedChartData = devices.reduce((ret, node) => {
      if (node.EID) {
        const cleanEID = node.EID.replaceAll(':', '')
        if (devicesWithChartData.includes(cleanEID)) {
          const varIDs = Object.keys(chartData[cleanEID])
          varIDs.forEach(varId => {
            const valueType = varId.split('-')[1]
            const sensorsArray = node.deviceConfiguration?.sensorsMap
              ?.filter(sensor => sensor.frequency !== 0)
              .concat(node.deviceConfiguration?.MFIO)
            sensorsArray?.forEach(sensor => {
              if (sensor.signalId === parseInt(varId)) {
                const filteredChartData = chartData[cleanEID][varId].filter(
                  dataPoint => dataPoint[0] >= minTimestamp && dataPoint[0] <= minCommonTimestamp
                )
                const adjustedChartData = filteredChartData
                  .map(dataPoint => {
                    let adjustedPoint = []
                    if (node.deviceType === 'CS100') {
                      if (sensor.signed) {
                        const transformedValue =
                          (twosComplementToDecimal(dataPoint[1], sensor.lengthOfBits) ?? NaN) * sensor.multiplier +
                          sensor.offset
                        if (transformedValue || transformedValue === 0) {
                          adjustedPoint = [dataPoint[0], transformedValue]
                        } else {
                          adjustedPoint = null
                        }
                      } else {
                        adjustedPoint = [dataPoint[0], dataPoint[1] * sensor.multiplier + sensor.offset]
                      }
                    } else if (node.deviceType === 'CS500') {
                      adjustedPoint = [dataPoint[0], dataPoint[1] / sensor.divider]
                    }

                    return adjustedPoint
                  })
                  .filter(Boolean)
                const filteredSeriesConf = colors.find(
                  color =>
                    color.seriesName === `${node.name}_${sensor.name}${valueType ? '_' + valueType : ''} agg(none, raw)`
                )
                const color = filteredSeriesConf ? filteredSeriesConf.color : '#CCC'
                const type = filteredSeriesConf ? filteredSeriesConf.type : 'line'
                const axisIndex = yAxis.findIndex(axis => axis.title.text === sensor.name)

                if (axisIndex !== -1) {
                  const seriesConfig = {
                    ...getSeriesType(type),
                    name: `${node.name}_${sensor.name}${valueType ? '_' + valueType : ''}${
                      sensor.unit !== '' ? ' (' + sensor.unit + ')' : ''
                    }`,
                    color,
                    data: adjustedChartData,
                    yAxis: axisIndex,
                    numberOfDecimals: sensor?.decimals
                  }
                  ret.push(seriesConfig)
                } else {
                  const seriesConfig = {
                    ...getSeriesType(type),
                    name: `${node.name}_${sensor.name}${valueType ? '_' + valueType : ''}${
                      sensor.unit !== '' ? ' (' + sensor.unit + ')' : ''
                    }`,
                    color,
                    data: adjustedChartData,
                    yAxis: yAxis.length,
                    numberOfDecimals: sensor?.decimals
                  }
                  ret.push(seriesConfig)
                  yAxis.push({
                    labels: {
                      format: '{value} ' + sensor.unit,
                      style: {
                        color
                      }
                    },
                    title: {
                      text: '',
                      style: {
                        color
                      }
                    },
                    opposite: yAxis.length % 2
                  })
                }
              }
            })
          })
        }
      }
      return ret
    }, [])

    const transformedChartAggData = devices.reduce((ret, node) => {
      if (node.EID) {
        const cleanEID = node.EID.replaceAll(':', '')
        if (devicesWithChartAggData.includes(cleanEID)) {
          const varIDs = Object.keys(chartAggData[cleanEID])
          varIDs.forEach(varId => {
            const splVarId = varId.split('-')
            const signalId = splVarId[0]
            const agg = splVarId[1]
            const bucket = splVarId[2]
            const valueType = splVarId[3]
            const sensorsArray = node.deviceConfiguration.sensorsMap
              .filter(sensor => sensor.frequency !== 0)
              .concat(node.deviceConfiguration.MFIO)
            sensorsArray.forEach(sensor => {
              if (sensor.signalId === parseInt(signalId)) {
                const filteredchartAggData = chartAggData[cleanEID][varId].filter(
                  dataPoint => dataPoint[0] >= minTimestamp && dataPoint[0] <= minCommonTimestamp
                )
                var adjustedchartAggData = filteredchartAggData
                  .map(dataPoint => {
                    let adjustedPoint = []
                    if (node.deviceType === 'CS100') {
                      if (sensor.signed) {
                        const transformedValue =
                          (twosComplementToDecimal(dataPoint[1], sensor.lengthOfBits) ?? NaN) * sensor.multiplier +
                          sensor.offset
                        if (transformedValue || transformedValue === 0) {
                          adjustedPoint = [dataPoint[0], transformedValue]
                        } else {
                          adjustedPoint = null
                        }
                      } else {
                        adjustedPoint = [dataPoint[0], dataPoint[1] * sensor.multiplier + sensor.offset]
                      }
                    } else if (node.deviceType === 'CS500') {
                      adjustedPoint = [dataPoint[0], dataPoint[1] / sensor.divider]
                    }

                    return adjustedPoint
                  })
                  .filter(Boolean)

                const filteredSeriesConf = colors.find(
                  color =>
                    color.seriesName ===
                    `${node.name}_${sensor.name}${valueType ? '_' + valueType : ''} agg(${agg}, ${bucket})`
                )
                const color = filteredSeriesConf ? filteredSeriesConf.color : '#CCC'
                const type = filteredSeriesConf ? filteredSeriesConf.type : 'line'
                const axisIndex = yAxis.findIndex(axis => axis.title.text === sensor.name)

                if (axisIndex !== -1) {
                  const seriesConfig = {
                    ...getSeriesType(type),
                    name: `${node.name}_${sensor.name}${valueType ? '_' + valueType : ''} agg (${agg}, ${bucket})${
                      sensor.unit !== '' ? ' (' + sensor.unit + ')' : ''
                    }`,
                    color,
                    data: adjustedchartAggData,
                    yAxis: axisIndex,
                    numberOfDecimals: sensor?.decimals
                  }
                  ret.push(seriesConfig)
                } else {
                  const seriesConfig = {
                    ...getSeriesType(type),
                    name: `${node.name}_${sensor.name}${valueType ? '_' + valueType : ''} agg (${agg}, ${bucket})${
                      sensor.unit !== '' ? ' (' + sensor.unit + ')' : ''
                    }`,
                    color,
                    data: adjustedchartAggData,
                    yAxis: yAxis.length,
                    numberOfDecimals: sensor?.decimals
                  }
                  ret.push(seriesConfig)
                  yAxis.push({
                    labels: {
                      format: '{value} ' + sensor.unit,
                      style: {
                        color
                      }
                    },
                    title: {
                      text: '', //sensor.name,
                      style: {
                        color
                      }
                    },
                    opposite: yAxis.length % 2
                  })
                }
              }
            })
          })
        }
      }
      return ret
    }, [])

    const transformedGpsTrackingsData = devices
      .filter(node => node.EID)
      .reduce((ret, node) => {
        const cleanEID = node.EID.replaceAll(':', '')
        const deviceGpsTrackings = gpsTrackingsData[cleanEID] || []

        const deviceGpsFilters = filters?.[node.id]?.raw?.gpsFilter ? filters[node.id].raw.gpsFilter.split(',') : []

        const series = deviceGpsFilters.map(filter => {
          const filterSensor = c.GPS_SENSORS.find(sensor => sensor.signalId === filter)
          const { color, type } =
            colors.find(item => item.seriesName === `${node.name}_${filterSensor.value} agg(none, raw)`) || {}

          const adjustedChartData = deviceGpsTrackings
            .map(tracking => {
              return [tracking.timestamp, tracking[filterSensor.value.toLowerCase()]]
            })
            .filter(tracking => tracking[1] !== 'NaN')
            .filter(tracking => tracking[0] >= minTimestamp && tracking[0] <= minCommonTimestamp)

          const axisIndex = yAxis.findIndex(axis => axis.title.text === filter)
          let seriesConfig = {}
          if (axisIndex !== -1) {
            seriesConfig = {
              ...getSeriesType(type),
              name: `${node.name}_${filterSensor.value}${
                filterSensor.unit !== '' ? ' (' + filterSensor.unit + ')' : ''
              }`,
              color,
              data: adjustedChartData,
              yAxis: axisIndex
            }
          } else {
            seriesConfig = {
              ...getSeriesType(type),
              name: `${node.name}_${filterSensor.value}${
                filterSensor.unit !== '' ? ' (' + filterSensor.unit + ')' : ''
              }`,
              color,
              data: adjustedChartData,
              yAxis: yAxis.length
            }

            yAxis.push({
              labels: {
                format: '{value} ' + filterSensor.unit,
                style: {
                  color
                }
              },
              title: {
                text: '', //filterSensor.value,
                style: {
                  color
                }
              },
              opposite: yAxis.length % 2
            })
          }

          return seriesConfig
        })

        const aggregatedSeries = [
          {
            bucket: 'hour',
            aggregatedFilters: filters?.[node.id]?.hour?.gpsFilter ? filters[node.id].hour.gpsFilter.split(',') : [],
            aggregations: filters?.[node.id]?.hour?.gpsAggType ? filters[node.id].hour.gpsAggType.split(',') : []
          },
          {
            bucket: 'day',
            aggregatedFilters: filters?.[node.id]?.day?.gpsFilter ? filters[node.id].day.gpsFilter.split(',') : [],
            aggregations: filters?.[node.id]?.day?.gpsAggType ? filters[node.id].day.gpsAggType.split(',') : []
          },
          {
            bucket: 'month',
            aggregatedFilters: filters?.[node.id]?.month?.gpsFilter ? filters[node.id].month.gpsFilter.split(',') : [],
            aggregations: filters?.[node.id]?.month?.gpsAggType ? filters[node.id].month.gpsAggType.split(',') : []
          }
        ]
          .map(({ bucket, aggregatedFilters, aggregations }) => {
            return aggregatedFilters.map((filter, index) => {
              const filterSensor = c.GPS_SENSORS.find(sensor => sensor.signalId === filter)
              const aggregation = aggregations[index]

              const { color, type } =
                colors.find(
                  item => item.seriesName === `${node.name}_${filterSensor.value} agg(${aggregation}, ${bucket})`
                ) || {}
              const adjustedChartData = (gpsTrackingsAggData[cleanEID]?.[bucket] || [])
                .map(tracking => {
                  return [tracking.timestamp, tracking[filterSensor.value.toLowerCase()][aggregation]]
                })
                .filter(tracking => tracking[1] !== 'NaN')
                .filter(tracking => tracking[0] >= minTimestamp && tracking[0] <= minCommonTimestamp)

              const axisIndex = yAxis.findIndex(axis => axis.title.text === filter)
              let seriesConfig = {}
              if (axisIndex !== -1) {
                seriesConfig = {
                  ...getSeriesType(type),
                  name: `${node.name}_${filterSensor.value} agg (${aggregation}, ${bucket})${
                    filterSensor.unit !== '' ? ' (' + filterSensor.unit + ')' : ''
                  }`,
                  color,
                  data: adjustedChartData,
                  yAxis: axisIndex
                }
              } else {
                seriesConfig = {
                  ...getSeriesType(type),
                  name: `${node.name}_${filterSensor.value} agg (${aggregation}, ${bucket})${
                    filterSensor.unit !== '' ? ' (' + filterSensor.unit + ')' : ''
                  }`,
                  color,
                  data: adjustedChartData,
                  yAxis: yAxis.length
                }

                yAxis.push({
                  labels: {
                    format: '{value} ' + filterSensor.unit,
                    style: {
                      color
                    }
                  },
                  title: {
                    text: '', //filterSensor.value,
                    style: {
                      color
                    }
                  },
                  opposite: yAxis.length % 2
                })
              }

              return seriesConfig
            })
          })
          .reduce((acc, cur) => [...acc, ...cur], [])

        return [...ret, ...series, ...aggregatedSeries]
      }, [])

    const advancedSignalsChartData = devices.reduce((ret, node) => {
      if (node.EID) {
        const cleanEID = node.EID.replaceAll(':', '')
        if (devicesWithAdvancedSignalsData.includes(cleanEID) && advancedSignalsData[cleanEID]) {
          const advancedSignalIDs = Object.keys(advancedSignalsData[cleanEID])

          advancedSignalIDs.forEach(advancedSignalId => {
            const name = advancedSignalsData[cleanEID][advancedSignalId].name
            const unit = advancedSignalsData[cleanEID][advancedSignalId].unit
            const data = advancedSignalsData[cleanEID][advancedSignalId].data

            const filteredAdvancedSignalData = data.filter(
              dataPoint => dataPoint[0] >= minTimestamp && dataPoint[0] <= minCommonTimestamp
            )

            const { color, type } = colors.find(item => item.seriesName === `${node.name}_${name} agg(none, raw)`) || {}
            const axisIndex = yAxis.findIndex(axis => axis.title.text === name)

            if (axisIndex !== -1) {
              const seriesConfig = {
                ...getSeriesType(type),
                name: name + ' (' + unit + ')',
                color,
                data: filteredAdvancedSignalData,
                yAxis: axisIndex,
                numberOfDecimals: 2
              }
              ret.push(seriesConfig)
            } else {
              const seriesConfig = {
                ...getSeriesType(type),
                name: name + ' (' + unit + ')',
                color,
                data: filteredAdvancedSignalData,
                yAxis: yAxis.length,
                numberOfDecimals: 2
              }
              ret.push(seriesConfig)
              yAxis.push({
                labels: {
                  format: '{value} ' + unit,
                  style: {
                    color
                  }
                },
                title: {
                  text: '', //name,
                  style: {
                    color
                  }
                },
                opposite: yAxis.length % 2
              })
            }
          })
        }
      }
      return ret
    }, [])

    return transformedChartData.length > 0 ||
      transformedChartAggData.length > 0 ||
      transformedGpsTrackingsData.length > 0 ||
      advancedSignalsChartData.length > 0
      ? {
        series: [
          ...transformedChartData,
          ...transformedChartAggData,
          ...transformedGpsTrackingsData,
          ...advancedSignalsChartData
        ],
        yAxis,
        chartStart: minTimestamp,
        chartEnd: minCommonTimestamp
      }
      : {}
  }
)
/* eslint-enable */

export {
  getChartData,
  getChartQuery,
  getFormatedChartData,
  getIsAdvancedSignalsLoading,
  getIsAggregatedGpsTrackingsLoading,
  getIsAggregatedSignalsLoading,
  getIsGpsTrackingsLoading,
  getIsSignalsLoading,
  getQueryStatus,
  getReports,
  isDataToShow,
  transformChartData
}
