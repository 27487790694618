import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getDevicesData } from 'ducks/groupDashboards'
import { actions as websocket, utils } from 'ducks/websocket'

import { actions as hactions } from 'utils/http'

import Box from './Box'
import BoxSettingsUnconnected from './BoxSettings'
import BoxTemplate from './BoxTemplate'

import csNode from 'modules/csNode'

const mapStateToProps = state => {
  return {
    devicesData: getDevicesData(state),
    getNodeCredentials: id => csNode.utils.getRealTimeCredentials(state)[id],
    isSubscribedTo: (eid, topic) => utils.isSubscribedTo(state, eid, topic)
  }
}

/* eslint-disable no-shadow */
const mapDispatchToProps = dispatch => {
  return {
    subscribeWidgetToWS: (topics, eid, credentials) =>
      dispatch(websocket.subscribeRealTimeSignal(topics, eid, credentials)),
    unsubscribeWidgetFromWS: (topic, eid) => dispatch(websocket.unsubscribe(topic, eid)),
    ...bindActionCreators(
      {
        getAzureToken: (id, eid) => dispatch => {
          const { promise } = dispatch(hactions.plus1Connect.getAzureNodeToken(id, eid))
          return promise
        }
      },
      dispatch
    )
  }
}
/* eslint-enable */

export default connect(mapStateToProps, mapDispatchToProps)(Box)

const BoxSettings = connect(mapStateToProps, mapDispatchToProps)(BoxSettingsUnconnected)
export { BoxSettings, BoxTemplate }
