import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils } from 'ducks/groups'
import { actions } from 'ducks/accessPasses'

import ShareAccessPasses from './ShareAccessPasses'

const mapStateToProps = (state, { match, history }) => ({
  getGroup: groupId => utils.getCurrentGroup(state, groupId),
  onShareAccessPasses: (name, number) => {
    history.replace(match.path, { action: 'accessPassesShared', name, number })
  },
  getChildGroups: groupId => utils.getChildGroups(state, groupId)
})

const mapDispatchToProps = dispatch => ({
  shareAccessPasses: (targetGroupId, body) => dispatch(actions.shareAccessPasses(targetGroupId, body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareAccessPasses))
