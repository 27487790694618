import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import PageTitle from 'components/PageTitle'

import messages from './messages'

const EditConfigurationFormTitle = ({ configurationsUrl, intl, location }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)

  const back = {
    pathname: configurationsUrl,
    state: location.state
  }

  return <PageTitle back={back} title={title} />
}

EditConfigurationFormTitle.propTypes = {
  configurationsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(injectIntl(EditConfigurationFormTitle))
