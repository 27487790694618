import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ShareAccessPasses.title',
    description: 'Access Pass sharing dialog title',
    defaultMessage: 'Share Access Passes'
  },
  selectTheGroup: {
    id: 'ShareAccessPasses.selectTheGroup',
    description: 'Select the group',
    defaultMessage: 'Group'
  },
  selectNumberOfAccessPasses: {
    id: 'ShareAccessPasses.selectNumberOfAccessPasses',
    description: 'Select number of Access Passes',
    defaultMessage: 'Select number of Access Passes'
  },
  cancel: {
    id: 'ShareAccessPasses.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  share: {
    id: 'ShareAccessPasses.share',
    description: 'Share button label',
    defaultMessage: 'Share'
  },
  errorAccessPassShare: {
    id: 'ShareAccessPasses.errorAccessPassShare',
    description: 'Access Pass sharing error message',
    defaultMessage: 'There was an error sharing the Access Passes'
  },
  thisFieldIsRequired: {
    id: 'ShareAccessPasses.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  invalidNumberOfAccessPasses: {
    id: 'ShareAccessPasses.invalidNumberOfAccessPasses',
    description: 'Invalid number of Access Passes',
    defaultMessage: 'Invalid number of Access Passes'
  },
  youExceededTheMaximum: {
    id: 'ShareAccessPasses.youExceededTheMaximum',
    description: 'You exceeded the amount of available Access Passes',
    defaultMessage: 'You exceeded the amount of available Access Passes'
  },
  allTheAccessPassesAlreadyShared: {
    id: 'ShareAccessPasses.allTheAccessPassesAlreadyShared',
    description: 'All the Access Passes were already shared with target group',
    defaultMessage: 'All the Access Passes were already shared with {groupName} group'
  }
})

export default messages
