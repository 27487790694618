import { connect } from 'react-redux'

import Home from './Home'
import reducer from './reducer'
import * as constants from './constants'
import * as actionTypes from './actionTypes'
import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'

const mapStateToProps = state => ({
  canCreateReports: groupUtils.hasPermission(state, c.SCHEDULE_CREATE),
  canEditReports: groupUtils.hasPermission(state, c.SCHEDULE_EDIT),
  canReadReports: groupUtils.hasPermission(state, c.SCHEDULE_READ)
})

export default connect(mapStateToProps)(Home)

export { reducer, constants, actionTypes }
export { getReportsUrl } from './url'
