import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import SidebarItem from '../SidebarItem'
import messages from './messages'
import AddGroupDashboard from 'modules/groupDashboards/Modals/AddGroupDashboard'

import { DEFAULT_GROUP_NAME } from 'utils/constants'
import { client, logError } from 'utils/http'

const styles = () => ({
  divider: {
    backgroundColor: props => props.appFontColor,
    opacity: '90%',
    marginTop: '10px'
  },
  icon: {
    textAlign: 'center',
    fontSize: '22px',
    opacity: '90%',
    color: props => props.appFontColor,
    '&:hover': {
      opacity: '100%'
    }
  },
  groupDashboardsSubtitle: {
    color: props => props.appFontColor + '80',
    fontWeight: 'bold',
    padding: '11px 16px 3px 16px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  }
})

class GroupDashboards extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openAddGroupDashboardModal: false,
      loading: false
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const {
      selectedGroup: { id: selectedGroupId }
    } = this.props

    if (selectedGroupId) {
      this.getGroupDashboards()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loadingGroup,
      selectedGroup: { id: selectedGroupId }
    } = this.props

    if (prevProps.selectedGroup.id !== selectedGroupId) {
      this.setState({ loading: true })
    }

    if (prevProps.loadingGroup && !loadingGroup) {
      this.getGroupDashboards()
    }
  }

  getGroupDashboards = () => {
    const {
      selectedGroup: { id: selectedGroupId },
      storeGroupDashboards
    } = this.props

    this.setState({ loading: true })

    client
      .getGroupDashboards(selectedGroupId)
      .then(response => {
        const dashboards = response.data
        storeGroupDashboards(dashboards)
      })
      .catch(error => {
        if (error?.response) {
          logError(error)
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }

  getCanShowDashboards = () => {
    const {
      selectedGroup: { id: selectedGroupId },
      userGroups
    } = this.props

    const defaultGroupId = userGroups.find(group => group.name === DEFAULT_GROUP_NAME)?.id

    return selectedGroupId !== defaultGroupId
  }

  handleAddGroupDashboardButtonClick = () => {
    this.setState({
      openAddGroupDashboardModal: true
    })
  }

  handleCloseAddGroupDashboardModalClick = () => {
    this.setState({
      openAddGroupDashboardModal: false
    })
  }

  handleGroupDashboardClick = () => {
    const { finishEditDashboard, isEditing, saveAndFinishEditingDashboard } = this.props

    if (isEditing) {
      finishEditDashboard()
    } else {
      saveAndFinishEditingDashboard()
    }
  }

  renderGroupDashboards = () => {
    const {
      appFontColor,
      classes,
      groupDashboards,
      isCollapsed,
      selectedGroup: { name: selectedGroupName }
    } = this.props
    const { loading } = this.state

    if (!loading && selectedGroupName) {
      return groupDashboards.map(dashboard => (
        <SidebarItem
          key={dashboard.uuid}
          color={appFontColor}
          href={'/dashboards/' + dashboard.uuid}
          icon={<Icon className='zmdi zmdi-view-dashboard' classes={{ root: classes.icon }} />}
          isCollapsed={isCollapsed}
          label={dashboard.name}
          name={dashboard.uuid}
          onClick={this.handleGroupDashboardClick}
        />
      ))
    } else {
      return (
        <div className='text-center' style={{ paddingTop: 10 }}>
          <CircularProgress size={20} style={{ color: appFontColor }} />
        </div>
      )
    }
  }

  render() {
    const { canCreateGroupDashboards, classes, isCollapsed, selectedGroup, appFontColor } = this.props
    const { openAddGroupDashboardModal } = this.state

    const canShowDashboards = this.getCanShowDashboards()

    return canShowDashboards ? (
      <React.Fragment>
        <Divider className={classes.divider} variant='middle' />
        <Typography
          className={classes.groupDashboardsSubtitle}
          style={{
            textAlign: isCollapsed ? 'center' : 'left'
          }}
          variant='caption'
        >
          {isCollapsed
            ? this.formatMessage(messages.groupDashboardsAcronym)
            : this.formatMessage(messages.groupDashboards)}
        </Typography>
        {this.renderGroupDashboards()}
        {canCreateGroupDashboards && (
          <SidebarItem
            color={appFontColor}
            href='#'
            icon={<Icon className='zmdi zmdi-plus' classes={{ root: classes.icon }} />}
            isCollapsed={isCollapsed}
            label={this.formatMessage(messages.addNewDashboard)}
            onClick={this.handleAddGroupDashboardButtonClick}
          />
        )}
        {canCreateGroupDashboards && openAddGroupDashboardModal && (
          <div>
            <AddGroupDashboard
              closeAddGroupDashboardModal={this.handleCloseAddGroupDashboardModalClick}
              selectedGroup={selectedGroup}
            />
          </div>
        )}
      </React.Fragment>
    ) : null
  }
}

GroupDashboards.propTypes = {
  appFontColor: PropTypes.string.isRequired,
  canCreateGroupDashboards: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  finishEditDashboard: PropTypes.func.isRequired,
  groupDashboards: PropTypes.array.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  loadingGroup: PropTypes.bool.isRequired,
  saveAndFinishEditingDashboard: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  storeGroupDashboards: PropTypes.func.isRequired,
  userGroups: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(GroupDashboards))
