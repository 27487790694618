import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import reactBootstrapTable from 'utils/reactBootstrapTable'

import messages from './messages'

export function getTableOptions({
  intl,
  isLoading,
  page,
  pageSize,
  filter,
  sort = {},
  onPageChange = () => {},
  onFilterChange = () => {},
  onSortChange = () => {}
}) {
  return {
    noDataText: isLoading ? <CircularProgress /> : intl.formatMessage(messages.noDataToDisplay),

    sizePerPageList: reactBootstrapTable.elementsPerPage,
    sizePerPage: pageSize,
    page,
    filter,
    ...sort,

    onPageChange,
    ignoreSinglePage: false,
    pageStartIndex: 1,
    paginationSize: 5,
    prePage: intl.formatMessage(messages.prePage),
    nextPage: intl.formatMessage(messages.nextPage),
    firstPage: intl.formatMessage(messages.firstPage),
    lastPage: intl.formatMessage(messages.lastPage),
    paginationShowsTotal: (start, end, total) => intl.formatMessage(messages.showingRows, { start, end, total }),
    paginationPosition: 'bottom',
    hideSizePerPage: false,
    alwaysShowAllBtns: false,
    withFirstAndLast: true,

    onFilterChange,
    onSortChange
  }
}
