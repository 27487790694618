import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'

import Paper from '@material-ui/core/Paper'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { client, logError } from 'utils/http'

import messages from './messages'

const styles = () => ({
  autocomplete: {
    margin: '15px 0px'
  },
  radioGroup: {
    gap: '35%'
  }
})

class AdvancedSignalsChartSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    this.state = {
      loadingAdvancedSignals: false
    }
  }

  componentDidMount() {
    const { advancedSignals } = this.props
    if (advancedSignals.length === 0) {
      this.getAdvancedSignals()
    }
  }

  getAdvancedSignals = () => {
    const { groupId, eid, updateDeviceInfo } = this.props
    this.setState(
      {
        loadingAdvancedSignals: true
      },
      () => {
        client
          .getMeasurements(groupId, [eid])
          .then(response => {
            updateDeviceInfo({ advancedSignals: response.data })
          })
          .catch(error => {
            logError(error)
          })
          .finally(() => {
            this.setState({
              loadingAdvancedSignals: false
            })
          })
      }
    )
  }

  handleChange = name => event => {
    const { onSettingsChange } = this.props
    onSettingsChange({
      [name]: event.target.value,
      [name + 'ErrorText']: ''
    })
  }

  handleAdvancedSignalsChange = (event, updatedAdvancedSignals) => {
    const { onSettingsChange, widgetAdvancedSignals: currentAdvancedSignals } = this.props
    const advancedSignalIds = updatedAdvancedSignals.map(({ configHashId }) => configHashId)
    const advancedSignals = [...currentAdvancedSignals]
    if (advancedSignals.length > advancedSignalIds.length) {
      const removedSignal = advancedSignals.find(signal => advancedSignalIds.indexOf(signal) === -1)
      const removedSignalIndex = advancedSignals.indexOf(removedSignal)
      advancedSignals.splice(removedSignalIndex, 1)
    } else if (advancedSignals.length < advancedSignalIds.length) {
      const addedSignal = advancedSignalIds.find(signal => advancedSignals.indexOf(signal) === -1)
      const addedSignalIndex = advancedSignalIds.indexOf(addedSignal)
      advancedSignals.splice(addedSignalIndex, 0, addedSignal)
    }
    onSettingsChange({ widgetAdvancedSignals: advancedSignals })
  }

  render() {
    const { classes, widgetAdvancedSignals, selectedTimeRangeErrorText, selectedTimeRange, advancedSignals } =
      this.props
    const { loadingAdvancedSignals } = this.state

    const timeRangeLabels = ['last day', 'last three days', 'last week', 'last two weeks', 'last month']
    const noDinamicDataMessageKey = loadingAdvancedSignals ? 'loadingConfiguratedSignals' : 'noAdvancedSignals'

    return (
      <div>
        <Paper style={{ borderRadius: 0, padding: 20 }}>
          {advancedSignals.length > -1 ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  classes={{ root: classes.autocomplete }}
                  fullWidth
                  getOptionLabel={option => option.measurementName}
                  getOptionSelected={(option, value) => option.configHashId === value.configHashId}
                  id='advanced-signals'
                  multiple
                  onChange={this.handleAdvancedSignalsChange}
                  options={advancedSignals}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={advancedSignals.filter(signal => widgetAdvancedSignals.includes(signal.configHashId))}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl error={selectedTimeRangeErrorText !== ''} fullWidth>
                  <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                    {this.formatMessage(messages.defaultTimeRange)}
                  </InputLabel>
                  <Select
                    displayEmpty
                    input={<Input id='selectedTimeRange-label-placeholder' name='selectedTimeRange' />}
                    name='selectedTimeRange'
                    onChange={this.handleChange('selectedTimeRange')}
                    value={selectedTimeRange}
                  >
                    {timeRangeLabels.map((label, index) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>{selectedTimeRangeErrorText}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          ) : loadingAdvancedSignals ? (
            <Grid container justify='center'>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DialogContentText id='alert-dialog-slide-description'>
                  <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                    {this.formatMessage(messages[noDinamicDataMessageKey])}
                  </span>
                </DialogContentText>
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
    )
  }
}

AdvancedSignalsChartSettings.propTypes = {
  advancedSignals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  selectedTimeRange: PropTypes.number.isRequired,
  selectedTimeRangeErrorText: PropTypes.string.isRequired,
  updateDeviceInfo: PropTypes.func.isRequired,
  widgetAdvancedSignals: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(AdvancedSignalsChartSettings))
