/* eslint-disable import/no-anonymous-default-export */
export default [
  '',
  'km/h',
  'km',
  'kPa',
  'l',
  'kg',
  'ºC',
  'l/h',
  '%',
  'A',
  'kA',
  'V',
  'kV',
  'Ah',
  'VA',
  'W',
  'kW',
  'Wh',
  'kWh',
  'rpm',
  'ms',
  's',
  'min',
  'h',
  'N',
  'kN',
  'Nm',
  'Hz',
  'dB',
  'dBm',
  'kg/s',
  'kJ/l',
  'g/min'
]
/* eslint-enable */
