import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils as theme } from 'ducks/theme'
import { LoginRouteHandler } from './LoginRouteHandler'

const mapStateToProps = state => ({
  loginPath: theme.getLoginPath(state)
})

export default withRouter(connect(mapStateToProps)(LoginRouteHandler))
