import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'

import SvgIcon from 'material-ui/SvgIcon'

import GroupDashboards from './GroupDashboards'
import SidebarItem from './SidebarItem'
import messages from './messages'

import './scrollbar.css'

const styles = () => ({
  bottomSideMenu: {
    bottom: 0,
    padding: 0,
    position: 'absolute',
    width: '100%'
  },
  divider: {
    backgroundColor: props => props.appFontColor,
    opacity: '90%',
    marginTop: '10px'
  },
  icon: {
    textAlign: 'center',
    fontSize: '22px',
    opacity: '90%',
    color: props => props.appFontColor,
    fill: props => props.appFontColor,
    '&:hover': {
      opacity: '100%'
    }
  },
  iconDeciveManagement: {
    textAlign: 'left',
    fontSize: '22px',
    opacity: '90%',
    color: props => props.appFontColor,
    '&:hover': {
      opacity: '100%'
    },
    width: '1.2em'
  }
})

const Sidebar = props => {
  const {
    actionsStateUrl,
    canReadCharts,
    canReadConfigurations,
    canReadGeofenceOrGeotracking,
    canReadGeotracking,
    canReadNotifications,
    canReadReports,
    canViewActions,
    canViewGroupDashboards,
    canViewMap,
    canViewNodes,
    chartsUrl,
    classes,
    configurationsUrl,
    cs500ConfigurationsUrl,
    devicesUrl,
    geofencesUrl,
    geotrackingUrl,
    intl: { formatMessage },
    isCollapsed,
    location: { pathname },
    maintenancesUrl,
    mapUrl,
    machinesUrl,
    reportsUrl,
    toggleCollapse,
    appFontColor,
    sideBarColor
  } = props

  return (
    <aside className='nav-container nav-fixed nav-vertical bg-sidebar-colors' id='nav-container'>
      <div className='nav-wrapper' style={{ backgroundColor: sideBarColor }}>
        <div className='slimScrollDiv sidebar'>
          <div className='customScrollbar'>
            <List>
              {canViewNodes && (
                <SidebarItem
                  color={appFontColor}
                  href={machinesUrl}
                  icon={
                    <SvgIcon viewBox='0 0 25 18' xmlns='http://www.w3.org/2000/svg'>
                      <g className={classes.icon}>
                        <path d='M5.40995 13.8C3.63995 13.8 2.19995 15.25 2.19995 17.02C2.19995 18.79 3.63995 20.24 5.40995 20.24C7.17995 20.24 8.62995 18.8 8.62995 17.02C8.62995 15.25 7.18995 13.8 5.40995 13.8ZM5.40995 18.34C4.68995 18.34 4.09995 17.75 4.09995 17.02C4.09995 16.29 4.68995 15.7 5.40995 15.7C6.12995 15.7 6.72995 16.29 6.72995 17.02C6.72995 17.75 6.14995 18.34 5.40995 18.34Z' />
                        <path d='M14.71 13.8C12.94 13.8 11.5 15.25 11.5 17.02C11.5 18.79 12.94 20.24 14.71 20.24C16.48 20.24 17.93 18.8 17.93 17.02C17.93 15.25 16.49 13.8 14.71 13.8ZM14.71 18.34C13.99 18.34 13.4 17.75 13.4 17.02C13.4 16.29 13.99 15.7 14.71 15.7C15.43 15.7 16.03 16.29 16.03 17.02C16.03 17.75 15.45 18.34 14.71 18.34Z' />
                        <path d='M24.42 5.04995C24.32 4.91995 24.17 4.83995 24.01 4.81995C23.85 4.79995 23.69 4.83995 23.56 4.94995L23.32 5.13995L19.48 0.229951C19.38 0.0999514 19.23 0.0199514 19.07 -4.86462e-05C18.9 -0.0200486 18.75 0.0299513 18.62 0.129951C17.37 1.10995 16.87 2.68995 17.18 4.14995L16.51 4.52995L14.68 5.60995C14.06 5.97995 13.61 6.59995 13.45 7.29995L13.09 8.95995L12.8 9.77995C12.77 9.42995 12.7 9.07995 12.57 8.75995L11.27 5.41995C10.98 4.67995 10.28 4.19995 9.48999 4.19995H4.41999C4.30999 4.19995 4.21999 4.28995 4.21999 4.39995V5.19995V8.93995H1.06999C0.95999 8.93995 0.86999 9.02995 0.86999 9.13995V14.31C0.86999 14.31 0.86999 14.33 0.82999 14.37C0.78999 14.43 0.72999 14.49 0.65999 14.56C0.40999 14.83 -0.0100098 15.28 -0.0100098 16.02C-0.0100098 17.05 0.96999 17.8899 1.99999 17.8899C1.98999 17.7599 1.87999 16.97 1.87999 16.83C1.85999 15.07 3.44999 13.39 5.42999 13.38C7.52999 13.37 8.98999 14.83 8.95999 17.5C8.95999 17.63 8.93999 17.7599 8.92999 17.8899H11.09C11.08 17.7599 11.06 17.63 11.06 17.5C11.06 15.23 12.39 13.38 14.66 13.38C16.93 13.38 18.28 15.24 18.28 17.5C18.28 17.63 18.26 17.75 18.25 17.87C19.04 17.73 19.61 17.05 19.61 16.25V14.67C19.61 11.46 18.44 11.16 16.43 10.52L16.48 10.08L16.6 8.40995C16.63 8.01995 16.82 7.64995 17.14 7.40995L17.91 6.81995L18.58 6.41995C19.31 7.00995 20.21 7.30995 21.12 7.30995C21.99 7.30995 22.86 7.02995 23.59 6.45995L24.32 5.88995C24.45 5.78995 24.53 5.63995 24.55 5.47995C24.57 5.31995 24.52 5.15995 24.42 5.02995V5.04995ZM10.99 10.6H6.04999V6.13995C6.04999 6.13995 6.08999 6.04995 6.13999 6.04995H9.47999C9.47999 6.04995 9.54999 6.06995 9.55999 6.10995L10.86 9.43995C10.95 9.66995 10.99 9.90995 10.99 10.15V10.6Z' />
                      </g>
                    </SvgIcon>
                  }
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === machinesUrl}
                  label={formatMessage(messages.machines)}
                  name='machines'
                  style
                />
              )}
              {canViewMap && (
                <SidebarItem
                  color={appFontColor}
                  href={mapUrl}
                  icon={<Icon className='zmdi zmdi-pin' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === mapUrl}
                  label={formatMessage(messages.map)}
                  name='map'
                />
              )}

              {canReadGeofenceOrGeotracking && (
                <SidebarItem
                  color={appFontColor}
                  href={canReadGeotracking ? geotrackingUrl : geofencesUrl}
                  icon={<Icon className='fas fa-compass' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === geotrackingUrl || pathname === geofencesUrl}
                  label={formatMessage(messages.gpstracking)}
                  name='geotracking'
                />
              )}
              {canReadReports && (
                <SidebarItem
                  color={appFontColor}
                  href={reportsUrl}
                  icon={<Icon className='zmdi zmdi-file-text' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === reportsUrl}
                  label={formatMessage(messages.reports)}
                  name='reports'
                />
              )}
              {canReadCharts && (
                <SidebarItem
                  color={appFontColor}
                  href={chartsUrl}
                  icon={<Icon className='zmdi zmdi-chart' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === chartsUrl}
                  label={formatMessage(messages.charts)}
                  name='charts'
                />
              )}
              {canViewActions && (
                <SidebarItem
                  color={appFontColor}
                  href={actionsStateUrl}
                  icon={<Icon className='fas fa-spinner' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === actionsStateUrl}
                  label={formatMessage(messages.actionsState)}
                  name='events history'
                />
              )}
              {canReadConfigurations && (
                <SidebarItem
                  color={appFontColor}
                  href={configurationsUrl}
                  icon={<Icon className='fas fa-cog' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === configurationsUrl || pathname === cs500ConfigurationsUrl}
                  label={formatMessage(messages.configurations)}
                  name='configurations'
                />
              )}
              {canViewNodes && (
                <SidebarItem
                  color={appFontColor}
                  href={devicesUrl}
                  icon={<Icon className='fas fa-toggle-on' classes={{ root: classes.iconDeciveManagement }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === devicesUrl}
                  label={formatMessage(messages.devices)}
                  name='device management'
                />
              )}

              {process.env.REACT_APP_MAINTENANCES_ENABLED && canReadNotifications && (
                <SidebarItem
                  color={appFontColor}
                  href={maintenancesUrl}
                  icon={<Icon className='zmdi zmdi-wrench' classes={{ root: classes.icon }} />}
                  isCollapsed={isCollapsed}
                  isCurrentPath={pathname === maintenancesUrl}
                  label={formatMessage(messages.maintenances)}
                  name='maintenances'
                />
              )}

              {canViewGroupDashboards && <GroupDashboards isCollapsed={isCollapsed} />}
            </List>
          </div>
        </div>
        <div
          className={classes.bottomSideMenu}
          style={{
            textAlign: isCollapsed ? 'center' : 'right'
          }}
        >
          <Divider className={classes.divider} variant='middle' />
          <IconButton onClick={toggleCollapse}>
            <Icon
              className={isCollapsed ? 'fas fa-angle-double-right' : 'fas fa-angle-double-left'}
              classes={{ root: classes.icon }}
              style={{ ...isCollapsed ? { margin: 0 } : {} }}
            />
          </IconButton>
        </div>
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  actionsStateUrl: PropTypes.string.isRequired,
  appFontColor: PropTypes.string.isRequired,
  canReadCharts: PropTypes.bool.isRequired,
  canReadConfigurations: PropTypes.bool.isRequired,
  canReadGeofenceOrGeotracking: PropTypes.bool.isRequired,
  canReadGeotracking: PropTypes.bool.isRequired,
  canReadNotifications: PropTypes.bool.isRequired,
  canReadReports: PropTypes.bool.isRequired,
  canViewActions: PropTypes.bool.isRequired,
  canViewGroupDashboards: PropTypes.bool.isRequired,
  canViewMap: PropTypes.bool.isRequired,
  canViewNodes: PropTypes.bool.isRequired,
  chartsUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  cs500ConfigurationsUrl: PropTypes.string.isRequired,
  devicesUrl: PropTypes.string.isRequired,
  geofencesUrl: PropTypes.string.isRequired,
  geotrackingUrl: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  machinesUrl: PropTypes.string.isRequired,
  maintenancesUrl: PropTypes.string.isRequired,
  mapUrl: PropTypes.string.isRequired,

  reportsUrl: PropTypes.string.isRequired,
  sideBarColor: PropTypes.string.isRequired,
  toggleCollapse: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(Sidebar))
