import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Highcharts from 'highcharts'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'

import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'

import { HistoricChart } from 'components/Chart'
import Loading from 'components/Loading'
import Card from 'components/UnboxedCard'

import config from './chartConfig.json'
import messages from './messages'

class EditReportChartArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      maxRequestDate: Math.floor(moment().valueOf() / 1000 / 60) * 1000 * 60,
      minRequestDate: Math.floor(moment().subtract(7, 'days').valueOf() / 1000 / 60) * 1000 * 60,
      maxResponseDate: 0,
      minResponseDate: 0,
      prevRequestTimestamps: []
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { colors, numberOfPages, selectedPage, chartData, isSidebarCollapsed } = this.props
    const { maxRequestDate, minRequestDate } = this.state

    if (
      !_isEqual(nextProps.colors, colors) ||
      !_isEqual(nextProps.chartData, chartData) ||
      nextProps.numberOfPages !== numberOfPages ||
      nextProps.selectedPage !== selectedPage ||
      nextProps.isSidebarCollapsed !== isSidebarCollapsed ||
      nextState.maxRequestDate !== maxRequestDate ||
      nextState.minRequestDate !== minRequestDate
    ) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { groupId, isSidebarCollapsed } = this.props

    if (prevProps.isSidebarCollapsed !== isSidebarCollapsed) {
      setTimeout(() => {
        this.forceUpdate()
      }, 500)
    }
    if (groupId && prevProps.groupId !== groupId) {
      this.setState({
        maxRequestDate: Math.floor(moment().valueOf() / 1000 / 60) * 1000 * 60,
        minRequestDate: Math.floor(moment().subtract(7, 'days').valueOf() / 1000 / 60) * 1000 * 60,
        maxResponseDate: 0,
        minResponseDate: 0,
        prevRequestTimestamps: []
      })
    }
  }

  createSelectMenuPages = () => {
    const { numberOfPages } = this.props
    const items = Array(numberOfPages)
      .fill(null)
      .map((_, i) => i + 1)
    return items.map((elem, i) => {
      return (
        <MenuItem key={i} value={i}>
          {elem}
        </MenuItem>
      )
    })
  }

  setSearchRange = (min, max) => {
    const { onSearch, setChartQueryType } = this.props
    onSearch(min, max)
    setChartQueryType('FIRST', min, max)
    this.setState({ maxRequestDate: max, minRequestDate: min })
  }

  handleDateTimeMinChange = min => {
    this.setState({ minRequestDate: min })
  }

  handleDateTimeMaxChange = max => {
    this.setState({ maxRequestDate: max })
  }

  handlePreviousHistoricSet = () => {
    const { onSearch, setChartQueryType } = this.props
    const { prevRequestTimestamps } = this.state
    const prevMinRequestTimestamp = prevRequestTimestamps[prevRequestTimestamps.length - 1][0]
    const prevMaxRequestTimestamp = prevRequestTimestamps[prevRequestTimestamps.length - 1][1]
    prevRequestTimestamps.pop()
    this.setState({
      prevRequestTimestamps
    })

    onSearch(prevMinRequestTimestamp, prevMaxRequestTimestamp)
    setChartQueryType('PREV', prevMinRequestTimestamp, prevMaxRequestTimestamp)
  }

  handleNextHistoricSet = () => {
    const { onSearch, chartData, setChartQueryType } = this.props
    const { maxRequestDate } = this.state
    const chartEnd = chartData.chartEnd
    const chartStart = chartData.chartStart

    this.setState(state => ({
      prevRequestTimestamps: [...state.prevRequestTimestamps, [chartStart, chartEnd]]
    }))
    onSearch(chartEnd + 1, maxRequestDate)
    setChartQueryType('NEXT', chartEnd + 1, maxRequestDate)
  }

  handlePageSelectionChanged = event => {
    const { onPageChanged } = this.props
    onPageChanged(event.target.value)
  }

  tooltipFormatter = () => {
    return function () {
      const tooltipPoints = this.points[0].series.chart.series.map(serie => {
        if (serie.visible && serie.navigatorSeries) {
          const { numberOfDecimals } = serie.options
          const timewiseClosestPoint = [...serie.data]
            .sort((pointA, pointB) => (pointA.x > pointB.x ? -1 : 1))
            .find(point => point.x <= this.x)

          if (timewiseClosestPoint) {
            return `<span style="color:${serie.color}">\u25CF</span>${serie.name}:
            <strong>${
              typeof numberOfDecimals !== 'undefined'
                ? Number(timewiseClosestPoint.y).toFixed(numberOfDecimals)
                : timewiseClosestPoint.y
            }</strong>`
          } else {
            return ''
          }
        }
        return ''
      })

      return '<strong>' + moment(this.x).format('LL HH:mm:ss') + '</strong><br />' + tooltipPoints.join('<br/>')
    }
  }

  render() {
    const { chartData, isDevicesLoading, isChartLoading, selectedPage } = this.props
    const { maxRequestDate, minRequestDate, maxResponseDate, minResponseDate, prevRequestTimestamps } = this.state

    const chartEnd = chartData.chartEnd
    const isPrev = prevRequestTimestamps.length > 0
    const isNext = maxRequestDate > chartEnd

    config.tooltip.formatter = this.tooltipFormatter()
    const mergedConfig = Highcharts.merge(config, chartData)

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Card>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <CardHeader style={{ paddingBottom: '0px' }} title={this.formatMessage(messages.chartPreview)} />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth style={{ paddingRight: '18px' }}>
                    <InputLabel style={{ paddingTop: '9px' }}>{this.formatMessage(messages.selectPage)}</InputLabel>
                    <Select
                      onChange={this.handlePageSelectionChanged}
                      style={{ paddingTop: '6px' }}
                      value={selectedPage}
                    >
                      {this.createSelectMenuPages()}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <CardContent style={{ paddingTop: '0px' }}>
                {isDevicesLoading ? (
                  <Loading />
                ) : (
                  <HistoricChart
                    config={mergedConfig}
                    isChartLoading={isChartLoading}
                    maxRequestDate={maxRequestDate}
                    maxResponseDate={maxResponseDate}
                    minRequestDate={minRequestDate}
                    minResponseDate={minResponseDate}
                    nextResponseSet={isNext}
                    onDateTimeMaxChange={this.handleDateTimeMaxChange}
                    onDateTimeMinChange={this.handleDateTimeMinChange}
                    onNextHistoricSet={this.handleNextHistoricSet}
                    onPreviousHistoricSet={this.handlePreviousHistoricSet}
                    previousResponseSet={isPrev}
                    requestHistoricData={i => i}
                    requestStatus=''
                    setSearchRange={this.setSearchRange}
                    title={this.formatMessage(messages.historicalDataQueryPreview)}
                  />
                )}
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

EditReportChartArea.propTypes = {
  chartData: PropTypes.object.isRequired,
  colors: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  isDevicesLoading: PropTypes.bool.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
  setChartQueryType: PropTypes.func.isRequired
}

export default injectIntl(EditReportChartArea)
