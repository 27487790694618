import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import EditNotificationFormGeneralProperties from './EditNotificationFormGeneralProperties'
import EditNotificationFormGeneralPropertiesTitle from './EditNotificationFormGeneralPropertiesTitle'

import * as c from '../constants'
import { adapterNotificationDetail } from '../utils'

import { client, logError } from 'utils/http'

import { injectIntl } from 'react-intl'
import messages from './messages'

class EditNotificationGeneralProperties extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      notificationId: props.notificationId,
      notification: {},
      loading: true,      
      alertMessages: false,
      alertMessagesType: 'danger',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    //Load notification rule from DBB
    client
      .getRuleInstance(this.props.notificationId)
      .then(response => {        
        if (response.data !== undefined) {
          const notificationData = response.data            
          const groupDescription = this.getGroupDescription(notificationData.groupId)
          const conditionVariableName = c.RULE_VARIABLE_CONDITION_NAME
          const originalConditionVariableName = c.RULE_VARIABLE_ORIGINAL_CONDITION_NAME
          const notificationDetail = adapterNotificationDetail(notificationData, groupDescription, conditionVariableName, originalConditionVariableName)
            
          this.setState({
            notification: notificationDetail,
            loading: false      
          })
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorText),
            alertMessagesText: [this.formatMessage(messages.errorLoadingNotification)]
          })
        }                  
      })
      .catch(response => {
        const error = { ...response}
        if (error.response !== undefined && error.response.status !== undefined) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {number: '400'}),
                alertMessagesText: [this.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              let message
              if (this.props.intl.locale === 'en') message = error.response.message;
              else message = this.formatMessage(messages.error401Message);
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {number: '401'}),
                alertMessagesText: [message]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {number: '403'}),
                alertMessagesText: [error.response.data.message]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {number: '404'}),
                alertMessagesText: [this.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {number: '406'}),
                alertMessagesText: [this.formatMessage(messages.error406Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {number: '500'}),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
              })
              logError(response)
          }
        }        
      })    
  }

  getGroupDescription = (groupId) => {
    let groupDescription = ''

    const groupInfo = this.props.getGroup(groupId)
    if (groupInfo.name !== undefined) {
      groupDescription = groupInfo.name
    }

    return groupDescription
  }

  setNotificationTitle = description => {
    if (description) {
      return description
    } else {
      return ''
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    if (this.state.loading) {
      return <Loading />
    } else if (this.state.alertMessages) {
      return (
        <Alert
          alertType={this.state.alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={this.state.alertMessagesText}
          messageTitle={this.state.alertMessagesTitle}
          showAlert={this.state.alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    if (this.state.loading || this.state.alertMessages) {     
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      //cargar paso 1
      return (
        <EditNotificationFormGeneralProperties
          canReadDevices={this.props.canReadDevices}
          editNotificationAssignUsersUrl={this.props.editNotificationAssignUsersUrl}
          editNotificationConditionalSignals={this.props.editNotificationAssignUsersUrl}
          getDevicesDetail={this.props.getDevicesDetail}
          notification={this.state.notification}
          notificationAssignDevicesUrl={this.props.notificationAssignDevicesUrl}
          notificationsUrl={this.props.notificationsUrl}
        />
      )
    }
  }

  render() {
    let notificationName = ''
    if (
      this.state.notification !== undefined &&
      this.state.notification !== {} && this.state.notification.name !== undefined
    ) {
      notificationName = this.state.notification.name
      if (this.state.notification.description !== undefined && this.state.notification.description !== '') {
        notificationName = notificationName + ' (' + this.state.notification.description + ')'
      }
    }
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <EditNotificationFormGeneralPropertiesTitle
              assignUsersUrl={this.props.notificationAssignUsersUrl}
              conditionalSignalsUrl={this.props.notificationConditionalSignalsUrl}
              notificationsUrl={this.props.notificationsUrl}    
              title={this.setNotificationTitle(notificationName)}
            />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

EditNotificationGeneralProperties.propTypes = {
  notificationId: PropTypes.string,
  notificationsUrl: PropTypes.string,
  notificationAssignDevicesUrl: PropTypes.string,  
  editNotificationConditionalSignalsUrl: PropTypes.func.isRequired,
  notificationConditionalSignalsUrl: PropTypes.string,
  editNotificationAssignUsersUrl: PropTypes.func.isRequired,
  notificationAssignUsersUrl: PropTypes.string,
  getDevicesDetail: PropTypes.func.isRequired
}

export default injectIntl(EditNotificationGeneralProperties)