import { defineMessages } from 'react-intl'

const messages = defineMessages({
  softwareVersion: {
    id: 'src.softwareVersion',
    description: 'Software version text',
    defaultMessage: 'Software version:'
  },
  node: {
    id: 'src.node',
    description: 'Node text',
    defaultMessage: 'Node:'
  },
  config: {
    id: 'src.config',
    description: 'Config state',
    defaultMessage: 'CONFIG'
  },
  initializing: {
    id: 'src.initializing',
    description: 'Initializing state',
    defaultMessage: 'INITIALIZING'
  },
  standby: {
    id: 'src.standby',
    description: 'Standby state',
    defaultMessage: 'STANDBY'
  },
  linked: {
    id: 'src.linked',
    description: 'Linked state',
    defaultMessage: 'LINKED'
  },
  unknown: {
    id: 'src.unknown',
    description: 'Unknown state',
    defaultMessage: 'UNKNOWN'
  },
  severity: {
    id: 'src.severity',
    description: 'Severity floating label text',
    defaultMessage: 'Severity'
  },
})

export default messages