import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'AdvancedSignalsConfigDialog.title',
    description: 'Configuration of advanced signals',
    defaultMessage: 'Configuration of advanced signals'
  },
  cancel: {
    id: 'AdvancedSignalsConfigDialog.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'AdvancedSignalsConfigDialog.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  addAdvancedSignal: {
    id: 'AdvancedSignalsConfigDialog.addAdvancedSignal',
    description: 'Add an advanced signal',
    defaultMessage: 'Add an advanced signal'
  },
  canBusSignals: {
    id: 'AdvancedSignalsConfigDialog.canBusSignals',
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  gpsSignals: {
    id: 'AdvancedSignalsConfigDialog.gpsSignals',
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  mfioSignals: {
    id: 'AdvancedSignalsConfigDialog.mfioSignals',
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  thisFieldIsRequired: {
    id: 'AdvancedSignalsConfigDialog.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  exportConfiguration: {
    id: 'AdvancedSignalsConfigDialog.exportConfiguration',
    description: 'Export configuration',
    defaultMessage: 'Export configuration'
  },
  name: {
    id: 'AdvancedSignalsConfigDialog.name',
    description: 'Name',
    defaultMessage: 'Name'
  },
  sourceSignal: {
    id: 'AdvancedSignalsConfigDialog.sourceSignal',
    description: 'Source signal',
    defaultMessage: 'Source signal'
  },
  operator: {
    id: 'AdvancedSignalsConfigDialog.operator',
    description: 'Operator',
    defaultMessage: 'Operator'
  },
  value: {
    id: 'AdvancedSignalsConfigDialog.value',
    description: 'Value',
    defaultMessage: 'Value'
  },
  operationType: {
    id: 'AdvancedSignalsConfigDialog.operationType',
    description: 'Operation type',
    defaultMessage: 'Operation type'
  },
  time: {
    id: 'AdvancedSignalsConfigDialog.time',
    description: 'Time operation type',
    defaultMessage: 'Time'
  },
  transtions: {
    id: 'AdvancedSignalsConfigDialog.transtions',
    description: 'Transitions',
    defaultMessage: 'Transitions'
  },
  points: {
    id: 'AdvancedSignalsConfigDialog.points',
    description: 'Points operation type',
    defaultMessage: 'Points'
  },
  fuel: {
    id: 'AdvancedSignalsConfigDialog.fuel',
    description: 'Fuel operation type',
    defaultMessage: 'Fuel'
  },
  unit: {
    id: 'AdvancedSignalsConfigDialog.unit',
    description: 'Unit',
    defaultMessage: 'Unit'
  },
  raw: {
    id: 'AdvancedSignalsConfigDialog.raw',
    description: 'Raw operation type',
    defaultMessage: 'Raw'
  }
})

export default messages
