import { defineMessages } from 'react-intl'

const messages = defineMessages({
  accessPassInventory: {
    id: 'AccessPassInventory.accessPassInventory',
    description: 'Access Pass Inventory title',
    defaultMessage: 'CS10 - 24h Access Pass Inventory'
  },
  boughtByMe: {
    id: 'AccessPassInventory.boughtByMe',
    description: 'Bought by me',
    defaultMessage: 'Bought by me'
  },
  sharedToThisGroup: {
    id: 'AccessPassInventory.sharedToThisGroup',
    description: 'Shared to this group',
    defaultMessage: 'Shared to this group'
  }
})

export default messages
