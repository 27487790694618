import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils as groupUtils } from 'ducks/groups'
import {
  storeGroupDashboards,
  getGroupDashboards,
  saveAndFinishEditingDashboard,
  finishEditDashboard,
  isEditing
} from 'ducks/groupDashboards'
import { utils as themeUtils } from 'ducks/theme'

import * as c from 'utils/constants'

import GroupDashboards from './GroupDashboards'

const mapStateToProps = state => ({
  canCreateGroupDashboards: groupUtils.hasPermission(state, c.GROUPDASHBOARD_CREATE),
  groupDashboards: getGroupDashboards(state),
  isEditing: isEditing(state),
  loadingGroup: groupUtils.getLoadingGroup(state),
  selectedGroup: groupUtils.getSelectedGroup(state),
  userGroups: groupUtils.getUserGroups(state),
  appFontColor: themeUtils.getFontColor(state)
})

const mapDispatchToProps = dispatch => {
  return {
    finishEditDashboard: () => dispatch(finishEditDashboard()),
    saveAndFinishEditingDashboard: () => dispatch(saveAndFinishEditingDashboard()),
    storeGroupDashboards: dashboards => dispatch(storeGroupDashboards(dashboards))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupDashboards))
