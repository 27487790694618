import * as t from '../actionTypes'
import * as gt from '../../actionTypes'

import initialState from './initialState'
import { selectLevel, getSelectableLevels } from './selectableLevels'

const zoom = (state = initialState, action) => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case t.CLEAR_EXTREMES:
      return selectLevel(state)

    case t.SELECT_ZOOM_LEVEL:
      return selectLevel(state, action.payload)

    case t.PUSH_EXTREME:
      const pushedZoom = [action.payload.min, action.payload.max]
      return {
        
        ...state,
        forcedZoom: pushedZoom,
        history: [...state.history, pushedZoom],
        ...getSelectableLevels(state, pushedZoom)
      }

    case t.POP_EXTREME:
      // Base zoom is not removed
      if (state.history.length === 0) {
        return state
      }

      const reducedHistory = state.history.slice(0, -1)
      const newZoom = reducedHistory[reducedHistory.length - 1]
      return {
        
        ...state,
        forcedZoom: reducedHistory.length > 0 ? newZoom : ['default'],
        history: reducedHistory,
        ...getSelectableLevels(state, newZoom)
      }

    case gt.UPDATE_CHART_CONFIGURATION:
      const maxDifference = (action.payload.max - action.payload.min) / 1000
      const allDisabled = action.payload.min === -1 && action.payload.max === -1
      const dataUpdated = { ...state, maxDate: action.payload.max,
        selectableLevels: state.selectableLevels.map(level =>
          ({ ...level, disabled:
              allDisabled || level.value !== -1 && maxDifference < level.value})
        ) // marks if options are enabled or disabled
      }
      // If the chart has new data, we must update the view window.
      // We do not consider that the chart might have been reduced.
      return selectLevel(dataUpdated, state.selectedLevel)

    default:
      return state
  }
}

export default zoom
export const MOUNTED_AT = 'zoom'

// Selectors
const getForcedZoom = substate => substate[MOUNTED_AT].forcedZoom
// If history is empty, we cannot unzoom
const canUnzoom = substate => substate[MOUNTED_AT].history.length > 0

// For the zoom level dropdown menu
const getZoomLevels = substate => substate[MOUNTED_AT].selectableLevels
const getSelectedZoomLevel = substate => substate[MOUNTED_AT].selectedLevel

export { getForcedZoom, canUnzoom, getZoomLevels, getSelectedZoomLevel }
