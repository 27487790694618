import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { logError } from 'utils/http'

import messages from './messages'
import { mapSignalsToColumns } from '../../utils'

const styles = () => ({
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
})

class LastKnownValueTableSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      devices: [],
      areDevicesLoading: true,
      configurationColumns: [],
      isConfigurationLoading: false
    }
  }

  componentDidMount() {
    this.getConfigurationsAndDevices()
  }

  componentDidUpdate(prevProps) {
    const { configurationId } = this.props
    if (prevProps.configurationId !== configurationId && configurationId) this.getConfigurationColumns()
  }

  getConfigurationsAndDevices = () => {
    const { getDynamicCSNodes, groupId, isGroupWidget, configurationId, onSettingsChange } = this.props
    const filters = {
      filters: {
        models: [
          {
            modelName: 'Device',
            columns: [
              {
                device_type: 'CS100'
              }
            ]
          }
        ]
      }
    }
    const deviceFields = {
      Device: ['eid', 'name'],
      Configuration: ['id', 'name', 'version_number']
    }

    this.setState(
      {
        areDevicesLoading: true
      },
      () => {
        getDynamicCSNodes(groupId, deviceFields, filters)
          .then(response => {
            const responseDevices = response.data.devices
            const devices = responseDevices.filter(device => Boolean(device.Configuration))

            const uniqueConfigurationIds = [...new Set(devices.map(device => device.Configuration.id))]

            this.setState({
              devices
            })
            if (uniqueConfigurationIds.includes(configurationId)) {
              if (isGroupWidget) {
                const eids = devices
                  .filter(device => device.Configuration.id === configurationId)
                  .map(device => device.eid)
                onSettingsChange({ eids })
              }
              this.getConfigurationColumns()
            } else {
              onSettingsChange({
                configurationId: '',
                eids: [],
                columns: [],
                columnsErrorText: '',
                isGroupWidget: false,
                configurationIdErrorText: '',
                eidsErrorText: '',
                allSignalsSelected: false
              })
            }
          })
          .catch(error => {
            logError(error)
          })
          .finally(() => {
            this.setState({
              areDevicesLoading: false
            })
          })
      }
    )
  }

  getConfigurationColumns = () => {
    const { configurationId, getCSNodeByEID, groupId, columns, onSettingsChange } = this.props
    const { devices } = this.state
    const { eid } = devices.find(device => device.Configuration?.id === configurationId)
    this.setState(
      {
        isConfigurationLoading: true
      },
      () => {
        getCSNodeByEID(eid, groupId)
          .then(response => {
            const { deviceConfiguration } = response.data
            const configurationColumns = mapSignalsToColumns(deviceConfiguration)
            this.setState({
              configurationColumns
            })
            const signalColumns = columns.filter(column => ['mfio', 'canBus'].includes(column.signalType))
            const allSignalsSelected =
              configurationColumns.length === signalColumns.length && configurationColumns.length > 0
            onSettingsChange({ allSignalsSelected })
          })
          .catch(error => {
            logError(error)
            this.setState({
              configurationColumns: []
            })
          })
          .finally(() => {
            this.setState({
              isConfigurationLoading: false
            })
          })
      }
    )
  }

  getDeviceConfigurations = () => {
    const { devices } = this.state
    const uniqueConfigurationIds = [...new Set(devices.map(device => device.Configuration.id))]
    const configurations = uniqueConfigurationIds.map(configId => {
      const { Configuration } = devices.find(device => device.Configuration?.id === configId)
      return { ...Configuration }
    })
    return configurations
  }

  handleConfigurationChange = (event, configuration) => {
    const { onSettingsChange } = this.props
    const { id = '' } = configuration || {}
    onSettingsChange({
      configurationId: id,
      eids: [],
      columns: [],
      columnsErrorText: '',
      isGroupWidget: false,
      configurationIdErrorText: '',
      eidsErrorText: '',
      allSignalsSelected: false
    })
  }

  handleDevicesChange = (event, devices) => {
    const { onSettingsChange } = this.props
    const deviceEids = devices.map(device => device.eid)
    onSettingsChange({
      eids: deviceEids,
      isGroupWidget: false,
      eidsErrorText: '',
      ...deviceEids.length === 0 && { columns: [], columnsErrorText: '', allSignalsSelected: false }
    })
  }

  handleIsGroupWidgetChange = event => {
    const { onSettingsChange, configurationId } = this.props
    const { devices } = this.state
    const isGroupWidget = event.target.checked
    const eids = devices.filter(device => device.Configuration.id === configurationId).map(device => device.eid)
    onSettingsChange({
      isGroupWidget,
      ...isGroupWidget && { eids, eidsErrorText: '' }
    })
  }

  handleColumnsChange = (event, newColumns) => {
    const { onSettingsChange, columns } = this.props
    const lessSignalColumns =
      columns.filter(column => Boolean(column.signalType)).length >
      newColumns.filter(column => Boolean(column.signalType)).length
    onSettingsChange({
      columns: newColumns,
      columnsErrorText: '',
      ...lessSignalColumns && { allSignalsSelected: false }
    })
  }

  handleAllSignalsSelected = (event, signalColumns) => {
    const { columns: currentColumns, onSettingsChange } = this.props
    const allSignalsSelected = event.target.checked
    const columns = [...currentColumns]
    if (allSignalsSelected) {
      const currentColumnsIds = currentColumns.map(column => column.id)
      const newColumns = signalColumns.filter(column => !currentColumnsIds.includes(column.id))
      columns.push(...newColumns)
    }
    onSettingsChange({
      columns,
      columnsErrorText: '',
      allSignalsSelected
    })
  }

  render() {
    const { areDevicesLoading, devices, configurationColumns, isConfigurationLoading } = this.state
    const {
      configurationId,
      configurationIdErrorText,
      eids,
      eidsErrorText,
      columns,
      columnsErrorText,
      isGroupWidget,
      allSignalsSelected,
      classes
    } = this.props
    const configurations = this.getDeviceConfigurations()
    const filteredDevices = devices.filter(device => device.Configuration.id === configurationId)
    const typedColumns = configurationColumns.map(signal => ({
      ...signal,
      columnType: this.formatMessage(messages[signal.signalType + 'Signals'])
    }))
    const deviceColumns = [
      { id: 'name', name: this.formatMessage(messages.name), columnType: this.formatMessage(messages.machineFields) },
      { id: 'eid', name: this.formatMessage(messages.eid), columnType: this.formatMessage(messages.machineFields) }
    ]
    const columnsOptions = [...typedColumns]
    if (!isConfigurationLoading) columnsOptions.splice(0, 0, ...deviceColumns)

    return areDevicesLoading ? (
      <Grid container justify='center'>
        <CircularProgress />
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Autocomplete
            fullWidth
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            id='configuration'
            onChange={this.handleConfigurationChange}
            options={configurations}
            renderInput={params => (
              <TextField
                {...params}
                error={configurationIdErrorText !== ''}
                helperText={configurationIdErrorText}
                label={this.formatMessage(messages.configurations)}
              />
            )}
            value={configurations.find(config => config.id === configurationId) || null}
          />
        </Grid>
        <Grid item md={4} xs={8}>
          {isGroupWidget ? (
            <TextField
              disabled
              fullWidth
              helperText={this.formatMessage(messages.machinesHelperText)}
              label={this.formatMessage(messages.machines)}
              value={this.formatMessage(messages.allMachinesSelected)}
            />
          ) : (
            <Autocomplete
              disabled={configurationId === ''}
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.eid === value.eid}
              id='devices'
              multiple
              onChange={this.handleDevicesChange}
              options={filteredDevices}
              renderInput={params => (
                <TextField
                  {...params}
                  error={eidsErrorText !== ''}
                  helperText={eidsErrorText}
                  label={this.formatMessage(messages.machines)}
                />
              )}
              value={filteredDevices.filter(device => eids.includes(device.eid))}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGroupWidget}
                classes={{ root: classes.checkbox, checked: classes.checked }}
                disabled={configurationId === ''}
                onChange={this.handleIsGroupWidgetChange}
              />
            }
            label={this.formatMessage(messages.allMachines)}
          />
        </Grid>
        <Grid item md={4} xs={8}>
          <Autocomplete
            classes={{ groupLabel: classes.groupLabel }}
            disabled={eids.length === 0}
            fullWidth
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            groupBy={option => option.columnType}
            id='columns'
            loading={isConfigurationLoading}
            loadingText={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={24} />
              </div>
            }
            multiple
            onChange={this.handleColumnsChange}
            options={columnsOptions}
            renderInput={params => (
              <TextField
                {...params}
                error={columnsErrorText !== ''}
                helperText={columnsErrorText}
                label={this.formatMessage(messages.columns)}
              />
            )}
            value={columns}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSignalsSelected}
                classes={{ root: classes.checkbox, checked: classes.checked }}
                disabled={eids.length === 0}
                onChange={e => this.handleAllSignalsSelected(e, typedColumns)}
              />
            }
            label={this.formatMessage(messages.allSignals)}
          />
        </Grid>
      </Grid>
    )
  }
}

LastKnownValueTableSettings.propTypes = {
  allSignalsSelected: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnsErrorText: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
  configurationIdErrorText: PropTypes.string.isRequired,
  eids: PropTypes.array.isRequired,
  eidsErrorText: PropTypes.string.isRequired,
  getCSNodeByEID: PropTypes.func.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isGroupWidget: PropTypes.bool.isRequired,
  onSettingsChange: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(LastKnownValueTableSettings))
