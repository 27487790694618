import * as R from 'ramda'
import moment from 'moment'

import * as ReportConstants from '../../constants'
import * as options from './options'
import { validationSchema } from './schemas'
import translations from 'i18n/locales'
import tz from 'utils/timezones'

export { options, validationSchema }

export const createMetric = (args = {}) => {
  const {
    asset = null,
    selectedSensorsIds = [],
    sensors = [],
    pageType = ReportConstants.PAGE_TYPES.CHART_PAGE_TYPE,
    tableType = ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
  } = args
  return { asset, selectedSensorsIds, sensors, pageType, tableType }
}

export const getInitialValues = ({ email }) => {
  const bucketSize = options.bucketSize()
  const frequency = options.frequency()
  const status = options.status()

  return {
    configuration: {
      bucketSize: bucketSize[1].value,
      frequency: frequency[1].value,
      initialDate: new Date(),
      name: '',
      status: status[0].value,
      timezone: tz[0].value
    },
    pages: [[createMetric()]],
    user: email
  }
}

export const getGpsSignals = (device, pageType = ReportConstants.PAGE_TYPES.CHART_PAGE_TYPE) => {
  const i18n = translations[localStorage.getItem('user_language')]

  const gpsSignals = ReportConstants.GPS_SENSORS.filter(item =>
    ReportConstants.GPS_SENSOR_IDS_PER_PAGE_TYPE[pageType].includes(item.signalId)
  ).map(item => {
    return {
      ...item,
      isGPS: true,
      deviceType: device.deviceType,
      signalType: i18n['NewReport.gpsSignals']
    }
  })

  return Object.values(device?.deviceConfiguration?.gps).some(gpsLogtimeValue => gpsLogtimeValue > 0) ? gpsSignals : []
}

export const getDm1Signals = (device, pageType = ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE) => {
  const i18n = translations[localStorage.getItem('user_language')]

  const dm1Signals = ReportConstants.DM1_SENSORS.filter(item =>
    ReportConstants.DM1_SENSOR_IDS_PER_PAGE_TYPE[pageType].includes(item.signalId)
  ).map(item => {
    return {
      ...item,
      isDm1: true,
      signalType: i18n['NewReport.dm1Signals']
    }
  })

  return dm1Signals
}

export const transformValuesForAPI = (values, assetsById, user) => {
  const uniqueAssets = R.pipe(
    R.prop('metrics'),
    R.pluck('asset'),
    R.uniq
  )(values).filter(asset => asset && asset !== ReportConstants.ALL_DEVICES_IN_GROUP)

  return {
    bucketSize: R.path(['bucketSize'], values),
    contact: R.pipe(
      R.split(','),
      R.map(R.trim),
      R.filter(email => email.length > 0)
    )(values.user),
    frequency: R.path(['frequency'], values),
    initialDate: moment(R.path(['initialDate'], values))
      .utc()
      .format(),
    devices: R.pipe(
      R.map(id => [id, assetsById[id].name]),
      R.fromPairs
    )(uniqueAssets),
    EIDS: R.pipe(
      R.map(id => [id, assetsById[id].EID]),
      R.fromPairs
    )(uniqueAssets),
    outputType: 'email',
    name: R.path(['name'], values),
    timezone: R.path(['timezone'], values),
    running: R.path(['status'], values),
    userId: user,
    reportMeta: values.metrics.reduce((ret, metric) => {
      if (!Object.keys(ret).includes(metric.asset)) {
        ret[metric.asset] = [] // eslint-disable-line no-param-reassign
      }
      // eslint-disable-next-line no-param-reassign
      ret[metric.asset] = ret[metric.asset].concat(
        metric.sensors.map(sensor => {
          return {
            page: metric.page,
            pageType: metric.pageType,
            tableType: metric.tableType,
            sensor: sensor.name,
            signalId: sensor.signalId,
            unit: sensor.unit,
            multiplier: sensor.multiplier,
            offset: sensor.offset,
            signed: sensor.signed,
            divider: sensor.divider,
            lengthOfBits: sensor.lengthOfBits,
            aggregationFunction: sensor.aggregationType,
            lineStyle: sensor.lineStyle,
            lineColor: sensor.lineColor,
            display: {
              table: sensor.display.table,
              chart: sensor.display.chart
            }
          }
        })
      )
      return ret
    }, {})
  }
}

export const transformValuesFromAPI = response => {
  const reportMeta = response.reportMeta
  const metricsByPage = Object.keys(reportMeta).reduce((ret, key) => {
    reportMeta[key].forEach(signal => {
      if (!signal.page) {
        signal.page = 0 // eslint-disable-line no-param-reassign
      }
      if (ret.length < signal.page + 1) {
        // eslint-disable-next-line no-param-reassign
        ret = ret.concat(
          Array(signal.page + 1 - ret.length)
            .fill()
            .map(item => ({}))
        )
      }
      if (!Object.keys(ret[signal.page]).includes(key)) {
        ret[signal.page][key] = [] // eslint-disable-line no-param-reassign
      }
      ret[signal.page][key].push(signal)
    })
    return ret
  }, [])

  return {
    bucketSize: response.bucketSize,
    frequency: response.frequency,
    initialDate: new Date(response.initialDate),
    name: response.name,
    status: response.running,
    timezone: response.timezone,
    user: response.contact.join(', '),
    pages: R.map(page =>
      R.pipe(
        R.toPairs,
        R.map(([asset, sensors]) => ({
          asset,
          pageType: sensors[0]?.pageType,
          tableType: sensors[0]?.tableType,
          selectedSensorsIds: R.pipe(
            R.pluck('signalId'),
            R.uniq,
            R.map(sensor => sensor)
          )(sensors),
          sensors: R.map(sensor => ({
            name: sensor.sensor,
            signalId: sensor.signalId,
            unit: sensor.unit,
            multiplier: sensor.multiplier,
            offset: sensor.offset,
            signed: sensor.signed,
            divider: sensor.divider,
            lengthOfBits: sensor.lengthOfBits,
            aggregationType: sensor.aggregationFunction,
            lineStyle: sensor.lineStyle,
            lineColor: sensor.lineColor,
            display: {
              table: sensor.display.table,
              chart: sensor.display.chart
            }
          }))(sensors)
        }))
      )(page)
    )(metricsByPage)
  }
}
