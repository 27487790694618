import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from '@material-ui/core/SvgIcon'

import { Marker } from 'components/Map'

const GenericMarker = ({ color, ...markerProps }) => (
  <Marker {...markerProps}>
    <SvgIcon>
      <circle
        r="3"
        cy="12"
        cx="12"
        fill="#EEE"
        style={{
          stroke: color,
          strokeWidth: 2,
          strokeOpacity: 1
        }}
      />
    </SvgIcon>
  </Marker>
)

GenericMarker.propTypes = {
  color: PropTypes.string.isRequired
}

export default GenericMarker
