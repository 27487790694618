import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Paper from '@material-ui/core/Paper'

import { MuiTreeList } from 'components/react-treeview-mui'
import { rolesHeaderToListitems, processRolesHierarchyToListitems } from '../utils'

import './Roles.css'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { withRouter } from 'react-router-dom'

class RolesGrid extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    let alertMessages = false
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    switch (props.urlAction) {
      case 'update':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.roleUpdatedSuccessfully)]
        break
      case 'create':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.roleCreatedSuccessfully)]
        break
      case 'delete':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.roleDeletedSuccessfully)]
        break
      default:
        alertMessages = false
        alertMessagesType = ''
        alertMessagesTitle = ''
        alertMessagesText = ['']
    }

    this.state = {
      alertMessages,
      alertMessagesType,
      alertMessagesTitle,
      alertMessagesText
    }
  }

  componentDidUpdate(prevProps) {
    const { location, history, getRoles } = this.props
    if (prevProps.location.state !== location.state && location.state === 'delete') {
      this.setState(
        {
          alertMessages: true,
          alertMessagesType: 'success',
          alertMessagesTitle: '',
          alertMessagesText: [this.formatMessage(messages.roleDeletedSuccessfully)]
        },
        () => {
          history.replace(location.pathname, undefined)
          getRoles()
        }
      )
    }
  }

  showAlert = () => {
    const { alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } = this.state

    if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  render() {
    const { userRole, rolesHierarchy, onDeleteRole } = this.props

    return (
      <div className='container-fluid roles' style={{ margin: '20px 0 0 0' }}>
        {this.showAlert()}
        <Paper style={{ borderRadius: 0 }}>
          <div className='rolesList'>
            <MuiTreeList
              activeListItem={-1}
              contentKey='title'
              haveSearchbar={false}
              listItems={rolesHeaderToListitems(this.formatMessage)}
            />
            <MuiTreeList
              activeListItem={-1}
              contentKey='title'
              haveSearchbar={false}
              listItems={processRolesHierarchyToListitems(userRole, rolesHierarchy, onDeleteRole)}
            />
          </div>
        </Paper>
      </div>
    )
  }
}

RolesGrid.propTypes = {
  getRoles: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onDeleteRole: PropTypes.func.isRequired,
  rolesHierarchy: PropTypes.array.isRequired,
  urlAction: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired
}

export default withRouter(injectIntl(RolesGrid))
