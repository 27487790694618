import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ManageAccessPassConsumption from './ManageAccessPassConsumption'
import ProductionTestMode from './ProductionTestMode'
import messages from './messages'
import { getDeviceEditUrl, getDevicesUrl } from '../../urls'

class Actions extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      anchorEl: null,
      ptmDialogOpened: false,
      manageConsumptionDialogOpened: false
    }
  }

  handleActionsButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleActionClick = dialogName => {
    this.setState({
      [dialogName]: true,
      anchorEl: null
    })
  }

  handleActionDialogClose = dialogName => {
    this.setState({ [dialogName]: false })
  }

  render() {
    const {
      canEditDevice,
      canUseProductionTestMode,
      canConsumeAccessPasses,
      device: { device_type, id, name, simStatus, allow_consumption },
      updateDevices
    } = this.props
    const { anchorEl, ptmDialogOpened, manageConsumptionDialogOpened } = this.state

    const canPTMOptionBeVisible = canUseProductionTestMode && (simStatus === 'T' || simStatus === 'X')
    const PTMTitleKey = simStatus === 'T' ? 'endTitle' : 'startTitle'
    const canManageConsumptionBeVisible = device_type === 'CS10' && canConsumeAccessPasses
    const manageConsumptionKey = allow_consumption ? 'disallowAccessPassConsumption' : 'allowAccessPassConsumption'

    return (
      <React.Fragment>
        <div>
          <Button onClick={this.handleActionsButtonClick}>
            <Icon className='zmdi zmdi-more-vert' />
          </Button>
          <Menu anchorEl={anchorEl} onClose={this.handleClose} open={Boolean(anchorEl)}>
            {canEditDevice && (
              <Link className='button-link' to={{ pathname: getDeviceEditUrl(id), state: { from: getDevicesUrl() } }}>
                <MenuItem>{this.formatMessage(messages.editDevice)}</MenuItem>
              </Link>
            )}
            {canPTMOptionBeVisible && (
              <MenuItem
                onClick={() => {
                  this.handleActionClick('ptmDialogOpened')
                }}
              >
                {this.formatMessage(messages[PTMTitleKey])}
              </MenuItem>
            )}
            {canManageConsumptionBeVisible && (
              <MenuItem
                onClick={() => {
                  this.handleActionClick('manageConsumptionDialogOpened')
                }}
              >
                {this.formatMessage(messages[manageConsumptionKey])}
              </MenuItem>
            )}
          </Menu>
        </div>

        {canPTMOptionBeVisible && (
          <ProductionTestMode
            deviceId={id}
            name={name}
            onClose={() => {
              this.handleActionDialogClose('ptmDialogOpened')
            }}
            open={ptmDialogOpened}
            simStatus={simStatus ?? ''}
            updateDevices={updateDevices}
          />
        )}

        {canManageConsumptionBeVisible && (
          <ManageAccessPassConsumption
            allow_consumption={allow_consumption}
            id={id}
            name={name}
            onClose={() => {
              this.handleActionDialogClose('manageConsumptionDialogOpened')
            }}
            open={manageConsumptionDialogOpened}
            updateDevices={updateDevices}
          />
        )}
      </React.Fragment>
    )
  }
}

Actions.propTypes = {
  canConsumeAccessPasses: PropTypes.bool.isRequired,
  canEditDevice: PropTypes.bool.isRequired,
  canUseProductionTestMode: PropTypes.bool.isRequired,
  device: PropTypes.shape({
    dataPlanExpirationDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    description: PropTypes.string,
    device_type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    eid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    simStatus: PropTypes.string,
    usesOverage: PropTypes.bool,
    allow_consumption: PropTypes.bool
  }).isRequired,
  intl: PropTypes.object.isRequired,
  updateDevices: PropTypes.func.isRequired
}

export default injectIntl(Actions)
