import { defineMessages } from 'react-intl'

const messages = defineMessages({
  errorLoadingComponent: {
    id: 'Undefined.errorLoadingComponent',
    description: 'Error loading component text',
    defaultMessage: 'Error loading component'
  }
})

export default messages