const prefix = 'websocket:'

export const OFFLINE = prefix + 'OFFLINE'
export const ERROR = prefix + 'ERROR'
export const SEND = prefix + 'SEND'

export const CONNECT = prefix + 'CONNECT'
export const RECONNECT = prefix + 'RECONNECT'
export const CONNECT_SUCCESS = prefix + 'CONNECT_SUCCESS'

export const DISCONNECT = prefix + 'DISCONNECT'
export const DISCONNECT_SUCCESS = prefix + 'DISCONNECT_SUCCESS'
export const DISCONNECT_FAIL = prefix + 'DISCONNECT_FAIL'

export const SUBSCRIBE = prefix + 'SUBSCRIBE'
export const SUBSCRIBE_SUCCESS = prefix + 'SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_FAIL = prefix + 'SUBSCRIBE_FAIL'

export const UNSUBSCRIBE = prefix + 'UNSUBSCRIBE'
export const UNSUBSCRIBE_SUCCESS = prefix + 'UNSUBSCRIBE_SUCCESS'
export const UNSUBSCRIBE_FAIL = prefix + 'UNSUBSCRIBE_FAIL'

export const MESSAGE_RECEIVED = prefix + 'ON_MESSAGE'
export const GROUPED_MESSAGES_RECEIVED = prefix + 'ON_MESSAGES'
