import { createAction } from 'redux-actions'

import { actions } from 'utils/http'

import { createRequestAction } from '../../utils/http/actions/utils'

import * as t from './actionTypes'
import * as url from './url'

const clearReportChart = () => dispatch => dispatch(createAction(t.RESET_REPORTS)())

const deleteReport = (reportId, groupId) => dispatch =>
  dispatch(actions.deleteSchedule(reportId, groupId)).promise.then(({ payload }) => payload)

const deleteReportAWS = (reportId, groupId) => dispatch =>
  dispatch(actions.deleteScheduleAWS(reportId, groupId)).promise.then(({ payload }) => payload)

// eslint-disable-next-line max-params
const getChartTimeSeries = (nodeId, start, end, filters, agg = false, bucket) => {
  if (agg) {
    return createRequestAction(
      t.GET_NODE_DATA_AGG_TIMESERIES,
      url.getAggTimeSeries(nodeId, start, end, filters, bucket),
      'get',
      { nodeId, min: start.valueOf(), max: end.valueOf(), filters, agg, bucket }
    )
  } else {
    return createRequestAction(
      t.GET_NODE_DATA_TIMESERIES,
      url.getReportTimeSeries(nodeId, start, end, filters),
      'get',
      { nodeId, min: start.valueOf(), max: end.valueOf(), filters }
    )
  }
}

const getChartTimeSeriesToExport = (nodeId, start, end, filters, agg = false, bucket) => {
  if (agg) {
    return createRequestAction(
      t.GET_NODE_DATA_AGG_TIMESERIES_TO_EXPORT,
      url.getAggTimeSeries(nodeId, start, end, filters, bucket),
      'get',
      { nodeId, min: start.valueOf(), max: end.valueOf(), filters, agg, bucket }
    )
  } else {
    return createRequestAction(
      t.GET_NODE_DATA_TIMESERIES_TO_EXPORT,
      url.getReportTimeSeries(nodeId, start, end, filters),
      'get',
      { nodeId, min: start.valueOf(), max: end.valueOf(), filters }
    )
  }
}

const setSearchFilters = (deviceId, filters) => {
  return {
    type: t.SET_SEARCH_FILTERS,
    payload: { deviceId, filters }
  }
}

const setChartQueryType = (chartQueryType, minRequestTimestamp, maxRequestTimestamp) => dispatch =>
  dispatch(createAction(t.SET_CHART_QUERY_TYPE, () => ({ chartQueryType, minRequestTimestamp, maxRequestTimestamp }))())

export {
  clearReportChart,
  deleteReport,
  deleteReportAWS,
  getChartTimeSeries,
  getChartTimeSeriesToExport,
  setChartQueryType,
  setSearchFilters
}
