import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SmsServiceConsent from './SmsServiceConsent'
import { SMS_SERVICE_CONSENT_TEMPLATE_URL } from './urls'

const smsServiceConsent = props => {
  return (
    <Switch>
      <Route component={SmsServiceConsent} exact path={SMS_SERVICE_CONSENT_TEMPLATE_URL} />
    </Switch>
  )
}

smsServiceConsent.propTypes = {}

export default smsServiceConsent
