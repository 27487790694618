import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import messages from './messages'

import { DM1Table, LastKnownValueTable } from './TableTypes'

const Table = ({ data, editing, eids, height, intl, width }) => {
  const { formatMessage } = intl
  if (data.settings && data.visualSettings) {
    switch (data.data) {
      case 'dm1':
        return <DM1Table data={data} editing={editing} eids={eids} height={height} width={width} />
      case 'lastKnownValue':
        return <LastKnownValueTable data={data} editing={editing} eids={eids} height={height} width={width} />
      default:
        return (
          <div className='notConfigured'>
            <Typography gutterBottom={false}>{formatMessage(messages.widgetNotConfigured)}</Typography>
          </div>
        )
    }
  } else {
    return (
      <div className='notConfigured'>
        <Typography gutterBottom={false}>{formatMessage(messages.outdatedWidget)}</Typography>
      </div>
    )
  }
}

Table.propTypes = {
  data: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  eids: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
}

export default injectIntl(Table)
