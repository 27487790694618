import { defineMessages } from 'react-intl'
 
const messages = defineMessages({
  baseUrlMissedInOptions: {
    id: 'FileHandler.baseUrlMissedInOptions',
    description: 'BaseUrl missed in options error message',
    defaultMessage: 'baseUrl missed in options'
  },
  consoleWarn: {
    id: 'FileHandler.consoleWarn',
    description: 'Your url contains query string, which will be ignored when options.query is set console warn',
    defaultMessage: 'Your url contains query string, which will be ignored when options.query is set.'
  },
  downloading: {
    id: 'FileHandler.downloading',
    description: 'File handler anchor inner HTML text',
    defaultMessage: 'downloading...'
  },
  confirmMessage: {
    id: 'FileHandler.confirmMessage',
    description: 'File handler confirm dialog message',
    defaultMessage: 'Displaying New Document\n\nUse Save As... to download, then click back to return to this page.'
  },
})
 
export default messages