import { CONSENT_STATES } from './constants'

const MAPPED_FIELDS = {
  CONSENT_STATES: 'CONSENT_STATES'
}

const apiMappings = {
  [MAPPED_FIELDS.CONSENT_STATES]: [
    {
      apiName: 'accepted',
      mappedName: CONSENT_STATES.ACCEPTED
    },
    {
      apiName: 'declined',
      mappedName: CONSENT_STATES.DECLINED
    },
    {
      apiName: 'pending',
      mappedName: CONSENT_STATES.PENDING
    }
  ]
}

function mapFields(rawValue, mappedField, inverse = false) {
  const [originName, destinationName] = inverse ? ['mappedName', 'apiName'] : ['apiName', 'mappedName']

  const mapping =
    apiMappings[mappedField].find(
      item => Array.isArray(item[originName]) && item[originName].includes(rawValue) || item[originName] === rawValue
    ) || {}

  return Array.isArray(mapping[destinationName]) ? mapping[destinationName][0] : mapping[destinationName]
}

export function getMappedConsentState(rawValue, inverse = false) {
  return mapFields(rawValue, MAPPED_FIELDS.CONSENT_STATES, inverse)
}
