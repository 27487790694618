import { createAction } from 'redux-actions'
import * as t from './actionTypes'

//ACCIONES DE REDUX
/*
  const nombreDeAccion = createAction(
    t.nombreDeAccion <- type de la acción,
    (parametros que recibe payloadcreator) => payload,
    NOTA: ({ accessKeyId, secretAccessKey, sessionToken }) => ({
    accessKeyId,
    secretAccessKey,
    sessionToken
  }), es igua a
  ({ accessKeyId, secretAccessKey, sessionToken }) => ({
    accessKeyId : accessKeyId,
    secretAccessKey : secretAccessKey,
    sessionToken : sessionToken
  }),
    (parametros que recibe metacreator) => metadata
  )

  llamada a la función: nombreDeAcción(parámetros para el payloadCreator,
  parámetros para el metacreator)
*/

const connect = createAction(
  t.CONNECT,
  ({ accessKeyId, secretAccessKey, sessionToken }) => ({
    accessKeyId,
    secretAccessKey,
    sessionToken
  }),
  ({ nodeId, expiration }) => ({ nodeId, expiration })
)

const disconnect = createAction(
  t.DISCONNECT,
  () => ({}),
  ({ nodeId }) => ({ nodeId })
)
const subscribe = createAction(
  t.SUBSCRIBE,
  ({ topicName }) => topicName,
  ({ onSuccess, onFail, nodeId }) => ({ onSuccess, onFail, nodeId })
)
const unsubscribe = createAction(
  t.UNSUBSCRIBE,
  ({ topicName }) => topicName,
  ({ onSuccess, onFail, nodeId }) => ({ onSuccess, onFail, nodeId })
)

const send = createAction(
  t.SEND,
  ({ message }) => message,
  ({ topic }) => ({ topic })
)

/*
  Constructor promise:
  let promesa = new Promise((onSuccess, onFail) => {
    dispatch(acción)
  })
  Se ejecuta la función ejecutora ((onSuccess, onFail) => {}),
  recibiendo esta las funciones onSuccess y onFail. Y luego se llama
  a onSuccess para resolver la promesa u onFail para rechazarla.
  Una promesa pendiente puede ser cumplida con un valor, 
  o rechazada con una razón (error). 
*/

/*
  dispatch(acción): Despachar una acción, es la única forma de realizar
  un cambio de estado. Devuelve el siguiente estado.
*/

const subscribeWithPromise = (nodeId, topicName) => dispatch =>
  new Promise((onSuccess, onFail) => {
    dispatch(subscribe({ topicName, onSuccess, onFail, nodeId }))
  })

const unsubscribeWithPromise = (nodeId, topicName) => dispatch =>
  new Promise((onSuccess, onFail) => {
    dispatch(unsubscribe({ topicName, onSuccess, onFail, nodeId }))
  })

const sendMessage = (topicName, message) => dispatch => {
  dispatch(send({ topic: topicName, message }))
}

export {
  connect,
  disconnect,
  subscribeWithPromise as subscribe,
  unsubscribeWithPromise as unsubscribe,
  sendMessage as send
}
