const composeLocalQuery = (page, pageLength, filterName) => {
  let query = ''
  let filterQuery = ''
  let separator = ''

  /* FILTER BY RULE NAME - COLUMN EVENT (column dataField "hashId") */
  if (filterName !== undefined) {
    filterQuery = filterQuery + separator + '(name=="*' + filterName + '*")'
    separator = ';'
  }

  if (filterQuery !== '') {
    query = '&' + query + 'search=' + filterQuery
  }

  query = query + '&page=' + (page - 1) + '&size=' + pageLength

  return query
}

const fitToBounds = (shape, form) => {
  if (window.google && window.google.maps) {
    const CIRCLE = 'circle'
    const POLYGON = 'polygon'
    const MARKERS = 'markers'
    const bounds = new window.google.maps.LatLngBounds()

    switch (shape) {
      case CIRCLE: {
        const shapeBounds = new window.google.maps.Circle({ center: form.center, radius: form.radius }).getBounds()
        let point = new window.google.maps.LatLng(shapeBounds.getNorthEast().lat(), shapeBounds.getNorthEast().lng())
        bounds.extend(point)
        point = new window.google.maps.LatLng(shapeBounds.getSouthWest().lat(), shapeBounds.getSouthWest().lng())
        bounds.extend(point)
        break
      }
      case POLYGON: {
        const paths = form.polygonPath
        paths.forEach(path => {
          const latitude = typeof path.lat === 'function' ? path.lat() : path.lat
          const longitude = typeof path.lng === 'function' ? path.lng() : path.lng
          const point = new window.google.maps.LatLng(latitude, longitude)
          bounds.extend(point)
        })
        break
      }
      case MARKERS: {
        const positions = form.map(marker => marker.position)
        positions.forEach(position => {
          const point = new window.google.maps.LatLng(position.latitude, position.longitude)
          bounds.extend(point)
        })
        break
      }
      default:
    }

    return bounds
  }
}

export { composeLocalQuery, fitToBounds }
