import moment from 'moment'
import initialState from './initialState'
import translations from 'i18n/locales'
import IntlMessageFormat from 'intl-messageformat'

const getSelectableLevels = (previousState, customZoom) => {
  let selectedLevel = -1
  const selectableLevels = previousState.selectableLevels.slice(0, initialState.selectableLevels.length)

  if (customZoom && customZoom.length === 2) {
    const zoomWindow = (customZoom[1] - customZoom[0]) / 1000.0
    if (!selectableLevels.some(l => l.value === zoomWindow)) {
      // If it is not within the predefined zoom levels...
      const i18n = translations[localStorage.getItem('user_language')]
      const customLabel = new IntlMessageFormat(i18n['Zoomable.custom']).format({
        duration: moment.duration(zoomWindow).humanize()
      })
      selectableLevels.push({
        value: zoomWindow,
        label: customLabel,
        disabled: false
      })
    }
    selectedLevel = zoomWindow
  }
  return { selectedLevel, selectableLevels }
}

/**
 * @param state The state of zoom reducer
 */
const selectLevel = (state, timeSpan = -1) => {
  if (timeSpan === -1) {
    return {
      ...state,
      forcedZoom: null,
      history: [],
      ...getSelectableLevels(state)
    }
  }

  const selectedZoom = [state.maxDate - timeSpan * 1000, state.maxDate]
  return {
    ...state,
    forcedZoom: selectedZoom,
    history: [selectedZoom],
    ...getSelectableLevels(state, selectedZoom)
  }
}

export { selectLevel, getSelectableLevels }
