import grey from '@material-ui/core/colors/grey'
import lightGreen from '@material-ui/core/colors/lightGreen'

import { white } from '@material-ui/core/colors/common'
import { darkBlack, fullBlack } from 'material-ui/styles/colors'
import { fade } from 'material-ui/utils/colorManipulator'

const danfossPrimary = '#5d5d5d'

const palleteV0 = {
  primary1Color: danfossPrimary,
  primary2Color: 'rgb(176, 176, 177)',
  primary3Color: 'rgba(0, 0, 0, 0.2)',
  primary4Color: white,
  primary5color: 'rgb(235, 235, 235)',
  primary6color: 'rgb(87, 87, 87)',
  accent1Color: lightGreen[500],
  accent2Color: grey[100],
  accent3Color: grey[500],
  textColor: darkBlack,
  secondaryTextColor: white,
  disabledTextColor: darkBlack,
  alternateTextColor: white,
  canvasColor: white,
  borderColor: grey[300],
  disabledColor: fade(darkBlack, 0.3),
  pickerHeaderColor: danfossPrimary,
  clockCircleColor: fade(darkBlack, 0.07),
  shadowColor: fullBlack
}

const pallete = {
  primary: {
    main: danfossPrimary
  },
  secondary: {
    main: danfossPrimary
  }
}

export default pallete
export { palleteV0 }
