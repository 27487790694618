import { connect } from 'react-redux'
import ReportActions from './ReportActions'

import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'

const mapStateToProps = state => ({
  canEditReports: groupUtils.hasPermission(state, c.SCHEDULE_EDIT),
  canDeleteReports: groupUtils.hasPermission(state, c.SCHEDULE_DELETE)
})

export default connect(mapStateToProps)(ReportActions)
