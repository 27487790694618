import React from 'react'
import { Map, Marker } from './wrapper'
import { LocationViewerProps } from '../props'

const LocationViewer = ({ position, label = '', size = 45, ...other }) => {
  if (!position) {
    return null
  }

  return (
    <Map center={position} defaultZoom={1.5} {...other}>
      <Marker
        position={position}
        label={label}
        size={size}
        onClick={() => false}
      />
    </Map>
  )
}

LocationViewer.propTypes = LocationViewerProps

export default LocationViewer
