import { defineMessages } from 'react-intl'

const namespace = 'BasicSignalsChartSettings'

const messages = defineMessages({
  lastOneHour: {
    id: `${namespace}.lastOneHour`,
    description: 'Last one hour',
    defaultMessage: 'Last 1 hour'
  },
  lastThreeHours: {
    id: `${namespace}.lastThreeHours`,
    description: 'Last three hours',
    defaultMessage: 'Last 3 hours'
  },
  lastSixHours: {
    id: `${namespace}.lastSixHours`,
    description: 'Last six hours',
    defaultMessage: 'Last 6 hours'
  },
  lastTwelveHours: {
    id: `${namespace}.lastTwelveHours`,
    description: 'Last twelve hours',
    defaultMessage: 'Last 12 hours'
  },
  lastTwentyFourHours: {
    id: `${namespace}.lastTwentyFourHours`,
    description: 'Last twenty four hours',
    defaultMessage: 'Last 24 hours'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  aggregation: {
    id: `${namespace}.aggregation`,
    description: 'Aggregation',
    defaultMessage: 'Aggregation'
  },
  none: {
    id: `${namespace}.none`,
    description: 'None aggregation type',
    defaultMessage: 'None'
  },
  avg: {
    id: `${namespace}.avg`,
    description: 'Average aggregation type',
    defaultMessage: 'Average'
  },
  min: {
    id: `${namespace}.min`,
    description: 'Minimum aggregation type',
    defaultMessage: 'Minimum'
  },
  max: {
    id: `${namespace}.max`,
    description: 'Maximum aggregation type',
    defaultMessage: 'Maximum'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  valuenone: {
    id: `${namespace}.valuenone`,
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueavg: {
    id: `${namespace}.valueavg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valuemin: {
    id: `${namespace}.valuemin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valuemax: {
    id: `${namespace}.valuemax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  defaultTimeRange: {
    id: `${namespace}.defaultTimeRange`,
    description: 'Default time range',
    defaultMessage: 'Default time range'
  },
  loadingConfiguratedSignals: {
    id: `${namespace}.loadingConfiguratedSignals`,
    description: 'Loading configurated signals',
    defaultMessage: 'Loading configurated signals'
  },
  notSupportedMachine: {
    id: `${namespace}.notSupportedMachine`,
    description: '',
    defaultMessage: 'Not supported machine'
  },
  otherHistoricWidgetSettings: {
    id: `${namespace}.otherHistoricWidgetSettings`,
    description: 'Historic widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  numberOfDecimals: {
    id: `${namespace}.numberOfDecimals`,
    description: 'Number of decimals',
    defaultMessage: 'Number of decimals'
  },
  basicSignalsChartSettings: {
    id: `${namespace}.basicSignalsChartSettings`,
    description: 'Basic signals chart settings',
    defaultMessage: 'Basic signals chart settings:'
  }
})

export default messages
