import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { injectIntl } from 'react-intl'
import messages from './messages'

import uuid from 'uuid'

const NAME_MAX_LENGTH = 50

class AddGroupDashboard extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      newDashboardName: '',
      newDashboardNameError: false,
      newDashboardNameMessage: this.formatMessage(messages.maxCharacters),
      newDashboardDescription: '',
      newDashboardHeight: '768',
      newDashboardHeightError: false,
      newDashboardWidth: '1366',
      newDashboardWidthError: false,
      zoom: 'actual',
      position: this.dashboardIds().length
    }
  }

  saveAddGroupDashboard = () => {
    let newHeight
    let newWidth
    let error = false
    let newDashboardNameError = false
    let newDashboardNameMessage = this.formatMessage(messages.maxCharacters)
    let newDashboardHeightError = false
    let newDashboardWidthError = false

    if (this.state.newDashboardName === '') {
      error = true
      newDashboardNameError = true
      newDashboardNameMessage = this.formatMessage(messages.mustHaveAValue)
    } else if (this.state.newDashboardName.length > NAME_MAX_LENGTH) {
      error = true
      newDashboardNameError = true
      newDashboardNameMessage = this.formatMessage(messages.maximum50Characters)
    } else {
      error = false
      newDashboardNameError = false
      newDashboardNameMessage = this.formatMessage(messages.maxCharacters)
    }

    if (this.state.newDashboardHeight === '') {
      error = true
      newDashboardHeightError = true
    } else {
      newHeight = this.state.newDashboardHeight
    }

    if (this.state.newDashboardWidth === '') {
      error = true
      newDashboardWidthError = true
    } else {
      newWidth = this.state.newDashboardWidth
    }

    if (error) {
      this.setState({
        newDashboardNameError,
        newDashboardNameMessage,
        newDashboardHeightError,
        newDashboardWidthError
      })
    } else {
      this.setState({
        newDashboardNameError: false,
        newDashboardHeightError: false,
        newDashboardWidthError: false
      })

      this.newGroupDashboard(
        this.state.newDashboardName,
        this.state.newDashboardDescription,
        parseInt(newHeight, 10),
        parseInt(newWidth, 10),
        this.state.zoom,
        this.state.position
      )

      this.props.closeAddGroupDashboardModal()
    }
  }

  // eslint-disable-next-line max-params
  newGroupDashboard = (name, description, height, width, zoom, position) => {
    // eslint-disable-next-line max-params
    const newGroupDashboardUUID = uuid()

    if (description === '') {
      description = name // eslint-disable-line no-param-reassign
    }

    const descriptionJSON = JSON.stringify({
      description,
      height,
      width,
      zoom
    })

    const groupDashboardData = {
      uuid: newGroupDashboardUUID,
      deviceId: null,
      groupId: this.props.selectedGroup.id,
      name,
      description: descriptionJSON,
      position
    }

    client
      .newGroupDashboard(groupDashboardData)
      .then(response => {
        this.newGroupDashboardSetup(response.data)
      })
      .catch(response => {
        // const error = Object.assign({}, response)
        logError(response)
      })
  }

  newGroupDashboardSetup = groupDashboardData => {
    const newDashboardTemplate = `{
      "maxZIndex": 1,
      "random": "${uuid()}",
      "widgets": {}
    }`

    const formData = new FormData()
    const blob = new Blob([newDashboardTemplate], { type: 'application/json' })
    formData.append('file', blob)

    // var request = new XMLHttpRequest()
    // request.open('POST', 'http://foo.com/submitform.php')
    // request.send(formData)

    client
      .newGroupDashboardSetup(groupDashboardData.hashId, formData)
      .then(() => {
        this.props.addGroupDashboard(groupDashboardData)
      })
      .catch(response => {
        // const error = Object.assign({}, response)
        logError(response)
      })
  }

  handleChange = name => event => {
    if (name === 'newDashboardName') {
      if (event.target.value === '') {
        this.setState({
          newDashboardName: '',
          newDashboardNameError: true,
          newDashboardNameMessage: this.formatMessage(messages.mustHaveAValue)
        })
      } else if (event.target.value.length > NAME_MAX_LENGTH) {
        this.setState({
          newDashboardName: event.target.value.substr(0, NAME_MAX_LENGTH),
          newDashboardNameError: true,
          newDashboardNameMessage: this.formatMessage(messages.maximum50Characters)
        })
      } else {
        this.setState({
          newDashboardName: event.target.value,
          newDashboardNameError: false,
          newDashboardNameMessage: this.formatMessage(messages.maxCharacters)
        })
      }
    }

    if (name !== 'newDashboardName') {
      this.setState({
        [name]: event.target.value
      })
    }
  }

  handleRadioChange = event => {
    this.setState({ zoom: event.target.value })
  }

  dashboardIds = () => {
    const dashboardIds = this.props.getGroupDashboards.map(dashboard => dashboard.hashId)

    return dashboardIds
  }

  render() {
    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-labelledby="alert-dialog-slide-title"
        fullWidth={true}
        keepMounted={true}
        maxWidth="xl"
        onClose={this.props.closeAddGroupDashboardModal}
        open={true}
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.newDashboard)}
          <IconButton
            onClick={this.props.closeAddGroupDashboardModal}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                error={this.state.newDashboardNameError}
                fullWidth={true}
                helperText={this.state.newDashboardNameMessage}
                id="newDashboardName"
                label={this.formatMessage(messages.name)}
                margin="normal"
                onChange={this.handleChange('newDashboardName')}
                required={true}
                type="text"
                value={this.state.newDashboardName}
              />
              <TextField
                fullWidth={true}
                id="newDashboardDescription"
                label={this.formatMessage(messages.description)}
                margin="normal"
                onChange={this.handleChange('newDashboardDescription')}
                required={false}
                type="text"
                value={this.state.newDashboardDescription}
              />
            </Grid>
          </Grid>
          <Grid container={true} spacing={3}>
            <Grid item={true} sm={6} xs={12}>
              <TextField
                error={this.state.newDashboardWidthError}
                fullWidth={true}
                helperText={this.state.newDashboardWidthError ? this.formatMessage(messages.mustHaveAValue) : null}
                id="newDashboardWidth"
                label={this.formatMessage(messages.width)}
                margin="normal"
                onChange={this.handleChange('newDashboardWidth')}
                required={true}
                type="text"
                value={this.state.newDashboardWidth}
              />
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <TextField
                error={this.state.newDashboardHeightError}
                fullWidth={true}
                helperText={this.state.newDashboardHeightError ? this.formatMessage(messages.mustHaveAValue) : null}
                id="newDashboardHeight"
                label={this.formatMessage(messages.height)}
                margin="normal"
                onChange={this.handleChange('newDashboardHeight')}
                required={true}
                type="text"
                value={this.state.newDashboardHeight}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container={true} spacing={3}>
            <Grid item={true} sm={6} xs={12}>
              <DialogContentText id="alert-dialog-slide-description">
                {this.formatMessage(messages.dashboardDisplayMode)}:
              </DialogContentText>
              <RadioGroup
                aria-label="zoom"
                name="zoom"
                onChange={this.handleRadioChange}
                row={true}
                value={this.state.zoom}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={this.formatMessage(messages.actualSize)}
                  labelPlacement="end"
                  value="actual"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={this.formatMessage(messages.fitToWidth)}
                  labelPlacement="end"
                  value="fit"
                />
              </RadioGroup>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <DialogContentText id="alert-dialog-slide-description">Tab position:</DialogContentText>
              <FormControl required={true} style={{ width: '100%' }}>
                <Select
                  input={<Input name="position" />}
                  name="position"
                  onChange={this.handleChange('position')}
                  value={this.state.position}
                >
                  {this.dashboardIds().map((dashboardId, index) => {
                    return (
                      <MenuItem key={dashboardId + '-' + index} value={index}>
                        {index + 1}
                      </MenuItem>
                    )
                  })}
                  <MenuItem key={this.dashboardIds().length} value={this.dashboardIds().length}>
                    {this.dashboardIds().length + 1}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeAddGroupDashboardModal}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveAddGroupDashboard}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddGroupDashboard.propTypes = {
  addGroupDashboard: PropTypes.func.isRequired,
  closeAddGroupDashboardModal: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object.isRequired
}

export default injectIntl(AddGroupDashboard)
