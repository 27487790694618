import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import messages from './messages'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const UsersTableTitle = ({ newUserUrl, canAddUser, addUser, selectedGroup, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)
  let button = null

  if (canAddUser) {
    button = {
      icon: 'zmdi-plus',
      label: formatMessage(messages.newUser),
      path: newUserUrl,
      disabled: !selectedGroup.id || selectedGroup.id === DEFAULT_GROUP_ID
    }
  }

  return (
    <div className='row'>
      <div className='col-sm-6'>
        <h1>{title}</h1>
      </div>
      <div className='col-sm-6 text-right'>
        {button && (
          <Button className='page-title-button' disabled={button.disabled} onClick={addUser} style={{ marginTop: 20 }}>
            {button.icon && <Icon className={'zmdi ' + button.icon} />}
            {button.label}
          </Button>
        )}
      </div>
    </div>
  )
}

UsersTableTitle.propTypes = {
  addUser: PropTypes.func.isRequired,
  canAddUser: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  newUserUrl: PropTypes.string.isRequired,
  selectedGroup: PropTypes.object.isRequired
}

export default injectIntl(UsersTableTitle)
