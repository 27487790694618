import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from '@material-ui/core/SvgIcon'

import { Marker } from 'components/Map'

const TailMarker = ({ color, ...markerProps }) => (
  <Marker {...markerProps}>
    <SvgIcon>
      <circle
        r="6"
        cy="12"
        cx="12"
        style={{
          color,
          opacity: 1
        }}
      />
    </SvgIcon>
  </Marker>
)

TailMarker.propTypes = {
  color: PropTypes.string.isRequired
}

export default TailMarker
