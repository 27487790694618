import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'
import { utils as groupUtils } from 'ducks/groups'

import { getGroupsUrl } from 'components/groups'
import { getRolesUrl } from 'components/roles'
import { getUsersUrl } from 'components/users'
import { getNotificationsRulesUrl } from 'components/notificationsRulesSettings'
import SettingsDropdown from './SettingsDropdown'

const mapStateToProps = state => ({
  canReadGroup: resolvePermissions(state, c.GROUPS_READ),
  canReadNotification: resolvePermissions(state, c.RULE_READ),
  canReadRole: groupUtils.hasPermission(state, c.GROUPROLE_READ),
  canReadUser: resolvePermissions(state, c.USER_READ),
  groupsUrl: getGroupsUrl(),
  notificationsUrl: getNotificationsRulesUrl(),
  rolesUrl: getRolesUrl(),
  usersUrl: getUsersUrl()
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const ret = { ...ownProps, ...stateProps, ...dispatchProps }
  delete ret.dispatch
  return ret
}

export default connect(mapStateToProps, undefined, mergeProps)(SettingsDropdown)
