const SUCCESS_COLOR = '#009541'
const WARNING_COLOR = '#f0ad4e'
const DANGER_COLOR = '#d9534f'
const INFO_COLOR = '#0077ff'

export const alertStyles = {
  alertTitle: {
    color: 'inherit',
    fontSize: '16px'
  },
  alertMessage: {
    width: '100%'
  },
  alertLink: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}

export const chipStyles = {
  chipRoot: {
    height: 'fit-content',
    fontSize: '14px'
  },
  chipDanger: {
    backgroundColor: DANGER_COLOR,
    color: '#FFFFFF'
  },
  chipInfo: {
    backgroundColor: INFO_COLOR,
    color: '#FFFFFF'
  },
  chipSuccess: {
    backgroundColor: SUCCESS_COLOR,
    color: '#FFFFFF'
  },
  chipWarning: {
    backgroundColor: WARNING_COLOR,
    color: '#FFFFFF'
  }
}

export const dialogStyles = {
  dialogTitle: {
    padding: '0px 8px',
    marginBottom: '16px'
  },
  dialogContent: {
    padding: '8px'
  },
  dialogContentGridContainer: {
    padding: '8px 0px',
    width: 'calc(100% - 8px)'
  }
}

export const iconButtonStyles = {
  iconButtonRoot: {
    padding: '5px'
  }
}

export const iconStyles = {
  iconDanger: {
    color: DANGER_COLOR
  },
  iconInfo: {
    color: INFO_COLOR
  },
  iconSuccess: {
    color: SUCCESS_COLOR
  },
  iconWarning: {
    color: WARNING_COLOR
  }
}

export const inputNumberStyles = {
  inputNumberRoot: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  }
}

export const linkButtonStyles = {
  linkButton: {
    alignItems: 'center',
    borderBottom: '2px solid transparent',
    color: '#32A0E9',
    cursor: 'pointer',
    display: 'inline-flex',
    fontWeight: '500',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
      borderBottom: '2px solid #32A0E9'
    }
  }
}

export const paperStyles = {
  paperRoot: {
    borderRadius: 0,
    padding: 20
  }
}

export const tooltipStyles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}
