import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import messages from './messages'
import { getOptionsForDM1 } from '../config'
import { DM1_FIELDS } from '../constants'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  title: {
    paddingLeft: 16
  },
  root: {
    padding: 0
  },
  action: {
    margin: 0
  },
  actionsRoot: {
    paddingRight: 1
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  button: {
    margin: 0
  },
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {}
})

const DM1 = ({
  changeLocalConfigurationField,
  classes,
  dm1LogToPortal,
  dm1Port,
  dm1PortErrorMessage,
  dm1SA,
  dm1SAErrorMessage,
  dm1UseSA,
  expanded,
  intl,
  isEditable
}) => {
  const { formatMessage } = intl
  const optionsForDM1Selects = getOptionsForDM1()

  return (
    <Card style={{ height: '100%', borderRadius: 0 }}>
      <CardHeader
        action={
          <CardActions classes={{ root: classes.actionsRoot }}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={expanded ? formatMessage(messages.collapse) : formatMessage(messages.expand)}
            >
              <IconButton
                classes={{ root: classes.button }}
                onClick={() => {
                  changeLocalConfigurationField(DM1_FIELDS.dm1Expanded, !expanded)
                }}
              >
                {expanded ? (
                  <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} fontSize='small' />
                ) : (
                  <ExpandMoreIcon className={classes.expand} fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          </CardActions>
        }
        classes={{
          title: classes.title,
          root: classes.root,
          action: classes.action
        }}
        title={formatMessage(messages.dm1Settings)}
      />
      <Collapse in={expanded}>
        <CardContent style={{ padding: 16 }}>
          <Grid container spacing={3}>
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dm1LogToPortal}
                      classes={{ root: classes.checkbox, checked: classes.checked }}
                      id={DM1_FIELDS.dm1LogToPortal}
                      onClick={event => {
                        changeLocalConfigurationField(DM1_FIELDS.dm1LogToPortal, event.target.checked)
                      }}
                    />
                  }
                  label={formatMessage(messages.dm1LogToPortal)}
                  style={{ marginTop: '15px', marginBottom: 0 }}
                />
              </Grid>
            ) : (
              <Grid item md={3} xs={6}>
                <Typography>{formatMessage(messages.dm1LogToPortal)}</Typography>
                <label>{dm1LogToPortal ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                <FormControl error={dm1PortErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.dm1Port)}</InputLabel>
                  <Select
                    name={DM1_FIELDS.dm1Port}
                    onChange={event => {
                      changeLocalConfigurationField(DM1_FIELDS.dm1Port, event.target.value)
                    }}
                    value={dm1Port}
                  >
                    {optionsForDM1Selects.dm1Port.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{dm1PortErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.dm1Port)}</Typography>
                <label>{optionsForDM1Selects.dm1Port.find(p => p.value === dm1Port)?.label}</label>
              </Grid>
            )}
            {isEditable ? (
              <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dm1UseSA}
                      classes={{ root: classes.checkbox, checked: classes.checked }}
                      id={DM1_FIELDS.dm1UseSA}
                      onClick={event => {
                        changeLocalConfigurationField(DM1_FIELDS.dm1UseSA, event.target.checked)
                      }}
                    />
                  }
                  label={formatMessage(messages.dm1UseSA)}
                  style={{ marginTop: '15px', marginBottom: 0 }}
                />
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.dm1UseSA)}</Typography>
                <label>{dm1UseSA ? formatMessage(messages.true) : formatMessage(messages.false)}</label>
              </Grid>
            )}
            {isEditable && dm1UseSA ? (
              <Grid item lg={3} md={4} sm={6} style={{ padding: '0px 12px 0px 12px' }} xs={12}>
                <FormControl error={dm1SAErrorMessage !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.dm1SA)}</InputLabel>
                  <Input
                    id={DM1_FIELDS.dm1SA}
                    inputProps={{ maxLength: 2 }}
                    onChange={event => {
                      const twoHexByte = /[^[0-9a-fA-F]$/
                      changeLocalConfigurationField(DM1_FIELDS.dm1SA, event.target.value.replace(twoHexByte, ''))
                    }}
                    placeholder=' [0x00 - 0xFD]'
                    startAdornment={
                      <InputAdornment position='start' style={{ marginRight: 2 }}>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'black'
                          }}
                        >
                          0x
                        </Typography>
                      </InputAdornment>
                    }
                    value={dm1SA}
                  />
                  <FormHelperText>{dm1SAErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              !isEditable &&
              dm1UseSA && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.dm1SA)}</Typography>
                  <label>{'0x' + dm1SA}</label>
                </Grid>
              )
            }
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}

DM1.propTypes = {
  changeLocalConfigurationField: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dm1LogToPortal: PropTypes.bool.isRequired,
  dm1Port: PropTypes.number.isRequired,
  dm1PortErrorMessage: PropTypes.string.isRequired,
  dm1SA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dm1SAErrorMessage: PropTypes.string.isRequired,
  dm1UseSA: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired
}

export default withStyles(styles)(injectIntl(DM1))
