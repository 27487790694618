import uuid from 'uuid'
import { cloneDeep } from 'lodash'
import update from 'immutability-helper'

import moment from 'moment'

import { getTimestampFromDateString, getLatestTimestamp } from './utils'

import { actionTypes as t } from 'ducks/geotracking'

// dashboards.js

// constants
export const NAMESPACE = 'dashboard'

// Actions
const SETUP_DASHBOARD = NAMESPACE + '/SETUP_DASHBOARD'
const EDIT_DASHBOARD = NAMESPACE + '/EDIT_DASHBOARD'
const SAVE_AND_FINISH_EDIT_DASHBOARD = NAMESPACE + '/SAVE_AND_FINISH_EDIT_DASHBOARD'
const FINISH_EDIT_DASHBOARD = NAMESPACE + '/FINISH_EDIT_DASHBOARD'
const WIDGET_ZINDEX_PLUS = NAMESPACE + '/WIDGET_ZINDEX_PLUS'
const WIDGET_ZINDEX_MINUS = NAMESPACE + '/WIDGET_ZINDEX_MINUS'
const WIDGET_SIZE = NAMESPACE + '/WIDGET_SIZE'
const WIDGET_POSITION = NAMESPACE + '/WIDGET_POSITION'
const WIDGET_SETTINGS = NAMESPACE + '/WIDGET_SETTINGS'
const DELETE_WIDGET = NAMESPACE + '/DELETE_WIDGET'
const ADD_WIDGET = NAMESPACE + '/ADD_WIDGET'
const WIDGET_UPDATE_REAL_TIME_VALUES = NAMESPACE + '/WIDGET_UPDATE_REAL_TIME_VALUES'
const IMPORT_DAHSBOARD = NAMESPACE + '/IMPORT_DAHSBOARD'
const DASHBOARD_SETTINGS = NAMESPACE + '/DASHBOARD_SETTINGS'
const NEW_GPS_POINTS = NAMESPACE + '/NEW_GPS_POINTS'

// initialState
const initialState = { editing: false }

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETUP_DASHBOARD:
      return dashboardSetupHelper(
        state,
        action.nodeFamily,
        action.maxZIndex,
        action.widgets,
        action.deviceData,
        action.settings
      )

    case EDIT_DASHBOARD:
      return startEditingDashboardHelper(state)

    case SAVE_AND_FINISH_EDIT_DASHBOARD:
      return saveAndFinishEditingDashboardHelper(state)

    case FINISH_EDIT_DASHBOARD:
      return finishEditingDashboardHelper(state)

    case WIDGET_ZINDEX_PLUS:
      return setWidgetZIndexPlusHelper(state, action.widgetId)

    case WIDGET_ZINDEX_MINUS:
      return setWidgetZIndexMinusHelper(state, action.widgetId)

    case WIDGET_SIZE:
      return setWidgetSizeHelper(state, action.widgetId, action.height, action.width)

    case WIDGET_POSITION:
      return setWidgetPositionHelper(state, action.widgetId, action.x, action.y)

    case WIDGET_SETTINGS:
      return setWidgetSettingsHelper(state, action.widgetId, action.data)

    case DELETE_WIDGET:
      return deleteWidgetHelper(state, action.widgetId)

    case ADD_WIDGET:
      return addWidgetHelper(state, action.template)

    case IMPORT_DAHSBOARD:
      return importDashboardHelper(state, action.maxZIndex, action.widgets)

    case DASHBOARD_SETTINGS:
      return dashboardSettingsHelper(state, action.settings)

    case WIDGET_UPDATE_REAL_TIME_VALUES:
      return widgetUpdateRealTimeValuesHelper(state, action.data, action.signals)

    case NEW_GPS_POINTS:
      return newGpsPointsHelper(state, action.meta, action.payload)

    default:
      return state
  }
}

// Action Creators
// eslint-disable-next-line max-params
export function setupDashboard(nodeFamily, maxZIndex, widgets, deviceData, settings) {
  return {
    type: SETUP_DASHBOARD,
    nodeFamily,
    maxZIndex,
    widgets,
    deviceData,
    settings
  }
}

export function startEditDashboard() {
  return {
    type: EDIT_DASHBOARD
  }
}

export function saveAndFinishEditingDashboard() {
  return {
    type: SAVE_AND_FINISH_EDIT_DASHBOARD
  }
}

export function finishEditDashboard() {
  return {
    type: FINISH_EDIT_DASHBOARD
  }
}

export function setWidgetZIndexPlus(widgetId) {
  return {
    type: WIDGET_ZINDEX_PLUS,
    widgetId
  }
}

export function setWidgetZIndexMinus(widgetId) {
  return {
    type: WIDGET_ZINDEX_MINUS,
    widgetId
  }
}

export function setWidgetSize(widgetId, height, width) {
  return {
    type: WIDGET_SIZE,
    widgetId,
    height,
    width
  }
}

export function setWidgetPosition(widgetId, x, y) {
  return {
    type: WIDGET_POSITION,
    widgetId,
    x,
    y
  }
}

export function setWidgetSettings(widgetId, data) {
  return {
    type: WIDGET_SETTINGS,
    widgetId,
    data
  }
}

export function deleteWidget(widgetId) {
  return {
    type: DELETE_WIDGET,
    widgetId
  }
}

export function addWidget(template) {
  return {
    type: ADD_WIDGET,
    template
  }
}

export function importDashboard(maxZIndex, widgets) {
  return {
    type: IMPORT_DAHSBOARD,
    maxZIndex,
    widgets
  }
}

export function setDashboardSettings(settings) {
  return {
    type: DASHBOARD_SETTINGS,
    settings
  }
}

export function widgetUpdateRealTimeValues(data, signals) {
  return {
    type: WIDGET_UPDATE_REAL_TIME_VALUES,
    data,
    signals
  }
}

export function newGpsPoints(meta, payload) {
  return {
    type: NEW_GPS_POINTS,
    meta,
    payload
  }
}

// Selectors
export function isEditing(state) {
  return state[NAMESPACE].editing || false
}

export function getNodeFamily(state) {
  return state[NAMESPACE].nodeFamily || ''
}

export function getWidgets(state) {
  return state[NAMESPACE].widgets || {}
}

export function getMaxZIndex(state) {
  return state[NAMESPACE].maxZIndex || 0
}

export function getRandom(state) {
  return state[NAMESPACE].random || ''
}

export function getStaticData(state) {
  return state[NAMESPACE]?.deviceData?.staticData || []
}
export function getDinamicData(state) {
  return state[NAMESPACE]?.deviceData?.dinamicData || []
}

export function getAdvancedSignalsData(state) {
  return state[NAMESPACE]?.deviceData?.advancedSignals || []
}

export function getDashboardSettings(state) {
  return state[NAMESPACE].settings || {}
}

// Helpers
// eslint-disable-next-line max-params
const dashboardSetupHelper = (state, nodeFamily, maxZIndex, widgets, deviceData, settings) => {
  const initial_state = {}
  const newState = {
    ...state,
    editing: false,
    nodeFamily,
    maxZIndex,
    random: uuid(),
    settings,
    widgets,
    deviceData,
    initial_state
  }

  return newState
}

const startEditingDashboardHelper = state => {
  const initial_state = {
    maxZIndex: state.maxZIndex,
    widgets: cloneDeep(state.widgets)
  }
  const newState = { ...state, editing: true, random: uuid(), initial_state }

  return newState
}

const saveAndFinishEditingDashboardHelper = state => {
  const initial_state = {}
  const newState = { ...state, editing: false, random: uuid(), initial_state }

  return newState
}

const finishEditingDashboardHelper = state => {
  const newState = { ...state, editing: false, random: uuid() }
  newState.maxZIndex = state.initial_state.maxZIndex
  newState.widgets = cloneDeep(state.initial_state.widgets)
  Object.entries(newState.widgets).forEach(([key, value]) => {
    const contentType = value.content.widgetType
    if (state.widgets[key]) {
      if (['realtimevalue', 'gauge', 'speedometer', 'box', 'image', 'text'].includes(contentType)) {
        value.content.params.value = state.widgets[key].content.params.value // eslint-disable-line no-param-reassign
      } else if (['columnchart', 'linechart'].includes(contentType)) {
        value.content.params.values = state.widgets[key].content.params.values // eslint-disable-line no-param-reassign
        value.content.params.timestamps = state.widgets[key].content.params.timestamps // eslint-disable-line no-param-reassign
      }
    } else {
      if (['realtimevalue', 'gauge', 'speedometer', 'box', 'image', 'text'].includes(contentType)) {
        value.content.params.value = '' // eslint-disable-line no-param-reassign
      } else if (['columnchart', 'linechart'].includes(contentType)) {
        value.content.params.values = [] // eslint-disable-line no-param-reassign
        value.content.params.timestamps = [] // eslint-disable-line no-param-reassign
      }
    }
  })
  newState.initial_state = {}
  return newState
}

const setWidgetZIndexPlusHelper = (state, widgetId) => {
  const INCREMENT = 1

  const newZIndex = state.widgets[widgetId].zIndex + INCREMENT
  let maxZIndex = state.maxZIndex

  const widgets = update(state.widgets, { [widgetId]: { zIndex: { $set: newZIndex } } })

  if (maxZIndex <= newZIndex) {
    maxZIndex = maxZIndex + INCREMENT
  }

  return { ...state, maxZIndex, widgets }
}

const setWidgetZIndexMinusHelper = (state, widgetId) => {
  const DECREMENT = -1
  const MIN_INDEX = 0

  let newZIndex = state.widgets[widgetId].zIndex + DECREMENT
  if (newZIndex <= MIN_INDEX) {
    newZIndex = MIN_INDEX
  }

  const widgets = update(state.widgets, { [widgetId]: { zIndex: { $set: newZIndex } } })

  return { ...state, widgets }
}

const setWidgetSizeHelper = (state, widgetId, height, width) => {
  const newState = { ...state }

  newState.widgets[widgetId].height = height
  newState.widgets[widgetId].width = width

  return newState
}

const setWidgetPositionHelper = (state, widgetId, x, y) => {
  const newState = { ...state }

  newState.widgets[widgetId].x = x
  newState.widgets[widgetId].y = y

  return newState
}

export const setWidgetSettingsHelper = (state, widgetId, data) => {
  const newState = { ...state }

  delete newState.widgets[widgetId].content.params
  newState.widgets[widgetId].content.params = data

  return newState
}

const deleteWidgetHelper = (state, widgetId) => update(state, { widgets: { $unset: [widgetId] } })

const addWidgetHelper = (state, template) => {
  const UUID = uuid()
  const INCREMENT = 1
  const newState = { ...state }
  const theWidget = { ...template }
  const maxZIndex = newState.maxZIndex + INCREMENT

  newState.maxZIndex = maxZIndex

  theWidget.id = UUID
  theWidget.zIndex = maxZIndex
  newState.widgets[UUID] = { ...theWidget }

  return newState
}
const importDashboardHelper = (state, maxZIndex, widgets) => {
  const newState = { ...state, maxZIndex, widgets }

  return newState
}

const dashboardSettingsHelper = (state, settings) => {
  const newState = { ...state, settings }
  return newState
}

const widgetUpdateRealTimeValuesHelper = (state, data, signals) => {
  const newState = { ...state }
  const { dinamicData } = newState.deviceData

  const widgets = Object.entries(newState.widgets).reduce((acc, [key, widget]) => {
    if (widget.content.params.data === data) {
      let widgetObject = widget
      const {
        widgetType,
        params: { valueType: vType }
      } = widgetObject.content
      const valueType = vType ? vType : 'value'
      const { multiplier = 1, offset = 0, divider = 1 } = dinamicData.find(({ signalId }) => signalId === data)
      const mappedSignals = signals.map(signal => {
        const { [valueType]: signalValue, deviceType, ...rest } = signal
        let numericSignalValue = parseFloat(signalValue)
        if (!isNaN(numericSignalValue)) {
          switch (deviceType) {
            case 'CS100':
              numericSignalValue = signalValue * multiplier + offset
              break
            case 'CS500':
              numericSignalValue /= divider
              break
            default:
          }
        }
        return { signalValue: numericSignalValue, ...rest }
      })
      switch (widgetType) {
        case 'image':
        case 'box':
        case 'text':
          widgetObject = updateBooleanWidget(widgetObject, mappedSignals)
          break
        case 'realtimevalue':
        case 'speedometer':
        case 'gauge':
          widgetObject = updateUniqueValueWidget(widgetObject, mappedSignals)
          break
        case 'columnchart':
        case 'linechart':
          widgetObject = updateMultipleValuesWidget(widgetObject, mappedSignals)
          break
        default:
          break
      }
      return update(acc, { [key]: { $set: widgetObject } })
    } else {
      return update(acc, { [key]: { $set: widget } })
    }
  }, {})
  return update(newState, { widgets: { $set: widgets } })
}

const newGpsPointsHelper = (state, meta, signals) => {
  let newState = { ...state }
  if (newState.widgets) {
    const widgets = Object.entries(newState.widgets).reduce((acc, [key, widget]) => {
      const gpsSignals = ['speed', 'altitude']
      const {
        widgetType,
        params: { data }
      } = widget.content
      const isGPSWidget = gpsSignals.includes(data)
      let widgetObject = { ...widget }
      if (isGPSWidget) {
        const mappedSignals = signals.map(({ [data]: signalValue, timestamp }) => {
          const numericSignalValue = parseFloat(signalValue)
          return { signalValue: numericSignalValue, timestamp }
        })
        switch (widgetType) {
          case 'realtimevalue':
          case 'speedometer':
          case 'gauge':
            widgetObject = updateBooleanWidget(widgetObject, mappedSignals)
            break
          case 'columnchart':
          case 'linechart':
            widgetObject = updateMultipleValuesWidget(widgetObject, mappedSignals)
            break
          default:
            break
        }
      }
      return update(acc, { [key]: { $set: widgetObject } })
    }, {})
    newState = update(newState, { widgets: { $set: widgets } })
  }
  return newState
}

const updateUniqueValueWidget = (widget, signals) => {
  let widgetObject = { ...widget }
  const reversedSignals = [...signals]
  reversedSignals.reverse()
  let signal = reversedSignals.find(({ signalValue }) => !isNaN(signalValue))
  if (!signal) {
    if (signals[0].isHistoricValue) signal = { timestamp: signals[0].timestamp, signalValue: '' }
  }
  if (signal) {
    const { value } = widget.content.params
    const { signalValue, timestamp } = signal
    if (value === '' || timestamp > value.timestamp) {
      widgetObject = update(widgetObject, {
        content: { params: { value: { $set: { timestamp, value: signalValue } } } }
      })
    }
  }
  return widgetObject
}

const updateBooleanWidget = (widget, signals) => {
  let widgetObject = { ...widget }
  const { lengthOfBits, value } = widget.content.params
  const reversedSignals = [...signals]
  reversedSignals.reverse()
  const signal = reversedSignals.find(({ signalValue }) => !isNaN(signalValue))
  if (signal) {
    const { signalValue, timestamp } = signal
    if (
      (typeof lengthOfBits === 'undefined' || lengthOfBits === 1) &&
      (value === '' || timestamp > value.timestamp) &&
      typeof signalValue === 'number'
    ) {
      widgetObject = update(widgetObject, {
        content: { params: { value: { $set: { timestamp, value: signalValue } } } }
      })
    }
  }
  return widgetObject
}

const updateMultipleValuesWidget = (widget, signals) => {
  let widgetObject = { ...widget }
  const { timestamps, values } = widget.content.params
  const validSignals = signals.filter(({ signalValue }) => !isNaN(signalValue))
  const previousSignals = timestamps.reduce(
    (acc, timestamp, index) => [...acc, { timestamp, signalValue: values[index] }],
    []
  )
  const allSignals = [...previousSignals, ...validSignals]
  allSignals.sort((a, b) => a.timestamp - b.timestamp)

  widgetObject = update(widgetObject, {
    content: {
      params: {
        values: {
          $set: allSignals.map(({ signalValue }) => signalValue)
        },
        timestamps: {
          $set: allSignals.map(({ timestamp }) => moment(timestamp).format('L LTS'))
        }
      }
    }
  })
  return widgetObject
}
