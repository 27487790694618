import React from 'react'
import PropTypes from 'prop-types'

const Alert = ({
  showAlert = false,
  alertType = 'success',
  closeFunction = undefined,
  messageTitle = '',
  messageText = [''],
  style = {}
}) => {
  let showAlertClass = 'hidden'
  const alertTypeClass = 'alert alert-dismissible animated fadeIn  alert-' + alertType

  if (showAlert) {
    showAlertClass = ''
  }

  const showTitle = title => {
    if (title !== '') {
      return <h4>{title}</h4>
    } else {
      return ''
    }
  }

  // eslint-disable-next-line no-shadow
  const showMessages = messageText => {
    let result

    if (messageText.length > 0) {
      if (messageText.length === 1) {
        result = <span className="h5">{messageText[0]}</span>
      } else {
        result = (
          <ul>
            {messageText.map((message, index) => (
              <li key={index}>
                <span className="h5">{message}</span>
              </li>
            ))}
          </ul>
        )
      }
    } else {
      result = ''
    }

    return result
  }

  // eslint-disable-next-line no-shadow
  const showCloseButton = closeFunction => {
    if (closeFunction !== undefined) {
      return (
        <button onClick={closeFunction} className="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      )
    }
  }

  return (
    <div className={showAlertClass}>
      <div className={alertTypeClass} style={style}>
        {showCloseButton(closeFunction)}
        {showTitle(messageTitle)}
        {showMessages(messageText)}
      </div>
    </div>
  )
}

Alert.propTypes = {
  alertType: PropTypes.string.isRequired,
  closeFunction: PropTypes.func,
  messageText: PropTypes.array.isRequired,
  messageTitle: PropTypes.string,
  showAlert: PropTypes.bool.isRequired,
  style: PropTypes.object
}

export default Alert
