import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/styles'

import { SEVERITY_LEVELS } from '../../../constants'
import messages from '../../../messages'
import { chipStyles } from '../../../styles'

const styles = {
  ...chipStyles
}

const NotificationSeverityChip = props => {
  const { classes, intl, severity } = props

  let chipClassName = ''
  let chipText = ''
  switch (severity) {
    case SEVERITY_LEVELS.LOW:
      chipClassName = classes.chipInfo
      chipText = intl.formatMessage(messages.low)
      break
    case SEVERITY_LEVELS.MEDIUM:
      chipClassName = classes.chipWarning
      chipText = intl.formatMessage(messages.medium)
      break
    case SEVERITY_LEVELS.HIGH:
      chipClassName = classes.chipDanger
      chipText = intl.formatMessage(messages.high)
      break
  }

  return <Chip className={classes.chipRoot + ' ' + chipClassName} label={chipText} />
}

NotificationSeverityChip.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  severity: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(NotificationSeverityChip))
