import React from 'react'
import PropTypes from 'prop-types'

import DataConsumptionEstimationForm from './DataConsumptionEstimationForm'

import { getEstimatedDataConsumption } from './variables'

class DataConsumptionEstimation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      configuration: props.configuration,
      loading: props.loading,
      machineUsagePercentage: 25,
      headersStackOverhead: 1,
      dataConsumptionEstimation: 0
    }
  }

  componentDidMount = () => {
    if (this.state.configuration) {
      this.estimateDataConsumption()
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (JSON.stringify(prevState.configuration) !== JSON.stringify(this.props.configuration)) {
      this.setState({
        configuration: this.props.configuration
      })
      this.estimateDataConsumption()
    }
    if (prevState.machineUsagePercentage !== this.state.machineUsagePercentage) {
      this.estimateDataConsumption()
    }
  }

  estimateDataConsumption = () => {
    const config = this.state.configuration
    const Mu = this.state.machineUsagePercentage / 100
    const Hso = this.state.headersStackOverhead

    const estimatedDataConsumption = getEstimatedDataConsumption(config, Mu, Hso)

    this.setState({
      dataConsumptionEstimation: estimatedDataConsumption + ' MB'
    })

    this.props.changeLocalConfigurationField('dataConsumptionEstimation', estimatedDataConsumption)
  }

  handleMachineUsagePercentageChange = percentage => {
    this.setState({
      machineUsagePercentage: percentage
    })
    this.props.changeLocalConfigurationField('machineUsagePercentage', parseInt(percentage))
  }

  render() {
    const { machineUsagePercentage, dataConsumptionEstimation, loading } = this.state
    return (
      <DataConsumptionEstimationForm
        machineUsagePercentage={machineUsagePercentage}
        dataConsumptionEstimation={dataConsumptionEstimation}
        loadingConfiguration={loading}
        handleMachineUsagePercentageChange={this.handleMachineUsagePercentageChange}
      />
    )
  }
}

DataConsumptionEstimation.propTypes = {
  changeLocalConfigurationField: PropTypes.func.isRequired
}

export default DataConsumptionEstimation
