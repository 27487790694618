import * as t from 'modules/reports/actionTypes'
import { actions } from 'utils/http'
import * as reports from 'modules/reports/actions'
import moment from 'moment'

const createMiddleware = () => {
  const middleware = store => {
    const dispatch = store.dispatch
    return next => action => {
      switch (action.type) {
        case actions.types.success(t.GET_NODE_DATA_TIMESERIES):
          if (action?.payload?.status === 200) {
            if (action.payload.data.content.length === 0) {
              const nextData = action.payload.data.next
              const queryData = action.meta.previousAction.payload.request.data
              delete action.payload.data.content // eslint-disable-line no-param-reassign
              if (nextData) {
                dispatch(
                  reports.getChartTimeSeries(
                    queryData.nodeId,
                    moment(nextData).valueOf(),
                    queryData.max,
                    queryData.filters,
                    queryData.bucket
                  )
                )
              }
            }
          }
          break
        default:
      }

      return next(action)
    }
  }
  return middleware
}

export default createMiddleware
