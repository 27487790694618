import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'
import { getOptionsForMFIOItem } from '../config'
import { MFIO_FIELDS } from '../constants'

const styles = () => ({
  title: {
    paddingLeft: 16
  },
  root: {
    padding: '16px 0 16px 0'
  },
  action: {
    margin: 0
  },
  actionsRoot: {
    paddingRight: 1
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  button: {
    margin: 0
  }
})

const MFIO = ({ changeLocalConfigurationField, classes, intl, isEditable, ...mfioSettings }) => {
  const { formatMessage } = intl
  const optionsForMFIOItem = getOptionsForMFIOItem()

  return (
    <Card style={{ height: '100%', borderRadius: 0 }}>
      <CardHeader
        classes={{
          title: classes.title,
          root: classes.root
        }}
        title={formatMessage(messages.mfioSettings)}
      />

      <CardContent
        classes={{
          root: classes.contentRoot
        }}
      >
        {[0, 1, 2, 3].map(i => (
          <Grid key={i} container spacing={2}>
            <Grid item lg={12} md={12} sm={12} style={{ paddingTop: 16 }} xs={12}>
              <Typography noWrap style={{ fontWeight: 'bold' }}>
                {formatMessage(messages['mfio' + i])}
              </Typography>
              <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />
            </Grid>
            {isEditable ? (
              <Grid item xs={2}>
                <FormControl
                  error={mfioSettings[`${'inputSignal' + i + 'ErrorMessage'}`] !== ''}
                  style={{ width: '100%' }}
                >
                  <InputLabel>{formatMessage(messages.inputSignal)}</InputLabel>
                  <Select
                    name={MFIO_FIELDS['inputSignal' + i]}
                    onChange={event => {
                      changeLocalConfigurationField(MFIO_FIELDS['inputSignal' + i], event.target.value)
                    }}
                    value={mfioSettings[`${'inputSignal' + i}`]}
                  >
                    {optionsForMFIOItem['inputSignal' + i].map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{mfioSettings[`${'inputSignal' + i + 'ErrorMessage'}`]}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.inputSignal)}</Typography>
                <label>
                  {
                    optionsForMFIOItem['inputSignal' + i].find(ip => ip.value === mfioSettings[`${'inputSignal' + i}`])
                      ?.label
                  }
                </label>
              </Grid>
            )}
            {isEditable && mfioSettings[`${'inputSignal' + i}`] !== 3 && mfioSettings[`${'inputSignal' + i}`] !== 4 ? (
              <Grid item xs={2}>
                <FormControl
                  error={mfioSettings[`${'inputRange' + i + 'ErrorMessage'}`] !== ''}
                  style={{ width: '100%' }}
                >
                  <InputLabel>{formatMessage(messages.inputRange)}</InputLabel>
                  <Select
                    name={MFIO_FIELDS['inputRange' + i]}
                    onChange={event => {
                      changeLocalConfigurationField(MFIO_FIELDS['inputRange' + i], event.target.value)
                    }}
                    value={mfioSettings[`${'inputRange' + i}`]}
                  >
                    {optionsForMFIOItem.inputRange.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{mfioSettings[`${'inputRange' + i + 'ErrorMessage'}`]}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              mfioSettings[`${'inputSignal' + i}`] !== 3 &&
              mfioSettings[`${'inputSignal' + i}`] !== 4 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.inputRange)}</Typography>
                  <label>
                    {optionsForMFIOItem.inputRange.find(ir => ir.value === mfioSettings[`${'inputRange' + i}`])?.label}
                  </label>
                </Grid>
              )
            }
            {isEditable ? (
              <Grid item xs={2}>
                <FormControl error={mfioSettings[`${'bias' + i + 'ErrorMessage'}`] !== ''} style={{ width: '100%' }}>
                  <InputLabel>{formatMessage(messages.bias)}</InputLabel>
                  <Select
                    name={MFIO_FIELDS['bias' + i]}
                    onChange={event => {
                      changeLocalConfigurationField(MFIO_FIELDS['bias' + i], event.target.value)
                    }}
                    value={mfioSettings[`${'bias' + i}`]}
                  >
                    {optionsForMFIOItem.bias.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{mfioSettings[`${'bias' + i + 'ErrorMessage'}`]}</FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography>{formatMessage(messages.bias)}</Typography>
                <label>{optionsForMFIOItem.bias.find(ip => ip.value === mfioSettings[`${'bias' + i}`])?.label}</label>
              </Grid>
            )}
            {isEditable && mfioSettings[`${'inputSignal' + i}`] === 1 ? (
              <Grid item xs={2}>
                <FormControl
                  error={mfioSettings[`${'digThreshLow' + i + 'ErrorMessage'}`] !== ''}
                  style={{ width: '100%' }}
                >
                  <InputLabel>{formatMessage(messages.digThreshLow)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS['digThreshLow' + i]}
                    inputProps={{ min: 50, max: 65535 }}
                    onChange={event => {
                      changeLocalConfigurationField(MFIO_FIELDS['digThreshLow' + i], event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(mV)'
                    type='number'
                    value={mfioSettings[`${'digThreshLow' + i}`]}
                  />
                  <FormHelperText>{mfioSettings[`${'digThreshLow' + i + 'ErrorMessage'}`]}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              mfioSettings[`${'inputSignal' + i}`] === 1 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.digThreshLow)}</Typography>
                  <label>{mfioSettings[`${'digThreshLow' + i}`]}</label>
                </Grid>
              )
            }
            {/* digThreshHigh: Only for signar source = 3 */}
            {isEditable && mfioSettings[`${'inputSignal' + i}`] === 1 ? (
              <Grid item xs={2}>
                <FormControl
                  error={mfioSettings[`${'digThreshHigh' + i + 'ErrorMessage'}`] !== ''}
                  style={{ width: '100%' }}
                >
                  <InputLabel>{formatMessage(messages.digThreshHigh)}</InputLabel>
                  <Input
                    id={MFIO_FIELDS['digThreshHigh' + i]}
                    inputProps={{ min: 50, max: 65535 }}
                    onChange={event => {
                      changeLocalConfigurationField(MFIO_FIELDS['digThreshHigh' + i], event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(mV)'
                    type='number'
                    value={mfioSettings[`${'digThreshHigh' + i}`]}
                  />
                  <FormHelperText>{mfioSettings[`${'digThreshHigh' + i + 'ErrorMessage'}`]}</FormHelperText>
                </FormControl>
              </Grid>
            ) : 
              mfioSettings[`${'inputSignal' + i}`] === 1 && (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.digThreshHigh)}</Typography>
                  <label>{mfioSettings[`${'digThreshHigh' + i}`]}</label>
                </Grid>
              )
            }
          </Grid>
        ))}
      </CardContent>
    </Card>
  )
}

MFIO.propTypes = {
  bias0: PropTypes.number.isRequired,
  bias0ErrorMessage: PropTypes.string.isRequired,
  bias1: PropTypes.number.isRequired,
  bias1ErrorMessage: PropTypes.string.isRequired,
  bias2: PropTypes.number.isRequired,
  bias2ErrorMessage: PropTypes.string.isRequired,
  bias3: PropTypes.number.isRequired,
  bias3ErrorMessage: PropTypes.string.isRequired,
  changeLocalConfigurationField: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  digThreshHigh0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshHigh0ErrorMessage: PropTypes.string.isRequired,
  digThreshHigh1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshHigh1ErrorMessage: PropTypes.string.isRequired,
  digThreshHigh2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshHigh2ErrorMessage: PropTypes.string.isRequired,
  digThreshHigh3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshHigh3ErrorMessage: PropTypes.string.isRequired,
  digThreshLow0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshLow0ErrorMessage: PropTypes.string.isRequired,
  digThreshLow1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshLow1ErrorMessage: PropTypes.string.isRequired,
  digThreshLow2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshLow2ErrorMessage: PropTypes.string.isRequired,
  digThreshLow3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  digThreshLow3ErrorMessage: PropTypes.string.isRequired,
  inputRange0: PropTypes.number.isRequired,
  inputRange0ErrorMessage: PropTypes.string.isRequired,
  inputRange1: PropTypes.number.isRequired,
  inputRange1ErrorMessage: PropTypes.string.isRequired,
  inputRange2: PropTypes.number.isRequired,
  inputRange2ErrorMessage: PropTypes.string.isRequired,
  inputRange3: PropTypes.number.isRequired,
  inputRange3ErrorMessage: PropTypes.string.isRequired,
  inputSignal0: PropTypes.number.isRequired,
  inputSignal0ErrorMessage: PropTypes.string.isRequired,
  inputSignal1: PropTypes.number.isRequired,
  inputSignal1ErrorMessage: PropTypes.string.isRequired,
  inputSignal2: PropTypes.number.isRequired,
  inputSignal2ErrorMessage: PropTypes.string.isRequired,
  inputSignal3: PropTypes.number.isRequired,
  inputSignal3ErrorMessage: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired
}

export default withStyles(styles)(injectIntl(MFIO))
