import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils as groupUtils } from 'ducks/groups'
import { getGroupDashboards, addGroupDashboard } from 'ducks/groupDashboards'

import AddGroupDashboard from './AddGroupDashboard'

const mapStateToProps = state => ({
  selectedGroup: groupUtils.getSelectedGroup(state),
  getGroupDashboards: getGroupDashboards(state)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addGroupDashboard: dashboard => dispatch(addGroupDashboard(dashboard))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddGroupDashboard))
