import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'

import AccessPassInventory from '../AccessPassInventory'
import DataPlanInventory from '../DataPlanInventory'
import DataPlansTable from './DataPlansTable'
import DataPlansTableTitle from './DataPlansTableTitle'

const DataPlans = ({
  canApplyDataPlans,
  canWriteDevices,
  dataPlansError,
  dataPlansLoaded,
  defaultGroupId,
  getDynamicCSNodes,
  groupDataplans,
  groupId,
  personalDataplans,
  updateDataplans,
  updateDevices
}) => {
  return (
    <div className='content-container' id='content'>
      <div style={{ margin: '20px 25px 20px 20px' }}>
        <div className='container-fluid'>
          <DataPlansTableTitle />
        </div>
        <div className='row' style={{ margin: '20px 0 0 0', padding: '0px 15px' }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <DataPlanInventory
                dataPlansLoaded={dataPlansLoaded}
                groupDataplans={groupDataplans}
                personalDataplans={personalDataplans}
                updateDataplans={updateDataplans}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <AccessPassInventory />
            </Grid>
            <Grid item xs={12}>
              <DataPlansTable
                canApplyDataPlans={canApplyDataPlans}
                canWriteDevices={canWriteDevices}
                dataPlansError={dataPlansError}
                dataPlansLoaded={dataPlansLoaded}
                dataplans={groupId === defaultGroupId ? personalDataplans : groupDataplans}
                getDynamicCSNodes={getDynamicCSNodes}
                groupId={groupId}
                updateDevices={updateDevices}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

DataPlans.propTypes = {
  canApplyDataPlans: PropTypes.bool.isRequired,
  canWriteDevices: PropTypes.bool.isRequired,
  dataPlansError: PropTypes.bool.isRequired,
  dataPlansLoaded: PropTypes.bool.isRequired,
  defaultGroupId: PropTypes.string.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupDataplans: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  personalDataplans: PropTypes.array.isRequired,
  updateDataplans: PropTypes.func.isRequired,
  updateDevices: PropTypes.func.isRequired
}

export default DataPlans
