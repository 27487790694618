import { defineMessages } from 'react-intl'

const messages = defineMessages({
  embeddedWebWidget: {
    id: 'EmbeddedWebSettings.embeddedWebWidget',
    description: 'Embedded Web widget',
    defaultMessage: 'EMBEDDED WEB WIDGET'
  },
  embeddedWebWidgetSettings: {
    id: 'EmbeddedWebSettings.embeddedWebWidgetSettings',
    description: 'Embedded web widget settigs dialog',
    defaultMessage: 'Embedded web widget settigs:'
  },
  url: {
    id: 'EmbeddedWebSettings.url',
    description: 'Url',
    defaultMessage: 'Url'
  },
  cancel: {
    id: 'EmbeddedWebSettings.cancel',
    description: 'Embedded web widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'EmbeddedWebSettings.save',
    description: 'Embedded web widget settings save button text',
    defaultMessage: 'Save'
  },
  urlMustStartWith: {
    id: 'EmbeddedWebSettings.urlMustStartWith',
    description: 'Url must start with "https://" or "http://"',
    defaultMessage: 'Url must start with "https://" or "http://"'
  },
  urlCantBeEmpty: {
    id: 'EmbeddedWebSettings.urlCantBeEmpty',
    description: 'Url can\'t be empty',
    defaultMessage: 'Url can\'t be empty'
  },
  widgetNotConfigured: {
    id: 'EmbeddedWebSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  errorFetchingPage: {
    id: 'EmbeddedWebSettings.errorFetchingPage',
    description: 'Error fetching page',
    defaultMessage: 'Error fetching page'
  }
})

export default messages
