export const mapCSToRCTUser = (csUser, roleId, roles) => {
  let role = {}
  if (roleId) {
    const selectedRole = roles.find(role => role.hashId === roleId) // eslint-disable-line no-shadow
    if (selectedRole) {
      role = {
        hashId: selectedRole.hashId,
        name: selectedRole.name,
        description: selectedRole.description,
        version: 0
      }
    }
  }
  return {
    firstName: csUser.firstName,
    lastName: csUser.lastName,
    hashId: csUser.id,
    email: csUser.email,
    enabled: true,
    version: 0,
    createdDate: csUser.createdAt,
    lastModifiedDate: csUser.updatedAt,
    notify: false,
    roles: [role]
  }
}
