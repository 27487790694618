import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import { logError } from 'utils/http'

import NewGroupForm from './NewGroupForm'
import NewGroupFormTitle from './NewGroupFormTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { mapGroupProperties, mapToHierarchicalGroups } from '../utils'

class NewGroup extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      groups: [],
      groupsHierarchy: [],
      loading: true,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    const { getGroups, intl } = this.props
    getGroups()
      .then(response => {
        this.setState({
          groups: mapGroupProperties(response.data.group),
          groupsHierarchy: mapToHierarchicalGroups(response.data.group),
          loading: false,
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = response.error
        switch (error && error.response.status) {
          case 400: // Bad request
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401: // Invalid credentials
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403: // Access denied
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500: // Server errors
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    const { loading, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { loading, alertMessages, groups, groupsHierarchy } = this.state
    const { onCreateGroup, groupsUrl, newGroup } = this.props
    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <NewGroupForm
          action={onCreateGroup}
          groups={groups}
          groupsHierarchy={groupsHierarchy}
          groupsUrl={groupsUrl}
          newGroup={newGroup}
        />
      )
    }
  }

  render() {
    const { groupsUrl } = this.props
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <NewGroupFormTitle groupsUrl={groupsUrl} />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

NewGroup.propTypes = {
  getGroups: PropTypes.func.isRequired,
  groupsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  newGroup: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired
}

export default injectIntl(NewGroup)
