import { defineMessages } from 'react-intl'

const namespace = 'DataPlans'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    description: 'Digital Service plans title',
    defaultMessage: 'Digital Service plans'
  },
  noDataPlansText: {
    id: `${namespace}.noDataPlansText`,
    description: 'No digital service plans text',
    defaultMessage: 'No digital service plans available'
  },
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error: {
    id: `${namespace}.error`,
    description: 'Error text',
    defaultMessage: 'Error {number}'
  },
  errorRequestingDataPlans: {
    id: `${namespace}.errorRequestingDataPlans`,
    description: 'Error requesting digital service plans',
    defaultMessage: 'Error requesting digital service plans'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions column header',
    defaultMessage: 'Actions'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Nodes table total text showing rows',
    defaultMessage: 'Showing plans'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Nodes table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Nodes table total text of',
    defaultMessage: 'of'
  },
  planID: {
    id: `${namespace}.planID`,
    description: 'Plan ID',
    defaultMessage: 'Plan ID'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name',
    defaultMessage: 'Name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  totalNumberOfPlans: {
    id: `${namespace}.totalNumberOfPlans`,
    description: 'Total number of plans',
    defaultMessage: 'Total number of plans'
  },
  activatedPlans: {
    id: `${namespace}.activatedPlans`,
    description: 'Activated plans',
    defaultMessage: 'Activated plans'
  },
  activate: {
    id: `${namespace}.activate`,
    description: 'Activate',
    defaultMessage: 'Activate'
  },
  buyPlans: {
    id: `${namespace}.buyPlans`,
    description: 'Buy plans',
    defaultMessage: 'Buy plans'
  },
  errorRequestingDevices: {
    id: `${namespace}.errorRequestingDevices`,
    description: 'Error requesting devices',
    defaultMessage: 'Error requesting devices'
  },
  thereAreNoDevicesWithoutDataPlan: {
    id: `${namespace}.thereAreNoDevicesWithoutDataPlan`,
    description: 'There are no devices without data plan',
    defaultMessage: 'There are no devices without data plan'
  },
  allTheseAreAlreadyActivated: {
    id: `${namespace}.allTheseAreAlreadyActivated`,
    description: 'All these plans are already activated',
    defaultMessage: 'All these plans are already activated'
  },
  dataPlanActivatedSuccessfully: {
    id: `${namespace}.dataPlanActivatedSuccessfully`,
    description: 'Data plan activated succesfully',
    defaultMessage: 'Data plan activated succesfully'
  },
  dataOverageReserve: {
    id: `${namespace}.dataOverageReserve`,
    description: 'Data overage reserve',
    defaultMessage: 'Data overage reserve'
  },
  totalData: {
    id: `${namespace}.totalData`,
    description: 'Total data column name',
    defaultMessage: 'Total data'
  },
  purchaseDate: {
    id: `${namespace}.purchaseDate`,
    description: 'Purchase date column name',
    defaultMessage: 'Purchase date'
  },
  subscription: {
    id: `${namespace}.subscription`,
    description: 'Subscription column name',
    defaultMessage: 'Subscription'
  },
  activationDate: {
    id: `${namespace}.activationDate`,
    description: 'Activation date column name',
    defaultMessage: 'Activation date'
  },
  timeToExpire: {
    id: `${namespace}.timeToExpire`,
    description: 'Time to expire column name',
    defaultMessage: 'Time to expire'
  },
  renewalDate: {
    id: `${namespace}.renewalDate`,
    description: 'Renewal date column name',
    defaultMessage: 'Renewal date'
  },
  expirationDate: {
    id: `${namespace}.expirationDate`,
    description: 'Expiration date column name',
    defaultMessage: 'Expiration date'
  },
  deviceName: {
    id: `${namespace}.deviceName`,
    description: 'Device name column name',
    defaultMessage: 'Device name'
  },
  boughtByMe: {
    id: `${namespace}.boughtByMe`,
    description: 'Bought by me',
    defaultMessage: 'Bought by me'
  },
  subscriptionDays: {
    id: `${namespace}.subscriptionDays`,
    description: 'Time period column name',
    defaultMessage: 'Plan time period'
  },
  oneYearServicePlan: {
    id: `${namespace}.oneYearServicePlan`,
    description: '1 year Digital Service Plan',
    defaultMessage: '1 year'
  },
  xYearsServicePlan: {
    id: `${namespace}.xYearsServicePlan`,
    description: 'X years Digital Service Plan',
    defaultMessage: '{number} years'
  },
  autorenewal: {
    id: `${namespace}.autorenewal`,
    description: 'Autorenewal',
    defaultMessage: 'Autorenewal'
  },
})

export default messages
