import React from 'react'

import grey from '@material-ui/core/colors/grey'

import { Map } from './wrapper'

import Marker from '../../mapView/CustomizedMarker'

import { LocationSelectorProps } from '../props'

import { injectIntl } from 'react-intl'

const LocationSelector = injectIntl(({ value, onChange, url, intl }) => (
  <Map defaultCenter={value} zoom={8}>
    <Marker
      draggable={onChange ? true : false}
      position={value}
      color={grey[800]}
      hoverColor={grey[800]}
      onDragEnd={({ lngLat }) =>
        onChange({
          longitude: lngLat[0],
          latitude: lngLat[1]
        })
      }
      url={url}
    />
  </Map>
))

LocationSelector.propTypes = LocationSelectorProps

export default LocationSelector
