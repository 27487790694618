import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import DropdownMenu from 'components/DropdownMenu'

import messages from './messages'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

const CodeDropdown = ({ languages, selectedCode, style, onSelected, classes, intl }) => {
  const selected = languages.find(l => l.code === selectedCode)
  const { formatMessage } = intl

  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50,
        width: 64
      }}
    >
      <DropdownMenu icon={selected.code.toUpperCase()} style={style}>
        {languages.map((value, index) => (
          <Tooltip
            key={index + 'tooltip'}
            classes={{ tooltip: classes.tooltip }}
            placement='bottom'
            title={value.underRevision ? formatMessage(messages.translationUnderRevision) : ''}
          >
            <MenuItem key={index} onClick={() => onSelected(value)} selected={value === selected}>
              <ListItemText primary={value.lang + ' (' + value.code + ')' + (value.underRevision ? ' *' : '')} />
            </MenuItem>
          </Tooltip>
        ))}
      </DropdownMenu>
    </div>
  )
}

CodeDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      flag: PropTypes.string,
      lang: PropTypes.string
    })
  ).isRequired,
  onSelected: PropTypes.func.isRequired,
  selectedCode: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(CodeDropdown))
