import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from 'modules/geofences/actions'
import * as c from 'modules/geofences/constants'
import * as utils from 'modules/geofences/reducer'
import { actions as groupActions } from 'ducks/groups'

import AssignUsersStep from './AssignUsersStep'

const mapStateToProps = state => ({
  isAssignUsersButtonDisabled: utils.isAssignUsersButtonDisabled(state[c.NAME]),
  ruleInstanceHashId: utils.getRuleInstanceHashId(state[c.NAME])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeEditGeofenceDialog: actions.closeEditGeofenceDialog,
      getGroupUsers: groupActions.getDetailedUsersOfGroup,
      getRuleInstance: actions.getRuleInstance,
      getUsersOfGroupDetail: groupActions.getUsersOfGroupDetails,
      updateRuleInstance: actions.updateRuleInstance
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AssignUsersStep)
