import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'

import MaintenanceConfiguration from './MaintenanceConfiguration'

const mapStateToProps = state => {
  return {
    selectedGroup: groupUtils.getSelectedGroup(state)
  }
}

export default connect(mapStateToProps)(MaintenanceConfiguration)
