import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Schedule from '@material-ui/icons/Schedule'


import messages from './messages'
import * as utils from '../utils'

const styles = {
  schedule: {
    fontSize: 11,
    alignSelf: 'center'
  }
}

class RealTimeValue extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage
  }

  getSignalData = () => {
    const { dinamicData, data } = this.props
    let signalData = dinamicData.filter(signal => {
      return signal.signalId === data.data
    })

    if (signalData[0] === undefined) {
      signalData = [
        {
          unit: this.formatMessage(messages.noSignal)
        }
      ]
    }

    return signalData[0]
  }

  signalDataValue = () => {
    const { data } = this.props
    let numOfDec = 0
    if (typeof data.numberOfDecimals !== 'undefined') numOfDec = data.numberOfDecimals
    const value = data.value.value
    const num = parseFloat('1e' + numOfDec)
    return Math.round((value + Number.EPSILON) * num) / num
  }

  formatTooltipTitle = value => {
    let title = moment(value.timestamp).format('L LTS')
    if (value.value === '') {
      title = this.formatMessage(messages.noDataSince, { date: title })
    }
    return title
  }

  render() {
    const { classes, dinamicData, data: propsData, eid, isSubscribedTo } = this.props
    const { data, bold, size, align, value } = propsData

    const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
    const isValidData = dinamicDataSignalIds.includes(data)
    const formattedUnit = this.getSignalData().unit ? ' ' + this.getSignalData().unit : null
    const widgetIsNotConfigured = data === ''
    const noDynamicSignalsAvailable = dinamicData.length === 0
    const widgetIsSubscribing = (eid === '' || !isSubscribedTo(eid, utils.getTopic(data, eid))) && isValidData

    return (
      <div className={widgetIsNotConfigured || noDynamicSignalsAvailable ? 'notConfigured' : ''}>
        {noDynamicSignalsAvailable ? (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: bold,
                fontSize: size,
                textAlign: align
              }}
            >
              {this.formatMessage(messages.notSupportedMachine)}
            </span>
          </Typography>
        ) : widgetIsNotConfigured ? (
          <Typography gutterBottom={false}>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : widgetIsSubscribing ? (
          <div className='sk-bounce small'>
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
          </div>
        ) : (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: bold,
                fontSize: size,
                textAlign: align
              }}
            >
              {value === '' || value?.value === ''
                ? '-- ' + formattedUnit
                  ? formattedUnit
                  : ''
                : this.signalDataValue() + formattedUnit}
              {value?.timestamp && (
                <Tooltip title={this.formatTooltipTitle(value)}>
                  <Schedule classes={{ root: classes.schedule }} color='secondary' />
                </Tooltip>
              )}
            </span>
          </Typography>
        )}
      </div>
    )
  }
}

RealTimeValue.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isSubscribedTo: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(RealTimeValue))
