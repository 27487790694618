import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'

import SignalSettingsStep from './SignalSettingsStep'

const mapStateToProps = state => {
  return {
    groupId: groupUtils.getSelectedGroup(state).id
  }
}

export default connect(mapStateToProps)(SignalSettingsStep)
