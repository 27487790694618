import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import Alert from 'components/Alert'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import TextField from 'material-ui/TextField'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { RoleSelect, getParentRole, processRolesHierarchyToSelect } from '../../roles'

import { client, logError } from 'utils/http'

class EditUserForm extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    let roleHashIdValue = null
    let roleDescriptionValue = ''
    let remarks = ''

    if (props.user.roles !== undefined && props.user.roles.length > 0) {
      roleHashIdValue = props.user.roles[0].hashId
      roleDescriptionValue = props.user.roles[0].description
    }

    if (props.user.remarks !== undefined) {
      remarks = props.user.remarks
    }

    this.state = {
      hashId_value: props.user.hashId,
      firstName_value: props.user.firstName,
      lastName_value: props.user.lastName,
      email_value: props.user.email,
      password_value: '',
      password_repeat_value: '',
      enabled_value: props.user.enabled,
      createdatValue: moment(props.user.createdDate).format('L'),
      modifiedatValue: moment(props.user.lastModifiedDate).format('L'),
      remarks_value: remarks,
      notify_value: props.user.notify,

      roleHashIdValue,
      roleDescriptionValue,
      roleDescriptionValue_errorText: '',

      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    let element_textError = ''
    const value = event.target.value,
      name = event.target.name,
      property_value = name + '_value',
      property_errorText = name + '_errorText',
      new_state = {}

    if (event.target.value === '') {
      element_textError = this.formatMessage(messages.thisFieldIsRequired)
    }

    new_state[property_value] = value
    new_state[property_errorText] = element_textError

    this.setState(new_state)
  }

  onRoleSelectChange = (event, index, value) => {
    const { roles } = this.props
    const newRoleHashId = value
    let parentRole

    if (value === null) {
      // Parent not selected
      this.setState({
        roleHashIdValue: null,
        roleDescriptionValue: '',
        roleDescriptionValue_errorText: this.formatMessage(messages.selectARole)
      })
    } else {
      parentRole = getParentRole(roles, newRoleHashId)

      this.setState({
        roleHashIdValue: parentRole.hashId,
        roleDescriptionValue: parentRole.description,
        roleDescriptionValue_errorText: ''
      })
    }
  }

  handleEnabledChange = (event, value) => {
    this.setState({
      enabled_value: value
    })
  }

  handleNotifyChange = (event, value) => {
    this.setState({
      notify_value: value
    })
  }

  emailIsValid = email => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i

    return regex.test(email)
  }

  saveUser = newUser => {
    const { action } = this.props
    client
      .editUser(newUser)
      .then(() => {
        action()
      })
      .catch(response => {
        const error = { ...response }

        switch (error.response.status) {
          case 400:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 404:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.otherError404Message)]
            })
            break
          case 409:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '409' }),
              alertMessagesText: [this.formatMessage(messages.error409Message)]
            })
            break
          case 422:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
              alertMessagesText: [this.formatMessage(messages.error422Message)]
            })
            break
          case 500:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          case 502:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '502' }),
              alertMessagesText: [this.formatMessage(messages.error502Message)]
            })
            break
          default:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  handleSave = () => {
    const { assignRoleToUser, group, action } = this.props
    const { roleHashIdValue, email_value } = this.state
    assignRoleToUser(group.id, roleHashIdValue, [email_value])
      .then(() => {
        action()
      })
      .catch(response => {
        if (response.error && response.error.response) {
          const { status, data } = response.error.response
          if (
            status === 400 &&
            typeof data.message === 'string' &&
            data.message.includes('ALREADY HAS THIS ROLE IN THIS GROUP')
          ) {
            action()
          } else logError(response.error)
        } else logError(response.error)
      })
  }

  render() {
    const { loggedUser, user, rolesHierarchy, group } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      createdatValue,
      email_errorText,
      email_value,
      enabled_value,
      firstName_errorText,
      firstName_value,
      hashId_value,
      lastName_errorText,
      lastName_value,
      modifiedatValue,
      notify_value,
      password_errorText,
      password_repeat_errorText,
      password_repeat_value,
      password_value,
      remarks_value,
      roleDescriptionValue,
      roleDescriptionValue_errorText,
      roleHashIdValue
    } = this.state
    return (
      <div className='container-fluid' style={{ margin: '20px 0 0 0' }}>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
        <Paper style={{ borderRadius: 0 }}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  disabled
                  errorText={firstName_errorText}
                  floatingLabelText={this.formatMessage(messages.firstName)}
                  fullWidth
                  name='firstName'
                  onChange={this.handleInputChange}
                  value={firstName_value}
                />
                <TextField
                  disabled
                  errorText={lastName_errorText}
                  floatingLabelText={this.formatMessage(messages.lastName)}
                  fullWidth
                  name='lastName'
                  onChange={this.handleInputChange}
                  value={lastName_value}
                />
                <TextField
                  disabled
                  errorText={email_errorText}
                  floatingLabelText={this.formatMessage(messages.email)}
                  fullWidth
                  name='email'
                  onChange={this.handleInputChange}
                  value={email_value}
                />
                <TextField
                  disabled
                  errorText={password_errorText}
                  floatingLabelText={this.formatMessage(messages.password)}
                  fullWidth
                  name='password'
                  onChange={this.handleInputChange}
                  type='password'
                  value={password_value}
                />
                <TextField
                  disabled
                  errorText={password_repeat_errorText}
                  floatingLabelText={this.formatMessage(messages.repeatPassword)}
                  fullWidth
                  name='password_repeat'
                  onChange={this.handleInputChange}
                  type='password'
                  value={password_repeat_value}
                />
                <div style={{ height: 72 }}>
                  <FormControlLabel
                    control={<Switch checked={enabled_value} disabled onChange={this.handleEnabledChange} />}
                    label={this.formatMessage(messages.enabled)}
                    style={{
                      color: 'rgba(0, 0, 0, 0.3)',
                      top: 38,
                      fontSize: 16
                    }}
                  />
                  <hr
                    style={{
                      borderTop: 'none rgb(224, 224, 224)',
                      borderLeft: 'none rgb(224, 224, 224)',
                      borderRight: 'none rgb(224, 224, 224)',
                      borderBottom: '1px solid rgb(224, 224, 224)',
                      position: 'relative',
                      top: -18
                    }}
                  />
                </div>
                <div style={{ height: 72 }}>
                  <FormControlLabel
                    control={<Switch checked={notify_value} disabled onChange={this.handleNotifyChange} />}
                    label={this.formatMessage(messages.notify)}
                    style={{
                      color: 'rgba(0, 0, 0, 0.3)',
                      top: 38,
                      fontSize: 16
                    }}
                  />
                  <hr
                    style={{
                      borderTop: 'none rgb(224, 224, 224)',
                      borderLeft: 'none rgb(224, 224, 224)',
                      borderRight: 'none rgb(224, 224, 224)',
                      borderBottom: '1px solid rgb(224, 224, 224)',
                      position: 'relative',
                      top: -18
                    }}
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div className='row'>
                  <div className='col-md-6'>
                    <TextField
                      disabled
                      floatingLabelText={this.formatMessage(messages.createdAt)}
                      fullWidth
                      name='createdAt'
                      value={createdatValue}
                    />
                  </div>
                  <div className='col-md-6'>
                    <TextField
                      disabled
                      floatingLabelText={this.formatMessage(messages.modifiedAt)}
                      fullWidth
                      name='modifiedAt'
                      value={modifiedatValue}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {loggedUser.hashId !== user.hashId ? (
                      <RoleSelect
                        disabled={loggedUser.hashId === user.hashId}
                        handleSelectChange={this.onRoleSelectChange}
                        parentDescriptionValue_errorText={roleDescriptionValue_errorText}
                        parentHashIdValue={roleHashIdValue}
                        parentRole={false}
                        roleDescriptionValue={roleDescriptionValue}
                        rolesHierarchy={processRolesHierarchyToSelect(rolesHierarchy, hashId_value)}
                        selectLabel={this.formatMessage(messages.selectRole)}
                      />
                    ) : (
                      <TextField
                        disabled
                        floatingLabelText={this.formatMessage(messages.role)}
                        fullWidth
                        name='role'
                        value={user.roles[0].description}
                      />
                    )}
                  </div>
                  <div className='col-md-6'>
                    <TextField
                      disabled
                      floatingLabelText={this.formatMessage(messages.group)}
                      fullWidth
                      name='group'
                      value={group.name}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <TextField
                      disabled
                      errorText=''
                      floatingLabelText={this.formatMessage(messages.remarks)}
                      fullWidth
                      hintText=''
                      multiLine
                      name='remarks'
                      onChange={this.handleInputChange}
                      rows={10}
                      style={{ top: -10 }}
                      value={remarks_value}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <p className='small' style={{ margin: '0 0 5px 0' }}>
                  {this.formatMessage(messages.dontInsert)}
                </p>
                <p className='small' style={{ margin: '0 0 5px 0' }}>
                  {this.formatMessage(messages.validPasswordRules)}
                </p>
                <ul className='small'>
                  <li>{this.formatMessage(messages.lengthBetween)}</li>
                  <li>{this.formatMessage(messages.atLeastOneUppercase)}</li>
                  <li>{this.formatMessage(messages.atLeastOneLowercase)}</li>
                  <li>{this.formatMessage(messages.atLeastOneNumber)}</li>
                  <li>{this.formatMessage(messages.whiteSpaceNotAllowed)}</li>
                  <li>{this.formatMessage(messages.specialCharactersNotAllowed)}</li>
                </ul>
              </div>
              <div className='col-md-12'>
                <p className='small' style={{ margin: '0 0 5px 0' }}>
                  {this.formatMessage(messages.systemWillNotify)}
                </p>
              </div>
            </div>

            <div>&nbsp;</div>

            <div className='row'>
              <div className='col-md-12 text-right'>
                <Button className='primary-action-button' disabled={!roleHashIdValue} onClick={this.handleSave}>
                  {this.formatMessage(messages.save)}
                </Button>
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
        </Paper>
      </div>
    )
  }
}

EditUserForm.propTypes = {
  action: PropTypes.func.isRequired,
  assignRoleToUser: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  rolesHierarchy: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
}

export default injectIntl(EditUserForm)
