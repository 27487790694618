import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils, actions } from 'ducks/theme'

import Home from './Home'

const mapStateToProps = state => ({
  defaultTheme: utils.getDefaultTheme(state)
})

const mapDispatchToProps = dispatch => ({
  setTheme: theme => dispatch(actions.setTheme(theme))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
