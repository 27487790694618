// FIXME Check if this is still necessary.
// Both imports replace old '@babel/polyfill'.
import 'core-js'
import 'regenerator-runtime/runtime'

import React from 'react' // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom'

import { createBrowserHistory } from 'history'

import Root from './containers'
import configureStore from './redux/configureStore'

import { isLocalhost } from './routes/utils'
import { Auth0Provider } from './react-auth0-spa'

// Configure history for react-router
const history = createBrowserHistory()

// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the key "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const initialState = window.__INITIAL_STATE__
const store = configureStore(initialState)

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

const config = {
  domain: process.env.REACT_APP_DIP_DOMAIN,
  client_id: process.env.REACT_APP_DIP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_DIP_REDIRECT_URI,
  audience: process.env.REACT_APP_DIP_AUDIENCE,
  responseType: 'code id_token token',
  scope:
    'openid profile email https://danfoss.com/identity_id https://danfoss.com/is_workforce https://danfoss.com/analytics_user_id'
}

// Now that redux and react-router have been configured, we can render the
// React application to the DOM!
//idiomas: inglés (en), español (es), chino (zh), japonés (ja), ruso (ru).
//it also can be implemented on the root of the module
//but it's better to put it here for future improvements
ReactDOM.render(
  <Auth0Provider
    audience={config.audience}
    client_id={config.client_id}
    domain={config.domain}
    onRedirectCallback={onRedirectCallback}
    redirect_uri={config.redirectUri}
    response_type={config.responseType}
    return_to={config.returnTo}
    scope={config.scope}
  >
    <Root history={history} store={store} />
  </Auth0Provider>,
  document.getElementById('root')
)

export { store }
