import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import { getRolesUrl } from '../url'
import { csPrivilegesHierarchy } from 'modules/privileges'
import { mapRolesProperties, mapCSToRCTPermissions } from '../utils'

import EditRoleForm from './EditRoleForm'
import EditRoleFormTitle from './EditRoleFormTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

class EditRole extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      role: {},
      privilegesHierarchy: csPrivilegesHierarchy(this.formatMessage),
      loading: true,
      alertMessages: false,
      alertMessagesType: 'danger',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      numOfPrivileges: 0
    }
  }

  componentDidMount() {
    this.getCSRole()
  }

  getUserGroups = async () => {
    const { getUserDetails } = this.props
    let next = true
    const limit = 10
    let offset = 0
    let userGroups = []
    while (next) {
      const payload = await getUserDetails(limit, offset)
      if (payload.data.data.currentCountOfUserGroups < limit) {
        userGroups = userGroups.concat(payload.data.data.result.UserGroups)
        next = false
      } else {
        offset += limit
        userGroups = userGroups.concat(payload.data.data.result.UserGroups)
        next = true
      }
    }
    return userGroups
  }

  getCSRole = () => {
    const { getRole, groupId, roleId } = this.props
    let role, rolePermissions, numOfPermissions

    getRole(groupId, roleId)
      .then(response => {
        role = response.data.groupRole
        rolePermissions = role.group_role_permissions
        numOfPermissions = rolePermissions.length
        role = mapRolesProperties([role])[0]
        //return this.props.getUserDetails(this.props.groupId)
        return this.getUserGroups()
      })
      .then(response => {
        const groupPermissions = response //.data.data.UserGroups
        const { user_permissions } = groupPermissions.find(groupPermission => groupPermission.GroupId === groupId)
        const parentPrivileges = mapCSToRCTPermissions(user_permissions)
        rolePermissions = rolePermissions.filter(permission =>
          parentPrivileges.find(privilege => privilege.hashId === permission.PermissionId)
        )
        // eslint-disable-next-line no-shadow
        const privileges = rolePermissions.map(permission =>
          parentPrivileges.find(privilege => privilege.hashId === permission.PermissionId)
        )
        role.privileges = privileges
        role.parent = { description: 'Root element', privileges: parentPrivileges }
        this.setState({
          role,
          numOfPrivileges: numOfPermissions,
          loading: false,
          alertMessages: false,
          alertMessagesType: 'success',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = response.error
        if (error.response) {
          switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 406:
            case 500:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
          }
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
        }
      })
  }

  setRoleTitle = description => {
    if (description) {
      return this.formatMessage(messages.editRoleTitle, { roleName: description })
    } else {
      return '...'
    }
  }

  renderTableLoadingAndError = () => {
    const { loading, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } = this.state
    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { onRoleUpdate, groupId, roleId, updateRole } = this.props
    const { loading, alertMessages, numOfPrivileges, privilegesHierarchy, role } = this.state

    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <EditRoleForm
          action={onRoleUpdate}
          groupId={groupId}
          numOfPrivileges={numOfPrivileges}
          privilegesHierarchy={privilegesHierarchy}
          role={role}
          roleId={roleId}
          updateRole={updateRole}
        />
      )
    }
  }

  render() {
    const { canReadRoles } = this.props
    const { role } = this.state
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <EditRoleFormTitle
              canReadRoles={canReadRoles}
              rolesUrl={getRolesUrl()}
              title={this.setRoleTitle(role.description)}
            />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

EditRole.propTypes = {
  canReadRoles: PropTypes.bool.isRequired,
  getRole: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onRoleUpdate: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
  updateRole: PropTypes.func.isRequired
}

export default injectIntl(EditRole)
