import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noResultsObtained: {
    id: 'SearchControl.noResultsObtained',
    description: 'No results obtained warning text',
    defaultMessage: 'No results obtained for the given dates.'
  },
  firstWarning: {
    id: 'SearchControl.firstWarning',
    description: 'Search control first warning text',
    defaultMessage: 'Looking for data in the range selected...'
  },
  secondWarning: {
    id: 'SearchControl.secondWarning',
    description: 'Search control second warning text',
    defaultMessage: 'The minimum displayed date has been adjusted '
  },
  thirdWarning: {
    id: 'SearchControl.thirdWarning',
    description: 'Search control third warning text',
    defaultMessage: 'The maximum displayed date has been adjusted '
  },
  fourthWarning: {
    id: 'SearchControl.fourthWarning',
    description: 'Search control fourth warning text',
    defaultMessage: 'and the maximum date'
  },
})

export default messages