import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { gmapsPatch } from 'components/Map'

import { DrawingGeofence } from 'modules/geofences/components/gmaps/DrawingGeofence'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const { Map, Marker } = gmapsPatch

class MapView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultCenter: { latitude: 43.295645, longitude: -1.980012 },
      defaultZoom: 3,
      mapTypeConstrolPosition: undefined,
      zoom: 3
    }
  }

  componentDidMount() {
    const { geofence, fitToBounds } = this.props

    if (geofence.shapeType) {
      fitToBounds(geofence.shapeType, geofence)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fitToBounds, geofence, groupId } = this.props

    if (!prevState.mapTypeConstrolPosition && window.google) {
      this.setState({
        mapTypeConstrolPosition: window.google.maps.ControlPosition.TOP_LEFT
      })
    }
    if (prevProps.groupId !== groupId && groupId === DEFAULT_GROUP_ID) {
      this.setState({
        defaultCenter: { latitude: 43.295645, longitude: -1.980012 },
        mapTypeConstrolPosition: undefined
      })
    }
    if (!prevProps?.geofence?.shapeType && geofence.shapeType) {
      fitToBounds(geofence.shapeType, geofence)
    }
  }

  handleZoomChanged = newZoom => {
    this.setState({
      zoom: newZoom
    })
  }

  render() {
    const {
      bounds,
      center,
      deleteGeofenceOverlay,
      geofence,
      geofenceColor,
      handleOverlayChange,
      markers,
      onCenterChanged,
      saveCurrentOverlay
    } = this.props
    const { defaultCenter, defaultZoom, mapTypeConstrolPosition, zoom } = this.state

    return (
      <div className='col-md-9' style={{ height: '100%', padding: '0 30px 0 0' }}>
        <Map
          bounds={bounds}
          center={center}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          onCenterChanged={onCenterChanged}
          onZoomChanged={this.handleZoomChanged}
          options={{
            scaleControl: false,
            mapTypeControl: true,
            panControl: true,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControlOptions: {
              position: mapTypeConstrolPosition
            }
          }}
          style={{
            width: '100%',
            height: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
          }}
          zoom={zoom}
        >
          <DrawingGeofence
            deleteGeofenceOverlay={deleteGeofenceOverlay}
            geofence={geofence}
            geofenceColor={geofenceColor}
            handleOverlayChange={handleOverlayChange}
            saveOverlay={saveCurrentOverlay}
          />
          {markers.map(marker => (
            <Marker
              key={marker.id}
              isSelected={marker.isSelected}
              label={marker.title}
              name={marker.id}
              notifications={marker.notifications}
              position={marker.position}
              status={marker.status}
            />
          ))}
        </Map>
      </div>
    )
  }
}

MapView.propTypes = {
  bounds: PropTypes.object,
  center: PropTypes.object.isRequired,
  deleteGeofenceOverlay: PropTypes.func.isRequired,
  fitToBounds: PropTypes.func.isRequired,
  geofence: PropTypes.object.isRequired,
  geofenceColor: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  handleOverlayChange: PropTypes.func.isRequired,
  markers: PropTypes.array.isRequired,
  onCenterChanged: PropTypes.func.isRequired,
  saveCurrentOverlay: PropTypes.func.isRequired
}

MapView.defaultProps = {
  bounds: undefined
}

export default MapView
