import React from 'react'
import EditorMap from './EditorMap'
import { LocationSelectorProps } from '../../props'

class LocationSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.value
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.value === null ||
      nextProps.value.latitude !== this.props.value.latitude &&
        nextProps.value.longitude !== this.props.value.longitude
    ) {
      if (this.props.value === null && nextProps.value !== null) {
        this.setState({
          selected: nextProps.value
        })
      }
    }
  }

  onChange = location => {
    this.setState({ selected: location }, () => this.props.onChange(location))
  }

  render() {
    const { selected } = this.state
    const { value, extraLocations, onChange } = this.props

    return (
      <EditorMap
        originalLocation={value}
        newLocation={onChange ? selected : value}
        extraLocations={extraLocations}
        onChange={onChange ? this.onChange : undefined}
      />
    )
  }
}

LocationSelector.propTypes = LocationSelectorProps

export default LocationSelector
