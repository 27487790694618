import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'

import TextField from 'material-ui/TextField'

import Alert from 'components/Alert'
import NotificationCriticalitySelector from 'components/NotificationCriticalitySelector'
import PageSubTitle from 'components/PageSubTitle'
import { client, logError } from 'utils/http'
import {
  getExplanationsByNotificationCriticality,
  getIconColorByNotificationCriticality
} from 'utils/notificationCriticality'

import MachinesAssignedToNotification from './MachinesAssignedToNotification'
import messages from './messages'
import NotificationsRulesSettingsStepper from '../NotificationsRulesSettingsStepper'

const momentDate = data => moment(data).format('L')

class EditNotificationFormGeneralProperties extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    if (props.notification !== null) {
      let alertSuccessMessagesClass = 'hidden'
      let successMessageTitle = ''
      let successMessage = ''

      if (
        props.history !== undefined &&
        props.history.location.state !== undefined &&
        props.history.location.state !== '' &&
        props.history.location.state === 'notificationCreated'
      ) {
        alertSuccessMessagesClass = ''
        successMessageTitle = this.formatMessage(messages.notificationCreated)
        successMessage = this.formatMessage(messages.notificationCreatedAssignUsers)
      }

      //Criticality
      let criticalitySelectedDescription = ''
      let criticalitySelectedIconColor = ''
      if (props.notification.criticality.toLowerCase() !== '') {
        criticalitySelectedDescription = getExplanationsByNotificationCriticality(
          props.notification.criticality.toLowerCase()
        )
        criticalitySelectedIconColor = getIconColorByNotificationCriticality(
          props.notification.criticality.toLowerCase()
        )
      }

      this.state = {
        notificationsUrl: props.notificationsUrl,

        notification: props.notification,

        nameValue: props.notification.name,
        descriptionValue: props.notification.description,

        enabledValue: props.notification.enabled,

        criticalityValue: props.notification.criticality.toLowerCase(),
        criticalityDescription: criticalitySelectedDescription,
        criticalityColor: criticalitySelectedIconColor,

        groupIdValue: props.notification.groupId,

        createdDateValue: momentDate(props.notification.createdDate),
        lastModifiedDateValue: momentDate(props.notification.lastModifiedDate),

        alertMessages: false,
        alertMessagesType: '',
        alertMessagesTitle: '',
        alertMessagesText: [''],

        alertSuccessMessagesClass,
        successMessageTitle,
        successMessage
      }
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  closeAlertResult = () => {
    this.setState({
      alertSuccessMessagesClass: 'hidden',
      successMessageTitle: '',
      successMessage: ''
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    let element_textError = ''
    const value = event.target.value,
      name = event.target.name,
      property_value = name + 'Value',
      property_errorText = name + 'ErrorText',
      new_state = {}

    if (event.target.value === '') {
      element_textError = this.formatMessage(messages.thisFieldIsRequired)
    }

    new_state[property_value] = value
    new_state[property_errorText] = element_textError

    this.setState(new_state)
  }

  handleEnableChange = (event, value) => {
    this.setState({
      enabledValue: value
    })
  }

  handleCriticality = (event, value) => {
    const criticalitySelected = event
    const criticalitySelectedDescription = getExplanationsByNotificationCriticality(criticalitySelected)
    const criticalitySelectedIconColor = getIconColorByNotificationCriticality(criticalitySelected)

    this.setState({
      criticalityValue: criticalitySelected,
      criticalityDescription: criticalitySelectedDescription,
      criticalityColor: criticalitySelectedIconColor
    })
  }

  handleDevicesAssignement = devicesAssigned => {
    let devicesToAssign = []
    let devicesAssignedErrorText = ''
    if (devicesAssigned !== []) {
      devicesAssignedErrorText = this.checkSelectedDevices(devicesAssigned)
      if (devicesAssignedErrorText === '') {
        devicesToAssign = devicesAssigned
      } else {
        devicesToAssign = []
      }
    } else {
      devicesAssignedErrorText = this.formatMessage(messages.assignAtLeastAMachine)
    }
    this.setState({
      devicesAssigned: devicesToAssign,
      devicesAssignedErrorText
    })
  }

  //Check if selected devices have a configuration file and the same configuration file
  checkSelectedDevices = devicesToCheck => {
    let selectionError = ''
    let devicesSelectedWithDifferentConfigurationFile = false
    let allDevicesSelectedHaveConfigurationFile = true
    let configurationFileId = ''
    let currentDeviceConfigurationFileId = ''
    let numDevicesChecked = 0
    devicesToCheck.map(device => {
      if (device.deviceConfiguration !== null) {
        currentDeviceConfigurationFileId = device.deviceConfiguration.id
        if (configurationFileId !== '') {
          if (currentDeviceConfigurationFileId !== configurationFileId) {
            devicesSelectedWithDifferentConfigurationFile = true
          }
        } else {
          configurationFileId = currentDeviceConfigurationFileId
          if (numDevicesChecked > 0) {
            devicesSelectedWithDifferentConfigurationFile = true
          }
        }
      } else {
        allDevicesSelectedHaveConfigurationFile = false
      }
      numDevicesChecked = numDevicesChecked + 1
      return device
    })

    if (devicesSelectedWithDifferentConfigurationFile) {
      selectionError = this.formatMessage(messages.deviceSelectedWithDifferentConfigurationFile)
    } else if (!allDevicesSelectedHaveConfigurationFile) {
      selectionError = this.formatMessage(messages.deviceSelectedWithoutConfigurationFile)
    }

    return selectionError
  }

  handleSave = () => {
    let save = true
    const new_state = {}

    //Comprobar errores
    if (this.state.descriptionValue === '') {
      new_state['descriptionValueErrorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    } else {
      new_state['descriptionValueErrorText'] = ''
    }

    if (this.state.criticalityValue === '') {
      new_state['criticalityValueErrorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    } else {
      new_state['criticalityValueErrorText'] = ''
    }

    if (save) {
      /*
        Get notification correctly version before call to saveNotification.
        When a request is made to modify an instance from behind, a series of actions are executed, different depending on the status of the notification, and each of these actions modifies the "version" field. When sending the modification request to the database, we must send in the version field
        the same version that is in the database, otherwise the modification request fails.
        */
      client
        .getRuleInstance(this.state.notification.hashId)
        .then(response => {
          if (response.data !== undefined && response.data.version !== undefined) {
            const notificationVersion = response.data.version
            const notificationToUpdateInDataBase = {
              hashId: this.state.notification.hashId,
              ruleHashId: this.state.notification.ruleHashId,
              name: this.state.notification.name,
              description: this.state.descriptionValue,
              criticality: this.state.criticalityValue,
              enabled: this.state.enabledValue,
              groupId: this.state.notification.groupId,
              variables: this.state.notification.variables,
              devices: this.state.notification.devices,
              users: this.state.notification.users,
              version: notificationVersion
            }
            this.saveNotification(notificationToUpdateInDataBase)
          } else {
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorText),
              alertMessagesText: [this.formatMessage(messages.errorSavingNotification)]
            })
          }
        })
        .catch(response => {
          this.setState({
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorText),
            alertMessagesText: [this.formatMessage(messages.errorSavingNotification)]
          })
        })
    } else {
      this.setState(new_state)
      window.scrollTo(0, 0)
    }
  }

  saveNotification = notificationToSave => {
    client
      .modifyRuleInstance(notificationToSave)
      .then(response => {
        if (response.data !== undefined) {
          const notificationUpdated = {
            ...this.state.notification,
            name: response.data.name,
            description: response.data.description,
            criticality: response.data.criticality,
            enabled: response.data.enabled,
            version: response.data.version
          }
          this.setState({
            notification: notificationUpdated,
            alertSuccessMessagesClass: '',
            successMessageTitle: this.formatMessage(messages.notificationUpdatedTitle),
            successMessage: this.formatMessage(messages.notificationUpdatedMessage)
          })
        } else {
          this.setState({
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorText),
            alertMessagesText: [this.formatMessage(messages.errorSavingNotification)]
          })
        }
      })
      .catch(response => {
        const error = { ...response }
        if (error.response.status !== undefined) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessagesText: [this.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              let message
              if (this.props.intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessagesText: [message]
              })
              break
            case 403: // Access denied
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessagesText: [this.formatMessage(messages.error403Text)]
              })
              break
            case 404: // API url not found
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessagesText: [this.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessagesText: [this.formatMessage(messages.error406Text)]
              })
              break
            case 409: // Data integrity violation
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '409' }),
                alertMessagesText: [this.formatMessage(messages.error409Text)]
              })
              break
            case 415: // Unsupported media type
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '415' }),
                alertMessagesText: [this.formatMessage(messages.error415Text)]
              })
              break
            case 422: // Validation failed
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
                alertMessagesText: [this.formatMessage(messages.error422Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [
                  this.formatMessage(messages.errorSavingNotification) +
                    ' ' +
                    this.formatMessage(messages.errorUndefinedText)
                ]
              })
              logError(response)
          }
        } else {
          this.setState({
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorText),
            alertMessagesText: [this.formatMessage(messages.errorSavingNotification)]
          })
        }
      })
  }

  renderDevicesAssignedToNotification = () => {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-8'>
            <h2>{this.formatMessage(messages.assignedMachines)}</h2>
          </div>
          {this.props.canReadDevices && (
            <div className='col-sm-4 text-right'>
              <Link className='button-link' to={this.props.notificationAssignDevicesUrl}>
                <Button className='primary-action-button' style={{ marginTop: 20 }}>
                  <Icon className='zmdi zmdi-plus' />
                  {this.formatMessage(messages.assignMachines)}
                </Button>
              </Link>
            </div>
          )}
        </div>
        <MachinesAssignedToNotification
          getDevicesDetail={this.props.getDevicesDetail}
          notification={this.state.notification}
        />
      </div>
    )
  }

  render() {
    return (
      <div className='container-fluid'>
        <NotificationsRulesSettingsStepper stepIndex={0} />
        <PageSubTitle title={this.formatMessage(messages.generalSettings)} />
        <Alert
          alertType={this.state.alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={this.state.alertMessagesText}
          messageTitle={this.state.alertMessagesTitle}
          showAlert={this.state.alertMessages}
        />

        <div className={this.state.alertSuccessMessagesClass}>
          <div className='alert alert-success alert-dismissible animated fadeIn'>
            <button aria-label='Close' className='close' onClick={this.closeAlertResult}>
              <span aria-hidden='true'>×</span>
            </button>
            <h4>{this.state.successMessageTitle}</h4>
            <p className='h5'>{this.state.successMessage}</p>
          </div>
        </div>
        <Paper>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.nameOfNotification)}
                  fullWidth
                  name='name'
                  value={this.state.notification.name}
                />
              </div>
              <div className='col-md-4'>
                <TextField
                  defaultValue={this.state.descriptionValue}
                  errorText={this.state.descriptionValueErrorText}
                  floatingLabelText={this.formatMessage(messages.description)}
                  fullWidth
                  name='description'
                  onChange={this.handleInputChange}
                />
              </div>
              <div className='col-md-4'>
                <FormControlLabel
                  control={<Switch checked={this.state.enabledValue} onChange={this.handleEnableChange} />}
                  label={this.formatMessage(messages.enabled)}
                  style={{
                    color: 'rgba(0, 0, 0, 0.3)',
                    top: 38,
                    fontSize: 16
                  }}
                />
                <p className='small' style={{ margin: '0 0 5px 0' }}>
                  {this.formatMessage(messages.ifEnabledIsntChecked)}
                </p>
              </div>
              <div className='col-md-2'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.createdAt)}
                  fullWidth
                  name='createdAt'
                  value={this.state.createdDateValue}
                />
              </div>
              <div className='col-md-2'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.modifiedAt)}
                  fullWidth
                  name='modifiedAt'
                  value={this.state.lastModifiedDateValue}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <NotificationCriticalitySelector
                  errorText={this.state.criticalityValueErrorText}
                  onChange={this.handleCriticality}
                  selected={this.state.criticalityValue}
                />
              </div>
              <div className='col-md-8' style={{ marginTop: 40 }}>
                {this.state.criticalityDescription !== '' ? (
                  <div>
                    <span>{this.state.criticalityDescription}</span>
                    <span>&nbsp;</span>
                    <i
                      className='zmdi zmdi-notifications'
                      style={{
                        color: this.state.criticalityColor,
                        fontSize: 26,
                        position: 'relative',
                        top: 0
                      }}
                    />
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <i
                      className='zmdi zmdi-pin'
                      style={{
                        color: this.state.criticalityColor,
                        fontSize: 26,
                        position: 'relative',
                        top: 0
                      }}
                    />
                  </div>
                ) : 
                  ''
                }
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.group)}
                  fullWidth
                  name='group'
                  value={this.state.notification.groupDescription}
                />
              </div>
              <div className='col-md-4'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.status)}
                  name='status'
                  value={this.state.notification.status.toLowerCase()}
                />
              </div>
            </div>

            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <div className='row'>
              <div className='col-md-12 text-right'>
                <Button className='primary-action-button' onClick={this.handleSave}>
                  {this.formatMessage(messages.save)}
                </Button>
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
        </Paper>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className='row'>{this.renderDevicesAssignedToNotification()}</div>
      </div>
    )
  }
}

EditNotificationFormGeneralProperties.propTypes = {
  getDevicesDetail: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  notificationAssignDevicesUrl: PropTypes.string,
  notificationsUrl: PropTypes.string
}

EditNotificationFormGeneralProperties.defaultProps = {
  notificationAssignDevicesUrl: '',
  notificationsUrl: ''
}

export default withRouter(injectIntl(EditNotificationFormGeneralProperties))
