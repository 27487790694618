import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { generateDeviceData } from 'utils/deviceDataGenerator'


import RealTimeValueTemplate from './RealTimeValueTemplate'
import messages from './messages'
import {
  manageWSSubscriptions,
  generatePartialStateFromProps,
  getValueTypes,
  getDecimals,
  withDefaultValues
} from '../utils'

const styles = theme => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  devicesButton: {
    marginBottom: 15
  },
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
})

class RealTimeValueSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const { node, dinamicData } = partialState
    const widgetData = withDefaultValues(props.data, RealTimeValueTemplate.content.params)
    const { size, bold, align, numberOfDecimals } = widgetData

    this.state = {
      ...partialState,
      size,
      bold,
      align,
      numberOfDecimals,
      numberOfDecimalsErrorText: '',
      numberOfDecimalsDisabled: Number.isInteger(getDecimals(node, dinamicData))
    }
  }

  handleChange = name => event => {
    let change = { [name]: event.target.value }
    if (name === 'numberOfDecimals') change = { ...change, [name + 'ErrorText']: '' }
    this.setState(change)
  }

  handleDinamicChange = (event, value) => {
    const { signalId = '' } = value || {}
    this.setState(state => {
      const { numberOfDecimals, numberOfDecimalsErrorText, node, valueType } = state
      let decimals = numberOfDecimals
      let decimalsDisabled = false
      let decimalsErrorText = numberOfDecimalsErrorText
      const valueTypes = getValueTypes(node, signalId)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      if (Number.isInteger(getDecimals(node, signalId))) {
        decimals = getDecimals(node, signalId)
        decimalsDisabled = true
        decimalsErrorText = ''
      }
      const change = {
        dinamicData: signalId,
        numberOfDecimalsDisabled: decimalsDisabled,
        numberOfDecimals: decimals,
        numberOfDecimalsErrorText: decimalsErrorText,
        valueTypes,
        valueType: newValueType
      }
      return change
    })
  }

  changeSelectedDevice = device => {
    this.setState({
      deviceEid: [device.EID],
      originalDevice: device,
      node: generateDeviceData(device),
      dinamicData: '',
      valueTypes: [],
      valueType: '',
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        originalDevice: nodeWithConfig,
        configurationLoading: false
      }
    })
  }

  handleChangeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleSaveSettings = () => {
    let error = false

    const { deviceEid, numberOfDecimals } = this.state

    if (deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (numberOfDecimals === '') {
      error = true
      this.setState({
        numberOfDecimalsErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    } else {
      if (numberOfDecimals < 0) {
        error = true
        this.setState({
          numberOfDecimalsErrorText: this.formatMessage(messages.onlyZeroOrHigherIsAllowed)
        })
      }
    }

    if (!error) {
      let value = ''

      const { dinamicData, valueType, wsSubscribedData, node } = this.state
      const { eid, data: propsData, devicesData, saveSettings } = this.props

      if (
        propsData.value &&
        dinamicData !== '' &&
        dinamicData === wsSubscribedData &&
        propsData.valueType === valueType &&
        eid === deviceEid[0]
      ) {
        value = { ...propsData.value }
      } else {
        value = {
          timestamp: '',
          value: ''
        }
      }
      const { size, bold, align } = this.state
      const data = {
        data: dinamicData,
        size,
        bold,
        align,
        numberOfDecimals,
        valueType,
        value
      }

      const propsNode = devicesData[deviceEid[0]]
      const deviceInfo = propsNode ? '' : node

      saveSettings(data, deviceEid, deviceInfo)

      // Sockets control parameters
      const { subscribeWidgetToWS, unsubscribeWidgetFromWS, getNodeCredentials, getAzureToken } = this.props

      manageWSSubscriptions(
        this.state,
        data,
        devicesData,
        subscribeWidgetToWS,
        unsubscribeWidgetFromWS,
        getNodeCredentials,
        getAzureToken
      )

      this.setState({
        wsSubscribedData: data.data,
        previousEid: deviceEid
      })
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  renderConfigurationStatus = () => {
    const {
      configurationLoading,
      node: { staticData }
    } = this.state

    const { classes } = this.props

    const data = staticData.find(({ name }) => name === 'hasConfiguration')

    let messageKey = ''
    let color = 'black'

    if (configurationLoading) {
      messageKey = 'Loading'
      color = '#f0ad4e'
    } else {
      if (data?.value) {
        messageKey = 'Available'
        color = 'green'
      } else {
        messageKey = 'NotAvailable'
        color = 'red'
      }
    }

    const configurationStatus = this.formatMessage(messages['configuration' + messageKey])

    return (
      <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
        <strong>{this.formatMessage(messages.configurationStatus)}: </strong>{' '}
        <span style={{ color }}>{configurationStatus}</span>
      </DialogContentText>
    )
  }

  render() {
    const { classes } = this.props
    const {
      deviceEid,
      valueTypes,
      configurationLoading,
      devicesButtonTextKey,
      devicesTableDisplayed,
      node,
      valueType,
      size,
      bold,
      align,
      numberOfDecimalsDisabled,
      numberOfDecimalsErrorText,
      numberOfDecimals,
      dinamicData
    } = this.state
    const selectedDeviceEid = deviceEid.length > 0 ? deviceEid[0] : ''
    const needsValueType = valueTypes.length > 0
    const noDinamicDataMessageKey = configurationLoading ? 'loadingConfiguratedSignals' : 'notSupportedMachine'
    const options = node.dinamicData.map(signal => {
      let signalType = this.formatMessage(messages.canBusSignals)
      if (signal.isGPS) signalType = this.formatMessage(messages.gpsSignals)
      else if (signal.isMFIO) signalType = this.formatMessage(messages.mfioSignals)
      return { ...signal, signalType }
    })

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.realTimeValueWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>
          <Button
            className='primary-action-button'
            classes={{ root: classes.devicesButton }}
            onClick={this.handleChangeDevicesTableDisplay}
          >
            {this.formatMessage(messages[devicesButtonTextKey])}
          </Button>
          <br />
          {this.renderConfigurationStatus()}
          <br />
          {devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
          <br />
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '0px 0px 10px 0px' }}>
              {this.formatMessage(messages.realTimeValueWidgetSettings)}
            </span>
          </DialogContentText>

          {options.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  classes={{ groupLabel: classes.groupLabel }}
                  fullWidth
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.signalId === value.signalId}
                  groupBy={option => option.signalType}
                  id='grouped-signals'
                  onChange={this.handleDinamicChange}
                  options={options}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={options.find(signal => signal.signalId === dinamicData) || null}
                />
              </Grid>
              {needsValueType && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='valueType-label-placeholder' shrink>
                      {this.formatMessage(messages.valueType)}
                    </InputLabel>
                    <Select onChange={this.handleChange('valueType')} value={valueType}>
                      {valueTypes.map(type => {
                        return (
                          <MenuItem key={type} value={type}>
                            {this.formatMessage(messages[type])}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DialogContentText id='alert-dialog-slide-description'>
                  <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                    {this.formatMessage(messages[noDinamicDataMessageKey])}
                  </span>
                </DialogContentText>
              </Grid>
            </Grid>
          )}

          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherValueWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='size-label-placeholder' shrink>
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  autoWidth
                  displayEmpty
                  input={<Input id='size-label-placeholder' name='size' />}
                  name='size'
                  onChange={this.handleChange('size')}
                  value={size}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='bold-label' shrink>
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='weight-label-placeholder' name='weight' />}
                  name='weight'
                  onChange={this.handleChange('bold')}
                  value={bold}
                >
                  <MenuItem value='bold'>{this.formatMessage(messages.bold)}</MenuItem>
                  <MenuItem value='normal'>{this.formatMessage(messages.normal)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='align-label' shrink>
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='align-label-placeholder' name='align' />}
                  name='align'
                  onChange={this.handleChange('align')}
                  value={align}
                >
                  <MenuItem value='left'>{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value='center'>{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value='right'>{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                key='numberOfDecimals'
                disabled={numberOfDecimalsDisabled}
                error={numberOfDecimalsErrorText !== ''}
                fullWidth
                helperText={numberOfDecimalsErrorText}
                id='numberOfDecimals'
                label={this.formatMessage(messages.numberOfDecimals)}
                onChange={this.handleChange('numberOfDecimals')}
                required
                type='number'
                value={numberOfDecimals}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={configurationLoading} onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

RealTimeValueSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  getAzureToken: PropTypes.func.isRequired,
  getNodeCredentials: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(RealTimeValueSettings))
