import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'

import ConfigurationsTable from './ConfigurationsTable'
import ConfigurationsTableTitle from './ConfigurationsTableTitle'
import messages from './messages'

import download from 'utils/fileHandler/download'
import { logError } from 'utils/http'

const styles = {
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
}
class Configurations extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    let alertVisibility = 'hidden'
    const alertMessageType = 'success'
    const alertMessageTitle = ''
    let alertMessageText = ''
    if (props.location.state !== undefined && props.location.state === 'newConfigurationApplied') {
      alertVisibility = ''
      alertMessageText = this.formatMessage(messages.nvConfigurationUpdatedSuccessfully)
    }

    this.state = {
      configType: 1,
      loadingNVConfiguration: false,
      alertVisibility,
      alertMessageType,
      alertMessageTitle,
      alertMessageText
    }
  }

  componentDidMount() {
    const { location, history } = this.props

    if (location.state === 'appliedConfiguration') {
      history.replace(location.pathname, undefined)
    }
  }

  getDeviceNonVolatileConfiguration = deviceEid => {
    const { getDeviceNonVolatileConfiguration, groupId } = this.props

    this.setState({
      loadingNVConfiguration: true
    })

    getDeviceNonVolatileConfiguration(groupId, deviceEid)
      .then(response => {
        this.setState({
          loadingNVConfiguration: false
        })
        const fileName = deviceEid.replaceAll(':', '') + '_nv_configuration.json'
        const blob = new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/octet-stream' })
        download(blob, fileName, 'application/octet-stream')
      })
      .catch(response => {
        const { error } = { ...response }
        if (error && error.response) {
          const message = error.response.data && error.response.data.message ? ': ' + error.response.data.message : ''
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessageText: [this.formatMessage(messages.error400Message) + message]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessageText: [this.formatMessage(messages.error401Message) + message]
              })
              break
            case 403: // Access denied
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessageText: [this.formatMessage(messages.error403Message) + message]
              })
              break
            case 404: // API url not found
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessageText: [this.formatMessage(messages.error404Message) + message]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessageText: [this.formatMessage(messages.error406Message) + message]
              })
              break
            case 500: // Unexpected error
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessageText: [this.formatMessage(messages.error500Message) + message]
              })
              break
            default:
              this.setState({
                loadingNVConfiguration: false,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessageText: [this.formatMessage(messages.errorUndefinedMessage) + message]
              })
              logError(error)
          }
        } else {
          this.setState({
            loadingNVConfiguration: false,
            alertVisibility: '',
            alertMessageType: 'danger',
            alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessageText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  handleConfigTypeChange = (event, value) => {
    const { history } = this.props

    this.setState({
      configType: value
    })
    if (value === 0) {
      history.push('/CS100/configurations')
    }
  }

  onAlertStateChange = args => {
    this.setState({
      ...args.alertVisibility !== undefined && { alertVisibility: args.alertVisibility },
      ...args.alertMessageType !== undefined && { alertMessageType: args.alertMessageType },
      ...args.alertMessageTitle !== undefined && { alertMessageTitle: args.alertMessageTitle },
      ...args.alertMessageText !== undefined && { alertMessageText: args.alertMessageText }
    })
  }

  handleCloseAlertClick = () => {
    this.onAlertStateChange({
      alertVisibility: 'hidden',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  render() {
    const {
      alertMessageText,
      alertMessageTitle,
      alertMessageType,
      alertVisibility,
      configType,
      loadingNVConfiguration
    } = this.state

    const {
      canCreateConfigurations,
      canEditConfigurations,
      canReadConfigurations,
      canReadDevices,
      classes,
      editConfigurationUrl,
      getCSNodes,
      groupId,
      loggedUserEmail,
      newConfigurationUrl,
      viewConfigurationUrl
    } = this.props
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <ConfigurationsTableTitle
              canCreateConfigurations={canCreateConfigurations}
              newConfigurationUrl={newConfigurationUrl}
            />
            <div className={alertVisibility}>
              <div className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}>
                <button aria-label='Close' className='close' onClick={this.handleCloseAlertClick}>
                  <span aria-hidden='true'>×</span>
                </button>
                {alertMessageTitle !== '' && <h4>{alertMessageTitle}</h4>}
                <span className='h5'>{alertMessageText}</span>
              </div>
            </div>
          </div>
          {loadingNVConfiguration && <Loading />}
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <div>
                <Tabs
                  classes={{ indicator: classes.indicator }}
                  onChange={this.handleConfigTypeChange}
                  style={{
                    boxShadow:
                      '0px 1px 5px 0px rgb(0 0 0 / 20%),' +
                      '0px 2px 2px 0px rgb(0 0 0 / 14%),' +
                      '0px 3px 1px -2px rgb(0 0 0 / 12%)',
                    maxWidth: 'fit-content'
                  }}
                  value={configType}
                >
                  <Tab
                    label='CS100'
                    style={{
                      boxShadow:
                        '0px 1px 5px 0px rgb(0 0 0 / 20%),' +
                        '0px 2px 2px 0px rgb(0 0 0 / 14%),' +
                        '0px 3px 1px -2px rgb(0 0 0 / 12%)',
                      backgroundColor: configType === 0 ? 'white' : '#dcdcdc'
                    }}
                  />
                  <Tab
                    label='CS500'
                    style={{
                      boxShadow:
                        '0px 1px 5px 0px rgb(0 0 0 / 20%),' +
                        '0px 2px 2px 0px rgb(0 0 0 / 14%),' +
                        '0px 3px 1px -2px rgb(0 0 0 / 12%)',
                      backgroundColor: configType === 1 ? 'white' : '#dcdcdc'
                    }}
                  />
                </Tabs>
              </div>
              {configType === 1 && (
                <ConfigurationsTable
                  canEditConfigurations={canEditConfigurations}
                  canReadConfigurations={canReadConfigurations}
                  canReadDevices={canReadDevices}
                  editConfigurationUrl={editConfigurationUrl}
                  getCSNodes={getCSNodes}
                  getDeviceNonVolatileConfiguration={this.getDeviceNonVolatileConfiguration}
                  groupId={groupId}
                  loggedUserEmail={loggedUserEmail}
                  viewConfigurationUrl={viewConfigurationUrl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Configurations.propTypes = {
  canCreateConfigurations: PropTypes.bool.isRequired,
  canEditConfigurations: PropTypes.bool.isRequired,
  canReadConfigurations: PropTypes.bool.isRequired,
  canReadDevices: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  editConfigurationUrl: PropTypes.func.isRequired,
  getCSNodes: PropTypes.func.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loggedUserEmail: PropTypes.string.isRequired,
  newConfigurationUrl: PropTypes.string.isRequired,
  viewConfigurationUrl: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(injectIntl(Configurations)))
