import { actions } from 'utils/http'

export const getMeasurements = (groupId, deviceEid) => dispatch =>
  dispatch(actions.getMeasurements(groupId, deviceEid)).promise.then(({ payload }) => payload)

export const getMeasurementResults = (deviceHashId, groupId, min, max, data, page, size) => dispatch =>
  dispatch(actions.getMeasurementResults(deviceHashId, groupId, min, max, data, page, size)).promise.then(
    ({ payload }) => payload
  )

export const getMeasurementResultsToExport = (deviceHashId, groupId, min, max, data, page, size) => dispatch =>
  dispatch(actions.getMeasurementResultsToExport(deviceHashId, groupId, min, max, data, page, size))
