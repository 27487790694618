import qs from 'qs'
import { generatePath } from 'react-router-dom'

const MAINTENANCES_BASE_URL = '/maintenances'

export const MAINTENANCES_TEMPLATE_URL = MAINTENANCES_BASE_URL
export const MAINTENANCES_NEW_TEMPLATE_URL = MAINTENANCES_BASE_URL + '/new'
export const MAINTENANCES_DETAIL_TEMPLATE_URL = MAINTENANCES_BASE_URL + '/:maintenanceId'
export const MAINTENANCES_EDIT_TEMPLATE_URL = MAINTENANCES_DETAIL_TEMPLATE_URL + '/edit'
export const MAINTENANCES_INSPECTION_HISTORY_TEMPLATE_URL = MAINTENANCES_DETAIL_TEMPLATE_URL + '/inspection-history'

export function getMaintenancesUrl() {
  return MAINTENANCES_TEMPLATE_URL
}

export function getNewMaintenanceUrl() {
  return MAINTENANCES_NEW_TEMPLATE_URL
}

export function getMaintenanceDetailUrl(maintenanceId) {
  return generatePath(MAINTENANCES_DETAIL_TEMPLATE_URL, { maintenanceId })
}

export function getEditMaintenanceUrl(maintenanceId) {
  return generatePath(MAINTENANCES_EDIT_TEMPLATE_URL, { maintenanceId })
}

export function getInspectionHistoryUrl(maintenanceId, deviceId) {
  const urlQueryParams = {
    deviceId
  }
  const urlQuery = qs.stringify(urlQueryParams)

  return `${generatePath(MAINTENANCES_INSPECTION_HISTORY_TEMPLATE_URL, { maintenanceId })}?${urlQuery}`
}
