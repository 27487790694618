import React from 'react'
import PropTypes from 'prop-types'

import { SVGOverlay } from 'react-map-gl'

import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'

import { props } from 'components/Map'

const FILLED_POLYGON_STYLE = {
  stroke: grey[900],
  strokeWidth: 1,
  fill: orange[700]
}

export const WATERMARK_STYLE = {
  stroke: grey[200],
  strokeWidth: 1,
  fill: orange[200]
}

export const EMPTY_POLYGON_STYLE = {
  stroke: grey[900],
  strokeWidth: 2,
  fill: 'none'
}

const ClosedPolygon = ({ features = [], style = FILLED_POLYGON_STYLE }) => (
  <SVGOverlay
    redraw={({ project }) => (
      <polygon
        points={features
          .map(({ latitude, longitude }) => {
            const [x, y] = project([longitude, latitude])
            return `${x},${y}`
          })
          .join(',')}
        style={{ strokeDasharray: 4, ...style }}
      />
    )}
  />
)

ClosedPolygon.propTypes = {
  features: PropTypes.arrayOf(props.Position),
  style: PropTypes.oneOf([
    FILLED_POLYGON_STYLE,
    WATERMARK_STYLE,
    EMPTY_POLYGON_STYLE
  ])
}

export default ClosedPolygon
