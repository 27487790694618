import { createAction } from 'redux-actions'
import moment from 'moment'

import { utils as group } from 'ducks/groups'
import { utils as map } from 'ducks/map'
import csNode from 'modules/csNode'
import { getNotificationCriticalityKeys } from 'utils/notificationCriticality'

import * as constants from './constants'
import * as t from './actionTypes'
import { parseMarker } from './utils'

//Bounding box map
import WebMercatorViewport from 'viewport-mercator-project'
import { bbox, lineString } from '@turf/turf'

import { resolvePermissions } from 'utils/permissions'
import * as c from 'utils/constants'

// ------------------------------------
// Simple action creators
// ------------------------------------

const show = createAction(t.SET_SHOWN, () => true)
const hide = createAction(t.SET_SHOWN, () => false)

const setCenter = createAction(t.SET_CENTER)
const setZoomLevel = createAction(t.SET_ZOOM_LEVEL)

const resetSearchFilter = createAction(t.RESET_SEARCH_FILTER)
const resetSearchField = createAction(t.RESET_SEARCH_FIELD)

const selectNode = createAction(t.SELECT_NODE, markerId => ({
  id: markerId,
  time: moment().valueOf()
}))
const unselectNode = createAction(t.UNSELECT_NODE, () => moment().valueOf())

const selectGroup = createAction(t.SELECT_GROUP)
const unselectGroup = createAction(t.UNSELECT_GROUP)

const addFilter = createAction(t.ADD_FILTER)
const removeFilter = createAction(t.REMOVE_FILTER)
const filterNodeByName = createAction(t.FILTER_NODE_BY_NAME)

// ------------------------------------
// Composed action creators
// ------------------------------------

const activateFilter = filterOption => (dispatch, getState) => {
  // FIXME better way to get current filters!
  const state = getState()[constants.NAME]
  if (state.currentFilters.indexOf(filterOption) > -1) {
    dispatch(removeFilter(filterOption))
  } else {
    dispatch(addFilter(filterOption))
  }
}

const addAllFilters = filterOptions => dispatch => {
  dispatch(resetSearchFilter())
  getNotificationCriticalityKeys().forEach(k => {
    dispatch(addFilter(k))
  })
}

const resetSearchFilters = () => dispatch => {
  dispatch(addAllFilters())
  dispatch(resetSearchField())
}

const checkLocations = (defaultLocation, markerLocationList = []) => {
  return markerLocationList.length === 0 ? defaultLocation : markerLocationList
}

const centerNodesView =
  (height = 800, width = 1280) =>
    (dispatch, getState) => {
      const state = getState()
      const canReadNodeDetails = resolvePermissions(state, [
        c.NODEDETAILS_VIEW,
        c.DEVICECONFIGURATION_EDIT,
        c.DEVICECONFIGURATION_READ,
        c.FIRMWARE_EDIT
      ])

      if (canReadNodeDetails) {
        let location
        let zoomView = 1.5

        // get Location of every marker
        const locationList = checkLocations(location, map.getFilteredMarkersLocations(getState()))

        if (locationList) {
          if (locationList.length > 1) {
            const feature = lineString(locationList)
            const [minLng, minLat, maxLng, maxLat] = bbox(feature)
            // construct a new viewport
            const viewport = new WebMercatorViewport({ width, height })
            const { longitude, latitude, zoom } = viewport.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat]
              ],
              {
                padding: {
                  top: 40,
                  bottom: 40,
                  left: 45,
                  right: 40
                }
              }
            )
            location = { longitude, latitude }
            zoomView = zoom
          } else if (locationList.length === 1) {
            location = {
              longitude: locationList[0][0],
              latitude: locationList[0][1]
            }
          }
        }
        dispatch(setZoomLevel(zoomView))
        dispatch(setCenter(location))
      }
    }

const onMarkerClick = (markerId, height, width) => (dispatch, getState) => {
  const state = getState()
  const canReadNodeDetails = resolvePermissions(state, [
    c.NODEDETAILS_VIEW,
    c.DEVICECONFIGURATION_EDIT,
    c.DEVICECONFIGURATION_READ,
    c.FIRMWARE_EDIT
  ])

  if (canReadNodeDetails) {
    // set center and zoom if group!
    dispatch(selectNode(markerId))
    const { isNode, id } = parseMarker(markerId)

    let location
    let zoomView = 15
    if (isNode) {
      const nd = csNode.utils.getCSNodeById(state, id)
      location = nd.location
    } else {
      dispatch(selectGroup(id))
      const grp = group.getGroup(state, id)
      location = grp.location

      // get Location of every marker
      const locationList = checkLocations(location, map.getFilteredMarkersLocations(getState()))

      if (locationList.length > 1) {
        const feature = lineString(locationList)
        const [minLng, minLat, maxLng, maxLat] = bbox(feature)
        // construct a new viewport
        const viewport = new WebMercatorViewport({ width, height })
        const { longitude, latitude, zoom } = viewport.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          {
            padding: {
              top: 40,
              bottom: 40,
              left: 45,
              right: 40
            }
          }
        )
        location = { longitude, latitude }
        zoomView = zoom
      } else if (locationList.length === 1) {
        location = {
          longitude: locationList[0][0],
          latitude: locationList[0][1]
        }
      }
      dispatch(setZoomLevel(zoomView))
    }

    if (location) {
      dispatch(setCenter(location))
    }
  }
}

const centerGeofenceView =
  (shape, form, height = 800, width = 1280) =>
    dispatch => {
      let location
      let zoomView = 1.5
      let locationList = []

      // get Location of every marker
      if (shape === 'polygon') {
        locationList = form.polygonPath.map(path => [path.lng, path.lat])
      }
      if (shape === 'circle') {
        locationList = [[form.center.lng, form.center.lat]]
      }

      if (locationList) {
        if (locationList.length > 1) {
          const feature = lineString(locationList)
          const [minLng, minLat, maxLng, maxLat] = bbox(feature)
          // construct a new viewport
          const viewport = new WebMercatorViewport({ width, height })
          const { longitude, latitude, zoom } = viewport.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat]
            ],
            {
              padding: {
                top: 40,
                bottom: 40,
                left: 45,
                right: 40
              }
            }
          )
          location = { longitude, latitude }
          zoomView = zoom
        } else if (locationList.length === 1) {
          location = {
            longitude: locationList[0][0],
            latitude: locationList[0][1]
          }
        }
      }
      dispatch(setZoomLevel(zoomView))
      dispatch(setCenter(location))
    }

// ------------------------------------
// Exported action creators
// ------------------------------------

export {
  show,
  hide,
  setCenter,
  setZoomLevel,
  resetSearchField,
  centerNodesView,
  onMarkerClick,
  unselectNode,
  selectGroup,
  unselectGroup,
  activateFilter,
  addAllFilters,
  filterNodeByName as findNode,
  resetSearchFilter as removeAllFilters,
  resetSearchFilters,
  centerGeofenceView
}
