import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/styles'

import { INSPECTION_STATES } from '../../../constants'
import messages from '../../../messages'
import { chipStyles } from '../../../styles'

const styles = {
  ...chipStyles
}

const InspectionStateChip = props => {
  const { classes, intl, state } = props

  let chipClassName = ''
  let chipText = ''
  switch (state) {
    case INSPECTION_STATES.ACTIVE:
      chipClassName = classes.chipSuccess
      chipText = intl.formatMessage(messages.active)
      break
    case INSPECTION_STATES.PENDING:
      chipClassName = classes.chipWarning
      chipText = intl.formatMessage(messages.pending)
      break
    case INSPECTION_STATES.RESOLVED:
      chipClassName = ''
      chipText = intl.formatMessage(messages.resolved)
      break
  }

  return <Chip className={classes.chipRoot + ' ' + chipClassName} label={chipText} />
}

InspectionStateChip.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  state: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(InspectionStateChip))
