import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { utils as groupUtils } from 'ducks/groups'

import * as actions from '../../../actions'
import * as c from '../../../constants'
import * as utils from '../../../reducer'

import Geofences from './Geofences'

import * as cp from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = state => ({
  isDeleteDialogOpen: utils.getIsDeleteDialogOpen(state[c.NAME]),
  deleteMessage: utils.getDeleteMessage(state[c.NAME]),
  isSelectDialogOpen: utils.getIsSelectDialogOpen(state[c.NAME]),
  selectMessage: utils.getSelectMessage(state[c.NAME]),
  isCreateAlertOpen: utils.getIscreateAlertOpen(state[c.NAME]),
  createAlertMessage: utils.getCreateAlertMessage(state[c.NAME]),
  deletingGeofenceAndRuleInstance: utils.deletingGeofenceAndRuleInstance(state[c.NAME]),
  isNewGeofenceDialogOpen: utils.getIsNewGeofenceDialogOpen(state[c.NAME]),
  isEditGeofenceDialogOpen: utils.getIsEditGeofenceDialogOpen(state[c.NAME]),

  groupId: groupUtils.getSelectedGroup(state).id,
  privileges: {
    canReadGeofence: resolvePermissions(state, cp.GEOFENCE_READ),
    canCreateGeofence: resolvePermissions(state, cp.GEOFENCE_CREATE),
    canEditGeofence: resolvePermissions(state, cp.GEOFENCE_EDIT),
    canDeleteGeofence: resolvePermissions(state, cp.GEOFENCE_DELETE),
    canViewGeotracking: resolvePermissions(state, cp.GEOTRACKING_VIEW)
  }
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Geofences))
