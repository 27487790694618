import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { actions as grp, utils } from 'ducks/groups'
import { utils as loginUtils } from 'ducks/login'
import { getUsersUrl } from '../url'
import NormalEditUser from './EditUser'

const mapStateToProps = (state, { match, history, location }) => ({
  userId: match.params.userId,
  onUserUpdate: () => {
    history.replace(location.pathname, undefined)
    history.push(getUsersUrl(), { action: 'update' })
  },
  roleId: location.state ? location.state.roleId : '',
  email: location.state ? location.state.email : '',
  group: utils.getSelectedGroup(state),
  loggedUser: loginUtils.getLoggedUser(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroupHierarchy: grp.fetchGroupHierarchy,
      getRoles: grp.getAllRolesOfGroup,
      assignRoleToUser: grp.assignRoleToUser,
      getDetailedUsers: grp.getDetailedUsersOfGroup,
      getUsersOfGroupDetails: grp.getUsersOfGroupDetails
    },
    dispatch
  )

const EditUser = withRouter(connect(mapStateToProps, mapDispatchToProps)(NormalEditUser))
export { EditUser }
