import moment from 'moment'

import translations from 'i18n/locales'

const HCTheme = () => {
  const HCThemeTMP = HCTheme || {}

  HCThemeTMP.highcharts = HCTheme.highcharts || {}

  HCThemeTMP.highcharts.theme = HCTheme.highcharts.theme || {}

  HCThemeTMP.highcharts.theme.semaforo = HCTheme.highcharts.semaforo || {}
  HCThemeTMP.highcharts.theme.semaforo.verde = '#334D5C'
  HCThemeTMP.highcharts.theme.semaforo.amarillo = '#F6BF48'
  HCThemeTMP.highcharts.theme.semaforo.rojo = '#E43031'

  HCThemeTMP.highcharts.theme.responsive = HCTheme.highcharts.responsive || {}
  HCThemeTMP.highcharts.theme.responsive.pixelesCorte = 480

  return HCThemeTMP
}

const HighchartsTheme = language => {
  const highChartsIntl = translations[language]
  return {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      panKey: 'shift',
      panning: true,
      style: {
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
      },
      zoomType: 'x'
    },

    colors: [
      '#334D5C',
      '#45B29D',
      '#EFC94C',
      '#FF8599',
      '#D5CCAD',
      '#E2793F',
      '#69395F',
      '#6DBCDB',
      '#DF5A49',
      '#FEC0A9',
      '#258C89',
      '#65C468',
      '#524B38'
    ],

    credits: {
      enabled: false
    },

    drilldown: {
      animation: {
        duration: 0
      }
    },

    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS',
            'viewData'
          ]
        }
      },
      enabled: true,
      fallbackToExportServer: false
    },

    lang: {
      decimalPoint: highChartsIntl['highcharts.decimalPoint'],
      downloadPNG: highChartsIntl['highcharts.downloadPNGImage'],
      exportButtonTitle: highChartsIntl['highcharts.export'],
      loading: highChartsIntl['highcharts.loading'],
      noData: 'No data available',
      resetZoom: highChartsIntl['highcharts.resetZoom'],
      resetZoomTitle: highChartsIntl['highcharts.resetZoom'],
      thousandsSep: highChartsIntl['highcharts.thousandsSep']
    },

    legend: {
      align: 'center',
      itemMarginBottom: 8,
      itemMarginTop: 8,
      itemStyle: {
        color: '#757575',
        fontSize: 12,
        fontWeight: 'normal'
      },
      layout: 'horizontal',
      symbolRadius: 0,
      verticalAlign: 'bottom'
    },

    navigation: {
      buttonOptions: {
        enabled: false
      }
    },

    pane: {
      background: null,
      endAngle: 90,
      startAngle: -90
    },

    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, HCTheme().highcharts.theme.semaforo.rojo],
            [0, HCTheme().highcharts.theme.semaforo.amarillo],
            [0, HCTheme().highcharts.theme.semaforo.verde]
          ]
        },
        lineWidth: 0,
        marker: {
          enabled: false
        },
        states: {
          hover: {
            lineWidthPlus: 0
          }
        },
        tooltip: {
          valueSuffix: '%'
        }
      },
      column: {
        showInLegend: false
      },
      gauge: {
        dataLabels: {
          style: {
            opacity: 1
          }
        }
      },
      line: {
        marker: {
          enabled: false
        }
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontWeight: 'normal'
          }
        },
        showInLegend: true,
        tooltip: {
          pointFormat:
            '{series.name}: <b>{point.y}</b><br/>' +
            HCTheme().highcharts.theme.percentage +
            ': <b>{point.percentage:,.2f}%</b>'
        }
      },
      series: {
        animation: {
          duration: 0
        }
      }
    },

    rangeSelector: {
      allButtonsEnabled: true,
      buttons: [
        {
          type: 'hour',
          count: 1,
          text: '1h'
        },
        {
          type: 'hour',
          count: 3,
          text: '3h'
        },
        {
          type: 'hour',
          count: 6,
          text: '6h'
        },
        {
          type: 'hour',
          count: 12,
          text: '12h'
        },
        {
          type: 'hour',
          count: 24,
          text: '24h'
        }
      ],
      enabled: true,
      inputDateFormat: '%d/%m/%Y',
      inputDateParser(value) {
        const time = value.split('-')
        let timestamp = Date.UTC(parseInt(time[0], 10), parseInt(time[1], 10) - 1, parseInt(time[2], 10))
        const addMins = moment().utcOffset() - moment(timestamp).utcOffset()
        if (addMins > 0) timestamp = moment(timestamp).add(addMins, 'minutes').valueOf()
        return timestamp
      },
      inputEditDateFormat: '%Y-%m-%d',
      inputPosition: {
        x: -50
      },
      selected: 4
    },

    responsive: {
      rules: [
        {
          chartOptions: {
            legend: {
              align: 'center',
              layout: 'horizontal',
              verticalAlign: 'bottom'
            },
            plotOptions: {
              column: {
                dataLabels: {
                  rotation: 270,
                  y: -20
                }
              },
              line: {
                dataLabels: {
                  rotation: 270,
                  y: -20
                }
              }
            }
          },
          condition: {
            maxWidth: HCTheme().highcharts.theme.responsive.pixelesCorte
          }
        }
      ]
    },

    time: {
      useUTC: false
    },

    title: {
      text: null
    },

    xAxis: {
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e. %b',
        week: '%e. %b',
        month: '%b %y',
        year: '%Y'
      }
    },

    yAxis: {
      title: {
        text: null
      }
    }
  }
}

export { HighchartsTheme, HCTheme }
