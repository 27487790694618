import { Root } from './Root'
import { connect } from 'react-redux'
import { utils } from 'ducks/language'

const mapStateToProps = state => {
  return {
    selectedLanguage: utils.getSelectedLanguage(state)
  }
}

export default connect(mapStateToProps)(Root)
