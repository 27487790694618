export const CONFIG_FIELD_CHANGE = 'CONFIG_FIELD_CHANGE'
export const CONFIG_ADD_SIGNAL = 'CONFIG_ADD_SIGNAL'
export const CONFIG_COPY_SIGNAL = 'CONFIG_COPY_SIGNAL'
export const CONFIG_ADD_MFIO = 'CONFIG_ADD_MFIO'
export const CONFIG_SIGNAL_FIELD_CHANGE = 'CONFIG_SIGNAL_FIELD_CHANGE'
export const CONFIG_MFIO_FIELD_CHANGE = 'CONFIG_MFIO_FIELD_CHANGE'
export const CONFIG_DELETE_SIGNAL = 'CONFIG_DELETE_SIGNAL'
export const CONFIG_DELETE_MFIO = 'CONFIG_DELETE_MFIO'
export const CONFIG_ERROR = 'CONFIG_ERROR'
export const CONFIG_MANAGE_ALERT = 'CONFIG_MANAGE_ALERT'
export const RESET_CONFIG = 'RESET_CONFIG'
export const SET_CONFIG = 'SET_CONFIG'
export const ADD_J1939_SIGNAL = 'ADD_J1939_SIGNAL'
export const CONFIG_SIGNAL_SA_CHANGE = 'CONFIG_SIGNAL_SA_CHANGE'
export const CONFIG_SIGNAL_PROTOCOL_NAME_CHANGE = 'CONFIG_SIGNAL_PROTOCOL_NAME_CHANGE'
export const EDITABLE_SIGNAL_ID = 'EDITABLE_SIGNAL_ID'
export const CONFIG_SIGNAL_ID_CHANGE = 'CONFIG_SIGNAL_ID_CHANGE'
export const CONFIG_SIGNAL_ID_VALIDATE = 'CONFIG_SIGNAL_ID_VALIDATE'