import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ActivateDataPlanDialog from './ActivateDataPlanDialog'
import messages from './messages'

class Actions extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      anchorEl: null,
      isOpenDspActivationDialog: false
    }
  }

  handleActionsButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleActivateDspMenuItemClick = () => {
    this.setState({ isOpenDspActivationDialog: true, anchorEl: null })
  }

  handleActivateDspDialogClose = () => {
    this.setState({ isOpenDspActivationDialog: false })
  }

  render() {
    const {
      devices,
      digitalServicePlan: { dataplanStatusId, id, name, deviceType: dSPDeviceType },
      groupId,
      updateDevices
    } = this.props
    const { anchorEl, isOpenDspActivationDialog } = this.state
    const isNewDSP = dataplanStatusId === 1

    if (isNewDSP) {
      return (
        <React.Fragment>
          <Button onClick={this.handleActionsButtonClick}>
            <Icon className='zmdi zmdi-more-vert' />
          </Button>
          <Menu anchorEl={anchorEl} onClose={this.handleMenuClose} open={Boolean(anchorEl)}>
            <MenuItem onClick={this.handleActivateDspMenuItemClick}>
              {this.formatMessage(messages.activateDSP)}
            </MenuItem>
          </Menu>
          <ActivateDataPlanDialog
            dSPDeviceType={dSPDeviceType}
            dataPlanId={id}
            dataPlanName={name}
            devices={devices}
            groupId={groupId}
            onClose={this.handleActivateDspDialogClose}
            open={isOpenDspActivationDialog}
            updateDevices={updateDevices}
          />
        </React.Fragment>
      )
    } else return null
  }
}

Actions.propTypes = {
  devices: PropTypes.array.isRequired,
  digitalServicePlan: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  updateDevices: PropTypes.func.isRequired
}

export default injectIntl(Actions)
