import React from 'react'

import Typography from '@material-ui/core/Typography'
import MaterialUILink from '@material-ui/core/Link'

import { injectIntl } from 'react-intl'
import messages from './messages'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  link: {
    color: '#337aa8'
  }
})

const Link = ({ data, classes, intl }) => {
  const { formatMessage } = intl
  return (
    <div className={data.text === '' ? 'notConfigured' : ''}>
      {data.text === '' ? (
        <Typography>{formatMessage(messages.widgetNotConfigured)}</Typography>
      ) : (
        <span
          style={{
            fontSize: data.size,
            fontWeight: data.bold,
            textAlign: data.align
          }}
        >
          <MaterialUILink
            block={true}
            target="_blank"
            rel="noopener noreferrer"
            href={data.url}
            classes={{ root: classes.link }}
          >
            {data.text}
          </MaterialUILink>
        </span>
      )}
    </div>
  )
}

export default withStyles(styles)(injectIntl(Link))
