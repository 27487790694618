import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurationsCS500'

import DM1 from './DM1'


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLocalConfigurationField: actions.changeLocalConfigurationField
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(DM1)
