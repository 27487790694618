import React from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

import { injectIntl } from 'react-intl'
import messages from './messages'

const ActionsStateGlobalActions = props => {
  const {
    canViewActions,
    selectedActions,
    selectedActionsStatus,
    clearSelectedActions,
    intl: { formatMessage }
  } = props

  const numberOfSelectedActions = selectedActions.length

  const showCancel =
    selectedActionsStatus.filter(status => {
      return status === 'waiting' || status === 'executing'
    }).length > 0

  const showRelaunch =
    selectedActionsStatus.filter(status => {
      return status === 'completed' || status === 'failed' || status === 'canceled'
    }).length > 0

  const handleGroupCancelClick = () => {
    if (selectedActions.length > -1) {
      selectedActions.map(task => {
        client
          .cancelTask(task)
          .then(() => {})
          .catch(response => {
            logError(response)
          })

        return task
      })

      clearSelectedActions()
    } else {
      return false
    }
  }

  const handleGroupRelaunchClick = () => {
    if (selectedActions.length > -1) {
      selectedActions.map(task => {
        client
          .relaunchTask(task)
          .then(() => {})
          .catch(response => {
            logError(response)
          })

        return task
      })

      clearSelectedActions()
    } else {
      return false
    }
  }

  const isClearSelectionButtonDisabled = numberOfSelectedActions < 1
  const isCancelButtonDisabled = numberOfSelectedActions < 1 || !showCancel || showCancel && showRelaunch
  const isRelaunchButtonDisabled = numberOfSelectedActions < 1 || !showRelaunch || showCancel && showRelaunch
  return (
    <Grid container={true}>
      <Grid container={true} item={true} style={{ paddingTop: 20 }} xs={12}>
        {canViewActions && (
          <React.Fragment>
            <Grid container={true} item={true} spacing={1} xs={4}>
              <Grid item={true}>
                <Button
                  disabled={true}
                  style={{
                    color: 'color: rgba(0, 0, 0, 0.87)'
                  }}
                >
                  {formatMessage(messages.selectedActions)} ({numberOfSelectedActions})
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  className="secondary-action-button"
                  disabled={isClearSelectionButtonDisabled}
                  onClick={clearSelectedActions}
                >
                  {formatMessage(messages.clearSelection)}
                </Button>
              </Grid>
            </Grid>
            <Grid container={true} item={true} justify="flex-end" spacing={1} xs={8}>
              <Grid item={true}>
                <Button className="delete-button" disabled={isCancelButtonDisabled} onClick={handleGroupCancelClick}>
                  <Icon className="zmdi zmdi-close-circle-o" />
                  {formatMessage(messages.cancel)}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  className="primary-action-button"
                  disabled={isRelaunchButtonDisabled}
                  onClick={handleGroupRelaunchClick}
                >
                  <Icon className="zmdi zmdi-replay" />
                  {formatMessage(messages.relaunch)}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  )
}

ActionsStateGlobalActions.propTypes = {
  canViewActions: PropTypes.bool.isRequired,
  clearSelectedActions: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  selectedActions: PropTypes.array.isRequired,
  selectedActionsStatus: PropTypes.array.isRequired
}

export default injectIntl(ActionsStateGlobalActions)
