const cleanEID = mac => {
  return mac.replaceAll(':', '')
}

const singlePosition = position => {
  const ret = []
  ret.push(position)
  return ret
}
export { cleanEID, singlePosition }
