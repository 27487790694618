import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import IconButton from '@material-ui/core/IconButton'

import messages from './messages'

const NavigatorOption = injectIntl(({ navigatorIsShown, intl, ...other }) => {
  const { formatMessage } = intl
  return (
    <IconButton
      className={navigatorIsShown ? 'fa fa-eye-slash' : 'fa fa-eye'}
      title={navigatorIsShown ? formatMessage(messages.hideNavigator) : formatMessage(messages.showNavigator)}
      {...other}
    />
  )
})

NavigatorOption.propTypes = {
  navigatorIsShown: PropTypes.bool
}

export { NavigatorOption }
