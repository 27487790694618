import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import csNode from 'modules/csNode'
import { utils } from 'ducks/groups'

import ProductionTestMode from './ProductionTestMode'

const mapStateToProps = (state, { match, history }) => ({
  onStartProductionTestMode: () => {
    history.replace(match.path, 'startedProductionTestMode')
  },
  onEndProductionTestMode: () => {
    history.replace(match.path, 'endedProductionTestMode')
  },
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      startProductionTestMode: csNode.actions.testDeviceSIM,
      endProductionTestMode: csNode.actions.pauseDeviceSIM
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductionTestMode))
