import subscription, { isSubscribed as isSubscrbd, isSubscribing as isSubscrbng, hasSubscribers } from './subscription'

import { actionTypes as wt } from 'middlewares/websocket'
import * as t from '../../../actionTypes'

const reducer = (state = {}, action) => {
  if (action) {
    switch (action.type) {
      case t.REQUEST_SUBSCRIPTIONS:
      case t.REQUEST_UNSUBSCRIPTIONS:
        return action.payload.topics.reduce(
          (ret, topic) => {
            ret[topic] = subscription(state[topic], action) // eslint-disable-line no-param-reassign
            return ret
          },
          { ...state }
        )

      case wt.SUBSCRIBE:
      case wt.UNSUBSCRIBE:
      case wt.SUBSCRIBE_SUCCESS:
        return action.payload.reduce(
          (ret, subs) => {
            ret[subs] = subscription(state[subs], action) // eslint-disable-line no-param-reassign
            return ret
          },
          { ...state }
        )

      case wt.UNSUBSCRIBE_SUCCESS:
        return Object.keys(state).reduce((ret, id) => {
          if (action.payload.indexOf(id) === -1) {
            ret[id] = state[id] // eslint-disable-line no-param-reassign
          }
          return ret
        }, {})

      case wt.SUBSCRIBE_FAIL:
        return action.payload.reduce(
          (ret, subs) => {
            ret[subs] = subscription(state[subs], action) // eslint-disable-line no-param-reassign
            // Forget about subscription if it has no current subscriptors
            if (!hasSubscribers(ret[subs])) {
              delete ret[subs] // eslint-disable-line no-param-reassign
            }
            return ret
          },
          { ...state }
        )

      default:
    }
  }

  return state
}

const MOUNT_ON = 'subscriptions'
export default reducer

const getSelector = substate => (substate ? substate[MOUNT_ON] : {})
const getTopicSubscription = (substate, topic) => {
  const subs = getSelector(substate)
  return subs ? subs[topic] : undefined
}

const hasPendingSubscriptions = substate =>
  Object.values(getSelector(substate)).some(v => hasSubscribers(v) && !isSubscrbd(v))

const isSubscribed = (substate, topic) => isSubscrbd(getTopicSubscription(substate, topic))
const isSubscribing = (substate, topic) => isSubscrbng(getTopicSubscription(substate, topic))
const hasNoSubscribers = (substate, topic) => !hasSubscribers(getTopicSubscription(substate, topic))

export { MOUNT_ON, hasPendingSubscriptions, isSubscribed, isSubscribing, hasNoSubscribers }
