import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import PageTitle from 'components/PageTitle'

import messages from './messages'

const EditConfigurationFormTitle = ({ configurationsUrl, deviceName, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title) + ' of ' + deviceName

  return <PageTitle back={configurationsUrl} title={title} />
}

EditConfigurationFormTitle.propTypes = {
  configurationsUrl: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(EditConfigurationFormTitle)
