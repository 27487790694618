import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DataChip from 'components/DataChip'

import { injectIntl } from 'react-intl'
import messages from './messages'

class NotificationsRulesSettingsFilter extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      criticality: {
        high: false,
        medium: false,
        low: false
      },
      enabled: {
        yes: false,
        no: false
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { criticality, enabled } = this.state

    if (criticality !== nextState.criticality || enabled !== nextState.enabled) {
      return true
    }

    return false
  }

  handleCriticalityCheck = event => {
    const { criticality } = this.state
    const { setFilterCriticality } = this.props

    const element = event.target.value
    const newCriticality = { ...criticality }

    newCriticality[element] = !criticality[element]

    this.setState(
      {
        criticality: newCriticality
      },
      () => {
        setFilterCriticality(newCriticality)
      }
    )
  }

  handleEnabledCheck = event => {
    const { enabled } = this.state
    const { setFilterEnabled } = this.props

    const element = event.target.value
    const newEnabled = { ...enabled }

    newEnabled[element] = !enabled[element]

    this.setState(
      {
        enabled: newEnabled
      },
      () => {
        setFilterEnabled(newEnabled)
      }
    )
  }

  render() {
    const { criticality, enabled } = this.state

    return (
      <Paper style={{ borderRadius: 0, padding: '15px 10px 10px 10px' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>{this.formatMessage(messages.severityUpper)}</strong>
              </p>
              <FormControlLabel
                className="filter-checkbox-line"
                control={<Checkbox checked={criticality.high} onChange={this.handleCriticalityCheck} value="high" />}
                label={<DataChip chipText="high" inline={true} intl={this.props.intl} />}
              />
              <FormControlLabel
                className="filter-checkbox-line"
                control={
                  <Checkbox checked={criticality.medium} onChange={this.handleCriticalityCheck} value="medium" />
                }
                label={<DataChip chipText="medium" inline={true} intl={this.props.intl} />}
              />
              <FormControlLabel
                className="filter-checkbox-line"
                control={<Checkbox checked={criticality.low} onChange={this.handleCriticalityCheck} value="low" />}
                label={<DataChip chipText="low" inline={true} intl={this.props.intl} />}
              />
            </div>
            <div className="col-md-6">
              <p>
                <strong>{this.formatMessage(messages.enabledFilter)}</strong>
              </p>
              <FormControlLabel
                className="filter-checkbox-line"
                control={<Checkbox checked={enabled.yes} onChange={this.handleEnabledCheck} value="yes" />}
                label={<DataChip chipText="EnabledYes" inline={true} intl={this.props.intl} />}
              />
              <FormControlLabel
                className="filter-checkbox-line"
                control={<Checkbox checked={enabled.no} onChange={this.handleEnabledCheck} value="no" />}
                label={<DataChip chipText="EnabledNo" inline={true} intl={this.props.intl} />}
              />
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}

NotificationsRulesSettingsFilter.propTypes = {
  setFilterCriticality: PropTypes.func.isRequired,
  setFilterEnabled: PropTypes.func.isRequired
}

export default injectIntl(NotificationsRulesSettingsFilter)
