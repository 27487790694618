import React from 'react'
import PropTypes from 'prop-types'
import { valueMeetsTheCondition } from '../utils'

class Box extends React.Component {
  render() {
    const dinamicDataSignalIds = this.props.dinamicData.map(signal => signal.signalId)
    const isValidData = dinamicDataSignalIds.includes(parseInt(this.props.data.data))

    let condition = !isValidData

    const { value } = this.props.data.value || { value: '' }

    if (typeof this.props.data.lengthOfBits === 'undefined') {
      condition = condition || value === '' || !valueMeetsTheCondition(this.props.data)
    } else {
      condition =
        condition || this.props.data.lengthOfBits !== 1 || value !== '' && Math.trunc(parseFloat(value)) === 1
    }

    let data = {}

    if (condition) data = this.props.data
    else if (typeof this.props.data.lengthOfBits === 'undefined') data = this.props.data.conditionalParams
    if (Object.keys(data).length > 0) {
      const topic = process.env.REACT_APP_TOPIC + 'm' + this.props.eid.replaceAll(':', '') + '/u/ds'

      return isValidData && !this.props.isSubscribedTo(this.props.eid, topic) ? (
        <div className="sk-bounce small">
          <div className="sk-bounce-dot" />
          <div className="sk-bounce-dot" />
          <div className="sk-bounce-dot" />
        </div>
      ) : (
        <div
          style={{
            borderWidth: data.width,
            borderStyle: data.style,
            borderColor: data.color,
            borderRadius: data.radius,
            background: `rgba(${data.backgroundRed}, ${data.backgroundGreen}, ${data.backgroundBlue},  ${
              data.backgroundAlpha / 10
            })`,
            height: '100%'
          }}
        />
      )
    } else {
      return null
    }
  }
}

Box.propTypes = {
  data: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired
}

export default Box
