import { connect } from 'react-redux'

import { getAdvancedSignalsData, getStaticData } from 'ducks/dashboards'
import { utils } from 'ducks/groups'

import AdvancedSignalsHistoric from './AdvancedSignalsHistoric'

const mapStateToProps = state => {
  return {
    groupId: utils.getSelectedGroup(state).id,
    advancedSignals: getAdvancedSignalsData(state),
    staticData: getStaticData(state)
  }
}

export default connect(mapStateToProps)(AdvancedSignalsHistoric)
