import PropTypes from 'prop-types'
import React from 'react'

import Loading from 'components/Loading'
import Widget from 'modules/groupDashboards/Widget'
import { generateDeviceData } from 'utils/deviceDataGenerator'
import { client, logError } from 'utils/http'

import 'modules/groupDashboards/theme/scss/theme.scss'

import { injectIntl } from 'react-intl'
import { isEqual, capitalize } from 'lodash'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { ADVANCED } from '../Widgets/Historic/constants'

import 'core-js/proposals/promise-all-settled'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.dynamicWidgetTypes = [
      'realtimevalue',
      'gauge',
      'speedometer',
      'columnchart',
      'linechart',
      'text',
      'box',
      'image'
    ]

    this.booleanWidgetTypes = ['text', 'box', 'image']
    this.gpsSignalIds = ['altitude', 'speed']

    this.state = {
      error: false,
      errorMessage: '',
      loading: true
    }
  }

  componentDidMount() {
    const { setupDashboard, editing } = this.props
    setupDashboard('cs', 0, {}, {}, {})

    if (!editing) {
      this.unsubscribeWidgetsFromWS()
      this.getGroupDashboardWidgets()
    }
  }

  async componentDidUpdate(prevProps) {
    const { widgets, subscribeToWS, unsuscribeFromWS, editing, getStaticData } = this.props
    if (prevProps.editing && !isEqual(widgets, prevProps.widgets)) {
      const devices = await this.getAndCacheDeviceData(Object.values(widgets))
      const currentWSWidgetsIds = Object.keys(widgets).filter(widgetId => this.isConnectedWidget(widgets[widgetId]))
      const prevWSWidgetsIds = Object.keys(prevProps.widgets).filter(widgetId =>
        this.isConnectedWidget(prevProps.widgets[widgetId])
      )
      const newWSWidgetsIds = currentWSWidgetsIds.filter(widgetId => !prevWSWidgetsIds.includes(widgetId))
      const oldWSWidgetsIds = prevWSWidgetsIds.filter(widgetId => !currentWSWidgetsIds.includes(widgetId))
      const credentials = await this.getAndCacheCredentials(devices)

      newWSWidgetsIds.forEach(widgetId => {
        const newWidget = widgets[widgetId]
        const [eid] = newWidget.deviceEids
        const topic = this.getTopic(newWidget, eid)
        subscribeToWS(topic, eid, credentials[eid])
      })
      oldWSWidgetsIds.forEach(widgetId => {
        const oldWidget = prevProps.widgets[widgetId]
        const [eid] = oldWidget.deviceEids
        const topic = this.getTopic(oldWidget, eid)
        unsuscribeFromWS(topic, eid)
      })
    }

    if (prevProps.editing && !editing) {
      const eidGroupedConnectedWidgets = Object.values(widgets)
        .filter(widget => this.isConnectedWidget(widget))
        .reduce((acc, current) => {
          const [eid] = current.deviceEids
          const sameEidWidgets = acc[eid] || []
          return { ...acc, [eid]: [...sameEidWidgets, current] }
        }, {})
      Object.entries(eidGroupedConnectedWidgets).forEach(([eid, groupedWidgets]) => {
        const staticData = getStaticData(eid)
        const { value: deviceType } = staticData.find(data => data.name === 'deviceType')
        this.setWidgetsFirstValue(groupedWidgets, eid, deviceType)
      })
    }
  }

  componentWillUnmount() {
    const { history, location } = this.props
    if (history.location.pathname !== location.pathname) this.unsubscribeWidgetsFromWS()
  }

  getGroupDashboardWidgets = () => {
    const { dashboardData } = this.props
    client
      .getGroupDashboardWidgets(dashboardData.hashId)
      .then(response => {
        const nodeFamily = 'cs'
        const maxZIndex = response.data.maxZIndex
        const widgets = response.data.widgets

        this.getDashboardSignals(nodeFamily, maxZIndex, widgets)
      })
      .catch(response => {
        const error = { ...response }
        switch (error.response.status) {
          case 404: // API url not found
            this.setState({})
            break
          case 410: // The requested resource is no longer available
            this.setState({
              error: true,
              errorMessage: error.response.data.message,
              loading: false
            })
            break
          default:
            this.setState({
              loading: false
            })
            logError(response)
        }
      })
  }

  getDashboardSignals = (nodeFamily, maxZIndex, widgets) => {
    const { getAzureToken, subscribeToWS, dashboardData, setupDashboard } = this.props

    let deviceEids = []
    let deviceEidsWithAdvancedSignals = []

    if (widgets) {
      Object.entries(widgets).forEach(([, widget]) => {
        if (Array.isArray(widget.deviceEids)) {
          deviceEids = [...deviceEids, ...widget.deviceEids]
          const { widgetType = '', params = {} } = widget.content
          if (widgetType === 'historic' && params.data === ADVANCED) {
            deviceEidsWithAdvancedSignals = [...deviceEidsWithAdvancedSignals, ...widget.deviceEids]
          }
        }
      })
    }
    const devicesEIDs = [...new Set(deviceEids)]
    const deviceEIDsWithAdvancedSignals = [...new Set(deviceEidsWithAdvancedSignals)]
    const { getDevicesDetail, groupId, fetchDeviceNonVolatileConfiguration } = this.props
    getDevicesDetail(groupId, devicesEIDs)
      .then(response => {
        const { devices = [] } = response.data

        const cs500Devices = devices.filter(device => device.device_type === 'CS500')
        const cs500DevicesConfigRequests = cs500Devices.map(device =>
          fetchDeviceNonVolatileConfiguration(groupId, device.EID)
        )

        Promise.allSettled(cs500DevicesConfigRequests).then(responses => {
          const cs500DevicesWithConfig = cs500Devices.map((device, index) => {
            let Configuration = null
            const configResponse = responses[index]
            if (configResponse.status === 'fulfilled') {
              const { value } = configResponse
              Configuration = value[device.EID].parsedConfiguration.deviceConfiguration
            }
            return { ...device, Configuration }
          })

          const cs100Devices = devices.filter(device => device.device_type === 'CS100')

          const devicesWithConfig = [...cs100Devices, ...cs500DevicesWithConfig]

          const devicesData = devicesWithConfig.reduce((acc, data) => {
            const deviceData = generateDeviceData(data)
            if (data.Configuration) {
              const { dinamicData } = deviceData

              const webSocketWidgets = Object.values(widgets).filter(
                widget => this.isConnectedWidget(widget, dinamicData) && widget.deviceEids.includes(data.EID)
              )

              getAzureToken(data.id, data.EID).then(resp => {
                if (webSocketWidgets.length > 0) {
                  webSocketWidgets.forEach(widget => {
                    const topic = this.getTopic(widget, data.EID)
                    subscribeToWS(topic, data.EID, resp.payload.data)
                  })
                }
              })

              this.setWidgetsFirstValue(webSocketWidgets, data.EID, data.device_type)
            }

            return { ...acc, [data.EID]: { ...deviceData } }
          }, {})

          const descriptionObject = JSON.parse(dashboardData.description)

          const settings = {
            name: dashboardData.name,
            ...descriptionObject
          }

          this.getDevicesDataWithAdvancedSignals(deviceEIDsWithAdvancedSignals, devicesData).then(
            devicesDataWithAdvancedSignals => {
              setupDashboard(nodeFamily, maxZIndex, widgets, devicesDataWithAdvancedSignals, settings)
            }
          )
        })
      })
      .catch(error => {
        const descriptionObject = JSON.parse(dashboardData.description)
        const settings = {
          name: dashboardData.name,
          ...descriptionObject
        }
        const devicesData = {}
        setupDashboard(nodeFamily, maxZIndex, widgets, devicesData, settings)
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }

  getAndCacheDeviceData = async widgets => {
    const { devicesData, getDevicesDetail, groupId, addDeviceData } = this.props
    const eids = widgets.reduce((acc, cur) => {
      let eid = ''
      if (Array.isArray(cur.deviceEids) && cur.deviceEids.length > 0) eid = cur.deviceEids[0]
      const newAcc = eid === '' ? [...acc] : [...acc, eid]
      return newAcc
    }, [])

    const newDeviceDataEids = eids.filter(eid => !devicesData[eid])
    const noDeviceDataEids = [...new Set(newDeviceDataEids)]
    let newDevicesData = []
    if (noDeviceDataEids.length > 0) {
      try {
        const response = await getDevicesDetail(groupId, noDeviceDataEids)
        const { devices } = response.data
        newDevicesData = devices.map(device => {
          const { EID } = device
          const deviceInfo = generateDeviceData(device)
          addDeviceData(EID, deviceInfo)
          return deviceInfo
        })
      } catch (error) {
        logError(error)
        newDevicesData = []
      }
    }
    const deviceDataEids = Object.keys(devicesData).filter(eid => !noDeviceDataEids.includes(eid))
    return newDevicesData.concat(deviceDataEids.map(eid => devicesData[eid]))
  }

  getAndCacheCredentials = async devices => {
    const { nodeCredentials, getAzureToken } = this.props
    const allCredentials = await devices.reduce(async (acc, cur) => {
      const { value: id } = cur.staticData.find(dataItem => dataItem.name === 'id')
      const { value: eid } = cur.staticData.find(dataItem => dataItem.name === 'EID')
      let credentials = nodeCredentials[id]
      if (!credentials) {
        const credentialsResponse = await getAzureToken(id, eid)
        credentials = credentialsResponse.payload.data
      }
      return { ...await acc, [eid]: credentials }
    }, {})
    return allCredentials
  }

  widgetCommunication = {
    onZIndexPlus: widgetId => {
      const { setWidgetZIndexPlus } = this.props
      setWidgetZIndexPlus(widgetId)
    },
    onZIndexMinus: widgetId => {
      const { setWidgetZIndexMinus } = this.props
      setWidgetZIndexMinus(widgetId)
    },
    // eslint-disable-next-line max-params
    onResize: (widgetId, height, width, x, y) => {
      const { setWidgetSize, setWidgetPosition } = this.props
      setWidgetSize(widgetId, parseInt(height, 10), parseInt(width, 10))
      setWidgetPosition(widgetId, x, y)
    },
    onDrag: (widgetId, x, y) => {
      const { setWidgetPosition } = this.props
      setWidgetPosition(widgetId, x, y)
    },
    onSettingsChange: (widgetId, data, devicesEids, deviceInfo) => {
      const { setWidgetSettings } = this.props
      setWidgetSettings(widgetId, data, devicesEids, deviceInfo)
    },
    onDelete: widgetId => {
      const { deleteWidget } = this.props
      deleteWidget(widgetId)
    },
    onClone: template => {
      const { addWidget } = this.props
      addWidget(template)
    }
  }

  unsubscribeWidgetsFromWS = () => {
    const { widgets, getStaticData, getDinamicData, unsuscribeFromWS } = this.props
    const widgetsToUnsubscribeFromWS = Object.values(widgets).filter(widget => this.isConnectedWidget(widget))
    widgetsToUnsubscribeFromWS.forEach(widget => {
      const { deviceEids } = widget
      deviceEids.forEach(eid => {
        if (getStaticData(eid).length > 0 && getDinamicData(eid).length > 0) {
          const topic = this.getTopic(widget, eid)
          unsuscribeFromWS(topic, eid)
        }
      })
    })
  }

  getTopic = (widget, eid) => {
    const { data } = widget.content.params
    const isGpsWidget = this.gpsSignalIds.includes(data)
    const topicEnding = isGpsWidget ? '/geo' : '/u/ds'
    return process.env.REACT_APP_TOPIC + 'm' + eid.replaceAll(':', '') + topicEnding
  }

  isConnectedWidget = (widget, signalsData) => {
    let eid = ''
    if (Array.isArray(widget.deviceEids) && widget.deviceEids.length > 0) eid = widget.deviceEids[0]
    else return false
    const { getDinamicData } = this.props
    const dinamicData = signalsData || getDinamicData(eid)
    const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
    const isDynamicWidget = widgetType => this.dynamicWidgetTypes.includes(widgetType)
    const isValidBooleanWidget = content =>
      this.booleanWidgetTypes.includes(content.widgetType) &&
      (typeof content.params.lengthOfBits === 'undefined' || content.params.lengthOfBits === 1)
    return (
      widget.content.params.data !== '' &&
      dinamicDataSignalIds.includes(widget.content.params.data) &&
      (isDynamicWidget(widget.content.widgetType) || isValidBooleanWidget(widget.content))
    )
  }

  setWidgetsFirstValue = (widgets, eid, deviceType) => {
    const widgetsWithoutValue = this.getWidgetsWithoutValue(widgets)
    const mfioOrCanBusWidgets = widgetsWithoutValue.filter(widget => typeof widget.content.params.data === 'number')
    this.setMfioAndCanBusWidgetsFirstValue(mfioOrCanBusWidgets, eid, deviceType)

    const gpsWidgets = widgetsWithoutValue.filter(widget => typeof widget.content.params.data === 'string')
    this.setGpsWidgetsFirstValue(gpsWidgets, eid)
  }

  getWidgetsWithoutValue = widgets =>
    widgets.filter(widget => {
      const {
        params: { value, values = [] },
        widgetType
      } = widget.content
      switch (widgetType) {
        case 'realtimevalue':
        case 'gauge':
        case 'speedometer':
        case 'text':
        case 'box':
        case 'image':
          return !value || !value.timestamp
        case 'columnchart':
        case 'linechart':
          return values.length === 0
        default:
          return false
      }
    })

  setMfioAndCanBusWidgetsFirstValue = (widgets, eid, deviceType) => {
    const signals = widgets.map(widget => {
      const { data, valueType: vType = '' } = widget.content.params
      let valueType = vType === 'value' ? '' : vType
      if (valueType) valueType = '-' + valueType.slice(-3).toLowerCase()
      return 'p-' + data + valueType
    })
    const uniqueSignals = [...new Set(signals)]

    if (uniqueSignals.length > 0) {
      const filters = uniqueSignals.join(',') + ',timestamp'
      const formattedEid = eid.replaceAll(':', '')
      client
        .getDatedAzureLogs(formattedEid, 0, moment().valueOf(), 'desc', 1000, filters)
        .then(response => {
          const { content = [], next = '' } = response.data
          uniqueSignals.forEach(signal => {
            let [, signalNumber, valueType = ''] = signal.split('-')
            signalNumber = parseInt(signalNumber)
            if (valueType) valueType = capitalize(valueType)
            let formattedTimestamp = next ? moment(next).valueOf() : ''
            let value = ''
            const firstRecord = content.find(entry => signal in entry?.azureData)
            if (typeof firstRecord !== 'undefined') {
              const { timestamp, [signal]: signalValue } = firstRecord.azureData
              formattedTimestamp = moment(timestamp).valueOf()
              value = signalValue
            }
            const data = {
              timestamp: formattedTimestamp,
              ['value' + valueType]: value,
              deviceType,
              isHistoricValue: true
            }
            if (formattedTimestamp !== '') {
              const { widgetUpdateRealTimeValues } = this.props
              widgetUpdateRealTimeValues(signalNumber, eid, [data])
            }
          })
        })
        .catch(error => {
          logError(error)
        })
    }
  }

  setGpsWidgetsFirstValue = (widgets, eid) => {
    if (widgets.length > 0) {
      const { getNodeLastGPSLocation, newGpsPoints } = this.props
      const formattedEid = eid.replaceAll(':', '')
      getNodeLastGPSLocation(formattedEid)
        .then(response => {
          const topic = process.env.REACT_APP_TOPIC + 'm' + formattedEid + '/geo'
          const meta = { topic }
          const { location, deviceType } = response.data
          let { altitude, speed } = response.data
          if (deviceType === 'CS100') {
            altitude = altitude * 0.125 - 2500
            speed /= 2500
          }
          const payload = [{ ...response.data, ...location, altitude, speed }]
          newGpsPoints(meta, payload)
        })
        .catch(error => {
          logError(error)
        })
    }
  }

  getDevicesDataWithAdvancedSignals = async (eids, devicesData) => {
    const { groupId } = this.props
    let advancedSignals = []
    if (eids.length > 0) {
      try {
        const advancedSignalsResponse = await client.getMeasurements(groupId, eids)
        advancedSignals = advancedSignalsResponse.data
      } catch (error) {
        advancedSignals = []
        logError(error)
      }
    }

    const devicesDataWithAdvancedSignals = { ...devicesData }

    advancedSignals.forEach(advancedSignal => {
      const [{ deviceEid }] = advancedSignal.measurementDevices
      const [{ targetSignal }] = advancedSignal.measurementSignals
      const targetSignalId = parseInt(targetSignal.replace('p-', ''))
      const { dinamicData } = devicesData[deviceEid]
      const index = dinamicData.findIndex(({ signalId }) => signalId === targetSignalId)
      if (index > -1) {
        devicesDataWithAdvancedSignals[deviceEid].advancedSignals.push(advancedSignal)
      }
    })

    return devicesDataWithAdvancedSignals
  }

  widgets = () => {
    const { widgets: dashboardWidgets, random, containerWidth, editing, dashboardData } = this.props
    const widgets = []
    const { zoom, width } = JSON.parse(dashboardData.description) || {}

    Object.values(dashboardWidgets).forEach(widget => {
      widgets.push(
        <Widget
          key={widget.id + random}
          configuration={widget}
          containerWidth={containerWidth}
          dashboardWidth={width}
          dashboardZoom={zoom}
          editing={editing}
          widgetCommunication={this.widgetCommunication}
          {...(widget.deviceEids && { eids: widget.deviceEids })}
        />
      )
    })
    /* eslint-enable */
    return widgets
  }

  dashboardStyle = () => {
    let styles = {
      height: '100%',
      width: '800px',
      position: 'relative',
      transform: 'none'
    }

    const { dashboardData, containerWidth, settings, editing } = this.props

    if (dashboardData.description !== undefined) {
      const height = settings.height && editing ? settings.height : JSON.parse(dashboardData.description).height
      const width = settings.width && editing ? settings.width : JSON.parse(dashboardData.description).width
      const zoom = settings.zoom && editing ? settings.zoom : JSON.parse(dashboardData.description).zoom

      styles = {
        height,
        width,
        position: 'relative',
        transform: 'none'
      }

      if (zoom === 'fit' && editing === false) {
        const reductionFactor = containerWidth / width

        styles = {
          height,
          width,
          position: 'relative',
          transformOrigin: '0 top 0',
          transform: 'scale(' + reductionFactor + ')'
        }
      }
      if (editing === false) styles = { ...styles, margin: 0 }
      else styles = { ...styles, marginLeft: 25 }
    }

    return styles
  }

  render() {
    const { editing } = this.props
    const { loading, error, errorMessage } = this.state

    return loading ? (
      <div className='PD-content-grid' style={this.dashboardStyle()}>
        <Loading />
      </div>
    ) : error ? (
      <div className='PD-content-grid' style={this.dashboardStyle()}>
        <h4>{errorMessage}</h4>
      </div>
    ) : (
      <div className={editing ? 'PD-content-grid PD-edit' : 'PD-content-grid'} style={this.dashboardStyle()}>
        {this.widgets()}
      </div>
    )
  }
}

Dashboard.propTypes = {
  addDeviceData: PropTypes.func.isRequired,
  addWidget: PropTypes.func.isRequired,
  containerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dashboardData: PropTypes.object.isRequired,
  deleteWidget: PropTypes.func.isRequired,
  devicesData: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getAzureToken: PropTypes.func.isRequired,
  getDevicesDetail: PropTypes.func.isRequired,
  getDinamicData: PropTypes.func.isRequired,
  getNodeLastGPSLocation: PropTypes.func.isRequired,
  getStaticData: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  newGpsPoints: PropTypes.func.isRequired,
  nodeCredentials: PropTypes.object.isRequired,
  random: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  setupDashboard: PropTypes.func.isRequired,
  setWidgetPosition: PropTypes.func.isRequired,
  setWidgetSettings: PropTypes.func.isRequired,
  setWidgetSize: PropTypes.func.isRequired,
  setWidgetZIndexMinus: PropTypes.func.isRequired,
  setWidgetZIndexPlus: PropTypes.func.isRequired,
  subscribeToWS: PropTypes.func.isRequired,
  unsuscribeFromWS: PropTypes.func.isRequired,
  widgets: PropTypes.object.isRequired,
  widgetUpdateRealTimeValues: PropTypes.func.isRequired
}

Dashboard.defaultProps = {
  containerWidth: 'auto',
  groupId: ''
}

export default withRouter(injectIntl(Dashboard))
