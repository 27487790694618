import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'Props.error',
    description: 'Quadrilateral bounds error',
    defaultMessage:
      'An array of 4 positions was expected for {propName} supplied to {componentName}. Validation failed.'
  }
})

export default messages
