import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'DataConsumptionEstimation.title',
    description: 'Title text',
    defaultMessage: 'Data consumption estimation'
  },
  machineUsage: {
    id: 'DataConsumptionEstimation.machineUsage',
    description: 'Machine usage percentage text',
    defaultMessage: 'Machine usage percentage'
  },
  megabytesPerMonth: {
    id: 'DataConsumptionEstimation.bytesPerMonth',
    description: 'Megabytes per month text',
    defaultMessage: 'Megabytes per month'
  }
})

export default messages