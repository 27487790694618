import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import messages from '../../messages'

const SuccessAlert = props => {
  const { intl, onClose, show } = props

  return (
    <Alert
      alertType='success'
      closeFunction={onClose}
      messageText={[intl.formatMessage(messages.notificationUpdatedMessage)]}
      messageTitle={intl.formatMessage(messages.notificationUpdatedTitle)}
      showAlert={show}
    />
  )
}

SuccessAlert.propTypes = {
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default injectIntl(SuccessAlert)
