import { defineMessages } from 'react-intl'

const messages = defineMessages({
  chartPreview: {
    id: 'EditReportChartArea.chartPreview',
    description: 'Chart preview',
    defaultMessage: 'Chart preview'
  },
  historicalDataQueryPreview: {
    id: 'EditReportChartArea.historicalDataQueryPreview',
    description: 'Historical data query preview',
    defaultMessage: 'Historical data query preview'
  },
  selectPage: {
    id: 'EditReportChartArea.selectPage',
    description: 'Select page',
    defaultMessage: 'Select page'
  }
})

export default messages
