import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import messages from './messages'

import Dialog from '@material-ui/core/Dialog'

import ProductionTestModeManagingDialog from './ProductionTestModeManagingDialog'
import ProductionTestModeInfoDialog from './ProductionTestModeInfoDialog'
//import ProductionTestModeEndedDialog from './ProductionTestModeEndedDialog'

class ProductionTestMode extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      step: 1,
      loading: false,
      showAlert: false,
      alertType: '',
      messageTitle: '',
      messageText: [''],
      endedMessageKey: 'dataDepletedParagraph'
    }
  }

  goToInfoStep = () => {
    this.setState({ step: 2 })
  }

  goToManagingStep = () => {
    this.setState({ step: 1 })
  }

  manageProductionTestMode = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        const { simStatus, startProductionTestMode, endProductionTestMode, deviceId, groupId } = this.props
        const innerSimStatus = simStatus
        let manageProductionTestMode = startProductionTestMode
        if (innerSimStatus === 'T') manageProductionTestMode = endProductionTestMode
        manageProductionTestMode(groupId, deviceId)
          .then(() => {
            const { updateDevices, onStartProductionTestMode, onEndProductionTestMode } = this.props
            updateDevices()
            if (innerSimStatus === 'T') onEndProductionTestMode()
            else onStartProductionTestMode()
            this.handleClose()
          })
          .catch(response => {
            const { error } = { ...response }
            if (error?.response?.status) {
              const { status } = error.response
              switch (status) {
                case 400:
                case 404:
                case 500:
                case 510:
                  this.setState({
                    loading: false,
                    showAlert: true,
                    alertType: 'danger',
                    messageTitle: this.formatMessage(messages.error, { number: status }),
                    messageText: [this.formatMessage(messages['error' + status + 'Message'])]
                  })
                  break
                default:
                  this.setState({
                    loading: false,
                    showAlert: true,
                    alertType: 'danger',
                    messageTitle: this.formatMessage(messages.errorUndefinedTitle),
                    messageText: [this.formatMessage(messages.errorUndefinedMessage)]
                  })
              }
            } else {
              this.setState({
                loading: false,
                showAlert: true,
                alertType: 'danger',
                messageTitle: this.formatMessage(messages.errorUndefinedTitle),
                messageText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
            }
          })
      }
    )
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleExited = () => {
    this.setState({
      step: 1,
      loading: false,
      showAlert: false,
      alertType: '',
      messageTitle: '',
      messageText: [''],
      endedMessageKey: 'dataDepletedParagraph'
    })
  }

  render() {
    const { open, simStatus, name } = this.props
    const { step, loading, endedMessageKey, ...alertProps } = this.state
    let component = (
      <ProductionTestModeManagingDialog
        simStatus={simStatus}
        onClose={this.handleClose}
        manageProductionTestMode={this.manageProductionTestMode}
        goToInfo={this.goToInfoStep}
        loading={loading}
        alertProps={alertProps}
        name={name}
      />
    )
    if (step === 2) {
      component = <ProductionTestModeInfoDialog onClose={this.handleClose} goToPreviouStep={this.goToManagingStep} />
    }
    return (
      <React.Fragment>
        <Dialog
          open={open}
          disableBackdropClick={true}
          fullWidth={true}
          maxWidth={step === 1 ? 'sm' : 'lg'}
          onExited={this.handleExited}
        >
          {component}
        </Dialog>
        {/*The Dialog behind is intended to be shown when production test mode is deactivated automatically*/}
        {/*<ProductionTestModeEndedDialog name={name} endedMessageKey={endedMessageKey} />*/}
      </React.Fragment>
    )
  }
}

ProductionTestMode.propTypes = {
  deviceId: PropTypes.string.isRequired,
  endProductionTestMode: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onEndProductionTestMode: PropTypes.func.isRequired,
  onStartProductionTestMode: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  simStatus: PropTypes.string.isRequired,
  startProductionTestMode: PropTypes.func.isRequired,
  updateDevices: PropTypes.func.isRequired
}

export default injectIntl(ProductionTestMode)
