import { defineMessages } from 'react-intl'

const namespace = 'Reports'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  addReport: {
    id: `${namespace}.addReport`,
    description: 'Add report button label',
    defaultMessage: 'New report'
  },
  reportUpdatedSuccessfully: {
    id: `${namespace}.reportUpdatedSuccessfully`,
    description: 'Report updated successfully text',
    defaultMessage: 'Report updated successfully.'
  },
  reportCreatedSuccessfully: {
    id: `${namespace}.reportCreatedSuccessfully`,
    description: 'Report created successfully text',
    defaultMessage: 'Report created successfully.'
  },
  reportDeletedSuccessfully: {
    id: `${namespace}.reportDeletedSuccessfully`,
    description: 'Report deleted successfully text',
    defaultMessage: 'Report deleted successfully.'
  },
  youCantDeleteYourOwnReport: {
    id: `${namespace}.youCantDeleteYourOwnReport`,
    description: 'You can`t delete your own report text',
    defaultMessage: 'You can`t delete your own report.'
  },
  errorDeletingReport: {
    id: `${namespace}.errorDeletingReport`,
    description: 'Error deleting report text',
    defaultMessage: 'Error deleting report'
  },
  cantDeleteReportWithChildrenNodes: {
    id: `${namespace}.cantDeleteReportWithChildrenNodes`,
    description: 'Can`t delete report with children roles text',
    defaultMessage: 'Can`t delete report with children roles.'
  },
  confirmReportToDelete: {
    id: `${namespace}.confirmReportToDelete`,
    description: 'Confirm report to delete text',
    defaultMessage: 'Confirm report to delete'
  },
  error412Message: {
    id: `${namespace}.error412Message`,
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error428Message: {
    id: `${namespace}.error428Message`,
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: `${namespace}.delete`,
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  groupActionsTitle: {
    id: `${namespace}.groupActionsTitle`,
    description: 'Group actions title',
    defaultMessage: 'Group actions'
  },
  selectAction: {
    id: `${namespace}.selectAction`,
    description: 'Select action text',
    defaultMessage: 'Select action'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Machines table total text showing rows',
    defaultMessage: 'Showing reports'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Machines table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Machines table total text of',
    defaultMessage: 'of'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Machines previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Machines next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Machine first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Machines last page button text',
    defaultMessage: 'Last'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection text',
    defaultMessage: 'Clear selection'
  },
  groupActionsText: {
    id: `${namespace}.groupActionsText`,
    description: 'Group actions text',
    defaultMessage: 'Group actions'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'Saaerch placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID',
    defaultMessage: 'Location ID'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name title',
    defaultMessage: 'Name'
  },
  timezone: {
    id: `${namespace}.timezone`,
    description: 'Timezone title',
    defaultMessage: 'Timezone'
  },
  frequency: {
    id: `${namespace}.frequency`,
    description: 'Frequency title',
    defaultMessage: 'Frequency'
  },
  outputType: {
    id: `${namespace}.outputType`,
    description: 'Output Type title',
    defaultMessage: 'Output Type'
  },
  groupName: {
    id: `${namespace}.groupName`,
    description: 'Group name title',
    defaultMessage: 'Group name'
  },
  manufacturer: {
    id: `${namespace}.manufacturer`,
    description: 'Manufacturer title',
    defaultMessage: 'Manufacturer'
  },
  mfr: {
    id: `${namespace}.mfr`,
    description: 'Manufacturer abbreviation title',
    defaultMessage: 'Mfr.'
  },
  mfrCode: {
    id: `${namespace}.code`,
    description: 'Manufacturer code title',
    defaultMessage: '{Mfr} code'
  },
  imeiOrMac: {
    id: `${namespace}.imeiOrMac`,
    description: 'IMEI or MAC title',
    defaultMessage: 'IMEI or MAC'
  },
  initialDate: {
    id: `${namespace}.initialDate`,
    description: 'Initial date title',
    defaultMessage: 'Initial date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions title',
    defaultMessage: 'Actions'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Page title',
    defaultMessage: 'Reports'
  },
  noReports: {
    id: `${namespace}.noReports`,
    description: 'No reports available',
    defaultMessage: 'No reports available'
  }
})

export default messages
