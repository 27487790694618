import { createSelector } from 'reselect'

import csNode from 'modules/csNode'

import { utils as grp } from 'ducks/groups'

import { getSelectedMarkerId, getSelectedGroup, getSearchInput, getCurrentFilters } from '../../map'
import { getRepresentableGroups } from '../groups'
import { csNodeToMarker, groupToMarker } from './marker'

const passesStatusFilters = (installationStatus, filteredStatuses) =>
  installationStatus === undefined || filteredStatuses.indexOf(installationStatus) > -1

const getGroupMarkers = createSelector(
  grp.getGroupsById,
  getRepresentableGroups,
  getCurrentFilters,
  (groupsById, representableGroups, filters) =>
    representableGroups
      .map(g => groupsById[g])
      .filter(g => passesStatusFilters(g.criticality, filters))
      .map(groupToMarker)
)

const passesNameFilter = (installationName, searchInput) => {
  const si = searchInput.trim()
  return si === '' || installationName.toLowerCase().indexOf(si.toLowerCase()) > -1
}

const getFilteredCSNodeMarkers = createSelector(
  csNode.utils.getAllCSNodes,
  grp.getAllDescendantsByParentGroupId,
  getSelectedMarkerId,
  getSelectedGroup,
  getRepresentableGroups,
  getSearchInput,
  getCurrentFilters,
  // eslint-disable-next-line max-params
  (csNodes, descendantsByParents, selectedId, selectedGroup, representableGroups, searchInput, filters) => {
    // nodes belonging to representable groups and their descendants
    const groupsWhoseNodesAreHidden = representableGroups.reduce((ret, gName) => {
      ret.push(gName)
      if (gName in descendantsByParents) {
        ret.push(...descendantsByParents[gName])
      }
      return ret
    }, [])

    // eslint-disable-next-line no-shadow
    const passesSearchFilters = node => passesNameFilter(node.name, searchInput) //&&
    //passesStatusFilters(node.notifications.criticality, filters)

    // filters not desdendant nodes if there is a selectedGroup
    // eslint-disable-next-line no-shadow
    const isDesdendantIfGroupSelected = node =>
      !selectedGroup ||
      grp.belongsToGroup(node, selectedGroup) ||
      grp.belongsToGroup(node, descendantsByParents[selectedGroup])

    const csNodeMarkers = csNodes
      .filter(
        n =>
          n.location &&
          passesSearchFilters(n) &&
          isDesdendantIfGroupSelected(n) &&
          !grp.belongsToGroup(n, groupsWhoseNodesAreHidden)
      )
      .map(nd => csNodeToMarker(nd, selectedId))

    return csNodeMarkers
  }
)

const getFilteredMarkers = createSelector(
  getGroupMarkers,
  getFilteredCSNodeMarkers,
  (groupMarkers, csNodeMarkers) => [...csNodeMarkers, ...groupMarkers]
)

const getFilteredMarkersLocations = createSelector(getFilteredMarkers, filteredMarkers =>
  filteredMarkers.map(marker => [marker.position.longitude, marker.position.latitude])
)

export { getFilteredMarkers, getFilteredMarkersLocations }
export { csNodeToMarker } from './marker'
