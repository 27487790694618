import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'
import genericMessages from '../messages'

class CloneWidget extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      cloneWidgetModal: false
    }
  }

  cloneWidget = () => {
    this.props.cloneWidget()
    this.props.closeCloneWidgetModal()
  }

  render() {
    return (
      <Dialog
        open={this.props.cloneWidgetModal}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.props.closeCloneWidgetModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.confirmWidgetToClone)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.props.closeCloneWidgetModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <strong>{this.formatMessage(messages.widgetID)}:</strong> {this.props.widgetData.id}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <strong>{this.formatMessage(messages.widgetType)}:</strong>{' '}
            {this.props.widgetData.content.widgetType === 'speedometer'
              ? 'gauge'
              : this.props.widgetData.content.widgetType}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeCloneWidgetModal}>
            {this.formatMessage(genericMessages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.cloneWidget}>
            {this.formatMessage(messages.clone)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

CloneWidget.propTypes = {
  cloneWidget: PropTypes.func.isRequired,
  cloneWidgetModal: PropTypes.bool.isRequired,
  closeCloneWidgetModal: PropTypes.func.isRequired,
  widgetData: PropTypes.object.isRequired
}

export default injectIntl(CloneWidget)
