import { defineMessages } from 'react-intl'

const messages = defineMessages({
  generalSettings: {
    id: 'NotificationsRulesSettingsStepper.generalSettings',
    description: 'General settings step label',
    defaultMessage: 'General Settings'
  },
  conditionalSignals: {
    id: 'NotificationsRulesSettingsStepper.conditionalSignals',
    description: 'Conditional Signals step label',
    defaultMessage: 'Conditional Signals'
  },
  assignedUsers: {
    id: 'NotificationsRulesSettingsStepper.assignedUsers',
    description: 'Assigned users step label',
    defaultMessage: 'Assigned Users'
  }
})

export default messages