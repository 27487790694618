import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as map from 'ducks/map'
import csNode from 'modules/csNode'
import { createNodesLoader } from 'components/LoadNodesOnMount'

import MapView from './MapView'

const load = groupId => dispatch => {
  dispatch(map.actions.show())
  dispatch(csNode.actions.fetchDynamicCSNodesLocationsAndNotifications(groupId))
}

const mapStateToProps = state => ({
  markers: map.utils.getFilteredMarkers(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...map.actions,
      load,
      unload: map.actions.hide
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(createNodesLoader(MapView))
