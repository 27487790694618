export const RULE_TYPES = {
  CAN_BUS: 'CAN_BUS',
  GPS: 'GPS',
  DM1_ALL_MESSAGES: 'DM1_ALL_MESSAGES',
  DM1_SPECIFIC_MESSAGES: 'DM1_SPECIFIC_MESSAGES'
}

export const RULE_TYPE_NAMES = {
  [RULE_TYPES.CAN_BUS]: 'ALARMS_ON_SIGNALS_RULE',
  [RULE_TYPES.GPS]: 'ALARMS_GPS_RULE',
  [RULE_TYPES.DM1_ALL_MESSAGES]: 'ALARMS_ON_DM1_EXISTENCE_RULE',
  [RULE_TYPES.DM1_SPECIFIC_MESSAGES]: 'ALARMS_DM1_RULE'
}

export const RULE_VARIABLE_CONDITION_NAME = 'condition'
export const RULE_VARIABLE_ORIGINAL_CONDITION_NAME = 'originalCondition'
