export const NAME = 'reports'

export const ALL_DEVICES_IN_GROUP = 'allDevicesInGroup'

export const GPS_SENSOR_IDS = {
  POSITION_MAP: -1,
  SPEED: 'gpsSpeed',
  ALTITUDE: 'gpsAltitude',
  HEADING: 'gpsHeading'
}

export const GPS_SENSORS = [
  {
    signalId: GPS_SENSOR_IDS.POSITION_MAP,
    label: 'GPS Position',
    value: 'GPS Position',
    unit: 'º',
    multiplier: undefined,
    offset: undefined,
    display: {}
  },
  {
    signalId: GPS_SENSOR_IDS.SPEED,
    label: 'Speed',
    value: 'Speed',
    unit: 'km/h',
    multiplier: undefined,
    offset: undefined,
    display: {}
  },
  {
    signalId: GPS_SENSOR_IDS.ALTITUDE,
    label: 'Altitude',
    value: 'Altitude',
    unit: 'm',
    multiplier: undefined,
    offset: undefined,
    display: {}
  }
]

export const DM1_SENSOR_IDS = {
  DM1_LAST_KNOWN_VALUES: -2,
  DM1_HISTORIC_VALUES: -3
}

export const DM1_SENSORS = [
  {
    signalId: DM1_SENSOR_IDS.DM1_LAST_KNOWN_VALUES,
    label: 'DM1 last known values',
    value: 'DM1 last known values',
    unit: undefined,
    multiplier: undefined,
    offset: undefined,
    display: {}
  },
  {
    signalId: DM1_SENSOR_IDS.DM1_HISTORIC_VALUES,
    label: 'DM1 historic values',
    value: 'DM1 historic values',
    unit: undefined,
    multiplier: undefined,
    offset: undefined,
    display: {}
  }
]

export const PAGE_TYPES = {
  CHART_PAGE_TYPE: 'CHART_PAGE_TYPE',
  MAP_PAGE_TYPE: 'MAP_PAGE_TYPE',
  TABLE_PAGE_TYPE: 'TABLE_PAGE_TYPE'
}

export const TABLE_TYPES = {
  DM1_HISTORIC_VALUES_TYPE: 'DM1_HISTORIC_VALUES_TYPE',
  DM1_LAST_KNOWN_VALUES_TYPE: 'DM1_LAST_KNOWN_VALUES_TYPE',
  SIGNAL_LAST_KNOWN_VALUES_TYPE: 'SIGNAL_LAST_KNOWN_VALUES_TYPE'
}

export const GPS_SENSOR_IDS_PER_PAGE_TYPE = {
  [PAGE_TYPES.CHART_PAGE_TYPE]: [GPS_SENSOR_IDS.ALTITUDE, GPS_SENSOR_IDS.SPEED],
  [PAGE_TYPES.MAP_PAGE_TYPE]: [GPS_SENSOR_IDS.POSITION_MAP],
  [PAGE_TYPES.TABLE_PAGE_TYPE]: []
}

export const DM1_SENSOR_IDS_PER_PAGE_TYPE = {
  [PAGE_TYPES.CHART_PAGE_TYPE]: [],
  [PAGE_TYPES.MAP_PAGE_TYPE]: [],
  [PAGE_TYPES.TABLE_PAGE_TYPE]: [DM1_SENSOR_IDS.DM1_LAST_KNOWN_VALUES, DM1_SENSOR_IDS.DM1_HISTORIC_VALUES]
}
