const getValueTypesAvailables = () => {
  const ALL_VALUE_TYPES = ['valueMax', 'valueMin', 'valueAvg', 'value']

  return ALL_VALUE_TYPES
}

const getValueTypesFromLogType = logType => {
  const valueTypesAvailables = getValueTypesAvailables()
  const logTypeStringBinary = logType.toString(2)
  const logTypeBinaryArray = logTypeStringBinary.split('').map(value => parseInt(value))
  const numberOfLeftZeros = valueTypesAvailables.length - logTypeBinaryArray.length
  const arrayOfZeros = Array(numberOfLeftZeros).fill(0)
  const completeLogTypeBinaryArray = arrayOfZeros.concat(logTypeBinaryArray)

  return valueTypesAvailables.filter((valueType, index) => completeLogTypeBinaryArray[index])
}

export { getValueTypesAvailables, getValueTypesFromLogType }
