import * as c from './constants'
import { getValueTypesFromLogType, getValueTypesAvailables } from '../../utils/valueTypes'

//Adapter notification detail
const adapterNotificationDetail = (
  notificationData,
  groupDescription,
  conditionVariableName,
  originalConditionVariableName
) => {
  let conditionValue = ''
  let originalConditionValue = ''
  if (notificationData.variables !== undefined && notificationData.variables.length > 0) {
    conditionValue = getVariableValue(notificationData.variables, conditionVariableName)
    originalConditionValue = getVariableValue(notificationData.variables, originalConditionVariableName)
    if (originalConditionValue !== '') {
      originalConditionValue = JSON.parse(originalConditionValue)
    }
  }

  const notificationDetail = {
    hashId: notificationData.hashId,
    ruleHashId: notificationData.ruleHashId,
    name: notificationData.name,
    description: notificationData.description,
    criticality: notificationData.criticality,
    enabled: notificationData.enabled,
    status: notificationData.status,
    notificationBody: notificationData.notificationBody,
    groupDescription,
    groupId: notificationData.groupId,

    devicesCount: notificationData.devicesCount,
    devices: notificationData.devices,

    variables: notificationData.variables,
    condition: conditionValue,
    originalCondition: originalConditionValue,

    usersCount: notificationData.usersCount,
    users: notificationData.users,

    version: notificationData.version,
    lastModifiedDate: notificationData.lastModifiedDate,
    createdAt: notificationData.createdAt,
    createdDate: notificationData.createdDate
  }

  return notificationDetail
}

const getVariableValue = (notificationVariables, variableToFindName) => {
  const { value: variableToFindValue = '' } =
    notificationVariables.find(
      (ruleVars, index) => ruleVars.name === variableToFindName && ruleVars.value !== undefined
    ) || {}

  return variableToFindValue
}

//Check if all the devices have configuration file and the configuration file is the same for all of them
// CS100 and CS500 can not have same configuration file
// As long as the cS500 configuration files do not have an identifier that can be used to see if two devices have the same configuration file, choosing more than one Cs500 is not supported.
const checkSelectedDevices = devicesToCheck => {
  let selectionError = ''
  let devicesSelectedWithDifferentConfigurationFile = false
  let allDevicesSelectedHaveConfigurationFile = true
  let devicesSelectedWithConfigurationFileWithoutSignals = false

  const cs100SelectedDevices = devicesToCheck.filter(device => device.deviceType === 'CS100')
  const cs500SelectedDevices = devicesToCheck.filter(device => device.deviceType === 'CS500')

  if (cs100SelectedDevices.length > 0 && cs500SelectedDevices.length > 0) {
    devicesSelectedWithDifferentConfigurationFile = true
  } else if (cs500SelectedDevices.length > 1) {
    devicesSelectedWithDifferentConfigurationFile = true
  } else {
    let configurationFileId = ''
    let currentDeviceConfigurationFileId = ''
    let numDevicesChecked = 0
    devicesToCheck.map(device => {
      if (device.deviceConfiguration !== undefined && device.deviceConfiguration !== null) {
        if (device.deviceType === 'CS500') {
          //Check if configuration file has signals
          let deviceWithSignals = false
          if (
            device?.deviceConfiguration?.MFIO?.length > 0 ||
            device?.deviceConfiguration?.sensorsMap?.length > 0 ||
            device?.deviceConfiguration?.dm1?.logToPortal ||
            device?.deviceConfiguration?.gps?.altitudeLogTime > 0 &&
              device?.deviceConfiguration?.gps?.altitudeLogTime < 4294967295 ||
            device?.deviceConfiguration?.gps?.speedLogTime > 0 &&
              device?.deviceConfiguration?.gps?.speedLogTime < 4294967295
          ) {
            deviceWithSignals = true
          }
          if (!deviceWithSignals) {
            devicesSelectedWithConfigurationFileWithoutSignals = true
          }
        } else {
          currentDeviceConfigurationFileId = device.deviceConfiguration.id
          if (configurationFileId !== '') {
            if (currentDeviceConfigurationFileId !== configurationFileId) {
              devicesSelectedWithDifferentConfigurationFile = true
            }
          } else {
            configurationFileId = currentDeviceConfigurationFileId
            if (numDevicesChecked > 0) {
              devicesSelectedWithDifferentConfigurationFile = true
            }
          }
        }
      } else {
        allDevicesSelectedHaveConfigurationFile = false
      }
      numDevicesChecked = numDevicesChecked + 1
      return device
    })
  }

  if (devicesSelectedWithDifferentConfigurationFile) {
    selectionError = 'devicesWithDifferentConfigurationFile'
  } else if (devicesSelectedWithConfigurationFileWithoutSignals) {
    selectionError = 'devicesWithConfigurationFileWithoutSignals'
  } else if (!allDevicesSelectedHaveConfigurationFile) {
    selectionError = 'devicesWithoutConfigurationFile'
  }

  return selectionError
}

const getAvailableCanBusSignals = (configurationFile, valueTypeTexts) => {
  let availableCanBusSignals = []

  if (configurationFile) {
    //MFIO signals (filter signals with pinFunction !== 0)
    const mfioSignals = configurationFile.MFIO.filter(sensor => sensor.pinFunction !== 0)
    //SensorsMaps signals
    const sensorsMapSignals = configurationFile.sensorsMap

    //Check "logType" field to see if there are available "max","min" and "avg" values for this signal
    //In CS100 devices only MFIO signals can have "logType" field (but not all MFIO signals have this field)
    //In CS500 devices both, MFIO and sensorsMap signals, can have "logType" field
    const mfioSignalsWithLogTypes = getSignalsWithLogTypes(mfioSignals, valueTypeTexts)
    const sensorsMapSignalsWithLogTypes = getSignalsWithLogTypes(sensorsMapSignals, valueTypeTexts)

    //MFIO signals + SensorMap signals (filter signals with sensor.frequency !== 0)
    const signalsData = mfioSignalsWithLogTypes.concat(sensorsMapSignalsWithLogTypes)
    availableCanBusSignals = signalsData
      .filter(sensor => sensor.frequency !== 0)
      .map(sensor => {
        const sensorData = {
          signalId: sensor.signalId.toString(),
          name: sensor.name,
          unit: sensor.unit,
          ...typeof sensor.lengthOfBits !== 'undefined' && { lengthOfBits: sensor.lengthOfBits },
          multiplier: sensor.multiplier,
          offset: sensor.offset,
          ...typeof sensor.decimals !== 'undefined' && { decimals: sensor.decimals },
          ...typeof sensor.logType !== 'undefined' && { logType: sensor.logType },
          ...typeof sensor.originalSignalId !== 'undefined' && { originalSignalId: sensor.originalSignalId },
          ...typeof sensor.deviceType !== 'undefined' && { deviceType: sensor.deviceType },
          ...typeof sensor.divider !== 'undefined' && { divider: sensor.divider }
        }
        return sensorData
      })
  }

  return availableCanBusSignals
}

const orderValueTypes = (a, b) => {
  if (a === 'value') {
    return -1
  } else if (b === 'value') {
    return 1
  } else {
    return 0
  }
}

const getSignalsWithLogTypes = (signals, valueTypeTexts) => {
  const valueTypes = getValueTypesAvailables()
  valueTypes.sort(orderValueTypes)

  const signalsWithLogTypes = signals.reduce((acc, signal) => {
    //Check "logType": not all signals have this field
    const logTypeValues = typeof signal.logType === 'number' ? getValueTypesFromLogType(signal.logType) : []
    const signalsValueTypes = valueTypes
      .map(valueType => {
        let newSignal = null
        if (logTypeValues.includes(valueType)) {
          let newSignalId = signal.signalId
          let newSignalName = signal.name
          let valueTypeToShow = ''
          if (valueType !== 'value') {
            valueTypeToShow = valueType.replace('value', '').toLowerCase()
            newSignalId = newSignalId + '#' + valueTypeToShow
            newSignalName = newSignalName + ' - ' + valueTypeTexts[valueTypeToShow]
          }
          newSignal = {
            ...signal,
            signalId: newSignalId,
            name: newSignalName,
            logType: valueTypeToShow
          }
        }
        return newSignal
      })
      .filter(el => el !== null)

    if (signalsValueTypes.length === 0) {
      signalsValueTypes.push(signal)
    }

    return [...acc, ...signalsValueTypes]
  }, [])

  return signalsWithLogTypes
}

const getQueryBuilderSignalOptions = availableSignals => {
  const queryBuilderSignalOptions = availableSignals.map(signal => {
    const signalUnit = signal.unit
    const signalText = signalUnit !== '' ? `${signal.name} (${signalUnit})` : signal.name

    const newField = {
      name: signal.signalId + '_#_' + signal.name,
      label: signalText,
      id: signal.signalId
    }
    return newField
  })

  return queryBuilderSignalOptions
}

const getAvailableOperators = signalType => {
  //"react-querybuilder" supports more operators but the api only these:
  const canBusOperators = [
    { name: '=', label: '=' },
    { name: '!=', label: '!=' },
    { name: '<', label: '<' },
    { name: '>', label: '>' },
    { name: '<=', label: '<=' },
    { name: '>=', label: '>=' },
    { name: 'in', label: 'in' },
    { name: 'notIn', label: 'not in' }
  ]

  const gpsOperators = [
    { name: '=', label: '=' },
    { name: '!=', label: '!=' },
    { name: '<', label: '<' },
    { name: '>', label: '>' },
    { name: '<=', label: '<=' },
    { name: '>=', label: '>=' }
  ]

  const dm1Operators = [
    { name: '=', label: '=' },
    { name: '!=', label: '!=' }
  ]

  switch (signalType) {
    case c.RULE_TYPES.CAN_BUS:
      return canBusOperators
    case c.RULE_TYPES.GPS:
      return gpsOperators
    case c.RULE_TYPES.DM1_SPECIFIC_MESSAGES:
      return dm1Operators

    default:
      return []
  }
}

//--> Rsql Expression:
//(id==2;value=ge=9);(id==2;avg==1);(id==4;value=in=(11,22,33)),(id==6;value!=34);(id==4;max==2);(id==0;min==2)
/*
  ; = AND
  , = OR
  () = grupo
*/
const getQueryAsRsqlExpression = (query, availableSignals) => {
  const { combinator, rules } = query

  let rsqlExpression = ''
  if (rules !== undefined) {
    if (rules.length > 0) {
      if (rules.length > 1) {
        let combinatorForRsql = ';' //By default "and"
        if (combinator === 'or') {
          combinatorForRsql = ','
        }
        rules.map(rule => {
          if (rule.combinator === undefined) {
            if (rsqlExpression !== '') {
              rsqlExpression = rsqlExpression + combinatorForRsql + getConditionForSignal(rule, availableSignals)
            } else {
              rsqlExpression = getConditionForSignal(rule, availableSignals)
            }
          } else {
            //Llamada recursiva
            const resultTransforRule = '(' + getQueryAsRsqlExpression(rule, availableSignals) + ')'
            if (rsqlExpression !== '') {
              rsqlExpression = rsqlExpression + combinatorForRsql + resultTransforRule
            } else {
              rsqlExpression = resultTransforRule
            }
          }
          return rule
        })
      } else {
        const rule = rules[0]
        rsqlExpression = getConditionForSignal(rule, availableSignals)
      }
    }
  }

  return rsqlExpression
}

const getConditionForSignal = (rule, availableSignals) => {
  let condition = ''

  if (rule?.field) {
    const signalId = rule.field.split('_#_')[0]
    const signal = availableSignals.find(e => e.signalId.toString() === signalId)
    if (signal !== undefined) {
      const signalOperator = getOperatorForRsql(rule.operator)
      let signalValue = getSignalValueForRuleEngine(signal, rule.value, signalOperator)
      if (signalOperator === '=in=' || signalOperator === '=out=') {
        signalValue = '(' + signalValue + ')'
      }

      if (signal.deviceType !== undefined && signal.deviceType === 'CS500') {
        condition = getConditionForSignalOfCS500(signal, signalOperator, signalValue)
      } else {
        condition = getConditionForSignalOfCS100(signal, signalOperator, signalValue)
      }
    }
  }

  return condition
}

/*
--> Rsql Expression:
 - In CS100 devices the "value" can be "value" or "max", "min, "avg" for MFIO signals with LogType only:
 (id==2;value=ge=9);(id==2;avg==1);(id==4;value=in=(11,22,33)),(id==6;value!=34);(id==4;max==2);(id==0;min==2)
*/
const getConditionForSignalOfCS100 = (signal, signalOperator, signalValue) => {
  let condition = ''
  const signalId = signal.signalId

  let signalIdForRuleEngine = signalId
  let signalValueText = 'value'

  if (signal.logType) {
    //SignalId format: "numbre#xxx" --> number#max, number#min or number#avg
    signalIdForRuleEngine = signalId.split('#')[0]
    signalValueText = signal.logType
  }

  condition = '(id==' + signalIdForRuleEngine + ';' + signalValueText + signalOperator + signalValue + ')'

  return condition
}

/*
--> Rsql Expression:
 - In CS500 devices only "value" field is used
*/
const getConditionForSignalOfCS500 = (signal, signalOperator, signalValue) => {
  let condition = ''

  let signalIdForRuleEngine = signal.originalSignalId
  if (signal.logType === '') {
    signalIdForRuleEngine = signalIdForRuleEngine + 'Value'
  } else {
    signalIdForRuleEngine = signalIdForRuleEngine + capitalizeFirstLetter(signal.logType)
  }

  condition = '(id==' + signalIdForRuleEngine + ';value' + signalOperator + signalValue + ')'

  return condition
}

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const getSignalValueForRuleEngine = (signal, signalValue, signalOperator) => {
  let signalFinalValue = signalValue

  if (signal !== undefined) {
    if (signal.deviceType !== undefined && signal.deviceType === 'CS500') {
      signalFinalValue = getSignalValueOfCS500ForRuleEngine(signal, signalValue, signalOperator)
    } else {
      signalFinalValue = getSignalValueOfCS100ForRuleEngine(signal, signalValue, signalOperator)
    }
  }

  return signalFinalValue
}

// For CS100 Devices's signals - If signal has multiplier and offset (it can be positive or negative) --> the value for Rule engine is: ((signalUserValue - signalOffset) / signalMultiplier)
const getSignalValueOfCS100ForRuleEngine = (signal, signalValue, signalOperator) => {
  let signalFinalValue = signalValue

  if (signal.multiplier !== undefined && signal.offset !== undefined) {
    const signalMultiplier = signal.multiplier
    const signalOffset = signal.offset

    if (signalOperator === '=in=' || signalOperator === '=out=') {
      const signalValueSet = signalValue.split(',')
      signalFinalValue = signalValueSet
        .reduce((acc, currentSignalValue) => {
          currentSignalValue = currentSignalValue - signalOffset
          if (signalMultiplier > 0) {
            currentSignalValue = currentSignalValue / signalMultiplier
          }
          acc.push(currentSignalValue)
          return acc
        }, [])
        .join(',')
    } else {
      signalFinalValue = signalValue - signalOffset
      if (signalMultiplier > 0) {
        signalFinalValue = signalFinalValue / signalMultiplier
      }
    }
  }

  return signalFinalValue
}

//  For CS500 Devices's signals - If signal has divider > 0 --> the value for Rule engine:  (signalValue * divider)
const getSignalValueOfCS500ForRuleEngine = (signal, signalValue, signalOperator) => {
  let signalFinalValue = signalValue

  if (signal.divider !== undefined && signal.divider > 1) {
    const signalDivider = signal.divider

    if (signalOperator === '=in=' || signalOperator === '=out=') {
      const signalValueSet = signalValue.split(',')
      signalFinalValue = signalValueSet
        .reduce((acc, currentSignalValue) => {
          currentSignalValue = currentSignalValue * signalDivider
          acc.push(currentSignalValue)
          return acc
        }, [])
        .join(',')
    } else {
      signalFinalValue = signalValue * signalDivider
    }
  }

  return signalFinalValue
}

const getOperatorForRsql = operator => {
  let rsqlOperator = ''

  switch (operator) {
    case '=':
      rsqlOperator = '=='
      break
    case '!=':
      rsqlOperator = '!='
      break
    case '<':
      rsqlOperator = '=lt='
      break
    case '<=':
      rsqlOperator = '=le='
      break
    case '>':
      rsqlOperator = '=gt='
      break
    case '>=':
      rsqlOperator = '=ge='
      break
    case 'in':
      rsqlOperator = '=in='
      break
    case 'notIn':
      rsqlOperator = '=out='
      break
    default:
      rsqlOperator = ''
  }

  return rsqlOperator
}

const getAvailableGpsSignals = deviceConfiguration => {
  const GPS_SENSORS = [
    {
      signalId: 'speed',
      name: 'Speed',
      value: 'speed',
      unit: 'km/h',
      multiplier: undefined,
      offset: undefined,
      display: {}
    },
    {
      signalId: 'altitude',
      name: 'Altitude',
      value: 'altitude',
      unit: 'm',
      multiplier: undefined,
      offset: undefined,
      display: {}
    }
  ]

  const availableGpsSignals = GPS_SENSORS.filter(sensor => {
    if (sensor.signalId === 'speed' && deviceConfiguration?.gps?.speedLogTime > 0) {
      return true
    }
    if (sensor.signalId === 'altitude' && deviceConfiguration?.gps?.altitudeLogTime > 0) {
      return true
    }
    return false
  })

  return availableGpsSignals
}

const getAvailableDm1Signals = deviceConfiguration => {
  const DM1_MESSAGE_TYPES = [
    {
      signalId: 'DM1__1_Address',
      name: 'Address',
      value: 'DM1__1_Address',
      unit: ''
    },
    {
      signalId: 'DM1__1_FMI',
      name: 'FMI',
      value: 'DM1__1_FMI',
      unit: ''
    },
    {
      signalId: 'DM1__1_SPN',
      name: 'SPN',
      value: 'DM1__1_SPN',
      unit: ''
    },
    {
      signalId: 'DM1__1_OC',
      name: 'OC',
      value: 'DM1__1_OC',
      unit: ''
    }
  ]

  if (deviceConfiguration?.dm1?.logToPortal) {
    return DM1_MESSAGE_TYPES
  } else {
    return []
  }
}

export {
  adapterNotificationDetail,
  checkSelectedDevices,
  getAvailableCanBusSignals,
  getAvailableGpsSignals,
  getAvailableDm1Signals,
  getQueryBuilderSignalOptions,
  getAvailableOperators,
  getQueryAsRsqlExpression
}
