import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import Alert from 'components/Alert'
import { logError } from 'utils/http'

import AssignableMachinesTable from './AssignableMachinesTable'
import AssignedMachinesTable from './AssignedMachinesTable'
import * as c from './constants'
import messages from './messages'


class AssignMachinesStep extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      isRuleInstanceLoading: true,
      ruleInstance: null,
      tableMode: c.VIEW_ASSIGNED_MACHINES_MODE
    }
  }

  componentDidMount() {
    this.fetchRuleInstance()
  }

  fetchRuleInstance = () => {
    const { getRuleInstance, intl, ruleInstanceHashId, updateRuleInstance } = this.props

    if (ruleInstanceHashId) {
      this.setState({
        isRuleInstanceLoading: true
      })

      getRuleInstance(ruleInstanceHashId)
        .then(response => {
          const ruleInstance = response.data
          this.setState(
            {
              ruleInstance,
              isRuleInstanceLoading: false
            },
            () => updateRuleInstance(ruleInstance)
          )
        })
        .catch(response => {
          const error = response.error

          switch (error.response.status) {
            case 400:
              this.setState({
                alertMessages: true,
                alertMessagesText: [this.formatMessage(messages.error400Text)],
                alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              break
            case 401:
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Text)
              this.setState({
                alertMessages: true,
                alertMessagesText: [message],
                alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              break
            case 403:
              this.setState({
                alertMessages: true,
                alertMessagesText: [this.formatMessage(messages.error403Text)],
                alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              break
            case 404:
              this.setState({
                alertMessages: true,
                alertMessagesText: [this.formatMessage(messages.error404Text)],
                alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              break
            case 406:
              this.setState({
                alertMessages: true,
                alertMessagesText: [this.formatMessage(messages.error406Text)],
                alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              break
            case 500:
              this.setState({
                alertMessages: true,
                alertMessagesText: [error.response.data.error_description],
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              break
            default:
              this.setState({
                alertMessages: true,
                alertMessagesText: [this.formatMessage(messages.errorUndefinedText)],
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesType: 'danger',
                isRuleInstanceLoading: false,
                ruleInstance: null
              })
              logError(error.response)
          }
        })
    }
  }

  changeTableMode = tableMode => {
    this.setState({
      tableMode
    })
  }

  setAlert = (alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText) => {
    this.setState({
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType
    })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: ''
    })
  }

  render() {
    const {
      fetchCSNodesDetails,
      getDynamicCSNodes,
      isAssignDevicesButtonDisabled,
      onNextStep,
      onPreviousStep
    } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      isRuleInstanceLoading,
      ruleInstance,
      tableMode
    } = this.state

    return (
      <div className='col-md-12' style={{ height: '80%', padding: 0 }}>
        {isRuleInstanceLoading ? (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <CircularProgress style={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <div className='col-md-12' style={{ height: '100%', padding: 0 }}>
            <div className='col-md-12' style={{ height: '10%', padding: 0 }}>
              <Divider style={{ border: 0, borderTop: '1px solid #eee' }} />
              {tableMode === c.VIEW_ASSIGNED_MACHINES_MODE && (
                <React.Fragment>
                  <div className='col-md-6' style={{ padding: 0 }}>
                    <Typography color='inherit' style={{ paddingTop: 20 }} variant='h6'>
                      {this.formatMessage(messages.assignedMachines)}
                    </Typography>
                  </div>
                  <div className='col-md-6' style={{ padding: 0, textAlign: 'end' }}>
                    <Button
                      className='primary-action-button'
                      onClick={() => this.changeTableMode(c.ASSIGN_MACHINES_MODE)}
                      style={{
                        marginTop: 14,
                        marginLeft: 5
                      }}
                    >
                      <Icon className='zmdi zmdi-plus' />
                      {this.formatMessage(messages.assignMachines)}
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {tableMode === c.ASSIGN_MACHINES_MODE && (
                <React.Fragment>
                  <div className='col-md-6' style={{ padding: 0 }}>
                    <Typography color='inherit' style={{ paddingTop: 20 }} variant='h6'>
                      {this.formatMessage(messages.assignMachines)}
                    </Typography>
                  </div>
                  <div className='col-md-6' style={{ padding: 0, textAlign: 'end' }}>
                    <Button
                      className='secondary-action-button'
                      onClick={() => this.changeTableMode(c.VIEW_ASSIGNED_MACHINES_MODE)}
                      style={{
                        marginTop: 14,
                        marginLeft: 5
                      }}
                    >
                      {this.formatMessage(messages.viewAssignedMachines)}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div style={{ height: '90%', paddingLeft: 0 }}>
              {alertMessages && (
                <div className='col-md-12' style={{ padding: 0 }}>
                  <Alert
                    alertType={alertMessagesType}
                    closeFunction={this.closeAlert}
                    messageText={alertMessagesText}
                    messageTitle={alertMessagesTitle}
                    showAlert={alertMessages}
                  />
                </div>
              )}
              {tableMode === c.VIEW_ASSIGNED_MACHINES_MODE && (
                <AssignedMachinesTable
                  fetchCSNodesDetails={fetchCSNodesDetails}
                  fetchRuleInstance={this.fetchRuleInstance}
                  ruleInstance={ruleInstance}
                  setAlert={this.setAlert}
                  tableHeight={alertMessages ? '70%' : '80%'}
                />
              )}
              {tableMode === c.ASSIGN_MACHINES_MODE && (
                <AssignableMachinesTable
                  changeTableMode={this.changeTableMode}
                  fetchCSNodesDetails={fetchCSNodesDetails}
                  fetchRuleInstance={this.fetchRuleInstance}
                  getDynamicCSNodes={getDynamicCSNodes}
                  ruleInstance={ruleInstance}
                  setAlert={this.setAlert}
                  tableHeight={alertMessages ? '70%' : '80%'}
                />
              )}
              <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <div style={{ paddingRight: 10, display: 'inline-table' }}>
                  <Button className='primary-action-button' onClick={onPreviousStep} style={{ paddingRight: 20 }}>
                    <Icon className='zmdi zmdi-chevron-left' style={{ margin: 0 }} />
                    {this.formatMessage(messages.stepBack)}
                  </Button>
                </div>

                <div style={{ display: 'inline-table' }}>
                  <Button
                    className='primary-action-button'
                    disabled={isAssignDevicesButtonDisabled}
                    onClick={onNextStep}
                    style={{ paddingLeft: 20 }}
                  >
                    {this.formatMessage(messages.nextStep)}
                    <Icon className='zmdi zmdi-chevron-right' style={{ margin: 0 }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

AssignMachinesStep.propTypes = {
  fetchCSNodesDetails: PropTypes.func.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  getRuleInstance: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isAssignDevicesButtonDisabled: PropTypes.bool.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  ruleInstanceHashId: PropTypes.string,
  updateRuleInstance: PropTypes.func.isRequired
}

AssignMachinesStep.defaultProps = {
  ruleInstanceHashId: null
}

export default injectIntl(AssignMachinesStep)
