import { defineMessages } from 'react-intl'

const namespace = 'Groups'

const messages = defineMessages({
  groupName: {
    id: `${namespace}.groupName`,
    description: 'Group name label',
    defaultMessage: 'Group name'
  },
  machines: {
    id: `${namespace}.machines`,
    description: 'Machines label',
    defaultMessage: 'machines'
  },
  createdAt: {
    id: `${namespace}.createdAt`,
    description: 'Creation date label',
    defaultMessage: 'Creation date'
  },
  modifiedAt: {
    id: `${namespace}.modifiedAt`,
    description: 'Modification date label',
    defaultMessage: 'Modification date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions label',
    defaultMessage: 'Actions'
  }
})

export default messages
