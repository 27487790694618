import axios from 'axios'
import { store } from 'index'

const instance = axios.create({
  baseURL: process.env.REACT_APP_HTTP_API,
  withCredentials: false
})

instance.interceptors.request.use(({ noAuthNeeded = false, noGroupIdNeeded = false, ...req }) => {
  const state = store.getState()
  const {
    login: { token },
    groups: {
      selectedGroup: { id: groupId }
    }
  } = state
  if (token) {
    req.headers['Authorization'] = 'Bearer ' + token // eslint-disable-line no-param-reassign
  }

  const urlHasGroupId = /(\?|&)groupId=/.test(req.url)

  if (groupId && !urlHasGroupId && !noGroupIdNeeded) {
    req.params = req.params || {} // eslint-disable-line no-param-reassign
    req.params['groupId'] = groupId // eslint-disable-line no-param-reassign
  }

  if (noAuthNeeded) delete req.headers['Authorization']

  return req
})

const getData = (url, params) => instance.get(url, params)

// Exports
export default instance

export { getData }
