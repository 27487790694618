import React from 'react'

import { Helmet } from 'react-helmet'

// To simplify the API, we get the
// Possible types: https://tutorialehtml.com/en/what-is-favicon-ico-usage/
const inferIconType = href => {
  const result = /\.\w{3}$/i.exec(href)
  if (result)
    switch (result[0]) {
      case '.ico':
        return 'image/x-icon'

      case '.png':
        return 'image/png'

      case '.gif':
        return 'image/gif'

      case '.jpg':
      case '.jpeg':
        return 'image/jpeg'

      case '.svg':
        return 'image/svg+xml'

      default:
    }
  return
}

const Favicon = ({ title, href }) => (
  // It keeps with the same value once it is set
  <Helmet>
    {title && <title>{title}</title>}
    {href && <link rel="icon" type={inferIconType(href)} href={href} />}
  </Helmet>
)

export { Favicon }
