import moment from 'moment'

const HistoricConfig = (min, max, signals, setAdvancedSignalsFormattedData) => {
  return {
    chart: {
      type: 'column',
      highstock: true,
      zoomType: 'x',
      animation: false,
      events: {
        load(e) {
          const { maxInput, minInput } = e.target.rangeSelector
          minInput.onclick = () => {
            minInput.showPicker()
          }
          minInput.onfocus = () => {
            minInput.min = '1970-01-01'
            minInput.showPicker()
          }
          minInput.onchange = async event => {
            const inputMin = moment(event.target.value).valueOf()
            setAdvancedSignalsFormattedData(signals, inputMin, max, true)
          }
          maxInput.onclick = () => {
            maxInput.showPicker()
          }
          maxInput.onfocus = () => {
            maxInput.showPicker()
          }
          maxInput.onchange = event => {
            const inputMax = moment(event.target.value).valueOf()
            setAdvancedSignalsFormattedData(signals, min, inputMax, true)
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ]
        }
      },
      chartOptions: {
        rangeSelector: {
          buttons: []
        }
      }
    },
    legend: {
      enabled: true
    },
    scrollbar: {
      enabled: false
    },

    navigator: {
      enabled: true,
      height: 60,
      series: {
        type: 'column'
      },
      xAxis: {
        ordinal: false
      }
    },
    plotOptions: {
      series: {
        showInNavigator: true
      },
      column: {
        centerInCategory: false
      }
    },
    rangeSelector: {
      buttons: [
        {
          type: 'day',
          count: 1,
          text: '1d'
        },
        {
          type: 'day',
          count: 3,
          text: '3d'
        },
        {
          type: 'week',
          count: 1,
          text: '1w'
        },
        {
          type: 'week',
          count: 2,
          text: '2w'
        },
        {
          type: 'month',
          count: 1,
          text: '1m'
        }
      ],
      height: 50
    },
    series: [],
    time: {
      useUTC: false
    },
    tooltip: {
      shared: true
    },
    xAxis: {
      ordinal: false,
      min,
      max,
      tickInterval: 24 * 3600 * 1000,
      events: {
        afterSetExtremes(e) {
          if (e.trigger === 'navigator') setAdvancedSignalsFormattedData(signals, e.min, e.max, true)
        }
      }
    }
  }
}

export default HistoricConfig
