import React from 'react'
import { Route } from 'react-router-dom'

import { NewRegistration } from './components'

import { BASE_URL } from './url'

const RegisterHome = () => <Route component={NewRegistration} path={BASE_URL} />

export default RegisterHome
