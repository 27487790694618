import { connect } from 'react-redux'

import { getStaticData } from 'ducks/dashboards'
import { actions as websocket } from 'ducks/websocket'

import Widget from './Widget'

const mapStateToProps = state => ({
  eid: getStaticData(state)[1]?.value || ''
})

const mapDispatchToProps = dispatch => ({
  subscribeWidgetToWS: (topics, eid, credentials) =>
    dispatch(websocket.subscribeRealTimeSignal(topics, eid, credentials))
})

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
