import * as c from './constants'
export const SET_CS500_CONFIGURATIONS = `${c.NAME}/SET_CS500_CONFIGURATIONS`

export const EDITABLE_SIGNAL_ID_NVC = 'EDITABLE_SIGNAL_ID_NVC'
export const NV_CONFIG_ADD_CAN_MESSAGE = 'NV_CONFIG_ADD_CAN_MESSAGE'
export const NV_CONFIG_ADD_SIGNAL = 'NV_CONFIG_ADD_SIGNAL'
export const NV_CONFIG_CAN_MESSAGE_FIELD_CHANGE = 'NV_CONFIG_CAN_MESSAGE_FIELD_CHANGE'
export const NV_CONFIG_COPY_CAN_MESSAGE = 'NV_CONFIG_COPY_CAN_MESSAGE'
export const NV_CONFIG_COPY_SIGNAL = 'NV_CONFIG_COPY_SIGNAL'
export const NV_CONFIG_DELETE_CAN_MESSAGE = 'NV_CONFIG_DELETE_CAN_MESSAGE'
export const NV_CONFIG_DELETE_SIGNAL = 'NV_CONFIG_DELETE_SIGNAL'
export const NV_CONFIG_ERROR = 'NV_CONFIG_ERROR'
export const NV_CONFIG_FIELD_CHANGE = 'NV_CONFIG_FIELD_CHANGE'
export const NV_CONFIG_MANAGE_ALERT = 'NV_CONFIG_MANAGE_ALERT'
export const NV_CONFIG_SIGNAL_FIELD_CHANGE = 'NV_CONFIG_SIGNAL_FIELD_CHANGE'
export const NV_CONFIG_SIGNAL_ID_CHANGE = 'NV_CONFIG_SIGNAL_ID_CHANGE'
export const NV_CONFIG_SIGNAL_ID_VALIDATE = 'NV_CONFIG_SIGNAL_ID_VALIDATE'
export const RESET_NV_CONFIG = 'RESET_NV_CONFIG'
export const SET_NV_CONFIG = 'SET_NV_CONFIG'
