import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'

import { SMS_SERVICE_STATES } from './constants'
import messages from './messages'

const styles = {
  chipRoot: {
    height: 'fit-content',
    fontSize: '14px'
  },
  chipDanger: {
    backgroundColor: '#d9534f',
    color: '#FFFFFF'
  },
  chipSuccess: {
    backgroundColor: '#009541',
    color: '#FFFFFF'
  }
}

const SmsServiceStateChip = props => {
  const { classes, intl, state } = props

  const { chipClassName, chipText } =
    state === SMS_SERVICE_STATES.AVAILABLE
      ? { chipClassName: classes.chipSuccess, chipText: intl.formatMessage(messages.available) }
      : { chipClassName: classes.chipDanger, chipText: intl.formatMessage(messages.notAvailable) }

  return <Chip className={classes.chipRoot + ' ' + chipClassName} label={chipText} />
}

SmsServiceStateChip.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  state: PropTypes.string
}

SmsServiceStateChip.defaultProps = {
  state: ''
}

export default withStyles(styles)(injectIntl(SmsServiceStateChip))
