import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noDashboardAvailable: {
    id: 'NoDashboard.noDashboardAvailable',
    description: 'No dashboard available',
    defaultMessage: 'No dashboard available'
  },
  clickOnToCreateTheFirstOne: {
    id: 'NoDashboard.clickOnToCreateTheFirstOne',
    description: 'Click on to create the first one.',
    defaultMessage: 'Click on { addDashboard } to create the first one.'
  },
  addDashboard: {
    id: 'NoDashboard.addDashboard',
    description: 'ADD DASHBOARD',
    defaultMessage: 'ADD DASHBOARD'
  }
})

export default messages
