import { createAction } from 'redux-actions'

import * as t from './actionTypes'
import * as utils from './reducer'

import { actions as websocket } from 'middlewares/websocket'
import { client } from 'utils/http'

const connectStart = createAction(
  t.CONNECT_START,
  () => ({}),
  ({ nodeId }) => ({ nodeId })
)
const connectFailed = createAction(
  t.CONNECT_END,
  ({ message }) => message,
  ({ nodeId }) => ({ nodeId })
)
const subscriptionsRequested = createAction(
  t.REQUEST_SUBSCRIPTIONS,
  ({ topics }) => ({ topics }),
  ({ nodeId }) => ({ nodeId })
)
const unsubscriptionsRequested = createAction(
  t.REQUEST_UNSUBSCRIPTIONS,
  ({ topics }) => ({ topics }),
  ({ nodeId }) => ({ nodeId })
)

const connect = nodeId => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // So others calling this can know about the current connection process
    dispatch(connectStart({ nodeId }))

    // Check if URL is already in reducer and has not expired
    const credentials = utils.getCredentialsForNode(getState(), nodeId)
    if (credentials) {
      dispatch(websocket.connect({ ...credentials, nodeId }))
      resolve()
    } else {
      // Get from API accessKey, secretKey, sessionToken and expiration
      // (assuming default right now)
      client
        .getNodeToken(nodeId)
        .then(response => {
          const { accessKeyId, secretAccessKey, sessionToken, expiration } = response.data
          dispatch(
            websocket.connect({
              nodeId,
              accessKeyId,
              secretAccessKey,
              sessionToken,
              expiration
            })
          )
          resolve()
        })
        .catch(err => {
          // Otherwise, it will end in connection success action
          // So others calling this can know about the current connection process
          dispatch(connectFailed({ nodeId }))
          reject()
        })
    }
  })

const subscribeToTopics = (nodeId, topics) => (dispatch, getState) => {
  const state = getState()

  // MQTT.js handles it before subscription
  const subscriptionTopics = topics.filter(
    topic =>
      // Otherwise subscription has already been dispatched
      !utils.isSubscribedTo(state, nodeId, topic) && !utils.isSubscringTo(state, nodeId, topic)
  )

  if (subscriptionTopics.length > 0) {
    dispatch(websocket.subscribe(nodeId, subscriptionTopics))
  }
}

const subscribeToTopic = (topicOrTopics, nodeId) => (dispatch, getState) => {
  const topics = topicOrTopics instanceof Array ? topicOrTopics : [topicOrTopics]
  dispatch(subscriptionsRequested({ nodeId, topics }))

  const state = getState()
  if (!utils.isConnected(state, nodeId) && !utils.isConnecting(state, nodeId)) {
    // Only if not already connected
    dispatch(connect(nodeId)).then(() => {
      dispatch(subscribeToTopics(nodeId, topics))
    })
  } else {
    dispatch(subscribeToTopics(nodeId, topics))
  }
}

const connectGPS = (nodeId, credentials) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // So others calling this can know about the current connection process
    dispatch(connectStart({ nodeId }))

    // Check if URL is already in reducer and has not expired
    if (credentials) {
      dispatch(websocket.connect({ ...credentials, nodeId }))
      resolve()
    }
  })

const subscribeGPS = (topicOrTopics, nodeId, wscredentials) => (dispatch, getState) => {
  const topics = topicOrTopics instanceof Array ? topicOrTopics : [topicOrTopics]
  dispatch(subscriptionsRequested({ nodeId, topics }))

  const state = getState()
  if (!utils.isConnected(state, nodeId) && !utils.isConnecting(state, nodeId)) {
    // Only if not already connected
    dispatch(connectGPS(nodeId, wscredentials)).then(() => {
      dispatch(subscribeToTopics(nodeId, topics))
    })
  } else {
    dispatch(subscribeToTopics(nodeId, topics))
  }
}

const connectRealTimeSignal = (nodeId, credentials) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // So others calling this can know about the current connection process
    dispatch(connectStart({ nodeId }))

    // Check if URL is already in reducer and has not expired
    if (credentials) {
      dispatch(websocket.connect({ ...credentials, nodeId }))
      resolve()
    }
  })

const subscribeRealTimeSignal = (topicOrTopics, nodeId, wscredentials) => (dispatch, getState) => {
  const topics = topicOrTopics instanceof Array ? topicOrTopics : [topicOrTopics]
  dispatch(subscriptionsRequested({ nodeId, topics }))

  const state = getState()
  if (!utils.isConnected(state, nodeId) && !utils.isConnecting(state, nodeId)) {
    dispatch(connectRealTimeSignal(nodeId, wscredentials)).then(() => {
      dispatch(subscribeToTopics(nodeId, topics))
    })
  } else {
    dispatch(subscribeToTopics(nodeId, topics))
  }
}

const connectGeneric = (nodeId, credentials) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // So others calling this can know about the current connection process
    dispatch(connectStart({ nodeId }))

    // Check if URL is already in reducer and has not expired
    if (credentials) {
      dispatch(websocket.connect({ ...credentials, nodeId }))
      resolve()
    }
  })

const subscribeGeneric = (topicOrTopics, nodeId, wscredentials) => (dispatch, getState) => {
  const topics = topicOrTopics instanceof Array ? topicOrTopics : [topicOrTopics]
  dispatch(subscriptionsRequested({ nodeId, topics }))

  const state = getState()
  if (!utils.isConnected(state, nodeId) && !utils.isConnecting(state, nodeId)) {
    dispatch(connectGeneric(nodeId, wscredentials)).then(() => {
      dispatch(subscribeToTopics(nodeId, topics))
    })
  } else {
    dispatch(subscribeToTopics(nodeId, topics))
  }
}

const unsubscribe = (topicOrTopics, nodeId) => (dispatch, getState) => {
  const topics = topicOrTopics instanceof Array ? topicOrTopics : [topicOrTopics]
  dispatch(unsubscriptionsRequested({ nodeId, topics }))
  // Important: the state must be the one after 'unsubscriptionsRequested'
  const state = getState()
  const unsubscriptionTopics = topics.filter(topic => utils.hasNoSubscribers(state, nodeId, topic))
  if (unsubscriptionTopics.length > 0) {
    dispatch(websocket.unsubscribe(nodeId, unsubscriptionTopics)).then(() => {
      if (!utils.isActive(getState(), nodeId)) {
        // It's important to get a new state
        dispatch(websocket.disconnect({ nodeId }))
      }
    })
  }
}

const send = websocket.send

// ------------------------------------
// Exported action creators
// ------------------------------------

export { subscribeToTopic as subscribe, subscribeGPS, unsubscribe, send, subscribeRealTimeSignal, subscribeGeneric }
