import * as t from './actionTypes'
import * as url from '../url'
import { createRequestAction } from './utils'

const getRules = groupId => createRequestAction(t.RULES, url.rules(groupId), 'get')

const getRule = ruleHashId => createRequestAction(t.RULE, url.rule(ruleHashId), 'get')

const getRuleInstances = (groupId, query) =>
  createRequestAction(t.RULE_INSTANCES, url.getRuleInstances(groupId, query), 'get')

const getRuleInstance = ruleInstanceHashId =>
  createRequestAction(t.RULE_INSTANCE, url.ruleInstance(ruleInstanceHashId), 'get')

const newRuleInstance = newRuleInstance =>
  createRequestAction(t.CREATE_RULE_INSTANCE, url.rulesInstances(), 'post', newRuleInstance)

const editRuleInstance = ruleInstance => {
  const ret = createRequestAction(t.EDIT_RULE_INSTANCE, url.ruleInstance(ruleInstance.hashId), 'put', ruleInstance)
  ret.payload.request.headers = {
    'if-match': ruleInstance.version
  }
  return ret
}

const deleteRuleInstance = ruleInstance => {
  const ret = createRequestAction(t.DELETE_RULE_INSTANCE, url.ruleInstance(ruleInstance.hashId), 'delete')
  ret.payload.request.headers = {
    'if-match': ruleInstance.version
  }
  return ret
}

export { getRules, getRule, getRuleInstances, getRuleInstance, newRuleInstance, editRuleInstance, deleteRuleInstance }
