import * as t from './actionTypes'

import { actions } from 'utils/http'
const { types: ht } = actions

const initialGeofence = {
  isLoading: false,
  name: '',
  nameErrorText: '',
  description: '',
  descriptionErrorText: '',
  color: '#fb4f4f',
  shapeType: null,
  polygonPath: null,
  center: null,
  radius: null,
  options: {
    fillColor: '#fb4f4f',
    fillOpacity: 0.3,
    strokeColor: '#fb4f4f',
    strokeOpacity: 1,
    strokeWeight: 1.5,
    editable: true,
    draggable: true
  },
  hashId: '',
  lastModifiedBy: '',
  lastModifiedDate: '',
  groupId: ''
}

const initialRuleInstance = {
  isLoading: false,
  name: '',
  description: '',
  ruleHashId: null,
  groupId: '',
  enabled: true,
  criticality: null,
  devices: [],
  variables: [
    {
      ruleVariableHashId: null,
      value: ''
    }
  ]
}

const initialState = {
  geofence: initialGeofence,
  ruleInstance: initialRuleInstance,
  rules: [],
  deletingGeofenceAndRuleInstance: false,
  selectedBoundary: null,
  // Dialogs
  isNewGeofenceDialogOpen: false,
  isEditGeofenceDialogOpen: false,
  isSelectDialogOpen: false,
  selectMessage: '',
  isDeleteDialogOpen: false,
  deleteMessage: '',
  iscreateAlertOpen: false,
  createAlertMessage: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.CLEAN_REDUCER_DATA:
      return {
        ...state,
        geofence: initialGeofence,
        ruleInstance: initialRuleInstance,
        actions: [],
        users: [],
        selectedDevices: [],
        savingUsersAndActions: false,
        selectedBoundary: null
      }

    case t.SAVE_GEOFENCE:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          ...action.payload,
          options: {
            ...state.geofence.options,
            fillColor: action.payload?.color,
            strokeColor: action.payload?.color
          }
        },
        ruleInstance: {
          ...state.ruleInstance,
          groupId: action.payload?.groupId
        }
      }

    case t.DELETE_OVERLAY:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          options: {
            fillColor: '#fb4f4f',
            fillOpacity: 0.3,
            strokeColor: '#fb4f4f',
            strokeOpacity: 1,
            strokeWeight: 1.5,
            editable: true,
            draggable: true
          },
          shapeType: null,
          polygonPath: null,
          radius: null,
          center: null
        }
      }

    case t.SAVE_NAME:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          name: action.payload
        },
        ruleInstance: {
          ...state.ruleInstance,
          name: action.payload
        }
      }

    case t.NAME_ERROR:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          nameErrorText: action.payload
        }
      }

    case t.SAVE_DESCRIPTION:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          description: action.payload
        },
        ruleInstance: {
          ...state.ruleInstance,
          description: action.payload
        }
      }

    case t.DESCRIPTION_ERROR:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          descriptionErrorText: action.payload
        }
      }

    case t.SAVE_COLOR:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          color: action.payload,
          options: {
            ...state.geofence.options,
            fillColor: action.payload,
            strokeColor: action.payload
          }
        }
      }

    case t.SAVE_RULE_INSTANCE:
      return {
        ...state,
        ruleInstance: {
          ...state.ruleInstance,
          ...action.payload,
          name: state.geofence.name,
          description: state.geofence.description
        }
      }

    case t.SAVE_RULES:
      return {
        ...state,
        rules: action.payload,
        selectedBoundary: {
          value: action.payload.find(r => r.variables[0].hashId === state.ruleInstance.variables[0].ruleVariableHashId),
          label: action.payload.find(r => r.variables[0].hashId === state.ruleInstance.variables[0].ruleVariableHashId)
            ?.description
        }
      }

    case t.SAVE_STATUS:
      return {
        ...state,
        ruleInstance: {
          ...state.ruleInstance,
          enabled: action.payload
        }
      }

    case t.SAVE_SEVERITY:
      return {
        ...state,
        ruleInstance: {
          ...state.ruleInstance,
          criticality: action.payload
        }
      }

    case t.SAVE_BOUNDARY:
      return {
        ...state,
        ruleInstance: {
          ...state.ruleInstance,
          name: state.geofence.name,
          description: state.geofence.description,
          ruleHashId: action.payload.hashId,
          variables: [
            {
              ...state.ruleInstance.variables[0],
              value: state.geofence.hashId,
              ruleVariableHashId: action.payload.variables[0].hashId
            }
          ]
        },
        selectedBoundary: {
          value: action.payload,
          label: action.payload.description
        }
      }

    case ht.CREATE_GEOFENCE:
    case ht.EDIT_GEOFENCE:
      return {
        ...state,
        geofence: {
          ...state.geofence,
          isLoading: true
        }
      }

    case ht.success(ht.CREATE_GEOFENCE):
    case ht.success(ht.EDIT_GEOFENCE):
      return {
        ...state,
        geofence: {
          ...state.geofence,
          ...action.payload.data,
          isLoading: false
        }
      }

    case ht.fail(ht.CREATE_GEOFENCE):
    case ht.fail(ht.EDIT_GEOFENCE):
      return {
        ...state,
        geofence: initialGeofence
      }

    case ht.CREATE_RULE_INSTANCE:
    case ht.EDIT_RULE_INSTANCE:
      return {
        ...state,
        ruleInstance: {
          ...state.ruleInstance,
          isLoading: true
        }
      }

    case ht.success(ht.CREATE_RULE_INSTANCE):
    case ht.success(ht.EDIT_RULE_INSTANCE):
      return {
        ...state,
        ruleInstance: {
          ...state.ruleInstance,
          ...action.payload.data,
          isLoading: false
        }
      }

    case ht.fail(ht.CREATE_RULE_INSTANCE):
    case ht.fail(ht.EDIT_RULE_INSTANCE):
      return {
        ...state,
        ruleInstance: initialRuleInstance
      }

    case ht.DELETE_GEOFENCE:
    case ht.DELETE_RULE_INSTANCE:
      return { ...state, deletingGeofenceAndRuleInstance: true }

    case ht.success(ht.DELETE_GEOFENCE):
    case ht.success(ht.DELETE_RULE_INSTANCE):
      return { ...state, deletingGeofenceAndRuleInstance: false, isDeleteDialogOpen: false }

    case ht.fail(ht.DELETE_GEOFENCE):
    case ht.fail(ht.DELETE_RULE_INSTANCE):
      return { ...state, deletingGeofenceAndRuleInstance: false, isDeleteDialogOpen: false }

    // Dialogs
    case t.NEW_GEOFENCE_DIALOG:
      return { ...state, isNewGeofenceDialogOpen: action.payload.open }

    case t.EDIT_GEOFENCE_DIALOG:
      return { ...state, isEditGeofenceDialogOpen: action.payload.open }

    case t.SELECT_GEOFENCE_DIALOG:
      return { ...state, isSelectDialogOpen: action.payload.open, selectMessage: action.payload.message }

    case t.DELETE_GEOFENCE_DIALOG:
      return { ...state, isDeleteDialogOpen: action.payload.open, deleteMessage: action.payload.message }

    case t.CREATE_ALERT:
      return { ...state, iscreateAlertOpen: action.payload.open, createAlertMessage: [action.payload.message] }

    default:
  }
  return state
}

const getGeofence = state => (state === null ? null : state.geofence)
const getGeofenceHashId = state => (state === null ? null : state.geofence.hashId)
const getGeofenceName = state => (state === null ? null : state.geofence.name)
const getNameErrorText = state => (state === null ? null : state.geofence.nameErrorText)
const getGeofenceDescription = state => (state === null ? null : state.geofence.description)
const getDescriptionErrorText = state => (state === null ? null : state.geofence.descriptionErrorText)
const getGeofenceColor = state => (state === null ? null : state.geofence.color)

const getRuleInstance = state => (state === null ? null : state.ruleInstance)
const getRuleInstanceHashId = state => (state === null ? null : state.ruleInstance.hashId)
const getStatus = state => (state === null ? null : state.ruleInstance.enabled)
const getSeverity = state => (state === null ? null : state.ruleInstance.criticality)
const getBoundary = state => (state === null ? null : state.selectedBoundary)

const getRules = state => (state === null ? null : state.rules)

const getNewGeofenceToSave = state => {
  if (state && state.geofence) {
    const geofence = {
      name: state.geofence.name,
      description: state.geofence.description,
      color: state.geofence.color,
      shapeType: state.geofence.shapeType,
      center: state.geofence.center,
      radius: state.geofence.radius,
      polygonPath: state.geofence.polygonPath,
      groupId: state.geofence.groupId
    }
    return geofence
  }
  return null
}

const getGeofenceToSave = state => {
  if (state && state.geofence) {
    const geofence = {
      hashId: state.geofence.hashId,
      name: state.geofence.name,
      description: state.geofence.description,
      color: state.geofence.color,
      shapeType: state.geofence.shapeType,
      center: state.geofence.center,
      radius: state.geofence.radius,
      polygonPath: state.geofence.polygonPath,
      version: state.geofence.version,
      createdDate: state.geofence.createdDate,
      lastModifiedDate: state.geofence.lastModifiedDate,
      groupId: state.geofence.groupId
    }
    return geofence
  }
  return null
}

const isCreateGeofenceButtonDisabled = state =>
  !state.geofence.polygonPath && !(state.geofence.center && state.geofence.radius) ||
  state.geofence.name === '' ||
  state.geofence.nameErrorText !== '' ||
  state.geofence.description === '' ||
  state.geofence.descriptionErrorText !== ''
    ? true
    : false

const isCreateRuleInstanceButtonDisabled = state =>
  !state.ruleInstance.criticality ||
  !state.ruleInstance.ruleHashId ||
  !state.ruleInstance.variables[0].ruleVariableHashId
    ? true
    : false

const isAssignDevicesButtonDisabled = state => (state.ruleInstance?.devices?.length > 0 ? false : true)
const isAssignUsersButtonDisabled = state => (state.ruleInstance?.users?.length > 0 ? false : true)

const creatingGeofence = state => (state === null ? null : state.geofence.isLoading)
const creatingRuleInstance = state => (state === null ? null : state.ruleInstance.isLoading)
const deletingGeofenceAndRuleInstance = state => (state === null ? null : state.deletingGeofenceAndRuleInstance)

// Dialogs
const getIsSelectDialogOpen = state => (state === null ? null : state.isSelectDialogOpen)
const getSelectMessage = state => (state === null ? null : state.selectMessage)
const getIsDeleteDialogOpen = state => (state === null ? null : state.isDeleteDialogOpen)
const getDeleteMessage = state => (state === null ? null : state.deleteMessage)
const getIscreateAlertOpen = state => (state === null ? null : state.iscreateAlertOpen)
const getCreateAlertMessage = state => (state === null ? null : state.createAlertMessage)

const getIsNewGeofenceDialogOpen = state => (state === null ? null : state.isNewGeofenceDialogOpen)
const getIsEditGeofenceDialogOpen = state => (state === null ? null : state.isEditGeofenceDialogOpen)

export {
  getGeofence,
  getGeofenceHashId,
  getRuleInstance,
  getRuleInstanceHashId,
  getRules,
  getGeofenceName,
  getNameErrorText,
  getGeofenceDescription,
  getDescriptionErrorText,
  getGeofenceColor,
  getStatus,
  getSeverity,
  getBoundary,
  //crear y editar
  getNewGeofenceToSave,
  getGeofenceToSave,
  creatingGeofence,
  creatingRuleInstance,
  deletingGeofenceAndRuleInstance,
  isCreateRuleInstanceButtonDisabled,
  isCreateGeofenceButtonDisabled,
  isAssignDevicesButtonDisabled,
  isAssignUsersButtonDisabled,
  // Dialogs
  getIsSelectDialogOpen,
  getSelectMessage,
  getIsDeleteDialogOpen,
  getDeleteMessage,
  getIscreateAlertOpen,
  getCreateAlertMessage,
  getIsNewGeofenceDialogOpen,
  getIsEditGeofenceDialogOpen
}
