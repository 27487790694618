import React from 'react'
import PropTypes from 'prop-types'
import { get as getHttp } from 'axios'

import Map from './BasicMap'
import { MapProps } from '../../../props'
import { MAPBOX_STYLE, MAPBOX_STYLE_SAT } from './constants'

class CustomizableMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapStyle: false
    }
  }

  componentDidMount() {
    const { mapStyle } = this.props

    this.loadStyle(mapStyle).then(mapBase => {
      this.setState({ mapStyle: mapBase })
    })
  }

  componentDidUpdate() {
    const { passMapRef } = this.props

    if (passMapRef && this.thisMap) {
      passMapRef(this.thisMap.ReactMapGL.current)
    }
  }

  getMapStyle = () => {
    const { layers, sources } = this.props
    const { mapStyle } = this.state

    if (mapStyle) {
      return {
        ...mapStyle,
        sources: {
          ...mapStyle.sources,
          ...sources
        },
        layers: [...mapStyle.layers, ...layers]
      }
    }
  }

  // 7KBs and some millis, does it worth?
  loadStyle = async (mapStyle, useCache = true) => {
    // Check in local storage to avoid costly loads
    if (useCache) {
      const cached = sessionStorage.getItem(mapStyle)
      if (cached) {
        return JSON.parse(cached)
      }
    }

    const stylePath = mapStyle.substr('mapbox://styles/'.length)
    const { data } = await getHttp(
      `https://api.mapbox.com/styles/v1/${stylePath}&access_token=` + process.env.REACT_APP_MAPBOX_TOKEN
    )

    if (useCache) {
      sessionStorage.setItem(mapStyle, JSON.stringify(data))
    }

    return data
  }

  handleMapStyleClick = () => {
    const toLoadMapStyle = MAPBOX_STYLE
    this.loadStyle(toLoadMapStyle).then(mapBase => {
      this.setState({ mapStyle: mapBase })
    })
  }

  handleSatelliteStyleClick = () => {
    const toLoadMapStyle = MAPBOX_STYLE_SAT
    this.loadStyle(toLoadMapStyle).then(mapBase => {
      this.setState({ mapStyle: mapBase })
    })
  }

  render() {
    const { ...other } = this.props
    const { mapStyle } = this.state
    delete other.sources
    delete other.layers

    if (!mapStyle) {
      return null
    }

    return (
      <Map
        ref={self => {
          this.thisMap = self
        }}
        {...other}
        mapStyle={this.getMapStyle()}
        onClickMap={this.handleMapStyleClick}
        onClickSat={this.handleSatelliteStyleClick}
      />
    )
  }
}

CustomizableMap.propTypes = {
  ...MapProps,
  layers: PropTypes.array.isRequired,
  mapStyle: PropTypes.string,
  sources: PropTypes.object.isRequired
}

CustomizableMap.defaultProps = {
  mapStyle: MAPBOX_STYLE
}

export default CustomizableMap
