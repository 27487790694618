import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@material-ui/core/Icon'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

class GroupSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      groupsHierarchy: props.groupsHierarchy,
      selectLabel: props.selectLabel,
      parentGroup: props.parentGroup,
      parentDescriptionValue: props.parentDescriptionValue,
      parentDescriptionValue_errorText: props.parentDescriptionValue_errorText,
      parentHashIdValue: props.parentHashIdValue,
      handleSelectChange: props.handleSelectChange,
      floatingLabelFixed: props.floatingLabelFixed,
      floatingLabelStyle: props.floatingLabelStyle
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      groupsHierarchy: nextProps.groupsHierarchy,
      selectLabel: nextProps.selectLabel,
      parentGroup: nextProps.parentGroup,
      parentDescriptionValue: nextProps.parentDescriptionValue,
      parentDescriptionValue_errorText: nextProps.parentDescriptionValue_errorText,
      parentHashIdValue: nextProps.parentHashIdValue,
      handleSelectChange: nextProps.handleSelectChange
    })
  }

  render() {
    return (
      <SelectField
        floatingLabelText={this.state.selectLabel}
        floatingLabelFixed={this.state.floatingLabelFixed}
        floatingLabelStyle={this.state.floatingLabelStyle}
        fullWidth={true}
        value={this.state.parentHashIdValue}
        errorText={this.state.parentDescriptionValue_errorText}
        onChange={this.state.handleSelectChange}
      >
        <MenuItem key={-1} value={null} primaryText="" />

        {this.state.groupsHierarchy.map((group, index) => {
          let selectText

          switch (group.depth) {
            case 2:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {group.title}
                </span>
              )
              break
            case 3:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {group.title}
                </span>
              )
              break
            case 4:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {group.title}
                </span>
              )
              break
            case 5:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {group.title}
                </span>
              )
              break
            case 6:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {group.title}
                </span>
              )
              break
            default:
              selectText = group.title
          }

          return <MenuItem key={index} value={group.hashId} primaryText={selectText} />
        })}
      </SelectField>
    )
  }
}

GroupSelect.propTypes = {
  floatingLabelFixed: PropTypes.bool,
  floatingLabelStyle: PropTypes.object,
  groupsHierarchy: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  parentDescriptionValue: PropTypes.string,
  parentDescriptionValue_errorText: PropTypes.string,
  parentGroup: PropTypes.bool,
  parentHashIdValue: PropTypes.node,
  selectLabel: PropTypes.string
}

GroupSelect.defaultProps = {
  floatingLabelFixed: false,
  floatingLabelStyle: {},
  parentDescriptionValue: '',
  parentDescriptionValue_errorText: '',
  parentGroup: true,
  parentHashIdValue: null,
  selectLabel: 'Select parent group'
}

export default GroupSelect
