import React from 'react'

const LoadingGps = () => {
  return (
    <div className="sk-bounce large" style={{ margin: 'auto' }}>
      <div className="sk-bounce-dot" />
      <div className="sk-bounce-dot" />
      <div className="sk-bounce-dot" />
    </div>
  )
}

export default LoadingGps
