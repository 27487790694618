import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  isEditing,
  getWidgets,
  getMaxZIndex,
  getDashboardSettings,
  startEditDashboard,
  saveAndFinishEditingDashboard,
  finishEditDashboard,
  addWidget
} from 'ducks/dashboards'

import { utils } from 'ducks/groups'
import { utils as themeUtils } from 'ducks/theme'
import * as c from 'utils/constants'

import DashboardWrapper from './DashboardWrapper'

const mapStateToProps = (state, ownProps) => {
  return {
    editing: isEditing(state),
    widgets: getWidgets(state),
    maxZIndex: getMaxZIndex(state),
    settings: getDashboardSettings(state),
    canDeleteDeviceDashboard: utils.hasPermission(state, c.DEVICEDASHBOARD_DELETE),
    canEditDeviceDashboard: utils.hasPermission(state, c.DEVICEDASHBOARD_EDIT),
    fontColor: themeUtils.getFontColor(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startEditDashboard: () => dispatch(startEditDashboard()),
    saveAndFinishEditingDashboard: () => dispatch(saveAndFinishEditingDashboard()),
    finishEditDashboard: () => dispatch(finishEditDashboard()),
    addWidget: template => dispatch(addWidget(template))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper))
