import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import messages from './messages'

const styles = () => ({
  autocomplete: {
    margin: '15px 0px'
  },
  aggregationLabel: {
    width: '100%',
    paddingTop: 8
  },
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
})

class BasicSignalsChartSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    this.state = {}
  }

  handleChange = name => event => {
    const { onSettingsChange } = this.props
    onSettingsChange({
      [name]: event.target.value,
      [name + 'ErrorText']: ''
    })
  }

  handleDinamicDataChange = (event, selectedSignals) => {
    const { aggregations: aggrs, valueTypes: vTypes, dinamicData: dynamicSignals, onSettingsChange } = this.props
    const selectedSignalIds = selectedSignals.map(({ signalId }) => signalId)
    const aggregations = [...aggrs]
    const valueTypes = [...vTypes]
    const dinamicData = [...dynamicSignals]
    if (dinamicData.length > selectedSignalIds.length) {
      const removedSignal = dinamicData.find(signal => selectedSignalIds.indexOf(signal) === -1)
      const removedSignalIndex = dinamicData.indexOf(removedSignal)
      aggregations.splice(removedSignalIndex, 1)
      valueTypes.splice(removedSignalIndex, 1)
      dinamicData.splice(removedSignalIndex, 1)
    } else if (dinamicData.length < selectedSignalIds.length) {
      const addedSignal = selectedSignalIds.find(signal => dinamicData.indexOf(signal) === -1)
      const addedSignalIndex = selectedSignalIds.indexOf(addedSignal)
      aggregations.splice(addedSignalIndex, 0, 'none')
      valueTypes.splice(addedSignalIndex, 0, 'none')
      dinamicData.splice(addedSignalIndex, 0, addedSignal)
    }
    onSettingsChange({
      dinamicData,
      aggregations,
      valueTypes
    })
  }

  handleAggregationTypeChange = index => event => {
    const { aggregations: aggrs, onSettingsChange } = this.props
    const aggregations = [...aggrs]
    aggregations[index] = event.target.value
    onSettingsChange({
      aggregations
    })
  }

  handleValueTypeChange = index => event => {
    const { valueTypes: vTypes, onSettingsChange } = this.props
    const valueTypes = [...vTypes]
    valueTypes[index] = event.target.value
    onSettingsChange({
      valueTypes
    })
  }

  render() {
    const timeRangeLabels = [
      this.formatMessage(messages.lastOneHour),
      this.formatMessage(messages.lastThreeHours),
      this.formatMessage(messages.lastSixHours),
      this.formatMessage(messages.lastTwelveHours),
      this.formatMessage(messages.lastTwentyFourHours)
    ]
    const aggregationTypes = ['none', 'avg', 'min', 'max']
    const defaultValueTypes = ['none', 'avg', 'min', 'max']

    const {
      classes,
      configurationLoading,
      deviceInfo: { dinamicData: signals = [] },
      dinamicData,
      aggregations,
      valueTypes,
      selectedTimeRange,
      selectedTimeRangeErrorText,
      numberOfDecimals,
      numberOfDecimalsErrorText
    } = this.props

    const noDinamicDataMessageKey = configurationLoading ? 'loadingConfiguratedSignals' : 'notSupportedMachine'
    const options = signals.map(signal => {
      let signalType = this.formatMessage(messages.canBusSignals)
      if (signal.isGPS) signalType = this.formatMessage(messages.gpsSignals)
      else if (signal.isMFIO) signalType = this.formatMessage(messages.mfioSignals)
      return { ...signal, signalType }
    })

    return (
      <div>
        <Paper style={{ borderRadius: 0, padding: 20 }}>
          {options.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  classes={{ groupLabel: classes.groupLabel, root: classes.autocomplete }}
                  fullWidth
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.signalId === value.signalId}
                  groupBy={option => option.signalType}
                  id='grouped-signals'
                  multiple
                  onChange={this.handleDinamicDataChange}
                  options={options}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={options.filter(signal => dinamicData.includes(signal.signalId))}
                />
              </Grid>
              {dinamicData.map((value, index) => {
                const aggregationValue = aggregations[index]
                const valueTypeValue = valueTypes[index]
                const { name } = options.find(dinamicSignal => dinamicSignal.signalId === value)
                const aggrKey = 'selectedAggregation-' + index
                const vTypeKey = 'selectedValueType-' + index
                const isMfioOrCanBusSignal = typeof value === 'number'
                const smWidth = isMfioOrCanBusSignal ? 4 : 8
                return (
                  <React.Fragment key={aggrKey}>
                    <Grid item sm={4} xs={12}>
                      <Typography classes={{ root: classes.aggregationLabel }}>{name}:</Typography>
                    </Grid>
                    <Grid item sm={smWidth} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor={aggrKey + '-label-placeholder'} shrink>
                          {this.formatMessage(messages.aggregation)}
                        </InputLabel>
                        <Select
                          input={<Input id={aggrKey + '-label-placeholder'} name={aggrKey} />}
                          name={aggrKey}
                          onChange={this.handleAggregationTypeChange(index)}
                          value={aggregationValue}
                        >
                          {aggregationTypes.map(type => {
                            return (
                              <MenuItem key={type} value={type}>
                                {this.formatMessage(messages[type])}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {isMfioOrCanBusSignal && (
                      <Grid item sm={4} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor={vTypeKey + '-label-placeholder'} shrink>
                            {this.formatMessage(messages.valueType)}
                          </InputLabel>
                          <Select
                            input={<Input id={vTypeKey + '-label-placeholder'} name={vTypeKey} />}
                            name={vTypeKey}
                            onChange={this.handleValueTypeChange(index)}
                            value={valueTypeValue}
                          >
                            {defaultValueTypes.map((type, idx) => {
                              return (
                                <MenuItem key={idx} value={type}>
                                  {this.formatMessage(messages['value' + type])}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </React.Fragment>
                )
              })}

              <Grid item sm={6} xs={12}>
                <FormControl error={selectedTimeRangeErrorText !== ''} fullWidth>
                  <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                    {this.formatMessage(messages.defaultTimeRange)}
                  </InputLabel>
                  <Select
                    displayEmpty
                    input={<Input id='selectedTimeRange-label-placeholder' name='selectedTimeRange' />}
                    name='selectedTimeRange'
                    onChange={this.handleChange('selectedTimeRange')}
                    value={selectedTimeRange}
                  >
                    {timeRangeLabels.map((label, index) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>{selectedTimeRangeErrorText}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  key='numberOfDecimals'
                  error={numberOfDecimalsErrorText !== ''}
                  fullWidth
                  helperText={numberOfDecimalsErrorText}
                  id='numberOfDecimals'
                  label={this.formatMessage(messages.numberOfDecimals)}
                  onChange={this.handleChange('numberOfDecimals')}
                  required
                  type='number'
                  value={numberOfDecimals}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DialogContentText id='alert-dialog-slide-description'>
                  <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                    {this.formatMessage(messages[noDinamicDataMessageKey])}
                  </span>
                </DialogContentText>
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
    )
  }
}

BasicSignalsChartSettings.propTypes = {
  aggregations: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  configurationLoading: PropTypes.bool.isRequired,
  deviceInfo: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  numberOfDecimals: PropTypes.number.isRequired,
  numberOfDecimalsErrorText: PropTypes.string.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  selectedTimeRange: PropTypes.number.isRequired,
  selectedTimeRangeErrorText: PropTypes.string.isRequired,
  valueTypes: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(BasicSignalsChartSettings))
