import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import { client, logError } from 'utils/http'

import messages from './messages'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}
class CS100ConfigurationDialog extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      action: props.action,
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],

      selectedConfiguration: null,
      selectedConfigurationName: '',
      configurations: [],
      configurationApplying: false,
      configurationApplyed: false,

      count: 0,
      start: 0,
      updateTable: false,
      tableOptions: {
        noDataText: <CircularProgress />,

        onSizePerPageList: this.onSizePerPageList,
        sizePerPageList: [
          {
            text: '10',
            value: 10
          },
          {
            text: '20',
            value: 20
          },
          {
            text: '50',
            value: 50
          },
          {
            text: '100',
            value: 100
          }
        ],
        sizePerPage: 10,
        page: 1,

        onPageChange: this.onPageChange,
        ignoreSinglePage: false,
        pageStartIndex: 1,
        paginationSize: 5,
        prePage: this.formatMessage(messages.prePage),
        nextPage: this.formatMessage(messages.nextPage),
        firstPage: this.formatMessage(messages.firstPage),
        lastPage: this.formatMessage(messages.lastPage),
        paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
        paginationPosition: 'bottom',
        alwaysShowAllBtns: false,
        withFirstAndLast: true
      }
    }
  }

  componentDidMount = () => {
    const { groupId } = this.props
    const { start, tableOptions } = this.state

    this.getConfigurations(groupId, tableOptions.sizePerPage, start)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { groupId } = this.props
    const { start, tableOptions, updateTable } = this.state
    if (prevProps.groupId !== groupId && groupId) {
      this.setState(
        state => ({
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />,
            page: 1
          },
          count: 0,
          start: 0,
          configurations: []
        }),
        () => {
          this.getConfigurations(groupId, tableOptions.sizePerPage, start)
        }
      )
    }
    if (!prevState.updateTable && updateTable && groupId) {
      this.getConfigurations(groupId, tableOptions.sizePerPage, start)
      this.handleUpdateTable(false)
    }
  }

  mapToPaginatedResponse = configurations => {
    const { start, tableOptions } = this.state
    return {
      configurations: configurations.slice(start, start + tableOptions.sizePerPage),
      count: configurations.length,
      total: configurations.length
    }
  }

  getConfigurations = (groupId, limit, offset) => {
    const { getConfigurations } = this.props

    getConfigurations(groupId, limit, offset)
      .then(response => {
        const data = response.data
        if (data.count === 0) {
          this.setState(state => {
            const { ...otherTableOptions } = state.tableOptions
            return {
              configurations: [],
              count: 0,
              tableOptions: {
                ...otherTableOptions,
                noDataText: this.formatMessage(messages.thereAreNoAvailableConfigurations)
              }
            }
          })
        } else {
          this.setState({
            configurations: data.configurations,
            count: data.total
          })
        }
      })
      .catch(response => {
        const { error } = { ...response }
        const { intl } = this.props
        if (error.response) {
          switch (error.response.status) {
            case 400:
              this.setState(state => {
                const { ...otherTableOptions } = state.tableOptions
                return {
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '400'
                  }),
                  alertMessagesText: [this.formatMessage(messages.error400Message)],
                  tableOptions: {
                    ...otherTableOptions,
                    noDataText: this.formatMessage(messages.errorFetchingData)
                  }
                }
              })
              break
            case 401:
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState(state => {
                const { ...otherTableOptions } = state.tableOptions
                return {
                  loading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '401'
                  }),
                  alertMessagesText: [message],
                  tableOptions: {
                    ...otherTableOptions,
                    noDataText: this.formatMessage(messages.errorFetchingData)
                  }
                }
              })
              break
            case 403:
              this.setState(state => {
                const { ...otherTableOptions } = state.tableOptions
                return {
                  loading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '403'
                  }),
                  alertMessagesText: [this.formatMessage(messages.error403Message)],
                  tableOptions: {
                    ...otherTableOptions,
                    noDataText: this.formatMessage(messages.errorFetchingData)
                  }
                }
              })
              break
            case 404:
              const errorReceived =
                typeof error.response.data === 'string' ? error.response.data : error.response.data.message
              if (
                errorReceived.includes('NO CONFIGURATION OF DEVICE IS SHARED WITH THIS USER IN THIS GROUP') ||
                errorReceived.includes('NO CONFIGURATION EXISTS IN THIS GROUP')
              ) {
                this.setState(state => ({
                  tableOptions: {
                    ...state.tableOptions,
                    noDataText: this.formatMessage(messages.thereAreNoAvailableConfigurations)
                  },
                  configurations: []
                }))
              } else {
                this.setState(state => {
                  const { ...otherTableOptions } = state.tableOptions
                  return {
                    loading: false,
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.error, {
                      number: '404'
                    }),
                    alertMessagesText: [this.formatMessage(messages.error404Message)],
                    tableOptions: {
                      ...otherTableOptions,
                      noDataText: this.formatMessage(messages.errorFetchingData)
                    }
                  }
                })
              }
              break
            case 406:
              this.setState(state => {
                const { ...otherTableOptions } = state.tableOptions
                return {
                  loading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '406'
                  }),
                  alertMessagesText: [this.formatMessage(messages.error406Message)],
                  tableOptions: {
                    ...otherTableOptions,
                    noDataText: this.formatMessage(messages.errorFetchingData)
                  }
                }
              })
              break
            case 500:
              this.setState(state => {
                const { ...otherTableOptions } = state.tableOptions
                return {
                  loading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '500'
                  }),
                  alertMessagesText: [error.response.data.error_description],
                  tableOptions: {
                    ...otherTableOptions,
                    noDataText: this.formatMessage(messages.errorFetchingData)
                  }
                }
              })
              break
            default:
              this.setState(state => {
                const { ...otherTableOptions } = state.tableOptions
                return {
                  loading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                  alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)],
                  tableOptions: {
                    ...otherTableOptions,
                    noDataText: this.formatMessage(messages.errorFetchingData)
                  }
                }
              })
              logError(response)
          }
        } else {
          this.setState(state => {
            const { ...otherTableOptions } = state.tableOptions
            return {
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)],
              tableOptions: {
                ...otherTableOptions,
                noDataText: this.formatMessage(messages.errorFetchingData)
              }
            }
          })
          logError(response)
        }
      })
  }

  handleUpdateTable = updateTable => {
    this.setState({
      updateTable
    })
  }

  onPageChange = (page, sizePerPage) => {
    const { groupId } = this.props

    this.setState(
      state => {
        const { ...otherTableOptions } = state.tableOptions
        return {
          start: (page - 1) * sizePerPage,
          tableOptions: {
            ...otherTableOptions,
            page,
            sizePerPage
          },
          configurations: []
        }
      },
      () => {
        const { start, tableOptions } = this.state
        this.getConfigurations(groupId, tableOptions.sizePerPage, start)
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    const { groupId } = this.props

    this.setState(
      state => {
        const { ...otherTableOptions } = state.tableOptions
        return {
          tableOptions: {
            ...otherTableOptions,
            sizePerPage
          },
          configurations: []
        }
      },
      () => {
        const { start, tableOptions } = this.state
        this.getConfigurations(groupId, tableOptions.sizePerPage, start)
      }
    )
    this.setState({ configurations: [] }, () => {
      const { start, tableOptions } = this.state
      this.getConfigurations(groupId, tableOptions.sizePerPage, start)
    })
  }

  remote(remoteObj) {
    return {
      ...remoteObj,
      pagination: true,
      sizePerPage: true
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  onRowSelect = (row, isSelected) => {
    if (isSelected) {
      this.setState({
        selectedConfiguration: row.id,
        selectedConfigurationName: row.name + ' v.' + row.versionNumber
      })
    } else {
      this.setState({
        selectedConfiguration: null,
        selectedConfigurationName: ''
      })
    }
  }

  handleSelectionChange = event => {
    const value = event.target.value
    this.setState({
      selectedConfiguration: value
    })
  }

  handleConfigurationUpdate = () => {
    const { csNodes } = this.props
    const { action, selectedConfiguration, selectedConfigurationName } = this.state

    const currentAction = action
    const configurationFile = selectedConfiguration
    const configurationFileName = selectedConfigurationName

    this.setState(
      {
        configurationApplying: true,
        configurationApplyed: false
      },
      this.createTasks(currentAction, csNodes, configurationFile, configurationFileName)
    )
  }

  createTasks = (action, nodes, configuration, configurationName) => {
    const { groupId } = this.props
    const actionId = action.hashId
    const actionName = action.name
    const nodesToTasks = nodes.map(node => {
      return {
        nodeHashId: node.id,
        nodeName: node.name
      }
    })
    const configurationFile = configuration
    const infoForTasks = {
      actionHashId: actionId,
      actionName,
      actionNodeType: 'CS',
      groupId,
      tasks: nodesToTasks,
      variables: [
        {
          name: 'configurationId',
          type: 0,
          value: configurationFile
        },
        {
          name: 'configurationName',
          type: 0,
          value: configurationName
        }
      ]
    }

    const actionToDispatch = client.newTasks(actionId, infoForTasks)

    actionToDispatch
      .then(() => {
        this.setState({
          configurationApplying: false,
          configurationApplyed: true,
          alertMessages: true,
          alertMessagesType: 'success',
          alertMessagesTitle: '',
          alertMessagesText: [this.formatMessage(messages.successfulConfiguration)]
        })
      })
      .catch(response => {
        let error = { ...response }
        if (typeof error.response === 'undefined') error = response.error

        switch (error.response.status) {
          case 501:
            this.setState({
              configurationApplying: false,
              configurationApplyed: true,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '501'
              }),
              alertMessagesText: [error.response.data.message]
            })
            break
          default:
            this.setState({
              configurationApplying: false,
              configurationApplyed: true,
              selectedConfiguration: null,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })

            logError(response)
        }
      })
  }

  renderCS100ConfigurationDialogLoadingAndError = () => {
    const { loading, configurationApplying, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } =
      this.state
    if (loading || configurationApplying) {
      return (
        <DialogContent>
          <Loading />
        </DialogContent>
      )
    } else if (alertMessages) {
      return (
        <DialogContent>
          <Alert
            alertType={alertMessagesType}
            messageText={alertMessagesText}
            messageTitle={alertMessagesTitle}
            showAlert={alertMessages}
          />
        </DialogContent>
      )
    }
  }

  renderCS100ConfigurationDialogContent = () => {
    const { loading, configurationApplying, alertMessages, configurations, count, tableOptions } = this.state
    if (loading || configurationApplying || alertMessages) {
      return this.renderCS100ConfigurationDialogLoadingAndError()
    } else {
      const selectRowProp = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: this.onRowSelect,
        bgColor: '#f5f5f5'
      }
      return (
        <DialogContent style={{ overflowY: 'initial' }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              data={configurations.map(c =>
                Object.assign(c, {
                  nameWithVersion: c.versionNumber > 0 ? c.name + ' v.' + c.versionNumber : c.name
                })
              )}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              options={tableOptions}
              pagination
              remote={this.remote}
              selectRow={selectRowProp}
              striped={false}
            >
              <TableHeaderColumn dataField='id' isKey width='0'>
                {this.formatMessage(messages.locationID)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='nameWithVersion' dataSort width='250'>
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </DialogContent>
      )
    }
  }

  render() {
    const { handleClose, canApplyConfiguration, classes } = this.props
    const { action, selectedConfiguration, alertMessages, configurationApplyed, configurationApplying } = this.state
    return (
      <React.Fragment>
        <DialogTitle>
          {action.description}
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {this.renderCS100ConfigurationDialogContent()}
        <DialogActions>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement='top'
            title={!canApplyConfiguration ? this.formatMessage(messages.notAllowedToApplyConfiguration) : ''}
          >
            <div style={{ padding: 0, display: 'inline-flex' }}>
              <Button
                className='primary-action-button'
                disabled={
                  selectedConfiguration === null ||
                  alertMessages ||
                  configurationApplyed ||
                  configurationApplying ||
                  !canApplyConfiguration
                }
                onClick={this.handleConfigurationUpdate}
                style={{ display: configurationApplyed ? 'none' : 'inherit' }}
              >
                {this.formatMessage(messages.apply)}
              </Button>
            </div>
          </Tooltip>
        </DialogActions>
      </React.Fragment>
    )
  }
}

CS100ConfigurationDialog.propTypes = {
  action: PropTypes.object.isRequired,
  canApplyConfiguration: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  csNodes: PropTypes.array.isRequired,
  getConfigurations: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(CS100ConfigurationDialog)
