import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils as configUtils } from 'ducks/configurations'
import { utils as groupUtils } from 'ducks/groups'

import { getConfigurationsUrl } from '../url'

import NewConfigurationConnected from './NewConfiguration'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = (state, { history }) => ({
  canReadConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_READ),
  configState: configUtils.getState(state),
  configurationsUrl: getConfigurationsUrl(),
  groupId: groupUtils.getSelectedGroup(state).id,
  onCreateConfiguration: () => history.push(getConfigurationsUrl(), 'createdConfiguration'),
  selectedCanProtocolData: selectedProtocolName => configUtils.getSelectedCanProtocolData(state, selectedProtocolName),
  selectedCanProtocolType: selectedProtocolName => configUtils.getSelectedCanProtocolType(state, selectedProtocolName)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addLocalConfigurationMFIO: actions.addLocalConfigurationMFIO,
      addLocalConfigurationSignal: actions.addLocalConfigurationSignal,
      changeLocalConfigurationField: actions.changeLocalConfigurationField,
      copyLocalConfigurationSignal: actions.copyLocalConfigurationSignal,
      localConfigurationError: actions.localConfigurationError,
      manageLocalConfigurationAlert: actions.manageLocalConfigurationAlert,
      resetLocalConfiguration: actions.resetLocalConfiguration,
      saveConfiguration: actions.createConfiguration
    },
    dispatch
  )

const NewConfiguration = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewConfigurationConnected))

export { NewConfiguration }
