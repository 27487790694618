import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import messages from './messages'

import { Link } from 'react-router-dom'

import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import { DEFAULT_GROUP_ID } from 'utils/constants'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

const ConfigurationsTableTitle = ({
  canCreateConfigurations,
  classes,
  groupId,
  intl,
  newConfigurationUrl,
  onDbcImportClick,
  onImportClick
}) => {
  const { formatMessage } = intl

  return (
    <div className='row'>
      <div className='col-sm-5'>
        <h1>{formatMessage(messages.title)}</h1>
      </div>
      {canCreateConfigurations && (
        <div className='col-sm-7 text-right'>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={groupId === DEFAULT_GROUP_ID ? formatMessage(messages.cantImportDbcInDefaultGroup) : ''}
          >
            <div style={{ display: 'inline-block' }}>
              <Button
                className='secondary-page-title-button'
                disabled={groupId === '' || groupId === DEFAULT_GROUP_ID}
                onClick={onDbcImportClick}
                style={{ marginRight: 10, marginTop: 20 }}
              >
                {formatMessage(messages.dbcFiles)}
              </Button>
            </div>
          </Tooltip>
          <Button
            className='secondary-page-title-button'
            disabled={groupId === ''}
            onClick={onImportClick}
            style={{ marginRight: 10, marginTop: 20 }}
          >
            <Icon className='zmdi zmdi-cloud-upload' />
            {formatMessage(messages.importConfigFromPC)}
          </Button>
          <Link className='button-link' to={newConfigurationUrl}>
            <Button className='page-title-button' disabled={groupId === ''} style={{ marginTop: 20 }}>
              <Icon className='zmdi zmdi-plus' />
              {formatMessage(messages.createConfiguration)}
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}

ConfigurationsTableTitle.propTypes = {
  canCreateConfigurations: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  newConfigurationUrl: PropTypes.string.isRequired,
  onDbcImportClick: PropTypes.func.isRequired,
  onImportClick: PropTypes.func.isRequired
}

export default injectIntl(withStyles(styles)(ConfigurationsTableTitle))
