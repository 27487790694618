import { connect } from 'react-redux'

import { actions } from 'ducks/advancedSignalsConfigDialog'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'


import MachineActions from './MachineActions'

// canViewDetail, canViewOverview, canViewHistory, canEditDetail
// Esto se debe a que si se tienen permisos de configurar el nodo también debería
// poder entrar a details pero solo a config.
const mapStateToProps = state => ({
  canViewDashboards: resolvePermissions(state, c.DEVICEDASHBOARD_READ),
  canViewDetail: resolvePermissions(state, c.NODEDETAILS_VIEW),
  canConfigureAdvancedSignals:
    resolvePermissions(state, c.DEVICECONFIGURATION_READ) && resolvePermissions(state, c.DEVICECONFIGURATION_EDIT)
})

const mapDispatchToProps = dispatch => ({
  onOpenDialog: (deviceEid, deviceType, deviceName) => dispatch(actions.open(deviceEid, deviceType, deviceName))
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineActions)
