import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Select from 'react-select'

import Button from '@material-ui/core/Button'
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Icon from '@material-ui/core/Icon'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import * as c from 'modules/geofences/constants'
import Alert from 'components/Alert'
import { ColorPicker } from 'components/common'
import { fitToBounds } from 'modules/geofences/utils'

import MapView from './MapView'
import messages from './messages'

import { logError } from 'utils/http'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 16
  },
  colorPicker: {
    width: 20,
    height: 20,
    display: 'inline-block',
    borderRadius: 10,
    margin: 'auto'
  },
  colorTitle: {
    fontSize: 16
  }
}

class NewGeofenceStep extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      bounds: undefined,
      center: { latitude: 43.295645, longitude: -1.980012 },
      isDetailedRulesLoading: true,
      isGeofenceLoading: false,
      isRuleInstanceLoading: false,
      isRulesLoading: true
    }
  }

  componentDidMount() {
    this.fetchGeofence()
    this.fetchRuleInstance()
    this.fetchRules().then(rules => {
      this.fetchDetailedRules(rules)
    })
  }

  fetchGeofence = () => {
    const { geofenceHashId, getGeofence, intl, updateGeofence } = this.props

    if (geofenceHashId) {
      this.setState({
        isGeofenceLoading: true
      })

      getGeofence(geofenceHashId)
        .then(response => {
          this.setState(
            {
              isGeofenceLoading: false,
              alertMessages: false,
              alertMessagesType: '',
              alertMessagesTitle: '',
              alertMessagesText: ['']
            },
            () => updateGeofence(response.data)
          )
        })
        .catch(response => {
          const error = response.error
          switch (error.response.status) {
            case 400:
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessagesText: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401:
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessagesText: [message]
              })
              break
            case 403:
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessagesText: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404:
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessagesText: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406:
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessagesText: [this.formatMessage(messages.error406Message)]
              })
              break
            case 500:
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                isGeofenceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error.response)
          }
        })
    }
  }

  fetchRuleInstance = () => {
    const { getRuleInstance, intl, ruleInstanceHashId, updateRuleInstance } = this.props

    if (ruleInstanceHashId) {
      this.setState({
        isRuleInstanceLoading: true
      })

      getRuleInstance(ruleInstanceHashId)
        .then(response => {
          const ruleInstance = response.data
          this.setState(
            {
              isRuleInstanceLoading: false,
              alertMessages: false,
              alertMessagesType: '',
              alertMessagesTitle: '',
              alertMessagesText: ['']
            },
            () => updateRuleInstance(ruleInstance)
          )
        })
        .catch(response => {
          const error = response.error

          switch (error.response.status) {
            case 400:
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessagesText: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401:
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessagesText: [message]
              })
              break
            case 403:
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessagesText: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404:
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessagesText: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406:
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessagesText: [this.formatMessage(messages.error406Message)]
              })
              break
            case 500:
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                isRuleInstanceLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error.response)
          }
        })
    }
  }

  fetchRules = async () => {
    const { groupId, getRules, intl } = this.props

    const validRuleNames = Object.values(c.RULE_TYPE_NAMES)
    let rules

    try {
      const response = await getRules(groupId)
      rules = response.data.filter(rule => validRuleNames.includes(rule.name))
      this.setState({
        isRulesLoading: false,
        alertMessages: false,
        alertMessagesType: '',
        alertMessagesTitle: '',
        alertMessagesText: ['']
      })
    } catch (response) {
      const error = response.error

      switch (error.response.status) {
        case 400:
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
            alertMessagesText: [this.formatMessage(messages.error400Message)]
          })
          break
        case 401:
          let message
          if (intl.locale === 'en') message = error.response.message
          else message = this.formatMessage(messages.error401Message)
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
            alertMessagesText: [message]
          })
          break
        case 403:
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
            alertMessagesText: [this.formatMessage(messages.error403Message)]
          })
          break
        case 404:
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
            alertMessagesText: [this.formatMessage(messages.error404Message)]
          })
          break
        case 406:
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
            alertMessagesText: [this.formatMessage(messages.error406Message)]
          })
          break
        case 500:
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
            alertMessagesText: [error.response.data.error_description]
          })
          break
        default:
          this.setState({
            isRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error.response)
      }
    }
    return rules
  }

  fetchDetailedRules = async rules => {
    const { getRule, intl, updateRules } = this.props
    let detailedRules

    try {
      const rulesResponses = await Promise.all(rules.map(ri => getRule(ri.hashId)))
      detailedRules = rulesResponses.map(response => response.data)
    } catch (response) {
      const error = response.error

      switch (error.response.status) {
        case 400:
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
            alertMessagesText: [this.formatMessage(messages.error400Message)]
          })
          break
        case 401:
          let message
          if (intl.locale === 'en') message = error.response.message
          else message = this.formatMessage(messages.error401Message)
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
            alertMessagesText: [message]
          })
          break
        case 403:
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
            alertMessagesText: [this.formatMessage(messages.error403Message)]
          })
          break
        case 404:
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
            alertMessagesText: [this.formatMessage(messages.error404Message)]
          })
          break
        case 406:
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
            alertMessagesText: [this.formatMessage(messages.error406Message)]
          })
          break
        case 500:
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
            alertMessagesText: [error.response.data.error_description]
          })
          break
        default:
          this.setState({
            isDetailedRulesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error.response)
      }
    } finally {
      this.setState({
        isDetailedRulesLoading: false
      })

      if (detailedRules) {
        updateRules(detailedRules)
      }
    }
  }

  fitToBounds = (shape, form) => {
    const bounds = fitToBounds(shape, form)
    this.setState({
      bounds,
      center: {
        latitude: bounds.getCenter().lat(),
        longitude: bounds.getCenter().lng()
      }
    })
  }

  handleCenterChanged = ({ latitude, longitude }) => {
    this.setState({
      center: {
        latitude,
        longitude
      }
    })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  render() {
    const {
      boundary,
      classes,
      closeNewGeofenceDialog,
      createGeofenceAction,
      creatingGeofence,
      creatingRuleInstance,
      description,
      descriptionErrorText,
      geofence,
      geofenceColor,
      groupId,
      handleBoundaryChange,
      handleDescriptionChange,
      handleNameChange,
      handleSeverityChange,
      handleStatusChange,
      isCreateGeofenceButtonDisabled,
      isCreateRuleInstanceButtonDisabled,
      name,
      nameErrorText,
      onNextStep,
      ruleInstanceHashId,
      rules,
      selectGeofenceColor,
      severity,
      status
    } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      bounds,
      center,
      isGeofenceLoading,
      isDetailedRulesLoading,
      isRuleInstanceLoading,
      isRulesLoading
    } = this.state

    const selectStyle = {
      control: (base, state) => ({
        ...base,
        borderStyle: 'none none solid none',
        borderRadius: 0,
        boxShadow: 0,
        '&:hover': {
          borderStyle: 'none none solid none'
        }
      }),
      valueContainer: (base, state) => ({
        ...base,
        paddingLeft: 0
      })
    }

    const isLoading = isGeofenceLoading || isRuleInstanceLoading || isRulesLoading || isDetailedRulesLoading

    return (
      <div className='col-md-12' style={{ height: '80%', padding: 0 }}>
        {isLoading ? (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <CircularProgress style={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <React.Fragment>
            <MapView
              bounds={bounds}
              center={center}
              fitToBounds={this.fitToBounds}
              geofence={geofence}
              geofenceColor={geofenceColor}
              groupId={groupId}
              onCenterChanged={this.handleCenterChanged}
            />
            <div className='col-md-3' style={{ height: '100%', padding: '0 0 15px 0' }}>
              <Typography color='inherit' variant='h6'>
                {this.formatMessage(messages.generalInformation)}
              </Typography>
              <FormControl error={nameErrorText !== ''} style={{ width: '100%', padding: 0 }}>
                <InputLabel>{this.formatMessage(messages.name)}</InputLabel>
                <Input
                  id='name'
                  onChange={event => {
                    handleNameChange(event.currentTarget.value)
                  }}
                  value={name}
                />
                <FormHelperText style={{ paddingBottom: 5 }}>{nameErrorText}</FormHelperText>
              </FormControl>
              <FormControl error={descriptionErrorText !== ''} style={{ width: '100%', padding: 0 }}>
                <InputLabel>{this.formatMessage(messages.description)}</InputLabel>
                <Input
                  id='description'
                  onChange={event => {
                    handleDescriptionChange(event.currentTarget.value)
                  }}
                  value={description}
                />
              </FormControl>
              <div className='col-md-12' style={{ width: '100%', padding: '20px 0 0 0' }}>
                <div className='col-md-2' style={{ padding: '0 0 20px 0' }}>
                  <Typography classes={{ h6: classes.colorTitle }} color='inherit' variant='h6'>
                    {this.formatMessage(messages.color)}
                  </Typography>
                </div>
                <div className='col-md-10' style={{ padding: '0 0 20px 0' }}>
                  <ColorPicker
                    className={classes.colorPicker}
                    onChange={selectedColor => {
                      selectGeofenceColor(selectedColor)
                    }}
                    value={geofenceColor}
                  />
                </div>
                <div className='col-md-12' style={{ padding: '0 0 20px 0' }}>
                  <Button
                    className='primary-action-button'
                    disabled={!geofence.shapeType}
                    onClick={() => this.fitToBounds(geofence.shapeType, geofence)}
                  >
                    <CenterFocusStrongIcon style={{ marginRight: '10px' }} />
                    {this.formatMessage(messages.centerView)}
                  </Button>
                </div>

                <div style={{ padding: '25px 0 0 2px' }}>
                  <span style={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)' }}>
                    {status !== null ? this.formatMessage(messages.status) : ''}
                  </span>
                </div>
                <Select
                  onChange={event => handleStatusChange(event.value)}
                  options={[
                    { value: true, label: this.formatMessage(messages.enabled) },
                    { value: false, label: this.formatMessage(messages.disabled) }
                  ]}
                  styles={selectStyle}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'rgba(0, 0, 0, 0.54)'
                    }
                  })}
                  value={
                    status !== null
                      ? {
                        value: status,
                        label: status ? this.formatMessage(messages.enabled) : this.formatMessage(messages.disabled)
                      }
                      : null
                  }
                />
                <div style={{ padding: '25px 0 0 2px' }}>
                  <span style={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)' }}>
                    {severity !== null ? this.formatMessage(messages.severity) : ''}
                  </span>
                </div>
                <Select
                  onChange={event => handleSeverityChange(event.value)}
                  options={[
                    { value: 'high', label: this.formatMessage(messages.high) },
                    { value: 'medium', label: this.formatMessage(messages.medium) },
                    { value: 'low', label: this.formatMessage(messages.low) }
                  ]}
                  placeholder={this.formatMessage(messages.severity)}
                  styles={selectStyle}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'rgba(0, 0, 0, 0.54)'
                    }
                  })}
                  value={severity ? { value: severity, label: this.formatMessage(messages[severity]) } : null}
                />
                <div style={{ padding: '25px 0 0 2px' }}>
                  <span style={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)' }}>
                    {boundary !== null ? this.formatMessage(messages.boundary) : ''}
                  </span>
                </div>
                <Select
                  isDisabled={ruleInstanceHashId !== ''}
                  isLoading={isDetailedRulesLoading}
                  onChange={event => handleBoundaryChange(event.value)}
                  options={Object.values(rules).map(rule => ({
                    value: rule,
                    label: rule.description
                  }))}
                  placeholder={this.formatMessage(messages.boundary)}
                  styles={selectStyle}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: 'rgba(0, 0, 0, 0.54)'
                    }
                  })}
                  value={boundary}
                />

                {alertMessages && (
                  <div className='col-md-12' style={{ padding: '0 0 20px 0' }}>
                    <Alert
                      alertType={alertMessagesType}
                      closeFunction={this.closeAlert}
                      messageText={alertMessagesText}
                      messageTitle={alertMessagesTitle}
                      showAlert={alertMessages}
                    />
                  </div>
                )}
              </div>
              <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <div style={{ paddingRight: 10, display: 'inline-table' }}>
                  <Button className='cancel-button' onClick={closeNewGeofenceDialog}>
                    {this.formatMessage(messages.cancel)}
                  </Button>
                </div>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    isCreateGeofenceButtonDisabled || isCreateRuleInstanceButtonDisabled
                      ? this.formatMessage(messages.incompleteRequiredFields)
                      : ''
                  }
                >
                  <div style={{ display: 'inline-table' }}>
                    <Button
                      className='primary-action-button'
                      disabled={
                        creatingGeofence ||
                        creatingRuleInstance ||
                        isCreateGeofenceButtonDisabled ||
                        isCreateRuleInstanceButtonDisabled ||
                        isDetailedRulesLoading ||
                        isGeofenceLoading ||
                        isRuleInstanceLoading
                      }
                      onClick={() => createGeofenceAction(onNextStep, groupId)}
                      style={{ paddingLeft: 20 }}
                    >
                      {creatingGeofence && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                      {this.formatMessage(messages.nextStep)}
                      <Icon className='zmdi zmdi-chevron-right' style={{ margin: 0 }} />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

NewGeofenceStep.propTypes = {
  boundary: PropTypes.object,
  classes: PropTypes.object.isRequired,
  closeNewGeofenceDialog: PropTypes.func.isRequired,
  createGeofenceAction: PropTypes.func.isRequired,
  creatingGeofence: PropTypes.bool.isRequired,
  creatingRuleInstance: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  descriptionErrorText: PropTypes.string.isRequired,
  geofence: PropTypes.object.isRequired,
  geofenceColor: PropTypes.string.isRequired,
  geofenceHashId: PropTypes.string.isRequired,
  getGeofence: PropTypes.func.isRequired,
  getRule: PropTypes.func.isRequired,
  getRuleInstance: PropTypes.func.isRequired,
  getRules: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleBoundaryChange: PropTypes.func.isRequired,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handleSeverityChange: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isCreateGeofenceButtonDisabled: PropTypes.bool.isRequired,
  isCreateRuleInstanceButtonDisabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  nameErrorText: PropTypes.string.isRequired,
  onNextStep: PropTypes.func.isRequired,
  ruleInstanceHashId: PropTypes.string,
  rules: PropTypes.array,
  selectGeofenceColor: PropTypes.func.isRequired,
  severity: PropTypes.string,
  status: PropTypes.bool.isRequired,
  updateGeofence: PropTypes.func.isRequired,
  updateRuleInstance: PropTypes.func.isRequired,
  updateRules: PropTypes.func.isRequired
}

NewGeofenceStep.defaultProps = {
  boundary: null,
  ruleInstanceHashId: '',
  rules: [],
  severity: ''
}

export default withStyles(styles)(injectIntl(NewGeofenceStep))
