import { defineMessages } from 'react-intl'

const messages = defineMessages({
  dataPlanInventory: {
    id: 'DataPlanInventory.dataPlanInventory',
    description: 'Digital Service Plan Inventory title',
    defaultMessage: 'Digital Service Plan Inventory'
  },
  boughtByMe: {
    id: 'DataPlanInventory.boughtByMe',
    description: 'Bought by me',
    defaultMessage: 'Bought by me'
  },
  sharedToThisGroup: {
    id: 'DataPlanInventory.sharedToThisGroup',
    description: 'Shared to this group',
    defaultMessage: 'Shared to this group'
  }
})

export default messages
