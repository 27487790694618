import React from 'react'
import PropTypes from 'prop-types'

import { props } from 'components/Map'
import OpenedPolygon from './OpenedPolygon'
import ClosedPolygon from './ClosedPolygon'

const SvgPolygon = ({ edges, features = [], ...other }) =>
  features.length < edges ? (
    <OpenedPolygon features={features} />
  ) : (
    <ClosedPolygon features={features} {...other} />
  )

SvgPolygon.propTypes = {
  edges: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(props.Position)
}

export default SvgPolygon
export { WATERMARK_STYLE, EMPTY_POLYGON_STYLE } from './ClosedPolygon'
