import { defineMessages } from 'react-intl'

const messages = defineMessages({
  exportDashboard: {
    id: 'ExportDashboard.exportDashboard',
    description: 'Export Dashboard',
    defaultMessage: 'EXPORT DASHBOARD'
  },
  name: {
    id: 'ExportDashboard.name',
    description: 'Dashboard name',
    defaultMessage: 'Name'
  },
  description: {
    id: 'ExportDashboard.description',
    description: 'Dashboard description',
    defaultMessage: 'Description'
  },
  cancel: {
    id: 'ExportDashboard.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  exportDashboardAction: {
    id: 'ExportDashboard.exportDashboardAction',
    description: 'Export dashboard',
    defaultMessage: 'Export dashboard'
  },
  parsingErrorMessage: {
    id: 'ExportDashboard.parsingErrorMessage',
    description: 'Parsing error message',
    defaultMessage: 'Error parsing description due to incorrect format. Please try it again'
  }
})

export default messages
