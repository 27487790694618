import React from 'react'

import { useAuth0 } from 'react-auth0-spa'
import Link from '@material-ui/core/Link'

const SSOSignUpLink = ({ text }) => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Link
      style={{ cursor: 'pointer' }}
      onClick={() => {
        loginWithRedirect({ signup: true })
      }}
    >
      {text}
    </Link>
  )
}

export default SSOSignUpLink
