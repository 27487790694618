import React from 'react'
import PropTypes from 'prop-types'
import { getUrlParameter } from 'routes'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import NewRegistrationForm from './NewRegistrationForm'
import NewRegistrationFormTitle from './NewRegistrationFormTitle'

class NewRegistration extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      eid: decodeURIComponent(getUrlParameter(props.location.search, 'eid')),
      loading: false,
      regCode: decodeURIComponent(getUrlParameter(props.location.search, 'regcode')),
      serialNumber: ''
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, loading } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  render() {
    const { activateDevice, canCreateDevices, getDevice, groupId } = this.props
    const { alertMessages, eid, loading, regCode, serialNumber } = this.state

    return (
      <div className="content-container" id="content">
        <div style={{ margin: '20px' }}>
          <div className="container-fluid">
            <NewRegistrationFormTitle />
          </div>
          {loading || alertMessages ? (
            <div className="container-fluid">{this.renderTableLoadingAndError()}</div>
          ) : (
            <NewRegistrationForm
              activateDevice={activateDevice}
              canCreateDevices={canCreateDevices}
              eid={eid}
              getDevice={getDevice}
              groupId={groupId}
              regCode={regCode}
              serialNumber={serialNumber}
            />
          )}
        </div>
      </div>
    )
  }
}

NewRegistration.propTypes = {
  activateDevice: PropTypes.func.isRequired,
  canCreateDevices: PropTypes.bool.isRequired,
  getDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export default NewRegistration
