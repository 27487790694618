import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions } from 'ducks/accessPasses'
import { utils } from 'ducks/groups'

import ManageAccessPassConsumption from './ManageAccessPassConsumption'

const mapStateToProps = (state, { match, history }) => ({
  onManageAccessPassConsumption: action => {
    const key = action === 'allow' ? 'accessPassConsumptionAllowed': 'accessPassConsumptionDisallowed'
    history.replace(match.path, key)
  },
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  manageAccessPassConsumption: (id, groupId, allow_consumption) =>
    dispatch(actions.manageAccessPassConsumption(id, groupId, allow_consumption))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageAccessPassConsumption))
