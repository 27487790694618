import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Maintenances from './Maintenances'

const mapStateToProps = state => {
  return {
    canCreateNotifications: resolvePermissions(state, c.RULE_CREATE),
    canDeleteNotifications: resolvePermissions(state, c.RULE_DELETE),
    canEditNotifications: resolvePermissions(state, c.RULE_EDIT),
    groupId: groupUtils.getSelectedGroup(state).id
  }
}

export default connect(mapStateToProps)(Maintenances)
