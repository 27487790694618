import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'LanguageDropdown.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request.'
  },
  error404Message: {
    id: 'LanguageDropdown.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  error409Message: {
    id: 'LanguageDropdown.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Conflict.'
  },
  error422Message: {
    id: 'LanguageDropdown.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Field validation error.'
  },
  error502Message: {
    id: 'LanguageDropdown.error502Message',
    description: 'Error 502 message',
    defaultMessage: 'Bad Gateway'
  },
  errorUndefinedTitle: {
    id: 'LanguageDropdown.errorUndefinedTitle',
    description: 'Error undefined message title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'LanguageDropdown.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description avaliable.'
  },
  error: {
    id: 'LanguageDropdown.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  translationUnderRevision: {
    id: 'LanguageDropdown.languageUnderRevision',
    description: 'Translation under revision',
    defaultMessage: 'Translation under revision'
  }
})

export default messages
