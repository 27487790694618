import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'

import DeviceConfiguration from './DeviceConfiguration'

const mapStateToProps = state => ({
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activateDevice: csNode.actions.activateDevice,
      getDevice: csNode.actions.fetchCSNode,
      getDevicesList: csNode.actions.fetchCSNodesDetails,
      updateDevice: csNode.actions.updateDevice
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceConfiguration))
