import { connect } from 'react-redux'

import {
  getNotificationsRulesUrl,
  getNotificationAssignDevicesUrl,
  getEditNotificationCondicionalSignalsUrl,
  getEditNotificationAssignUsersUrl
} from '../url'
import NormalEditNotificationGeneralProperties from './EditNotificationGeneralProperties'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { utils as groupUtils } from 'ducks/groups'

import csNode from 'modules/csNode'

const mapStateToProps = (state, { match, history }) => ({
  notificationId: match.params.notificationId,
  notificationsUrl: getNotificationsRulesUrl(),
  notificationAssignDevicesUrl: getNotificationAssignDevicesUrl(match.params.notificationId),
  editNotificationConditionalSignalsUrl: getEditNotificationCondicionalSignalsUrl,
  notificationConditionalSignalsUrl: getEditNotificationCondicionalSignalsUrl(match.params.notificationId),
  editNotificationAssignUsersUrl: getEditNotificationAssignUsersUrl,
  notificationAssignUsersUrl: getEditNotificationAssignUsersUrl(match.params.notificationId),
  currentGroupId: groupUtils.getSelectedGroup(state).id,
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId),
  canReadDevices: resolvePermissions(state, c.DEVICE_READ)
})

const mapDispatchToProps = dispatch => ({
  getDevicesDetail: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs))
})

const EditNotificationGeneralProperties = connect(
  mapStateToProps,
  mapDispatchToProps
)(NormalEditNotificationGeneralProperties)

export { EditNotificationGeneralProperties }
