import { actions } from 'utils/http'

const getDataPlans = (groupId, limit, offset) => dispatch =>
  dispatch(actions.getDataPlans(groupId, limit, offset)).promise.then(({ payload }) => payload)

const getDataPlan = dataPlanId => dispatch =>
  dispatch(actions.getDataPlan(dataPlanId)).promise.then(({ payload }) => payload)

const activateDataPlan = (groupId, dataPlanId, deviceEid) => dispatch =>
  dispatch(actions.activateDataPlan(groupId, dataPlanId, deviceEid)).promise.then(({ payload }) => payload)

const getNumberOfDataPlans = groupId => dispatch =>
  dispatch(actions.getNumberOfDataPlans(groupId)).promise.then(({ payload }) => payload)

const shareDataPlans = (targetGroupId, body) => dispatch =>
  dispatch(actions.shareDataPlans(targetGroupId, body)).promise.then(({ payload }) => payload)

export { getDataPlans, getDataPlan, activateDataPlan, getNumberOfDataPlans, shareDataPlans }
