import { defineMessages } from 'react-intl'

const messages = defineMessages({
  videoWidget: {
    id: 'VideoSettings.videoWidget',
    description: 'Video widget',
    defaultMessage: 'VIDEO WIDGET'
  },
  videoWidgetSettings: {
    id: 'VideoSettings.videoWidgetSettings',
    description: 'Video widget settigs dialog',
    defaultMessage: 'Video widget settigs:'
  },
  url: {
    id: 'VideoSettings.url',
    description: 'Url',
    defaultMessage: 'Url'
  },
  cancel: {
    id: 'VideoSettings.cancel',
    description: 'Video widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'VideoSettings.save',
    description: 'Video widget settings save button text',
    defaultMessage: 'Save'
  },
  urlMustStartWith: {
    id: 'VideoSettings.urlMustStartWith',
    description: 'Url must start with "https://" or "http://"',
    defaultMessage: 'Url must start with "https://" or "http://"'
  },
  urlCantBeEmpty: {
    id: 'VideoSettings.urlCantBeEmpty',
    description: 'Url can\'t be empty',
    defaultMessage: 'Url can\'t be empty'
  },
  widgetNotConfigured: {
    id: 'VideoSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  errorFetchingVideo: {
    id: 'VideoSettings.errorFetchingVideo',
    description: 'Error fetching video',
    defaultMessage: 'Error fetching video'
  }
})

export default messages
