import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Text: {
    id: 'EditNotificationAssignUsers.error400Text',
    description: 'Error 400 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error401Text: {
    id: 'EditNotificationAssignUsers.error401Text',
    description: 'Error 401 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error403Text: {
    id: 'EditNotificationAssignUsers.error403Text',
    description: 'Error 403 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error404Text: {
    id: 'EditNotificationAssignUsers.error404Text',
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: 'EditNotificationAssignUsers.error406Text',
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  error500Text: {
    id: 'EditNotificationAssignUsers.error500Text',
    description: 'Error 500 text',
    defaultMessage: 'Access denied'
  },
  errorUndefinedTitle: {
    id: 'EditNotificationAssignUsers.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: 'EditNotificationAssignUsers.errorUndefinedText',
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  title: {
    id: 'EditNotificationAssignUsers.title',
    description: 'Edit notification title',
    defaultMessage: 'Notification'
  },
  previous: {
    id: 'EditNotificationAssignUsers.previous',
    description: 'Previous button label',
    defaultMessage: 'Previous'
  },
  list: {
    id: 'EditNotificationAssignUsers.list',
    description: 'List button label',
    defaultMessage: 'List'
  },
  newUsersAssigned: {
    id: 'EditNotificationAssignUsers.newUsersAssigned',
    description: 'New users assigned to this notification message',
    defaultMessage: 'New users assigned to this notification.'
  },
  notificationCreatedAssignUsers: {
    id: 'EditNotificationAssignUsers.notificationCreatedAssignUsers',
    description: 'Notification created now you can assign users message',
    defaultMessage: 'The notification was successfully created. Now you can assign users to it.'
  },
  otherError400Text: {
    id: 'EditNotificationAssignUsers.otherError400Text',
    description: 'Other error 400 text',
    defaultMessage: 'Bad request'
  },
  unassignUsers: {
    id: 'EditNotificationAssignUsers.unassignUsers',
    description: 'Unassign part of title',
    defaultMessage: 'Unassign {number} users.'
  },
  unassignUser: {
    id: 'EditNotificationAssignUsers.unassignUser',
    description: 'Unassign user button title',
    defaultMessage: 'Unassign user.'
  },
  usersUnassignedCorrectly: {
    id: 'EditNotificationAssignUsers.usersUnassignedCorrectly',
    description: 'Users unassigned correctly message',
    defaultMessage: 'Users unassigned correctly.'
  },
  cancel: {
    id: 'EditNotificationAssignUsers.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  error409Text: {
    id: 'EditNotificationAssignUsers.error409Text',
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation: Group name it is already in use'
  },
  error415Text: {
    id: 'EditNotificationAssignUsers.error415Text',
    description: 'Error 415 text',
    defaultMessage: 'Unsupported media type'
  },
  error422Text: {
    id: 'EditNotificationAssignUsers.error422Text',
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  yes: {
    id: 'EditNotificationAssignUsers.yes',
    description: 'Yes enabled value',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'EditNotificationAssignUsers.no',
    description: 'No enabled value',
    defaultMessage: 'No'
  },
  notificationUpdatedTitle: {
    id: 'EditNotificationAssignUsers.notificationUpdatedTitle',
    description: 'Notification updated title',
    defaultMessage: 'Notification updated'
  },
  notificationUpdatedMessage: {
    id: 'EditNotificationAssignUsers.notificationUpdatedMessage',
    description: 'Notification updated message',
    defaultMessage: 'Data have been saved correctly.'
  },
  otherError404Text: {
    id: 'EditNotificationAssignUsers.otherError404Text',
    description: 'Other error 404 text',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  otherError409Text: {
    id: 'EditNotificationAssignUsers.otherError409Text',
    description: 'Other error 409 text',
    defaultMessage: 'Data integrity violation'
  },
  error500ToSHow: {
    id: 'EditNotificationAssignUsers.error500ToSHow',
    description: 'Error 500 to show message',
    defaultMessage: 'Server error'
  },
  typesOfNotification: {
    id: 'EditNotificationAssignUsers.typesOfNotification',
    description: 'Types of notification title',
    defaultMessage: 'Types of notification'
  },
  selectTypesOfNotification: {
    id: 'EditNotificationAssignUsers.selectTypesOfNotification',
    description: 'Select the types of notification to apply to assigned users message',
    defaultMessage: 'Select the types of notification to apply to assigned users:'
  },
  sendAnEmail: {
    id: 'EditNotificationAssignUsers.sendAnEmail',
    description: 'Send an email option label',
    defaultMessage: 'Send an email'
  },
  sendPush: {
    id: 'EditNotificationAssignUsers.sendPush',
    description: 'Send a push notification option label',
    defaultMessage: 'Send a push notification'
  },
  save: {
    id: 'EditNotificationAssignUsers.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  showingRows: {
    id: 'EditNotificationAssignUsers.showingRows',
    description: 'Edit notifications table total text showing rows',
    defaultMessage: 'Showing users'
  },
  to: {
    id: 'EditNotificationAssignUsers.to',
    description: 'Edit notifications table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'EditNotificationAssignUsers.of',
    description: 'Edit notifications table total text of',
    defaultMessage: 'of'
  },
  noUsersAssigned: {
    id: 'EditNotificationAssignUsers.noUsersAssigned',
    description: 'No users assigned message',
    defaultMessage: 'No users assigned'
  },
  prePage: {
    id: 'EditNotificationAssignUsers.prePage',
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'EditNotificationAssignUsers.nextPage',
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'EditNotificationAssignUsers.firstPage',
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'EditNotificationAssignUsers.lastPage',
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  assignedUsers: {
    id: 'EditNotificationAssignUsers.assignedUsers',
    description: 'Assigned users header',
    defaultMessage: 'Assigned users'
  },
  assignUsers: {
    id: 'EditNotificationAssignUsers.assignUsers',
    description: 'Assign users button label',
    defaultMessage: 'Assign users'
  },
  unassignSelectedUsers: {
    id: 'EditNotificationAssignUsers.unassignSelectedUsers',
    description: 'Unassign selected users button label',
    defaultMessage: 'Unassign selected users'
  },
  clearSelection: {
    id: 'EditNotificationAssignUsers.clearSelection',
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  searchPlaceholder: {
    id: 'EditNotificationAssignUsers.searchPlaceholder',
    description: 'searchPlaceholder',
    defaultMessage: 'Filter by orderable columns'
  },
  placeholder: {
    id: 'EditNotificationAssignUsers.placeholder',
    description: 'Table header column filter placeholder',
    defaultMessage: 'Enter'
  },
  firstName: {
    id: 'EditNotificationAssignUsers.firstName',
    description: 'First name table header column label',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'EditNotificationAssignUsers.lastName',
    description: 'Last name table header column label',
    defaultMessage: 'Last name'
  },
  email: {
    id: 'EditNotificationAssignUsers.email',
    description: 'Email table header column label',
    defaultMessage: 'Email'
  },
  smsService: {
    id: 'AssignUsersToNotification.smsService',
    defaultMessage: 'SMS Service'
  },
  available: {
    id: 'AssignUsersToNotification.available',
    defaultMessage: 'Available'
  },
  notAvailable: {
    id: 'AssignUsersToNotification.notAvailable',
    defaultMessage: 'Not available'
  },
  sms: {
    id: 'AssignUsersToNotification.sms',
    defaultMessage: 'SMS'
  },
  enabled: {
    id: 'EditNotificationAssignUsers.enabled',
    description: 'Enabled table header column label',
    defaultMessage: 'Enabled'
  },
  notify: {
    id: 'EditNotificationAssignUsers.notify',
    description: 'Notify table header column label',
    defaultMessage: 'Notify'
  },
  actions: {
    id: 'EditNotificationAssignUsers.actions',
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  confirmUserUnassignament: {
    id: 'EditNotificationAssignUsers.confirmUserUnassignament',
    description: 'Confirm user unassignment message',
    defaultMessage: 'Confirm user unassignment'
  },
  error: {
    id: 'EditNotificationAssignUsers.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  errorRequestingUsers: {
    id: 'EditNotificationAssignUsers.errorRequestingUsers',
    description: 'Error requesting users',
    defaultMessage: 'Error requesting users'
  },
  error401Message: {
    id: 'EditNotificationAssignUsers.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  unassign: {
    id: 'EditNotificationAssignUsers.unassign',
    description: 'Unassign button label',
    defaultMessage: 'Unassign'
  },
  close: {
    id: 'EditNotificationAssignUsers.close',
    description: 'Close button label',
    defaultMessage: 'Close'
  },
  selectedUsers: {
    id: 'EditNotificationAssignUsers.selectedUsers',
    description: 'Selected users button label',
    defaultMessage: 'Selected users'
  },
  groupActions: {
    id: 'EditNotificationAssignUsers.groupActions',
    description: 'Group actions label text',
    defaultMessage: 'Group actions'
  },
  selectAction: {
    id: 'EditNotificationAssignUsers.selectAction',
    description: 'Select action menu item hint text',
    defaultMessage: 'Select action'
  },
  assignedUserId: {
    id: 'EditNotificationAssignUsers.assignedUserId',
    description: 'Assigned user ID table header column text',
    defaultMessage: 'Assigned user ID'
  },
  notifyBy: {
    id: 'EditNotificationAssignUsers.notifyBy',
    description: 'Notify by table header column text',
    defaultMessage: 'Notify by'
  },
  bell: {
    id: 'EditNotificationAssignUsers.bell',
    description: 'Bell header column text',
    defaultMessage: 'Bell'
  },
  push: {
    id: 'EditNotificationAssignUsers.push',
    description: 'Push table header column text',
    defaultMessage: 'Push'
  }
})

export default messages
