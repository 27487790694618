import * as constants from './constants'
import reducer, * as utils from './reducer'

import * as actions from './actions'

import * as url from './url'
import * as plus1ConnectUrl from './plus1ConnectUrl'
import client from './client'
import instance from './requests'
import { getData } from './requests'
import { mapDynamicDevicesFilterObject, getFiltersMatchingDevices } from './filters'

import plus1ConnectInstance from './plus1ConnectRequest'
import { getData as getPlus1ConnectData } from './plus1ConnectRequest'

// Simple error handling
const logError = error => {
  console.error('Incorrect request', error) // eslint-disable-line no-console
}

export { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware'
export { axiosMiddlewareOptions, axiosPlus1ConnectMiddlewareOptions } from './configuration'
export {
  constants,
  reducer,
  utils,
  url,
  plus1ConnectUrl,
  actions,
  client,
  logError,
  instance, // Rethink: Used outside to configure 'redux-axios-middleware'
  plus1ConnectInstance,
  getPlus1ConnectData,
  getData,
  mapDynamicDevicesFilterObject,
  getFiltersMatchingDevices
}
