import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import ReportsTable from './ReportsTable'

import ReportsTableTitle from './ReportsTableTitle'
class Reports extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: 'danger'
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  setAlert = (alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText) => {
    this.setState({
      alertMessages,
      alertMessagesType,
      alertMessagesTitle,
      alertMessagesText
    })
  }

  render() {
    const { canCreateReports, newReportUrl, deleteReport, deleteReportAWS, groupId, fetchReports } = this.props
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType } = this.state
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <ReportsTableTitle canCreateReports={canCreateReports} newReportUrl={newReportUrl} />
          </div>
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              {alertMessages && (
                <Alert
                  alertType={alertMessagesType}
                  closeFunction={this.closeAlert}
                  messageText={alertMessagesText}
                  messageTitle={alertMessagesTitle}
                  showAlert={alertMessages}
                />
              )}
              <ReportsTable
                deleteReport={deleteReport}
                deleteReportAWS={deleteReportAWS}
                fetchReports={fetchReports}
                groupId={groupId}
                setAlert={this.setAlert}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Reports.propTypes = {
  canCreateReports: PropTypes.bool.isRequired,
  deleteReport: PropTypes.func.isRequired,
  deleteReportAWS: PropTypes.func.isRequired,
  fetchReports: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  newReportUrl: PropTypes.string.isRequired
}

export default injectIntl(Reports)
