import React from 'react'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

const NewRoleFormTitle = ({ rolesUrl, canReadRoles, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)

  return <PageTitle title={title} back={canReadRoles ? rolesUrl : null} /*button={canReadRoles ? button : null}*/ />
}

export default injectIntl(NewRoleFormTitle)
