import React from 'react'
import PropTypes from 'prop-types'

import { Map, Marker } from '../wrapper'
import { Position } from '../../props'

import EditLocation from '@material-ui/icons/EditLocation'
import FixedLocation from '@material-ui/icons/Place'

import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'

import { injectIntl } from 'react-intl'
import messages from './messages'

const areEqual = (loc1, loc2) => loc1 && loc2 && loc1.longitude === loc2.longitude && loc1.latitude === loc2.latitude

const EditorMap = ({
  originalLocation = {
    longitude: -1.9800711,
    latitude: 43.2956367
  },
  newLocation,
  extraLocations,
  onChange,
  intl
}) => {
  const { formatMessage } = intl
  let otherLocations = null
  if (extraLocations) {
    otherLocations = extraLocations.map((l, i) => <Marker key={i} position={l} />)
  }

  let previous = null
  if (areEqual(originalLocation, newLocation)) {
    previous = (
      <Marker
        position={originalLocation}
        label={formatMessage(messages.markerLabel)}
        onClick={() => onChange(originalLocation)}
      >
        {onChange ? <EditLocation color={grey[500]} /> : <FixedLocation color={red[500]} />}
      </Marker>
    )
  }

  let next = null
  if (newLocation) {
    next = (
      <Marker position={newLocation}>
        <EditLocation color={red[500]} />
      </Marker>
    )
  }

  return (
    <Map
      onClick={e => {
        if (onChange) {
          onChange({
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng()
          })
        }
      }}
      defaultCenter={originalLocation}
      defaultZoom={5}
    >
      {otherLocations}
      {previous}
      {next}
    </Map>
  )
}

EditorMap.propTypes = {
  extraLocations: PropTypes.arrayOf(Position),
  newLocation: Position,
  onChange: PropTypes.func,
  originalLocation: Position
}

export default injectIntl(EditorMap)
