import { defineMessages } from 'react-intl'
 
const messages = defineMessages({
  logo: {
    id: 'Logo.logo',
    description: 'Logo alt text',
    defaultMessage: 'Logo'
  },
})
 
export default messages