import { combineReducers } from 'redux'

import {
  MOUNTED_AT as C_MOUNT,
  createReducer as createConfigReducer
} from './config'
import { MOUNTED_AT as S_MOUNT, reducer as series } from './series'
import { MOUNTED_AT as ST_MOUNT, reducer as status } from './status'

const createReducer = (
  { selectInNavigator, pushExtreme },
  plotStrategy,
  transformationArguments,
  createSetExtremes
) => {
  const config = createConfigReducer(
    plotStrategy,
    { selectInNavigator, pushExtreme, createSetExtremes },
    transformationArguments
  )

  return combineReducers({
    [ST_MOUNT]: status,
    [C_MOUNT]: config,
    [S_MOUNT]: series
  })
}

export default createReducer

export {
  getConfig,
  isNavigatorEnabled,
  getLowerLimit,
  getUpperLimit,
  getSeries as getPlottedSeries
} from './config'

export { getSeries, getVisibleSeries, isOnRealTimeMode } from './series'

export { isPlotting, hasBeenUpdatedAfter } from './status'
