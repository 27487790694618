const isRepresentable = grp => grp.markerURL && grp.location

const _getRepresentableGroupsRecursively = (
  groupsById,
  groups = [],
  ret = []
) => {
  groups.forEach(g => {
    const grp = groupsById[g]
    if (grp) {
      if (isRepresentable(grp)) {
        ret.push(g)
      } else {
        _getRepresentableGroupsRecursively(groupsById, grp.children, ret)
      }
    }
  })
  return ret
}

export { isRepresentable, _getRepresentableGroupsRecursively }
