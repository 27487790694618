const getUrlParameter = (search = '', parameterName) => {
  if (search.length > 0) {
    const parts = search.substr(1).split('&')
    const key = `${parameterName}=`
    const found = parts.find(p => p.startsWith(key))
    if (found) {
      return found.substr(key.length)
    }
  }
  // return undefined
}

const isLocalhost = Boolean(
  window.location.hostname === process.env.REACT_APP_HOST ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export { getUrlParameter, isLocalhost }
