import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions } from 'ducks/groups'

import { getGroupsUrl } from '../urls'
import GroupSelect from './GroupSelect'
import NewGroup from './NewGroup'

const mapStateToProps = (state, { history }) => ({
  groupsUrl: getGroupsUrl(),
  onCreateGroup: () => {
    history.push(getGroupsUrl(), 'create')
  }
})

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(actions.getGroups()),
  newGroup: (parentGroupId, groupName) => dispatch(actions.createGroup(parentGroupId, groupName))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewGroup))
export { GroupSelect }
