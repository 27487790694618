import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

import SmsServiceStateChip from 'components/SmsServiceStateChip'
import client from 'utils/http/client'

import { getAdaptedDetailedUsers, getAdaptedNotificationActions } from '../../../apiAdapters'
import { NOTIFICATION_TYPES } from '../../../constants'
import messages from '../../../messages'
import { dialogStyles, iconButtonStyles, paperStyles } from '../../../styles'
import { getTableOptions } from '../../../tableHelpers'
import ServerErrorAlert from '../../alerts/ServerErrorAlert'
import NotificationStateChip from '../../chips/NotificationStateChip'

const styles = {
  ...dialogStyles,
  ...iconButtonStyles,
  ...paperStyles
}

class AssignedUsersDetailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAssignedUsersLoading: false,
      assignedUsersData: [],

      isNotificationActionsLoading: false,
      notificationActionsData: [],

      isApiError: false,

      totalSize: 0
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      this.fetchAssignedUsersData().then(this.fetchNotificationActions)
    }
  }

  fetchAssignedUsersData = () => {
    const { assignedUsers, fetchUsersOfGroupDetails, groupId } = this.props

    const userEmails = assignedUsers.map(user => user.email)

    this.setState({ isAssignedUsersLoading: true, isApiError: false })
    return fetchUsersOfGroupDetails(groupId, userEmails)
      .then(response => {
        const assignedUsersData = getAdaptedDetailedUsers(response?.data?.users)

        this.setState({ assignedUsersData, totalSize: assignedUsersData.length })
      })
      .catch(() => {
        this.setState({ isApiError: true })
        return true
      })
      .finally((isApiError = false) => {
        this.setState({ isAssignedUsersLoading: false })
        return isApiError
      })
  }

  fetchNotificationActions = (isApiError = false) => {
    const { groupId } = this.props

    if (isApiError) return

    this.setState({ isNotificationActionsLoading: true, isApiError: false })
    client
      .getNotificationsActions(groupId)
      .then(response => {
        const notificationActionsData = getAdaptedNotificationActions(response.data)

        this.setState({ notificationActionsData })
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isNotificationActionsLoading: false })
      })
  }

  getTableOptions = () => {
    const { intl } = this.props
    const { isAssignedUsersLoading, isNotificationActionsLoading } = this.state

    return getTableOptions({
      intl,
      isLoading: isAssignedUsersLoading || isNotificationActionsLoading,
      page: 1,
      pageSize: 10
    })
  }

  getTableData = () => {
    const { assignedUsers } = this.props
    const { assignedUsersData, notificationActionsData } = this.state

    return assignedUsers.map(user => {
      const userData = assignedUsersData.find(item => item.email === user.email) || {}
      const notificationIds = user.notifications.map(item => item.hashId)
      const { hashId: bellNotificationId } =
        notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.BELL) || {}
      const { hashId: emailNotificationId } =
        notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.EMAIL) || {}
      const { hashId: smsNotificationId } =
        notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.SMS) || {}
      const hasBellNotification = notificationIds.includes(bellNotificationId)
      const hasEmailNotification = notificationIds.includes(emailNotificationId)
      const hasSmsNotification = notificationIds.includes(smsNotificationId)

      return {
        email: user.email,
        name: userData.name,
        lastName: userData.lastName,
        smsService: userData.smsService,
        hasBellNotification,
        hasEmailNotification,
        hasSmsNotification
      }
    })
  }

  formatSmsServiceColumn = state => {
    return <SmsServiceStateChip state={state} />
  }

  formatNotificationsColumn = isEnabled => {
    return <NotificationStateChip isEnabled={isEnabled} />
  }

  render() {
    const { assignedUsers, classes, intl, isOpen, onCloseClick, onEditClick } = this.props
    const { isApiError, isAssignedUsersLoading, isNotificationActionsLoading, totalSize } = this.state

    const tableOptions = this.getTableOptions()
    const tableData = this.getTableData()

    const isLoading = isAssignedUsersLoading || isNotificationActionsLoading

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.paperRoot } }}
        fullWidth
        maxWidth='lg'
        onClose={onCloseClick}
        open={isOpen}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.assignedUsersDetail)}
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onCloseClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isOpen && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid classes={{ root: classes.dialogContentGridContainer }} container>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {isApiError && (
                    <Grid item xs={12}>
                      <ServerErrorAlert />
                    </Grid>
                  )}
                  <Grid container item justifyContent='flex-end' xs={12}>
                    <Button
                      className='primary-action-button'
                      onClick={onEditClick}
                      startIcon={assignedUsers.length === 0 ? <AddIcon /> : <EditIcon />}
                    >
                      {intl.formatMessage(messages.edit)}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='table-with-pagination' style={{ padding: 0 }}>
                      <BootstrapTable
                        bordered={false}
                        data={isLoading ? [] : tableData}
                        fetchInfo={{ dataTotalSize: totalSize }}
                        hover
                        keyField='email'
                        options={tableOptions}
                        pagination
                        remote={remoteObj => ({
                          ...remoteObj,
                          search: false,
                          pagination: true,
                          sizePerPage: true,
                          sort: false,
                          filter: true
                        })}
                      >
                        <TableHeaderColumn dataField='name' dataSort>
                          {intl.formatMessage(messages.name)}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='lastName' dataSort>
                          {intl.formatMessage(messages.lastName)}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='email' dataSort>
                          {intl.formatMessage(messages.email)}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='smsService' dataFormat={this.formatSmsServiceColumn} dataSort>
                          {intl.formatMessage(messages.smsService)}
                        </TableHeaderColumn>

                        <TableHeaderColumn dataField='hasBellNotification' dataFormat={this.formatNotificationsColumn}>
                          {intl.formatMessage(messages.bellNotifications)}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='hasEmailNotification' dataFormat={this.formatNotificationsColumn}>
                          {intl.formatMessage(messages.emailNotifications)}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='hasSmsNotification' dataFormat={this.formatNotificationsColumn}>
                          {intl.formatMessage(messages.smsNotifications)}
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    )
  }
}

AssignedUsersDetailModal.propTypes = {
  assignedUsers: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  fetchUsersOfGroupDetails: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(AssignedUsersDetailModal))
