import { connect } from 'react-redux'

import { actions, utils as groupUtils } from 'ducks/groups'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { getUsersUrl, getEditUsersUrl, getNewUserUrl } from '../url'
import UnconnectedUsers from './Users'

const mapStateToProps = state => ({
  user: state.login.user,
  canAddUser: resolvePermissions(state, c.USER_CREATE),
  canEditUser: resolvePermissions(state, c.USER_EDIT),
  canDeleteUser: resolvePermissions(state, c.USER_EDIT),
  canReadRoles: groupUtils.hasPermission(state, c.GROUPROLE_READ),
  usersUrl: getUsersUrl(),
  editUsersUrl: getEditUsersUrl(),
  newUserUrl: getNewUserUrl(),
  selectedGroup: groupUtils.getSelectedGroup(state)
})

const mapDispatchToProps = dispatch => ({
  getUsers: groupName => dispatch(actions.getUsersOfGroup(groupName)),
  deleteUser: (groupId, users) => dispatch(actions.removeUsersFromGroup(groupId, users)),
  changeGroup: groupId => dispatch(actions.changeGroup(groupId)),
  getDetailedUsers: (groupId, limit, offset) => dispatch(actions.getDetailedUsersOfGroup(groupId, limit, offset))
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const ret = { ...stateProps, ...dispatchProps, ...ownProps }
  delete ret.staticContext
  return ret
}

const Users = connect(mapStateToProps, mapDispatchToProps, mergeProps)(UnconnectedUsers)

export { Users }
