import imageBase64 from './imageBase64'

const ImageTemplate = {
  id: '',
  width: 280,
  height: 160,
  x: 20,
  y: 20,
  minHeight: undefined,
  minWidth: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'image',
    params: {
      image: imageBase64,
      name: 'default',
      type: 'image/jpeg',
      size: '10kb',
      data: '',
      valueType: '',
      value: {
        timestamp: '',
        value: ''
      },
      url: '',
      conditionalParams: {
        operator: '',
        value: '',
        image: '',
        name: '',
        type: '',
        size: '',
        url: ''
      }
    }
  }
}

export default ImageTemplate
