import { createSelector } from 'reselect'

import { actionTypes as wt } from 'middlewares/websocket'
import * as t from '../../actionTypes'
import subscriptions, {
  MOUNT_ON as SUBS,
  hasNoSubscribers
} from './subscriptions'

const CONNECTING = 'connecting'
const DISCONNECTING = 'disconnecting'
const CONNECTED = 'connected'
const DISCONNECTED = 'disconnected'

const initialState = {
  status: DISCONNECTED,
  accessKeyId: null,
  secretAccessKey: null,
  sessionToken: null,
  [SUBS]: subscriptions()
}

// Websocket URLs by node id
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.CONNECT_START:
      return {
        ...state,
        status: CONNECTING,
        accessKeyId: null,
        secretAccessKey: null,
        sessionToken: null
      }

    case wt.CONNECT:
      const { accessKeyId, secretAccessKey, sessionToken } = action.payload
      return {
        ...state,
        status: CONNECTING,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        expiration: action.meta.expiration
      }

    case wt.RECONNECT:
      return {
        ...state,
        status: CONNECTING
      }

    case wt.CONNECT_SUCCESS:
      return {
        ...state,
        status: CONNECTED
      }

    case wt.DISCONNECT:
      return {
        ...state,
        status: DISCONNECTING
      }

    case wt.OFFLINE:
    case wt.DISCONNECT_SUCCESS:
    case wt.CONNECT_FAIL:
    case t.CONNECT_END:
      return {
        ...state,
        status: DISCONNECTED
      }

    case t.REQUEST_SUBSCRIPTIONS:
    case t.REQUEST_UNSUBSCRIPTIONS:
    case wt.SUBSCRIBE:
    case wt.SUBSCRIBE_SUCCESS:
    case wt.SUBSCRIBE_FAIL:
    case wt.UNSUBSCRIBE:
    case wt.UNSUBSCRIBE_SUCCESS:
      const newSubscriptions = subscriptions(state[SUBS], action)
      return {
        ...state,
        [SUBS]:
          Object.keys(newSubscriptions).length > 0
            ? newSubscriptions
            : undefined
      }

    default:
  }

  return state
}

export default reducer

// Selectors
const isConnected = conn => (conn ? conn.status === CONNECTED : false)
const isConnecting = conn => (conn ? conn.status === CONNECTING : false)

const getCredentials = createSelector(conn => {
  if (isConnected(conn)) {
    return {
      accessKeyId: conn.accessKeyId,
      secretAccessKey: conn.secretAccessKey,
      sessionToken: conn.sessionToken
    }
  }
  // return undefined
})
const getAccessKeyID = conn => (conn ? conn.accessKeyId : null)
const hasExpired = conn =>
  conn && 'expiration' in conn ? new Date(conn.expiration) < new Date() : false

/* SUBSCRIPTIONS*/
const isActive = conn => {
  const isValidConn = conn && conn[SUBS] && typeof conn[SUBS] === 'object'
  const topics = isValidConn ? Object.keys(conn[SUBS]) : []
  return isConnected(conn) && topics.some(topic => !hasNoSubscribers(conn, topic))
}

export {
  isConnected,
  isConnecting,
  getAccessKeyID,
  getCredentials,
  hasExpired,
  isActive
}

export {
  hasPendingSubscriptions,
  isSubscribed,
  isSubscribing,
  hasNoSubscribers
} from './subscriptions'
