import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const PageSubTitle = ({ title = '', button = null }) => {
  const showButton = () => {
    if (button !== null) {
      if (button.primary === undefined) {
        button.primary = true // eslint-disable-line no-param-reassign
      }
      return (
        <Link to={button.path} className="button-link">
          <Button variant="contained" color={button.primary ? 'primary' : undefined} style={{ marginTop: 20 }}>
            {button.icon && <Icon className={'zmdi ' + button.icon} />}
            {button.label}
          </Button>
        </Link>
      )
    } else {
      return null
    }
  }

  return (
    <div className="row">
      <div className="col-sm-8">
        <h2>{title}</h2>
      </div>
      <div className="col-sm-4 text-right">{showButton()}</div>
    </div>
  )
}

PageSubTitle.propTypes = {
  button: PropTypes.any,
  title: PropTypes.string.isRequired
}

export default PageSubTitle
