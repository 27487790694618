export const parseBucketSize = (value) => {
  switch (value) {
    case 60000:
      return 'minute' //should be minute
    case 3600000:
      return 'hour'
    case 86400000:
      return 'day'
    default:
      return 'hour'
  }
}
