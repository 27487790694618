import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getRolesUrl, getNewRoleUrl } from '../url'
import UnconnectedRoles from './Roles'

import { actions, utils as groupUtils } from 'ducks/groups'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = (state, { history }) => ({
  userRole: state.login.userProfile.roles.length > 0 ? state.login.userProfile.roles[0].name : '',
  newRoleUrl: getNewRoleUrl(),
  urlAction: history.location.state,
  onDeleteRole: () => {
    history.push(getRolesUrl(), 'delete')
  },
  selectedGroup: groupUtils.getSelectedGroup(state),
  groupId: groupUtils.getSelectedGroup(state).id,
  canCreateRoles: resolvePermissions(state, c.GROUPROLE_CREATE)
})

const mapDispatchToProps = dispatch => ({
  getRoles: groupId => dispatch(actions.getAllRolesOfGroup(groupId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnconnectedRoles))
