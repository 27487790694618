import { millisecondIn } from './utils'
import translations from 'i18n/locales'

export const bucketSize = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return [
    { value: millisecondIn.minute(), label: i18n['NewReport.minute'] },
    { value: millisecondIn.hour(), label: i18n['NewReport.hour'] },
    { value: millisecondIn.day(), label: i18n['NewReport.day'] }
  ]
}

export const frequency = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return [
    { label: i18n['NewReport.daily'], value: 'DAY' },
    { label: i18n['NewReport.weekly'], value: 'WEEK' },
    { label: i18n['NewReport.monthly'], value: 'MONTH' }
  ]
}

export const status = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return [
    { value: true, label: i18n['NewReport.active'] }
    //,{ value: false, label: 'Inactive' }
  ]
}
