import { actionTypes as wt } from 'middlewares/websocket'
import { widgetUpdateRealTimeValues } from 'ducks/dashboards'
import { widgetUpdateRealTimeValues as updateWidgetRealTimeValues } from 'ducks/groupDashboards'
import { isDeviceDashboardLocation } from 'utils/location'

const createMiddleware = isRealTimeSignalTopic => {
  const middleware = store => {
    const dispatch = store.dispatch
    return next => action => {
      switch (action.type) {
        case wt.MESSAGE_RECEIVED:
        case wt.GROUPED_MESSAGES_RECEIVED:
          if (isRealTimeSignalTopic(action.meta.topic) && action.payload.length !== 0) {
            const signals = action.payload
              .map(entry => JSON.parse(entry))
              .filter(({ type, id }) => type === 'signal' && typeof parseFloat(id) === 'number')
              .sort((a, b) => a.timestamp - b.timestamp)
            const signalsById = signals.reduce((acc, current) => {
              const { id, ...rest } = current
              const valuesWithCurrentId = acc[id] || []
              return { ...acc, [id]: [...valuesWithCurrentId, rest] }
            }, {})

            Object.entries(signalsById).forEach(([key, signalValues]) => {
              const signalNumber = parseFloat(key)
              const isDeviceDash = isDeviceDashboardLocation()
              if (isDeviceDash) {
                dispatch(widgetUpdateRealTimeValues(signalNumber, signalValues))
              } else {
                const rawEid = action.meta.topic.split('/')[1].substring(1)
                let eid = Array.from(rawEid).reduce((acc, cur, index) => {
                  let eidPart = cur
                  if (index % 2 === 1) eidPart += ':'
                  return acc + eidPart
                }, '')
                eid = eid.slice(0, -1)
                dispatch(updateWidgetRealTimeValues(signalNumber, eid, signalValues))
              }
            })
          }
          break
        default:
      }

      return next(action)
    }
  }
  return middleware
}

export default createMiddleware
