import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  deleteGroupDashboard,
  isEditing,
  getWidgets,
  getMaxZIndex,
  getDashboardSettings,
  startEditDashboard,
  saveAndFinishEditingDashboard,
  finishEditDashboard,
  addWidget,
  updateGroupDashboard
} from 'ducks/groupDashboards'

import { utils } from 'ducks/groups'
import { utils as themeUtils } from 'ducks/theme'
import * as c from 'utils/constants'

import DashboardWrapper from './DashboardWrapper'

const mapStateToProps = (state, ownProps) => {
  return {
    editing: isEditing(state),
    widgets: getWidgets(state),
    maxZIndex: getMaxZIndex(state),
    settings: getDashboardSettings(state),
    canDeleteGroupDashboard: utils.hasPermission(state, c.GROUPDASHBOARD_DELETE),
    canEditGroupDashboard: utils.hasPermission(state, c.GROUPDASHBOARD_EDIT),
    fontColor: themeUtils.getFontColor(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteGroupDashboard: dashboard => dispatch(deleteGroupDashboard(dashboard)),
    updateGroupDashboard: dashboard => dispatch(updateGroupDashboard(dashboard)),
    startEditDashboard: (hashId, previousName, description) =>
      dispatch(startEditDashboard(hashId, previousName, description)),
    saveAndFinishEditingDashboard: () => dispatch(saveAndFinishEditingDashboard()),
    finishEditDashboard: () => dispatch(finishEditDashboard()),
    addWidget: (template, deviceInfo) => dispatch(addWidget(template, deviceInfo))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper))
