import { connect } from 'react-redux'

import csNode from 'modules/csNode'
import { utils } from 'ducks/groups'

import DM1TableSettings from './DM1TableSettings'

const mapStateToProps = state => ({
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  getDynamicCSNodes: (groupId, extraQueryParameters, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, Infinity, 0, extraQueryParameters, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(DM1TableSettings)
