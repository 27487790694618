import canJ1939Map from './maps/J1939.json'
import inputSignalsMap from './maps/inputSignals'
import unitsMap from './maps/units'

const signalConfigBaseRegExpStringTemplate = 'SignalConfig(##n##)__(##m##)_'
const mfioSettingsBaseRegExpStringTemplate = 'MFIO(##n##)_'
const gnssSettingsBaseRegExpStringTemplate = 'GNSS_Config_'
const dm1SettingsBaseRegExpStringTemplate = 'DM1_'
const signalVirtualParameterRegExpStringTemplate = 'SignalConfig(##n##)__(##m##)_SignalSource_NV'

function getRegExpStringWithReplacedId(signalConfigRegExpStringTemplate, n, m = '') {
  return signalConfigRegExpStringTemplate.replace('##n##', n).replace('##m##', m)
}

const signalSourceValidValues = {
  CAN_J1939: 1,
  CAN_CUSTOMIZED: 2,
  MFIO: 3
}

const canJ1939ConfigMappingRegExpTemplates = {
  CANPort: signalConfigBaseRegExpStringTemplate + 'CCH_NV',
  pgn: signalConfigBaseRegExpStringTemplate + 'PIU_NV',
  spn: signalConfigBaseRegExpStringTemplate + 'SML_NV',
  sourceAddress: signalConfigBaseRegExpStringTemplate + 'SS__NV',
  divider: signalConfigBaseRegExpStringTemplate + '_DD_NV',
  frequency: signalConfigBaseRegExpStringTemplate + 'Sampleperiod_NV',
  sendInmediately: signalConfigBaseRegExpStringTemplate + 'SendImmediatly_NV',
  filterType: signalConfigBaseRegExpStringTemplate + 'Filtertype_NV' // Change the numeric value for the equivalent string
}

const canCustomizedConfigMappingRegExpTemplates = {
  CANPort: signalConfigBaseRegExpStringTemplate + 'CCH_NV',
  CANId: signalConfigBaseRegExpStringTemplate + 'PIU_NV',
  idMask: signalConfigBaseRegExpStringTemplate + 'SML_NV',
  startBit: signalConfigBaseRegExpStringTemplate + 'SS__NV',
  lengthOfBits: signalConfigBaseRegExpStringTemplate + '_L__NV',
  multiplier: signalConfigBaseRegExpStringTemplate + '_MM_NV',
  divider: signalConfigBaseRegExpStringTemplate + '_DD_NV',
  offset: signalConfigBaseRegExpStringTemplate + '_OO_NV',
  unit: signalConfigBaseRegExpStringTemplate + '_UU_NV',
  frequency: signalConfigBaseRegExpStringTemplate + 'Sampleperiod_NV',
  sendInmediately: signalConfigBaseRegExpStringTemplate + 'SendImmediatly_NV',
  filterType: signalConfigBaseRegExpStringTemplate + 'Filtertype_NV' // Change the numeric value for the equivalent string
}

const mfioConfigMappingRegExpTemplates = {
  pin: signalConfigBaseRegExpStringTemplate + 'CCH_NV',
  upperLimit: signalConfigBaseRegExpStringTemplate + 'PIU_NV',
  lowerLimit: signalConfigBaseRegExpStringTemplate + 'SML_NV',
  multiplier: signalConfigBaseRegExpStringTemplate + '_MM_NV',
  divider: signalConfigBaseRegExpStringTemplate + '_DD_NV',
  offset: signalConfigBaseRegExpStringTemplate + '_OO_NV',
  unit: signalConfigBaseRegExpStringTemplate + '_UU_NV',
  frequency: signalConfigBaseRegExpStringTemplate + 'Sampleperiod_NV',
  sendInmediately: signalConfigBaseRegExpStringTemplate + 'SendImmediatly_NV',
  filterType: signalConfigBaseRegExpStringTemplate + 'Filtertype_NV' // Change the numeric value for the equivalent string
}

const mfioSettingsMappingRegExpTemplates = {
  inputSignal: mfioSettingsBaseRegExpStringTemplate + 'InputMode_NV',
  bias: mfioSettingsBaseRegExpStringTemplate + 'Bias_NV',
  inputRange: mfioSettingsBaseRegExpStringTemplate + 'Range_NV',
  digThreshLow: mfioSettingsBaseRegExpStringTemplate + 'DigTreshL_NV', // This is not a typo, it's written this way at origin
  digThreshHigh: mfioSettingsBaseRegExpStringTemplate + 'DigThresH_NV'
}

const gnssSettingsMappingRegExpTemplates = {
  positionLogTime: gnssSettingsBaseRegExpStringTemplate + 'PositionLogTime_NV',
  altitudeLogTime: gnssSettingsBaseRegExpStringTemplate + 'AltitudeLogTime_NV',
  speedLogTime: gnssSettingsBaseRegExpStringTemplate + 'SpeedLogTime_NV',
  headingLogTime: gnssSettingsBaseRegExpStringTemplate + 'HeadingLogTime_NV'
}

const dm1SettingsMappingRegExpTemplates = {
  logToPortal: dm1SettingsBaseRegExpStringTemplate + 'LogToPortal_NV'
}

function canJ1939ExternalMapperAsCs100(config) {
  const inputProperty = 'spn'
  const outputProperty = 'unit'
  const extraConfig = {}

  if (config[inputProperty] && canJ1939Map.signals[config[inputProperty]]) {
    extraConfig[outputProperty] = canJ1939Map.signals[config[inputProperty]][outputProperty]
  }

  return extraConfig
}

function inputSignalExternalMapperAsCs100(config) {
  const inputProperty = 'inputSignal'
  const outputProperty = 'inputSignal'
  const unit = 'unit'
  const extraConfig = {}

  if (config[inputProperty]) {
    extraConfig[outputProperty] = inputSignalsMap[config[inputProperty]]

    if (config[inputProperty] === 1) {
      extraConfig[unit] = ''
    }
  }

  return extraConfig
}

function unitExternalMapperAsCs100(config) {
  const customUnit = 'customUnit'
  const inputProperty = 'unit'
  const outputProperty = 'unit'
  const extraConfig = {}

  if (config[customUnit] !== undefined && config[customUnit] !== null) {
    extraConfig[outputProperty] = config[customUnit]
  } else if (config[inputProperty] || config[inputProperty] === 0) {
    extraConfig[outputProperty] = unitsMap[config[inputProperty]]
  }

  return extraConfig
}

function logTypeExternalMapperAsCs100(config) {
  const inputProperty = 'filterType'
  const outputProperty = 'logType'
  const extraConfig = {}

  if (config[inputProperty] === 6) {
    extraConfig[outputProperty] = 15
  } else {
    extraConfig[outputProperty] = 1
  }

  return extraConfig
}

function getSignalOriginalSignalId(n, m) {
  return `Signal${n}__${m}_`
}

function getSignalName(signalSourceValue, signalId, customName) {
  switch (signalSourceValue) {
    case signalSourceValidValues.CAN_J1939:
      return customName !== null ? customName : `CAN J1939 - Signal ${signalId}`
    case signalSourceValidValues.CAN_CUSTOMIZED:
      return customName !== null ? customName : `CustomCAN - Signal ${signalId}`
    case signalSourceValidValues.MFIO:
      return customName !== null ? customName : `MFIO - Signal ${signalId}`

    default:
      break
  }
}

function getMappedConfigAsCs100(
  mappingRegExpTemplates,
  signalSource,
  parameters,
  virtualParameters,
  additionalExternalMappers = []
) {
  const config = {}
  config.deviceType = 'CS500'
  let n = ''
  let m = ''

  if (signalSource) {
    const signalConfigBaseRegExpString = getRegExpStringWithReplacedId(
      signalConfigBaseRegExpStringTemplate,
      '[0-9]',
      '[0-9]'
    )
    const signalConfigBaseRegExp = new RegExp(signalConfigBaseRegExpString)
    ;[, n, m] = signalSource.id.match(signalConfigBaseRegExp)

    const signalId = Number(n) * 10 + Number(m)
    config.signalId = signalId
    config.originalSignalId = getSignalOriginalSignalId(n, m)

    let customName = null
    const signalVirtualParameterRegExpString = getRegExpStringWithReplacedId(
      signalVirtualParameterRegExpStringTemplate,
      n,
      m
    )
    if (signalVirtualParameterRegExpString !== '') {
      const mappingRegExp = new RegExp(signalVirtualParameterRegExpString)
      customName =
        virtualParameters?.find(
          parameter => mappingRegExp.test(parameter.id) && parameter.name !== undefined && parameter.name !== null
        )?.name || null
    }
    config.name = getSignalName(signalSource.value, signalId, customName)

    if (
      signalVirtualParameterRegExpString !== '' &&
      (signalSource.value === signalSourceValidValues.CAN_CUSTOMIZED ||
        signalSource.value === signalSourceValidValues.MFIO)
    ) {
      const mappingRegExp = new RegExp(signalVirtualParameterRegExpString)
      let customUnit = null
      customUnit =
        virtualParameters?.find(
          parameter => mappingRegExp.test(parameter.id) && parameter.unit !== undefined && parameter.unit !== null
        )?.unit || null
      config.customUnit = customUnit
    }
  }

  Object.keys(mappingRegExpTemplates).forEach(mappingKey => {
    const mappingRegExpString = getRegExpStringWithReplacedId(mappingRegExpTemplates[mappingKey], n, m)
    const mappingRegExp = new RegExp(mappingRegExpString)
    const mappingParameter = parameters.find(parameter => mappingRegExp.test(parameter.id)) || {}

    config[mappingKey] = mappingParameter.value
  })

  const extraConfig = additionalExternalMappers.reduce((accumulatedConfig, currentMapper) => {
    let currentExtraConfig = {}
    if (typeof currentMapper === 'function') {
      currentExtraConfig = currentMapper(config)
    }

    return { ...accumulatedConfig, ...currentExtraConfig }
  }, {})

  return { ...config, ...extraConfig }
}

function getMappedMfioConfigAsCs100(pin, parameters, additionalExternalMappers) {
  const config = {}

  Object.keys(mfioSettingsMappingRegExpTemplates).forEach(mappingKey => {
    const mappingRegExpString = getRegExpStringWithReplacedId(mfioSettingsMappingRegExpTemplates[mappingKey], pin)
    const mappingRegExp = new RegExp(mappingRegExpString)
    const mappingParameter = parameters.find(parameter => mappingRegExp.test(parameter.id)) || {}

    config[mappingKey] = mappingParameter.value
  })

  const extraConfig = additionalExternalMappers.reduce((accumulatedConfig, currentMapper) => {
    let currentExtraConfig = {}
    if (typeof currentMapper === 'function') {
      currentExtraConfig = currentMapper(config)
    }

    return { ...accumulatedConfig, ...currentExtraConfig }
  }, {})

  return { ...config, ...extraConfig }
}

function getCanJ1939ConfigAsCs100(signalSource, parameters, virtualParameters) {
  return getMappedConfigAsCs100(canJ1939ConfigMappingRegExpTemplates, signalSource, parameters, virtualParameters, [
    canJ1939ExternalMapperAsCs100,
    logTypeExternalMapperAsCs100
  ])
}

function getCanCustomizedConfigAsCs100(signalSource, parameters, virtualParameters) {
  return getMappedConfigAsCs100(
    canCustomizedConfigMappingRegExpTemplates,
    signalSource,
    parameters,
    virtualParameters,
    [unitExternalMapperAsCs100, logTypeExternalMapperAsCs100]
  )
}

function getMfioConfigAsCs100(signalSource, parameters, virtualParameters) {
  const basicConfig = getMappedConfigAsCs100(
    mfioConfigMappingRegExpTemplates,
    signalSource,
    parameters,
    virtualParameters,
    [unitExternalMapperAsCs100, logTypeExternalMapperAsCs100]
  )
  const additionalMFIOConfig = getMappedMfioConfigAsCs100(basicConfig.pin, parameters, [
    inputSignalExternalMapperAsCs100
  ])

  return { ...basicConfig, ...additionalMFIOConfig }
}

function getGnssConfigAsCs100(parameters) {
  const basicConfig = getMappedConfigAsCs100(gnssSettingsMappingRegExpTemplates, null, parameters)
  return basicConfig
}

function getDm1ConfigAs100(parameters) {
  const basicConfig = getMappedConfigAsCs100(dm1SettingsMappingRegExpTemplates, null, parameters)
  return basicConfig
}

function getValidSignalSources(parameters) {
  const signalConfigBaseRegExpString = getRegExpStringWithReplacedId(
    signalConfigBaseRegExpStringTemplate,
    '[0-9]',
    '[0-9]'
  )
  const signalSourceRegExpString = signalConfigBaseRegExpString + 'SignalSource_NV'
  const signalSourceRegExp = new RegExp(signalSourceRegExpString)

  return parameters
    .filter(parameter => signalSourceRegExp.test(parameter.id))
    .filter(parameter => Object.values(signalSourceValidValues).includes(parameter.value))
}

export const cs500ConfigToCs100Config = cs500Config => {
  const { parameters, virtualParameters = [] } = cs500Config

  const signalSources = getValidSignalSources(parameters)

  const config = {
    EID: cs500Config.eid,
    deviceConfiguration: {
      gps: {},
      MFIO: [],
      sensorsMap: []
    }
  }

  signalSources.forEach(signalSource => {
    switch (signalSource.value) {
      case signalSourceValidValues.CAN_J1939:
        config.deviceConfiguration.sensorsMap.push(
          getCanJ1939ConfigAsCs100(signalSource, parameters, virtualParameters)
        )
        break
      case signalSourceValidValues.CAN_CUSTOMIZED:
        config.deviceConfiguration.sensorsMap.push(
          getCanCustomizedConfigAsCs100(signalSource, parameters, virtualParameters)
        )
        break
      case signalSourceValidValues.MFIO:
        config.deviceConfiguration.MFIO.push(getMfioConfigAsCs100(signalSource, parameters, virtualParameters))
        break

      default:
        break
    }
  })

  config.deviceConfiguration.gps = { ...getGnssConfigAsCs100(parameters) }
  config.deviceConfiguration.dm1 = { ...getDm1ConfigAs100(parameters) }

  return config
}
