import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurations'

import Signal from './Signal'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleSignalDelete: actions.deleteLocalConfigurationSignal,
      handleFieldChange: actions.changeLocalConfigurationSignalField,
      handleSourceAddressChange: actions.changeLocalConfigurationSignalSourceAddress,
      handleSignalIdChange: actions.changeLocalConfigurationSignalId,
      validateNewSignalId: actions.validateLocalConfigurationSignalId,
      makeSignalIdEditable: actions.makeSignalIdEditable
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Signal)
