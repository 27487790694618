import { defineMessages } from 'react-intl'

const messages = defineMessages({
  importDbc: {
    id: 'ProtocolSelector.importDbc',
    description: 'Import DBC file',
    defaultMessage: 'Import DBC file'
  },
  searchBy: {
    id: 'ProtocolSelector.searchBy',
    description: 'Search by NAME, SPN or PGN',
    defaultMessage: ' | Search by NAME, SPN or PGN'
  }
})

export default messages
