import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'

import { FREQUENCY_TYPES, TIME_UNITS } from './constants'
import messages from './messages'

export function getFormattedFrequency({ intl, frequencyType, frequencyValue = 0, frequencyUnit = '' }) {
  return `${frequencyValue} ${getFormattedFrequencyUnit({ intl, frequencyType, frequencyValue, frequencyUnit })}`
}

export function getFormattedFrequencyUnit({ intl, frequencyType, frequencyValue = 0, frequencyUnit = '' }) {
  if (frequencyType === FREQUENCY_TYPES.TIME_BASED) {
    switch (frequencyUnit) {
      case TIME_UNITS.DAYS:
        return intl.formatMessage(messages.nDays, { days: frequencyValue }).toLowerCase()
      case TIME_UNITS.WEEKS:
        return intl.formatMessage(messages.nWeeks, { weeks: frequencyValue }).toLowerCase()
      case TIME_UNITS.MONTHS:
        return intl.formatMessage(messages.nMonths, { months: frequencyValue }).toLowerCase()
      case TIME_UNITS.YEARS:
        return intl.formatMessage(messages.nYears, { years: frequencyValue }).toLowerCase()
    }
  } else {
    return frequencyUnit.toLowerCase()
  }
}

export function getFormattedInspection({ frequencyType, inspection, advancedSignalUnit }) {
  if (frequencyType === FREQUENCY_TYPES.TIME_BASED) {
    return getFormattedInspectionDate({ inspectionDate: inspection })
  } else {
    return getFormattedInspectionValue({ inspectionValue: inspection, advancedSignalUnit })
  }
}

export function getFormattedInspectionDate({ inspectionDate }) {
  return inspectionDate ? utcTimeToBrowserLocalShort(inspectionDate) : '-'
}

export function getFormattedInspectionValue({ inspectionValue, advancedSignalUnit }) {
  return inspectionValue || inspectionValue === 0
    ? `${inspectionValue} ${getFormattedInspectionUnit({ advancedSignalUnit })}`
    : '-'
}

export function getFormattedInspectionUnit({ advancedSignalUnit }) {
  return advancedSignalUnit.toLowerCase()
}
