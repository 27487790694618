import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'

import DropdownPanel from 'components/DropdownPanel'
import Panel from './SeriesVisibilityPanel'

import { injectIntl } from 'react-intl'
import messages from './messages'

const icon = <MultilineChartIcon />
const iconOpened = <MultilineChartIcon color="primary" />

const SeriesVisibilityOption = injectIntl(props => {
  const { formatMessage } = props.intl
  return (
    <DropdownPanel
      title={formatMessage(messages.showOrHideSeries)}
      icon={icon}
      iconOpened={iconOpened}
      component={IconButton}
    >
      <Panel {...props} />
    </DropdownPanel>
  )
})

export { SeriesVisibilityOption }
