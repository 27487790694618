import { FREQUENCY_TYPES, INSPECTION_STATES, NOTIFICATION_TYPES, SEVERITY_LEVELS } from './constants'

const MAPPED_FIELDS = {
  FREQUENCY_TYPE: 'FREQUENCY_TYPE',
  INSPECTION_STATE: 'INSPECTION_STATE',
  NOTIFICATION_ACTION_TYPE: 'NOTIFICATION_ACTION_TYPE',
  SEVERITY: 'SEVERITY'
}

const apiMappings = {
  [MAPPED_FIELDS.FREQUENCY_TYPE]: [
    {
      apiName: 'VALUE_BASED',
      mappedName: FREQUENCY_TYPES.VALUE_BASED
    },
    {
      apiName: 'DATE_BASED',
      mappedName: FREQUENCY_TYPES.TIME_BASED
    }
  ],
  [MAPPED_FIELDS.INSPECTION_STATE]: [
    {
      apiName: ['ENABLED', 'ACTIVE'],
      mappedName: INSPECTION_STATES.ACTIVE
    },
    {
      apiName: 'THRESHOLD_EXCEEDED_ALARM_FIRED',
      mappedName: INSPECTION_STATES.PENDING
    },
    {
      apiName: 'RESOLVED',
      mappedName: INSPECTION_STATES.RESOLVED
    }
  ],
  [MAPPED_FIELDS.SEVERITY]: [
    {
      apiName: 'high',
      mappedName: SEVERITY_LEVELS.HIGH
    },
    {
      apiName: 'medium',
      mappedName: SEVERITY_LEVELS.MEDIUM
    },
    {
      apiName: 'low',
      mappedName: SEVERITY_LEVELS.LOW
    }
  ],
  [MAPPED_FIELDS.NOTIFICATION_ACTION_TYPE]: [
    {
      apiName: 'LIVE_NOTIFICATION',
      mappedName: NOTIFICATION_TYPES.BELL
    },
    {
      apiName: 'EMAIL_NOTIFICATION',
      mappedName: NOTIFICATION_TYPES.EMAIL
    },
    {
      apiName: 'PUSH_NOTIFICATION',
      mappedName: NOTIFICATION_TYPES.PUSH
    },
    {
      apiName: 'SMS_NOTIFICATION',
      mappedName: NOTIFICATION_TYPES.SMS
    }
  ]
}

function mapFields(rawValue, mappedField, inverse = false) {
  const [originName, destinationName] = inverse ? ['mappedName', 'apiName'] : ['apiName', 'mappedName']

  const mapping =
    apiMappings[mappedField].find(
      item => Array.isArray(item[originName]) && item[originName].includes(rawValue) || item[originName] === rawValue
    ) || {}

  return Array.isArray(mapping[destinationName]) ? mapping[destinationName][0] : mapping[destinationName]
}

export function getMappedFrequencyType(rawValue, inverse = false) {
  return mapFields(rawValue, MAPPED_FIELDS.FREQUENCY_TYPE, inverse)
}

export function getMappedInspectionState(rawValue, inverse = false) {
  return mapFields(rawValue, MAPPED_FIELDS.INSPECTION_STATE, inverse)
}

export function getMappedNotificationActionType(rawValue, inverse = false) {
  return mapFields(rawValue, MAPPED_FIELDS.NOTIFICATION_ACTION_TYPE, inverse)
}

export function getMappedSeverity(rawValue, inverse = false) {
  return mapFields(rawValue, MAPPED_FIELDS.SEVERITY, inverse)
}
