import { NODE_PREFIX, CS_NODE_PREFIX } from './constants'

const toMarkerId = (id, prefix) => `${prefix}-${id}`

const parseMarker = (markerId = '') => {
  //const [prefix, postfix] = markerId.split('-')
  const prefix = markerId.substring(0, markerId.indexOf('-'))
  const postfix = markerId.substring(markerId.indexOf('-') + 1)

  return { isNode: prefix === NODE_PREFIX || prefix === CS_NODE_PREFIX, id: postfix, prefix }
}

export { toMarkerId, parseMarker }
