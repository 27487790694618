import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { logError } from 'utils/http'

import Alert from 'components/Alert'
import messages from './messages'

class ManageAccessPassConsumption extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showAlert: false
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleAllow = () => {
    const {
      updateDevices,
      onClose,
      onManageAccessPassConsumption,
      id,
      manageAccessPassConsumption,
      groupId,
      allow_consumption
    } = this.props
    this.setState(
      {
        loading: true
      },
      () => {
        const action = allow_consumption ? 'disallow': 'allow'
        manageAccessPassConsumption(id, groupId, !allow_consumption)
          .then(response => {
            updateDevices()
            onManageAccessPassConsumption(action)
            onClose()
          })
          .catch(response => {
            logError(response)
            this.setState({
              showAlert: true
            })
          })
          .finally(() => {
            this.setState({
              loading: false
            })
          })
      }
    )
  }

  handleExited = () => {
    this.setState({
      loading: false,
      showAlert: false
    })
  }

  render() {
    const { name, open, onClose, allow_consumption } = this.props
    const { showAlert, loading } = this.state
    const manageButtonDisabled = showAlert || loading
    const action = this.formatMessage(messages[allow_consumption ? 'disallow' : 'allow'])
    const actionLowerCase = this.formatMessage(messages[allow_consumption ? 'disallowLowerCase' : 'allowLowerCase'])
    return (
      <Dialog disableBackdropClick fullWidth maxWidth='md' onExited={this.handleExited} open={open}>
        <DialogTitle>
          <Grid alignItems='center' container>
            <Grid item xs={10}>
              {this.formatMessage(messages.title, { action, name })}
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {showAlert ? (
            <Alert
              alertType='danger'
              messageText={[
                this.formatMessage(messages.errorManageAccessPassConsumption, {
                  action: this.formatMessage(messages[allow_consumption ? 'allowing' : 'disallowing'])
                })
              ]}
              showAlert={showAlert}
            />
          ) : (
            <React.Fragment>
              <DialogContentText>
                {this.formatMessage(messages.confirmQuestion, { action: actionLowerCase, name })}
              </DialogContentText>
              <br />
              {!allow_consumption && <DialogContentText>{this.formatMessage(messages.rememberText)}</DialogContentText>}
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={onClose}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={manageButtonDisabled} onClick={this.handleAllow}>
            {action}
            {loading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ManageAccessPassConsumption.propTypes = {
  allow_consumption: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  manageAccessPassConsumption: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onManageAccessPassConsumption: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateDevices: PropTypes.func.isRequired
}

ManageAccessPassConsumption.defaultProps = {
  allow_consumption: true
}

export default injectIntl(ManageAccessPassConsumption)
