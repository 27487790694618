import { merge } from 'lodash'

import { getValueTypesFromLogType } from 'utils/valueTypes'

/* eslint-disable max-params */
const manageWSSubscriptions = (
  wsSubscribedData,
  data,
  eid,
  nodeCredentials,
  subscribeWidgetToWS,
  unsubscribeWidgetFromWS
) => {
  const wasWSConnected = wsSubscribedData !== ''
  const isWSConnected = data.data !== ''
  const hasChangedSignalType = typeof wsSubscribedData !== typeof data.data

  if (isWSConnected && (!wasWSConnected || hasChangedSignalType)) {
    const topic = getTopic(data.data, eid)
    subscribeWidgetToWS(topic, eid, nodeCredentials)
  }
  if (wasWSConnected && (!isWSConnected || hasChangedSignalType)) {
    const topic = getTopic(wsSubscribedData, eid)
    unsubscribeWidgetFromWS(topic, eid)
  }
}
/* eslint-enable */

const getTopic = (data, eid) => {
  const topicEnding = typeof data === 'string' ? '/geo' : '/u/ds'
  return process.env.REACT_APP_TOPIC + 'm' + eid.replaceAll(':', '') + topicEnding
}

const createZoomConfig = chartId => {
  sessionStorage.setItem(chartId, JSON.stringify({ loaded: false, xMin: '', xMax: '' }))
}

const setValue = (chartId, field, value) => {
  let zoomConfig = JSON.parse(sessionStorage.getItem(chartId))
  if (zoomConfig) zoomConfig[field] = value
  else {
    zoomConfig = { loaded: false, xMin: '', xMax: '', [field]: value }
  }
  sessionStorage.setItem(chartId, JSON.stringify(zoomConfig))
}

const getValue = (chartId, field) => {
  const zoomConfig = JSON.parse(sessionStorage.getItem(chartId))
  if (zoomConfig) return zoomConfig[field]
  else return ''
}

const deleteZoomConfig = chartId => {
  sessionStorage.removeItem(chartId)
}

const valueMeetsTheCondition = data => {
  let meetsTheCondition = false
  if (data.conditionalParams && data.conditionalParams.value) {
    const conditionValue = parseFloat(data.conditionalParams.value)
    const value = data.value.value
    switch (data.conditionalParams.operator) {
      case '=':
        meetsTheCondition = value === conditionValue
        break
      case '>':
        meetsTheCondition = value > conditionValue
        break
      case '>=':
        meetsTheCondition = value >= conditionValue
        break
      case '<':
        meetsTheCondition = value < conditionValue
        break
      case '<=':
        meetsTheCondition = value <= conditionValue
        break
      case '!=':
        meetsTheCondition = value !== conditionValue
        break
      default:
        break
    }
  }
  return meetsTheCondition
}

const getValueTypes = (dinamicData, staticData, signalId) => {
  let valueTypes = []
  if (Array.isArray(dinamicData) && Array.isArray(staticData)) {
    // eslint-disable-next-line no-shadow
    const data = staticData.find(data => data.name === 'deviceType')
    // eslint-disable-next-line no-shadow
    const signal = dinamicData.find(signal => signal.signalId === signalId)
    if (data?.value && signal) {
      switch (data.value) {
        case 'CS100':
          if (signal.isMFIO && typeof signal.logType === 'number') {
            valueTypes = getValueTypesFromLogType(signal.logType)
          }
          break
        case 'CS500':
          if (signal.filterType === 6) valueTypes = getValueTypesFromLogType(15)
          break
        default:
      }
    }
  }
  return valueTypes
}

const generatePartialStateFromProps = props => {
  let dinamicData = ''
  let { valueType } = props.data
  const { data } = props.data

  const dinamicDataSignalIds = props.dinamicData.map(signal => signal.signalId)
  const isValidData = dinamicDataSignalIds.includes(data)
  if (isValidData) dinamicData = data

  const valueTypes = getValueTypes(props.dinamicData, props.staticData, dinamicData)
  if (!valueTypes.includes(valueType)) valueType = valueTypes[0] || ''

  return {
    dinamicData,
    wsSubscribedData: dinamicData,
    valueTypes,
    valueType
  }
}

const mapToConditionalProperties = conditionalParams =>
  Object.entries(conditionalParams).reduce((acc, current) => {
    let key = current[0]
    if (key !== 'operator') {
      const charSplittedKey = key.split('')
      key = 'conditional' + charSplittedKey[0].toUpperCase() + charSplittedKey.slice(1).join('')
    }
    return { ...acc, [key]: current[1] }
  }, {})

const getDecimals = (dynamicData, signalId) => {
  let decimals = null
  if (Array.isArray(dynamicData) && typeof signalId === 'number') {
    const signal = dynamicData.find(dynamicSignal => dynamicSignal.signalId === signalId)
    if (typeof signal.decimals === 'number') decimals = signal.decimals
  }
  return decimals
}

const withDefaultValues = (widgetData, templateParams) => merge({}, templateParams, widgetData)

export {
  manageWSSubscriptions,
  getTopic,
  createZoomConfig,
  setValue,
  getValue,
  deleteZoomConfig,
  valueMeetsTheCondition,
  getValueTypes,
  generatePartialStateFromProps,
  mapToConditionalProperties,
  getDecimals,
  withDefaultValues
}
