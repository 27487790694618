import { defineMessages } from 'react-intl'

const messages = defineMessages({
  deleteDashboard: {
    id: 'DeleteDashboard.deleteDashboard',
    description: 'Delete dashboard',
    defaultMessage: 'DELETE DASHBOARD'
  },
  name: {
    id: 'DeleteDashboard.name',
    description: 'Dashboard name',
    defaultMessage: 'Name'
  },
  description: {
    id: 'DeleteDashboard.description',
    description: 'Dashboard description',
    defaultMessage: 'Description'
  },
  cancel: {
    id: 'DeleteDashboard.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'DeleteDashboard.delete',
    description: 'Delete',
    defaultMessage: 'Delete'
  }
})

export default messages
