import * as plus1Url from '../plus1ConnectUrl'
import * as t from './actionTypes'
import { createRequestAction } from './utils'

export function updateUserConsent(userId, consent, ipAddress) {
  return createRequestAction(
    t.USER_UPDATE_CONSENT,
    plus1Url.user(userId),
    'put',
    { consent, ipAddress },
    'plus1Connect'
  )
}
