import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, numberFilter } from 'react-bootstrap-table2-filter'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { isObject, get, omit } from 'lodash'

import { saveAs } from 'file-saver'

import messages from './messages'
import { client } from 'utils/http'
import { utcTimeToBrowserLocalNoSeconds } from 'utils/timeFormat'
import { compareValues } from '../../utils'

class LastKnownValueTable extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const { visualSettings } = props.data

    this.state = {
      allData: [],
      noDataIndication: this.formatMessage(messages.noLastKnownValuesAvailable),
      paginationOptions: {
        page: 1,
        sizePerPage: visualSettings.defaultRowNumber,
        totalSize: 0
      },
      sort: {},
      filters: {}
    }
  }

  componentDidMount() {
    const { eids, editing } = this.props
    if (!editing && eids.length > 0) this.getData()
  }

  getData = () => {
    const {
      eids,
      data: {
        settings: { columns, isGroupWidget, configurationId },
        visualSettings: { defaultRowNumber }
      },
      groupId,
      getDynamicCSNodes
    } = this.props
    this.setState(
      {
        allData: [],
        noDataIndication: <CircularProgress />
      },
      async () => {
        const deviceFields = {
          Device: ['id', 'eid', 'name'],
          Configuration: ['id']
        }
        const filters = { filters: { models: [{ modelName: 'Device', columns: [{ device_type: 'CS100' }] }] } }
        try {
          const dynamicCSNodesResponse = await getDynamicCSNodes(groupId, deviceFields, filters)
          const { devices = [] } = dynamicCSNodesResponse.data
          const devicesWithSelectedConfig = devices.filter(device => device.Configuration?.id === configurationId)
          let selectedDevices = devicesWithSelectedConfig
          if (!isGroupWidget) {
            selectedDevices = selectedDevices.filter(device => eids.includes(device.eid))
          }

          let data = []
          if (selectedDevices.length > 0) {
            const formattedEids = selectedDevices.map(device => 'm' + device.eid.replaceAll(':', '')).join(',')
            const formattedMFIOCANBusSignals = columns
              .filter(column => Number.isInteger(column.id))
              .map(column => 'p-' + column.id)
            if (formattedMFIOCANBusSignals.length > 0) {
              const lastKnownSignalsResponse = await client.getLastKnownSignals(
                groupId,
                formattedEids,
                formattedMFIOCANBusSignals
              )
              const { content = [] } = lastKnownSignalsResponse.data
              data = content.map(deviceContent => {
                const { eid, name } = selectedDevices.find(device => {
                  const formattedEid = 'm' + device.eid.replaceAll(':', '')
                  return formattedEid === deviceContent.device
                })
                const filteredSignals = deviceContent.signals.filter(signal => signal.signal.startsWith('p-'))
                return filteredSignals.reduce(
                  (acc, cur) => {
                    const { unit, multiplier = 1, offset = 0 } = columns.find(column => 'p-' + column.id === cur.signal)
                    const value = parseFloat(cur.value) * multiplier + offset
                    return { ...acc, [cur.signal]: { value, timestamp: cur.timestamp, unit } }
                  },
                  { eid, name }
                )
              })
            }
          }
          this.setState(state => ({
            noDataIndication: data.length === 0 ? this.formatMessage(messages.noLastKnownValuesAvailable) : '',
            allData: data,
            paginationOptions: {
              ...state.paginationOptions,
              totalSize: data.length
            }
          }))
        } catch (error) {
          this.setState({
            noDataIndication: this.formatMessage(messages.lastKnownValuesError),
            allData: [],
            paginationOptions: {
              page: 1,
              sizePerPage: defaultRowNumber,
              totalSize: 0
            }
          })
        }
      }
    )
  }

  getFilteredData = data => {
    const { filters } = this.state
    let filteredData = [...data]
    Object.entries(filters).forEach(([field, filter]) => {
      filteredData = filteredData.filter(row => {
        let fieldValue = get(row, field)
        if (fieldValue && typeof fieldValue === 'object') fieldValue = get(fieldValue, 'value')
        if (typeof fieldValue === 'undefined') return false
        else {
          if (filter.filterType === 'TEXT') return fieldValue.includes(filter.filterVal)
          else if (filter.filterType === 'NUMBER') {
            const number = parseInt(filter.filterVal.number)
            const comparator = filter.filterVal.comparator
            return compareValues(fieldValue, number, comparator)
          } else return false
        }
      })
    })
    return filteredData
  }

  getOrderedData = data => {
    const {
      sort: { order, field }
    } = this.state
    const sortedData = [...data]
    if (order && field) {
      sortedData.sort((a, b) => {
        let fieldA = get(a, field)
        if (fieldA && typeof fieldA === 'object') fieldA = get(fieldA, 'value')
        let fieldB = get(b, field)
        if (fieldB && typeof fieldB === 'object') fieldB = get(fieldB, 'value')
        if (typeof fieldA === 'string' && typeof fieldB === 'string') {
          return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA)
        } else if (typeof fieldA === 'number' && typeof fieldB === 'number') {
          return order === 'asc' ? fieldA - fieldB : fieldB - fieldA
        } else {
          if (order === 'asc' && typeof fieldA === 'undefined') return 1
          if (order === 'asc' && typeof fieldB === 'undefined') return 1
          if (order === 'desc' && typeof fieldA === 'undefined') return -1
          if (order === 'desc' && typeof fieldB === 'undefined') return -1
          return 0
        }
      })
    }
    return sortedData
  }

  getFilteredAndSortedData = () => {
    const { allData } = this.state
    const filteredData = this.getFilteredData(allData)
    const filteredAndSortedData = this.getOrderedData(filteredData)
    return filteredAndSortedData
  }

  getTableWidth = () => {
    const { width } = this.props
    const scrollDiv = document.createElement('div')
    scrollDiv.style = 'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;'
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    const tableWidth = width - scrollbarWidth
    return tableWidth
  }

  getTableHeight = () => {
    const { height } = this.props

    const theadElement = document.getElementsByClassName('last-known-value-table-header-class').item(0)
    const tableHeaderHeight = theadElement ? theadElement.offsetHeight : 0
    const exportButtonHeight = 38
    const sizePerPageButtonHeight = 38
    const tableBottomMargin = 20
    const tableHeight =
      height - tableHeaderHeight - exportButtonHeight - sizePerPageButtonHeight - tableBottomMargin - 5
    return tableHeight
  }

  getPaginationOptions = () => {
    const { paginationOptions } = this.state
    return {
      custom: true,
      paginationSize: 1,
      showTotal: true,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],
      alwaysShowAllBtns: false,
      firstPageText: this.formatMessage(messages.firstPage),
      prePageText: this.formatMessage(messages.prePage),
      nextPageText: this.formatMessage(messages.nextPage),
      lastPageText: this.formatMessage(messages.lastPage),
      hideSizePerPage: false,
      withFirstAndLast: false,
      onPageChange: this.handlePageChange,
      onSizePerPageChange: this.handleSizePerPageChange,
      ...paginationOptions
    }
  }

  getTableColumns = () => {
    const {
      data: {
        settings: { columns }
      }
    } = this.props

    const formattedColumns = columns.map(column => {
      let { id } = column
      if (Number.isInteger(id)) id = 'p-' + id
      return { ...column, id }
    })
    return [{ name: 'Key field', id: 'keyField' }, ...formattedColumns]
  }

  getTableData = () => {
    const {
      paginationOptions: { page, sizePerPage }
    } = this.state
    const start = (page - 1) * sizePerPage
    const end = start + sizePerPage
    const filteredAndSortedData = this.getFilteredAndSortedData()
    const data = filteredAndSortedData.slice(start, end)
    const dataWithKeyField = data.map((item, index) => ({ ...item, keyField: index }))
    return dataWithKeyField
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(({ paginationOptions }) => ({
      paginationOptions: {
        ...paginationOptions,
        page,
        sizePerPage
      }
    }))
  }

  handleSizePerPageChange = sizePerPage => {
    this.setState(({ paginationOptions }) => ({
      paginationOptions: {
        ...paginationOptions,
        sizePerPage
      }
    }))
  }

  handleTableChange = (type, { sortOrder, sortField, filters }) => {
    switch (type) {
      case 'sort':
        this.setState({
          sort: {
            order: sortOrder,
            field: sortField
          }
        })
        break
      case 'filter':
        const invalidFilterKeys = []
        Object.entries(filters).forEach(([key, { filterType, filterVal }]) => {
          if (filterType === 'TEXT' && filterVal === '') invalidFilterKeys.push(key)
          if (filterType === 'NUMBER' && (filterVal.comparator === '' || filterVal.number === '')) {
            invalidFilterKeys.push(key)
          }
        })
        const updatedFilters = omit(filters, invalidFilterKeys)
        this.setState({ filters: updatedFilters })
        break
    }
  }

  generateCSVFormattedData = () => {
    const {
      data: {
        settings: { columns }
      }
    } = this.props
    const { allData } = this.state

    const csvHeaders = columns
      .map(column => {
        let signalHeaderValue = column.name
        let signalHeaderDate = ''
        if (column.signalType) {
          signalHeaderValue = `${signalHeaderValue}  (${column.unit || '-'})`
          signalHeaderDate = `,${column.name} (date)`
        }
        return signalHeaderValue + signalHeaderDate
      })
      .join(',')

    const csvData = allData.reduce((acc, deviceData) => {
      const csvRowValues = columns.map(({ id, signalType }) => {
        if (typeof signalType === 'undefined') return deviceData[id]
        else {
          const { value = '-', timestamp = '-' } = deviceData[`p-${id}`] || {}
          return `${value},${timestamp}`
        }
      })
      return `${acc}\n${csvRowValues.join(',')}`
    }, csvHeaders)
    return csvData
  }

  handleExportClick = () => {
    const csvData = this.generateCSVFormattedData()
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    saveAs(blob, 'file.csv')
  }

  renderTableColumn = cell => {
    let columnValue = cell
    if (isObject(cell)) {
      columnValue = (
        <>
          <span style={{ fontWeight: 'bold' }}>{cell.value + cell.unit}</span>
          <br />
          <span style={{ fontSize: 12 }}>{utcTimeToBrowserLocalNoSeconds(cell.timestamp)}</span>
        </>
      )
    }
    return columnValue
  }

  render() {
    const {
      data: {
        settings: { columns },
        visualSettings: { condensed }
      },
      editing
    } = this.props
    const { noDataIndication } = this.state
    const tableData = this.getTableData()
    const tableColumns = this.getTableColumns()
    const tableHeight = this.getTableHeight()
    const tableWidth = this.getTableWidth()
    const columnWidth = columns.length ? tableWidth / columns.length : tableWidth

    return (
      <>
        <Grid alignItems='center' container justify='flex-end'>
          <Button
            className='primary-action-button'
            disabled={tableData.length === 0}
            onClick={() => this.handleExportClick()}
            startIcon={<Icon className='zmdi zmdi-swap-vertical' style={{ marginLeft: 5 }} />}
          >
            {this.formatMessage(messages.exportToCsv)}
          </Button>
          <Button
            className='primary-action-button'
            disabled={editing}
            onClick={() => this.getData()}
            startIcon={<Icon className='zmdi zmdi-refresh' style={{ marginLeft: 5 }} />}
            style={{ marginLeft: 20 }}
          >
            {this.formatMessage(messages.refresh)}
          </Button>
        </Grid>
        <PaginationProvider pagination={paginationFactory(this.getPaginationOptions())}>
          {({ paginationProps, paginationTableProps }) => (
            <div
              style={{
                '--table-height': tableHeight + 'px',
                '--table-width': tableWidth + 'px'
              }}
            >
              <BootstrapTable
                bodyClasses={tableData.length > 0 ? 'table-widget-body' : ''}
                bordered={false}
                columns={tableColumns.map(column => ({
                  dataField: column.id,
                  filter: ['mfio', 'canBus'].includes(column.signalType) ? numberFilter() : textFilter(),
                  formatter: this.renderTableColumn,
                  headerStyle: () => ({
                    width: columnWidth,
                    ...tableData.length > 0 && { display: 'inline-block' }
                  }),
                  hidden: column.id === 'keyField',
                  sort: true,
                  style: () => ({ width: columnWidth }),
                  text: column.name
                }))}
                condensed={condensed}
                data={tableData}
                filter={filterFactory()}
                headerWrapperClasses='last-known-value-table-header-class'
                keyField='keyField'
                noDataIndication={noDataIndication}
                onTableChange={this.handleTableChange}
                remote={{ pagination: true, sort: true, filter: true }}
                {...paginationTableProps}
              />
              <SizePerPageDropdownStandalone {...paginationProps} variation='dropup' />
              <PaginationListStandalone {...paginationProps} />
            </div>
          )}
        </PaginationProvider>
      </>
    )
  }
}

LastKnownValueTable.propTypes = {
  data: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  eids: PropTypes.array.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
}

export default injectIntl(LastKnownValueTable)
