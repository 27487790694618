import React from 'react'
import PropTypes from 'prop-types'
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'
import _isEqual from 'lodash/isEqual'
import { MAP, DRAWING_MANAGER, CIRCLE, POLYGON } from 'react-google-maps/lib/constants'
import { Polygon, Circle } from 'react-google-maps'

class DrawingGeofence extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      drawingControl: true,
      drawingMode: null
    }
  }

  componentDidMount() {
    if (this.mapRef && this.mapRef.context[MAP]) {
      const controls = this.mapRef.context[MAP].controls
      this.deleteGeofenceControl(controls)

      window.google.maps.event.addListener(
        this.mapRef.state[DRAWING_MANAGER],
        'overlaycomplete',
        this.handleOverlayComplete
      )
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_isEqual(this.props, nextProps) && _isEqual(this.state, nextState)) {
      return false
    } else {
      return true
    }
  }

  hideDrawingControls = () => {
    this.setState({
      drawingControl: false,
      drawingMode: null
    })
  }

  showDrawingControls = () => {
    const { geofence } = this.props

    if (geofence) {
      this.setState({
        drawingControl: true
      })
    }
  }

  deleteGeofenceControl = controls => {
    const { deleteGeofenceOverlay } = this.props

    const deleteControlDiv = document.createElement('div')
    const controlUI = document.createElement('div')
    controlUI.style.backgroundColor = '#fff'
    controlUI.style.border = '2px solid #fff'
    controlUI.style.borderRadius = '3px'
    controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
    controlUI.style.cursor = 'pointer'
    controlUI.style.height = '24px'
    controlUI.style.marginTop = '5px'
    controlUI.style.marginLeft = '-5px'
    controlUI.style.paddingTop = '1px'
    controlUI.style.textAlign = 'left'
    controlUI.position = 'relative'
    controlUI.title = 'Click to delete geofence'
    deleteControlDiv.appendChild(controlUI)

    const icon = document.createElement('i')
    icon.className = 'zmdi zmdi-delete'
    icon.style.color = 'rgb(104,104,104)'
    icon.style.fontSize = '15px'
    icon.style.lineHeight = '20px'
    icon.style.paddingLeft = '5px'
    icon.style.paddingRight = '5px'
    controlUI.appendChild(icon)

    controlUI.addEventListener('click', () => {
      this.showDrawingControls()
      deleteGeofenceOverlay()
    })

    controls[window.google.maps.ControlPosition.TOP_CENTER].push(deleteControlDiv)
  }

  handleOverlayComplete = event => {
    const { saveOverlay } = this.props

    const CIRCLE_SHAPE = 'circle'
    const POLYGON_SHAPE = 'polygon'

    switch (event.type) {
      case CIRCLE_SHAPE:
        this.hideDrawingControls()
        event.overlay.setMap(null)
        saveOverlay(event.overlay, CIRCLE_SHAPE)
        break
      case POLYGON_SHAPE:
        this.hideDrawingControls()
        event.overlay.setMap(null)
        saveOverlay(event.overlay, POLYGON_SHAPE)
        break

      default:
    }
  }

  render() {
    const { geofence, geofenceColor, handleOverlayChange } = this.props
    const { drawingControl, drawingMode } = this.state

    const controlPosition = window.google.maps.ControlPosition.TOP_CENTER
    const CIRCLE_SHAPE = 'circle'
    const POLYGON_SHAPE = 'polygon'
    const CENTER = 'center'
    const RADIUS = 'radius'

    return (
      <div>
        <DrawingManager
          ref={self => {
            this.mapRef = self
          }}
          options={{
            drawingControl:
              geofence.center && geofence.radius || geofence.polygonPath && geofence.polygonPath.length > 0
                ? false
                : drawingControl,
            drawingMode,
            drawingControlOptions: {
              drawingModes: [CIRCLE_SHAPE, POLYGON_SHAPE],
              position: controlPosition
            },
            polygonOptions: {
              fillColor: geofenceColor,
              fillOpacity: 0.4,
              strokeColor: geofenceColor,
              strokeOpacity: 1,
              strokeWeight: 1.5,
              editable: true,
              draggable: true
            },
            circleOptions: {
              fillColor: geofenceColor,
              fillOpacity: 0.4,
              strokeColor: geofenceColor,
              strokeOpacity: 1,
              strokeWeight: 1.5,
              editable: true,
              draggable: true
            }
          }}
        />
        {geofence && geofence.shapeType === POLYGON_SHAPE ? (
          <Polygon
            ref={self => {
              this.polygon = self
            }}
            onDragEnd={() => {
              handleOverlayChange(POLYGON_SHAPE, null, this.polygon.state[POLYGON].getPath().getArray())
            }}
            onMouseUp={() => {
              handleOverlayChange(POLYGON_SHAPE, null, this.polygon.state[POLYGON].getPath().getArray())
            }}
            options={geofence.options}
            path={geofence.polygonPath}
          />
        ) : null}
        {geofence && geofence.shapeType === CIRCLE_SHAPE ? (
          <Circle
            ref={self => {
              this.circle = self
            }}
            center={geofence.center}
            onCenterChanged={() => {
              handleOverlayChange(CIRCLE_SHAPE, CENTER, this.circle.state[CIRCLE].center)
            }}
            onRadiusChanged={() => {
              handleOverlayChange(CIRCLE_SHAPE, RADIUS, this.circle.state[CIRCLE].radius)
            }}
            options={geofence.options}
            radius={geofence.radius}
          />
        ) : null}
      </div>
    )
  }
}

DrawingGeofence.propTypes = {
  deleteGeofenceOverlay: PropTypes.func.isRequired,
  geofence: PropTypes.object.isRequired,
  geofenceColor: PropTypes.string.isRequired,
  handleOverlayChange: PropTypes.func.isRequired,
  saveOverlay: PropTypes.func.isRequired
}

export { DrawingGeofence }
