import * as R from 'ramda'
import moment from 'moment-timezone'

const wrapWithZero = val => {
  return val < 10 ? `0${val}` : val
}

const convertOffsetToHours = offset => {
  const sign = Math.sign(offset) === -1 ? '-' : '+'
  const offsetAbs = Math.abs(offset)
  const hours = wrapWithZero(Math.floor(offsetAbs / 60))
  const minutes = wrapWithZero(offsetAbs % 60)
  return `${sign}${hours}:${minutes}`
}

const wrapWithOffset = name => {
  const pureName = name.slice(name.indexOf('/') + 1)
  const offset = moment.tz(name)._offset
  const offsetString = convertOffsetToHours(offset)
  return `(UTC${offsetString}) ${pureName}`
}

const usZonesNames = R.pipe(
  R.path(['tz', '_names']),
  R.values,
  R.filter(R.anyPass([R.startsWith('UTC')])),
  R.map(name => ({
    value: name,
    label: wrapWithOffset(name),
  })),
  R.sortBy(R.prop('label')),
)(moment)

export default usZonesNames
