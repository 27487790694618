import { defineMessages } from 'react-intl'

const messages = defineMessages({
  mfioSettings: {
    id: 'MFIOCS500.mfioSettings',
    description: 'MFIO settings paper title',
    defaultMessage: 'MFIO settings'
  },
  mfio0: {
    id: 'MFIOCS500.mfio1',
    description: 'MFIO 1 title text',
    defaultMessage: 'MFIO 1'
  },
  mfio1: {
    id: 'MFIOCS500.mfio2',
    description: 'MFIO 2 title text',
    defaultMessage: 'MFIO 2'
  },
  mfio2: {
    id: 'MFIOCS500.mfio3',
    description: 'MFIO 3 title text',
    defaultMessage: 'MFIO 3'
  },
  mfio3: {
    id: 'MFIOCS500.mfio4',
    description: 'MFIO 4 title text',
    defaultMessage: 'MFIO 4'
  },
  inputSignal: {
    id: 'MFIOCS500.inputSignal',
    description: 'Pin mode',
    defaultMessage: 'Pin mode'
  },
  inputRange: {
    id: 'MFIOCS500.inputRange',
    description: 'Voltage Range',
    defaultMessage: 'Voltage Range'
  },
  bias: {
    id: 'MFIOCS500.bias',
    description: 'Input Circuit',
    defaultMessage: 'Input Circuit'
  },
  digThreshHigh: {
    id: 'MFIOCS500.digThreshHigh',
    description: 'Digital threshold for high value',
    defaultMessage: 'Digital threshold for high value (mV)'
  },
  digThreshLow: {
    id: 'MFIOCS500.digThreshLow',
    description: 'Digital threshold for low value',
    defaultMessage: 'Digital threshold for low value (mV)'
  }
})

export default messages
