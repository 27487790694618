import { getMappedConsentState } from 'components/SmsServiceConsentDialog/apiMappings'
import { getSmsServiceState } from 'components/SmsServiceStateChip/helpers'

export function mapToTableUsers(detailedUsers) {
  return detailedUsers.map(detailedUser => {
    const { userId, email, firstName, lastName } = detailedUser
    let roleDescription = '-'
    let roleId
    if (detailedUser && detailedUser.userProperties && detailedUser.userProperties.GroupRoles.length > 0) {
      roleDescription = detailedUser.userProperties.GroupRoles[0].role_name
      roleId = detailedUser.userProperties.GroupRoles[0].id
    }

    const consentState = getMappedConsentState(detailedUser?.consent)
    const isPhoneNumberPresent = detailedUser?.isPhoneNumberPresent

    return {
      id: userId,
      firstName,
      lastName,
      email,
      enabled: true,
      roleDescription,
      roleId,
      smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
    }
  })
}
