import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

const EditReportFormTitle = ({ title, reportsUrl }) => {
  return <PageTitle title={title} back={reportsUrl} />
}

EditReportFormTitle.propTypes = {
  reportsUrl: PropTypes.string,
  title: PropTypes.string
}

EditReportFormTitle.defaultProps = {
  reportsUrl: '',
  title: '...'
}

export default EditReportFormTitle
