import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'


import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import Alert from 'components/Alert'
import { client, logError } from 'utils/http'

import messages from './messages'

class SetNotificationsByType extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      assignedUsers: props.assignedUsers,
      action: props.action,
      ruleInstanceHashId: props.ruleInstanceHashId,
      redrawAssignedUsersTable: props.redrawAssignedUsersTable,

      isLoading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    const { action, assignedUsers, ruleInstanceHashId } = this.state

    if (
      action.name === 'ASSIGN_NOTIFICATIONS_BY_BELL' ||
      action.name === 'ASSIGN_NOTIFICATIONS_BY_EMAIL' ||
      action.name === 'ASSIGN_NOTIFICATIONS_BY_PUSH' ||
      action.name === 'ASSIGN_NOTIFICATIONS_BY_SMS'
    ) {
      this.assignNotificationAction(action, assignedUsers, ruleInstanceHashId)
    } else if (
      action.name === 'UNASSIGN_NOTIFICATIONS_BY_BELL' ||
      action.name === 'UNASSIGN_NOTIFICATIONS_BY_EMAIL' ||
      action.name === 'UNASSIGN_NOTIFICATIONS_BY_PUSH' ||
      action.name === 'UNASSIGN_NOTIFICATIONS_BY_SMS'
    ) {
      this.unassignNotificationAction(action, assignedUsers, ruleInstanceHashId)
    }
  }

  componentDidUpdate(prevProps) {
    const { action, assignedUsers, ruleInstanceHashId } = this.props

    if (prevProps.assignedUsers !== assignedUsers) {
      this.setState({
        assignedUsers
      })
    }

    if (prevProps.action !== action) {
      this.setState({
        action
      })
    }

    if (prevProps.ruleInstanceHashId !== ruleInstanceHashId) {
      this.setState({
        ruleInstanceHashId
      })
    }
  }

  assignNotificationAction = (action, assignedUsers, ruleInstanceHashId) => {
    const { redrawAssignedUsersTable } = this.state

    if (action !== undefined) {
      const actionHashId = action.notificationActionHashId

      const usersToSetAction = assignedUsers
      const actionsToSetAction = []
      const actionObject = {
        hashId: actionHashId
      }
      actionsToSetAction.push(actionObject)

      const notificationActionsForAssignedUserObject = {
        users: usersToSetAction,
        actions: actionsToSetAction
      }

      client
        .assignNotificationsActionsToRuleInstanceAssignedUsers(
          ruleInstanceHashId,
          notificationActionsForAssignedUserObject
        )
        .then(response => {
          this.setState({
            isLoading: false,
            alertMessages: true,
            alertMessagesType: 'success',
            alertMessagesTitle: '',
            alertMessagesText: [this.formatMessage(messages.successMessage)]
          })
          redrawAssignedUsersTable()
        })
        .catch(response => {
          const error = { ...response }
          if (error.response !== undefined && error.response.status !== undefined) {
            switch (error.response.status) {
              case 400: // Bad request
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                  alertMessagesText: [this.formatMessage(messages.error400Message)]
                })
                break
              case 401: // Invalid credentials
                let error401ToShow = ''
                if (error.response.message !== undefined) {
                  error401ToShow = error.response.message
                }
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                  alertMessagesText: [error401ToShow]
                })
                break
              case 403: // Access denied
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                  alertMessagesText: [this.formatMessage(messages.error403Message)]
                })
                break
              case 404: // API url not found
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
                  alertMessagesText: [this.formatMessage(messages.error404Message)]
                })
                break
              case 406: // Not acceptable
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                  alertMessagesText: [this.formatMessage(messages.error406Message)]
                })
                break
              case 409: // Data integrity violation
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '409' }),
                  alertMessagesText: [this.formatMessage(messages.error409Message)]
                })
                break
              case 422: // Validation failed
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
                  alertMessagesText: [this.formatMessage(messages.error422Message)]
                })
                break
              case 500: // Server errors
                let error500ToShow = this.formatMessage(messages.error500ToShow)
                if (
                  error.response.message !== undefined &&
                  error.response.message.data !== undefined &&
                  error.response.message.data.error_description !== undefined
                ) {
                  error500ToShow = error.response.data.error_description
                }
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                  alertMessagesText: [error500ToShow]
                })
                break
              default:
                this.setState({
                  isLoading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                  alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                })
                logError(response)
            }
          } else {
            this.setState({
              isLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
          }
        })
    }
  }

  unassignNotificationAction = (action, assignedUsers, ruleInstanceHashId) => {
    const { redrawAssignedUsersTable } = this.state

    if (action !== undefined) {
      const actionHashId = action.notificationActionHashId

      const usersToSetAction = assignedUsers
      const actionsToSetAction = []
      const actionObject = {
        hashId: actionHashId
      }
      actionsToSetAction.push(actionObject)

      const notificationActionsForAssignedUserObject = {
        users: usersToSetAction,
        actions: actionsToSetAction
      }

      client
        .unassignNotificationsActionsToRuleInstanceAssignedUsers(
          ruleInstanceHashId,
          notificationActionsForAssignedUserObject
        )
        .then(response => {
          this.setState({
            isLoading: false,
            alertMessages: true,
            alertMessagesType: 'success',
            alertMessagesTitle: '',
            alertMessagesText: [this.formatMessage(messages.successMessage)]
          })
          redrawAssignedUsersTable()
        })
        .catch(response => {
          const error = { ...response }
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessagesText: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401: // Invalid credentials
              let error401ToShow = ''
              if (error.response.message !== undefined) {
                error401ToShow = error.response.message
              }
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessagesText: [error401ToShow]
              })
              break
            case 403: // Access denied
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessagesText: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404: // API url not found
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: 'Error 404',
                alertMessagesText: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessagesText: [this.formatMessage(messages.error406Message)]
              })
              break
            case 409: // Data integrity violation
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '409' }),
                alertMessagesText: [this.formatMessage(messages.error409Message)]
              })
              break
            case 422: // Validation failed
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
                alertMessagesText: [this.formatMessage(messages.error422Message)]
              })
              break
            case 500: // Server errors
              let error500ToShow = this.formatMessage(messages.error500ToShow)
              if (
                error.response.message.data !== undefined &&
                error.response.message.data.error_description !== undefined
              ) {
                error500ToShow = error.response.data.error_description
              }
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error500ToShow]
              })
              break
            default:
              this.setState({
                isLoading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(response)
          }
        })
    }
  }

  render() {
    const { action, alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, isLoading } = this.state
    return (
      <div>
        <Typography style={{ margin: 0 }} variant='h6'>
          {action.description}
        </Typography>

        {isLoading && <CircularProgress />}
        {alertMessages && (
          <Alert
            alertType={alertMessagesType}
            closeFunction={undefined}
            messageText={alertMessagesText}
            messageTitle={alertMessagesTitle}
            showAlert={alertMessages}
          />
        )}
      </div>
    )
  }
}

SetNotificationsByType.propTypes = {
  action: PropTypes.object,
  assignedUsers: PropTypes.array,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  redrawAssignedUsersTable: PropTypes.func,
  ruleInstanceHashId: PropTypes.string
}

SetNotificationsByType.defaultProps = {
  action: {},
  assignedUsers: [],
  redrawAssignedUsersTable: i => i,
  ruleInstanceHashId: ''
}

export default injectIntl(SetNotificationsByType)
