import moment from 'moment'

const HistoricConfig = (min, max, setFormattedHistoricData) => {
  return {
    chart: {
      highstock: true,
      zoomType: 'x',
      animation: false,
      events: {
        redraw(e) {
          const { maxInput, minInput } = e.target.rangeSelector
          minInput.onclick = () => {
            minInput.showPicker()
          }
          minInput.onfocus = () => {
            minInput.min = '1970-01-01'
            minInput.showPicker()
          }
          minInput.onchange = event => {
            const inputMin = moment(event.target.value).valueOf()
            setFormattedHistoricData(inputMin, max, true)
          }
          maxInput.onclick = () => {
            maxInput.showPicker()
          }
          maxInput.onfocus = () => {
            maxInput.showPicker()
          }
          maxInput.onchange = event => {
            const inputMax = moment(event.target.value).valueOf()
            setFormattedHistoricData(min, inputMax, true)
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ]
        }
      },
      chartOptions: {
        rangeSelector: {
          buttons: []
        }
      }
    },
    legend: {
      enabled: true
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: true,
      height: 60,
      series: {
        fillOpacity: 0
      },
      xAxis: {
        type: 'datetime',
        ordinal: false
      }
    },
    plotOptions: {
      series: {
        showInNavigator: true
      }
    },
    rangeSelector: {
      height: 50
    },
    series: [],
    tooltip: {
      shared: true,
      valueDecimals: 0
    },
    xAxis: {
      type: 'datetime',
      ordinal: false,
      min,
      max
    }
  }
}

export default HistoricConfig
