import * as c from './constants'
import * as at from './actionTypes'

const initialState = {
  isOpen: false,
  deviceEid: '',
  deviceType: '',
  deviceName: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case at.OPEN:
      const { deviceEid, deviceType, deviceName } = action
      return {
        isOpen: true,
        deviceEid,
        deviceType,
        deviceName
      }
    case at.CLOSE:
      return {
        isOpen: false,
        deviceEid: '',
        deviceType: '',
        deviceName: ''
      }
    default:
      return state
  }
}

export default reducer

const getState = state => state[c.NAME]
const getIsOpen = state => getState(state).isOpen
const getDialogDeviceEid = state => getState(state).deviceEid
const getDialogDeviceType = state => getState(state).deviceType
const getDialogDeviceName = state => getState(state).deviceName

export { getIsOpen, getDialogDeviceEid, getDialogDeviceType, getDialogDeviceName }
