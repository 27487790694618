import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'

import AssignedDevicesCurrentValuesModal from './AssignedDevicesCurrentValuesModal'

const mapStateToProps = state => {
  return {
    groupId: groupUtils.getSelectedGroup(state).id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCSNodesDetails: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedDevicesCurrentValuesModal)
