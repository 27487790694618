import ActivateDataPlanDialog from './ActivateDataPlanDialog'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { actions } from 'ducks/dataPlans'

const mapStateToProps = (state, { match, history }) => ({
  onActivateDSP: () => {
    history.replace(match.path, 'dspActivated')
  }
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activateDataPlan: actions.activateDataPlan
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivateDataPlanDialog))
