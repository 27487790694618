const HistoricTemplate = {
  id: '',
  width: 300,
  height: 300,
  x: 20,
  y: 20,
  minHeight: 100,
  minWidth: 100,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'historic',
    params: {
      data: '',
      settings: {}
    }
  }
}

export default HistoricTemplate
