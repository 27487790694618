import { connect } from 'react-redux'
import { utils } from 'ducks/theme'

import { Favicon } from './Favicon'

const mapStateToProps = state => ({
  href: utils.getFavicon(state),
  title: utils.getPageTitle(state)
})

export default connect(mapStateToProps)(Favicon)
