import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'lodash/isEqual'
import { Polygon, Circle } from 'react-google-maps'

import { gmapsPatch } from 'components/Map'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const { Map, Marker } = gmapsPatch

class MapView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultCenter: { latitude: 43.295645, longitude: -1.980012 },
      defaultZoom: 3,
      mapTypeConstrolPosition: undefined,
      zoom: 3,
      center: { latitude: 43.295645, longitude: -1.980012 }
    }
  }

  componentDidMount() {
    const { fitToBounds, markers } = this.props
    if (markers.length > 0) {
      const MARKERS = 'markers'
      fitToBounds(MARKERS, markers)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fitToBounds, markers, groupId } = this.props

    if (!prevState.mapTypeConstrolPosition && window.google) {
      this.setState({
        mapTypeConstrolPosition: window.google.maps.ControlPosition.TOP_CENTER
      })
    }
    if (!_isEqual(prevProps.markers, markers)) {
      const MARKERS = 'markers'
      fitToBounds(MARKERS, markers)
    }
    if (prevProps.groupId !== groupId && groupId === DEFAULT_GROUP_ID) {
      this.setState({
        defaultCenter: { latitude: 43.295645, longitude: -1.980012 },
        mapTypeConstrolPosition: undefined
      })
    }
  }

  handleZoomChanged = newZoom => {
    this.setState({
      zoom: newZoom
    })
  }

  handleCenterChanged = ({ latitude, longitude }) => {
    this.setState({
      center: {
        latitude,
        longitude
      }
    })
  }

  render() {
    const { bounds, geofences, handleSelectFromTheMap, mapContainerRef, markers, openSelectDialog } = this.props
    const { center, defaultCenter, defaultZoom, mapTypeConstrolPosition, zoom } = this.state

    return (
      <div
        ref={mapContainerRef}
        style={{
          height: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          flexGrow: 3
        }}
      >
        <Map
          bounds={bounds}
          center={center}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          onCenterChanged={this.handleCenterChanged}
          onZoomChanged={this.handleZoomChanged}
          options={{
            scaleControl: false,
            mapTypeControl: true,
            panControl: true,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControlOptions: {
              position: mapTypeConstrolPosition
            }
          }}
          style={{
            width: '100%',
            height: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
          }}
          zoom={zoom}
        >
          {markers.map(marker => (
            <Marker
              key={marker.id}
              isSelected={marker.isSelected}
              label={marker.title}
              name={marker.id}
              notifications={marker.notifications}
              position={marker.position}
              status={marker.status}
            />
          ))}
          {geofences.map((geofence, i) =>
            geofence && geofence.shapeType === 'circle' ? (
              <Circle
                key={geofence.hashId}
                center={geofence.center}
                onClick={() => {
                  handleSelectFromTheMap(geofence)
                  openSelectDialog()
                }}
                options={{
                  clickable: true,
                  fillColor:
                    geofence.ruleInstances && geofence.ruleInstances.some(ri => ri.enabled)
                      ? geofence.color
                      : '#939fab',
                  fillOpacity: 0.4,
                  strokeColor: geofence.color,
                  strokeOpacity: 1,
                  strokeWeight: geofence.ruleInstances && geofence.ruleInstances.some(ri => ri.enabled) ? 1 : 2
                }}
                radius={geofence.radius}
              />
            ) : (
              <Polygon
                key={geofence.hashId}
                onClick={() => {
                  handleSelectFromTheMap(geofence)
                  openSelectDialog()
                }}
                options={{
                  clickable: true,
                  fillColor:
                    geofence.ruleInstances && geofence.ruleInstances.some(ri => ri.enabled)
                      ? geofence.color
                      : '#939fab',
                  fillOpacity: 0.4,
                  strokeColor: geofence.color,
                  strokeOpacity: 1,
                  strokeWeight: geofence.ruleInstances && geofence.ruleInstances.some(ri => ri.enabled) ? 1 : 2
                }}
                path={geofence.polygonPath}
              />
            )
          )}
        </Map>
      </div>
    )
  }
}

MapView.propTypes = {
  bounds: PropTypes.object,
  fitToBounds: PropTypes.func.isRequired,
  geofences: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSelectFromTheMap: PropTypes.func.isRequired,
  mapContainerRef: PropTypes.object.isRequired,
  markers: PropTypes.array.isRequired,
  openSelectDialog: PropTypes.func.isRequired
}

MapView.defaultProps = {
  bounds: undefined
}

export default MapView
