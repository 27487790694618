import { defineMessages } from 'react-intl'

const namespace = 'NoDashboard'

const messages = defineMessages({
  noDashboardAvailable: {
    id: `${namespace}.noDashboardAvailable`,
    description: 'No dashboard available',
    defaultMessage: 'No dashboard available'
  },
  clickOnToCreateTheFirstOne: {
    id: `${namespace}.clickOnToCreateTheFirstOne`,
    description: 'Click on to create the first one.',
    defaultMessage: 'Click on { addDashboard } to create the first one.'
  },
  backToMachines: {
    id: `${namespace}.backToMachines`,
    description: 'Back to machines',
    defaultMessage: 'Back to machines'
  },
  addDashboard: {
    id: `${namespace}.addDashboard`,
    description: 'ADD DASHBOARD',
    defaultMessage: 'ADD DASHBOARD'
  }
})

export default messages
