import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'


import LineChartConfig from './LineChartConfig'
import messages from './messages'
import * as utils from '../utils'


class LineChart extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.chartTextRenderer = null
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data.values.length === 0 && data.values.length > 0 && this.chartTextRenderer) {
      this.chartTextRenderer.destroy()
    }
  }

  getSignalData = () => {
    const { dinamicData, data } = this.props
    let signalData = dinamicData.filter(signal => {
      return signal.signalId === data.data
    })

    if (signalData[0] === undefined) {
      signalData = [
        {
          unit: this.formatMessage(messages.noSignal)
        }
      ]
    }

    return signalData[0]
  }

  signalDataValues = () => {
    const { data } = this.props
    const formatedValues = data.values.map(value => {
      let numOfDec = 0
      if (typeof data.numberOfDecimals !== 'undefined') numOfDec = data.numberOfDecimals
      const num = parseFloat('1e' + numOfDec)
      return Math.round((value + Number.EPSILON) * num) / num
    })

    return formatedValues
  }

  tooltipFormatter = (unit = '') => {
    return function () {
      return '<strong>' + this.x + '</strong><br />' + this.point.y + ' ' + unit
    }
  }

  afterRender = chart => {
    if (this.getSignalData().unit === this.formatMessage(messages.noSignal)) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = '16px Arial'
      const textWidth = ctx.measureText(this.formatMessage(messages.noSignal)).width
      const x = Math.floor(chart.chartWidth / 2) - Math.floor(textWidth / 2)
      const y = Math.floor(chart.chartHeight / 2) - 8
      this.chartTextRenderer = chart.renderer
        .text(this.formatMessage(messages.noSignal), x, y)
        .css({
          fontSize: '16px'
        })
        .add()
    } else if (chart.series[0].data.length === 0 || chart.series[0].data[0].isNull) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = '16px Arial'
      const textWidth = ctx.measureText(this.formatMessage(messages.noDataAvailable)).width
      const x = Math.floor(chart.chartWidth / 2) - Math.floor(textWidth / 2)
      const y = Math.floor(chart.chartHeight / 2) - 8
      this.chartTextRenderer = chart.renderer
        .text(this.formatMessage(messages.noDataAvailable), x, y)
        .css({
          fontSize: '16px'
        })
        .add()
    }
  }

  render() {
    const {
      data: { timestamps, data },
      dinamicData,
      eid,
      isSubscribedTo
    } = this.props

    const config = LineChartConfig()

    config.xAxis = config.xAxis || {}
    config.xAxis.categories = timestamps

    config.series[0].data = this.signalDataValues()

    config.tooltip = config.tooltip || {}
    config.tooltip.formatter = this.tooltipFormatter(this.getSignalData() ? this.getSignalData().unit : '')

    const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
    const isValidData = dinamicDataSignalIds.includes(data)
    const widgetIsNotConfigured = data === ''
    const noDynamicSignalsAvailable = dinamicData.length === 0
    const widgetIsSubscribing = (eid === '' || !isSubscribedTo(eid, utils.getTopic(data, eid))) && isValidData

    return (
      <div
        className={
          widgetIsNotConfigured || noDynamicSignalsAvailable ? 'highcharts-wrapper notConfigured' : 'highcharts-wrapper'
        }
      >
        {noDynamicSignalsAvailable ? (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'left'
              }}
            >
              {this.formatMessage(messages.notSupportedMachine)}
            </span>
          </Typography>
        ) : widgetIsNotConfigured ? (
          <Typography gutterBottom={false}>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : widgetIsSubscribing ? (
          <div className='sk-bounce small'>
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
          </div>
        ) : (
          <HighchartsReact callback={this.afterRender} highcharts={Highcharts} options={config} />
        )}
      </div>
    )
  }
}

LineChart.propTypes = {
  data: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isSubscribedTo: PropTypes.func.isRequired
}

export default injectIntl(LineChart)
