import { defineMessages } from 'react-intl'

const messages = defineMessages({
  editGroup: {
    id: 'TreeList.editGroup',
    description: 'Edit group label',
    defaultMessage: 'Edit group'
  },
  deleteGroup: {
    id: 'TreeList.deleteGroup',
    description: 'Delete group label',
    defaultMessage: 'Delete group'
  }
})

export default messages
