import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from 'material-ui/TextField'

import { MuiTreeList } from 'components/react-treeview-mui'

import { updatePrivilegeState, processPrivilegesToListitems } from '../utils'

import { predefinedRoles } from '../predefinedRoles'

import messages from './messages'

class NewRoleForm extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      descriptionErrorText: '',
      descriptionValue: '',
      exampleRole: -1,
      isRoleBeingCreated: false,
      privilegeState: [],
      showPrivileges: false,
      showPrivilegesKey: 0
    }
  }

  componentDidMount() {
    const { groupId, permissions } = this.props
    if (groupId && permissions.length > 0) {
      this.setState({
        showPrivileges: true,
        showPrivilegesKey: Math.random()
      })
    }
  }

  setPrivilegeState = (hashIds, value) => {
    const { privileges } = this.props
    hashIds.forEach(hashId => {
      this.setState(({ privilegeState }) => {
        const newPrivilegeState = updatePrivilegeState(privileges, privilegeState, hashId, value)
        return { privilegeState: newPrivilegeState }
      })
    })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    let elementTextError = ''
    const value = event.target.value
    const name = event.target.name
    const propertyValue = name + 'Value'
    const propertyErrorText = name + 'ErrorText'
    const newState = {}

    if (!event.target.value.trim()) {
      elementTextError = this.formatMessage(messages.thisFieldIsRequired)
    }

    newState[propertyValue] = value
    newState[propertyErrorText] = elementTextError

    this.setState(newState)
  }

  handleSelectChange = (event, index, value) => {
    const newRoleHashId = value

    if (value === null) {
      // Parent not selected
      this.setState({
        showPrivileges: false,
        showPrivilegesKey: Math.random()
      })
    } else if (newRoleHashId === 0) {
      // Root element
      this.setState({
        showPrivileges: true,
        showPrivilegesKey: Math.random(),
        privilegeState: []
      })
    } else {
      this.setState({
        showPrivileges: true,
        showPrivilegesKey: Math.random(),
        privilegeState: []
      })
    }
  }

  handleExampleRoleChange = event => {
    const { permissions, privileges } = this.props
    const exampleRole = event.target.value
    const examplePermissions = predefinedRoles[exampleRole].permissionNames
    const permissionsNames = permissions.map(permission => permission.name)
    const filteredExamplePermissions = examplePermissions.filter(examplePermission =>
      permissionsNames.includes(examplePermission)
    )
    const filteredPrivileges = filteredExamplePermissions.map(permission =>
      privileges.find(privilege => privilege.name === permission)
    )

    this.setState({ exampleRole, privilegeState: filteredPrivileges })
  }

  handleSave = () => {
    const { groupId } = this.props
    const { privilegeState, descriptionValue } = this.state
    const permissions = privilegeState.map(privilege => privilege.hashId)
    this.saveRole([groupId, descriptionValue, permissions])
  }

  saveRole = params => {
    const { createRole, intl, action } = this.props

    this.setState({
      isRoleBeingCreated: true
    })

    createRole(...params)
      .then(() => {
        action()
      })
      .catch(response => {
        const error = response.error

        switch (error.response.status) {
          case 400:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.invalidRoleName)]
            })
            break
          case 401:
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 409:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '409' }),
              alertMessagesText: [this.formatMessage(messages.error409Message)]
            })
            break
          case 415:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '415' }),
              alertMessagesText: [this.formatMessage(messages.error415Message)]
            })
            break
          case 422:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
              alertMessagesText: [this.formatMessage(messages.error422Message)]
            })
            break
          case 500:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              isRoleBeingCreated: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
        }
      })
  }

  listPrivileges = () => {
    const { permissions, privilegesHierarchy } = this.props
    const { showPrivileges, showPrivilegesKey, privilegeState } = this.state
    if (showPrivileges) {
      return (
        <div key={showPrivilegesKey} className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <h2>{this.formatMessage(messages.permissions)}</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='privilegeList'>
                <MuiTreeList
                  activeListItem={-1}
                  contentKey='title'
                  haveSearchbar={false}
                  // expandedListItems={[1, 4, 11, 12, 15, 18]}
                  listItems={processPrivilegesToListitems(
                    privilegesHierarchy,
                    permissions,
                    this.setPrivilegeState,
                    privilegeState
                  )}
                />
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
      )
    }
  }

  render() {
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      descriptionErrorText,
      descriptionValue,
      exampleRole,
      isRoleBeingCreated,
      privilegeState
    } = this.state
    return (
      <div className='container-fluid' style={{ margin: '20px 0 0 0' }}>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
        <Paper style={{ borderRadius: 0 }}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  errorText={descriptionErrorText}
                  floatingLabelText={this.formatMessage(messages.name)}
                  fullWidth
                  name='description'
                  onChange={this.handleInputChange}
                  value={descriptionValue}
                />
              </div>
              <div className='col-md-4'>
                <FormControl fullWidth style={{ marginTop: 14 }}>
                  <InputLabel>{this.formatMessage(messages.selectAnExampleRole)}</InputLabel>
                  <Select fullWidth onChange={this.handleExampleRoleChange} value={exampleRole}>
                    {predefinedRoles.map((role, index) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {role.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          {this.listPrivileges()}
          <p>&nbsp;</p>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <div style={{ padding: '20px 10px' }}>
                  <Button
                    className='primary-action-button'
                    disabled={
                      descriptionValue === '' ||
                      descriptionErrorText !== '' ||
                      privilegeState.length === 0 ||
                      isRoleBeingCreated
                    }
                    onClick={this.handleSave}
                  >
                    {isRoleBeingCreated && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                    {this.formatMessage(messages.save)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

NewRoleForm.propTypes = {
  action: PropTypes.func.isRequired,
  createRole: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  privileges: PropTypes.array.isRequired,
  privilegesHierarchy: PropTypes.any.isRequired
}

NewRoleForm.defaultProps = {
  permissions: []
}

export default injectIntl(NewRoleForm)
