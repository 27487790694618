import { defineMessages } from 'react-intl'
 
const messages = defineMessages({
  successMessage: {
    id: 'SetNotificationsByType.successMessage',
    description: 'The action was executed successfully message',
    defaultMessage: 'The action was executed successfully.'
  },
  error400Message: {
    id: 'SetNotificationsByType.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'SetNotificationsByType.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'SetNotificationsByType.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  error406Message: {
    id: 'SetNotificationsByType.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error409Message: {
    id: 'SetNotificationsByType.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: '+
        'Group name it\'s already in use'
  },
  error500ToShow: {
    id: 'SetNotificationsByType.error500ToShow',
    description: 'Error 500 message',
    defaultMessage: 'Server error'
  },
  errorUndefinedTitle: {
    id: 'SetNotificationsByType.errorUndefinedTitle',
    description: 'Error undefined title', 
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'SetNotificationsByType.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error422Message: {
    id: 'SetNotificationsByType.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'SetNotificationsByType.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
})
 
export default messages