import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ProfileLoader.title',
    description: 'Temporarily down for maintenance title',
    defaultMessage: 'Temporarily down for maintenance.'
  },
  subtitle: {
    id: 'ProfileLoader.subtitle',
    description: 'Temporarily down for maintenance subtitle',
    defaultMessage: 'We are performing scheduled maintenance. We should be back online shortly.'
  },
  notVerified: {
    id: 'ProfileLoader.notVerified',
    description: 'Your email is not verified.',
    defaultMessage: 'Your email is not verified.'
  },
  loadingProfile: {
    id: 'ProfileLoader.loadingProfile',
    description: 'Loading profile text',
    defaultMessage: 'Loading profile...'
  },
  return: {
    id: 'ProfileLoader.return',
    description: 'Go back to login page button',
    defaultMessage: 'Go back to login page'
  },
  toExpire: {
    id: 'ProfileLoader.toExpire',
    description: 'Your session is about to expire',
    defaultMessage: 'WARNING: Your session is about to expire'
  },
  timeToExpire: {
    id: 'ProfileLoader.timeToExpire',
    description: 'Your session is going to expire in 2 minutes',
    defaultMessage: 'Your session is going to expire in 2 minutes.'
  },
  understand: {
    id: 'ProfileLoader.understand',
    description: 'I understand it',
    defaultMessage: 'I understand it'
  }
})

export default messages
