export const alertStyles = {
  alertTitle: {
    color: 'inherit',
    fontSize: '16px'
  },
  alertMessage: {
    width: '100%'
  },
  alertLink: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}

export const dialogStyles = {
  dialogTitle: {
    padding: '0px 8px',
    marginBottom: '16px'
  },
  dialogContent: {
    padding: '8px'
  },
  dialogContentGridContainer: {
    padding: '0px 0px 32px 0px',
    width: 'calc(100% - 32px)'
  }
}

export const iconButtonStyles = {
  iconButtonRoot: {
    padding: '5px'
  }
}

export const paperStyles = {
  paperRoot: {
    borderRadius: 0,
    padding: 20
  }
}
