import React from 'react'
import { OverlayView } from 'react-google-maps'

import { OverlayImageProps } from '../../../props'
import { LoadedOverlayView } from './LoadedOverlayView'

import { injectIntl } from 'react-intl'
import messages from './messages'

const CustomOverlayImage = ({
  label = '',
  url,
  bounds,
  rotate = 0,
  intl,
  ...other
}) => {
  const { formatMessage } = intl
  const imgStyle = {
    width: '100%',
    height: '100%',
    transform: `rotate(${rotate}deg)`
  }

  const children = <img alt={formatMessage(messages.overlay)+` ${label}`} src={url} style={imgStyle} />

  return (
    <LoadedOverlayView
      label={label}
      name={label + '_overlayImage'}
      bounds={bounds}
      className="LoadedOverlayView"
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      {children}
    </LoadedOverlayView>
  )
}

CustomOverlayImage.propTypes = OverlayImageProps

export default injectIntl(CustomOverlayImage)
