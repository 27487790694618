import moment from 'moment'

// BEGIN: Bits of the general configuration object.
const generalSettings = {
  chart: {
    highstock: true, // Flag used to know when to use highstock or highchart
    zoomType: 'x',
    // To make sure that range changes don't animate changes in axis changes
    animation: false,
    resetZoomButton: {
      theme: {
        display: 'none'
      }
    }
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  series: []
}

const plotSettings = {
  plotOptions: {
    series: {
      dataGrouping: {
        enabled: false
      },
      showInNavigator: true,
      fillColor: false,
      allowPointSelect: true,
      animation: false
      // Don't connect all the points if there is too much space between them
      // gapSize: 5
    }
  },
  xAxis: {
    plotBands: [],
    labels: {
      formatter() {
        const t = moment(this.value)
        return t.format('LTS') + '<br />' + t.format('L')
      }
    },
    minPadding: 0.05,
    maxPadding: 0.05,
    // It won't adapt x-axis (makes sense to see gaps in timelines)
    ordinal: false
  },
  yAxis: {
    title: {
      text: ''
    },
    labels: {}
  }
}

const navigatorSettings = {
  navigator: {
    enabled: true,
    height: 200,
    series: {
      type: 'line', // Needed to make step work in the navigator
      animation: false,
      dataGrouping: {
        enabled: false
      },
      marker: {
        enabled: false
      },
      ordinal: false
      //gapSize: 5
    },
    xAxis: {
      type: 'datetime',
      ordinal: false,
      style: {
        paddingTop: 10
      }
    },
    yAxis: {
      reversed: false
    },
    style: {
      paddingBottom: 10
    }
  }
}

const buttonsSettings = {
  rangeSelector: {
    enabled: false,
    inputEnabled: false,
    buttons: []
  }
}
// END: Bits of the general configuration object.

export default {
  ...generalSettings,
  ...plotSettings,
  ...navigatorSettings,
  ...buttonsSettings
}
