export const mapDynamicDevicesFilterObject = (
  filterObject,
  { sortColumnKey, sortColumnModel, sortColumnOrder } = {}
) => {
  const mappedFilterObject = Object.entries(filterObject).reduce((acc, [key, { type, value }]) => {
    let model = 'Device'
    let columnKey = key
    if (columnKey.includes('.')) {
      const [newModel, newKey] = key.split('.')
      model = newModel
      columnKey = newKey
    }
    const newAcc = { ...acc }
    let field = 'searchIn'
    if (type === 'SelectFilter') field = 'filters'
    if (field in newAcc) {
      const index = newAcc[field].models.findIndex(({ modelName }) => modelName === model)
      if (index > -1) newAcc[field].models[index].columns.push({ [columnKey]: value })
      else newAcc[field].models.push({ modelName: model, columns: [{ [columnKey]: value }] })
    } else {
      newAcc[field] = {
        models: [
          {
            modelName: model,
            columns: [{ [columnKey]: value }]
          }
        ]
      }
    }
    return newAcc
  }, {})

  if (sortColumnKey) {
    mappedFilterObject.orderBy = {
      models: [
        {
          modelName: sortColumnModel,
          column: sortColumnKey,
          order: sortColumnOrder
        }
      ]
    }
  }

  return mappedFilterObject
}

export const getFiltersMatchingDevices = (filterObject, devices) => {
  const removingDeviceIds = []
  Object.entries(filterObject).forEach(([key, { value, type }]) => {
    for (let i = 0; i < devices.length; i++) {
      const device = devices[i]
      if (type === 'SelectFilter' && device[key] !== value) removingDeviceIds.push(device.id)
      else if (type === 'TextFilter' && !device[key].includes(value)) removingDeviceIds.push(device.id)
    }
  })
  const uniqueRemovingDeviceIds = [...new Set(removingDeviceIds)]
  const filtersMatchingDevices = devices.filter(device => !uniqueRemovingDeviceIds.includes(device.id))
  return filtersMatchingDevices
}
