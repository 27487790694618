import * as t from './actionTypes'
import * as url from '../url'
import { createRequestAction } from './utils'

const getGeofences = (groupId, query) => createRequestAction(t.GEOFENCES, url.geofences(groupId, query), 'get')

const getGeofence = geofenceId => createRequestAction(t.GEOFENCE, url.geofence(geofenceId), 'get')

const newGeofence = (groupId, geofence) =>
  createRequestAction(t.CREATE_GEOFENCE, url.geofences(groupId), 'post', geofence)

const editGeofence = geofence => {
  const ret = createRequestAction(t.EDIT_GEOFENCE, url.geofence(geofence.hashId), 'put', geofence)
  ret.payload.request.headers = {
    'if-match': geofence.version
  }
  return ret
}

const deleteGeofence = geofence => {
  const ret = createRequestAction(t.DELETE_GEOFENCE, url.geofence(geofence.hashId), 'delete')
  ret.payload.request.headers = {
    'if-match': geofence.version
  }
  return ret
}

export { getGeofences, getGeofence, newGeofence, editGeofence, deleteGeofence }
