import React from 'react'
import PropTypes from 'prop-types'

class LoaderWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { groupId, onMount } = this.props
    if (groupId) onMount(groupId)
  }

  componentDidUpdate(prevProps) {
    const { globalQuery, onMount, groupId } = this.props
    if (prevProps.globalQuery !== globalQuery) onMount()
    else if (prevProps.groupId !== groupId && groupId) onMount(groupId)
  }

  componentWillUnmount() {
    const { onUnmount } = this.props
    onUnmount()
  }

  render() {
    const { children } = this.props
    return children
  }
}

LoaderWrapper.propTypes = {
  globalQuery: PropTypes.string,
  groupId: PropTypes.string,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func
}

LoaderWrapper.defaultProps = {
  globalQuery: '',
  groupId: '',
  onUnmount: i => i
}

const createNodesLoader = WrappedComponent =>
  function ({ load, unload, globalQuery, groupId, ...other }) {
    if (!WrappedComponent) {
      return <div />
    }

    return (
      <LoaderWrapper globalQuery={globalQuery} groupId={groupId} onMount={load} onUnmount={unload}>
        <WrappedComponent {...other} groupId={groupId} reloadMap={load} />
      </LoaderWrapper>
    )
  }

export { createNodesLoader }
