const chartConfig = timestampString => ({
  chart: {
    type: 'gauge'
  },

  exporting: {
    enabled: false
  },

  pane: {
    startAngle: -150,
    endAngle: 150,
    background: [
      {
        backgroundColor: {
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        outerRadius: '109%'
      },
      {
        backgroundColor: {
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        outerRadius: '107%'
      },
      {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      },
      {
        backgroundColor: '#FFF',
        outerRadius: '103%'
      }
    ]
  },

  series: [
    {
      name: '',
      data: [],
      tooltip: {
        valueSuffix: ''
      }
    }
  ],

  tooltip: {
    formatter() {
      const {
        name,
        tooltipOptions: { valueSuffix }
      } = this.series
      const value = this.y || '--'
      return `${timestampString ? timestampString + '<br/>' : ''}${name}: <b>${value}${valueSuffix}</b>`
    }
  },

  yAxis: {
    max: 100,
    min: 0,
    minorTickWidth: 1,
    minorTickLength: 10,
    minorTickPosition: 'inside',
    minorTickColor: '#666',

    tickPixelInterval: 30,
    tickWidth: 2,
    tickPosition: 'inside',
    tickLength: 10,
    tickColor: '#666',
    labels: {
      step: 2,
      rotation: 'auto'
    },
    title: {
      text: '',
      style: {
        fontSize: '16px'
      }
    },
    plotBands: [
      {
        from: 0,
        to: 100
      }
    ]
  }
})

export default chartConfig
