import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import PageTitle from 'components/PageTitle'

import messages from './messages'

const NewConfigurationFormTitle = ({ configurationsUrl, canReadConfigurations, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)

  return <PageTitle back={configurationsUrl} title={title} />
}

NewConfigurationFormTitle.propTypes = {
  canReadConfigurations: PropTypes.bool.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(NewConfigurationFormTitle)
