import axios from 'axios'

const getForecastData = (lat, lon, units) => {
  const mappedUnits = {
    Celsius: 'metric',
    Fahrenheit: 'imperial'
  }
  const urlPrefix = 'https://api.openweathermap.org/data/2.5/forecast?'
  const coordinates = lat && lon ? 'lat=' + lat + '&lon=' + lon : ''
  const urlSuffix = '&APPID=' + process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY + '&units=' + mappedUnits[units]
  const url = [urlPrefix, coordinates, urlSuffix].join('')
  return axios.get(url)
}

export { getForecastData }
