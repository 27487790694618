import { defineMessages } from 'react-intl'

const messages = defineMessages({
  confirmWidgetToDelete: {
    id: 'DeleteWidget.confirmWidgeteToDelete',
    description: 'Delete widget confirm',
    defaultMessage: 'CONFIRM WIDGET TO DELETE'
  },
  widgetID: {
    id: 'DeleteWidget.widgetID',
    description: 'Delete widget confirm',
    defaultMessage: 'Widget ID'
  },
  widgetType: {
    id: 'DeleteWidget.widgetType',
    description: 'Delete widget confirm',
    defaultMessage: 'Widget type'
  }
})

export default messages
