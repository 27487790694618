import React from 'react'
import PropTypes from 'prop-types'

import OverlayView from './CustomOverlayView'
import { Bounds } from '../../../props'

// To fix issue: https://github.com/tomchentw/react-google-maps/issues/482
class LoadedOverlayView extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.setState({ loaded: true })
  }

  _getPixelPositionOffset = () => {
    return { x: -this.props.size / 2, y: -this.props.size }
  }

  render() {
    if (!this.state.loaded) {
      return <div />
    }

    const { children, position, zIndex, bounds, ...other } = this.props
    delete other.size

    let gmapsPosition = undefined
    if (position) {
      gmapsPosition = {
        lng: position.longitude,
        lat: position.latitude
      }
    }

    let gmapsBounds = undefined
    if (bounds) {
      const { maps } = window.google
      const getLatlng = b => ({ lat: b.latitude, lng: b.longitude })

      gmapsBounds = new maps.LatLngBounds(getLatlng(bounds.sw), getLatlng(bounds.ne))
    }

    return (
      <OverlayView
        position={gmapsPosition}
        bounds={gmapsBounds}
        getPixelPositionOffset={this._getPixelPositionOffset}
        {...other}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex
          }}
        >
          {children}
        </div>
      </OverlayView>
    )
  }
}

LoadedOverlayView.propTypes = {
  bounds: Bounds,
  zIndex: PropTypes.number
}

export { LoadedOverlayView }
