import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import messages from './messages'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const ProductionTestModeInfoDialog = ({ goToPreviouStep, onClose, intl }) => {
  const { formatMessage } = intl
  const inBoldFormat = key => <b>{formatMessage(messages[key])}:</b>
  const inItalicFormat = key => <i>{formatMessage(messages[key])}</i>

  const handleClose = () => {
    onClose()
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <IconButton
          style={{
            position: 'absolute',
            right: 3,
            top: 3,
            padding: 5
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {formatMessage(messages.infoTitle)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{formatMessage(messages.infoParagraphOne)}</DialogContentText>
        <br />
        <DialogContentText>
          {formatMessage(messages.inactiveParagraph, { state: inBoldFormat('inactive') })}
        </DialogContentText>
        <br />
        <DialogContentText>
          {formatMessage(messages.productionTestModeParagraph, {
            state: inBoldFormat('productionTestMode'),
            productionTestMode: inItalicFormat('productionTestMode')
          })}
        </DialogContentText>
        <DialogContentText>
          {formatMessage(messages.postProductionTestModeParagraphOne, {
            productionTestMode: inItalicFormat('productionTestMode'),
            suspend: inItalicFormat('suspend')
          })}
        </DialogContentText>
        <DialogContent>
          <br />
          <DialogContentText>
            {formatMessage(messages.postProductionTestModeParagraphTwo, {
              productionTestMode: inItalicFormat('productionTestMode'),
              suspend: inItalicFormat('suspend')
            })}
          </DialogContentText>
          <DialogContentText>
            {formatMessage(messages.postProductionTestModeParagraphThree, { suspend: inItalicFormat('suspend') })}
          </DialogContentText>
          <DialogContentText>
            {formatMessage(messages.postProductionTestModeParagraphFour, {
              productionTestMode: inItalicFormat('productionTestMode'),
              suspend: inItalicFormat('suspend')
            })}
          </DialogContentText>
        </DialogContent>
        <DialogContentText>
          {formatMessage(messages.suspendParagraph, {
            state: inBoldFormat('suspend'),
            productionTestMode: inItalicFormat('productionTestMode'),
            suspend: inItalicFormat('suspend')
          })}
        </DialogContentText>
        <br />
        <DialogContentText>
          {formatMessage(messages.activeParagraph, { state: inBoldFormat('active'), active: inItalicFormat('active') })}
        </DialogContentText>
        <div className="sim-status-graph">
          <img
            src={process.env.PUBLIC_URL + '/images/drawing.png'}
            alt={formatMessage(messages.simStatusGraph)}
            className="sim-status-graph-image"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="cancel-button" onClick={handleClose}>
          {formatMessage(messages.cancel)}
        </Button>
        <Button className="primary-action-button" onClick={goToPreviouStep}>
          {formatMessage(messages.back)}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

ProductionTestModeInfoDialog.propTypes = {
  goToPreviouStep: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default injectIntl(ProductionTestModeInfoDialog)
