import { defineMessages } from 'react-intl'

const messages = defineMessages({
  help: {
    id: 'HelpDropdown.help',
    description: 'Help',
    defaultMessage: 'HELP'
  },
  statusPage: {
    id: 'HelpDropdown.statusPage',
    description: 'Operations Status',
    defaultMessage: 'Operations Status'
  },
  support: {
    id: 'HelpDropdown.support',
    description: 'Support',
    defaultMessage: 'Support'
  },
  cookies: {
    id: 'HelpDropdown.cookies',
    description: 'Cookies button label',
    defaultMessage: 'Cookies'
  }
})

export default messages
