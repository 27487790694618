import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { logError } from 'utils/http'

import Alert from 'components/Alert'
import messages from './messages'

class ShareAccessPasses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isAlertShown: false,
      selectedGroupId: '',
      selectedGroupIdErrorText: '',
      numberOfAccessPassess: '1',
      alertMessage: ''
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleShare = () => {
    const { selectedGroupId, numberOfAccessPassess } = this.state
    const {
      groupId,
      shareAccessPasses,
      onClose,
      onShareAccessPasses,
      maxNumberOfAccessPasses,
      getChildGroups,
      getGroup,
      onAccessPassesShared,
      portalGroupId
    } = this.props
    if (
      selectedGroupId &&
      parseInt(numberOfAccessPassess) > 0 &&
      parseInt(numberOfAccessPassess) <= maxNumberOfAccessPasses
    ) {
      const body = {
        originGroupId: groupId,
        shareAll: false,
        shareCount: numberOfAccessPassess,
        resourceType: 'Access_pass'
      }
      this.setState(
        {
          isLoading: true
        },
        () => {
          shareAccessPasses(selectedGroupId, body)
            .then(() => {
              const { name } = getGroup(selectedGroupId)
              onShareAccessPasses(name, numberOfAccessPassess)
              onClose()
              if (selectedGroupId === portalGroupId) onAccessPassesShared()
            })
            .catch(response => {
              logError(response)
              const { error } = { ...response }
              let alertMessage = this.formatMessage(messages.errorAccessPassShare)
              if (error?.response?.status === 400 && error?.response?.data.includes('NO ACCESS PASSES WERE FOUND')) {
                const childGroups = getChildGroups(groupId)
                const { name } = childGroups.find(childGroup => childGroup.id === selectedGroupId)
                alertMessage = this.formatMessage(messages.allTheAccessPassesAlreadyShared, {
                  groupName: '\'' + name + '\''
                })
              }
              this.setState({
                isAlertShown: true,
                alertMessage
              })
            })
            .finally(() => {
              this.setState({
                isLoading: false
              })
            })
        }
      )
    }
  }

  handleSelectedGroupChange = (event, value) => {
    const id = value?.id || ''
    const selectedGroupIdErrorText = id === '' ? this.formatMessage(messages.thisFieldIsRequired) : ''
    this.setState({
      selectedGroupId: id,
      selectedGroupIdErrorText
    })
  }

  handleNumberOfAPsChange = event => {
    const { value } = event.target
    this.setState({
      numberOfAccessPassess: value
    })
  }

  handleExited = () => {
    this.setState({
      isLoading: false,
      isAlertShown: false,
      selectedGroupId: '',
      selectedGroupIdErrorText: '',
      numberOfAccessPassess: 1,
      alertMessage: ''
    })
  }

  render() {
    const { open, onClose, maxNumberOfAccessPasses, groupId, getChildGroups } = this.props
    const { isLoading, isAlertShown, selectedGroupId, numberOfAccessPassess, selectedGroupIdErrorText, alertMessage } =
      this.state
    const shareButtonDisabled =
      isLoading ||
      isAlertShown ||
      selectedGroupId === '' ||
      !parseInt(numberOfAccessPassess) > 0 ||
      parseInt(numberOfAccessPassess) > maxNumberOfAccessPasses
    const childGroups = getChildGroups(groupId)
    let numberOfAPsErrorText = ''
    if (parseInt(numberOfAccessPassess) < 1)
      numberOfAPsErrorText = this.formatMessage(messages.invalidNumberOfAccessPasses)
    else if (parseInt(numberOfAccessPassess) > maxNumberOfAccessPasses)
      numberOfAPsErrorText = this.formatMessage(messages.youExceededTheMaximum)

    return (
      <Dialog disableBackdropClick fullWidth maxWidth='md' onExited={this.handleExited} open={open}>
        <DialogTitle>
          <Grid alignItems='center' container>
            <Grid item xs={10}>
              {this.formatMessage(messages.title)}
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isAlertShown ? (
            <Alert alertType='danger' messageText={[alertMessage]} showAlert={isAlertShown} />
          ) : (
            <Grid container spacing={3}>
              <Grid item sm={8} xs={12}>
                <Autocomplete
                  fullWidth
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={this.handleSelectedGroupChange}
                  options={childGroups}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={selectedGroupIdErrorText !== ''}
                      helperText={selectedGroupIdErrorText}
                      label={this.formatMessage(messages.selectTheGroup)}
                    />
                  )}
                  value={childGroups.find(group => group.id === selectedGroupId) || null}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  error={numberOfAPsErrorText !== ''}
                  fullWidth
                  helperText={numberOfAPsErrorText}
                  label={this.formatMessage(messages.selectNumberOfAccessPasses)}
                  onChange={this.handleNumberOfAPsChange}
                  type='number'
                  value={numberOfAccessPassess}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={onClose}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={shareButtonDisabled} onClick={this.handleShare}>
            {this.formatMessage(messages.share)}
            {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ShareAccessPasses.propTypes = {
  getChildGroups: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  maxNumberOfAccessPasses: PropTypes.number.isRequired,
  onAccessPassesShared: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onShareAccessPasses: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  portalGroupId: PropTypes.string.isRequired,
  shareAccessPasses: PropTypes.func.isRequired
}

ShareAccessPasses.defaultProps = {
  onAccessPassesShared: i => i
}

export default injectIntl(ShareAccessPasses)
