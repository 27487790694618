import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'

import TextField from 'material-ui/TextField'

import NotificationCriticalitySelector from 'components/NotificationCriticalitySelector'
import PageSubTitle from 'components/PageSubTitle'
import { devicesEndpointAdapter } from 'utils/adapters'
import {
  getExplanationsByNotificationCriticality,
  getIconColorByNotificationCriticality
} from 'utils/notificationCriticality'

import NotificationsRulesSettingsStepper from '../../NotificationsRulesSettingsStepper'
import { checkSelectedDevices } from '../../utils'
import messages from '../messages'
import AssignMachinesTable from './AssignMachinesTable'

class GeneralSettingsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assignedDevicesErrorText: '',
      criticalityColor: '',
      criticalityDescription: '',
      criticalityErrorText: '',
      criticalityValue: '',
      descriptionInputErrorText: '',
      descriptionInputValue: '',
      enabledValue: true,
      nameInputErrorText: '',
      nameInputValue: ''
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  getDevicesAssignmentErrorText = assignedDevices => {
    let assignedDevicesErrorText = ''

    if (assignedDevices.length === 0) {
      assignedDevicesErrorText = this.formatMessage(messages.assignAtLeastAMachine)
    } else {
      const devicesAssignedErrorReceived = checkSelectedDevices(assignedDevices)
      if (devicesAssignedErrorReceived === 'devicesWithDifferentConfigurationFile') {
        assignedDevicesErrorText = this.formatMessage(messages.machineSelectedWithDifferentConfigurationFile)
      } else if (devicesAssignedErrorReceived === 'devicesWithoutConfigurationFile') {
        assignedDevicesErrorText = this.formatMessage(messages.machineSelectedWithoutConfigurationFile)
      } else if (devicesAssignedErrorReceived === 'devicesWithConfigurationFileWithoutSignals') {
        assignedDevicesErrorText = this.formatMessage(messages.machineSelectedWithConfigurationFileWithoutSignals)
      }
    }

    return assignedDevicesErrorText
  }

  handleNameInputChange = (event, value) => {
    const nameInputErrorText = value === '' ? this.formatMessage(messages.thisFieldIsRequired) : ''
    this.setState({ nameInputValue: value, nameInputErrorText })
  }

  handleDescriptionInputChange = (event, value) => {
    const descriptionInputErrorText = value === '' ? this.formatMessage(messages.thisFieldIsRequired) : ''
    this.setState({ descriptionInputValue: value, descriptionInputErrorText })
  }

  handleEnableChange = (event, value) => {
    this.setState({ enabledValue: value })
  }

  handleCriticalityChange = value => {
    const criticalitySelectedDescription = getExplanationsByNotificationCriticality(value)
    const criticalitySelectedIconColor = getIconColorByNotificationCriticality(value)

    this.setState({
      criticalityValue: value,
      criticalityDescription: criticalitySelectedDescription,
      criticalityColor: criticalitySelectedIconColor
    })
  }

  handleAssignedDevicesChange = assignedDevices => {
    const { onAssignedDevicesChange } = this.props

    const mappedAssignedDevices = devicesEndpointAdapter(assignedDevices)
    const assignedDevicesErrorText = this.getDevicesAssignmentErrorText(mappedAssignedDevices)

    this.setState({ assignedDevicesErrorText })
    onAssignedDevicesChange(mappedAssignedDevices)
  }

  handleNextClick = () => {
    const { assignedDevices, groupId, groupDescription, onSaveNewNotification } = this.props
    const { criticalityValue, descriptionInputValue, enabledValue, nameInputValue } = this.state

    const errorsState = {
      nameInputErrorText: nameInputValue === '' ? this.formatMessage(messages.thisFieldIsRequired) : '',
      descriptionInputErrorText: descriptionInputValue === '' ? this.formatMessage(messages.thisFieldIsRequired) : '',
      criticalityErrorText: criticalityValue === '' ? this.formatMessage(messages.thisFieldIsRequired) : '',
      assignedDevicesErrorText: this.getDevicesAssignmentErrorText(assignedDevices)
    }

    if (Object.values(errorsState).every(error => !error)) {
      const devices = assignedDevices.map(device => {
        return {
          eid: device.EID,
          deviceName: device.name,
          deviceType: device.deviceType
        }
      })

      const newNotification = {
        ruleHashId: null,
        name: nameInputValue,
        description: descriptionInputValue,
        criticality: criticalityValue,
        enabled: enabledValue,
        groupId,
        groupDescription,
        devices,
        variables: null,
        users: null
      }

      onSaveNewNotification(newNotification)
    } else {
      this.setState(errorsState)
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { assignedDevices, groupId, groupDescription, notificationsUrl } = this.props
    const {
      assignedDevicesErrorText,
      criticalityColor,
      criticalityDescription,
      criticalityErrorText,
      criticalityValue,
      descriptionInputErrorText,
      descriptionInputValue,
      enabledValue,
      nameInputErrorText,
      nameInputValue
    } = this.state

    const isNextButtonDisabled = assignedDevicesErrorText !== '' || assignedDevices.length === 0

    return (
      <div className='container-fluid'>
        <NotificationsRulesSettingsStepper stepIndex={0} />
        <PageSubTitle title={this.formatMessage(messages.generalSettings)} />

        <Paper style={{ padding: '20px' }}>
          <Grid container spacing={3}>
            <Grid container item xs={12}>
              <Grid container item spacing={3} xs={12}>
                <Grid item md={4} xs={12}>
                  <TextField
                    errorText={nameInputErrorText}
                    floatingLabelText={this.formatMessage(messages.nameOfNotification)}
                    fullWidth
                    name='name'
                    onChange={this.handleNameInputChange}
                    value={nameInputValue}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    errorText={descriptionInputErrorText}
                    floatingLabelText={this.formatMessage(messages.description)}
                    fullWidth
                    name='description'
                    onChange={this.handleDescriptionInputChange}
                    value={descriptionInputValue}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={enabledValue} onChange={this.handleEnableChange} />}
                  label={this.formatMessage(messages.enabled)}
                  style={{
                    color: 'rgba(0, 0, 0, 0.3)',
                    top: 38,
                    fontSize: 16
                  }}
                />
                <p className='small' style={{ margin: '0 0 5px 0' }}>
                  {this.formatMessage(messages.ifEnabledIsntChecked)}
                </p>
              </Grid>
              <Grid container item xs={12}>
                <Grid item md={4}>
                  <NotificationCriticalitySelector
                    errorText={criticalityErrorText}
                    onChange={this.handleCriticalityChange}
                    selected={criticalityValue}
                  />
                </Grid>
                <Grid alignItems='center' container item md={8}>
                  {criticalityDescription !== '' ? (
                    <Grid item xs={12}>
                      <span style={{ marginRight: '8px' }}>{criticalityDescription}</span>
                      <i
                        className='zmdi zmdi-notifications'
                        style={{
                          color: criticalityColor,
                          fontSize: 26,
                          marginRight: '8px'
                        }}
                      />
                      <i
                        className='zmdi zmdi-pin'
                        style={{
                          color: criticalityColor,
                          fontSize: 26,
                          marginRight: '8px'
                        }}
                      />
                    </Grid>
                  ) : 
                    ''
                  }
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item md={4}>
                  <TextField
                    disabled
                    floatingLabelText={this.formatMessage(messages.group)}
                    fullWidth
                    name='group'
                    value={groupDescription}
                  />
                </Grid>
              </Grid>
            </Grid>

            {groupId !== null ? (
              <Grid container item xs={12}>
                <AssignMachinesTable
                  assignedDevices={assignedDevices}
                  assignedDevicesErrorText={assignedDevicesErrorText}
                  groupId={groupId}
                  onAssignedDevicesChange={this.handleAssignedDevicesChange}
                />
              </Grid>
            ) : 
              ''
            }

            <Grid container item justify='flex-end' xs={12}>
              <Link className='button-link' to={notificationsUrl}>
                <Button className='cancel-button' style={{ marginRight: 10 }}>
                  {this.formatMessage(messages.cancel)}
                </Button>
              </Link>
              <Button
                className='primary-action-button'
                disabled={isNextButtonDisabled}
                onClick={this.handleNextClick}
                style={{ minWidth: 100 }}
              >
                {this.formatMessage(messages.next)}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

GeneralSettingsForm.propTypes = {
  assignedDevices: PropTypes.array.isRequired,
  groupDescription: PropTypes.string,
  groupId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  notificationsUrl: PropTypes.string,
  onAssignedDevicesChange: PropTypes.func.isRequired,
  onSaveNewNotification: PropTypes.func.isRequired
}

GeneralSettingsForm.defaultProps = {
  groupDescription: '',
  groupId: '',
  notificationsUrl: ''
}

export default injectIntl(GeneralSettingsForm)
