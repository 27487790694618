import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'
import { utils } from 'ducks/groups'

import Actions from './Actions'

const mapStateToProps = state => ({
  canEditDevice: resolvePermissions(state, c.DEVICE_EDIT),
  canUseProductionTestMode: resolvePermissions(state, c.DEVICESIM_EDIT),
  canConsumeAccessPasses: utils.hasPermission(state, c.ACCESSPASS_CONSUME)
})

export default connect(mapStateToProps)(Actions)
