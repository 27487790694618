import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker } from '@material-ui/pickers'

import client from 'utils/http/client'
import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'

import { getAdaptedOneAdvancedSignalData, getAdaptedCurrentValuesData } from '../../apiAdapters'
import ServerErrorAlert from '../../components/alerts/ServerErrorAlert'
import AssignedDevicesCurrentValuesModal from '../../components/modals/AssignedDevicesCurrentValuesModal'
import { FREQUENCY_TYPES, MAINTENANCE_CONFIGURATION_MODES, TIME_UNITS } from '../../constants'
import { getFormattedInspectionUnit } from '../../helpers'
import messages from '../../messages'
import { alertStyles, iconButtonStyles, inputNumberStyles, paperStyles, tooltipStyles } from '../../styles'

const styles = {
  ...alertStyles,
  ...iconButtonStyles,
  ...inputNumberStyles,
  ...paperStyles,
  ...tooltipStyles,
  formControlLabelRoot: {
    marginBottom: 0,
    marginRight: 0
  }
}

class SignalSettingsStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdvancedSignalLoading: false,
      advancedSignalData: null,
      isCurrentValuesLoading: false,
      currentValuesData: [],
      currentValuesModalData: undefined,
      isApiStepFetchError: false
    }
  }

  componentDidMount() {
    const { mode } = this.props

    if (mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION) {
      this.fetchAdvancedSignalData()
    }

    this.fetchCurrentValues()
  }

  componentDidUpdate(prevProps) {
    const { advancedSignalConfigHashId } = this.props

    if (prevProps.advancedSignalConfigHashId !== advancedSignalConfigHashId) {
      this.fetchCurrentValues()
    }
  }

  fetchAdvancedSignalData = () => {
    const { advancedSignalHashId, groupId } = this.props

    this.setState({ isAdvancedSignalLoading: true, isApiStepFetchError: false })
    client
      .getOneMeasurement(groupId, advancedSignalHashId)
      .then(response => {
        const advancedSignalData = getAdaptedOneAdvancedSignalData(response.data)

        this.setState({ advancedSignalData })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
      })
      .finally(() => {
        this.setState({ isAdvancedSignalLoading: false })
      })
  }

  fetchCurrentValues = () => {
    const { advancedSignalConfigHashId, assignedDevicesEids, groupId } = this.props

    this.setState({ isCurrentValuesLoading: true, isApiStepFetchError: false })
    client
      .getMeasurementsDevices(groupId, advancedSignalConfigHashId)
      .then(response => {
        const allCurrentValuesData = getAdaptedCurrentValuesData(response.data)
        const currentValuesData = allCurrentValuesData.filter(item => assignedDevicesEids.includes(item.deviceEid))

        this.setState({ currentValuesData })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
      })
      .finally(() => {
        this.setState({ isCurrentValuesLoading: false })
      })
  }

  getSelectedAdvancedSignalData = () => {
    const { advancedSignalHashId, availableAdvancedSignals, mode } = this.props

    const { advancedSignalData } = this.state

    if (mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION) {
      return advancedSignalData
    } else {
      return availableAdvancedSignals.find(signal => signal.hashId === advancedSignalHashId)
    }
  }

  handleAdvancedSignalChange = (e, value) => {
    const { frequencyType, onFrequencyUnitChange, onAdvancedSignalChange } = this.props

    if (frequencyType === FREQUENCY_TYPES.VALUE_BASED) {
      onFrequencyUnitChange(value.unit)
    }

    onAdvancedSignalChange(value)
  }

  handleShowCurrentValuesClick = () => {
    const { initialValue } = this.props
    const { currentValuesData } = this.state

    const selectedAdvancedSignalData = this.getSelectedAdvancedSignalData()
    const modalData = {
      currentValuesData,
      initialValue,
      selectedAdvancedSignalData
    }

    this.setState({ currentValuesModalData: modalData })
  }

  handleCurrentValuesModalCloseClick = () => {
    this.setState({ currentValuesModalData: undefined })
  }

  handleFrequencyTypeChange = e => {
    const { advancedSignalUnit, onFrequencyTypeChange, onFrequencyUnitChange } = this.props

    const value = e.target.value

    if (value === FREQUENCY_TYPES.TIME_BASED) {
      onFrequencyUnitChange(TIME_UNITS.MONTHS)
    } else {
      onFrequencyUnitChange(advancedSignalUnit)
    }

    onFrequencyTypeChange(value)
  }

  handleInitialDateChange = value => {
    const { onInitialDateChange } = this.props

    onInitialDateChange(moment(value).valueOf())
  }

  handleInitialValueChange = e => {
    const { onInitialValueChange } = this.props

    const value = Math.floor(Number(e.target.value))

    onInitialValueChange(value)
  }

  handleFrequencyValueChange = e => {
    const { onFrequencyValueChange } = this.props

    const value = Math.floor(Number(e.target.value))

    onFrequencyValueChange(value)
  }

  handleTimeUnitChange = e => {
    const { onFrequencyUnitChange } = this.props

    const value = e.target.value

    onFrequencyUnitChange(value)
  }

  handleIsInspectionReschedulingEnabledChange = e => {
    const { onIsInspectionReschedulingEnabledChange } = this.props
    const value = e.target.checked

    onIsInspectionReschedulingEnabledChange(value)
  }

  render() {
    const {
      advancedSignalHashId,
      advancedSignalUnit,
      availableAdvancedSignals,
      classes,
      frequencyClarificationSentence,
      frequencyType,
      frequencyUnit,
      frequencyValue,
      initialDate,
      initialValue,
      intl,
      isApiFetchError,
      isInspectionReschedulingEnabled,
      mode,
      originalFrequencyUnit,
      originalFrequencyValue
    } = this.props
    const {
      currentValuesData,
      currentValuesModalData,
      isAdvancedSignalLoading,
      isApiStepFetchError,
      isCurrentValuesLoading
    } = this.state

    const isApiError = isApiFetchError || isApiStepFetchError

    const isAdvancedSignalSelected = Boolean(advancedSignalHashId)
    const selectedAdvancedSignalData = this.getSelectedAdvancedSignalData()

    const isCurrentValuesModalOpen = Boolean(currentValuesModalData)

    const isAdvancedSignalSelectorDisabled =
      isApiError || mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
    const isShowCurrentValuesButtonDisabled = isApiError
    const isFrequencyTypeSelectorDisabled =
      isApiError || !isAdvancedSignalSelected || mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
    const isInitialDateOrValueDisabled =
      isApiError || !isAdvancedSignalSelected || mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
    const isFrequencyValueDisabled = isApiError || !isAdvancedSignalSelected
    const isFrequencyUnitDisabled = isApiError || !isAdvancedSignalSelected

    const isAutomaticInspectionSchedulingAlertShown =
      !isCurrentValuesLoading &&
      frequencyType === FREQUENCY_TYPES.VALUE_BASED &&
      currentValuesData.some(item => item.currentValue >= initialValue)
    const isInspectionReschedulingOptionShown =
      mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION &&
      (frequencyValue !== originalFrequencyValue || frequencyUnit !== originalFrequencyUnit)

    return (
      <Grid item xs={12}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Grid container spacing={3}>
            {isApiError && (
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography component='h2' variant='h5'>
                {intl.formatMessage(messages.signalSettings)}
              </Typography>
            </Grid>
            <Grid alignContent='flex-start' container item md={6} spacing={3} xs={12}>
              <Grid item xs={12}>
                <Typography component='h3' variant='h6'>
                  {intl.formatMessage(messages.advancedSignal)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  disabled={isAdvancedSignalSelectorDisabled}
                  getOptionLabel={option => option.name || ''}
                  getOptionSelected={(option, value) => option.hashId === value.hashId}
                  onChange={this.handleAdvancedSignalChange}
                  options={availableAdvancedSignals}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: isAdvancedSignalLoading ? <CircularProgress size='1.5em' /> : null
                      }}
                      fullWidth
                      label={intl.formatMessage(messages.signal)}
                    />
                  )}
                  value={selectedAdvancedSignalData || null}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component='h3' variant='h6'>
                  {intl.formatMessage(messages.assignMachines)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component='span' style={{ display: 'inline-block', marginRight: 8 }} variant='body2'>
                  {intl.formatMessage(messages.currentValueAssignedMachines)}
                </Typography>
                <Button
                  disabled={isShowCurrentValuesButtonDisabled}
                  onClick={this.handleShowCurrentValuesClick}
                  variant='outlined'
                >
                  {intl.formatMessage(messages.show)}
                </Button>
              </Grid>
            </Grid>
            <Grid container item md={6} spacing={3} xs={12}>
              <Grid item xs={12}>
                <Typography component='h3' variant='h6'>
                  {intl.formatMessage(messages.frequencyParameters)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{intl.formatMessage(messages.frequencyType)}</InputLabel>
                  <Select
                    disabled={isFrequencyTypeSelectorDisabled}
                    onChange={this.handleFrequencyTypeChange}
                    value={frequencyType}
                  >
                    <MenuItem value={FREQUENCY_TYPES.TIME_BASED}>{intl.formatMessage(messages.timeBased)}</MenuItem>
                    <MenuItem value={FREQUENCY_TYPES.VALUE_BASED}>{intl.formatMessage(messages.valueBased)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {frequencyType === FREQUENCY_TYPES.TIME_BASED ? (
                  <DatePicker
                    autoOk
                    cancelLabel={intl.formatMessage(messages.cancel)}
                    disablePast={!isInitialDateOrValueDisabled}
                    disabled={isInitialDateOrValueDisabled}
                    fullWidth
                    label={intl.formatMessage(messages.initialDate)}
                    labelFunc={value => utcTimeToBrowserLocalShort(value)}
                    okLabel={intl.formatMessage(messages.ok)}
                    onChange={this.handleInitialDateChange}
                    value={initialDate}
                  />
                ) : (
                  <TextField
                    InputProps={{
                      classes: { root: classes.inputNumberRoot },
                      endAdornment: (
                        <InputAdornment position='end'>
                          {getFormattedInspectionUnit({
                            advancedSignalUnit
                          })}
                        </InputAdornment>
                      )
                    }}
                    disabled={isInitialDateOrValueDisabled}
                    fullWidth
                    inputProps={{
                      min: 0,
                      step: 1
                    }}
                    label={intl.formatMessage(messages.initialValue)}
                    onChange={this.handleInitialValueChange}
                    onWheel={e => e.target.blur()}
                    type='number'
                    value={initialValue}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <Grid alignItems='flex-end' container spacing={3} wrap='nowrap'>
                  <Grid item>
                    <Typography>{intl.formatMessage(messages.frequency)}</Typography>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                      InputProps={{
                        classes: { root: classes.inputNumberRoot }
                      }}
                      disabled={isFrequencyValueDisabled}
                      fullWidth
                      inputProps={{
                        min: 1,
                        step: 1
                      }}
                      onChange={this.handleFrequencyValueChange}
                      onWheel={e => e.target.blur()}
                      type='number'
                      value={frequencyValue}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {frequencyType === FREQUENCY_TYPES.TIME_BASED ? (
                  <FormControl fullWidth>
                    <Select
                      disabled={isFrequencyUnitDisabled}
                      onChange={this.handleTimeUnitChange}
                      value={frequencyUnit}
                    >
                      <MenuItem value={TIME_UNITS.DAYS}>{intl.formatMessage(messages.days)}</MenuItem>
                      <MenuItem value={TIME_UNITS.WEEKS}>{intl.formatMessage(messages.weeks)}</MenuItem>
                      <MenuItem value={TIME_UNITS.MONTHS}>{intl.formatMessage(messages.months)}</MenuItem>
                      <MenuItem value={TIME_UNITS.YEARS}>{intl.formatMessage(messages.years)}</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <TextField disabled fullWidth value={frequencyUnit} />
                )}
              </Grid>
              <Grid item xs={12}>
                {frequencyClarificationSentence}
              </Grid>
              {isInspectionReschedulingOptionShown && (
                <Grid item xs={12}>
                  <FormControlLabel
                    classes={{ root: classes.formControlLabelRoot }}
                    control={
                      <Checkbox
                        checked={isInspectionReschedulingEnabled}
                        onChange={this.handleIsInspectionReschedulingEnabledChange}
                      />
                    }
                    label={intl.formatMessage(messages.rescheduleActiveInspections)}
                  />
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={intl.formatMessage(messages.rescheduleActiveInspectionsClarification)}
                  >
                    <IconButton classes={{ root: classes.iconButtonRoot }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {isAutomaticInspectionSchedulingAlertShown && (
                <Grid item xs={12}>
                  <Alert severity='info'>
                    <AlertTitle classes={{ root: classes.alertTitle }}>
                      {intl.formatMessage(messages.automaticInspectionScheduling)}
                    </AlertTitle>
                    {intl.formatMessage(messages.machinesHaveGreaterValue)}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
        <AssignedDevicesCurrentValuesModal
          data={currentValuesModalData}
          isOpen={isCurrentValuesModalOpen}
          onCloseClick={this.handleCurrentValuesModalCloseClick}
        />
      </Grid>
    )
  }
}

SignalSettingsStep.propTypes = {
  advancedSignalConfigHashId: PropTypes.string.isRequired,
  advancedSignalHashId: PropTypes.string.isRequired,
  advancedSignalUnit: PropTypes.string.isRequired,
  assignedDevicesEids: PropTypes.array.isRequired,
  availableAdvancedSignals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  frequencyClarificationSentence: PropTypes.node.isRequired,
  frequencyType: PropTypes.string.isRequired,
  frequencyUnit: PropTypes.string.isRequired,
  frequencyValue: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  initialDate: PropTypes.number.isRequired,
  initialValue: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  isApiFetchError: PropTypes.bool.isRequired,
  isInspectionReschedulingEnabled: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([
    MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION,
    MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
  ]).isRequired,
  onAdvancedSignalChange: PropTypes.func.isRequired,
  onFrequencyTypeChange: PropTypes.func.isRequired,
  onFrequencyUnitChange: PropTypes.func.isRequired,
  onFrequencyValueChange: PropTypes.func.isRequired,
  onInitialDateChange: PropTypes.func.isRequired,
  onInitialValueChange: PropTypes.func.isRequired,
  onIsInspectionReschedulingEnabledChange: PropTypes.func.isRequired,
  originalFrequencyUnit: PropTypes.string.isRequired,
  originalFrequencyValue: PropTypes.number.isRequired
}

export default withStyles(styles)(injectIntl(SignalSettingsStep))
