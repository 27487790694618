import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import csNode from 'modules/csNode'
import * as actions from 'modules/geofences/actions'
import * as c from 'modules/geofences/constants'
import * as utils from 'modules/geofences/reducer'

import AssignMachinesStep from './AssignMachinesStep'

const mapStateToProps = state => ({
  isAssignDevicesButtonDisabled: utils.isAssignDevicesButtonDisabled(state[c.NAME]),
  ruleInstanceHashId: utils.getRuleInstanceHashId(state[c.NAME])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRuleInstance: actions.getRuleInstance,
      updateRuleInstance: actions.updateRuleInstance,
      getDynamicCSNodes: csNode.actions.fetchDynamicCSNodes,
      fetchCSNodesDetails: csNode.actions.fetchCSNodesDetails
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignMachinesStep))
