import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { DatePicker } from '@material-ui/pickers'

import client from 'utils/http/client'
import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'

import { getMappedInspectionState } from '../../../apiMappings'
import { FREQUENCY_TYPES, INSPECTION_STATES } from '../../../constants'
import { getFormattedFrequencyUnit, getFormattedInspectionUnit } from '../../../helpers'
import messages from '../../../messages'
import { dialogStyles, iconButtonStyles, inputNumberStyles, paperStyles } from '../../../styles'
import ServerErrorAlert from '../../alerts/ServerErrorAlert'

const styles = {
  ...dialogStyles,
  ...iconButtonStyles,
  ...inputNumberStyles,
  ...paperStyles,
  filledInput: {
    '&&': {
      backgroundColor: '#EDF7ED',
      borderRadius: 0
    },
    '&:hover': {
      backgroundColor: '#E0F1DF'
    }
  }
}

class ResolveInspectionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inspectionDate: 0,
      inspectionValue: 0,
      nextInspectionDate: 0,
      nextInspectionValue: 0,
      inspectedBy: '',
      notes: '',
      isResolveInspectionLoading: false,
      isApiError: false
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      this.setDefaultValues()
    }
  }

  getNextInspectionDate = inspectionDate => {
    const { data } = this.props

    return moment(inspectionDate).add(Number(data.frequencyValue), data.frequencyUnit).valueOf()
  }

  getNextInspectionValue = inspectionValue => {
    const { data } = this.props

    return Number(inspectionValue) + Number(data.frequencyValue)
  }

  setDefaultValues = () => {
    const { data } = this.props
    const todayTimestamp = moment().utc().startOf('day').valueOf()

    this.setState({
      inspectionDate: todayTimestamp,
      inspectionValue: Number(data.currentValue),
      nextInspectionDate: this.getNextInspectionDate(todayTimestamp),
      nextInspectionValue: this.getNextInspectionValue(data.currentValue),
      inspectedBy: '',
      notes: ''
    })
  }

  handleInspectionDateChange = value => {
    const inspectionDate = moment(value).valueOf()
    const nextInspectionDate = this.getNextInspectionDate(inspectionDate)
    this.setState({ inspectionDate, nextInspectionDate })
  }

  handleInspectionValueChange = e => {
    const rawValue = Math.floor(Number(e.target.value))
    const value = rawValue >= 0 ? rawValue : 0
    const nextInspectionValue = this.getNextInspectionValue(value)
    this.setState({ inspectionValue: value, nextInspectionValue })
  }

  handleNextInspectionDateChange = value => {
    const nextInspectionDate = moment(value).valueOf()
    this.setState({ nextInspectionDate })
  }

  handleNextInspectionValueChange = e => {
    const rawValue = Math.floor(Number(e.target.value))
    const value = rawValue >= 0 ? rawValue : 0
    this.setState({ nextInspectionValue: value })
  }

  handleInspectedByChange = e => {
    const value = e.target.value
    this.setState({ inspectedBy: value })
  }

  handleNotesChange = e => {
    const value = e.target.value
    this.setState({ notes: value })
  }

  handleResolveClick = () => {
    const { data, groupId, onResolveClick } = this.props
    const { inspectionDate, inspectionValue, nextInspectionDate, nextInspectionValue, inspectedBy, notes } = this.state

    const { maintenanceId } = data

    const resolvedInspectionData = {
      hashId: data.hashId,
      inspectionResolutionDate: moment(inspectionDate).valueOf(),
      notes,
      resolutionValue: inspectionValue,
      resolvedBy: inspectedBy,
      status: getMappedInspectionState(INSPECTION_STATES.RESOLVED, true)
    }

    const newInspectionData = {
      device: {
        hashId: data.deviceId
      },
      ...data.frequencyType === FREQUENCY_TYPES.TIME_BASED
        ? {
          serviceThresholdDate: moment(nextInspectionDate).valueOf()
        }
        : {
          serviceThreshold: nextInspectionValue
        }
    }

    this.setState({ isResolveInspectionLoading: true, isApiError: false })
    client
      .updateOneMaintenceInspections({ groupId, maintenanceId, inspectionData: resolvedInspectionData })
      .then(() => client.newOneMaintenanceInpection({ groupId, maintenanceId, inspectionData: newInspectionData }))
      .then(() => {
        onResolveClick()
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isResolveInspectionLoading: false })
      })
  }

  render() {
    const { classes, data, intl, isOpen, onCloseClick } = this.props
    const {
      inspectedBy,
      inspectionDate,
      inspectionValue,
      isApiError,
      isResolveInspectionLoading,
      nextInspectionDate,
      nextInspectionValue,
      notes
    } = this.state

    const isInspectionValueError = inspectionValue > data.currentValue
    const isNextInspectionValueError =
      data.frequencyType === FREQUENCY_TYPES.VALUE_BASED && nextInspectionValue < data.currentValue
    const isNextInspectionDateError =
      data.frequencyType === FREQUENCY_TYPES.TIME_BASED && nextInspectionDate < moment().utc().startOf('day').valueOf()
    const isResolveButtonDisabled =
      isInspectionValueError || isNextInspectionValueError || isNextInspectionDateError || isResolveInspectionLoading
    const isCancelButtonDisabled = isResolveInspectionLoading

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.paperRoot } }}
        fullWidth
        maxWidth='lg'
        onClose={isCancelButtonDisabled ? undefined : onCloseClick}
        open={isOpen}
        scroll='body'
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.resolveInspection)}
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <IconButton
                classes={{ root: classes.iconButtonRoot }}
                disabled={isCancelButtonDisabled}
                onClick={onCloseClick}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isOpen && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid classes={{ root: classes.dialogContentGridContainer }} container>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          fullWidth
                          label={intl.formatMessage(messages.deviceName)}
                          value={data.deviceName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          fullWidth
                          label={intl.formatMessage(messages.advancedSignal)}
                          value={data.advancedSignalName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                {getFormattedFrequencyUnit({
                                  intl,
                                  frequencyType: data.frequencyType,
                                  frequencyValue: data.frequencyValue,
                                  frequencyUnit: data.frequencyUnit
                                })}
                              </InputAdornment>
                            )
                          }}
                          disabled
                          fullWidth
                          label={intl.formatMessage(messages.frequency)}
                          value={data.frequencyValue}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePicker
                          autoOk
                          cancelLabel={intl.formatMessage(messages.cancel)}
                          disableFuture
                          disabled={isResolveInspectionLoading}
                          fullWidth
                          label={intl.formatMessage(messages.inspectionDate)}
                          labelFunc={value => utcTimeToBrowserLocalShort(value)}
                          okLabel={intl.formatMessage(messages.ok)}
                          onChange={this.handleInspectionDateChange}
                          value={inspectionDate}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            classes: { root: classes.inputNumberRoot },
                            endAdornment: (
                              <InputAdornment position='end'>
                                {getFormattedInspectionUnit({
                                  advancedSignalUnit: data.advancedSignalUnit
                                })}
                              </InputAdornment>
                            )
                          }}
                          disabled={isResolveInspectionLoading}
                          fullWidth
                          helperText={
                            isInspectionValueError ? intl.formatMessage(messages.resolveInspectionValueError) : ''
                          }
                          inputProps={{
                            min: 0,
                            step: 1,
                            max: data.currentValue
                          }}
                          label={intl.formatMessage(messages.inspectionValue)}
                          onChange={this.handleInspectionValueChange}
                          onWheel={e => e.target.blur()}
                          type='number'
                          value={inspectionValue}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {data.frequencyType === FREQUENCY_TYPES.TIME_BASED ? (
                          <DatePicker
                            TextFieldComponent={textFieldProps => (
                              <TextField
                                {...textFieldProps}
                                InputProps={{ ...textFieldProps.InputProps, classes: { root: classes.filledInput } }}
                                error={isInspectionValueError}
                                label={intl.formatMessage(messages.nextInspection)}
                                variant='filled'
                              />
                            )}
                            autoOk
                            cancelLabel={intl.formatMessage(messages.cancel)}
                            disablePast
                            disabled={isResolveInspectionLoading}
                            fullWidth
                            labelFunc={value => utcTimeToBrowserLocalShort(value)}
                            okLabel={intl.formatMessage(messages.ok)}
                            onChange={this.handleNextInspectionDateChange}
                            value={nextInspectionDate}
                          />
                        ) : (
                          <TextField
                            InputProps={{
                              classes: { root: classes.filledInput + ' ' + classes.inputNumberRoot },
                              endAdornment: (
                                <InputAdornment position='end'>
                                  {getFormattedInspectionUnit({
                                    advancedSignalUnit: data.advancedSignalUnit
                                  })}
                                </InputAdornment>
                              )
                            }}
                            disabled={isResolveInspectionLoading}
                            error={isNextInspectionValueError}
                            fullWidth
                            helperText={
                              isNextInspectionValueError
                                ? intl.formatMessage(messages.resolveInspectionNextValueError)
                                : ''
                            }
                            inputProps={{
                              min: data.currentValue,
                              step: 1
                            }}
                            label={intl.formatMessage(messages.nextInspection)}
                            onChange={this.handleNextInspectionValueChange}
                            onWheel={e => e.target.blur()}
                            type='number'
                            value={nextInspectionValue}
                            variant='filled'
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {intl.formatMessage(messages.nextInspectionClarification, {
                          frequencyType: data.frequencyType
                        })}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          disabled={isResolveInspectionLoading}
                          fullWidth
                          label={intl.formatMessage(messages.inspectedBy)}
                          onChange={this.handleInspectedByChange}
                          value={inspectedBy}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={isResolveInspectionLoading}
                          fullWidth
                          label={intl.formatMessage(messages.notes)}
                          multiline
                          onChange={this.handleNotesChange}
                          rows={13}
                          value={notes}
                          variant='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Grid container spacing={3}>
            {isApiError && (
              <Grid item xs={6}>
                <ServerErrorAlert />
              </Grid>
            )}
            <Grid alignItems='flex-end' container item justifyContent='flex-end' xs={isApiError ? 6 : 12}>
              <Button disabled={isCancelButtonDisabled} onClick={onCloseClick}>
                {intl.formatMessage(messages.cancel)}
              </Button>
              <Button
                className='primary-action-button'
                disabled={isResolveButtonDisabled}
                onClick={this.handleResolveClick}
              >
                {isResolveInspectionLoading ? <CircularProgress size={20} /> : intl.formatMessage(messages.resolve)}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

ResolveInspectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onResolveClick: PropTypes.func.isRequired
}

ResolveInspectionModal.defaultProps = {
  data: {}
}

export default withStyles(styles)(injectIntl(ResolveInspectionModal))
