import { defineMessages } from 'react-intl'
 
const messages = defineMessages({
  search: {
    id: 'TreeList.search',
    description: 'Search input placeholder || text field hint text',
    defaultMessage: 'Search'
  },
})
 
export default messages