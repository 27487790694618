import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import PageTitle from 'components/PageTitle'

const ViewConfigurationFormTitle = ({ configurationsUrl, title }) => {
  return <PageTitle back={configurationsUrl} title={title} />
}

ViewConfigurationFormTitle.propTypes = {
  configurationsUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default withRouter(ViewConfigurationFormTitle)
