import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getDevicesData } from 'ducks/groupDashboards'
import { actions as websocket, utils } from 'ducks/websocket'

import Gauge from './Gauge'
import GaugeSettingsUnconnected from './GaugeSettings'
import GaugeTemplate from './GaugeTemplate'

import { actions as hactions } from 'utils/http'

import csNode from 'modules/csNode'

const mapStateToProps = state => {
  return {
    devicesData: getDevicesData(state),
    getNodeCredentials: id => csNode.utils.getRealTimeCredentials(state)[id],
    isSubscribedTo: (eid, topic) => utils.isSubscribedTo(state, eid, topic)
  }
}

/* eslint-disable no-shadow */
const mapDispatchToProps = dispatch => {
  return {
    subscribeWidgetToWS: (topics, eid, credentials) => dispatch(websocket.subscribeGeneric(topics, eid, credentials)),
    unsubscribeWidgetFromWS: (topic, eid) => dispatch(websocket.unsubscribe(topic, eid)),
    ...bindActionCreators(
      {
        getAzureToken: (id, eid) => dispatch => {
          const { promise } = dispatch(hactions.plus1Connect.getAzureNodeToken(id, eid))
          return promise
        }
      },
      dispatch
    )
  }
}
/* eslint-enable */

export default connect(mapStateToProps, mapDispatchToProps)(Gauge)

const GaugeSettings = connect(mapStateToProps, mapDispatchToProps)(GaugeSettingsUnconnected)
export { GaugeSettings, GaugeTemplate }
