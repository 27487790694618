import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { utils } from 'ducks/groups'

import Home from './Home'

const mapStateToProps = state => ({
  canReadGroups: resolvePermissions(state, c.GROUPS_READ),
  canCreateGroups: resolvePermissions(state, c.GROUPS_CREATE),
  canEditGroups: resolvePermissions(state, c.GROUPS_EDIT),
  canReadDevices: resolvePermissions(state, c.DEVICE_READ),
  canShareDevices: utils.hasPermission(state, c.RESOURCESHARING_ALLOW)
})

export default connect(mapStateToProps)(Home)

export { GroupSelect } from './NewGroup'
export { getGroupsUrl } from './urls'
export { getParentGroup, processGroupsHierarchyToSelect } from './utils'
