import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noDataAvailable: {
    id: 'BasicSignalsHistoric.noDataAvailable',
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  loadingDataFromServer: {
    id: 'BasicSignalsHistoric.loadingDataFromServer',
    description: 'Loading data from server...',
    defaultMessage: 'Loading data from server...'
  },
  refreshData: {
    id: 'BasicSignalsHistoric.refreshData',
    description: 'Refresh data',
    defaultMessage: 'Refresh data'
  },
  notSupportedMachine: {
    id: 'BasicSignalsHistoric.notSupportedMachine',
    description: 'Not supported machine',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: 'BasicSignalsHistoric.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  }
})

export default messages
