import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import PageTitle from 'components/PageTitle'
import { getAdaptedUserDetailsData } from 'components/SmsServiceConsentDialog/apiAdapters'

import ConsentStatusChip from '../components/ConsentStatusChip'
import ServerErrorAlert from '../components/ServerErrorAlert'
import messages from '../messages'
import { paperStyles } from '../styles'

const styles = {
  ...paperStyles
}

class SmsServiceConsent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isApiError: false,
      consentState: null
    }
  }

  componentDidMount() {
    this.fetchUserData()
  }

  componentDidUpdate(prevProps) {
    const { isSmsServiceConsentDialogOpen } = this.props

    if (!isSmsServiceConsentDialogOpen && prevProps.isSmsServiceConsentDialogOpen) {
      this.fetchUserData()
    }
  }

  fetchUserData = () => {
    const { fetchUserDetails } = this.props

    this.setState({ isLoading: true, isApiError: false })
    fetchUserDetails()
      .then(response => {
        const userData = getAdaptedUserDetailsData(response.data)
        this.setState({ consentState: userData?.consentState })
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handleReviewConsentClick = () => {
    const { openSmsServiceConsentDialog } = this.props
    openSmsServiceConsentDialog()
  }

  render() {
    const { classes, intl } = this.props
    const { consentState, isApiError, isLoading } = this.state

    const isReviewConsentButtonDisabled = isApiError

    return (
      <div style={{ margin: '20px 25px 20px 20px' }}>
        <div className='container-fluid'>
          <PageTitle title={intl.formatMessage(messages.smsServiceConsent)} />
        </div>
        <div className='row' style={{ margin: '20px 0 0 0' }}>
          <div className='container-fluid'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper classes={{ root: classes.paperRoot }} style={{ paddingBottom: 100 }}>
                  <Grid container spacing={3}>
                    {isApiError && (
                      <Grid item xs={12}>
                        <ServerErrorAlert />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography component='h2' variant='h5'>
                        {intl.formatMessage(messages.smsService)}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} sm={6} style={{ display: 'flex' }} xs={12}>
                      <Grid container direction='column' spacing={3} wrap='nowrap'>
                        <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                          <Grid
                            container
                            justifyContent='space-between'
                            style={{ borderBottom: '1px solid #D6D6D6', paddingBottom: 8 }}
                          >
                            <Grid item>
                              <Typography component='h3' variant='h6'>
                                {intl.formatMessage(messages.consentStatus)}
                              </Typography>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                              {isLoading ? <CircularProgress size={20} /> : <ConsentStatusChip status={consentState} />}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }} xs={12}>
                          <Grid alignContent='flex-start' container direction='column' spacing={1} wrap='nowrap'>
                            <Grid item>
                              <Button
                                className='primary-action-button'
                                disabled={isReviewConsentButtonDisabled}
                                onClick={this.handleReviewConsentClick}
                              >
                                {intl.formatMessage(messages.reviewConsent)}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

SmsServiceConsent.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isSmsServiceConsentDialogOpen: PropTypes.bool.isRequired,
  openSmsServiceConsentDialog: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(SmsServiceConsent))
