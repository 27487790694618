import React from 'react'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

const NewGroupFormTitle = ({ groupsUrl, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)

  return <PageTitle title={title} back={groupsUrl} /*button={button}*/ />
}

export default injectIntl(NewGroupFormTitle)
