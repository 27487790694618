export const DEVICE_TYPES = {
  CS10: 'CS10',
  CS100: 'CS100',
  CS500: 'CS500'
}

export const FREQUENCY_TYPES = {
  TIME_BASED: 'TIME_BASED',
  VALUE_BASED: 'VALUE_BASED'
}

export const INSPECTION_STATES = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED'
}

export const MAINTENANCE_CONFIGURATION_MODES = {
  NEW_MAINTENANCE_CONFIGURATION: 'NEW_MAINTENANCE_CONFIGURATION',
  EDIT_MAINTENANCE_CONFIGURATION: 'EDIT_MAINTENANCE_CONFIGURATION'
}

export const MAINTENANCE_CONFIGURATION_STEPS = {
  GENERAL_SETTINGS: 'GENERAL_SETTINGS',
  MACHINES_SETTINGS: 'MACHINES_SETTINGS',
  SIGNAL_SETTINGS: 'SIGNAL_SETTINGS',
  NOTIFICATIONS_SETTINGS: 'NOTIFICATIONS_SETTINGS',
  SUMMARY: 'SUMMARY'
}

export const MAINTENANCE_CONFIGURATION_SUB_STEPS = {
  MACHINES_SETTINGS_EDIT: 'MACHINES_SETTINGS_EDIT',
  NOTIFICATIONS_SETTINGS_EDIT: 'NOTIFICATIONS_SETTINGS_EDIT'
}

export const NOTIFICATION_TYPES = {
  BELL: 'BELL',
  EMAIL: 'EMAIL',
  PUSH: 'PUSH',
  SMS: 'SMS'
}

export const SEVERITY_LEVELS = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW'
}

export const SMS_SERVICE_STATES = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE'
}

export const TIME_UNITS = {
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS'
}
