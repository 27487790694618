import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addNewDashboard: {
    id: 'GroupDashboards.addNewDashboard',
    description: 'Add new dashboard',
    defaultMessage: 'Add new dashboard'
  },
  groupDashboards: {
    id: 'GroupDashboards.groupDashboards',
    description: 'Group Dashboards',
    defaultMessage: 'Group Dashboards'
  },
  groupDashboardsAcronym: {
    id: 'GroupDashboards.groupDashboardsAcronym',
    description: 'Group Dashboards Acronym',
    defaultMessage: 'GD'
  }
})

export default messages
