import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils as groupUtils } from 'ducks/groups'

import UpdateFirmware from './UpdateFirmware'

import * as c from 'utils/constants'
import { actions as hactions } from 'utils/http'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = state => ({
  canApplyFirmware: resolvePermissions(state, c.FIRMWARE_EDIT),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      // eslint-disable-next-line no-shadow
      getAvailableFOTA: groupId => dispatch => {
        const { promise } = dispatch(hactions.plus1Connect.getAvailableFOTA(groupId))
        return promise.then(({ payload }) => {
          return payload
        })
      }
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFirmware)
