import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as reports from 'modules/reports/actions'
import * as reportsUtils from 'modules/reports/reducer'
import EditReportChartArea from './EditReportChartArea'
import { utils as template } from 'ducks/template'

const mapStateToProps = (state, { colors }) => ({
  chartData: reportsUtils.getFormatedChartData(state, colors),
  isSidebarCollapsed: template.isCollapsed(state),
  queryStatus: reportsUtils.getQueryStatus(state)
})

const mapDispatchToProps = dispatch => ({
  setChartQueryType: (chartQueryType, minRequestTimestamp, maxRequestTimestamp) =>
    dispatch(reports.setChartQueryType(chartQueryType, minRequestTimestamp, maxRequestTimestamp))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditReportChartArea))
