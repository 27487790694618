import { defineMessages } from 'react-intl'

const namespace = 'BasicSignalsHistoric'

const messages = defineMessages({
  noDataAvailable: {
    id: `${namespace}.noDataAvailable`,
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  loadingDataFromServer: {
    id: `${namespace}.loadingDataFromServer`,
    description: 'Loading data from server...',
    defaultMessage: 'Loading data from server...'
  },
  refreshData: {
    id: `${namespace}.refreshData`,
    description: 'Refresh data',
    defaultMessage: 'Refresh data'
  },
  notSupportedMachine: {
    id: `${namespace}.notSupportedMachine`,
    description: 'Not supported machine',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  }
})

export default messages
