const GROUP_ID = 'group_id'

const getSessionGroupId = () => sessionStorage.getItem(GROUP_ID)

const getLastGroupId = () => localStorage.getItem(GROUP_ID)

const cacheSessionGroupId = groupId => {
  sessionStorage.setItem(GROUP_ID, groupId)
}

const cacheLastGroupId = groupId => {
  localStorage.setItem(GROUP_ID, groupId)
}

export { getSessionGroupId, getLastGroupId, cacheSessionGroupId, cacheLastGroupId }
