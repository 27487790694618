import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ShareDataPlans.title',
    description: 'Digital Service Plans sharing dialog title',
    defaultMessage: 'Share Digital Service Plans'
  },
  selectTheGroup: {
    id: 'ShareDataPlans.selectTheGroup',
    description: 'Select the group label',
    defaultMessage: 'Group'
  },
  sharingMode: {
    id: 'ShareDataPlans.sharingMode',
    description: 'Sharing mode dropdown select title',
    defaultMessage: 'Sharing mode'
  },
  shareAll: {
    id: 'ShareDataPlans.shareAll',
    description: 'Share all the Digital Service Plans option label',
    defaultMessage: 'Share all the Digital Service Plans'
  },
  selectDataPlans: {
    id: 'ShareDataPlans.selectDataPlans',
    description: 'Select Digital Service Plans to share option label',
    defaultMessage: 'Digital Service Plans to share'
  },
  loadingDataPlansAutocomplete: {
    id: 'ShareDataPlans.loadingDataPlansAutocomplete',
    description: 'Loading Digital Service Plans autocomplete',
    defaultMessage: 'Loading...'
  },
  shareByType: {
    id: 'ShareDataPlans.shareByType',
    description: 'Share by Digital Service Plan type option label',
    defaultMessage: 'Share Digital Service Plans by type'
  },
  dataPlanType: {
    id: 'ShareDataPlans.dataPlanType',
    description: 'Device type dropdown select title',
    defaultMessage: 'Digital Service Plan type'
  },
  dataPlanCs100: {
    id: 'ShareDataPlans.dataPlanCs100',
    description: 'CS100 Digital Service Plan option label',
    defaultMessage: 'CS100 Digital Service Plan'
  },
  dataPlanCs500: {
    id: 'ShareDataPlans.dataPlanCs500',
    description: 'CS500 dataplan option label',
    defaultMessage: 'CS500 Digital Service Plan'
  },
  selectNumberOfCs100DataPlans: {
    id: 'ShareDataPlans.selectNumberOfCs100DataPlans',
    description: 'Number of CS100 Digital Service Plans to share',
    defaultMessage: 'Number of CS100 Digital Service Plans to share'
  },
  selectNumberOfCs500DataPlans: {
    id: 'ShareDataPlans.selectNumberOfCs500DataPlans',
    description: 'Number of CS500 Digital Service Plans to share',
    defaultMessage: 'Number of CS500 Digital Service Plans to share'
  },
  cancel: {
    id: 'ShareDataPlans.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  share: {
    id: 'ShareDataPlans.share',
    description: 'Share button label',
    defaultMessage: 'Share'
  },
  errorDataPlanShare: {
    id: 'ShareDataPlans.errorDataPlanShare',
    description: 'Digital Service Plans sharing error message',
    defaultMessage: 'There was an error sharing Digital Service Plans'
  },
  thisFieldIsRequired: {
    id: 'ShareDataPlans.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  invalidNumberOfDataPlans: {
    id: 'ShareDataPlans.invalidNumberOfDataPlans',
    description: 'Invalid number of Digital Service Plans',
    defaultMessage: 'Invalid number of Digital Service Plans'
  },
  youExceededTheMaximum: {
    id: 'ShareDataPlans.youExceededTheMaximum',
    description: 'You exceeded the amount of available Digital Service Plans',
    defaultMessage: 'You exceeded the amount of available Digital Service Plans'
  },
  allTheDataPlansAlreadyShared: {
    id: 'ShareDataPlans.allTheDataPlansAlreadyShared',
    description: 'All the Digital Service Plans were already shared with target group',
    defaultMessage: 'All the Digital Service Plans were already shared with {groupName} group'
  },
  oneYearServicePlan: {
    id: 'DataPlans.oneYearServicePlan',
    description: '1 year Digital Service Plan',
    defaultMessage: '1 year'
  },
  xYearsServicePlan: {
    id: 'DataPlans.xYearsServicePlan',
    description: 'X years Digital Service Plan',
    defaultMessage: '{number} years'
  }
})

export default messages