const MapTemplate = {
  id: '',
  width: 500,
  height: 300,
  x: 20,
  y: 20,
  minHeight: undefined,
  minWidth: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'map',
    params: {}
  }
}
export default MapTemplate
