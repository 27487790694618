import React from 'react'
import { injectIntl } from 'react-intl'


import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import Alert from 'components/Alert'

import messages from './messages'

const NoDashboard = ({ intl, actionFunction }) => {
  const { formatMessage } = intl

  return (
    <div className='content-container' id='content' style={{ width: '100%' }}>
      <div className='col-md-12' style={{ padding: '30px' }}>
        <Alert
          alertType='info'
          messageText={[
            formatMessage(messages.clickOnToCreateTheFirstOne, {
              addDashboard: <b>{formatMessage(messages.addDashboard)}</b>
            })
          ]}
          messageTitle={formatMessage(messages.noDashboardAvailable)}
          showAlert
        />
        <Button onClick={actionFunction} variant='contained'>
          <Icon className='zmdi zmdi-chevron-left' />
          {formatMessage(messages.backToMachines)}
        </Button>
      </div>
    </div>
  )
}

export default injectIntl(NoDashboard)
