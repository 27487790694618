export const predefinedRoles = [
  {
    name: 'Administrator',
    permissionNames: [
      'accessPass.consume',
      'accessPass.share',
      'charts.create',
      'charts.delete',
      'charts.edit',
      'charts.read',
      'dataplan.apply',
      'dataplan.read',
      'dataplan.share',
      'device.edit',
      'device.read',
      'deviceConfiguration.create',
      'deviceConfiguration.delete',
      'deviceConfiguration.edit',
      'deviceConfiguration.read',
      'deviceDashboard.create',
      'deviceDashboard.delete',
      'deviceDashboard.edit',
      'deviceDashboard.read',
      'deviceSim.edit',
      'eventsHistory.edit',
      'eventsHistory.view',
      'firmware.edit',
      'firmware.read',
      'geoFence.create',
      'geoFence.delete',
      'geoFence.edit',
      'geoFence.read',
      'geoTracking.edit',
      'geoTracking.view',
      'groupDashboard.create',
      'groupDashboard.delete',
      'groupDashboard.edit',
      'groupDashboard.read',
      'groupRole.create',
      'groupRole.delete',
      'groupRole.edit',
      'groupRole.read',
      'groups.create',
      'groups.delete',
      'groups.edit',
      'groups.read',
      'map.read',
      'nodeDetails.view',
      'notification.edit',
      'notification.read',
      'resourceSharing.allow',
      'rule.create',
      'rule.delete',
      'rule.edit',
      'rule.read',
      'schedule.create',
      'schedule.delete',
      'schedule.edit',
      'schedule.read',
      'user.create',
      'user.edit',
      'user.read'
    ]
  },
  {
    name: 'Configuration Manager',
    permissionNames: [
      'deviceConfiguration.read',
      'device.edit',
      'deviceConfiguration.delete',
      'deviceConfiguration.edit',
      'deviceConfiguration.create',
      'nodeDetails.view',
      'firmware.edit',
      'firmware.read',
      'device.read'
    ]
  },
  {
    name: 'Configuration Editor',
    permissionNames: [
      'deviceConfiguration.create',
      'deviceConfiguration.delete',
      'deviceConfiguration.read',
      'deviceConfiguration.edit',
      'device.edit'
    ]
  },
  {
    name: 'Service Plan Manager',
    permissionNames: [
      'dataplan.apply',
      'dataplan.read',
      'dataplan.share',
      'device.edit',
      'device.read',
      'resourceSharing.allow'
    ]
  }
]
