import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'


import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { generateDeviceData } from 'utils/deviceDataGenerator'



import messages from './messages'

const styles = theme => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  devicesButton: {
    marginBottom: 15
  }
})

class MapSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const devicesData = props.devicesData[props.eid] || {
      staticData: [],
      dinamicDataId: '',
      dinamicData: []
    }

    this.state = {
      configurationLoading: false,
      deviceEid: [props.eid],
      deviceEidError: '',
      devicesButtonTextKey: 'changeMachine',
      devicesTableDisplayed: false,
      node: devicesData,
      originalDevice: ''
    }
  }

  changeSelectedDevice = device => {
    this.setState({
      deviceEid: [device.EID],
      originalDevice: device,
      node: generateDeviceData(device),
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        configurationLoading: false
      }
    })
  }

  changeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (!error) {
      const data = {}

      const propsNode = this.props.devicesData[this.state.deviceEid[0]]
      const deviceInfo = propsNode ? '' : this.state.node

      this.props.saveSettings(data, this.state.deviceEid, deviceInfo)
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  render() {
    const { classes } = this.props
    const selectedDeviceEid = this.state.deviceEid.length > 0 ? this.state.deviceEid[0] : ''

    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.closeSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.mapWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>
          <Button
            className='primary-action-button'
            classes={{ root: classes.devicesButton }}
            onClick={this.changeDevicesTableDisplay}
          >
            {this.formatMessage(messages[this.state.devicesButtonTextKey])}
          </Button>
          {this.state.devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button
            className='primary-action-button'
            disabled={this.state.configurationLoading}
            onClick={this.saveSettings}
          >
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

MapSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(MapSettings))
