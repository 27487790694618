import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'

import UsersTable from './UsersTable'
import UsersTableTitle from './UsersTableTitle'

import { AddUserModal } from '../Modals'

class Users extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      open: false
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  openDialog = open => {
    this.setState({
      open
    })
  }

  renderTablecontent = () => {
    if (this.state.alertMessages) {
      return (
        <div className='container-fluid'>
          <Alert
            alertType={this.state.alertMessagesType}
            closeFunction={this.closeAlert}
            messageText={this.state.alertMessagesText}
            messageTitle={this.state.alertMessagesTitle}
            showAlert={this.state.alertMessages}
          />
        </div>
      )
    } else {
      return (
        <UsersTable
          canDeleteUser={this.props.canDeleteUser}
          canEditUser={this.props.canEditUser}
          canReadRoles={this.props.canReadRoles}
          changeGroup={this.props.changeGroup}
          deleteUser={this.props.deleteUser}
          getDetailedUsers={this.props.getDetailedUsers}
          getUsers={this.props.getUsers}
          selectedGroup={this.props.selectedGroup}
          user={this.props.user}
          usersUrl={this.props.usersUrl}
        />
      )
    }
  }

  render() {
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <UsersTableTitle
              addUser={() => {
                this.openDialog(true)
              }}
              canAddUser={this.props.canAddUser && this.props.canReadRoles}
              newUserUrl={this.props.newUserUrl}
              selectedGroup={this.props.selectedGroup}
            />
          </div>

          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>{this.renderTablecontent()}</div>
          </div>
        </div>
        {this.props.canAddUser && (
          <AddUserModal
            closeDialog={() => {
              this.openDialog(false)
            }}
            groupId={this.props.selectedGroup.id}
            open={this.state.open}
          />
        )}
      </div>
    )
  }
}

Users.propTypes = {
  canAddUser: PropTypes.bool.isRequired,
  canDeleteUser: PropTypes.bool.isRequired,
  canEditUser: PropTypes.bool.isRequired,
  canReadRoles: PropTypes.bool.isRequired,
  changeGroup: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  getDetailedUsers: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  newUserUrl: PropTypes.string.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired
}

export default Users
