import { defineMessages } from 'react-intl'

const messages = defineMessages({
  exitDashboard: {
    id: 'ExitDashboardEdition.exitDashboard',
    description: 'Exit',
    defaultMessage: 'EXIT'
  },
  allModificationsWillBeLost: {
    id: 'ExitDashboardEdition.allModificationsWillBeLost',
    description: 'All modifications will be lost.',
    defaultMessage: 'All modifications will be lost.'
  },
  cancel: {
    id: 'ExitDashboardEdition.cancel',
    description: 'Cancel button text',
    defaultMessage: 'Cancel'
  },
  exit: {
    id: 'ExitDashboardEdition.exit',
    description: 'Exit',
    defaultMessage: 'Exit'
  }
})

export default messages
