import { connect } from 'react-redux'

import { getMapUrl } from 'routes/helper'
import { utils/*, actions*/ } from 'ducks/theme'

import { createLoader } from 'components/LoadOnMount'
import { Logo } from './Logo'

const mapStateToProps = state => ({
  mapUrl: getMapUrl(),
  logo: utils.getLogo(state)
})

const mapDispatchToProps = dispatch => ({
  load: () => {}//dispatch(actions.loadThemeCustomizations())
})

export default createLoader(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Logo)
)
