import LanguageDropdown from './LanguageDropdown'
import { connect } from 'react-redux'
import { utils } from 'ducks/language'
import { actions } from 'utils/http'
import { utils as loginUtils } from 'ducks/login'
import { actions as loginActions } from 'ducks/login'

const mapStateToProps = state => {
  return {
    selectedLanguage: utils.getSelectedLanguage(state),
    user: loginUtils.getLoggedUser(state),
    userHasGroup: loginUtils.userHasGroup(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: language => dispatch(actions.setLanguage(language)),
    updateProfile: profile => dispatch(loginActions.updateProfile(profile)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageDropdown)
