import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class VideoSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      url: props.data.url,
      urlError: ''
    }
  }

  handleChange = name => event => {
    let update = { [name]: event.target.value }
    if (name === 'url') update = { ...update, [name + 'Error']: '' }
    this.setState(update)
  }

  processUrl = url => {
    const trimedUrl = url.trim()
    return trimedUrl.toLowerCase()
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    const processedUrl = this.processUrl(this.state.url)

    if (!processedUrl.startsWith('https://') && !processedUrl.startsWith('http://')) {
      error = true
      let message = this.formatMessage(messages.urlMustStartWith)
      if (processedUrl === '') {
        message = this.formatMessage(messages.urlCantBeEmpty)
      }
      this.setState({
        urlError: message
      })
    }

    if (!error) {
      const data = {
        url: this.state.url.trim()
      }

      this.props.saveSettings(data)
    }
  }

  render() {
    const { urlError } = this.state

    const supportedPlatforms = ['YouTube', 'Vimeo']
    const helperText =
      urlError || this.formatMessage(messages.supportedPlatforms, { supportedPlatforms: supportedPlatforms.join(', ') })

    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        fullWidth={true}
        keepMounted={true}
        maxWidth="xl"
        onClose={this.closeSettings}
        open={true}
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.videoWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.videoWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <FormControl error={this.state.urlError !== ''} fullWidth={true} required={true}>
                <InputLabel htmlFor="staticData-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.url)}
                </InputLabel>
                <Input name="url" onChange={this.handleChange('url')} value={this.state.url} />
                <FormHelperText>{helperText}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

VideoSettings.propTypes = {
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.shape({
    url: PropTypes.string
  }),
  saveSettings: PropTypes.func.isRequired
}

VideoSettings.defaultProps = {
  data: {
    url: ''
  }
}

export default injectIntl(VideoSettings)
