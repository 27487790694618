const prefix = 'map/'

// FIXME little hack used to put use an special class in the container?
export const SET_SHOWN = prefix + 'SET_SHOWN'

export const SET_CENTER = prefix + 'SET_CENTER'
export const SET_ZOOM_LEVEL = prefix + 'SET_ZOOM_LEVEL'

export const RESET_SEARCH_FIELD = prefix + 'RESET_SEARCH_FIELD'
export const RESET_SEARCH_FILTER = prefix + 'RESET_SEARCH_FILTER'

export const SELECT_NODE = prefix + 'SELECT_NODE'
export const UNSELECT_NODE = prefix + 'UNSELECT_NODE'

export const SELECT_GROUP = prefix + 'SELECT_GROUP'
export const UNSELECT_GROUP = prefix + 'UNSELECT_GROUP'

export const ADD_FILTER = prefix + 'ADD_FILTER'
export const REMOVE_FILTER = prefix + 'REMOVE_FILTER'
export const FILTER_NODE_BY_NAME = prefix + 'FILTER_NODE_BY_NAME'

// Used in reducers from other modules
export const actionTypes = {
  SELECT_NODE
}
