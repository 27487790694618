import React from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import MoreVert from '@material-ui/icons/MoreVert'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ResizeObserver from 'resize-observer-polyfill'

import DeleteDashboard from 'modules/groupDashboards/Modals/DeleteDashboard'
import ExportDashboard from 'modules/groupDashboards/Modals/ExportDashboard'
import ImportDashboard from 'modules/groupDashboards/Modals/ImportDashboard'
import EditDashboard from 'modules/groupDashboards/Modals/EditDashboard'
import AddWidget from 'modules/groupDashboards/Modals/AddWidget'
import ErrorSavingDashboard from 'modules/groupDashboards/Modals/ErrorSavingDashboard'
import ExitDashboardEdition from 'modules/groupDashboards/Modals/ExitDashboardEdition'

import Dashboard from 'modules/groupDashboards/Dashboard'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { removeValuesFromRealTimeWidgets } from '../utils'

import { isEqual } from 'lodash'

class DashboardWrapper extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      open: false,
      editDashboardModal: false,
      exportDashboardModal: false,
      importDashboardModal: false,
      deleteDashboardModal: false,
      addWidgetModal: false,
      exitDashboardEditionModal: false,
      errorSavingDashboardModal: false,
      containerWidth: 'auto'
    }

    this.DASHBOARD_MAX_SIZE = 10
    this.mainRef = React.createRef()

    this.resizeObserver = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { width } = entry.contentRect

        this.setState({
          containerWidth: width
        })
      }
    })
  }

  static propTypes = {
    dashboardData: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    startEditDashboard: PropTypes.func.isRequired,
    saveAndFinishEditingDashboard: PropTypes.func.isRequired,
    finishEditDashboard: PropTypes.func.isRequired,
    fontColor: PropTypes.string.isRequired,
    addWidget: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    dashboardIds: PropTypes.array.isRequired,
    groupId: PropTypes.string.isRequired,
    canDeleteGroupDashboard: PropTypes.bool.isRequired,
    canEditGroupDashboard: PropTypes.bool.isRequired
  }

  static defaultProps = {
    dashboardData: {},
    editing: false,
    startEditDashboard: i => i,
    saveAndFinishEditingDashboard: i => i,
    finishEditDashboard: i => i,
    settings: {},
    dashboardIds: [],
    changeSelectedTab: i => i,
    groupId: ''
  }

  componentDidMount() {
    this.resizeObserver.observe(this.mainRef.current)

    this.setState({
      containerWidth: this.mainRef.current.clientWidth
    })
  }

  // EDIT DASHBOARD
  openEditDashboardModal = () => {
    this.setState({
      editDashboardModal: true
    })
  }
  closeEditDashboardModal = () => {
    this.setState({
      editDashboardModal: false
    })
  }

  // DELETE DASHBOARD
  openDeleteDashboardModal = () => {
    this.setState({
      deleteDashboardModal: true
    })
  }
  closeDeleteDashboardModal = () => {
    this.setState({
      deleteDashboardModal: false
    })
  }

  // ADD WIDGET
  openAddWidgetModal = () => {
    this.setState({
      addWidgetModal: true
    })
  }
  closeAddWidgetModal = () => {
    this.setState({
      addWidgetModal: false
    })
  }

  // EXPORT DASHBOARD
  openExportDashboardModal = () => {
    this.setState({
      exportDashboardModal: true
    })
  }
  closeExportDashboardModal = () => {
    this.setState({
      exportDashboardModal: false
    })
  }

  // IMPORT DASHBOARD
  openImportDashboardModal = () => {
    this.setState({
      importDashboardModal: true
    })
  }
  closeImportDashboardModal = () => {
    this.setState({
      importDashboardModal: false
    })
  }

  // EXIT WITHOUT SAVING
  openExitDashboardEditionModal = () => {
    this.setState({
      exitDashboardEditionModal: true
    })
  }
  closeExitDashboardEditionModal = () => {
    this.setState({
      exitDashboardEditionModal: false
    })
    this.handleClose()
  }

  exitDashboardEdition = () => {
    this.closeExitDashboardEditionModal()
    this.props.finishEditDashboard()
    this.handleClose()
  }

  // ERROR SAVING (TOO HEAVY)
  openErrorSavingDashboardModal = () => {
    this.setState({
      errorSavingDashboardModal: true
    })
  }
  closeErrorSavingDashboardModal = () => {
    this.setState({
      errorSavingDashboardModal: false
    })
  }
  errorSavingDashboard = () => {
    this.closeErrorSavingDashboardModal()
    this.handleClose()
  }

  // MENU HANDLERS
  handleOpenEditDashboardModal = () => {
    this.openEditDashboardModal()
    this.handleClose()
  }

  handleOpenDeleteDashboardModal = () => {
    this.openDeleteDashboardModal()
    this.handleClose()
  }

  handleOpenAddWidgetModal = () => {
    this.openAddWidgetModal()
    this.handleClose()
  }

  handleExportDashboardModal = () => {
    this.openExportDashboardModal()
    this.handleClose()
  }

  handleImportDashboardModal = () => {
    this.openImportDashboardModal()
    this.handleClose()
  }

  handleEditDashboard = () => {
    const { hashId, name, description } = this.props.dashboardData

    this.props.startEditDashboard(hashId, name, description)
    this.handleClose()
  }

  handleSaveAndFinishEditingDashboard = () => {
    this.updateDashboard()
  }

  bytesToMB = bytes => {
    return bytes / 1024 / 1024
  }

  updateDashboard = () => {
    const DASHBOARD_MAX_SIZE = this.DASHBOARD_MAX_SIZE
    const widgetsWithoutValues = removeValuesFromRealTimeWidgets(this.props.widgets)
    const newGroupDashboard = `{
      "maxZIndex": ${this.props.maxZIndex},
      "widgets": ${JSON.stringify(widgetsWithoutValues)}
    }`
    const formData = new FormData()
    const blob = new Blob([newGroupDashboard], { type: 'application/json' })

    if (this.bytesToMB(blob.size) < DASHBOARD_MAX_SIZE) {
      formData.append('file', blob)

      client
        .updateGroupDashboard(this.props.dashboardData.hashId, formData)
        .then(response => {
          const position = response.data.position
          const descriptionObject = JSON.parse(this.props.dashboardData.description)
          const dashboardSettings = {
            name: this.props.dashboardData.name,
            ...descriptionObject
          }
          if (!isEqual(this.props.settings, dashboardSettings)) {
            const descriptionJSON = JSON.stringify({
              description: this.props.settings.description,
              height: this.props.settings.height,
              width: this.props.settings.width,
              zoom: this.props.settings.zoom
            })

            const dashboard = {
              hashId: this.props.dashboardData.hashId,
              groupId: this.props.dashboardData.groupId,
              uuid: this.props.dashboardData.uuid,
              version: this.props.dashboardData.version,
              name: this.props.settings.name,
              description: descriptionJSON,
              position
            }
            return client.editGroupDashboard(dashboard)
          } else {
            return Promise.resolve(this.formatMessage(messages.noNeedToUpdateDashboardSettings))
          }
        })
        .then(response => {
          if (typeof response === 'object') this.props.updateGroupDashboard(response.data)
          this.props.saveAndFinishEditingDashboard()
          this.handleClose()
        })
        .catch(response => {
          const error = { ...response }
          console.log('ERROR', error)
          logError(error)

          switch (error.response.status) {
            case 400: // The file exceeds the maximum upload size allowed
              this.openErrorSavingDashboardModal()
              break

            case 500: // Server errors
              this.openErrorSavingDashboardModal()

              break
            default:
          }
        })
    } else {
      this.openErrorSavingDashboardModal()
    }
  }

  handleOpenExitDashboardEditionModal = () => {
    this.openExitDashboardEditionModal()
    this.handleClose()
  }

  // OTHER
  handleToggle = () => {
    this.setState({
      open: !this.state.open
    })
  }

  handleClose = event => {
    this.setState({
      open: false
    })
  }

  myStyle = () => {
    const style = { overflow: 'auto', height: 'calc(100vh - 100px)', marginLeft: 0 }
    //if (!this.props.editing) style = { ...style, marginLeft: 0 }
    return style
  }

  render() {
    return (
      <div ref={this.mainRef} className='dashboard-wrapper' style={this.myStyle()}>
        {this.props.editing && (
          <React.Fragment>
            <IconButton
              alt={this.formatMessage(messages.showHideMenuOptions)}
              aria-haspopup='true'
              aria-owns={this.state.open ? 'menu-list-grow' : undefined}
              buttonRef={node => {
                this.anchorEl = node
              }}
              className='menu-toogle-button'
              onClick={this.handleToggle}
              style={{ color: this.props.fontColor }}
            >
              <MoreVert />
            </IconButton>

            <Popper
              anchorEl={this.anchorEl}
              disablePortal={false}
              modifiers={{
                flip: {
                  enabled: true
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent'
                },
                arrow: {
                  enabled: false,
                  element: this.anchorEl
                }
              }}
              open={this.state.open}
              placement='bottom-end'
              style={{ zIndex: 10000 }}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id='menu-list-grow'
                  style={{
                    transformOrigin: placement === 'bottom' ? 'left top' : 'right bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <MenuItem onClick={this.handleOpenAddWidgetModal}>
                          <ListItemIcon>
                            <Icon className='zmdi zmdi-plus' style={{ fontSize: '25px', paddingLeft: '2px' }} />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            primary={this.formatMessage(messages.addWidget)}
                            style={{ paddingLeft: '0px' }}
                          />
                        </MenuItem>
                        <MenuItem onClick={this.handleExportDashboardModal}>
                          <ListItemIcon>
                            <img
                              alt={this.formatMessage(messages.exportDashboard)}
                              src={process.env.PUBLIC_URL + '/images/dashboards/export.png'}
                            />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            primary={this.formatMessage(messages.exportDashboard)}
                            style={{ paddingLeft: '0px' }}
                          />
                        </MenuItem>
                        <MenuItem onClick={this.handleImportDashboardModal}>
                          <ListItemIcon>
                            <img
                              alt={this.formatMessage(messages.importDashboard)}
                              src={process.env.PUBLIC_URL + '/images/dashboards/import.png'}
                            />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            primary={this.formatMessage(messages.importDashboard)}
                            style={{ paddingLeft: '0px' }}
                          />
                        </MenuItem>
                        <MenuItem onClick={this.handleSaveAndFinishEditingDashboard}>
                          <ListItemIcon>
                            <img
                              alt={this.formatMessage(messages.saveAndExit)}
                              src={process.env.PUBLIC_URL + '/images/dashboards/save.png'}
                            />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            primary={this.formatMessage(messages.saveAndExit)}
                            style={{ paddingLeft: '0px' }}
                          />
                        </MenuItem>
                        <MenuItem onClick={this.handleOpenExitDashboardEditionModal}>
                          <ListItemIcon>
                            <Icon
                              alt={this.formatMessage(messages.exit)}
                              className='zmdi zmdi-close'
                              style={{ fontSize: '20px', paddingLeft: '3px' }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            inset
                            primary={this.formatMessage(messages.exit)}
                            style={{ paddingLeft: '0px' }}
                          />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        )}
        {!this.props.editing && (
          <React.Fragment>
            {(this.props.canEditGroupDashboard || this.props.canDeleteGroupDashboard) && (
              <IconButton
                alt={this.formatMessage(messages.showHideMenuOptions)}
                aria-haspopup='true'
                aria-owns={this.state.open ? 'menu-list-grow' : undefined}
                buttonRef={node => {
                  this.anchorEl = node
                }}
                className='menu-toogle-button'
                onClick={this.handleToggle}
                style={{ color: this.props.fontColor }}
              >
                <MoreVert />
              </IconButton>
            )}

            <Popper
              anchorEl={this.anchorEl}
              disablePortal={false}
              modifiers={{
                flip: {
                  enabled: true
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent'
                },
                arrow: {
                  enabled: false,
                  element: this.anchorEl
                }
              }}
              open={this.state.open}
              placement='bottom-end'
              style={{ zIndex: 10000 }}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id='menu-list-grow'
                  style={{
                    transformOrigin: placement === 'bottom' ? 'left top' : 'right bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        {this.props.canEditGroupDashboard && (
                          <MenuItem onClick={this.handleEditDashboard}>
                            <ListItemIcon>
                              <img
                                alt={this.formatMessage(messages.editDashboard)}
                                src={process.env.PUBLIC_URL + '/images/dashboards/edit.png'}
                              />
                            </ListItemIcon>
                            <ListItemText
                              inset
                              primary={this.formatMessage(messages.editDashboard)}
                              style={{ paddingLeft: '0px' }}
                            />
                          </MenuItem>
                        )}
                        {this.props.canEditGroupDashboard && (
                          <MenuItem onClick={this.handleOpenEditDashboardModal}>
                            <ListItemIcon>
                              <img
                                alt={this.formatMessage(messages.dashboardSettings)}
                                src={process.env.PUBLIC_URL + '/images/dashboards/settings.png'}
                              />
                            </ListItemIcon>
                            <ListItemText
                              inset
                              primary={this.formatMessage(messages.dashboardSettings)}
                              style={{ paddingLeft: '0px' }}
                            />
                          </MenuItem>
                        )}
                        {this.props.canDeleteGroupDashboard && (
                          <MenuItem onClick={this.handleOpenDeleteDashboardModal}>
                            <ListItemIcon>
                              <Icon
                                alt={this.formatMessage(messages.deleteDashboard)}
                                className='far fa-trash-alt'
                                style={{ color: '#5d5d5d', fontSize: '15px', marginRight: 5, paddingLeft: 3 }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              inset
                              primary={this.formatMessage(messages.deleteDashboard)}
                              style={{ paddingLeft: '0px' }}
                            />
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        )}
        <Dashboard containerWidth={this.state.containerWidth} dashboardData={this.props.dashboardData} />

        {this.state.editDashboardModal && (
          <EditDashboard
            closeEditDashboardModal={this.closeEditDashboardModal}
            dashboardData={this.props.dashboardData}
            dashboardIds={this.props.dashboardIds}
            editDashboardModal={this.state.editDashboardModal}
            updateGroupDashboard={this.props.updateGroupDashboard}
          />
        )}
        {this.state.deleteDashboardModal && (
          <DeleteDashboard
            closeDeleteDashboardModal={this.closeDeleteDashboardModal}
            dashboardData={this.props.dashboardData}
            deleteDashboardModal={this.state.deleteDashboardModal}
            deleteGroupDashboard={this.props.deleteGroupDashboard}
            refresh={this.props.refresh}
          />
        )}
        {this.state.addWidgetModal && (
          <AddWidget
            addWidget={this.props.addWidget}
            addWidgetModal={this.state.addWidgetModal}
            closeAddWidgetModal={this.closeAddWidgetModal}
            dashboardData={this.props.dashboardData}
          />
        )}
        {this.state.exportDashboardModal && (
          <ExportDashboard
            closeExportDashboardModal={this.closeExportDashboardModal}
            dashboardData={this.props.dashboardData}
            exportDashboardModal={this.state.exportDashboardModal}
          />
        )}
        {this.state.importDashboardModal && (
          <ImportDashboard
            closeImportDashboardModal={this.closeImportDashboardModal}
            dashboardData={this.props.dashboardData}
            importDashboardModal={this.state.importDashboardModal}
          />
        )}
        {this.state.exitDashboardEditionModal && (
          <ExitDashboardEdition
            closeExitDashboardEditionModal={this.closeExitDashboardEditionModal}
            exitDashboardEdition={this.exitDashboardEdition}
          />
        )}
        {this.state.errorSavingDashboardModal && (
          <ErrorSavingDashboard
            closeErrorSavingDashboardModal={this.closeErrorSavingDashboardModal}
            errorSavingDashboard={this.errorSavingDashboard}
          />
        )}
      </div>
    )
  }
}

export default injectIntl(DashboardWrapper)
