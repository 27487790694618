const SUCCESS_COLOR = '#009541'
const WARNING_COLOR = '#f0ad4e'
const DANGER_COLOR = '#d9534f'
const INFO_COLOR = '#0077ff'

export const alertStyles = {
  alertTitle: {
    color: 'inherit',
    fontSize: '16px'
  },
  alertMessage: {
    width: '100%'
  },
  alertLink: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}

export const chipStyles = {
  chipRoot: {
    height: 'fit-content',
    fontSize: '14px'
  },
  chipDanger: {
    backgroundColor: DANGER_COLOR,
    color: '#FFFFFF'
  },
  chipInfo: {
    backgroundColor: INFO_COLOR,
    color: '#FFFFFF'
  },
  chipSuccess: {
    backgroundColor: SUCCESS_COLOR,
    color: '#FFFFFF'
  },
  chipWarning: {
    backgroundColor: WARNING_COLOR,
    color: '#FFFFFF'
  }
}

export const paperStyles = {
  paperRoot: {
    borderRadius: 0,
    padding: 20
  }
}
