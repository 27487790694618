import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import Alert from 'components/Alert'

import { client } from 'utils/http'

import messages from './messages'

class DeleteDashboards extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessage: '',
      alertType: ''
    }
  }

  handleDashboardsDelete = () => {
    const { csNodes, groupId } = this.props
    const devices = csNodes.map(({ id, name }) => ({ deviceId: id, name }))
    client
      .deleteDashboards(groupId, devices)
      .then(() => {
        this.setState({
          alertMessage: this.formatMessage(messages.successfulDeletion),
          alertType: 'success'
        })
      })
      .catch(() => {
        this.setState({
          alertMessage: this.formatMessage(messages.errorDeletingDashboard)
        })
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessage: ''
    })
  }

  render() {
    const { handleClose, csNodes } = this.props
    const { alertMessage, alertType } = this.state
    const isAlertDisplayed = alertMessage !== ''

    return (
      <React.Fragment>
        <DialogTitle>
          {this.formatMessage(messages.deleteDashboards)}
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isAlertDisplayed ? (
            <Alert
              alertType={alertType}
              closeFunction={alertType === 'danger' ? this.closeAlert : undefined}
              messageText={[alertMessage]}
              showAlert={isAlertDisplayed}
            />
          ) : (
            <React.Fragment>
              <DialogContentText>
                {this.formatMessage(messages.warningMessage, { all: <b>{this.formatMessage(messages.all)}</b> })}
              </DialogContentText>
              <br />
              <DialogContentText id='alert-dialog-slide-description'>
                <span style={{ fontWeight: 'bold' }}>Selected devices:</span>
              </DialogContentText>
              <ul>
                {csNodes.map(device => (
                  <li>{device.name}</li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='delete-button' disabled={isAlertDisplayed} onClick={this.handleDashboardsDelete}>
            {this.formatMessage(messages.delete)}
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

DeleteDashboards.propTypes = {
  csNodes: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default DeleteDashboards
