import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import { client, logError } from 'utils/http'

import * as c from '../constants'
import { adapterNotificationDetail } from '../utils'
import EditNotificationAssignUsersTitle from './EditNotificationAssignUsersTitle'
import EditNotificationFormAssignUsers from './EditNotificationFormAssignUsers'
import messages from './messages'

class EditNotificationAssignUsers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      notificationAssignUsersUrl: props.notificationAssignUsersUrl,

      notification: {},
      loading: true,

      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    this.fetchNotification()
  }

  fetchNotification = () => {
    const { intl, notificationId } = this.props

    client
      .getRuleInstance(notificationId)
      .then(response => {
        if (response.data !== undefined) {
          const notificationData = response.data
          const groupDescription = this.getGroupDescription(notificationData.groupId)
          const conditionVariableName = c.RULE_VARIABLE_CONDITION_NAME
          const originalConditionVariableName = c.RULE_VARIABLE_ORIGINAL_CONDITION_NAME
          const notificationDetail = adapterNotificationDetail(
            notificationData,
            groupDescription,
            conditionVariableName,
            originalConditionVariableName
          )

          this.setState({
            notification: notificationDetail,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: intl.formatMessage(messages.errorText),
            alertMessagesText: [intl.formatMessage(messages.errorLoadingNotification)]
          })
        }
      })
      .catch(response => {
        const error = { ...response }
        if (error.response !== undefined && error.response.status !== undefined) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: '400' }),
                alertMessagesText: [intl.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = intl.formatMessage(messages.error401Message)
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: '401' }),
                alertMessagesText: [message]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: '403' }),
                alertMessagesText: [error.response.data.message]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: '404' }),
                alertMessagesText: [intl.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: '406' }),
                alertMessagesText: [intl.formatMessage(messages.error406Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
              })
              logError(response)
          }
        }
      })
  }

  getGroupDescription = groupId => {
    const { getGroup } = this.props

    let groupDescription = ''

    const groupInfo = getGroup(groupId)
    if (groupInfo.name !== undefined) {
      groupDescription = groupInfo.name
    }

    return groupDescription
  }

  setNotificationTitle = description => {
    if (description) {
      return description
    } else {
      return ''
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, loading } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { canReadUsers, currentGroupName, getUsersOfGroupDetail } = this.props
    const { alertMessages, loading, notification, notificationAssignUsersUrl } = this.state

    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <EditNotificationFormAssignUsers
          canReadUsers={canReadUsers}
          currentGroupName={currentGroupName}
          getUsersOfGroupDetail={getUsersOfGroupDetail}
          notification={notification}
          notificationAssignUsersUrl={notificationAssignUsersUrl}
        />
      )
    }
  }

  render() {
    const { editNotificationConditionalSignalsUrl, notificationsUrl } = this.props
    const { notification } = this.state

    const notificationName = `${notification?.name || ''}${
      notification?.description ? ` (${notification.description})` : ''
    }`
    const notificationHashId = notification?.hashId || ''

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <EditNotificationAssignUsersTitle
              notificationConditionalSignalsUrl={editNotificationConditionalSignalsUrl(notificationHashId)}
              notificationsUrl={notificationsUrl}
              title={this.setNotificationTitle(notificationName)}
            />
          </div>
        </div>
        {this.renderTablecontent()}
      </div>
    )
  }
}

EditNotificationAssignUsers.propTypes = {
  canReadUsers: PropTypes.bool.isRequired,
  currentGroupName: PropTypes.string.isRequired,
  editNotificationConditionalSignalsUrl: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  getUsersOfGroupDetail: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notificationAssignUsersUrl: PropTypes.string.isRequired,
  notificationId: PropTypes.string.isRequired,
  notificationsUrl: PropTypes.string.isRequired
}

export default injectIntl(EditNotificationAssignUsers)
