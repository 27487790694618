import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'EditRole.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'EditRole.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'EditRole.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'EditRole.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: 'EditRole.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: 'EditRole.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'EditRole.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  return: {
    id: 'EditRole.return',
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: 'EditRole.thisFieldIsRequired',
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  error401Message: {
    id: 'EditRole.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error412Message: {
    id: 'EditRole.error412Message',
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error415Message: {
    id: 'EditRole.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'EditRole.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error428Message: {
    id: 'EditRole.error428Message',
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  name: {
    id: 'EditRole.name',
    description: 'Name floating label text',
    defaultMessage: 'Name:'
  },
  parentRole: {
    id: 'EditRole.parentRole',
    description: 'Parent role floating label text',
    defaultMessage: 'Parent role'
  },
  createdAt: {
    id: 'EditRole.createdAt',
    description: 'Created at floating label text',
    defaultMessage: 'Created at:'
  },
  modifiedAt: {
    id: 'EditRole.modifiedAt',
    description: 'Modified at floating label text',
    defaultMessage: 'Modified at:'
  },
  permissions: {
    id: 'EditRole.permissions',
    description: 'Permissions title',
    defaultMessage: 'Permissions'
  },
  save: {
    id: 'EditRole.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  error: {
    id: 'EditRole.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  editRoleTitle: {
    id: 'EditRole.editRoleTitle',
    description: 'Edit role title text',
    defaultMessage: 'Edit "{roleName}" role'
  }
})

export default messages
