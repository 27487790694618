import { createSelector } from 'reselect'

import csNode from 'modules/csNode'

import { getSelectedMarkerId } from '../map'
import { parseMarker } from '../../utils'
import { getDisplayedGroups } from './groups'
import { CS_NODE_PREFIX } from '../../constants'

const getSelectedNode = createSelector(getSelectedMarkerId, csNode.utils.getCSNodes, (selectedMarkerId, csNodes) => {
  const { isNode, id, prefix } = parseMarker(selectedMarkerId)
  if (isNode) {
    if (prefix === CS_NODE_PREFIX) {
      return { nd: csNodes[id], nodeType: prefix }
    }
  } else {
    return undefined
  }
})

const getOverlayImages = state =>
  getDisplayedGroups(state).reduce((ret, g) => {
    if (g.overlays) {
      ret.push(...g.overlays)
    }
    return ret
  }, [])

export { getSelectedNode, getOverlayImages }
export { getRepresentableSelectedGroups } from './groups'
export { getFilteredMarkers, getFilteredMarkersLocations } from './markers'
