import { MapController } from 'react-map-gl'
import { isMobile } from 'react-device-detect'

class MobileMapControls extends MapController {
  constructor() {
    super()
    this.events = ['panstart', 'panmove', 'panend']
  }

  setOptions(options) {
    if (options.onPanStart) {
      if (isMobile) {
        // save the custom callback
        this.onPanStart = options.onPanStart
      }
    } else {
      super.setOptions(options)
    }
  }

  _onPanStart(event) {
    super._onPanStart(event)
    if (this.onPanStart) {
      this.onPanStart()
    }
  }
}

export default MobileMapControls
