import { connect } from 'react-redux'

import Home from './Home'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = state => ({
  canReadNotifications: resolvePermissions(state, c.RULE_READ),
  canEditNotifications: resolvePermissions(state, c.RULE_EDIT),
  canDeleteNotifications: resolvePermissions(state, c.RULE_DELETE),
  canCreateNotifications: resolvePermissions(state, c.RULE_CREATE),
  canReadDevices: resolvePermissions(state, c.DEVICE_READ),
  canReadUsers: resolvePermissions(state, c.USER_READ)
})

export default connect(mapStateToProps)(Home)

export { getNotificationsRulesUrl } from './url'
