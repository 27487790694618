export const SHARING_MODE = {
  SHARE_ALL: 'SHARE_ALL',
  SELECT_DATAPLANS: 'SELECT_DATAPLANS',
  SHARE_BY_TYPE: 'SHARE_BY_TYPE'
}

export const DEVICE_TYPES = {
  CS100: 'CS100',
  CS500: 'CS500'
}
