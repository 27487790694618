import { connect } from 'react-redux'

import { actions, utils as groupUtils } from 'ducks/groups'

import NotificationSettingsStep from './NotificationSettingsStep'

const mapStateToProps = state => {
  return {
    groupId: groupUtils.getSelectedGroup(state).id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsersOfGroupDetails: (groupId, emails) => dispatch(actions.getUsersOfGroupDetails(groupId, emails)),
    fetchDetailedUsers: (groupId, limit, offset) => dispatch(actions.getDetailedUsersOfGroup(groupId, limit, offset))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsStep)
