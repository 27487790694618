import { logTypeToValues } from '../config'

// Lp: Logging upload period
// Lb: Logging buffer threshold
// TLb: Time needed to reach Logging buffer threshold
// Tt: Transmit period
// Gt: GNSS signal period
// N: Number of signals (MFIO, GPS, CAN)
// Tn: Time Intervals of each signal (MFIO, GPS, CAN)
// Ge: GNSS enable (0 or 1)
// Ae: Altitude enable (0 or 1)
// He: Heading Enable (0 or 1)
// Se: Speed Enable (0 or 1)
// Gs: GNSS info size
// Nm: Number of transmitted messages in a month
// Mu: Machine usage percentage

export const getTimeToReachLoggingBufferThreshold = (Lb, gps, mfio, signals) => {
  // TLb: Time needed to reach Logging buffer threshold
  // Lb: Logging buffer threshold
  // gps: GNSS signals
  // mfio: MFIO signals
  // signals: SensorMap signals

  const sumGps = gps.positionLogTime !== 0 ? 1 / gps.positionLogTime : 0

  let sumMfio = 0
  Array.isArray(mfio) &&
    mfio.forEach(m => {
      const logCycle = m.logCycle ? m.logCycle : 0

      m.pinFunction !== 0 && logCycle !== 0 ? sumMfio += 1 / logCycle : sumMfio += 0
    })

  let sumSignals = 0

  signals.forEach(s => (s.frequency !== 0 ? sumSignals += 1 / s.frequency : sumSignals += 0))

  const TLb = sumGps + sumMfio + sumSignals !== 0 ? Lb / (sumGps + sumMfio + sumSignals) : 0
  return TLb
}

export const getTransmitPeriod = (Lp, TLb) => {
  // Tt: Transmit Period (Tt): The minimum value between Logging upload period (Lp) and Logging buffer threshold (Lb) if both are enabled

  let Tt = 0
  if (Lp === 0 && TLb !== 0) {
    Tt = TLb
  } else if (Lp !== 0 && TLb === 0) {
    Tt = Lp
  } else if (Lp !== 0 && TLb !== 0) {
    Tt = Math.min(Lp, TLb)
  }
  return Tt
}

export const getGnssInfoSize = (Ge, Ae, He, Se) => {
  // Gs: GNSS info size
  // Ge: GNSS enable (0 or 1)
  // Ae: Altitude enable (0 or 1)
  // He: Heading Enable (0 or 1)
  // Se: Speed Enable (0 or 1)

  const Gs = Ge * (55 + Ae * 13 + Se * 13 + He * 14)
  return Gs
}

export const getNumberOfTransmittedMessagesInAMonth = Tt => {
  // Nm: Number of transmitted messages in a month
  // Tt: Transmit period
  // 2592000 Seconds in a month

  const Nm = Tt !== 0 ? 2592000 / Tt : 0
  return Nm
}

export const getHeadersSizePerMonth = Nm => {
  // Hsm: Headers size in a month (MB)
  // Nm: Number of transmitted messages in a month

  const Hsm = 38 * Nm / 1048576
  return Hsm
}

export const getGnssSizePerMonth = (Gt, Gs) => {
  // Gt: GNSS signal period
  // Gs: GNSS info size

  const Gsm = Gt !== 0 ? 2592000 * Gs / Gt / 1048578 : 0
  return Gsm
}

export const getMfioSizePerMonth = mfio => {
  // Signal size: 35 -- if only one
  // For each extra: 13

  if (Array.isArray(mfio)) {
    const mfio0 = mfio.filter(m => m.signalId === 0)[0]
    const mfio0Enabled = mfio0.pinFunction !== 0 ? 1 : 0
    const mfio0LogCycle = mfio0.logCycle ? mfio0.logCycle : 0
    const logType0 = mfio0.logType ? mfio0.logType : 1
    const logTypeValues0 = logTypeToValues(logType0)

    let size0 = 0
    Object.keys(logTypeValues0).forEach(v => {
      if (logTypeValues0[v] === 1) {
        if (size0 !== 0) {
          size0 += 13
        } else {
          size0 = 35
        }
      }
    })

    const mfio0Size = mfio0LogCycle !== 0 ? mfio0Enabled * 2592000 * size0 / mfio0LogCycle : 0

    const mfio1 = mfio.filter(m => m.signalId === 1)[0]
    const mfio1Enabled = mfio1.pinFunction !== 0 ? 1 : 0
    const mfio1LogCycle = mfio1.logCycle ? mfio1.logCycle : 0
    const logType1 = mfio1.logType ? mfio1.logType : 1
    const logTypeValues1 = logTypeToValues(logType1)

    let size1 = 0
    Object.keys(logTypeValues1).forEach(v => {
      if (logTypeValues1[v] === 1) {
        if (size1 !== 0) {
          size1 += 13
        } else {
          size1 = 35
        }
      }
    })

    const mfio1Size = mfio1LogCycle !== 0 ? mfio1Enabled * 2592000 * size1 / mfio1LogCycle : 0

    const Mm = (mfio0Size + mfio1Size) / 1048578
    return Mm
  } else {
    return 0
  }
}

export const getSignalsSisePerMonth = signals => {
  let signalSum = 0
  signals.forEach(s => {
    let signalSize = 0
    switch (true) {
      case s.lengthOfBits >= 1 && s.lengthOfBits <= 8:
        signalSize = 30
        break
      case s.lengthOfBits >= 9 && s.lengthOfBits <= 16:
        signalSize = 33
        break
      case s.lengthOfBits >= 17 && s.lengthOfBits <= 32:
        signalSize = 35
        break
      case s.lengthOfBits >= 33 && s.lengthOfBits <= 64:
        signalSize = 41
        break
      default:
    }

    s.frequency !== 0 ? signalSum += signalSize / s.frequency : signalSum += 0
  })

  const Sm = 2592000 / 1048578 * signalSum
  return Sm
}

// eslint-disable-next-line max-params
export const getMegabytesPerMonth = (Mu, Hso, Hm, Gm, Mm, Sm) => {
  // Mu: Machine usage percentage in a month
  // Hso: MQTT/TLS/TCP/IP stack overhead
  // Hm: Headers size/month (MB)
  // Gsm: GNSS data/month (MB)
  // Mm: MFIO data/month (MB)
  // Sm: CAN signals data/month (MB)

  const bytesPerMonth = Mu * Hso * (Hm + Gm + Mm + Sm)
  return bytesPerMonth
}

export const getEstimatedDataConsumption = (config, Mu, Hso = 1) => {
  const TLb = getTimeToReachLoggingBufferThreshold(
    config.loggingBufferThreshold,
    config.gps,
    config.MFIO,
    config.sensorsMap
  )
  const Tt = getTransmitPeriod(config.loggingUploadPeriod, TLb)
  const Gt = parseInt(config.gps.positionLogTime)
  const Gs = getGnssInfoSize(
    parseInt(config.gps.positionLogTime) !== 0 ? 1 : 0,
    parseInt(config.gps.altitudeLogTime) !== 0 ? 1 : 0,
    parseInt(config.gps.headingLogTime) !== 0 ? 1 : 0,
    parseInt(config.gps.speedLogTime) !== 0 ? 1 : 0
  )
  const Nm = getNumberOfTransmittedMessagesInAMonth(Tt)
  const Hm = getHeadersSizePerMonth(Nm)
  const Gm = getGnssSizePerMonth(Gt, Gs)
  const Mm = getMfioSizePerMonth(config.MFIO)
  const Sm = getSignalsSisePerMonth(config.sensorsMap)

  const megabytesPerMonth = getMegabytesPerMonth(Mu, Hso, Hm, Gm, Mm, Sm)
  const dataConsumptionEstimation = Math.round((megabytesPerMonth + Number.EPSILON) * 100) / 100

  return dataConsumptionEstimation
}
