const LineTemplate = {
  id: '',
  width: 200,
  height: 10,
  x: 20,
  y: 20,
  minHeight: undefined,
  minWidth: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'line',
    params: {
      type: 'horizontal',
      width: 'thin',
      style: 'solid',
      color: 'default'
    }
  }
}

export default LineTemplate
