import React from 'react'
import ReactDOM from 'react-dom'
import invariant from 'invariant'
import { OverlayView } from 'react-google-maps'
import { OVERLAY_VIEW } from 'react-google-maps/lib/constants'

import { injectIntl } from 'react-intl'
import messages from './messages'

// Source: https://github.com/tomchentw/react-google-maps/issues/482#issuecomment-348026200
class CustomOverlayView extends OverlayView {
  getContainer = () => {
    const { bounds } = this.props

    if (this.containerElement && bounds && Object.keys(bounds).length > 0) {
      const overlayProjection = this.getProjection()
      const sw = overlayProjection.fromLatLngToDivPixel(bounds.getSouthWest())
      const ne = overlayProjection.fromLatLngToDivPixel(bounds.getNorthEast())

      this.containerElement.style.left = sw.x + 'px'
      this.containerElement.style.top = ne.y + 'px'
      this.containerElement.style.width = ne.x - sw.x + 'px'
      this.containerElement.style.height = sw.y - ne.y + 'px'
    }

    return this.containerElement
  }

  // Override draw function to catch errors with map panes being undefined to prevent console errors
  draw() {
    const {
      intl: { formatMessage }
    } = this.props
    const { mapPaneName } = this.props
    invariant(!!mapPaneName, formatMessage(messages.invariantText), mapPaneName)
    // https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapPanes
    const mapPanes = this.state[OVERLAY_VIEW].getPanes()
    const containerElement = this.getContainer()
    if (mapPanes && containerElement) {
      // <-- Add conditional to ensure panes and container exist before drawing
      mapPanes[mapPaneName].appendChild(containerElement)

      ReactDOM.unstable_renderSubtreeIntoContainer(
        this,
        React.Children.only(this.props.children),
        containerElement,
        this.onPositionElement
      )
    }
  }
}

export default injectIntl(CustomOverlayView)
