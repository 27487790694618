import * as c from './constants'
import { SET_LANGUAGE } from 'utils/http/actions/actionTypes'

const initState = {
  selectedLanguage: localStorage.getItem('user_language')
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, selectedLanguage: action.language}

    default:
      return state
  }
}

export default reducer

//Selector
const getSelector = state => state[c.NAME]
const getSelectedLanguage = state => getSelector(state).selectedLanguage

export { getSelectedLanguage }
