import { defineMessages } from 'react-intl'

const messages = defineMessages({
  confirmWidgetToClone: {
    id: 'CloneWidget.confirmWidgeteToClone',
    description: 'Clone widget confirm',
    defaultMessage: 'CONFIRM WIDGET TO CLONE'
  },
  widgetID: {
    id: 'CloneWidget.widgetID',
    description: 'Clone widget confirm',
    defaultMessage: 'Widget ID'
  },
  widgetType: {
    id: 'CloneWidget.widgetType',
    description: 'Clone widget confirm',
    defaultMessage: 'Widget type'
  },
  clone: {
    id: 'CloneWidget.clone',
    description: 'Clone widget confirm',
    defaultMessage: 'clone'
  }
})

export default messages
