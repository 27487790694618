import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import { ColorPicker } from 'components/common'
import { metricLineStyles, metricValueTypes, metricAggregations, metricBucket } from '../../../config'
import messages from '../../../../messages'

const styles = {
  colorPicker: {
    width: 16,
    height: 16,
    display: 'inline-block',
    borderRadius: 10,
    verticalAlign: 'middle',
    margin: 'auto'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class ChartView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleValueTypeChanged = event => {
    const { onSignalValueTypeChanged } = this.props
    onSignalValueTypeChanged(event.target.value)
  }

  handleAggregationChanged = event => {
    const { onSignalAggregationChanged } = this.props
    onSignalAggregationChanged(event.target.value)
  }

  handleBucketChanged = event => {
    const { onSignalBucketChanged } = this.props
    onSignalBucketChanged(event.target.value)
  }

  handleLinestyleChanged = event => {
    const { onSignalLinestyleChanged } = this.props
    onSignalLinestyleChanged(event.target.value)
  }

  renderSelectMenuWithLabels = (itemList = []) => {
    return itemList.map((elem, i) => {
      return (
        <MenuItem key={i} value={elem.value}>
          {elem.label}
        </MenuItem>
      )
    })
  }

  render() {
    const { classes, onDeleteSignal, onDuplicateSignal, onSignalColorChanged, sensor, sensorsOptions } = this.props

    const currentSensorOption = sensorsOptions.find(sensorOption => sensorOption.value === sensor.name)
    const disableValueType = currentSensorOption.isGPS || currentSensorOption.isAdvancedSignal
    const showValueType = currentSensorOption.isMFIO && currentSensorOption.deviceType === 'CS100'
    const disableAggregation = currentSensorOption.isAdvancedSignal
    const disableChangeLineStyle = currentSensorOption.isAdvancedSignal

    return (
      <Grid container spacing={3}>
        <Grid container item spacing={0} xs={3}>
          <Grid className='text-right' container item xs={2}>
            <ColorPicker
              className={classes.colorPicker}
              onChange={onSignalColorChanged}
              style={{ margin: 'auto' }}
              value={sensor.lineColor}
            />
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <InputLabel>{this.formatMessage(messages.signal)}</InputLabel>
              <Select disabled value={sensor.name}>
                {this.renderSelectMenuWithLabels(sensorsOptions)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {showValueType && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>{this.formatMessage(messages.valueType)}</InputLabel>
              <Select disabled={disableValueType} onChange={this.handleValueTypeChanged} value={sensor.valueType}>
                {this.renderSelectMenuWithLabels(metricValueTypes)}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs>
          <FormControl fullWidth>
            <InputLabel>{this.formatMessage(messages.aggregation)}</InputLabel>
            <Select
              disabled={disableAggregation}
              onChange={this.handleAggregationChanged}
              value={sensor.aggregationType}
            >
              {this.renderSelectMenuWithLabels(metricAggregations)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel> {this.formatMessage(messages.bucket)}</InputLabel>
            <Select
              disabled={sensor.aggregationType === 'none'}
              onChange={this.handleBucketChanged}
              value={sensor.bucket}
            >
              {this.renderSelectMenuWithLabels(metricBucket)}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={3}>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel>{this.formatMessage(messages.lineStyle)}</InputLabel>
              <Select disabled={disableChangeLineStyle} onChange={this.handleLinestyleChanged} value={sensor.lineStyle}>
                {this.renderSelectMenuWithLabels(metricLineStyles)}
              </Select>
            </FormControl>
          </Grid>
          <Grid className='text-right' container item justify='flex-end' xs={4}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement='top'
              title={this.formatMessage(messages.duplicateSignal)}
            >
              <IconButton onClick={onDuplicateSignal}>
                <Icon className='zmdi zmdi-plus-circle-o-duplicate' style={{ marginRight: 0 }} />
              </IconButton>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement='top'
              title={this.formatMessage(messages.deleteSignal)}
            >
              <IconButton onClick={onDeleteSignal}>
                <Icon className='zmdi zmdi-delete' style={{ color: '#484848', marginRight: 0 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ChartView.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  onDeleteSignal: PropTypes.func.isRequired,
  onDuplicateSignal: PropTypes.func.isRequired,
  onSignalAggregationChanged: PropTypes.func.isRequired,
  onSignalBucketChanged: PropTypes.func.isRequired,
  onSignalColorChanged: PropTypes.func.isRequired,
  onSignalLinestyleChanged: PropTypes.func.isRequired,
  onSignalValueTypeChanged: PropTypes.func.isRequired,
  sensor: PropTypes.object.isRequired,
  sensorsOptions: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(ChartView))
