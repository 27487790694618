import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import messages from './messages'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const DevicesTableTitle = props => {
  const {
    intl: { formatMessage }
  } = props

  const title = formatMessage(messages.title)
  const button = {
    label: formatMessage(messages.buyPlans),
    path: process.env.REACT_APP_MARKETPLACE_LINK,
    primary: true
  }

  return (
    <div className="row">
      <div className="col-sm-6">
        <h1>{title}</h1>
      </div>
      <div className="col-sm-6 text-right">
        <a className="button-link" href={button.path} rel="noopener noreferrer" target="_blank">
          <Button className="page-title-button" style={{ marginTop: 20 }}>
            {button.icon && <Icon className={'zmdi ' + button.icon} />}
            {button.label}
          </Button>
        </a>
      </div>
    </div>
  )
}

DevicesTableTitle.propTypes = {
  intl: PropTypes.object.isRequired
}

export default injectIntl(DevicesTableTitle)
