import React from 'react'
import PropTypes from 'prop-types'
import { getColoredCheckbox } from './ColoredCheckbox'

import { injectIntl } from 'react-intl'
import messages from './messages'

const SeriesVisibilityPanel = ({ series = [], onSerieVisibilityChange, intl }) => {
  const { formatMessage } = intl
  return (
    <div className="row" style={{ maxWidth: '900px', width: '80vw', margin: 0 }}>
      <h4 className="col-xs-12">{formatMessage(messages.showOrHideSeries)}</h4>
      {series.map(({ id, color, ...other }) => {
        const ColoredCheckbox = getColoredCheckbox(color)
        return (
          <ColoredCheckbox
            key={id}
            onChange={isInputChecked => onSerieVisibilityChange(id, isInputChecked)}
            {...other}
          />
        )
      })}
    </div>
  )
}

SeriesVisibilityPanel.propTypes = {
  onSerieVisibilityChange: PropTypes.func.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.any,
      isVisible: PropTypes.bool,
      name: PropTypes.string
    })
  )
}

export default injectIntl(SeriesVisibilityPanel)
