import React from 'react'
import PropTypes from 'prop-types'

import { SVGOverlay } from 'react-map-gl'
import blue from '@material-ui/core/colors/blue'

import { props } from 'components/Map'

const OpenedPolygon = ({ features = [] }) => (
  <SVGOverlay
    redraw={({ project }) =>
      features.map(({ latitude, longitude }, i) => {
        const [cx, cy] = project([longitude, latitude])
        return <circle key={i} cx={cx} cy={cy} r={4} fill={blue[700]} />
      })
    }
  />
)

OpenedPolygon.propTypes = {
  features: PropTypes.arrayOf(props.Position)
}

export default OpenedPolygon
