import { connect } from 'react-redux'

import { getDevicesData } from 'ducks/groupDashboards'

import Value from './Value'
import ValueSettingsUnconnected from './ValueSettings'
import ValueTemplate from './ValueTemplate'

const mapStateToProps = state => {
  return {
    devicesData: getDevicesData(state)
  }
}

export default connect(mapStateToProps)(Value)

const ValueSettings = connect(mapStateToProps)(ValueSettingsUnconnected)
export { ValueSettings, ValueTemplate }
