import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noLastKnownValuesAvailable: {
    id: 'DM1Table.noLastKnownValuesAvailable',
    description: 'No last known values available warning text',
    defaultMessage: 'No last known values available'
  },
  prePage: {
    id: 'LastKnownValueTable.prePage',
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'LastKnownValueTable.nextPage',
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'LastKnownValueTable.firstPage',
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'LastKnownValueTable.lastPage',
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  exportToCsv: {
    id: 'LastKnownValueTable.exportToCsv',
    description: 'Export to CSV',
    defaultMessage: 'Export to CSV'
  },
  lastKnownValuesError: {
    id: 'LastKnownValueTable.lastKnownValuesError',
    description: 'Error retrieving last known values',
    defaultMessage: 'Error retrieving last known values'
  },
  refresh: {
    id: 'LastKnownValueTable.refresh',
    description: 'Refresh',
    defaultMessage: 'Refresh'
  }
})

export default messages
