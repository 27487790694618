import moment from 'moment'

export const TIME_DATE_TEXT = 'DD-MMM-YYYY'
export const TIME_DATE_TIME_24_TEXT = 'DD-MMM-YYYY HH:mm:ss'
export const TIME_DATE_TIME_24_TEXT_NO_SECONDS = 'DD-MMM-YYYY HH:mm'
export const TIME_DATE_TIME_24_TEXT_WITH_MILLISECONDS = 'DD-MMM-YYYY HH:mm:ss.SSS'

export const utcTimeToBrowserLocalMilliseconds = utcTime => {
  if (utcTime) {
    const formatedTime = moment.utc(utcTime).local().format(TIME_DATE_TIME_24_TEXT)
    const formatedTimeInMilliseconds = Date.parse(formatedTime)

    return formatedTimeInMilliseconds
  } else {
    return ''
  }
}

export const utcTimeToBrowserLocal = utcTime => {
  if (utcTime) {
    const formatedTime = moment.utc(utcTime).local().format(TIME_DATE_TIME_24_TEXT)

    return formatedTime
  } else {
    return ''
  }
}

export const utcTimeToBrowserLocalNoSeconds = utcTime => {
  if (utcTime) {
    const formatedTime = moment.utc(utcTime).local().format(TIME_DATE_TIME_24_TEXT_NO_SECONDS)

    return formatedTime
  } else {
    return ''
  }
}

export const utcTimeToBrowserLocalShort = utcTime => {
  if (utcTime) {
    const formatedTime = moment.utc(utcTime).local().format(TIME_DATE_TEXT)

    return formatedTime
  } else {
    return ''
  }
}

export const utcTimeToBrowserLocalWithMilliseconds = utcTime => {
  if (utcTime) {
    const formatedTime = moment.utc(utcTime).local().format(TIME_DATE_TIME_24_TEXT_WITH_MILLISECONDS)

    return formatedTime
  } else {
    return ''
  }
}
