import { defineMessages } from 'react-intl'

export default defineMessages({
  available: {
    id: 'AssignUsersToNotification.available',
    defaultMessage: 'Available'
  },
  notAvailable: {
    id: 'AssignUsersToNotification.notAvailable',
    defaultMessage: 'Not available'
  }
})
