import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'

const styles = {
  dialog: {
    minWidth: 800
  }
}

class ApplyConfigurationDialog extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage
  }

  render() {
    const {
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      applyConfiguration,
      classes,
      isApplyConfigurationDialogButtonVisible,
      isDialogOpen,
      onDialogClose
    } = this.props

    return (
      <Dialog
        classes={{ paper: classes.dialog }}
        onClose={(event, reason) => onDialogClose(reason)}
        open={isDialogOpen}
      >
        <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
          <DialogTitle style={{ padding: 12 }}>
            <IconButton
              onClick={onDialogClose}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
            {this.formatMessage(messages.configurationUpdate)}
          </DialogTitle>

          <div style={{ padding: '12px 12px 0 12px' }}>
            <div
              className={'alert alert-' + alertMessagesType + ' alert-dismissible animated fadeIn'}
              style={{ marginBottom: 0 }}
            >
              {alertMessagesTitle !== '' && (
                <h4 style={{ display: 'flex', alignItems: 'flex-start', margin: 0 }}>
                  {alertMessagesType === 'success' && <CheckCircleIcon style={{ marginRight: 10 }} />}
                  {alertMessagesType === 'info' && <InfoIcon style={{ marginRight: 10 }} />}
                  {alertMessagesType === 'danger' && <ErrorIcon style={{ marginRight: 10 }} />}
                  {alertMessagesType === 'warning' && alertMessagesTitle.includes('WARNING') ? (
                    <WarningIcon style={{ marginRight: 10 }} />
                  ) : 
                    alertMessagesType === 'warning' && <AccessTimeIcon style={{ marginRight: 10 }} />
                  }
                  {alertMessagesTitle}
                </h4>
              )}
              {alertMessagesText !== '' && <h5 style={{ margin: '0 0 0 35px' }}>{alertMessagesText}</h5>}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {isApplyConfigurationDialogButtonVisible && (
            <Button className='primary-action-button' onClick={applyConfiguration}>
              {this.formatMessage(messages.apply)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

ApplyConfigurationDialog.propTypes = {
  alertMessagesText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  alertMessagesTitle: PropTypes.string.isRequired,
  alertMessagesType: PropTypes.string.isRequired,
  applyConfiguration: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isApplyConfigurationDialogButtonVisible: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired
}
ApplyConfigurationDialog.defaultProps = {
  alertMessagesText: ''
}

export default withStyles(styles)(injectIntl(ApplyConfigurationDialog))
