import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import DataChip from 'components/DataChip'
import messages from '../messages'
import { handleDownloadFile } from '../utils'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

const ActionDetailDialog = props => {
  const {
    intl,
    intl: { formatMessage },
    onClose,
    open,
    task
  } = props

  let showFileName = false
  let fileName = ''
  let dashboardName = ''

  if (task.variables !== undefined) {
    const taskVariables = task.variables
    const actionVariableForFile = taskVariables.find(
      x => x.name === 'configurationName' || x.name === 'fileName' || x.name === 'File'
    )
    if (
      actionVariableForFile !== undefined &&
      actionVariableForFile.type !== undefined &&
      actionVariableForFile.value !== null
    ) {
      if (actionVariableForFile.value !== '') {
        fileName = actionVariableForFile.value
        showFileName = true
      }
    }
    const dashboardNameVariable = taskVariables.find(variable => variable.name === 'DashboardName')
    if (dashboardNameVariable) dashboardName = dashboardNameVariable.value || ''
  }

  return (
    <Dialog fullWidth maxWidth='sm' onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <Grid alignItems='center' container>
          <Grid item xs={10}>
            <Typography variant='h5'>{task.nodeName}</Typography>
          </Grid>
          <Grid container item justify='flex-end' xs={2}>
            <IconButton aria-label='close' onClick={onClose} style={{ padding: '3px' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <p style={{ marginBottom: 0 }}>{task.actionDescription}</p>
      </DialogTitle>
      <DialogContent style={{ fontSize: 16 }}>
        <hr style={{ marginTop: 0 }} />
        <p>
          {formatMessage(messages.taskCreatedByOn, {
            user: (
              <a href={'mailto:' + task.userEmail}>
                <strong>
                  {task.userFirstName} {task.userLastName}
                </strong>
              </a>
            ),
            createdDate: utcTimeToBrowserLocal(task.createdDate)
          })}
        </p>
        <div style={{ margin: '1em 0' }}>
          <DataChip chipText={task.status} intl={intl} />
          {task.faultValue ? (
            <div className='alert alert-danger' style={{ margin: '1em 0 0 0', padding: '0.8em' }}>
              {task.faultValue}
            </div>
          ) : null}
          {task.outputType ? (
            <div className='alert alert-success' style={{ margin: '1em 0 0 0', padding: '0.8em' }}>
              <Button onClick={handleDownloadFile(task)}>
                <Icon className='zmdi zmdi-download' />
                {formatMessage(messages.downloadFile)}
              </Button>
            </div>
          ) : null}
        </div>
        <hr style={{ marginTop: 0 }} />
        <ul style={{ marginBottom: 25, paddingLeft: 0, listStyleType: 'none' }}>
          <li>
            <strong>{formatMessage(messages.startDate)}</strong> {utcTimeToBrowserLocal(task.startDate)}
          </li>
          <li>
            <strong>{formatMessage(messages.endDate)}</strong>{' '}
            {task.endDate ? utcTimeToBrowserLocal(task.endDate) : '--'}
          </li>
          <li>
            <strong>{formatMessage(messages.duration)}</strong>{' '}
            {task.duration
              ? formatMessage(messages.seconds, {
                number: Math.round(task.duration / 1000 * 100) / 100
              })
              : '--'}
          </li>
          <li>
            <strong>{formatMessage(messages.statusDetail)}</strong> {task.status}
          </li>
          {showFileName ? (
            <li>
              <strong>{formatMessage(messages.file)}:</strong> {fileName}
            </li>
          ) : null}
          <li>
            <strong>{formatMessage(messages.archivedDetail)}</strong> {task.archived ? 'Yes' : 'No'}
          </li>
          {task.faultValue && task.faultValue !== '' ? (
            <li>
              <strong>{formatMessage(messages.blobStorageId)}</strong> {task.faultValue}
            </li>
          ) : null}
          {task?.actionName && task?.actionName.endsWith('DASHBOARDS') && (
            <li>
              <strong>{formatMessage(messages.dashboard)}</strong> {dashboardName || '--'}
            </li>
          )}
        </ul>
      </DialogContent>
    </Dialog>
  )
}

ActionDetailDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired
}

export default injectIntl(ActionDetailDialog)
