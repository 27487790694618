import React from 'react'
import PropTypes from 'prop-types'

import {
  getNotificationCriticalityKeysWithCriticality,
  getLabelNotificationCriticality
} from 'utils/notificationCriticality'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import { injectIntl } from 'react-intl'
import messages from './messages'

const NotificationCriticalitySelector = ({ selected, onChange, errorText, intl }) => {
  const { formatMessage } = intl
  return (
    <SelectField
      fullWidth={true}
      floatingLabelText={formatMessage(messages.severity)}
      value={selected}
      errorText={errorText}
      onChange={(event, key, payload) => onChange(payload)}
    >
      {getNotificationCriticalityKeysWithCriticality().map(sk => (
        <MenuItem key={sk} value={sk} primaryText={getLabelNotificationCriticality(sk)} />
      ))}
    </SelectField>
  )
}

NotificationCriticalitySelector.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.string
}

export default injectIntl(NotificationCriticalitySelector)
