import { CONSENT_STATES } from 'components/SmsServiceConsentDialog/constants'

import { SMS_SERVICE_STATES } from './constants'

export function getSmsServiceState(consentState, isPhoneNumberPresent) {
  if (consentState === CONSENT_STATES.ACCEPTED && isPhoneNumberPresent) {
    return SMS_SERVICE_STATES.AVAILABLE
  } else {
    return SMS_SERVICE_STATES.NOT_AVAILABLE
  }
}
