import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ChartView } from './components'

import { BASE_URL } from './url'

const ChartsHome = ({ canPreviewCharts = false }) => (
  <Switch>{canPreviewCharts && <Route component={ChartView} path={BASE_URL} />}</Switch>
)

export default ChartsHome
