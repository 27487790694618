import { defineMessages } from 'react-intl'

const messages = defineMessages({
  waiting: {
    id: 'DataChip.waiting',
    description: 'waiting datachip text',
    defaultMessage: 'waiting'
  },
  executing: {
    id: 'DataChip.executing',
    description: 'executing datachip text',
    defaultMessage: 'executing'
  },
  completed: {
    id: 'DataChip.completed',
    description: 'completed datachip text',
    defaultMessage: 'completed'
  },
  failed: {
    id: 'DataChip.failed',
    description: 'failed datachip text',
    defaultMessage: 'failed'
  },
  canceled: {
    id: 'DataChip.canceled',
    description: 'canceled datachip text',
    defaultMessage: 'canceled'
  },
  low: {
    id: 'DataChip.low',
    description: 'low datachip text',
    defaultMessage: 'low'
  },
  medium: {
    id: 'DataChip.medium',
    description: 'medium datachip text',
    defaultMessage: 'medium'
  },
  high: {
    id: 'DataChip.high',
    description: 'high datachip text',
    defaultMessage: 'high'
  },
  open: {
    id: 'DataChip.open',
    description: 'open datachip text',
    defaultMessage: 'open'
  },
  closed: {
    id: 'DataChip.closed',
    description: 'closed datachip text',
    defaultMessage: 'closed'
  },
  ackd: {
    id: 'DataChip.ackd',
    description: 'ackd datachip text',
    defaultMessage: 'ackd'
  },
  ackdUpperCase: {
    id: 'DataChip.ackdUpperCase',
    description: 'ackd upper case datachip text',
    defaultMessage: 'ACKD'
  },
  acknowledged: {
    id: 'DataChip.acknowledged',
    description: 'acknowledged datachip text',
    defaultMessage: 'acknowledged'
  },
  enabledYes: {
    id: 'DataChip.enabledYes',
    description: 'EnabledYes datachip text',
    defaultMessage: 'yes'
  },
  enabledNo: {
    id: 'DataChip.enabledNo',
    description: 'EnabledNo datachip text',
    defaultMessage: 'no'
  },
  active: {
    id: 'DataChip.active',
    description: 'Active datachip text',
    defaultMessage: 'Active'
  },
  deactivated: {
    id: 'DataChip.deactivated',
    description: 'Deactivated datachip text',
    defaultMessage: 'Deactivated'
  },
  ready: {
    id: 'DataChip.ready',
    description: 'Ready datachip text',
    defaultMessage: 'Ready'
  },
  unavailable: {
    id: 'DataChip.unavailable',
    description: 'Unavailable datachip text',
    defaultMessage: 'Unavailable'
  }
})

export default messages
