import * as yup from 'yup'
import {stringSchema, usersSchema} from 'utils/yupHelpers'

const initialDateSchema = () => {
  return yup
    .date()
    .typeError('Should be a valid date')
    .required('Initial date is required')
}

const configurationSchema = () => {
  return yup.object().shape({
    bucketSize: stringSchema({name: 'Bucket'}),
    frequency: stringSchema({name: 'Frequency'}),
    initialDate: initialDateSchema(),
    name: stringSchema({name: 'Name'}),
    status: stringSchema({name: 'Status'}),
    timezone: stringSchema({name: 'Timezone'}),
  })
}

const selectedSensorsSchema = () => {
  return yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .required()
    .min(1, 'Select at least one signal')
}

const sensorSchema = () => {
  return yup.object().shape({
    aggregationType: stringSchema({name: 'Aggregation'}),
    name: stringSchema({name: 'Sensor'}),
    lineStyle: stringSchema({name: 'Line'}),
    color: stringSchema({name: 'Color'}),
    display: yup
      .object()
      .shape({
        table: yup
          .boolean()
          .test('display', 'Select at least one display type', function() {
            return this.parent.table || this.parent.chart
          }),
        chart: yup
          .boolean()
          .test('display', 'Select at least one display type', function() {
            return this.parent.table || this.parent.chart
          }),
      })
      .required(),
  })
}

const sensorsSchema = () => {
  return yup.array().of(sensorSchema())
}

const metricSchema = () => {
  return yup.object().shape({
    asset: stringSchema({name: 'Asset'}),
    selectedSensors: selectedSensorsSchema(),
    sensors: sensorsSchema(),
  })
}

const metricsSchema = () => {
  return yup
    .array()
    .of(metricSchema())
    .min(1, 'At least one rule is required')
    .required()
}

export const validationSchema = yup.object().shape({
  configuration: configurationSchema(),
  metrics: metricsSchema(),
  users: usersSchema(),
})
