import moment from 'moment'
import * as t from '../actionTypes'

const initialState = {
  // In milliseconds
  minDate: moment()
    .subtract(7, 'days')
    .valueOf(),
  maxDate: moment().valueOf()
}

const historic = (state = initialState, action) => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case t.SET_MIN_DATE_TIME_REQUEST:
      return { ...state, minDate: action.payload}

    case t.SET_MAX_DATE_TIME_REQUEST:
      return { ...state, maxDate: action.payload}

    default:
  }

  return state
}

export default historic
export const MOUNTED_AT = 'historic'

// Selectors
const getMinDate = substate => substate[MOUNTED_AT].minDate
const getMaxDate = substate => substate[MOUNTED_AT].maxDate

export { getMinDate, getMaxDate }
