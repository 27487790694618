import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

import EditGeofence from '../EditGeofence'
import MapView from './MapView'
import NewGeofence from '../NewGeofence'
import QueryPanel from './QueryPanel'
import SelectGeofenceDialog from './SelectGeofenceDialog'

import messages from './messages'

import { composeLocalQuery, fitToBounds } from '../../../utils'

import { DEFAULT_GROUP_ID } from 'utils/constants'
import { logError } from 'utils/http'

const styles = {
  dialog: {
    minWidth: 600
  },
  geofence: {
    width: '100%',
    height: '100%'
  },
  geofencePaper: {
    width: '90%',
    height: '85%',
    maxWidth: '90%',
    maxHeight: '85%'
  }
}

class Geofences extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      bounds: undefined,
      geofences: [],
      itemMenuOpen: {},
      isDetailedRuleInstancesLoading: false,
      isGeofencesLoading: false,
      isRuleInstancesLoading: false,
      mapTypeConstrolPosition: undefined,
      ruleInstances: [],
      selectedFromTheList: null,
      selectedGeofence: null
    }

    this.mapContainerRef = React.createRef()
  }

  componentDidMount() {
    const { groupId } = this.props

    if (groupId !== DEFAULT_GROUP_ID) {
      this.getGeofencesAndRuleInstances()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupId, isEditGeofenceDialogOpen, isNewGeofenceDialogOpen } = this.props

    if (!prevState.mapTypeConstrolPosition && window.google) {
      this.setState({
        mapTypeConstrolPosition: window.google.maps.ControlPosition.TOP_CENTER
      })
    }
    if (prevProps.groupId !== groupId && groupId !== DEFAULT_GROUP_ID) {
      this.getGeofencesAndRuleInstances()
    }
    if (
      prevProps.isNewGeofenceDialogOpen && !isNewGeofenceDialogOpen ||
      prevProps.isEditGeofenceDialogOpen && !isEditGeofenceDialogOpen
    ) {
      this.getGeofencesAndRuleInstances()
    }
    if (prevProps.groupId !== groupId && groupId === DEFAULT_GROUP_ID) {
      this.setState({
        alertMessages: false,
        alertMessagesText: [''],
        alertMessagesTitle: '',
        alertMessagesType: '',
        bounds: undefined,
        geofences: [],
        itemMenuOpen: {},
        isDetailedRuleInstancesLoading: false,
        isGeofencesLoading: false,
        isRuleInstancesLoading: false,
        mapTypeConstrolPosition: undefined,
        ruleInstances: [],
        selectedFromTheList: null,
        selectedGeofence: null
      })
    }
  }

  getGeofencesAndRuleInstances = () => {
    const page = 1
    const length = 50

    const p1 = this.getGeofences(page, length)
    const p2 = this.getRuleInstances(page, length).then(() => this.getDetailedRuleInstances())

    Promise.allSettled([p1, p2]).then(() => {
      this.joinGeofencesAndRuleInstances()
    })
  }

  getGeofences = async (page, length, result = []) => {
    const { getGeofences, groupId, intl } = this.props

    this.setState({
      geofences: [],
      isGeofencesLoading: true
    })

    const query = composeLocalQuery(page, length)

    try {
      const response = await getGeofences(groupId, query)
      const geofences = response.data
      const totalGeofences = [...result, ...geofences]

      if (geofences.length === length) {
        this.getGeofences(page + 1, length, totalGeofences)
      } else {
        this.setState({
          geofences: totalGeofences,
          isGeofencesLoading: false,
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      }
    } catch (error) {
      switch (error.response.status) {
        case 400:
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
            alertMessagesText: [this.formatMessage(messages.error400Message)]
          })
          break
        case 401:
          let message
          if (intl.locale === 'en') message = error.response.message
          else message = this.formatMessage(messages.error401Message)
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
            alertMessagesText: [message]
          })
          break
        case 403:
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
            alertMessagesText: [this.formatMessage(messages.error403Message)]
          })
          break
        case 404:
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
            alertMessagesText: [this.formatMessage(messages.error404Message)]
          })
          break
        case 406:
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
            alertMessagesText: [this.formatMessage(messages.error406Message)]
          })
          break
        case 500:
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
            alertMessagesText: [error.response.data.error_description]
          })
          break
        default:
          this.setState({
            geofences: [],
            isGeofencesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error.response)
      }
    }
  }

  getRuleInstances = async (page, length, result = []) => {
    const { getRuleInstances, groupId, intl } = this.props

    this.setState({
      ruleInstances: [],
      isRuleInstancesLoading: true
    })

    const query = composeLocalQuery(page, length)

    try {
      const response = await getRuleInstances(groupId, query)
      const ruleInstances = response.data
      const totalRuleInstances = [...result, ...ruleInstances]
      if (ruleInstances.length === length) {
        await this.getRuleInstances(page + 1, length, totalRuleInstances)
      } else {
        this.setState({
          ruleInstances: totalRuleInstances,
          isRuleInstancesLoading: false,
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      }
    } catch (error) {
      switch (error.response.status) {
        case 400:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
            alertMessagesText: [this.formatMessage(messages.error400Message)]
          })
          break
        case 401:
          let message
          if (intl.locale === 'en') message = error.response.message
          else message = this.formatMessage(messages.error401Message)
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
            alertMessagesText: [message]
          })
          break
        case 403:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
            alertMessagesText: [this.formatMessage(messages.error403Message)]
          })
          break
        case 404:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
            alertMessagesText: [this.formatMessage(messages.error404Message)]
          })
          break
        case 406:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
            alertMessagesText: [this.formatMessage(messages.error406Message)]
          })
          break
        case 500:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
            alertMessagesText: [error.response.data.error_description]
          })
          break
        default:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error.response)
      }
    }
  }

  getDetailedRuleInstances = async () => {
    const { getRuleInstance, intl } = this.props
    const { ruleInstances } = this.state

    this.setState({
      isDetailedRuleInstancesLoading: true
    })

    let detailedRuleInstances
    try {
      const ruleInstancesResponses = await Promise.all(ruleInstances.map(ri => getRuleInstance(ri.hashId)))
      detailedRuleInstances = ruleInstancesResponses.map(response => response.data)
    } catch (error) {
      switch (error.response.status) {
        case 400:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
            alertMessagesText: [this.formatMessage(messages.error400Message)]
          })
          break
        case 401:
          let message
          if (intl.locale === 'en') message = error.response.message
          else message = this.formatMessage(messages.error401Message)
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
            alertMessagesText: [message]
          })
          break
        case 403:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
            alertMessagesText: [this.formatMessage(messages.error403Message)]
          })
          break
        case 404:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
            alertMessagesText: [this.formatMessage(messages.error404Message)]
          })
          break
        case 406:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
            alertMessagesText: [this.formatMessage(messages.error406Message)]
          })
          break
        case 500:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
            alertMessagesText: [error.response.data.error_description]
          })
          break
        default:
          this.setState({
            ruleInstances: [],
            isRuleInstancesLoading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error.response)
      }
    } finally {
      this.setState({
        isDetailedRuleInstancesLoading: false,
        ruleInstances: [...detailedRuleInstances]
      })
    }
  }

  findRuleInstancesAssociatedToGeofence = (geofence, ruleInstances) => {
    const rils = ruleInstances.reduce((ret, ri) => {
      if (geofence.hashId === ri?.variables[0]?.value) {
        ret.push(ri)
      }
      return ret
    }, [])
    return rils
  }

  joinGeofencesAndRuleInstances = () => {
    const { geofences, ruleInstances } = this.state

    const geofencesAndRuleInstances = geofences.map(geofence => {
      const geofenceAndRuleInstance = { ...geofence }
      const assignedRuleIntances = this.findRuleInstancesAssociatedToGeofence(geofence, ruleInstances)
      if (assignedRuleIntances.length > 0) {
        geofenceAndRuleInstance.ruleInstances = assignedRuleIntances
      }
      return geofenceAndRuleInstance
    })

    this.setState({
      geofences: [...geofencesAndRuleInstances]
    })
  }

  fitToBounds = (shape, form) => {
    const bounds = fitToBounds(shape, form)
    this.setState({
      bounds
    })
  }

  deleteGeofenceAndRuleInstances = geofence => {
    const { deleteGeofence, deleteRuleInstance } = this.props

    const ruleInstancesToDelete = geofence.ruleInstances

    const promises = []
    const { promise: promise1 } = deleteGeofence(geofence)
    promises.push(promise1)

    if (ruleInstancesToDelete) {
      ruleInstancesToDelete.forEach(ritd => {
        const { promise: promise2 } = deleteRuleInstance(ritd)
        promises.push(promise2)
      })
    }

    Promise.all(promises).then(() => {
      this.getGeofencesAndRuleInstances()
    })
  }

  onSelectFromTheList = geofence => {
    this.setState({
      selectedGeofence: geofence.value,
      selectedFromTheList: geofence
    })
    if (geofence && geofence.value) {
      this.fitToBounds(geofence.value.shapeType, geofence.value)
    }
  }

  onSelectFromTheMap = geofence => {
    if (geofence) {
      this.setState({
        selectedGeofence: geofence,
        selectedFromTheList: { value: geofence, label: geofence.name }
      })
      this.fitToBounds(geofence.shapeType, geofence)
    }
  }

  onGeofenceChange = geofence => {
    this.setState({
      selectedFromTheList: geofence
    })
  }

  onToggle = geofence => {
    const { itemMenuOpen } = this.state

    const geofenceId = geofence.hashId
    const isItemMenuOpen = itemMenuOpen[geofenceId] ? itemMenuOpen[geofenceId] : false

    this.setState(state => {
      return {
        selectedGeofence: geofence,
        itemMenuOpen: {
          ...state.itemMenuOpen,
          [geofenceId]: !isItemMenuOpen
        }
      }
    })
  }

  onClose = geofenceId => {
    const { itemMenuOpen } = this.state

    this.setState({
      itemMenuOpen: {
        ...itemMenuOpen,
        [geofenceId]: false
      }
    })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  render() {
    const {
      classes,
      closeCreateAlert,
      closeDeleteDialog,
      closeEditGeofenceDialog,
      closeNewGeofenceDialog,
      closeSelectDialog,
      createAlertMessage,
      deleteMessage,
      deletingGeofenceAndRuleInstance,
      groupId,
      intl,
      isCreateAlertOpen,
      isDeleteDialogOpen,
      isEditGeofenceDialogOpen,
      isNewGeofenceDialogOpen,
      isSelectDialogOpen,
      openDeleteDialog,
      openEditGeofenceDialog,
      openNewGeofenceDialog,
      openSelectDialog,
      privileges
    } = this.props

    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      bounds,
      geofences,
      itemMenuOpen,
      isDetailedRuleInstancesLoading,
      isGeofencesLoading,
      isRuleInstancesLoading,
      selectedFromTheList,
      selectedGeofence
    } = this.state

    const { formatMessage } = intl

    return (
      <div className='content-container' id='content' style={{ height: 'calc(100vh - 50px)' }}>
        <div style={{ padding: 0, height: '100%', display: 'flex' }}>
          <QueryPanel
            alertMessages={alertMessages}
            alertMessagesText={alertMessagesText}
            alertMessagesTitle={alertMessagesTitle}
            alertMessagesType={alertMessagesType}
            fitToBounds={this.fitToBounds}
            geofences={geofences}
            groupId={groupId}
            handleClose={this.onClose}
            handleGeofenceChange={this.onGeofenceChange}
            handleSelectFromTheList={this.onSelectFromTheList}
            handleToggle={this.onToggle}
            isDetailedRuleInstancesLoading={isDetailedRuleInstancesLoading}
            isGeofencesLoading={isGeofencesLoading}
            isRuleInstancesLoading={isRuleInstancesLoading}
            itemMenuOpen={itemMenuOpen}
            openDeleteDialog={openDeleteDialog}
            openEditGeofenceDialog={openEditGeofenceDialog}
            openNewGeofenceDialog={openNewGeofenceDialog}
            privileges={privileges}
            selectedFromTheList={selectedFromTheList}
          />
          <MapView
            bounds={bounds}
            fitToBounds={this.fitToBounds}
            geofences={geofences}
            groupId={groupId}
            handleSelectFromTheMap={this.onSelectFromTheMap}
            mapContainerRef={this.mapContainerRef}
            openSelectDialog={openSelectDialog}
          />
        </div>

        {/* CREATED DIALOG */}
        <Dialog classes={{ paper: classes.dialog }} onClose={closeCreateAlert} open={isCreateAlertOpen}>
          <DialogTitle>
            <IconButton
              onClick={closeCreateAlert}
              style={{
                position: 'absolute',
                right: 3,
                top: 3
              }}
            >
              <CloseIcon />
            </IconButton>
            {formatMessage(messages.somethingWentWrong)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText color='textPrimary' style={{ display: 'inline-flex' }}>
              {createAlertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='primary-action-button' onClick={closeCreateAlert} style={{ marginLeft: 15 }}>
              {formatMessage(messages.ok)}
            </Button>
          </DialogActions>
        </Dialog>

        {/* DELETE DIALOG */}
        <Dialog classes={{ paper: classes.dialog }} onClose={closeDeleteDialog} open={isDeleteDialogOpen}>
          <DialogTitle>
            <IconButton
              onClick={closeDeleteDialog}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
            {deleteMessage}
          </DialogTitle>
          <DialogActions>
            <Button
              className='delete-button'
              disabled={!privileges.canDeleteGeofence}
              onClick={() => {
                this.deleteGeofenceAndRuleInstances(selectedGeofence)
              }}
              style={{ marginLeft: 5, marginRight: 0 }}
            >
              {deletingGeofenceAndRuleInstance && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              {formatMessage(messages.delete)}
            </Button>
          </DialogActions>
        </Dialog>

        {/* SELECTED GEOFENCE DIALOG */}
        <SelectGeofenceDialog
          isOpen={isSelectDialogOpen}
          onClose={closeSelectDialog}
          onDeleteClick={() => {
            closeSelectDialog()
            openDeleteDialog(selectedGeofence.name, selectedGeofence.hashId, selectedGeofence.version)
          }}
          onEditClick={openEditGeofenceDialog}
          privileges={privileges}
          selectedGeofence={selectedGeofence}
        />

        {/* NEW GEOFENCE DIALOG */}
        <Dialog
          classes={{ root: classes.geofence, paper: classes.geofencePaper }}
          onClose={closeNewGeofenceDialog}
          open={isNewGeofenceDialogOpen}
        >
          <NewGeofence groupId={groupId} privileges={privileges} />
        </Dialog>

        {/* EDIT GEOFENCE DIALOG */}
        <Dialog
          classes={{ root: classes.geofence, paper: classes.geofencePaper }}
          onClose={closeEditGeofenceDialog}
          open={isEditGeofenceDialogOpen}
        >
          <EditGeofence geofence={selectedGeofence} groupId={groupId} privileges={privileges} />
        </Dialog>
      </div>
    )
  }
}

Geofences.propTypes = {
  classes: PropTypes.object.isRequired,
  closeCreateAlert: PropTypes.func.isRequired,
  closeDeleteDialog: PropTypes.func.isRequired,
  closeEditGeofenceDialog: PropTypes.func.isRequired,
  closeNewGeofenceDialog: PropTypes.func.isRequired,
  closeSelectDialog: PropTypes.func.isRequired,
  createAlertMessage: PropTypes.string.isRequired,
  deleteGeofence: PropTypes.func.isRequired,
  deleteMessage: PropTypes.string.isRequired,
  deleteRuleInstance: PropTypes.func.isRequired,
  deletingGeofenceAndRuleInstance: PropTypes.bool.isRequired,
  getGeofences: PropTypes.func.isRequired,
  getRuleInstance: PropTypes.func.isRequired,
  getRuleInstances: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isCreateAlertOpen: PropTypes.bool.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
  isEditGeofenceDialogOpen: PropTypes.bool.isRequired,
  isNewGeofenceDialogOpen: PropTypes.bool.isRequired,
  isSelectDialogOpen: PropTypes.bool.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  openEditGeofenceDialog: PropTypes.func.isRequired,
  openNewGeofenceDialog: PropTypes.func.isRequired,
  openSelectDialog: PropTypes.func.isRequired,
  privileges: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(Geofences))
