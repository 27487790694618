import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'

import ToolbarGroup from './ToolbarGroup'

const ZoomSelector = ({ onZoomSelected, predefinedZoomLevels, selectedZoomLevel }) => {
  if (!onZoomSelected) {
    return <div />
  }

  return (
    <ToolbarGroup>
      <Typography variant="body1" color="inherit" noWrap={true}>
        Zoom:
      </Typography>
      <DropDownMenu onChange={(e, k, v) => onZoomSelected(v)} value={selectedZoomLevel}>
        {predefinedZoomLevels.map(zoomLevel => (
          <MenuItem
            key={zoomLevel.value}
            value={zoomLevel.value}
            primaryText={zoomLevel.label}
            disabled={zoomLevel.disabled}
          />
        ))}
      </DropDownMenu>
    </ToolbarGroup>
  )
}

ZoomSelector.propTypes = {
  onZoomSelected: PropTypes.func,
  predefinedZoomLevels: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  selectedZoomLevel: PropTypes.number
}

export default ZoomSelector
