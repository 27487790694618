import { saveAs } from 'file-saver'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/GetApp'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { client, logError } from 'utils/http'

import messages from './messages'
import {
  mapSignalsToColumns,
  mapMeasurementsToAdvancedSignals,
  mapAdvancedSignalsToMeasurements,
  getNewAdvancedSignal
} from './utils'

import 'core-js/proposals/promise-all-settled'

const styles = {
  addAdvancedSignal: {
    alignItems: 'center',
    borderBottom: '2px solid transparent',
    color: '#32A0E9',
    cursor: 'pointer',
    display: 'inline-flex',
    fontWeight: '500',
    marginTop: '15px',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
      borderBottom: '2px solid #1377b9',
      color: '#1377b9'
    }
  },
  advancedSignal: {
    borderLeft: '4px solid #5e5d52',
    margin: '6px 0px'
  },
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
}

class AdvancedSignalsConfigDialog extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      loading: false,
      saving: false,
      signals: [],
      advancedSignals: [],
      previousAdvancedSignals: [],
      deviceConfigurationId: ''
    }
  }

  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) this.getDeviceConfigurationAndAdvancedSignals()
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    if (!prevProps.isOpen && isOpen) this.getDeviceConfigurationAndAdvancedSignals()
  }

  getDeviceConfigurationAndAdvancedSignals = () => {
    const { groupId, deviceEid, getDevice, deviceType, fetchDeviceNonVolatileConfiguration } = this.props
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          let configuration = null
          switch (deviceType) {
            case 'CS100':
              const deviceResponse = await getDevice(deviceEid, groupId)
              configuration = deviceResponse.data.deviceConfiguration
              break
            case 'CS500':
              const configResponse = await fetchDeviceNonVolatileConfiguration(groupId, deviceEid)
              configuration = configResponse[deviceEid].parsedConfiguration.deviceConfiguration
              break
          }
          const signals = mapSignalsToColumns(configuration)
          this.setState({
            signals,
            deviceConfigurationId: configuration.id || ''
          })

          const measurementsResponse = await client.getMeasurements(groupId, [deviceEid])
          const measurements = measurementsResponse.data
          const previousAdvancedSignals = mapMeasurementsToAdvancedSignals(measurements, signals)
          const signalIds = signals.map(({ signalId }) => signalId)
          const advancedSignals = cloneDeep(previousAdvancedSignals).filter(({ targetId }) =>
            signalIds.includes(targetId)
          )
          if (advancedSignals.length === 0) {
            advancedSignals.push(getNewAdvancedSignal())
          }

          this.setState({
            advancedSignals,
            previousAdvancedSignals
          })
        } catch (error) {
          logError(error)
        } finally {
          this.setState({
            loading: false
          })
        }
      }
    )
  }

  handleTextFieldChange = (event, field, index) => {
    const { value } = event.target
    this.setState(({ advancedSignals }) => {
      const newAdvancedSignals = [...advancedSignals]
      newAdvancedSignals[index][field] = value
      if (field !== 'unit') newAdvancedSignals[index][field + 'Error'] = ''
      return {
        advancedSignals: newAdvancedSignals
      }
    })
  }

  handleAutocompleteChange = (event, fieldValue, field, index) => {
    let value = ''
    if (fieldValue) value = field === 'operator' ? fieldValue.value : fieldValue.id
    this.setState(({ advancedSignals }) => {
      const newAdvancedSignals = [...advancedSignals]
      newAdvancedSignals[index][field] = value
      if (field === 'operationType' && fieldValue?.id === '1') {
        newAdvancedSignals[index]['unit'] = 'hours'
      }
      return {
        advancedSignals: newAdvancedSignals
      }
    })
  }

  handleDeleteAdvancedSignal = index => {
    this.setState(({ advancedSignals }) => {
      const newAdvancedSignals = [...advancedSignals]
      newAdvancedSignals.splice(index, 1)
      return {
        advancedSignals: newAdvancedSignals
      }
    })
  }

  handleAddAdvancedSignal = () => {
    this.setState(({ advancedSignals }) => ({
      advancedSignals: [...advancedSignals, getNewAdvancedSignal()]
    }))
  }

  getAreFieldErrors = () => {
    const { advancedSignals } = this.state
    let error = false
    const updatedAdvancedSignalsFields = [...advancedSignals]
    advancedSignals.forEach((advancedSignal, index) => {
      const { name, targetId, operator, value, operationType, unit } = advancedSignal
      if (name === '') {
        updatedAdvancedSignalsFields[index]['nameError'] = this.formatMessage(messages.thisFieldIsRequired)
        error = true
      }
      if (targetId === '') {
        updatedAdvancedSignalsFields[index]['targetIdError'] = this.formatMessage(messages.thisFieldIsRequired)
        error = true
      }
      if (operator === '' && operationType !== '3' && operationType !== '') {
        updatedAdvancedSignalsFields[index]['operatorError'] = this.formatMessage(messages.thisFieldIsRequired)
        error = true
      }
      if (value === '' && operationType !== '3' && operationType !== '') {
        updatedAdvancedSignalsFields[index]['valueError'] = this.formatMessage(messages.thisFieldIsRequired)
        error = true
      }
      if (operationType === '') {
        updatedAdvancedSignalsFields[index]['operationTypeError'] = this.formatMessage(messages.thisFieldIsRequired)
        error = true
      }
    })
    if (error) {
      this.setState({
        advancedSignals: updatedAdvancedSignalsFields
      })
    }
    return error
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({
      loading: false,
      saving: false,
      signals: [],
      advancedSignals: [],
      previousAdvancedSignals: []
    })
    onClose()
  }

  handleExport = () => {
    const { deviceEid, deviceName, deviceType } = this.props
    const { advancedSignals, signals, deviceConfigurationId } = this.state
    const areEmptyFields = this.getAreFieldErrors()
    if (!areEmptyFields) {
      const deviceData = { deviceEid, deviceName, deviceType, deviceConfigurationId }
      const advancedSignalsWithoutHashIds = advancedSignals.map(
        ({ hashId, measurementSignalHashId, measurementDeviceHashId, ...rest }) => ({ ...rest })
      )
      const measurements = mapAdvancedSignalsToMeasurements(advancedSignalsWithoutHashIds, signals, deviceData)
      const advancedSignalsData = JSON.stringify(measurements)
      const blob = new Blob([advancedSignalsData], { type: 'application/json' })
      saveAs(blob, 'advancedSignals.json')
    }
  }

  handleSave = () => {
    const { groupId, deviceEid, deviceName, deviceType } = this.props
    const { advancedSignals, previousAdvancedSignals, signals, deviceConfigurationId } = this.state
    const areEmptyFields = this.getAreFieldErrors()
    if (!areEmptyFields) {
      this.setState(
        {
          saving: true
        },
        async () => {
          const advancedSignalsHashIds = advancedSignals.filter(signal => signal.hashId).map(signal => signal.hashId)
          const deletedAdvancedSignals = previousAdvancedSignals.filter(
            ({ hashId }) => !advancedSignalsHashIds.includes(hashId)
          )

          const advancedSignalDeleteRequests = deletedAdvancedSignals.map(({ hashId }) =>
            client.deleteMeasurement(hashId, groupId)
          )

          try {
            await Promise.allSettled(advancedSignalDeleteRequests)
            const { newAdvancedSignals, updatedAdvancedSignals } = advancedSignals.reduce(
              (acc, advancedSignal) => {
                if (advancedSignal.hashId === '') {
                  return {
                    ...acc,
                    newAdvancedSignals: acc.newAdvancedSignals.concat([advancedSignal])
                  }
                } else {
                  const previousAdvancedSignal = previousAdvancedSignals.find(
                    ({ hashId }) => hashId === advancedSignal.hashId
                  )
                  const {
                    name: previousName,
                    targetId: previousTargetId,
                    operator: previousOperator,
                    value: previousValue,
                    unit: previousUnit
                  } = previousAdvancedSignal
                  const { name, targetId, operator, value, unit } = advancedSignal
                  if (
                    previousName !== name ||
                    previousTargetId !== targetId ||
                    previousOperator !== operator ||
                    previousValue !== value ||
                    previousUnit !== unit
                  ) {
                    return {
                      ...acc,
                      updatedAdvancedSignals: acc.updatedAdvancedSignals.concat([advancedSignal])
                    }
                  } else return acc
                }
              },
              { newAdvancedSignals: [], updatedAdvancedSignals: [] }
            )
            const deviceData = { deviceEid, deviceName, deviceType, deviceConfigurationId }
            if (newAdvancedSignals.length > 0) {
              const newMeasurements = mapAdvancedSignalsToMeasurements(newAdvancedSignals, signals, deviceData)
              await client.newMeasurements(groupId, newMeasurements)
            }
            if (updatedAdvancedSignals.length > 0) {
              const updatedMeasurements = mapAdvancedSignalsToMeasurements(updatedAdvancedSignals, signals, deviceData)
              const updateMeasurementsRequests = updatedMeasurements.map(measurement =>
                client.updateMeasurement(measurement.hashId, groupId, measurement)
              )
              await Promise.allSettled(updateMeasurementsRequests)
            }
          } catch (error) {
            logError(error)
          } finally {
            this.handleClose()
          }
        }
      )
    }
  }

  renderAdvancedSignals = () => {
    const { classes } = this.props
    const { advancedSignals, signals } = this.state

    const signalOptions = signals.map(signal => {
      let signalTypeText = this.formatMessage(messages.canBusSignals)
      if (signal.signalType === 'mfio') signalTypeText = this.formatMessage(messages.mfioSignals)
      return { ...signal, signalTypeText }
    })

    const operatorOptions = [
      { label: '=', value: '==' },
      { label: '>', value: '>' },
      { label: '>=', value: '>=' },
      { label: '<', value: '<' },
      { label: '<=', value: '<=' },
      { label: '!=', value: '!=' }
    ]

    const operationTypeOptions = [
      { id: '0', name: this.formatMessage(messages.raw) },
      { id: '1', name: this.formatMessage(messages.time) },
      { id: '2', name: this.formatMessage(messages.transtions) },
      { id: '5', name: this.formatMessage(messages.points) }
    ]

    return advancedSignals.map((advancedSignal, index) => {
      const isThresholdNeeded = advancedSignal.operationType !== '' && advancedSignal.operationType !== '3'
      return (
        <Grid key={index} className={classes.advancedSignal} container spacing={3}>
          <Grid item md={3} xs={6}>
            <TextField
              error={advancedSignal.nameError !== ''}
              fullWidth
              helperText={advancedSignal.nameError}
              label={this.formatMessage(messages.name)}
              onChange={event => this.handleTextFieldChange(event, 'name', index)}
              required
              value={advancedSignal.name}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event, fieldValues) =>
                this.handleAutocompleteChange(event, fieldValues, 'operationType', index)
              }
              options={operationTypeOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  error={advancedSignal.operationTypeError !== ''}
                  helperText={advancedSignal.operationTypeError}
                  label={this.formatMessage(messages.operationType)}
                  required
                />
              )}
              value={operationTypeOptions.find(option => option.id === advancedSignal.operationType) || null}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Autocomplete
              classes={{ groupLabel: classes.groupLabel }}
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              groupBy={option => option.signalTypeText}
              onChange={(event, fieldValues) => this.handleAutocompleteChange(event, fieldValues, 'targetId', index)}
              options={signalOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  error={advancedSignal.targetIdError !== ''}
                  helperText={advancedSignal.targetIdError}
                  label={this.formatMessage(messages.sourceSignal)}
                  required
                />
              )}
              value={signalOptions.find(signal => signal.id === advancedSignal.targetId) || null}
            />
          </Grid>
          <Grid item md={1} xs={4}>
            <TextField
              disabled={advancedSignal.operationType === '1'}
              fullWidth
              label={this.formatMessage(messages.unit)}
              onChange={event => this.handleTextFieldChange(event, 'unit', index)}
              value={advancedSignal.unit}
            />
          </Grid>
          <Grid item md={1} xs={3}>
            {isThresholdNeeded ? (
              <Autocomplete
                fullWidth
                getOptionLabel={option => option?.label || option}
                getOptionSelected={(option, id) => option.value === id.value}
                onChange={(event, fieldValues) => this.handleAutocompleteChange(event, fieldValues, 'operator', index)}
                options={operatorOptions}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={advancedSignal.operatorError !== ''}
                    helperText={advancedSignal.operatorError}
                    label={this.formatMessage(messages.operator)}
                    required
                  />
                )}
                value={operatorOptions.find(option => option.value === advancedSignal.operator) || null}
              />
            ) : null}
          </Grid>
          <Grid item md={1} xs={4}>
            {isThresholdNeeded ? (
              <TextField
                error={advancedSignal.valueError !== ''}
                fullWidth
                helperText={advancedSignal.valueError}
                label={this.formatMessage(messages.value)}
                onChange={event => this.handleTextFieldChange(event, 'value', index)}
                required
                type='number'
                value={advancedSignal.value}
              />
            ) : null}
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => this.handleDeleteAdvancedSignal(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      )
    })
  }

  render() {
    const { isOpen, classes } = this.props
    const { loading, advancedSignals, saving } = this.state

    const advancedSignalsWithErrors = advancedSignals.filter(
      ({ nameError, targetIdError, operatorError, valueError }) =>
        nameError !== '' || targetIdError !== '' || operatorError !== '' || valueError !== ''
    )

    const buttonDisabled = loading || advancedSignalsWithErrors.length > 0 || saving
    const MAX_ALLOWED_NUM_OF_ADVANCED_SIGNALS = 5

    return (
      <Dialog fullWidth maxWidth='lg' open={isOpen} scroll='paper'>
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.title)}
          <IconButton
            onClick={this.handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Grid container justify='center'>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container>
              <React.Fragment>
                {this.renderAdvancedSignals()}
                {advancedSignals.length < MAX_ALLOWED_NUM_OF_ADVANCED_SIGNALS && (
                  <span className={classes.addAdvancedSignal} onClick={this.handleAddAdvancedSignal}>
                    <Icon className='zmdi zmdi-plus' style={{ fontSize: '14px' }} />
                    {this.formatMessage(messages.addAdvancedSignal)}
                  </span>
                )}
              </React.Fragment>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='secondary-action-button' disabled={buttonDisabled} onClick={this.handleExport}>
            <DownloadIcon />
            {this.formatMessage(messages.exportConfiguration)}
          </Button>
          <Divider flexItem orientation='vertical' />
          <Button className='cancel-button' onClick={this.handleClose}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={buttonDisabled} onClick={this.handleSave}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AdvancedSignalsConfigDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceEid: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(AdvancedSignalsConfigDialog))
