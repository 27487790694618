import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/dataPlans'
import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import DevicesConnected from './Devices'

const mapStateToProps = state => ({
  canReadDevices: resolvePermissions(state, c.DEVICE_READ),
  canWriteDevices: resolvePermissions(state, c.DEVICE_EDIT),
  canCreateDevices: resolvePermissions(state, c.DEVICE_CREATE),
  canReadDataPlans: groupUtils.hasPermission(state, c.DATAPLAN_READ),
  canUseProductionTestMode: resolvePermissions(state, c.DEVICESIM_EDIT),
  canConsumeAccessPasses: groupUtils.hasPermission(state, c.ACCESSPASS_CONSUME),
  groupId: groupUtils.getSelectedGroup(state).id,
  loadingGroup: groupUtils.getLoadingGroup(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDynamicCSNodes: csNode.actions.fetchDynamicCSNodes,
      getCSNodeSIMStatus: csNode.actions.fetchDeviceSIMStatus,
      getDataPlans: actions.getDataPlans,
      unregisterCS100Device: csNode.actions.unregisterCS100Device
    },
    dispatch
  )

const Devices = connect(mapStateToProps, mapDispatchToProps)(DevicesConnected)

export { Devices }
