import * as c from './constants'

import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'

import translations from 'i18n/locales'

// Base info
const notificationCriticalities = () => {

  const i18n = translations[localStorage.getItem('user_language')]

  return[

    {
      key: c.NOTIFICATION_CRITICALITY_HIGH,
      label: i18n['notificationCriticallity.high'],
      color: {
        primary: red[500],
        secondary: red[200]
      },
      explanation:
        i18n['notificationCriticallity.highExplanation'],
      iconcolor: '#d9534f'
    },
    {
      key: c.NOTIFICATION_CRITICALITY_MEDIUM,
      label: i18n['notificationCriticallity.medium'],
      color: {        
        primary: amber[700],
        secondary: amber[200]
      },
      explanation:
      i18n['notificationCriticallity.mediumExplanation'],
      iconcolor: '#f0ad4e'
    },
    {
      key: c.NOTIFICATION_CRITICALITY_LOW,
      label: i18n['notificationCriticallity.low'],
      color: {
        primary: blue[600],
        secondary: blue[400]
      },
      explanation:
      i18n['notificationCriticallity.lowExplanation'],
      iconcolor: '#0077FF'
    },
    {
      key: c.NOTIFICATION_CRITICALITY_NORMAL,
      label: i18n['notificationCriticallity.normal'],
      color: {
        primary: green[500],
        secondary: green[200]
      },
      explanation: '',
      iconcolor: '#4CAF50'
    },    
    {
      key: c.NOTIFICATION_CRITICALITY_NORMAL_LASTCHECK_HIGH,
      label: i18n['notificationCriticallity.normal'],
      color: {
        primary: grey[500],
        secondary: grey[200]
      },
      explanation: '',
      iconcolor: '#9E9E9E'
    }
  ]
}

// Utility methods / selectors
const getNotificationCriticalityKeys = () => [
  c.NOTIFICATION_CRITICALITY_HIGH,
  c.NOTIFICATION_CRITICALITY_MEDIUM,
  c.NOTIFICATION_CRITICALITY_LOW,
  c.NOTIFICATION_CRITICALITY_NORMAL
]

const getNotificationCriticalityKeysWithCriticality = () => [
  c.NOTIFICATION_CRITICALITY_HIGH,
  c.NOTIFICATION_CRITICALITY_MEDIUM,
  c.NOTIFICATION_CRITICALITY_LOW
]

const findNotificationCriticality = notificationCriticalityKey =>
  notificationCriticalities().find(
    ({ key }) => key === notificationCriticalityKey
  )

// FIXME not used anywhere
const isValidNotificationCriticality = notificationCriticalityKey =>
  findNotificationCriticality(notificationCriticalityKey) === undefined

const getLabelNotificationCriticality = notificationCriticalityKey => {
  const r = findNotificationCriticality(notificationCriticalityKey)
  return r ? r.label : null
}

const getColorsByNotificationCriticality = notificationCriticalityKey => {
  const r = findNotificationCriticality(notificationCriticalityKey)
  return r ? r.color : { primary: undefined, secondary: undefined }
}

const getExplanationsByNotificationCriticality = notificationCriticalityKey => {
  const r = findNotificationCriticality(notificationCriticalityKey)
  return r ? r.explanation : ''
}

const getIconColorByNotificationCriticality = notificationCriticalityKey => {
  const r = findNotificationCriticality(notificationCriticalityKey)
  return r ? r.iconcolor : ''
}

const keyOfNotificationCriticalityNormal = c.NOTIFICATION_CRITICALITY_NORMAL

const getHighestCriticality = criticalities =>
  criticalities.reduce((ret, v) => {
    switch (ret) {
      case c.NOTIFICATION_CRITICALITY_HIGH:
        break

      case c.NOTIFICATION_CRITICALITY_MEDIUM:
        if (v === c.NOTIFICATION_CRITICALITY_HIGH) {
          return v
        }
        break

      case c.NOTIFICATION_CRITICALITY_LOW:
        if (
          v === c.NOTIFICATION_CRITICALITY_HIGH ||
          v === c.NOTIFICATION_CRITICALITY_MEDIUM
        ) {
          return v
        }
        break

      case c.NOTIFICATION_CRITICALITY_NORMAL:
        return v

      default:
    }
    return ret
  }, c.NOTIFICATION_CRITICALITY_NORMAL)

const getGroupCriticality = groupNotifications => {
  let groupCriticality = c.NOTIFICATION_CRITICALITY_NORMAL     
  if (groupNotifications !== undefined) {        
    if (groupNotifications.length > 0 && groupNotifications[0].criticality !== undefined) {             
      groupCriticality = groupNotifications[0].criticality       
    }
  }
  return groupCriticality
}

export {
  getNotificationCriticalityKeys,
  getNotificationCriticalityKeysWithCriticality,
  findNotificationCriticality,
  isValidNotificationCriticality,
  getLabelNotificationCriticality,
  getColorsByNotificationCriticality,
  getExplanationsByNotificationCriticality,
  getIconColorByNotificationCriticality,
  keyOfNotificationCriticalityNormal,
  getHighestCriticality,
  getGroupCriticality
}
