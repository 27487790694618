import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import Paper from '@material-ui/core/Paper'
import DateLabel from 'components/DateLabel'
import DataChip from 'components/DataChip'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import ActionsStateFilter from './ActionsStateFilter'
import ActionsStateGlobalActions from './ActionsStateGlobalActions'
import ActionDetailDialog from './ActionDetailDialog'
import ActionCancelDialog from './ActionCancelDialog'
import ActionRelaunchDialog from './ActionRelaunchDialog'
import messages from './messages'
import { composeLocalQuery, handleDownloadFile, handleDownloadTaskFile } from './utils'

import { DEFAULT_GROUP_ID, IMPORT_DASHBOARDS, DELETE_DASHBOARDS, IMPORT_ADVANCED_SIGNALS } from 'utils/constants'
import { client, logError } from 'utils/http'

class ActionsStateContent extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      tasks: [],
      selectedActions: [],
      selectedActionsStatus: [],
      archivedTasksHashIds: [],

      count: 0,
      start: 0,

      filter: {},
      filterStatus: {},
      filterArchived: false,
      filterDates: {},

      openActionDetailDialog: false,
      openActionCancelDialog: false,
      openActionRelaunchDialog: false,

      dialogTask: {},

      tableOptions: {
        // No data
        noDataText: <CircularProgress />,

        // Sorting
        // defaultSortName: this.state.order,
        // sortName: this.state.order,
        // defaultSortOrder: this.state.orderSort,
        // sortOrder: this.state.orderSort,
        // onSortChange: this.onSortChange,
        // sortIndicator: true,

        // Page size select
        onSizePerPageList: this.onSizePerPageList,
        sizePerPageList: [
          {
            text: '10',
            value: 10
          },
          {
            text: '20',
            value: 20
          },
          {
            text: '50',
            value: 50
          },
          {
            text: '100',
            value: 100
          }
        ], // you can change the dropdown list for size per page
        sizePerPage: 10, // which size per page you want to locate as default
        page: 1, // which page you want to show as default

        // Pagination
        onPageChange: this.onPageChange,
        ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 5, // the pagination bar size.
        prePage: this.formatMessage(messages.prePage), // Previous page button text
        nextPage: this.formatMessage(messages.nextPage), // Next page button text
        firstPage: this.formatMessage(messages.firstPage), // First page button text
        lastPage: this.formatMessage(messages.lastPage), // Last page button text
        paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage), // Accept bool or function
        paginationPosition: 'bottom', // default is bottom, top and both is all available
        hideSizePerPage: false, // You can hide the dropdown for sizePerPage
        alwaysShowAllBtns: false, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        variation: 'dropup',

        // Filter options
        onFilterChange: this.onFilterChange

        // Search options
        // onSearchChange: this.onSearchChange,
        // clearSearch: false,
        // searchDelayTime: 400 // A delay for triggering search after a keyup event
      }
    }

    this.ACTION_STATE_POLLING_INTERVAL = 5000
  }

  componentDidMount() {
    this.getTasks()
    this.startPoll()
  }

  componentDidUpdate(prevProps) {
    const { groupId, loadingGroup, secondaryGlobalQuery } = this.props

    if (prevProps.secondaryGlobalQuery !== secondaryGlobalQuery) {
      this.getTasks()
    }

    if (groupId && prevProps.groupId !== groupId) {
      this.setState(state => {
        const { noDataText, ...otherTableOptions } = state.tableOptions
        return {
          tasks: [],
          count: 0,
          tableOptions: { noDataText: <CircularProgress />, ...otherTableOptions }
        }
      })
    }

    if (prevProps.loadingGroup && !loadingGroup) {
      this.setState(state => {
        const { page, ...otherTableOptions } = state.tableOptions
        return {
          filter: {},
          filterStatus: {},
          filterArchived: false,
          filterDates: {},
          tableOptions: { page: 1, ...otherTableOptions },
          start: 0
        }
      }, this.getTasks)
    }
  }

  componentWillUnmount() {
    this.stopPool()
  }

  getTasks = () => {
    const { groupId, secondaryGlobalQuery } = this.props
    const {
      filter,
      filterStatus,
      filterArchived,
      filterDates,
      tableOptions: { page },
      tableOptions: { sizePerPage }
    } = this.state

    let query = composeLocalQuery(
      filter,
      filterStatus,
      filterArchived,
      filterDates,
      secondaryGlobalQuery,
      page,
      sizePerPage
    )

    if (groupId !== DEFAULT_GROUP_ID) {
      query += '&groupId=' + groupId
    }

    client
      .getTasks(query)
      .then(response => {
        const tasksCount = parseInt(response.headers['x-total-count'], 10)
        const tasksToShow = response.data
        //actualizar la tabla
        const tasksArchived = []
        if (tasksToShow.length === 0) {
          this.setState(state => {
            const { noDataText, ...otherTableOptions } = state.tableOptions
            return {
              tasks: tasksToShow,
              count: 0,
              archivedTasksHashIds: tasksArchived,
              tableOptions: { noDataText: this.formatMessage(messages.noTasksAvailable), ...otherTableOptions }
            }
          })
        } else {
          tasksToShow.map(theTask => {
            if (theTask.archived) {
              tasksArchived.push(theTask.hashId)
            }
            return theTask
          })

          this.setState({
            tasks: tasksToShow,
            count: tasksCount,
            archivedTasksHashIds: tasksArchived
          })
        }
      })
      .catch(response => {
        this.setState(state => {
          const { noDataText, ...otherTableOptions } = state.tableOptions
          return {
            tableOptions: { noDataText: this.formatMessage(messages.noTasksAvailable), ...otherTableOptions },
            count: 0
          }
        })
        logError(response)
      })
  }

  startPoll = () => {
    this.poolTimer = setTimeout(this.poolAction, this.ACTION_STATE_POLLING_INTERVAL)
  }

  stopPool = () => {
    clearTimeout(this.poolTimer)
  }

  poolAction = () => {
    this.stopPool()
    this.getTasks()
    this.startPoll()
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      state => {
        const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          start: (page - 1) * sizePerPage,
          tableOptions: { page, sizePerPage, ...otherTableOptions },
          tasks: []
        }
      },
      () => {
        this.getTasks()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      state => {
        const { sizePerPage: previousSizePerPage, ...otherTableOptions } = state.tableOptions
        return {
          tableOptions: { sizePerPage, ...otherTableOptions },
          tasks: []
        }
      },
      () => {
        this.getTasks()
      }
    )
  }

  onRowSelect = (row, isSelected, event, rowNumber) => {
    const { selectedActions, selectedActionsStatus } = this.state

    const element = row.hashId
    const elementStatus = row.status

    const newSelectedActions = selectedActions.map(action => action)
    const newSelectedActionsStatus = selectedActionsStatus.map(status => status)
    const indexOfAction = selectedActions.indexOf(element)

    if (isSelected) {
      if (indexOfAction < 0) {
        newSelectedActions.push(element)
        newSelectedActionsStatus.push(elementStatus)
      }
    } else {
      if (indexOfAction > -1) {
        newSelectedActions.splice(indexOfAction, 1)
        newSelectedActionsStatus.splice(indexOfAction, 1)
      }
    }

    this.setState({
      selectedActions: newSelectedActions,
      selectedActionsStatus: newSelectedActionsStatus
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedActions, selectedActionsStatus } = this.state

    let indexOfAction
    let element
    let elementStatus

    const newSelectedActions = selectedActions.map(action => action)
    const newSelectedActionsStatus = selectedActionsStatus.map(status => status)

    const actionsToProcess = rows.map(action => {
      return action
    })

    actionsToProcess.map(action => {
      element = action.hashId
      elementStatus = action.status
      indexOfAction = newSelectedActions.indexOf(element)

      if (isSelected) {
        if (indexOfAction < 0) {
          newSelectedActions.push(element)
          newSelectedActionsStatus.push(elementStatus)
        }
      } else {
        if (indexOfAction > -1) {
          newSelectedActions.splice(indexOfAction, 1)
          newSelectedActionsStatus.splice(indexOfAction, 1)
        }
      }

      return action
    })

    this.setState({
      selectedActions: newSelectedActions,
      selectedActionsStatus: newSelectedActionsStatus
    })
  }

  onFilterChange = filterObj => {
    this.setState(
      state => {
        const { page, ...otherTableOptions } = state.tableOptions
        return {
          filter: filterObj,
          tableOptions: { page: 1, ...otherTableOptions },
          start: 0
        }
      },
      () => {
        this.getTasks()
      }
    )

    return false
  }

  formatNodeName = (cell, row) => {
    return <span>{row.nodeName}</span>
  }

  formatDateDetail = cell => {
    if (cell !== undefined) {
      return <DateLabel date={cell} format='DD-MMM-YYYY HH:mm:ss' />
    } else {
      return '-'
    }
  }

  formatStatus = cell => {
    const { intl } = this.props
    return <DataChip chipText={cell} inline intl={intl} />
  }

  showActions = () => {
    const {
      privileges: { canEditActions, canViewActions }
    } = this.props
    if (canViewActions || canEditActions) {
      return (
        <TableHeaderColumn
          dataAlign='center'
          dataField='hashId'
          dataFormat={this.formatActionDetail}
          headerAlign='center'
          width='150'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    } else {
      return <TableHeaderColumn visible='false' width='0' />
    }
  }

  formatActionDetail = (cell, row) => {
    const {
      privileges: { canEditActions }
    } = this.props

    const actionIsArchived = row.archived
    const status = row.status.toLowerCase()
    const isFrontEndAction = [IMPORT_DASHBOARDS, DELETE_DASHBOARDS, IMPORT_ADVANCED_SIGNALS].includes(row.actionName)

    const showDetail = true
    let showCancel = false
    let showRelaunch = false
    let showDownloadFile = false
    let showDownloadTaskFile = false
    let disableDownloadTaskFile = false
    let downloadTaskFileIconColor = '#484848'

    if (!actionIsArchived && !isFrontEndAction) {
      switch (status) {
        case 'waiting':
          showCancel = true
          showRelaunch = false
          break
        case 'executing':
          showCancel = true
          showRelaunch = false
          break
        case 'completed':
          showCancel = false
          showRelaunch = true
          if (row.outputType === 1) {
            showDownloadFile = true
          } else if (row.outputType === 0 && row.actionName === 'READ_CONFIGURATION') {
            showDownloadFile = true
          } else {
            showDownloadTaskFile = true
          }
          break
        case 'failed':
          showCancel = false
          showRelaunch = true
          showDownloadTaskFile = true
          break
        case 'canceled':
          showCancel = false
          showRelaunch = true
          showDownloadTaskFile = true
          break
        default:
          showCancel = false
          showRelaunch = false
          showDownloadTaskFile = false
      }

      if (showDownloadTaskFile) {
        //Check if show button disabled or not
        //Download File task - check if type of variable that contain the file or configurationId is a string (type == 0) or file (type == 1)
        //If is a string, the file it is not available to download
        if (row.variables !== undefined) {
          const actionVariableForFile = row.variables.find(x => x.name === 'configurationId' || x.name === 'file')
          if (actionVariableForFile !== undefined && actionVariableForFile.type !== undefined) {
            if (actionVariableForFile.type === 0) {
              disableDownloadTaskFile = true
              downloadTaskFileIconColor = '#E7E3E3'
            }
          }
        }
      }
    }

    if (!canEditActions) {
      showCancel = false
      showRelaunch = false
    }

    return (
      <div className='users'>
        {showDetail && (
          <Button
            className='users-action-buttons'
            onClick={() => this.handleShowDetail(row)}
            title={this.formatMessage(messages.viewActionDetail)}
          >
            <Icon className='zmdi zmdi-search' style={{ color: '#484848' }} />
          </Button>
        )}

        {showCancel && (
          <Button
            className='users-action-buttons'
            onClick={() => this.handleCancel(row)}
            title={this.formatMessage(messages.cancelAction)}
          >
            <Icon className='zmdi zmdi-close-circle-o' style={{ color: '#484848' }} />
          </Button>
        )}

        {showRelaunch && (
          <Button
            className='users-action-buttons'
            onClick={() => this.handleRelaunch(row)}
            title={this.formatMessage(messages.relaunchAction)}
          >
            <Icon className='zmdi zmdi-replay' style={{ color: '#484848' }} />
          </Button>
        )}

        {showDownloadFile && (
          <Button
            className='users-action-buttons'
            onClick={handleDownloadFile(row)}
            title={this.formatMessage(messages.downloadFile)}
          >
            <Icon className='zmdi zmdi-download' style={{ color: '#484848' }} />
          </Button>
        )}

        {showDownloadTaskFile && (
          <Button
            className='users-action-buttons'
            disabled={disableDownloadTaskFile}
            onClick={handleDownloadTaskFile(row)}
            title={this.formatMessage(messages.downloadFile)}
          >
            <Icon className='zmdi zmdi-download' style={{ color: { downloadTaskFileIconColor } }} />
          </Button>
        )}
      </div>
    )
  }

  clearSelectedActions = () => {
    this.setState({
      selectedActions: [],
      selectedActionsStatus: []
    })
  }

  setFilterDates = dates => {
    this.setState(
      state => {
        const { page, ...otherTableOptions } = state.tableOptions
        return {
          filterDates: dates,
          tableOptions: { page: 1, ...otherTableOptions },
          start: 0
        }
      },
      () => {
        this.getTasks()
      }
    )
  }

  setFilterStatus = status => {
    this.setState(
      state => {
        const { page, ...otherTableOptions } = state.tableOptions
        return {
          filterStatus: status,
          tableOptions: { page: 1, ...otherTableOptions },
          start: 0
        }
      },
      () => {
        this.getTasks()
      }
    )
  }

  setFilterArchived = archived => {
    this.setState(
      state => {
        const { page, ...otherTableOptions } = state.tableOptions
        return {
          filterArchived: archived,
          tableOptions: { page: 1, ...otherTableOptions },
          start: 0
        }
      },
      () => {
        this.getTasks()
      }
    )
  }

  handleShowDetail = task => {
    this.setState({
      openActionDetailDialog: true,
      dialogTask: task
    })
  }

  handleCancel = task => {
    this.setState({
      openActionCancelDialog: true,
      dialogTask: task
    })
  }

  handleRelaunch = task => {
    this.setState({
      openActionRelaunchDialog: true,
      dialogTask: task
    })
  }

  handleDialogCloseClick = () => {
    this.setState({
      openActionDetailDialog: false,
      openActionCancelDialog: false,
      openActionRelaunchDialog: false
    })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const {
      privileges: { canViewActions }
    } = this.props
    const {
      archivedTasksHashIds,
      count,
      openActionCancelDialog,
      openActionDetailDialog,
      openActionRelaunchDialog,
      dialogTask,
      selectedActions,
      selectedActionsStatus,
      tableOptions,
      tasks
    } = this.state

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedActions,
      unselectable: archivedTasksHashIds
    }

    const remote = remoteObj => {
      return { ...remoteObj, search: false, pagination: true, sizePerPage: true, sort: false, filter: true }
    }

    return (
      <div>
        <ActionsStateFilter
          setFilterArchived={this.setFilterArchived}
          setFilterDates={this.setFilterDates}
          setFilterStatus={this.setFilterStatus}
        />
        <br />
        <Paper style={{ borderRadius: 0 }}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <ActionsStateGlobalActions
                  canViewActions={canViewActions}
                  clearSelectedActions={this.clearSelectedActions}
                  selectedActions={selectedActions}
                  selectedActionsStatus={selectedActionsStatus}
                />
              </div>
            </div>
          </div>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              data={tasks}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={remote}
              search={false}
              searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
              selectRow={canViewActions ? selectRowProp : {}}
              striped={false}
            >
              <TableHeaderColumn
                dataField='hashId'
                dataFormat={this.formatNodeName}
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.name)
                }}
                isKey
                width='300'
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='actionDescription'
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder:
                    this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.actionDescription)
                }}
                width='250'
              >
                {this.formatMessage(messages.actionDescription)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='userEmail'
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.taskCreator)
                }}
                width='300'
              >
                {this.formatMessage(messages.taskCreator)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='creationDate' dataFormat={this.formatDateDetail} width='200'>
                {this.formatMessage(messages.created)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='status' dataFormat={this.formatStatus} width='150'>
                {this.formatMessage(messages.status)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>

          <ActionDetailDialog onClose={this.handleDialogCloseClick} open={openActionDetailDialog} task={dialogTask} />
          <ActionCancelDialog onClose={this.handleDialogCloseClick} open={openActionCancelDialog} task={dialogTask} />
          <ActionRelaunchDialog
            onClose={this.handleDialogCloseClick}
            open={openActionRelaunchDialog}
            task={dialogTask}
          />
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>
      </div>
    )
  }
}

ActionsStateContent.propTypes = {
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  loadingGroup: PropTypes.bool.isRequired,
  privileges: PropTypes.object.isRequired,
  secondaryGlobalQuery: PropTypes.string.isRequired
}

export default ActionsStateContent
