import { defineMessages } from 'react-intl'

const messages = defineMessages({
  gaugeWidget: {
    id: 'GaugeSettings.gaugeWidget',
    description: 'Gauge widget',
    defaultMessage: 'GAUGE WIDGET'
  },
  gaugeWidgetSettings: {
    id: 'GaugeSettings.gaugeWidgetSettings',
    description: 'Gauge widget settings',
    defaultMessage: 'Gauge widget settings.'
  },
  dinamicValue: {
    id: 'GaugeSettings.dinamicValues',
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: 'GaugeSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  notSupportedMachine: {
    id: 'GaugeSettings.notSupportedMachine',
    description: '',
    defaultMessage: 'Not supported machine'
  },
  otherGaugeWidgetSettings: {
    id: 'GaugeSettings.otherGaugeWidgetSettings',
    description: 'Gauge widget settigs dialog',
    defaultMessage: 'Other widget settings:'
  },
  minValue: {
    id: 'GaugeSettings.minValue',
    description: 'Minimum value',
    defaultMessage: 'Minimum value'
  },
  maxValue: {
    id: 'GaugeSettings.maxValue',
    description: 'Maximum value',
    defaultMessage: 'Maximum value'
  },
  mustHaveAValue: {
    id: 'GaugeSettings.mustHaveAValue',
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  cancel: {
    id: 'GaugeSettings.cancel',
    description: 'Gauge settings cancel button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'GaugeSettings.save',
    description: 'Gauge settings saving button text',
    defaultMessage: 'Save'
  },
  widgetNotConfigured: {
    id: 'GaugeSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noDataAvailable: {
    id: 'GaugeSettings.noDataAvailable',
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  noSignal: {
    id: 'GaugeSettings.noSignal',
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  numberOfDecimals: {
    id: 'GaugeSettings.numberOfDecimals',
    description: 'Number of decimals',
    defaultMessage: 'No. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: 'GaugeSettings.onlyZeroOrHigherIsAllowed',
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: 'GaugeSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  valueType: {
    id: 'GaugeSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: 'GaugeSettings.value',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: 'GaugeSettings.valueAvg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: 'GaugeSettings.valueMin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: 'GaugeSettings.valueMax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  noDataSince: {
    id: 'GaugeSettings.noDataSince',
    description: 'No data since',
    defaultMessage: 'No data since {date}'
  },
  gpsSignals: {
    id: 'GaugeSettings.gpsSignals',
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: 'GaugeSettings.canBusSignals',
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: 'GaugeSettings.mfioSignals',
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: 'GaugeSettings.signals',
    description: 'Signals',
    defaultMessage: 'Signals'
  }
})

export default messages
