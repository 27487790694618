import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { getForecastData } from './url'

import ArrowUpIcon from 'mdi-react/ArrowUpIcon'
import ArrowDownIcon from 'mdi-react/ArrowDownIcon'
import ArrowDownLeftIcon from 'mdi-react/ArrowDownLeftIcon'
import ArrowDownRightIcon from 'mdi-react/ArrowDownRightIcon'
import ArrowUpLeftIcon from 'mdi-react/ArrowUpLeftIcon'
import ArrowUpRightIcon from 'mdi-react/ArrowUpRightIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon'
import WeatherCloudyIcon from 'mdi-react/WeatherCloudyIcon'
import WeatherLightningRainyIcon from 'mdi-react/WeatherLightningRainyIcon'
import WeatherPouringIcon from 'mdi-react/WeatherPouringIcon'
import WeatherSnowyIcon from 'mdi-react/WeatherSnowyIcon'

import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { logError } from 'utils/http'

import './styles.css'

const styles = theme => ({
  errorTypography: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle'
  }
})

class Weather extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      forecast: {},
      days: [],
      minTemps: [],
      maxTemps: [],
      winds: [],
      weather: [],
      icons: [],
      errorMessage: ''
    }
  }

  componentDidMount() {
    if (this.props.deviceId && this.props.data.days && this.props.data.unit) {
      this.props.getDeviceInfo(this.props.deviceId, this.props.groupId)
    }
  }

  componentDidUpdate(prevProps) {
    const { location, data, getDeviceInfo, deviceId, groupId } = this.props
    if (prevProps.location !== location || prevProps.data.days !== data.days || prevProps.data.unit !== data.unit) {
      this.fetchData()
    }
    if (prevProps.deviceId !== deviceId && deviceId) {
      getDeviceInfo(deviceId, groupId)
    }
  }

  fetchData = () => {
    if (this.props.location) {
      const lat = this.props.location.latitude
      const lon = this.props.location.longitude

      if (lat && lon) {
        getForecastData(lat, lon, this.props.data.unit)
          .then(response => {
            this.setState(
              {
                forecast: response.data,
                errorMessage: ''
              },
              this.setForecastData
            )
          })
          .catch(error => {
            logError(error)
            this.setState({
              errorMessage: this.formatMessage(messages.errorFetchingWeatherData)
            })
          })
      }
    } else {
      this.setState({
        errorMessage: this.formatMessage(messages.noLocationAvailable)
      })
    }
  }

  setForecastData = () => {
    const dayOfWeek = [
      this.formatMessage(messages.sundayShort),
      this.formatMessage(messages.mondayShort),
      this.formatMessage(messages.tuesdayShort),
      this.formatMessage(messages.wednesdayShort),
      this.formatMessage(messages.thursdayShort),
      this.formatMessage(messages.fridayShort),
      this.formatMessage(messages.saturdayShort)
    ]
    const days = []
    const minTemps = []
    const maxTemps = []
    const weather = []
    const icons = []
    const winds = []
    for (let i = 0; i < this.props.data.days * 8; i = i + 8) {
      const date = new Date(this.state.forecast.list[i].dt_txt)
      const day = dayOfWeek[date.getDay()]
      days.push(day)
      minTemps.push(Math.round(this.state.forecast.list[i].main.temp_min))
      maxTemps.push(Math.round(this.state.forecast.list[i].main.temp_max))
      if (this.state.forecast.list[i].weather[0].main === 'Clouds') weather.push('Cloudy')
      else weather.push(this.state.forecast.list[i].weather[0].main)
      icons.push(this.convertWeatherIcons(this.state.forecast.list[i].weather[0].main))
      winds.push(this.state.forecast.list[i].wind)
    }
    this.setState({
      days,
      minTemps,
      maxTemps,
      weather,
      icons,
      winds
    })
  }

  convertWeatherIcons = weather => {
    switch (weather) {
      case 'Clear':
        return <CircleOutlineIcon size={32} />
      case 'Clouds':
        return <WeatherCloudyIcon size={32} />
      case 'Snow':
        return <WeatherSnowyIcon size={32} />
      case 'Rain':
        return <WeatherPouringIcon size={32} />
      case 'Drizzle':
        return <WeatherPouringIcon size={32} />
      case 'Thunderstorm':
        return <WeatherLightningRainyIcon size={32} />
      default:
        return <WeatherCloudyIcon size={32} />
    }
  }

  setIndex = index => {
    this.setState({
      displayIndex: index
    })
  }

  roundValue = (value, numOfDec = 2) => {
    const num = parseFloat('1e' + numOfDec)
    return Math.round((value + Number.EPSILON) * num) / num
  }

  mapArrowFromDegrees = degrees => {
    if (degrees > 337.5 || degrees <= 22.5) return <ArrowUpIcon size={24} />
    if (degrees > 22.5 && degrees <= 67.5) return <ArrowUpRightIcon size={24} />
    if (degrees > 67.5 && degrees <= 112.5) return <ArrowRightIcon size={24} />
    if (degrees > 112.5 && degrees <= 157.5) return <ArrowDownRightIcon size={24} />
    if (degrees > 157.5 && degrees <= 202.5) return <ArrowDownIcon size={24} />
    if (degrees > 202.5 && degrees <= 247.5) return <ArrowDownLeftIcon size={24} />
    if (degrees > 247.5 && degrees <= 292.5) return <ArrowLeftIcon size={24} />
    if (degrees > 292.5 && degrees <= 337.5) return <ArrowUpLeftIcon size={24} />
  }

  render() {
    const { days, maxTemps, minTemps, weather, icons, winds } = this.state
    const unit = this.props.data.unit === 'Celsius' ? '°C' : '°F'
    let background = 'cloudy'

    switch (weather[0]) {
      case 'Clear':
        background = 'clear'
        break
      case 'Cloudy':
        background = 'cloudy'
        break
      case 'Snow':
        background = 'snow'
        break
      case 'Rain':
        background = 'rain'
        break
      case 'Drizzle':
        background = 'rain'
        break
      case 'Thunderstorm':
        background = 'thunderstorm'
        break
      default:
        background = 'cloudy'
    }

    const classes = this.props.classes
    const multiplier = this.props.data.unit === 'Celsius' ? 3.6 : 1
    const mappedWinds = winds.map(wind => ({
      speed: this.roundValue(wind.speed * multiplier, 0),
      arrow: this.mapArrowFromDegrees(wind.deg)
    }))
    const windUnit = this.props.data.unit === 'Celsius' ? 'km/h' : 'mi/hr'
    const width = typeof this.props.width === 'number' ? this.props.width : parseInt(this.props.width.slice(0, -2))

    return (
      <div
        className={!this.props.data.days || !this.props.data.unit ? 'notConfigured' : ''}
        style={{ width: '100%', height: '100%', ...this.state.errorMessage && { display: 'table' } }}
      >
        {!this.props.data.days || !this.props.data.unit ? (
          <Typography>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : this.state.errorMessage !== '' ? (
          <Typography classes={{ root: classes.errorTypography }}>{this.state.errorMessage}</Typography>
        ) : 
          Object.keys(this.state.forecast).length > 0 && (
            <div className={'widget '.concat(...background)}>
              <div className="selection-panel">
                {days.map((item, index) => {
                  return (
                    <div className="selection-column" key={index}>
                      <div>{mappedWinds[index].speed + ' ' + windUnit}</div>
                      <div>{mappedWinds[index].arrow}</div>
                      <div style={{ fontWeight: 'bold' }}>{item}</div>
                      <div>{icons[index]}</div>
                      <div className={width < 400 && this.props.data.days === 5 ? 'temp-cell' : ''}>
                        <span style={{ fontWeight: 'bold' }}>{maxTemps[index] + unit + '/'}</span>
                        {minTemps[index] + unit}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="openweathermap-credits">
                Provided by:{' '}
                <a href="https://openweathermap.org/" target="_blank" rel="noopener noreferrer">
                  OpenWeatherMap
                </a>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

Weather.propTypes = {
  data: PropTypes.object.isRequired,
  deviceId: PropTypes.string,
  location: PropTypes.object
}

Weather.defaultProps = {
  deviceId: '',
  location: {}
}

export default withStyles(styles)(injectIntl(Weather))
