export const BASE_URL = '/reports'
export const NEW_REPORT = BASE_URL + '/new'
export const EDIT_REPORT = BASE_URL + '/:reportId/edit'

const getReportsUrl = () => BASE_URL
const getNewReportUrl = () => NEW_REPORT
const getEditReportUrl = reportId => EDIT_REPORT.replace(':reportId', reportId)
const getReportTimeSeries = (deviceId, start, end, filters) =>
  '/api/v2/logs/datedAzure/m' +
  deviceId +
  '?min=' +
  start +
  '&max=' +
  end +
  '&size=4000&sortOrder=asc&filters=' +
  filters +
  ',timestamp'
// eslint-disable-next-line max-params
const getAggTimeSeries = (deviceId, start, end, filters, bucket) =>
  '/api/v2/logs/aggAzure/m' +
  deviceId +
  '?min=' +
  start +
  '&max=' +
  end +
  '&size=4000&sortOrder=asc&aggLevel=' +
  bucket +
  '&filters=' +
  filters +
  ',timestamp'

export { getReportsUrl, getNewReportUrl, getEditReportUrl, getReportTimeSeries, getAggTimeSeries }
