import React from 'react'

import SetNotificationsByType from './Actions/SetNotificationsByType'
import messages from './messages'

const AssignedUsersGroupActionsConfig = formatMessage => {
  return [
    {
      hashId: '1',
      name: 'ASSIGN_NOTIFICATIONS_BY_BELL',
      description: formatMessage(messages.assignNotificationsByTheBell),
      notificationActionName: 'LIVE_NOTIFICATION',
      component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
        <SetNotificationsByType
          action={action}
          assignedUsers={assignedUsers}
          formatMessage={formatMessage}
          redrawAssignedUsersTable={redrawAssignedUsersTable}
          ruleInstanceHashId={ruleInstanceHashId}
        />
      )
    },
    {
      hashId: '2',
      name: 'UNASSIGN_NOTIFICATIONS_BY_BELL',
      description: formatMessage(messages.unassignNotificationsByTheBell),
      notificationActionName: 'LIVE_NOTIFICATION',
      component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
        <SetNotificationsByType
          action={action}
          assignedUsers={assignedUsers}
          formatMessage={formatMessage}
          redrawAssignedUsersTable={redrawAssignedUsersTable}
          ruleInstanceHashId={ruleInstanceHashId}
        />
      )
    },
    {
      hashId: '3',
      name: 'ASSIGN_NOTIFICATIONS_BY_EMAIL',
      description: formatMessage(messages.assignNotificationsByEmail),
      notificationActionName: 'EMAIL_NOTIFICATION',
      component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
        <SetNotificationsByType
          action={action}
          assignedUsers={assignedUsers}
          formatMessage={formatMessage}
          redrawAssignedUsersTable={redrawAssignedUsersTable}
          ruleInstanceHashId={ruleInstanceHashId}
        />
      )
    },
    {
      hashId: '4',
      name: 'UNASSIGN_NOTIFICATIONS_BY_EMAIL',
      description: formatMessage(messages.unassignNotificationsByEmail),
      notificationActionName: 'EMAIL_NOTIFICATION',
      component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
        <SetNotificationsByType
          action={action}
          assignedUsers={assignedUsers}
          formatMessage={formatMessage}
          redrawAssignedUsersTable={redrawAssignedUsersTable}
          ruleInstanceHashId={ruleInstanceHashId}
        />
      )
    },
    /*
  ,
  {
    hashId: '5',
    name: 'ASSIGN_NOTIFICATIONS_BY_PUSH',
    description: formatMessage(messages.assignNotificationsByPush),
    notificationActionName: 'PUSH_NOTIFICATION', 
    component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
      <SetNotificationsByType action={action} formatMessage={formatMessage} assignedUsers={assignedUsers} ruleInstanceHashId={ruleInstanceHashId} redrawAssignedUsersTable={redrawAssignedUsersTable} />
    )
  },
  {
    hashId: '6',
    name: 'UNASSIGN_NOTIFICATIONS_BY_PUSH',
    description: formatMessage(messages.unassignNotificationsByPush),
    notificationActionName: 'PUSH_NOTIFICATION',    
    component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
      <SetNotificationsByType action={action} formatMessage={formatMessage} assignedUsers={assignedUsers} ruleInstanceHashId={ruleInstanceHashId} redrawAssignedUsersTable={redrawAssignedUsersTable} />
    )
  }
  */ {
      hashId: '7',
      name: 'ASSIGN_NOTIFICATIONS_BY_SMS',
      description: formatMessage(messages.assignNotificationsBySms),
      notificationActionName: 'SMS_NOTIFICATION',
      component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
        <SetNotificationsByType
          action={action}
          assignedUsers={assignedUsers}
          formatMessage={formatMessage}
          redrawAssignedUsersTable={redrawAssignedUsersTable}
          ruleInstanceHashId={ruleInstanceHashId}
        />
      )
    },
    {
      hashId: '8',
      name: 'UNASSIGN_NOTIFICATIONS_BY_SMS',
      description: formatMessage(messages.unassignNotificationsBySms),
      notificationActionName: 'SMS_NOTIFICATION',
      component: (action, assignedUsers, ruleInstanceHashId, redrawAssignedUsersTable) => (
        <SetNotificationsByType
          action={action}
          assignedUsers={assignedUsers}
          formatMessage={formatMessage}
          redrawAssignedUsersTable={redrawAssignedUsersTable}
          ruleInstanceHashId={ruleInstanceHashId}
        />
      )
    }
  ]
}

export default AssignedUsersGroupActionsConfig
