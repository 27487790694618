import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import LaunchIcon from '@material-ui/icons/Launch'

import messages from '../messages'

const ConsentAcceptedStep = props => {
  const { intl, onGoToMyProfileClick } = props

  return (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <p>
          {intl.formatMessage(messages.toBeAbleToReceive, {
            a: text => (
              <a href='#' onClick={onGoToMyProfileClick} rel='noreferrer' target='_blank'>
                {text} <LaunchIcon fontSize='small' style={{ verticalAlign: 'text-bottom' }} />
              </a>
            )
          })}
        </p>
      </Grid>
    </Grid>
  )
}

ConsentAcceptedStep.propTypes = {
  intl: PropTypes.object.isRequired,
  onGoToMyProfileClick: PropTypes.func.isRequired
}

export default injectIntl(ConsentAcceptedStep)
