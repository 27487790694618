import * as t from './actionTypes'
import { createRequestAction } from './utils'
import * as url from '../plus1ConnectUrl'

const getDataPlans = (groupId, limit, offset) =>
  createRequestAction(t.DATA_PLAN, url.dataPlans(groupId, limit, offset), 'get', null, 'plus1Connect')

const getDataPlan = dataPlanId =>
  createRequestAction(t.DATA_PLAN, url.dataPlan(dataPlanId), 'get', null, 'plus1Connect')

const activateDataPlan = (groupId, dataPlanId, deviceEid) =>
  createRequestAction(
    t.DATA_PLAN,
    url.dataPlanActivate(groupId, dataPlanId),
    'post',
    { eid: deviceEid },
    'plus1Connect'
  )

const getNumberOfDataPlans = groupId =>
  createRequestAction(t.DATA_PLAN, url.numberOfDataPlans(groupId), 'get', null, 'plus1Connect')

const shareDataPlans = (targetGroupId, body) =>
  createRequestAction(t.DATA_PLAN, url.shareDataPlans(targetGroupId, body), 'put', body, 'plus1Connect')

export { getDataPlans, getDataPlan, activateDataPlan, getNumberOfDataPlans, shareDataPlans }
