/**
 * https://github.com/wubocong/react-upload-file
 */
import React, { Fragment, Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import messages from './messages'

class ReactUploadFile extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const emptyFunction = () => {}
    const options = {
      dataType: 'json',
      timeout: 0,
      numberLimit: 0,
      userAgent: window.navigator.userAgent,
      multiple: false,
      withCredentials: false,
      beforeChoose: emptyFunction,
      didChoose: emptyFunction,
      beforeUpload: emptyFunction,
      didUpload: emptyFunction,
      didLoad: emptyFunction,
      didUploadS3: emptyFunction,
      uploading: emptyFunction,
      uploadSuccess: emptyFunction,
      uploadError: emptyFunction,
      uploadFail: emptyFunction,
      onAbort: emptyFunction,
      ...props.options
    }
    const timeout = parseInt(options.timeout, 10)
    options.timeout = Number.isInteger(timeout) && timeout > 0 ? timeout : 0
    const dataType = options.dataType && options.dataType.toLowerCase()
    options.dataType = dataType !== 'json' && 'text'
    options.accept = '.xml'

    /* copy options to instance */
    Object.keys(options).forEach(key => {
      this[key] = options[key]
    })

    this.state = {
      /* xhrs' list after start uploading files */
      xhrList: [],
      currentXHRId: 0
    }
  }

  componentDidMount = () => {
    this.input = ReactDOM.findDOMNode(this).querySelector('[name=ajax-upload-file-input]') // eslint-disable-line react/no-find-dom-node
  }

  /* trigger input's click*/
  /* trigger beforeChoose*/
  commonChooseFile = e => {
    if (e.target.name !== 'ajax-upload-file-input') {
      const jud = this.beforeChoose()
      if (jud !== true && jud !== undefined) return
      this.input.click()
    }
  }

  /* input change event with File API */
  /* trigger chooseFile */
  handleCommonChangeFile = e => {
    this.files = this.input.files
    this.didChoose(this.files)
    /* immediately upload files */
    const { uploadFileButton } = this.props
    if (!uploadFileButton) {
      this.commonLoadFile(e)
      /* enable repeated uploads */
      e.target.value = '' // eslint-disable-line no-param-reassign
    }
  }

  /* execute upload */
  commonUploadFile = e => {
    if (!this.files || !this.files.length) return false
    if (!this.baseUrl) {
      throw new Error(this.formatMessage(messages.baseUrlMissedInOptions))
    }

    const jud = e === true ? true : this.beforeUpload(this.files)
    if (!jud) {
      return false
    }

    let formData = new FormData()
    formData = this.appendFieldsToFormData(formData)

    const fieldNameType = typeof this.fileFieldName
    const numberLimit = this.numberLimit === 0 ? this.files.length : Math.min(this.files.length, this.numberLimit)
    for (let i = numberLimit - 1; i >= 0; i--) {
      if (fieldNameType === 'function') {
        const file = this.files[i]
        const fileFieldName = this.fileFieldName(file)
        formData.append(fileFieldName, file)
      } else if (fieldNameType === 'string') {
        const file = this.files[i]
        formData.append(this.fileFieldName, file)
      } else {
        const file = this.files[i]
        formData.append(file.name, file)
      }
    }

    let baseUrl = this.baseUrl
    /* url query*/
    const query = typeof this.query === 'function' ? this.query(this.files) : this.query
    const pos = baseUrl.indexOf('?')
    let queryStr
    if (pos > -1) {
      queryStr = baseUrl.substring(pos)
      baseUrl = baseUrl.substring(0, pos)
    }
    if (query) {
      const queryArr = []
      Object.keys(query).forEach(key => queryArr.push(`${key}=${query[key]}`))
      queryStr = `?${queryArr.join('&')}`
    }
    queryStr = queryStr || ''
    const targetUrl = `${baseUrl}${queryStr}`

    const xhr = new XMLHttpRequest()
    xhr.open('post', targetUrl, true)

    /* authorization info for cross-domain */
    xhr.withCredentials = this.withCredentials

    const rh = this.requestHeaders
    if (rh) {
      Object.keys(rh).forEach(key => xhr.setRequestHeader(key, rh[key]))
    }

    if (this.timeout) {
      xhr.timeout = this.timeout
      xhr.addEventListener('timeout', () => {
        this.uploadError({
          type: '408',
          message: 'Request Timeout'
        })
      })
      setTimeout(() => {}, this.timeout)
    }

    xhr.addEventListener('load', () => {
      this.input.value = ''
      const res = this.dataType === 'json' ? JSON.parse(xhr.responseText) : xhr.responseText
      this.uploadSuccess(res)
    })

    xhr.addEventListener('error', () => {
      this.input.value = ''
      const err = this.dataType === 'json' ? JSON.parse(xhr.responseText) : xhr.responseText
      this.uploadError({
        type: err.type,
        message: err.message
      })
    })

    xhr.addEventListener('progress', progress => {
      this.uploading(progress)
    })

    const { currentXHRId, xhrList } = this.state

    const curId = xhrList.length - 1
    xhr.addEventListener('abort', () => {
      this.onAbort(curId)
    })

    xhr.send(formData)

    this.setState({
      currentXHRId: curId,
      xhrList: [...xhrList, xhr]
    })

    this.didUpload(this.files, currentXHRId)
    return true
  }

  /* append text params to formData */
  appendFieldsToFormData = formData => {
    const field = typeof this.body === 'function' ? this.body() : this.body
    if (field) {
      Object.keys(field).forEach(index => {
        formData.append(index, field[index])
      })
    }
    return formData
  }

  commonLoadFile = e => {
    if (!this.files || !this.files.length) return false
    if (!this.baseUrl) {
      throw new Error(this.formatMessage(messages.baseUrlMissedInOptions))
    }

    const jud = e === true ? true : this.beforeUpload(this.files)
    if (!jud) {
      return false
    }

    let pcFile = null
    for (let i = 0; i < this.files.length; i++) {
      pcFile = this.files[i]
      this.didLoad(pcFile)
    }
  }

  commonUploadToS3 = e => {
    if (!this.files || !this.files.length) return false
    if (!this.baseUrl) {
      throw new Error(this.formatMessage(messages.baseUrlMissedInOptions))
    }

    const jud = e === true ? true : this.beforeUpload(this.files)
    if (!jud) {
      return false
    }

    let pcFile = null
    for (let i = 0; i < this.files.length; i++) {
      pcFile = this.files[i]
      this.didUploadS3(pcFile)
    }
    this.input.value = ''
  }

  /**
   * public method. Manually process files
   * @param func(files)
   * @return files
   * Filelist:
   * {
   *   0: file,
   *   1: file,
   *   length: 2
   * }
   */
  processFile = func => {
    this.files = func(this.files)
  }

  /* public method. Manually trigger commonChooseFile for debug */
  manuallyChooseFile = e => {
    this.commonChooseFile(e)
  }

  /* public method. Manually trigger commonUploadFile to upload files */
  manuallyUploadFile = files => {
    this.files = files && files.length ? files : this.files
    this.commonLoadFile(true)
  }

  /* public method. Abort a xhr by id which didUpload has returned, default the last one */
  abort = id => {
    const { currentXHRId, xhrList } = this.state
    if (id) {
      xhrList[id].abort()
    } else {
      xhrList[currentXHRId].abort()
    }
  }

  render() {
    const {
      chooseFileButton: chooseFileButtonProps,
      id,
      options,
      uploadFileButton: uploadFileButtonProps,
      uploadS3Button: uploadS3ButtonProps
    } = this.props
    const inputProps = {
      accept: options.accept,
      multiple: options.multiple
    }

    const chooseFileButton = React.cloneElement(chooseFileButtonProps, {
      onClick: this.commonChooseFile
    })

    const uploadFileButton =
      uploadFileButtonProps &&
      React.cloneElement(uploadFileButtonProps, {
        onClick: this.commonUploadFile
      })

    const uploadS3Button =
      uploadS3ButtonProps &&
      React.cloneElement(uploadS3ButtonProps, {
        onClick: this.commonUploadToS3
      })

    return (
      <Fragment>
        <div className='col-md-12' style={{ padding: 0 }}>
          <input
            id={id}
            name='ajax-upload-file-input'
            onChange={this.handleCommonChangeFile}
            style={{ display: 'none' }}
            type='file'
            {...inputProps}
            key='file-button'
          />
          <label htmlFor={id} style={{ width: '100%' }}>
            {chooseFileButton}
          </label>
        </div>
        <div className='col-md-3'>{uploadFileButton}</div>
        <div className='col-md-3'>{uploadS3Button}</div>
      </Fragment>
    )
  }
}

ReactUploadFile.propTypes = {
  chooseFileButton: PropTypes.element.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  intl: PropTypes.object.isRequired,
  options: PropTypes.shape({
    accept: PropTypes.string,
    baseUrl: PropTypes.string.isRequired,
    beforeChoose: PropTypes.func,
    beforeUpload: PropTypes.func,
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    dataType: PropTypes.string,
    didChoose: PropTypes.func,
    didUpload: PropTypes.func,
    didUploadS3: PropTypes.func,
    fileFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    multiple: PropTypes.bool,
    numberLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    onAbort: PropTypes.func,
    query: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    requestHeaders: PropTypes.object,
    timeout: PropTypes.number,
    uploadError: PropTypes.func,
    uploadFail: PropTypes.func,
    uploadSuccess: PropTypes.func,
    uploading: PropTypes.func,
    userAgent: PropTypes.string,
    withCredentials: PropTypes.bool
  }).isRequired,
  style: PropTypes.object,
  uploadFileButton: PropTypes.element,
  uploadS3Button: PropTypes.element
}

ReactUploadFile.defaultProps = {
  className: '',
  id: '',
  style: {},
  uploadFileButton: null,
  uploadS3Button: null
}

export default ReactUploadFile
