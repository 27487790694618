import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import NewReportForm from './NewReportForm'
import NewReportFormTitle from './NewReportFormTitle'

class NewReport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      reportsUrl: props.reportsUrl,
      onCreateReport: props.onCreateReport,
      reports: [],
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    if (this.state.loading) {
      return <Loading />
    } else if (this.state.alertMessages) {
      return (
        <Alert
          showAlert={this.state.alertMessages}
          alertType={this.state.alertMessagesType}
          closeFunction={this.closeAlert}
          messageTitle={this.state.alertMessagesTitle}
          messageText={this.state.alertMessagesText}
        />
      )
    }
  }

  renderTablecontent = () => {
    if (this.state.loading || this.state.alertMessages) {
      return <div className="container-fluid">{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <NewReportForm
          plus1ConnectUser={this.props.plus1ConnectUser}
          plus1ConnectUserId={this.props.plus1ConnectUserId}
          reports={this.state.reports}
          groupId={this.props.groupId}
          action={this.props.onCreateReport}
          getAssets={this.props.getAssets}
          fetchDeviceNonVolatileConfiguration={this.props.fetchDeviceNonVolatileConfiguration}
          createReport={this.props.createReport}
          createReportAWS={this.props.createReportAWS}
          reportsUrl={this.state.reportsUrl}
        />
      )
    }
  }

  render() {
    return (
      <div id="content" className="content-container">
        <div style={{ margin: '20px' }}>
          <div className="container-fluid">
            <NewReportFormTitle reportsUrl={this.state.reportsUrl} />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

NewReport.propTypes = {
  onCreateReport: PropTypes.func,
  reportsUrl: PropTypes.string
}

export default NewReport
