import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions } from 'ducks/groups'
import { getUsersUrl } from '../../url'
import AddUser from './AddUser'

const mapStateToProps = (state, { history }) => ({
  onAddUser: () => {
    history.push(getUsersUrl(), { action: 'create' })
  }
})

const mapDispatchToProps = dispatch => ({
  addUser: (groupId, users, permissions) => dispatch(actions.addUsersToGroup(groupId, users, permissions)),
  getAllRoles: groupId => dispatch(actions.getAllRolesOfGroup(groupId)),
  assignRoleToUser: (groupId, groupRoleId, users) => dispatch(actions.assignRoleToUser(groupId, groupRoleId, users)),
  addUsersToGroupWithRole: (groupId, groupRoleId, users) =>
    dispatch(actions.addUsersToGroupWithRole(groupId, groupRoleId, users))
})

const AddUserModal = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser))

export { AddUserModal }
