import { utils } from 'ducks/login'
import { utils as groupUtils } from 'ducks/groups'

export const resolvePermissions = (state, csPermissions) => {
  if (utils.isPlus1ConnectAuthorized(state)) {
    const permissionsCheckingFunction = Array.isArray(csPermissions)
      ? groupUtils.hasSomePermission
      : groupUtils.hasPermission
    return permissionsCheckingFunction(state, csPermissions)
  } else return false
}
