import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'

import ConditionalSignalsForm from './ConditionalSignalsForm'
import ErrorAlert from './ErrorAlert'
import GeneralSettingsForm from './GeneralSettingsForm'
import messages from './messages'

import { client, logError } from 'utils/http'

class NewNotification extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage
    this.state = {
      assignedDevices: [],
      availableRules: [],
      currentStep: 0,
      error: {},
      isErrorAlertShown: false,
      isLoading: false,
      newNotification: {}
    }
  }

  componentDidMount() {
    this.fetchRulesData()
  }

  fetchRulesData = () => {
    const { currentGroupId } = this.props

    this.setState({ isErrorAlertShown: false, isLoading: true })
    client
      .getRules(currentGroupId)
      .then(response => {
        const availableRules = response.data

        this.setState({
          availableRules
        })
      })
      .catch(error => {
        this.setState({ error, isErrorAlertShown: true })
        logError(error)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  getGroupInfo = () => {
    const { currentGroupId, getGroup } = this.props

    return getGroup(currentGroupId)
  }

  handleErrorAlertClose = () => {
    this.setState({
      error: {},
      isErrorAlertShown: false
    })
  }

  handleAssignedDevicesChange = assignedDevices => {
    this.setState({ assignedDevices })
  }

  handleSaveNewNotification = newNotification => {
    this.setState({
      newNotification,
      currentStep: 1
    })
  }

  render() {
    const { notificationsUrl } = this.props
    const {
      assignedDevices,
      availableRules,
      currentStep,
      error,
      isErrorAlertShown,
      isLoading,
      newNotification
    } = this.state

    const groupInfo = this.getGroupInfo()

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <PageTitle back={notificationsUrl} title={this.formatMessage(messages.title)} />
          </div>
          <div className='container-fluid'>
            <ErrorAlert error={error} onClose={this.handleErrorAlertClose} show={isErrorAlertShown} />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {currentStep === 0 && (
                <GeneralSettingsForm
                  assignedDevices={assignedDevices}
                  groupDescription={groupInfo.name}
                  groupId={groupInfo.id}
                  notificationsUrl={notificationsUrl}
                  onAssignedDevicesChange={this.handleAssignedDevicesChange}
                  onSaveNewNotification={this.handleSaveNewNotification}
                />
              )}

              {currentStep === 1 && (
                <ConditionalSignalsForm
                  assignedDevices={assignedDevices}
                  availableRules={availableRules}
                  newNotification={newNotification}
                  notificationsUrl={notificationsUrl}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

NewNotification.propTypes = {
  currentGroupId: PropTypes.string.isRequired,
  getGroup: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notificationsUrl: PropTypes.string.isRequired
}

export default injectIntl(NewNotification)
