import React from 'react'

import Map from './MapLoader'
import { OverlayImage } from '../Overlays'
import { Polygon } from 'react-google-maps'

import { ExtendedMapProps } from '../../../props'
import { injectIntl } from 'react-intl'

const getBounds = bounds => (Array.isArray(bounds) ? { ne: bounds[1], sw: bounds[3] } : bounds)

const ExtendedMap = ({ polygons, images, lines, children, intl, ...other }) => (
  <Map selectedLanguage={intl.locale} {...other}>
    {children}
    {polygons &&
      polygons.map((p, i) => (
        <Polygon
          key={i}
          path={p.path.map(np => ({ lat: np.latitude, lng: np.longitude }))}
          options={{
            fillColor: p.color,
            fillOpacity: 0.3,
            strokeColor: p.color,
            strokeOpacity: 1,
            strokeWeight: 1
          }}
        />
      ))}
    {images && images.map((img, i) => <OverlayImage key={i} {...img} bounds={getBounds(img.bounds)} />)}
  </Map>
)

ExtendedMap.propTypes = ExtendedMapProps

export default injectIntl(ExtendedMap)
