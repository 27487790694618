import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

import { withRouter } from 'react-router-dom'

const EditUserFormTitle = ({ title, usersUrl, location }) => {
  const back = {
    pathname: usersUrl,
    state: { action: 'back', payload: location.state }
  }

  return <PageTitle back={back} title={title} />
}

EditUserFormTitle.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string,
  usersUrl: PropTypes.string.isRequired
}

EditUserFormTitle.defaultProps = {
  title: ''
}

export default withRouter(EditUserFormTitle)
