import { connect } from 'react-redux'

import { actions } from 'ducks/groups'
import { openSmsServiceConsentDialog } from 'ducks/smsServiceConsentDialog/actions'
import { getIsSmsServiceConsentDialogOpen } from 'ducks/smsServiceConsentDialog/selectors'

import SmsServiceConsent from './SmsServiceConsent'

const mapStateToProps = state => {
  return {
    isSmsServiceConsentDialogOpen: getIsSmsServiceConsentDialogOpen(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserDetails: () => dispatch(actions.getUserDetails(0, 0)),
    openSmsServiceConsentDialog: () => dispatch(openSmsServiceConsentDialog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsServiceConsent)
