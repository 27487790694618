import { connect } from 'react-redux'

import { utils } from 'ducks/groups'

import ImportDashboards from './ImportDashboards'

const mapStateToProps = state => ({
  groupId: utils.getSelectedGroup(state).id
})

export default connect(mapStateToProps)(ImportDashboards)
