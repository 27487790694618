import * as t from './actionTypes'
import * as c from './constants'

const initialState = {
  [c.IS_DIALOG_OPEN]: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.OPEN_SMS_SERVICE_CONSENT_DIALOG:
      return { ...state, [c.IS_DIALOG_OPEN]: true }
    case t.CLOSE_SMS_SERVICE_CONSENT_DIALOG:
      return { ...state, [c.IS_DIALOG_OPEN]: false }

    default:
      return state
  }
}
