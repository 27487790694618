import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import Card from 'components/UnboxedCard'

import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import Feedback from './Feedback'
import messages from './messages'
import { csTypes } from './options'
import { device, deviceImg } from './style.module.css'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const cellMargins = { marginTop: '1em', marginBottom: '1em' }

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}
class NewRegistrationForm extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      name: '',
      name_errorText: '',

      eid: props.eid,
      eid_errorText: '',

      regCode: props.regCode,
      regCode_errorText: '',

      serialNumber: props.serialNumber,
      serialNumber_errorText: '',

      csType: 10,
      csType_errorText: '',

      feedbackDialogOpen: false,
      phase: 'activation',
      status: '',
      loading: false,
      activationErrorMessage: '',
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    const { value, name } = event.target
    const propertyValue = name
    const propertyErrorText = name + '_errorText'
    const elementTextError = value === '' ? this.formatMessage(messages.thisFieldIsRequired) : ''

    this.setState({
      [propertyValue]: value,
      [propertyErrorText]: elementTextError
    })
  }

  handleCSTypeChange = event => {
    this.setState({ csType: event.target.value })
  }

  handleActivateSimCard = event => {
    this.setState({ activateSimCard: event.target.value })
  }

  createSelectMenuWithLabels = (itemList = []) => {
    return itemList.map((elem, i) => {
      return (
        <MenuItem key={i} value={elem.value}>
          {elem.label}
        </MenuItem>
      )
    })
  }

  changeToFeedbackPhase = () => {
    this.setState({
      loading: false,
      feedbackDialogOpen: true
    })
  }

  onDialogClose = () => {
    this.setState({
      feedbackDialogOpen: false
    })
  }

  handleDeviceActivation = () => {
    const { activateDevice, getDevice } = this.props
    const { csType, name, serialNumber, regCode, eid } = this.state

    let save = true
    const new_state = {}

    if (name === '') {
      new_state['name'] = ''
      new_state['name_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (eid === '') {
      new_state['eid'] = ''
      new_state['eid_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (csType === 10 && regCode === '') {
      new_state['regCode'] = ''
      new_state['regCode_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (csType !== 10 && serialNumber === '') {
      new_state['serialNumber'] = ''
      new_state['serialNumber_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (save) {
      const deviceType = 'CS' + csType

      this.setState(
        {
          loading: true
        },
        () => {
          const extraParameters = deviceType === 'CS10' ? { regCode } : { serialNumber }
          activateDevice(name, eid, extraParameters)
            .then(response => {
              if (response?.data?.id) return getDevice(response.data.id)
              else return getDevice(response.data.deviceId)
            })
            .then(response => {
              const { data } = { ...response }
              this.setState(
                {
                  status: data.status,
                  csType: data.deviceType,
                  loading: false
                },
                this.changeToFeedbackPhase
              )
            })
            .catch(response => {
              const { error } = { ...response }
              if (error.response) {
                let errorMessage
                switch (error.response.status) {
                  case 400:
                    if (typeof error.response.data === 'string') errorMessage = error.response.data
                    else errorMessage = error.response.data.message
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: this.formatMessage(messages.error, {
                        number: '400'
                      }),
                      alertMessagesText: [this.formatMessage(messages.error400Message) + ': ' + errorMessage]
                    })
                    break
                  case 404:
                    if (typeof error.response.data === 'string') errorMessage = error.response.data
                    else errorMessage = error.response.data.message
                    if (errorMessage.toUpperCase().includes('DEVICE NOT FOUND')) {
                      this.setState(
                        {
                          activationErrorMessage: this.formatMessage(messages.deviceNotFound),
                          loading: false
                        },
                        this.changeToFeedbackPhase
                      )
                    } else {
                      this.setState({
                        loading: false,
                        alertMessages: true,
                        alertMessagesType: 'danger',
                        alertMessagesTitle: this.formatMessage(messages.error, {
                          number: '404'
                        }),
                        alertMessagesText: [this.formatMessage(messages.error404Message) + ': ' + errorMessage]
                      })
                    }
                    break
                  case 409:
                    let errorMsg
                    if (typeof error.response.data === 'string') errorMsg = error.response.data
                    else errorMsg = error.response.data.message
                    if (errorMsg.toUpperCase().includes('DEVICE IS ALREADY ACTIVATED')) {
                      this.setState(
                        {
                          activationErrorMessage: this.formatMessage(messages.eidDeviceAlreadyActivated),
                          loading: false
                        },
                        this.changeToFeedbackPhase
                      )
                    } else {
                      this.setState({
                        loading: false,
                        alertMessages: true,
                        alertMessagesType: 'danger',
                        alertMessagesTitle: this.formatMessage(messages.error, {
                          number: '409'
                        }),
                        alertMessagesText: [this.formatMessage(messages.error409Message) + ': ' + errorMsg]
                      })
                    }
                    break
                  case 500:
                    let url
                    if (error.request && error.request.responseURL) url = error.request.responseURL
                    let alertMessagesText = []
                    if (url && url.endsWith('activate'))
                      alertMessagesText = [this.formatMessage(messages.activateError500Message)]
                    else alertMessagesText = [this.formatMessage(messages.error500Message)]
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: this.formatMessage(messages.error, {
                        number: '500'
                      }),
                      alertMessagesText
                    })
                    break
                  case 501:
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: this.formatMessage(messages.error, {
                        number: '501'
                      }),
                      alertMessagesText: [this.formatMessage(messages.error501Message)]
                    })
                    break
                  default:
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                      alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                    })
                }
              } else {
                this.setState({
                  loading: false,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                  alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                })
              }
            })
        }
      )
    } else {
      this.setState(new_state)
    }
  }

  onExit = () => {
    this.setState({
      phase: 'activation',
      eid: '',
      name: '',
      serialNumber: '',
      activateSimCard: true,
      csType: 10,
      regCode: '',
      status: '',
      loading: false,
      activationErrorMessage: '',
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: ' ',
      alertMessagesText: ['']
    })
  }

  render() {
    const { canCreateDevices, classes, groupId } = this.props
    const {
      activationErrorMessage,
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      csType,
      eid,
      eid_errorText,
      feedbackDialogOpen,
      loading,
      name,
      name_errorText,
      regCode,
      regCode_errorText,
      serialNumber,
      serialNumber_errorText,
      status
    } = this.state

    const someError =
      Object.keys(this.state)
        .filter(s => s.endsWith('errorText'))
        .some(s => this.state[s] !== '') || name === ''

    let image
    switch ('CS' + csType) {
      case 'CS10':
        image = 'cs10.png'
        break
      case 'CS100':
        image = 'cs100.png'
        break
      case 'CS500':
        image = 'cs500.jpg'
        break
      default:
        image = 'cs10.png'
    }

    return (
      <div className="container-fluid">
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />

        <Grid container={true} spacing={3}>
          <Grid item={true} xs={8}>
            <Paper style={{ borderRadius: 0 }}>
              <Card>
                <CardHeader style={{ paddingBottom: '0px' }} title={this.formatMessage(messages.register)} />
                <CardContent>
                  <div>
                    <FormControl fullWidth={true}>
                      <InputLabel>{this.formatMessage(messages.csType) + '*'}</InputLabel>
                      <Select onChange={this.handleCSTypeChange} style={{ fontSize: '14px' }} value={csType}>
                        {this.createSelectMenuWithLabels(csTypes)}
                      </Select>
                    </FormControl>
                    <TextField
                      autoFocus={true}
                      error={name_errorText !== ''}
                      fullWidth={true}
                      helperText={name_errorText}
                      label={this.formatMessage(messages.name) + '*'}
                      name="name"
                      onChange={this.handleInputChange}
                      style={cellMargins}
                      value={name}
                    />
                    <TextField
                      autoFocus={true}
                      error={eid_errorText !== ''}
                      fullWidth={true}
                      helperText={eid_errorText}
                      label={this.formatMessage(messages.eid) + '*'}
                      name="eid"
                      onChange={this.handleInputChange}
                      style={cellMargins}
                      value={eid}
                    />
                    {csType === 10 ? (
                      <TextField
                        autoFocus={true}
                        error={regCode_errorText !== ''}
                        fullWidth={true}
                        helperText={regCode_errorText}
                        label={this.formatMessage(messages.regCode) + '*'}
                        name="regCode"
                        onChange={this.handleInputChange}
                        style={cellMargins}
                        value={regCode}
                      />
                    ) : (
                      <TextField
                        autoFocus={true}
                        error={serialNumber_errorText !== ''}
                        fullWidth={true}
                        helperText={serialNumber_errorText}
                        label={this.formatMessage(messages.serialNumber) + '*'}
                        name="serialNumber"
                        onChange={this.handleInputChange}
                        style={cellMargins}
                        value={serialNumber}
                      />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item={true} xs={4}>
            <Paper className={device} style={{ borderRadius: 0 }}>
              <img
                alt={this.formatMessage(messages.deviceImage)}
                className={deviceImg}
                src={process.env.PUBLIC_URL + '/images/' + image}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid className="text-right" item={true} xs={12}>
            <div style={{ padding: '20px 0' }}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                style={{ padding: '20px 0' }}
                title={
                  groupId !== DEFAULT_GROUP_ID ? this.formatMessage(messages.canActivateDevicesOnlyInDefaultGroup) : ''
                }
              >
                <div style={{ display: 'inline-block' }}>
                  <Button
                    className="primary-action-button"
                    disabled={someError || loading || !canCreateDevices}
                    onClick={this.handleDeviceActivation}
                  >
                    <span>{this.formatMessage(messages.activate)}</span>
                    {loading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Feedback
          activationErrorMessage={activationErrorMessage}
          alertMessages={alertMessages}
          alertMessagesText={alertMessagesText}
          alertMessagesTitle={alertMessagesTitle}
          alertMessagesType={alertMessagesType}
          deviceType={csType}
          eid={eid}
          handleClose={this.onDialogClose}
          handleExited={this.onExit}
          name={name}
          open={feedbackDialogOpen}
          regCode={regCode}
          serialNumber={serialNumber}
          status={status}
        />
      </div>
    )
  }
}

NewRegistrationForm.propTypes = {
  activateDevice: PropTypes.func.isRequired,
  canCreateDevices: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  getDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  regCode: PropTypes.string.isRequired,
  serialNumber: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(NewRegistrationForm))
