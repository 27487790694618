import * as c from '../constants'

import {
  LOGO,
  PAGE_TITLE,
  FAVICON,
  APP_BAR_COLOR,
  GROUP_NAME,
  LOGIN_PATH,
  FONT_COLOR,
  SIDEBAR_COLOR,
  LOGIN_TEXT,
  LOGIN_IMAGE
} from './defaults'

// Logo and logoBig-small not initially defined to avoid unpleasant flickering
// in the first load.

const cachedTheme = JSON.parse(localStorage.getItem('theme') || '{}')

const defaultTheme = {
  title: PAGE_TITLE,
  favicon: FAVICON,
  logoBig: LOGO,
  appBarColor: APP_BAR_COLOR,
  groupName: GROUP_NAME,
  loginPath: LOGIN_PATH,
  fontColor: FONT_COLOR,
  sideBarColor: SIDEBAR_COLOR,
  loginText: LOGIN_TEXT,
  loginImage: LOGIN_IMAGE
}

const initialState = {
  title: cachedTheme.title || PAGE_TITLE,
  favicon: cachedTheme.favicon || FAVICON,
  logoBig: cachedTheme.logoBig || LOGO,
  appBarColor: cachedTheme.appBarColor || APP_BAR_COLOR,
  groupName: cachedTheme.groupName || GROUP_NAME,
  loginPath: cachedTheme.loginPath || LOGIN_PATH,
  fontColor: cachedTheme.fontColor || FONT_COLOR,
  sideBarColor: cachedTheme.sideBarColor || SIDEBAR_COLOR,
  loginText: cachedTheme.loginText || LOGIN_TEXT,
  loginImage: cachedTheme.loginImage || LOGIN_IMAGE
}

const theme = (state = initialState, action) => {
  switch (action.type) {
    case c.SET_THEME:
      const logoBig = action.theme.logoBig || LOGO
      const favicon = action.theme.favicon || FAVICON
      const title = action.theme.title || PAGE_TITLE
      const appBarColor = action.theme.appBarColor || APP_BAR_COLOR
      const groupName = action.theme.groupName || GROUP_NAME
      const loginPath = action.theme.loginPath || LOGIN_PATH
      const fontColor = action.theme.fontColor || FONT_COLOR
      const sideBarColor = action.theme.sideBarColor || SIDEBAR_COLOR
      const loginText = action.theme.loginText || LOGIN_TEXT
      const loginImage = action.theme.loginImage || LOGIN_IMAGE

      const updatedTheme = {
        logoBig,
        favicon,
        title,
        appBarColor,
        groupName,
        loginPath,
        fontColor,
        sideBarColor,
        loginText,
        loginImage
      }
      localStorage.setItem('theme', JSON.stringify(updatedTheme))
      return {
        ...state,
        ...updatedTheme
      }

    default:
  }
  return state
}

export default theme

// BEGIN: selectors
const getSelector = state => state[c.NAME]
const getTheme = state => getSelector(state)
const getFavicon = state => getSelector(state).favicon
const getPageTitle = state => getSelector(state).title
const getLogo = state => getSelector(state).logoBig
const getAppBarColor = state => getSelector(state).appBarColor
const getGroupName = state => getSelector(state).groupName
const getLoginPath = state => getSelector(state).loginPath
const getFontColor = state => getSelector(state).fontColor
const getSideBarColor = state => getSelector(state).sideBarColor
const getLoginText = state => getSelector(state).loginText
const getLoginImage = state => getSelector(state).loginImage
const getDefaultTheme = () => defaultTheme

// END: selectors

export {
  getTheme,
  getDefaultTheme,
  getFavicon,
  getPageTitle,
  getLogo,
  getAppBarColor,
  getGroupName,
  getLoginPath,
  getFontColor,
  getSideBarColor,
  getLoginText,
  getLoginImage
}
