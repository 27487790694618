import { defineMessages } from 'react-intl'

const namespace = 'gmaps.Geofences'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  thereAreCurrentlyNoGeofences: {
    id: `${namespace}.thereAreCurrentlyNoGeofences`,
    description: 'There are currently no geofences message',
    defaultMessage: 'There are currently no geofences.'
  },
  ok: {
    id: `${namespace}.ok`,
    description: 'Ok button label',
    defaultMessage: 'OK'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'CANCEL'
  },
  delete: {
    id: `${namespace}.delete`,
    description: 'Delete button label',
    defaultMessage: 'DELETE'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name dialog content text',
    defaultMessage: 'Name:'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description dialog content text',
    defaultMessage: 'Description:'
  },
  group: {
    id: `${namespace}.group`,
    description: 'Group dialog content text',
    defaultMessage: 'Group:'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status dialog content text',
    defaultMessage: 'Status:'
  },
  edit: {
    id: `${namespace}.edit`,
    description: 'Edit button label',
    defaultMessage: 'Edit'
  },
  search: {
    id: `${namespace}.search`,
    description: 'Search autocomplete',
    defaultMessage: 'Search'
  },
  deleteLow: {
    id: `${namespace}.deleteLow`,
    description: 'Delete button label lowcase',
    defaultMessage: 'Delete'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Geofences title',
    defaultMessage: 'Geofence'
  },
  createGeofence: {
    id: `${namespace}.createGeofence`,
    description: 'Create geofence button label',
    defaultMessage: 'New Geofence'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  notification: {
    id: `${namespace}.notification`,
    description: 'Notification',
    defaultMessage: 'Notification:'
  },
  machines: {
    id: `${namespace}.machines`,
    description: 'Machines',
    defaultMessage: 'Machines:'
  },
  notificationEnabled: {
    id: `${namespace}.notificationEnabled`,
    description: 'Notification rule assigned and enabled',
    defaultMessage: 'Notification enabled'
  },
  notificationDisabled: {
    id: `${namespace}.notificationDisabled`,
    description: 'Notification rule assigned and disabled',
    defaultMessage: 'Notification disabled'
  },
  notificationNotAssigned: {
    id: `${namespace}.notificationNotAssigned`,
    description: 'No notification rule assigned',
    defaultMessage: 'No notification assigned'
  },
  somethingWentWrong: {
    id: `${namespace}.somethingWentWrong`,
    description: 'Something went wrong',
    defaultMessage: 'Something went wrong:'
  },
  cantCreateInDefaultGroup: {
    id: `${namespace}.cantCreateInDefaultGroup`,
    description: 'You can`t create geofences in Danfoss Default Group',
    defaultMessage: 'You can`t create geofences in Danfoss Default Group'
  }
})

export default messages
