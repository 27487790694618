import { BASIC } from './constants'

const getDefaultSettingsByHistoricType = ({ devicesData, eid, data }, historicType) => {
  if (historicType === BASIC) {
    const signals = []
    const aggregations = []
    const valueTypes = []
    let selectedTimeRange = -1
    let numberOfDecimals = 0

    if (devicesData && eid && data) {
      const settings = data.settings || data
      const {
        data: settingsData,
        aggregations: settingsAggregations,
        valueTypes: settingsValueTypes,
        selectedTimeRange: settingsSelectedTimeRange,
        numberOfDecimals: settingsNumberOfDecimals = 0
      } = settings

      const dinamicData = devicesData[eid]?.dinamicData || []
      const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)

      if (settingsData && dinamicData.length > 0) {
        for (let i = 0; i < settingsData.length; i++) {
          if (dinamicDataSignalIds.includes(settingsData[i])) {
            signals.push(settingsData[i])
            if (settingsAggregations && settingsAggregations[i]) aggregations.push(settingsAggregations[i])
            else aggregations.push('none')
            if (settingsValueTypes && settingsValueTypes[i]) valueTypes.push(settingsValueTypes[i])
            else valueTypes.push('none')
          }
        }
      }
      selectedTimeRange = settingsSelectedTimeRange >= 0 ? Math.min(settingsSelectedTimeRange, 4) : -1
      numberOfDecimals = settingsNumberOfDecimals
    }

    return {
      dinamicData: signals,
      aggregations,
      valueTypes,
      selectedTimeRange,
      selectedTimeRangeErrorText: '',
      numberOfDecimals,
      numberOfDecimalsErrorText: ''
    }
  } else {
    let widgetAdvancedSignals = []
    let type = BASIC
    let selectedTimeRange = -1
    if (data) {
      const {
        settings: { signals = [], type: settingsType = BASIC, selectedTimeRange: settingsSelectedTimeRange = -1 }
      } = data

      const { advancedSignals = [] } = devicesData[eid]
      const filteredSignals = signals.filter(
        signal => advancedSignals.findIndex(({ configHashId }) => configHashId === signal) > -1
      )

      widgetAdvancedSignals = filteredSignals
      type = settingsType
      selectedTimeRange = settingsSelectedTimeRange
    }

    return {
      widgetAdvancedSignals,
      type,
      selectedTimeRange,
      selectedTimeRangeErrorText: ''
    }
  }
}

const getMomentParamsFromSelectedTimeRange = (selectedTimeRange = 0) => {
  const momentParamsOptions = [
    [1, 'day'],
    [3, 'days'],
    [1, 'week'],
    [2, 'weeks'],
    [1, 'month']
  ]
  return momentParamsOptions[selectedTimeRange]
}

const getMomentParamsFromSelectedTimeRangeBasicSignals = (selectedTimeRange = 0) => {
  const momentParamsOptions = [
    [1, 'hour'],
    [3, 'hours'],
    [6, 'hours'],
    [12, 'hours'],
    [1, 'day']
  ]
  return momentParamsOptions[selectedTimeRange]
}

export {
  getDefaultSettingsByHistoricType,
  getMomentParamsFromSelectedTimeRange,
  getMomentParamsFromSelectedTimeRangeBasicSignals
}
