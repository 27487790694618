import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  item: {
    borderLeft: '3px solid transparent',
    '&:hover': {
      borderLeft: '3px solid #fff',
      backgroundColor: props => props.color + '33',
      fontWeight: '500'
    }
  },
  label: {
    color: props => props.color,
    fontSize: 14,
    fontWeight: 'inherit'
  },
  labelDeviceManagement: {
    color: props => props.color,
    fontSize: 14,
    fontWeight: 'inherit',
    marginRight: '12px'
  },
  labelMachines: {
    color: props => props.color,
    fontSize: 14,
    fontWeight: 'inherit',
    marginRight: '14px'
  },
  inset: {
    padding: 0
  },
  collapsedRoot: {
    alignContent: 'center',
    padding: 0,
    width: '100%'
  },
  collapsedLabel: {
    color: props => props.color,
    fontSize: 10,
    overflowWrap: 'break-word',
    textAlign: 'center'
  },
  addToHomeAvatar: {
    height: '22px',
    opacity: '90%',
    padding: 2,
    textAlign: 'center',
    width: '26px'
  }
})

const SidebarItem = props => {
  const { avatarProps, href, icon, isCollapsed, isCurrentPath, label, color, classes, ...others } = props

  let item = null
  if (isCollapsed) {
    item = (
      <ListItem
        {...others}
        button
        classes={{ root: classes.item }}
        style={{
          ...isCurrentPath ? { borderLeft: '3px solid #fff', backgroundColor: color + '33', fontWeight: '500' } : {}
        }}
      >
        {avatarProps ? (
          <ListItemAvatar>
            <Avatar
              {...avatarProps}
              classes={{ root: classes.addToHomeAvatar }}
              style={{
                marginTop: 3
              }}
            />
          </ListItemAvatar>
        ) : (
          <ListItemIcon classes={{ root: classes.label }}>{icon}</ListItemIcon>
        )}
      </ListItem>
    )
  } else {
    item = (
      <ListItem
        {...others}
        button
        classes={{ root: classes.item }}
        style={{
          ...avatarProps ? { marginTop: 3 } : {},
          ...isCurrentPath ? { borderLeft: '3px solid #fff', backgroundColor: color + '33', fontWeight: '500' } : {},
          width: '220px'
        }}
      >
        {avatarProps ? (
          <ListItemAvatar>
            <Avatar
              {...avatarProps}
              classes={{ root: classes.addToHomeAvatar }}
              style={{
                marginRight: 12
              }}
            />
          </ListItemAvatar>
        ) : (
          <ListItemIcon
            classes={{
              root:
                href === '/devices'
                  ? classes.labelDeviceManagement
                  : href === '/machines'
                    ? classes.labelMachines
                    : classes.label
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            root: classes.itemText,
            primary: classes.label,
            inset: classes.inset
          }}
          inset
        >
          {label}
        </ListItemText>
      </ListItem>
    )
  }

  if (href) {
    return (
      <Link style={{ textDecoration: 'none' }} to={href}>
        {item}
      </Link>
    )
  } else {
    return item
  }
}

SidebarItem.propTypes = {
  avatarProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node,
  isCollapsed: PropTypes.bool.isRequired,
  isCurrentPath: PropTypes.bool,
  label: PropTypes.node.isRequired
}

SidebarItem.defaultProps = {
  avatarProps: null,
  href: '',
  icon: null,
  isCurrentPath: false
}

export default withStyles(styles)(SidebarItem)
