import { PREFIX } from '../constants'

export const SET_TITLE = PREFIX + 'SET_TITLE'
export const UPDATE_CHART_CONFIGURATION = PREFIX + 'UPDATE_CHART_CONFIGURATION'
export const UPDATE_CHART_DATA = PREFIX + 'UPDATE_CHART_DATA'

export const TOGGLE_NAVIGATOR_VISIBILITY =
  PREFIX + 'TOGGLE_NAVIGATOR_VISIBILITY'
export const SET_XAXIS_LIMITS = PREFIX + 'SET_XAXIS_LIMITS'

export const SET_SERIES = PREFIX + 'SET_SERIES'

export const SHOW_SERIE = PREFIX + 'SHOW_SERIE'
export const HIDE_SERIE = PREFIX + 'HIDE_SERIE'

export const SHOW_NAVIGATOR = PREFIX + 'SHOW_NAVIGATOR'
export const HIDE_NAVIGATOR = PREFIX + 'HIDE_NAVIGATOR'

export const START_PLOTTING = PREFIX + 'START_PLOTTING'
export const FINISH_PLOTTING = PREFIX + 'FINISH_PLOTTING'

// Actions needed to change between modes:
export const TOGGLE_NORMAL = PREFIX + 'TOGGLE_NORMAL'
export const TOGGLE_REAL_TIME = PREFIX + 'TOGGLE_REAL_TIME'
