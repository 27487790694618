import React from 'react'

import moment from 'moment'
import { TreeListRoles, TreeListPrivileges } from './TreeList'

import messages from './messages'

const momentDate = data => {
  return moment(data).format('L')
}

const rolesHeaderToListitems = formatMessage => {
  const listItems = []

  // Item to "simulate" the TreeList Header
  const itemHeader = {
    title: (
      <TreeListRoles
        actionsTitle={formatMessage(messages.actions)}
        className='listHeader'
        createdDate={formatMessage(messages.createdAt)}
        description={formatMessage(messages.roleName)}
        hashId=''
        lastModifiedDate={formatMessage(messages.modifiedAt)}
        usersCount={formatMessage(messages.users)}
      />
    ),
    parentIndex: 0,
    depth: 1,
    disabled: !0
  }

  listItems.push(itemHeader)

  return listItems
}

const processRolesHierarchyToListitems = (userRole, rolesHierarchy, deleteRole) => {
  const listItems = []

  const deleteRoleFunction = deleteRole

  const addItem = (role, parentIndex = 0, depth = 1) => {
    const item = {
      title: (
        <TreeListRoles
          actionsTitle=''
          className=''
          createdDate={role.createdDate}
          deleteRole={() => {
            deleteRoleFunction(role)
          }}
          description={role.description}
          hashId={role.hashId}
          lastModifiedDate={role.lastModifiedDate}
          name={role.name}
          userRole={userRole}
          usersCount={role.usersCount}
        />
      ),
      parentIndex,
      depth,
      disabled: !1
    }

    const index = listItems.length
    listItems.push(item)

    if (role.children.length > 0) {
      item.children = role.children.map(children => addItem(children, index, depth + 1))
    }

    return index
  }

  rolesHierarchy.map(role => addItem(role))

  return listItems
}

const processRolesHierarchyToSelect = (rolesHierarchy, hashIdValue) => {
  const listItems = []

  const addItem = (role, parentIndex = 0, depth = 1) => {
    if (role.hashId !== hashIdValue) {
      const item = {
        title: role.description,
        hashId: role.hashId,
        parentIndex,
        depth,
        disabled: !1
      }

      const index = listItems.length
      listItems.push(item)

      if (role.children.length > 0) {
        item.children = role.children.map(children => addItem(children, index, depth + 1))
      }

      return index
    }
  }

  rolesHierarchy.map(role => addItem(role))

  return listItems
}

const privilegeExists = (parentPrivileges, privilegeHashId) => {
  const exists = parentPrivileges.filter(parentPrivilege => {
    return parentPrivilege.hashId === privilegeHashId
  })

  if (exists.length > 0) {
    return true
  } else {
    return false
  }
}

const privilegeCheck = (privilegeState, hashId) => {
  const check = privilegeState.filter(privilege => {
    return privilege.hashId === hashId
  })

  if (check.length > 0) {
    return true
  } else {
    return false
  }
}

const fakeHasChildren = (parentPrivileges, childrenHashes) => {
  const hasChild = parentPrivileges.filter(privilege => {
    const childs = childrenHashes.filter(children => {
      return privilege.hashId === children
    })

    if (childs.length > 0) {
      return true
    } else {
      return false
    }
  })

  if (hasChild.length > 0) {
    return true
  } else {
    return false
  }
}

const updatePrivilegeState = (privileges, privilegeState, hashId, value) => {
  let newPrivilegeState
  //This first case is due to 'USER_TRACK_PRIVILEGE' as it is privilege for activity log
  //and Download user activity
  if (value === false) {
    // We must remove the privilege
    newPrivilegeState = privilegeState.filter(privilege => {
      return privilege.hashId !== hashId
    })
    const removedPrivileges = privilegeState.filter(privilege => !newPrivilegeState.includes(privilege))
    if (removedPrivileges.length > 0) {
      const removedPrivilege = removedPrivileges[0]
      if (removedPrivilege.name === 'CHANGE_CONFIG_PRIVILEGE') {
        newPrivilegeState = newPrivilegeState.filter(privilege => privilege.name !== 'READ_CONFIG_PRIVILEGE')
      }
      if (removedPrivilege.name === 'WRITE_NOTIFICATION_PRIVILEGE') {
        newPrivilegeState = newPrivilegeState.filter(privilege => privilege.name !== 'READ_NOTIFICATION_PRIVILEGE')
      }
      if (removedPrivilege.name === 'WRITE_DASHBOARD_PRIVILEGE') {
        newPrivilegeState = newPrivilegeState.filter(privilege => privilege.name !== 'READ_DASHBOARD_PRIVILEGE')
      }
    }
  } else {
    if (privilegeState.filter(privilege => privilege.hashId === hashId).length > 0) {
      return privilegeState
    } else {
      const privilegeToAdd = privileges.filter(privilege => {
        return privilege.hashId === hashId
      })

      newPrivilegeState = privilegeState.slice(0)
      newPrivilegeState.push(privilegeToAdd[0])

      if (privilegeToAdd[0].name === 'CHANGE_CONFIG_PRIVILEGE') {
        const newPrivilege = privileges.filter(privilege => {
          return privilege.name === 'READ_CONFIG_PRIVILEGE'
        })
        newPrivilegeState.push(newPrivilege[0])
      }

      if (privilegeToAdd[0].name === 'WRITE_NOTIFICATION_PRIVILEGE') {
        const newPrivilege = privileges.filter(privilege => {
          return privilege.name === 'READ_NOTIFICATION_PRIVILEGE'
        })
        newPrivilegeState.push(newPrivilege[0])
      }

      if (privilegeToAdd[0].name === 'WRITE_DASHBOARD_PRIVILEGE') {
        const newPrivilege = privileges.filter(privilege => {
          return privilege.name === 'READ_DASHBOARD_PRIVILEGE'
        })
        newPrivilegeState.push(newPrivilege[0])
      }
    }
  }

  return newPrivilegeState
}

const processPrivilegesToListitems = (privilegesHierarchy, parentPrivileges, setPrivilegeState, privilegeState) => {
  const listItems = []

  if (
    privilegesHierarchy !== undefined &&
    parentPrivileges &&
    parentPrivileges.length > 0 &&
    setPrivilegeState !== undefined &&
    privilegeState !== undefined
  ) {
    const addItem = (privilege, parentIndex = 0, depth = 1) => {
      /* eslint-disable no-shadow */
      if (
        privilege.hashId === 'fake' && fakeHasChildren(parentPrivileges, privilege.childrenHashes) ||
        privilegeExists(parentPrivileges, privilege.hashId)
      ) {
        let childrenHashes = []
        if (privilege.hashId === 'fake') {
          childrenHashes = privilege.childrenHashes.filter(hashId =>
            parentPrivileges.find(privilege => privilege.hashId === hashId)
          )
        }

        const item = {
          title: (
            <TreeListPrivileges
              childrenHashes={childrenHashes}
              description={privilege.description}
              hashId={privilege.hashId}
              privilegeState={privilegeState}
              setPrivilegeState={setPrivilegeState}
            />
          ),
          parentIndex,
          depth,
          disabled: !1
        }

        const index = listItems.length
        listItems.push(item)

        if (privilege.children && privilege.children.length > 0) {
          item.children = privilege.children.map(children => addItem(children, index, depth + 1))
        }

        return index
      }
      /* eslint-enable */
    }
    privilegesHierarchy.map(privilege => addItem(privilege))
  }

  return listItems
}

const getParentRole = (roles, parentRoleHashId) => {
  const parentRole = roles.filter(role => {
    return role.hashId === parentRoleHashId
  })

  return parentRole[0]
}

const mapRolesProperties = roles =>
  roles.map(role => ({
    hashId: role.id,
    name: role.role_name,
    createdDate: momentDate(role.createdAt),
    lastModifiedDate: momentDate(role.updatedAt),
    description: role.role_description,
    parent_id: typeof role.parent_id === 'undefined' ? '' : role.parent_id,
    children: []
  }))

const getChildrenAndHashIds = (parentHashId, childrenRoles) => {
  const directChildren = childrenRoles.filter(role => role.parent_id === parentHashId)
  if (directChildren.length === 0) return [[], []]
  else {
    let offsprings = childrenRoles.filter(role => !role.parent_id !== parentHashId)
    return directChildren.reduce(
      (acc, cur) => {
        const [children, childrenHashIds] = getChildrenAndHashIds(cur.hashId, offsprings)
        offsprings = offsprings.filter(role => !childrenHashIds.includes(role.hashId))
        const currentWithChildren = { ...cur, children }
        const hashIds = [cur.hashId].concat(childrenHashIds)
        return [acc[0].concat([currentWithChildren]), acc[1].concat(hashIds)]
      },
      [[], []]
    )
  }
}

const mapToHierarchicalRoles = roles => {
  const rolesWithUpdatedProperties = mapRolesProperties(roles)
  const allHashIds = rolesWithUpdatedProperties.map(role => role.hashId)
  // eslint-disable-next-line prefer-const
  let [rootRoles, childrenRoles] = rolesWithUpdatedProperties.reduce(
    (acc, cur) => {
      if (allHashIds.includes(cur.parent_id)) return [acc[0], acc[1].concat([cur])]
      else return [acc[0].concat([cur]), acc[1]]
    },
    [[], []]
  )
  // eslint-disable-next-line no-shadow
  const hierarchicRoles = rootRoles.map(role => {
    const [children, hashIds] = getChildrenAndHashIds(role.hashId, childrenRoles)
    // eslint-disable-next-line no-shadow
    childrenRoles = childrenRoles.filter(role => !hashIds.includes(role.hashId))
    return { ...role, children }
  })
  return hierarchicRoles
}

const mapCSToRCTPermissions = permissions =>
  permissions.map(
    (permission, index) =>
      permission.name !== 'device.create' && {
        '@objectId': index + 1,
        name: permission.name,
        description: permission.description,
        hashId: permission.id
      }
  )

export {
  momentDate,
  rolesHeaderToListitems,
  processRolesHierarchyToListitems,
  processRolesHierarchyToSelect,
  privilegeCheck,
  updatePrivilegeState,
  processPrivilegesToListitems,
  getParentRole,
  mapRolesProperties,
  mapToHierarchicalRoles,
  mapCSToRCTPermissions
}
