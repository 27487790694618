import { defineMessages } from 'react-intl'

const messages = defineMessages({
  boundary: {
    id: 'gmaps.ConfigureNotificationStep.boundary',
    description: 'Boundary floating label text',
    defaultMessage: 'Geofence boundary'
  },
  disabled: {
    id: 'gmaps.ConfigureNotificationStep.disabled',
    description: 'Disabled menu item primary text',
    defaultMessage: 'Disabled'
  },
  enabled: {
    id: 'gmaps.ConfigureNotificationStep.enabled',
    description: 'Enabled menu item primary text',
    defaultMessage: 'Enabled'
  },
  high: {
    id: 'gmaps.ConfigureNotificationStep.high',
    description: 'High menu item primary text',
    defaultMessage: 'High'
  },
  low: {
    id: 'gmaps.ConfigureNotificationStep.low',
    description: 'Low menu item primary text',
    defaultMessage: 'Low'
  },
  medium: {
    id: 'gmaps.ConfigureNotificationStep.medium',
    description: 'Medium menu item primary text',
    defaultMessage: 'Medium'
  },
  severity: {
    id: 'gmaps.ConfigureNotificationStep.severity',
    description: 'Severity floating label text',
    defaultMessage: 'Notification severity'
  },
  status: {
    id: 'gmaps.ConfigureNotificationStep.status',
    description: 'Status floating label text',
    defaultMessage: 'Rule status'
  },
  cancel: {
    id: 'gmaps.NewGeofenceStep.cancel',
    description: 'Cancel button label',
    defaultMessage: 'CANCEL'
  },
  centerView: {
    id: 'gmaps.NewGeofenceStep.centerView',
    description: 'Center view to the geofence button text',
    defaultMessage: 'Center view'
  },
  color: {
    id: 'gmaps.NewGeofenceStep.color',
    description: 'Color floating label text',
    defaultMessage: 'Color'
  },
  description: {
    id: 'gmaps.NewGeofenceStep.description',
    description: 'Description hint text || floating label text',
    defaultMessage: 'Description*'
  },
  error: {
    id: 'gmaps.EditGeofence.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  errorUndefinedTitle: {
    id: 'gmaps.NewGeofenceStep.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'gmaps.NewGeofenceStep.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error400Message: {
    id: 'gmaps.NewGeofenceStep.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error401Message: {
    id: 'gmaps.EditGeofence.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error403Message: {
    id: 'gmaps.NewGeofenceStep.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'gmaps.NewGeofenceStep.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'gmaps.NewGeofenceStep.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  generalInformation: {
    id: 'gmaps.NewGeofenceStep.generalInformation',
    description: 'General information title',
    defaultMessage: 'General information'
  },
  incompleteRequiredFields: {
    id: 'gmaps.NewGeofenceStep.incompleteRequiredFields',
    description: 'There are incomplete required fields message',
    defaultMessage: 'There are incomplete required fields.'
  },
  name: {
    id: 'gmaps.NewGeofenceStep.name',
    description: 'Name text',
    defaultMessage: 'Name*'
  },
  nextStep: {
    id: 'gmaps.NewGeofenceStep.nextStep',
    description: 'Next',
    defaultMessage: 'NEXT'
  }
})

export default messages
