import * as t from './actionTypes'

const initialState = {
  baseTime: -1, // To display t0 when no measures have been received
  noopAppenderId: -1
}

const updated = (state = initialState, action) => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case t.SET_BASE_TIME:
      return { ...state, baseTime: action.payload}

    case t.RESET_NOOP_APPENDER:
      return { ...state, noopAppenderId: -1}

    case t.SET_NOOP_APPENDER:
      return { ...state, noopAppenderId: action.payload}

    default:
  }

  return state
}

export default updated
export const MOUNTED_AT = 'updated'

// Selectors
const getBaseTime = substate => substate[MOUNTED_AT].baseTime
const getNoopAppenderId = substate => substate[MOUNTED_AT].noopAppenderId

export { getBaseTime, getNoopAppenderId }
