import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { injectIntl } from 'react-intl'
import messages from './messages'

import uuid from 'uuid'

const NAME_MAX_LENGTH = 50

class AddDashboard extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      newDashboardName: '',
      newDashboardNameError: false,
      newDashboardNameMessage: this.formatMessage(messages.maxCharacters),
      newDashboardDescription: '',
      newDashboardHeight: '768',
      newDashboardHeightError: false,
      newDashboardWidth: '1366',
      newDashboardWidthError: false,
      zoom: 'actual',
      position: props.dashboardIds.length
    }
  }

  saveAddDashboard = () => {
    let newHeight
    let newWidth
    let error = false
    let newDashboardNameError = false
    let newDashboardNameMessage = this.formatMessage(messages.maxCharacters)
    let newDashboardHeightError = false
    let newDashboardWidthError = false

    if (this.state.newDashboardName === '') {
      error = true
      newDashboardNameError = true
      newDashboardNameMessage = this.formatMessage(messages.mustHaveAValue)
    } else if (this.state.newDashboardName.length > NAME_MAX_LENGTH) {
      error = true
      newDashboardNameError = true
      newDashboardNameMessage = this.formatMessage(messages.maximum50Characters)
    } else {
      error = false
      newDashboardNameError = false
      newDashboardNameMessage = this.formatMessage(messages.maxCharacters)
    }

    if (this.state.newDashboardHeight === '') {
      error = true
      newDashboardHeightError = true
    } else {
      newHeight = this.state.newDashboardHeight
    }

    if (this.state.newDashboardWidth === '') {
      error = true
      newDashboardWidthError = true
    } else {
      newWidth = this.state.newDashboardWidth
    }

    if (error) {
      this.setState({
        newDashboardNameError,
        newDashboardNameMessage,
        newDashboardHeightError,
        newDashboardWidthError
      })
    } else {
      this.setState({
        newDashboardNameError: false,
        newDashboardHeightError: false,
        newDashboardWidthError: false
      })

      this.newDashboard(
        this.state.newDashboardName,
        this.state.newDashboardDescription,
        parseInt(newHeight, 10),
        parseInt(newWidth, 10),
        this.state.zoom,
        this.state.position
      )

      this.props.closeAddDashboardModal()
    }
  }

  // eslint-disable-next-line max-params
  newDashboard = (name, description, height, width, zoom, position) => {
    const newDashboardUUID = uuid()

    if (description === '') {
      description = name // eslint-disable-line no-param-reassign
    }

    const descriptionJSON = JSON.stringify({
      description,
      height,
      width,
      zoom
    })

    const dashboardData = {
      uuid: newDashboardUUID,
      deviceId: this.props.deviceId,
      groupId: null,
      name,
      description: descriptionJSON,
      position
    }

    client
      .newDashboard(dashboardData)
      .then(response => {
        this.newDashboardSetup(response.data)
      })
      .catch(response => {
        // const error = Object.assign({}, response)
        logError(response)
      })
  }

  newDashboardSetup = dashboardData => {
    const textWindgetUUID = uuid()
    const newDashboardTemplate = `{
      "nodeFamily": "${this.props.nodeFamily}",
      "maxZIndex": 1,
      "random": "${uuid()}",
      "widgets": {
        "${textWindgetUUID}": {
          "id": "${textWindgetUUID}",
          "width": 300,
          "height": 30,
          "x": 20,
          "y": 20,
          "minHeight": 20,
          "minWidth": -1,
          "maxHeight": -1,
          "maxWidth": -1,
          "zIndex": 0,
          "content": {
            "widgetType": "text",
            "params": {
              "text": "${dashboardData.name}",
              "size": 14,
              "bold": "bold",
              "align": "left",
              "data": "",
              "value": {
                "timestamp": "",
                "value": ""
              },
              "conditionalParams": {
                "operator": "",
                "value": "",
                "text": "",
                "size": 0,
                "bold": "",
                "align": ""
              }
            }
          }
        }
      }
    }`

    const formData = new FormData()
    const blob = new Blob([newDashboardTemplate], { type: 'application/json' })
    formData.append('file', blob)

    // var request = new XMLHttpRequest()
    // request.open('POST', 'http://foo.com/submitform.php')
    // request.send(formData)

    client
      .newDashboardSetup(dashboardData.hashId, formData)
      .then(() => {
        this.props.refresh('other', dashboardData.hashId)
      })
      .catch(response => {
        // const error = Object.assign({}, response)
        logError(response)
      })
  }

  handleChange = name => event => {
    if (name === 'newDashboardName') {
      if (event.target.value === '') {
        this.setState({
          newDashboardName: '',
          newDashboardNameError: true,
          newDashboardNameMessage: this.formatMessage(messages.mustHaveAValue)
        })
      } else if (event.target.value.length > NAME_MAX_LENGTH) {
        this.setState({
          newDashboardName: event.target.value.substr(0, NAME_MAX_LENGTH),
          newDashboardNameError: true,
          newDashboardNameMessage: this.formatMessage(messages.maximum50Characters)
        })
      } else {
        this.setState({
          newDashboardName: event.target.value,
          newDashboardNameError: false,
          newDashboardNameMessage: this.formatMessage(messages.maxCharacters)
        })
      }
    }

    if (name !== 'newDashboardName') {
      this.setState({
        [name]: event.target.value
      })
    }
  }

  handleRadioChange = event => {
    this.setState({ zoom: event.target.value })
  }

  render() {
    return (
      <Dialog
        open={true}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.props.closeAddDashboardModal}
        aria-labelledby="alert-dialog-slide-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.newDashboard)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.props.closeAddDashboardModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                margin="normal"
                id="newDashboardName"
                label={this.formatMessage(messages.name)}
                value={this.state.newDashboardName}
                onChange={this.handleChange('newDashboardName')}
                type="text"
                helperText={this.state.newDashboardNameMessage}
                required={true}
                error={this.state.newDashboardNameError}
                fullWidth={true}
              />
              <TextField
                margin="normal"
                id="newDashboardDescription"
                label={this.formatMessage(messages.description)}
                value={this.state.newDashboardDescription}
                onChange={this.handleChange('newDashboardDescription')}
                type="text"
                required={false}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={6}>
              <TextField
                margin="normal"
                id="newDashboardWidth"
                label={this.formatMessage(messages.width)}
                value={this.state.newDashboardWidth}
                onChange={this.handleChange('newDashboardWidth')}
                helperText={this.state.newDashboardWidthError ? this.formatMessage(messages.mustHaveAValue) : null}
                type="text"
                required={true}
                error={this.state.newDashboardWidthError}
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <TextField
                margin="normal"
                id="newDashboardHeight"
                label={this.formatMessage(messages.height)}
                value={this.state.newDashboardHeight}
                onChange={this.handleChange('newDashboardHeight')}
                type="text"
                helperText={this.state.newDashboardHeightError ? this.formatMessage(messages.mustHaveAValue) : null}
                required={true}
                error={this.state.newDashboardHeightError}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={6}>
              <DialogContentText id="alert-dialog-slide-description">
                {this.formatMessage(messages.dashboardDisplayMode)}:
              </DialogContentText>
              <RadioGroup
                row={true}
                aria-label="zoom"
                name="zoom"
                value={this.state.zoom}
                onChange={this.handleRadioChange}
              >
                <FormControlLabel
                  value="actual"
                  control={<Radio color="primary" />}
                  label={this.formatMessage(messages.actualSize)}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="fit"
                  control={<Radio color="primary" />}
                  label={this.formatMessage(messages.fitToWidth)}
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <DialogContentText id="alert-dialog-slide-description">Tab position:</DialogContentText>
              <FormControl style={{ width: '100%' }} required={true}>
                <Select
                  value={this.state.position}
                  onChange={this.handleChange('position')}
                  input={<Input name="position" />}
                  name="position"
                >
                  {this.props.dashboardIds.map((dashboardId, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {index + 1}
                      </MenuItem>
                    )
                  })}
                  <MenuItem key={this.props.dashboardIds.length} value={this.props.dashboardIds.length}>
                    {this.props.dashboardIds.length + 1}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeAddDashboardModal}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveAddDashboard}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddDashboard.propTypes = {
  closeAddDashboardModal: PropTypes.func.isRequired,
  dashboardIds: PropTypes.array.isRequired,
  deviceId: PropTypes.string.isRequired,
  nodeFamily: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired
}

export default injectIntl(AddDashboard)
