import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import {
  getNotificationsRulesUrl,
  getNewNotificationUrl,
  getEditNotificationGeneralPropertiesUrl,
  getEditNotificationAssignUsersUrl
} from '../url'
import NotificationsRulesSettingsConnected from './NotificationsRulesSettings'

import { utils as groupUtils } from 'ducks/groups'

const mapStateToProps = (state, { history, match }) => ({
  canEditNotification: resolvePermissions(state, c.RULE_EDIT),
  canDeleteNotification: resolvePermissions(state, c.RULE_DELETE),
  canCreateNotification: resolvePermissions(state, c.RULE_CREATE),
  notificationsUrl: getNotificationsRulesUrl(),
  newNotificationUrl: getNewNotificationUrl(),
  editNotificationGeneralPropertiesUrl: getEditNotificationGeneralPropertiesUrl(),
  editNotificationAssignUsersUrl: getEditNotificationAssignUsersUrl(),
  urlAction: history.location.state,
  onDeleteNotification: () => {
    history.push(getNotificationsRulesUrl(), 'delete')
    window.location.reload() // FIXME!!!
  },
  userSelectedGroup: groupUtils.getSelectedGroup(state)
})

const NotificationsRulesSettings = withRouter(connect(mapStateToProps)(NotificationsRulesSettingsConnected))

export { NotificationsRulesSettings }
