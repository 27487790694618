import * as t from './actionTypes'
import * as url from  '../url'
import { createRequestAction } from './utils'

const getProtocols = groupId => 
  createRequestAction(t.CAN_PROTOCOLS, url.getProtocols(groupId), 'get')


const getProtocol = (groupId, protocolName) => 
  createRequestAction(t.CAN_PROTOCOL, url.getProtocol(groupId, protocolName), 'get')


const deleteProtocol = (groupId, protocolName) => 
  createRequestAction(t.DELETE_CAN_PROTOCOL, url.deleteProtocol(groupId, protocolName), 'delete')


const uploadDbcFile = (groupId, dbcFile) => 
  createRequestAction(t.UPLOAD_DBC_FILE, url.uploadDbcFile(groupId), 'post', dbcFile)


export {
  getProtocols,
  getProtocol,
  deleteProtocol,
  uploadDbcFile
}