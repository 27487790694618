const prefix = 'websocket/'

// These actions are used to mark the following process:
//  1. HTTP request to get credentials
//  2. Connect to websocket with credentials
export const CONNECT_START = prefix + 'CONNECT_START'
export const CONNECT_END = prefix + 'CONNECT_END'

export const REQUEST_SUBSCRIPTIONS = prefix + 'REQUEST_SUBSCRIPTIONS'
export const REQUEST_UNSUBSCRIPTIONS = prefix + 'REQUEST_UNSUBSCRIPTIONS'
