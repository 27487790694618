import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Home from './Home'

const mapStateToProps = state => ({
  canReadDevices: resolvePermissions(state, c.DEVICE_READ),
  canCreateDevices: resolvePermissions(state, c.DEVICE_CREATE),
  canEditDevices: resolvePermissions(state, c.DEVICE_EDIT),
  groupId: groupUtils.getSelectedGroup(state).id
})

export default connect(mapStateToProps)(Home)

export { getDevicesUrl, getDeviceEditUrl } from './urls'
