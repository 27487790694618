import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// Better than simply using MenuItem's onClick since
// it allows you to interact with the link in the browser.
const LinkedMenuItem = ({ to, label, icon, ...others }) => (
  <Link style={{ textDecoration: 'none' }} to={to}>
    <MenuItem {...others}>
      <ListItemIcon style={{ margin: 0 }}>{icon}</ListItemIcon>
      <ListItemText inset={true} primary={label} />
    </MenuItem>
  </Link>
)

LinkedMenuItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.node,
  to: PropTypes.string.isRequired
}

export default LinkedMenuItem
