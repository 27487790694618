import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions as cs500Actions } from 'ducks/configurationsCS500'
import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'
import { getEditNotificationGeneralPropertiesUrl } from '../url'
import UnfilledAssignDevicesToNotification from './AssignDevicesToNotification'

const mapStateToProps = (state, { match, history }) => ({
  editNotificationGeneralPropertiesUrl: getEditNotificationGeneralPropertiesUrl(match.params.notificationId),
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId),
  notificationId: match.params.notificationId,
  onNotificationAssignDevicesEdited: () => {
    history.push(getEditNotificationGeneralPropertiesUrl(match.params.notificationId), 'devicesAssigned')
  }
})

const mapDispatchToProps = dispatch => ({
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  getDevicesDetail: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs)),
  // eslint-disable-next-line max-params
  getGroupDevices: (groupId, limit, offset, deviceFields, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields, filters))
})

const AssignDevicesToNotification = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnfilledAssignDevicesToNotification)
)

export { AssignDevicesToNotification }
