import { createAction } from 'redux-actions'
import { actions } from 'utils/http'
import * as aT from './actionTypes'
import * as utils from './reducer'

const getConfigurations = (groupId, limit, offset) => dispatch =>
  dispatch(actions.getConfigurations(groupId, limit, offset)).promise.then(({ payload }) => payload)

const getConfiguration = (groupId, configurationId) => dispatch =>
  dispatch(actions.getConfiguration(groupId, configurationId)).promise.then(({ payload }) => payload)

const updateConfiguration = (groupId, configurationId, configuration) => dispatch =>
  dispatch(actions.updateConfiguration(groupId, configurationId, configuration)).promise.then(({ payload }) => payload)

const createConfiguration = (groupId, configuration) => dispatch =>
  dispatch(actions.createConfiguration(groupId, configuration)).promise.then(({ payload }) => payload)

const deleteConfiguration = (groupId, configurationId) => dispatch =>
  dispatch(actions.deleteConfiguration(groupId, configurationId)).promise.then(({ payload }) => payload)

const changeLocalConfigurationField = (field, value) => dispatch =>
  dispatch(
    createAction(aT.CONFIG_FIELD_CHANGE, () => ({
      field,
      value
    }))()
  )

const addLocalConfigurationSignal = () => dispatch => dispatch(createAction(aT.CONFIG_ADD_SIGNAL)())

const copyLocalConfigurationSignal = signalId => dispatch => 
  dispatch(createAction(aT.CONFIG_COPY_SIGNAL, () => ({ signalId }))())

const addLocalConfigurationMFIO = signalId => dispatch =>
  dispatch(createAction(aT.CONFIG_ADD_MFIO, () => ({ signalId }))())

const addLocalConfigurationJ1939Signal = (signalId, j1939Parameters) => dispatch =>
  dispatch(createAction(aT.ADD_J1939_SIGNAL, () => ({ signalId, j1939Parameters }))())

const changeLocalConfigurationSignalField = (signalId, field, value) => dispatch =>
  dispatch(createAction(aT.CONFIG_SIGNAL_FIELD_CHANGE, () => ({ signalId, field, value }))())

const changeLocalConfigurationMFIOField = (signalId, field, value) => dispatch =>
  dispatch(createAction(aT.CONFIG_MFIO_FIELD_CHANGE, () => ({ signalId, field, value }))())

const changeLocalConfigurationSignalSourceAddress = (signalId, sourceAddress) => dispatch =>
  dispatch(createAction(aT.CONFIG_SIGNAL_SA_CHANGE, () => ({ signalId, sourceAddress }))())

const changeLocalConfigurationSignalId = (signalId, newSignalId) => dispatch =>
  dispatch(createAction(aT.CONFIG_SIGNAL_ID_CHANGE, () => ({ signalId, newSignalId }))())

const validateLocalConfigurationSignalId = signalId => dispatch =>
  dispatch(createAction(aT.CONFIG_SIGNAL_ID_VALIDATE, () => ({ signalId }))())

const changeLocalConfigurationSignalProtocolName = (signalId, selectedCanProtocolName) => dispatch =>
  dispatch(createAction(aT.CONFIG_SIGNAL_PROTOCOL_NAME_CHANGE, () => ({ signalId, selectedCanProtocolName }))())

const deleteLocalConfigurationSignal = signalId => dispatch =>
  dispatch(createAction(aT.CONFIG_DELETE_SIGNAL, () => ({ signalId }))())

const deleteLocalConfigurationMFIO = signalId => dispatch =>
  dispatch(createAction(aT.CONFIG_DELETE_MFIO, () => ({ signalId }))())

const localConfigurationError = errors => dispatch => dispatch(createAction(aT.CONFIG_ERROR, () => ({ errors }))())

const manageLocalConfigurationAlert = alertState => dispatch =>
  dispatch(createAction(aT.CONFIG_MANAGE_ALERT, () => alertState)())

const resetLocalConfiguration = () => dispatch => dispatch(createAction(aT.RESET_CONFIG)())

const setLocalConfiguration = state => dispatch => dispatch(createAction(aT.SET_CONFIG, () => state)())

const getCanProtocolList = groupId => dispatch => 
  dispatch(actions.getProtocols(groupId)).promise.then(({ payload }) => payload)

const getSelectedCanProtocol = (groupId, protocolName) => (dispatch, getState) => {
  const selectedCanProtocol = utils.getSelectedCanProtocolData(getState(), protocolName)
  if (selectedCanProtocol) {
    return selectedCanProtocol
  } else {
    dispatch(actions.getProtocol(groupId, protocolName))
  }
}

const deleteCanProtocol = (groupId, protocolName) => dispatch => 
  dispatch(actions.deleteProtocol(groupId, protocolName)).promise.then(({ payload }) => payload)

const uploadDbcFile = (groupId, dbcFile) => dispatch => 
  dispatch(actions.uploadDbcFile(groupId, dbcFile)).promise.then(({ payload }) => payload)

const makeSignalIdEditable = (signalId, isEditable) => dispatch => 
  dispatch(createAction(aT.EDITABLE_SIGNAL_ID, () => ({ signalId, isEditable }))())
  

export {
  getConfigurations,
  getConfiguration,
  updateConfiguration,
  createConfiguration,
  deleteConfiguration,
  changeLocalConfigurationField,
  addLocalConfigurationSignal,
  copyLocalConfigurationSignal,
  addLocalConfigurationMFIO,
  addLocalConfigurationJ1939Signal,
  changeLocalConfigurationSignalField,
  changeLocalConfigurationMFIOField,
  changeLocalConfigurationSignalSourceAddress,
  changeLocalConfigurationSignalProtocolName,
  deleteLocalConfigurationSignal,
  deleteLocalConfigurationMFIO,
  localConfigurationError,
  manageLocalConfigurationAlert,
  resetLocalConfiguration,
  setLocalConfiguration,
  getCanProtocolList,
  getSelectedCanProtocol,
  deleteCanProtocol,
  uploadDbcFile,
  makeSignalIdEditable,
  changeLocalConfigurationSignalId,
  validateLocalConfigurationSignalId,
}
