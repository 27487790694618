import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

import TextField from 'material-ui/TextField'

import Alert from 'components/Alert'
import { logError } from 'utils/http'

import GroupSelect from './GroupSelect'
import messages from './messages'
import { processGroupsHierarchyToSelect, getParentGroup } from '../utils'

class NewGroupForm extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      descriptionValue: '',
      description_errorText: '',
      hashIdValue: '',

      parent: {},
      parentHashIdValue: null,
      parentDescriptionValue: '',
      parentDescriptionValue_errorText: '',

      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],

      isSavingGroup: false
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    let element_textError = ''
    const value = event.target.value
    const name = event.target.name
    const property_value = name + 'Value'
    const property_errorText = name + '_errorText'
    const new_state = {}

    if (event.target.value === '') {
      element_textError = this.formatMessage(messages.thisFieldIsRequired)
    }

    new_state[property_value] = value
    new_state[property_errorText] = element_textError

    this.setState(new_state)
  }

  handleSelectChange = (event, index, value) => {
    const { groups } = this.props
    const newGroupHashId = value
    let parentGroup

    if (value === null) {
      // Parent not selected
      this.setState({
        parentHashIdValue: null,
        parentDescriptionValue: '',
        parentDescriptionValue_errorText: this.formatMessage(messages.selectAParentGroup)
      })
    } else {
      parentGroup = getParentGroup(groups, newGroupHashId)

      this.setState({
        parent: parentGroup,
        parentHashIdValue: parentGroup.hashId,
        parentDescriptionValue: parentGroup.description,
        parentDescriptionValue_errorText: ''
      })
    }
  }

  handleSave = () => {
    const { descriptionValue, parentHashIdValue, parent } = this.state

    this.setState({ isSavingGroup: true })

    let save = true
    let newGroup = {}
    const new_state = {}

    newGroup = {
      name: descriptionValue + Date.now(),
      description: descriptionValue
    }

    // Checking errors
    if (newGroup.description === '') {
      new_state['descriptionValue'] = ''
      new_state['description_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (parentHashIdValue === null) {
      new_state['parentDescriptionValue'] = null
      new_state['parentDescriptionValue_errorText'] = this.formatMessage(messages.selectParentGroup)
      save = false
    } else {
      newGroup['parent'] = {
        hashId: parent.hashId,
        description: parent.description,
        name: parent.name,
        version: parent.version
      }
    }

    if (save) {
      this.saveGroup(newGroup)
    } else {
      this.setState(new_state)
    }
  }

  saveGroup = newGroup => {
    const { newGroup: newGroupRequest, action, intl } = this.props
    newGroupRequest(newGroup.parent.hashId, newGroup.description)
      .then(() => {
        action()
      })
      .catch(response => {
        const error = response.error
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401: // Invalid credentials
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403: // Access denied
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 409: // Data integrity violation
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '409' }),
              alertMessagesText: [this.formatMessage(messages.error409Message)]
            })
            break
          case 415: // Unsupported media type
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '415' }),
              alertMessagesText: [this.formatMessage(messages.error415Message)]
            })
            break
          case 422: // Validation failed
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
              alertMessagesText: [this.formatMessage(messages.error422Message)]
            })
            break
          case 500: // Server errors
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
      .finally(() => {
        this.setState({ isSavingGroup: false })
      })
  }

  render() {
    const { groupsHierarchy, groupsUrl } = this.props
    const {
      alertMessagesType,
      alertMessagesText,
      alertMessagesTitle,
      alertMessages,
      description_errorText,
      descriptionValue,
      hashIdValue,
      parentDescriptionValue,
      parentDescriptionValue_errorText,
      parentHashIdValue,
      isSavingGroup
    } = this.state

    return (
      <div className='container-fluid' style={{ margin: '20px 0 0 0' }}>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />

        <Paper style={{ borderRadius: 0 }}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  errorText={description_errorText}
                  floatingLabelText={this.formatMessage(messages.name)}
                  fullWidth
                  name='description'
                  onChange={this.handleInputChange}
                  value={descriptionValue}
                />
              </div>
              <div className='col-md-4'>
                <GroupSelect
                  groupsHierarchy={processGroupsHierarchyToSelect(groupsHierarchy, hashIdValue)}
                  handleSelectChange={this.handleSelectChange}
                  parentDescriptionValue={parentDescriptionValue}
                  parentDescriptionValue_errorText={parentDescriptionValue_errorText}
                  parentGroup
                  parentHashIdValue={parentHashIdValue}
                  selectLabel={this.formatMessage(messages.selectParentGroup)}
                />
              </div>
            </div>

            <div>&nbsp;</div>
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <div style={{ padding: '20px 10px' }}>
                  <Link className='button-link' to={groupsUrl}>
                    <Button className='cancel-button' style={{ marginRight: 10 }}>
                      {this.formatMessage(messages.cancel)}
                    </Button>
                  </Link>
                  <Button className='primary-action-button' disabled={isSavingGroup} onClick={this.handleSave}>
                    {isSavingGroup ? <CircularProgress size={20} /> : this.formatMessage(messages.save)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

NewGroupForm.propTypes = {
  action: PropTypes.func.isRequired,
  groups: PropTypes.any.isRequired,
  groupsHierarchy: PropTypes.any.isRequired,
  groupsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  newGroup: PropTypes.func.isRequired
}

export default injectIntl(NewGroupForm)
