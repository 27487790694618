import { defineMessages } from 'react-intl'

const messages = defineMessages({
  lineWidget: {
    id: 'LineSettings.lineWidget',
    description: 'Line widget',
    defaultMessage: 'LINE WIDGET'
  },
  type: {
    id: 'LineSettings.type',
    description: 'Line widget: type',
    defaultMessage: 'Type'
  },
  width: {
    id: 'LineSettings.width',
    description: 'Line widget: border width',
    defaultMessage: 'Border width'
  },
  style: {
    id: 'LineSettings.style',
    description: 'Line widget: border style',
    defaultMessage: 'Border style'
  },
  color: {
    id: 'LineSettings.color',
    description: 'Line widget: border color',
    defaultMessage: 'Border color'
  },
  cancel: {
    id: 'LineSettings.cancel',
    description: 'Line widget settings cancellig button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'LineSettings.save',
    description: 'Line widget settigs saving button text',
    defaultMessage: 'Save'
  },
  lineWidgetSettings: {
    id: 'LineSettings.lineWidgetSettings',
    description: 'Line widget settigs dialog context text',
    defaultMessage: 'Line widget settigs:'
  },
  horizontal: {
    id: 'LineSettings.horizontal',
    description: 'Line widget: horizontal',
    defaultMessage: 'Horizontal'
  },
  vertical: {
    id: 'LineSettings.vertical',
    description: 'Line widget: vertical',
    defaultMessage: 'Vertical'
  },
  selectType: {
    id: 'LineSettings.selectType',
    description: 'Line widget: select type',
    defaultMessage: 'Select type'
  },
  thin: {
    id: 'LineSettings.thin',
    description: 'Line widget: border width',
    defaultMessage: 'Thin'
  },
  medium: {
    id: 'LineSettings.medium',
    description: 'Line widget: border medium',
    defaultMessage: 'Medium'
  },
  thick: {
    id: 'LineSettings.thick',
    description: 'Line widget: border thick',
    defaultMessage: 'Thick'
  },
  selectBorderWidth: {
    id: 'LineSettings.selectBorderWidth',
    description: 'Select border width',
    defaultMessage: 'Select border width'
  },
  solid: {
    id: 'LineSettings.solid',
    description: 'Line widget: border style solid',
    defaultMessage: 'Solid'
  },
  dotted: {
    id: 'LineSettings.dotted',
    description: 'Line widget: border style dotted',
    defaultMessage: 'Dotted'
  },
  dashed: {
    id: 'LineSettings.dashed',
    description: 'Line widget: border style dashed',
    defaultMessage: 'Dashed'
  },
  selectBorderStyle: {
    id: 'LineSettings.selectBorderStyle',
    description: 'Select border style',
    defaultMessage: 'Select border style'
  },
  default: {
    id: 'LineSettings.default',
    description: 'Line widget: border color default',
    defaultMessage: 'Default'
  },
  lightGrey: {
    id: 'LineSettings.lightGrey',
    description: 'Line widget: border color light grey',
    defaultMessage: 'Light grey'
  },
  grey: {
    id: 'LineSettings.grey',
    description: 'Line widget: border color grey',
    defaultMessage: 'Grey'
  },
  darkGrey: {
    id: 'LineSettings.darkGrey',
    description: 'Line widget: border color dark grey',
    defaultMessage: 'Dark grey'
  },
  selectBorderColor: {
    id: 'LineSettings.selectBorderColor',
    description: 'Select border color',
    defaultMessage: 'Select border color'
  }
})

export default messages
