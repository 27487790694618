import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import QueryBuilder from 'react-querybuilder'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'

import PageSubTitle from 'components/PageSubTitle'
import * as c from '../../constants'
import NotificationsRulesSettingsStepper from '../../NotificationsRulesSettingsStepper'
import {
  getAvailableCanBusSignals,
  getAvailableGpsSignals,
  getAvailableDm1Signals,
  getQueryBuilderSignalOptions,
  getAvailableOperators,
  getQueryAsRsqlExpression
} from '../../utils'
import { getEditNotificationAssignUsersUrl } from '../../url'
import messages from '../messages'
import ErrorAlert from '../ErrorAlert'

import { client, logError } from 'utils/http'

class ConditionalSignalsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      isAllDm1MessagesCheckboxChecked: false,
      isErrorAlertShown: false,
      isSavingLoading: false,
      originalQuery: {},
      queryAsRsqlExpression: '',
      selectedRuleTypeOption: ''
    }

    const {
      intl: { formatMessage }
    } = this.props
    this.formatMessage = formatMessage

    this.ruleTypeOptions = {
      CAN_BUS: 'CAN_BUS',
      GPS: 'GPS',
      DM1: 'DM1'
    }
  }

  fetchRuleDetails = () => {
    const { availableRules } = this.props

    const ruleType = this.getRuleType()

    const ruleName = c.RULE_TYPE_NAMES[ruleType]
    const { hashId: ruleHashId } = availableRules.find(rule => rule.name === ruleName) || {}

    return client
      .getRule(ruleHashId)
      .then(response => {
        const ruleDetail = response.data
        return ruleDetail
      })
      .catch(error => {
        logError(error)
        this.setState({ error, isErrorAlertShown: true })
      })
  }

  getRuleType = () => {
    const { isAllDm1MessagesCheckboxChecked, selectedRuleTypeOption } = this.state

    if (selectedRuleTypeOption === this.ruleTypeOptions.CAN_BUS) {
      return c.RULE_TYPES.CAN_BUS
    } else if (selectedRuleTypeOption === this.ruleTypeOptions.GPS) {
      return c.RULE_TYPES.GPS
    } else if (selectedRuleTypeOption === this.ruleTypeOptions.DM1 && isAllDm1MessagesCheckboxChecked) {
      return c.RULE_TYPES.DM1_ALL_MESSAGES
    } else if (selectedRuleTypeOption === this.ruleTypeOptions.DM1 && !isAllDm1MessagesCheckboxChecked) {
      return c.RULE_TYPES.DM1_SPECIFIC_MESSAGES
    }
  }

  getAvailableCanBusSignals = () => {
    const { assignedDevices } = this.props

    const configurationFile = assignedDevices?.[0]?.deviceConfiguration
    const valueTypeTexts = {
      avg: this.formatMessage(messages.valueAvg),
      min: this.formatMessage(messages.valueMin),
      max: this.formatMessage(messages.valueMax)
    }

    const availableCanBusSignals = getAvailableCanBusSignals(configurationFile, valueTypeTexts)

    return availableCanBusSignals
  }

  getAvailableGpsSignals = () => {
    const { assignedDevices } = this.props

    const configurationFile = assignedDevices?.[0]?.deviceConfiguration
    const availableGpsSignals = getAvailableGpsSignals(configurationFile)

    return availableGpsSignals
  }

  getAvailableDm1Signals = () => {
    const { assignedDevices } = this.props

    const configurationFile = assignedDevices?.[0]?.deviceConfiguration
    const availableDm1Signals = getAvailableDm1Signals(configurationFile)

    return availableDm1Signals
  }

  handleRuleTypeChange = event => {
    const { isAllDm1MessagesCheckboxChecked } = this.state
    const value = event.target.value

    this.setState({
      selectedRuleTypeOption: value,
      isAllDm1MessagesCheckboxChecked: value === this.ruleTypeOptions.DM1 ? isAllDm1MessagesCheckboxChecked : false
    })
  }

  handleAllDm1MessagesCheckboxChange = (event, value) => {
    this.setState({ isAllDm1MessagesCheckboxChecked: value })
  }

  handleQueryChange = (ruleType, query) => {
    let availableSignals = []
    switch (ruleType) {
      case c.RULE_TYPES.CAN_BUS:
        availableSignals = this.getAvailableCanBusSignals()
        break
      case c.RULE_TYPES.GPS:
        availableSignals = this.getAvailableGpsSignals()
        break
      case c.RULE_TYPES.DM1_SPECIFIC_MESSAGES:
        availableSignals = this.getAvailableDm1Signals()
        break

      default:
        break
    }

    const rsqlExpression = getQueryAsRsqlExpression(query, availableSignals)

    this.setState({
      originalQuery: query,
      queryAsRsqlExpression: rsqlExpression
    })
  }

  handleSaveClick = () => {
    const { history, newNotification } = this.props
    const { originalQuery, queryAsRsqlExpression } = this.state

    const ruleType = this.getRuleType()

    if (ruleType !== c.RULE_TYPES.DM1_ALL_MESSAGES && queryAsRsqlExpression === '') {
      this.setState({ isErrorAlertShown: true })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        isErrorAlertShown: false,
        error: {},
        isSavingLoading: true
      })

      this.fetchRuleDetails()
        .then(ruleDetails => {
          const { hashId: ruleHashId } = ruleDetails
          const { hashId: originalQueryVariableHashId } =
            ruleDetails.variables.find(variable => variable.name === c.RULE_VARIABLE_ORIGINAL_CONDITION_NAME) || {}
          const { hashId: rsqlExpressionVariableHashId } =
            ruleDetails.variables.find(variable => variable.name === c.RULE_VARIABLE_CONDITION_NAME) || {}
          const variablesForNotification =
            ruleType === c.RULE_TYPES.DM1_ALL_MESSAGES
              ? []
              : [
                {
                  ruleVariableHashId: rsqlExpressionVariableHashId,
                  value: queryAsRsqlExpression
                },
                {
                  ruleVariableHashId: originalQueryVariableHashId,
                  value: JSON.stringify(originalQuery)
                }
              ]

          const newNotificationForDatabase = {
            ...newNotification,
            ruleHashId,
            variables: variablesForNotification
          }

          return newNotificationForDatabase
        })
        .then(newNotificationForDatabase => {
          client
            .newRuleInstance(newNotificationForDatabase)
            .then(response => {
              if (response?.data?.hashId) {
                const newNotificationHashId = response.data.hashId
                const textToSend = 'notificationCreated'

                history.push(getEditNotificationAssignUsersUrl(newNotificationHashId), textToSend)
              }
            })
            .catch(error => {
              logError(error)
              this.setState({ error, isErrorAlertShown: true })
            })
        })
        .finally(() => {
          this.setState({ isSavingLoading: false })
        })
    }
  }

  handleErrorAlertClose = () => {
    this.setState({ error: {}, isErrorAlertShown: false })
  }

  render() {
    const { notificationsUrl } = this.props
    const {
      error,
      isAllDm1MessagesCheckboxChecked,
      isErrorAlertShown,
      isSavingLoading,
      queryAsRsqlExpression,
      selectedRuleTypeOption
    } = this.state

    const availableCanBusSignals = this.getAvailableCanBusSignals()
    const availableGpsSignals = this.getAvailableGpsSignals()
    const availableDm1Signals = this.getAvailableDm1Signals()

    return (
      <div className='container-fluid'>
        <NotificationsRulesSettingsStepper stepIndex={1} />
        <PageSubTitle title={this.formatMessage(messages.conditionalSignals)} />
        <ErrorAlert
          error={error}
          isQueryRsqlExpressionEmpty={queryAsRsqlExpression === ''}
          onClose={this.handleErrorAlertClose}
          show={isErrorAlertShown}
        />

        <Paper style={{ padding: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl style={{ width: '250px' }}>
                <InputLabel>Rule type</InputLabel>
                <Select onChange={this.handleRuleTypeChange} value={selectedRuleTypeOption}>
                  <MenuItem
                    key={this.ruleTypeOptions.CAN_BUS}
                    disabled={availableCanBusSignals.length === 0}
                    value={this.ruleTypeOptions.CAN_BUS}
                  >
                    CAN Bus
                  </MenuItem>
                  <MenuItem
                    key={this.ruleTypeOptions.GPS}
                    disabled={availableGpsSignals.length === 0}
                    value={this.ruleTypeOptions.GPS}
                  >
                    GPS
                  </MenuItem>
                  <MenuItem
                    key={this.ruleTypeOptions.DM1}
                    disabled={availableDm1Signals.length === 0}
                    value={this.ruleTypeOptions.DM1}
                  >
                    DM1
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {selectedRuleTypeOption === this.ruleTypeOptions.CAN_BUS && (
                <QueryBuilder
                  fields={getQueryBuilderSignalOptions(availableCanBusSignals)}
                  onQueryChange={query => this.handleQueryChange(c.RULE_TYPES.CAN_BUS, query)}
                  operators={getAvailableOperators(c.RULE_TYPES.CAN_BUS)}
                />
              )}
              {selectedRuleTypeOption === this.ruleTypeOptions.GPS && (
                <QueryBuilder
                  fields={getQueryBuilderSignalOptions(availableGpsSignals)}
                  onQueryChange={query => this.handleQueryChange(c.RULE_TYPES.GPS, query)}
                  operators={getAvailableOperators(c.RULE_TYPES.GPS)}
                />
              )}
              {selectedRuleTypeOption === this.ruleTypeOptions.DM1 && (
                <React.Fragment>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAllDm1MessagesCheckboxChecked}
                        color='primary'
                        name='allDm1Messages'
                        onChange={this.handleAllDm1MessagesCheckboxChange}
                      />
                    }
                    label='Any DM1 message'
                  />
                  <div
                    style={{
                      opacity: isAllDm1MessagesCheckboxChecked ? 0.3 : 1,
                      pointerEvents: isAllDm1MessagesCheckboxChecked ? 'none' : 'auto'
                    }}
                  >
                    <QueryBuilder
                      fields={getQueryBuilderSignalOptions(availableDm1Signals)}
                      onQueryChange={query => this.handleQueryChange(c.RULE_TYPES.DM1_SPECIFIC_MESSAGES, query)}
                      operators={getAvailableOperators(c.RULE_TYPES.DM1_SPECIFIC_MESSAGES)}
                    />
                  </div>
                </React.Fragment>
              )}
            </Grid>
            <Grid container item justify='flex-end' xs={12}>
              <Link className='button-link' to={notificationsUrl}>
                <Button className='cancel-button' style={{ marginRight: 10 }}>
                  {this.formatMessage(messages.cancel)}
                </Button>
              </Link>
              <Button className='primary-action-button' disabled={isSavingLoading} onClick={this.handleSaveClick}>
                {this.formatMessage(messages.save)}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

ConditionalSignalsForm.propTypes = {
  assignedDevices: PropTypes.array.isRequired,
  availableRules: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  newNotification: PropTypes.object.isRequired,
  notificationsUrl: PropTypes.string.isRequired
}

export default injectIntl(ConditionalSignalsForm)
