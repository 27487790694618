import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'Roles.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'Roles.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'Roles.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'Roles.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedMessage: {
    id: 'Roles.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: 'Roles.title',
    description: 'Roles title',
    defaultMessage: 'Roles management'
  },
  addRole: {
    id: 'Roles.addRole',
    description: 'Add role button label',
    defaultMessage: 'Add role'
  },
  newRole: {
    id: 'Roles.newRole',
    description: 'New role button label',
    defaultMessage: 'New role'
  },
  roleUpdatedSuccessfully: {
    id: 'Roles.roleUpdatedSuccessfully',
    description: 'Role updated successfully text',
    defaultMessage: 'Role updated successfully.'
  },
  roleCreatedSuccessfully: {
    id: 'Roles.roleCreatedSuccessfully',
    description: 'Role created successfully text',
    defaultMessage: 'Role created successfully.'
  },
  roleDeletedSuccessfully: {
    id: 'Roles.roleDeletedSuccessfully',
    description: 'Role deleted successfully text',
    defaultMessage: 'Role deleted successfully.'
  },
  youCantDeleteYourOwnRole: {
    id: 'Roles.youCantDeleteYourOwnRole',
    description: 'You can\'t delete your own role text',
    defaultMessage: 'You can\'t delete your own role.'
  },
  errorDeletingRole: {
    id: 'Roles.errorDeletingRole',
    description: 'Error deleting role text',
    defaultMessage: 'Error deleting role'
  },
  cantDeleteRoleWithChildrenNodes: {
    id: 'Roles.cantDeleteRoleWithChildrenNodes',
    description: 'Can\'t delete role with children roles text',
    defaultMessage: 'Can\'t delete role with children roles.'
  },
  cantDeleteRoleAsignedToUsers: {
    id: 'Roles.cantDeleteRoleAsignedToUsers',
    description: 'Can\'t delete role asigned to users text',
    defaultMessage: 'Can\'t delete role asigned to users'
  },
  confirmRoleToDelete: {
    id: 'Roles.confirmRoleToDelete',
    description: 'Confirm role to delete text',
    defaultMessage: 'Confirm role to delete'
  },
  error412Message: {
    id: 'Roles.error412Message',
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error428Message: {
    id: 'Roles.error428Message',
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  errorUndefinedTitle: {
    id: 'Roles.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  cancel: {
    id: 'Roles.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'Roles.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  error: {
    id: 'Roles.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'Roles.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  }
})

export default messages
