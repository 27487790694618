import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import ReportActions from '../ReportActions'
import { options } from '../NewReport/config'

import messages from './messages'

import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'
import { logError } from 'utils/http'

const styles = {
  dialog: {
    minWidth: 800
  }
}

class ReportsTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      start: 0,
      reports: [],
      count: 0,
      selectedReport: '',
      isDeleteReportLoading: false,
      dialogOpen: false,
      tableOptions: {
        noDataText: this.formatMessage(messages.noReports),
        sizePerPage: 10,
        page: 1
      }
    }
  }

  componentDidMount() {
    this.getReports()
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props
    if (prevProps.groupId !== groupId && groupId) {
      this.setState(state => {
        return {
          start: 0,
          tableOptions: {
            ...state.tableOptions,
            page: 1
          }
        }
      }, this.getReports)
    }
  }

  getReports() {
    const { fetchReports, groupId, setAlert } = this.props

    const {
      start,
      tableOptions: { sizePerPage }
    } = this.state

    this.setState(state => ({
      reports: [],
      count: 0,
      tableOptions: {
        ...state.tableOptions,
        noDataText: <CircularProgress />
      }
    }))

    let alertMessages = false
    let alertMessagesType = 'danger'
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    fetchReports(groupId, sizePerPage, start)
      .then(({ payload }) => {
        this.setState(state => ({
          reports: payload.data.schedules,
          count: payload.data.total,
          tableOptions: {
            ...state.tableOptions,
            noDataText: payload.data.total === 0 ? this.formatMessage(messages.noReports) : <CircularProgress />
          }
        }))
      })
      .catch(({ error }) => {
        const { response } = { ...error }
        switch (response.status) {
          case 400:
            ;alertMessages = true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '400'
            }),
            alertMessagesText = [this.formatMessage(messages.error400Message)]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            break
          case 401:
            ;alertMessages = true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '401'
            }),
            alertMessagesText = [response.message]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            break
          case 403:
            ;alertMessages = true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '403'
            }),
            alertMessagesText = [this.formatMessage(messages.error403Message)]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            break
          case 404:
            ;alertMessages = true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '404'
            }),
            alertMessagesText = [this.formatMessage(messages.error404Message)]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            break
          case 406:
            ;alertMessages = true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '406'
            }),
            alertMessagesText = [this.formatMessage(messages.error406Message)]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            break
          case 500:
            ;alertMessages = response.data.includes(
              'V2 Schedule Service : NO SCHEDULE RESOURCE IS SHARED WITH THIS USER'
            )
              ? false
              : true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '500'
            }),
            alertMessagesText = [response.data]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            break
          default:
            ;alertMessages = true,
            alertMessagesType = 'danger',
            alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText = [this.formatMessage(messages.errorUndefinedMessage)]
            this.setState(state => ({
              reports: [],
              count: 0,
              tableOptions: {
                ...state.tableOptions,
                noDataText: this.formatMessage(messages.noReports)
              }
            }))
            logError(error)
        }
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
      })
  }

  handleDeleteClick = () => {
    const { deleteReport, deleteReportAWS, intl, setAlert, groupId } = this.props
    const { selectedReport } = this.state

    let alertMessages = false
    let alertMessagesType = 'danger'
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    this.setState(state => ({
      isDeleteReportLoading: true,
      reports: [],
      count: 0,
      start: 0,
      tableOptions: {
        ...state.tableOptions,
        page: 1
      }
    }))
    deleteReport(selectedReport.id, groupId)
      .then(() => {
        deleteReportAWS(selectedReport.id, groupId)
          .then(() => {
            alertMessages = true
            alertMessagesType = 'success'
            alertMessagesTitle = ''
            alertMessagesText = [this.formatMessage(messages.reportDeletedSuccessfully)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteText: '',
              dialogSelectedReportToDeleteTitle: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
            this.getReports()
          })
          .catch(response => {
            const error = response.error
            switch (error.response.status) {
              case 400:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '400'
                })
                alertMessagesText = [this.formatMessage(messages.error400Message)]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              case 401:
                let message
                if (intl.locale === 'en') message = error.response.message
                else message = this.formatMessage(messages.error401Message)
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '401'
                })
                alertMessagesText = [message]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              case 403:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '403'
                })
                alertMessagesText = [this.formatMessage(messages.error403Message)]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              case 404:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '404'
                })
                alertMessagesText = [this.formatMessage(messages.error404Message)]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              case 412:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '412'
                })
                alertMessagesText = [this.formatMessage(messages.error412Message)]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              case 428:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '428'
                })
                alertMessagesText = [this.formatMessage(messages.error428Message)]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              case 500:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.error, {
                  number: '500'
                })
                alertMessagesText = [error.response.data.error]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
                break
              default:
                alertMessages = true
                alertMessagesType = 'danger'
                alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                alertMessagesText = [this.formatMessage(messages.errorUndefinedMessage)]
                this.setState({
                  dialogOpen: false,
                  dialogSelectedReportToDeleteTitle: '',
                  dialogSelectedReportToDeleteText: '',
                  isDeleteReportLoading: false,
                  selectedReport: ''
                })
            }
            setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
            this.getReports()
          })
      })
      .catch(response => {
        const error = response.error
        switch (error.response.status) {
          case 400:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '400'
            })
            alertMessagesText = [this.formatMessage(messages.error400Message)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          case 401:
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '401'
            })
            alertMessagesText = [message]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          case 403:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '403'
            })
            alertMessagesText = [this.formatMessage(messages.error403Message)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          case 404:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '404'
            })
            alertMessagesText = [this.formatMessage(messages.error404Message)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          case 412:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '412'
            })
            alertMessagesText = [this.formatMessage(messages.error412Message)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          case 428:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '428'
            })
            alertMessagesText = [this.formatMessage(messages.error428Message)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          case 500:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.error, {
              number: '500'
            })
            alertMessagesText = [error.response.data]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            break
          default:
            alertMessages = true
            alertMessagesType = 'danger'
            alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
            alertMessagesText = [this.formatMessage(messages.errorUndefinedMessage)]
            this.setState({
              dialogOpen: false,
              dialogSelectedReportToDeleteTitle: '',
              dialogSelectedReportToDeleteText: '',
              isDeleteReportLoading: false,
              selectedReport: ''
            })
            logError(response)
        }
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
        this.getReports()
      })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(state => {
      return {
        start: (page - 1) * sizePerPage,
        tableOptions: {
          ...state.tableOptions,
          page,
          sizePerPage
        }
      }
    }, this.getReports)
  }

  onSizePerPageList = sizePerPage => {
    this.setState(state => {
      return {
        tableOptions: {
          ...state.tableOptions,
          sizePerPage
        }
      }
    }, this.getReports)
  }

  handleCloseDialog = () => {
    this.setState({
      dialogOpen: false
    })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) => {
    return (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )
  }

  openDeleteReportDialog = report => {
    const reportText = report.name + ' (' + report.id + ')'

    this.setState({
      dialogOpen: true,
      dialogSelectedReportToDeleteTitle: this.formatMessage(messages.confirmReportToDelete),
      dialogSelectedReportToDeleteText: reportText,
      selectedReport: report
    })
  }

  formatInitialDate = date => {
    return utcTimeToBrowserLocalShort(date)
  }

  formatActionsDetail = (cell, row) => {
    return <ReportActions openDeleteReportDialog={this.openDeleteReportDialog} report={row} />
  }

  formatFrequencyData = cell => {
    const frequency = R.indexBy(R.prop('value'), options.frequency())
    return R.pathOr('---', [cell, 'label'], frequency)
  }

  getTableOptions = () => {
    const { tableOptions } = this.state
    const completeTableOptions = {
      noDataText: this.formatMessage(messages.noReports),
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: this.onPageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      ...tableOptions
    }

    return completeTableOptions
  }

  render() {
    const { classes } = this.props
    const {
      count,
      dialogOpen,
      dialogSelectedReportToDeleteText,
      dialogSelectedReportToDeleteTitle,
      isDeleteReportLoading,
      reports
    } = this.state
    const tableOptions = this.getTableOptions()
    return (
      <div>
        <Paper style={{ borderRadius: 0 }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              data={reports}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              options={tableOptions}
              pagination
              remote={remoteObj => ({
                ...remoteObj,
                search: false,
                pagination: true,
                sizePerPage: true,
                filter: false
              })}
            >
              <TableHeaderColumn
                dataField='id'
                hidden
                isKey
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='0'
              >
                {this.formatMessage(messages.locationID)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='name'
                dataSort
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='150'
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='initialDate'
                dataFormat={this.formatInitialDate}
                dataSort
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='200'
              >
                {this.formatMessage(messages.initialDate)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='timezone'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='180'
              >
                {this.formatMessage(messages.timezone)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='frequency'
                dataFormat={this.formatFrequencyData}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='100'
              >
                {this.formatMessage(messages.frequency)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='outputType'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='100'
              >
                {this.formatMessage(messages.outputType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='center'
                dataField='id'
                dataFormat={this.formatActionsDetail}
                headerAlign='center'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='100'
              >
                {this.formatMessage(messages.actions)}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>

        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleCloseDialog} open={dialogOpen}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleCloseDialog}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
              {dialogSelectedReportToDeleteTitle}
            </DialogTitle>
            <DialogContentText style={{ padding: '0 12px 12px 12px' }}>
              {dialogSelectedReportToDeleteText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              key='delete-button'
              className='delete-button'
              disabled={isDeleteReportLoading}
              onClick={this.handleDeleteClick}
              style={{ marginLeft: 5 }}
            >
              {isDeleteReportLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              {this.formatMessage(messages.delete)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ReportsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteReport: PropTypes.func.isRequired,
  deleteReportAWS: PropTypes.func.isRequired,
  fetchReports: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(ReportsTable))
