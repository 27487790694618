import { defineMessages } from 'react-intl'

const messages = defineMessages({
  startTitle: {
    id: 'ProductionTestMode.startTitle',
    description: 'Title of production test mode start dialog',
    defaultMessage: 'Start Production Test Mode'
  },
  startParagraphOne: {
    id: 'ProductionTestMode.startParagraphOne',
    description: 'Paragraph one',
    defaultMessage: 'When using Production Test Mode, you have 500kB of data to test out PLUS+1 Connect Services.'
  },
  startParagraphTwo: {
    id: 'ProductionTestMode.startParagraphTwo',
    description: 'Paragraph two',
    defaultMessage: 'The Test Mode will be active for 7 days.'
  },
  startParagraphThree: {
    id: 'ProductionTestMode.startParagraphThree',
    description: 'Paragraph three',
    defaultMessage:
      'Once the data limit has been exceeded, or the 7 days have passed, you will need to assign a ' +
      'Digital Service Plan to your device to continue using the services.'
  },
  moreInformation: {
    id: 'ProductionTestMode.moreInformation',
    description: 'More information link text',
    defaultMessage: 'More information'
  },
  cancel: {
    id: 'ProductionTestMode.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  start: {
    id: 'ProductionTestMode.start',
    description: 'Start button label',
    defaultMessage: 'Start'
  },
  endTitle: {
    id: 'ProductionTestMode.endTitle',
    description: 'Title of production test mode end dialog',
    defaultMessage: 'End Production Test Mode'
  },
  endParagraphOne: {
    id: 'ProductionTestMode.endParagraphOne',
    description: 'Paragraph one',
    defaultMessage: 'Do you want to end the Production Test Mode?'
  },
  endParagraphTwo: {
    id: 'ProductionTestMode.endParagraphTwo',
    description: 'Paragraph two',
    defaultMessage:
      'If you end the Production Test Mode, the SIM of the {name} device will transition into Suspend state. '
  },
  endParagraphThree: {
    id: 'ProductionTestMode.endParagraphThree',
    description: 'Paragraph three',
    defaultMessage: 'To activate the SIM, you will need to buy a Digital Service Plan.'
  },
  end: {
    id: 'ProductionTestMode.end',
    description: 'End button label',
    defaultMessage: 'End'
  },
  infoTitle: {
    id: 'ProductionTestMode.infoTitle',
    description: 'Title of production test mode information dialog',
    defaultMessage: 'Production Test Mode'
  },
  infoParagraphOne: {
    id: 'ProductionTestMode.infoParagraphOne',
    description: 'Paragraph one',
    defaultMessage:
      'For customers using the product as an integrated part of their production processes, it is important ' +
      'to be aware of the possible states of the CS100 SIM card. The CS100 SIM card may be transitioned into ' +
      'following states.'
  },
  inactive: {
    id: 'ProductionTestMode.inactive',
    description: 'Inactive state',
    defaultMessage: 'Inactive'
  },
  inactiveParagraph: {
    id: 'ProductionTestMode.inactiveParagraph',
    description: 'Inactive state information paragraph',
    defaultMessage:
      '{state} The initial SIM card state will always be inactive. When the SIM is in this state, the product ' +
      'will not attach to any network and data transmission is blocked. No billing is incurred at this point. ' +
      'Once the SIM has moved out of Inactive, the CS100 can never return to this state again.'
  },
  productionTestMode: {
    id: 'ProductionTestMode.productionTestMode',
    description: 'Production Test Mode state',
    defaultMessage: 'Production Test Mode'
  },
  productionTestModeParagraph: {
    id: 'ProductionTestMode.productionTestModeParagraph',
    description: 'Production Test Mode state information paragraph',
    defaultMessage:
      '{state} This SIM state is optional and is mainly suggested for customers wishing to integrate CS100 and ' +
      'PLUS+1 Connect into production and test processes. The state is entered by using the “Actions” drop down ' +
      'under “Device Management” to access the {productionTestMode} option. {productionTestMode} is applied prior ' +
      'to the activation of the Digital Service Plan. 500 kB of data is available in this state where the customer ' +
      'can utilize this limited amount of data for test processes.'
  },
  postProductionTestModeParagraphOne: {
    id: 'ProductionTestMode.postProductionTestModeParagraphOne',
    description: 'Post Production Test Mode state information paragraph one',
    defaultMessage:
      'Post {productionTestMode}, the succeeding state will always be {suspend}. There are 3 possible ways which can ' +
      'trigger the SIM to move into {suspend}.'
  },
  postProductionTestModeParagraphTwo: {
    id: 'ProductionTestMode.postProductionTestModeParagraphTwo',
    description: 'Post Production Test Mode state information paragraph two',
    defaultMessage:
      '1. When the SIM has been moved into {productionTestMode}, the SIM will automatically transition into {suspend} ' +
      'after 7 days'
  },
  postProductionTestModeParagraphThree: {
    id: 'ProductionTestMode.postProductionTestModeParagraphThree',
    description: 'Post Production Test Mode state information paragraph three',
    defaultMessage:
      '2. When usage of the 500 kB has been exceeded, the SIM will automatically transition into {suspend}.'
  },
  postProductionTestModeParagraphFour: {
    id: 'ProductionTestMode.postProductionTestModeParagraphFour',
    description: 'Post Production Test Mode state information paragraph four',
    defaultMessage:
      '3. If the customer wishes to end {productionTestMode}, the customer might choose to manually transition the SIM into ' +
      '{suspend} by using the option “End Production Test Mode” from the “Actions” drop down under “Device Management”.'
  },
  suspend: {
    id: 'ProductionTestMode.suspend',
    description: 'Suspend state',
    defaultMessage: 'Suspend'
  },
  suspendParagraph: {
    id: 'ProductionTestMode.suspendParagraph',
    description: 'Suspend state information paragraph',
    defaultMessage:
      '{state} This SIM state is optional and is mainly suggested for customers wishing to integrate CS100 and PLUS+1 Connect ' +
      'into production and test processes. The state functions as a "standby" state to be used post {productionTestMode}. At ' +
      'this point in time the CS100 has been tested and verified with the customer application and is in a waiting position to ' +
      'reach the end user. During {suspend}, no billing of the Digital Service Plan is incurred, and the state thereby serves as ' +
      'a convenient solution in cases where significant lead time exist between the Danfoss customer and the end user of the machine.'
  },
  active: {
    id: 'ProductionTestMode.active',
    description: 'Active state',
    defaultMessage: 'Active'
  },
  activeParagraph: {
    id: 'ProductionTestMode.activeParagraph',
    description: 'Active state information paragraph',
    defaultMessage:
      '{state} This is the operational state of the SIM. The state is accessed by activating the Digital Service Plan. By entering ' +
      '{active} state, the billing period starts.'
  },
  back: {
    id: 'ProductionTestMode.back',
    description: 'Back button label',
    defaultMessage: 'Back'
  },
  simStatusGraph: {
    id: 'ProductionTestMode.simStatusGraph',
    description: 'SIM Status graph',
    defaultMessage: 'SIM Status graph'
  },
  error: {
    id: 'ProductionTestMode.error',
    description: 'Error text',
    defaultMessage: 'Error {number}'
  },
  error400Message: {
    id: 'ProductionTestMode.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error404Message: {
    id: 'ProductionTestMode.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'Device Not Found'
  },
  error500Message: {
    id: 'ProductionTestMode.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Internal Server Error'
  },
  error510Message: {
    id: 'ProductionTestMode.error510Message',
    description: 'Error 510 message',
    defaultMessage: 'Vodafone Server Error'
  },
  errorUndefinedTitle: {
    id: 'ProductionTestMode.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'ProductionTestMode.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  productionTestModeEndedTitle: {
    id: 'ProductionTestMode.productionTestModeEndedTitle',
    description: 'Production Test Mode ended dialog title',
    defaultMessage: 'Production Test Mode ended'
  },
  dataDepletedParagraph: {
    id: 'ProductionTestMode.dataDepletedParagraph',
    description: 'Production Test Mode data depleted paragraph',
    defaultMessage:
      'The 500kB of test data has been depleted. The device {name} will transition from Test Mode to Suspend.'
  },
  daysFinishedParagraph: {
    id: 'ProductionTestMode.daysFinishedParagraph',
    description: 'Production Test Mode days finished paragraph',
    defaultMessage:
      'The 7 days of Production Test Mode have been finished. The device {name} will transition from Test Mode to Suspend.'
  },
  purchaseParagraph: {
    id: 'ProductionTestMode.purchaseParagraph',
    description: 'Production Test Mode finished purchase paragraph',
    defaultMessage: 'Please, purchase and active a Digital Service Plan to continue.'
  },
  agree: {
    id: 'ProductionTestMode.agree',
    description: 'Agree button label',
    defaultMessage: 'Agree'
  }
})

export default messages
