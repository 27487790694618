export const STEPS = {
  CONSENT_REQUEST: 'CONSENT_REQUEST',
  CONSENT_ACCEPTED: 'CONSENT_ACCEPTED'
}

export const CONSENT_STATES = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING'
}
