const BoxTemplate = {
  id: '',
  width: 200,
  height: 200,
  x: 20,
  y: 20,
  minHeight: undefined,
  minWidth: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'box',
    params: {
      width: 'thin',
      style: 'solid',
      color: '#000000',
      backgroundRed: 255,
      backgroundGreen: 255,
      backgroundBlue: 255,
      backgroundAlpha: 0,
      radius: 0,
      data: '',
      valueType: '',
      value: {
        timestamp: '',
        value: ''
      },
      conditionalParams: {
        operator: '',
        value: '',
        width: 'thin',
        style: 'solid',
        color: '#000000',
        backgroundRed: 255,
        backgroundGreen: 255,
        backgroundBlue: 255,
        backgroundAlpha: 0,
        radius: 0
      }
    }
  }
}

export default BoxTemplate
