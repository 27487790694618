import React from 'react'

import moment from 'moment'
import { Polyline } from 'react-google-maps'

import blue from '@material-ui/core/colors/blue'

import { Map, Marker } from './wrapper'
import { LocationTrackerProps } from '../props'
import { injectIntl } from 'react-intl'

const LocationTracker = injectIntl(({ positions = [], highlighted = -1, intl, ...other }) => {
  if (positions.length === 0) {
    return null
  }

  let highlightedPos = undefined
  if (highlighted >= 0 && highlighted < positions.length) {
    highlightedPos = positions[highlighted]
  }

  // FIXME defaultCenter does not get refreshed after the first render here
  return (
    <Map
      defaultCenter={highlightedPos || positions[0]}
      defaultZoom={1.5}
      {...other}
    >
      <Polyline
        path={positions.map(({ latitude, longitude }) => ({
          lng: longitude,
          lat: latitude
        }))}
        strokeColor="#f00"
      />
      {positions
        .map((position, i) => {
          const remarked = highlighted === i
          return (
            <Marker
              key={position.timestamp}
              position={position}
              label={moment(position.timestamp).format()}
              zIndex={remarked ? 1000 : 100}
              color={remarked ? blue[500] : 'black'}
              size={remarked ? 30 : 14}
            />
          )
        })
        .reverse() // Latest comes first
      }
    </Map>
  )
})

LocationTracker.propTypes = LocationTrackerProps

export default LocationTracker
