import { createSelector } from 'reselect'
import { getSelector } from '../reducer'

import { getGroupCriticality } from 'utils/notificationCriticality'

// Needed to be isolated while "node" is not in duck form
import { _getDescendants } from './utils'

const getParentsByGroupId = createSelector(getSelector, groupsById =>
  Object.keys(groupsById).reduce((ret, k) => {
    const { children = [] } = groupsById[k]
    children.forEach(c => ret[c] = k) // eslint-disable-line no-param-reassign
    return ret
  }, {})
)

// Given several group Ids => { child1: child1Descendants, ..., child2: child2Descendants }
const getAllDescendantsByParentGroupId = createSelector(getSelector, groupsById =>
  Object.keys(groupsById).reduce((ret, k) => {
    const { children = [] } = groupsById[k]
    ret[k] = _getDescendants(children, groupsById) // eslint-disable-line no-param-reassign
    return ret
  }, {})
)

const belongsToGroup = (n, groupOrGroups) => {
  if (n.assignedTo && groupOrGroups) {
    const nodeIsAssignedTo = g => n.assignedTo.includes(g)
    return Array.isArray(groupOrGroups) ? groupOrGroups.some(nodeIsAssignedTo) : nodeIsAssignedTo(groupOrGroups)
  }
  return false
}

// Group notifications are inferred here
const getGroupsById = createSelector(getSelector, groupsById =>
  Object.keys(groupsById).reduce((ret, gId) => {
    const groupNotifications = groupsById[gId].notifications
    // eslint-disable-next-line no-param-reassign
    ret[gId] = {
      ...groupsById[gId],
      criticality: getGroupCriticality(groupNotifications)
    }

    return ret
  }, {})
)

export {
  getParentsByGroupId,
  getAllDescendantsByParentGroupId,
  belongsToGroup, // Not rewally a selector but an utility function
  getGroupsById
}
