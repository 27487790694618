import { defineMessages } from 'react-intl'

const messages = defineMessages({
  errorSavingDashboard: {
    id: 'ErrorSavingDashboard.errorSavingDashboard',
    description: 'Error saving dashboard',
    defaultMessage: 'ERROR SAVING DASHBOARD'
  },
  dashboardTooHeavy: {
    id: 'ErrorSavingDashboard.dashboardTooHeavy',
    description: 'The dashboard is too heavy. Try to reduce his size (ex: delete images or reduce their quality)',
    defaultMessage: 'The dashboard is too heavy. Try to reduce his size (ex: delete images or reduce their quality)'
  },
  exit: {
    id: 'ErrorSavingDashboard.exit',
    description: 'Exit',
    defaultMessage: 'Exit'
  }
})

export default messages
