import * as t from './actionTypes'
import * as url from '../url'
import { createRequestAction } from './utils'

const getMeasurements = (groupId, deviceEid) =>
  createRequestAction(t.GET_MEASUREMENTS, url.getMeasurements(groupId, deviceEid), 'get')
const getMeasurementResults = (deviceHashId, groupId, min, max, data, page, size) =>
  createRequestAction(
    t.GET_MEASUREMENT_RESULTS,
    url.measurmentsResults(deviceHashId, groupId, min, max, page, size),
    'get',
    data
  )
const getMeasurementResultsToExport = (deviceHashId, groupId, min, max, data, page, size) =>
  createRequestAction(
    t.GET_MEASUREMENT_RESULTS_TO_EXPORT,
    url.measurmentsResults(deviceHashId, groupId, min, max, page, size),
    'get',
    data
  )

export { getMeasurements, getMeasurementResults, getMeasurementResultsToExport }
