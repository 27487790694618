import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { injectIntl } from 'react-intl'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'

import messages from '../messages'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 16
  },
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  },
  menuItem: {
    marginLeft: 0,
    padding: '20px 20px 20px 10px'
  }
}

const selectStyle = {
  container: (base, state) => ({
    ...base,
    padding: '20px 0px 0px 20px'
  }),
  control: (base, state) => ({
    ...base,
    borderStyle: 'none none solid none',
    borderRadius: 0,
    boxShadow: 0,
    '&:hover': {
      borderStyle: 'none none solid none'
    }
  }),
  menu: (base, state) => ({
    ...base,
    boxShadow: 0
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: '100%',
    width: '95%'
  })
}

class QueryPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: true,
      inputValue: ''
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleTabChange = (event, value) => {
    const { history } = this.props
    const { isMenuOpen } = this.state

    if (value === 1) {
      this.setState({
        isMenuOpen: !isMenuOpen
      })
    } else {
      history.push('/geotracking')
    }
  }

  handleInputChange = (inputValue, { action }) => {
    this.setState({ inputValue })
  }

  render() {
    const {
      classes,
      fitToBounds,
      geofences,
      groupId,
      handleClose,
      handleGeofenceChange,
      handleSelectFromTheList,
      handleToggle,
      intl,
      itemMenuOpen,
      isGeofencesLoading,
      isRuleInstancesLoading,
      openDeleteDialog,
      openEditGeofenceDialog,
      openNewGeofenceDialog,
      privileges,
      selectedFromTheList
    } = this.props

    const { inputValue, isMenuOpen } = this.state

    const { formatMessage } = intl

    const MenuListComponent = props => {
      const { children, options, theme } = props
      return (
        <List options={options} style={{ width: '97%' }} theme={theme}>
          {children}
        </List>
      )
    }

    const OptionComponent = props => {
      return (
        <ListItem
          key={props.value.hashId}
          button
          classes={{ root: classes.menuItem }}
          onClick={() => handleSelectFromTheList(props)}
          selected={selectedFromTheList && selectedFromTheList.value.hashId === props.value.hashId}
        >
          <ListItemIcon>
            <Icon className='zmdi zmdi-circle' style={{ color: props.value.color }} />
          </ListItemIcon>
          <ListItemText
            inset
            primary={props.value.name}
            secondary={
              <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                {props.value.description ? props.value.description : ''}
                {props.value.ruleInstances && props.value.ruleInstances.some(ri => ri.enabled)
                  ? ' (' + formatMessage(messages.notificationEnabled) + ')'
                  : ''}
                {props.value.ruleInstances && !props.value.ruleInstances.some(ri => ri.enabled)
                  ? ' (' + formatMessage(messages.notificationDisabled) + ')'
                  : ''}
                {!props.value.ruleInstances ? ' (' + formatMessage(messages.notificationNotAssigned) + ')' : ''}
              </span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-haspopup='true'
              aria-owns={itemMenuOpen[props.value.hashId] ? 'menu-list' : undefined}
              buttonRef={node => {
                this.anchorEl = node
              }}
              onClick={() => handleToggle(props.value)}
              style={{ position: 'relative', right: 0 }}
            >
              <Icon className='fas fa-ellipsis-v' style={{ fontSize: '18px', color: 'rgb(94, 93, 82)', margin: 0 }} />
            </IconButton>
            <Popper
              anchorEl={this.anchorEl}
              disablePortal
              open={itemMenuOpen[props.value.hashId] ? itemMenuOpen[props.value.hashId] : false}
              style={{ zIndex: 10 }}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} id='menu-list'>
                  <Paper
                    style={{
                      position: 'absolute',
                      bottom: '-33px',
                      right: '10px'
                    }}
                  >
                    <ClickAwayListener onClickAway={() => handleClose(props.value.hashId)}>
                      <MenuList>
                        <MenuItem
                          disabled={!privileges.canEditGeofence}
                          onClick={() => {
                            openEditGeofenceDialog()
                          }}
                        >
                          {formatMessage(messages.edit)}
                        </MenuItem>
                        <MenuItem
                          disabled={!privileges.canDeleteGeofence}
                          onClick={() => {
                            fitToBounds(props.value.shapeType, props.value)
                            openDeleteDialog(props.value.name, props.value.hashId, props.value.version)
                            handleClose(props.value.hashId)
                          }}
                        >
                          {formatMessage(messages.deleteLow)}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ListItemSecondaryAction>
        </ListItem>
      )
    }

    return (
      <div
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          height: '100%',
          flexGrow: isMenuOpen ? 1 : 0,
          flexShrink: 0,
          flexBasis: 0,
          minWidth: isMenuOpen ? '315px' : 0,
          position: 'relative',
          zIndex: 1
        }}
      >
        <div
          id='geofence'
          style={{
            height: '100%',
            width: isMenuOpen ? '100%' : 0,
            display: 'inline-block'
          }}
        >
          <Paper
            square
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto'
            }}
          >
            <Grid
              container
              spacing={3}
              style={{
                height: '55px',
                margin: 0,
                width: '97%'
              }}
            >
              <Grid item md={4} xs={12}>
                <Typography
                  color='inherit'
                  style={{
                    padding: '0 12px 0 12px',
                    width: '100%',
                    float: 'left'
                  }}
                  variant='h6'
                >
                  {formatMessage(messages.title)}
                </Typography>
              </Grid>

              <Grid item md={8} style={{ textAlign: 'end' }} xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={groupId === DEFAULT_GROUP_ID ? formatMessage(messages.cantCreateInDefaultGroup) : ''}
                >
                  <div style={{ padding: '0 0px 0px 12px' }}>
                    <Button
                      className='primary-action-button'
                      disabled={
                        isGeofencesLoading ||
                        isRuleInstancesLoading ||
                        groupId === DEFAULT_GROUP_ID ||
                        !privileges.canCreateGeofence
                      }
                      onClick={openNewGeofenceDialog}
                    >
                      {[<AddIcon key='addIcon' style={{ marginRight: 1 }} />, formatMessage(messages.createGeofence)]}
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                margin: 0,
                width: '97%'
              }}
            >
              <Grid item xs={12}>
                <div className='row' style={{ margin: 0 }}>
                  {isGeofencesLoading || isRuleInstancesLoading ? (
                    <div className='row' style={{ pading: '10px' }}>
                      <Loading />
                    </div>
                  ) : (
                    <Select
                      blurInputOnSelect={false}
                      closeMenuOnSelect={false}
                      components={{
                        Option: OptionComponent,
                        MenuList: MenuListComponent
                      }}
                      inputValue={inputValue}
                      isClearable
                      isSearchable
                      menuIsOpen
                      onChange={handleGeofenceChange}
                      onInputChange={this.handleInputChange}
                      options={geofences.map(geofence => ({
                        label: geofence.name,
                        value: geofence
                      }))}
                      placeholder={
                        <span>
                          <Icon
                            className='fas fa-search'
                            style={{
                              marginRight: '10px',
                              color: 'rgba(0, 0, 0, 0.54)',
                              fontSize: '18px'
                            }}
                          />
                          {formatMessage(messages.search)}
                        </span>
                      }
                      styles={selectStyle}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: 'rgba(0, 0, 0, 0.54)'
                        }
                      })}
                      value={selectedFromTheList}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
        <div
          style={{
            transformOrigin: 'left top',
            display: 'inline-block',
            position: 'absolute',
            right: '-33px',
            transform: 'translateX(100%) rotate(90deg) scale(0.7)'
          }}
        >
          <Tabs
            classes={{ indicator: classes.indicator }}
            onChange={this.handleTabChange}
            style={{ float: 'left' }}
            value={1}
          >
            <Tab
              disabled={!privileges.canViewGeotracking}
              label={privileges.canViewGeotracking ? 'Geotracking' : ''}
              style={{
                backgroundColor: !privileges.canViewGeotracking ? 'transparent' : '#dcdcdc'
              }}
            />
            <Tab
              label='Geofence'
              style={{
                backgroundColor: 'white'
              }}
            />
          </Tabs>
        </div>
      </div>
    )
  }
}

QueryPanel.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object.isRequired,
  fitToBounds: PropTypes.func.isRequired,
  geofences: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleGeofenceChange: PropTypes.func.isRequired,
  handleSelectFromTheList: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isGeofencesLoading: PropTypes.bool.isRequired,
  isRuleInstancesLoading: PropTypes.bool.isRequired,
  itemMenuOpen: PropTypes.object.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  openEditGeofenceDialog: PropTypes.func.isRequired,
  openNewGeofenceDialog: PropTypes.func.isRequired,
  options: PropTypes.object,
  privileges: PropTypes.object.isRequired,
  selectedFromTheList: PropTypes.object,
  theme: PropTypes.object
}

QueryPanel.defaultProps = {
  children: undefined,
  options: undefined,
  selectedFromTheList: null,
  theme: undefined
}

export default withStyles(styles)(injectIntl(QueryPanel))
