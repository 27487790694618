import { generatePath } from 'react-router-dom'

export const DEVICES_BASE_URL = '/devices'
export const DEVICES_REGISTER_URL = DEVICES_BASE_URL + '/register'
export const DEVICES_DETAIL_URL = DEVICES_BASE_URL + '/:deviceId'
export const DEVICES_EDIT_URL = DEVICES_DETAIL_URL + '/edit'

const getDevicesUrl = () => DEVICES_BASE_URL
const getRegisterDeviceUrl = () => DEVICES_REGISTER_URL
const getDeviceDetailUrl = deviceId => generatePath(DEVICES_DETAIL_URL, { deviceId })
const getDeviceEditUrl = deviceId => generatePath(DEVICES_EDIT_URL, { deviceId })

export { getDevicesUrl, getRegisterDeviceUrl, getDeviceDetailUrl, getDeviceEditUrl }
