import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { injectIntl } from 'react-intl'
import _isEmpty from 'lodash/isEmpty'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import messages from '../../../messages'
import ChartMetricSignal from './ChartMetricSignal'

const styles = {
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  },
  metric: {
    borderLeft: '4px solid #5e5d52',
    margin: '6px 0px'
  },
  tag: {
    height: '26px'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class ChartMetric extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  getSensorsOptions = () => {
    const { devices, getGpsSignals, metric } = this.props

    const devicesById = R.indexBy(R.prop('id'), devices)
    const deviceId = metric.device
    const device = devicesById?.[deviceId]

    if (!_isEmpty(device?.deviceConfiguration)) {
      const mappedSensorMap = device.deviceConfiguration?.sensorsMap
        ?.filter(sensor => sensor.frequency !== 0)
        ?.map(sensor => ({
          signalId: sensor.signalId,
          label: sensor.name,
          value: sensor.name,
          deviceType: device.deviceType,
          signalType: this.formatMessage(messages.canBusSignals),
          unit: sensor.unit
        }))

      const mappedMfio = device.deviceConfiguration?.MFIO?.map(sensor => ({
        signalId: sensor.signalId,
        label: sensor.name,
        value: sensor.name,
        isMFIO: true,
        deviceType: device.deviceType,
        signalType: this.formatMessage(messages.mfioSignals),
        unit: sensor.unit
      }))

      const mappedGps = getGpsSignals(device)

      const mappedAdvancedSignals =
        device?.advancedSignals?.map(advancedSignal => ({
          signalId: advancedSignal.measurementDevices[0].hashId,
          label: advancedSignal.measurementName,
          value: advancedSignal.measurementName,
          isAdvancedSignal: true,
          deviceType: advancedSignal.deviceType,
          signalType: this.formatMessage(messages.advancedSignal),
          operationType: advancedSignal.operationType,
          unit: advancedSignal.measurementUnit
        })) || []

      return [...mappedGps, ...mappedMfio, ...mappedSensorMap, ...mappedAdvancedSignals]
    }

    return []
  }

  handleDeviceChange = (event, newValue) => {
    const { onDeviceChange } = this.props
    onDeviceChange(newValue?.id, newValue?.EID)
  }

  handleSignalChange = (event, newValue) => {
    const { onSignalUpdate } = this.props
    onSignalUpdate(
      newValue.map(item => item.signalId),
      this.getSensorsOptions()
    )
  }

  handleDeleteDeviceClick = () => {
    const { onDeleteMetric, isOnlyOne } = this.props
    if (!isOnlyOne) {
      onDeleteMetric()
    }
  }

  renderSelectSignalsMenu = (itemList = []) => {
    return itemList.map((elem, i) => {
      return (
        <MenuItem key={i} value={elem.value}>
          {elem.label}
        </MenuItem>
      )
    })
  }

  renderMetricSignals = () => {
    const {
      metric: { sensors },
      onDeleteSignal,
      onDuplicateSignal,
      onSignalAggregationChanged,
      onSignalBucketChanged,
      onSignalColorChanged,
      onSignalLinestyleChanged,
      onSignalValueTypeChanged
    } = this.props

    const sensorsOptions = this.getSensorsOptions()
    return sensors.map((sensor, sensorIdx) => {
      return (
        <ChartMetricSignal
          key={sensorIdx}
          onDeleteSignal={(...args) => onDeleteSignal(sensorIdx, ...args)}
          onDuplicateSignal={(...args) => onDuplicateSignal(sensorIdx, ...args)}
          onSignalAggregationChanged={(...args) => onSignalAggregationChanged(sensorIdx, ...args)}
          onSignalBucketChanged={(...args) => onSignalBucketChanged(sensorIdx, ...args)}
          onSignalColorChanged={(...args) => onSignalColorChanged(sensorIdx, ...args)}
          onSignalLinestyleChanged={(...args) => onSignalLinestyleChanged(sensorIdx, ...args)}
          onSignalValueTypeChanged={(...args) => onSignalValueTypeChanged(sensorIdx, ...args)}
          sensor={sensor}
          sensorsOptions={sensorsOptions}
        />
      )
    })
  }

  render() {
    const {
      classes,
      devices,
      isConfigurationLoading,
      isOnlyOne,
      metric: { device: selectedDeviceId, selectedSensorsIds },
      selectedDevices
    } = this.props

    const sensorsOptions = this.getSensorsOptions()

    return (
      <Grid className={classes.metric} container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Autocomplete
                classes={{ groupLabel: classes.groupLabel, tag: classes.tag }}
                disableClearable
                getOptionDisabled={option => (selectedDevices.find(device => device === option.id) ? true : false)}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.handleDeviceChange}
                options={devices}
                renderInput={params => <TextField {...params} label={this.formatMessage(messages.machine)} />}
                value={devices.find(item => item.id === selectedDeviceId) || null}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                classes={{ groupLabel: classes.groupLabel, tag: classes.tag }}
                disableClearable
                disableCloseOnSelect
                getOptionLabel={option => option.label || ''}
                getOptionSelected={(option, value) => option.signalId === value.signalId}
                groupBy={option => option.signalType}
                loading={isConfigurationLoading}
                loadingText={this.formatMessage(messages.loadingSignalsAutocomplete)}
                multiple
                onChange={this.handleSignalChange}
                options={sensorsOptions}
                renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                value={sensorsOptions.filter(item => selectedSensorsIds.includes(item.signalId)) || []}
              />
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                placement='top'
                title={this.formatMessage(messages.removeMachine)}
              >
                <div>
                  <IconButton disabled={isOnlyOne} onClick={this.handleDeleteDeviceClick}>
                    <Icon
                      className='zmdi zmdi-delete'
                      style={isOnlyOne ? { color: '#BBBBBB' } : { color: '#484848' }}
                    />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {this.renderMetricSignals()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ChartMetric.propTypes = {
  classes: PropTypes.object.isRequired,
  devices: PropTypes.array.isRequired,
  getGpsSignals: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isConfigurationLoading: PropTypes.bool.isRequired,
  isOnlyOne: PropTypes.bool.isRequired,
  metric: PropTypes.object.isRequired,
  onDeleteMetric: PropTypes.func.isRequired,
  onDeleteSignal: PropTypes.func.isRequired,
  onDeviceChange: PropTypes.func.isRequired,
  onDuplicateSignal: PropTypes.func.isRequired,
  onSignalAggregationChanged: PropTypes.func.isRequired,
  onSignalBucketChanged: PropTypes.func.isRequired,
  onSignalColorChanged: PropTypes.func.isRequired,
  onSignalLinestyleChanged: PropTypes.func.isRequired,
  onSignalUpdate: PropTypes.func.isRequired,
  onSignalValueTypeChanged: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array.isRequired
}

export default injectIntl(withStyles(styles)(ChartMetric))
