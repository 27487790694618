import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import HelpIcon from '@material-ui/icons/Help'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'

import DropdownMenu from 'components/DropdownMenu'

import messages from './messages'

const HelpDropdown = ({ intl, user, ...other }) => {
  const { formatMessage } = intl

  let supportLink = process.env.REACT_APP_SUPPORT_LINK
  if (user?.email) supportLink += '&plus1_email=' + user.email
  supportLink += '#tab-overview'

  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50,
        width: 64
      }}
    >
      <DropdownMenu
        icon={<HelpIcon style={{ padding: 0, margin: 0, textAlign: 'center' }} />}
        subheader={
          <ListSubheader
            style={{
              opacity: 0.5,
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              color: 'black',
              paddingTop: 10
            }}
          >
            {formatMessage(messages.help)}
          </ListSubheader>
        }
        {...other}
      >
        <MenuItem
          component='a'
          href={process.env.REACT_APP_STATUS_PAGE_LINK}
          name='Status page'
          rel='noopener noreferrer'
          target='_blank'
        >
          <ListItemIcon style={{ margin: 0 }}>
            <VerifiedUserOutlinedIcon />
          </ListItemIcon>
          <ListItemText inset primary={formatMessage(messages.statusPage)} />
        </MenuItem>

        <MenuItem component='a' href={supportLink} name='Status page' rel='noopener noreferrer' target='_blank'>
          <ListItemIcon style={{ margin: 0 }}>
            <HelpOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText inset primary={formatMessage(messages.support)} />
        </MenuItem>

        <MenuItem
          name='Cookies'
          onClick={event => {
            event.preventDefault()
            if (window.CookieConsent.renew) {
              window.CookieConsent.renew()
            }
          }}
        >
          <ListItemIcon style={{ margin: 0 }}>
            <img alt='' src='/images/cookies.png' />
          </ListItemIcon>
          <ListItemText inset primary={formatMessage(messages.cookies)} />
        </MenuItem>
      </DropdownMenu>
    </div>
  )
}

HelpDropdown.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object
}

HelpDropdown.defaultProps = {
  user: null
}

export default injectIntl(HelpDropdown)
