import reducer from './reducer'

export default reducer
export {
  getTheme,
  getDefaultTheme,
  getFavicon,
  getPageTitle,
  getLogo,
  getAppBarColor,
  getGroupName,
  getLoginPath,
  getFontColor,
  getSideBarColor,
  getLoginText,
  getLoginImage
} from './reducer'
