import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AssignMachinesToGroup } from './AssignMachinesToGroup'
import EditGroup from './EditGroup'
import Groups from './Groups'
import NewGroup from './NewGroup'
import { GROUPS_BASE, GROUP_SETTINGS_NEW, GROUP_SETTINGS_EDIT, GROUP_SETTINGS_ASSIGN_MACHINES } from './urls'

const GroupsHome = ({ canReadGroups, canCreateGroups, canEditGroups, canReadDevices, canShareDevices, location }) => (
  <Switch>
    {canEditGroups && canReadGroups && canReadDevices && canShareDevices && (
      <Route component={AssignMachinesToGroup} path={GROUP_SETTINGS_ASSIGN_MACHINES} />
    )}
    {canCreateGroups && canReadGroups && <Route component={NewGroup} path={GROUP_SETTINGS_NEW} />}
    {canEditGroups && canReadDevices && location.pathname !== '/groups/group' && (
      <Route component={EditGroup} path={GROUP_SETTINGS_EDIT} />
    )}
    {canReadGroups && <Route component={Groups} path={GROUPS_BASE} />}
  </Switch>
)

export default GroupsHome
