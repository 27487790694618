import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurationsCS500'
import { utils as groupUtils } from 'ducks/groups'
import { utils } from 'ducks/login'

import Signal from './Signal'

const mapStateToProps = state => ({
  groupId: groupUtils.getSelectedGroup(state).id,
  loggedUser: utils.getLoggedUserEmail(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFieldChange: actions.changeLocalConfigurationSignalField,
      handleSignalDelete: actions.deleteLocalConfigurationSignal,
      handleSignalIdChange: actions.changeLocalConfigurationSignalId,
      makeSignalIdEditable: actions.makeSignalIdEditable,
      validateNewSignalId: actions.validateLocalConfigurationSignalId,
      handleSignalCopy: actions.copyLocalConfigurationSignal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Signal)
