import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../../../actions'

import NewGeofence from './NewGeofence'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createGeofence: actions.createGeofence,
      editGeofence: actions.editGeofence,
      updateGeofence: actions.updateGeofence,
      updateRuleInstance: actions.updateRuleInstance
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(NewGeofence)
