import { defineMessages } from 'react-intl'

const namespace = 'ChartView'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'No machines exists.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Charts title',
    defaultMessage: 'Charts'
  },
  return: {
    id: `${namespace}.return`,
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  error412Message: {
    id: `${namespace}.error412Message`,
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error428Message: {
    id: `${namespace}.error428Message`,
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name floating label text || Name table header column label',
    defaultMessage: 'Name'
  },
  generate: {
    id: `${namespace}.generate`,
    description: 'Generate button label',
    defaultMessage: 'Generate Chart'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Edit chart table total text showing rows',
    defaultMessage: 'Showing rows'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Edit chart table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Edit chart table total text of',
    defaultMessage: 'of'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID table header column label',
    defaultMessage: 'Location ID'
  },
  invalidSubpath: {
    id: `${namespace}.invalidSubpath`,
    description: 'Invalid subpath message',
    defaultMessage: 'Invalid subpath, remove any invalid characters (like "/")'
  },
  themeTitle: {
    id: `${namespace}.themeTitle`,
    description: 'Theme title',
    defaultMessage: 'Theme'
  },
  customizeTheTheme: {
    id: `${namespace}.customizeTheTheme`,
    description: 'Customize the theme subheader',
    defaultMessage: 'Customize the theme for this chart'
  },
  logoURL: {
    id: `${namespace}.logoURL`,
    description: 'Logo`s URL label',
    defaultMessage: 'Logo URL'
  },
  smallLogoURL: {
    id: `${namespace}.smallLogoURL`,
    description: 'Small logo`s URL label',
    defaultMessage: 'Small logo URL'
  },
  loginPath: {
    id: `${namespace}.loginPath`,
    description: 'Login path label',
    defaultMessage: 'Login path'
  },
  faviconURL: {
    id: `${namespace}.faviconURL`,
    description: 'Favicon`s URL label',
    defaultMessage: 'Favicon URL'
  },
  pageTitle: {
    id: `${namespace}.pageTitle`,
    description: 'Page`s title label',
    defaultMessage: 'Page title'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  errorNoDataTitle: {
    id: `${namespace}.errorNoDataTitle`,
    description: 'Error no data title',
    defaultMessage: 'No data to show'
  },
  errorNoDataMessage: {
    id: `${namespace}.errorNoDataMessage`,
    description: 'Error no data message',
    defaultMessage: 'The query returned no data'
  },
  errorNoMachineTitle: {
    id: `${namespace}.errorNoMachineTitle`,
    description: 'Error no machine title',
    defaultMessage: 'No machine or metric selected'
  },
  errorNoMachineMessage: {
    id: `${namespace}.errorNoMachineMessage`,
    description: 'Error no machine message',
    defaultMessage: 'Select at least a machine and metric to query'
  },
  noMachinesOrMetric: {
    id: `${namespace}.noMachinesOrMetric`,
    description: 'No machine or metric available',
    defaultMessage: 'No machine or metric available'
  },
  signal: {
    id: `${namespace}.signal`,
    description: 'Signal',
    defaultMessage: 'Signal'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  aggregation: {
    id: `${namespace}.aggregation`,
    description: 'Aggregation',
    defaultMessage: 'Aggregation'
  },
  bucket: {
    id: `${namespace}.bucket`,
    description: 'Bucket Size',
    defaultMessage: 'Bucket Size'
  },
  lineStyle: {
    id: `${namespace}.lineStyle`,
    description: 'Line Style',
    defaultMessage: 'Line Style'
  },
  displayInfo: {
    id: `${namespace}.displayInfo`,
    description: 'Display of Information',
    defaultMessage: 'Display of Information'
  },
  noOption: {
    id: `${namespace}.noOption`,
    description: 'Select at least one option',
    defaultMessage: 'Select at least one option'
  },
  deleteSignal: {
    id: `${namespace}.deleteSignal`,
    description: 'Remove Signal Tooltip',
    defaultMessage: 'Remove Signal'
  },
  duplicateSignal: {
    id: `${namespace}.duplicateSignal`,
    description: 'Duplicate Signal tooltip',
    defaultMessage: 'Duplicate Signal'
  },
  removeMachine: {
    id: `${namespace}.removeMachine`,
    description: 'Remove machine tooltip',
    defaultMessage: 'Remove machine'
  },
  addMachine: {
    id: `${namespace}.addMachine`,
    description: 'Add a machine',
    defaultMessage: 'Add a machine'
  },
  machine: {
    id: `${namespace}.machine`,
    description: 'Machine',
    defaultMessage: 'Machine'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  advancedSignal: {
    id: `${namespace}.advancedSignal`,
    description: 'Advanced signals',
    defaultMessage: 'Advanced signals'
  },
  chartPreview: {
    id: `${namespace}.chartPreview`,
    description: 'Chart preview',
    defaultMessage: 'Chart preview'
  },
  retrieveCSVData: {
    id: `${namespace}.retrieveCSVData`,
    description: 'Retrieve CSV data',
    defaultMessage: 'Retrieve CSV data'
  },
  downloadCSVData: {
    id: `${namespace}.downloadCSVData`,
    description: 'Download CSV data',
    defaultMessage: 'Download CSV data'
  },
  historicalDataQueryPreview: {
    id: `${namespace}.historicalDataQueryPreview`,
    description: 'Historical data query preview',
    defaultMessage: 'Historical data query preview'
  },
  metrics: {
    id: `${namespace}.metrics`,
    description: 'Metrics',
    defaultMessage: 'Metrics'
  },
  loadingChartText: {
    id: `${namespace}.loadingChartText`,
    description: 'Loading chart text',
    defaultMessage: 'Loading...'
  },
  loadingSignalsAutocomplete: {
    id: `${namespace}.loadingSignalsAutocomplete`,
    description: 'Loading signals autocomplete',
    defaultMessage: 'Loading...'
  },
  exportingDataTitle: {
    id: `${namespace}.exportingDataTitle`,
    description: 'Exporting data title',
    defaultMessage: 'Exporting data to CSV file.'
  },
  longProcessAlert: {
    id: `${namespace}.longProcessAlert`,
    description: 'Long process alert',
    defaultMessage: 'This process may take several minutes. If this window is closed the process will end. '
  },
  fileMaxSizeAlert: {
    id: `${namespace}.fileMaxSizeAlert`,
    description: 'File maximum size alert',
    defaultMessage: 'Files with a maximum of {maxSize} entries will be downloaded.'
  },
  selectedDataRange: {
    id: `${namespace}.selectedDataRange`,
    description: 'Selected data range',
    defaultMessage: 'Selected data range: FROM: {from} TO: {to}.'
  },
  selectedSignalAmount: {
    id: `${namespace}.selectedSignalAmount`,
    description: 'Amount of selected signals',
    defaultMessage: 'Amount of selected signals: {metricsCount}'
  }
})

export default messages
