const LineChartTemplate = {
  id: '',
  width: 300,
  height: 300,
  x: 20,
  y: 20,
  minHeight: 20,
  minWidth: 20,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'linechart',
    params: {
      data: '',
      valueType: '',
      values: [],
      timestamps: [],
      numberOfDecimals: 0
    }
  }
}

export default LineChartTemplate
