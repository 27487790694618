import { connect } from 'react-redux'

import { getDinamicData, getStaticData } from 'ducks/dashboards'
import { utils } from 'ducks/groups'


import BasicSignalsHistoric from './BasicSignalsHistoric'

const mapStateToProps = state => {
  return {
    dinamicData: getDinamicData(state),
    staticData: getStaticData(state),
    groupId: utils.getSelectedGroup(state).id
  }
}

export default connect(mapStateToProps)(BasicSignalsHistoric)
