const composeLocalQuery = (page, pageLength) => {
  return '?page=' + (page - 1) + '&size=' + pageLength
}

//Adapter notification detail
const adapterNotificationDetail = (
  notificationData,
  groupDescription,
  conditionVariableName,
  originalConditionVariableName
) => {
  let conditionValue = ''
  let originalConditionValue = ''
  if (notificationData.variables !== undefined && notificationData.variables.length > 0) {
    conditionValue = getVariableValue(notificationData.variables, conditionVariableName)
    originalConditionValue = getVariableValue(notificationData.variables, originalConditionVariableName)
    if (originalConditionValue !== '') {
      originalConditionValue = JSON.parse(originalConditionValue)
    }
  }

  const notificationDetail = {
    hashId: notificationData.hashId,
    ruleHashId: notificationData.ruleHashId,
    name: notificationData.name,
    description: notificationData.description,
    criticality: notificationData.criticality,
    enabled: notificationData.enabled,
    status: notificationData.status,
    notificationBody: notificationData.notificationBody,
    groupDescription,
    groupId: notificationData.groupId,

    devicesCount: notificationData.devicesCount,
    devices: notificationData.devices,

    variables: notificationData.variables,
    condition: conditionValue,
    originalCondition: originalConditionValue,

    usersCount: notificationData.usersCount,
    users: notificationData.users,

    version: notificationData.version,
    lastModifiedDate: notificationData.lastModifiedDate,
    createdAt: notificationData.createdAt,
    createdDate: notificationData.createdDate
  }

  return notificationDetail
}

const getVariableValue = (notificationVariables, variableToFindName) => {
  let variableToFindValue = ''
  const variableToFind = notificationVariables.find(function (ruleVars, index) {
    if (ruleVars.name === variableToFindName) {
      if (ruleVars.value !== undefined) {
        return ruleVars
      } else {
        return null
      }
    } else {
      return ''
    }
  })
  if (variableToFind !== null) {
    variableToFindValue = variableToFind.value
  }

  return variableToFindValue
}

export { adapterNotificationDetail, composeLocalQuery }
