import { defineMessages } from 'react-intl'

const messages = defineMessages({
  linkWidget: {
    id: 'LinkSettings.linkWidget',
    description: 'Link widget',
    defaultMessage: 'LINK WIDGET'
  },
  linkWidgetSettings: {
    id: 'LinkSettings.linkWidgetSettings',
    description: 'Link widget settigs dialog',
    defaultMessage: 'Link widget settigs:'
  },
  otherLinkWidgetSettings: {
    id: 'LinkSettings.otherLinkWidgetSettings',
    description: 'Link widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  size: {
    id: 'LinkSettings.size',
    description: 'Font size',
    defaultMessage: 'Font size'
  },
  selectSizeInPixels: {
    id: 'LinkSettings.selectSizeInPixels',
    description: 'Link widget settigs: Select font size in pixels',
    defaultMessage: 'Select font size in pixels'
  },
  weight: {
    id: 'LinkSettings.weight',
    description: 'Font weight',
    defaultMessage: 'Font weight'
  },
  bold: {
    id: 'LinkSettings.bold',
    description: 'Font weight: bold',
    defaultMessage: 'Bold'
  },
  normal: {
    id: 'LinkSettings.normal',
    description: 'Font weight: normal',
    defaultMessage: 'Normal'
  },
  selectWeight: {
    id: 'LinkSettings.selectWeight',
    description: 'Link widget settigs: Select font weight',
    defaultMessage: 'Select font weight'
  },
  align: {
    id: 'LinkSettings.align',
    description: 'Text align',
    defaultMessage: 'Text align'
  },
  left: {
    id: 'LinkSettings.left',
    description: 'Text align: left',
    defaultMessage: 'Left'
  },
  center: {
    id: 'LinkSettings.center',
    description: 'Text align: center',
    defaultMessage: 'Center'
  },
  right: {
    id: 'LinkSettings.right',
    description: 'Text align: right',
    defaultMessage: 'Right'
  },
  selectAlign: {
    id: 'LinkSettings.selectAlign',
    description: 'Link widget settigs: Select text align',
    defaultMessage: 'Select text align'
  },
  cancel: {
    id: 'LinkSettings.cancel',
    description: 'Link widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'LinkSettings.save',
    description: 'Link widget settings save button text',
    defaultMessage: 'Save'
  },
  widgetNotConfigured: {
    id: 'LinkSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  textCantBeEmpty: {
    id: 'LinkSettings.textCantBeEmpty',
    description: 'Text can\'t be empty',
    defaultMessage: 'Text can\'t be empty'
  },
  text: {
    id: 'LinkSettings.text',
    description: 'Text input label',
    defaultMessage: 'Text'
  },
  url: {
    id: 'LinkSettings.url',
    description: 'Url',
    defaultMessage: 'Url'
  },
  urlCantBeEmpty: {
    id: 'LinkSettings.urlCantBeEmpty',
    description: 'Url can\'t be empty',
    defaultMessage: 'Url can\'t be empty'
  },
  urlMustStartWith: {
    id: 'LinkSettings.urlMustStartWith',
    description: 'Url must start with "https://" or "http://"',
    defaultMessage: 'Url must start with "https://" or "http://"'
  }
})

export default messages
