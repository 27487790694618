import { defineMessages } from 'react-intl'

const namespace = 'AddWidget'

const messages = defineMessages({
  newWidget: {
    id: `${namespace}.newWidget`,
    description: 'AddWidget new widget title',
    defaultMessage: 'NEW WIDGET'
  },
  widgetType: {
    id: `${namespace}.widgetType`,
    description: 'Widget type',
    defaultMessage: 'Widget Type'
  },
  box: {
    id: `${namespace}.box`,
    description: 'Widget box',
    defaultMessage: 'Box'
  },
  text: {
    id: `${namespace}.text`,
    description: 'Widget text',
    defaultMessage: 'Text'
  },
  image: {
    id: `${namespace}.image`,
    description: 'Widget image',
    defaultMessage: 'Image'
  },
  realtimevalue: {
    id: `${namespace}.realtimevalue`,
    description: 'Widget real time value',
    defaultMessage: 'Real Time Value'
  },
  value: {
    id: `${namespace}.value`,
    description: 'Widget value',
    defaultMessage: 'Value'
  },
  gauge: {
    id: `${namespace}.gauge`,
    description: 'Widget gauge',
    defaultMessage: 'Gauge'
  },
  columnchart: {
    id: `${namespace}.columnchart`,
    description: 'Widget Column chart',
    defaultMessage: 'Column chart'
  },
  linechart: {
    id: `${namespace}.linechart`,
    description: 'Widget Line chart',
    defaultMessage: 'Line chart'
  },
  line: {
    id: `${namespace}.line`,
    description: 'Widget Line',
    defaultMessage: 'Line'
  },
  link: {
    id: `${namespace}.link`,
    description: 'Widget Link',
    defaultMessage: 'Link'
  },
  historic: {
    id: `${namespace}.historic`,
    description: 'Widget Historic',
    defaultMessage: 'Historic'
  },
  map: {
    id: `${namespace}.map`,
    description: 'Widget Map',
    defaultMessage: 'Map'
  },
  video: {
    id: `${namespace}.video`,
    description: 'Widget Video',
    defaultMessage: 'Video'
  },
  weather: {
    id: `${namespace}.weather`,
    description: 'Widget Weather',
    defaultMessage: 'Weather'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine:'
  },
  configurationStatus: {
    id: `${namespace}.configurationStatus`,
    description: 'Configuration status',
    defaultMessage: 'Configuration status:'
  },
  selectMachineFromTable: {
    id: `${namespace}.selectMachineFromTable`,
    description: 'Select machine from the table',
    defaultMessage: 'Select machine from the table'
  },
  configurationAvailable: {
    id: `${namespace}.configurationAvailable`,
    description: 'Available',
    defaultMessage: 'Available'
  },
  configurationNotAvailable: {
    id: `${namespace}.configurationNotAvailable`,
    description: 'Not available',
    defaultMessage: 'Not available'
  },
  configurationLoading: {
    id: `${namespace}.configurationLoading`,
    description: 'Loading',
    defaultMessage: 'Loading'
  },
  embeddedweb: {
    id: `${namespace}.embeddedweb`,
    description: 'Widget embeddedweb',
    defaultMessage: 'Embedded web'
  },
  table: {
    id: `${namespace}.table`,
    description: 'Widget table',
    defaultMessage: 'Table'
  },
  appearance: {
    id: `${namespace}.appearance`,
    description: 'Appearance widgets subheader',
    defaultMessage: 'Appearance widgets'
  },
  data: {
    id: `${namespace}.data`,
    description: 'Data widgets subheader',
    defaultMessage: 'Data widgets'
  },
  externalInfo: {
    id: `${namespace}.externalInfo`,
    description: 'External information widgets subheader',
    defaultMessage: 'External information widgets'
  }
})

export default messages
