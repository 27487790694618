import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import GetAppIcon from '@material-ui/icons/GetApp'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

const ViewConfigurationFormRaw = ({
  classes,
  downloadMetadata,
  downloadNVConfiguration,
  intl,
  metadata,
  nvConfiguration
}) => {
  const { formatMessage } = intl

  return (
    <div className="container-fluid" style={{ height: '85%' }}>
      <Grid container={true} spacing={2} style={{ height: '100%' }}>
        <Grid item={true} sm={6} style={{ height: '100%' }} xs={12}>
          <Card style={{ height: '100%', borderRadius: 0 }}>
            <CardHeader
              action={
                <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.downloadNVConfiguration)}>
                  <IconButton onClick={downloadNVConfiguration} style={{ padding: '5px' }}>
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              }
              style={{ height: '10%' }}
              title={formatMessage(messages.nvConfiguration)}
            />
            <CardContent style={{ height: '90%' }}>
              <pre style={{ overflowY: 'scroll', height: '100%' }}>{JSON.stringify(nvConfiguration, null, 2)}</pre>
            </CardContent>
          </Card>
        </Grid>
        <Grid item={true} sm={6} style={{ height: '100%' }} xs={12}>
          <Card style={{ height: '100%', borderRadius: 0 }}>
            <CardHeader
              action={
                <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.downloadMetadata)}>
                  <IconButton onClick={downloadMetadata} style={{ padding: '5px' }}>
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
              }
              style={{ height: '10%' }}
              title={formatMessage(messages.metadata)}
            />
            <CardContent style={{ height: '90%' }}>
              <pre style={{ overflowY: 'scroll', height: '100%' }}>{JSON.stringify(metadata, null, 2)}</pre>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

ViewConfigurationFormRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  downloadMetadata: PropTypes.func.isRequired,
  downloadNVConfiguration: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  nvConfiguration: PropTypes.object.isRequired
}

export default injectIntl(withStyles(styles)(ViewConfigurationFormRaw))
