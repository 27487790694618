import React from 'react'

import { Map as MapComponent, Marker } from 'components/Map'

const Map = ({ node = {} }) => {
  const { location = {}, name, id } = node
  const { latitude, longitude } = location
  const position = {
    latitude: latitude ? parseFloat(latitude) : undefined,
    longitude: longitude ? parseFloat(longitude) : undefined
  }
  const defaultCenter = { latitude: latitude ? latitude : 38.19394, longitude: longitude ? longitude : 15.55256 }

  return (
    <MapComponent
      bounds={undefined}
      center={{ latitude: position.latitude, longitude: position.longitude }}
      defaultCenter={defaultCenter}
      fitBounds={undefined}
      options={{
        scaleControl: false,
        mapTypeControl: true,
        panControl: true,
        zoomControl: true,
        streetViewControl: false
      }}
      sat
      zoom={position.latitude && position.longitude ? 7 : 0}
    >
      {latitude && longitude && <Marker key={id} label={name} name={id} position={position} />}
    </MapComponent>
  )
}

export default Map
