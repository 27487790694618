import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import messages from './messages'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import Alert from 'components/Alert'

const styles = theme => ({
  link: {
    color: '#337aa8'
  }
})

const ProductionTestModeManagingDialog = ({
  simStatus,
  goToInfo,
  onClose,
  loading,
  manageProductionTestMode,
  alertProps,
  name,
  intl,
  classes
}) => {
  const { formatMessage } = intl
  const { link } = classes
  let title = formatMessage(messages.startTitle)
  let paragraphs = [
    formatMessage(messages.startParagraphOne),
    formatMessage(messages.startParagraphTwo),
    formatMessage(messages.startParagraphThree)
  ]
  let action = 'start'
  if (simStatus === 'T') {
    title = formatMessage(messages.endTitle)
    paragraphs = [
      formatMessage(messages.endParagraphOne),
      formatMessage(messages.endParagraphTwo, { name }),
      formatMessage(messages.endParagraphThree)
    ]
    action = 'end'
  }

  const handleClose = () => {
    onClose()
  }

  let dialogContent = (
    <React.Fragment>
      {paragraphs.map((p, index) => (
        <React.Fragment key={index}>
          <DialogContentText>{p}</DialogContentText>
          <br />
        </React.Fragment>
      ))}
      <Link component="button" onClick={goToInfo} classes={{ root: link }}>
        {formatMessage(messages.moreInformation)}
      </Link>
    </React.Fragment>
  )

  const { showAlert } = alertProps
  if (showAlert) {
    dialogContent = <Alert {...alertProps} />
  }

  const buttonDisabled = loading || showAlert

  return (
    <React.Fragment>
      <DialogTitle>
        <IconButton
          style={{
            position: 'absolute',
            right: 3,
            top: 3,
            padding: 5
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {title}
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button className="cancel-button" onClick={handleClose}>
          {formatMessage(messages.cancel)}
        </Button>
        <Button className="primary-action-button" disabled={buttonDisabled} onClick={manageProductionTestMode}>
          {formatMessage(messages[action])}
          {loading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

ProductionTestModeManagingDialog.propTypes = {
  alertProps: PropTypes.shape({
    alertType: PropTypes.string.isRequired,
    messageText: PropTypes.arrayOf(PropTypes.string).isRequired,
    messageTitle: PropTypes.string.isRequired,
    showAlert: PropTypes.bool.isRequired
  }).isRequired,
  goToInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  manageProductionTestMode: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  simStatus: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(ProductionTestModeManagingDialog))
