import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { isEditing, getGroupDashboards, finishEditDashboard, getDashboardSettings } from 'ducks/groupDashboards'
import { utils } from 'ducks/websocket'

import { utils as groupUtils } from 'ducks/groups'
import { utils as themeUtils } from 'ducks/theme'
import * as c from 'utils/constants'

import GroupDashboards from './GroupDashboards'
import GroupDashboardsEmptyTMP from './GroupDashboardsEmpty'

const mapStateToProps = state => {
  return {
    isEditing: isEditing(state),
    isSubscribedTo: (nodeId, topic) => utils.isSubscribedTo(state, nodeId, topic),
    settings: getDashboardSettings(state),
    getGroupDashboards: getGroupDashboards(state),
    groupId: groupUtils.getSelectedGroup(state).id,
    canCreateGroupDashboards: groupUtils.hasPermission(state, c.GROUPDASHBOARD_CREATE),
    dashboardBarColor: themeUtils.getSideBarColor(state),
    fontColor: themeUtils.getFontColor(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    finishEditDashboard: () => dispatch(finishEditDashboard())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupDashboards))

const GroupDashboardsEmpty = withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupDashboardsEmptyTMP))
export { GroupDashboardsEmpty }
