import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import { getMappedConsentState } from 'components/SmsServiceConsentDialog/apiMappings'
import SmsServiceStateChip from 'components/SmsServiceStateChip'
import { getSmsServiceState } from 'components/SmsServiceStateChip/helpers'
import { client, logError } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'

import messages from './messages'

class AssignUsersToNotificationTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showUnassignedUsersTable: false,

      unassignedUsers: [],
      assignedUsersDetail: [],

      loading: false,
      loadingUnassignedUsers: false,
      loadingUsersAssignedDetail: false,

      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],

      count: reactBootstrapTable.count,
      length: reactBootstrapTable.length,
      elementsPerPage: reactBootstrapTable.elementsPerPage,
      page: reactBootstrapTable.page,
      start: reactBootstrapTable.start,

      selectedUsers: [],
      selectedUsersForNotificationRule: []
    }
  }

  componentDidMount() {
    this.fetchUnassignedUsers()
    this.fetchAssignedUsersDetails()
  }

  fetchUnassignedUsers = () => {
    const {
      getGroupUsers,
      notification: { groupId: notificationGroupId }
    } = this.props
    const { intl, length, loadingUsersAssignedDetail, start } = this.state

    getGroupUsers(notificationGroupId, length, start)
      .then(response => {
        const unassignedUsers =
          response?.data?.groupUsers?.users?.map(user => {
            const consentState = getMappedConsentState(user?.consent)
            const isPhoneNumberPresent = user?.isPhoneNumberPresent

            return {
              ...user,
              smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
            }
          }) || []

        this.setState({
          unassignedUsers,
          count: response.data.groupUsers.total,
          loading: false || loadingUsersAssignedDetail,
          loadingUnassignedUsers: false,
          showUnassignedUsersTable: true
        })
      })
      .catch(response => {
        const errorResponse = { ...response }
        if (errorResponse && errorResponse.error && errorResponse.error.response) {
          const errorDetail = errorResponse.error.response
          switch (errorDetail.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 406:
            case 500:
              this.setState({
                loading: false || loadingUsersAssignedDetail,
                loadingUnassignedUsers: false,
                unassignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, {
                  number: errorDetail.status.toString()
                }),
                alertMessagesText: [
                  intl.formatMessage(messages.errorRequestingUsers) +
                    ' (' +
                    intl.formatMessage(messages['error' + errorDetail.status + 'Text']) +
                    ')'
                ]
              })
              break
            default:
              this.setState({
                loading: false || loadingUsersAssignedDetail,
                loadingUnassignedUsers: false,
                unassignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [
                  intl.formatMessage(messages.errorRequestingUsers) +
                    ' (' +
                    intl.formatMessage(messages.errorUndefinedText) +
                    ')'
                ]
              })
          }
        } else {
          this.setState({
            loading: false || loadingUsersAssignedDetail,
            loadingUnassignedUsers: false,
            unassignedUsers: [],
            count: 0,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [
              intl.formatMessage(messages.errorRequestingUsers) +
                ' (' +
                intl.formatMessage(messages.errorUndefinedText) +
                ')'
            ]
          })
        }
      })
  }

  fetchAssignedUsersDetails = () => {
    const {
      getUsersDetail,
      intl,
      notification: { groupId: notificationGroupId, users: notificationUsers }
    } = this.props
    const { loadingUnassignedUsers } = this.state

    const notificationUserEmails = notificationUsers.map(user => user.email)

    if (notificationUserEmails.length > 0) {
      getUsersDetail(notificationGroupId, notificationUserEmails)
        .then(response => {
          if (response.data.users !== undefined && response.data.users.length > 0) {
            this.setState({
              assignedUsersDetail: response.data.users,
              loadingUsersAssignedDetail: false,
              loading: false || loadingUnassignedUsers
            })
          }
        })
        .catch(response => {
          const error = { ...response }
          if (error.response !== undefined && error.response.status !== undefined) {
            let errorMessagesTitle = ''
            let errorMessagesText = ''
            switch (error.response.status) {
              case 400: // Bad request
                errorMessagesTitle = intl.formatMessage(messages.error, { message: 400 })
                errorMessagesText = [intl.formatMessage(messages.error400Text)]
                break
              case 401: // Invalid credentials
                errorMessagesTitle = intl.formatMessage(messages.error, { message: 401 })
                errorMessagesText = [error.response.message]
                break
              case 403: // Access denied
                errorMessagesTitle = intl.formatMessage(messages.error, { message: 403 })
                errorMessagesText = [intl.formatMessage(messages.error403Text)]
                break
              case 404: // API url not found
                errorMessagesTitle = intl.formatMessage(messages.error, { message: 404 })
                errorMessagesText = [intl.formatMessage(messages.error404Text)]
                break
              case 500: // Server errors
                errorMessagesTitle = intl.formatMessage(messages.error, { message: 500 })
                errorMessagesText = [error.response.data.error_description]
                break
              default:
                errorMessagesTitle = intl.formatMessage(messages.errorUndefinedTitle)
                errorMessagesText = [intl.formatMessage(messages.errorUndefinedText)]
            }

            this.setState({
              assignedUsersDetail: [],
              loadingUsersAssignedDetail: false,
              loading: false || loadingUnassignedUsers,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: errorMessagesTitle,
              alertMessagesText: errorMessagesText
            })
          } else {
            this.setState({
              assignedUsersDetail: [],
              loadingUsersAssignedDetail: false,
              loading: false || loadingUnassignedUsers,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
            })
          }
        })
    } else {
      this.setState({
        assignedUsersDetail: [],
        loadingUsersAssignedDetail: false,
        loading: false || loadingUnassignedUsers
      })
    }
  }

  handleClearSelectionClick = () => {
    this.setState({
      selectedUsers: [],
      selectedUsersForNotificationRule: []
    })
  }

  formatFirstName = (cell, row) => {
    if (row.firstName !== undefined) {
      return row.firstName
    } else {
      return ''
    }
  }

  formatSmsService = cell => {
    return <SmsServiceStateChip state={cell} />
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        length: sizePerPage,
        unassignedUsers: []
      },
      () => {
        this.fetchUnassignedUsers()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        unassignedUsers: []
      },
      () => this.fetchUnassignedUsers()
    )
  }

  onRowSelect = (row, isSelected) => {
    const { selectedUsers, selectedUsersForNotificationRule } = this.state

    const element = row
    const elementId = row.userId
    const newSelectedUsers = selectedUsers.map(userId => userId)
    const newSelectedUsersForNotificationRule = selectedUsersForNotificationRule.map(userEmail => userEmail)

    const indexOfUser = selectedUsers.indexOf(elementId)

    if (isSelected) {
      if (indexOfUser < 0) {
        newSelectedUsers.push(elementId)
        newSelectedUsersForNotificationRule.push(element)
      }
    } else {
      if (indexOfUser > -1) {
        newSelectedUsers.splice(indexOfUser, 1)
        newSelectedUsersForNotificationRule.splice(indexOfUser, 1)
      }
    }

    this.setState({
      selectedUsers: newSelectedUsers,
      selectedUsersForNotificationRule: newSelectedUsersForNotificationRule
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedUsers, selectedUsersForNotificationRule } = this.state

    let indexOfUser
    const newSelectedUsers = selectedUsers.map(userId => userId)
    const newSelectedUsersForNotificationRule = selectedUsersForNotificationRule.map(userEmail => userEmail)
    const usersToProcess = rows.map(user => {
      return user
    })

    usersToProcess.map(user => {
      indexOfUser = newSelectedUsers.indexOf(user.userId)

      if (isSelected) {
        if (indexOfUser < 0) {
          newSelectedUsers.push(user.userId)
          newSelectedUsersForNotificationRule.push(user)
        }
      } else {
        if (indexOfUser > -1) {
          newSelectedUsers.splice(indexOfUser, 1)
          newSelectedUsersForNotificationRule.splice(indexOfUser, 1)
        }
      }

      return user
    })

    this.setState({
      selectedUsers: newSelectedUsers,
      selectedUsersForNotificationRule: newSelectedUsersForNotificationRule
    })
  }

  handleAssignUsersClick = () => {
    const { intl, notification, onNotificationAssignUsersEdited } = this.props
    const { selectedUsersForNotificationRule } = this.state

    const usersToAssign = selectedUsersForNotificationRule.map(user => ({ email: user.email, userId: user.userId }))

    client
      .assignUsersToRuleInstance(notification.hashId, usersToAssign)
      .then(() => {
        onNotificationAssignUsersEdited()
      })
      .catch(response => {
        const error = { ...response }
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 400',
              alertMessagesText: [intl.formatMessage(messages.otherError400Text)]
            })
            break
          case 401: // Invalid credentials
            let error401ToShow = ''
            if (error.response.message !== undefined) {
              error401ToShow = error.response.message
            }
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 401',
              alertMessagesText: [error401ToShow]
            })
            break
          case 403: // Access denied
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 403',
              alertMessagesText: [intl.formatMessage(messages.error403Text)]
            })
            break
          case 404: // API url not found
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 404',
              alertMessagesText: [intl.formatMessage(messages.otherError404Text)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 406',
              alertMessagesText: [intl.formatMessage(messages.error406Text)]
            })
            break
          case 409: // Data integrity violation
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 409',
              alertMessagesText: [intl.formatMessage(messages.error409Text)]
            })
            break
          case 422: // Validation failed
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 422',
              alertMessagesText: [intl.formatMessage(messages.error422Text)]
            })
            break
          case 500: // Server errors
            let error500ToShow = intl.formatMessage(messages.error500ToSHow)
            if (
              error.response.message.data !== undefined &&
              error.response.message.data.error_description !== undefined
            ) {
              error500ToShow = error.response.data.error_description
            }
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: 'Error 500',
              alertMessagesText: [error500ToShow]
            })
            break
          default:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
            })
            logError(response)
        }
      })
  }

  showUsersTools = () => {
    const { intl } = this.props
    const { selectedUsers } = this.state

    return (
      <div className='container-fluid'>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Button disabled style={{ marginTop: 14, marginLeft: 5, color: 'color: rgba(0, 0, 0, 0.87)' }}>
                {intl.formatMessage(messages.selectedUsers)} ({selectedUsers.length})
              </Button>
              <Button
                className='secondary-action-button'
                disabled={selectedUsers.length === 0}
                onClick={this.handleClearSelectionClick}
                style={{
                  marginTop: 14,
                  marginLeft: 5
                }}
              >
                {intl.formatMessage(messages.clearSelection)}
              </Button>
            </Grid>
            <Grid container item justify='flex-end' xs={8}>
              <Grid item>
                <Button
                  className='primary-action-button'
                  disabled={selectedUsers.length === 0}
                  onClick={this.handleAssignUsersClick}
                  style={{
                    marginTop: 14,
                    marginLeft: 5
                  }}
                >
                  {intl.formatMessage(messages.assignUsers)}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderTableLoadingAndError = () => {
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      loading,
      loadingUnassignedUsers,
      loadingUsersAssignedDetail
    } = this.state

    if (loading || loadingUnassignedUsers || loadingUsersAssignedDetail) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  showUsers = () => {
    const { intl } = this.props
    const {
      assignedUsersDetail,
      count,
      elementsPerPage,
      length,
      page,
      selectedUsers,
      showUnassignedUsersTable,
      unassignedUsers
    } = this.state

    if (showUnassignedUsersTable) {
      if (unassignedUsers.length > 0) {
        const tableOptions = {
          noDataText: intl.formatMessage(messages.noUsersUnassigned),
          onSizePerPageList: this.onSizePerPageList,
          sizePerPageList: elementsPerPage,
          sizePerPage: length,
          page,
          onPageChange: this.onPageChange,
          ignoreSinglePage: false,
          pageStartIndex: 1,
          paginationSize: 5,
          prePage: intl.formatMessage(messages.prePage),
          nextPage: intl.formatMessage(messages.nextPage),
          firstPage: intl.formatMessage(messages.firstPage),
          lastPage: intl.formatMessage(messages.lastPage),
          paginationShowsTotal: this.renderPaginationShowsTotal(intl.formatMessage),
          paginationPosition: 'bottom',
          hideSizePerPage: true,
          alwaysShowAllBtns: false,
          withFirstAndLast: true
        }

        const currentAssignedUsersIds = assignedUsersDetail.map(user => user.id)
        const selectRowProp = {
          mode: 'checkbox',
          clickToSelect: false,
          onSelect: this.onRowSelect,
          onSelectAll: this.onSelectAll,
          bgColor: '#f5f5f5',
          selected: selectedUsers,
          unselectable: currentAssignedUsersIds
        }

        const remote = remoteObj => {
          remoteObj.search = false // eslint-disable-line no-param-reassign
          remoteObj.pagination = true // eslint-disable-line no-param-reassign
          remoteObj.sizePerPage = true // eslint-disable-line no-param-reassign
          remoteObj.sort = false // eslint-disable-line no-param-reassign
          remoteObj.filter = false // eslint-disable-line no-param-reassign

          return remoteObj
        }

        return (
          <div>
            <div key='panel2' className='container-fluid'>
              <Paper>
                {this.showUsersTools()}
                <div className='table-with-pagination'>
                  <BootstrapTable
                    bordered={false}
                    condensed={false}
                    data={unassignedUsers}
                    exportCSV={false}
                    fetchInfo={{ dataTotalSize: count }}
                    hover
                    multiColumnSearch={false}
                    options={tableOptions}
                    pagination
                    remote={remote}
                    search={false}
                    searchPlaceholder={intl.formatMessage(messages.filterByOrderableColumns)}
                    selectRow={selectRowProp}
                    striped={false}
                  >
                    <TableHeaderColumn dataField='userId' dataFormat={this.formatFirstName} isKey>
                      {intl.formatMessage(messages.firstName)}
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='lastName'>{intl.formatMessage(messages.lastName)}</TableHeaderColumn>
                    <TableHeaderColumn dataField='email'>{intl.formatMessage(messages.email)}</TableHeaderColumn>
                    <TableHeaderColumn dataField='smsService' dataFormat={this.formatSmsService}>
                      {intl.formatMessage(messages.smsService)}
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </Paper>
            </div>
          </div>
        )
      } else {
        return (
          <div key='panel2' className='container-fluid'>
            <Paper>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12'>
                    <p>&nbsp;</p>
                    <p className='h5'>{intl.formatMessage(messages.allPosibleUsersAreAssigned)}</p>
                  </div>
                </div>
                <br />
              </div>
            </Paper>
          </div>
        )
      }
    }
  }

  render() {
    const { alertMessages, loading, loadingUnassignedUsers, loadingUsersAssignedDetail } = this.state

    if (loading || loadingUnassignedUsers || loadingUsersAssignedDetail || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return <div>{this.showUsers()}</div>
    }
  }
}

AssignUsersToNotificationTable.propTypes = {
  getGroupUsers: PropTypes.func.isRequired,
  getUsersDetail: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  onNotificationAssignUsersEdited: PropTypes.func.isRequired
}

export default injectIntl(AssignUsersToNotificationTable)
