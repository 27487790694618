import { createSelector } from 'reselect'

import base64url from 'base64-url'
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware'

import * as c from '../constants'
import { types } from '../actions'

import request, {
  CREATE,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CACHE,
  getRequestStatus as grs,
  getResponseData as grd,
  getResponseMetadata as grm
} from './request'

const MAXIMUM_ENTRIES = 50

// Tracking all request (e.g., to show loading icons) and
// sort of rudimentary app-level cache.
const requests = (state = [], action) => {
  if (action.type === types.CLEAR_STALE_ENTRIES && action.payload) {
    const when = action.payload
    const cleanedState = state.filter(v => when < v[0])
    return cleanedState.length === state.length ? state : cleanedState
  }

  if ('meta' in action) {
    const { previousAction } = action.meta
    if (previousAction) {
      // 'payload' in action or 'error' in action!!!
      const { url } = previousAction.payload.request
      const reqNumber = state.findIndex(r => r[1].url === url)
      if (reqNumber !== -1) {
        const newState = [...state]
        const previousEntry = state[reqNumber] // Request + TTL

        if (action.type.endsWith(SUCCESS_SUFFIX)) {
          // action.meta.cache: Flag to check if the response is cached
          const shouldCache = previousAction.meta && 'cache' in previousAction.meta
          newState[reqNumber] = [
            previousEntry[0],
            request(previousEntry[1], {
              type: shouldCache ? CACHE : LOAD_SUCCESS,
              payload: action.payload.data
            })
          ]
        } else if (action.type.endsWith(ERROR_SUFFIX)) {
          newState[reqNumber] = [
            previousEntry[0],
            request(previousEntry[1], {
              type: LOAD_FAIL
            })
          ]
        } else {
          return state // Nothing changed
        }

        return newState
      }
    }
  }

  // After processing responses that might also have a request,
  // status is stored no matter whether the request will be cached or not.
  if (
    action.payload !== null &&
    typeof action.payload === 'object' &&
    'request' in action.payload &&
    'url' in action.payload.request
  ) {
    const { url } = action.payload.request

    let ttl = 10 * 60000 // By default: 10 minutes to get stale
    if (action.meta && action.meta.cache && action.meta.cache.ttl) {
      ttl = action.meta.cache.ttl
    }

    const expiresAt = Date.now() + ttl
    const newEntry = [
      expiresAt,
      request(undefined, {
        type: CREATE,
        payload: url
      })
    ]

    // New entry will replace any previous request with the same URL
    const newState = state.filter(r => r[1].url !== url)

    const insertIn = newState.findIndex(r => ttl > r[0])
    if (insertIn < MAXIMUM_ENTRIES) {
      if (insertIn === -1 && newState.length < MAXIMUM_ENTRIES) {
        return [...newState, newEntry]
      }
      const trailing = newState.length < MAXIMUM_ENTRIES ? newState.slice(insertIn) : newState.slice(insertIn, -1)
      return [...newState.slice(0, insertIn), newEntry, ...trailing]
    }
  }

  return state
}

export default requests

// Selectors
const getRequestsSelector = state => state[c.NAME]

// eslint-disable-next-line no-shadow
const getRequestsById = createSelector(getRequestsSelector, requests =>
  requests.reduce((ret, entry) => {
    // eslint-disable-next-line no-shadow
    const request = entry[1]
    // We could also use the URL as id, but to shorten it a little bit...
    const reqId = base64url.encode(request.url)
    ret[reqId] = request // eslint-disable-line no-param-reassign

    return ret
  }, {})
)

const getRequestStatus = (state, id) => grs(getRequestsById(state)[id])
const getResponseData = (state, id) => grd(getRequestsById(state)[id])
const getResponseMetadata = (state, id) => grm(getRequestsById(state)[id])

export { getRequestStatus, getResponseData, getResponseMetadata }
