import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import messages from '../../../messages'
import { alertStyles, iconButtonStyles } from '../../../styles'

const styles = {
  ...alertStyles,
  ...iconButtonStyles
}

class ResolveInspectionSuccessAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, intl, onCloseClick } = this.props

    return (
      <Alert classes={{ message: classes.alertMessage }} severity='success'>
        <AlertTitle classes={{ root: classes.alertTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.success)}
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onCloseClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AlertTitle>
        {intl.formatMessage(messages.inspectionSuccessfullyResolved)}
      </Alert>
    )
  }
}

ResolveInspectionSuccessAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(ResolveInspectionSuccessAlert))
