import { defineMessages } from 'react-intl'

const messages = defineMessages({
  textWidget: {
    id: 'TextSettings.textWidget',
    description: 'Text widget',
    defaultMessage: 'TEXT WIDGET'
  },
  text: {
    id: 'TextSettings.text',
    description: 'Text widget',
    defaultMessage: 'Text'
  },
  size: {
    id: 'TextSettings.size',
    description: 'Font size',
    defaultMessage: 'Font size'
  },
  weight: {
    id: 'TextSettings.weight',
    description: 'Font weight',
    defaultMessage: 'Font weight'
  },
  align: {
    id: 'TextSettings.align',
    description: 'Text align',
    defaultMessage: 'Text align'
  },
  cancel: {
    id: 'TextSettings.cancel',
    description: 'Text widget settings cancellig button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'TextSettings.save',
    description: 'Text widget settigs saving button text',
    defaultMessage: 'Save'
  },
  textWidgetSettings: {
    id: 'TextSettings.textWidgetSettings',
    description: 'Text widget settigs dialog context text',
    defaultMessage: 'Text widget settigs:'
  },
  selectSizeInPixels: {
    id: 'TextSettings.selectSizeInPixels',
    description: 'Text widget settigs: Select font size in pixels',
    defaultMessage: 'Select font size in pixels'
  },
  selectWeight: {
    id: 'TextSettings.selectWeight',
    description: 'Text widget settigs: Select font weight',
    defaultMessage: 'Select font weight'
  },
  selectAlign: {
    id: 'TextSettings.selectAlign',
    description: 'Text widget settigs: Select text align',
    defaultMessage: 'Select text align'
  },
  mustHaveAValue: {
    id: 'TextSettings.mustHaveAValue',
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  bold: {
    id: 'TextSettings.bold',
    description: 'Font weight: bold',
    defaultMessage: 'Bold'
  },
  normal: {
    id: 'TextSettings.normal',
    description: 'Font weight: normal',
    defaultMessage: 'Normal'
  },
  left: {
    id: 'TextSettings.left',
    description: 'Text align: left',
    defaultMessage: 'Left'
  },
  center: {
    id: 'TextSettings.center',
    description: 'Text align: center',
    defaultMessage: 'Center'
  },
  right: {
    id: 'TextSettings.right',
    description: 'Text align: right',
    defaultMessage: 'Right'
  },
  selectBooleanSignal: {
    id: 'TextSettings.selectBooleanSignal',
    description: '',
    defaultMessage: 'Select boolean signal'
  },
  selectValueToDisplay: {
    id: 'TextSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  booleanSignalDependable: {
    id: 'TextSettings.booleanSignalDependable',
    description: '',
    defaultMessage: 'Make this widget boolean signal dependable'
  },
  youMustChooseOneSignal: {
    id: 'TextSettings.youMustChooseOneSignal',
    description: '',
    defaultMessage: 'You must choose a valid signal or disable the option otherwise'
  },
  operator: {
    id: 'TextSettings.operator',
    description: 'Operator',
    defaultMessage: 'Operator'
  },
  conditionalValue: {
    id: 'TextSettings.conditionalValue',
    description: 'Value',
    defaultMessage: 'Value'
  },
  textWidgetSettingsWhenTrue: {
    id: 'TextSettings.textWidgetSettingsWhenTrue',
    description: 'Text widget settings when conditional signal is TRUE',
    defaultMessage: 'Text widget settings when conditional signal is TRUE'
  },
  textWidgetSettingsWhenFalse: {
    id: 'TextSettings.textWidgetSettingsWhenFalse',
    description: 'Text widget settings when conditional signal is FALSE',
    defaultMessage: 'Text widget settings when conditional signal is FALSE'
  },
  required: {
    id: 'TextSettings.required',
    description: 'Required',
    defaultMessage: 'Required'
  },
  thisFieldIsRequired: {
    id: 'TextSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  valueType: {
    id: 'TextSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: 'TextSettings.value',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: 'TextSettings.valueAvg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: 'TextSettings.valueMin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: 'TextSettings.valueMax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  }
})

export default messages
