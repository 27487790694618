import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'

import AssignMachinesStep from './AssignMachinesStep'
import AssignUsersStep from './AssignUsersStep'
import NewGeofenceStep from './NewGeofenceStep'
import messages from './messages'

class NewGeofence extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      activeStep: 0,
      createGeofenceAction: props.createGeofence
    }
  }

  componentDidMount() {
    const { groupId, updateGeofence, updateRuleInstance } = this.props

    updateGeofence({ groupId })
    updateRuleInstance({ groupId })
  }

  componentDidUpdate(prevProps, prevState) {
    const { editGeofence } = this.props
    const { activeStep } = this.state

    if (prevState.activeStep === 0 && activeStep === 1) {
      this.setState({
        createGeofenceAction: editGeofence
      })
    }
  }

  getSteps = () => {
    return [
      this.formatMessage(messages.newGeofenceStep),
      this.formatMessage(messages.assignMachinesStep),
      this.formatMessage(messages.assignUsersStep)
    ]
  }

  handleNextStep = () => {
    const { activeStep } = this.state
    this.setState({
      activeStep: activeStep + 1
    })
  }

  handlePreviousStep = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }))
  }

  render() {
    const { groupId, privileges } = this.props
    const { activeStep, createGeofenceAction } = this.state

    const steps = this.getSteps()

    return (
      <div className='content-container' style={{ height: '100%', padding: '20px 30px 20px 30px' }}>
        <div className='col-md-12' style={{ height: '100%', padding: 0 }}>
          <div className='col-md-12' style={{ height: '10%', padding: 0 }}>
            <h1 style={{ margin: '10px 0 20px 0' }}>{this.formatMessage(messages.title)}</h1>
          </div>
          <div className='col-md-12' style={{ height: '9%', padding: '0 0 10px 0' }}>
            <Stepper
              activeStep={activeStep}
              className='col align-self-center'
              style={{ padding: 0, marginRight: '20%', marginLeft: '20%' }}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {activeStep === 0 && (
            <NewGeofenceStep
              activeStep={activeStep}
              createGeofenceAction={createGeofenceAction}
              groupId={groupId}
              onNextStep={this.handleNextStep}
              privileges={privileges}
            />
          )}
          {activeStep === 1 && (
            <AssignMachinesStep
              groupId={groupId}
              onNextStep={this.handleNextStep}
              onPreviousStep={this.handlePreviousStep}
              privileges={privileges}
            />
          )}
          {activeStep === 2 && (
            <AssignUsersStep groupId={groupId} onPreviousStep={this.handlePreviousStep} privileges={privileges} />
          )}
        </div>
      </div>
    )
  }
}

NewGeofence.propTypes = {
  createGeofence: PropTypes.func.isRequired,
  editGeofence: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  privileges: PropTypes.object.isRequired,
  updateGeofence: PropTypes.func.isRequired,
  updateRuleInstance: PropTypes.func.isRequired
}

export default injectIntl(NewGeofence)
