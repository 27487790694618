import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import ReactPlayer from 'react-player'

import { withStyles } from '@material-ui/core/styles'

import { injectIntl } from 'react-intl'
import messages from './messages'

const styles = theme => ({
  errorTypography: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle'
  }
})

class Video extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      fetchError: ''
    }
  }

  errorCallback = e => {
    this.setState({
      fetchError: this.formatMessage(messages.errorFetchingVideo)
    })
  }
  render() {
    const classes = this.props.classes

    return (
      <div
        className={this.props.data.url === '' ? 'notConfigured' : ''}
        style={{ width: '100%', height: '100%', ...this.state.fetchError && { display: 'table' } }}
      >
        {this.props.data.url === '' ? (
          <Typography>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : this.state.fetchError !== '' ? (
          <Typography classes={{ root: classes.errorTypography }}>{this.state.fetchError}</Typography>
        ) : (
          <ReactPlayer
            url={this.props.data.url}
            width="100%"
            height="100%"
            onError={this.errorCallback}
            controls={true}
          />
        )}
      </div>
    )
  }
}

Video.propTypes = {
  data: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(Video))
