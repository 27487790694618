import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noDM1MessagesAvailable: {
    id: 'DM1Table.noDM1MessagesAvailable',
    description: 'No DM1 messages available warning text',
    defaultMessage: 'No DM1 messages available'
  },
  prePage: {
    id: 'DM1Table.prePage',
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'DM1Table.nextPage',
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'DM1Table.firstPage',
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'DM1Table.lastPage',
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  exportToCsv: {
    id: 'DM1Table.exportToCsv',
    description: 'Export to CSV',
    defaultMessage: 'Export to CSV'
  },
  dm1MessagesError: {
    id: 'DM1Table.dm1MessagesError',
    description: 'Error retrieving last known DM1 messages',
    defaultMessage: 'Error retrieving last known DM1 messages'
  },
  refresh: {
    id: 'DM1Table.refresh',
    description: 'Refresh',
    defaultMessage: 'Refresh'
  }
})

export default messages
