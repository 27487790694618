import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions } from 'ducks/groups'
import { openSmsServiceConsentDialog, closeSmsServiceConsentDialog } from 'ducks/smsServiceConsentDialog/actions'
import { getIsSmsServiceConsentDialogOpen } from 'ducks/smsServiceConsentDialog/selectors'
import { actions as httpActions } from 'utils/http'

import SmsServiceConsentDialog from './SmsServiceConsentDialog'

const mapStateToProps = state => {
  return {
    isSmsServiceConsentDialogOpen: getIsSmsServiceConsentDialogOpen(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSmsServiceConsentDialog: () => dispatch(closeSmsServiceConsentDialog()),
    fetchUserDetails: () => dispatch(actions.getUserDetails(0, 0)),
    openSmsServiceConsentDialog: () => dispatch(openSmsServiceConsentDialog()),
    updateUserConsent: (userId, consent, ipAddress) =>
      dispatch(httpActions.updateUserConsent(userId, consent, ipAddress)).promise.then(({ payload }) => payload)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmsServiceConsentDialog))
