import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurations'

import MFIO from './MFIO'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleMFIODelete: actions.deleteLocalConfigurationMFIO,
      handleMFIOFieldChange: actions.changeLocalConfigurationMFIOField,
      changeLocalConfigurationField: actions.changeLocalConfigurationField
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(MFIO)