import NotificationsBell from './NotificationsBell'

import { connect } from 'react-redux'

import { utils } from 'ducks/login'
import { utils as notificationsUtils } from 'ducks/notifications'

const mapStateToProps = state => ({
  user: utils.getLoggedUser(state),
  notificationsTotalsData: notificationsUtils.getNotificationsTotalObject(state)
})

export default connect(mapStateToProps)(NotificationsBell)
