import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import * as c from '../constants'
import { adapterNotificationDetail } from '../utils'
import EditNotificationFormConditionalSignals from './EditNotificationFormConditionalSignals'
import EditNotificationFormConditonalSignalsTitle from './EditNotificationFormConditonalSignalsTitle'
import messages from './messages'

import { client, logError } from 'utils/http'

class EditNotificationConditionalSignals extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      notificationId: props.notificationId,
      notification: {},
      loading: true,
      alertMessages: false,
      alertMessagesType: 'danger',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    //Load notification rule from DBB
    const {
      intl: { locale }
    } = this.props
    const { notificationId } = this.state

    client
      .getRuleInstance(notificationId)
      .then(response => {
        if (response.data !== undefined) {
          const notificationData = response.data
          const groupDescription = this.getGroupDescription(notificationData.groupId)
          const conditionVariableName = c.RULE_VARIABLE_CONDITION_NAME
          const originalConditionVariableName = c.RULE_VARIABLE_ORIGINAL_CONDITION_NAME
          const notificationDetail = adapterNotificationDetail(
            notificationData,
            groupDescription,
            conditionVariableName,
            originalConditionVariableName
          )
          this.setState({
            notification: notificationDetail,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorText),
            alertMessagesText: [this.formatMessage(messages.errorLoadingNotification)]
          })
        }
      })
      .catch(response => {
        const error = { ...response }
        if (error.response !== undefined && error.response.status !== undefined) {
          let message = ''
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessagesText: [this.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              if (locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessagesText: [message]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessagesText: [error.response.data.message]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessagesText: [this.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessagesText: [this.formatMessage(messages.error406Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
              })
              logError(response)
          }
        }
      })
  }

  getGroupDescription = groupId => {
    let groupDescription = ''
    const { getGroup } = this.props
    const groupInfo = getGroup(groupId)
    if (groupInfo.name !== undefined) {
      groupDescription = groupInfo.name
    }

    return groupDescription
  }

  setNotificationTitle = description => {
    if (description) {
      return description
    } else {
      return ''
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleNotificationUpdate = updatedNotification => {
    this.setState({ notification: updatedNotification })
  }

  renderTableLoadingAndError = () => {
    const { loading, alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText } = this.state
    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { loading, alertMessages, notification } = this.state
    const { fetchDeviceNonVolatileConfiguration, getDevicesDetail, notificationsUrl } = this.props
    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <EditNotificationFormConditionalSignals
          fetchDeviceNonVolatileConfiguration={fetchDeviceNonVolatileConfiguration}
          getDevicesDetail={getDevicesDetail}
          notification={notification}
          notificationsUrl={notificationsUrl}
          onNotificationUpdate={this.handleNotificationUpdate}
        />
      )
    }
  }

  render() {
    const { notification } = this.state
    const { editNotificationAssignUsersUrl, editNotificationGeneralPropertiesUrl, notificationsUrl } = this.props
    let notificationHashId = ''
    let notificationName = ''
    if (notification !== undefined && notification !== {} && notification.name !== undefined) {
      notificationName = notification.name
      if (notification.description !== undefined && notification.description !== '') {
        notificationName = notificationName + ' (' + notification.description + ')'
      }
      notificationHashId = notification.hashId
    }
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <EditNotificationFormConditonalSignalsTitle
              assignUsersUrl={editNotificationAssignUsersUrl(notificationHashId)}
              notificationGeneralPropertiesUrl={editNotificationGeneralPropertiesUrl(notificationHashId)}
              notificationsUrl={notificationsUrl}
              title={this.setNotificationTitle(notificationName)}
            />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

EditNotificationConditionalSignals.propTypes = {
  editNotificationAssignUsersUrl: PropTypes.func.isRequired,
  editNotificationGeneralPropertiesUrl: PropTypes.func.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getDevicesDetail: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notificationId: PropTypes.string.isRequired,
  notificationsUrl: PropTypes.string.isRequired
}

export default injectIntl(EditNotificationConditionalSignals)
