import base64url from 'base64-url'
import throttle from 'lodash.throttle'
import { SUCCESS_SUFFIX } from 'redux-axios-middleware'
import { utils, actions } from 'utils/http' // getResponseData

/**
 * Middleware which intercepts 'redux-axios-middleware' actions and returns:
 *   a) A promise with a new HTTP request
 *      (it simply propagates the action to 'redux-axios-middleware').
 *   b) The promise of a previous (unfulfilled) HTTP request.
 *   c) The promise with the result of a previously cached request.
 */
const createMiddleware = () => {
  const middleware = store => {
    const throttledClear = throttle(() => store.dispatch(actions.clearStaleEntries()), 1500, { trailing: true })
    const { getState } = store
    const pendingResponses = {}
    return next => action => {
      if (
        action.payload !== null &&
        typeof action.payload === 'object' &&
        'request' in action.payload &&
        'url' in action.payload.request
      ) {
        const requestId = base64url.encode(action.payload.request.url)

        const pendingResponse = pendingResponses[requestId]
        if (pendingResponse && action.payload.request.method === 'get') {
          return {
            requestId,
            promise: pendingResponse
          }
        } else {
          // If we want to always force the request being made,
          // we should simply not add a 'cache' attribute to the meta.
          const ignoreCache = action.payload.request.method !== 'get' || !(action.meta && 'cache' in action.meta)

          if (!ignoreCache) {
            throttledClear()
            const data = utils.getResponseData(getState(), requestId)

            if (data !== null) {
              // This hit might never appear in the console if the calling
              // function does not chain a .then() to the promise returned.

              return {
                requestId,
                promise: Promise.resolve({ payload: { data } })
              }
            }
          }

          return {
            requestId,
            promise: new Promise((resolve, reject) => {
              // console.warn('Setting pending request')
              // A promise should be returned by axiosMiddleware
              pendingResponses[requestId] = next(action)
              pendingResponses[requestId]
                .then(response => {
                  // console.warn('Pending request finished')
                  delete pendingResponses[requestId]

                  if (response.type.endsWith(SUCCESS_SUFFIX)) {
                    resolve(response)
                  } else {
                    // if (response.type.endsWith(ERROR_SUFFIX))
                    reject(response)
                  }
                })
                .catch(error => {
                  delete pendingResponses[requestId]
                  reject(error)
                })
            })
          }
        }
      }

      // Normal case
      return next(action)
    }
  }

  return middleware
}

export default createMiddleware
