import { defineMessages } from 'react-intl'

const messages = defineMessages({
  dm1Settings: {
    id: 'DM1CS500.dm1Settings',
    description: 'DM1 settings paper title',
    defaultMessage: 'DM1 settings'
  },
  dm1LogToPortal: {
    id: 'DM1CS500.dm1LogToPortal',
    description: 'DM1 log to portal',
    defaultMessage: 'DM1 log to portal'
  },
  dm1Port: {
    id: 'DM1CS500.dm1Port',
    description: 'DM1 port',
    defaultMessage: 'DM1 port'
  },
  dm1UseSA: {
    id: 'DM1CS500.dm1UseSA',
    description: 'Use SA',
    defaultMessage: 'Use source address'
  },
  dm1SA: {
    id: 'DM1CS500.dm1SA',
    description: 'Source address',
    defaultMessage: 'Source address'
  },
  true: {
    id: 'DM1CS500.true',
    description: 'True',
    defaultMessage: 'True'
  },
  false: {
    id: 'DM1CS500.false',
    description: 'False',
    defaultMessage: 'False'   
  },
  expand: {
    id: 'DM1CS500.expand',
    description: 'Expand',
    defaultMessage: 'Expand'
  },
  collapse: {
    id: 'DM1CS500.collapse',
    description: 'Collapse',
    defaultMessage: 'Collapse'    
  }
})

export default messages