import React from 'react'
import PropTypes from 'prop-types'

import { MuiThemeProvider } from '@material-ui/core/styles' // v1.x
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui'

import theme, { themeV0 } from './theme'

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <V0MuiThemeProvider muiTheme={themeV0}>{children}</V0MuiThemeProvider>
  </MuiThemeProvider>
)

ThemeProvider.defaultProps = {
  children: PropTypes.node.isRequired
}

export default ThemeProvider
