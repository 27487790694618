import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import SendCANMessages from './SendCANMessages'

import { actions } from 'ducks/configurationsCS500'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFieldChange: actions.changeLocalConfigurationCANMessageField,
      handleMessageCopy: actions.copyLocalConfigurationMessage,
      handleMessageDelete: actions.deleteLocalConfigurationMessage
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(SendCANMessages)
