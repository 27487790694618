import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'NewUserModal.title',
    description: 'Add a new user to this group',
    defaultMessage: 'Add a new user to this group'
  },
  error400Message: {
    id: 'NewUserModal.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'NewUserModal.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'NewUserModal.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'NewUserModal.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'NewUserModal.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'NewUserModal.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: 'NewUserModal.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Group name it\'s already in use'
  },
  error415Message: {
    id: 'NewUserModal.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'NewUserModal.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'NewUserModal.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'NewUserModal.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error500Message: {
    id: 'NewUserModal.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Internal Server Error'
  },
  email: {
    id: 'NewUserModal.email',
    description: 'Email',
    defaultMessage: 'Email'
  },
  cancel: {
    id: 'NewUserModal.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  add: {
    id: 'NewUserModal.add',
    description: 'Add',
    defaultMessage: 'Add'
  },
  emailError: {
    id: 'NewUserModal.emailError',
    description: 'Email error',
    defaultMessage: 'Invalid email format'
  },
  userDoesntExist: {
    id: 'NewUserModal.userDoesntExist',
    description: 'User doesn\'t exist message',
    defaultMessage: 'This user doesn\'t exist. Please, register it {here}'
  },
  here: {
    id: 'NewUserModal.here',
    description: 'here',
    defaultMessage: 'here'
  },
  role: {
    id: 'NewUserModal.role',
    description: 'Role',
    defaultMessage: 'Role'
  }
})

export default messages
