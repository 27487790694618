import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
//import messages from './messages'

// Destructure props like so, and use default arguments as a way of setting defaultProps
const AssignUsersToNotificationTitle = ({ title = '...', editNotificationAssignUsersUrl, intl }) => {
  /*const { formatMessage } = intl
  const button = {
    icon: 'zmdi-chevron-left',
    label: formatMessage(messages.return),
    path: editNotificationAssignUsersUrl,
    primary: false
  }*/

  return <PageTitle title={title} back={editNotificationAssignUsersUrl} /*button={button}*/ />
}

AssignUsersToNotificationTitle.propTypes = {
  editNotificationAssignUsersUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default injectIntl(AssignUsersToNotificationTitle)
