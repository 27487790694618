import { defineMessages } from 'react-intl'

const namespace = 'SmsServiceConsent'

export default defineMessages({
  accepted: {
    id: `${namespace}.accepted`,
    defaultMessage: 'Accepted'
  },

  consentStatus: {
    id: `${namespace}.consentStatus`,
    defaultMessage: 'Consent status'
  },
  declined: {
    id: `${namespace}.declined`,
    defaultMessage: 'Declined'
  },
  pending: {
    id: `${namespace}.pending`,
    defaultMessage: 'Pending'
  },
  reviewConsent: {
    id: `${namespace}.reviewConsent`,
    defaultMessage: 'Review consent'
  },
  serverError: {
    id: `${namespace}.serverError`,
    defaultMessage: 'Server error'
  },
  serverErrorClarification: {
    id: `${namespace}.serverErrorClarification`,
    defaultMessage:
      'Please try again or refresh the page. \
      If the error persists, contact <a>Plus+1 Connect Support</a>.'
  },
  smsService: {
    id: `${namespace}.smsService`,
    defaultMessage: 'SMS Service'
  },
  smsServiceConsent: {
    id: `${namespace}.smsServiceConsent`,
    defaultMessage: 'SMS Service Consent'
  }
})
