import { createAction } from 'redux-actions'

import * as t from './actionTypes'
import * as utils from './reducer'
import * as c from './constants'
import { actions } from 'utils/http'

import translations from 'i18n/locales'
import IntlMessageFormat from 'intl-messageformat'

const cleanReducerData = createAction(t.CLEAN_REDUCER_DATA)

// CREATE/EDIT/DELETE GEOFENCE OVERLAY

const saveGeofence = createAction(t.SAVE_GEOFENCE)
const deleteOverlay = createAction(t.DELETE_OVERLAY)
const saveName = createAction(t.SAVE_NAME)
const nameErrorText = createAction(t.NAME_ERROR)
const saveDescription = createAction(t.SAVE_DESCRIPTION)
const descriptionErrorText = createAction(t.DESCRIPTION_ERROR)
const saveColor = createAction(t.SAVE_COLOR)

const updateGeofence = geofence => dispatch => dispatch(saveGeofence(geofence))
const deleteGeofenceOverlay = () => dispatch => dispatch(deleteOverlay())
const handleNameChange = newName => dispatch => {
  const i18n = translations[localStorage.getItem('user_language')]
  dispatch(saveName(newName))
  if (newName.length === 0) {
    dispatch(nameErrorText(i18n['Geofences.thisFieldIsRequired']))
  } else if (newName.length > 50) {
    dispatch(nameErrorText(i18n['Geofences.nameMaxLength']))
  } else {
    dispatch(nameErrorText(''))
  }
}
const handleDescriptionChange = newDescription => dispatch => {
  const i18n = translations[localStorage.getItem('user_language')]
  dispatch(saveDescription(newDescription))
  if (newDescription.length === 0) {
    dispatch(descriptionErrorText(i18n['Geofences.thisFieldIsRequired']))
  } else if (newDescription.length > 200) {
    dispatch(descriptionErrorText(i18n['Geofences.descriptionMaxLength']))
  } else {
    dispatch(descriptionErrorText(''))
  }
}
const selectGeofenceColor = newColor => dispatch => dispatch(saveColor(newColor))

// CREATE/EDIT/DELETE RULE INSTANCE

const saveRuleInstance = createAction(t.SAVE_RULE_INSTANCE)
const saveStatus = createAction(t.SAVE_STATUS)
const saveSeverity = createAction(t.SAVE_SEVERITY)
const saveBoundary = createAction(t.SAVE_BOUNDARY)

const updateRuleInstance = ruleInstance => dispatch => dispatch(saveRuleInstance(ruleInstance))
const handleStatusChange = newStatus => dispatch => dispatch(saveStatus(newStatus))
const handleSeverityChange = newSeverity => dispatch => dispatch(saveSeverity(newSeverity))
const handleBoundaryChange = newBoundary => dispatch => dispatch(saveBoundary(newBoundary))

// RULES
const saveRules = createAction(t.SAVE_RULES)
const updateRules = rules => dispatch => dispatch(saveRules(rules))

const setGeofence = createAction(t.SET_EDIT_GEOFENCE, (geofence, rules, notificationActions, devices) => ({
  geofence,
  rules,
  notificationActions,
  devices
}))

// Dialogs

const selectGeofenceDialog = createAction(t.SELECT_GEOFENCE_DIALOG, (open, message) => ({ open, message }))
const deleteGeofenceDialog = createAction(
  t.DELETE_GEOFENCE_DIALOG,
  (open, message, geofenceId, version, ruleInstanceHashId, ruleInstanceVersion) => ({
    open,
    message,
    geofenceId,
    version,
    ruleInstanceHashId,
    ruleInstanceVersion
  })
)

const newGeofenceDialog = createAction(t.NEW_GEOFENCE_DIALOG, open => ({ open }))
const editGeofenceDialog = createAction(t.EDIT_GEOFENCE_DIALOG, open => ({ open }))

const createAlert = createAction(t.CREATE_ALERT, (open, message) => ({ open, message }))

const handleOverlayChange = (overlayType, change, newValue) => (dispatch, getState) => {
  let currentGeofence = utils.getGeofence(getState()[c.NAME])
  if (currentGeofence) {
    switch (overlayType) {
      case 'circle':
        if (change === 'radius') {
          currentGeofence = { ...currentGeofence, ...currentGeofence, radius: newValue }
        }
        if (change === 'center') {
          currentGeofence = { ...currentGeofence, ...currentGeofence, center: newValue }
        }
        break

      case 'polygon':
        currentGeofence = { ...currentGeofence, ...currentGeofence, polygonPath: newValue }
        break

      default:
    }
    dispatch(saveGeofence(currentGeofence))
  }
}

const saveCurrentOverlay = (overlay, shapeType) => (dispatch, getState) => {
  let currentGeofence = utils.getGeofence(getState()[c.NAME])

  switch (shapeType) {
    case 'polygon':
      currentGeofence = {
        ...currentGeofence,
        ...currentGeofence,
        polygonPath: overlay.getPath().getArray(),
        shapeType,
        options: {
          fillColor: overlay.fillColor,
          fillOpacity: 0.3,
          strokeColor: overlay.strokeColor,
          strokeOpacity: 1,
          strokeWeight: 1.5,
          editable: true,
          draggable: true
        }
      }
      break
    case 'circle':
      currentGeofence = {
        ...currentGeofence,
        ...currentGeofence,
        center: overlay.getCenter(),
        radius: overlay.getRadius(),
        shapeType,
        options: {
          fillColor: overlay.fillColor,
          fillOpacity: 0.3,
          strokeColor: overlay.strokeColor,
          strokeOpacity: 1,
          strokeWeight: 1.5,
          editable: true,
          draggable: true
        }
      }
      break
    default:
  }
  dispatch(saveGeofence(currentGeofence))
}

const setGeofenceToEdit = (geofence, rules, notificationActions, devices) => dispatch =>
  dispatch(setGeofence(geofence, rules, notificationActions, devices))

const getGeofence = geofenceHashId => dispatch =>
  dispatch(actions.getGeofence(geofenceHashId)).promise.then(({ payload }) => payload)

const getGeofences = (groupId, query) => dispatch =>
  dispatch(actions.getGeofences(groupId, query)).promise.then(({ payload }) => payload)

const getRuleInstances = (groupId, query) => dispatch =>
  dispatch(actions.getRuleInstances(groupId, query)).promise.then(({ payload }) => payload)

const getRuleInstance = ruleInstanceHashId => dispatch =>
  dispatch(actions.getRuleInstance(ruleInstanceHashId)).promise.then(({ payload }) => payload)

const getRules = groupId => dispatch => dispatch(actions.getRules(groupId)).promise.then(({ payload }) => payload)

const getRule = ruleHashId => dispatch => dispatch(actions.getRule(ruleHashId)).promise.then(({ payload }) => payload)

const createGeofence = (handleNextStep, groupId) => (dispatch, getState) => {
  const newGeofence = utils.getNewGeofenceToSave(getState()[c.NAME])
  const ruleInstance = utils.getRuleInstance(getState()[c.NAME])

  if (newGeofence.polygonPath) {
    newGeofence.polygonPath[newGeofence.polygonPath.length] = newGeofence.polygonPath[0]
  }

  dispatch(actions.newGeofence(groupId, newGeofence))
    .promise.then(response => {
      const geofenceHashId = response?.payload?.data?.hashId
      const newRuleInstance = {
        ...ruleInstance,
        variables: [
          {
            ...ruleInstance.variables[0],
            value: geofenceHashId
          }
        ]
      }
      dispatch(actions.newRuleInstance(newRuleInstance))
        .promise.then(() => {
          handleNextStep()
        })
        .catch(error => {
          const alertMessage = error.error && error.error.response.status + ': ' + error.error.response.data.message
          dispatch(openCreateAlert(alertMessage))
          dispatch(closeEditGeofenceDialog())
          dispatch(closeNewGeofenceDialog())
        })
    })
    .catch(error => {
      const alertMessage = error.error && error.error.response.status + ': ' + error.error.response.data.message
      dispatch(openCreateAlert(alertMessage))
      dispatch(closeEditGeofenceDialog())
      dispatch(closeNewGeofenceDialog())
    })
}

const editGeofence = handleNextStep => (dispatch, getState) => {
  const geofence = utils.getGeofenceToSave(getState()[c.NAME])
  const newRuleInstance = utils.getRuleInstance(getState()[c.NAME])

  if (geofence.polygonPath) {
    geofence.polygonPath[geofence.polygonPath.length] = geofence.polygonPath[0]
  }

  dispatch(actions.editRuleInstance(newRuleInstance))
    .promise.then(() => {
      dispatch(actions.editGeofence(geofence))
        .promise.then(() => {
          handleNextStep()
        })
        .catch(response => {
          const error = response.error
          const i18n = translations[localStorage.getItem('user_language')]
          let alertMessage

          switch (error.response.status) {
            case 405: // Not allowed
              alertMessage =
                new IntlMessageFormat(i18n['Geofences.error']).format({ number: '405' }) +
                ': ' +
                i18n['Geofences.error405Message']
              break
            default:
              alertMessage = error.response.status + ': ' + response.error.response.data.message
          }

          dispatch(openCreateAlert(alertMessage))
          dispatch(closeEditGeofenceDialog())
          dispatch(closeNewGeofenceDialog())
        })
    })
    .catch(response => {
      const error = response.error
      const i18n = translations[localStorage.getItem('user_language')]
      let alertMessage

      switch (error.response.status) {
        case 412: // Not allowed
          alertMessage =
            new IntlMessageFormat(i18n['Geofences.error']).format({ number: '412' }) +
            ': ' +
            i18n['Geofences.error412Message']
          break
        default:
          alertMessage = error.response.status + ': ' + response.error.response.data.message
      }

      dispatch(openCreateAlert(alertMessage))
      dispatch(closeEditGeofenceDialog())
      dispatch(closeNewGeofenceDialog())
    })
}

const deleteGeofence = geofence => dispatch => {
  const promise = dispatch(actions.deleteGeofence(geofence))
  return promise
}

const deleteRuleInstance = ruleInstance => dispatch => {
  const promise = dispatch(actions.deleteRuleInstance(ruleInstance))
  return promise
}

const geofenceToEdit = geofence => (dispatch, getState) => {
  let currentGeofence = utils.getGeofence(getState()[c.NAME])

  currentGeofence = {
    ...currentGeofence,
    ...currentGeofence,
    ...geofence,
    options: {
      ...currentGeofence.options,
      fillColor: geofence.color,
      strokeColor: geofence.color
    }
  }
  dispatch(saveGeofence(currentGeofence))
}

/*const handleGroupChange = groups => (dispatch, getState) => {
  dispatch(saveRCTGroup(groups))
}*/

// Dialogs
const openNewGeofenceDialog = () => dispatch => {
  dispatch(cleanReducerData())
  dispatch(newGeofenceDialog(true))
}

const closeNewGeofenceDialog = () => dispatch => dispatch(newGeofenceDialog(false))

const openEditGeofenceDialog = () => dispatch => {
  dispatch(cleanReducerData())
  dispatch(editGeofenceDialog(true))
}

const closeEditGeofenceDialog = () => dispatch => {
  dispatch(editGeofenceDialog(false))
  dispatch(closeSelectDialog())
}

const openDeleteDialog = (name, geofenceId, version, ruleInstanceHashId, ruleInstanceVersion) => dispatch => {
  const i18n = translations[localStorage.getItem('user_language')]
  dispatch(
    deleteGeofenceDialog(
      true,
      new IntlMessageFormat(i18n['Geofences.deleteGeofence']).format({ name }),
      geofenceId,
      version,
      ruleInstanceHashId,
      ruleInstanceVersion
    )
  )
}

const closeDeleteDialog = () => dispatch => dispatch(deleteGeofenceDialog(false, '', ''))

const openSelectDialog = () => dispatch => {
  const i18n = translations[localStorage.getItem('user_language')]
  dispatch(selectGeofenceDialog(true, i18n['Geofences.geofenceData']))
}

const closeSelectDialog = () => dispatch => dispatch(selectGeofenceDialog(false, '', null))

const openCreateAlert = message => dispatch => dispatch(createAlert(true, message))

const closeCreateAlert = message => dispatch => dispatch(createAlert(false, ''))

export {
  handleNameChange,
  handleDescriptionChange,
  handleStatusChange,
  handleSeverityChange,
  handleBoundaryChange,
  handleOverlayChange,
  selectGeofenceColor,
  saveCurrentOverlay,
  updateGeofence,
  getGeofence,
  getGeofences,
  getRuleInstances,
  getRuleInstance,
  updateRules,
  getRules,
  getRule,
  deleteGeofenceOverlay,
  setGeofenceToEdit,
  updateRuleInstance,
  createGeofence,
  geofenceToEdit,
  editGeofence,
  deleteGeofence,
  deleteRuleInstance,
  // Dialogs
  openNewGeofenceDialog,
  closeNewGeofenceDialog,
  openEditGeofenceDialog,
  closeEditGeofenceDialog,
  openSelectDialog,
  closeSelectDialog,
  openDeleteDialog,
  closeDeleteDialog,
  openCreateAlert,
  closeCreateAlert
}
