import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import PreviewIcon from '@material-ui/icons/CropOriginal'
import PolygonIcon from '@material-ui/icons/CropPortrait'

import grey from '@material-ui/core/colors/grey'
import Marker from '../../../mapView/CustomizedMarker'

import { Map } from '../wrapper'
import { MenuItem } from '../EditionPanel'

import { Position, QuadrilateralBounds } from '../../props'

import SvgPolygon, { WATERMARK_STYLE, EMPTY_POLYGON_STYLE } from './SvgPolygon'

import { injectIntl } from 'react-intl'
import messages from './messages'

const lngLatToPosition = lngLat => ({
  longitude: lngLat[0],
  latitude: lngLat[1]
})

const getImage = (image, features) => {
  if (image && features.length === 4) {
    return [{ url: image, bounds: features }]
  }
  return []
}

const PolygonEditor = ({
  edges = 4,
  image,
  defaultCenter,
  defaultFeatures = [],
  onChange = () => {},
  onMarkerChange = () => {},
  value,
  markerUrl,
  intl
}) => {
  const { formatMessage } = intl
  const [editMode, setEditMode] = useState(false)
  const [showPreview, setPreview] = useState(false)
  const [features, setFeatures] = useState(defaultFeatures)
  const [tempFeatures, setTempFeatures] = useState([])

  const previewable = () => {
    return image && (editMode && tempFeatures.length === edges || !editMode && features.length === edges)
  }

  const onMapClick = e => {
    if (editMode) {
      if (tempFeatures.length < edges) {
        setTempFeatures([...tempFeatures, lngLatToPosition(e.lngLat)])
      } else {
        // Reset, start a new polygon...
        setTempFeatures([lngLatToPosition(e.lngLat)])
      }
    }
  }

  const onCancel = () => {
    setEditMode(false)
    setTempFeatures([])
  }

  const onAccept = () => {
    setFeatures(tempFeatures)
    setEditMode(false)
    onChange(tempFeatures)
  }

  const onEdit = () => {
    setTempFeatures([])
    setEditMode(true)
  }

  const onDelete = () => {
    setFeatures([])
    onChange([])
  }

  const togglePreview = () => setPreview(!showPreview)

  const images = showPreview ? getImage(image, editMode ? tempFeatures : features) : []

  const initialPosition = features.length > 0 ? features[0] : defaultCenter

  return (
    <Map
      {...initialPosition}
      zoom={11}
      images={images}
      onClick={onMapClick}
      controls={
        <React.Fragment>
          {!editMode && <MenuItem key="edit" title={formatMessage(messages.edit)} icon={EditIcon} onClick={onEdit} />}
          {editMode && <MenuItem title={formatMessage(messages.cancel)} icon={CancelIcon} onClick={onCancel} />}
          {previewable() && (
            <MenuItem
              title={showPreview ? formatMessage(messages.showPolygon) : formatMessage(messages.previewOverlay)}
              icon={showPreview ? PolygonIcon : PreviewIcon}
              onClick={togglePreview}
            />
          )}
          {!editMode && features.length !== 0 && (
            <MenuItem title={formatMessage(messages.delete)} icon={DeleteIcon} onClick={onDelete} />
          )}
          {editMode && tempFeatures.length === 4 && (
            <MenuItem title={formatMessage(messages.accept)} icon={DoneIcon} onClick={onAccept} />
          )}
        </React.Fragment>
      }
    >
      {(editMode || images.length === 0) && (
        <SvgPolygon edges={edges} features={features} style={editMode ? WATERMARK_STYLE : undefined} />
      )}
      {editMode && (
        <SvgPolygon
          edges={edges}
          features={tempFeatures}
          style={images.length === 0 ? undefined : EMPTY_POLYGON_STYLE}
        />
      )}

      {!editMode && (
        <Marker
          draggable={onMarkerChange ? true : false}
          position={value}
          color={grey[800]}
          hoverColor={grey[800]}
          onDragEnd={({ lngLat }) =>
            onMarkerChange({
              longitude: lngLat[0],
              latitude: lngLat[1]
            })
          }
          url={markerUrl}
        />
      )}
    </Map>
  )
}

PolygonEditor.propTypes = {
  defaultCenter: Position,
  defaultFeatures: QuadrilateralBounds,
  edges: PropTypes.number,
  image: PropTypes.string,
  onChange: PropTypes.func
}

export default injectIntl(PolygonEditor)
