import { defineMessages } from 'react-intl'

const messages = defineMessages({
  invalidSubpath: {
    id: 'EditTheme.invalidSubpath',
    description: 'Invalid subpath message',
    defaultMessage: 'Invalid subpath, remove any invalid characters (like "/")'
  },
  customizeTheTheme: {
    id: 'EditTheme.customizeTheTheme',
    description: 'Customize the theme subheader',
    defaultMessage: 'Customize the theme for this group'
  },
  themeTitle: {
    id: 'EditTheme.themeTitle',
    description: 'Theme title',
    defaultMessage: 'Theme'
  },
  logo: {
    id: 'EditTheme.logo',
    description: 'Logo\'s label',
    defaultMessage: 'Logo'
  },
  smallLogo: {
    id: 'EditTheme.smallLogo',
    description: 'Small logo\'s label',
    defaultMessage: 'Small logo'
  },
  loginPath: {
    id: 'EditTheme.loginPath',
    description: 'Login path label',
    defaultMessage: 'Login path'
  },
  favicon: {
    id: 'EditTheme.favicon',
    description: 'Favicon\'s label',
    defaultMessage: 'Favicon'
  },
  pageTitle: {
    id: 'EditTheme.pageTitle',
    description: 'Page\'s title label',
    defaultMessage: 'Page title'
  },
  appBarColor: {
    id: 'EditTheme.appBarColor',
    description: 'App top bar\'s color',
    defaultMessage: 'App top bar color'
  },
  appSideBarColor: {
    id: 'EditTheme.appSideBarColor',
    description: 'App side bar\'s color',
    defaultMessage: 'App side bar color'
  },
  appFontColor: {
    id: 'EditTheme.appFontColor',
    description: 'App font color',
    defaultMessage: 'App font color'
  },
  loginImage: {
    id: 'EditTheme.loginImage',
    description: 'Login image\'s label',
    defaultMessage: 'Login image'
  },
  loginText: {
    id: 'EditTheme.loginText',
    description: 'Login text',
    defaultMessage: 'Login text'
  }
})

export default messages
