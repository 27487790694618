import { defineMessages } from 'react-intl'

const namespace = 'ImageSettings'

const messages = defineMessages({
  imageWidget: {
    id: `${namespace}.imageWidget`,
    description: 'Image Widget',
    defaultMessage: 'IMAGE WIDGET'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  youMustSelectAMachine: {
    id: `${namespace}.youMustSelectAMachine`,
    description: 'You must select a machine',
    defaultMessage: 'You must select a machine'
  },
  currentImage: {
    id: `${namespace}.currentImage`,
    description: 'Image widget',
    defaultMessage: 'Current image'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Image name',
    defaultMessage: 'Name:'
  },
  type: {
    id: `${namespace}.type`,
    description: 'Image type',
    defaultMessage: 'Image type'
  },
  size: {
    id: `${namespace}.size`,
    description: 'Image size',
    defaultMessage: 'Image size'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Image widget settings cancel button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Image widget settings confirm button text',
    defaultMessage: 'Save'
  },
  imageWidgetSettings: {
    id: `${namespace}.imageWidgetSettings`,
    description: 'Image widget settigs dialog context text',
    defaultMessage: 'Image widget settigs:'
  },
  selectBooleanSignal: {
    id: `${namespace}.selectBooleanSignal`,
    description: '',
    defaultMessage: 'Select boolean signal'
  },
  selectValueToDisplay: {
    id: `${namespace}.selectValueToDisplay`,
    description: '',
    defaultMessage: 'Select value to display'
  },
  booleanSignalDependable: {
    id: `${namespace}.booleanSignalDependable`,
    description: '',
    defaultMessage: 'Make this widget boolean signal dependable'
  },
  youMustChooseOneSignal: {
    id: `${namespace}.youMustChooseOneSignal`,
    description: '',
    defaultMessage: 'You must choose a valid signal or disable the option otherwise'
  },
  urlMustStartWith: {
    id: `${namespace}.urlMustStartWith`,
    description: 'Url must start with "https://" or "http://"',
    defaultMessage: 'Url must start with "https://" or "http://"'
  },
  url: {
    id: `${namespace}.url`,
    description: 'Url',
    defaultMessage: 'Url'
  },
  required: {
    id: `${namespace}.required`,
    description: 'Required',
    defaultMessage: 'Required'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  operator: {
    id: `${namespace}.operator`,
    description: 'Operator',
    defaultMessage: 'Operator'
  },
  conditionalValue: {
    id: `${namespace}.conditionalValue`,
    description: 'Value',
    defaultMessage: 'Value'
  },
  imageWidgetSettingsWhenTrue: {
    id: `${namespace}.imageWidgetSettingsWhenTrue`,
    description: 'Image widget settings when conditional signal is TRUE',
    defaultMessage: 'Image widget settings when conditional signal is TRUE'
  },
  imageWidgetSettingsWhenFalse: {
    id: `${namespace}.imageWidgetSettingsWhenFalse`,
    description: 'Image widget settings when conditional signal is FALSE',
    defaultMessage: 'Image widget settings when conditional signal is FALSE'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: `${namespace}.value`,
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: `${namespace}.valueAvg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: `${namespace}.valueMin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: `${namespace}.valueMax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  configurationStatus: {
    id: `${namespace}.configurationStatus`,
    description: 'Configuration status',
    defaultMessage: 'Configuration status'
  },
  configurationAvailable: {
    id: `${namespace}.configurationAvailable`,
    description: 'Available',
    defaultMessage: 'Available'
  },
  configurationNotAvailable: {
    id: `${namespace}.configurationNotAvailable`,
    description: 'Not available',
    defaultMessage: 'Not available'
  },
  configurationLoading: {
    id: `${namespace}.configurationLoading`,
    description: 'Loading',
    defaultMessage: 'Loading'
  }
})

export default messages
