import reducer from './map'

export default reducer

export {
  getIsMounted,
  getSearchInput,
  getCurrentFilters,
  getCurrentZoom,
  getCurrentMapCenter,
  isNodeMarkerClicked
} from './map'

export * from './mixings'
