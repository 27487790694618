import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import BarChartIcon from '@material-ui/icons/BarChart'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SearchIcon from '@material-ui/icons/Search'

import { getMachineDetailsUrl } from 'components/machineDetails'
import { getDashboardsRoute } from 'routes/helper'

import messages from '../messages'
import { container, narrowButton } from './style.module.css'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

// eslint-disable-next-line max-params
const getViewDetailAction = (nodeId, buttonClass, locationState, formatMessage, classes) => [
  <Link key='detail' className='button-link' to={{ pathname: getMachineDetailsUrl(nodeId), state: locationState }}>
    <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.machineDetails)}>
      <IconButton style={{ padding: '5px' }}>
        <SearchIcon />
      </IconButton>
    </Tooltip>
  </Link>
]

const ActionsField = ({
  nodeId,
  eid,
  narrow,
  locationState,
  canViewDashboards,
  canViewDetail,
  style,
  children,
  intl,
  classes,
  onOpenDialog,
  deviceType,
  deviceName,
  areAdvancedSignalsHidden,
  canConfigureAdvancedSignals
}) => {
  const { formatMessage } = intl
  const buttonClass = `${narrow ? narrowButton : undefined}`
  const actions = []

  if (deviceType !== 'CS10' && !areAdvancedSignalsHidden && canConfigureAdvancedSignals) {
    actions.push(
      <Tooltip
        key='advancedSignalsConfiguration'
        classes={{ tooltip: classes.tooltip }}
        title={formatMessage(messages.advancedSignalsConfiguration)}
      >
        <IconButton
          onClick={() => {
            onOpenDialog(eid, deviceType, deviceName)
          }}
          style={{ padding: 3 }}
        >
          <BarChartIcon style={{ fontSize: 28 }} />
        </IconButton>
      </Tooltip>
    )
  }

  if (canViewDashboards) {
    actions.push(
      <Link
        key='dashboards'
        className='button-link'
        to={{ pathname: getDashboardsRoute('cs', nodeId), state: locationState }}
      >
        <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.dashboards)}>
          <IconButton style={{ padding: '5px' }}>
            <DashboardIcon />
          </IconButton>
        </Tooltip>
      </Link>
    )
  }

  if (canViewDetail) actions.push(getViewDetailAction(nodeId, buttonClass, locationState, formatMessage, classes))

  return (
    <div className={container} style={style}>
      {actions !== [] && actions[0]}
      {actions !== [] && actions[1]}
      {actions !== [] && actions[2]}
      {children}
    </div>
  )
}

ActionsField.propTypes = {
  areAdvancedSignalsHidden: PropTypes.bool,
  canConfigureAdvancedSignals: PropTypes.bool.isRequired,
  canViewDashboards: PropTypes.bool.isRequired,
  canViewDetail: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  deviceName: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  locationState: PropTypes.object,
  narrow: PropTypes.bool,
  nodeId: PropTypes.string.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired
}

ActionsField.defaultProps = {
  areAdvancedSignalsHidden: false,
  locationState: {},
  narrow: false
}

export default withStyles(styles)(injectIntl(ActionsField))
