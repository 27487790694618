import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as map from 'ducks/map'
import csNode from 'modules/csNode'
import { createNodesLoader } from 'components/LoadNodesOnMount'

import * as actions from '../../../../../actions'

import MapView from './MapView'

const mapStateToProps = state => ({
  markers: map.utils.getFilteredMarkers(state)
})

const load = groupId => dispatch => {
  dispatch(map.actions.show())
  dispatch(csNode.actions.fetchDynamicCSNodesLocationsAndNotifications(groupId))
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteGeofenceOverlay: actions.deleteGeofenceOverlay,
      handleOverlayChange: actions.handleOverlayChange,
      load,
      saveCurrentOverlay: actions.saveCurrentOverlay,
      unload: map.actions.hide
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(createNodesLoader(MapView))
