import PropTypes from 'prop-types'
import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { DEFAULT_GROUP_ID } from 'utils/constants'

import DeviceConfiguration from './DeviceConfiguration'
import { Devices } from './Devices'
import { DEVICE_CONFIGURATION_MODES } from './constants'
import { DEVICES_BASE_URL, DEVICES_REGISTER_URL, DEVICES_EDIT_URL } from './urls'

const DevicesHome = ({ canReadDevices, canCreateDevices, canEditDevices, groupId }) => (
  <Switch>
    {canReadDevices && <Route component={Devices} exact path={DEVICES_BASE_URL} />}
    {canCreateDevices && groupId === DEFAULT_GROUP_ID && (
      <Route
        exact
        path={DEVICES_REGISTER_URL}
        render={allProps => (
          <DeviceConfiguration mode={DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION} {...allProps} />
        )}
      />
    )}
    {canEditDevices && (
      <Route
        exact
        path={DEVICES_EDIT_URL}
        render={allProps => (
          <DeviceConfiguration mode={DEVICE_CONFIGURATION_MODES.EDIT_DEVICE_CONFIGURATION} {...allProps} />
        )}
      />
    )}
  </Switch>
)

DevicesHome.propTypes = {
  canCreateDevices: PropTypes.bool.isRequired,
  canEditDevices: PropTypes.bool.isRequired,
  canReadDevices: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired
}

export default DevicesHome
