import * as c from '../constants'

export const CREATE = 'CREATE'
export const LOAD_SUCCESS = 'LOAD_SUCCESS'
export const LOAD_FAIL = 'LOAD_FAIL'
export const CACHE = 'CACHE'

const initState = {
  status: c.STATUS_PENDING
}

const request = (state = initState, action) => {
  if (action) {
    switch (action.type) {
      case CREATE:
        return { ...state, url: action.payload}

      case LOAD_SUCCESS:
      case CACHE:
        const { next, previous } = action.payload
        return { ...state, status: c.STATUS_DONE,
          metadata: { next, previous },
          cache: CACHE ? action.payload : undefined}

      case LOAD_FAIL:
        return { ...state, status: c.STATUS_FAILED}

      default:
    }
  }
  return state
}

export default request

const getRequestStatus = substate =>
  substate ? substate.status : c.STATUS_UNDONE

// Selectors
const getResponseData = substate => (substate ? substate.cache : null)

const getResponseMetadata = substate =>
  substate ? substate.metadata : { minDate: null, maxDate: null }

export { getRequestStatus, getResponseData, getResponseMetadata }
