import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import DashboardWrapper from 'modules/dashboards/DashboardWrapper'
import AddDashboard from 'modules/dashboards/Modals/AddDashboard'
import NoDashboard from 'modules/dashboards/NoDashboard'
import UnsupportedDashboard from 'modules/dashboards/UnsupportedDashboard'
import { client, logError } from 'utils/http'


import messages from './messages'


const styles = () => ({
  bar: {
    backgroundColor: props => props.dashboardsBarColor,
    color: props => props.fontColor
  },
  indicator: {
    backgroundColor: props => props.fontColor
  },
  fab: {
    backgroundColor: props => props.dashboardsBarColor,
    color: props => props.fontColor,
    '&:hover': {
      backgroundColor: props => props.dashboardsBarColor
    }
  }
})

class Dashboards extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      tabValue: 0,
      dashboards: [],
      incompatible: true,
      addDashboardModal: false,
      loading: true,
      tabsDisabled: true
    }
  }

  componentDidMount() {
    if (this.props.groupId) this.getNodeDashboards()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupId !== this.props.groupId && this.props.groupId) this.getNodeDashboards()
    if (prevProps.eid !== '' && this.props.eid === prevProps.eid) {
      const topic = process.env.REACT_APP_TOPIC + 'm' + prevProps.eid.replaceAll(':', '') + '/u/ds'
      const geoTopic = process.env.REACT_APP_TOPIC + 'm' + prevProps.eid.replaceAll(':', '') + '/geo'
      if (
        !prevProps.isSubscribedTo(prevProps.eid, topic) && this.props.isSubscribedTo(this.props.eid, topic) ||
        !prevProps.isSubscribedTo(prevProps.eid, geoTopic) && this.props.isSubscribedTo(this.props.eid, geoTopic)
      ) {
        this.setTabsDisabled(false)
      }
    }
  }

  componentWillUnmount() {
    const { history, location, editing } = this.props
    if (history.location.pathname !== location.pathname && editing) this.props.finishEditDashboard()
  }

  updateNodeDashboards = (dashboard = 'current', dashboardHashId) => {
    this.setState(
      {
        loading: true
      },
      this.getNodeDashboards(dashboard, dashboardHashId)
    )
  }

  getNodeDashboards = (dashboard = 'current', dashboardHashId) => {
    client
      .getNodeDashboards(this.props.match.params.nodeId)
      .then(response => {
        const dashboards = response.data
        // eslint-disable-next-line no-shadow
        const dashboardIds = dashboards.map(dashboard => dashboard.hashId)
        let tabSelected = this.state.tabValue
        switch (dashboard) {
          case 'first':
            tabSelected = 0
            break
          case 'last':
            tabSelected = dashboards.length - 1
            break
          case 'other':
            const selectedTab = dashboardIds.indexOf(dashboardHashId)
            if (selectedTab === -1) tabSelected = 0
            else tabSelected = selectedTab
            break
          case 'current':
          default:
            //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
            tabSelected = this.state.tabValue
            break
        }

        this.setState({
          tabValue: tabSelected,
          dashboards,
          incompatible: false,
          loading: false
        })
      })
      .catch(response => {
        const error = { ...response }
        switch (error.response.status) {
          case 404: // API url not found
            this.setState({
              dashboards: [],
              incompatible: true,
              loading: false
            })
            break
          default:
            this.setState({
              dashboards: [],
              incompatible: true,
              loading: false
            })
            logError(response)
        }
      })
  }

  handleTabsChange = (event, tabValue) => {
    event.preventDefault()

    if (this.state.tabValue !== tabValue) {
      this.setState({
        tabValue,
        tabsDisabled: true
      })
    }
  }

  openAddDashboardModal = () => {
    this.setState({
      addDashboardModal: true
    })
  }
  closeAddDashboardModal = () => {
    this.setState({
      addDashboardModal: false
    })
  }

  backToNodes = () => {
    const { history, location, nodesUrl } = this.props
    history.push(nodesUrl, location.state)
  }

  setTabsDisabled = tabsDisabled => {
    this.setState({
      tabsDisabled
    })
  }

  changeSelectedTab = tabValue => {
    this.setState({
      tabValue
    })
  }

  showToolbar = () => {
    const { classes } = this.props
    return (
      <Toolbar className='dashboards-toolbar'>
        <Fab
          aria-label={this.formatMessage(messages.backToMachines)}
          className='dashboards-toolbar-back'
          classes={{ root: classes.fab }}
          onClick={this.backToNodes}
          title={this.formatMessage(messages.backToMachines)}
        >
          <Icon className='zmdi zmdi-chevron-left' />
        </Fab>
        {this.state.dashboards.length > 0 ? (
          <Tabs
            classes={{ indicator: classes.indicator }}
            onChange={this.handleTabsChange}
            scrollButtons='auto'
            value={this.state.tabValue}
            variant='scrollable'
          >
            {this.state.dashboards.map((dashboard, index) => {
              const description = JSON.parse(dashboard.description).description
              const disabled = this.state.tabValue !== index && this.state.tabsDisabled
              return (
                <Tab key={dashboard.uuid} disabled={disabled} label={dashboard.name} tabIndex='1' title={description} />
              )
            })}
          </Tabs>
        ) : null}

        <div className='dashboards-toolbar_title' />

        {this.state.incompatible || !this.props.canCreateDeviceDashboards ? null : (
          <Button className='add-dashboard-button' color='inherit' onClick={this.openAddDashboardModal}>
            {this.formatMessage(messages.addDashboard)}
          </Button>
        )}
      </Toolbar>
    )
  }

  render() {
    const dashboardIds = this.state.dashboards.map(dashboard => dashboard.hashId)
    const { classes } = this.props
    return (
      <React.Fragment>
        <div className='dashboards'>
          <AppBar className='dashboards-appbar' classes={{ root: classes.bar }} position='static'>
            {this.props.editing ? (
              <Toolbar className='dashboards-toolbar'>
                <div className='dashboards-toolbar_title'>
                  <span>{this.formatMessage(messages.editingDashboard)}</span>
                  &nbsp;
                  {this.props.settings && this.props.settings.name ? (
                    <span>{this.props.settings.name}</span>
                  ) : (
                    <span>...</span>
                  )}
                </div>
              </Toolbar>
            ) : 
              this.showToolbar()
            }
          </AppBar>
          {this.state.loading ? (
            <Loading />
          ) : this.state.incompatible ? (
            <UnsupportedDashboard actionFunction={this.backToNodes} />
          ) : this.state.dashboards.length === 0 ? (
            <NoDashboard actionFunction={this.backToNodes} />
          ) : (
            <DashboardWrapper
              key={this.state.dashboards[this.state.tabValue] ? this.state.dashboards[this.state.tabValue].uuid : 'x7r'}
              dashboardData={this.state.dashboards[this.state.tabValue]}
              dashboardIds={dashboardIds}
              refresh={this.updateNodeDashboards}
              setTabsDisabled={this.setTabsDisabled}
            />
          )}
        </div>

        {this.state.addDashboardModal && this.props.canCreateDeviceDashboards && (
          <AddDashboard
            closeAddDashboardModal={this.closeAddDashboardModal}
            dashboardIds={dashboardIds}
            deviceId={this.props.match.params.nodeId}
            nodeFamily={this.props.match.params.nodeFamily}
            refresh={this.getNodeDashboards}
          />
        )}
      </React.Fragment>
    )
  }
}

Dashboards.propTypes = {
  canCreateDeviceDashboards: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  dashboardsBarColor: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  finishEditDashboard: PropTypes.func.isRequired,
  fontColor: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      nodeId: PropTypes.string.isRequired,
      nodeFamily: PropTypes.string.isRequired
    })
  }),
  nodesUrl: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired
}

Dashboards.defaultProps = {
  groupId: ''
}

export default withStyles(styles)(withRouter(injectIntl(Dashboards)))
