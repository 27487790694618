import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'UpdateConfiguration.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error400Message: {
    id: 'UpdateConfiguration.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error401Message: {
    id: 'UpdateConfiguration.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error403Message: {
    id: 'UpdateConfiguration.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'UpdateConfiguration.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'UpdateConfiguration.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error409Message: {
    id: 'UpdateConfiguration.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it\'s already in use'
  },
  error415Message: {
    id: 'UpdateConfiguration.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'UpdateConfiguration.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error500Message: {
    id: 'UpdateConfiguration.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: 'UpdateConfiguration.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'UpdateConfiguration.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  successfulConfiguration: {
    id: 'UpdateConfiguration.successfulConfiguration',
    description: 'Configuration update process started successfully',
    defaultMessage: 'Configuration update process started successfully. See more details in \'Events history\' section.'
  },
  cancel: {
    id: 'UpdateConfiguration.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  apply: {
    id: 'UpdateConfiguration.apply',
    description: 'Apply',
    defaultMessage: 'Apply'
  },
  thereAreNoAvailableConfigurations: {
    id: 'UpdateConfiguration.thereAreNoAvailableConfigurations',
    description: 'There are no available configurations',
    defaultMessage: 'There are no available configurations'
  },
  prePage: {
    id: 'UpdateConfiguration.prePage',
    description: 'Actions state previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'UpdateConfiguration.nextPage',
    description: 'Actions state next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'UpdateConfiguration.firstPage',
    description: 'Actions state first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'UpdateConfiguration.lastPage',
    description: 'Actions state last page button text',
    defaultMessage: 'Last'
  },
  errorFetchingData: {
    id: 'UpdateConfiguration.errorFetchingData',
    description: 'Error fetching data',
    defaultMessage: 'No configurations available'
  },
  showingRows: {
    id: 'UpdateConfiguration.showingRows',
    description: 'Nodes table total text showing rows',
    defaultMessage: 'Showing configurations'
  },
  to: {
    id: 'UpdateConfiguration.to',
    description: 'Nodes table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'UpdateConfiguration.of',
    description: 'Nodes table total text of',
    defaultMessage: 'of'
  },
  locationID: {
    id: 'UpdateConfiguration.locationID',
    description: 'Location ID',
    defaultMessage: 'Location ID'
  },
  name: {
    id: 'UpdateConfiguration.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  notAllowedToApplyConfiguration: {
    id: 'UpdateConfiguration.notAllowedToApplyConfiguration',
    description: 'You don\'t have enough permissions to apply a configuration to the device.',
    defaultMessage: 'You don\'t have enough permissions to apply a configuration to the device.'
  },
  browse: {
    id: 'UpdateConfiguration.browse',
    description: 'Browse file',
    defaultMessage: 'Browse file'
  },
  selectFileFromPC: {
    id: 'UpdateConfiguration.selectFileFromPC',
    description: 'Select file to import from PC:',
    defaultMessage: 'Select file to import from PC:'
  },
  validConfigFile: {
    id: 'UpdateConfiguration.validConfigFile',
    description: 'Valid configuration file',
    defaultMessage: 'Valid configuration file.'
  },
  invalidConfigFile: {
    id: 'UpdateConfiguration.invalidConfigFile',
    description: 'Invalid configuration file',
    defaultMessage: 'Invalid configuration file.'
  },
  mustBeJson: {
    id: 'UpdateConfiguration.mustBeJson',
    description: 'Selected file has not JSON format',
    defaultMessage: 'Selected file has not JSON format.'
  },
  invalidCS500config: {
    id: 'UpdateConfiguration.invalidCS500config',
    description: 'Selected file is not is not a valid CS500 configuration',
    defaultMessage: 'Selected file is not is not a valid CS500 configuration.'
  },
  loadedFile: {
    id: 'UpdateConfiguration.loadedFile',
    description: 'Loaded file',
    defaultMessage: 'Loaded file:'
  },
  devicesToUpdate: {
    id: 'UpdateConfiguration.devicesToUpdate',
    description: 'Devices to update',
    defaultMessage: 'Devices to update:'
  },
  sameConfig: {
    id: 'UpdateConfiguration.sameConfig',
    description: 'The configuration you are about to apply is already configured in the device',
    defaultMessage: 'The configuration you are trying to apply is already configured in the device.'
  },
  nvConfigurationUpdatedSuccessfully: {
    id: 'UpdateConfiguration.nvConfigurationUpdatedSuccessfully',
    description: 'Configuration successfully applied',
    defaultMessage: 'Configuration successfully applied.'
  },
  selectFile: {
    id: 'UpdateConfiguration.selectFile',
    description: 'Selected file',
    defaultMessage: 'Selected file:'
  }
})

export default messages
