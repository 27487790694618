import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

class ValidatedTextField extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getValidatedValue(props.value)
  }

  getValidatedValue(newValue) {
    const { validator } = this.props
    return {
      value: newValue,
      error: validator(newValue)
    }
  }

  handleChange(newValue) {
    const { onChange } = this.props
    const { value, error } = this.getValidatedValue(newValue)
    this.setState({ error })
    onChange(value, error === null)
  }

  render() {
    const { value, id, label, name } = this.props

    const { error = null } = this.state

    return (
      <TextField
        error={error !== null}
        fullWidth
        helperText={error}
        id={id}
        label={label}
        name={name}
        onChange={e => {
          e.preventDefault()
          this.handleChange(e.target.value)
        }}
        onClick={e => e.stopPropagation()}
        value={value}
      />
    )
  }
}

ValidatedTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export { ValidatedTextField }
