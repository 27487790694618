import * as c from './constants'

export const ADD_GPS_TRACKINGS_LOADING = `${c.NAME}/ADD_GPS_TRACKINGS_LOADING`
export const CLEAR_GPS_TRACKING = `${c.NAME}/CLEAR_GPS_TRACKING`
export const CLEAR_GPS_TRACKINGS = `${c.NAME}/CLEAR_GPS_TRACKINGS`
export const GET_AGGREGATED_GPS_TRACKINGS = `${c.NAME}/GET_AGGREGATED_GPS_TRACKINGS`
export const GET_AGGREGATED_GPS_TRACKINGS_FOR_CHARTS = `${c.NAME}/GET_AGGREGATED_GPS_TRACKINGS_FOR_CHARTS`
export const GET_AGGREGATED_GPS_TRACKINGS_TO_EXPORT = `${c.NAME}/GET_AGGREGATED_GPS_TRACKINGS_TO_EXPORT`
export const GET_GPS_TRACKINGS = `${c.NAME}/GET_GPS_TRACKINGS`
export const GET_GPS_TRACKINGS_FOR_CHARTS = `${c.NAME}/GET_GPS_TRACKINGS_FOR_CHARTS`
export const GET_GPS_TRACKINGS_TO_EXPORT = `${c.NAME}/GET_GPS_TRACKINGS_TO_EXPORT`
export const GET_LAST_LOCATION = `${c.NAME}/GET_LAST_LOCATION`
export const NEW_GPS_POINT = `${c.NAME}/NEW_GPS_POINT`
export const REMOVE_GPS_TRACKINGS_LOADING = `${c.NAME}/REMOVE_GPS_TRACKINGS_LOADING`
