import * as t from './actionTypes'
import * as url from '../plus1ConnectUrl'
import { createRequestAction } from './utils'

const getConfigurations = (groupId, limit, offset) =>
  createRequestAction(t.CONFIGURATION, url.configurations(groupId, limit, offset), 'get', null, 'plus1Connect')

const getConfiguration = (groupId, configurationId) =>
  createRequestAction(t.CONFIGURATION, url.configuration(groupId, configurationId), 'get', null, 'plus1Connect')

const createConfiguration = (groupId, newConfiguration) =>
  createRequestAction(t.CONFIGURATION, url.configurations(groupId), 'post', newConfiguration, 'plus1Connect')

const updateConfiguration = (groupId, configurationId, configuration) =>
  createRequestAction(
    t.CONFIGURATION,
    url.configuration(groupId, configurationId),
    'put',
    configuration,
    'plus1Connect'
  )

const deleteConfiguration = (groupId, configurationId) =>
  createRequestAction(t.CONFIGURATION, url.configuration(groupId, configurationId), 'delete', null, 'plus1Connect')

export { createConfiguration, getConfigurations, getConfiguration, updateConfiguration, deleteConfiguration }
