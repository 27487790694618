import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import { actions, utils } from 'ducks/groups'
import { actions as themeActions } from 'ducks/theme'
import csNode from 'modules/csNode'
import * as c from 'utils/constants'

import { getGroupsUrl, getAssignMachinesUrl } from '../urls'
import EditGroup from './EditGroup'

const mapStateToProps = (state, { history, match }) => ({
  canShareDevices: utils.hasPermission(state, c.RESOURCESHARING_ALLOW),
  editingGroup: utils.getCurrentGroup(state, match.params.groupId),
  getAssignMachinesUrl: getAssignMachinesUrl(match.params.groupId),
  getGroup: groupId => utils.getCurrentGroup(state, groupId),
  groupsUrl: getGroupsUrl(),
  onEditGroup: () => {
    history.push(getGroupsUrl(), 'update')
  },
  urlAction: history.location.state,
  portalGroupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = (dispatch, { match }) => ({
  editGroup: groupName => dispatch(actions.updateGroup(match.params.groupId, groupName)),
  // eslint-disable-next-line max-params
  getGroupDevices: (groupId, limit, offset, deviceFields, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields, filters)),
  removeDevicesFromGroup: (groupId, deviceIds, deviceType) =>
    dispatch(actions.removeDevicesFromGroup(groupId, deviceIds, deviceType)),
  setTheme: theme => dispatch(themeActions.setTheme(theme))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditGroup))
