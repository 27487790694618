import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ChartConfig from './ChartConfig'
import ChartConfigTitle from './ChartConfigTitle'

class ChartView extends Component {
  componentDidMount() {
    const { clearChart } = this.props
    clearChart()
  }

  render() {
    const {
      clearChart,
      fetchDevice,
      fetchDeviceNonVolatileConfiguration,
      getAdvancedSignalResults,
      getAdvancedSignalResultsToExport,
      getAdvancedSignals,
      getAggregatedGPSTrackingTimeSeries,
      getAggregatedGPSTrackingTimeSeriesToExport,
      getAssets,
      getChartTimeSeries,
      getChartTimeSeriesToExport,
      getGPSTrackingTimeSeries,
      getGPSTrackingTimeSeriesToExport,
      groupId,
      isAdvancedSignalsLoading,
      isAggregatedGpsTrackingsLoading,
      isAggregatedSignalsLoading,
      isGpsTrackingsLoading,
      isSidebarCollapsed,
      isSignalsLoading,
      queryStatus,
      setSearchFilters
    } = this.props
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <ChartConfigTitle />
          </div>
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <ChartConfig
              fetchDevice={fetchDevice}
              fetchDeviceNonVolatileConfiguration={fetchDeviceNonVolatileConfiguration}
              getAdvancedSignalResults={getAdvancedSignalResults}
              getAdvancedSignalResultsToExport={getAdvancedSignalResultsToExport}
              getAdvancedSignals={getAdvancedSignals}
              getAggregatedGPSTrackingTimeSeries={getAggregatedGPSTrackingTimeSeries}
              getAggregatedGPSTrackingTimeSeriesToExport={getAggregatedGPSTrackingTimeSeriesToExport}
              getAssets={getAssets}
              getChartTimeSeries={getChartTimeSeries}
              getChartTimeSeriesToExport={getChartTimeSeriesToExport}
              getGPSTrackingTimeSeries={getGPSTrackingTimeSeries}
              getGPSTrackingTimeSeriesToExport={getGPSTrackingTimeSeriesToExport}
              groupId={groupId}
              isAdvancedSignalsLoading={isAdvancedSignalsLoading}
              isAggregatedGpsTrackingsLoading={isAggregatedGpsTrackingsLoading}
              isAggregatedSignalsLoading={isAggregatedSignalsLoading}
              isGpsTrackingsLoading={isGpsTrackingsLoading}
              isSidebarCollapsed={isSidebarCollapsed}
              isSignalsLoading={isSignalsLoading}
              queryStatus={queryStatus}
              resetChart={clearChart}
              setSearchFilters={setSearchFilters}
            />
          </div>
        </div>
      </div>
    )
  }
}

ChartView.propTypes = {
  clearChart: PropTypes.any.isRequired,
  fetchDevice: PropTypes.func.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getAdvancedSignalResults: PropTypes.func.isRequired,
  getAdvancedSignalResultsToExport: PropTypes.func.isRequired,
  getAdvancedSignals: PropTypes.func.isRequired,
  getAggregatedGPSTrackingTimeSeries: PropTypes.func.isRequired,
  getAggregatedGPSTrackingTimeSeriesToExport: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  getChartTimeSeries: PropTypes.func.isRequired,
  getChartTimeSeriesToExport: PropTypes.func.isRequired,
  getGPSTrackingTimeSeries: PropTypes.func.isRequired,
  getGPSTrackingTimeSeriesToExport: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isAdvancedSignalsLoading: PropTypes.bool.isRequired,
  isAggregatedGpsTrackingsLoading: PropTypes.bool.isRequired,
  isAggregatedSignalsLoading: PropTypes.bool.isRequired,
  isGpsTrackingsLoading: PropTypes.bool.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  isSignalsLoading: PropTypes.bool.isRequired,
  queryStatus: PropTypes.object,
  setSearchFilters: PropTypes.func.isRequired
}

ChartView.defaultProps = {
  queryStatus: undefined
}

export default ChartView
