import React from 'react'
import { injectIntl } from 'react-intl'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import IconButton from '@material-ui/core/IconButton'

import messages from './messages'

const PreviousPageOption = injectIntl(({ intl, loading, ...other }) => {
  const { formatMessage } = intl
  return (
    <IconButton disabled={loading} style={{ padding: 5 }} title={formatMessage(messages.showPreviousPage)} {...other}>
      <ChevronLeftIcon style={{ fontSize: 35 }} />
    </IconButton>
  )
})

export { PreviousPageOption }
