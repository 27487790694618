import { actionTypes as wt } from 'middlewares/websocket'
import * as t from '../../../actionTypes'

const SUBSCRIBING = 'subscribing'
const UNSUBSCRIBING = 'unsubscribing'
const SUBSCRIBED = 'subscribed'
const UNSUBSCRIBED = 'unsubscribed'

const initialState = {
  status: UNSUBSCRIBED,
  // More than one component might request a subcription/desubscription
  numberOfSubscriptors: 0
}

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case t.REQUEST_SUBSCRIPTIONS:
        return {
          ...state,
          numberOfSubscriptors: state.numberOfSubscriptors + 1
        }

      case t.REQUEST_UNSUBSCRIPTIONS:
        if (state.numberOfSubscriptors > 0) {
          return {
            ...state,
            numberOfSubscriptors: state.numberOfSubscriptors - 1
          }
        }
        break

      case wt.SUBSCRIBE:
        return { ...state, status: SUBSCRIBING }

      case wt.UNSUBSCRIBE:
        return { ...state, status: UNSUBSCRIBING }

      case wt.SUBSCRIBE_FAIL:
      case wt.UNSUBSCRIBE_SUCCESS:
        return { ...state, status: UNSUBSCRIBED }

      case wt.SUBSCRIBE_SUCCESS:
      case wt.UNSUBSCRIBE_FAIL:
        return { ...state, status: SUBSCRIBED }

      default:
    }
  }

  return state
}

export default reducer

// Selectors
const isSubscribing = subscription =>
  subscription && subscription.status === SUBSCRIBING || false

const isSubscribed = subscription =>
  subscription && subscription.status === SUBSCRIBED || false

const hasSubscribers = subscription =>
  subscription &&
  subscription.numberOfSubscriptors &&
  subscription.numberOfSubscriptors > 0

export { isSubscribed, isSubscribing, hasSubscribers }
// For testing purposes
export { SUBSCRIBING, UNSUBSCRIBING, SUBSCRIBED, UNSUBSCRIBED }
