import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'Configurations.title',
    description: 'Configuration title',
    defaultMessage: 'Configurations'
  },
  createConfiguration: {
    id: 'Configurations.createConfiguration',
    description: 'Add configuration button label',
    defaultMessage: 'New configuration'
  },
  prePage: {
    id: 'Configurations.prePage',
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'Configurations.nextPage',
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'Configurations.firstPage',
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'Configurations.lastPage',
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  showingRows: {
    id: 'Configurations.showingRows',
    description: 'Nodes table total text showing rows',
    defaultMessage: 'Showing configurations'
  },
  to: {
    id: 'Configurations.to',
    description: 'Nodes table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'Configurations.of',
    description: 'Nodes table total text of',
    defaultMessage: 'of'
  },
  noConfigurationsText: {
    id: 'Configurations.noConfigurationsText',
    description: 'No configurations text',
    defaultMessage: 'No configurations available'
  },
  error400Message: {
    id: 'Configurations.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'Configurations.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'Configurations.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'Configurations.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'Configurations.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'Configurations.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  locationID: {
    id: 'Configurations.locationID',
    description: 'Location ID',
    defaultMessage: 'Location ID'
  },
  name: {
    id: 'Configurations.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  created: {
    id: 'Configurations.created',
    description: 'Created',
    defaultMessage: 'Created'
  },
  updated: {
    id: 'Configurations.updated',
    description: 'updated',
    defaultMessage: 'Updated'
  },
  actions: {
    id: 'Configurations.actions',
    description: 'actions',
    defaultMessage: 'Actions'
  },
  cancel: {
    id: 'Configurations.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'Configurations.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  confirmConfigurationToDelete: {
    id: 'Configurations.confirmConfigurationToDelete',
    description: 'Confirm configuration to delete message',
    defaultMessage: 'Confirm configuration to delete:'
  },
  configuration: {
    id: 'Configurations.configuration',
    description: 'Configuration',
    defaultMessage: 'Configuration {name}'
  },
  configurationDeletedSuccessfully: {
    id: 'Configurations.configurationDeletedSuccessfully',
    description: 'Configuration deleted successfully message',
    defaultMessage: 'Configuration deleted succesfully'
  },
  error: {
    id: 'Configurations.error',
    description: 'Error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'Configurations.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error412Message: {
    id: 'Configurations.error412Message',
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error428Message: {
    id: 'Configurations.error428Message',
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  error500Message: {
    id: 'Configurations.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  configurationCreatedSuccessfully: {
    id: 'Configurations.configurationCreatedSuccessfully',
    description: 'Configuration created successfully message',
    defaultMessage: 'Configuration created succesfully'
  },
  configurationUpdatedSuccessfully: {
    id: 'Configurations.configurationUpdatedSuccessfully',
    description: 'Configuration updated successfully message',
    defaultMessage: 'Configuration updated succesfully'
  },
  errorFetchingData: {
    id: 'Configurations.errorFetchingData',
    description: 'Error fetching data',
    defaultMessage: 'No configurations available'
  },
  showDetails: {
    id: 'Configurations.showDetails',
    description: 'Show details',
    defaultMessage: 'Show details'
  },
  newVersion: {
    id: 'Configurations.newVersion',
    description: 'New version',
    defaultMessage: 'New version'
  },
  selectFileFromPC: {
    id: 'Configurations.selectFileFromPC',
    description: 'Select file to import from PC',
    defaultMessage: 'Select file to import from PC:'
  },
  saveConfiguration: {
    id: 'Configurations.saveConfiguration',
    description: 'Save configuration',
    defaultMessage: 'Save configuration'
  },
  loadedFile: {
    id: 'Configurations.loadedFile',
    description: 'Loaded file',
    defaultMessage: 'Loaded file:'
  },
  saveConfigAs: {
    id: 'Configurations.saveConfigAs',
    description: 'Save configuration as',
    defaultMessage: 'Save configuration as:'
  },
  configurationName: {
    id: 'Configurations.configurationName',
    description: 'Configuration name',
    defaultMessage: 'Configuration name'
  },
  validConfigFile: {
    id: 'Configurations.validConfigFile',
    description: 'Valid configuration file',
    defaultMessage: 'Valid configuration file'
  },
  invalidConfigFile: {
    id: 'Configurations.invalidConfigFile',
    description: 'Invalid configuration file',
    defaultMessage: 'Invalid configuration file'
  },
  invalidJsonFile: {
    id: 'Configurations.invalidJsonFile',
    description: 'Invalid json file',
    defaultMessage: 'Invalid json file'
  },
  invalidJsonFileMessage: {
    id: 'Configurations.invalidJsonFileMessage',
    description: 'It seems that this file is not a valid JSON file',
    defaultMessage: 'It seems that this file is not a valid JSON file'
  },
  importConfigFromPC: {
    id: 'Configurations.importConfigFromPC',
    description: 'Import configuration',
    defaultMessage: 'Import configuration'
  },
  dbcFiles: {
    id: 'Configurations.dbcFiles',
    description: 'DBC files',
    defaultMessage: 'DBC files'
  },
  browse: {
    id: 'Configurations.browse',
    description: 'Browse file',
    defaultMessage: 'Browse file'
  },
  cantImportDbcInDefaultGroup: {
    id: 'Configurations.cantImportDbcInDefaultGroup',
    description: 'You can\'t import DBC files in Danfoss Default Group',
    defaultMessage: 'You can\'t import DBC files in Danfoss Default Group'
  },
  viewConfiguration: {
    id: 'Configurations.viewConfiguration',
    description: 'View configuration icon button tooltip',
    defaultMessage: 'View configuration'
  },
  editConfiguration: {
    id: 'Configurations.editConfiguration',
    description: 'Edit configuration icon button tooltip',
    defaultMessage: 'Edit configuration'
  },
  downloadConfiguration: {
    id: 'Configurations.downloadConfiguration',
    description: 'Download configuration icon button tooltip',
    defaultMessage: 'Download configuration'
  },
  deleteConfiguration: {
    id: 'Configurations.deleteConfiguration',
    description: 'Delete configuration icon button tooltip',
    defaultMessage: 'Delete configuration'
  },
  machineUsage: {
    id: 'Configurations.machineUsage',
    description: 'Machine usage percentage text',
    defaultMessage: 'Machine usage percentage'
  },
  megabytesPerMonth: {
    id: 'Configurations.bytesPerMonth',
    description: 'Megabytes per month text',
    defaultMessage: 'Megabytes per month: '
  },
  invalidConfigSizeMessage: {
    id: 'Configurations.invalidConfigSizeMessage',
    description: 'Configuration denied dialog text',
    defaultMessage:
      'The data consumption estimated for this configuration is too large. Please, try again with a different file to get an estimated data consumption below 25 MB per month.'
  }
})

export default messages
