import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import messages from '../../../messages'
import { dialogStyles, iconButtonStyles, paperStyles } from '../../../styles'

const styles = {
  ...dialogStyles,
  ...iconButtonStyles,
  ...paperStyles
}

const MaintenanceDeletionModal = props => {
  const {
    classes,
    data: { hashId: maintenanceId, name: maintenanceName },
    intl,
    isDeleteMaintenanceLoading,
    isOpen,
    onCloseClick,
    onDeleteClick
  } = props

  return (
    <Dialog
      PaperProps={{ classes: { root: classes.paperRoot } }}
      fullWidth
      maxWidth='sm'
      onClose={onCloseClick}
      open={isOpen}
      scroll='paper'
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Grid container>
          <Grid item xs={11}>
            {intl.formatMessage(messages.deleteMaintenance)}
          </Grid>
          <Grid container item justifyContent='flex-end' xs={1}>
            <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onCloseClick}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {isOpen && (
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText>
            {intl.formatMessage(messages.deleteMaintenanceConfirmation, {
              b: text => <b>{text}</b>,
              br: <br />,
              maintenanceName
            })}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCloseClick}>{intl.formatMessage(messages.cancel)}</Button>
        <Button
          className='primary-action-button'
          disabled={isDeleteMaintenanceLoading}
          onClick={() => onDeleteClick(maintenanceId)}
        >
          {isDeleteMaintenanceLoading ? <CircularProgress size={20} /> : intl.formatMessage(messages.delete)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MaintenanceDeletionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  intl: PropTypes.object.isRequired,
  isDeleteMaintenanceLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}

MaintenanceDeletionModal.defaultProps = {
  data: {}
}

export default withStyles(styles)(injectIntl(MaintenanceDeletionModal))
