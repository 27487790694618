import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import messages from './messages'

const ConfigurationsTableTitle = ({ newConfigurationUrl, canCreateConfigurations, intl }) => {
  const { formatMessage } = intl

  return (
    <div className='row'>
      <div className='col-sm-6'>
        <h1>{formatMessage(messages.title)}</h1>
      </div>
      {canCreateConfigurations && (
        <div className='col-sm-6 text-right'>
          <Link className='button-link' to={newConfigurationUrl}>
            <Button className='page-title-button' style={{ marginTop: 20 }}>
              <Icon className='zmdi zmdi-plus' />
              {formatMessage(messages.createConfiguration)}
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}

ConfigurationsTableTitle.propTypes = {
  canCreateConfigurations: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  newConfigurationUrl: PropTypes.string.isRequired
}

export default injectIntl(ConfigurationsTableTitle)
