import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils } from 'ducks/groups'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { getRolesUrl } from '../url'
import NewRole from './NewRole'
import RoleSelect from './RoleSelect'

const mapStateToProps = (state, { history }) => ({
  onRoleCreated: () => {
    history.push(getRolesUrl(), 'create')
  },
  groupId: utils.getSelectedGroup(state).id,
  canReadRoles: resolvePermissions(state, c.GROUPROLE_READ)
})

const mapDispatchToProps = dispatch => ({
  createRole: (groupId, roleName, permissions) => dispatch(actions.createRole(groupId, roleName, permissions)),
  getUserDetails: (/*groupId*/ limit, offset) => dispatch(actions.getUserDetails(/*groupId*/ limit, offset))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewRole))
export { RoleSelect }
