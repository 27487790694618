import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { Rnd } from 'react-rnd'

import Icon from '@material-ui/core/Icon'

import CloneWidget from 'modules/groupDashboards/Modals/CloneWidget'
import DeleteWidget from 'modules/groupDashboards/Modals/DeleteWidget'
import * as availableWidgets from 'modules/groupDashboards/Widgets'

import messages from './messages'

class Widget extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.dynamicWidgetTypes = ['realtimevalue', 'gauge', 'speedometer', 'columnchart', 'linechart']
    this.booleanWidgetTypes = ['box', 'image', 'text']

    this.state = {
      width: props.configuration.width,
      height: props.configuration.height,
      x: props.configuration.x,
      y: props.configuration.y,

      manipulating: false,
      editWidget: false,
      deleteWidgetModal: false,
      cloneWidgetModal: false
    }

    this.timer = undefined

    this.GRID_STEP = 10
  }

  handleWidgetSettingsOpen = () => {
    this.setState({
      editWidget: true
    })
  }

  handleDragStart = (e, d) => {
    this.setState({
      manipulating: true
    })
  }

  handleDragStop = (e, d) => {
    const HALF = 2
    let real_x
    let real_y

    if (d.x % this.GRID_STEP > this.GRID_STEP / HALF) {
      real_x = d.x - d.x % this.GRID_STEP + this.GRID_STEP
    } else {
      real_x = d.x - d.x % this.GRID_STEP
    }
    if (d.y % this.GRID_STEP > this.GRID_STEP / HALF) {
      real_y = d.y - d.y % this.GRID_STEP + this.GRID_STEP
    } else {
      real_y = d.y - d.y % this.GRID_STEP
    }

    if (d.x < 0) {
      real_x = 0
    }
    if (d.y < 0) {
      real_y = 0
    }

    this.setState({
      manipulating: false,
      x: real_x,
      y: real_y
    })

    const { x, y } = this.state
    const {
      widgetCommunication,
      configuration: { id }
    } = this.props

    widgetCommunication.onDrag(id, parseInt(x, 10), parseInt(y, 10))
  }

  enableResizing = () => {
    const { editing } = this.props
    return {
      bottom: false,
      bottomLeft: editing,
      bottomRight: editing,
      left: false,
      right: false,
      top: false,
      topLeft: editing,
      topRight: editing
    }
  }

  handleResizeStart = (e, d) => {
    this.setState({
      manipulating: true
    })
  }

  handleResizeStop = (e, d) => {
    this.setState({
      manipulating: false
    })

    const {
      widgetCommunication,
      configuration: { id }
    } = this.props
    const { height, width, x, y } = this.state

    widgetCommunication.onResize(id, height, width, x, y)
  }

  handleResize = (e, direction, ref, delta, position) => {
    this.setState({
      height: ref.style.height,
      width: ref.style.width,
      x: position.x,
      y: position.y
    })
  }

  resizeHandleClasses = () => {
    const { editing } = this.props
    return {
      topLeft: editing ? 'PD-widget_resizeHandler_topLeft' : '',
      topRight: editing ? 'PD-widget_resizeHandler_topRight' : '',
      bottomLeft: editing ? 'PD-widget_resizeHandler_bottomLeft' : '',
      bottomRight: editing ? 'PD-widget_resizeHandler_bottomRight' : ''
    }
  }

  handleWidgetZIndexPlus = event => {
    event.preventDefault()
    event.stopPropagation()
    const {
      widgetCommunication,
      configuration: { id }
    } = this.props
    widgetCommunication.onZIndexPlus(id)
    return false
  }

  handleWidgetZIndexMinus = event => {
    event.preventDefault()
    event.stopPropagation()
    const {
      widgetCommunication,
      configuration: { id }
    } = this.props
    widgetCommunication.onZIndexMinus(id)

    return false
  }

  // DELETE WIDGET
  handleOpenDeleteWidgetModal = () => {
    this.setState({
      deleteWidgetModal: true
    })
  }

  closeDeleteWidgetModal = () => {
    this.setState({
      deleteWidgetModal: false
    })
  }

  deleteWidget = () => {
    const {
      widgetCommunication,
      configuration: { id }
    } = this.props
    widgetCommunication.onDelete(id)
  }

  closeSettings = () => {
    this.setState({
      editWidget: false
    })
  }

  saveSettings = (data, devicesEids, deviceInfo) => {
    this.setState({
      editWidget: false
    })

    const {
      widgetCommunication,
      configuration: { id, content }
    } = this.props

    if (content.widgetType === 'line' && data.type !== content.params.type) {
      let width, height
      if (data.type === 'horizontal') {
        width = 200
        height = 10
      } else {
        height = 200
        width = 10
      }
      this.setState(
        {
          height,
          width
        },
        () => {
          const { height, width, x, y } = this.state
          widgetCommunication.onResize(id, height, width, x, y)
        }
      )
    }
    widgetCommunication.onSettingsChange(id, data, devicesEids, deviceInfo)
  }

  handleOpenCloneWidgetModal = () => {
    this.setState({
      cloneWidgetModal: true
    })
  }

  closeCloneWidgetModal = () => {
    this.setState({
      cloneWidgetModal: false
    })
  }

  subscribeWidgetToTopic = () => {
    const { configuration, eids, subscribeWidgetToWS } = this.props
    const widgetType = configuration.content.widgetType
    const lengthOfBits = configuration.content.params.lengthOfBits

    if (
      this.dynamicWidgetTypes.includes(widgetType) ||
      this.booleanWidgetTypes.includes(widgetType) && (typeof lengthOfBits === 'undefined' || lengthOfBits === 1)
    ) {
      const eid = eids[0]
      const topic = process.env.REACT_APP_TOPIC + 'm' + eid.replaceAll(':', '') + '/u/ds'
      subscribeWidgetToWS(topic, eid)
    }
  }

  cloneWidget = () => {
    const { configuration, widgetCommunication } = this.props
    const data = configuration.content.params.data
    const widgetTemplate = cloneDeep({ ...configuration, x: 20, y: 20 })
    delete widgetTemplate.id
    widgetCommunication.onClone(widgetTemplate)
    if (data !== '') this.subscribeWidgetToTopic()
  }

  renderWidget = () => {
    const { configuration, eids, editing } = this.props
    const { height, width } = this.state
    const widgetType = configuration.content.widgetType
    let component
    let settings
    const widgetHeight = typeof height === 'number' ? height : parseInt(height.slice(0, -2))
    const widgetWidth = typeof width === 'number' ? width : parseInt(width.slice(0, -2))
    const eid = eids[0] ? eids[0] : ''

    switch (widgetType) {
      case 'box':
        component = <availableWidgets.Box data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.BoxSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'text':
        component = <availableWidgets.Text data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.TextSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'image':
        component = <availableWidgets.Image data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.ImageSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'realtimevalue':
        component = <availableWidgets.RealTimeValue data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.RealTimeValueSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'value':
        component = <availableWidgets.Value data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.ValueSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'speedometer':
      case 'gauge':
        component = (
          <availableWidgets.Gauge
            data={configuration.content.params}
            editing={editing}
            eid={eid}
            height={widgetHeight}
            width={widgetWidth}
          />
        )
        settings = (
          <availableWidgets.GaugeSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'columnchart':
        component = <availableWidgets.ColumnChart data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.ColumnChartSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'linechart':
        component = <availableWidgets.LineChart data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.LineChartSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break
      case 'link':
        component = <availableWidgets.Link data={configuration.content.params} />
        settings = (
          <availableWidgets.LinkSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'line':
        component = <availableWidgets.Line data={configuration.content.params} />
        settings = (
          <availableWidgets.LineSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'historic':
        component = (
          <availableWidgets.Historic
            data={configuration.content.params}
            editing={editing}
            eid={eid}
            height={this.state.height}
            width={this.state.width}
          />
        )
        settings = (
          <availableWidgets.HistoricSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'map':
        component = <availableWidgets.Map data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.MapSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'video':
        component = <availableWidgets.Video data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.VideoSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'weather':
        component = (
          <availableWidgets.Weather data={configuration.content.params} editing={editing} eid={eid} width={width} />
        )
        settings = (
          <availableWidgets.WeatherSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'embeddedweb':
        component = <availableWidgets.EmbeddedWeb data={configuration.content.params} eid={eid} />
        settings = (
          <availableWidgets.EmbeddedWebSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            eid={eid}
            saveSettings={this.saveSettings}
          />
        )
        break

      case 'table':
        component = (
          <availableWidgets.Table
            data={configuration.content.params}
            editing={editing}
            eids={eids}
            height={widgetHeight}
            width={widgetWidth}
          />
        )
        settings = (
          <availableWidgets.TableSettings
            closeSettings={this.closeSettings}
            data={configuration.content.params}
            editing={editing}
            eids={eids}
            saveSettings={this.saveSettings}
          />
        )
        break

      default:
        component = <availableWidgets.Undefined />
        settings = null
    }

    return { component, settings }
  }

  xPosition = () => {
    const { containerWidth, editing, dashboardWidth, dashboardZoom } = this.props
    const { x } = this.state
    let xValue = x
    let reductionFactor = 0

    if (dashboardZoom && dashboardWidth) {
      if (dashboardZoom === 'fit' && editing === false) {
        //if (width >= containerWidth) {
        reductionFactor = containerWidth / dashboardWidth
        xValue = x * reductionFactor
        //}
      }
    }

    return xValue
  }

  yPosition = () => {
    const { containerWidth, editing, dashboardWidth, dashboardZoom } = this.props
    const { y } = this.state
    let yValue = y

    if (dashboardZoom && dashboardWidth) {
      if (dashboardZoom === 'fit' && editing === false) {
        const reductionFactor = containerWidth / dashboardWidth
        yValue = y * reductionFactor
      }
    }

    return yValue
  }

  render() {
    const { manipulating, x, y, width, height, editWidget, deleteWidgetModal, cloneWidgetModal } = this.state
    const { editing, containerWidth, configuration, dashboardZoom, dashboardWidth } = this.props
    const { minHeight, minWidth, maxHeight, maxWidth } = configuration
    return (
      <Fragment>
        <Rnd
          className={'PD-widget' + (manipulating ? ' PD-widget_manipulating' : '') + (editing ? ' PD-edit' : '')}
          disableDragging={!editing}
          dragGrid={[this.GRID_STEP, this.GRID_STEP]}
          dragHandleClassName='PD-widget_dragHandler'
          enableResizing={this.enableResizing()}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minWidth={minWidth}
          onDragStart={this.handleDragStart}
          // bounds="parent"
          onDragStop={this.handleDragStop}
          onResize={this.handleResize}
          onResizeStart={this.handleResizeStart}
          onResizeStop={this.handleResizeStop}
          position={{ x, y }}
          resizeGrid={[this.GRID_STEP, this.GRID_STEP]}
          scale={!editing && dashboardZoom === 'fit' ? containerWidth / dashboardWidth : 1}
          size={{ width, height }}
          style={{
            zIndex: configuration.zIndex,
            ...!editing && { touchAction: 'auto' }
          }}
          // resizeHandleWrapperClass={'PD-widget_resizeHandler'}
          // resizeHandleClasses={this.resizeHandleClasses()}
        >
          {this.renderWidget().component}

          <span className={editing ? 'PD-widget_dragHandler' : ''} title={this.formatMessage(messages.moveWidget)} />
          <span
            className={editing ? 'PD-widget_config' : 'PD-widget_config-hidden'}
            onClick={this.handleWidgetSettingsOpen}
            title={this.formatMessage(messages.widgetConfiguration)}
          />
          <span
            className={editing ? 'PD-widget_zindex' : 'PD-widget_zindex-hidden'}
            title={this.formatMessage(messages.widgetDepth)}
          >
            <span
              className='PD-widget_zindex-plus'
              onClick={this.handleWidgetZIndexPlus}
              title={this.formatMessage(messages.widgetUp)}
            >
              &#9652;
            </span>
            {configuration.zIndex}
            <span
              className='PD-widget_zindex-minus'
              onClick={this.handleWidgetZIndexMinus}
              title={this.formatMessage(messages.widgetDown)}
            >
              &#9662;
            </span>
          </span>
          <span
            className={editing ? 'PD-widget_duplicate' : 'PD-widget_duplicate-hidden'}
            onClick={this.handleOpenCloneWidgetModal}
            title={this.formatMessage(messages.cloneWidget)}
          >
            <Icon className='zmdi zmdi-plus-circle-o-duplicate' fontSize='inherit' />
          </span>
          <span
            className={editing ? 'PD-widget_delete' : 'PD-widget_delete-hidden'}
            onClick={this.handleOpenDeleteWidgetModal}
            title={this.formatMessage(messages.deleteWidget)}
          >
            &#8855;
          </span>
        </Rnd>
        {editWidget ? this.renderWidget().settings : null}

        <DeleteWidget
          closeDeleteWidgetModal={this.closeDeleteWidgetModal}
          deleteWidget={this.deleteWidget}
          deleteWidgetModal={deleteWidgetModal}
          widgetData={configuration}
        />

        <CloneWidget
          cloneWidget={this.cloneWidget}
          cloneWidgetModal={cloneWidgetModal}
          closeCloneWidgetModal={this.closeCloneWidgetModal}
          widgetData={configuration}
        />
      </Fragment>
    )
  }
}

Widget.propTypes = {
  configuration: PropTypes.object.isRequired,
  containerWidth: PropTypes.number.isRequired,
  dashboardWidth: PropTypes.number,
  dashboardZoom: PropTypes.string,
  editing: PropTypes.bool.isRequired,
  eids: PropTypes.array,
  intl: PropTypes.object.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  widgetCommunication: PropTypes.object.isRequired
}

Widget.defaultProps = {
  dashboardWidth: 0,
  dashboardZoom: '',
  eids: []
}

export default injectIntl(Widget)
