import * as t from '../actionTypes'
import { MOUNTED_AT as ROOT_MOUNT } from '../constants'

const initialStatus = {
  plotting: false,
  lastUpdate: 0 // To avoid updating the chart too frequently
}

const reducer = (state = initialStatus, action) => {
  if (action) {
    switch (action.type) {
      case t.START_PLOTTING:
        return { ...state, plotting: true}

      case t.FINISH_PLOTTING:
        return { ...state, plotting: false,
          lastUpdate: action.payload.time}

      default:
    }
  }

  return state
}

export { reducer }
export const MOUNTED_AT = 'status'

// Selectors
const getSelector = substate => substate[ROOT_MOUNT][MOUNTED_AT]
const isPlotting = substate => getSelector(substate).plotting
const hasBeenUpdatedAfter = (substate, comparisonDate) =>
  comparisonDate < getSelector(substate).lastUpdate

export { isPlotting, hasBeenUpdatedAfter }
