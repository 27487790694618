import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import InformationIcon from '@material-ui/icons/Info'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Grid from '@material-ui/core/Grid'
import DialogContentText from '@material-ui/core/DialogContentText'
import Tooltip from '@material-ui/core/Tooltip'

import messages from './messages'

const styles = {
  dashboard: {
    cursor: 'move',
    paddingLeft: 0
  },
  dragAvatar: {
    display: 'flex',
    marginRight: -10
  },
  dropzone: {
    backgroundColor: '#cccccc',
    color: '#ffffff'
  }
}

class DashboardFilesList extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleDragStart = (event, fileIndex) => {
    event.target.style.opacity = 0.4
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', fileIndex)
  }

  handleDragEnd = event => {
    event.target.style.opacity = 1
  }

  handleDragOver = event => {
    event.preventDefault()
    event.dataTransfer.effectAllowed = 'move'
    event.stopPropagation()
  }

  handleDrop = (event, fileIndex) => {
    const { onChangeFileIndex } = this.props
    const oldIndex = parseInt(event.dataTransfer.getData('text/plain'))
    if (!isNaN(oldIndex) && typeof fileIndex === 'number' && fileIndex !== oldIndex) {
      onChangeFileIndex(oldIndex, fileIndex)
    }
  }

  render() {
    const { classes, files, onRemoveFile } = this.props
    return (
      <Grid container>
        <Grid item xs={12}>
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ fontWeight: 'bold' }}>{this.formatMessage(messages.importingDashboards)}</span>
            <Tooltip arrow placement='top-start' title={this.formatMessage(messages.tooltipMessage)}>
              <span>
                <IconButton disabled style={{ padding: '0px 3px 10px' }}>
                  <InformationIcon style={{ fontSize: 15 }} />
                </IconButton>
              </span>
            </Tooltip>
          </DialogContentText>
        </Grid>
        <Grid item xs={8}>
          <List dense>
            {files.map(({ metadata }, index) => (
              <ListItem
                key={index}
                className={classes.dashboard}
                draggable
                id={index}
                onDragEnd={this.handleDragEnd}
                onDragOver={this.handleDragOver}
                onDragStart={e => this.handleDragStart(e, index)}
                onDrop={e => this.handleDrop(e, index)}
              >
                <ListItemAvatar className={classes.dragAvatar} draggable={false}>
                  <DragHandleIcon />
                </ListItemAvatar>
                <ListItemText draggable={false} primary={metadata.fileName} />
                <ListItemSecondaryAction draggable={false}>
                  <IconButton
                    aria-label='delete'
                    edge='end'
                    onClick={() => {
                      onRemoveFile(index)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    )
  }
}

DashboardFilesList.propTypes = {
  classes: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  onChangeFileIndex: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired
}

export default withStyles(styles)(DashboardFilesList)
