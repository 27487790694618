import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { utils } from 'ducks/groups'
import { actions } from 'ducks/dataPlans'

import ShareDataPlans from './ShareDataPlans'

const mapStateToProps = (state, { match, history }) => ({
  getGroup: groupId => utils.getCurrentGroup(state, groupId),
  onShareDataPlans: (name, number) => {
    history.replace(match.path, { action: 'dataPlansShared', name, number })
  },
  getChildGroups: groupId => utils.getChildGroups(state, groupId)
})

const mapDispatchToProps = dispatch => ({
  shareDataPlans: (targetGroupId, body) => dispatch(actions.shareDataPlans(targetGroupId, body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareDataPlans))
