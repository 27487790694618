import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DefaultButton from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

const DropdownMenu = ({
  children,
  component = DefaultButton,
  disablePortal = false,
  icon,
  iconOpened,
  propagateClose = false,
  staticContext,
  style,
  ...other
}) => {
  const [anchorEl, setAnchor] = useState(null)
  const opened = anchorEl !== null
  const Button = component

  const close = () => setAnchor(null)
  const toggleOpen = e => {
    if (opened) {
      setAnchor(null)
    } else {
      setAnchor(e.currentTarget)
    }
    //e.preventDefault()
  }

  // It is important to use "onMouseUp" instead of "onClick" to prevent event
  // propagation to ClickAwayListener.
  return (
    <Fragment>
      <Button
        aria-haspopup="true"
        aria-label="More"
        aria-owns={opened ? 'settings-menu' : undefined}
        onMouseUp={toggleOpen}
        style={style}
        {...other}
      >
        {opened && iconOpened ? iconOpened : icon}
      </Button>
      <Popper anchorEl={anchorEl} disablePortal={disablePortal} open={opened} transition={true}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={close}>
              <Paper>{propagateClose ? children({ onDropdownClose: close }) : children}</Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

DropdownMenu.propTypes = {
  component: PropTypes.any,
  disablePortal: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  iconOpened: PropTypes.node
}

export default DropdownMenu
