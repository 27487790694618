import { defineMessages } from 'react-intl'

const messages = defineMessages({
  errorUndefinedTitle: {
    id: 'UpdateFirmware.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'UpdateFirmware.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error400Message: {
    id: 'UpdateFirmware.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'UpdateFirmware.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'UpdateFirmware.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'UpdateFirmware.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error409Message: {
    id: 'UpdateFirmware.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Report name it\'s already in use'
  },
  error415Message: {
    id: 'UpdateFirmware.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'UpdateFirmware.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'UpdateFirmware.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'UpdateFirmware.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  successfulFirmwareMessage: {
    id: 'UpdateFirmware.successfulFirmwareMessage',
    description: 'Firmware update process started successfully',
    defaultMessage: 'Firmware update process started successfully. See more details in \'Machine details\' section.'
  },
  cancel: {
    id: 'UpdateFirmware.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  apply: {
    id: 'UpdateFirmware.apply',
    description: 'Apply',
    defaultMessage: 'Apply'
  },
  thereAreNoAvailableFirmwares: {
    id: 'UpdateFirmware.thereAreNoAvailableFirmwares',
    description: 'There are no available firmwares',
    defaultMessage: 'There are no available firmwares'
  },
  warningDowngrade: {
    id: 'UpdateFirmware.cautionDowngrade',
    description: 'WARNING! Attempting to downgrade firmware version may cause irreparable damage to the device',
    defaultMessage: 'WARNING! Attempting to downgrade firmware\'s version may cause irreparable damage to the device.'
  },
  notAllowedToApplyFirmware: {
    id: 'UpdateFirmware.notAllowedToApplyFirmware',
    description: 'You don\'t have enough permissions to apply a firmware to the device.',
    defaultMessage: 'You don\'t have enough permissions to apply a firmware to the device.'
  },
  selectedDevice: {
    id: 'UpdateFirmware.selectedDevice',
    description: 'Selected device text',
    defaultMessage: 'Selected device: '
  },
  activeFirmware: {
    id: 'UpdateFirmware.activeFirmware',
    description: 'Active firmware text',
    defaultMessage: 'Active firmware: '
  },
  readMore: {
    id: 'UpdateFirmware.readMore',
    description: 'Read more text',
    defaultMessage: '...Read more'
  },
  readLess: {
    id: 'UpdateFirmware.readLess',
    description: 'Read less text',
    defaultMessage: 'Read less'
  }
})

export default messages
