import { defineMessages } from 'react-intl'
 
const messages = defineMessages({
  title: {
    id: 'Callback.title',
    description: 'Temporarily down for maintenance title',
    defaultMessage: 'Temporarily down for maintenance.'
  },
  subtitle: {
    id: 'Callback.subtitle',
    description: 'Temporarily down for maintenance subtitle',
    defaultMessage: 'We are performing scheduled maintenance. We should be back online shortly.'
  },
  return: {
    id: 'Callback.return',
    description: 'Go back to login page button',
    defaultMessage: 'Go back to login page'
  }
})
 
export default messages