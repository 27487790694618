import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

// Destructure props like so, and use default arguments as a way of setting defaultProps
const DateLabel = ({ date = '', format = 'DD-MMM-YYYY HH:mm:ss' }) => {
  if (date !== undefined && moment(date).isValid()) {
    return <span>{moment(date).format(format)}</span>
  } else {
    return <span>-</span>
  }
}

DateLabel.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string
}

export default DateLabel
