import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import MaintenanceConfiguration from './MaintenanceConfiguration'
import MaintenanceDetail from './MaintenanceDetail'
import MaintenanceInspectionHistory from './MaintenanceInspectionHistory'
import Maintenances from './Maintenances'
import { MAINTENANCE_CONFIGURATION_MODES } from './constants'
import {
  MAINTENANCES_TEMPLATE_URL,
  MAINTENANCES_NEW_TEMPLATE_URL,
  MAINTENANCES_EDIT_TEMPLATE_URL,
  MAINTENANCES_DETAIL_TEMPLATE_URL,
  MAINTENANCES_INSPECTION_HISTORY_TEMPLATE_URL
} from './urls'

const MaintenancesHome = props => {
  const { canCreateNotifications, canEditNotifications, canReadNotifications } = props
  return (
    <Switch>
      {canReadNotifications && <Route component={Maintenances} exact path={MAINTENANCES_TEMPLATE_URL} />}
      {canCreateNotifications && (
        <Route
          exact
          path={MAINTENANCES_NEW_TEMPLATE_URL}
          render={allProps => (
            <MaintenanceConfiguration
              mode={MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION}
              {...allProps}
            />
          )}
        />
      )}
      {canReadNotifications && <Route component={MaintenanceDetail} exact path={MAINTENANCES_DETAIL_TEMPLATE_URL} />}
      {canEditNotifications && (
        <Route
          exact
          path={MAINTENANCES_EDIT_TEMPLATE_URL}
          render={allProps => (
            <MaintenanceConfiguration
              mode={MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION}
              {...allProps}
            />
          )}
        />
      )}
      {canReadNotifications && (
        <Route component={MaintenanceInspectionHistory} exact path={MAINTENANCES_INSPECTION_HISTORY_TEMPLATE_URL} />
      )}
    </Switch>
  )
}

MaintenancesHome.propTypes = {
  canCreateNotifications: PropTypes.bool.isRequired,
  canEditNotifications: PropTypes.bool.isRequired,
  canReadNotifications: PropTypes.bool.isRequired
}

export default MaintenancesHome
