import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import messages from '../../../messages'
import { alertStyles, iconButtonStyles } from '../../../styles'

const styles = {
  ...alertStyles,
  ...iconButtonStyles
}

class SaveDeviceSucessAlert extends Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {}
  }

  render() {
    const { classes } = this.props

    return (
      <Alert classes={{ message: classes.alertMessage }} severity='success'>
        <AlertTitle classes={{ root: classes.alertTitle }}>{this.formatMessage(messages.success)}</AlertTitle>
        {this.formatMessage(messages.deviceSuccessfullySaved)}
      </Alert>
    )
  }
}

SaveDeviceSucessAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(SaveDeviceSucessAlert))
