import { defineMessages } from 'react-intl'

const namespace = 'LastKnownValueTableSettings'

const messages = defineMessages({
  loadingConfigurations: {
    id: `${namespace}.loadingConfigurations`,
    description: 'Loading configurations',
    defaultMessage: 'Loading configurations...'
  },
  configurations: {
    id: `${namespace}.configurations`,
    description: 'Configurations',
    defaultMessage: 'Configurations'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  machines: {
    id: `${namespace}.machines`,
    description: 'Machines',
    defaultMessage: 'Machines'
  },
  columns: {
    id: `${namespace}.columns`,
    description: 'Columns',
    defaultMessage: 'Columns'
  },
  allMachines: {
    id: `${namespace}.allMachines`,
    description: 'All machines',
    defaultMessage: 'All machines'
  },
  machineFields: {
    id: `${namespace}.machineFields`,
    description: 'Machine fields',
    defaultMessage: 'Machine fields'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name',
    defaultMessage: 'Name'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID',
    defaultMessage: 'EID'
  },
  allMachinesSelected: {
    id: `${namespace}.allMachinesSelected`,
    description: 'All machines selected',
    defaultMessage: 'All machines selected*'
  },
  machinesHelperText: {
    id: `${namespace}.machinesHelperText`,
    description: 'Machines selector helper text',
    defaultMessage: '*Machines with the same configuration added in the future will also appear in the table'
  },
  allSignals: {
    id: `${namespace}.allSignals`,
    description: 'All signals checkbox label',
    defaultMessage: 'All signals'
  }
})

export default messages
