import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'AssignUsersToNotification.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'AssignNodesToGroup.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error400Text: {
    id: 'AssignUsersToNotification.error400Text',
    description: 'Error 400 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error404Text: {
    id: 'AssignUsersToNotification.error404Text',
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: 'AssignUsersToNotification.error406Text',
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'AssignUsersToNotification.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: 'AssignUsersToNotification.errorUndefinedText',
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  errorText: {
    id: 'AssignUsersToNotification.errorText',
    description: 'Error title',
    defaultMessage: 'Error'
  },
  errorLoadingNotification: {
    id: 'AssignUsersToNotification.errorLoadingNotification',
    description: 'Error loading notification description',
    defaultMessage: 'It was not possible to load the requested notification.'
  },
  errorRequestingUsers: {
    id: 'AssignUsersToNotification.errorRequestingUsers',
    description: 'Error requesting users',
    defaultMessage: 'Error requesting users'
  },
  title: {
    id: 'AssignUsersToNotification.title',
    description: 'Title text',
    defaultMessage: 'Assign users to notification'
  },
  return: {
    id: 'AssignUsersToNotification.return',
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  otherError400Text: {
    id: 'AssignUsersToNotification.otherError400Text',
    description: 'Other error 400 text',
    defaultMessage: 'Bad request'
  },
  error401Text: {
    id: 'AssignUsersToNotification.error401Text',
    description: 'Error 401 text',
    defaultMessage: 'Access denied'
  },
  error403Text: {
    id: 'AssignUsersToNotification.error403Text',
    description: 'Error 403 text',
    defaultMessage: 'Access denied'
  },
  error500Text: {
    id: 'AssignUsersToNotification.error500Text',
    description: 'Error 500 text',
    defaultMessage: 'Unexpected error'
  },
  otherError404Text: {
    id: 'AssignUsersToNotification.otherError404Text',
    description: 'Other error 404 text',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  error409Text: {
    id: 'AssignUsersToNotification.error409Text',
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation.'
  },
  error422Text: {
    id: 'AssignUsersToNotification.error422Text',
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  error500ToSHow: {
    id: 'AssignUsersToNotification.error500ToSHow',
    description: 'Error 500 to show message',
    defaultMessage: 'Server error'
  },
  yes: {
    id: 'AssignUsersToNotification.yes',
    description: 'Yes enabled value',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'AssignUsersToNotification.no',
    description: 'No enabled value',
    defaultMessage: 'No'
  },
  selectedUsers: {
    id: 'AssignUsersToNotification.selectedUsers',
    description: 'Selected users button label',
    defaultMessage: 'Selected users'
  },
  clearSelection: {
    id: 'AssignUsersToNotification.clearSelection',
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  assignUsers: {
    id: 'AssignUsersToNotification.assignUsers',
    description: 'Assign users button label',
    defaultMessage: 'Assign users'
  },
  showingRows: {
    id: 'AssignUsersToNotification.showingRows',
    description: 'Assign users total text showing rows',
    defaultMessage: 'Showing users'
  },
  to: {
    id: 'AssignUsersToNotification.to',
    description: 'Assign users table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'AssignUsersToNotification.of',
    description: 'Assign users table total text of',
    defaultMessage: 'of'
  },
  noUsersUnassigned: {
    id: 'AssignUsersToNotification..noUsersUnassigned',
    description: 'No users unassigned message',
    defaultMessage: 'No users unassigned'
  },
  prePage: {
    id: 'AssignUsersToNotification..prePage',
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'AssignUsersToNotification.nextPage',
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  placeholder: {
    id: 'AssignUsersToNotification.placeholder',
    description: 'Table header column filter placeholder',
    defaultMessage: 'Enter'
  },
  firstPage: {
    id: 'AssignUsersToNotification.firstPage',
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'AssignUsersToNotification.lastPage',
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  filterByOrderableColumns: {
    id: 'AssignUsersToNotification.filterByOrderableColumns',
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  firstName: {
    id: 'AssignUsersToNotification.firstName',
    description: 'First name table header column label',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'AssignUsersToNotification.lastName',
    description: 'Last name table header column label',
    defaultMessage: 'Last name'
  },
  email: {
    id: 'AssignUsersToNotification.email',
    description: 'Email table header column label',
    defaultMessage: 'Email'
  },
  smsService: {
    id: 'AssignUsersToNotification.smsService',
    defaultMessage: 'SMS Service'
  },
  enabled: {
    id: 'AssignUsersToNotification.enabled',
    description: 'Enabled table header column label',
    defaultMessage: 'Enabled'
  },
  notify: {
    id: 'AssignUsersToNotification.notify',
    description: 'Notify table header column label',
    defaultMessage: 'Notify'
  },
  allPosibleUsersAreAssigned: {
    id: 'AssignUsersToNotification.allPosibleUsersAreAssigned',
    description: 'All possible users are assigned message',
    defaultMessage: 'All possible users are assigned.'
  },
  usersAssignementError: {
    id: 'AssignUsersToNotification.usersAssignementError',
    description: 'Users assignement error description',
    defaultMessage: 'An error occurred while trying to assign the selected users.'
  }
})

export default messages
