import React from 'react'
import PropTypes from 'prop-types'

import grey from '@material-ui/core/colors/grey'
import IconButton from '@material-ui/core/IconButton'
import AcknowledgedIcon from '@material-ui/icons/PersonPinCircle'
import PlaceIcon from '@material-ui/icons/Place'

import memoize from 'lodash/memoize'
import { withStyles } from '@material-ui/core/styles'

import { iconTooltip, selectedMarker } from './style.module.css'

const ColouredIconWrapper = ({
  label = '',
  name = '',
  size,
  isSelected = false,
  acknowledged = false,
  onClick,
  classes,
  children,
  ...other
}) => {
  let editedChildren = <PlaceIcon /> // Default icon
  if (acknowledged) {
    editedChildren = <AcknowledgedIcon />
  } else if (children) {
    editedChildren = children
  }

  const wh = { width: size, height: size }

  const icon = React.Children.map(editedChildren, child =>
    React.cloneElement(child, {
      ...other,
      size,
      style: wh,
      className: isSelected ? selectedMarker : undefined
    })
  )

  if (onClick) {
    return (
      <IconButton classes={classes} name={name + '_marker'} onClick={() => onClick(name)} style={{ padding: 0, ...wh }}>
        <span className={iconTooltip} title={label}>
          {icon}
        </span>
      </IconButton>
    )
  } else {
    return (
      <IconButton classes={classes} name={name + '_marker'} onClick={() => {}} style={{ padding: 0, ...wh }}>
        <span className={iconTooltip} title={label}>
          {icon}
        </span>
      </IconButton>
    )
  }

  //return icon
}

ColouredIconWrapper.propTypes = {
  acknowledged: PropTypes.bool,
  isSelected: PropTypes.bool,
  size: PropTypes.number
}

// So mui-v3 does not create infinite CSS classes for the same definition
const getColorStyle = memoize((color, hoverColor, component) =>
  withStyles({
    root: {
      color,
      '&:hover': {
        color: hoverColor
      }
    },
    label: {
      height: '100%'
    }
  })(component)
)

const IconWrapper = ({ color = grey[600], hoverColor = grey[400], ...other }) => {
  const ColouredIcon = getColorStyle(color, hoverColor, ColouredIconWrapper)
  return <ColouredIcon {...other} />
}

IconWrapper.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string
}

export { IconWrapper }
