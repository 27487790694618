import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import QueryBuilder from 'react-querybuilder'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'

import Loading from 'components/Loading'
import PageSubTitle from 'components/PageSubTitle'

import NotificationsRulesSettingsStepper from '../../NotificationsRulesSettingsStepper'
import * as c from '../../constants'
import {
  getAvailableCanBusSignals,
  getAvailableGpsSignals,
  getQueryBuilderSignalOptions,
  getAvailableDm1Signals,
  getAvailableOperators,
  getQueryAsRsqlExpression
} from '../../utils'
import messages from '../messages'
import ErrorAlert from './ErrorAlert'
import SuccessAlert from './SuccessAlert'

import { client, logError } from 'utils/http'

class EditNotificationFormConditionalSignals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      availableRules: [],
      configurationFile: {},
      error: {},
      isAllDm1MessagesCheckboxChecked: false,
      isDevicesLoading: false,
      isErrorAlertShown: false,
      isRulesLoading: false,
      isSavingLoading: false,
      isSuccessAlertShown: false,
      originalQuery: {},
      queryAsRsqlExpression: '',
      selectedRuleTypeOption: ''
    }

    const {
      intl: { formatMessage }
    } = this.props
    this.formatMessage = formatMessage

    this.ruleTypeOptions = {
      CAN_BUS: 'CAN_BUS',
      GPS: 'GPS',
      DM1: 'DM1'
    }
  }

  componentDidMount() {
    this.fetchRulesData()
    this.fetchDeviceConfigurationFile()
  }

  fetchRulesData = () => {
    const { notification } = this.props

    this.setState({ isErrorAlertShown: false, isRulesLoading: true })
    client
      .getRules(notification.groupId)
      .then(response => {
        const availableRules = response.data

        const ruleHashId = notification.ruleHashId
        const { name: selectedRuleName = '' } = availableRules.find(rule => rule.hashId === ruleHashId) || {}
        const [ruleType] = Object.entries(c.RULE_TYPE_NAMES).find(([key, value]) => value === selectedRuleName)
        const selectedRuleTypeOption = this.getRuleTypeOption(ruleType)
        const isAllDm1MessagesCheckboxChecked = ruleType === c.RULE_TYPES.DM1_ALL_MESSAGES

        this.setState({
          availableRules,
          isAllDm1MessagesCheckboxChecked,
          selectedRuleTypeOption
        })
      })
      .catch(error => {
        this.setState({ error, isErrorAlertShown: true })
        logError(error)
      })
      .finally(() => {
        this.setState({ isRulesLoading: false })
      })
  }

  fetchDeviceConfigurationFile = () => {
    const { fetchDeviceNonVolatileConfiguration, getDevicesDetail, notification } = this.props

    if (notification.devices.length === 0) return

    //All devices assigned to the notification must have the same configuration file
    const deviceEidToGetConfigurationFile = notification.devices[0].eid
    const groupId = notification.groupId

    this.setState({ isDevicesLoading: true })
    getDevicesDetail(groupId, [deviceEidToGetConfigurationFile])
      .then(response => {
        if (response?.data?.devices?.length > 0) {
          const currentDevice = response.data.devices[0]

          let configurationFilePromise = null
          if (currentDevice.device_type === 'CS500') {
            configurationFilePromise = fetchDeviceNonVolatileConfiguration(groupId, currentDevice.EID)
              .then(cs500ConfigurationResponse => {
                const cs500Configuration = cs500ConfigurationResponse[currentDevice.EID]
                return cs500Configuration?.parsedConfiguration?.deviceConfiguration
              })
              .catch(error => {
                return {}
              })
          } else {
            configurationFilePromise = Promise.resolve(currentDevice.Configuration)
          }

          return configurationFilePromise.then(configurationFile => this.setState({ configurationFile }))
        }
      })
      .catch(error => {
        logError(error)

        this.setState({ error, isErrorAlertShown: true, isSuccessAlertShown: false })
      })
      .finally(() => {
        this.setState({ isDevicesLoading: false })
      })
  }

  fetchRuleDetails = () => {
    const { availableRules } = this.state

    const ruleType = this.getRuleType()

    const ruleName = c.RULE_TYPE_NAMES[ruleType]
    const { hashId: ruleHashId } = availableRules.find(rule => rule.name === ruleName) || {}

    return client
      .getRule(ruleHashId)
      .then(response => {
        const ruleDetail = response.data
        return ruleDetail
      })
      .catch(error => {
        logError(error)
        this.setState({ error, isErrorAlertShown: true, isSuccessAlertShown: false })
      })
  }

  fetchNotificationVersion = notificationHashId => {
    /*
        Get the correct notification version before we update it.
        When a request to modify an instance is performed, a serie of actions are executed under the hood.
        These actions vary depending on the status of the notification and each of these actions modifies the "version" field.
        When we perform the request to modify the notification, we must send the same version number as the one on the database,
        otherwise, the request will fail.
      */
    return client
      .getRuleInstance(notificationHashId)
      .then(response => {
        if (response?.data?.version) {
          const notificationVersion = response.data.version
          return notificationVersion
        }
      })
      .catch(error => {
        logError(error)
        this.setState({ error, isErrorAlertShown: true, isSuccessAlertShown: false })
      })
  }

  getRuleTypeOption = ruleType => {
    switch (ruleType) {
      case c.RULE_TYPES.CAN_BUS:
        return this.ruleTypeOptions.CAN_BUS
      case c.RULE_TYPES.GPS:
        return this.ruleTypeOptions.GPS
      case c.RULE_TYPES.DM1_ALL_MESSAGES:
        return this.ruleTypeOptions.DM1
      case c.RULE_TYPES.DM1_SPECIFIC_MESSAGES:
        return this.ruleTypeOptions.DM1
      default:
        return ''
    }
  }

  getRuleType = () => {
    const { isAllDm1MessagesCheckboxChecked, selectedRuleTypeOption } = this.state

    if (selectedRuleTypeOption === this.ruleTypeOptions.CAN_BUS) {
      return c.RULE_TYPES.CAN_BUS
    } else if (selectedRuleTypeOption === this.ruleTypeOptions.GPS) {
      return c.RULE_TYPES.GPS
    } else if (selectedRuleTypeOption === this.ruleTypeOptions.DM1 && isAllDm1MessagesCheckboxChecked) {
      return c.RULE_TYPES.DM1_ALL_MESSAGES
    } else if (selectedRuleTypeOption === this.ruleTypeOptions.DM1 && !isAllDm1MessagesCheckboxChecked) {
      return c.RULE_TYPES.DM1_SPECIFIC_MESSAGES
    }
  }

  getAvailableCanBusSignals = () => {
    const { configurationFile } = this.state
    if (Object.keys(configurationFile).length === 0) return []

    const valueTypeTexts = {
      avg: this.formatMessage(messages.valueAvg),
      min: this.formatMessage(messages.valueMin),
      max: this.formatMessage(messages.valueMax)
    }

    const availableCanBusSignals = getAvailableCanBusSignals(configurationFile, valueTypeTexts)

    return availableCanBusSignals
  }

  getAvailableGpsSignals = () => {
    const { configurationFile } = this.state
    if (Object.keys(configurationFile).length === 0) return []

    const availableGpsSignals = getAvailableGpsSignals(configurationFile)

    return availableGpsSignals
  }

  getAvailableDm1Signals = () => {
    const { configurationFile } = this.state
    if (Object.keys(configurationFile).length === 0) return []

    const availableDm1Signals = getAvailableDm1Signals(configurationFile)

    return availableDm1Signals
  }

  handleRuleTypeChange = event => {
    const { isAllDm1MessagesCheckboxChecked } = this.state
    const value = event.target.value

    this.setState({
      selectedRuleTypeOption: value,
      isAllDm1MessagesCheckboxChecked: value === this.ruleTypeOptions.DM1 ? isAllDm1MessagesCheckboxChecked : false
    })
  }

  handleAllDm1MessagesCheckboxChange = (event, value) => {
    this.setState({ isAllDm1MessagesCheckboxChecked: value })
  }

  handleQueryChange = (ruleType, query) => {
    let availableSignals = []
    switch (ruleType) {
      case c.RULE_TYPES.CAN_BUS:
        availableSignals = this.getAvailableCanBusSignals()
        break
      case c.RULE_TYPES.GPS:
        availableSignals = this.getAvailableGpsSignals()
        break
      case c.RULE_TYPES.DM1_SPECIFIC_MESSAGES:
        availableSignals = this.getAvailableDm1Signals()
        break

      default:
        break
    }

    const rsqlExpression = getQueryAsRsqlExpression(query, availableSignals)

    this.setState({
      originalQuery: query,
      queryAsRsqlExpression: rsqlExpression
    })
  }

  handleSaveClick = () => {
    const { notification, onNotificationUpdate } = this.props
    const { originalQuery, queryAsRsqlExpression } = this.state

    const ruleType = this.getRuleType()

    if (ruleType !== c.RULE_TYPES.DM1_ALL_MESSAGES && queryAsRsqlExpression === '') {
      this.setState({ isErrorAlertShown: true, isSuccessAlertShown: false })
      window.scrollTo(0, 0)
    } else {
      this.setState({ error: {}, isErrorAlertShown: false, isSuccessAlertShown: false, isSavingLoading: true })

      this.fetchRuleDetails()
        .then(ruleDetails => {
          const { hashId: ruleHashId } = ruleDetails
          const { hashId: originalQueryVariableHashId } =
            ruleDetails.variables.find(variable => variable.name === c.RULE_VARIABLE_ORIGINAL_CONDITION_NAME) || {}
          const { hashId: rsqlExpressionVariableHashId } =
            ruleDetails.variables.find(variable => variable.name === c.RULE_VARIABLE_CONDITION_NAME) || {}

          const originalQueryVariables =
            notification.variables.find(variable => variable.name === c.RULE_VARIABLE_ORIGINAL_CONDITION_NAME) || {}
          const rsqlExpressionVariables =
            notification.variables.find(variable => variable.name === c.RULE_VARIABLE_CONDITION_NAME) || {}

          const variablesForNotification = [
            {
              ...rsqlExpressionVariables,
              ...ruleType !== c.RULE_TYPES.DM1_ALL_MESSAGES
                ? {
                  ruleVariableHashId: rsqlExpressionVariableHashId,
                  value: queryAsRsqlExpression
                }
                : {}
            },
            {
              ...originalQueryVariables,
              ...ruleType !== c.RULE_TYPES.DM1_ALL_MESSAGES
                ? {
                  ruleVariableHashId: originalQueryVariableHashId,
                  value: JSON.stringify(originalQuery)
                }
                : {}
            }
          ]

          const notificationForDatabase = {
            ...notification,
            ruleHashId,
            variables: variablesForNotification
          }
          return notificationForDatabase
        })
        .then(notificationForDatabase => {
          return this.fetchNotificationVersion(notification.hashId).then(notificationVersion => {
            return {
              ...notificationForDatabase,
              version: notificationVersion
            }
          })
        })
        .then(notificationForDatabase => {
          return client
            .modifyRuleInstance(notificationForDatabase)
            .then(response => {
              const updatedNotification = {
                ...notification,
                variables: response.data.variables,
                version: response.data.version
              }
              onNotificationUpdate(updatedNotification)
              this.setState({ isErrorAlertShown: false, isSuccessAlertShown: true })
            })
            .catch(error => {
              logError(error)

              this.setState({ error, isErrorAlertShown: true, isSuccessAlertShown: false })
            })
        })
        .finally(() => {
          this.setState({ isSavingLoading: false })
        })
    }
  }

  handleErrorAlertClose = () => {
    this.setState({ error: {}, isErrorAlertShown: false })
  }

  handleSuccessAlertClose = () => {
    this.setState({ isSuccessAlertShown: false })
  }

  render() {
    const { notificationsUrl, notification } = this.props
    const {
      configurationFile,
      error,
      isAllDm1MessagesCheckboxChecked,
      isDevicesLoading,
      isErrorAlertShown,
      isRulesLoading,
      isSavingLoading,
      isSuccessAlertShown,
      queryAsRsqlExpression,
      selectedRuleTypeOption
    } = this.state

    const availableCanBusSignals = this.getAvailableCanBusSignals()
    const availableGpsSignals = this.getAvailableGpsSignals()
    const availableDm1Signals = this.getAvailableDm1Signals()

    // TODO: We disable this until the back-end is able to handle rule type changes
    const isRuleTypeChangeDisabled = true

    return (
      <div className='container-fluid'>
        <NotificationsRulesSettingsStepper stepIndex={1} />
        <PageSubTitle title={this.formatMessage(messages.conditionalSignals)} />
        <ErrorAlert
          configurationFile={configurationFile}
          error={error}
          isQueryRsqlExpressionEmpty={queryAsRsqlExpression === ''}
          onClose={this.handleErrorAlertClose}
          show={isErrorAlertShown}
        />
        <SuccessAlert onClose={this.handleSuccessAlertClose} show={isSuccessAlertShown} />

        {isRulesLoading || isDevicesLoading ? (
          <Loading />
        ) : (
          <Paper style={{ padding: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl style={{ width: '250px' }}>
                  <InputLabel>Rule type</InputLabel>
                  <Select
                    disabled={isRuleTypeChangeDisabled}
                    onChange={this.handleRuleTypeChange}
                    value={selectedRuleTypeOption}
                  >
                    <MenuItem
                      key={this.ruleTypeOptions.CAN_BUS}
                      disabled={availableCanBusSignals.length === 0}
                      value={this.ruleTypeOptions.CAN_BUS}
                    >
                      CAN Bus
                    </MenuItem>
                    <MenuItem
                      key={this.ruleTypeOptions.GPS}
                      disabled={availableGpsSignals.length === 0}
                      value={this.ruleTypeOptions.GPS}
                    >
                      GPS
                    </MenuItem>
                    <MenuItem
                      key={this.ruleTypeOptions.DM1}
                      disabled={availableDm1Signals.length === 0}
                      value={this.ruleTypeOptions.DM1}
                    >
                      DM1
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {selectedRuleTypeOption === this.ruleTypeOptions.CAN_BUS && (
                  <QueryBuilder
                    fields={getQueryBuilderSignalOptions(availableCanBusSignals)}
                    onQueryChange={query => this.handleQueryChange(c.RULE_TYPES.CAN_BUS, query)}
                    operators={getAvailableOperators(c.RULE_TYPES.CAN_BUS)}
                    query={notification.originalCondition}
                  />
                )}
                {selectedRuleTypeOption === this.ruleTypeOptions.GPS && (
                  <QueryBuilder
                    fields={getQueryBuilderSignalOptions(availableGpsSignals)}
                    onQueryChange={query => this.handleQueryChange(c.RULE_TYPES.GPS, query)}
                    operators={getAvailableOperators(c.RULE_TYPES.GPS)}
                    query={notification.originalCondition}
                  />
                )}
                {selectedRuleTypeOption === this.ruleTypeOptions.DM1 && (
                  <React.Fragment>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAllDm1MessagesCheckboxChecked}
                          color='primary'
                          disabled={isRuleTypeChangeDisabled}
                          name='allDm1Messages'
                          onChange={this.handleAllDm1MessagesCheckboxChange}
                        />
                      }
                      label='Any DM1 message'
                    />
                    <div
                      style={{
                        opacity: isAllDm1MessagesCheckboxChecked ? 0.3 : 1,
                        pointerEvents: isAllDm1MessagesCheckboxChecked ? 'none' : 'auto'
                      }}
                    >
                      <QueryBuilder
                        fields={getQueryBuilderSignalOptions(availableDm1Signals)}
                        onQueryChange={query => this.handleQueryChange(c.RULE_TYPES.DM1_SPECIFIC_MESSAGES, query)}
                        operators={getAvailableOperators(c.RULE_TYPES.DM1_SPECIFIC_MESSAGES)}
                        query={notification.originalCondition}
                      />
                    </div>
                  </React.Fragment>
                )}
              </Grid>
              <Grid container item justify='flex-end' xs={12}>
                <Link className='button-link' to={notificationsUrl}>
                  <Button className='cancel-button' style={{ marginRight: 10 }}>
                    {this.formatMessage(messages.cancel)}
                  </Button>
                </Link>
                <Button
                  className='primary-action-button'
                  disabled={isRulesLoading || isDevicesLoading || isSavingLoading}
                  onClick={this.handleSaveClick}
                >
                  {this.formatMessage(messages.save)}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    )
  }
}

EditNotificationFormConditionalSignals.propTypes = {
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getDevicesDetail: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  notificationsUrl: PropTypes.string.isRequired,
  onNotificationUpdate: PropTypes.func.isRequired
}

export default injectIntl(EditNotificationFormConditionalSignals)
