const prefix = 'geofences/'

export const SELECT_GEOFENCE_DIALOG = prefix + 'SELECT_GEOFENCE_DIALOG'
export const OPEN_DELETE_DIALOG = prefix + 'OPEN_DELETE_DIALOG'
export const CLOSE_DELETE_DIALOG = prefix + 'CLOSE_DELETE_DIALOG'
export const DELETE_GEOFENCE_DIALOG = prefix + 'DELETE_GEOFENCE_DIALOG'

export const NEW_GEOFENCE_DIALOG = prefix + 'NEW_GEOFENCE_DIALOG'
export const EDIT_GEOFENCE_DIALOG = prefix + 'EDIT_GEOFENCE_DIALOG'

export const CREATE_ALERT = prefix + 'CREATE_ALERT'

export const SAVE_GEOFENCE = prefix + 'SAVE_GEOFENCE'
export const DELETE_OVERLAY = prefix + 'DELETE_OVERLAY'
export const SAVE_NAME = prefix + 'SAVE_NAME'
export const NAME_ERROR = prefix + 'NAME_ERROR'
export const SAVE_DESCRIPTION = prefix + 'SAVE_DESCRIPTION'
export const DESCRIPTION_ERROR = prefix + 'DESCRIPTION_ERROR'
export const SAVE_COLOR = prefix + 'SAVE_COLOR'

export const SAVE_RULE_INSTANCE = prefix + 'SAVE_RULE_INSTANCE'
export const SAVE_STATUS = prefix + 'SAVE_STATUS'
export const SAVE_SEVERITY = prefix + 'SAVE_SEVERITY'
export const SAVE_BOUNDARY = prefix + 'SAVE_BOUNDARY'

export const SAVE_RULES = prefix + 'SAVE_RULES'

export const CLEAN_REDUCER_DATA = prefix + 'CLEAN_REDUCER_DATA'
export const SET_EDIT_GEOFENCE = prefix + 'SET_EDIT_GEOFENCE'
