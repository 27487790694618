import moment from 'moment'

import { twosComplementToDecimal } from 'utils/binary'

const transformChartData = timeseries => {
  const result = timeseries.map(resulLine => {
    const dataVars = Object.keys(resulLine.azureData)
    const timestamp = moment(resulLine.azureData.timestamp).valueOf().toString()
    const ret = {}

    dataVars.forEach(dVar => {
      if (dVar !== 'timestamp') {
        const newVarId = dVar.split('-')[1]
        const valueType = dVar.split('-')[2]
        const newId = `${newVarId}${valueType ? '-' + valueType : ''}`
        if (!(timestamp in ret)) ret[timestamp] = [] // eslint-disable-line no-param-reassign
        ret[timestamp].push([newId, Number(resulLine.azureData[dVar])])
      }
    })
    return ret
  }, {})
  return result
}

const transformChartAggData = (timeseries, qFilters, qAgg, qBucket) => {
  const filters = qFilters.split(',')
  const agg = qAgg.split(',')
  const result = filters.reduce((ret, dVar, index) => {
    const newVarId = dVar.split('-')[1]
    const valueType = dVar.split('-')[2]
    const dAgg = agg[index]
    const newId = `${newVarId}-${dAgg}-${qBucket}${valueType ? '-' + valueType : ''}`
    if (timeseries[dVar]) {
      timeseries[dVar].forEach(resulLine => {
        const timestamp = moment(resulLine.timestamp).valueOf()
        const value = Number(resulLine[dAgg])
        if (!(timestamp in ret)) ret[timestamp] = [] // eslint-disable-line no-param-reassign
        ret[timestamp].push([newId, value])
      })
    }
    return ret
  }, {})

  return Object.keys(result).map(key => ({ [key]: result[key] }))
}

const transformGpsTrackingsData = timeseries => {
  const result = timeseries
    .filter(gpsPoint => gpsPoint.altitude !== 'NaN' || gpsPoint.speed !== 'NaN')
    .sort((itemA, itemB) => (itemA['timestamp'] < itemB['timestamp'] ? -1 : 1))
    .map(gpsPoint => {
      const timestamp = gpsPoint.timestamp
      const altitude = gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude * 0.125 - 2500 : gpsPoint.altitude
      const speed = gpsPoint.deviceType === 'CS100' ? gpsPoint.speed / 256 : gpsPoint.speed * 0.01 * 3.6
      const ret = {}
      ret[timestamp] = [
        ['gpsAltitude', altitude],
        ['gpsSpeed', speed]
      ]
      return ret
    })

  return result
}

const transformGpsTrackingsAggData = timeseries => {
  const result = timeseries.map(gpsPoint => {
    const timestamp = gpsPoint.timestamp
    const ret = {}
    ret[timestamp] = [
      {
        ['gpsAltitude']: {
          min: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude.min * 0.125 - 2500 : gpsPoint.altitude.min,
          max: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude.max * 0.125 - 2500 : gpsPoint.altitude.max,
          avg: gpsPoint.deviceType === 'CS100' ? gpsPoint.altitude.avg * 0.125 - 2500 : gpsPoint.altitude.avg
        },
        ['gpsSpeed']: {
          min: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed.min / 256 : gpsPoint.speed.min * 0.01 * 3.6,
          max: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed.max / 256 : gpsPoint.speed.max * 0.01 * 3.6,
          avg: gpsPoint.deviceType === 'CS100' ? gpsPoint.speed.avg / 256 : gpsPoint.speed.avg * 0.01 * 3.6
        }
      }
    ]
    return ret
  })
  return result
}

const transformAdvancedSignalsData = (measurements, operationType) => {
  const result = measurements.reduce((ret, measurement) => {
    const timestamp = moment(measurement.signalEndTime).utc().startOf('day').valueOf()
    const value = operationType === '1' ? getHoursFromSeconds(measurement.calculatedValue) : measurement.calculatedValue
    ret.push([timestamp, value])
    return ret
  }, [])
  return result
}

const getHoursFromSeconds = seconds => {
  const num = parseFloat('1e' + 2)
  return Math.round((seconds / 3600 + Number.EPSILON) * num) / num
}

const getFormatedChartDataToExport = (exportData, device) => {
  const sensorsArray =
    device?.deviceConfiguration?.sensorsMap
      ?.filter(sensor => sensor.frequency !== 0)
      ?.concat(device?.deviceConfiguration?.MFIO) || []

  const transformedData = []
  Object.keys(exportData).forEach(key => {
    const timestamp = Object.keys(exportData[key])
    const dataPoints = Object.values(exportData[key])[0]

    const transformedDataArrayPerTimestamp = dataPoints.reduce((ret, dataPoint) => {
      const signalId = parseInt(dataPoint[0])
      const signalRawValue = dataPoint[1]
      const signalConfig = sensorsArray.find(signal => signal.signalId === signalId)

      let adjustedDataPoint = []
      if (device.deviceType === 'CS100') {
        if (signalConfig.signed) {
          const transformedValue =
            (twosComplementToDecimal(signalRawValue, signalConfig.lengthOfBits) ?? NaN) * signalConfig.multiplier +
            signalConfig.offset
          if (transformedValue || transformedValue === 0) {
            adjustedDataPoint = [signalId, signalRawValue, transformedValue]
          } else {
            adjustedDataPoint = null
          }
        } else {
          adjustedDataPoint = [signalId, signalRawValue, signalRawValue * signalConfig.multiplier + signalConfig.offset]
        }
      } else if (device.deviceType === 'CS500') {
        adjustedDataPoint = [signalId, signalRawValue, signalRawValue / signalConfig.divider]
      }

      return [...ret, adjustedDataPoint]
    }, [])

    transformedData.push({ [timestamp]: transformedDataArrayPerTimestamp })
  })

  return transformedData
}

const getFormatedAggChartDataToExport = (exportData, device) => {
  const sensorsArray =
    device?.deviceConfiguration?.sensorsMap
      ?.filter(sensor => sensor.frequency !== 0)
      ?.concat(device?.deviceConfiguration?.MFIO) || []

  const transformedAggData = []
  Object.keys(exportData).forEach(key => {
    const timestamp = Object.keys(exportData[key])
    const dataPoints = Object.values(exportData[key])[0]

    const transformedAggDataArrayPerTimestamp = dataPoints.reduce((ret, dataPoint) => {
      const varId = dataPoint[0]
      const splVarId = varId.split('-')
      const signalId = parseInt(splVarId[0])

      const signalRawValue = dataPoint[1]
      const signalConfig = sensorsArray.find(signal => signal.signalId === signalId)

      let adjustedDataPoint = []
      if (device.deviceType === 'CS100') {
        if (signalConfig.signed) {
          const transformedValue =
            (twosComplementToDecimal(signalRawValue, signalConfig.lengthOfBits) ?? NaN) * signalConfig.multiplier +
            signalConfig.offset
          if (transformedValue || transformedValue === 0) {
            adjustedDataPoint = [signalId, signalRawValue, transformedValue]
          } else {
            adjustedDataPoint = null
          }
        } else {
          adjustedDataPoint = [signalId, signalRawValue, signalRawValue * signalConfig.multiplier + signalConfig.offset]
        }
      } else if (device.deviceType === 'CS500') {
        adjustedDataPoint = [signalId, signalRawValue, signalRawValue / signalConfig.divider]
      }

      return [...ret, adjustedDataPoint]
    }, [])

    transformedAggData.push({ [timestamp]: transformedAggDataArrayPerTimestamp })
  })

  return transformedAggData
}

export {
  getFormatedAggChartDataToExport,
  getFormatedChartDataToExport,
  transformAdvancedSignalsData,
  transformChartAggData,
  transformChartData,
  transformGpsTrackingsAggData,
  transformGpsTrackingsData
}
