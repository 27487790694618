import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

const ReportsTableTitle = ({ newReportUrl = '', intl, canCreateReports }) => {
  const { formatMessage } = intl
  const button = canCreateReports
    ? {
      icon: 'zmdi-plus',
      label: formatMessage(messages.addReport),
      path: newReportUrl,
      primary: true
    }
    : null

  return <PageTitle title={formatMessage(messages.title)} button={button} />
}

ReportsTableTitle.propTypes = {
  canCreateReports: PropTypes.bool.isRequired,
  newReportUrl: PropTypes.string.isRequired
}

export default injectIntl(ReportsTableTitle)
