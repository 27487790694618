import React from 'react'
import PropTypes from 'prop-types'

import { valueMeetsTheCondition } from '../utils'

class Image extends React.Component {
  render() {
    if (this.props.devicesData[this.props.eid]) {
      const dinamicData = this.props.devicesData[this.props.eid].dinamicData || []

      const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
      const isValidData = dinamicDataSignalIds.includes(parseInt(this.props.data.data))

      let data = {}
      let condition = !isValidData

      const { value } = this.props.data.value || { value: '' }

      if (typeof this.props.data.lengthOfBits === 'undefined') {
        condition = condition || value === '' || !valueMeetsTheCondition(this.props.data)
      } else {
        condition =
          condition || this.props.data.lengthOfBits !== 1 || value !== '' && Math.trunc(parseFloat(value)) === 1
      }
      if (condition) data = this.props.data
      else if (typeof this.props.data.lengthOfBits === 'undefined') data = this.props.data.conditionalParams
      if (data.url) {
        const topic = process.env.REACT_APP_TOPIC + 'm' + this.props.eid.replaceAll(':', '') + '/u/ds'
        return isValidData && !this.props.isSubscribedTo(this.props.eid, topic) ? (
          <div className="sk-bounce small">
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
          </div>
        ) : (
          <div className="PD-widget_content">
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              <img src={data.image} className="PD-widget_content-image" alt={data.name} />
            </a>
          </div>
        )
      } else if (data.image && data.name) {
        const topic = process.env.REACT_APP_TOPIC + 'm' + this.props.eid.replaceAll(':', '') + '/u/ds'
        return isValidData && !this.props.isSubscribedTo(this.props.eid, topic) ? (
          <div className="sk-bounce small">
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
          </div>
        ) : (
          <div className="PD-widget_content">
            <img src={data.image} className="PD-widget_content-image" alt={data.name} />
          </div>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

Image.propTypes = {
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  isSubscribedTo: PropTypes.func.isRequired
}

export default Image
