import React from 'react'
import { controlsContainer, menuItem } from './style.module.css'

const LayerSelectionPanel = ({ onClickMap, onClickSat }) => (
  <div className={controlsContainer}>
    <button
      className={`${menuItem}`}
      type="button"
      title="Map"
      aria-label="Map"
      onClick={onClickMap}
    >
      Map
    </button>
    <button
      className={`${menuItem}`}
      type="button"
      title="Satellite"
      aria-label="Satellite"
      onClick={onClickSat}
    >
      Satellite
    </button>

  </div>
)

export default LayerSelectionPanel

