import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import PageTitle from 'components/PageTitle'

import messages from './messages'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const RolesTableTitle = ({ newRoleUrl, canCreateRoles, selectedGroup, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)

  const button = {
    icon: 'zmdi-plus',
    label: formatMessage(messages.newRole),
    path: newRoleUrl,
    disabled: !selectedGroup.id || selectedGroup.id === DEFAULT_GROUP_ID
  }

  return <PageTitle button={canCreateRoles ? button : null} title={title} />
}

RolesTableTitle.propTypes = {
  canCreateRoles: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  newRoleUrl: PropTypes.string.isRequired,
  selectedGroup: PropTypes.object.isRequired
}

export default injectIntl(RolesTableTitle)
