import { LAST_OPEN_URL } from 'utils/constants'

export const getLastOpenURL = () => {
  return localStorage.getItem(LAST_OPEN_URL) || ''
}

export const saveLastOpenURL = url => {
  localStorage.setItem(LAST_OPEN_URL, url)
}
export const clearStoredURL = () => {
  localStorage.setItem(LAST_OPEN_URL, '')
}
