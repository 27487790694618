import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import ReactResizeDetector from 'react-resize-detector'
import _isEqual from 'lodash/isEqual'

import { RootRef } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/styles'

import LoadingGps from '../LoadingGps'
import baseConfig from './chartBaseConfig'
import messages from '../../messages'

const styles = {
  mapPanel: {
    backgroundColor: 'rgb(255, 255, 255)',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    height: '15vh',
    padding: '16px 0px',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
  },
  historicNavigationButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 15px'
  }
}

class TimelinePanel extends React.Component {
  constructor(props) {
    super()
    this.state = {
      containerHeight: 15
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.containerRef = React.createRef()
    this.chartRef = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props, nextProps) || !_isEqual(this.state, nextState)
  }

  afterChartDraw = event => {
    const { isReady, onSetTimelinePanelContainerHeight } = this.props

    const chart = event.target
    const CHART_CATEGORY_HEIGHT = 4.2
    const FALLBACK_CATEGORY_NUMBER = 2
    const MAX_CHART_CATEGORIES = 3
    const LOADING_HEIGHT = CHART_CATEGORY_HEIGHT * FALLBACK_CATEGORY_NUMBER
    const ADDITIONAL_HEIGHT = 6.6

    const chartCategoryNumber = chart?.options?.yAxis?.[0]?.categories?.length || FALLBACK_CATEGORY_NUMBER
    const newContainerHeight = isReady
      ? (chartCategoryNumber > MAX_CHART_CATEGORIES ? MAX_CHART_CATEGORIES : chartCategoryNumber) *
          CHART_CATEGORY_HEIGHT +
        ADDITIONAL_HEIGHT
      : LOADING_HEIGHT + ADDITIONAL_HEIGHT

    onSetTimelinePanelContainerHeight(newContainerHeight)
  }

  setChart(chart) {
    this.setState({ chart })
  }

  handleResize = () => {
    const chart = this.chartRef?.current?.chart || null

    if (chart) {
      chart.reflow()
    }
  }

  handleHistoricNavigationBackClick = () => {
    const { chartPoints, onStepChange, step, trail } = this.props

    const newStep = chartPoints.length > (step + 1) * trail ? step + 1 : step
    onStepChange(newStep)
  }

  handleHistoricNavigationForwardClick = () => {
    const { onStepChange, step } = this.props

    const newStep = step >= 1 ? step - 1 : 0
    onStepChange(newStep)
  }

  render() {
    const { classes, chartData, gpsTrackings, isReady } = this.props

    const config = Highcharts.merge(baseConfig, chartData)
    config.chart.events = config.chart.events || {}
    config.chart.events.render = this.afterChartDraw

    return (
      <ReactResizeDetector
        handleHeight
        handleWidth
        onResize={this.handleResize}
        refreshMode='debounce'
        refreshRate={200}
      >
        {({ targetRef }) => (
          <RootRef rootRef={targetRef}>
            <Grid className={classes.mapPanel} container item style={{ height: '100%' }} xs={12}>
              {isReady ? (
                <React.Fragment>
                  <div className={classes.historicNavigationButtonContainer}>
                    <Tooltip placement='top' title={this.formatMessage(messages.prevPoints)}>
                      <div>
                        <IconButton
                          disabled={Object.keys(gpsTrackings).length < 1}
                          onClick={this.handleHistoricNavigationBackClick}
                          size='small'
                          style={{ padding: '6px' }}
                        >
                          <Icon className='zmdi zmdi-long-arrow-left' style={{ fontSize: '20px', margin: 0 }} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ flexGrow: 1, flexBasis: 0, height: '100%', overflow: 'hidden' }}>
                    <HighchartsReact
                      ref={this.chartRef}
                      containerProps={{ style: { height: '100%', width: '100%' } }}
                      highcharts={Highcharts}
                      options={config}
                    />
                  </div>

                  <div className={classes.historicNavigationButtonContainer}>
                    <Tooltip placement='top' title={this.formatMessage(messages.nextPoints)}>
                      <div>
                        <IconButton
                          disabled={Object.keys(gpsTrackings).length < 1}
                          onClick={this.handleHistoricNavigationForwardClick}
                          size='small'
                          style={{ padding: '6px' }}
                        >
                          <Icon className='zmdi zmdi-long-arrow-right' style={{ fontSize: '20px', margin: 0 }} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </React.Fragment>
              ) : (
                <LoadingGps />
              )}
            </Grid>
          </RootRef>
        )}
      </ReactResizeDetector>
    )
  }
}

TimelinePanel.propTypes = {
  chartData: PropTypes.object.isRequired,
  chartPoints: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  gpsTrackings: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isReady: PropTypes.bool.isRequired,
  onSetTimelinePanelContainerHeight: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  trail: PropTypes.number.isRequired
}

export default injectIntl(withStyles(styles)(TimelinePanel))
