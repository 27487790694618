import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import DataChip from 'components/DataChip'
import messages from '../messages'
import { client, logError } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

class ActionCancelDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const { task } = this.props

    client
      .cancelTask(task.hashId)
      .then(() => {})
      .catch(response => {
        logError(response)
      })
  }

  render() {
    const { intl, onClose, open, task } = this.props

    return (
      <Dialog fullWidth={true} maxWidth="sm" onClose={onClose} open={open}>
        <DialogTitle disableTypography={true}>
          <Grid alignItems="center" container={true}>
            <Grid item={true} xs={10}>
              <Typography variant="h5">{task.nodeName}</Typography>
            </Grid>
            <Grid container={true} item={true} justify="flex-end" xs={2}>
              <IconButton aria-label="close" onClick={onClose} style={{ padding: '3px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <p style={{ marginBottom: 0 }}>{task.actionDescription}</p>
        </DialogTitle>
        <DialogContent style={{ fontSize: 16 }}>
          <hr style={{ marginTop: 0 }} />
          <p>
            <strong>{this.formatMessage(messages.original)}</strong>{' '}
            {this.formatMessage(messages.taskCreatedByOriginal)}{' '}
            <a href={'mailto:' + task.userEmail}>
              <strong>
                {task.userFirstName} {task.userLastName}
              </strong>
            </a>{' '}
            {this.formatMessage(messages.on)} {utcTimeToBrowserLocal(task.createdDate)}
          </p>
          <div style={{ margin: '1em 0' }}>
            <DataChip chipText={this.formatMessage(messages.canceled)} intl={intl} />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

ActionCancelDialog.propTypes = {
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired
}

export default injectIntl(ActionCancelDialog)
