import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { generateDeviceData } from 'utils/deviceDataGenerator'


import TextTemplate from './TextTemplate'
import messages from './messages'
import {
  manageWSSubscriptions,
  generatePartialStateFromProps,
  getValueTypes,
  mapToConditionalProperties,
  withDefaultValues
} from '../utils'

const styles = theme => ({
  conditionalSettings: {
    display: 'none',
    paddingTop: 0
  },
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  marginBottom: {
    marginBottom: 15
  }
})

class TextSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const widgetData = withDefaultValues(props.data, TextTemplate.content.params)
    const { data, value, valueType, conditionalParams, ...params } = widgetData

    this.state = {
      ...partialState,
      dinamicDataError: '',
      switch: partialState.dinamicData !== '',
      ...params,
      textError: false,
      ...mapToConditionalProperties(conditionalParams),
      operatorError: '',
      conditionalValueError: '',
      conditionalTextError: ''
    }
  }

  handleChange = name => event => {
    const names = ['operator', 'conditionalValue', 'conditionalText']
    const { value } = event.target
    if (names.includes(name) && event.target.value !== '') {
      this.setState({
        [name]: value,
        [name + 'Error']: ''
      })
    } else if (name === 'dinamicData') {
      const { node, valueType } = this.state
      const valueTypes = getValueTypes(node, value)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      this.setState({
        dinamicData: value,
        dinamicDataError: '',
        valueTypes,
        valueType: newValueType
      })
    } else {
      this.setState({
        [name]: value
      })
    }
  }

  handleSwitchChange = () => {
    this.setState(state => {
      let conditionalText = ''
      let conditionalBold = ''
      let conditionalAlign = ''
      let conditionalSize = 0
      let valueTypes = []
      let valueType = ''
      if (!state.switch) {
        conditionalSize = 14
        conditionalBold = 'bold'
        conditionalAlign = 'left'
        conditionalText = 'Text'
        valueTypes = getValueTypes(state.node, state.dinamicData)
        valueType = valueTypes[0] ? valueTypes[0] : ''
      }

      return {
        switch: !state.switch,
        dinamicData: '',
        operator: '',
        conditionalValue: '',
        conditionalText,
        conditionalBold,
        conditionalAlign,
        conditionalSize,
        operatorError: '',
        conditionalValueError: '',
        conditionalTextError: '',
        dinamicDataError: '',
        valueTypes,
        valueType
      }
    })
  }

  changeSelectedDevice = device => {
    this.handleSwitchChange()
    this.setState({
      deviceEid: [device.EID],
      switch: false,
      node: generateDeviceData(device),
      originalDevice: device,
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        originalDevice: nodeWithConfig,
        configurationLoading: false
      }
    })
  }

  changeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (this.state.text === '') {
      error = true
      this.setState({
        textError: true
      })
    }

    if (this.state.switch) {
      if (this.state.dinamicData === '') {
        error = true
        this.setState({
          dinamicDataError: this.formatMessage(messages.youMustChooseOneSignal)
        })
      }

      if (this.state.operator === '') {
        error = true
        this.setState({
          operatorError: this.formatMessage(messages.required)
        })
      }

      if (this.state.conditionalValue === '') {
        error = true
        this.setState({
          conditionalValueError: this.formatMessage(messages.thisFieldIsRequired)
        })
      }

      if (this.state.conditionalText === '') {
        error = true
        this.setState({
          conditionalTextError: this.formatMessage(messages.mustHaveAValue)
        })
      }
    }

    if (!error) {
      let value = ''
      if (
        this.props.data.value &&
        this.state.dinamicData !== '' &&
        this.state.dinamicData === this.state.wsSubscribedData &&
        this.state.switch &&
        this.props.data.valueType === this.state.valueType &&
        this.props.eid === this.state.deviceEid[0]
      ) {
        value = { ...this.props.data.value }
      } else {
        value = {
          timestamp: '',
          value: ''
        }
      }
      const data = {
        data: this.state.switch ? this.state.dinamicData : '',
        text: this.state.text,
        size: this.state.size,
        bold: this.state.bold,
        align: this.state.align,
        valueType: this.state.valueType,
        value,
        conditionalParams: {
          operator: this.state.operator,
          value: this.state.conditionalValue,
          text: this.state.conditionalText,
          size: this.state.conditionalSize,
          bold: this.state.conditionalBold,
          align: this.state.conditionalAlign
        }
      }

      const propsNode = this.props.devicesData[this.state.deviceEid[0]]
      const deviceInfo = propsNode ? '' : this.state.node

      this.props.saveSettings(data, this.state.deviceEid, deviceInfo)

      this.props.saveSettings(data, this.state.deviceEid, deviceInfo)

      // Sockets control parameters
      const { devicesData, subscribeWidgetToWS, unsubscribeWidgetFromWS, getNodeCredentials, getAzureToken } =
        this.props

      manageWSSubscriptions(
        this.state,
        data,
        devicesData,
        subscribeWidgetToWS,
        unsubscribeWidgetFromWS,
        getNodeCredentials,
        getAzureToken
      )

      this.setState({
        wsSubscribedData: data.data,
        previousEid: this.state.deviceEid
      })
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  renderConfigurationStatus = () => {
    const {
      configurationLoading,
      node: { staticData }
    } = this.state

    const { classes } = this.props

    const data = staticData.find(({ name }) => name === 'hasConfiguration')

    let messageKey = ''
    let color = 'black'

    if (configurationLoading) {
      messageKey = 'Loading'
      color = '#f0ad4e'
    } else {
      if (data?.value) {
        messageKey = 'Available'
        color = 'green'
      } else {
        messageKey = 'NotAvailable'
        color = 'red'
      }
    }

    const configurationStatus = this.formatMessage(messages['configuration' + messageKey])

    return (
      <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
        <strong>{this.formatMessage(messages.configurationStatus)}: </strong>{' '}
        <span style={{ color }}>{configurationStatus}</span>
      </DialogContentText>
    )
  }

  render() {
    const conditionalOperators = ['=', '>', '>=', '<', '<=', '!=']
    const { classes } = this.props
    const { deviceEid, valueTypes, configurationLoading } = this.state
    const selectedDeviceEid = deviceEid.length > 0 ? deviceEid[0] : ''
    const needsValueType = this.state.switch && valueTypes.length > 0
    const disabled = configurationLoading
    const dinamicSignals = this.state.node.dinamicData.filter(signal => !signal.isGPS)

    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.closeSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.textWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>
          <Button
            className='primary-action-button'
            classes={{ root: classes.marginBottom }}
            onClick={this.changeDevicesTableDisplay}
          >
            {this.formatMessage(messages[this.state.devicesButtonTextKey])}
          </Button>
          <br />
          {this.renderConfigurationStatus()}
          <br />
          {this.state.devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
          <br />
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '12px 0 0 0' }}>
              {this.state.switch
                ? this.formatMessage(messages.textWidgetSettingsWhenFalse)
                : this.formatMessage(messages.textWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={this.state.textError}
                fullWidth
                helperText={this.state.textError ? this.formatMessage(messages.mustHaveAValue) : null}
                id='text'
                label={this.formatMessage(messages.text)}
                margin='normal'
                onChange={this.handleChange('text')}
                required
                type='text'
                value={this.state.text}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='size-label-placeholder' shrink>
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  autoWidth
                  displayEmpty
                  input={<Input id='size-label-placeholder' name='size' />}
                  name='size'
                  onChange={this.handleChange('size')}
                  value={this.state.size}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='bold-label' shrink>
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='weight-label-placeholder' name='weight' />}
                  name='weight'
                  onChange={this.handleChange('bold')}
                  value={this.state.bold}
                >
                  <MenuItem value='bold'>{this.formatMessage(messages.bold)}</MenuItem>
                  <MenuItem value='normal'>{this.formatMessage(messages.normal)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='align-label' shrink>
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='align-label-placeholder' name='align' />}
                  name='align'
                  onChange={this.handleChange('align')}
                  value={this.state.align}
                >
                  <MenuItem value='left'>{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value='center'>{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value='right'>{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item style={{ marginTop: 20, paddingBottom: 4 }} xs={12}>
              <FormControlLabel
                control={<Switch checked={this.state.switch} onChange={this.handleSwitchChange} />}
                label={this.formatMessage(messages.booleanSignalDependable)}
                labelPlacement='start'
                style={{ marginLeft: 0 }}
              />
            </Grid>
          </Grid>

          <Grid
            classes={this.state.switch ? {} : { container: classes.conditionalSettings }}
            container
            spacing={1}
          >
            <Grid item md={9} xs={12}>
              <FormControl disabled={disabled} error={this.state.dinamicDataError !== ''} fullWidth>
                <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                  {this.formatMessage(messages.selectBooleanSignal)}
                </InputLabel>
                <Select onChange={this.handleChange('dinamicData')} value={this.state.dinamicData}>
                  <MenuItem key='dinamicDataDefault' value=''>
                    {this.formatMessage(messages.selectValueToDisplay)}
                  </MenuItem>
                  {dinamicSignals.map(eachDinamicData => {
                    return (
                      <MenuItem key={eachDinamicData.signalId} value={eachDinamicData.signalId}>
                        {eachDinamicData.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.dinamicDataError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={2}>
              <FormControl disabled={disabled} error={this.state.operatorError !== ''} fullWidth>
                <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                  {this.formatMessage(messages.operator)}
                </InputLabel>
                <Select onChange={this.handleChange('operator')} value={this.state.operator}>
                  {conditionalOperators.map(operator => {
                    return (
                      <MenuItem key={operator} value={operator}>
                        {operator}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.operatorError}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={2} sm={4} xs={6}>
              <TextField
                key='conditionalValue'
                InputLabelProps={{
                  shrink: true
                }}
                disabled={disabled}
                error={this.state.conditionalValueError !== ''}
                fullWidth
                helperText={this.state.conditionalValueError}
                id='conditionalValue'
                label={this.formatMessage(messages.conditionalValue)}
                onChange={this.handleChange('conditionalValue')}
                type='number'
                value={this.state.conditionalValue}
              />
            </Grid>
            {needsValueType && (
              <Grid item sm={6} xs={12}>
                <FormControl disabled={disabled} fullWidth>
                  <InputLabel htmlFor='valueType-label-placeholder' shrink>
                    {this.formatMessage(messages.valueType)}
                  </InputLabel>
                  <Select onChange={this.handleChange('valueType')} value={this.state.valueType}>
                    {valueTypes.map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {this.formatMessage(messages[type])}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <DialogContentText
            classes={this.state.switch ? {} : { root: classes.conditionalSettings }}
            id='alert-dialog-slide-description'
          >
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0 0 0' }}>
              {this.formatMessage(messages.textWidgetSettingsWhenTrue)}
            </span>
          </DialogContentText>
          <Grid
            classes={this.state.switch ? {} : { container: classes.conditionalSettings }}
            container
            spacing={3}
          >
            <Grid item xs={12}>
              <TextField
                error={this.state.conditionalTextError !== ''}
                fullWidth
                helperText={this.state.conditionalTextError}
                id='conditionalText'
                label={this.formatMessage(messages.text)}
                margin='normal'
                onChange={this.handleChange('conditionalText')}
                required
                type='text'
                value={this.state.conditionalText}
              />
            </Grid>
          </Grid>

          <Grid
            classes={this.state.switch ? {} : { container: classes.conditionalSettings }}
            container
            spacing={3}
          >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='conditionalSize-label-placeholder' shrink>
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  autoWidth
                  displayEmpty
                  input={<Input id='conditionalSize-label-placeholder' name='conditionalSize' />}
                  name='conditionalSize'
                  onChange={this.handleChange('conditionalSize')}
                  value={this.state.conditionalSize}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='conditionalBold-label' shrink>
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='conditionalWeight-label-placeholder' name='conditionalWeight' />}
                  name='conditionalWeight'
                  onChange={this.handleChange('conditionalBold')}
                  value={this.state.conditionalBold}
                >
                  <MenuItem value='bold'>{this.formatMessage(messages.bold)}</MenuItem>
                  <MenuItem value='normal'>{this.formatMessage(messages.normal)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='conditionalAlign-label' shrink>
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty
                  input={<Input id='conditionalAlign-label-placeholder' name='conditionalAlign' />}
                  name='conditionalAlign'
                  onChange={this.handleChange('conditionalAlign')}
                  value={this.state.conditionalAlign}
                >
                  <MenuItem value='left'>{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value='center'>{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value='right'>{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button className='cancel-button' onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={disabled} onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TextSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  getAzureToken: PropTypes.func.isRequired,
  getNodeCredentials: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(TextSettings))
