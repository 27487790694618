import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import EditConfigurationFormTitle from './EditConfigurationFormTitle'
import EditConfigurationForm from './EditConfigurationForm'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { transformValuesFromAPI } from '../config'
import { logError } from 'utils/http'

class EditConfiguration extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      loading: true,
      alertMessagesConfiguration: false,
      alertMessagesTypeConfiguration: 'danger',
      alertMessagesTitleConfiguration: '',
      alertMessagesTextConfiguration: ['']
    }
  }

  componentDidMount = () => {
    const { groupId } = this.props

    if (groupId) {
      this.getConfiguration()
    }
  }

  componentDidUpdate = prevProps => {
    const { groupId } = this.props

    if (groupId && prevProps.groupId !== groupId) {
      this.getConfiguration()
    }
  }

  getConfiguration = () => {
    const { configurationId, getConfiguration, groupId, intl, setLocalConfiguration } = this.props

    getConfiguration(groupId, configurationId)
      .then(response => {
        this.setState(
          {
            loading: false
          },
          () => {
            setLocalConfiguration(transformValuesFromAPI(response.data))
          }
        )
      })
      .catch(response => {
        const { error } = { ...response }
        if (error.response) {
          switch (error.response.status) {
            case 400:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401:
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesTextConfiguration: [message]
              })
              break
            case 403:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error406Message)]
              })
              break
            case 422:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, {
                  number: '422'
                }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error422Message)]
              })
              break
            case 500:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesTextConfiguration: [this.formatMessage(messages.error500Message)]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessagesConfiguration: true,
                alertMessagesTypeConfiguration: 'danger',
                alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
          }
        } else {
          this.setState({
            loading: false,
            alertMessagesConfiguration: true,
            alertMessagesTypeConfiguration: 'danger',
            alertMessagesTitleConfiguration: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesTextConfiguration: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  renderEditConfigurationLoadingAndError = () => {
    const {
      alertMessagesConfiguration,
      alertMessagesTextConfiguration,
      alertMessagesTitleConfiguration,
      alertMessagesTypeConfiguration,
      loading
    } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessagesConfiguration) {
      return (
        <Alert
          alertType={alertMessagesTypeConfiguration}
          messageText={alertMessagesTextConfiguration}
          messageTitle={alertMessagesTitleConfiguration}
          showAlert={alertMessagesConfiguration}
        />
      )
    }
  }

  render() {
    const {
      addLocalConfigurationMFIO,
      addLocalConfigurationSignal,
      changeLocalConfigurationField,
      configState,
      configurationId,
      configurationsUrl,
      copyLocalConfigurationSignal,
      groupId,
      localConfigurationError,
      manageLocalConfigurationAlert,
      onUpdateConfiguration,
      resetLocalConfiguration,
      saveConfiguration,
      selectedCanProtocolData,
      selectedCanProtocolType
    } = this.props

    const { alertMessagesConfiguration, loading } = this.state

    return (
      <div className="content-container" id="content">
        <div style={{ margin: '20px' }}>
          <div className="container-fluid">
            <EditConfigurationFormTitle configurationsUrl={configurationsUrl} />
          </div>
          {loading || alertMessagesConfiguration ? (
            <div className="container-fluid" style={{ marginTop: 10 }}>
              {this.renderEditConfigurationLoadingAndError()}
            </div>
          ) : (
            <EditConfigurationForm
              addLocalConfigurationMFIO={addLocalConfigurationMFIO}
              addLocalConfigurationSignal={addLocalConfigurationSignal}
              changeLocalConfigurationField={changeLocalConfigurationField}
              configState={configState}
              configurationId={configurationId}
              configurationsUrl={configurationsUrl}
              copyLocalConfigurationSignal={copyLocalConfigurationSignal}
              groupId={groupId}
              localConfigurationError={localConfigurationError}
              manageLocalConfigurationAlert={manageLocalConfigurationAlert}
              onUpdateConfiguration={onUpdateConfiguration}
              resetLocalConfiguration={resetLocalConfiguration}
              saveConfiguration={saveConfiguration}
              selectedCanProtocolData={selectedCanProtocolData}
              selectedCanProtocolType={selectedCanProtocolType}
            />
          )}
        </div>
      </div>
    )
  }
}

EditConfiguration.propTypes = {
  addLocalConfigurationMFIO: PropTypes.func.isRequired,
  addLocalConfigurationSignal: PropTypes.func.isRequired,
  changeLocalConfigurationField: PropTypes.func.isRequired,
  configState: PropTypes.object.isRequired,
  configurationId: PropTypes.string.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  copyLocalConfigurationSignal: PropTypes.func.isRequired,
  getConfiguration: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  localConfigurationError: PropTypes.func.isRequired,
  manageLocalConfigurationAlert: PropTypes.func.isRequired,
  onUpdateConfiguration: PropTypes.func.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  selectedCanProtocolData: PropTypes.func.isRequired,
  selectedCanProtocolType: PropTypes.func.isRequired,
  setLocalConfiguration: PropTypes.func.isRequired
}

export default injectIntl(EditConfiguration)
