import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'

import PageTitle from 'components/PageTitle'
import messages from '../messages'

const ChartConfigTitle = ({ intl }) => {
  const { formatMessage } = intl

  return <PageTitle title={formatMessage(messages.title)} />
}

ChartConfigTitle.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired
}

export default injectIntl(ChartConfigTitle)
