export const LOGO = '/images/logo/logo-plus-1connect.png'
export const PAGE_TITLE = 'PLUS+1® Connect'
export const FAVICON = '/favicon2.ico'
export const APP_BAR_COLOR = '#b60710'
export const GROUP_NAME = 'Danfoss Default Group'
export const LOGIN_PATH = 'default'
export const FONT_COLOR = '#ffffff'
export const SIDEBAR_COLOR = '#5e5d52'
export const LOGIN_TEXT = 'Maintain and diagnose your machine equipment in the field.'
export const LOGIN_IMAGE = '/images/home/diapo-bg.jpg'
