import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils as configUtils } from 'ducks/configurations'
import { utils as groupUtils } from 'ducks/groups'

import { getConfigurationsUrl } from '../url'

import ViewConfigurationConnected from './ViewConfiguration'

const mapStateToProps = (state, { match }) => ({
  configState: configUtils.getState(state),
  configurationId: match.params.configurationId,
  configurationsUrl: getConfigurationsUrl(),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getConfiguration: actions.getConfiguration,
      getConfigurations: actions.getConfigurations,
      resetLocalConfiguration: actions.resetLocalConfiguration,
      setLocalConfiguration: actions.setLocalConfiguration
    },
    dispatch
  )

const ViewConfiguration = withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewConfigurationConnected))

export { ViewConfiguration }
