import { defineMessages } from 'react-intl'

const messages = defineMessages({
  weatherWidget: {
    id: 'WeatherSettings.weatherWidget',
    description: 'Weather widget',
    defaultMessage: 'WEATHER WIDGET'
  },
  weatherWidgetSettings: {
    id: 'WeatherSettings.weatherWidgetSettings',
    description: 'Weather widget settings',
    defaultMessage: 'Weather widget settings:'
  },
  days: {
    id: 'WeatherSettings.days',
    description: 'Days',
    defaultMessage: 'Days'
  },
  unit: {
    id: 'WeatherSettings.unit',
    description: 'Unit',
    defaultMessage: 'Unit'
  },
  cancel: {
    id: 'WeatherSettings.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'WeatherSettings.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  youMustSelectForecastDays: {
    id: 'WeatherSettings.youMustSelectForecastDays',
    description: 'You must select forecast days',
    defaultMessage: 'You must select forecast days'
  },
  youMustSelectUnit: {
    id: 'WeatherSettings.youMustSelectUnit',
    description: 'You must select unit',
    defaultMessage: 'You must select unit'
  },
  selectForecastDays: {
    id: 'WeatherSettings.selectForecastDays',
    description: 'Select forecast days',
    defaultMessage: 'Select forecast days'
  },
  selectTemperaturetUnit: {
    id: 'WeatherSettings.selectTemperatureUnit',
    description: 'Select temperature unit',
    defaultMessage: 'Select temperature unit'
  },
  mondayShort: {
    id: 'WeatherSettings.mondayShort',
    description: 'Monday short',
    defaultMessage: 'Mon'
  },
  tuesdayShort: {
    id: 'WeatherSettings.tuesdayShort',
    description: 'Tuesday short',
    defaultMessage: 'Tue'
  },
  wednesdayShort: {
    id: 'WeatherSettings.wednesdayShort',
    description: 'Wednesday short',
    defaultMessage: 'Wed'
  },
  thursdayShort: {
    id: 'WeatherSettings.thursdayShort',
    description: 'Thursday short',
    defaultMessage: 'Thu'
  },
  fridayShort: {
    id: 'WeatherSettings.fridayShort',
    description: 'Friday short',
    defaultMessage: 'Fri'
  },
  saturdayShort: {
    id: 'WeatherSettings.saturdayShort',
    description: 'Saturday short',
    defaultMessage: 'Sat'
  },
  sundayShort: {
    id: 'WeatherSettings.sundayShort',
    description: 'Sunday short',
    defaultMessage: 'Sun'
  },
  mondayLong: {
    id: 'WeatherSettings.mondayLong',
    description: 'Monday long',
    defaultMessage: 'Monday'
  },
  tuesdayLong: {
    id: 'WeatherSettings.tuesdayLong',
    description: 'Tuesday long',
    defaultMessage: 'Tuesday'
  },
  wednesdayLong: {
    id: 'WeatherSettings.wednesdayLong',
    description: 'Wednesday long',
    defaultMessage: 'Wednesday'
  },
  thursdayLong: {
    id: 'WeatherSettings.thursdayLong',
    description: 'Thursday long',
    defaultMessage: 'Thursday'
  },
  fridayLong: {
    id: 'WeatherSettings.fridayLong',
    description: 'Friday long',
    defaultMessage: 'Friday'
  },
  saturdayLong: {
    id: 'WeatherSettings.saturdayLong',
    description: 'Saturday long',
    defaultMessage: 'Saturday'
  },
  sundayLong: {
    id: 'WeatherSettings.sundayLong',
    description: 'Sunday long',
    defaultMessage: 'Sunday'
  },
  today: {
    id: 'WeatherSettings.today',
    description: 'Today',
    defaultMessage: 'Today'
  },
  errorFetchingWeatherData: {
    id: 'WeatherSettings.errorFetchingWeatherData',
    description: 'Error fetching weather data',
    defaultMessage: 'Error fetching weather data'
  },
  noLocationAvailable: {
    id: 'WeatherSettings.noLocationAvailable',
    description: 'No location available',
    defaultMessage: 'No location available'
  },
  widgetNotConfigured: {
    id: 'WeatherSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  }
})

export default messages
