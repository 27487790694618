import { connect } from 'react-redux'

import { utils } from 'ducks/groups'
import csNode from 'modules/csNode'
import * as c from 'utils/constants'

import DataPlansDisconnected from './DataPlans'

const mapStateToProps = state => ({
  canApplyDataPlans: utils.hasPermission(state, c.DATAPLAN_APPLY),
  canConsumeAccessPasses: utils.hasPermission(state, c.ACCESSPASS_CONSUME),
  defaultGroupId: utils.getDefaultGroupId(state),
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  getDynamicCSNodes: (groupId, deviceFields) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, Infinity, 0, deviceFields))
})

const DataPlans = connect(mapStateToProps, mapDispatchToProps)(DataPlansDisconnected)
export { DataPlans }
