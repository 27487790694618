import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
//import axiosMiddleware from 'redux-axios-middleware'
import { multiClientMiddleware } from 'redux-axios-middleware'

import cachingMiddleware from 'middlewares/caching'
import websocketMiddleware from 'middlewares/websocket'
import realTimeGeolocationMiddleware from 'middlewares/realTimeGeolocation'
import realTimeSignalMiddleware from 'middlewares/realTimeSignal'
import csNodesMiddleware from 'middlewares/csNodes'
import chartDataExportMiddleware from 'middlewares/reports'
import gpsTrackingMiddleware from 'middlewares/geotracking'

import {
  instance as axiosInstance,
  plus1ConnectInstance as axiosPlus1ConnectInstance,
  axiosMiddlewareOptions,
  axiosPlus1ConnectMiddlewareOptions
} from 'utils/http'
import { WEBSOCKET_MAXIMUM_WAIT } from 'utils/constants'

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const geoRegExp = prefix => {
  switch (prefix) {
    case 'at/':
      return /^at\/[im]\w+\/geo+$/
    case 'az/':
      return /^az\/[im]\w+\/geo+$/
    case 'a/':
      return /^a\/[im]\w+\/geo+$/
    default:
      break
  }
}

const realTimeRegExp = prefix => {
  switch (prefix) {
    case 'at/':
      return /^at\/[im]\w+\/u\/ds+$/
    case 'az/':
      return /^az\/[im]\w+\/u\/ds+$/
    case 'a/':
      return /^a\/[im]\w+\/u\/ds+$/
    default:
      break
  }
}

const isRealtimeGeolocationTopic = topicUrl => geoRegExp(process.env.REACT_APP_TOPIC).test(topicUrl)
const isRealTimeSignalTopic = topicUrl => realTimeRegExp(process.env.REACT_APP_TOPIC).test(topicUrl)

const configureStore = (initialState = {}) => {
  // Compose final middleware and use devtools in debug environment
  const middlewares = [
    thunk,
    // This should be before axios middleware since it relies on it
    cachingMiddleware(),
    //axiosMiddleware(axiosInstance, axiosMiddlewareOptions),
    multiClientMiddleware({
      default: {
        client: axiosInstance,
        options: axiosMiddlewareOptions
      },
      plus1Connect: {
        client: axiosPlus1ConnectInstance,
        options: axiosPlus1ConnectMiddlewareOptions
      }
    }),
    websocketMiddleware({ trailing: true }, WEBSOCKET_MAXIMUM_WAIT),
    realTimeGeolocationMiddleware(isRealtimeGeolocationTopic),
    realTimeSignalMiddleware(isRealTimeSignalTopic),
    csNodesMiddleware(),
    chartDataExportMiddleware(),
    gpsTrackingMiddleware()
  ]

  let middleware = applyMiddleware(...middlewares)

  if (process.env.NODE_ENV === 'development') {
    middleware = compose(middleware, devTools)
  }

  // Create final store and subscribe router in debug env ie. for devtools
  const store = createStore(rootReducer, initialState, middleware)

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default

      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}

export default configureStore
