import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import _isEqual from 'lodash/isEqual'
import _omit from 'lodash/omit'

import { withStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getMapUrl } from 'routes'
import { Map, props as mapPropTypes } from 'components/Map'
import Marker from './CustomizedMarker'
import NodePanel from './NodePanel'

const styles = {
  loadingContainer: {
    position: 'absolute',
    top: '50px',
    right: '0px',
    bottom: '0px',
    zIndex: 10,
    background: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mapContainer: {
    maxHeight: '1440px',
    position: 'absolute',
    top: '50px',
    right: '0px',
    bottom: '0px',
    zIndex: 0
  }
}

class MapView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isReady: false
    }

    this.mapContainerRef = React.createRef()
  }

  componentDidMount() {
    const { centerNodesView, markers } = this.props

    if (markers.length > 0) {
      const height = this.mapContainerRef?.current?.clientHeight
      const width = this.mapContainerRef?.current?.clientWidth
      centerNodesView(height, width)
    }
  }

  componentDidUpdate(prevProps) {
    const { centerNodesView, groupId, isNodeClicked, markers, notificationsTotalsReceivedData, reloadMap } = this.props

    if (
      !isNodeClicked &&
      this.getHaveMarkersChanged(prevProps.markers, markers) &&
      _isEqual(prevProps.notificationsTotalsReceivedData, notificationsTotalsReceivedData)
    ) {
      const height = this.mapContainerRef?.current?.clientHeight
      const width = this.mapContainerRef?.current?.clientWidth
      centerNodesView(height, width)
    }

    if (!_isEqual(prevProps.notificationsTotalsReceivedData, notificationsTotalsReceivedData)) {
      this.updateNotificationsData()
    }

    if (prevProps.groupId !== groupId) {
      reloadMap(groupId)
    }
  }

  getHaveMarkersChanged(prevMarkers, markers) {
    return (
      prevMarkers.length !== markers.length ||
      !markers.every(marker => prevMarkers.find(prevMarker => prevMarker.id === marker.id))
    )
  }

  updateNotificationsData = () => {
    const { markers, searchNotificationsByDeviceEIDs } = this.props
    const devicesEIDs = markers.map(marker => marker.deviceEID).filter(Boolean)
    searchNotificationsByDeviceEIDs(devicesEIDs)
  }

  handleLoad = () => {
    this.setState({ isReady: true })
  }

  handleMapClick = () => {
    const { isNodeClicked, unselectNode } = this.props

    if (isNodeClicked) {
      unselectNode()
    }
  }

  renderMarkers = () => {
    const { markers, onMarkerClick } = this.props

    return markers.map(({ id, title, ...marker }) => (
      <Marker {..._omit(marker, ['deviceEID'])} key={id} label={title} name={id} onClick={onMarkerClick} />
    ))
  }

  render() {
    const {
      classes,
      currentMapCenter,
      currentZoom,
      isCollapsed,
      isNodeClicked,
      overlayImages,
      setCenter,
      setZoomLevel,
      unselectNode
    } = this.props
    const { isReady } = this.state

    return (
      <React.Fragment>
        {!isReady ? (
          <div
            className={classes.loadingContainer}
            style={{
              left: isCollapsed ? '60px' : '215px'
            }}
          >
            <CircularProgress size={80} />
          </div>
        ) : null}

        <Map
          center={currentMapCenter}
          containerElement={
            <div
              ref={this.mapContainerRef}
              className={classes.mapContainer}
              style={{
                left: isCollapsed ? '60px' : '215px'
              }}
            />
          }
          images={overlayImages}
          onCenterChanged={setCenter}
          onClick={this.handleMapClick}
          onLoad={this.handleLoad}
          onZoomChanged={setZoomLevel}
          zoom={currentZoom}
        >
          {this.renderMarkers()}
        </Map>
        <Route path={getMapUrl()} render={() => isNodeClicked && <NodePanel unselectNode={unselectNode} />} />
      </React.Fragment>
    )
  }
}

MapView.propTypes = {
  centerNodesView: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentMapCenter: mapPropTypes.Position.isRequired,
  currentZoom: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  isNodeClicked: PropTypes.bool.isRequired,
  markers: PropTypes.arrayOf(mapPropTypes.Marker).isRequired,
  notificationsTotalsReceivedData: PropTypes.object,
  onMarkerClick: PropTypes.func.isRequired,
  overlayImages: PropTypes.arrayOf(mapPropTypes.OverlayImage).isRequired,
  reloadMap: PropTypes.func.isRequired,
  searchNotificationsByDeviceEIDs: PropTypes.func.isRequired,
  setCenter: PropTypes.func.isRequired,
  setZoomLevel: PropTypes.func.isRequired,
  unselectNode: PropTypes.func.isRequired
}

MapView.defaultProps = {
  notificationsTotalsReceivedData: {}
}

export default withStyles(styles)(MapView)
