import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import ShareDataPlans from './ShareDataPlans'
import messages from './messages'

const styles = {
  card: {
    borderRadius: 0,
    height: '100%'
  },
  title: {
    fontSize: 14,
    fontWeight: 700
  },
  span: {
    height: 44,
    width: 12
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class DataPlanInventory extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      isDataPlansShared: false,
      isDefaultDpShareDialogOpen: false,
      isDefaultLoading: false,
      isDpShareDialogOpen: false,
      isLoading: false,
      numberOfDataPlansCs100: 0,
      numberOfDataPlansCs500: 0,
      personalNumberOfDataPlansCs100: 0,
      personalNumberOfDataPlansCs500: 0
    }
  }

  componentDidMount() {
    this.getNumberOfDataPlans('')
    this.getNumberOfDataPlans('default')
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupId } = this.props
    const { isDataPlansShared } = this.state

    if (prevProps.groupId !== groupId && groupId) {
      this.setState(
        {
          isDataPlansShared: false,
          isDefaultDpShareDialogOpen: false,
          isDefaultLoading: false,
          isDpShareDialogOpen: false,
          isLoading: false,
          numberOfDataPlansCs100: 0,
          numberOfDataPlansCs500: 0,
          personalNumberOfDataPlansCs100: 0,
          personalNumberOfDataPlansCs500: 0
        },
        () => {
          this.getNumberOfDataPlans('')
          this.getNumberOfDataPlans('default')
        }
      )
    }
    if (!prevState.isDataPlansShared && isDataPlansShared) {
      this.setState({
        isDataPlansShared: false
      })
      this.getNumberOfDataPlans()
    }
  }

  getNumberOfDataPlans = groupType => {
    const { getNumberOfDataPlans, groupId, defaultGroupId } = this.props
    const loadingKey = groupType === 'default' ? 'isDefaultLoading' : 'isLoading'
    const numberOfDataPlansKey = groupType === 'default' ? 'personalNumberOfDataPlans' : 'numberOfDataPlans'
    const param = groupType === 'default' ? defaultGroupId : groupId
    this.setState(
      {
        [loadingKey]: true
      },
      () => {
        getNumberOfDataPlans(param)
          .then(response => {
            const { count } = response.data
            this.setState({
              [numberOfDataPlansKey + 'Cs100']: count.CS100,
              [numberOfDataPlansKey + 'Cs500']: count.CS500
            })
          })
          .catch(response => {
            if (response?.error?.response?.data) {
              const errorMessage = response?.error?.response?.data
              if (errorMessage.includes('V2 AAA Service : DIGITAL SERVICE PLAN RESOURCE NOT FOUND FOR THIS GROUP')) {
                this.setState({
                  [numberOfDataPlansKey + 'Cs100']: 0,
                  [numberOfDataPlansKey + 'Cs500']: 0
                })
              }
            }
          })
          .finally(() => {
            this.setState({
              [loadingKey]: false
            })
          })
      }
    )
  }

  handleDefaultDPShareDialogOpen = () => {
    this.setState({
      isDefaultDpShareDialogOpen: true
    })
  }

  handleDefaultDPShareDialogClose = () => {
    this.setState({
      isDefaultDpShareDialogOpen: false
    })
  }

  handleDPShareDialogOpen = () => {
    this.setState({
      isDpShareDialogOpen: true
    })
  }

  handleDPShareDialogClose = () => {
    this.setState({
      isDpShareDialogOpen: false
    })
  }

  handleDataPlansShared = () => {
    this.setState({
      isDataPlansShared: true
    })
  }

  render() {
    const {
      canShareDataPlans,
      classes: { card, title, tooltip, span },
      dataPlansLoaded,
      personalDataplans,
      groupDataplans,
      defaultGroupId,
      groupId,
      updateDataplans
    } = this.props
    const {
      numberOfDataPlansCs100,
      numberOfDataPlansCs500,
      isLoading,
      isDefaultLoading,
      isDpShareDialogOpen,
      isDefaultDpShareDialogOpen,
      personalNumberOfDataPlansCs100,
      personalNumberOfDataPlansCs500
    } = this.state
    const isCurrentGroupDefault = groupId === defaultGroupId
    const largeGrid = isCurrentGroupDefault ? 12 : 6
    return (
      <React.Fragment>
        <Card classes={{ root: card }}>
          <CardContent style={{ padding: '16px 16px 8px 25px' }}>
            <Grid container>
              <Grid alignItems='center' classes={{ container: title }} container item justify='flex-start' xs={12}>
                {this.formatMessage(messages.dataPlanInventory)}
              </Grid>
              <Grid container item lg={largeGrid} xs={12}>
                <Grid alignItems='center' container item justify='flex-start' xs={9}>
                  {this.formatMessage(messages.boughtByMe)}
                </Grid>
                <Grid alignItems='center' container item justify='flex-end' style={{ fontSize: 18 }} xs={3}>
                  {isDefaultLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <Tooltip
                      classes={{ tooltip }}
                      placement='top'
                      title={
                        <Grid container>
                          <Grid
                            alignItems='center'
                            classes={{ container: title }}
                            container
                            item
                            justify='center'
                            xs={12}
                          >
                            CS100 dataplans: {personalNumberOfDataPlansCs100}
                          </Grid>
                          <Grid
                            alignItems='center'
                            classes={{ container: title }}
                            container
                            item
                            justify='center'
                            xs={12}
                          >
                            CS500 dataplans: {personalNumberOfDataPlansCs500}
                          </Grid>
                        </Grid>
                      }
                    >
                      <span> {personalNumberOfDataPlansCs100 + personalNumberOfDataPlansCs500}</span>
                    </Tooltip>
                  )}
                  {canShareDataPlans ? (
                    <IconButton
                      disabled={personalNumberOfDataPlansCs100 + personalNumberOfDataPlansCs500 < 1}
                      onClick={this.handleDefaultDPShareDialogOpen}
                    >
                      <ShareIcon fontSize='small' />
                    </IconButton>
                  ) : (
                    <span className={span} />
                  )}
                </Grid>
              </Grid>
              {!isCurrentGroupDefault && (
                <Grid container item lg={6} xs={12}>
                  <Grid alignItems='center' container item justify='flex-start' xs={9}>
                    {this.formatMessage(messages.sharedToThisGroup)}
                  </Grid>
                  <Grid alignItems='center' container item justify='flex-end' style={{ fontSize: 18 }} xs={3}>
                    {isLoading ? (
                      <CircularProgress size={18} />
                    ) : (
                      <Tooltip
                        classes={{ tooltip }}
                        placement='top'
                        title={
                          <Grid container>
                            <Grid
                              alignItems='center'
                              classes={{ container: title }}
                              container
                              item
                              justify='flex-start'
                              xs={12}
                            >
                              CS100 dataplans: {numberOfDataPlansCs100}
                            </Grid>
                            <Grid
                              alignItems='center'
                              classes={{ container: title }}
                              container
                              item
                              justify='flex-start'
                              xs={12}
                            >
                              CS500 dataplans: {numberOfDataPlansCs500}
                            </Grid>
                          </Grid>
                        }
                      >
                        <span> {numberOfDataPlansCs100 + numberOfDataPlansCs500}</span>
                      </Tooltip>
                    )}
                    {canShareDataPlans ? (
                      <IconButton
                        disabled={numberOfDataPlansCs100 + numberOfDataPlansCs500 < 1}
                        onClick={this.handleDPShareDialogOpen}
                      >
                        <ShareIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <span className={span} />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        {canShareDataPlans && (
          <ShareDataPlans
            dataPlansLoaded={dataPlansLoaded}
            dataplans={personalDataplans}
            groupId={defaultGroupId}
            maxNumberOfDataPlansCs100={personalNumberOfDataPlansCs100}
            maxNumberOfDataPlansCs500={personalNumberOfDataPlansCs500}
            onClose={this.handleDefaultDPShareDialogClose}
            onDataPlansShared={this.handleDataPlansShared}
            open={isDefaultDpShareDialogOpen}
            portalGroupId={groupId}
            updateDataplans={updateDataplans}
          />
        )}
        {canShareDataPlans && !isCurrentGroupDefault && (
          <ShareDataPlans
            dataPlansLoaded={dataPlansLoaded}
            dataplans={groupDataplans}
            groupId={groupId}
            maxNumberOfDataPlansCs100={numberOfDataPlansCs100}
            maxNumberOfDataPlansCs500={numberOfDataPlansCs500}
            onClose={this.handleDPShareDialogClose}
            open={isDpShareDialogOpen}
            portalGroupId={groupId}
            updateDataplans={updateDataplans}
          />
        )}
      </React.Fragment>
    )
  }
}

DataPlanInventory.propTypes = {
  canShareDataPlans: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  dataPlansLoaded: PropTypes.bool.isRequired,
  defaultGroupId: PropTypes.string.isRequired,
  getNumberOfDataPlans: PropTypes.func.isRequired,
  groupDataplans: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  personalDataplans: PropTypes.array.isRequired,
  updateDataplans: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(DataPlanInventory))
