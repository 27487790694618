import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurations'
import { utils } from 'ducks/login'
import { utils as groupUtils } from 'ducks/groups'

import { getConfigurationsUrl, getNewConfigurationUrl, getEditConfigurationUrl, getViewConfigurationUrl } from '../url'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import ConfigurationsConnected from './Configurations'

const mapStateToProps = (state, { history }) => ({
  canCreateConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_CREATE),
  canEditConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_EDIT),
  canDeleteConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_DELETE),
  configurationsUrl: getConfigurationsUrl(),
  newConfigurationUrl: getNewConfigurationUrl(),
  viewConfigurationUrl: getViewConfigurationUrl,
  editConfigurationUrl: getEditConfigurationUrl,

  onCreateConfiguration: () => history.push(getConfigurationsUrl(), 'createdConfiguration'),
  groupId: groupUtils.getSelectedGroup(state).id,
  loggedUserEmail: utils.getLoggedUserEmail(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getConfigurations: actions.getConfigurations,
      deleteConfiguration: actions.deleteConfiguration,
      saveConfiguration: actions.createConfiguration,
      manageLocalConfigurationAlert: actions.manageLocalConfigurationAlert
    },
    dispatch
  )

const Configurations = connect(mapStateToProps, mapDispatchToProps)(ConfigurationsConnected)

export { Configurations }
