import { defineMessages } from 'react-intl'

const messages = defineMessages({
  showOrHideSeries: {
    id: 'SeriesVisibilityOption.showOrHideSeries',
    description: 'Series showing or hiding dropdown title',
    defaultMessage: 'Show or hide series'
  },
})

export default messages