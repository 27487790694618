import moment from 'moment'

export const isJson = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getLatestTimestamp = timestamps => {
  let timestamp = 0
  if (timestamps.length > 0) {
    const [lastDateString] = timestamps.slice(-1)
    timestamp = moment(lastDateString).valueOf()
  }
  return timestamp
}

export const formatEid = topicEid => Array.from(topicEid.slice(1)).reduce((acc, cur, index, array) => {
  let eidPart = cur
  if (index % 2 === 1 && index < array.length - 1) eidPart += ':'
  return acc + eidPart
}, '')
