import React from 'react'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Input from '@material-ui/core/Input'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'

import Loading from 'components/Loading'

import { injectIntl } from 'react-intl'
import messages from './messages'

const DataConsumptionEstimationForm = ({
  intl,
  classes,
  machineUsagePercentage,
  dataConsumptionEstimation,
  loadingConfiguration,
  handleMachineUsagePercentageChange
}) => {
  const { formatMessage } = intl

  return(
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} sm={12}>
        <Card style={{ height: '100%', borderRadius: 0 }}>
          <CardHeader title={formatMessage(messages.title)} />
          <CardContent className="col-md-12" style={{ padding: '16px 0 16px 0' }}>
            <div className="col-md-6">
              <Typography>{formatMessage(messages.machineUsage)}</Typography>
              <FormControl>
                <Input
                  id="machineUsagePercentage"
                  type="number"
                  value={machineUsagePercentage}
                  placeholder="%"
                  inputProps={{ min: 0, max: 100 }}
                  endAdornment={<InputAdornment position="end">{' %'}</InputAdornment>}
                  onChange={event => {
                    handleMachineUsagePercentageChange(event.target.value)
                  }}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <Typography>{formatMessage(messages.megabytesPerMonth)}</Typography>
              {loadingConfiguration 
                ? <Loading />
                : <label style={{ fontSize: 16 }}>{dataConsumptionEstimation}</label>
              }
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default injectIntl(DataConsumptionEstimationForm)