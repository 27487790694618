import { point, circle } from '@turf/turf'

const getCoordinates = bounds => {
  if (Array.isArray(bounds)) {
    return bounds.map(b => [b.longitude, b.latitude])
  }

  const { ne, sw } = bounds
  return [
    [sw.longitude, ne.latitude],
    [ne.longitude, ne.latitude],
    [ne.longitude, sw.latitude],
    [sw.longitude, sw.latitude]
  ]
}

const toLines = (lines = []) =>
  lines.reduce(
    (ret, ln, i) => {
      const lineId = `line_${i}`

      // eslint-disable-next-line no-param-reassign
      ret.sources[lineId] = {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: ln.map(l => [l.longitude, l.latitude])
          },
          properties: {}
        }
      }

      ret.layers.push({
        id: lineId,
        type: 'line',
        source: lineId,
        paint: { 'line-color': '#007cbf' }
      })

      return ret
    },
    { sources: {}, layers: [] }
  )

const toImages = (images = []) =>
  images.reduce(
    (ret, img, i) => {
      const imageId = `image_${i}`

      // eslint-disable-next-line no-param-reassign
      ret.sources[imageId] = {
        type: 'image',
        url: img.url,
        // TODO rotate could be taken into account here
        coordinates: getCoordinates(img.bounds)
      }

      ret.layers.push({
        id: imageId, // 'overlay'
        type: 'raster',
        source: imageId
        // raster: { 'raster-opacity': 0.85 }
      })

      return ret
    },
    { sources: {}, layers: [] }
  )

const toPolygons = (polygons = []) =>
  polygons.reduce(
    (ret, poly, i) => {
      const polygonId = poly.hashId ? poly.hashId : `poly_${i}`

      let coord = []
      if (poly.path) {
        coord = [...poly.path.map(p => [p.longitude, p.latitude]), [poly.path[0].longitude, poly.path[0].latitude]]
      } else if (poly.polygonPath) {
        coord = [...poly.polygonPath.map(p => [p.lng, p.lat]), [poly.polygonPath[0].lng, poly.polygonPath[0].lat]]
      }

      // http://mapster.me/right-hand-rule-geojson-fixer/
      // Tricky, we could also use: geojson-rewind
      if (coord[0][1] < coord[1][1]) {
        coord.reverse()
      }

      // eslint-disable-next-line no-param-reassign
      ret.sources[polygonId] = {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coord]
          },
          properties: {
            description:
              '<strong>Truckeroo</strong><p><a href="http://www.truckeroodc.com/www/" target="_blank">Truckeroo</a> brings dozens of food trucks, live music, and games to half and M Street SE (across from Navy Yard Metro Station) today from 11:00 a.m. to 11:00 p.m.</p>',
            click: d => d
          }
        }
      }

      ret.layers.push({
        id: polygonId,
        type: 'fill',
        source: polygonId,
        paint: {
          'fill-outline-color': poly.color,
          'fill-color': poly.status === 'Enabled' ? poly.color : '#939fab',
          'fill-opacity': 0.4
        }
      })

      return ret
    },
    { sources: {}, layers: [] }
  )

const toPolygonLines = (polygons = []) =>
  polygons.reduce(
    (ret, poly, i) => {
      const polygonLineId = `polyLine_${i}`

      let coord = []
      if (poly.path) {
        coord = [...poly.path.map(p => [p.longitude, p.latitude]), [poly.path[0].longitude, poly.path[0].latitude]]
      } else if (poly.polygonPath) {
        coord = [...poly.polygonPath.map(p => [p.lng, p.lat]), [poly.polygonPath[0].lng, poly.polygonPath[0].lat]]
      }

      // http://mapster.me/right-hand-rule-geojson-fixer/
      // Tricky, we could also use: geojson-rewind
      if (coord[0][1] < coord[1][1]) {
        coord.reverse()
      }

      // eslint-disable-next-line no-param-reassign
      ret.sources[polygonLineId] = {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coord
          },
          properties: {}
        }
      }

      ret.layers.push({
        id: polygonLineId,
        type: 'line',
        source: polygonLineId,
        paint: {
          'line-color': poly.color,
          'line-width': 2
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        }
      })

      return ret
    },
    { sources: {}, layers: [] }
  )

const toCircles = (circles = []) =>
  circles.reduce(
    (ret, cir, i) => {
      const coord = [cir.center.lng, cir.center.lat]
      if (coord[0][1] < coord[1][1]) {
        coord.reverse()
      }

      const center = point(coord)
      const circleLayer = circle(center, cir.radius, { units: 'meters' })
      const circleId = cir.hashId ? cir.hashId : `circle_${i}`

      // eslint-disable-next-line no-param-reassign
      ret.sources[circleId] = {
        type: 'geojson',
        data: circleLayer
      }

      ret.layers.push({
        id: circleId,
        //type: 'circle',
        type: 'fill',
        source: circleId,
        paint: {
          //'fill-outline-color': circle.color,
          'fill-color': cir.status === 'Enabled' ? cir.color : '#939fab',
          'fill-opacity': 0.4
        }
      })

      return ret
    },
    { sources: {}, layers: [] }
  )

const toCircleOutlines = (circles = []) =>
  circles.reduce(
    (ret, cir, i) => {
      const coord = [cir.center.lng, cir.center.lat]
      if (coord[0][1] < coord[1][1]) {
        coord.reverse()
      }

      const center = point(coord)
      const circleLayer = circle(center, cir.radius, { units: 'meters' })
      const circleOutlineId = `circleOutline_${i}`

      // eslint-disable-next-line no-param-reassign
      ret.sources[circleOutlineId] = {
        type: 'geojson',
        data: circleLayer
      }

      ret.layers.push({
        id: circleOutlineId,
        type: 'line',
        source: circleOutlineId,
        paint: {
          'line-color': cir.color,
          'line-width': 2
        }
      })

      return ret
    },
    { sources: {}, layers: [] }
  )

export { toLines, toImages, toPolygons, toPolygonLines, toCircles, toCircleOutlines }
