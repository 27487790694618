import * as R from 'ramda'
import * as yup from 'yup'

export const numberTransform = number => (isNaN(number) ? undefined : number)

export const usersSchema = ({
  path = ['users'],
  message = 'One of the emails is not valid',
} = {}) => {
  return yup
    .string()
    .required('Users is required')
    .test('valid-email', message, function testUsers() {
      const users = R.path(path, this.parent)
      if (!users) return false
      return R.pipe(
        R.split(','),
        R.map(R.trim),
        R.filter(email => email.length > 0),
        R.all(email => {
          try {
            yup
              .string()
              .required('')
              .email('')
              .validateSync(email)
            return true
          } catch (error) {
            return false
          }
        }),
      )(users)
    })
}

export const stringSchema = ({name, message = `${name} is required`} = {}) => {
  return yup
    .string()
    .required(message)
    .nullable()
}
