import React from 'react'
import PropTypes from 'prop-types'

import { NavigatorOption, SeriesVisibilityOption } from '../options'

const NormalBody = ({
  navigator,
  toggleNavigatorVisibility,
  series,
  onSerieVisibilityChange,
  lowerXAxisLimit,
  upperXAxisLimit,
  setXAxisLimits,
  additionalControls
}) => {
  let controls = []
  if (additionalControls) {
    controls = additionalControls.map((control, index) => React.cloneElement(control, { key: index }))
  }

  if (onSerieVisibilityChange) {
    controls.push(
      <SeriesVisibilityOption key='seriesVisible' onSerieVisibilityChange={onSerieVisibilityChange} series={series} />
    )
  }

  if (toggleNavigatorVisibility) {
    // If function has been passed
    controls.push(<NavigatorOption key='nav' navigatorIsShown={navigator} onClick={toggleNavigatorVisibility} />)
  }

  /* eslint-disable react/jsx-no-useless-fragment */
  return <React.Fragment>{controls}</React.Fragment>
  /* eslint-enable */
}

NormalBody.propTypes = {
  additionalControls: PropTypes.arrayOf(PropTypes.node),
  lowerXAxisLimit: PropTypes.number,
  navigator: PropTypes.bool,
  onSerieVisibilityChange: PropTypes.func,
  series: PropTypes.array,
  setXAxisLimits: PropTypes.func,
  toggleNavigatorVisibility: PropTypes.func,
  upperXAxisLimit: PropTypes.number
}

export default NormalBody
