import { connect } from 'react-redux'

import { actions as cs500Actions } from 'ducks/configurationsCS500'
import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'
import {
  getNotificationsRulesUrl,
  getEditNotificationGeneralPropertiesUrl,
  getEditNotificationAssignUsersUrl
} from '../url'
import NormalEditNotificationConditionalSignals from './EditNotificationConditionalSignals'

const mapStateToProps = (state, { match, history }) => ({
  notificationId: match.params.notificationId,
  editNotificationGeneralPropertiesUrl: getEditNotificationGeneralPropertiesUrl,
  editNotificationAssignUsersUrl: getEditNotificationAssignUsersUrl,
  notificationsUrl: getNotificationsRulesUrl(),
  currentGroupId: groupUtils.getSelectedGroup(state).id,
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId)
})

const mapDispatchToProps = dispatch => ({
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  getDevicesDetail: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs))
})

const EditNotificationConditionalSignals = connect(
  mapStateToProps,
  mapDispatchToProps
)(NormalEditNotificationConditionalSignals)

export { EditNotificationConditionalSignals }
