import React from 'react'
import PropTypes from 'prop-types'

import MenuList from '@material-ui/core/MenuList'

import DropdownPanel from 'components/DropdownPanel'

const DropdownMenu = ({ children, subheader, ...other }) => (
  <DropdownPanel disablePortal propagateClose {...other}>
    {({ onDropdownClose }) => (
      <MenuList subheader={subheader}>
        {React.Children.map(children, child => {
          if (!child) return child

          const onClick =
            child && child.props.onClick
              ? e => {
                child.props.onClick(e)
                onDropdownClose()
              }
              : onDropdownClose
          return React.cloneElement(child, { onClick })
        })}
      </MenuList>
    )}
  </DropdownPanel>
)

DropdownMenu.propTypes = {
  children: PropTypes.array.isRequired,
  subheader: PropTypes.object
}

DropdownMenu.defaultProps = {
  subheader: null
}

export default DropdownMenu
