import * as t from '../../actionTypes'

import { MOUNTED_AT as ROOT_MOUNT } from '../../constants'
import visible, { MOUNTED_AT as VS_MOUNT } from './visible'

const initialSeries = {
  all: [],
  cached: [],
  [VS_MOUNT]: visible(),
  mode: 'normal'
}

const series = (state = initialSeries, action) => {
  if (!action) {
    return state
  }

  switch (action.type) {
    case t.SET_SERIES:
      return { ...state, all: action.payload.map(s => {
        // We allow to pass serie ids only
        return typeof s === 'object' ? s : { id: s }
      }),
      [VS_MOUNT]: visible(state[VS_MOUNT], {
        type: action.type,
        payload: action.payload.map(s => {
          return typeof s === 'object' ? s.id : s
        })
      })}

    case t.SHOW_SERIE:
    case t.HIDE_SERIE:
      return { ...state, [VS_MOUNT]: visible(state[VS_MOUNT], action)}

    case t.TOGGLE_NORMAL:
      if (state.mode !== 'normal') {
        return { ...state, mode: 'normal',
          all: state.cached,
          cached: [],
          [VS_MOUNT]: visible(state[VS_MOUNT], {
            type: t.SET_SERIES,
            payload: state.cached.map(s => s.id)
          })}
      }
      break // It should be already in normal mode

    case t.TOGGLE_REAL_TIME:
      if (state.mode !== 'realTime') {
        const seriesToLoad = action.payload
        return { ...state, mode: 'realTime',
          all: seriesToLoad,
          cached: state.all,
          [VS_MOUNT]: visible(state[VS_MOUNT], {
            type: t.SET_SERIES,
            payload: seriesToLoad.map(s => s.id)
          })}
      }
      break

    default:
      return state
  }
}

export default series
export const MOUNTED_AT = 'series'

// Selectors
const getSelector = substate => substate[ROOT_MOUNT][MOUNTED_AT]
const getSeries = substate => getSelector(substate).all
const getVisibleSeries = substate => getSelector(substate)[VS_MOUNT]
const isOnRealTimeMode = state => getSelector(state).mode === 'realTime'

export { series as reducer, getSeries, getVisibleSeries, isOnRealTimeMode }
