import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import csNode from 'modules/csNode'
import { utils as groupUtils } from 'ducks/groups'
import { utils } from 'ducks/login'

import { getEditCS500ConfigurationUrl, getNewCS500ConfigurationUrl, getViewCS500ConfigurationUrl } from '../url'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import ConfigurationsConnected from './Configurations'

const mapStateToProps = state => ({
  canReadConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_READ),
  canCreateConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_CREATE),
  canEditConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_EDIT),
  canReadDevices: resolvePermissions(state, c.DEVICE_READ),
  editConfigurationUrl: getEditCS500ConfigurationUrl,
  groupId: groupUtils.getSelectedGroup(state).id,
  loggedUserEmail: utils.getLoggedUserEmail(state),
  newConfigurationUrl: getNewCS500ConfigurationUrl(),
  viewConfigurationUrl: getViewCS500ConfigurationUrl
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCSNodes: csNode.actions.fetchDynamicCSNodes,
      getDeviceNonVolatileConfiguration: csNode.actions.fetchDeviceNonVolatileConfiguration
    },
    dispatch
  )

const Configurations = connect(mapStateToProps, mapDispatchToProps)(ConfigurationsConnected)

export { Configurations }
