import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { colorPickerStyles } from './styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { SketchPicker } from 'react-color'

import { injectIntl } from 'react-intl'
import messages from './messages'

class ColorPicker extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props

    this.state = {
      opened: false,
      selectedColor: null
    }

    this.formatMessage = formatMessage
    this.colors = [
      '#F44336',
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5',
      '#2196F3',
      '#00BCD4',
      '#607D8B',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      '#795548'
    ]
  }

  handleClose = reason => {
    if (reason === 'backdropClick') {
      return false
    } else {
      this.setState({ opened: false })
    }
  }

  handleOpen = () => {
    const { value } = this.props
    this.setState({
      opened: true,
      selectedColor: value
    })
  }

  handleApply = () => {
    const { onChange } = this.props
    const { selectedColor } = this.state
    onChange(selectedColor)
    this.handleClose()
  }

  handleChangeComplete = color => {
    this.setState({ selectedColor: color.hex })
  }

  render() {
    const { classes, className, value } = this.props
    const { opened, selectedColor } = this.state

    return (
      <React.Fragment>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement='top'
          title={this.formatMessage(messages.selectColor)}
        >
          <span className={className} onClick={this.handleOpen} style={{ backgroundColor: value }} />
        </Tooltip>
        <Dialog onClose={(event, reason) => this.handleClose(reason)} open={opened}>
          <DialogTitle>
            {this.formatMessage(messages.selectColor)}
            <IconButton
              onClick={(event, reason) => this.handleClose(reason)}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <SketchPicker
              color={selectedColor}
              disableAlpha
              onChangeComplete={this.handleChangeComplete}
              presetColors={this.colors}
            />
          </DialogContent>
          <DialogActions>
            <Button className='primary-action-button' onClick={this.handleApply}>
              {this.formatMessage(messages.apply)}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

ColorPicker.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
}

ColorPicker.defaultProps = {
  className: '',
  value: ''
}

export default injectIntl(withStyles(colorPickerStyles)(ColorPicker))
