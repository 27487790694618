// eslint-disable-next-line max-params
const composeLocalQuery = (filter, filterCriticality, filterEnabled, filterSelectedGroups, page, pageLength) => {
  let query = ''
  let filterQuery = ''
  let separator = ''
  let criticalityQuery = ''
  let criticalitySeparator = ''
  let enabledQuery = ''
  let enabledSeparator = ''

  /* FILTER BY GROUPS */
  if (filterSelectedGroups !== undefined && filterSelectedGroups.length > 0) {
    const listOfGroups = filterSelectedGroups.join()

    filterQuery = filterQuery + separator + 'groupHashId=in=(' + listOfGroups + ')'
    separator = ';'
  }

  /* FILTER BY SEVERITY (CRITICALITY) */
  if (filterCriticality.high) {
    criticalityQuery = criticalityQuery + criticalitySeparator + 'criticality==high'
    criticalitySeparator = ','
  }
  if (filterCriticality.medium) {
    criticalityQuery = criticalityQuery + criticalitySeparator + 'criticality==medium'
    criticalitySeparator = ','
  }
  if (filterCriticality.low) {
    criticalityQuery = criticalityQuery + criticalitySeparator + 'criticality==low'
    criticalitySeparator = ','
  }
  if (criticalityQuery !== '') {
    filterQuery = filterQuery + separator + '(' + criticalityQuery + ')'
    separator = ';'
  }

  /* FILTER BY ENABLED */
  if (filterEnabled.yes) {
    enabledQuery = enabledQuery + enabledSeparator + 'enabled==true'
    enabledSeparator = ','
  }
  if (filterEnabled.no) {
    enabledQuery = enabledQuery + enabledSeparator + 'enabled==false'
    enabledSeparator = ','
  }
  if (enabledQuery !== '') {
    filterQuery = filterQuery + separator + '(' + enabledQuery + ')'
    separator = ';'
  }

  /* FILTER BY RULE NAME - COLUMN EVENT (column dataField "hashId") */
  if (filter.hashId !== undefined) {
    filterQuery = filterQuery + separator + '(name=="*' + filter.hashId.value + '*")'
    separator = ';'
  }

  /* FILTER BY RULE DESCRIPTION - COLUMN DESCRIPTION */
  if (filter.description !== undefined) {
    filterQuery = filterQuery + separator + '(description=="*' + filter.description.value + '*")'
    separator = ';'
  }

  /* FILTER BY STATUS - COLUMN "status"  */
  if (filter.status !== undefined && filter.status.value !== undefined) {
    const statusToFind = filter.status.value
    filterQuery = filterQuery + separator + '(status=="' + statusToFind + '")'
    separator = ';'
  }

  if (filterQuery !== '') {
    query = '&' + query + 'search=' + filterQuery
  }

  query = query + '&page=' + (page - 1) + '&size=' + pageLength

  return query
}

export { composeLocalQuery }
