import React from 'react'
import PropTypes from 'prop-types'
import { OverlayView } from 'react-google-maps'

import { Position } from '../../../../props'
import { LoadedOverlayView } from '../LoadedOverlayView'

/*
 * I have problems capturing click events on normal markers in several circumstances:
 *  a) Defining a label.
 *  b) Defining the marker with a Symbol.
 * To avoid this, I will use custom elements.
 */
const CustomOverlayMarker = ({ size, position, zIndex, children }) => (
  <LoadedOverlayView
    className="LoadedOverlayView"
    size={size}
    zIndex={zIndex}
    position={position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    getPixelPositionOffset={() => ({ x: -size / 2, y: -size })}
  >
    {children}
  </LoadedOverlayView>
)

CustomOverlayMarker.propTypes = {
  position: Position,
  size: PropTypes.number,
  zIndex: PropTypes.number
}

export default CustomOverlayMarker
