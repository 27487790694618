import { connect } from 'react-redux'

import csNode from 'modules/csNode'
import { utils } from 'ducks/groups'

import LastKnownValueTableSettings from './LastKnownValueTableSettings'

const mapStateToProps = state => ({
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  getDynamicCSNodes: (groupId, extraQueryParameters, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, Infinity, 0, extraQueryParameters, filters)),
  getCSNodeByEID: (eid, groupId) => dispatch(csNode.actions.fetchCSNodeByEid(eid, groupId))
})

export default connect(mapStateToProps, mapDispatchToProps)(LastKnownValueTableSettings)
