export const dialogStyles = {
  dialogTitle: {
    padding: '0px 8px',
    marginBottom: '16px'
  },
  dialogContent: {
    padding: '8px',
    overflow: 'hidden'
  }
}
