import { defineMessages } from 'react-intl'

const namespace = 'gmaps.AssignUsersStep'

const messages = defineMessages({
  errorText: {
    id: `${namespace}.errorText`,
    description: 'Error title',
    defaultMessage: 'Error'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error401Text: {
    id: `${namespace}.error401Text`,
    description: 'Error 401 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error403Text: {
    id: `${namespace}.error403Text`,
    description: 'Error 403 text',
    defaultMessage: 'Bad request to get notification data'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  error409Text: {
    id: `${namespace}.error409Text`,
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation: Group name it is already in use'
  },
  error415Text: {
    id: `${namespace}.error415Text`,
    description: 'Error 415 text',
    defaultMessage: 'Unsupported media type'
  },
  error422Text: {
    id: `${namespace}.error422Text`,
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  error500Text: {
    id: `${namespace}.error500Text`,
    description: 'Error 500 text',
    defaultMessage: 'Access denied'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  assignedUsers: {
    id: `${namespace}.assignedUsers`,
    description: 'Assigned users header',
    defaultMessage: 'Assigned users'
  },
  assignUsers: {
    id: `${namespace}.assignUsers`,
    description: 'Assign users button label',
    defaultMessage: 'Assign users'
  },
  viewAssignedUsers: {
    id: `${namespace}.viewAssignedUsers`,
    description: 'View assigned users button label',
    defaultMessage: 'View assigned users'
  },
  stepBack: {
    id: `${namespace}.stepBack`,
    description: 'Back',
    defaultMessage: 'BACK'
  },
  lastStep: {
    id: `${namespace}.lastStep`,
    description: 'Finish',
    defaultMessage: 'FINISH'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  noUsers: {
    id: `${namespace}.noUsers`,
    description: 'No users available message',
    defaultMessage: 'No users are available.'
  },
  noUsersAssigned: {
    id: `${namespace}.noUsersAssigned`,
    description: 'No users assigned message',
    defaultMessage: 'No users assigned'
  },
  unassignUsers: {
    id: `${namespace}.unassignUsers`,
    description: 'Unassign part of title',
    defaultMessage: 'Unassign {number} users.'
  },
  errorRequestingUsers: {
    id: `${namespace}.errorRequestingUsers`,
    description: 'Error requesting users',
    defaultMessage: 'Error requesting users'
  },
  usersAssignedCorrectly: {
    id: `${namespace}.usersAssignedCorrectly`,
    description: 'Users assigned correctly message',
    defaultMessage: 'Users assigned correctly.'
  },
  usersUnassignedCorrectly: {
    id: `${namespace}.usersUnassignedCorrectly`,
    description: 'Users unassigned correctly message',
    defaultMessage: 'Users unassigned correctly.'
  },
  unassignUser: {
    id: `${namespace}.unassignUser`,
    description: 'Unassign user button title',
    defaultMessage: 'Unassign user.'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Edit notifications table total text showing rows',
    defaultMessage: 'Showing users'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Edit notifications table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Edit notifications table total text of',
    defaultMessage: 'of'
  },
  selectedUsers: {
    id: `${namespace}.selectedUsers`,
    description: 'Selected users button label',
    defaultMessage: 'Selected users'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  unassign: {
    id: `${namespace}.unassign`,
    description: 'Unassign button label',
    defaultMessage: 'Unassign'
  },
  groupActions: {
    id: `${namespace}.groupActions`,
    description: 'Group actions label text',
    defaultMessage: 'Group actions'
  },
  selectAction: {
    id: `${namespace}.selectAction`,
    description: 'Select action menu item hint text',
    defaultMessage: 'Select action'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'searchPlaceholder',
    defaultMessage: 'Filter by orderable columns'
  },
  firstName: {
    id: `${namespace}.firstName`,
    description: 'First name table header column label',
    defaultMessage: 'First name'
  },
  lastName: {
    id: `${namespace}.lastName`,
    description: 'Last name table header column label',
    defaultMessage: 'Last name'
  },
  email: {
    id: `${namespace}.email`,
    description: 'Email table header column label',
    defaultMessage: 'Email'
  },
  smsService: {
    id: 'gmaps.smsService',
    defaultMessage: 'SMS Service'
  },
  available: {
    id: 'gmaps.available',
    defaultMessage: 'Available'
  },
  notAvailable: {
    id: 'gmaps.notAvailable',
    defaultMessage: 'Not available'
  },
  sms: {
    id: 'gmaps.sms',
    defaultMessage: 'SMS'
  },
  notifyBy: {
    id: `${namespace}.notifyBy`,
    description: 'Notify by table header column text',
    defaultMessage: 'Notify by'
  },
  bell: {
    id: `${namespace}.bell`,
    description: 'Bell header column text',
    defaultMessage: 'Bell'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  confirmUserUnassignament: {
    id: `${namespace}.confirmUserUnassignament`,
    description: 'Confirm user unassignment message',
    defaultMessage: 'Confirm user unassignment'
  },
  ok: {
    id: `${namespace}.ok`,
    description: 'OK button label',
    defaultMessage: 'OK'
  },
  userConditionTextNoSelectableUsers: {
    id: `${namespace}.userConditionTextNoSelectableUsers`,
    description: 'User no selectables explanation',
    defaultMessage: 'Non-selectable users are already assigned to the notification.'
  }
})

export default messages
