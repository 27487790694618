import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import _isEqual from 'lodash/isEqual'

import MenuItem from 'material-ui/MenuItem'
import SelectField from 'material-ui/SelectField'

import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'

import Alert from 'components/Alert'
import Card from 'components/UnboxedCard'

import * as ReportConstants from '../../constants'

import Page from '../NewReport/Page'
import { createMetric, getGpsSignals, options, transformValuesForAPI } from '../NewReport/config'

import EditReportChartArea from './EditReportChartArea'
import messages from './messages'
import { parseBucketSize } from './utils'

import tz from 'utils/timezones'
import { devicesEndpointAdapter } from 'utils/adapters'
import { logError } from 'utils/http'
import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'

class EditReportForm extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      assets: [],
      bucketSize: props.schedule.bucketSize,
      chartColors: [],
      frequency: props.schedule.frequency,
      initialDate: props.schedule.initialDate,
      isChartLoading: false,
      loading: false,
      nameValue: props.schedule.name,
      name_errorText: '',
      pages: props.schedule.pages,
      selectedPage: 0,
      status: true,
      timezone: props.schedule.timezone,
      timezone_errorText: '',
      usersValue: props.schedule.user,
      users_errorText: ''
    }
  }

  componentDidMount() {
    this.getAssets()
  }

  componentDidUpdate(prevProps) {
    const { groupId, isAggregatedGpsTrackingsLoading, isAggregatedSignalsLoading, queryStatus } = this.props

    if (queryStatus && !_isEqual(prevProps.queryStatus, queryStatus)) {
      this.checkStatus(queryStatus)
    }
    if (groupId && prevProps.groupId !== groupId) this.getAssets()
    if (
      isAggregatedSignalsLoading !== prevProps.isAggregatedSignalsLoading ||
      isAggregatedGpsTrackingsLoading !== prevProps.isAggregatedGpsTrackingsLoading
    ) {
      if (isAggregatedSignalsLoading || isAggregatedGpsTrackingsLoading) {
        this.setState({ isChartLoading: true })
      } else {
        this.setState({ isChartLoading: false })
      }
    }
  }

  getAssets() {
    const { groupId } = this.props

    this.setState({ loading: true })
    this.getAssetsPaged(groupId, 200, 0)
  }

  getAssetsPaged(groupId, limit, offset, assets = []) {
    const { fetchDeviceNonVolatileConfiguration, getAssets } = this.props

    const deviceFields = {
      Device: ['id', 'name', 'eid', 'device_type'],
      Configuration: ['id', 'gps', 'sensors_map', 'multi_functional_inputs']
    }

    getAssets(groupId, limit, offset, deviceFields)
      .then(payload => {
        const cs500ConfigurationPromises = devicesEndpointAdapter(payload.data.devices)
          .map(device => {
            if (device.deviceType === 'CS500') {
              return {
                EID: device.EID,
                config: fetchDeviceNonVolatileConfiguration(groupId, device.EID).catch(() => {
                  // If there's an error fetching the configuration we shouldn't break the whole chain. So that's why we simply return an empty object.
                  return {}
                })
              }
            } else {
              return null
            }
          })
          .filter(Boolean)

        return Promise.all(cs500ConfigurationPromises.map(item => item.config)).then(values => {
          const cs500Configurations = values.reduce((acc, value) => ({ ...acc, ...value }), {})
          const devices = devicesEndpointAdapter(payload.data.devices).map(device => {
            const newDevice = { ...device }
            if (device.deviceType === 'CS500' && device.EID in cs500Configurations) {
              newDevice.deviceConfiguration = cs500Configurations[device.EID].parsedConfiguration.deviceConfiguration
            }
            return newDevice
          })

          return {
            ...payload,
            data: {
              ...payload.data,
              devices
            }
          }
        })
      })
      .then(payload => {
        // eslint-disable-next-line no-param-reassign
        assets = assets.concat(
          payload.data.devices.map(asset => {
            if (asset.deviceConfiguration) {
              if (!Array.isArray(asset.deviceConfiguration.MFIO)) {
                asset.deviceConfiguration.MFIO = [] // eslint-disable-line no-param-reassign
              } else {
                // eslint-disable-next-line no-param-reassign
                asset.deviceConfiguration.MFIO = asset.deviceConfiguration.MFIO.filter(
                  ms => ms.pinFunction > 0 || asset.deviceType === 'CS500'
                )
              }
            }
            return asset
          })
        )

        if (payload.data.total > assets.length) {
          this.getAssetsPaged(groupId, limit, offset + limit, assets)
        } else {
          this.setState({
            assets,
            chartColors: this.getChartColors(assets),
            loading: false
          })
        }
      })
      .catch(({ error }) => {
        const { response } = { ...error }
        switch (response.status) {
          case 400:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '400'
              }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '401'
              }),
              alertMessagesText: [response.message]
            })
            break
          case 403:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '403'
              }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '404'
              }),
              alertMessagesText: [this.formatMessage(messages.noMachineExists404)]
            })

            break
          case 406:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '406'
              }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '500'
              }),
              alertMessagesText: [response.data.error_description]
            })
            break
          default:
            this.setState({
              assets: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(error)
        }
      })
  }

  checkStatus = queryStatus => {
    const statusCodes = Object.keys(queryStatus).map(query => queryStatus[query])
    const statusCode = statusCodes.find(code => code === 200) ? 200 : statusCodes[0]
    switch (statusCode) {
      case 400:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '400'
          }),
          alertMessagesText: [this.formatMessage(messages.error400Message)]
        })
        break
      case 401:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '401'
          }),
          alertMessagesText: ''
        })
        break
      case 403:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '403'
          }),
          alertMessagesText: [this.formatMessage(messages.error403Message)]
        })
        break
      case 404:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '404'
          }),
          alertMessagesText: [this.formatMessage(messages.error404Message)]
        })
        break
      case 406:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '406'
          }),
          alertMessagesText: [this.formatMessage(messages.error406Message)]
        })
        break
      case 200:
        this.setState({
          loading: false,
          alertMessages: false,
          alertMessagesText: [''],
          alertMessagesTitle: '',
          alertMessagesType: ''
        })
        break
      case 204:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorNoDataTitle),
          alertMessagesText: [this.formatMessage(messages.errorNoDataMessage)]
        })
        break
      default:
        // eslint-disable-next-line no-console
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  getChartColors = assets => {
    const { pages, bucketSize, selectedPage = 0 } = this.state
    const colors = assets.reduce((ret, asset) => {
      const assetMetric = pages[selectedPage].filter(metric => metric.asset === asset.id)
      if (assetMetric.length > 0) {
        const parsedBucket = parseBucketSize(bucketSize)
        assetMetric[0].sensors.forEach(sensor => {
          ret.push({
            seriesName: `${asset.name}_${sensor.name} agg(${
              sensor.aggregationType === 'average' ? 'avg' : sensor.aggregationType
            }, ${parsedBucket})`,
            color: sensor.lineColor,
            type: sensor.lineStyle
          })
        })
      }
      return ret
    }, [])
    return colors
  }

  getSearchFilters = metric => {
    const { assets, bucketSize } = this.state

    const parsedBucket = parseBucketSize(bucketSize)
    const asset = assets.find(item => item.id === metric.asset) || {}
    let filters = []

    if (asset?.deviceConfiguration) {
      const sensorIds = asset.deviceConfiguration.sensorsMap
        .filter(sensor => metric.selectedSensorsIds.includes(sensor.signalId) && sensor.frequency !== 0)
        .concat(asset.deviceConfiguration.MFIO.filter(sensor => metric.selectedSensorsIds.includes(sensor.signalId)))
        .reduce((ret, sensor) => {
          return { ...ret, [sensor.signalId]: sensor.signalId }
        }, {})

      const gpsSignals = getGpsSignals(asset)
      const gpsSignalIds = gpsSignals.map(signal => signal.signalId)

      filters = metric.sensors.reduce((ret, sensor) => {
        const newFilter = { ...ret }

        if (!(parsedBucket in newFilter)) {
          newFilter[parsedBucket] = { filter: [], aggType: [], gpsFilter: [], gpsAggType: [] }
        }
        if (gpsSignalIds.includes(sensor.signalId)) {
          newFilter[parsedBucket].gpsFilter.push(sensor.signalId)
          newFilter[parsedBucket].gpsAggType.push(sensor.aggregationType === 'average' ? 'avg' : sensor.aggregationType)
        } else {
          newFilter[parsedBucket].filter.push('p-' + sensorIds[sensor.signalId])
          newFilter[parsedBucket].aggType.push(sensor.aggregationType === 'average' ? 'avg' : sensor.aggregationType)
        }

        return { ...ret, ...newFilter }
      }, {})

      Object.keys(filters).forEach(bucketKey => {
        filters[bucketKey].filter = filters[bucketKey].filter.join(',')
        filters[bucketKey].aggType = filters[bucketKey].aggType.join(',')
        filters[bucketKey].gpsFilter = filters[bucketKey].gpsFilter.join(',')
        filters[bucketKey].gpsAggType = filters[bucketKey].gpsAggType.join(',')
      })
    }

    return filters
  }

  handleSelectedPageChange = page => {
    this.setState({ selectedPage: page })
  }

  handleBucketSizeSelectChange = (value, index) => {
    const bucketSize = options.bucketSize()
    this.setState({ bucketSize: bucketSize[index].value })
  }

  handleFrequencySelectChange = (value, index) => {
    const frequency = options.frequency()
    this.setState({ frequency: frequency[index].value })
  }

  handleDateChange = date => {
    this.setState({
      initialDate: moment(date).toDate()
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    const { value, name } = event.target
    const propertyValue = name + 'Value'
    const propertyErrorText = name + '_errorText'
    const elementTextError = value === '' ? this.formatMessage(messages.thisFieldIsRequired) : ''

    this.setState({
      [propertyValue]: value,
      [propertyErrorText]: elementTextError
    })
  }

  handleStatusSelectChange = (value, index) => {
    const status = options.status()
    this.setState({ status: status[index].value })
  }

  handleTimezoneSelectChange = (value, index) => {
    this.setState({ timezone: tz[index].value })
  }

  handleGenGraph = (start, end) => {
    const { clearChart, getAggregatedGPSTrackingTimeSeries, getChartTimeSeries, groupId, setSearchFilters } = this.props
    const { assets, pages, selectedPage } = this.state

    this.closeAlert()
    clearChart()

    const colors = this.getChartColors(assets)
    this.setState({ chartColors: colors })

    if (pages[selectedPage][0].selectedSensorsIds.length > 0) {
      pages[selectedPage].forEach(metric => {
        const asset = assets.find(item => item.id === metric.asset) || {}
        const cleanEID = asset.EID.replaceAll(':', '')
        const filters = this.getSearchFilters(metric)
        setSearchFilters(metric.asset, filters)

        Object.keys(filters).forEach(bucketKey => {
          setTimeout(() => {
            if (filters[bucketKey].filter) {
              getChartTimeSeries(
                cleanEID,
                moment(start),
                moment(end),
                filters[bucketKey].filter,
                filters[bucketKey].aggType,
                bucketKey
              )
            }
            if (filters[bucketKey].gpsFilter) {
              getAggregatedGPSTrackingTimeSeries(
                cleanEID,
                asset.deviceType,
                groupId,
                moment(start).valueOf(),
                moment(end).valueOf(),
                4000,
                0,
                'asc',
                bucketKey
              )
            }
          }, 500)
        })
      })
      return true
    } else {
      this.setState({
        alertMessages: true,
        alertMessagesType: 'danger',
        alertMessagesTitle: this.formatMessage(messages.errorNoAssetTitle),
        alertMessagesText: [this.formatMessage(messages.errorNoAssetMessage)]
      })
      return false
    }
  }

  /* eslint-disable max-len */
  usersIsValid = value => {
    const regex =
      /^((?:(?:[a-zA-Z0-9_\-.]+)@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(?:(?:[a-zA-Z0-9-]+\.)+))(?:[a-zA-Z]{2,4}|[0-9]{1,3})(?:\]?)(?:\s*;\s*|\s*$))*)$/i
    return regex.test(value)
  }
  /* eslint-enable */

  transformPages = pages => {
    return pages.reduce((ret, page, index) => {
      const isMapTypePage = page?.[0]?.pageType === ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE
      const isTableTypePage = page?.[0]?.pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE
      let tableType = page?.[0]?.tableType

      const newPage = page.map(metric => {
        let { selectedSensorsIds, sensors, pageType } = { ...metric }
        if (isMapTypePage) {
          selectedSensorsIds = [ReportConstants.GPS_SENSOR_IDS.POSITION_MAP]
          sensors = ReportConstants.GPS_SENSORS.filter(
            sensor => sensor.signalId === ReportConstants.GPS_SENSOR_IDS.POSITION_MAP
          )
          pageType = ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE
        }
        if (isTableTypePage) {
          switch (tableType) {
            case ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE:
              selectedSensorsIds = [ReportConstants.DM1_SENSOR_IDS.DM1_LAST_KNOWN_VALUES]
              sensors = ReportConstants.DM1_SENSORS.filter(
                sensor => sensor.signalId === ReportConstants.DM1_SENSOR_IDS.DM1_LAST_KNOWN_VALUES
              )
              tableType = ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
              break
            case ReportConstants.TABLE_TYPES.DM1_HISTORIC_VALUES_TYPE:
              selectedSensorsIds = [ReportConstants.DM1_SENSOR_IDS.DM1_HISTORIC_VALUES]
              sensors = ReportConstants.DM1_SENSORS.filter(
                sensor => sensor.signalId === ReportConstants.DM1_SENSOR_IDS.DM1_HISTORIC_VALUES
              )
              tableType = ReportConstants.TABLE_TYPES.DM1_HISTORIC_VALUES_TYPE
              break
          }
          pageType = ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE
        }
        return { ...metric, selectedSensorsIds, sensors, page: index, pageType, tableType }
      })

      return [...ret, ...newPage]
    }, [])
  }

  handleSave = () => {
    const { plus1ConnectUserId } = this.props
    const { bucketSize, frequency, initialDate, pages, nameValue, status, timezone, usersValue, assets } = this.state

    let save = true
    const new_state = {}

    if (nameValue === '') {
      new_state['nameValue'] = ''
      new_state['name_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (usersValue === '') {
      new_state['usersValue'] = ''
      new_state['users_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (!this.usersIsValid(usersValue)) {
      new_state['users_errorText'] = this.formatMessage(messages.checkEmailFormat)
      save = false
    }

    if (bucketSize === '') {
      new_state['bucketSize'] = ''
      new_state['bucketSize_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (status === '') {
      new_state['status'] = ''
      new_state['status_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (initialDate === '') {
      new_state['initialDate'] = ''
      new_state['initialDate_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (frequency === '') {
      new_state['frequency'] = ''
      new_state['frequency_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    if (timezone === '') {
      new_state['timezone'] = ''
      new_state['timezone_errorText'] = this.formatMessage(messages.thisFieldIsRequired)
      save = false
    }

    const metrics = this.transformPages(pages)

    const isAnyEmptyFieldInPages = metrics.some(
      metric => !metric.asset || metric.pageType === 'CHART_PAGE_TYPE' && metric.selectedSensorsIds.length === 0
    )

    new_state['isAnyEmptyFieldInPages'] = isAnyEmptyFieldInPages

    if (
      isAnyEmptyFieldInPages ||
      Object.keys(new_state)
        .filter(s => s.endsWith('_errorText'))
        .some(s => new_state[s])
    ) {
      save = false
      this.setState({
        alertMessages: true,
        alertMessagesType: 'danger',
        alertMessagesTitle: this.formatMessage(messages.incompleteFieldTitle),
        alertMessagesText: [this.formatMessage(messages.incompleteFieldText)]
      })
    }

    if (save) {
      const formValues = {
        bucketSize,
        frequency,
        initialDate,
        metrics,
        name: nameValue,
        status,
        timezone,
        user: usersValue
      }

      const assetsById = R.indexBy(R.prop('id'), assets)
      const newReport = transformValuesForAPI(formValues, assetsById, plus1ConnectUserId)
      this.saveReport(newReport)
    } else {
      this.setState(new_state)
    }
  }

  saveReport = report => {
    const {
      intl: { locale },
      reportId,
      updateSchedule,
      updateScheduleAWS,
      groupId,
      action
    } = this.props

    const newReport = { ...report }

    updateSchedule(reportId, newReport, groupId)
      .then(({ payload }) => {
        const jsonAWS = { id: payload.data.id, json: JSON.stringify(payload.data) }
        updateScheduleAWS(jsonAWS, groupId)
        action()
      })
      .catch(response => {
        const error = { ...response }

        switch (error.response.status) {
          case 400:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '400'
              }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401:
            const message = locale === 'en' ? error.response.message : this.formatMessage(messages.error401Message)
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '401'
              }),
              alertMessagesText: [message]
            })
            break
          case 403:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '403'
              }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '404'
              }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '406'
              }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 412:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '412'
              }),
              alertMessagesText: [this.formatMessage(messages.error412Message)]
            })
            break
          case 415:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '415'
              }),
              alertMessagesText: [this.formatMessage(messages.error415Message)]
            })
            break
          case 422:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '422'
              }),
              alertMessagesText: [this.formatMessage(messages.error422Message)]
            })
            break
          case 428:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '428'
              }),
              alertMessagesText: [this.formatMessage(messages.error428Message)]
            })
            break
          case 500:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '500'
              }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  createSelectMenu = (itemList = []) => {
    return itemList.map((elem, i) => {
      return (
        <MenuItem
          key={i}
          disabled={elem.label === 'Minute' ? true : false}
          primaryText={elem.label}
          value={elem.value}
        />
      )
    })
  }

  handleAddPageClick = () => {
    const { pages } = this.state
    pages.push([createMetric()])
    this.setState({ pages: JSON.parse(JSON.stringify(pages)) })
  }

  handlePageChange = (page, index) => {
    const { pages, selectedPage } = this.state
    const newPages = pages
    newPages[index] = page
    this.setState({ pages: JSON.parse(JSON.stringify(newPages)) })

    if (index === selectedPage) {
      const { assets } = this.state
      const colors = this.getChartColors(assets)
      this.setState({ chartColors: colors })
    }
  }

  handleDeletePageClick = index => {
    const { pages } = this.state

    const newPages = pages
    newPages.splice(index, 1)
    this.setState({ pages: JSON.parse(JSON.stringify(newPages)) })
  }

  handleDuplicatePageClick = index => {
    const { pages } = this.state

    const newPages = pages
    newPages.splice(index + 1, 0, newPages[index])
    this.setState({ pages: JSON.parse(JSON.stringify(newPages)) })
  }

  render() {
    const { reportsUrl, groupId } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      assets,
      bucketSize,
      chartColors,
      frequency,
      initialDate,
      isChartLoading,
      loading,
      nameValue,
      name_errorText,
      pages,
      selectedPage,
      status,
      timezone,
      timezone_errorText,
      usersValue,
      users_errorText
    } = this.state

    return (
      <div className='container-fluid'>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ borderRadius: 0 }}>
              <Card>
                <CardHeader style={{ paddingBottom: '0px' }} title={this.formatMessage(messages.users)} />
                <CardContent style={{ paddingTop: '0px' }}>
                  <TextField
                    error={users_errorText !== ''}
                    fullWidth
                    helperText={users_errorText}
                    name='users'
                    onChange={this.handleInputChange}
                    value={usersValue}
                  />
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper style={{ borderRadius: 0 }}>
              <Card>
                <CardHeader style={{ paddingBottom: '0px' }} title={this.formatMessage(messages.reportSettings)} />
                <CardContent style={{ paddingTop: '0px' }}>
                  <TextField
                    autoFocus
                    error={name_errorText !== ''}
                    fullWidth
                    helperText={name_errorText}
                    label={this.formatMessage(messages.reportName)}
                    name='name'
                    onChange={this.handleInputChange}
                    style={{ marginTop: 16 }}
                    value={nameValue}
                  />
                  <SelectField
                    floatingLabelText={this.formatMessage(messages.bucketSize)}
                    fullWidth
                    onChange={this.handleBucketSizeSelectChange}
                    style={{ marginTop: 8 }}
                    value={bucketSize}
                  >
                    {this.createSelectMenu(options.bucketSize())}
                  </SelectField>
                  <SelectField
                    disabled
                    floatingLabelText={this.formatMessage(messages.status)}
                    fullWidth
                    value={status}
                  >
                    {this.createSelectMenu(options.status())}
                  </SelectField>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ borderRadius: 0 }}>
              <Card>
                <CardHeader style={{ paddingBottom: '0px' }} title={this.formatMessage(messages.scheduleSettings)} />
                <CardContent style={{ paddingTop: '0px' }}>
                  <DatePicker
                    autoOk
                    cancelLabel={this.formatMessage(messages.cancel)}
                    label={this.formatMessage(messages.initialDateOfExecution)}
                    labelFunc={() => utcTimeToBrowserLocalShort(initialDate)}
                    name={this.formatMessage(messages.initialDateOfExecution)}
                    okLabel=''
                    onChange={this.handleDateChange}
                    style={{ width: '100%', marginTop: 14, marginBottom: 10 }}
                    value={initialDate}
                  />
                  <SelectField
                    floatingLabelText={this.formatMessage(messages.reportFrequency)}
                    fullWidth
                    onChange={this.handleFrequencySelectChange}
                    value={frequency}
                  >
                    {this.createSelectMenu(options.frequency())}
                  </SelectField>
                  <SelectField
                    disabled
                    errorText={timezone_errorText}
                    floatingLabelText={this.formatMessage(messages.timezone)}
                    fullWidth
                    onChange={this.handleTimezoneSelectChange}
                    value={timezone}
                  >
                    {this.createSelectMenu(tz)}
                  </SelectField>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: '12px', borderRadius: 0 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {pages.map((page, index) => {
                    return (
                      <Page
                        key={index}
                        assets={assets}
                        loading={loading}
                        metrics={page}
                        onDeletePage={this.handleDeletePageClick}
                        onDuplicatePage={this.handleDuplicatePageClick}
                        onPagesUpdate={this.handlePageChange}
                        onlyOne={pages.length === 1}
                        pageIndex={index}
                      />
                    )
                  })}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid className='text-right' item xs={12}>
                  <Button className='primary-action-button' onClick={this.handleAddPageClick}>
                    <Icon className='zmdi zmdi-plus' />
                    {this.formatMessage(messages.newPage)}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <EditReportChartArea
          colors={chartColors}
          groupId={groupId}
          isChartLoading={isChartLoading}
          isDevicesLoading={loading}
          numberOfPages={pages.length}
          onPageChanged={this.handleSelectedPageChange}
          onSearch={this.handleGenGraph}
          selectedPage={selectedPage}
        />

        <Grid container spacing={3}>
          <Grid className='text-right' item xs={12}>
            <div style={{ padding: '20px 0' }}>
              <Link className='button-link' to={reportsUrl}>
                <Button className='cancel-button' style={{ marginRight: 10 }}>
                  {this.formatMessage(messages.cancel)}
                </Button>
              </Link>
              <Button className='primary-action-button' onClick={this.handleSave}>
                {this.formatMessage(messages.save)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

EditReportForm.propTypes = {
  action: PropTypes.func.isRequired,
  clearChart: PropTypes.func.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getAggregatedGPSTrackingTimeSeries: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  getChartTimeSeries: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired, locale: PropTypes.string.isRequired }).isRequired,
  isAggregatedGpsTrackingsLoading: PropTypes.bool.isRequired,
  isAggregatedSignalsLoading: PropTypes.bool.isRequired,
  plus1ConnectUserId: PropTypes.string.isRequired,
  queryStatus: PropTypes.object,
  reportId: PropTypes.string.isRequired,
  reportsUrl: PropTypes.string.isRequired,
  schedule: PropTypes.object.isRequired,
  setSearchFilters: PropTypes.func.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  updateScheduleAWS: PropTypes.func.isRequired
}

EditReportForm.defaultProps = {
  queryStatus: undefined
}

export default injectIntl(EditReportForm)
