import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class DeleteDashboard extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {}
  }

  deleteDashboard = () => {
    client
      .deleteGroupDashboard(this.props.dashboardData.hashId, this.props.dashboardData.version)
      .then(() => {
        this.setState({
          deleteDashboardModal: false
        })
        this.props.closeDeleteDashboardModal()
        this.props.deleteGroupDashboard(this.props.dashboardData)
        this.props.refresh('first')
      })
      .catch(response => {
        // const error = Object.assign({}, response)
        logError(response)
      })
  }

  render() {
    return (
      <Dialog
        open={this.props.deleteDashboardModal}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.props.closeDeleteDashboardModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.deleteDashboard)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.props.closeDeleteDashboardModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <strong>{this.formatMessage(messages.name)}:</strong> {this.props.dashboardData.name}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <strong>{this.formatMessage(messages.description)}:</strong>{' '}
            {JSON.parse(this.props.dashboardData.description).description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeDeleteDashboardModal}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="delete-button" onClick={this.deleteDashboard}>
            {this.formatMessage(messages.delete)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DeleteDashboard.propTypes = {
  closeDeleteDashboardModal: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
  deleteDashboardModal: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired
}

export default injectIntl(DeleteDashboard)
