import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

const NotificationsRulesSettingsTableTitle = ({ newNotificationUrl, canCreateNotification, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)
  let button

  if (canCreateNotification) {
    button = {
      icon: 'zmdi-plus',
      label: formatMessage(messages.newNotification),
      path: newNotificationUrl,
      primary: true
    }
  } else {
    button = null
  }

  return <PageTitle button={button} title={title} />
}

NotificationsRulesSettingsTableTitle.propTypes = {
  canCreateNotification: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  newNotificationUrl: PropTypes.string.isRequired
}

export default injectIntl(NotificationsRulesSettingsTableTitle)
