import { saveAs } from 'file-saver'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import MenuItem from 'material-ui/MenuItem'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import Card from 'components/UnboxedCard'
import { getGpsSignals } from 'modules/reports/components/NewReport/config'
import { devicesEndpointAdapter } from 'utils/adapters'
import { logError } from 'utils/http'

import messages from '../messages'
import ChartArea from './ChartArea'
import ChartMetrics from './ChartMetrics'
import {
  getFormatedAggChartDataToExport,
  getFormatedChartDataToExport,
  transformAdvancedSignalsData,
  transformChartAggData,
  transformChartData,
  transformGpsTrackingsAggData,
  transformGpsTrackingsData
} from './utils'

const { AsyncParser } = require('json2csv')

const styles = {
  addDevice: {
    alignItems: 'center',
    borderBottom: '2px solid transparent',
    color: '#32A0E9',
    cursor: 'pointer',
    display: 'inline-flex',
    fontWeight: '500',
    marginTop: '15px',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
      borderBottom: '2px solid #1377b9',
      color: '#1377b9'
    }
  }
}

class ChartConfig extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      abortController: new AbortController(),
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      chartColors: [],
      csvFileNumberPerDevice: {},
      devices: [],
      from: null,
      isChartLoading: false,
      isConfigurationLoading: false,
      isExportLoading: false,
      loading: false,
      metrics: [this.getNewMetric()],
      remainingTransformedTimeseries: {},
      to: null
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    this.getAllDevices()
  }

  componentDidUpdate(prevProps) {
    const {
      groupId,
      isAdvancedSignalsLoading,
      isAggregatedGpsTrackingsLoading,
      isAggregatedSignalsLoading,
      isGpsTrackingsLoading,
      isSignalsLoading,
      queryStatus
    } = this.props

    if (queryStatus && !_isEqual(prevProps.queryStatus, queryStatus)) {
      this.checkStatus(queryStatus)
    }
    if (groupId && prevProps.groupId !== groupId) {
      this.setState(
        {
          metrics: [this.getNewMetric()],
          devices: [],
          chartColors: [],
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        },
        this.getAllDevices
      )
    }
    if (
      isSignalsLoading !== prevProps.isSignalsLoading ||
      isAggregatedSignalsLoading !== prevProps.isAggregatedSignalsLoading ||
      isGpsTrackingsLoading !== prevProps.isGpsTrackingsLoading ||
      isAggregatedGpsTrackingsLoading !== prevProps.isAggregatedGpsTrackingsLoading ||
      isAdvancedSignalsLoading !== prevProps.isAdvancedSignalsLoading
    ) {
      if (
        isSignalsLoading ||
        isAggregatedSignalsLoading ||
        isGpsTrackingsLoading ||
        isAggregatedGpsTrackingsLoading ||
        isAdvancedSignalsLoading
      ) {
        this.setState({ isChartLoading: true })
      } else {
        this.setState({ isChartLoading: false })
      }
    }
  }
  componentWillUnmount() {
    const { abortController } = this.state
    abortController.abort()
  }

  initAsyncParser = (fields, device, type) => {
    const asyncParser = new AsyncParser({ fields })

    let csv = []
    asyncParser.processor
      .on('data', chunk => csv.push(chunk.toString()))
      .on('end', () => {
        if (csv.length > 1) {
          const { csvFileNumberPerDevice, from, to } = this.state
          const index = csvFileNumberPerDevice?.[device.EID.replaceAll(':', '')] || 1
          const fromTitle = new Date(parseInt(from))?.toISOString().replaceAll(':', '.')
          const toTitle = new Date(parseInt(to))?.toISOString().replaceAll(':', '.')
          const fileName = `${device.name}${type ? '_' + type : ''}_${fromTitle}-${toTitle}_${index}.csv`

          const STRING_MAX_LENGTH = 260000000
          let csvLength = 0
          csv.forEach(item => csvLength += item.length)
          if (csvLength > STRING_MAX_LENGTH) {
            const blobPartCount = Math.floor(csvLength / STRING_MAX_LENGTH) + 1
            const blobArray = [new Blob([csv.slice(0, csv.length / blobPartCount)])]

            for (let i = 1; i < blobPartCount; i++) {
              blobArray.push(
                new Blob([csv.slice(i * (csv.length / blobPartCount), (i + 1) * (csv.length / blobPartCount))])
              )
            }
            saveAs(new Blob(blobArray, { type: 'text/csv;charset=utf-8' }), fileName)
            csv = []
          } else {
            saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName)
            csv = []
          }
        }
      })
      .on('error', () => {
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })
      })
    return asyncParser
  }

  handleDialogClose = reason => {
    if (reason === 'backdropClick') {
      return false
    } else {
      const { abortController } = this.state
      abortController.abort()
      this.setState({
        isExportLoading: false,
        abortController: new AbortController()
      })
    }
  }

  resetExportDataQueryStatus = () => {
    this.setState({
      isExportLoading: false
    })
  }

  checkStatus = queryStatus => {
    const statusCodes = Object.keys(queryStatus).map(query => queryStatus[query])
    const statusCode = statusCodes.find(code => code === 200) ? 200 : statusCodes[0]
    switch (statusCode) {
      case 400:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '400'
          }),
          alertMessagesText: [this.formatMessage(messages.error400Message)]
        })
        break
      case 401:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '401'
          }),
          alertMessagesText: ''
        })
        break
      case 403:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '403'
          }),
          alertMessagesText: [this.formatMessage(messages.error403Message)]
        })
        break
      case 404:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '404'
          }),
          alertMessagesText: [this.formatMessage(messages.error404Message)]
        })
        break
      case 406:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.error, {
            number: '406'
          }),
          alertMessagesText: [this.formatMessage(messages.error406Message)]
        })
        break
      case 200:
        this.setState({
          loading: false,
          alertMessages: false,
          alertMessagesText: [''],
          alertMessagesTitle: '',
          alertMessagesType: ''
        })
        break
      case 204:
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorNoDataTitle),
          alertMessagesText: [this.formatMessage(messages.errorNoDataMessage)]
        })
        break
      default:
        // eslint-disable-next-line no-console
        this.setState({
          loading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })
    }
  }

  getAllDevices() {
    const { groupId } = this.props

    this.setState({ loading: true })
    this.getDevicesPaged(groupId, 200, 0)
  }

  getDevicesPaged(groupId, limit, offset, devices = []) {
    const { getAssets } = this.props

    const deviceFields = {
      Device: ['id', 'name', 'eid', 'device_type']
    }

    getAssets(groupId, limit, offset, deviceFields)
      .then(payload => {
        const newDevices = devices.concat(devicesEndpointAdapter(payload.data.devices))

        if (payload.data.total > newDevices.length) {
          this.getDevicesPaged(groupId, limit, offset + limit, newDevices)
        } else {
          this.setState({
            devices: newDevices,
            loading: false
          })
        }
      })
      .catch(({ error }) => {
        const { response } = { ...error }
        switch (response.status) {
          case 400:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '400'
              }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '401'
              }),
              alertMessagesText: [response.message]
            })
            break
          case 403:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '403'
              }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '404'
              }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '406'
              }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '500'
              }),
              alertMessagesText: [response.data.error_description]
            })
            break
          default:
            this.setState({
              devices: [],
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(error)
        }
      })
  }

  getDeviceConfiguration = deviceId => {
    const { devices } = this.state
    const selectedDevice = devices.find(device => device.id === deviceId)
    if (selectedDevice?.deviceType === 'CS100' && _isEmpty(selectedDevice.deviceConfiguration)) {
      const { fetchDevice, groupId } = this.props
      this.setState({ isConfigurationLoading: true })

      fetchDevice(selectedDevice.id, groupId).then(async response => {
        const deviceConfiguration = response.data.deviceConfiguration
        let advancedSignals = []
        if (deviceConfiguration) {
          if (!Array.isArray(deviceConfiguration.MFIO)) {
            deviceConfiguration.MFIO = []
          } else {
            deviceConfiguration.MFIO = deviceConfiguration.MFIO.filter(ms => ms.pinFunction > 0)
          }
        }

        await this.getDevicesAdvancedSignals([selectedDevice.EID]).then(deviceAdSignalsResponse => {
          const { value, status } = deviceAdSignalsResponse[0]

          if (status === 'fulfilled' && value?.data.length > 0) {
            advancedSignals = value.data
          }
        })

        const newDevices = devices.map(device => {
          if (device.EID === selectedDevice.EID) {
            return {
              ...device,
              deviceConfiguration,
              advancedSignals
            }
          } else {
            return device
          }
        })

        this.setState({ devices: newDevices, isConfigurationLoading: false })
      })
    } else if (selectedDevice?.deviceType === 'CS500' && _isEmpty(selectedDevice.deviceConfiguration)) {
      const { fetchDeviceNonVolatileConfiguration, groupId } = this.props
      this.setState({ isConfigurationLoading: true })

      fetchDeviceNonVolatileConfiguration(groupId, selectedDevice.EID).then(async response => {
        const { deviceConfiguration = {} } = response[selectedDevice.EID].parsedConfiguration
        let advancedSignals = []
        await this.getDevicesAdvancedSignals([selectedDevice.EID]).then(deviceAdSignalsResponse => {
          const { value, status } = deviceAdSignalsResponse[0]

          if (status === 'fulfilled' && value?.data.length > 0) {
            advancedSignals = value.data
          }
        })

        const newDevices = devices.map(device => {
          if (device.EID === selectedDevice.EID) {
            return {
              ...device,
              deviceConfiguration,
              advancedSignals
            }
          } else {
            return device
          }
        })

        this.setState({ devices: newDevices, isConfigurationLoading: false })
      })
    }
  }

  getDevicesAdvancedSignals = eids => {
    const { groupId, getAdvancedSignals } = this.props
    return Promise.allSettled(eids.map(eid => getAdvancedSignals(groupId, eid)))
  }

  getNewMetric = (args = {}) => {
    const { device = null, deviceEid = null, selectedSensorsIds = [], sensors = [] } = args
    return { device, deviceEid, selectedSensorsIds, sensors }
  }

  getSearchFilters = metric => {
    const { devices } = this.state

    let filters = []
    const device = devices.find(item => item.id === metric.device)

    if (device?.deviceConfiguration) {
      const sensorIds = device.deviceConfiguration.sensorsMap
        .filter(sensor => metric.selectedSensorsIds.includes(sensor.signalId) && sensor.frequency !== 0)
        .concat(device.deviceConfiguration.MFIO.filter(sensor => metric.selectedSensorsIds.includes(sensor.signalId)))
        .reduce((ret, sensor) => {
          return { ...ret, [sensor.signalId]: sensor.signalId }
        }, {})

      const gpsSignals = getGpsSignals(device)
      const gpsSignalIds = gpsSignals.map(signal => signal.signalId)
      const advancedSignalIds =
        device?.advancedSignals?.map(advancedSignal => advancedSignal.measurementDevices[0].hashId) || []

      filters = metric.sensors.reduce((ret, sensor) => {
        const newFilter = { ...ret }
        if (sensor.aggregationType !== 'none') {
          if (!(sensor.bucket in newFilter)) {
            newFilter[sensor.bucket] = { filter: [], aggType: [], gpsFilter: [], gpsAggType: [] }
          }
          if (gpsSignalIds.includes(sensor.signalId)) {
            newFilter[sensor.bucket].gpsFilter.push(sensor.signalId)
            newFilter[sensor.bucket].gpsAggType.push(sensor.aggregationType)
          } else {
            if (sensor.valueType !== 'last') {
              newFilter[sensor.bucket].filter.push('p-' + sensorIds[sensor.signalId] + '-' + sensor.valueType)
            } else {
              newFilter[sensor.bucket].filter.push('p-' + sensorIds[sensor.signalId])
            }
            newFilter[sensor.bucket].aggType.push(sensor.aggregationType)
          }
        } else {
          if (!('raw' in newFilter)) {
            newFilter['raw'] = { filter: [], aggType: [], gpsFilter: [], gpsAggType: [], advancedSignalFilter: [] }
          }
          if (advancedSignalIds.includes(sensor.signalId)) {
            newFilter[sensor.bucket].advancedSignalFilter.push(sensor.signalId)
          } else if (gpsSignalIds.includes(sensor.signalId)) {
            newFilter[sensor.bucket].gpsFilter.push(sensor.signalId)
          } else {
            if (sensor.valueType !== 'last') {
              newFilter['raw'].filter.push('p-' + sensorIds[sensor.signalId] + '-' + sensor.valueType)
            } else {
              newFilter['raw'].filter.push('p-' + sensorIds[sensor.signalId])
            }
          }
        }

        return { ...ret, ...newFilter }
      }, {})

      Object.keys(filters).forEach(bucket => {
        filters[bucket].filter = filters[bucket].filter.join(',')
        filters[bucket].aggType = filters[bucket].aggType.join(',')
        filters[bucket].gpsFilter = filters[bucket].gpsFilter.join(',')
        filters[bucket].gpsAggType = filters[bucket].gpsAggType.join(',')
      })
    }

    return filters
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleDataToExport = async (start, end) => {
    const { groupId } = this.props
    const { abortController, devices, metrics } = this.state
    this.setState({
      from: start,
      to: end
    })

    this.closeAlert()

    // forEach is not promise-aware (you can’t return values in a forEach loop). It cannot support async and await. You cannot use await in forEach.
    if (metrics[0].selectedSensorsIds.length > 0) {
      this.setState({
        isExportLoading: true
      })
      for (let i = 0; i < metrics.length; i++) {
        const metric = metrics[i]
        const device = devices.find(item => item.id === metric.device)
        const filters = this.getSearchFilters(metric)
        const filterNames = Object.keys(filters)

        for (let j = 0; j < filterNames.length; j++) {
          const bucketKey = filterNames[j]
          if (bucketKey === 'raw') {
            if (filters[bucketKey].filter && !abortController.signal.aborted) {
              await this.getChartTimeSeriesToExportToCsv(
                device,
                moment(start),
                moment(end),
                filters[bucketKey].filter,
                bucketKey
              )
            }
            if (filters[bucketKey].gpsFilter && !abortController.signal.aborted) {
              await this.getGPSTrackingTimeSeriesToExportToCsv(
                device,
                moment(start).valueOf(),
                moment(end).valueOf(),
                bucketKey
              )
            }
            if (filters[bucketKey].advancedSignalFilter) {
              const advancedSignals = filters[bucketKey].advancedSignalFilter
              for (let z = 0; z < advancedSignals.length; z++) {
                const signalId = advancedSignals[z]
                const sensor = metric.sensors.find(s => s.signalId === signalId)
                const data = {
                  deviceEid: device.EID.replaceAll(':', ''),
                  measurementName: sensor.name,
                  operationType: sensor.operationType,
                  unit: sensor.unit,
                  deviceHashId: signalId,
                  min: moment(start).valueOf(),
                  max: moment(end).valueOf()
                }
                await this.getAdvancedSignalResultsToExportToCsv(
                  device,
                  signalId,
                  groupId,
                  moment(start).valueOf(),
                  moment(end).valueOf(),
                  data
                )
              }
            }
          } else {
            if (filters[bucketKey].filter && !abortController.signal.aborted) {
              await this.getAggregatedChartTimeSeriesToExportToCsv(
                device,
                moment(start),
                moment(end),
                filters[bucketKey].filter,
                filters[bucketKey].aggType,
                bucketKey
              )
            }
            if (filters[bucketKey].gpsFilter && !abortController.signal.aborted) {
              await this.getAggregatedGPSTrackingTimeSeriesToExportToCsv(
                device,
                moment(start).valueOf(),
                moment(end).valueOf(),
                filters[bucketKey].gpsFilter,
                filters[bucketKey].gpsAggType,
                bucketKey
              )
            }
          }
        }
      }
      if (!abortController.signal.aborted) {
        this.setState({
          isExportLoading: false
        })
      }
    } else {
      this.setState({
        alertMessages: true,
        alertMessagesType: 'danger',
        alertMessagesTitle: this.formatMessage(messages.errorNoMachineTitle),
        alertMessagesText: [this.formatMessage(messages.errorNoMachineMessage)]
      })
    }
  }

  // eslint-disable-next-line max-params
  getChartTimeSeriesToExportToCsv = async (device, start, end, filter, bucket) => {
    const { getChartTimeSeriesToExport } = this.props
    const { abortController, metrics } = this.state
    const sensorsArray = metrics
      .find(metric => metric.deviceEid === device.EID)
      .sensors.filter(
        sensor =>
          sensor.bucket === bucket &&
          sensor.signalId !== 'gpsAltitude' &&
          sensor.signalId !== 'gpsSpeed' &&
          !sensor.operationType
      )
    const deviceEid = device.EID.replaceAll(':', '')
    let startTimestamp = start
    const fields = [
      {
        label: 'Timestamp',
        value: 'ts'
      },
      {
        label: 'Date',
        value: 'date'
      },
      {
        label: 'Device name',
        value: 'name'
      },
      {
        label: 'Device EID',
        value: 'eid'
      },
      {
        label: 'Device type',
        value: 'type'
      }
    ]

    for (let i = 0; i < sensorsArray.length; i++) {
      const sensor = sensorsArray[i]
      fields.push(
        {
          label: sensor.name + ' raw value' + (sensor.unit ? ' (' + sensor.unit + ')' : ''),
          value: 'raw' + sensor.signalId
        },
        {
          label: sensor.name + ' final value' + (sensor.unit ? ' (' + sensor.unit + ')' : ''),
          value: 'final' + sensor.signalId
        }
      )
    }

    let asyncParser = this.initAsyncParser(fields, device)
    let lineCount = 0

    this.setState({
      csvFileNumberPerDevice: {
        [deviceEid]: 0
      }
    })

    do {
      await getChartTimeSeriesToExport(deviceEid, startTimestamp, end, filter)
        .then(response => {
          const { remainingTransformedTimeseries } = this.state
          const MAX_DATA_SIZE = 500000
          const next = response?.payload?.data?.next
          startTimestamp = next ? moment(next).valueOf() : null
          const timeseries = response?.payload?.data?.content || []

          const transformedTimeseries = transformChartData(timeseries)
          const exportData = getFormatedChartDataToExport(
            [...remainingTransformedTimeseries?.[deviceEid] || [], ...transformedTimeseries],
            device
          )

          exportData.forEach((timestampObject, k) => {
            if (lineCount < MAX_DATA_SIZE) {
              const timestamp = Object.keys(timestampObject)[0]
              const values = Object.values(timestampObject)[0]
              exportData[k] = null // eslint-disable-line no-param-reassign
              transformedTimeseries.splice(0, 1)
              const data = {}
              data.ts = timestamp || ''
              data.date = new Date(parseInt(timestamp))?.toISOString() || ''
              data.name = device.name
              data.eid = device.EID
              data.type = device.deviceType

              for (let j = 0; j < sensorsArray.length; j++) {
                const sensor = sensorsArray[j]
                const signal = values.find(v => v[0] === sensor.signalId)

                data['raw' + sensor.signalId] = signal ? signal[1] : ''
                data['final' + sensor.signalId] = signal ? signal[2] : ''
              }
              asyncParser.input.push(JSON.stringify(data))
              lineCount++
            }
          })

          if (lineCount === MAX_DATA_SIZE || !next) {
            lineCount = 0

            this.setState(
              state => ({
                remainingTransformedTimeseries: {
                  ...state.remainingTransformedTimeseries,
                  [deviceEid]: transformedTimeseries
                },
                csvFileNumberPerDevice: {
                  ...state.csvFileNumberPerDevice,
                  [deviceEid]: state?.csvFileNumberPerDevice?.[deviceEid]
                    ? state?.csvFileNumberPerDevice?.[deviceEid] + 1
                    : 1
                }
              }),
              () => {
                asyncParser.input.push(null)
                asyncParser = this.initAsyncParser(fields, device)
              }
            )
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
        })
    } while (startTimestamp && !abortController.signal.aborted)
  }

  getGPSTrackingTimeSeriesToExportToCsv = async (device, start, end, bucket) => {
    const { getGPSTrackingTimeSeriesToExport } = this.props
    const { abortController, metrics } = this.state
    const sensorsArray = metrics
      .find(metric => metric.deviceEid === device.EID)
      .sensors.filter(
        sensor => sensor.bucket === bucket && (sensor.signalId === 'gpsAltitude' || sensor.signalId === 'gpsSpeed')
      )
    const deviceEid = device.EID.replaceAll(':', '')
    let startTimestamp = start
    const fields = [
      {
        label: 'Timestamp',
        value: 'ts'
      },
      {
        label: 'Date',
        value: 'date'
      },
      {
        label: 'Device name',
        value: 'name'
      },
      {
        label: 'Device EID',
        value: 'eid'
      },
      {
        label: 'Device type',
        value: 'type'
      }
    ]

    for (let i = 0; i < sensorsArray.length; i++) {
      const sensor = sensorsArray[i]
      fields.push({
        label: sensor.name + (sensor.unit ? ' (' + sensor.unit + ')' : ''),
        value: 'value' + sensor.signalId
      })
    }

    const type = 'GPS'
    let asyncParser = this.initAsyncParser(fields, device, type)
    let lineCount = 0

    this.setState({
      csvFileNumberPerDevice: {
        [deviceEid]: 0
      }
    })

    do {
      await getGPSTrackingTimeSeriesToExport(deviceEid, startTimestamp, end, 0, 'asc')
        .then(response => {
          const { remainingTransformedTimeseries } = this.state
          const MAX_DATA_SIZE = 500000
          const timeseries = response?.payload?.data?.content || []
          const page = response?.payload?.data?.page || 0
          const pages = response?.payload?.data?.pages || 1
          const isNextPage = page < pages - 1

          const transformedTimeseries = transformGpsTrackingsData(timeseries)
          const exportData = [...remainingTransformedTimeseries?.[deviceEid] || [], ...transformedTimeseries]
          startTimestamp = isNextPage ? parseInt(Object.keys(exportData[exportData.length - 1])[0]) + 1 : null

          exportData.forEach((timestampObject, k) => {
            if (lineCount < MAX_DATA_SIZE) {
              const timestamp = Object.keys(timestampObject)[0]
              const values = Object.values(timestampObject)[0]
              exportData[k] = null // eslint-disable-line no-param-reassign
              transformedTimeseries.splice(0, 1)
              const data = {}
              data.ts = timestamp || ''
              data.date = new Date(parseInt(timestamp))?.toISOString() || ''
              data.name = device.name
              data.eid = device.EID
              data.type = device.deviceType

              for (let j = 0; j < sensorsArray.length; j++) {
                const sensor = sensorsArray[j]
                const signal = values.find(v => v[0] === sensor.signalId)

                data['value' + sensor.signalId] = signal ? signal[1] : ''
              }
              asyncParser.input.push(JSON.stringify(data))
              lineCount++
            }
          })

          if (lineCount === MAX_DATA_SIZE || !isNextPage) {
            lineCount = 0

            this.setState(
              state => ({
                remainingTransformedTimeseries: {
                  ...state.remainingTransformedTimeseries,
                  [deviceEid]: transformedTimeseries
                },
                csvFileNumberPerDevice: {
                  ...state.csvFileNumberPerDevice,
                  [deviceEid]: state?.csvFileNumberPerDevice?.[deviceEid]
                    ? state?.csvFileNumberPerDevice?.[deviceEid] + 1
                    : 1
                }
              }),
              () => {
                asyncParser.input.push(null)
                asyncParser = this.initAsyncParser(fields, device, type)
              }
            )
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
        })
    } while (startTimestamp && !abortController.signal.aborted)
  }
  // eslint-disable-next-line max-params
  getAggregatedChartTimeSeriesToExportToCsv = async (device, start, end, filter, aggType, bucket) => {
    const { getChartTimeSeriesToExport } = this.props
    const { abortController, metrics } = this.state
    const sensorsArray = metrics
      .find(metric => metric.deviceEid === device.EID)
      .sensors.filter(
        sensor => sensor.bucket === bucket && sensor.signalId !== 'gpsAltitude' && sensor.signalId !== 'gpsSpeed'
      )
    const deviceEid = device.EID.replaceAll(':', '')
    let startTimestamp = start
    const fields = [
      {
        label: 'Timestamp',
        value: 'ts'
      },
      {
        label: 'Date',
        value: 'date'
      },
      {
        label: 'Device name',
        value: 'name'
      },
      {
        label: 'Device EID',
        value: 'eid'
      },
      {
        label: 'Device type',
        value: 'type'
      }
    ]

    for (let i = 0; i < sensorsArray.length; i++) {
      const sensor = sensorsArray[i]
      fields.push(
        {
          label:
            sensor.name +
            ' (' +
            sensor.aggregationType +
            ', ' +
            sensor.bucket +
            ') raw value' +
            (sensor.unit ? ' (' + sensor.unit + ')' : ''),
          value: 'raw' + sensor.signalId
        },
        {
          label:
            sensor.name +
            ' (' +
            sensor.aggregationType +
            ', ' +
            sensor.bucket +
            ') final value' +
            (sensor.unit ? ' (' + sensor.unit + ')' : ''),
          value: 'final' + sensor.signalId
        }
      )
    }

    let type = ''
    switch (bucket) {
      case 'hour':
        type = 'HOURLY'
        break
      case 'day':
        type = 'DAILY'
        break
      case 'month':
        type = 'MONTHLY'
        break
    }
    let asyncParser = this.initAsyncParser(fields, device, type)
    let lineCount = 0

    this.setState({
      csvFileNumberPerDevice: {
        [deviceEid]: 0
      }
    })

    do {
      await getChartTimeSeriesToExport(deviceEid, startTimestamp, end, filter, aggType, bucket)
        .then(response => {
          const { remainingTransformedTimeseries } = this.state
          const MAX_DATA_SIZE = 500000
          const timeseries = response?.payload?.data?.data || []
          const page = response?.payload?.data?.page || 0
          const pages = response?.payload?.data?.pages || 1
          const isNextPage = page < pages - 1

          const transformedTimeseries = transformChartAggData(timeseries, filter, aggType, bucket)
          const exportData = getFormatedAggChartDataToExport(
            [...remainingTransformedTimeseries?.[deviceEid] || [], ...transformedTimeseries],
            device
          )
          startTimestamp = isNextPage ? parseInt(Object.keys(exportData[exportData.length - 1])[0]) + 1 : null

          exportData.forEach((timestampObject, k) => {
            if (lineCount < MAX_DATA_SIZE) {
              const timestamp = Object.keys(timestampObject)[0]
              const values = Object.values(timestampObject)[0]
              exportData[k] = null // eslint-disable-line no-param-reassign
              transformedTimeseries.splice(0, 1)
              const data = {}
              data.ts = timestamp || ''
              data.date = new Date(parseInt(timestamp))?.toISOString() || ''
              data.name = device.name
              data.eid = device.EID
              data.type = device.deviceType

              for (let j = 0; j < sensorsArray.length; j++) {
                const sensor = sensorsArray[j]
                const signal = values.find(v => v[0] === sensor.signalId)

                data['raw' + sensor.signalId] = signal ? signal[1] : ''
                data['final' + sensor.signalId] = signal ? signal[2] : ''
              }
              asyncParser.input.push(JSON.stringify(data))
              lineCount++
            }
          })

          if (lineCount === MAX_DATA_SIZE || !isNextPage) {
            lineCount = 0

            this.setState(
              state => ({
                remainingTransformedTimeseries: {
                  ...state.remainingTransformedTimeseries,
                  [deviceEid]: transformedTimeseries
                },
                csvFileNumberPerDevice: {
                  ...state.csvFileNumberPerDevice,
                  [deviceEid]: state?.csvFileNumberPerDevice?.[deviceEid]
                    ? state?.csvFileNumberPerDevice?.[deviceEid] + 1
                    : 1
                }
              }),
              () => {
                asyncParser.input.push(null)
                asyncParser = this.initAsyncParser(fields, device, type)
              }
            )
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
        })
    } while (startTimestamp && !abortController.signal.aborted)
  }

  // eslint-disable-next-line max-params
  getAggregatedGPSTrackingTimeSeriesToExportToCsv = async (device, start, end, filter, aggType, bucket) => {
    const { getAggregatedGPSTrackingTimeSeriesToExport, groupId } = this.props
    const { abortController, metrics } = this.state
    const sensorsArray = metrics
      .find(metric => metric.deviceEid === device.EID)
      .sensors.filter(
        sensor => sensor.bucket === bucket && (sensor.signalId === 'gpsAltitude' || sensor.signalId === 'gpsSpeed')
      )
    const deviceEid = device.EID.replaceAll(':', '')
    const deviceType = device.deviceType
    let startTimestamp = start
    const fields = [
      {
        label: 'Timestamp',
        value: 'ts'
      },
      {
        label: 'Date',
        value: 'date'
      },
      {
        label: 'Device name',
        value: 'name'
      },
      {
        label: 'Device EID',
        value: 'eid'
      },
      {
        label: 'Device type',
        value: 'type'
      }
    ]

    for (let i = 0; i < sensorsArray.length; i++) {
      const sensor = sensorsArray[i]
      fields.push({
        label:
          sensor.name +
          ' (' +
          sensor.aggregationType +
          ', ' +
          sensor.bucket +
          ')' +
          (sensor.unit ? ' (' + sensor.unit + ')' : ''),
        value: 'value' + sensor.signalId
      })
    }

    let type = ''
    switch (bucket) {
      case 'hour':
        type = 'GPS HOURLY'
        break
      case 'day':
        type = 'GPS DAILY'
        break
      case 'month':
        type = 'GPS MONTHLY'
        break
    }
    let asyncParser = this.initAsyncParser(fields, device, type)
    let lineCount = 0

    this.setState({
      csvFileNumberPerDevice: {
        [deviceEid]: 0
      }
    })

    do {
      await getAggregatedGPSTrackingTimeSeriesToExport(
        deviceEid,
        deviceType,
        groupId,
        startTimestamp,
        end,
        4000,
        0,
        'asc',
        bucket
      )
        .then(response => {
          const { remainingTransformedTimeseries } = this.state
          const MAX_DATA_SIZE = 500000
          const timeseries = response?.payload?.data?.content || []
          const page = response?.payload?.data?.page || 0
          const pages = response?.payload?.data?.pages || 1
          const isNextPage = page < pages - 1

          const transformedTimeseries = transformGpsTrackingsAggData(timeseries, filter, aggType, bucket)
          const exportData = [...remainingTransformedTimeseries?.[deviceEid] || [], ...transformedTimeseries]
          startTimestamp = isNextPage ? parseInt(Object.keys(exportData[exportData.length - 1])[0]) + 1 : null

          exportData.forEach((timestampObject, k) => {
            if (lineCount < MAX_DATA_SIZE) {
              const timestamp = Object.keys(timestampObject)[0]
              const values = Object.values(timestampObject)[0][0]
              exportData[k] = null // eslint-disable-line no-param-reassign
              transformedTimeseries.splice(0, 1)
              const data = {}
              data.ts = timestamp || ''
              data.date = new Date(parseInt(timestamp))?.toISOString() || ''
              data.name = device.name
              data.eid = device.EID
              data.type = device.deviceType

              for (let j = 0; j < sensorsArray.length; j++) {
                const sensor = sensorsArray[j]
                const signalKey = Object.keys(values).find(key => key === sensor.signalId)
                const signal = values[signalKey]
                const aggregationType = sensor.aggregationType

                data['value' + sensor.signalId] = signal ? signal[aggregationType] : ''
              }
              asyncParser.input.push(JSON.stringify(data))
              lineCount++
            }
          })

          if (lineCount === MAX_DATA_SIZE || !isNextPage) {
            lineCount = 0

            this.setState(
              state => ({
                remainingTransformedTimeseries: {
                  ...state.remainingTransformedTimeseries,
                  [deviceEid]: transformedTimeseries
                },
                csvFileNumberPerDevice: {
                  ...state.csvFileNumberPerDevice,
                  [deviceEid]: state?.csvFileNumberPerDevice?.[deviceEid]
                    ? state?.csvFileNumberPerDevice?.[deviceEid] + 1
                    : 1
                }
              }),
              () => {
                asyncParser.input.push(null)
                asyncParser = this.initAsyncParser(fields, device, type)
              }
            )
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
        })
    } while (startTimestamp && !abortController.signal.aborted)
  }

  // eslint-disable-next-line max-params
  getAdvancedSignalResultsToExportToCsv = async (device, signalId, groupId, start, end, metadata) => {
    const { getAdvancedSignalResultsToExport } = this.props
    const { abortController } = this.state

    const deviceEid = device.EID.replaceAll(':', '')
    let startTimestamp = start
    const fields = [
      {
        label: 'Timestamp',
        value: 'ts'
      },
      {
        label: 'Date',
        value: 'date'
      },
      {
        label: 'Device name',
        value: 'name'
      },
      {
        label: 'Device EID',
        value: 'eid'
      },
      {
        label: 'Device type',
        value: 'type'
      },
      {
        label: metadata.measurementName + (metadata.unit ? ' (' + metadata.unit + ')' : ''),
        value: 'measurementValue'
      }
    ]

    const type = metadata.measurementName
    let asyncParser = this.initAsyncParser(fields, device, type)
    let lineCount = 0

    this.setState({
      csvFileNumberPerDevice: {
        [deviceEid]: 0
      }
    })

    if (!abortController.signal.aborted) {
      await getAdvancedSignalResultsToExport(signalId, groupId, startTimestamp, end, metadata, 0, 4000)
        .then(response => {
          const { remainingTransformedTimeseries } = this.state
          const MAX_DATA_SIZE = 500000
          const measurements = response?.payload?.data || []
          const operationType = metadata.operationType
          const page = response?.payload?.data?.page || 0
          const pages = response?.payload?.data?.pages || 1
          const isNextPage = page < pages - 1

          const transformedTimeseries = transformAdvancedSignalsData(measurements, operationType)
          const exportData = [...remainingTransformedTimeseries?.[deviceEid] || [], ...transformedTimeseries]
          startTimestamp = isNextPage ? parseInt(Object.keys(exportData[exportData.length - 1])[0]) + 1 : null

          exportData.forEach((measurement, k) => {
            if (lineCount < MAX_DATA_SIZE) {
              const timestamp = measurement[0]
              const value = measurement[1]
              exportData[k] = null // eslint-disable-line no-param-reassign
              transformedTimeseries.splice(0, 1)
              const data = {}
              data.ts = timestamp || ''
              data.date = new Date(parseInt(timestamp))?.toISOString() || ''
              data.name = device.name
              data.eid = device.EID
              data.type = device.deviceType
              data.measurementValue = value

              asyncParser.input.push(JSON.stringify(data))
              lineCount++
            }
          })
          if (lineCount === MAX_DATA_SIZE || !isNextPage) {
            lineCount = 0

            this.setState(
              state => ({
                remainingTransformedTimeseries: {
                  ...state.remainingTransformedTimeseries,
                  [deviceEid]: transformedTimeseries
                },
                csvFileNumberPerDevice: {
                  ...state.csvFileNumberPerDevice,
                  [deviceEid]: state?.csvFileNumberPerDevice?.[deviceEid]
                    ? state?.csvFileNumberPerDevice?.[deviceEid] + 1
                    : 1
                }
              }),
              () => {
                asyncParser.input.push(null)
                asyncParser = this.initAsyncParser(fields, device, type)
              }
            )
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
        })
    }
  }

  handleSearch = (start, end) => {
    const {
      getAdvancedSignalResults,
      getAggregatedGPSTrackingTimeSeries,
      getChartTimeSeries,
      getGPSTrackingTimeSeries,
      groupId,
      resetChart,
      setSearchFilters
    } = this.props
    const { devices, metrics } = this.state

    this.closeAlert()

    if (metrics[0].selectedSensorsIds.length > 0) {
      resetChart()
      this.setState({ isChartLoading: true })

      metrics.forEach(metric => {
        const device = devices.find(item => item.id === metric.device) || {}
        const cleanEID = device.EID.replaceAll(':', '')
        const filters = this.getSearchFilters(metric)
        setSearchFilters(metric.device, filters)

        Object.keys(filters).forEach(bucketKey => {
          if (bucketKey === 'raw') {
            if (filters[bucketKey].filter) {
              getChartTimeSeries(cleanEID, moment(start), moment(end), filters[bucketKey].filter, false)
            }
            if (filters[bucketKey].gpsFilter) {
              getGPSTrackingTimeSeries(cleanEID, moment(start).valueOf(), moment(end).valueOf(), 0, 'asc')
            }
            if (filters[bucketKey].advancedSignalFilter) {
              const advancedSignals = filters[bucketKey].advancedSignalFilter
              advancedSignals.forEach(signalId => {
                const sensor = metric.sensors.find(s => s.signalId === signalId)
                const data = {
                  deviceEid: cleanEID,
                  measurementName: sensor.name,
                  operationType: sensor.operationType,
                  unit: sensor.unit,
                  deviceHashId: signalId,
                  min: moment(start).valueOf(),
                  max: moment(end).valueOf()
                }
                getAdvancedSignalResults(
                  signalId,
                  groupId,
                  moment(start).valueOf(),
                  moment(end).valueOf(),
                  data,
                  0,
                  4000
                )
              })
            }
          } else {
            setTimeout(() => {
              if (filters[bucketKey].filter) {
                getChartTimeSeries(
                  cleanEID,
                  moment(start),
                  moment(end),
                  filters[bucketKey].filter,
                  filters[bucketKey].aggType,
                  bucketKey
                )
              }
              if (filters[bucketKey].gpsFilter) {
                getAggregatedGPSTrackingTimeSeries(
                  cleanEID,
                  device.deviceType,
                  groupId,
                  moment(start).valueOf(),
                  moment(end).valueOf(),
                  4000,
                  0,
                  'asc',
                  bucketKey
                )
              }
            }, 500)
          }
        })
      })
    } else {
      this.setState({
        alertMessages: true,
        alertMessagesType: 'danger',
        alertMessagesTitle: this.formatMessage(messages.errorNoMachineTitle),
        alertMessagesText: [this.formatMessage(messages.errorNoMachineMessage)]
      })
    }
  }

  handleMetricsUpdate = newMetrics => {
    const { devices } = this.state

    const colors = devices.reduce((ret, device) => {
      const deviceMetric = newMetrics.find(metric => metric.device === device.id)

      let newColors = []
      if (deviceMetric) {
        newColors = deviceMetric.sensors.map(sensor => {
          return {
            seriesName: `${device.name}_${sensor.name}${
              sensor.valueType !== 'last' ? '_' + sensor.valueType : ''
            } agg(${sensor.aggregationType}, ${sensor.bucket})`,
            color: sensor.lineColor,
            type: sensor.lineStyle
          }
        })
      }

      return [...ret, ...newColors]
    }, [])

    let metricsCount = 0
    newMetrics.forEach(metric => metricsCount += metric.selectedSensorsIds.length)

    this.setState({ metrics: newMetrics, chartColors: colors, metricsCount })
  }

  handleAddDeviceClick = () => {
    const { metrics } = this.state
    const newMetrics = [...metrics, this.getNewMetric()]
    this.handleMetricsUpdate(newMetrics)
  }

  createSelectMenu = (itemList = []) => {
    return itemList.map((elem, i) => {
      return <MenuItem key={i} primaryText={elem.label} value={elem.value} />
    })
  }

  renderisChartLoadingAndError = () => {
    const { alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText } = this.state

    if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  render() {
    const { classes, groupId, isSidebarCollapsed } = this.props
    const {
      chartColors,
      devices,
      from,
      isChartLoading,
      isConfigurationLoading,
      isExportLoading,
      loading,
      metrics,
      metricsCount,
      to
    } = this.state

    return (
      <div className='container-fluid'>
        {this.renderisChartLoadingAndError()}

        <Grid container spacing={3} style={{ minWidth: '550px' }}>
          <ChartArea
            colors={chartColors}
            groupId={groupId}
            isChartLoading={isChartLoading}
            isRetrieveCsvDataButtonLoading={isExportLoading}
            isSidebarCollapsed={isSidebarCollapsed}
            onExport={(start, end) => this.handleDataToExport(start, end)}
            onSearch={this.handleSearch}
            resetExportDataQueryStatus={this.resetExportDataQueryStatus}
          />
          <Grid item xs={12}>
            <Paper style={{ borderRadius: 0 }}>
              <Card>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CardHeader style={{ paddingBottom: '0px' }} title={this.formatMessage(messages.metrics)} />
                  </Grid>
                </Grid>
                <CardContent>
                  {loading ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <ChartMetrics
                        devices={devices}
                        getDeviceConfiguration={this.getDeviceConfiguration}
                        getGpsSignals={getGpsSignals}
                        getNewMetric={this.getNewMetric}
                        isConfigurationLoading={isConfigurationLoading}
                        metrics={metrics}
                        onMetricsUpdate={this.handleMetricsUpdate}
                      />
                      <span className={classes.addDevice} onClick={this.handleAddDeviceClick}>
                        <Icon className='zmdi zmdi-plus' style={{ fontSize: '14px' }} />
                        {this.formatMessage(messages.addMachine)}
                      </span>
                    </React.Fragment>
                  )}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
        <Dialog onClose={(event, reason) => this.handleDialogClose(reason)} open={isExportLoading}>
          <DialogTitle>Exporting data to CSV files</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.formatMessage(messages.longProcessAlert)}</DialogContentText>
            <DialogContentText>{this.formatMessage(messages.fileMaxSizeAlert, { maxSize: 500000 })}</DialogContentText>
            <DialogContentText>
              {this.formatMessage(messages.selectedDataRange, {
                from: moment(from).format('DD-MMM-YYYY HH:mm'),
                to: moment(to).format('DD-MMM-YYYY HH:mm')
              })}
            </DialogContentText>
            <DialogContentText>{this.formatMessage(messages.selectedSignalAmount, { metricsCount })}</DialogContentText>
            <Loading />
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={(event, reason) => this.handleDialogClose(reason)}>
              {this.formatMessage(messages.cancel)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ChartConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchDevice: PropTypes.func.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getAdvancedSignalResults: PropTypes.func.isRequired,
  getAdvancedSignalResultsToExport: PropTypes.func.isRequired,
  getAdvancedSignals: PropTypes.func.isRequired,
  getAggregatedGPSTrackingTimeSeries: PropTypes.func.isRequired,
  getAggregatedGPSTrackingTimeSeriesToExport: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  getChartTimeSeries: PropTypes.func.isRequired,
  getChartTimeSeriesToExport: PropTypes.func.isRequired,
  getGPSTrackingTimeSeries: PropTypes.func.isRequired,
  getGPSTrackingTimeSeriesToExport: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isAdvancedSignalsLoading: PropTypes.bool.isRequired,
  isAggregatedGpsTrackingsLoading: PropTypes.bool.isRequired,
  isAggregatedSignalsLoading: PropTypes.bool.isRequired,
  isGpsTrackingsLoading: PropTypes.bool.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  isSignalsLoading: PropTypes.bool.isRequired,
  queryStatus: PropTypes.object,
  resetChart: PropTypes.func.isRequired,
  setSearchFilters: PropTypes.func.isRequired
}

ChartConfig.defaultProps = {
  queryStatus: undefined
}

export default injectIntl(withStyles(styles)(ChartConfig))
