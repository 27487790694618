// Directly setting min and max in config did not worked.
const initialState = {
  forcedZoom: [],
  history: [],
  selectableLevels: [
    { value: 1, label: '1s' },
    { value: 5, label: '5s' },
    { value: 30, label: '30s' },
    { value: 60, label: '1m' },
    { value: 3600, label: '1h' },
    { value: 36000, label: '10h' },
    { value: -1, label: localStorage.getItem('user_language') === 'en' ? 'All': 'Todo' }
  ],
  selectedLevel: -1,
  maxDate: -1
}

export default initialState
