import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import EditReportForm from './EditReportForm'
import EditReportFormTitle from './EditReportFormTitle'
import messages from './messages'
import { transformValuesFromAPI } from '../NewReport/config'

import { logError } from 'utils/http'

class EditReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alertMessagesReport: false,
      alertMessagesTextReport: [''],
      alertMessagesTitleReport: '',
      alertMessagesTypeReport: 'danger',
      loadingReport: true,
      report: {}
    }
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const {
      clearChart,
      getSchedule,
      groupId,
      intl: { locale },
      reportId
    } = this.props
    clearChart()

    getSchedule(reportId, groupId)
      .then(({ payload }) => {
        const data = transformValuesFromAPI(payload.data)
        this.setState({
          report: data,
          loadingReport: false,
          alertMessagesTypeReport: 'danger',
          alertMessagesTitleReport: '',
          alertMessagesTextReport: ['']
        })
      })
      .catch(({ error }) => {
        const { response } = { ...error }
        if (response) {
          switch (response.status) {
            case 400:
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesTextReport: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401:
              const message = locale === 'en' ? error.response.message : this.formatMessage(messages.error401Message)
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesTextReport: [message]
              })
              break
            case 403:
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesTextReport: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404:
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesTextReport: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406:
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesTextReport: [this.formatMessage(messages.error406Message)]
              })
              break
            case 500:
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessagesTextReport: [this.formatMessage(messages.error500Message)]
              })
              break
            default:
              this.setState({
                loadingReport: false,
                alertMessagesReport: true,
                alertMessagesTypeReport: 'danger',
                alertMessagesTitleReport: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextReport: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
          }
        }
      })
  }

  componentWillUnmount() {
    const { clearChart } = this.props

    clearChart()
  }

  renderReportTableLoadingAndError = () => {
    const {
      alertMessagesReport,
      alertMessagesTypeReport,
      alertMessagesTitleReport,
      alertMessagesTextReport,
      loadingReport
    } = this.state

    if (loadingReport) {
      return <Loading />
    } else if (alertMessagesReport) {
      return (
        <Alert
          alertType={alertMessagesTypeReport}
          messageText={alertMessagesTextReport}
          messageTitle={alertMessagesTitleReport}
          showAlert={alertMessagesReport}
        />
      )
    }
  }

  renderReportTablecontent = () => {
    const {
      clearChart,
      fetchDeviceNonVolatileConfiguration,
      getAggregatedGPSTrackingTimeSeries,
      getAssets,
      getChartTimeSeries,
      groupId,
      isAggregatedGpsTrackingsLoading,
      isAggregatedSignalsLoading,
      onEditReport,
      plus1ConnectUserId,
      queryStatus,
      reportId,
      reportsUrl,
      setSearchFilters,
      updateSchedule,
      updateScheduleAWS
    } = this.props
    const { alertMessagesReport, loadingReport, report } = this.state

    if (loadingReport || alertMessagesReport) {
      return <div className='container-fluid'>{this.renderReportTableLoadingAndError()}</div>
    } else {
      return (
        <EditReportForm
          action={onEditReport}
          clearChart={clearChart}
          fetchDeviceNonVolatileConfiguration={fetchDeviceNonVolatileConfiguration}
          getAggregatedGPSTrackingTimeSeries={getAggregatedGPSTrackingTimeSeries}
          getAssets={getAssets}
          getChartTimeSeries={getChartTimeSeries}
          groupId={groupId}
          isAggregatedGpsTrackingsLoading={isAggregatedGpsTrackingsLoading}
          isAggregatedSignalsLoading={isAggregatedSignalsLoading}
          plus1ConnectUserId={plus1ConnectUserId}
          queryStatus={queryStatus}
          reportId={reportId}
          reportsUrl={reportsUrl}
          schedule={report}
          setSearchFilters={setSearchFilters}
          updateSchedule={updateSchedule}
          updateScheduleAWS={updateScheduleAWS}
        />
      )
    }
  }

  render() {
    const { reportsUrl } = this.props
    const { report } = this.state

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <EditReportFormTitle reportsUrl={reportsUrl} title={report.name} />
          </div>
          {this.renderReportTablecontent()}
        </div>
      </div>
    )
  }
}

EditReport.propTypes = {
  clearChart: PropTypes.func.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getAggregatedGPSTrackingTimeSeries: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  getChartTimeSeries: PropTypes.func.isRequired,
  getSchedule: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired, locale: PropTypes.string.isRequired }).isRequired,
  isAggregatedGpsTrackingsLoading: PropTypes.bool.isRequired,
  isAggregatedSignalsLoading: PropTypes.bool.isRequired,
  onEditReport: PropTypes.func.isRequired,
  plus1ConnectUserId: PropTypes.string.isRequired,
  queryStatus: PropTypes.object,
  reportId: PropTypes.string.isRequired,
  reportsUrl: PropTypes.string.isRequired,
  setSearchFilters: PropTypes.func.isRequired,
  updateSchedule: PropTypes.func.isRequired,
  updateScheduleAWS: PropTypes.func.isRequired
}

EditReport.defaultProps = {
  queryStatus: undefined
}

export default injectIntl(EditReport)
