import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import EmailIcon from '@material-ui/icons/Email'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MobileOffIcon from '@material-ui/icons/MobileOff'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import RoomIcon from '@material-ui/icons/Room'
import SmartphoneIcon from '@material-ui/icons/Smartphone'

import SmsServiceStateChip from 'components/SmsServiceStateChip'
import client from 'utils/http/client'

import { getAdaptedDetailedUsers, getAdaptedNotificationActions } from '../../apiAdapters'
import ServerErrorAlert from '../../components/alerts/ServerErrorAlert'
import NotificationStateChip from '../../components/chips/NotificationStateChip'
import { NOTIFICATION_TYPES, SEVERITY_LEVELS } from '../../constants'
import { ReactComponent as EmailOffIcon } from '../../icon-mail-off.svg'
import messages from '../../messages'
import { iconButtonStyles, iconStyles, paperStyles, tooltipStyles } from '../../styles'
import { getTableOptions } from '../../tableHelpers'

const styles = {
  ...iconButtonStyles,
  ...iconStyles,
  ...paperStyles,
  ...tooltipStyles,
  menuItemIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '10px'
  }
}

class NotificationSettingsStep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUsersLoading: false,
      allUsersData: [],

      isAssignedUsersLoading: false,
      assignedUsersData: [],

      isNotificationActionsLoading: false,
      notificationActionsData: [],

      selectedUsersEmails: [],

      isApiStepFetchError: false,

      currentPage: 1,
      currentPageSize: 10,
      totalSize: 0
    }

    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.fetchAssignedUsersData().then(this.fetchNotificationActions)
  }

  componentDidUpdate(prevProps) {
    const { isSubStep } = this.props

    if (isSubStep && prevProps.isSubStep !== isSubStep) {
      this.tableRef.current.cleanSort()
      this.setState({ currentPage: 1, totalSize: 0 }, () => this.fetchAllUsersData())
    }
    if (!isSubStep && prevProps.isSubStep !== isSubStep) {
      this.setState({ currentPage: 1, totalSize: 0 }, () => this.fetchAssignedUsersData())
    }
  }

  fetchAssignedUsersData = () => {
    const { assignedUsers, fetchUsersOfGroupDetails, groupId } = this.props

    const userEmails = assignedUsers.map(user => user.email)

    this.setState({ isAssignedUsersLoading: true, isApiStepFetchError: false })
    return fetchUsersOfGroupDetails(groupId, userEmails)
      .then(response => {
        const assignedUsersData = getAdaptedDetailedUsers(response?.data?.users)

        this.setState({ assignedUsersData, totalSize: assignedUsersData.length })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
        return true
      })
      .finally((isApiStepFetchError = false) => {
        this.setState({ isAssignedUsersLoading: false })
        return isApiStepFetchError
      })
  }

  fetchAllUsersData = () => {
    const { fetchDetailedUsers, groupId } = this.props
    const { currentPage, currentPageSize } = this.state

    const offset = (currentPage - 1) * currentPageSize

    this.setState({ allUsersData: [], isUsersLoading: true, isApiStepFetchError: false })
    fetchDetailedUsers(groupId, currentPageSize, offset)
      .then(response => {
        const allUsersData = getAdaptedDetailedUsers(response?.data?.groupUsers?.users)
        const totalNumberOfUsers = response?.data?.groupUsers?.total

        this.setState({ allUsersData, totalSize: totalNumberOfUsers })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
      })
      .finally(() => {
        this.setState({ isUsersLoading: false })
      })
  }

  fetchNotificationActions = (isApiStepFetchError = false) => {
    const { groupId } = this.props

    if (isApiStepFetchError) return

    this.setState({ isNotificationActionsLoading: true, isApiStepFetchError: false })
    client
      .getNotificationsActions(groupId)
      .then(response => {
        const notificationActionsData = getAdaptedNotificationActions(response.data)

        this.setState({ notificationActionsData })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
      })
      .finally(() => {
        this.setState({ isNotificationActionsLoading: false })
      })
  }

  getTableOptions = () => {
    const { intl } = this.props
    const { isAssignedUsersLoading, isNotificationActionsLoading, isUsersLoading, currentPage, currentPageSize } =
      this.state

    return getTableOptions({
      intl,
      isLoading: isUsersLoading || isAssignedUsersLoading || isNotificationActionsLoading,
      page: currentPage,
      pageSize: currentPageSize,

      onPageChange: this.handlePageChange
    })
  }

  getTableData = () => {
    const { assignedUsers, isSubStep } = this.props
    const { allUsersData, assignedUsersData, notificationActionsData } = this.state

    if (isSubStep) {
      return allUsersData
    } else {
      return assignedUsers.map(user => {
        const userData = assignedUsersData.find(item => item.email === user.email) || {}
        const notificationIds = user.notifications.map(item => item.hashId)
        const { hashId: bellNotificationId } =
          notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.BELL) || {}
        const { hashId: emailNotificationId } =
          notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.EMAIL) || {}
        const { hashId: smsNotificationId } =
          notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.SMS) || {}
        const hasBellNotification = notificationIds.includes(bellNotificationId)
        const hasEmailNotification = notificationIds.includes(emailNotificationId)
        const hasSmsNotification = notificationIds.includes(smsNotificationId)

        return {
          email: user.email,
          name: userData.name,
          lastName: userData.lastName,
          smsService: userData.smsService,
          hasBellNotification,
          hasEmailNotification,
          hasSmsNotification
        }
      })
    }
  }

  handleSeverityChange = e => {
    const { onSeverityChange } = this.props

    const value = e.target.value

    onSeverityChange(value)
  }

  handleEditClick = () => {
    const { assignedUsers, onSubStepEnterClick } = this.props

    const assignedUsersEmails = assignedUsers.map(user => user.email)

    this.setState({ selectedUsersEmails: [...assignedUsersEmails] })
    onSubStepEnterClick()
  }

  handleClearSelectionClick = () => {
    this.setState({ selectedUsersEmails: [] })
  }

  handleOkClick = () => {
    const { assignedUsers, onAssignedUsersChange, onSubStepExitClick } = this.props
    const { allUsersData, notificationActionsData, selectedUsersEmails } = this.state

    const newAssignedUsers = selectedUsersEmails.map(userEmail => {
      const isUserAlreadyAssigned = assignedUsers.map(user => user.email).includes(userEmail)
      if (isUserAlreadyAssigned) {
        const userData = assignedUsers.find(user => user.email === userEmail) || {}

        return userData
      } else {
        const userData = allUsersData.find(user => user.email === userEmail) || {}
        const { hashId: bellNotificationId } =
          notificationActionsData.find(item => item.type === NOTIFICATION_TYPES.BELL) || {}

        return {
          id: userData.id,
          email: userData.email,
          notifications: [{ hashId: bellNotificationId }]
        }
      }
    })

    this.setState({ selectedUsersEmails: [] })
    onAssignedUsersChange(newAssignedUsers)
    onSubStepExitClick()
  }

  handleCancelClick = () => {
    const { onSubStepExitClick } = this.props

    this.setState({ selectedUsersEmails: [] })
    onSubStepExitClick()
  }

  handleRowSelect = (row, isSelected) => {
    const { selectedUsersEmails } = this.state

    let newSelectedUsersEmails
    if (isSelected) {
      newSelectedUsersEmails = [...selectedUsersEmails, row.email]
    } else {
      newSelectedUsersEmails = selectedUsersEmails.filter(email => email !== row.email)
    }

    this.setState({ selectedUsersEmails: newSelectedUsersEmails })
  }

  handleAllRowsSelect = (isSelected, rows) => {
    const { selectedUsersEmails } = this.state

    let newSelectedUsersEmails
    if (isSelected) {
      newSelectedUsersEmails = [...selectedUsersEmails, ...rows.map(row => row.email)]
    } else {
      newSelectedUsersEmails = selectedUsersEmails.filter(email => !rows.map(row => row.email).includes(email))
    }

    this.setState({ selectedUsersEmails: newSelectedUsersEmails })
  }

  handleDisableNotificationsClick = (userEmails, notificationType) => {
    const { assignedUsers, onAssignedUsersChange } = this.props
    const { notificationActionsData } = this.state

    const { hashId: notificationTypeId } = notificationActionsData.find(item => item.type === notificationType) || {}

    const newAssignedUsers = assignedUsers.map(user => {
      if (userEmails.includes(user.email)) {
        return {
          ...user,
          notifications: user.notifications.filter(notification => notification.hashId !== notificationTypeId)
        }
      } else {
        return user
      }
    })

    onAssignedUsersChange(newAssignedUsers)
  }

  handleEnableNotificationsClick = (userEmails, notificationType) => {
    const { assignedUsers, onAssignedUsersChange } = this.props
    const { notificationActionsData } = this.state

    const { hashId: notificationTypeId } = notificationActionsData.find(item => item.type === notificationType) || {}

    const newAssignedUsers = assignedUsers.map(user => {
      if (userEmails.includes(user.email)) {
        const isNotificationTypeAssigned = user.notifications
          .map(notification => notification.hashId)
          .includes(notificationTypeId)

        return {
          ...user,
          notifications: [
            ...user.notifications,
            ...isNotificationTypeAssigned ? [] : [{ hashId: notificationTypeId }]
          ]
        }
      } else {
        return user
      }
    })

    onAssignedUsersChange(newAssignedUsers)
  }

  handlePageChange = (page, pageSize) => {
    const { isSubStep } = this.props
    this.setState({ currentPage: page, currentPageSize: pageSize }, () => {
      if (isSubStep) {
        this.fetchAllUsersData()
      }
    })
  }

  formatSmsServiceColumn = state => {
    return <SmsServiceStateChip state={state} />
  }

  formatNotificationsColumn = isEnabled => {
    return <NotificationStateChip isEnabled={isEnabled} />
  }

  formatActionsColumn = (cell, row) => {
    const { classes, intl, isApiFetchError } = this.props
    const { isApiStepFetchError } = this.state

    const isApiError = isApiFetchError || isApiStepFetchError
    const isActionsButtonsDisabled = isApiError

    return (
      <div>
        {row.hasBellNotification ? (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.disableBellNotifications)}>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              disabled={isActionsButtonsDisabled}
              onClick={() => this.handleDisableNotificationsClick([row.email], NOTIFICATION_TYPES.BELL)}
            >
              <NotificationsOffIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.enableBellNotifications)}>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              disabled={isActionsButtonsDisabled}
              onClick={() => this.handleEnableNotificationsClick([row.email], NOTIFICATION_TYPES.BELL)}
            >
              <NotificationsIcon />
            </IconButton>
          </Tooltip>
        )}
        {row.hasEmailNotification ? (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={intl.formatMessage(messages.disableEmailNotifications)}
          >
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              disabled={isActionsButtonsDisabled}
              onClick={() => this.handleDisableNotificationsClick([row.email], NOTIFICATION_TYPES.EMAIL)}
            >
              <SvgIcon component={EmailOffIcon} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.enableEmailNotifications)}>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              disabled={isActionsButtonsDisabled}
              onClick={() => this.handleEnableNotificationsClick([row.email], NOTIFICATION_TYPES.EMAIL)}
            >
              <EmailIcon />
            </IconButton>
          </Tooltip>
        )}
        {row.hasSmsNotification ? (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.disableSmsNotifications)}>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              disabled={isActionsButtonsDisabled}
              onClick={() => this.handleDisableNotificationsClick([row.email], NOTIFICATION_TYPES.SMS)}
            >
              <MobileOffIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.enableSmsNotifications)}>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              disabled={isActionsButtonsDisabled}
              onClick={() => this.handleEnableNotificationsClick([row.email], NOTIFICATION_TYPES.SMS)}
            >
              <SmartphoneIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    )
  }

  render() {
    const { assignedUsers, classes, intl, isApiFetchError, isSubStep, severity } = this.props
    const {
      isApiStepFetchError,
      isAssignedUsersLoading,
      isNotificationActionsLoading,
      isUsersLoading,
      selectedUsersEmails,
      totalSize
    } = this.state

    const isApiError = isApiFetchError || isApiStepFetchError
    const isLoading = isUsersLoading || isAssignedUsersLoading || isNotificationActionsLoading

    const isSeveritySelectorDisabled = isApiError || isLoading
    const isMultiRowActionsButtonDisabled = isApiError || isLoading || selectedUsersEmails.length === 0
    const isEditButtonDisabled = isApiError || isLoading
    const isOkButtonDisabled = isApiError || isLoading

    const tableData = this.getTableData()
    const tableOptions = this.getTableOptions()

    return (
      <Grid item xs={12}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Grid container spacing={3}>
            {isApiError && (
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography component='h2' variant='h5'>
                {intl.formatMessage(messages.notificationSettings)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>{intl.formatMessage(messages.severity)}</InputLabel>
                <Select disabled={isSeveritySelectorDisabled} onChange={this.handleSeverityChange} value={severity}>
                  <MenuItem value={SEVERITY_LEVELS.LOW}>{intl.formatMessage(messages.low)}</MenuItem>
                  <MenuItem value={SEVERITY_LEVELS.MEDIUM}>{intl.formatMessage(messages.medium)}</MenuItem>
                  <MenuItem value={SEVERITY_LEVELS.HIGH}>{intl.formatMessage(messages.high)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid alignContent='flex-end' container item xs={9}>
              {severity === SEVERITY_LEVELS.LOW && (
                <React.Fragment>
                  <Typography variant='body1'>{intl.formatMessage(messages.severityLowClarification)}</Typography>
                  <NotificationsIcon classes={{ root: classes.iconInfo }} />
                  <RoomIcon classes={{ root: classes.iconInfo }} />
                </React.Fragment>
              )}
              {severity === SEVERITY_LEVELS.MEDIUM && (
                <React.Fragment>
                  <Typography variant='body1'>{intl.formatMessage(messages.severityMediumClarification)}</Typography>
                  <NotificationsIcon classes={{ root: classes.iconWarning }} />
                  <RoomIcon classes={{ root: classes.iconWarning }} />
                </React.Fragment>
              )}
              {severity === SEVERITY_LEVELS.HIGH && (
                <React.Fragment>
                  <Typography variant='body1'>{intl.formatMessage(messages.severityHighClarification)}</Typography>
                  <NotificationsIcon classes={{ root: classes.iconDanger }} />
                  <RoomIcon classes={{ root: classes.iconDanger }} />
                </React.Fragment>
              )}
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={12}>
                <Typography component='h3' variant='h6'>
                  {intl.formatMessage(messages.assignedUsers)}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item>
                  <Button disabled>
                    {intl.formatMessage(messages.selected)} ({selectedUsersEmails.length})
                  </Button>
                  <Button
                    className='secondary-action-button'
                    onClick={this.handleClearSelectionClick}
                    style={{ marginRight: 5 }}
                  >
                    {intl.formatMessage(messages.clearSelection)}
                  </Button>
                  {!isSubStep && (
                    <PopupState variant='popover'>
                      {popupState => (
                        <React.Fragment>
                          <Button
                            {...bindTrigger(popupState)}
                            className='secondary-action-button'
                            disabled={isMultiRowActionsButtonDisabled}
                            endIcon={<ArrowDropDownIcon />}
                            startIcon={<FormatListBulletedIcon />}
                            style={{ marginRight: 5 }}
                          >
                            {intl.formatMessage(messages.actions)}
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            getContentAnchorEl={null}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          >
                            <MenuItem
                              onClick={(...params) => {
                                this.handleEnableNotificationsClick(selectedUsersEmails, NOTIFICATION_TYPES.BELL)
                                popupState.close(...params)
                              }}
                            >
                              <NotificationsIcon classes={{ root: classes.menuItemIcon }} />
                              {intl.formatMessage(messages.enableBellNotifications)}
                            </MenuItem>
                            <MenuItem
                              onClick={(...params) => {
                                this.handleDisableNotificationsClick(selectedUsersEmails, NOTIFICATION_TYPES.BELL)
                                popupState.close(...params)
                              }}
                            >
                              <NotificationsOffIcon classes={{ root: classes.menuItemIcon }} />{' '}
                              {intl.formatMessage(messages.disableBellNotifications)}
                            </MenuItem>
                            <MenuItem
                              onClick={(...params) => {
                                this.handleEnableNotificationsClick(selectedUsersEmails, NOTIFICATION_TYPES.EMAIL)
                                popupState.close(...params)
                              }}
                            >
                              <EmailIcon classes={{ root: classes.menuItemIcon }} />
                              {intl.formatMessage(messages.enableEmailNotifications)}
                            </MenuItem>
                            <MenuItem
                              onClick={(...params) => {
                                this.handleDisableNotificationsClick(selectedUsersEmails, NOTIFICATION_TYPES.EMAIL)
                                popupState.close(...params)
                              }}
                            >
                              <SvgIcon classes={{ root: classes.menuItemIcon }} component={EmailOffIcon} />
                              {intl.formatMessage(messages.disableEmailNotifications)}
                            </MenuItem>
                            <MenuItem
                              onClick={(...params) => {
                                this.handleEnableNotificationsClick(selectedUsersEmails, NOTIFICATION_TYPES.SMS)
                                popupState.close(...params)
                              }}
                            >
                              <SmartphoneIcon classes={{ root: classes.menuItemIcon }} />
                              {intl.formatMessage(messages.enableSmsNotifications)}
                            </MenuItem>
                            <MenuItem
                              onClick={(...params) => {
                                this.handleDisableNotificationsClick(selectedUsersEmails, NOTIFICATION_TYPES.SMS)
                                popupState.close(...params)
                              }}
                            >
                              <MobileOffIcon classes={{ root: classes.menuItemIcon }} />
                              {intl.formatMessage(messages.disableSmsNotifications)}
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  )}
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Grid container justifyContent='flex-end'>
                    {isSubStep ? (
                      <React.Fragment>
                        <Button onClick={this.handleCancelClick}>{intl.formatMessage(messages.cancel)}</Button>
                        <Button
                          className='primary-action-button'
                          disabled={isOkButtonDisabled}
                          onClick={this.handleOkClick}
                          startIcon={<DoneIcon />}
                        >
                          {intl.formatMessage(messages.ok)}
                        </Button>
                      </React.Fragment>
                    ) : (
                      <Button
                        className='primary-action-button'
                        disabled={isEditButtonDisabled}
                        onClick={this.handleEditClick}
                        startIcon={assignedUsers.length === 0 ? <AddIcon /> : <EditIcon />}
                      >
                        {assignedUsers.length === 0
                          ? intl.formatMessage(messages.assignUsers)
                          : intl.formatMessage(messages.edit)}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className='table-with-pagination' style={{ padding: 0 }}>
                    <BootstrapTable
                      ref={this.tableRef}
                      bordered={false}
                      data={isLoading ? [] : tableData}
                      fetchInfo={{ dataTotalSize: totalSize }}
                      hover
                      keyField='email'
                      options={tableOptions}
                      pagination
                      remote={remoteObj => ({
                        ...remoteObj,
                        search: false,
                        pagination: true,
                        sizePerPage: true,
                        sort: false,
                        filter: true
                      })}
                      selectRow={{
                        mode: 'checkbox',
                        onSelect: this.handleRowSelect,
                        onSelectAll: this.handleAllRowsSelect,
                        bgColor: '#f5f5f5',
                        selected: selectedUsersEmails
                      }}
                    >
                      <TableHeaderColumn dataField='name' dataSort>
                        {intl.formatMessage(messages.name)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='lastName' dataSort>
                        {intl.formatMessage(messages.lastName)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='email' dataSort>
                        {intl.formatMessage(messages.email)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='smsService' dataFormat={this.formatSmsServiceColumn} dataSort>
                        {intl.formatMessage(messages.smsService)}
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='hasBellNotification'
                        dataFormat={this.formatNotificationsColumn}
                        hidden={isSubStep}
                      >
                        {intl.formatMessage(messages.bellNotifications)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='hasEmailNotification'
                        dataFormat={this.formatNotificationsColumn}
                        hidden={isSubStep}
                      >
                        {intl.formatMessage(messages.emailNotifications)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='hasSmsNotification'
                        dataFormat={this.formatNotificationsColumn}
                        hidden={isSubStep}
                      >
                        {intl.formatMessage(messages.smsNotifications)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign='center'
                        dataField='actions'
                        dataFormat={this.formatActionsColumn}
                        headerAlign='center'
                        hidden={isSubStep}
                        width='180'
                      >
                        {intl.formatMessage(messages.actions)}
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

NotificationSettingsStep.propTypes = {
  assignedUsers: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  fetchDetailedUsers: PropTypes.func.isRequired,
  fetchUsersOfGroupDetails: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isApiFetchError: PropTypes.bool.isRequired,
  isSubStep: PropTypes.bool.isRequired,
  onAssignedUsersChange: PropTypes.func.isRequired,
  onSeverityChange: PropTypes.func.isRequired,
  onSubStepEnterClick: PropTypes.func.isRequired,
  onSubStepExitClick: PropTypes.func.isRequired,
  severity: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(NotificationSettingsStep))
