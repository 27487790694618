const TextTemplate = {
  id: '',
  width: 300,
  height: 30,
  x: 20,
  y: 20,
  minHeight: undefined,
  minWidth: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'text',
    params: {
      text: 'Text',
      size: 14,
      bold: 'bold',
      align: 'left',
      data: '',
      valueType: 'last',
      value: {
        timestamp: '',
        value: ''
      },
      conditionalParams: {
        operator: '',
        value: '',
        text: '',
        size: 14,
        bold: 'bold',
        align: 'left'
      }
    }
  }
}

export default TextTemplate
