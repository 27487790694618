import React from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'lodash/isEqual'

import { withGoogleMap, GoogleMap } from 'react-google-maps'

const defaultStyles = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }]
  }
]

const gmapsOptions = {
  keyboardShortcuts: false,
  mapTypeControl: false,
  streetViewControl: false,
  minZoom: 2,
  maxZoom: 18,
  styles: defaultStyles
}

class EnhancedMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      zoom: props.zoom
    }
    this.mapRef = React.createRef()
  }

  componentDidMount() {
    const { bounds } = this.props
    if (bounds) {
      this.mapRef.current.fitBounds(bounds)
    }
  }

  componentDidUpdate(prevProps) {
    const { bounds, zoom } = this.props

    if (bounds && !_isEqual(prevProps.bounds, bounds)) {
      // fitBounds changes the zoom
      const prevZoom = this.mapRef.current.getZoom()
      this.mapRef.current.fitBounds(bounds)
      this.setState({
        zoom: prevZoom
      })
    }
    if (prevProps.zoom !== zoom) {
      this.setState({
        zoom
      })
    }
  }

  handleZoomChanged = () => {
    const { onZoomChanged } = this.props

    if (onZoomChanged) {
      const newZoomLevel = this.mapRef.current.getZoom()
      onZoomChanged(newZoomLevel)
    }
  }

  handleCenterChanged = () => {
    const { onCenterChanged } = this.props

    if (onCenterChanged) {
      const newCenter = this.mapRef.current.getCenter()
      onCenterChanged({
        latitude: newCenter.lat(),
        longitude: newCenter.lng()
      })
    }
  }

  toCoords = (defaultCenter, center) => {
    const coords = {}

    if (defaultCenter) {
      coords.defaultCenter = {
        lat: defaultCenter.latitude,
        lng: defaultCenter.longitude
      }
    }

    if (center) {
      coords.center = {
        lat: center.latitude,
        lng: center.longitude
      }
    }

    return coords
  }

  render() {
    const { bounds, center, children, defaultCenter, images, onCenterChanged, onZoomChanged, options, ...other } =
      this.props
    const { zoom } = this.state

    return (
      <GoogleMap
        ref={this.mapRef}
        onCenterChanged={this.handleCenterChanged}
        onZoomChanged={this.handleZoomChanged}
        options={{ ...gmapsOptions, ...options }}
        zoom={zoom}
        {...this.toCoords(defaultCenter, center)}
        {...other}
      >
        {children}
      </GoogleMap>
    )
  }
}

EnhancedMap.propTypes = {
  bounds: PropTypes.object,
  center: PropTypes.object,
  children: PropTypes.array,
  defaultCenter: PropTypes.object,
  images: PropTypes.object,
  onCenterChanged: PropTypes.func,
  onZoomChanged: PropTypes.func,
  options: PropTypes.object.isRequired,
  zoom: PropTypes.number
}

EnhancedMap.defaultProps = {
  bounds: undefined,
  center: { latitude: 0, longitude: 0 },
  children: [],
  defaultCenter: { latitude: 0, longitude: 0 },
  images: {},
  onCenterChanged: () => {},
  onZoomChanged: () => {},
  zoom: 4
}

export default withGoogleMap(EnhancedMap)
