import React from 'react'
import PropTypes from 'prop-types'
import { menuItem, blackIcon } from './style.module.css'

const MenuItem = ({ title, icon: Icon, onClick, disabled }) => (
  <button
    className={`${menuItem} mapboxgl-ctrl-icon`}
    type="button"
    title={title}
    aria-label={title}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon className={blackIcon} />
  </button>
)

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string
}

MenuItem.defaultProps = {
  disabled: false
}

export default MenuItem
