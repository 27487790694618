import { defineMessages } from 'react-intl'

const namespace = 'Dashboards'

const messages = defineMessages({
  addDashboard: {
    id: `${namespace}.addDashboard`,
    description: 'Add dashboard',
    defaultMessage: 'Add dashboard'
  },
  backToMachines: {
    id: `${namespace}.backToMachines`,
    description: 'Back to machines',
    defaultMessage: 'Back to machines'
  },
  editingDashboard: {
    id: `${namespace}.editingDashboard`,
    description: 'Editing dashboard:',
    defaultMessage: 'EDITING DASHBOARD:'
  }
})

export default messages
