import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils as groupUtils } from 'ducks/groups'
import { utils } from 'ducks/login'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { getGroupsUrl, getNewGroupUrl } from '../urls'
import Groups from './Groups'

const mapStateToProps = (state, { history }) => ({
  userGroup:
    utils.isPlus1ConnectAuthorized(state) && groupUtils.getSelectedGroup(state)
      ? groupUtils.getSelectedGroup(state).name
      : state.login.userProfile.groups[0].name,
  newGroupUrl: getNewGroupUrl(),
  urlAction: history.location.state,
  onDeleteGroup: () => {
    history.push(getGroupsUrl(), 'delete')
  },
  canCreateGroups: resolvePermissions(state, c.GROUPS_CREATE),
  canReadGroups: resolvePermissions(state, c.GROUPS_READ)
})

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(actions.getGroups()),
  deleteGroup: groupId => dispatch(actions.deleteGroup(groupId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Groups))
