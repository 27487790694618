import NormalNewNotification from './NewNotification'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { utils as groupUtils } from 'ducks/groups'
import { getNotificationsRulesUrl } from '../url'

const mapStateToProps = state => ({
  notificationsUrl: getNotificationsRulesUrl(),
  currentGroupId: groupUtils.getSelectedGroup(state).id,
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId)
})

const NewNotification = withRouter(connect(mapStateToProps)(NormalNewNotification))

export { NewNotification }
