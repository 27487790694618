import { defineMessages } from 'react-intl'

const namespace = 'NotificationsRulesSettings'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    description: 'Notification management title',
    defaultMessage: 'Notification management'
  },
  addNotification: {
    id: `${namespace}.addNotification`,
    description: 'Add notification button label',
    defaultMessage: 'Add notification'
  },
  newNotification: {
    id: `${namespace}.newNotification`,
    description: 'New notification button label',
    defaultMessage: 'New notification'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  error502Text: {
    id: `${namespace}.error502Text`,
    description: 'Error 502 text',
    defaultMessage: 'Bad Gateway.'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  notificationDeletedSuccessfully: {
    id: `${namespace}.notificationDeletedSuccessfully`,
    description: 'Notification deleted successfully text',
    defaultMessage: 'Notification deleted successfully.'
  },
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Incorrect rule instance version. Try it later.'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error428Text: {
    id: `${namespace}.error428Text`,
    description: 'Error 428 text',
    defaultMessage: '`if-match` header required.'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: `${namespace}.delete`,
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Nottifications settings table total text showing rows',
    defaultMessage: 'Showing notifications'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Nottifications settings table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Nottifications settings table total text of',
    defaultMessage: 'of'
  },
  noNotificationsAvailable: {
    id: `${namespace}.noNotificationsAvailable`,
    description: 'No notifications available warning text',
    defaultMessage: 'No notifications available'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Notifications table previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Notifications table next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Notifications table first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Notifications table last page button text',
    defaultMessage: 'Last'
  },
  created: {
    id: `${namespace}.created`,
    description: 'Created notifications status label',
    defaultMessage: 'created'
  },
  compiled: {
    id: `${namespace}.compiled`,
    description: 'Compiled notifications status label',
    defaultMessage: 'compiled'
  },
  packaged: {
    id: `${namespace}.packaged`,
    description: 'Packaged notifications status label',
    defaultMessage: 'packaged'
  },
  deployed: {
    id: `${namespace}.deployed`,
    description: 'Deployed notifications status label',
    defaultMessage: 'deployed'
  },
  running: {
    id: `${namespace}.running`,
    description: 'Running notifications status label',
    defaultMessage: 'running'
  },
  failed: {
    id: `${namespace}.failed`,
    description: 'Failed notifications status label',
    defaultMessage: 'failed'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  textPlaceholder: {
    id: `${namespace}.textPlaceholder`,
    description: 'Table header column text filter placeholder',
    defaultMessage: 'Enter'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Table header column select filter placeholder',
    defaultMessage: 'Select'
  },
  event: {
    id: `${namespace}.event`,
    description: 'Event table header column label',
    defaultMessage: 'Event'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description table header column label',
    defaultMessage: 'Description'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status table header column label',
    defaultMessage: 'Status'
  },
  severity: {
    id: `${namespace}.severity`,
    description: 'Severity table header column label',
    defaultMessage: 'Severity'
  },
  group: {
    id: `${namespace}.group`,
    description: 'Group table header column label',
    defaultMessage: 'Group'
  },
  machines: {
    id: `${namespace}.machines`,
    description: 'Machines table header column label',
    defaultMessage: 'Machines'
  },
  users: {
    id: `${namespace}.users`,
    description: 'Users table header column label',
    defaultMessage: 'Users'
  },
  enabled: {
    id: `${namespace}.enabled`,
    description: 'Enabled table header column label',
    defaultMessage: 'Enabled'
  },
  confirmNotificationToDelete: {
    id: `${namespace}.confirmNotificationToDelete`,
    description: 'Confirm notification to delete message',
    defaultMessage: 'Confirm notification to delete'
  },
  severityUpper: {
    id: `${namespace}.severityUpper`,
    description: 'Severity notification filter label',
    defaultMessage: 'SEVERITY'
  },
  high: {
    id: `${namespace}.high`,
    description: 'Notification high severity label',
    defaultMessage: 'high'
  },
  medium: {
    id: `${namespace}.medium`,
    description: 'Notification medium severity label',
    defaultMessage: 'medium'
  },
  low: {
    id: `${namespace}.low`,
    description: 'Notification low severity label',
    defaultMessage: 'low'
  },
  enabledFilter: {
    id: `${namespace}.enabledFilter`,
    description: 'Enabled notification filter label',
    defaultMessage: 'ENABLED'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  editNotification: {
    id: `${namespace}.editNotification`,
    description: 'Edit notification',
    defaultMessage: 'Edit notification'
  },
  deleteNotification: {
    id: `${namespace}.deleteNotification`,
    description: 'Delete notification',
    defaultMessage: 'Delete notification'
  }
})

export default messages
