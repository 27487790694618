import { defineMessages } from 'react-intl'

const messages = defineMessages({
  editDevice: {
    id: 'Actions.editDevice',
    description: 'Edit the device ',
    defaultMessage: 'Edit device'
  },
  activateAutoRenewal: {
    id: 'Actions.activateAutoRenewal',
    description: 'Activate auto-renewal of the subscription',
    defaultMessage: 'Activate auto-renewal of the subscription'
  },
  deactivateAutoRenewal: {
    id: 'Actions.deactivateAutoRenewal',
    description: 'Deactivate auto-renewal of the subscription',
    defaultMessage: 'Deactivate auto-renewal of the subscription'
  },
  productionTestMode: {
    id: 'Actions.productionTestMode',
    description: 'Production Test Mode',
    defaultMessage: 'Production Test Mode'
  },
  startTitle: {
    id: 'Actions.startTitle',
    description: 'Title of production test mode start dialog',
    defaultMessage: 'Start Production Test Mode'
  },
  endTitle: {
    id: 'Actions.endTitle',
    description: 'Title of production test mode end dialog',
    defaultMessage: 'End Production Test Mode'
  },
  useOverageTitle: {
    id: 'Actions.useOverageTitle',
    description: 'Title of overage use action',
    defaultMessage: 'Use overage data'
  },
  stopOverageTitle: {
    id: 'Actions.stopOverageTitle',
    description: 'Title of overage use stopping action',
    defaultMessage: 'Stop using overage data'
  },
  allowAccessPassConsumption: {
    id: 'Actions.allowAccessPassConsumption',
    description: 'Title of Access Pass consumption allowing action',
    defaultMessage: 'Allow Access Pass consumption'
  },
  disallowAccessPassConsumption: {
    id: 'Actions.disallowAccessPassConsumption',
    description: 'Title of Access Pass consumption disallowing action',
    defaultMessage: 'Disallow Access Pass consumption'
  }
})

export default messages
