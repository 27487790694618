import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  isEditing,
  getMaxZIndex,
  getRandom,
  getWidgets,
  setupDashboard,
  setWidgetZIndexPlus,
  setWidgetZIndexMinus,
  setWidgetSize,
  setWidgetPosition,
  setWidgetSettings,
  deleteWidget,
  addWidget,
  getDashboardSettings,
  getDinamicData,
  getStaticData,
  getDevicesData,
  addDeviceData,
  widgetUpdateRealTimeValues,
  newGpsPoints
} from 'ducks/groupDashboards'

import { actions as websocket } from 'ducks/websocket'

import csNode from 'modules/csNode'

import Dashboard from './Dashboard'

import { actions as hactions } from 'utils/http'

import { actions as cs500Actions } from 'ducks/configurationsCS500'

import { utils } from 'ducks/groups'

import { actions as geoActions } from 'ducks/geotracking'

const mapStateToProps = (state, { match }) => {
  return {
    editing: isEditing(state),
    random: getRandom(state),
    widgets: getWidgets(state),
    nodeCredentials: csNode.utils.getRealTimeCredentials(state),
    settings: getDashboardSettings(state),
    getDinamicData: eid => getDinamicData(state, eid),
    getStaticData: eid => getStaticData(state, eid),
    devicesData: getDevicesData(state),
    groupId: utils.getSelectedGroup(state).id
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDevicesDetail: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs)),
    // eslint-disable-next-line max-params
    setupDashboard: (nodeFamily, maxZIndex, widgets, deviceData, settings) =>
      dispatch(setupDashboard(nodeFamily, maxZIndex, widgets, deviceData, settings)),
    setWidgetZIndexPlus: widgetId => dispatch(setWidgetZIndexPlus(widgetId)),
    setWidgetZIndexMinus: widgetId => dispatch(setWidgetZIndexMinus(widgetId)),
    setWidgetSize: (widgetId, height, width) => dispatch(setWidgetSize(widgetId, height, width)),
    setWidgetPosition: (widgetId, x, y) => dispatch(setWidgetPosition(widgetId, x, y)),
    setWidgetSettings: (widgetId, data, devicesEids, deviceInfo) =>
      dispatch(setWidgetSettings(widgetId, data, devicesEids, deviceInfo)),
    deleteWidget: widgetId => dispatch(deleteWidget(widgetId)),
    subscribeToWS: (topics, eid, credentials) => dispatch(websocket.subscribeGeneric(topics, eid, credentials)),
    unsuscribeFromWS: (topic, eid) => dispatch(websocket.unsubscribe(topic, eid)),
    addWidget: template => dispatch(addWidget(template)),
    addDeviceData: (eid, deviceData) => dispatch(addDeviceData(eid, deviceData)),
    widgetUpdateRealTimeValues: (signalNumber, eid, data) =>
      dispatch(widgetUpdateRealTimeValues(signalNumber, eid, data)),
    fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
      dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
    getNodeLastGPSLocation: eid =>
      dispatch(geoActions.getNodeLastGPSLocation(eid)).promise.then(({ payload }) => payload),
    newGpsPoints: (meta, payload) => dispatch(newGpsPoints(meta, payload)),
    ...bindActionCreators(
      {
        /* eslint-disable no-shadow */
        getAzureToken: (id, eid) => dispatch => {
          const { promise } = dispatch(hactions.plus1Connect.getAzureNodeToken(id, eid))
          return promise
        }
        /* eslint-enable */
      },
      dispatch
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
