import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurationsCS500'

import MFIO from './MFIO'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLocalConfigurationField: actions.changeLocalConfigurationField
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(MFIO)
