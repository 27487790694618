import HighchartsReact from 'highcharts-react-official'
import Highstock from 'highcharts/highstock'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import { client, logError } from 'utils/http'

import { getMomentParamsFromSelectedTimeRange } from '../../utils'
import HistoricConfig from './HistoricConfig'
import messages from './messages'

class AdvancedSignalsHistoric extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const max = moment().valueOf()
    const min = moment().subtract(1, 'month').valueOf()

    this.state = {
      historicSeries: [],
      yAxis: [],
      chartMax: max,
      chartMin: min,
      zoomMax: null,
      zoomMin: null
    }

    this.chartRef = React.createRef()
  }

  componentDidMount() {
    const { editing, eid, settings, devicesData } = this.props
    const { chartMin, chartMax } = this.state

    if (!editing && eid && Array.isArray(settings?.signals)) {
      const { advancedSignals } = devicesData[eid]
      const validSignals = settings.signals.filter(
        signal => advancedSignals.findIndex(({ configHashId }) => configHashId === signal) > -1
      )
      this.setAdvancedSignalsFormattedData(validSignals, chartMin, chartMax, false)
    }
  }

  setAdvancedSignalsFormattedData = async (advancedSignals, min, max, isxAxisUpdateNeeded = false) => {
    const {
      settings: { selectedTimeRange }
    } = this.props
    const {
      chartMin: prevChartMin,
      chartMax: prevChartMax,
      historicSeries: prevHistoricSeries,
      yAxis: prevYaxis,
      zoomMin: prevZoomMin,
      zoomMax: prevZoomMax
    } = this.state

    const selectedTimeRangeParams = getMomentParamsFromSelectedTimeRange(selectedTimeRange)
    const zoomMin =
      prevZoomMin === null
        ? moment(max)
          .subtract(...selectedTimeRangeParams)
          .valueOf()
        : min
    const zoomMax = max

    const chartMin = min < prevChartMin ? min : prevChartMin
    const chartMax = max > prevChartMax ? max : prevChartMax

    let historicSeries = prevHistoricSeries
    let yAxis = prevYaxis

    try {
      if (chartMin < prevChartMin || chartMax > prevChartMax || prevZoomMin === null && prevZoomMax === null) {
        const { groupId, eid } = this.props
        this.enableDateRangeInputs(false)
        if (this.chartRef.current) {
          this.chartRef.current.chart.showLoading(this.formatMessage(messages.loadingDataFromServer))
        }
        const measurementsResponse = await client.getMeasurements(groupId, [eid])
        const selectedAdvancedSignals = measurementsResponse.data
          .filter(advancedSignal => advancedSignals.includes(advancedSignal.configHashId))
          .map(advancedSignal => {
            const { measurementName, measurementUnit, measurementDevices, operationType } = advancedSignal
            return {
              measurementName,
              measurementUnit,
              deviceHashId: measurementDevices[0].hashId,
              operationType
            }
          })

        const advancedSignalsDataRequests = selectedAdvancedSignals.map(({ deviceHashId }) =>
          client.getMeasurementsResults(deviceHashId, groupId, chartMin, chartMax)
        )

        const advancedSignalsDataResponses = await Promise.all(advancedSignalsDataRequests)
        const { newHistoricSeries, newYAxis } = advancedSignalsDataResponses.reduce(
          (acc, { data }, index) => {
            let updatedAcc = { newHistoricSeries: [...acc.newHistoricSeries], newYAxis: [...acc.newYAxis] }
            if (data.length > 0) {
              const { deviceHashId } = data[0]
              const { measurementName, measurementUnit, operationType } = selectedAdvancedSignals.find(
                advancedSignal => advancedSignal.deviceHashId === deviceHashId
              )
              const unit = operationType === '1' ? 'hours' : measurementUnit
              const serie = {
                name: `${measurementName} (${unit})`,
                showInLegend: true,
                data: data.map(result => {
                  const { signalEndTime, calculatedValue: y } = result
                  const x = moment(signalEndTime).utc().startOf('day').valueOf()
                  return {
                    name: moment(x).format('DD-MMM-YYYY'),
                    x,
                    y: operationType === '1' ? this.getHoursFromSeconds(y) : y
                  }
                }),
                yAxis: index
              }

              const yAxisItem = {
                labels: {
                  align: index % 2 === 0 ? 'left' : 'right',
                  format: '{value} ' + measurementUnit,
                  style: {
                    color: Highstock.getOptions().colors[index]
                  }
                },
                opposite: index % 2 === 0,
                showLastLabel: true
              }

              updatedAcc = {
                newHistoricSeries: [...updatedAcc.newHistoricSeries, serie],
                newYAxis: [...updatedAcc.newYAxis, yAxisItem]
              }
            }
            return updatedAcc
          },
          { newHistoricSeries: [], newYAxis: [] }
        )

        historicSeries = [...newHistoricSeries]
        yAxis = [...newYAxis]
      }

      this.setState(
        {
          historicSeries,
          yAxis,
          chartMin,
          chartMax,
          zoomMin,
          zoomMax
        },
        () => {
          if (isxAxisUpdateNeeded && this.chartRef.current) {
            this.chartRef.current.chart.xAxis.forEach(axis => {
              axis.setExtremes(zoomMin, zoomMax)
            })
          }
        }
      )
    } catch (error) {
      logError(error)
    } finally {
      if (this.chartRef.current) this.chartRef.current.chart.hideLoading()
      this.enableDateRangeInputs(true)
    }
  }

  getHoursFromSeconds = seconds => {
    const num = parseFloat('1e' + 2)
    return Math.round((seconds / 3600 + Number.EPSILON) * num) / num
  }

  enableDateRangeInputs = bool => {
    let cursor = 'not-allowed'
    let disabled = true
    if (bool) {
      cursor = ''
      disabled = false
    }
    const selectorCollection = document.getElementsByClassName('highcharts-range-selector')
    for (const selector of selectorCollection) {
      selector.disabled = disabled
      selector.style.cursor = cursor
    }
  }

  render() {
    const {
      settings: { signals, selectedTimeRange },
      devicesData = {},
      eid,
      width,
      height
    } = this.props
    const { zoomMin, zoomMax, chartMin, chartMax, historicSeries, yAxis } = this.state

    const { staticData = [], advancedSignals } = devicesData[eid]
    const validSignals = signals.filter(
      signal => advancedSignals.findIndex(({ configHashId }) => configHashId === signal) > -1
    )

    const { value: filename = 'chart' } = staticData.find(dataField => dataField.name === 'name') || {}
    const max = zoomMax
    const min = zoomMin
    const lastIndex = 4
    const extraOptions = {
      rangeSelector: { selected: Math.min(selectedTimeRange, lastIndex) },
      series: historicSeries,
      yAxis,
      exporting: {
        filename,
        sourceWidth: width,
        sourceHeight: height,
        chartOptions: {
          title: { align: 'center', text: filename, floating: true }
        }
      },
      navigator: { xAxis: { min: chartMin, max: chartMax } }
    }
    const config = Highstock.merge(
      Highstock.getOptions(),
      HistoricConfig(min, max, validSignals, this.setAdvancedSignalsFormattedData),
      extraOptions
    )

    return (
      <div className={signals.length === 0 ? 'highcharts-wrapper notConfigured' : 'highcharts-wrapper'}>
        {signals.length === 0 ? (
          <Typography gutterBottom={false}>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : (
          <HighchartsReact
            ref={this.chartRef}
            constructorType='stockChart'
            highcharts={Highstock}
            immutable
            options={_cloneDeep(config)}
          />
        )}
      </div>
    )
  }
}

AdvancedSignalsHistoric.propTypes = {
  devicesData: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  editing: PropTypes.bool.isRequired,
  eid: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
}

export default injectIntl(AdvancedSignalsHistoric)
