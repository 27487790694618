import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import LineChartTemplate from './LineChartTemplate'
import messages from './messages'
import {
  getValueTypes,
  generatePartialStateFromProps,
  withDefaultValues,
  getDecimals,
  manageWSSubscriptions
} from '../utils'

const styles = theme => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  groupLabel: {
    color: 'black',
    fontWeight: 'bold'
  }
})
class LineChartSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const widgetData = withDefaultValues(props.data, LineChartTemplate.content.params)
    const { numberOfDecimals } = widgetData

    this.state = {
      ...partialState,
      numberOfDecimals,
      numberOfDecimalsErrorText: '',
      numberOfDecimalsDisabled: Number.isInteger(getDecimals(props.dinamicData, partialState.dinamicData))
    }
  }

  handleChange = name => event => {
    let change = { [name]: event.target.value }
    if (name === 'numberOfDecimals') change = { ...change, [name + 'ErrorText']: '' }
    this.setState(change)
  }

  handleDinamicChange = (event, value) => {
    const { signalId = '' } = value || {}
    this.setState(state => {
      const { numberOfDecimals, numberOfDecimalsErrorText, valueType } = state
      const { dinamicData, staticData } = this.props
      let decimals = numberOfDecimals
      let decimalsDisabled = false
      let decimalsErrorText = numberOfDecimalsErrorText
      const valueTypes = getValueTypes(dinamicData, staticData, signalId)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      if (Number.isInteger(getDecimals(dinamicData, signalId))) {
        decimals = getDecimals(dinamicData, signalId)
        decimalsDisabled = true
        decimalsErrorText = ''
      }
      const change = {
        dinamicData: signalId,
        numberOfDecimalsDisabled: decimalsDisabled,
        numberOfDecimals: decimals,
        numberOfDecimalsErrorText: decimalsErrorText,
        valueTypes,
        valueType: newValueType
      }
      return change
    })
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleSaveSettings = () => {
    let error = false

    const { numberOfDecimals } = this.state

    if (numberOfDecimals === '') {
      error = true
      this.setState({
        numberOfDecimalsErrorText: this.formatMessage(messages.thisFieldIsRequired)
      })
    } else {
      if (numberOfDecimals < 0) {
        error = true
        this.setState({
          numberOfDecimalsErrorText: this.formatMessage(messages.onlyZeroOrHigherIsAllowed)
        })
      }
    }

    if (!error) {
      const { valueType, dinamicData, wsSubscribedData } = this.state
      const { data: propsData, saveSettings } = this.props
      const canKeepValues = dinamicData !== '' && dinamicData === wsSubscribedData && propsData.valueType === valueType
      const data = {
        data: dinamicData,
        numberOfDecimals,
        valueType,
        values: propsData.values && canKeepValues ? propsData.values : [],
        timestamps: propsData.timestamps && canKeepValues ? propsData.timestamps : []
      }

      saveSettings(data)

      const { eid, nodeCredentials, subscribeWidgetToWS, unsubscribeWidgetFromWS } = this.props

      manageWSSubscriptions(wsSubscribedData, data, eid, nodeCredentials, subscribeWidgetToWS, unsubscribeWidgetFromWS)
      this.setState({
        wsSubscribedData: data.data
      })
    }
  }

  render() {
    const { dinamicData: signals, classes } = this.props
    const {
      dinamicData,
      valueTypes,
      valueType,
      numberOfDecimalsDisabled,
      numberOfDecimals,
      numberOfDecimalsErrorText
    } = this.state
    const needsValueType = valueTypes.length > 0
    const options = signals.map(signal => {
      let signalType = this.formatMessage(messages.canBusSignals)
      if (signal.isGPS) signalType = this.formatMessage(messages.gpsSignals)
      else if (signal.isMFIO) signalType = this.formatMessage(messages.mfioSignals)
      return { ...signal, signalType }
    })

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.linechartWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id='alert-dialog-slide-description'>
            {this.formatMessage(messages.linechartWidgetSettings)}
          </DialogContentText>
          {options.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  classes={{ groupLabel: classes.groupLabel }}
                  fullWidth
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.signalId === value.signalId}
                  groupBy={option => option.signalType}
                  id='grouped-signals'
                  onChange={this.handleDinamicChange}
                  options={options}
                  renderInput={params => <TextField {...params} label={this.formatMessage(messages.signals)} />}
                  value={options.find(signal => signal.signalId === dinamicData) || null}
                />
              </Grid>
              {needsValueType && (
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='valueType-label-placeholder' shrink>
                      {this.formatMessage(messages.valueType)}
                    </InputLabel>
                    <Select onChange={this.handleChange('valueType')} value={valueType}>
                      {valueTypes.map(type => {
                        return (
                          <MenuItem key={type} value={type}>
                            {this.formatMessage(messages[type])}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DialogContentText id='alert-dialog-slide-description'>
                  <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                    {this.formatMessage(messages.notSupportedMachine)}
                  </span>
                </DialogContentText>
              </Grid>
            </Grid>
          )}
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherLineChartWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <TextField
                key='numberOfDecimals'
                disabled={numberOfDecimalsDisabled}
                error={numberOfDecimalsErrorText !== ''}
                fullWidth
                helperText={numberOfDecimalsErrorText}
                id='numberOfDecimals'
                label={this.formatMessage(messages.numberOfDecimals)}
                onChange={this.handleChange('numberOfDecimals')}
                required
                type='number'
                value={numberOfDecimals}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

LineChartSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  nodeCredentials: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  staticData: PropTypes.array.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(LineChartSettings))
