import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Configurations } from './Configurations'
import { NewConfiguration } from './NewConfiguration'
import { EditConfiguration } from './EditConfiguration'
import { ViewConfiguration } from './ViewConfiguration'

import {
  CONFIGURATIONS_BASE_CS100,
  NEW_CONFIGURATION_CS100,
  VIEW_CONFIGURATION_CS100,
  EDIT_CONFIGURATION_CS100,
} from './url'

const ConfigurationsHome = ({ canReadConfigurations, canCreateConfigurations, canEditConfigurations, location }) => (
  <Switch>
    {canCreateConfigurations && <Route exact={true} path={NEW_CONFIGURATION_CS100} component={NewConfiguration} />}
    {canEditConfigurations && <Route exact={true} path={EDIT_CONFIGURATION_CS100} component={EditConfiguration} />}
    {canReadConfigurations && location.pathname !== 'CS100/configurations/configuration' && (
      <Route exact={true} path={VIEW_CONFIGURATION_CS100} component={ViewConfiguration} />
    )}
    {canReadConfigurations && <Route path={CONFIGURATIONS_BASE_CS100} component={Configurations} />}
  </Switch>
)

export default ConfigurationsHome
