import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { injectIntl } from 'react-intl'

import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import DbcFileImportFromPC from 'modules/configurationsCS100/DbcFileImportFromPC'

import MenuListComponent from './MenuListComponent'
import messages from './messages'

import { DEFAULT_GROUP_ID } from 'utils/constants'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

const selectStyle = {
  control: base => ({
    ...base,
    borderStyle: 'none none solid none',
    borderRadius: 0,
    boxShadow: 0,
    '&:hover': {
      borderStyle: 'none none solid none'
    }
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? 'rgb(235, 235, 235)' : isFocused ? 'rgb(245, 245, 245)' : 'none'
  }),
  singleValue: base => ({
    ...base,
    display: 'contents'
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: 0
  }),
  container: base => ({
    ...base,
    display: 'inline-block',
    width: '100%'
  }),
  menu: base => ({
    ...base,
    height: '200px'
  }),
  menuList: base => ({
    ...base,
    maxHeight: '100%'
  })
}

const selectSignalStyle = {
  control: base => ({
    ...base,
    borderStyle: 'none none solid none',
    borderRadius: 0,
    boxShadow: 0,
    '&:hover': {
      borderStyle: 'none none solid none'
    }
  }),
  option: (styles, { data, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? 'rgb(235, 235, 235)' : isFocused ? 'rgb(245, 245, 245)' : 'none',
    color: isSelected ? 'black' : data.color
  }),
  singleValue: base => ({
    ...base,
    display: 'contents'
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: 0
  }),
  menu: base => ({
    ...base,
    height: '200px'
  })
}

class ProtocolSelector extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage },
      classes
    } = props
    this.state = {
      dialogOpen: false
    }
    this.formatMessage = formatMessage
    this.classes = classes
  }

  componentDidMount = () => {
    const { groupId, getProtocols } = this.props
    if (groupId) {
      getProtocols(groupId)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { groupId, getProtocols } = this.props
    const { dialogOpen } = this.state

    if (groupId && prevProps.groupId !== groupId || prevState.dialogOpen !== dialogOpen) {
      getProtocols(groupId)
    }
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  render() {
    const {
      groupId,
      handleProtocolChange,
      isProtocolListLoading,
      isProtocolLoading,
      loadSelectedCanProtocol,
      protocolList,
      selectJ1939Signal,
      selectedCanProtocolData,
      selectedCanProtocolName,
      selectedCanProtocolType,
      signalId,
      selectedSignal
    } = this.props

    const { dialogOpen } = this.state

    let variables = []
    if (selectedCanProtocolName && selectedCanProtocolData !== null) {
      variables = Object.values(selectedCanProtocolData).map(variable => {
        return {
          value: variable.spn ? variable.spn : variable.spnName ? variable.spnName : variable.name,
          label: variable.spnName ? variable.spnName : variable.name
        }
      })
    }

    return (
      <div className='col-md-12' style={{ padding: '0 0 10px 0' }}>
        <div
          key='import-dbc-select-protocol-div'
          className='col-md-3'
          style={{ paddingLeft: 0, paddingRight: 8, display: 'flex', alignItems: 'flex-start', alignContent: 'center' }}
        >
          <div key='import-dbc-file-div' style={{ textAlign: 'start' }}>
            <Tooltip
              key='import-dbc-file-tooltip'
              classes={{ tooltip: this.classes.tooltip }}
              placement='top'
              title={this.formatMessage(messages.importDbc)}
            >
              <div key='import-dbc-file-button-div' style={{ padding: 0 }}>
                <IconButton
                  key='import-dbc-file-button'
                  disabled={groupId === DEFAULT_GROUP_ID}
                  onClick={this.handleDialogOpen}
                  style={{ display: 'inline-block', float: 'left' }}
                >
                  <AddIcon key='import-dbc-file-icon' />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          <div key='select-protocol-div' style={{ textAlign: 'end', paddingRight: 8, width: '100%' }}>
            <Select
              key='select-protocol-select-menu'
              components={{
                SingleValue: ({ children, ...props }) => {
                  const toShow =
                    selectedCanProtocolType && selectedCanProtocolType === 'J1939' ? (
                      <div key={selectedCanProtocolType} style={{ color: '#1592E6', paddingLeft: 10 }}>
                        {selectedCanProtocolType + ' compliant'}
                      </div>
                    ) : 
                      []
                    

                  return <components.SingleValue {...props}>{[children, toShow]}</components.SingleValue>
                }
              }}
              formatOptionLabel={({ value, label }) => (
                <div style={{ display: 'flex' }}>
                  <div
                    key='label'
                    style={{
                      fontWeight: value !== 'custom' ? 'bold' : 'normal',
                      color: value !== 'custom' ? '#333333' : '#808080'
                    }}
                  >
                    {label}
                  </div>
                </div>
              )}
              isLoading={isProtocolListLoading}
              onChange={event => {
                if (event.value !== selectedCanProtocolName) {
                  handleProtocolChange(signalId, event.value)
                }
                if (event.value !== 'custom' && event.value !== 'common') {
                  loadSelectedCanProtocol(groupId, event.value)
                }
              }}
              options={protocolList}
              placeholder='| Select a predefined protocol'
              styles={selectStyle}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'rgba(0, 0, 0, 0.54)'
                }
              })}
              value={protocolList.find(opt => opt.value === selectedCanProtocolName)}
            />
          </div>
        </div>
        <div key='select-signal-div' className='col-md-9' style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Select
            key='select-signal-select-menu'
            components={{ MenuList: MenuListComponent }}
            filterOption={(option, input) => {
              if (!isProtocolLoading) {
                const signal = selectedCanProtocolData[option.value]
                return (
                  option.value.toString().toLowerCase().includes(input.toLowerCase()) ||
                  option.label.toString().toLowerCase().includes(input.toLowerCase()) ||
                  signal.pgn.toString().toLowerCase().includes(input.toLowerCase())
                )
              } else {
                return (
                  option.value.toString().toLowerCase().includes(input.toLowerCase()) ||
                  option.label.toString().toLowerCase().includes(input.toLowerCase())
                )
              }
            }}
            formatOptionLabel={({ value }) => {
              if (
                selectedCanProtocolData !== null &&
                selectedCanProtocolData[value] /*&& selectedCanProtocolType === 'J1939'*/
              ) {
                const signal = selectedCanProtocolData[value]
                if (signal.spn) {
                  return (
                    <div style={{ display: 'flex' }}>
                      <div key='spn' style={{ fontWeight: 'bold' }}>
                        {'SPN: ' + signal.spn}
                      </div>
                      <div key='spn-name' style={{ marginLeft: '5px' }}>
                        {'- ' + signal.spnName}
                      </div>
                      <div key='pgn' style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                        {'PGN: ' + signal.pgn}
                      </div>
                      <div key='pgn-name' style={{ marginLeft: '5px' }}>
                        {'- ' + signal.pgnName}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div style={{ display: 'flex' }}>
                      <div key='signal-name' style={{ fontWeight: 'bold' }}>
                        {'Signal name: '}
                      </div>
                      <div key='spn-name' style={{ marginLeft: '5px' }}>
                        {signal.spnName}
                      </div>
                      <div key='pgn' style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                        {'PGN: ' + signal.pgn}
                      </div>
                      <div key='pgn-name' style={{ marginLeft: '5px' }}>
                        {'- ' + signal.pgnName}
                      </div>
                    </div>
                  )
                }
              } else {
                return <div style={{ color: '#808080' }}>{this.formatMessage(messages.searchBy)}</div>
              }
            }}
            isDisabled={!selectedCanProtocolName || selectedCanProtocolName === 'custom'}
            isLoading={isProtocolLoading}
            onChange={event => {
              if (event) {
                return selectJ1939Signal(signalId, selectedCanProtocolData[event.value])
              } else {
                return undefined
              }
            }}
            options={variables}
            placeholder={this.formatMessage(messages.searchBy)}
            styles={selectSignalStyle}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'rgba(0, 0, 0, 0.54)'
              }
            })}
            value={selectedSignal}
          />
        </div>
        {groupId !== DEFAULT_GROUP_ID && (
          <DbcFileImportFromPC dialogOpen={dialogOpen} onDialogClose={this.handleDialogClose} />
        )}
      </div>
    )
  }
}

ProtocolSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  getProtocols: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleProtocolChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isProtocolListLoading: PropTypes.bool.isRequired,
  isProtocolLoading: PropTypes.bool.isRequired,
  loadSelectedCanProtocol: PropTypes.func.isRequired,
  protocolList: PropTypes.array.isRequired,
  selectJ1939Signal: PropTypes.func.isRequired,
  selectedCanProtocolData: PropTypes.object,
  selectedCanProtocolName: PropTypes.string,
  selectedCanProtocolType: PropTypes.string,
  selectedSignal: PropTypes.object,
  signalId: PropTypes.number.isRequired
}

ProtocolSelector.defaultProps = {
  selectedCanProtocolData: null,
  selectedCanProtocolName: '',
  selectedCanProtocolType: '',
  selectedSignal: null
}

export default withStyles(styles)(injectIntl(ProtocolSelector))
