import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'

import NoDashboard from 'modules/groupDashboards/DashboardMessages/NoDashboard'

import AddGroupDashboard from 'modules/groupDashboards/Modals/AddGroupDashboard'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { withRouter } from 'react-router-dom'

class GroupDashboardsEmpty extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      addGroupDashboardModal: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getGroupDashboards.length === 0 && this.props.getGroupDashboards.length > 0) {
      this.props.history.push('/dashboards/' + this.props.getGroupDashboards[0].uuid)
    }
  }

  openAddGroupDashboardModal = () => {
    this.setState({
      addGroupDashboardModal: true
    })
  }
  closeAddGroupDashboardModal = () => {
    this.setState({
      addGroupDashboardModal: false
    })
  }

  showToolbar = () => (
    <Toolbar className="dashboards-toolbar">
      <div className="dashboards-toolbar_title" />

      <div className="dashboards-toolbar_title" />

      <Button color="inherit" onClick={this.openAddGroupDashboardModal} className="add-dashboard-button">
        {this.formatMessage(messages.addGroupDashboard)}
      </Button>
    </Toolbar>
  )

  render() {
    return (
      <React.Fragment>
        <div className="dashboards">
          <AppBar position="static" className="dashboards-appbar">
            {this.showToolbar()}
          </AppBar>

          <NoDashboard />
        </div>

        {this.state.addGroupDashboardModal && (
          <AddGroupDashboard
            selectedGroup={this.props.selectedGroup}
            closeAddGroupDashboardModal={this.closeAddGroupDashboardModal}
          />
        )}
      </React.Fragment>
    )
  }
}

GroupDashboardsEmpty.propTypes = {
  getGroupDashboards: PropTypes.array.isRequired
}

export default withRouter(injectIntl(GroupDashboardsEmpty))
