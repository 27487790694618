import moment from 'moment'

import * as lt from 'ducks/login/actionTypes'
import { actions as ha } from 'utils/http'

import * as t from './actionTypes'
import {
  getAccessToken as getCachedAccessToken,
  getEmail as getCachedEmail,
  getUserId as getCachedUserId,
  cacheRegisterPath,
  cacheRegisterSearch,
  getRegisterPath as getCachedRegisterPath,
  clearRegisterPath
} from './cache'
import * as c from './constants'

const initState = {
  token: getCachedAccessToken(),
  // In the future this distinction might be useful to extract user related
  // information to another module.
  user: getCachedEmail(),
  userId: getCachedUserId(),
  userProfile: null,
  loadingProfile: false,
  plus1connectAuthorized: false,
  authorizingPlus1connect: false,
  userState: 'loading',
  showErrorMessage: 'hidden',
  errorMessage: '',
  privileges: []
}

const setPrivileges = (privileges, roleName) => {
  const newPrivileges = privileges.reduce((ret, privilege) => {
    ret[privilege.name] = true // eslint-disable-line no-param-reassign
    return ret
  }, {})

  if (roleName === 'ROLE_ADMIN') {
    newPrivileges['ADMINISTRATOR'] = true
  }

  return newPrivileges
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case t.AUTHORIZE:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user
      }

    case ha.PLUS1CONNECT_LOGIN:
      return {
        ...state,
        authorizingPlus1connect: true
      }

    case ha.types.success(ha.types.PLUS1CONNECT_LOGIN):
      return {
        ...state,
        plus1connectAuthorized: true,
        authorizingPlus1connect: false
      }

    case ha.types.fail(ha.types.PLUS1CONNECT_LOGIN):
      return {
        ...state,
        plus1connectAuthorized: false,
        authorizingPlus1connect: false
      }

    case ha.types.success(ha.types.GROUP_USER_DETAILS):
      const data = action.payload.data.data.result
      const { firstName, lastName } = JSON.parse(action.payload?.config?.data)

      const userProfile = {
        '@objectId': 1,
        hashId: data.id,
        firstName: firstName ? firstName : data.firstName,
        lastName: lastName ? lastName : data.lastName,
        email: data.email,
        enabled: true,
        notify: true,
        remarks: '',
        version: 1,
        createdBy: data.token_user_profile.sub,
        createdDate: moment(data.createdAt).valueOf(),
        lastModifiedDate: moment(data.updatedAt).valueOf(),
        roles: [],
        groups: [],
        groupsData: []
      }

      return {
        ...state,
        privileges: [],
        userProfile,
        loadingProfile: false
      }

    case ha.types.fail(ha.types.GROUP_USER_DETAILS):
      return {
        ...state,
        userProfile: null,
        loadingProfile: false
      }

    case ha.types.success(ha.types.LOGOUT):
      return {
        ...state,
        token: null,
        user: null,
        userProfile: null
      }

    case lt.LOADING_USER:
      return {
        ...state,
        userState: action.payload
      }

    case ha.types.LOAD_PROFILE:
      return {
        ...state,
        loadingProfile: true
      }

    case ha.types.success(ha.types.LOAD_PROFILE):
      const languageCodes = Object.keys(JSON.parse(localStorage.getItem('available_languages')))
      const { preferredLanguage, ...newProfile } = { ...action.payload.data }

      if (preferredLanguage) {
        const newPreferredLanguage = preferredLanguage.split('_')[0]
        if (languageCodes.includes(newPreferredLanguage)) {
          localStorage.setItem('user_language', newPreferredLanguage)
        }
      } else {
        if (!languageCodes.includes(localStorage.getItem('user_language'))) localStorage.setItem('user_language', 'en')
      }

      newProfile.id = newProfile.user_id
      newProfile.phoneNumber = newProfile.phone_number
      delete newProfile.user_id
      delete newProfile.phone_number
      newProfile.groupsData = newProfile.groups
      newProfile.groups = newProfile.groups.map(({ hashId }) => hashId)

      const privileges = setPrivileges(newProfile.roles[0].privileges, newProfile.roles[0].name)
      return {
        ...state,
        privileges,
        userProfile: newProfile,
        loadingProfile: false
      }

    case t.UPDATE_PROFILE:
      const prof = { ...action.profile }
      let language = prof.preferredLanguage
      delete prof.preferredLanguage
      if (language) {
        language = language.split('_')[0]
        // eslint-disable-next-line no-shadow
        const languageCodes = Object.keys(JSON.parse(localStorage.getItem('available_languages')))
        if (languageCodes.includes(language)) {
          localStorage.setItem('user_language', language)
        }
      } else localStorage.setItem('user_language', 'en')

      prof.id = prof.user_id
      prof.phoneNumber = prof.phone_number
      delete prof.user_id
      delete prof.phone_number
      prof.groupsData = prof.groups
      prof.groups = prof.groups.map(({ hashId }) => hashId)

      return {
        ...state,
        userProfile: prof,
        loadingProfile: false
      }

    case ha.types.fail(ha.types.LOAD_PROFILE):
      return {
        ...state,
        userProfile: null,
        loadingProfile: false
      }

    case t.SHOW_ERROR:
      return { ...state, showErrorMessage: '', errorMessage: action.payload }

    case t.CLEAR_ERROR:
      return { ...state, showErrorMessage: 'hidden', errorMessage: '' }

    default:
  }

  return state
}

export default reducer

// Selector
const getLoginSelector = state => state[c.NAME]

const getAccessToken = state => getLoginSelector(state).token
const getShowErrorMessage = state => getLoginSelector(state).showErrorMessage
const getErrorMessage = state => getLoginSelector(state).errorMessage
const isLoggedIn = state => getAccessToken(state) !== null
const isPlus1ConnectAuthorized = state => getLoginSelector(state).plus1connectAuthorized
const isPlus1ConnectBeingAuthorized = state => getLoginSelector(state).authorizingPlus1connect
const getLoggedUserEmail = state => getLoginSelector(state).user
const getLoggedUserId = state => getLoginSelector(state).userId
const getLoggedUser = state => getLoginSelector(state).userProfile
const getUserState = state => getLoginSelector(state).userState
const hasBeenInitialized = state => {
  const s = getLoginSelector(state)
  return s.token !== null && s.user !== null
}
const isProfileLoaded = state => getLoggedUser(state) !== null

const isProfileBeingLoaded = state => getLoginSelector(state).loadingProfile

const getGroups = state => getLoggedUser(state).groups

const getGroupsData = state => getLoggedUser(state).groupsData

const userHasGroup = state => {
  let userHasGroups = false
  let userGroups = []
  if (isProfileLoaded(state)) {
    userGroups = getGroups(state)
  }
  if (typeof userGroups !== 'undefined' && userGroups.length > 0) {
    userHasGroups = true
  }
  return userHasGroups
}

export {
  hasBeenInitialized,
  isProfileLoaded,
  isProfileBeingLoaded,
  isLoggedIn,
  isPlus1ConnectAuthorized,
  isPlus1ConnectBeingAuthorized,
  getAccessToken,
  getLoggedUserEmail,
  getLoggedUserId,
  getLoggedUser,
  getShowErrorMessage,
  getErrorMessage,
  getUserState,
  getGroups,
  getGroupsData,
  userHasGroup,
  getCachedUserId,
  cacheRegisterPath,
  cacheRegisterSearch,
  getCachedRegisterPath,
  clearRegisterPath
}
