import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import { ColorPicker } from 'components/common'

import messages from '../../../messages'

const styles = {
  colorPicker: {
    borderRadius: 10,
    cursor: 'pointer',
    display: 'inline-block',
    height: 12,
    marginRight: '12px',
    verticalAlign: 'middle',
    width: 12
  }
}

const SelectedMachinesListItem = React.memo(props => {
  const {
    classes,
    color,
    hasNoGpsTrackingOnQuery,
    intl,
    name,
    noGpsTracking,
    onFlyToMarkerClick,
    onDeviceColorChange,
    onDeviceDeselect
  } = props

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <ColorPicker className={classes.colorPicker} onChange={onDeviceColorChange} value={color} />
        <span style={{ fontSize: '12px' }}> {name} </span>
      </Grid>

      <Grid container item justify='flex-end' xs={4}>
        {hasNoGpsTrackingOnQuery ? (
          <Tooltip placement='top' title={intl.formatMessage(messages.noGpsTrackingsTooltip)}>
            <Icon className='zmdi zmdi-cloud-off' style={{ fontSize: '16px', margin: 4 }} />
          </Tooltip>
        ) : null}
        {noGpsTracking ? (
          <Tooltip placement='top' title={intl.formatMessage(messages.noGPS)}>
            <Icon className='zmdi zmdi-gps-off' style={{ fontSize: '16px', margin: 4 }} />
          </Tooltip>
        ) : (
          <Tooltip placement='top' title={intl.formatMessage(messages.goTo)}>
            <IconButton onClick={onFlyToMarkerClick} size='small' style={{ padding: '4px' }}>
              <Icon className='zmdi zmdi-gps-dot' style={{ fontSize: '16px', margin: 0 }} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip placement='top' title={intl.formatMessage(messages.removeMachine)}>
          <IconButton onClick={onDeviceDeselect} size='small' style={{ padding: '4px' }}>
            <Icon className='zmdi zmdi-close' style={{ fontSize: '16px', margin: 0 }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
})

SelectedMachinesListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  hasNoGpsTrackingOnQuery: PropTypes.bool.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  name: PropTypes.string.isRequired,
  noGpsTracking: PropTypes.bool.isRequired,
  onDeviceColorChange: PropTypes.func.isRequired,
  onDeviceDeselect: PropTypes.func.isRequired,
  onFlyToMarkerClick: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(SelectedMachinesListItem))
