import { defineMessages } from 'react-intl'

const messages = defineMessages({
  assignNotificationsByTheBell: {
    id: 'AssignedUsersGroupNotifications.assignNotificationsByTheBell',
    description: 'Assign notifications by the bell description',
    defaultMessage: 'Assign notifications by the bell'
  },
  unassignNotificationsByTheBell: {
    id: 'AssignedUsersGroupNotifications.unassignNotificationsByTheBell',
    description: 'Unassign notifications by the bell description',
    defaultMessage: 'Unassign notifications by the bell'
  },
  assignNotificationsByEmail: {
    id: 'AssignedUsersGroupNotifications.assignNotificationsByEmail',
    description: 'Assign notifications by email description',
    defaultMessage: 'Assign notifications by email'
  },
  unassignNotificationsByEmail: {
    id: 'AssignedUsersGroupNotifications.unassignNotificationsByEmail',
    description: 'Unassign notifications by email description',
    defaultMessage: 'Unassign notifications by email'
  },
  assignNotificationsByPush: {
    id: 'AssignedUsersGroupNotifications.assignNotificationsByPush',
    description: 'Assign notifications by push description',
    defaultMessage: 'Assign notifications by push'
  },
  unassignNotificationsByPush: {
    id: 'AssignedUsersGroupNotifications.unassignNotificationsByPush',
    description: 'Unassign notifications by push description',
    defaultMessage: 'Unassign notifications by push'
  },
  assignNotificationsBySms: {
    id: 'AssignedUsersGroupNotifications.assignNotificationsBySms',
    description: 'Assign notifications by SMS description',
    defaultMessage: 'Assign notifications by SMS'
  },
  unassignNotificationsBySms: {
    id: 'AssignedUsersGroupNotifications.unassignNotificationsBySms',
    description: 'Unassign notifications by SMS description',
    defaultMessage: 'Unassign notifications by SMS'
  }
})

export default messages
