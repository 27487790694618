import React from 'react'

import PropTypes from 'prop-types'

import { Step, Stepper, StepLabel } from 'material-ui/Stepper'

import { injectIntl } from 'react-intl'
import messages from './messages'

/**
 * Horizontal steppers are ideal when the contents of one step depend on an earlier step.
 * Avoid using long step names in horizontal steppers.
 *
 * Linear steppers require users to complete one step in order to move on to the next.
 */
class NotificationsRulesSettingsStepper extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage  
    this.state = {
      stepIndex: props.stepIndex
    }
  }

  render() {
    return (
      <div
        style={{
          borderBottom: '2px solid rgb(189, 189, 189)',
          marginBottom: 10
        }}
      >
        <div style={{ margin: 'auto', maxWidth: 700, width: '100%' }}>
          <Stepper activeStep={this.state.stepIndex}>
            <Step>
              <StepLabel className="stepLabel-icon">
                {this.formatMessage(messages.generalSettings)}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel className="stepLabel-icon">{this.formatMessage(messages.conditionalSignals)}</StepLabel>
            </Step>
            
            <Step>
              <StepLabel className="stepLabel-icon">{this.formatMessage(messages.assignedUsers)}</StepLabel>
            </Step>
          </Stepper>
        </div>
      </div>
    )
  }
}

NotificationsRulesSettingsStepper.propTypes = {
  stepIndex: PropTypes.number
}

export default injectIntl(NotificationsRulesSettingsStepper)
