import { createAction } from 'redux-actions'

import * as t from './actionTypes'
import { utils } from '../login'
import { getAccessTokendDateExpiresIn } from '../login/cache'

import { hasBeenInitialized } from '../login/reducer'
import { unauthorize } from '../login/actions'

import { utils as groupUtils } from 'ducks/groups'

import { url } from 'utils/http'

import SockJS from 'sockjs-client'
import Stomp from 'stompjs'

import moment from 'moment'

// ------------------------------------
// Simple action creators
// ------------------------------------

const setNotificationsTotals = createAction(t.SET_TOTALS, (timestamp, totalsData) => ({
  notificationsTotalsReceivedTimestamp: timestamp,
  notificationsTotalsReceivedData: totalsData
}))

const setStompClientSuscribe = createAction(t.SET_STOMPCLIENT, stompClientSuscribe => ({
  notificationsTotalsStompClient: stompClientSuscribe
}))

// ------------------------------------
// Composed action creators
// ------------------------------------
const connectToWebSocketForNotifications = () => (dispatch, getState) => {
  const state = getState()

  // Check if token exists
  if (hasBeenInitialized(state)) {
    const currentToken = utils.getAccessToken(state)

    if (currentToken !== undefined) {
      // Check if token has expired
      const currentDate = moment()
      const currentTokenDateExpirenIn = moment(getAccessTokendDateExpiresIn())
      const diffDates = currentTokenDateExpirenIn.diff(currentDate)
      let tokenIsExpired = false
      if (diffDates < 0) {
        tokenIsExpired = true
      }

      if (!tokenIsExpired) {
        const userSelectedGroup = groupUtils.getSelectedGroup(state)
        const urlGetNotificationTotals = url.getNotificationsTotals(userSelectedGroup.id)
        const urlAPI = process.env.REACT_APP_HTTP_API
        const webSocketSuscribeUrl = urlAPI + urlGetNotificationTotals + '&access_token=' + currentToken

        // Before connecting with new client check if there is another stompClient running to disconnect from it before
        const stompClientConnected = state.notifications.currentStompClient
        if (stompClientConnected !== undefined) {
          stompClientConnected.disconnect(function () {
            dispatch(connectAndSuscribeToWebSocket(webSocketSuscribeUrl, currentToken, 0))
          })
        } else {
          dispatch(connectAndSuscribeToWebSocket(webSocketSuscribeUrl, currentToken, 0))
        }
      } else {
        //Disconnect User
        dispatch(unauthorize())
      }
    } else {
      //Disconnect User
      dispatch(unauthorize())
    }
  }
}

const connectAndSuscribeToWebSocket = (webSocketSuscribeUrl, currentToken, numOfAttemps) => dispatch => {
  let stompClient = null

  const socket = new SockJS(webSocketSuscribeUrl)
  stompClient = Stomp.over(socket)

  // Disable debug message of stomp
  stompClient.debug = null

  //Open connection with websocket
  stompClient.connect(
    {},
    () => {
      //Connection success
      const urlSessionIdExtractorRegEx = url.getNotificationsTotalsUrlSessionIdExtractorRegEx()
      const result = urlSessionIdExtractorRegEx.exec(stompClient.ws._transport.url)

      if (result !== null) {
        const { sessionId } = result.groups
        const suscribeTo = url.getNotificationsTotalsMethodToSuscribe(sessionId)
        stompClient.subscribe(suscribeTo, data => {
          const jsonData = JSON.parse(data.body)
          if (jsonData !== undefined) {
            dispatch(setNotificationsTotals(jsonData.timestamp, jsonData.totals))
          }
        })
      }

      //Save stompClient in state
      dispatch(setStompClientSuscribe(stompClient))
    },
    () => {
      //Connection error
      //console.log("Connection error")

      numOfAttemps = numOfAttemps + 1 // eslint-disable-line no-param-reassign
      if (numOfAttemps <= 2) {
        setTimeout(() => {
          dispatch(connectAndSuscribeToWebSocket(webSocketSuscribeUrl, numOfAttemps))
        }, 5000)
      }
    }
  )
}

export { connectToWebSocketForNotifications }
