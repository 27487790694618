import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

const GroupsTableTitle = props => {
  const {
    newGroupUrl = '',
    canCreateGroups = false,
    canReadGroups = false,
    intl: { formatMessage }
  } = props

  const button =
    canCreateGroups && canReadGroups
      ? {
        icon: 'zmdi-plus',
        label: formatMessage(messages.newGroup),
        path: newGroupUrl,
        primary: true
      }
      : null

  return <PageTitle button={button} title={formatMessage(messages.title)} />
}

GroupsTableTitle.propTypes = {
  canCreateGroups: PropTypes.bool.isRequired,
  canReadGroups: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  newGroupUrl: PropTypes.string.isRequired
}

export default injectIntl(GroupsTableTitle)
