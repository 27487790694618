import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import Card from 'components/UnboxedCard'
import { config } from './chartParams.js'
import { HistoricChart } from 'components/Chart'

import messages from '../../messages'

const styles = {
  downloadCsvButton: {
    minWidth: '200px !important',
    textTransform: 'uppercase',
    '&:hover': {
      minWidth: '200px !important'
    },
    '&:disabled': {
      minWidth: '200px !important'
    }
  },
  downloadCsvReadyButton: {
    backgroundColor: '#009541 !important',
    '&:hover': {
      backgroundColor: '#006e30 !important'
    }
  }
}

class ChartArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      maxRequestDate: Math.floor(moment().valueOf() / 1000 / 60) * 1000 * 60,
      minRequestDate: Math.floor(moment().subtract(7, 'days').valueOf() / 1000 / 60) * 1000 * 60,
      maxResponseDate: 0,
      minResponseDate: 0,
      prevRequestTimestamps: []
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { colors, isSidebarCollapsed, isChartLoading, chartData, isRetrieveCsvDataButtonLoading } = this.props
    const { maxRequestDate, minRequestDate } = this.state

    if (
      !_isEqual(nextProps.colors, colors) ||
      !_isEqual(nextProps.chartData, chartData) ||
      !_isEqual(nextProps.isRetrieveCsvDataButtonLoading, isRetrieveCsvDataButtonLoading) ||
      nextProps.isSidebarCollapsed !== isSidebarCollapsed ||
      nextProps.isChartLoading !== isChartLoading ||
      nextState.maxRequestDate !== maxRequestDate ||
      nextState.minRequestDate !== minRequestDate
    ) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const { groupId, isSidebarCollapsed } = this.props

    if (prevProps.isSidebarCollapsed !== isSidebarCollapsed) {
      setTimeout(() => {
        this.forceUpdate()
      }, 500)
    }
    if (groupId && prevProps.groupId !== groupId) {
      this.setState({
        maxRequestDate: Math.floor(moment().valueOf() / 1000 / 60) * 1000 * 60,
        minRequestDate: Math.floor(moment().subtract(7, 'days').valueOf() / 1000 / 60) * 1000 * 60,
        maxResponseDate: 0,
        minResponseDate: 0,
        prevRequestTimestamps: []
      })
    }
  }

  setSearchRange = (min, max) => {
    const { onSearch, setChartQueryType } = this.props
    this.setState({ maxRequestDate: max, minRequestDate: min, prevRequestTimestamps: [] })
    setChartQueryType('FIRST', min, max)
    onSearch(min, max)
  }

  handleDateTimeMinChange = min => {
    const { resetExportDataQueryStatus } = this.props
    resetExportDataQueryStatus()
    this.setState({ minRequestDate: min })
  }

  handleDateTimeMaxChange = max => {
    const { resetExportDataQueryStatus } = this.props
    resetExportDataQueryStatus()
    this.setState({ maxRequestDate: max })
  }

  handleExportDataClick = () => {
    const { onExport } = this.props
    const { maxRequestDate, minRequestDate } = this.state

    onExport(minRequestDate, maxRequestDate)
  }

  handlePreviousHistoricSet = () => {
    const { onSearch, setChartQueryType } = this.props
    const { prevRequestTimestamps } = this.state
    const prevMinRequestTimestamp = prevRequestTimestamps[prevRequestTimestamps.length - 1][0]
    const prevMaxRequestTimestamp = prevRequestTimestamps[prevRequestTimestamps.length - 1][1]
    prevRequestTimestamps.pop()
    this.setState({
      prevRequestTimestamps
    })
    setChartQueryType('PREV', prevMinRequestTimestamp, prevMaxRequestTimestamp)
    onSearch(prevMinRequestTimestamp, prevMaxRequestTimestamp)
  }

  handleNextHistoricSet = () => {
    const { onSearch, chartData, setChartQueryType } = this.props
    const { maxRequestDate } = this.state
    const chartEnd = chartData.chartEnd
    const chartStart = chartData.chartStart

    this.setState(state => ({
      prevRequestTimestamps: [...state.prevRequestTimestamps, [chartStart, chartEnd]]
    }))
    setChartQueryType('NEXT', chartEnd + 1, maxRequestDate)
    onSearch(chartEnd + 1, maxRequestDate)
  }

  getChartDataWithNavigatorOptions = chartData => {
    const updatedChartData = _cloneDeep(chartData)
    if (Array.isArray(updatedChartData?.series) && updatedChartData.series.length > 0) {
      updatedChartData.series = updatedChartData.series.map(serie => ({
        ...serie,
        navigatorOptions: {
          type: serie.type || 'line'
        }
      }))
    }
    return updatedChartData
  }

  render() {
    const { chartData, classes, isChartLoading, isRetrieveCsvDataButtonLoading } = this.props
    const { maxRequestDate, minRequestDate, maxResponseDate, minResponseDate, prevRequestTimestamps } = this.state

    const chartEnd = chartData.chartEnd
    const isPrev = prevRequestTimestamps.length > 0
    const isNext = maxRequestDate > chartEnd

    const chartDataWithNavigatorOptions = this.getChartDataWithNavigatorOptions(chartData)
    const mergedConfig = Highcharts.merge(config(), chartDataWithNavigatorOptions)

    return (
      <Grid item xs={12}>
        <Paper style={{ borderRadius: 0 }}>
          <Card>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CardHeader
                  action={
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          className={`primary-action-button ${classes.downloadCsvButton}`}
                          disabled={isRetrieveCsvDataButtonLoading}
                          onClick={this.handleExportDataClick}
                          startIcon={<i className='zmdi zmdi-swap-vertical' style={{ fontSize: 16 }} />}
                          variant='contained'
                        >
                          {this.formatMessage(messages.retrieveCSVData)}
                          {isRetrieveCsvDataButtonLoading && (
                            <CircularProgress size={24} style={{ position: 'absolute' }} />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  }
                  style={{ paddingBottom: '0px' }}
                  title={this.formatMessage(messages.chartPreview)}
                />
              </Grid>
            </Grid>
            <CardContent style={{ paddingTop: '0px' }}>
              <HistoricChart
                config={mergedConfig}
                isChartLoading={isChartLoading}
                maxRequestDate={maxRequestDate}
                maxResponseDate={maxResponseDate}
                minRequestDate={minRequestDate}
                minResponseDate={minResponseDate}
                nextResponseSet={isNext}
                onDateTimeMaxChange={this.handleDateTimeMaxChange}
                onDateTimeMinChange={this.handleDateTimeMinChange}
                onNextHistoricSet={this.handleNextHistoricSet}
                onPreviousHistoricSet={this.handlePreviousHistoricSet}
                previousResponseSet={isPrev}
                requestHistoricData={i => i}
                requestStatus=''
                setSearchRange={this.setSearchRange}
                title={this.formatMessage(messages.historicalDataQueryPreview)}
              />
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    )
  }
}

ChartArea.propTypes = {
  chartData: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  colors: PropTypes.any.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  isRetrieveCsvDataButtonLoading: PropTypes.bool.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  resetExportDataQueryStatus: PropTypes.func.isRequired,
  setChartQueryType: PropTypes.func.isRequired
}

export default injectIntl(withStyles(styles)(ChartArea))
