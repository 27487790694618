export const alertStyles = {
  alertTitle: {
    color: 'inherit',
    fontSize: '16px'
  },
  alertMessage: {
    width: '100%'
  },
  alertLink: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}

export const iconButtonStyles = {
  iconButtonRoot: {
    padding: '5px'
  }
}

export const deviceConfigurationStyles = theme => ({
  paperRoot: {
    borderRadius: 0,
    padding: 20
  },
  buttonLabel: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 10
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 8
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 10
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 9
    }
  }
})
