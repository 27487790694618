import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from 'modules/geofences/actions'
import * as c from 'modules/geofences/constants'
import * as utils from 'modules/geofences/reducer'

import EditGeofenceStep from './EditGeofenceStep'

const mapStateToProps = state => ({
  boundary: utils.getBoundary(state[c.NAME]),
  description: utils.getGeofenceDescription(state[c.NAME]),
  descriptionErrorText: utils.getDescriptionErrorText(state[c.NAME]),
  editingGeofence: utils.creatingGeofence(state[c.NAME]),
  editingRuleInstance: utils.creatingRuleInstance(state[c.NAME]),
  geofence: utils.getGeofence(state[c.NAME]),
  geofenceColor: utils.getGeofenceColor(state[c.NAME]),
  geofenceHashId: utils.getGeofenceHashId(state[c.NAME]),
  isEditGeofenceButtonDisabled: utils.isCreateGeofenceButtonDisabled(state[c.NAME]),
  isEditRuleInstanceButtonDisabled: utils.isCreateRuleInstanceButtonDisabled(state[c.NAME]),
  name: utils.getGeofenceName(state[c.NAME]),
  nameErrorText: utils.getNameErrorText(state[c.NAME]),
  ruleInstanceHashId: utils.getRuleInstanceHashId(state[c.NAME]),
  rules: utils.getRules(state[c.NAME]),
  severity: utils.getSeverity(state[c.NAME]),
  status: utils.getStatus(state[c.NAME])
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeEditGeofenceDialog: actions.closeEditGeofenceDialog,
      editGeofence: actions.editGeofence,
      getGeofence: actions.getGeofence,
      getRule: actions.getRule,
      getRuleInstance: actions.getRuleInstance,
      getRules: actions.getRules,
      handleBoundaryChange: actions.handleBoundaryChange,
      handleDescriptionChange: actions.handleDescriptionChange,
      handleNameChange: actions.handleNameChange,
      handleSeverityChange: actions.handleSeverityChange,
      handleStatusChange: actions.handleStatusChange,
      selectGeofenceColor: actions.selectGeofenceColor,
      updateGeofence: actions.updateGeofence,
      updateRuleInstance: actions.updateRuleInstance,
      updateRules: actions.updateRules
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditGeofenceStep)
