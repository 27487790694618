import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ActivateDataPlan.title',
    description: 'Activate data plan title',
    defaultMessage: 'Activate {planName}'
  },
  selectExistingDevice: {
    id: 'ActivateDataPlan.selectExistingDevice',
    description: 'Select existing device',
    defaultMessage: 'Select existing device'
  },
  device: {
    id: 'ActivateDataPlan.device',
    description: 'Device',
    defaultMessage: 'Device'
  },
  cancel: {
    id: 'ActivateDataPlan.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  activate: {
    id: 'ActivateDataPlan.activate',
    description: 'Activate button label',
    defaultMessage: 'Activate'
  },
  error400Message: {
    id: 'ActivateDataPlan.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'ActivateDataPlan.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'ActivateDataPlan.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'ActivateDataPlan.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'ActivateDataPlan.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'ActivateDataPlan.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available'
  },
  error409Message: {
    id: 'ActivateDataPlan.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Conflict'
  },
  error415Message: {
    id: 'ActivateDataPlan.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'ActivateDataPlan.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error500Message: {
    id: 'ActivateDataPlan.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  error510Message: {
    id: 'ActivateDataPlan.error510Message',
    description: 'Error 510 message',
    defaultMessage: 'Vodafone Server Error'
  },
  error: {
    id: 'ActivateDataPlan.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'ActivateDataPlan.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  }
})

export default messages
