import { defineMessages } from 'react-intl'

const messages = defineMessages({
  from: {
    id: 'DateTimeRangeSelector.from',
    description: 'DateTimeRangeSelector from label',
    defaultMessage: 'From'
  },
  to: {
    id: 'DateTimeRangeSelector.to',
    description: 'DateTimeRangeSelector to label',
    defaultMessage: 'To'
  },
  time: {
    id: 'DateTimeRangeSelector.time',
    description: 'Time hint text part',
    defaultMessage: '{ floatinglabeltext } time'
  },
  ok: {
    id: 'DateTimeRangeSelector.ok',
    description: 'Ok button label',
    defaultMessage: 'OK'
  },
  cancel: {
    id: 'DateTimeRangeSelector.cancel',
    description: 'Cancel button label',
    defaultMessage: 'cancel'
  },
})

export default messages