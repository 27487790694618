import { defineMessages } from 'react-intl'

const namespace = 'WeatherSettings'

const messages = defineMessages({
  weatherWidget: {
    id: `${namespace}.weatherWidget`,
    description: 'Weather widget',
    defaultMessage: 'WEATHER WIDGET'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  youMustSelectAMachine: {
    id: `${namespace}.youMustSelectAMachine`,
    description: 'You must select a machine',
    defaultMessage: 'You must select a machine'
  },
  weatherWidgetSettings: {
    id: `${namespace}.weatherWidgetSettings`,
    description: 'Weather widget settings',
    defaultMessage: 'Weather widget settings:'
  },
  days: {
    id: `${namespace}.days`,
    description: 'Days',
    defaultMessage: 'Days'
  },
  unit: {
    id: `${namespace}.unit`,
    description: 'Unit',
    defaultMessage: 'Unit'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  youMustSelectForecastDays: {
    id: `${namespace}.youMustSelectForecastDays`,
    description: 'You must select forecast days',
    defaultMessage: 'You must select forecast days'
  },
  youMustSelectUnit: {
    id: `${namespace}.youMustSelectUnit`,
    description: 'You must select unit',
    defaultMessage: 'You must select unit'
  },
  selectForecastDays: {
    id: `${namespace}.selectForecastDays`,
    description: 'Select forecast days',
    defaultMessage: 'Select forecast days'
  },
  selectTemperaturetUnit: {
    id: `${namespace}.selectTemperatureUnit`,
    description: 'Select temperature unit',
    defaultMessage: 'Select temperature unit'
  },
  mondayShort: {
    id: `${namespace}.mondayShort`,
    description: 'Monday short',
    defaultMessage: 'Mon'
  },
  tuesdayShort: {
    id: `${namespace}.tuesdayShort`,
    description: 'Tuesday short',
    defaultMessage: 'Tue'
  },
  wednesdayShort: {
    id: `${namespace}.wednesdayShort`,
    description: 'Wednesday short',
    defaultMessage: 'Wed'
  },
  thursdayShort: {
    id: `${namespace}.thursdayShort`,
    description: 'Thursday short',
    defaultMessage: 'Thu'
  },
  fridayShort: {
    id: `${namespace}.fridayShort`,
    description: 'Friday short',
    defaultMessage: 'Fri'
  },
  saturdayShort: {
    id: `${namespace}.saturdayShort`,
    description: 'Saturday short',
    defaultMessage: 'Sat'
  },
  sundayShort: {
    id: `${namespace}.sundayShort`,
    description: 'Sunday short',
    defaultMessage: 'Sun'
  },
  mondayLong: {
    id: `${namespace}.mondayLong`,
    description: 'Monday long',
    defaultMessage: 'Monday'
  },
  tuesdayLong: {
    id: `${namespace}.tuesdayLong`,
    description: 'Tuesday long',
    defaultMessage: 'Tuesday'
  },
  wednesdayLong: {
    id: `${namespace}.wednesdayLong`,
    description: 'Wednesday long',
    defaultMessage: 'Wednesday'
  },
  thursdayLong: {
    id: `${namespace}.thursdayLong`,
    description: 'Thursday long',
    defaultMessage: 'Thursday'
  },
  fridayLong: {
    id: `${namespace}.fridayLong`,
    description: 'Friday long',
    defaultMessage: 'Friday'
  },
  saturdayLong: {
    id: `${namespace}.saturdayLong`,
    description: 'Saturday long',
    defaultMessage: 'Saturday'
  },
  sundayLong: {
    id: `${namespace}.sundayLong`,
    description: 'Sunday long',
    defaultMessage: 'Sunday'
  },
  today: {
    id: `${namespace}.today`,
    description: 'Today',
    defaultMessage: 'Today'
  },
  errorFetchingWeatherData: {
    id: `${namespace}.errorFetchingWeatherData`,
    description: 'Error fetching weather data',
    defaultMessage: 'Error fetching weather data'
  },
  noLocationAvailable: {
    id: `${namespace}.noLocationAvailable`,
    description: 'No location available',
    defaultMessage: 'No location available'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  }
})

export default messages
