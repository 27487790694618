import PropTypes from 'prop-types'
import React from 'react'
import Draggable from 'react-draggable'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import messages from '../messages'

const styles = {
  dialog: {
    minWidth: 800
  },
  dialogTitle: {
    padding: '12px'
  },
  dialogTitleText: {
    paddingLeft: '12px'
  },
  dialogContent: {
    padding: '12px 10px 10px 12px'
  },
  dialogTitleButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    padding: 5
  },
  dialogContentText: {
    marginBottom: '10px',
    paddingLeft: '12px'
  }
}

const SelectGeofenceDialog = props => {
  const {
    classes,
    intl: { formatMessage },
    isOpen,
    onClose,
    onDeleteClick,
    onEditClick,
    privileges,
    selectedGeofence
  } = props

  if (!selectedGeofence) return null

  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      PaperComponent={subcomponentProps => (
        <Draggable>
          <Paper style={{ borderLeft: '10px solid ' + selectedGeofence.color }} {...subcomponentProps} />
        </Draggable>
      )}
      classes={{ paper: classes.dialog }}
      onClose={onClose}
      open={isOpen}
    >
      <div style={{ marginLeft: '10px', borderLeft: '5px solid ' + selectedGeofence.color }} />

      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>{selectedGeofence.name}</span>
        <IconButton className={classes.dialogTitleButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText} color='textPrimary'>
          <strong>{formatMessage(messages.description)}</strong>{' '}
          {selectedGeofence.description ? selectedGeofence.description : ' - '}
        </DialogContentText>
        <DialogContentText className={classes.dialogContentText} color='textPrimary'>
          <strong>{formatMessage(messages.notification)}</strong>{' '}
          {selectedGeofence.ruleInstances
            ? selectedGeofence.ruleInstances[0].enabled
              ? formatMessage(messages.notificationEnabled)
              : formatMessage(messages.notificationDisabled)
            : formatMessage(messages.notificationNotAssigned)}
        </DialogContentText>
        <DialogContentText className={classes.dialogContentText} color='textPrimary'>
          <strong>{formatMessage(messages.machines)}</strong>{' '}
          {selectedGeofence?.ruleInstances?.[0]?.devices?.length > 0
            ? selectedGeofence.ruleInstances[0].devices.map(p => p.deviceName).join(', ')
            : '-'}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          className='primary-action-button'
          disabled={!privileges.canEditGeofence}
          onClick={onEditClick}
          style={{ minWidth: 100 }}
        >
          {formatMessage(messages.edit)}
        </Button>
        <Button
          className='delete-button'
          disabled={!privileges.canDeleteGeofence}
          onClick={onDeleteClick}
          style={{ minWidth: 100 }}
        >
          {formatMessage(messages.delete)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SelectGeofenceDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  privileges: PropTypes.object.isRequired,
  selectedGeofence: PropTypes.object
}

SelectGeofenceDialog.defaultProps = {
  selectedGeofence: {}
}

export default injectIntl(withStyles(styles)(SelectGeofenceDialog))
