import { connect } from 'react-redux'

import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'

import MachinesSettingsStep from './MachinesSettingsStep'

const mapStateToProps = state => {
  return {
    groupId: groupUtils.getSelectedGroup(state).id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    /* eslint-disable max-params */
    fetchCSNodesDetails: (groupId, devicesEIDs) => dispatch(csNode.actions.fetchCSNodesDetails(groupId, devicesEIDs)),
    fetchDynamicCSNodes: (groupId, limit, offset, extraQueryParameters, filters) =>
      dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, extraQueryParameters, filters))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MachinesSettingsStep)
