import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Users } from './Users'

import { EditUser } from './EditUser'
import { USERS_BASE, USER_SETTINGS_EDIT } from './url'

const UsersHome = ({ canReadUsers, canEditUsers, location }) => (
  <Switch>
    {canEditUsers && location.pathname !== '/users/user' && <Route component={EditUser} path={USER_SETTINGS_EDIT} />}
    {canReadUsers && <Route component={Users} path={USERS_BASE} />}
  </Switch>
)

export default UsersHome
