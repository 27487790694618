import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import GaugeConfig from './GaugeConfig'
import messages from './messages'
import * as utils from '../utils'

class Gauge extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.chartTextRenderer = null
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (!prevProps.data.value?.value && typeof data.value?.value === 'number' && this.chartTextRenderer) {
      this.chartTextRenderer.destroy()
    }
  }

  getSignalData = () => {
    const { devicesData, eid, data } = this.props
    if (devicesData[eid]) {
      let signalData = devicesData[eid].dinamicData.filter(signal => {
        return signal.signalId === data.data
      })

      if (signalData[0] === undefined) {
        signalData = [
          {
            unit: this.formatMessage(messages.noSignal)
          }
        ]
      }

      return signalData[0]
    }

    return {}
  }

  signalDataValue = () => {
    const { data } = this.props
    let numOfDec = 0
    if (typeof data.numberOfDecimals !== 'undefined') numOfDec = data.numberOfDecimals
    const value = data.value.value
    const num = parseFloat('1e' + numOfDec)
    return Math.round((value + Number.EPSILON) * num) / num
  }

  getSignalName = signalId => {
    const { devicesData, eid } = this.props
    let dinamicSignal = null

    if (devicesData[eid]) {
      dinamicSignal = devicesData[eid].dinamicData.find(signal => signal.signalId === signalId)
    }

    if (dinamicSignal && dinamicSignal.name) {
      return dinamicSignal.name
    }
  }

  getChartTitle = () => {
    let title = ''
    if (this.getSignalData()) title = this.getSignalData().unit
    return title
  }

  afterRender = chart => {
    if (this.getSignalData().unit === this.formatMessage(messages.noSignal)) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      ctx.font = '16px Arial'
      const textWidth = ctx.measureText(this.formatMessage(messages.noSignal)).width
      const x = Math.floor(chart.chartWidth / 2) - Math.floor(textWidth / 2)
      const y = chart.chartHeight - Math.floor(chart.chartHeight / 3)
      this.chartTextRenderer = chart.renderer
        .text(this.formatMessage(messages.noSignal), x, y)
        .css({
          fontSize: '16px'
        })
        .add()
    }
  }

  render() {
    const { data: propsData, devicesData, eid, isSubscribedTo, height, width, editing } = this.props
    const { minValue, maxValue, value, data } = propsData
    const timestampString = value?.timestamp ? moment(value.timestamp).format('L LTS') : ''

    const config = GaugeConfig(timestampString)
    const min = parseInt(minValue, 10)
    const max = parseInt(maxValue, 10)
    const valueFontSize = (height + width) / 25
    const titleFontSize = (height + width) / 30

    config.yAxis.min = min
    config.yAxis.max = max
    config.yAxis.plotBands[0].from = min
    config.yAxis.plotBands[0].to = max
    config.yAxis.title.text = this.getChartTitle()
    config.yAxis.title.style.fontSize = titleFontSize

    if (value === '') {
      config.yAxis.title.text = this.formatMessage(messages.noSignal)
      config.yAxis.title.style.color = '#F00'
      config.yAxis.title.style.fontWeight = 'bold'
    } else {
      config.yAxis.title.style.width = '100%'
    }
    if (!editing) {
      config.series[0].data[0] = typeof value?.value === 'number' ? this.signalDataValue() : null
      config.series[0].tooltip.valueSuffix = this.getSignalData() ? ' ' + this.getSignalData().unit : ''
      config.series[0].name = this.getSignalName(data) ? this.getSignalName(data) : ''
      config.series[0].dataLabels = { style: { fontSize: valueFontSize } }
    }

    const dinamicData = devicesData[eid]?.dinamicData || []
    const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)

    const isValidData = dinamicDataSignalIds.includes(data)
    const widgetIsNotConfigured = data === ''
    const noDynamicSignalsAvailable = dinamicData.length === 0
    const widgetIsSubscribing = (eid === '' || !isSubscribedTo(eid, utils.getTopic(data, eid))) && isValidData

    return (
      <div
        className={
          widgetIsNotConfigured || noDynamicSignalsAvailable ? 'highcharts-wrapper notConfigured' : 'highcharts-wrapper'
        }
      >
        {noDynamicSignalsAvailable ? (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'left'
              }}
            >
              {this.formatMessage(messages.notSupportedMachine)}
            </span>
          </Typography>
        ) : widgetIsNotConfigured ? (
          <Typography gutterBottom={false}>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : widgetIsSubscribing ? (
          <div className='sk-bounce small'>
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
            <div className='sk-bounce-dot' />
          </div>
        ) : (
          <HighchartsReact callback={this.afterRender} highcharts={Highcharts} options={config} />
        )}
      </div>
    )
  }
}

Gauge.propTypes = {
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  eid: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  isSubscribedTo: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
}

export default injectIntl(Gauge)
