import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import csNode from 'modules/csNode'

import Weather from './Weather'
import WeatherSettingsUnconnected from './WeatherSettings'
import WeatherTemplate from './WeatherTemplate'

const mapStateToProps = (state, { match }) => {
  const { nodeId } = match.params
  return {
    node: csNode.utils.getCSNodeById(state, nodeId)
  }
}

export default withRouter(connect(mapStateToProps, null)(Weather))
const WeatherSettings = withRouter(connect(mapStateToProps, null)(WeatherSettingsUnconnected))
export { WeatherSettings, WeatherTemplate }
