import { connect } from 'react-redux'

import { actions as cs500Actions } from 'ducks/configurationsCS500'
import { utils } from 'ducks/groups'
import csNode from 'modules/csNode'

import SelectMachine from './SelectMachine'

const mapStateToProps = state => ({
  groupId: utils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  // eslint-disable-next-line max-params
  getGroupDevices: (groupId, limit, offset, deviceFields, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields, filters)),
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  getCSNodeByEID: (eid, groupId) => dispatch(csNode.actions.fetchCSNodeByEid(eid, groupId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectMachine)
