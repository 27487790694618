import { defineMessages } from 'react-intl'

const namespace = 'EditNotificationConditionalSignals'

const messages = defineMessages({
  conditionalSignals: {
    id: `${namespace}.conditionalSignals`,
    description: 'Conditional Signals form',
    defaultMessage: 'Conditional Signals'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Edit notification title',
    defaultMessage: 'Notification'
  },
  previous: {
    id: `${namespace}.previous`,
    description: 'Previous button label',
    defaultMessage: 'Previous'
  },
  next: {
    id: `${namespace}.next`,
    description: 'Next button label',
    defaultMessage: 'Next'
  },
  list: {
    id: `${namespace}.list`,
    description: 'List button label',
    defaultMessage: 'List'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  errorText: {
    id: `${namespace}.errorText`,
    description: 'Error title',
    defaultMessage: 'Error'
  },
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Bad request'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error403Text: {
    id: `${namespace}.error403Text`,
    description: 'Error 403 text',
    defaultMessage: 'Access denied'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  error409Text: {
    id: `${namespace}.error409Text`,
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation: ' + 'Group name it`s already in use'
  },
  error415Text: {
    id: `${namespace}.error415Text`,
    description: 'Error 415 text',
    defaultMessage: 'Unsupported media type'
  },
  error422Text: {
    id: `${namespace}.error422Text`,
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  errorGeneric: {
    id: 'NewNotification.errorGeneric',
    description: 'Error generic',
    defaultMessage: 'Error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  errorLoadingNotification: {
    id: `${namespace}.errorLoadingNotification`,
    description: 'Error loading notification description',
    defaultMessage: 'It was not possible to load the requested notification.'
  },
  noSignalAvailable: {
    id: `${namespace}.noSignalAvailable`,
    description: 'No signal available title',
    defaultMessage: 'No signals available'
  },
  noSignalAreAvailableAtThisMoment: {
    id: `${namespace}.noSignalAreAvailableAtThisMoment`,
    description: 'No signal available error',
    defaultMessage: 'There are no signals available to configure the rule with the chosen machines'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  configureConditionForRule: {
    id: `${namespace}.configureConditionForRule`,
    description: 'Configure condition for rule error',
    defaultMessage: 'You have to configure the signal conditions to generate the rule'
  },
  notificationUpdatedTitle: {
    id: `${namespace}.notificationUpdatedTitle`,
    description: 'Notification updated title',
    defaultMessage: 'Notification updated'
  },
  notificationUpdatedMessage: {
    id: `${namespace}.notificationUpdatedMessage`,
    description: 'Notification updated message',
    defaultMessage: 'Data have been saved correctly.'
  },
  errorSavingNotification: {
    id: `${namespace}.errorSavingNotification`,
    description: 'Error saving notification description',
    defaultMessage: 'An error occurred while saving the notification. Try again in a few minutes.'
  },
  valueAvg: {
    id: `${namespace}.valueAvg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: `${namespace}.valueMin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: `${namespace}.valueMax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  }
})

export default messages
