import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'manageAccessPassConsumption.title',
    description: 'Allow Access Pass consumption dialog title',
    defaultMessage: '{action} Access Pass consumption on {name} device'
  },
  errorManageAccessPassConsumption: {
    id: 'manageAccessPassConsumption.errorManageAccessPassConsumption',
    description: 'Allow Access Pass consumption error message',
    defaultMessage: 'There was an error allowing Access Pass consumption for this device'
  },
  confirmQuestion: {
    id: 'manageAccessPassConsumption.confirmQuestion',
    description: 'Allow Access Pass consumption confirming question',
    defaultMessage: 'Are you sure that you want to {action} Access Pass consumption on {name} device?'
  },
  rememberText: {
    id: 'manageAccessPassConsumption.rememberText',
    description: 'Allow Access Pass consumption conditions remembering text',
    defaultMessage: 'Remember going to the Service Tool to start consuming an Access Pass'
  },
  cancel: {
    id: 'manageAccessPassConsumption.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  allow: {
    id: 'manageAccessPassConsumption.allow',
    description: 'Allow',
    defaultMessage: 'Allow'
  },
  disallow: {
    id: 'manageAccessPassConsumption.disallow',
    description: 'Disallow',
    defaultMessage: 'Disallow'
  },
  allowLowerCase: {
    id: 'manageAccessPassConsumption.allowLowerCase',
    description: 'Allow',
    defaultMessage: 'allow'
  },
  disallowLowerCase: {
    id: 'manageAccessPassConsumption.disallowLowerCase',
    description: 'Disallow',
    defaultMessage: 'disallow'
  },
  allowing: {
    id: 'manageAccessPassConsumption.allowing',
    description: 'allowing',
    defaultMessage: 'allowing'
  },
  disallowing: {
    id: 'manageAccessPassConsumption.disallowing',
    description: 'disallowing',
    defaultMessage: 'disallowing'
  }
})

export default messages
