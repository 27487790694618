import { defineMessages } from 'react-intl'

const namespace = 'gmaps.EditGeofence'

const messages = defineMessages({
  assignMachinesStep: {
    id: `${namespace}.assignMachinesStep`,
    description: 'Assign machines step',
    defaultMessage: 'Assign machines'
  },
  assignUsersStep: {
    id: `${namespace}.assignUsersStep`,
    description: 'Assign users step',
    defaultMessage: 'Assign users'
  },
  configureNotificationStep: {
    id: `${namespace}.configureNotificationStep`,
    description: 'Configure notification step',
    defaultMessage: 'Configure notification'
  },
  editGeofenceStep: {
    id: `${namespace}.createGeofenceStep`,
    description: 'Edit geofence step',
    defaultMessage: 'Edit geofence'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Edit geofence title',
    defaultMessage: 'Edit geofence notification'
  }
})

export default messages
