import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from '@material-ui/core/SvgIcon'

import { Marker } from 'components/Map'

const DirectionalMarker = ({ color, headingAngle, noHeading, ...markerProps }) => {
  const triangle = [
    { x: 12, y: 3 },
    { x: 10, y: 6 },
    { x: 14, y: 6 }
  ]

  const markerCenterPoint = {
    x: 12,
    y: 12
  }

  const rotatedTriangle = triangle.map(trianglePoint => {
    let headingAngleInRadians = 0
    if (!isNaN(headingAngle)) {
      headingAngleInRadians = Math.PI * headingAngle / 180
    }
    const cos = Math.cos(headingAngleInRadians)
    const sin = Math.sin(headingAngleInRadians)
    const nx =
      cos * (trianglePoint.x - markerCenterPoint.x) +
      sin * (trianglePoint.y - markerCenterPoint.y) +
      markerCenterPoint.x
    const ny =
      cos * (trianglePoint.y - markerCenterPoint.y) -
      sin * (trianglePoint.x - markerCenterPoint.x) +
      markerCenterPoint.y
    return [nx, ny]
  })

  return (
    <Marker {...markerProps}>
      <SvgIcon>
        <circle
          r="3"
          cy="12"
          cx="12"
          style={{
            color,
            opacity: 1
          }}
        />
        <circle
          r="11"
          cy="12"
          cx="12"
          style={{
            color,
            opacity: 0.5,
            stroke: color,
            strokeWidth: 1,
            strokeOpacity: 1
          }}
        />
        {noHeading ? null : (
          <polygon
            points={rotatedTriangle}
            style={{
              color,
              stroke: color,
              strokeWidth: 1,
              strokeOpacity: 1
            }}
          />
        )}
      </SvgIcon>
    </Marker>
  )
}

DirectionalMarker.propTypes = {
  color: PropTypes.string.isRequired,
  headingAngle: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]),
  noHeading: PropTypes.bool.isRequired
}

DirectionalMarker.defaultProps = {
  headingAngle: 0
}

export default DirectionalMarker
