const reactBootstrapTable = {
  count: 0,
  length: 10,
  elementsPerPage: [
    {
      text: '10',
      value: 10
    },
    {
      text: '20',
      value: 20
    },
    {
      text: '50',
      value: 50
    },
    {
      text: '100',
      value: 100
    }
  ],
  page: 1,
  start: 0
}

export default reactBootstrapTable
