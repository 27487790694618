import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import memoize from 'lodash/memoize'
import { withStyles } from '@material-ui/core/styles'

const SerieCheckbox = ({ name, isVisible = true, classes, onChange }) => (
  <span className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
    <FormControlLabel
      control={
        <Checkbox checked={isVisible} classes={classes} onChange={(_, isInputChecked) => onChange(isInputChecked)} />
      }
      label={name}
    />
  </span>
)

SerieCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

// So mui-v3 does not create infinite CSS classes for the same definition
const getColorStyle = memoize((color, component) =>
  withStyles({
    root: {
      color
    },
    checked: { '&$checked': { color } }
  })(component)
)

const getColoredCheckbox = color => getColorStyle(color, SerieCheckbox)

export { getColoredCheckbox }
