import update from 'immutability-helper'

const removeValuesFromRealTimeWidgets = widgets =>
  Object.entries(widgets).reduce((acc, current) => {
    if (current[1].content.params.data !== '') {
      const valueTemplate = {
        timestamp: '',
        value: ''
      }
      switch (current[1].content.widgetType) {
        case 'realtimevalue':
        case 'speedometer':
        case 'gauge':
        case 'box':
        case 'text':
        case 'image':
          const currentObj = update(current[1], { content: { params: { value: { $set: valueTemplate } } } })
          return update(acc, { [current[0]]: { $set: currentObj } })
        case 'columnchart':
        case 'linechart':
          const currObj = update(current[1], {
            content: {
              params: {
                values: { $set: [] },
                timestamps: { $set: [] }
              }
            }
          })
          return update(acc, { [current[0]]: { $set: currObj } })
        default:
          return update(acc, { [current[0]]: { $set: current[1] } })
      }
    } else {
      return update(acc, { [current[0]]: { $set: current[1] } })
    }
  }, {})

export { removeValuesFromRealTimeWidgets }
