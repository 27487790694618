import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { injectIntl } from 'react-intl'
import messages from './messages'

const NAME_MAX_LENGTH = 50

class EditDashboard extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const description = JSON.parse(props.dashboardData.description)

    this.state = {
      hashId: props.dashboardData.hashId,
      deviceId: props.dashboardData.deviceId,
      uuid: props.dashboardData.uuid,
      version: props.dashboardData.version,
      editDashboardName: props.dashboardData.name,
      editDashboardNameError: false,
      editDashboardNameMessage: this.formatMessage(messages.maxCharacters),
      editDashboardDescription: description.description,
      editDashboardHeight: description.height,
      editDashboardHeightError: false,
      editDashboardWidth: description.width,
      editDashboardWidthError: false,
      zoom: description.zoom,
      position: props.dashboardIds.indexOf(props.dashboardData.hashId)
    }
  }

  // eslint-disable-next-line max-params
  saveDashboard = (name, description, height, width, zoom, position) => {
    if (description === '') {
      description = name // eslint-disable-line no-param-reassign
    }

    const descriptionJSON = JSON.stringify({
      description,
      height,
      width,
      zoom
    })

    const dashboard = {
      hashId: this.state.hashId,
      deviceId: this.state.deviceId,
      groupId: null,
      uuid: this.state.uuid,
      version: this.state.version,
      name,
      description: descriptionJSON,
      position
    }

    client
      .editDashboard(dashboard)
      .then(() => {
        this.props.refresh('other', dashboard.hashId)
      })
      .catch(response => {
        // const error = Object.assign({}, response)
        logError(response)
      })
  }

  saveEditDashboard = () => {
    let newHeight
    let newWidth
    let error = false
    let editDashboardNameError = false
    let editDashboardNameMessage = this.formatMessage(messages.maxCharacters)
    let editDashboardHeightError = false
    let editDashboardWidthError = false

    if (this.state.editDashboardName === '') {
      error = true
      editDashboardNameError = true
    }

    if (this.state.newDashboardName === '') {
      error = true
      editDashboardNameError = true
      editDashboardNameMessage = this.formatMessage(messages.mustHaveAValue)
    } else if (this.state.editDashboardName.length > NAME_MAX_LENGTH) {
      error = true
      editDashboardNameError = true
      editDashboardNameMessage = this.formatMessage(messages.maximum50Characters)
    } else {
      error = false
      editDashboardNameError = false
      editDashboardNameMessage = this.formatMessage(messages.maxCharacters)
    }

    if (this.state.editDashboardHeight === '') {
      error = true
      editDashboardHeightError = true
    } else {
      newHeight = this.state.editDashboardHeight
    }

    if (this.state.editDashboardWidth === '') {
      error = true
      editDashboardWidthError = true
    } else {
      newWidth = this.state.editDashboardWidth
    }

    if (error) {
      this.setState({
        editDashboardNameError,
        editDashboardNameMessage,
        editDashboardHeightError,
        editDashboardWidthError
      })
    } else {
      this.setState({
        editDashboardNameError: false,
        editDashboardHeightError: false,
        editDashboardWidthError: false
      })

      this.saveDashboard(
        this.state.editDashboardName,
        this.state.editDashboardDescription,
        parseInt(newHeight, 10),
        parseInt(newWidth, 10),
        this.state.zoom,
        this.state.position
      )

      this.props.closeEditDashboardModal()
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleRadioChange = event => {
    this.setState({ zoom: event.target.value })
  }

  render() {
    return (
      <Dialog
        open={this.props.editDashboardModal}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.props.closeEditDashboardModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.dashboardSettings)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.props.closeEditDashboardModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                margin="normal"
                id="editDashboardName"
                label={this.formatMessage(messages.name)}
                value={this.state.editDashboardName}
                onChange={this.handleChange('editDashboardName')}
                type="text"
                helperText={this.state.editDashboardNameMessage}
                required={true}
                error={this.state.editDashboardNameError}
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                margin="normal"
                id="editDashboardDescription"
                label={this.formatMessage(messages.description)}
                value={this.state.editDashboardDescription}
                onChange={this.handleChange('editDashboardDescription')}
                type="text"
                required={false}
                error={this.state.editDashboardNameError}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={6}>
              <TextField
                margin="normal"
                id="editDashboardWidth"
                label={this.formatMessage(messages.width)}
                value={this.state.editDashboardWidth}
                onChange={this.handleChange('editDashboardWidth')}
                helperText={this.state.editDashboardWidthError ? this.formatMessage(messages.mustHaveAValue) : null}
                type="text"
                required={true}
                error={this.state.editDashboardWidthError}
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <TextField
                margin="normal"
                id="editDashboardHeight"
                label={this.formatMessage(messages.height)}
                value={this.state.editDashboardHeight}
                onChange={this.handleChange('editDashboardHeight')}
                type="text"
                helperText={this.state.editDashboardHeightError ? this.formatMessage(messages.mustHaveAValue) : null}
                required={true}
                error={this.state.editDashboardHeightError}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={6}>
              <DialogContentText id="alert-dialog-slide-description">
                {this.formatMessage(messages.displayMode)}
              </DialogContentText>
              <RadioGroup
                row={true}
                aria-label="zoom"
                name="zoom"
                value={this.state.zoom}
                onChange={this.handleRadioChange}
              >
                <FormControlLabel
                  value="actual"
                  control={<Radio color="primary" />}
                  label={this.formatMessage(messages.actualSize)}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="fit"
                  control={<Radio color="primary" />}
                  label={this.formatMessage(messages.fitToWidth)}
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <DialogContentText id="alert-dialog-slide-description">Tab position:</DialogContentText>
              <FormControl style={{ width: '100%' }} required={true}>
                <Select
                  value={this.state.position}
                  onChange={this.handleChange('position')}
                  input={<Input name="position" />}
                  name="position"
                >
                  {this.props.dashboardIds.map((dashboardId, index) => {
                    return (
                      <MenuItem key={dashboardId} value={index}>
                        {index + 1}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeEditDashboardModal}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveEditDashboard}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditDashboard.propTypes = {
  closeEditDashboardModal: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
  dashboardIds: PropTypes.array.isRequired,
  editDashboardModal: PropTypes.bool.isRequired
}

export default injectIntl(EditDashboard)
