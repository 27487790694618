import { connect } from 'react-redux'

import Home from './Home'

import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'

const mapStateToProps = state => ({
  canPreviewCharts: groupUtils.hasPermission(state, c.CHARTS_READ)
})
export default connect(mapStateToProps)(Home)

export { getChartsUrl } from './url'
