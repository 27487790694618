import { defineMessages } from 'react-intl'

const namespace = 'TextSettings'

const messages = defineMessages({
  textWidget: {
    id: `${namespace}.textWidget`,
    description: 'Text widget',
    defaultMessage: 'TEXT WIDGET'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  youMustSelectAMachine: {
    id: `${namespace}.youMustSelectAMachine`,
    description: 'You must select a machine',
    defaultMessage: 'You must select a machine'
  },
  text: {
    id: `${namespace}.text`,
    description: 'Text widget',
    defaultMessage: 'Text'
  },
  size: {
    id: `${namespace}.size`,
    description: 'Font size',
    defaultMessage: 'Font size'
  },
  weight: {
    id: `${namespace}.weight`,
    description: 'Font weight',
    defaultMessage: 'Font weight'
  },
  align: {
    id: `${namespace}.align`,
    description: 'Text align',
    defaultMessage: 'Text align'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Text widget settings cancellig button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Text widget settigs saving button text',
    defaultMessage: 'Save'
  },
  textWidgetSettings: {
    id: `${namespace}.textWidgetSettings`,
    description: 'Text widget settigs dialog context text',
    defaultMessage: 'Text widget settigs:'
  },
  selectSizeInPixels: {
    id: `${namespace}.selectSizeInPixels`,
    description: 'Text widget settigs: Select font size in pixels',
    defaultMessage: 'Select font size in pixels'
  },
  selectWeight: {
    id: `${namespace}.selectWeight`,
    description: 'Text widget settigs: Select font weight',
    defaultMessage: 'Select font weight'
  },
  selectAlign: {
    id: `${namespace}.selectAlign`,
    description: 'Text widget settigs: Select text align',
    defaultMessage: 'Select text align'
  },
  mustHaveAValue: {
    id: `${namespace}.mustHaveAValue`,
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  bold: {
    id: `${namespace}.bold`,
    description: 'Font weight: bold',
    defaultMessage: 'Bold'
  },
  normal: {
    id: `${namespace}.normal`,
    description: 'Font weight: normal',
    defaultMessage: 'Normal'
  },
  left: {
    id: `${namespace}.left`,
    description: 'Text align: left',
    defaultMessage: 'Left'
  },
  center: {
    id: `${namespace}.center`,
    description: 'Text align: center',
    defaultMessage: 'Center'
  },
  right: {
    id: `${namespace}.right`,
    description: 'Text align: right',
    defaultMessage: 'Right'
  },
  selectBooleanSignal: {
    id: `${namespace}.selectBooleanSignal`,
    description: '',
    defaultMessage: 'Select boolean signal'
  },
  selectValueToDisplay: {
    id: `${namespace}.selectValueToDisplay`,
    description: '',
    defaultMessage: 'Select value to display'
  },
  booleanSignalDependable: {
    id: `${namespace}.booleanSignalDependable`,
    description: '',
    defaultMessage: 'Make this widget boolean signal dependable'
  },
  youMustChooseOneSignal: {
    id: `${namespace}.youMustChooseOneSignal`,
    description: '',
    defaultMessage: 'You must choose a valid signal or disable the option otherwise'
  },
  operator: {
    id: `${namespace}.operator`,
    description: 'Operator',
    defaultMessage: 'Operator'
  },
  conditionalValue: {
    id: `${namespace}.conditionalValue`,
    description: 'Value',
    defaultMessage: 'Value'
  },
  textWidgetSettingsWhenTrue: {
    id: `${namespace}.textWidgetSettingsWhenTrue`,
    description: 'Text widget settings when conditional signal is TRUE',
    defaultMessage: 'Text widget settings when conditional signal is TRUE'
  },
  textWidgetSettingsWhenFalse: {
    id: `${namespace}.textWidgetSettingsWhenFalse`,
    description: 'Text widget settings when conditional signal is FALSE',
    defaultMessage: 'Text widget settings when conditional signal is FALSE'
  },
  required: {
    id: `${namespace}.required`,
    description: 'Required',
    defaultMessage: 'Required'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: `${namespace}.value`,
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: `${namespace}.valueAvg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: `${namespace}.valueMin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: `${namespace}.valueMax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  configurationStatus: {
    id: `${namespace}.configurationStatus`,
    description: 'Configuration status',
    defaultMessage: 'Configuration status'
  },
  configurationAvailable: {
    id: `${namespace}.configurationAvailable`,
    description: 'Available',
    defaultMessage: 'Available'
  },
  configurationNotAvailable: {
    id: `${namespace}.configurationNotAvailable`,
    description: 'Not available',
    defaultMessage: 'Not available'
  },
  configurationLoading: {
    id: `${namespace}.configurationLoading`,
    description: 'Loading',
    defaultMessage: 'Loading'
  }
})

export default messages
