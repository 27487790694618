import React from 'react'

const grow = {
  display: 'flex',
  flexWrap: 'wrap',
  flexGrow: 1,
  alignItems: 'center'
}

const ToolbarGroup = ({ children }) => <div style={grow}>{children}</div>

export default ToolbarGroup
