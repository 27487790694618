import React from 'react'
import PropTypes from 'prop-types'

import { client, logError } from 'utils/http'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import RolesTableTitle from './RolesTableTitle'
import RolesGrid from './RolesGrid'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { mapToHierarchicalRoles } from '../utils'

class Roles extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      rolesHierarchy: [],
      loading: true,
      alertMessages: false,
      alertMessagesType: 'danger',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    const { groupId } = this.props
    if (groupId) this.getRoles()
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props
    if (prevProps.groupId !== groupId && groupId) this.getRoles()
  }

  getRoles = () => {
    const { groupId, getRoles: getRoleRequest, intl } = this.props

    let getRoles = client.getRolesHierarchy
    const params = []
    if (groupId) {
      getRoles = getRoleRequest
      params.push(groupId)
    }

    getRoles(...params)
      .then(response => {
        let rolesHierarchy = response.data
        if (groupId) rolesHierarchy = mapToHierarchicalRoles(response.data.groupRoles)
        this.setState({
          rolesHierarchy,
          loading: false,
          alertMessages: false,
          alertMessagesType: 'danger',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = response.error
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401: // Invalid credentials
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403: // Access denied
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500: // Server errors
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    const { loading, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { urlAction, onDeleteRole, userRole } = this.props
    const { loading, alertMessages, rolesHierarchy } = this.state

    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <RolesGrid
          getRoles={this.getRoles}
          onDeleteRole={onDeleteRole}
          rolesHierarchy={rolesHierarchy}
          urlAction={urlAction}
          userRole={userRole}
        />
      )
    }
  }

  render() {
    const { canCreateRoles, selectedGroup, newRoleUrl } = this.props

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <RolesTableTitle canCreateRoles={canCreateRoles} newRoleUrl={newRoleUrl} selectedGroup={selectedGroup} />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

Roles.propTypes = {
  canCreateRoles: PropTypes.bool.isRequired,
  getRoles: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  newRoleUrl: PropTypes.string.isRequired,
  onDeleteRole: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  urlAction: PropTypes.string,
  userRole: PropTypes.string.isRequired
}

Roles.defaultProps = {
  groupId: '',
  selectedGroup: {},
  urlAction: ''
}

export default injectIntl(Roles)
