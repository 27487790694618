import * as t from 'ducks/geotracking/actionTypes'
import { actions } from 'utils/http'
import * as gpstracking from 'ducks/geotracking/actions'

const createMiddleware = () => {
  const middleware = store => {
    const dispatch = store.dispatch
    return next => action => {
      switch (action.type) {
        case actions.types.success(t.GET_GPS_TRACKINGS):
          {
            const page = action.payload.data.page
            const pages = action.payload.data.pages
            const queryData = action.meta.previousAction.payload.request.data
            if (page < pages - 1) {
              dispatch(gpstracking.getGPSTrackingTimeSeries(queryData.nodeId, queryData.min, queryData.max, page + 1))
            } else {
              dispatch(gpstracking.removeGpsTrackingsLoading(queryData.nodeId))
            }
          }
          break
        case actions.types.fail(t.GET_GPS_TRACKINGS):
          {
            const nodeId = action.meta.previousAction.payload.request.data.nodeId
            dispatch(gpstracking.removeGpsTrackingsLoading(nodeId))
          }
          break

        case actions.types.success(t.GET_AGGREGATED_GPS_TRACKINGS):
          {
            const page = action.payload.data.page
            const pages = action.payload.data.pages
            const { nodeId, deviceType, groupId, start, end, size, sortOrder, aggLevel } =
              action.meta.previousAction.payload.request.data
            if (page < pages - 1) {
              dispatch(
                gpstracking.getAggregatedGPSTrackingTimeSeries(
                  nodeId,
                  deviceType,
                  groupId,
                  start,
                  end,
                  size,
                  page + 1,
                  sortOrder,
                  aggLevel
                )
              )
            } else {
              dispatch(gpstracking.removeGpsTrackingsLoading(nodeId, aggLevel))
            }
          }
          break
        case actions.types.fail(t.GET_AGGREGATED_GPS_TRACKINGS):
          {
            const { nodeId, aggLevel } = action.meta.previousAction.payload.request.data
            dispatch(gpstracking.removeGpsTrackingsLoading(nodeId, aggLevel))
          }
          break
        default:
      }

      return next(action)
    }
  }
  return middleware
}

export default createMiddleware
