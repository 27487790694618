import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'NewGroup.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'NewGroup.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'NewGroup.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'NewGroup.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'NewGroup.errorUndefinedTitle',
    description: 'Error undefined title', 
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'NewGroup.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: 'NewGroup.title',
    description: 'New group title',
    defaultMessage: 'New group'
  },
  return: {
    id: 'NewGroup.return',
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  cancel: {
    id: 'NewGroup.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  thisFieldIsRequired: {
    id: 'NewGroup.thisFieldIsRequired',
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  selectAParentGroup: {
    id: 'NewGroup.selectAParentGroup',
    description: 'Select a parent group message',
    defaultMessage: 'Select a parent group.'
  },
  error409Message: {
    id: 'NewGroup.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: '+
        'Group name it\'s already in use'
  },
  error415Message: {
    id: 'NewGroup.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'NewGroup.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  name: {
    id: 'NewGroup.name',
    description: 'Name floating label text',
    defaultMessage: 'Name'
  },
  selectParentGroup: {
    id: 'NewGroup.selectParentGroup',
    description: 'Select parent group label text',
    defaultMessage: 'Select parent group'
  },
  save: {
    id: 'NewGroup.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  error: {
    id: 'NewGroup.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'NewGroup.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  }
})

export default messages