import React from 'react'

import moment from 'moment'

import blue from '@material-ui/core/colors/blue'

import { Map } from './wrapper'

import Marker from '../../mapView/CustomizedMarker'

import { LocationTrackerProps } from '../props'

import { injectIntl } from 'react-intl'

const LocationTracker = ({
  positions = [],
  highlighted = -1,
  url = undefined,
  ...other
}) => {
  if (positions.length === 0) {
    return null
  }

  return (
    <Map
      {...other}
      defaultCenter={positions[highlighted] || positions[0]}
      zoom={14}
      lines={[positions]}
    >
      {positions
        .map((position, i) => {
          const remarked = highlighted === i
          return (
            <Marker
              key={position.timestamp}
              position={position}
              label={moment(position.timestamp).format()}
              zIndex={remarked ? 1000 : 100}
              color={remarked ? blue[500] : 'black'}
              size={remarked ? 30 : 14}
              url={url}
            />
          )
        })
        .reverse() // Latest comes first
      }
    </Map>
  )
}

LocationTracker.propTypes = LocationTrackerProps

export default injectIntl(LocationTracker)
