import { connect } from 'react-redux'

import Home from './Home'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = state => ({
  canCreateDevices: resolvePermissions(state, c.DEVICE_CREATE),
})

export default connect(mapStateToProps)(Home)

export { getRegisterUrl } from './url'
