import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

const EditRoleFormTitle = ({ title = '???', rolesUrl, canReadRoles }) => {
  return <PageTitle title={title} back={canReadRoles ? rolesUrl : null} />
}

EditRoleFormTitle.propTypes = {
  rolesUrl: PropTypes.string,
  title: PropTypes.string
}

export default EditRoleFormTitle
