import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@material-ui/core/Icon'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

class RoleSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rolesHierarchy: props.rolesHierarchy,
      selectLabel: props.selectLabel,
      parentRole: props.parentRole,
      parentDescriptionValue: props.parentDescriptionValue,
      parentDescriptionValue_errorText: props.parentDescriptionValue_errorText,
      parentHashIdValue: props.parentHashIdValue,
      handleSelectChange: props.handleSelectChange
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      rolesHierarchy: nextProps.rolesHierarchy,
      selectLabel: nextProps.selectLabel,
      parentRole: nextProps.parentRole,
      parentDescriptionValue: nextProps.parentDescriptionValue,
      parentDescriptionValue_errorText: nextProps.parentDescriptionValue_errorText,
      parentHashIdValue: nextProps.parentHashIdValue,
      handleSelectChange: nextProps.handleSelectChange
    })
  }

  renderRootElement = () => {
    if (this.state.parentRole) {
      return <MenuItem key={-1} value={0} primaryText="Root element" />
    }
  }

  render() {
    return (
      <SelectField
        floatingLabelText={this.state.selectLabel}
        fullWidth={true}
        value={this.state.parentHashIdValue}
        errorText={this.state.parentDescriptionValue_errorText}
        onChange={this.state.handleSelectChange}
      >
        <MenuItem key={-2} value={null} primaryText="" />
        {this.renderRootElement()}

        {this.state.rolesHierarchy.map((role, index) => {
          let selectText

          switch (role.depth) {
            case 2:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {role.title}
                </span>
              )
              break
            case 3:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {role.title}
                </span>
              )
              break
            case 4:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {role.title}
                </span>
              )
              break
            case 5:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {role.title}
                </span>
              )
              break
            case 6:
              selectText = (
                <span>
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp;
                  <Icon className="zmdi zmdi-chevron-right" style={{ top: 1, fontSize: 18, color: '#ccc' }} />
                  &nbsp; {role.title}
                </span>
              )
              break
            default:
              selectText = role.title
          }

          return <MenuItem key={index} value={role.hashId} primaryText={selectText} />
        })}
      </SelectField>
    )
  }
}

RoleSelect.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  parentDescriptionValue: PropTypes.string,
  parentDescriptionValue_errorText: PropTypes.string,
  parentHashIdValue: PropTypes.node,
  parentRole: PropTypes.bool,
  rolesHierarchy: PropTypes.array.isRequired,
  selectLabel: PropTypes.string
}

RoleSelect.defaultProps = {
  parentDescriptionValue: '',
  parentDescriptionValue_errorText: '',
  parentHashIdValue: null,
  parentRole: true,
  selectLabel: 'Select parent role'
}

export default RoleSelect
