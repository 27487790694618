import * as t from '../../actionTypes'

const visibleSeries = (state = [], action) => {
  if (!action) {
    return state
  }
  switch (action.type) {
    case t.SHOW_SERIE:
      const serieToShow = action.payload
      const showPosition = state.indexOf(serieToShow)
      if (showPosition === -1) {
        return [...state, serieToShow]
      }
      return state

    case t.HIDE_SERIE:
      const serieToHide = action.payload
      const hidePosition = state.indexOf(serieToHide)
      if (hidePosition > -1) {
        const newVisibleSeries = state.slice()
        newVisibleSeries.splice(hidePosition, 1)
        return newVisibleSeries
      }
      return state

    case t.SET_SERIES:
      return action.payload

    default:
      return state
  }
}

export default visibleSeries
export const MOUNTED_AT = 'visibleSeries'
