const VideoTemplate = {
  id: '',
  width: 560,
  height: 315,
  x: 20,
  y: 20,
  minHeight: 20,
  minWidth: 20,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'video',
    params: {
      url: ''
    }
  }
}

export default VideoTemplate
