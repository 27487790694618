import { defineMessages } from 'react-intl'

const namespace = 'UnsupportedDashboard'

const messages = defineMessages({
  noDashboardAvailable: {
    id: `${namespace}.noDashboardAvailable`,
    description: 'No dashboard available',
    defaultMessage: 'No dashboard available'
  },
  unsupportedMachine: {
    id: `${namespace}.unsupportedMachine`,
    description: 'Unsupported machine.',
    defaultMessage: 'Unsupported machine.'
  }
})

export default messages
