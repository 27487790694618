import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import TreeListGroups from './TreeListGroups'

const mapStateToProps = state => ({
  canEditGroup: resolvePermissions(state, c.GROUPS_EDIT),
  canDeleteGroup: resolvePermissions(state, c.GROUPS_DELETE),
  canReadDevices: resolvePermissions(state, c.DEVICE_READ)
})

export default connect(mapStateToProps, null)(TreeListGroups)
