import { defineMessages } from 'react-intl'

const namespace = 'SmsServiceConsentDialog'

export default defineMessages({
  accept: {
    id: `${namespace}.accept`,
    defaultMessage: 'Accept'
  },
  additionalInformationTitle: {
    id: `${namespace}.additionalInformationTitle`,
    defaultMessage: 'Additional information needed for PLUS+1\u00AE Connect'
  },
  cancel: {
    id: `${namespace}.cancel`,
    defaultMessage: 'Cancel'
  },
  decline: {
    id: `${namespace}.decline`,
    defaultMessage: 'Decline'
  },
  enableSmsService: {
    id: `${namespace}.enableSmsService`,
    defaultMessage: 'Enable SMS service for PLUS+1\u00AE Connect to receive notifications to your phone.'
  },
  goToMyProfile: {
    id: `${namespace}.goToMyProfile`,
    defaultMessage: 'Go to my profile'
  },
  legalText: {
    id: `${namespace}.legalText`,
    defaultMessage:
      // eslint-disable-next-line
      'By enabling the SMS service with Danfoss, you agree and acknowledge that Danfoss may send text messages to your phone number for any purpose, including marketing purposes, but not limited to, auto-dialers, pre-recordings, and general telemarketing practices. You agree that these text messages may be regarding the products and/or services that you have previously purchased and products and/or services that Danfoss may market to you. You acknowledge that this consent may be removed at your request but that until such consent is revoked, you may receive text messages from Danfoss at your number.'
  },
  messageFrequencyVaries: {
    id: `${namespace}.messageFrequencyVaries`,
    defaultMessage:
      'Message frequency varies. Message and data rates may apply. You can change your decision at any time.'
  },
  serverError: {
    id: `${namespace}.serverError`,
    defaultMessage: 'Server error'
  },
  serverErrorClarification: {
    id: `${namespace}.serverErrorClarification`,
    defaultMessage:
      'Please try again or refresh the page. \
      If the error persists, contact <a>Plus+1 Connect Support</a>.'
  },
  smsService: {
    id: `${namespace}.smsService`,
    defaultMessage: 'SMS service for PLUS+1\u00AE Connect'
  },
  toBeAbleToReceive: {
    id: `${namespace}.toBeAbleToReceive`,
    defaultMessage: 'To be able to receive SMS notifications, go to <a>My Profile</a> and enter your phone number.'
  }
})
