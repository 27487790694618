import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import DropdownMenu from 'components/DropdownMenu'

const FlagDropdown = ({ languages, selectedCode, style, onSelected }) => {
  const selected = languages.find(l => l.code === selectedCode)
  return (
    <DropdownMenu icon={<Icon className={'flag ' + selected.flag} />} style={style}>
      {languages.map((value, index) => (
        <MenuItem key={index} selected={value === selected} onClick={() => onSelected(value)}>
          <ListItemIcon>
            <Icon className={'flag ' + value.flag} />
          </ListItemIcon>
          <ListItemText inset={true} primary={value.lang} />
        </MenuItem>
      ))}
    </DropdownMenu>
  )
}

FlagDropdown.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      flag: PropTypes.string,
      lang: PropTypes.string
    })
  ),
  onSelected: PropTypes.func,
  selectedCode: PropTypes.string,
  style: PropTypes.object
}

export default FlagDropdown
