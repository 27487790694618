import PropTypes from 'prop-types'
import React from 'react'

import { client, logError } from 'utils/http'

import CodeDropdown from './CodeDropdown'
import FlagDropdown from './FlagDropdown'

class LanguageDropdown extends React.Component {
  constructor(props) {
    super(props)
    // abbr follows ISO 639-1
    const { selectedLanguage } = this.props
    this.state = {
      selected: selectedLanguage,
      languages: JSON.parse(localStorage.getItem('available_languages'))
    }
  }

  getUserPreferredLanguage = code => {
    let userPreferredLanguage
    switch (code) {
      case 'es':
        userPreferredLanguage = 'es_ES'
        break
      case 'de':
        userPreferredLanguage = 'de_DE'
        break
      case 'it':
        userPreferredLanguage = 'it_IT'
        break
      case 'pt':
        userPreferredLanguage = 'pt_BR'
        break
      default:
        userPreferredLanguage = 'en_GB'
        break
    }
    return userPreferredLanguage
  }

  saveLanguage = code => {
    const userPreferredLanguage = this.getUserPreferredLanguage(code)
    const { userHasGroup, user, setLanguage } = this.props
    //only save user language preference in database if user has groups
    if (userHasGroup) {
      const newUser = {
        hashId: user.hashId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        enabled: user.enabled,
        roles: user.roles,
        groups: user.groupsData,
        version: user.version,
        notify: user.notify,
        preferredLanguage: userPreferredLanguage
      }
      this.saveUser(newUser)
    }
    localStorage.setItem('user_language', code)
    setLanguage(code)
  }

  saveUser = newUser => {
    const { updateProfile } = this.props
    client
      .editUser(newUser)
      .then(response => {
        return response.data
      })
      .then(data => {
        const userProfile = {
          hashId: data.hashId,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          enabled: data.enabled,
          roles: data.roles,
          groups: data.groups,
          version: data.version,
          notify: data.notify,
          preferredLanguage: data.preferredLanguage
        }
        updateProfile(userProfile)
      })
      .catch(response => {
        logError(response)
      })
  }

  render() {
    const { useFlags, ...other } = this.props
    const { languages, selected } = this.state
    const dropdownProps = {
      ...other,
      languages: Object.keys(languages).map(code => ({ code, ...languages[code] })),
      selectedCode: selected,
      onSelected: l => {
        this.setState({ selected: l.code })
        if (l.code !== localStorage.getItem('user_language')) {
          this.saveLanguage(l.code)
        }
      }
    }
    if (useFlags) {
      return <FlagDropdown {...dropdownProps} />
    }
    return <CodeDropdown {...dropdownProps} />
  }
}

LanguageDropdown.propTypes = {
  selectedLanguage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setLanguage: PropTypes.func,
  updateProfile: PropTypes.func,
  useFlags: PropTypes.bool,
  user: PropTypes.object,
  userHasGroup: PropTypes.bool
}

LanguageDropdown.defaultProps = {
  selectedLanguage: {},
  setLanguage: () => {},
  updateProfile: () => {},
  useFlags: false,
  user: {},
  userHasGroup: false
}

export default LanguageDropdown
