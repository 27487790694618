import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions as geotrackingActions, selectors as geotrackingSelectors } from 'ducks/geotracking'
import { actions as websocketActions } from 'ducks/websocket'
import { actions as hactions } from 'utils/http'

import SelectedMachinesManager from './SelectedMachinesManager'

const mapStateToProps = state => ({
  gpsTrackings: geotrackingSelectors.getGPSTrackings(state)
})

const mapDispatchToProps = dispatchFunc =>
  bindActionCreators(
    {
      connectRealtimeGPSWebS: (nodeId, topic, wscredentials) => dispatch => {
        dispatch(websocketActions.subscribeGPS(topic, nodeId, wscredentials))
      },
      disconnectRealtimeGPSWebS: (nodeId, topic) => dispatch => {
        dispatch(websocketActions.unsubscribe(topic, nodeId))
      },
      getAzureToken: (id, nodeMac) => dispatch => {
        const { promise } = dispatch(hactions.plus1Connect.getAzureNodeToken(id, nodeMac))
        return promise
      },
      getNodeLastGPSLocation: nodeMac => dispatch => {
        const { promise } = dispatch(geotrackingActions.getNodeLastGPSLocation(nodeMac))
        return promise
      }
    },
    dispatchFunc
  )

export default connect(mapStateToProps, mapDispatchToProps)(SelectedMachinesManager)
