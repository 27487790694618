import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

import { logError } from 'utils/http'
import { utcTimeToBrowserLocalShort } from 'utils/timeFormat'

import Actions from './actions'
import messages from './messages'


class DataPlansTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      devices: [],
      start: 0,
      pageChanging: false,
      tableOptions: {
        noDataText: <CircularProgress />,
        sizePerPage: 5,
        page: 1
      }
    }
  }

  componentDidMount() {
    const { groupId } = this.props
    if (groupId) this.getDevices()
  }

  componentDidUpdate = prevProps => {
    const {
      dataplans,
      dataPlansLoaded,
      dataPlansError,
      groupId,
      history,
      location: { state, pathname }
    } = this.props

    if (dataplans.length === 0) {
      let noDataElement = ''
      if (dataPlansLoaded && !prevProps.dataPlansLoaded) {
        if (dataPlansError) noDataElement = this.formatMessage(messages.errorRequestingDataPlans)
        else noDataElement = this.formatMessage(messages.noDataPlansText)
        this.setState(currentState => ({
          tableOptions: {
            ...currentState.tableOptions,
            noDataText: noDataElement
          }
        }))
      }
    }
    if (prevProps.groupId !== groupId && groupId) this.getDevices()
    if ((state === 'updatedDevice' || state?.deviceActivated) && prevProps.location.state === undefined) {
      this.getDevices()
      if (state?.deviceActivated) history.replace(pathname, undefined)
    }
  }

  getDevices = () => {
    const { getDynamicCSNodes, groupId } = this.props

    const deviceFields = {
      Device: ['id', 'device_type', 'name', 'eid', 'dataplan']
    }
    getDynamicCSNodes(groupId, deviceFields)
      .then(response => {
        const { devices = [] } = response.data
        const devicesWithoutDataPlan = devices.filter(device => device.dataplan === null)
        this.setState({
          devices: devicesWithoutDataPlan
        })
      })
      .catch(response => {
        const { error } = { ...response }
        this.setState({
          devices: []
        })
        logError(error)
      })
  }

  getTableOptions = () => {
    const { tableOptions } = this.state

    return {
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        }
      ],

      onPageChange: this.onPageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      ...tableOptions
    }
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      state => ({
        start: (page - 1) * sizePerPage,
        tableOptions: {
          ...state.tableOptions,
          page,
          sizePerPage
        }
      }),
      this.simulateDeviceUpdate
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      state => ({
        tableOptions: { ...state.tableOptions, sizePerPage }
      }),
      this.simulateDeviceUpdate
    )
  }

  simulateDeviceUpdate = () => {
    this.setState(
      state => ({
        pageChanging: true,
        tableOptions: {
          ...state.tableOptions,
          noDataText: <CircularProgress />
        }
      }),
      () => {
        setTimeout(() => {
          this.setState({
            pageChanging: false
          })
        }, 500)
      }
    )
  }

  showActions = () => {
    const { canApplyDataPlans } = this.props

    if (canApplyDataPlans) {
      return (
        <TableHeaderColumn
          dataAlign='left'
          dataField='id'
          dataFormat={this.renderActionsDetail}
          headerAlign='left'
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          width='100'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    } else {
      return <TableHeaderColumn visible='false' width='0' />
    }
  }

  renderActionsDetail = (cell, row) => {
    const { updateDevices, groupId } = this.props
    const { devices } = this.state

    return <Actions devices={devices} digitalServicePlan={row} groupId={groupId} updateDevices={updateDevices} />
  }

  remote(remoteObj) {
    return {
      ...remoteObj,
      pagination: true,
      sizePerPage: true
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  renderPlanID = cell => cell || '-'

  renderDate = cell => (cell ? utcTimeToBrowserLocalShort(cell) : '-')

  renderSubscriptionDays = (subscriptionDays, dataplan) => {
    let subscriptionDaysText = ''
    if (subscriptionDays === 365) subscriptionDaysText = this.formatMessage(messages.oneYearServicePlan)
    else subscriptionDaysText = this.formatMessage(messages.xYearsServicePlan, {number: subscriptionDays / 365})
    if (dataplan?.subscriptionProduct) subscriptionDaysText += ' - ' + this.formatMessage(messages.autorenewal)
    return subscriptionDaysText
  }

  renderTimeToExpire = (cell, row) => {
    let timeToExpire = '-'
    const { startDate, dataplanStatusId } = row
    if (dataplanStatusId === 2) {
      const endMoment = moment(startDate).add(cell, 'days')
      let color = 'gray'
      let unit = 'years'
      let timeToExpireValue = endMoment.diff(moment(), unit)
      if (timeToExpireValue >= 1) {
        if (timeToExpireValue === 1) unit = 'year'
      } else {
        unit = 'months'
        timeToExpireValue = endMoment.diff(moment(), unit)
        if (timeToExpireValue >= 1) {
          if (timeToExpireValue < 3) color = '#f0ad4e'
          if (timeToExpireValue === 1) unit = 'month'
        } else {
          unit = 'weeks'
          color = '#f0ad4e'
          timeToExpireValue = endMoment.diff(moment(), unit)
          if (timeToExpireValue >= 1) {
            if (timeToExpireValue < 3) color = '#d9534f'
            if (timeToExpireValue) unit = 'week'
          } else {
            unit = 'days'
            color = '#d9534f'
            timeToExpireValue = endMoment.diff(moment(), unit)
            if (timeToExpireValue < 2) unit = 'day'
            if (timeToExpireValue < 1) timeToExpireValue = '< 1'
          }
        }
      }
      timeToExpire = <span style={{ color }}>{timeToExpireValue + ' ' + unit}</span>
    }
    return timeToExpire
  }

  renderEndDate = (cell, row) => {
    //Here we will manage renewal Date and expiration date
    //depending on if digital service plan is autorenewable or not
    let endDate = '-'
    const { dataplanStatusId, subscriptionDays } = row
    if (dataplanStatusId === 2 && Number.isInteger(subscriptionDays)) {
      const endTimestamp = moment(cell).add(subscriptionDays, 'days').valueOf()
      endDate = utcTimeToBrowserLocalShort(endTimestamp)
    }
    return endDate
  }

  renderDeviceType = cell => cell || '-'

  expirationDateSortFunc = (a, b, order) => {
    if (order === 'desc') {
      if (a.dataplanStatusId !== 2) return 1
      else if (b.dataplanStatusId !== 2) return -1
      else {
        const endTimestampA = moment(a.startDate).add(a.subscriptionDays, 'days').valueOf()
        const endTimestampB = moment(b.cell).add(b.subscriptionDays, 'days').valueOf()
        return endTimestampA - endTimestampB
      }
    } else {
      if (a.dataplanStatusId !== 2) return -1
      else if (b.dataplanStatusId !== 2) return 1
      else {
        const endTimestampA = moment(a.startDate).add(a.subscriptionDays, 'days').valueOf()
        const endTimestampB = moment(b.cell).add(b.subscriptionDays, 'days').valueOf()
        return endTimestampA - endTimestampB
      }
    }
  }

  render() {
    const { dataplans } = this.props
    const { pageChanging, start } = this.state
    const tableOptions = this.getTableOptions()

    return (
      <div>
        <Paper style={{ borderRadius: 0 }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              data={pageChanging ? [] : dataplans.slice(start, start + tableOptions.sizePerPage)}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: dataplans.length }}
              options={tableOptions}
              pagination
              remote={this.remote}
            >
              <TableHeaderColumn
                dataAlign='left'
                dataField='id'
                headerAlign='left'
                isKey
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.planID)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='totalData'
                headerAlign='left'
                hidden
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.totalData)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='deviceType'
                dataFormat={this.renderDeviceType}
                dataSort
                headerAlign='left'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.deviceType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='subscriptionDays'
                dataFormat={this.renderSubscriptionDays}
                headerAlign='left'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.subscriptionDays)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='createdAt'
                dataFormat={this.renderDate}
                dataSort
                headerAlign='left'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.purchaseDate)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='subscription'
                headerAlign='left'
                hidden
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.subscription)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='startDate'
                dataFormat={this.renderDate}
                dataSort
                headerAlign='left'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.activationDate)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='subscriptionDays'
                dataFormat={this.renderTimeToExpire}
                headerAlign='left'
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.timeToExpire)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='startDate'
                dataFormat={() => '-'}
                headerAlign='left'
                hidden
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.renewalDate)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='startDate'
                dataFormat={this.renderEndDate}
                dataSort
                headerAlign='left'
                sortFunc={this.expirationDateSortFunc}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='150'
              >
                {this.formatMessage(messages.expirationDate)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='left'
                dataField='name'
                dataFormat={() => '-'}
                headerAlign='left'
                hidden
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                width='200'
              >
                {this.formatMessage(messages.deviceName)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>
      </div>
    )
  }
}

DataPlansTable.propTypes = {
  canApplyDataPlans: PropTypes.bool.isRequired,
  dataPlansError: PropTypes.bool.isRequired,
  dataPlansLoaded: PropTypes.bool.isRequired,
  dataplans: PropTypes.array.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateDevices: PropTypes.func.isRequired
}

export default withRouter(injectIntl(DataPlansTable))
