import { defineMessages } from 'react-intl'

const messages = defineMessages({
  boxWidget: {
    id: 'BoxSettings.boxWidget',
    description: 'Box widget',
    defaultMessage: 'BOX WIDGET'
  },
  width: {
    id: 'BoxSettings.width',
    description: 'Box widget: border width',
    defaultMessage: 'Border width'
  },
  style: {
    id: 'BoxSettings.style',
    description: 'Box widget: border style',
    defaultMessage: 'Border style'
  },
  color: {
    id: 'BoxSettings.color',
    description: 'Box widget: border color',
    defaultMessage: 'Border color'
  },
  backgroundColor: {
    id: 'BoxSettings.backgroundColor',
    description: 'Box widget: background color',
    defaultMessage: 'Background color'
  },
  backgroundAlpha: {
    id: 'BoxSettings.backgroundAlpha',
    description: 'Box widget: background color alpha',
    defaultMessage: 'Background alpha'
  },
  radius: {
    id: 'BoxSettings.radius',
    description: 'Box widget: border radius',
    defaultMessage: 'Border radius'
  },
  cancel: {
    id: 'BoxSettings.cancel',
    description: 'Box widget settings cancellig button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'BoxSettings.save',
    description: 'Box widget settigs saving button text',
    defaultMessage: 'Save'
  },
  boxWidgetSettings: {
    id: 'BoxSettings.boxWidgetSettings',
    description: 'Box widget settigs dialog context text',
    defaultMessage: 'Box widget settigs:'
  },
  thin: {
    id: 'BoxSettings.thin',
    description: 'Box widget: border width',
    defaultMessage: 'Thin'
  },
  medium: {
    id: 'BoxSettings.medium',
    description: 'Box widget: border medium',
    defaultMessage: 'Medium'
  },
  thick: {
    id: 'BoxSettings.thick',
    description: 'Box widget: border thick',
    defaultMessage: 'Thick'
  },
  selectBorderWidth: {
    id: 'BoxSettings.selectBorderWidth',
    description: 'Select border width',
    defaultMessage: 'Select border width'
  },
  none: {
    id: 'BoxSettings.none',
    description: 'Box widget: border style none',
    defaultMessage: 'None'
  },
  noneTooltip: {
    id: 'BoxSettings.noneTooltip',
    description: 'Box widget: border style none - tooltip',
    defaultMessage: 'Specifies no border.'
  },
  hidden: {
    id: 'BoxSettings.hidden',
    description: 'Box widget: border style hidden',
    defaultMessage: 'Hidden'
  },
  hiddenTooltip: {
    id: 'BoxSettings.hiddenTooltip',
    description: 'Box widget: border style hidden - tooltip',
    defaultMessage: 'The same as "none", except in border conflict resolution for table elements.'
  },
  solid: {
    id: 'BoxSettings.solid',
    description: 'Box widget: border style solid',
    defaultMessage: 'Solid'
  },
  solidTooltip: {
    id: 'BoxSettings.solidTooltip',
    description: 'Box widget: border style solid - tooltip',
    defaultMessage: 'Specifies a solid border.'
  },
  dotted: {
    id: 'BoxSettings.dotted',
    description: 'Box widget: border style dotted',
    defaultMessage: 'Dotted'
  },
  dottedTooltip: {
    id: 'BoxSettings.dottedTooltip',
    description: 'Box widget: border style dotted - tooltip',
    defaultMessage: 'Specifies a dotted border.'
  },
  dashed: {
    id: 'BoxSettings.dashed',
    description: 'Box widget: border style dashed',
    defaultMessage: 'Dashed'
  },
  dashedTooltip: {
    id: 'BoxSettings.dashedTooltip',
    description: 'Box widget: border style dashed - tooltip',
    defaultMessage: 'Specifies a dashed border.'
  },
  double: {
    id: 'BoxSettings.double',
    description: 'Box widget: border style double',
    defaultMessage: 'Double'
  },
  doubleTooltip: {
    id: 'BoxSettings.doubleTooltip',
    description: 'Box widget: border style double - tooltip',
    defaultMessage: 'Specifies a double border.'
  },
  groove: {
    id: 'BoxSettings.groove',
    description: 'Box widget: border style groove',
    defaultMessage: 'Groove'
  },
  grooveTooltip: {
    id: 'BoxSettings.grooveTooltip',
    description: 'Box widget: border style groove - tooltip',
    defaultMessage: 'Specifies a 3D grooved border. The effect depends on the border-color value.'
  },
  ridge: {
    id: 'BoxSettings.ridge',
    description: 'Box widget: border style ridge',
    defaultMessage: 'Ridge'
  },
  ridgeTooltip: {
    id: 'BoxSettings.ridgeTooltip',
    description: 'Box widget: border style ridge - tooltip',
    defaultMessage: 'Specifies a 3D ridged border. The effect depends on the border-color value.'
  },
  inset: {
    id: 'BoxSettings.inset',
    description: 'Box widget: border style inset',
    defaultMessage: 'Inset'
  },
  insetTooltip: {
    id: 'BoxSettings.insetTooltip',
    description: 'Box widget: border style inset - tooltip',
    defaultMessage: 'Specifies a 3D inset border. The effect depends on the border-color value.'
  },
  outset: {
    id: 'BoxSettings.outset',
    description: 'Box widget: border style outset',
    defaultMessage: 'Outset'
  },
  outsetTooltip: {
    id: 'BoxSettings.outsetTooltip',
    description: 'Box widget: border style outset - tooltip',
    defaultMessage: 'Specifies a 3D outset border. The effect depends on the border-color value.'
  },
  selectBorderStyle: {
    id: 'BoxSettings.selectBorderStyle',
    description: 'Select border style',
    defaultMessage: 'Select border style'
  },
  default: {
    id: 'BoxSettings.default',
    description: 'Box widget: border color default',
    defaultMessage: 'Default'
  },
  lightGrey: {
    id: 'BoxSettings.lightGrey',
    description: 'Box widget: border color light grey',
    defaultMessage: 'Light grey'
  },
  grey: {
    id: 'BoxSettings.grey',
    description: 'Box widget: border color grey',
    defaultMessage: 'Grey'
  },
  darkGrey: {
    id: 'BoxSettings.darkGrey',
    description: 'Box widget: border color dark grey',
    defaultMessage: 'Dark grey'
  },
  selectBorderColor: {
    id: 'BoxSettings.selectBorderColor',
    description: 'Select border color',
    defaultMessage: 'Select border color'
  },
  selectBackgroundColor: {
    id: 'BoxSettings.selectBackgroundColor',
    description: 'Select background color',
    defaultMessage: 'Select background color'
  },
  selectBackgroundAlpha: {
    id: 'BoxSettings.selectBackgroundAlpha',
    description: 'Select background alpha',
    defaultMessage: 'Select background transparency'
  },
  selectBorderRadius: {
    id: 'BoxSettings.selectBorderRadius',
    description: 'Select border radius',
    defaultMessage: 'Select border radius'
  },
  selectBooleanSignal: {
    id: 'BoxSettings.selectBooleanSignal',
    description: 'Select boolean signal',
    defaultMessage: 'Select boolean signal'
  },
  selectValueToDisplay: {
    id: 'BoxSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  booleanSignalDependable: {
    id: 'BoxSettings.booleanSignalDependable',
    description: '',
    defaultMessage: 'Make this widget boolean signal dependable'
  },
  youMustChooseOneSignal: {
    id: 'BoxSettings.youMustChooseOneSignal',
    description: '',
    defaultMessage: 'You must choose a valid signal or disable the option otherwise'
  },
  required: {
    id: 'BoxSettings.required',
    description: 'Required',
    defaultMessage: 'Required'
  },
  thisFieldIsRequired: {
    id: 'BoxSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  mustHaveAValue: {
    id: 'BoxSettings.mustHaveAValue',
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  operator: {
    id: 'BoxSettings.operator',
    description: 'Operator',
    defaultMessage: 'Operator'
  },
  conditionalValue: {
    id: 'BoxSettings.conditionalValue',
    description: 'Value',
    defaultMessage: 'Value'
  },
  boxWidgetSettingsWhenTrue: {
    id: 'BoxSettings.boxWidgetSettingsWhenTrue',
    description: 'Box widget settings when conditional signal is TRUE',
    defaultMessage: 'Box widget settings when conditional signal is TRUE'
  },
  boxWidgetSettingsWhenFalse: {
    id: 'BoxSettings.boxWidgetSettingsWhenFalse',
    description: 'Box widget settings when conditional signal is FALSE',
    defaultMessage: 'Box widget settings when conditional signal is FALSE'
  },
  valueType: {
    id: 'BoxSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: 'BoxSettings.value',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: 'BoxSettings.valueAvg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: 'BoxSettings.valueMin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: 'BoxSettings.valueMax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  }
})

export default messages
