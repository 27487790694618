import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import { BasicSignalsHistoric, AdvancedSignalsHistoric } from './HistoricTypes'
import { ADVANCED, BASIC } from './constants'
import messages from './messages'

const Historic = ({ data, editing, eid, intl, height, width }) => {
  const { formatMessage } = intl
  if (data.settings) {
    switch (data.data) {
      case BASIC:
        return (
          <BasicSignalsHistoric editing={editing} eid={eid} height={height} settings={data.settings} width={width} />
        )
      case ADVANCED:
        return (
          <AdvancedSignalsHistoric editing={editing} eid={eid} height={height} settings={data.settings} width={width} />
        )
      default:
        return (
          <div className='notConfigured'>
            <Typography gutterBottom={false}>{formatMessage(messages.widgetNotConfigured)}</Typography>
          </div>
        )
    }
  } else {
    return <BasicSignalsHistoric editing={editing} eid={eid} settings={data} />
  }
}

Historic.propTypes = {
  data: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  eid: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
}

export default injectIntl(Historic)
