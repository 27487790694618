import React from 'react'
import PropTypes from 'prop-types'

import NewConfigurationForm from './NewConfigurationForm'
import NewConfigurationFormTitle from './NewConfigurationFormTitle'

class NewConfiguration extends React.Component {
  render() {
    const {
      addLocalConfigurationMFIO,
      addLocalConfigurationSignal,
      canReadConfigurations,
      changeLocalConfigurationField,
      configState,
      configurationsUrl,
      copyLocalConfigurationSignal,
      groupId,
      localConfigurationError,
      manageLocalConfigurationAlert,
      onCreateConfiguration,
      resetLocalConfiguration,
      saveConfiguration,
      selectedCanProtocolData,
      selectedCanProtocolType
    } = this.props

    return (
      <div className="content-container" id="content">
        <div style={{ margin: '20px' }}>
          <div className="container-fluid">
            <NewConfigurationFormTitle
              canReadConfigurations={canReadConfigurations}
              configurationsUrl={configurationsUrl}
            />
          </div>
          <NewConfigurationForm
            addLocalConfigurationMFIO={addLocalConfigurationMFIO}
            addLocalConfigurationSignal={addLocalConfigurationSignal}
            changeLocalConfigurationField={changeLocalConfigurationField}
            configState={configState}
            configurationsUrl={configurationsUrl}
            copyLocalConfigurationSignal={copyLocalConfigurationSignal}
            groupId={groupId}
            localConfigurationError={localConfigurationError}
            manageLocalConfigurationAlert={manageLocalConfigurationAlert}
            onCreateConfiguration={onCreateConfiguration}
            resetLocalConfiguration={resetLocalConfiguration}
            saveConfiguration={saveConfiguration}
            selectedCanProtocolData={selectedCanProtocolData}
            selectedCanProtocolType={selectedCanProtocolType}
          />
        </div>
      </div>
    )
  }
}

NewConfiguration.propTypes = {
  addLocalConfigurationMFIO: PropTypes.func.isRequired,
  addLocalConfigurationSignal: PropTypes.func.isRequired,
  canReadConfigurations: PropTypes.bool.isRequired,
  changeLocalConfigurationField: PropTypes.func.isRequired,
  configState: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  copyLocalConfigurationSignal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  localConfigurationError: PropTypes.func.isRequired,
  manageLocalConfigurationAlert: PropTypes.func.isRequired,
  onCreateConfiguration: PropTypes.func.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  selectedCanProtocolData: PropTypes.func.isRequired,
  selectedCanProtocolType: PropTypes.func.isRequired
}
export default NewConfiguration
