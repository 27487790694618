import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as login from 'ducks/login'

import Callback from './Callback'

const mapStateToProps = state => ({
  userState: login.utils.getUserState(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      SSOLogin: login.actions.SSOLogin,
      unauthorize: login.actions.unauthorize
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Callback)