import { createAction } from 'redux-actions'

import { createRequestAction } from 'utils/http/actions/utils'
import { gpsTrackings, nodeLastGPSLocation, nodeAggregatedGPSLocations } from 'utils/http/url'
import * as t from './actionTypes'

const clearGPSTrackings = createAction(t.CLEAR_GPS_TRACKINGS)
const clearGPSTracking = createAction(t.CLEAR_GPS_TRACKING, nodeId => nodeId)

const deleteGPSTrackings = () => dispatch => {
  dispatch(clearGPSTrackings())
}

const deleteGPSTracking = nodeId => dispatch => {
  dispatch(clearGPSTracking(nodeId))
}

const getGPSTrackingTimeSeries = (nodeId, start, end, page) =>
  createRequestAction(t.GET_GPS_TRACKINGS, gpsTrackings(nodeId, start, end, page), 'get', {
    nodeId,
    min: start.valueOf(),
    max: end.valueOf(),
    page
  })

// eslint-disable-next-line max-params
const getGPSTrackingTimeSeriesForCharts = (deviceEid, start, end, page, sortOrder) =>
  createRequestAction(t.GET_GPS_TRACKINGS_FOR_CHARTS, gpsTrackings(deviceEid, start, end, page, sortOrder), 'get', {
    deviceEid,
    min: start.valueOf(),
    max: end.valueOf(),
    page,
    sortOrder
  })

// eslint-disable-next-line max-params
const getGPSTrackingTimeSeriesToExport = (deviceEid, start, end, page, sortOrder) =>
  createRequestAction(t.GET_GPS_TRACKINGS_TO_EXPORT, gpsTrackings(deviceEid, start, end, page, sortOrder), 'get', {
    deviceEid,
    min: start.valueOf(),
    max: end.valueOf(),
    page,
    sortOrder
  })

const getNodeLastGPSLocation = nodeMac => createRequestAction(t.GET_LAST_LOCATION, nodeLastGPSLocation(nodeMac), 'get')

// eslint-disable-next-line max-params
const getAggregatedGPSTrackingTimeSeries = (nodeId, deviceType, groupId, start, end, size, page, sortOrder, aggLevel) =>
  createRequestAction(
    t.GET_AGGREGATED_GPS_TRACKINGS,
    nodeAggregatedGPSLocations(nodeId, groupId, start, end, size, page, sortOrder, aggLevel),
    'get',
    {
      nodeId,
      deviceType,
      groupId,
      start,
      end,
      size,
      page,
      sortOrder,
      aggLevel
    }
  )

const getAggregatedGPSTrackingTimeSeriesForCharts = (
  deviceEid,
  deviceType,
  groupId,
  start,
  end,
  size,
  page,
  sortOrder,
  aggLevel // eslint-disable-next-line max-params
) =>
  createRequestAction(
    t.GET_AGGREGATED_GPS_TRACKINGS_FOR_CHARTS,
    nodeAggregatedGPSLocations(deviceEid, groupId, start, end, size, page, sortOrder, aggLevel),
    'get',
    {
      deviceEid,
      deviceType,
      groupId,
      start,
      end,
      size,
      page,
      sortOrder,
      aggLevel
    }
  )

const getAggregatedGPSTrackingTimeSeriesToExport = (
  deviceEid,
  deviceType,
  groupId,
  start,
  end,
  size,
  page,
  sortOrder,
  aggLevel // eslint-disable-next-line max-params
) =>
  createRequestAction(
    t.GET_AGGREGATED_GPS_TRACKINGS_TO_EXPORT,
    nodeAggregatedGPSLocations(deviceEid, groupId, start, end, size, page, sortOrder, aggLevel),
    'get',
    {
      deviceEid,
      deviceType,
      groupId,
      start,
      end,
      size,
      page,
      sortOrder,
      aggLevel
    }
  )

const addGpsTrackingsLoading = createAction(t.ADD_GPS_TRACKINGS_LOADING, (nodeId, agg = 'raw') => `${nodeId}_${agg}`)
const removeGpsTrackingsLoading = createAction(
  t.REMOVE_GPS_TRACKINGS_LOADING,
  (nodeId, agg = 'raw') => `${nodeId}_${agg}`
)

export {
  addGpsTrackingsLoading,
  deleteGPSTracking,
  deleteGPSTrackings,
  getAggregatedGPSTrackingTimeSeries,
  getAggregatedGPSTrackingTimeSeriesForCharts,
  getAggregatedGPSTrackingTimeSeriesToExport,
  getGPSTrackingTimeSeries,
  getGPSTrackingTimeSeriesForCharts,
  getGPSTrackingTimeSeriesToExport,
  getNodeLastGPSLocation,
  removeGpsTrackingsLoading
}
