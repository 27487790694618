import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { PreviousPageOption, NextPageOption } from '../toolbar'
import { Chart } from '../Chart'

import SearchControl, { propTypes as SearchControlPropTypes } from './SearchControl'
import messages from './messages'

class HistoricChart extends React.Component {
  componentDidUpdate(prevProps) {
    const { requestStatus, requestHistoricData } = this.props
    if (prevProps.requestStatus !== requestStatus && requestStatus === 'undone') {
      requestHistoricData()
    }
  }

  getHasNoSeries() {
    const { config } = this.props
    return config.series.length === 0
  }

  getHasNoResults() {
    const { config } = this.props
    return config.series.every(s => s.data.length === 0)
  }

  getHasPreviousPage = () => {
    const { previousResponseSet } = this.props
    return previousResponseSet
  }

  getHasNextPage = () => {
    const { nextResponseSet } = this.props
    return nextResponseSet
  }

  getPaginationControls() {
    const { isChartLoading, nextResponseSet, onNextHistoricSet, onPreviousHistoricSet, previousResponseSet } =
      this.props

    return [
      ...this.getHasPreviousPage()
        ? [
          <PreviousPageOption
            key='prevPage'
            loading={isChartLoading}
            onClick={() => onPreviousHistoricSet(previousResponseSet)}
          />
        ]
        : [],
      ...this.getHasNextPage()
        ? [
          <NextPageOption
            key='nextPage'
            loading={isChartLoading}
            onClick={() => onNextHistoricSet(nextResponseSet)}
          />
        ]
        : []
    ]
  }

  getPropsNotUsedInOtherMethods() {
    const { ...props } = this.props
    delete props.nextResponseSet
    delete props.previousResponseSet
    delete props.requestHistoricData
    delete props.onPreviousHistoricSet
    delete props.onNextHistoricSet
    return props
  }

  formatMessage = ''

  render() {
    const {
      config,
      intl: { formatMessage },
      isChartLoading,
      title,
      previousResponseSet
    } = this.props
    this.formatMessage = formatMessage

    const SearchControlPropTypesKeys = Object.keys(SearchControlPropTypes)
    const emptyResult = !isChartLoading && !previousResponseSet && (this.getHasNoSeries() || this.getHasNoResults())
    const { scParams } = SearchControlPropTypesKeys.reduce(
      (ret, propName) => {
        ret.scParams[propName] = ret.other[propName] // eslint-disable-line no-param-reassign
        delete ret.other[propName] // eslint-disable-line no-param-reassign

        return ret
      },
      {
        other: this.getPropsNotUsedInOtherMethods(),
        scParams: { showWarning: !emptyResult }
      }
    )

    let mainBody
    if (emptyResult) {
      mainBody = (
        <p className='row text-center'>
          {this.getHasNoSeries() ? null : this.formatMessage(messages.noResultsObtained)}
        </p>
      )
    } else {
      mainBody = (
        <Chart
          additionalControls={this.getPaginationControls()}
          config={config}
          isChartLoading={isChartLoading}
          title={title}
        />
      )
    }

    return (
      <div>
        <SearchControl isChartLoading={isChartLoading} {...scParams} />
        {mainBody}
      </div>
    )
  }
}

HistoricChart.propTypes = {
  config: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  maxRequestDate: PropTypes.number,
  maxResponseDate: PropTypes.number,
  minRequestDate: PropTypes.number,
  minResponseDate: PropTypes.number,
  nextResponseSet: PropTypes.bool,
  onDateTimeMaxChange: PropTypes.func.isRequired,
  onDateTimeMinChange: PropTypes.func.isRequired,
  onNextHistoricSet: PropTypes.func,
  onPreviousHistoricSet: PropTypes.func,
  previousResponseSet: PropTypes.bool,
  requestHistoricData: PropTypes.func,
  requestStatus: PropTypes.string,
  setSearchRange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

HistoricChart.defaultProps = {
  maxRequestDate: undefined,
  maxResponseDate: undefined,
  minRequestDate: undefined,
  minResponseDate: undefined,
  nextResponseSet: undefined,
  onNextHistoricSet: undefined,
  onPreviousHistoricSet: undefined,
  previousResponseSet: undefined,
  requestHistoricData: undefined,
  requestStatus: undefined
}

export default injectIntl(HistoricChart)
