import { defineMessages } from 'react-intl'

const messages = defineMessages({
  invariantText: {
    id: 'Overlays.invariantText',
    description: 'Overlay view invariant message',
    defaultMessage: 'OverlayView requires either props.mapPaneName or props.defaultMapPaneName but got %s'
  },
  overlay: {
    id: 'Overlays.overlay',
    description: 'Overlay alt text',
    defaultMessage: 'Overlay'
  },
})

export default messages