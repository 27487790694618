import React from 'react'
import PropTypes from 'prop-types'

import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import Typography from '@material-ui/core/Typography'

import { injectIntl } from 'react-intl'
import messages from './messages'

class Value extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    setTimeout(this.simulateLoading, Math.floor(Math.random() * 2000))
  }

  simulateLoading = () => {
    this.setState({
      loading: false
    })
  }

  showStaticData = data => {
    let staticData
    let value = ''

    if (this.props.devicesData[this.props.eid]) {
      staticData = this.props.devicesData[this.props.eid].staticData
      value = staticData.filter(dataItem => {
        return dataItem.name === data
      })
    }

    switch (data) {
      case 'totalActivityTime':
        if (value.length < 1) {
          value = [{ value: '--' }]
        } else if (value[0].value === null) {
          value = [{ value: '--' }]
        } else {
          const totalMinutes = value[0].value
          const d = Math.floor(totalMinutes / (60 * 24))
          const h = Math.floor(totalMinutes % (60 * 24) / 60)
          const min = Math.floor(totalMinutes % 60)

          value = [{ value: d + ' d ' + h + ' h ' + min + ' min' }]
        }
        break

      case 'startDateTime':
      case 'lastDateTime':
      case 'lastActivityTime':
      case 'mainFirmwareLastChangeTimestamp':
      case 'cellFirmwareLastChangeTimestamp':
      case 'configurationLastChangeTimestamp':
        if (value.length < 1) {
          value = [{ value: '--' }]
        } else if (value[0].value === null) {
          value = [{ value: '--' }]
        } else {
          value = [{ value: utcTimeToBrowserLocal(value[0].value) }]
        }
        break
      default:
        if (value.length < 1) {
          value = [{ value: '' }]
        }
    }

    return value[0].value
  }

  render() {
    return (
      <div className={this.props.data.data === '' ? 'notConfigured' : ''}>
        {this.state.loading ? (
          <div className="sk-bounce small">
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
          </div>
        ) : this.props.data.data === '' ? (
          <Typography gutterBottom={false}>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: this.props.data.bold,
                fontSize: this.props.data.size,
                textAlign: this.props.data.align
              }}
            >
              {this.props.data.data !== ''
                ? this.showStaticData(this.props.data.data)
                : this.formatMessage(messages.widgetNotConfigured)}
            </span>
          </Typography>
        )}
      </div>
    )
  }
}

Value.propTypes = {
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired
}

export default injectIntl(Value)
