import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class LineSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      type: props.data.type ? props.data.type : 'horizontal',
      width: props.data.width ? props.data.width : 'thin',
      style: props.data.style ? props.data.style : 'solid',
      color: props.data.color ? props.data.color : 'default'
    }
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    const error = false

    if (!error) {
      const data = {
        type: this.state.type,
        width: this.state.width,
        style: this.state.style,
        color: this.state.color
      }

      this.props.saveSettings(data)
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  render() {
    return (
      <Dialog
        open={true}
        keepMounted={true}
        scroll="paper"
        onClose={this.closeSettings}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.lineWidget)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.closeSettings}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.lineWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="type-label-placeholder">
                  {this.formatMessage(messages.type)}
                </InputLabel>
                <Select
                  value={this.state.type}
                  onChange={this.handleChange('type')}
                  input={<Input name="radius" id="type-label-placeholder" />}
                  displayEmpty={true}
                  name="type"
                  autoWidth={true}
                >
                  <MenuItem value="horizontal">{this.formatMessage(messages.horizontal)}</MenuItem>
                  <MenuItem value="vertical">{this.formatMessage(messages.vertical)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectType)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="width-label-placeholder">
                  {this.formatMessage(messages.width)}
                </InputLabel>
                <Select
                  value={this.state.width}
                  onChange={this.handleChange('width')}
                  input={<Input name="width" id="width-label-placeholder" />}
                  displayEmpty={true}
                  name="width"
                  autoWidth={true}
                >
                  <MenuItem value="thin">{this.formatMessage(messages.thin)}</MenuItem>
                  <MenuItem value="medium">{this.formatMessage(messages.medium)}</MenuItem>
                  <MenuItem value="thick">{this.formatMessage(messages.thick)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectBorderWidth)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="style-label-placeholder">
                  {this.formatMessage(messages.style)}
                </InputLabel>
                <Select
                  value={this.state.style}
                  onChange={this.handleChange('style')}
                  input={<Input name="style" id="style-label-placeholder" />}
                  displayEmpty={true}
                  name="style"
                  autoWidth={true}
                >
                  <MenuItem value="solid">{this.formatMessage(messages.solid)}</MenuItem>
                  <MenuItem value="dotted">{this.formatMessage(messages.dotted)}</MenuItem>
                  <MenuItem value="dashed">{this.formatMessage(messages.dashed)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectBorderStyle)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="color-label-placeholder">
                  {this.formatMessage(messages.color)}
                </InputLabel>
                <Select
                  value={this.state.color}
                  onChange={this.handleChange('color')}
                  input={<Input name="color" id="style-label-placeholder" />}
                  displayEmpty={true}
                  name="color"
                  autoWidth={true}
                >
                  <MenuItem value="default">{this.formatMessage(messages.default)}</MenuItem>
                  <MenuItem value="lightGrey">{this.formatMessage(messages.lightGrey)}</MenuItem>
                  <MenuItem value="grey">{this.formatMessage(messages.grey)}</MenuItem>
                  <MenuItem value="darkGrey">{this.formatMessage(messages.darkGrey)}</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectBorderColor)}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

LineSettings.propTypes = {
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default injectIntl(LineSettings)
