import { defineMessages } from 'react-intl'

const messages = defineMessages({
  users: {
    id: 'SettingsDropdown.users',
    description: 'SettingsDropdown users',
    defaultMessage: 'Users'
  },
  roles: {
    id: 'SettingsDropdown.roles',
    description: 'SettingsDropdown roles',
    defaultMessage: 'Roles'
  },
  groups: {
    id: 'SettingsDropdown.groups',
    description: 'SettingsDropdown groups',
    defaultMessage: 'Groups'
  },
  notifications: {
    id: 'SettingsDropdown.notifications',
    description: 'SettingsDropdown notifications',
    defaultMessage: 'Notifications'
  },
  development: {
    id: 'SettingsDropdown.development',
    description: 'SettingsDropdown development',
    defaultMessage: 'Development'
  },
  activityLog: {
    id: 'SettingsDropdown.activityLog',
    description: 'SettingsDropdown activityLog',
    defaultMessage: 'Activity Log'
  },
  management: {
    id: 'SettingsDropdown.management',
    description: 'MANAGEMENT label',
    defaultMessage: 'MANAGEMENT'
  }
})

export default messages
