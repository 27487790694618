import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils as configUtils } from 'ducks/configurations'
import { utils as groupUtils } from 'ducks/groups'

import EditConfigurationConnected from './EditConfiguration'
import { getConfigurationsUrl } from '../url'

const mapStateToProps = (state, { match, history }) => ({
  configState: configUtils.getState(state),
  configurationId: match.params.configurationId,
  configurationsUrl: getConfigurationsUrl(),
  groupId: groupUtils.getSelectedGroup(state).id,
  onUpdateConfiguration: () => history.push(getConfigurationsUrl(), 'updatedConfiguration'),
  selectedCanProtocolData: selectedProtocolName => configUtils.getSelectedCanProtocolData(state, selectedProtocolName),
  selectedCanProtocolType: selectedProtocolName => configUtils.getSelectedCanProtocolType(state, selectedProtocolName)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addLocalConfigurationMFIO: actions.addLocalConfigurationMFIO,
      addLocalConfigurationSignal: actions.addLocalConfigurationSignal,
      changeLocalConfigurationField: actions.changeLocalConfigurationField,
      copyLocalConfigurationSignal: actions.copyLocalConfigurationSignal,
      getConfiguration: actions.getConfiguration,
      localConfigurationError: actions.localConfigurationError,
      manageLocalConfigurationAlert: actions.manageLocalConfigurationAlert,
      resetLocalConfiguration: actions.resetLocalConfiguration,
      saveConfiguration: actions.updateConfiguration,
      setLocalConfiguration: actions.setLocalConfiguration
    },
    dispatch
  )

const EditConfiguration = withRouter(connect(mapStateToProps, mapDispatchToProps)(EditConfigurationConnected))

export { EditConfiguration }
