import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import { injectIntl } from 'react-intl'
import messages from './messages'

const EditNotificationAssignUsersTitle = ({
  title = '???',
  notificationConditionalSignalsUrl,
  notificationsUrl,
  intl
}) => {
  const { formatMessage } = intl
  return (
    <div className="row">
      <div className="col-sm-8">
        <h1>{formatMessage(messages.title) + `: ${title}`}</h1>
      </div>
      <div className="col-sm-4 text-right">
        <Link to={notificationConditionalSignalsUrl} className="button-link">
          <Button className="primary-action-button" style={{ marginLeft: 10, marginTop: 20, paddingRight: 20 }}>
            <Icon className="zmdi zmdi-chevron-left" style={{ margin: 0 }} />
            {formatMessage(messages.previous)}
          </Button>
        </Link>
        <Link to={notificationsUrl} className="button-link">
          <Button
            className="primary-action-button"
            style={{ marginLeft: 10, marginTop: 20, paddingRight: 20, paddingLeft: 20 }}
          >
            <Icon className="zmdi zmdi-format-list-bulleted" />
            &nbsp;{formatMessage(messages.list)}
          </Button>
        </Link>
      </div>
    </div>
  )
}

EditNotificationAssignUsersTitle.propTypes = {
  notificationsUrl: PropTypes.string,
  title: PropTypes.string
}

export default injectIntl(EditNotificationAssignUsersTitle)
