import React from 'react'
import PropTypes from 'prop-types'

import { BrowserRouter } from 'react-router-dom'
import { LoginRouteHandler } from 'components/login'
import { LoggedRoutes } from './LoggedRoutes'

import { useAuth0 } from '../../react-auth0-spa'
import PortalLoading from 'components/PortalLoading'

const RouteHandler = ({ isLoggedIn = false, ...other }) => {
  const { loading } = useAuth0()

  if (loading) {
    return <PortalLoading />
  } else {
    return <BrowserRouter>{isLoggedIn ? <LoggedRoutes {...other} /> : <LoginRouteHandler />}</BrowserRouter>
  }
}

RouteHandler.propTypes = {
  isLoggedIn: PropTypes.bool
}

export default RouteHandler
