import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getNewReportUrl } from '../../url'
import { deleteReportAWS, deleteReport } from '../../actions'

import Reports from './Reports'

import { utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'
import { actions } from 'ducks/reports'

const mapStateToProps = state => ({
  newReportUrl: getNewReportUrl(),
  canCreateReports: groupUtils.hasPermission(state, c.SCHEDULE_CREATE),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  fetchReports: (groupId, limit, offset) => dispatch(actions.fetchSchedules(groupId, limit, offset)),
  deleteReport: (reportId, groupId) => dispatch(deleteReport(reportId, groupId)),
  deleteReportAWS: (reportId, groupId) => dispatch(deleteReportAWS(reportId, groupId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports))
