import { defineMessages } from 'react-intl'

const messages = defineMessages({
  linechartWidget: {
    id: 'LinechartSettings.linechartWidget',
    description: 'Line chart widget',
    defaultMessage: 'LINE CHART WIDGET'
  },
  linechartWidgetSettings: {
    id: 'LinechartSettings.linechartWidgetSettings',
    description: 'Line chart widget settings',
    defaultMessage: 'Line chart widget settings.'
  },
  dinamicValue: {
    id: 'LinechartSettings.dinamicValue',
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: 'LinechartSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  mustHaveAValue: {
    id: 'LinechartSettings.mustHaveAValue',
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  cancel: {
    id: 'LinechartSettings.cancel',
    description: 'Value widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'LinechartSettings.save',
    description: 'Value widget settings save button text',
    defaultMessage: 'Save'
  },
  notSupportedMachine: {
    id: 'LinechartSettings.notSupportedMachine',
    description: '',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: 'LinechartSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noDataAvailable: {
    id: 'LinechartSettings.noDataAvailable',
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  noSignal: {
    id: 'LinechartSettings.noSignal',
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  otherLineChartWidgetSettings: {
    id: 'LinechartSettings.otherLineChartWidgetSettings',
    description: 'Line chart widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  numberOfDecimals: {
    id: 'LinechartSettings.numberOfDecimals',
    description: 'Number of decimals',
    defaultMessage: 'No. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: 'LinechartSettings.onlyZeroOrHigherIsAllowed',
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: 'LinechartSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  valueType: {
    id: 'LinechartSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: 'LinechartSettings.value',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: 'LinechartSettings.valueAvg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: 'LinechartSettings.valueMin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: 'LinechartSettings.valueMax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  gpsSignals: {
    id: 'LinechartSettings.gpsSignals',
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: 'LinechartSettings.canBusSignals',
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: 'LinechartSettings.mfioSignals',
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: 'LinechartSettings.signals',
    description: 'Signals',
    defaultMessage: 'Signals'
  }
})

export default messages
