import * as R from 'ramda'

export const colorPickerStyles = theme => ({
  dialogContent: {
    padding: [theme.spacing(2), theme.spacing(3)]
  },
  paletteIcon: {
    fontSize: 26
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
})

/***
 * Spacing unit selector
 *
 * Example:
 * const theme = { spacing: { unit: 4 } }
 * unit(theme) * 2 === 8 // true
 *
 * @param theme {Object} Theme object
 * @returns {Number}
 */
export const unit = R.path(['spacing', 'unit'])
