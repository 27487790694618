export const BASE_URL = '/charts'

const getChartsUrl = () => BASE_URL
const gpsChartTimeSeries = (deviceId, start, end, filters) =>
  '/api/v2/logs/datedAzure/m' +
  deviceId +
  '?min=' +
  start +
  '&max=' +
  end +
  '&size=50000&filters=' +
  filters +
  ',timestamp'

export { getChartsUrl, gpsChartTimeSeries }
