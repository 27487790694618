import { connect } from 'react-redux'

import { utils, actions } from 'ducks/advancedSignalsConfigDialog'
import { utils as groupUtils } from 'ducks/groups'
import { actions as cs500Actions } from 'ducks/configurationsCS500'
import csNode from 'modules/csNode'

import AdvancedSignalsConfigDialog from './AdvancedSignalsConfigDialog'

const mapStateToProps = state => ({
  isOpen: utils.getIsOpen(state),
  deviceEid: utils.getDialogDeviceEid(state),
  deviceType: utils.getDialogDeviceType(state),
  deviceName: utils.getDialogDeviceName(state),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(actions.close()),
  getDevice: (deviceId, groupId) => dispatch(csNode.actions.fetchCSNodeByEid(deviceId, groupId)),
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSignalsConfigDialog)
