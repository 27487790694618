import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as login from 'ducks/login'

import Logout from './Logout'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      partmLogout: login.actions.unauthorize
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Logout)