import { defineMessages } from 'react-intl'
 
const messages = defineMessages({
  edit: {
    id: 'PolygonEditor.edit',
    description: 'Edit menu item title',
    defaultMessage: 'Edit'
  },
  cancel: {
    id: 'PolygonEditor.cancel',
    description: 'Cancel menu item title',
    defaultMessage: 'Cancel'
  },
  showPolygon: {
    id: 'PolygonEditor.showPolygon',
    description: 'Show polygon menu item title',
    defaultMessage: 'Show polygon'
  },
  previewOverlay: {
    id: 'PolygonEditor.previewOverlay',
    description: 'Preview overlay menu item title',
    defaultMessage: 'Preview overlay'
  },
  delete: {
    id: 'PolygonEditor.delete',
    description: 'Delete menu item title',
    defaultMessage: 'Delete'
  },
  accept: {
    id: 'PolygonEditor.accept',
    description: 'Accept menu item title',
    defaultMessage: 'Accept'
  },
})
 
export default messages