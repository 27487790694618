import moment from 'moment'
import translations from 'i18n/locales'

export const config = () => {
  const i18n = translations[localStorage.getItem('user_language')]

  return {
    chart: {
      highstock: true,
      zoomType: 'x',
      animation: false
    },
    series: [],
    title: {
      text: ''
    },
    loading: {
      labelStyle: {
        color: 'white',
        fontSize: '20px',
        opacity: 1
      },
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        opacity: 1
      }
    },
    lang: {
      loading: i18n['ChartView.loadingChartText']
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        },
        showInLegend: true,
        showInNavigator: true,
        fillColor: false,
        allowPointSelect: true,
        animation: false,
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      ordinal: false
    },
    navigator: {
      enabled: true,
      height: 60,
      series: {},
      xAxis: {
        type: 'datetime',
        ordinal: false,
        style: {
          paddingTop: 10
        }
      },
      yAxis: {
        reversed: false
      },
      style: {
        paddingBottom: 10
      }
    },
    rangeSelector: {
      enabled: true,
      inputEnabled: false,
      buttons: [
        {
          type: 'minute',
          count: 5,
          text: '5m'
        },
        {
          type: 'hour',
          count: 1,
          text: '1h'
        },
        {
          type: 'hour',
          count: 6,
          text: '6h'
        },
        {
          type: 'day',
          count: 1,
          text: '1d'
        },
        {
          type: 'all',
          text: 'All'
        }
      ]
    },
    tooltip: {
      shared: true,
      formatter() {
        const tooltipPoints = this.points[0].series.chart.series.map(serie => {
          if (serie.visible && serie.navigatorSeries) {
            const { numberOfDecimals } = serie.options
            const timewiseClosestPoint = [...serie.data]
              .sort((pointA, pointB) => (pointA?.x > pointB?.x ? -1 : 1))
              .find(point => point?.x <= this?.x)

            if (timewiseClosestPoint) {
              return `
              <span style="color:${serie.color}">\u25CF</span>${serie.name}:
              <strong>
              ${
  typeof numberOfDecimals !== 'undefined'
    ? Number(timewiseClosestPoint.y).toFixed(numberOfDecimals)
    : timewiseClosestPoint.y
}
              </strong>`
            } else {
              return ''
            }
          }
          return ''
        })

        return '<strong>' + moment(this.x).format('LL HH:mm:ss.SSS') + '</strong><br />' + tooltipPoints.join('<br/>')
      }
    }
  }
}
/* eslint-enable */
