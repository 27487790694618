import { defineMessages } from 'react-intl'

const messages = defineMessages({
  moveWidget: {
    id: 'Widget.moveWidget',
    description: 'Move widget',
    defaultMessage: 'Move widget'
  },
  widgetConfiguration: {
    id: 'Widget.widgetConfiguration',
    description: 'Widget configuration',
    defaultMessage: 'Widget configuration'
  },
  widgetDepth: {
    id: 'Widget.widgetDepth',
    description: 'Widget depth',
    defaultMessage: 'Widget depth'
  },
  widgetUp: {
    id: 'Widget.widgetUp',
    description: 'Widget up',
    defaultMessage: 'Widget up'
  },
  widgetDown: {
    id: 'Widget.widgetDown',
    description: 'Widget down',
    defaultMessage: 'Widget down'
  },
  cloneWidget: {
    id: 'Widget.cloneWidget',
    description: 'Clone widget',
    defaultMessage: 'Clone widget'
  },
  deleteWidget: {
    id: 'Widget.deleteWidget',
    description: 'Delete widget',
    defaultMessage: 'Delete widget'
  }
})

export default messages
