import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { generateDeviceData } from 'utils/deviceDataGenerator'



import messages from './messages'

const styles = theme => ({
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  devicesButton: {
    marginBottom: 15
  }
})

class WeatherSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const devicesData = props.devicesData[props.eid] || {
      staticData: [{}, {}, { value: '--' }],
      dinamicDataId: '',
      dinamicData: []
    }

    const { days, unit } = this.props.data

    this.state = {
      node: devicesData,
      deviceEid: [props.eid],
      deviceEidError: '',
      days,
      daysErrorText: '',
      unit,
      unitErrorText: '',
      devicesTableDisplayed: false,
      devicesButtonTextKey: 'changeMachine',
      originalDevice: '',
      configurationLoading: false
    }
  }

  handleChange = event => {
    const name = event.target.name
    this.setState({
      [name]: event.target.value,
      [name + 'ErrorText']: ''
    })
  }

  changeSelectedDevice = device => {
    const node = generateDeviceData(device)

    this.setState({
      deviceEid: [device.EID],
      originalDevice: device,
      node,
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        configurationLoading: false
      }
    })
  }

  changeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (this.state.days === 0) {
      error = true
      this.setState({
        daysErrorText: this.formatMessage(messages.youMustSelectForecastDays)
      })
    }

    if (this.state.unit === '') {
      error = true
      this.setState({
        unitErrorText: this.formatMessage(messages.youMustSelectUnit)
      })
    }

    if (!error) {
      const data = {
        days: this.state.days,
        unit: this.state.unit
      }

      const propsNode = this.props.devicesData[this.state.deviceEid[0]]
      const deviceInfo = propsNode ? '' : this.state.node

      this.props.saveSettings(data, this.state.deviceEid, deviceInfo)
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  render() {
    const arrayOfForecastDays = [3, 5]
    const units = ['Celsius', 'Fahrenheit']
    const { classes } = this.props
    const selectedDeviceEid = this.state.deviceEid.length > 0 ? this.state.deviceEid[0] : ''
    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.closeSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.weatherWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>
          <Button
            className='primary-action-button'
            classes={{ root: classes.devicesButton }}
            onClick={this.changeDevicesTableDisplay}
          >
            {this.formatMessage(messages[this.state.devicesButtonTextKey])}
          </Button>
          {this.state.devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.weatherWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl error={this.state.daysErrorText !== ''} fullWidth required>
                <InputLabel htmlFor='days-label-placeholder' shrink>
                  {this.formatMessage(messages.days)}
                </InputLabel>
                <Select
                  displayEmpty
                  input={<Input id='days-label-placeholder' name='days' />}
                  name='days'
                  onChange={this.handleChange}
                  value={this.state.days}
                >
                  <MenuItem key={0} value={0}>
                    {this.formatMessage(messages.selectForecastDays)}
                  </MenuItem>
                  {arrayOfForecastDays.map((forecastDays, index) => {
                    return (
                      <MenuItem key={index + 1} value={forecastDays}>
                        {forecastDays}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.daysErrorText}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl error={this.state.unitErrorText !== ''} fullWidth required>
                <InputLabel htmlFor='unit-label-placeholder' shrink>
                  {this.formatMessage(messages.unit)}
                </InputLabel>
                <Select
                  displayEmpty
                  input={<Input id='unit-label-placeholder' name='unit' />}
                  name='unit'
                  onChange={this.handleChange}
                  value={this.state.unit}
                >
                  <MenuItem key={0} value=''>
                    {this.formatMessage(messages.selectTemperaturetUnit)}
                  </MenuItem>
                  {units.map((unit, index) => {
                    return (
                      <MenuItem key={index + 1} value={unit}>
                        {unit}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.unitErrorText}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button
            className='primary-action-button'
            disabled={this.state.configurationLoading}
            onClick={this.saveSettings}
          >
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

WeatherSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(WeatherSettings))
