import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { ColorPicker } from 'components/common'
import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import { generateDeviceData } from 'utils/deviceDataGenerator'

import BoxTemplate from './BoxTemplate'
import messages from './messages'
import HEX2RGB from './utils'
import {
  manageWSSubscriptions,
  generatePartialStateFromProps,
  getValueTypes,
  mapToConditionalProperties,
  withDefaultValues
} from '../utils'

const styles = theme => ({
  colorPicker: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 0,
    cursor: 'pointer',
    display: 'block',
    height: 26,
    marginTop: 21,
    width: '100%',

    '&:hover': {
      border: '3px solid rgba(0, 0, 0, 0.5)'
    }
  },
  conditionalSettings: {
    display: 'none',
    paddingTop: 0
  },
  selectedDeviceText: {
    display: 'inline',
    marginRight: 20
  },
  marginBottom: {
    marginBottom: 15
  }
})

class BoxSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const widgetData = withDefaultValues(props.data, BoxTemplate.content.params)
    const { data, value, valueType, conditionalParams, ...params } = widgetData

    this.state = {
      ...partialState,
      dinamicDataError: '',
      switch: partialState.dinamicData !== '',
      ...params,
      ...mapToConditionalProperties(conditionalParams),
      operatorError: '',
      conditionalValueError: ''
    }
  }

  handleChange = name => event => {
    const names = ['operator', 'conditionalValue']
    const { value } = event.target
    if (names.includes(name) && value !== '') {
      this.setState({
        [name]: value,
        [name + 'Error']: ''
      })
    } else if (name === 'dinamicData') {
      const { node, valueType } = this.state
      const valueTypes = getValueTypes(node, value)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      this.setState({
        dinamicData: value,
        dinamicDataError: '',
        valueTypes,
        valueType: newValueType
      })
    } else
      this.setState({
        [name]: value
      })
  }

  handleBorderColorChanged = type => color => {
    let change = { color }
    if (type === 'conditional') change = { conditionalColor: color }
    this.setState(change)
  }

  handleBackgroundChanged = type => color => {
    const RGB = HEX2RGB(color)
    let change = {
      backgroundRed: RGB[0],
      backgroundGreen: RGB[1],
      backgroundBlue: RGB[2]
    }
    if (type === 'conditional') {
      change = {
        conditionalBackgroundRed: RGB[0],
        conditionalBackgroundGreen: RGB[1],
        conditionalBackgroundBlue: RGB[2]
      }
    }
    this.setState(change)
  }

  handleAlphaChange = type => (event, value) => {
    let change = { backgroundAlpha: value }
    if (type === 'conditional') change = { conditionalBackgroundAlpha: value }
    this.setState(change)
  }

  handleSwitchChange = () => {
    this.setState(state => {
      let conditionalWidth = ''
      let conditionalStyle = ''
      let conditionalColor = ''
      let conditionalBackgroundRed = ''
      let conditionalBackgroundGreen = ''
      let conditionalBackgroundBlue = ''
      let conditionalBackgroundAlpha = 0
      let conditionalRadius = ''
      let valueTypes = []
      let valueType = ''
      if (!state.switch) {
        conditionalWidth = 'thin'
        conditionalStyle = 'solid'
        conditionalColor = '#000000'
        conditionalBackgroundRed = 255
        conditionalBackgroundGreen = 255
        conditionalBackgroundBlue = 255
        conditionalBackgroundAlpha = 0
        conditionalRadius = 0
        valueTypes = getValueTypes(state.node, state.dinamicData)
        valueType = valueTypes[0] ? valueTypes[0] : ''
      }
      return {
        switch: !state.switch,
        dinamicData: '',
        operator: '',
        conditionalValue: '',
        conditionalWidth,
        conditionalStyle,
        conditionalColor,
        conditionalBackgroundRed,
        conditionalBackgroundGreen,
        conditionalBackgroundBlue,
        conditionalBackgroundAlpha,
        conditionalRadius,
        operatorError: '',
        conditionalValueError: '',
        dinamicDataError: '',
        valueTypes,
        valueType
      }
    })
  }

  changeSelectedDevice = device => {
    this.handleSwitchChange()
    this.setState({
      deviceEid: [device.EID],
      switch: false,
      node: generateDeviceData(device),
      originalDevice: device,
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ originalDevice }) => {
      const nodeWithConfig = { ...originalDevice, deviceConfiguration: configuration }
      const node = generateDeviceData(nodeWithConfig)
      return {
        node,
        originalDevice: nodeWithConfig,
        configurationLoading: false
      }
    })
  }

  changeDevicesTableDisplay = () => {
    this.setState(state => ({
      devicesTableDisplayed: !state.devicesTableDisplayed,
      devicesButtonTextKey: state.devicesTableDisplayed ? 'changeMachine' : 'hideMachines'
    }))
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.deviceEid.length === 0) {
      error = true
      this.setState({
        deviceEidError: this.formatMessage(messages.youMustSelectAMachine)
      })
    }

    if (this.state.switch) {
      if (this.state.dinamicData === '') {
        error = true
        this.setState({
          dinamicDataError: this.formatMessage(messages.youMustChooseOneSignal)
        })
      }
      if (this.state.operator === '') {
        error = true
        this.setState({
          operatorError: this.formatMessage(messages.required)
        })
      }
      if (this.state.conditionalValue === '') {
        error = true
        this.setState({
          conditionalValueError: this.formatMessage(messages.thisFieldIsRequired)
        })
      }
      if (this.state.conditionalText === '') {
        error = true
        this.setState({
          conditionalTextError: this.formatMessage(messages.mustHaveAValue)
        })
      }
    }

    if (!error) {
      let value = ''
      if (
        this.props.data.value &&
        this.state.dinamicData !== '' &&
        this.state.dinamicData === this.state.wsSubscribedData &&
        this.state.switch &&
        this.props.data.valueType === this.state.valueType &&
        this.props.eid === this.state.deviceEid[0]
      ) {
        value = { ...this.props.data.value }
      } else {
        value = {
          timestamp: '',
          value: ''
        }
      }
      const data = {
        data: this.state.switch ? this.state.dinamicData : '',
        width: this.state.width,
        style: this.state.style,
        color: this.state.color,
        backgroundRed: this.state.backgroundRed,
        backgroundGreen: this.state.backgroundGreen,
        backgroundBlue: this.state.backgroundBlue,
        backgroundAlpha: this.state.backgroundAlpha,
        radius: this.state.radius,
        valueType: this.state.valueType,
        value,
        conditionalParams: {
          operator: this.state.operator,
          value: this.state.conditionalValue,
          width: this.state.conditionalWidth,
          style: this.state.conditionalStyle,
          color: this.state.conditionalColor,
          backgroundRed: this.state.conditionalBackgroundRed,
          backgroundGreen: this.state.conditionalBackgroundGreen,
          backgroundBlue: this.state.conditionalBackgroundBlue,
          backgroundAlpha: this.state.conditionalBackgroundAlpha,
          radius: this.state.conditionalRadius
        }
      }

      const propsNode = this.props.devicesData[this.state.deviceEid[0]]
      const deviceInfo = propsNode ? '' : this.state.node

      this.props.saveSettings(data, this.state.deviceEid, deviceInfo)

      // Sockets control parameters
      const { devicesData, subscribeWidgetToWS, unsubscribeWidgetFromWS, getNodeCredentials, getAzureToken } =
        this.props

      manageWSSubscriptions(
        this.state,
        data,
        devicesData,
        subscribeWidgetToWS,
        unsubscribeWidgetFromWS,
        getNodeCredentials,
        getAzureToken
      )

      this.setState({
        wsSubscribedData: data.data,
        previousEid: this.state.deviceEid
      })
    }
  }

  renderDeviceName = () => {
    const {
      node: { staticData }
    } = this.state
    const dataItem = staticData.find(({ name }) => name === 'name')
    return dataItem?.value || '--'
  }

  renderConfigurationStatus = () => {
    const {
      configurationLoading,
      node: { staticData }
    } = this.state

    const { classes } = this.props

    const data = staticData.find(({ name }) => name === 'hasConfiguration')

    let messageKey = ''
    let color = 'black'

    if (configurationLoading) {
      messageKey = 'Loading'
      color = '#f0ad4e'
    } else {
      if (data?.value) {
        messageKey = 'Available'
        color = 'green'
      } else {
        messageKey = 'NotAvailable'
        color = 'red'
      }
    }

    const configurationStatus = this.formatMessage(messages['configuration' + messageKey])

    return (
      <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
        <strong>{this.formatMessage(messages.configurationStatus)}: </strong>{' '}
        <span style={{ color }}>{configurationStatus}</span>
      </DialogContentText>
    )
  }

  render() {
    const conditionalOperators = ['=', '>', '>=', '<', '<=', '!=']
    const { classes } = this.props
    const { deviceEid, valueTypes, configurationLoading } = this.state
    const selectedDeviceEid = deviceEid.length > 0 ? deviceEid[0] : ''
    const needsValueType = this.state.switch && valueTypes.length > 0
    const disabled = configurationLoading
    const dinamicSignals = this.state.node.dinamicData.filter(signal => !signal.isGPS)

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={this.closeSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.boxWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText classes={{ root: classes.selectedDeviceText }} id='alert-dialog-slide-description'>
            <strong>{this.formatMessage(messages.selectedMachine)}: </strong> {this.renderDeviceName()}
          </DialogContentText>
          <Button
            className='primary-action-button'
            classes={{ root: classes.marginBottom }}
            onClick={this.changeDevicesTableDisplay}
          >
            {this.formatMessage(messages[this.state.devicesButtonTextKey])}
          </Button>
          <br />
          {this.renderConfigurationStatus()}
          <br />
          {this.state.devicesTableDisplayed && (
            <SelectMachine
              changeSelectedDevice={this.changeSelectedDevice}
              selectedDeviceEid={selectedDeviceEid}
              updateDeviceConfiguration={this.updateDeviceConfiguration}
            />
          )}
          <br />
          <DialogContentText id='alert-dialog-slide-description'>
            <span style={{ display: 'block', fontWeight: 'bold', padding: '0px 0px 10px 0px' }}>
              {this.state.switch
                ? this.formatMessage(messages.boxWidgetSettingsWhenFalse)
                : this.formatMessage(messages.boxWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='width-label-placeholder' shrink>
                      {this.formatMessage(messages.width)}
                    </InputLabel>
                    <Select
                      autoWidth
                      displayEmpty
                      input={<Input id='width-label-placeholder' name='width' />}
                      name='width'
                      onChange={this.handleChange('width')}
                      value={this.state.width}
                    >
                      <MenuItem value='thin'>{this.formatMessage(messages.thin)}</MenuItem>
                      <MenuItem value='medium'>{this.formatMessage(messages.medium)}</MenuItem>
                      <MenuItem value='thick'>{this.formatMessage(messages.thick)}</MenuItem>
                      <MenuItem value='0px'>0px</MenuItem>
                      <MenuItem value='1px'>1px</MenuItem>
                      <MenuItem value='2px'>2px</MenuItem>
                      <MenuItem value='3px'>3px</MenuItem>
                      <MenuItem value='4px'>4px</MenuItem>
                      <MenuItem value='5px'>5px</MenuItem>
                      <MenuItem value='6px'>6px</MenuItem>
                      <MenuItem value='7px'>7px</MenuItem>
                      <MenuItem value='8px'>8px</MenuItem>
                      <MenuItem value='9px'>9px</MenuItem>
                      <MenuItem value='10px'>10px</MenuItem>
                      <MenuItem value='12px'>12px</MenuItem>
                      <MenuItem value='14px'>13px</MenuItem>
                      <MenuItem value='16px'>16px</MenuItem>
                      <MenuItem value='18px'>18px</MenuItem>
                      <MenuItem value='20px'>20px</MenuItem>
                    </Select>
                    <FormHelperText>{this.formatMessage(messages.selectBorderWidth)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='style-label-placeholder' shrink>
                      {this.formatMessage(messages.style)}
                    </InputLabel>
                    <Select
                      autoWidth
                      displayEmpty
                      input={<Input id='style-label-placeholder' name='style' />}
                      name='style'
                      onChange={this.handleChange('style')}
                      value={this.state.style}
                    >
                      <MenuItem value='none'>
                        <Tooltip placement='top' title={this.formatMessage(messages.noneTooltip)}>
                          <span>{this.formatMessage(messages.none)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='hidden'>
                        <Tooltip placement='top' title={this.formatMessage(messages.hiddenTooltip)}>
                          <span>{this.formatMessage(messages.hidden)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='solid'>
                        <Tooltip placement='top' title={this.formatMessage(messages.solidTooltip)}>
                          <span>{this.formatMessage(messages.solid)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='dotted'>
                        <Tooltip placement='top' title={this.formatMessage(messages.dottedTooltip)}>
                          <span>{this.formatMessage(messages.dotted)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='dashed'>
                        <Tooltip placement='top' title={this.formatMessage(messages.dashedTooltip)}>
                          <span>{this.formatMessage(messages.dashed)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='double'>
                        <Tooltip placement='top' title={this.formatMessage(messages.doubleTooltip)}>
                          <span>{this.formatMessage(messages.double)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='groove'>
                        <Tooltip placement='top' title={this.formatMessage(messages.grooveTooltip)}>
                          <span>{this.formatMessage(messages.groove)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='ridge'>
                        <Tooltip placement='top' title={this.formatMessage(messages.ridgeTooltip)}>
                          <span>{this.formatMessage(messages.ridge)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='inset'>
                        <Tooltip placement='top' title={this.formatMessage(messages.insetTooltip)}>
                          <span>{this.formatMessage(messages.inset)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='outset'>
                        <Tooltip placement='top' title={this.formatMessage(messages.outsetTooltip)}>
                          <span>{this.formatMessage(messages.outset)}</span>
                        </Tooltip>
                      </MenuItem>
                    </Select>
                    <FormHelperText>{this.formatMessage(messages.selectBorderStyle)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='color-label-placeholder' shrink>
                      {this.formatMessage(messages.color)}
                    </InputLabel>
                    <ColorPicker
                      className={this.props.classes.colorPicker}
                      onChange={this.handleBorderColorChanged('default')}
                      value={this.state.color}
                    />
                    <FormHelperText>{this.formatMessage(messages.selectBorderColor)}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='color-label-placeholder' shrink>
                      {this.formatMessage(messages.backgroundColor)}
                    </InputLabel>
                    {/* eslint-disable max-len */}
                    <ColorPicker
                      className={this.props.classes.colorPicker}
                      onChange={this.handleBackgroundChanged('default')}
                      value={`rgb(${this.state.backgroundRed}, ${this.state.backgroundGreen}, ${this.state.backgroundBlue})`}
                    />
                    {/* eslint-enable */}
                    <FormHelperText>{this.formatMessage(messages.selectBackgroundColor)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='color-label-placeholder' shrink>
                      {this.formatMessage(messages.backgroundAlpha)}
                    </InputLabel>
                    <Slider
                      aria-labelledby='label'
                      max={10}
                      min={0}
                      onChange={this.handleAlphaChange('default')}
                      step={1}
                      style={{ marginTop: 19, padding: '15px 0px 14px 5px' }}
                      value={this.state.backgroundAlpha}
                    />
                    <FormHelperText>{this.formatMessage(messages.selectBackgroundAlpha)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='size-label-placeholder' shrink>
                      {this.formatMessage(messages.radius)}
                    </InputLabel>
                    <Select
                      autoWidth
                      displayEmpty
                      input={<Input id='radius-label-placeholder' name='radius' />}
                      name='radius'
                      onChange={this.handleChange('radius')}
                      value={this.state.radius}
                    >
                      <MenuItem value={0}>{this.formatMessage(messages.none)}</MenuItem>
                      <MenuItem value={3}>3px</MenuItem>
                      <MenuItem value={4}>4px</MenuItem>
                      <MenuItem value={5}>5px</MenuItem>
                      <MenuItem value={6}>6px</MenuItem>
                      <MenuItem value={8}>8px</MenuItem>
                      <MenuItem value={10}>10px</MenuItem>
                      <MenuItem value={12}>12px</MenuItem>
                      <MenuItem value={14}>14px</MenuItem>
                      <MenuItem value={16}>16px</MenuItem>
                      <MenuItem value={18}>18px</MenuItem>
                      <MenuItem value={20}>20px</MenuItem>
                      <MenuItem value={24}>24px</MenuItem>
                      <MenuItem value={50}>50px</MenuItem>
                      <MenuItem value={70}>70px</MenuItem>
                      <MenuItem value={100}>100px</MenuItem>
                    </Select>
                    <FormHelperText>{this.formatMessage(messages.selectBorderRadius)}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    height: '10%',
                    width: '100%',
                    textAlign: 'center',
                    position: 'absolute',
                    left: 0,
                    top: '45%',
                    zIndex: 1
                  }}
                >
                  Lorem ipsum
                </div>
                <div
                  style={{
                    borderWidth: this.state.width,
                    borderStyle: this.state.style,
                    borderColor: this.state.color,
                    borderRadius: this.state.radius,
                    background: `rgba(${this.state.backgroundRed}, ${this.state.backgroundGreen}, ${
                      this.state.backgroundBlue
                    }, ${this.state.backgroundAlpha / 10})`,
                    height: '50%',
                    width: '50%',
                    position: 'absolute',
                    left: '25%',
                    top: '25%',
                    zIndex: 10
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item style={{ paddingBottom: 4, marginTop: 4 }} xs={12}>
            <FormControlLabel
              control={<Switch checked={this.state.switch} onChange={this.handleSwitchChange} />}
              label={this.formatMessage(messages.booleanSignalDependable)}
              labelPlacement='start'
              style={{ marginLeft: 0 }}
            />
          </Grid>

          <Grid
            classes={
              this.state.switch ? { container: classes.marginBottom } : { container: classes.conditionalSettings }
            }
            container
            spacing={1}
          >
            <Grid item md={9} xs={12}>
              <FormControl disabled={disabled} error={this.state.dinamicDataError !== ''} fullWidth>
                <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                  {this.formatMessage(messages.selectBooleanSignal)}
                </InputLabel>
                <Select onChange={this.handleChange('dinamicData')} value={this.state.dinamicData}>
                  <MenuItem key='dinamicDataDefault' value=''>
                    {this.formatMessage(messages.selectValueToDisplay)}
                  </MenuItem>
                  {dinamicSignals.map(eachDinamicData => {
                    return (
                      <MenuItem key={eachDinamicData.signalId} value={eachDinamicData.signalId}>
                        {eachDinamicData.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.dinamicDataError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={2}>
              <FormControl disabled={disabled} error={this.state.operatorError !== ''} fullWidth>
                <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                  {this.formatMessage(messages.operator)}
                </InputLabel>
                <Select onChange={this.handleChange('operator')} value={this.state.operator}>
                  {conditionalOperators.map(operator => {
                    return (
                      <MenuItem key={operator} value={operator}>
                        {operator}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.operatorError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <TextField
                key='conditionalValue'
                InputLabelProps={{
                  shrink: true
                }}
                disabled={disabled}
                error={this.state.conditionalValueError !== ''}
                fullWidth
                helperText={this.state.conditionalValueError}
                id='conditionalValue'
                label={this.formatMessage(messages.conditionalValue)}
                onChange={this.handleChange('conditionalValue')}
                type='number'
                value={this.state.conditionalValue}
              />
            </Grid>
            {needsValueType && (
              <Grid item sm={6} xs={12}>
                <FormControl disabled={disabled} fullWidth>
                  <InputLabel htmlFor='valueType-label-placeholder' shrink>
                    {this.formatMessage(messages.valueType)}
                  </InputLabel>
                  <Select onChange={this.handleChange('valueType')} value={this.state.valueType}>
                    {valueTypes.map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {this.formatMessage(messages[type])}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <DialogContentText
            classes={this.state.switch ? {} : { root: classes.conditionalSettings }}
            id='alert-dialog-slide-description'
          >
            <span style={{ display: 'block', fontWeight: 'bold', padding: '0px 0px 10px 0px' }}>
              {this.formatMessage(messages.boxWidgetSettingsWhenTrue)}
            </span>
          </DialogContentText>
          <Grid
            classes={this.state.switch ? {} : { container: classes.conditionalSettings }}
            container
            spacing={3}
          >
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='conditionalWidth-label-placeholder' shrink>
                      {this.formatMessage(messages.width)}
                    </InputLabel>
                    <Select
                      autoWidth
                      displayEmpty
                      input={<Input id='conditionalWidth-label-placeholder' name='conditionalWidth' />}
                      name='conditionalWidth'
                      onChange={this.handleChange('conditionalWidth')}
                      value={this.state.conditionalWidth}
                    >
                      <MenuItem value='thin'>{this.formatMessage(messages.thin)}</MenuItem>
                      <MenuItem value='medium'>{this.formatMessage(messages.medium)}</MenuItem>
                      <MenuItem value='thick'>{this.formatMessage(messages.thick)}</MenuItem>
                      <MenuItem value='0px'>0px</MenuItem>
                      <MenuItem value='1px'>1px</MenuItem>
                      <MenuItem value='2px'>2px</MenuItem>
                      <MenuItem value='3px'>3px</MenuItem>
                      <MenuItem value='4px'>4px</MenuItem>
                      <MenuItem value='5px'>5px</MenuItem>
                      <MenuItem value='6px'>6px</MenuItem>
                      <MenuItem value='7px'>7px</MenuItem>
                      <MenuItem value='8px'>8px</MenuItem>
                      <MenuItem value='9px'>9px</MenuItem>
                      <MenuItem value='10px'>10px</MenuItem>
                      <MenuItem value='12px'>12px</MenuItem>
                      <MenuItem value='14px'>13px</MenuItem>
                      <MenuItem value='16px'>16px</MenuItem>
                      <MenuItem value='18px'>18px</MenuItem>
                      <MenuItem value='20px'>20px</MenuItem>
                    </Select>
                    <FormHelperText>{this.formatMessage(messages.selectBorderWidth)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='conditionalStyle-label-placeholder' shrink>
                      {this.formatMessage(messages.style)}
                    </InputLabel>
                    <Select
                      autoWidth
                      displayEmpty
                      input={<Input id='conditionalStyle-label-placeholder' name='conditionalStyle' />}
                      name='conditionalStyle'
                      onChange={this.handleChange('conditionalStyle')}
                      value={this.state.conditionalStyle}
                    >
                      <MenuItem value='none'>
                        <Tooltip placement='top' title={this.formatMessage(messages.noneTooltip)}>
                          <span>{this.formatMessage(messages.none)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='hidden'>
                        <Tooltip placement='top' title={this.formatMessage(messages.hiddenTooltip)}>
                          <span>{this.formatMessage(messages.hidden)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='solid'>
                        <Tooltip placement='top' title={this.formatMessage(messages.solidTooltip)}>
                          <span>{this.formatMessage(messages.solid)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='dotted'>
                        <Tooltip placement='top' title={this.formatMessage(messages.dottedTooltip)}>
                          <span>{this.formatMessage(messages.dotted)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='dashed'>
                        <Tooltip placement='top' title={this.formatMessage(messages.dashedTooltip)}>
                          <span>{this.formatMessage(messages.dashed)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='double'>
                        <Tooltip placement='top' title={this.formatMessage(messages.doubleTooltip)}>
                          <span>{this.formatMessage(messages.double)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='groove'>
                        <Tooltip placement='top' title={this.formatMessage(messages.grooveTooltip)}>
                          <span>{this.formatMessage(messages.groove)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='ridge'>
                        <Tooltip placement='top' title={this.formatMessage(messages.ridgeTooltip)}>
                          <span>{this.formatMessage(messages.ridge)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='inset'>
                        <Tooltip placement='top' title={this.formatMessage(messages.insetTooltip)}>
                          <span>{this.formatMessage(messages.inset)}</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value='outset'>
                        <Tooltip placement='top' title={this.formatMessage(messages.outsetTooltip)}>
                          <span>{this.formatMessage(messages.outset)}</span>
                        </Tooltip>
                      </MenuItem>
                    </Select>
                    <FormHelperText>{this.formatMessage(messages.selectBorderStyle)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='color-label-placeholder' shrink>
                      {this.formatMessage(messages.color)}
                    </InputLabel>
                    <ColorPicker
                      className={this.props.classes.colorPicker}
                      onChange={this.handleBorderColorChanged('conditional')}
                      value={this.state.conditionalColor}
                    />
                    <FormHelperText>{this.formatMessage(messages.selectBorderColor)}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='color-label-placeholder' shrink>
                      {this.formatMessage(messages.backgroundColor)}
                    </InputLabel>
                    {/* eslint-disable max-len */}
                    <ColorPicker
                      className={this.props.classes.colorPicker}
                      onChange={this.handleBackgroundChanged('conditional')}
                      value={`rgb(${this.state.conditionalBackgroundRed}, ${this.state.conditionalBackgroundGreen}, ${this.state.conditionalBackgroundBlue})`}
                    />
                    {/* eslint-enable */}
                    <FormHelperText>{this.formatMessage(messages.selectBackgroundColor)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='color-label-placeholder' shrink>
                      {this.formatMessage(messages.backgroundAlpha)}
                    </InputLabel>
                    <Slider
                      aria-labelledby='label'
                      max={10}
                      min={0}
                      onChange={this.handleAlphaChange('conditional')}
                      step={1}
                      style={{ marginTop: 19, padding: '15px 0px 14px 5px' }}
                      value={this.state.conditionalBackgroundAlpha}
                    />
                    <FormHelperText>{this.formatMessage(messages.selectBackgroundAlpha)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='size-label-placeholder' shrink>
                      {this.formatMessage(messages.radius)}
                    </InputLabel>
                    <Select
                      autoWidth
                      displayEmpty
                      input={<Input id='conditionalRadius-label-placeholder' name='conditionalRadius' />}
                      name='conditionalRadius'
                      onChange={this.handleChange('conditionalRadius')}
                      value={this.state.conditionalRadius}
                    >
                      <MenuItem value={0}>{this.formatMessage(messages.none)}</MenuItem>
                      <MenuItem value={3}>3px</MenuItem>
                      <MenuItem value={4}>4px</MenuItem>
                      <MenuItem value={5}>5px</MenuItem>
                      <MenuItem value={6}>6px</MenuItem>
                      <MenuItem value={8}>8px</MenuItem>
                      <MenuItem value={10}>10px</MenuItem>
                      <MenuItem value={12}>12px</MenuItem>
                      <MenuItem value={14}>14px</MenuItem>
                      <MenuItem value={16}>16px</MenuItem>
                      <MenuItem value={18}>18px</MenuItem>
                      <MenuItem value={20}>20px</MenuItem>
                      <MenuItem value={24}>24px</MenuItem>
                      <MenuItem value={50}>50px</MenuItem>
                      <MenuItem value={70}>70px</MenuItem>
                      <MenuItem value={100}>100px</MenuItem>
                    </Select>
                    <FormHelperText>{this.formatMessage(messages.selectBorderRadius)}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    height: '10%',
                    width: '100%',
                    textAlign: 'center',
                    position: 'absolute',
                    left: 0,
                    top: '45%',
                    zIndex: 1
                  }}
                >
                  Lorem ipsum
                </div>
                <div
                  style={{
                    borderWidth: this.state.conditionalWidth,
                    borderStyle: this.state.conditionalStyle,
                    borderColor: this.state.conditionalColor,
                    borderRadius: this.state.conditionalRadius,
                    background: `rgba(${this.state.conditionalBackgroundRed}, ${
                      this.state.conditionalBackgroundGreen
                    }, ${this.state.conditionalBackgroundBlue}, ${this.state.conditionalBackgroundAlpha / 10})`,
                    height: '50%',
                    width: '50%',
                    position: 'absolute',
                    left: '25%',
                    top: '25%',
                    zIndex: 10
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={disabled} onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

BoxSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  getAzureToken: PropTypes.func.isRequired,
  getNodeCredentials: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(BoxSettings))
