const mapDeviceStatus = letter => {
  const allStatusObject = {
    A: 'Activated',
    T: 'Test',
    S: 'Suspended',
    X: 'Inactive'
  }
  return allStatusObject[letter]
}

export { mapDeviceStatus }
