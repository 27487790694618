import { connect } from 'react-redux'

import { importDashboard, setDashboardSettings, updateGroupDashboard } from 'ducks/groupDashboards'

import ImportDashboard from './ImportDashboard'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    importDashboard: (maxZIndex, widgets) => dispatch(importDashboard(maxZIndex, widgets)),
    setDashboardSettings: settings => dispatch(setDashboardSettings(settings)),
    updateGroupDashboard: dashboardData => dispatch(updateGroupDashboard(dashboardData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportDashboard)
