import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'

import { valueMeetsTheCondition } from '../utils'

class Text extends React.Component {
  render() {
    if (this.props.devicesData[this.props.eid]) {
      const dinamicData = this.props.devicesData[this.props.eid].dinamicData || []

      const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
      const isValidData = dinamicDataSignalIds.includes(parseInt(this.props.data.data))

      let condition = !isValidData

      const { value } = this.props.data.value || { value: '' }

      if (typeof this.props.data.lengthOfBits === 'undefined') {
        condition = condition || value === '' || !valueMeetsTheCondition(this.props.data)
      } else {
        condition =
          condition || this.props.data.lengthOfBits !== 1 || value !== '' && Math.trunc(parseFloat(value)) === 1
      }

      let data = {}

      if (condition) {
        data = this.props.data
      } else if (typeof this.props.data.lengthOfBits === 'undefined') {
        data = this.props.data.conditionalParams
      }

      if (Object.keys(data).length > 0 && data.text) {
        const topic = process.env.REACT_APP_TOPIC + 'm' + this.props.eid.replaceAll(':', '') + '/u/ds'
        return isValidData && !this.props.isSubscribedTo(this.props.eid, topic) ? (
          <div className="sk-bounce small">
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
            <div className="sk-bounce-dot" />
          </div>
        ) : (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: data.bold,
                fontSize: data.size,
                textAlign: data.align
              }}
            >
              {data.text}
            </span>
          </Typography>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

Text.propTypes = {
  data: PropTypes.object.isRequired,
  devicesData: PropTypes.object.isRequired,
  eid: PropTypes.string.isRequired,
  isSubscribedTo: PropTypes.func.isRequired
}

export default Text
