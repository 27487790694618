import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

const MapSettings = ({ closeSettings, intl }) => {
  const { formatMessage } = intl
  return (
    <Dialog
      open={true}
      keepMounted={true}
      scroll="paper"
      onClose={closeSettings}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {formatMessage(messages.mapWidget)}
        <IconButton
          style={{
            position: 'absolute',
            right: 3,
            top: 3
          }}
          onClick={closeSettings}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ flexGrow: 1 }}>
        <DialogContentText id="alert-dialog-slide-description">
          <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
            {formatMessage(messages.mapWidgetSettings)}
          </span>
        </DialogContentText>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <DialogContentText id="alert-dialog-slide-description">
              <span style={{ display: 'block', padding: '24px 0px 15px 0px' }}>
                {formatMessage(messages.noSettingsAvailable)}
              </span>
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className="cancel-button" onClick={closeSettings}>
          {formatMessage(messages.close)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MapSettings.propTypes = {
  closeSettings: PropTypes.func.isRequired
}

export default injectIntl(MapSettings)
