import { defineMessages } from 'react-intl'

const messages = defineMessages({
  dialogTitle: {
    id: 'TreeList.dialogTitle',
    description: 'Role deleting dialog title',
    defaultMessage: 'Confirm role "{ roleDescription }" to delete'
  },
  cancel: {
    id: 'TreeList.cancel',
    description: 'Role deleting cancel button label',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'TreeList.delete',
    description: 'Role deleting confirming button label',
    defaultMessage: 'Delete'
  },
  errorUndefinedMessage: {
    id: 'TreeList.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  errorUndefinedTitle: {
    id: 'TreeList.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  cannotDeleteRole: {
    id: 'TreeList.cannotDeleteRole',
    description: 'Can not delete role',
    defaultMessage: 'Can not delete this role, as the role is currently assigned to users in this group'
  },
  editRole: {
    id: 'TreeList.editRole',
    description: 'Edit role icon tooltip title',
    defaultMessage: 'Edit role'
  },
  deleteRole: {
    id: 'TreeList.deleteRole',
    description: 'Delete role icon tooltip title',
    defaultMessage: 'Delete role'
  }
})

export default messages
