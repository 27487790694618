import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'

import Loading from 'components/Loading'

import { cleanEID, singlePosition } from '../../../../utils'
import MachineSelector from './MachineSelector'
import SelectedMachinesListItem from './SelectedMachinesListItem'

class SelectedMachinesManager extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleDeviceSelect = selectedDevice => {
    const {
      connectRealtimeGPSWebS,
      getAzureToken,
      getNodeLastGPSLocation,
      isRealtimeGpsTrackingRunning,
      onSelectedDevicesChange,
      selectedDevices
    } = this.props

    getAzureToken(selectedDevice.id, selectedDevice.EID).then(response => {
      if (isRealtimeGpsTrackingRunning) {
        const wsCredentials = {
          accessKeyId: response?.payload?.data?.accessKeyId ?? null,
          secretAccessKey: response?.payload?.data?.secretAccessKey ?? null,
          sessionToken: response?.payload?.data?.sessionToken ?? null,
          expiration: response?.payload?.data?.expiration ?? null
        }
        const topic = process.env.REACT_APP_TOPIC + 'm' + cleanEID(selectedDevice.EID) + '/geo'
        connectRealtimeGPSWebS(selectedDevice.EID, topic, wsCredentials)
      }
    })

    getNodeLastGPSLocation(cleanEID(selectedDevice.EID)).then(({ payload }) => {
      const positionData = payload?.data

      const position =
        positionData?.location?.latitude >= -90 &&
        positionData?.location?.latitude <= 90 &&
        positionData?.location?.longitude >= -180 &&
        positionData?.location?.longitude <= 180
          ? {
            timestamp: positionData.timestamp,
            latitude: parseFloat(positionData.location.latitude),
            longitude: parseFloat(positionData.location.longitude),
            altitude:
                positionData.deviceType === 'CS100'
                  ? parseFloat(positionData.altitude * 0.125 - 2500)
                  : parseFloat(positionData.altitude),
            heading: positionData.deviceType === 'CS100' ? positionData.heading / 128 : positionData.heading * 0.01,
            speed: positionData.deviceType === 'CS100' ? positionData.speed / 256 : positionData.speed * 0.01 * 3.6
          }
          : {}

      const newDevice = {
        ...selectedDevice,
        tracking: [],
        position
      }

      const newSelectedDevices = [...selectedDevices, newDevice]
      onSelectedDevicesChange(newSelectedDevices)
    })
  }

  handleDeviceDeselect = deviceEID => {
    const { disconnectRealtimeGPSWebS, onSelectedDevicesChange, selectedDevices } = this.props

    const topic = process.env.REACT_APP_TOPIC + 'm' + cleanEID + '/geo'
    disconnectRealtimeGPSWebS(deviceEID, topic)

    const newSelectedDevices = selectedDevices.filter(selectedDevice => selectedDevice.EID !== deviceEID)
    onSelectedDevicesChange(newSelectedDevices)
  }

  handleDeviceColorChange = (deviceEID, value) => {
    const { deviceColors, onDeviceColorsChange } = this.props

    const newDeviceColors = deviceColors.map(device => {
      let newDevice = device
      if (device.EID === deviceEID) {
        newDevice = {
          ...device,
          color: value
        }
      }

      return newDevice
    })

    onDeviceColorsChange(newDeviceColors)
  }

  renderSelectedDevicesList = () => {
    const {
      deviceColors,
      isRealtimeGpsTrackingRunning,
      gpsTrackings,
      onFlyToMarkerClick,
      queriedDevices,
      selectedDevices
    } = this.props

    const deviceEIDsWithGpsTrackings = Object.keys(gpsTrackings)
    const isReady =
      deviceEIDsWithGpsTrackings.length > 0 &&
      deviceEIDsWithGpsTrackings.every(deviceEID => gpsTrackings[deviceEID].ready)

    return selectedDevices.map(selectedDevice => {
      const { color } = deviceColors.find(device => device.EID === selectedDevice.EID) || {}

      const deviceGpsTracking = gpsTrackings[cleanEID(selectedDevice.EID)]
        ? gpsTrackings[cleanEID(selectedDevice.EID)].points
        : !_isEmpty(selectedDevice.position)
          ? singlePosition(selectedDevice.position)
          : []
      const isQueriedDevice = queriedDevices.map(device => device.EID).includes(selectedDevice.EID)
      const hasGpsTracking = gpsTrackings[cleanEID(selectedDevice.EID)]?.points?.length > 0

      const hasNoGpsTrackingOnQuery = !isRealtimeGpsTrackingRunning && isReady && isQueriedDevice && !hasGpsTracking

      return (
        <SelectedMachinesListItem
          key={cleanEID(selectedDevice.EID)}
          color={color}
          hasNoGpsTrackingOnQuery={hasNoGpsTrackingOnQuery}
          name={selectedDevice.name}
          noGpsTracking={deviceGpsTracking.length < 1}
          onDeviceColorChange={value => this.handleDeviceColorChange(selectedDevice.EID, value)}
          onDeviceDeselect={() => this.handleDeviceDeselect(selectedDevice.EID)}
          onFlyToMarkerClick={() => onFlyToMarkerClick(deviceGpsTracking)}
        />
      )
    })
  }

  render() {
    const { isAssetsLoading, selectedDevices, unselectedDevices } = this.props

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isAssetsLoading ? (
            <Loading />
          ) : (
            <MachineSelector
              onDeviceSelect={this.handleDeviceSelect}
              selectedDevices={selectedDevices}
              unselectedDevices={unselectedDevices}
            />
          )}
        </Grid>
        <Grid item style={{ marginTop: 10 }} xs={12}>
          {this.renderSelectedDevicesList()}
        </Grid>
      </Grid>
    )
  }
}

SelectedMachinesManager.propTypes = {
  connectRealtimeGPSWebS: PropTypes.func.isRequired,
  deviceColors: PropTypes.array.isRequired,
  disconnectRealtimeGPSWebS: PropTypes.func.isRequired,
  getAzureToken: PropTypes.func.isRequired,
  getNodeLastGPSLocation: PropTypes.func.isRequired,
  gpsTrackings: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isAssetsLoading: PropTypes.bool.isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  onDeviceColorsChange: PropTypes.func.isRequired,
  onFlyToMarkerClick: PropTypes.func.isRequired,
  onSelectedDevicesChange: PropTypes.func.isRequired,
  queriedDevices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  unselectedDevices: PropTypes.array.isRequired
}

export default injectIntl(SelectedMachinesManager)
