import React from 'react'
import PropTypes from 'prop-types'

import ActionsStateTitle from './ActionsStateTitle'
import ActionsStateContent from './ActionsStateContent'

import { injectIntl } from 'react-intl'
import messages from './messages'

class ActionsState extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  render() {
    const { groupId, intl, loadingGroup, privileges, secondaryGlobalQuery } = this.props

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <ActionsStateTitle title={this.formatMessage(messages.title)} />
          </div>
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <ActionsStateContent
                groupId={groupId}
                intl={intl}
                loadingGroup={loadingGroup}
                privileges={privileges}
                secondaryGlobalQuery={secondaryGlobalQuery}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ActionsState.propTypes = {
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  loadingGroup: PropTypes.bool.isRequired,
  privileges: PropTypes.object.isRequired,
  secondaryGlobalQuery: PropTypes.string.isRequired
}

export default injectIntl(ActionsState)
