import { defineMessages } from 'react-intl'

const messages = defineMessages({
  advancedSignalsChartSettings: {
    id: 'AdvancedSignalsChartSettings.advancedSignalsChartSettings',
    description: 'Advanced signals chart settings',
    defaultMessage: 'Advanced signals chart settings:'
  },
  loadingConfiguratedSignals: {
    id: 'AdvancedSignalsChartSettings.loadingConfiguratedSignals',
    description: 'Loading configurated signals',
    defaultMessage: 'Loading configurated advanced signals'
  },
  noAdvancedSignals: {
    id: 'AdvancedSignalsChartSettings.noAdvancedSignals',
    description: 'No advanced signals available',
    defaultMessage: 'No advanced signals available'
  },
  signals: {
    id: 'AdvancedSignalsChartSettings.signals',
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  defaultTimeRange: {
    id: 'AdvancedSignalsChartSettings.defaultTimeRange',
    description: 'Default time range',
    defaultMessage: 'Default time range'
  },
  lastWeek: {
    id: 'AdvancedSignalsChartSettings.lastWeek',
    description: 'Last week time range',
    defaultMessage: 'Last week'
  },
  lastMonth: {
    id: 'AdvancedSignalsChartSettings.lastMonth',
    description: 'Last month time range',
    defaultMessage: 'Last month'
  },
  lastThreeMonths: {
    id: 'AdvancedSignalsChartSettings.lastThreeMonths',
    description: 'Last three months time range',
    defaultMessage: 'Last three months'
  },
  lastSixMonths: {
    id: 'AdvancedSignalsChartSettings.lastSixMonths',
    description: 'Last six months time range',
    defaultMessage: 'Last six months'
  },
  lastYear: {
    id: 'AdvancedSignalsChartSettings.lastYear',
    description: 'Last year time range',
    defaultMessage: 'Last year'
  },
  basicColumn: {
    id: 'AdvancedSignalsChartSettings.basicColumn',
    description: 'Basic column',
    defaultMessage: 'Basic column'
  },
  stackedColumn: {
    id: 'AdvancedSignalsChartSettings.stackedColumn',
    description: 'Stacked column',
    defaultMessage: 'Stacked column'
  },
  typeOfGraphic: {
    id: 'AdvancedSignalsChartSettings.typeOfGraphic',
    description: 'Type of graphic',
    defaultMessage: 'Type of graphic'
  }
})

export default messages
