import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as reports from 'modules/reports/actions'
import * as reportsUtils from 'modules/reports/reducer'
import ChartArea from './ChartArea'

const mapStateToProps = (state, { colors }) => ({
  chartData: reportsUtils.getFormatedChartData(state, colors)
})

const mapDispatchToProps = dispatch => ({
  setChartQueryType: (chartQueryType, minRequestTimestamp, maxRequestTimestamp) =>
    dispatch(reports.setChartQueryType(chartQueryType, minRequestTimestamp, maxRequestTimestamp))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartArea))
