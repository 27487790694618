import { combineReducers } from 'redux'

import * as advancedSignalsConfigDialog from 'ducks/advancedSignalsConfigDialog'
import * as configurations from 'ducks/configurations'
import * as configurationsCS500 from 'ducks/configurationsCS500'
import * as dashboards from 'ducks/dashboards'
import { reducer as gpstrackingReducer, constants as gpstrackingConstants } from 'ducks/geotracking'
import * as groupDashboards from 'ducks/groupDashboards'
import * as grps from 'ducks/groups'
import * as language from 'ducks/language'
import * as login from 'ducks/login'
import * as map from 'ducks/map'
import * as notifications from 'ducks/notifications'
import {
  constants as smsServiceConsentDialogConstants,
  reducer as smsServiceConsentDialogReducer
} from 'ducks/smsServiceConsentDialog'
import * as template from 'ducks/template'
import * as theme from 'ducks/theme'
import * as websocket from 'ducks/websocket'
import csNode from 'modules/csNode'
import { reducer as geofencesReducer, constants as geofencesConstants } from 'modules/geofences'
import { reducer as reportReducer, constants as reportConstants } from 'modules/reports'
import * as http from 'utils/http'

// Key order: alphabetical
export default combineReducers({
  [advancedSignalsConfigDialog.constants.NAME]: advancedSignalsConfigDialog.reducer,
  [configurations.constants.NAME]: configurations.reducer,
  [configurationsCS500.constants.NAME]: configurationsCS500.reducer,
  [csNode.constants.NAME]: csNode.reducer,
  [dashboards.NAMESPACE]: dashboards.default,
  [geofencesConstants.NAME]: geofencesReducer,
  [gpstrackingConstants.NAME]: gpstrackingReducer,
  [groupDashboards.NAMESPACE]: groupDashboards.default,
  [grps.constants.NAME]: grps.reducer,
  [http.constants.NAME]: http.reducer,
  [language.constants.NAME]: language.reducer,
  [login.constants.NAME]: login.reducer,
  [map.constants.NAME]: map.reducer,
  [notifications.constants.NAME]: notifications.reducer,
  [reportConstants.NAME]: reportReducer,
  [smsServiceConsentDialogConstants.NAME]: smsServiceConsentDialogReducer,
  [template.constants.NAME]: template.reducer,
  [theme.constants.NAME]: theme.reducer,
  [websocket.constants.NAME]: websocket.reducer
})
