import { defineMessages } from 'react-intl'

const messages = defineMessages({
  realTimeValueWidget: {
    id: 'RealTimeValueSettings.realTimeValueWidget',
    description: 'Real time value widget',
    defaultMessage: 'REAL TIME VALUE WIDGET'
  },
  realTimeValueWidgetSettings: {
    id: 'RealTimeValueSettings.realTimeValueWidgetSettings',
    description: 'Real time value widget settings dialog context text',
    defaultMessage: 'Real time value widget settings:'
  },
  dinamicValue: {
    id: 'RealTimeValueSettings.dinamicValue',
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: 'RealTimeValueSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  otherValueWidgetSettings: {
    id: 'RealTimeValueSettings.otherValueWidgetSettings',
    description: 'Real time value widget settings dialog',
    defaultMessage: 'Other widget settings:'
  },
  mustHaveAValue: {
    id: 'RealTimeValueSettings.mustHaveAValue',
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  size: {
    id: 'RealTimeValueSettings.size',
    description: 'Font size',
    defaultMessage: 'Font size'
  },
  selectSizeInPixels: {
    id: 'RealTimeValueSettings.selectSizeInPixels',
    description: 'Real time value widget settings: Select font size in pixels',
    defaultMessage: 'Select font size in pixels'
  },
  weight: {
    id: 'RealTimeValueSettings.weight',
    description: 'Font weight',
    defaultMessage: 'Font weight'
  },
  bold: {
    id: 'RealTimeValueSettings.bold',
    description: 'Font weight: bold',
    defaultMessage: 'Bold'
  },
  normal: {
    id: 'RealTimeValueSettings.normal',
    description: 'Font weight: normal',
    defaultMessage: 'Normal'
  },
  selectWeight: {
    id: 'RealTimeValueSettings.selectWeight',
    description: 'Real time value widget settings: Select font weight',
    defaultMessage: 'Select font weight'
  },
  align: {
    id: 'RealTimeValueSettings.align',
    description: 'Text align',
    defaultMessage: 'Text align'
  },
  left: {
    id: 'RealTimeValueSettings.left',
    description: 'Text align: left',
    defaultMessage: 'Left'
  },
  center: {
    id: 'RealTimeValueSettings.center',
    description: 'Text align: center',
    defaultMessage: 'Center'
  },
  right: {
    id: 'RealTimeValueSettings.right',
    description: 'Text align: right',
    defaultMessage: 'Right'
  },
  selectAlign: {
    id: 'RealTimeValueSettings.selectAlign',
    description: 'Real time value widget settings: Select text align',
    defaultMessage: 'Select text align'
  },
  cancel: {
    id: 'RealTimeValueSettings.cancel',
    description: 'Real time value widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'RealTimeValueSettings.save',
    description: 'Real time value widget settings save button text',
    defaultMessage: 'Save'
  },
  notSupportedMachine: {
    id: 'RealTimeValueSettings.notSupportedMachine',
    description: '',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: 'RealTimeValueSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noSignal: {
    id: 'RealTimeValueSettings.noSignal',
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  numberOfDecimals: {
    id: 'RealTimeValueSettings.numberOfDecimals',
    description: 'Number of decimals',
    defaultMessage: 'No. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: 'RealTimeValueSettings.onlyZeroOrHigherIsAllowed',
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: 'RealTimeValueSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  valueType: {
    id: 'RealTimeValueSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: 'RealTimeValueSettings.value',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: 'RealTimeValueSettings.valueAvg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: 'RealTimeValueSettings.valueMin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: 'RealTimeValueSettings.valueMax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  noDataSince: {
    id: 'RealTimeValueSettings.noDataSince',
    description: 'No data since',
    defaultMessage: 'No data since {date}'
  },
  gpsSignals: {
    id: 'RealTimeValueSettings.gpsSignals',
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: 'RealTimeValueSettings.canBusSignals',
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: 'RealTimeValueSettings.mfioSignals',
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: 'RealTimeValueSettings.signals',
    description: 'Signals',
    defaultMessage: 'Signals'
  }
})

export default messages
