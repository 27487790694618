import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import messages from '../messages'
import { alertStyles } from '../styles'

const styles = {
  ...alertStyles
}

class ServerErrorAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, intl } = this.props
    return (
      <Alert classes={{ message: classes.alertMessage }} severity='error'>
        <AlertTitle classes={{ root: classes.alertTitle }}>{intl.formatMessage(messages.serverError)}</AlertTitle>
        {intl.formatMessage(messages.serverErrorClarification, {
          a: text => (
            <a className={classes.alertLink} href={process.env.REACT_APP_SUPPORT_LINK} rel='noreferrer' target='_blank'>
              {text}
            </a>
          )
        })}
      </Alert>
    )
  }
}

ServerErrorAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(ServerErrorAlert))
