import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import csNode from 'modules/csNode'

import Map from './Map'
import MapSettingsUnconnected from './MapSettings'
import MapTemplate from './MapTemplate'

const mapStateToProps = (state, { match }) => {
  const { nodeId } = match.params
  return {
    node: csNode.utils.getCSNodeById(state, nodeId)
  }
}

export default withRouter(connect(mapStateToProps, null)(Map))
const MapSettings = withRouter(connect(mapStateToProps, null)(MapSettingsUnconnected))
export { MapSettings, MapTemplate }
