const HOME = 'home'
const CALLBACK = 'callback'

export const HOME_PATH = `/*/${HOME}`
export const CALLBACK_PATH = `/${CALLBACK}`

const DEFAULT_HOME = `/default/${HOME}`
const DEFAULT_CALLBACK = `/default/${CALLBACK}`

export const REDIRECTIONS = [
  { from: HOME, to: DEFAULT_HOME },
  { from: CALLBACK, to: DEFAULT_CALLBACK }
]

const getUnloggedRedirection = groupPath => (groupPath ? HOME_PATH.replace('*', groupPath) : DEFAULT_HOME)

const getHomeUrl = () => HOME

const getCallbackUrl = () => CALLBACK

export { getUnloggedRedirection, getHomeUrl, getCallbackUrl }
