import { defineMessages } from 'react-intl'

const namespace = 'DeleteDashboards'

const messages = defineMessages({
  deleteDashboards: {
    id: `${namespace}.deleteDashboards`,
    description: 'Delete Dashboards',
    defaultMessage: 'Delete dashboards'
  },
  errorDeletingDashboard: {
    id: `${namespace}.errorDeletingDashboard`,
    description: 'There was an error deleting the dashboard',
    defaultMessage: 'There was an error deleting the dashboard'
  },
  all: {
    id: `${namespace}.all`,
    description: 'All',
    defaultMessage: 'all'
  },
  warningMessage: {
    id: `${namespace}.warningMessage`,
    description: 'All dashboards deleting warning',
    defaultMessage:
      'Are you sure you want to delete the {all} the dashboards of the selected devices? ' +
      'Remember that they will be eliminated from {all} the groups.'
  },
  delete: {
    id: `${namespace}.delete`,
    description: 'Delete button label',
    defaultMessage: 'Delete'
  },
  successfulDeletion: {
    id: 'UpdateConfiguration.successfulDeletion',
    description: 'Dashboards deletion process started successfully',
    defaultMessage: 'Dashboards deletion process started successfully. See more details in `Events history` section.'
  }
})

export default messages
