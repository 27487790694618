import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import ErrorIcon from '@material-ui/icons/Error'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { ERROR_IN_CONFIG_MODE, SELECT_DEVICES_MODE, UPDATE_CONFIG_MODE } from './constants'
import { applyConfiguration } from './utils'

import messages from './messages'

const styles = {
  checkbox: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {},
  dialog: {
    minWidth: 800
  },
  divider: {
    margin: '32px 0 0 0'
  },
  subtitle: {
    width: '100%',
    padding: '16px 0 0 0',
    fontSize: 16,
    color: '#5E5E5E',
    fontWeight: 'bold'
  },
  text: {
    width: '100%',
    padding: 0,
    fontSize: 16,
    color: '#5E5E5E'
  },
  title: {
    fontSize: 20,
    padding: '16px 0 0 0'
  }
}

class ApplyConfigurationDialog extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage },
      classes
    } = props

    this.formatMessage = formatMessage
    this.classes = classes
  }

  showConfigUpdateStatus = (device, stateCopy) => {
    const alertMessageText = stateCopy['configUpdateStatusText|' + device.EID]
    const alertMessageTitle = device.name + ' - ' + device.EID
    const alertMessageType = stateCopy['configUpdateAlertType|' + device.EID]

    return (
      <div key={device.EID + '-status'} className='col-md-12' style={{ padding: '0px 0 10px 0' }}>
        <div
          className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}
          style={{ marginBottom: 0 }}
        >
          {alertMessageTitle !== '' && (
            <h4 style={{ display: 'flex', alignItems: 'flex-start', margin: 0 }}>
              {alertMessageType === 'success' && <CheckCircleIcon style={{ marginRight: 10 }} />}
              {alertMessageType === 'info' && <InfoIcon style={{ marginRight: 10 }} />}
              {alertMessageType === 'danger' && <ErrorIcon style={{ marginRight: 10 }} />}
              {alertMessageType === 'warning' && <AccessTimeIcon style={{ marginRight: 10 }} />}
              {alertMessageTitle}
            </h4>
          )}
          {alertMessageText !== '' && <h5 style={{ margin: '0 0 0 35px' }}>{alertMessageText}</h5>}
        </div>
      </div>
    )
  }

  render() {
    const {
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      canEditDevice,
      config,
      devices,
      dialogMode,
      getDeviceNonVolatileConfiguration,
      groupId,
      isDialogOpen,
      onDialogClose,
      selectDevices,
      selectedDevices,
      setDeviceNonVolatileConfiguration,
      setState,
      stateCopy
    } = this.props

    return (
      <Dialog
        classes={{ paper: this.classes.dialog }}
        onClose={(event, reason) => {
          onDialogClose(reason)
        }}
        open={isDialogOpen}
      >
        <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
          <DialogTitle style={{ padding: 12 }}>
            <IconButton
              onClick={onDialogClose}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
            {this.formatMessage(messages.configurationUpdate)}
          </DialogTitle>

          {dialogMode === SELECT_DEVICES_MODE && (
            <div style={{ padding: '12px 12px 0 12px' }}>
              <DialogContentText style={styles.text}>{this.formatMessage(messages.selectDevice)}</DialogContentText>
              <FormControl style={{ width: '100%', padding: '16px 24px 0 24px' }}>
                {devices.map(device => (
                  <FormControlLabel
                    key={device.id + 'checkbox'}
                    control={
                      <Checkbox
                        checked={selectedDevices.find(d => d.id === device.id) ? true : false}
                        classes={{ root: this.classes.checkbox, checked: this.classes.checked }}
                        onChange={event => {
                          selectDevices(event, device, selectedDevices, setState)
                        }}
                      />
                    }
                    label={device.name + ' - ' + device.EID}
                    style={{ display: 'flex' }}
                  />
                ))}
              </FormControl>
              {devices.length === 0 && (
                <div style={{ padding: '12px 12px 0 12px' }}>
                  <div
                    className={'alert alert-' + alertMessagesType + ' alert-dismissible animated fadeIn'}
                    style={{ marginBottom: 0 }}
                  >
                    <h4 style={{ display: 'flex', alignItems: 'flex-start', margin: 0 }}>
                      <ErrorIcon style={{ marginRight: 10 }} />
                      {alertMessagesTitle}
                    </h4>
                    <h5 style={{ margin: '0 0 0 35px' }}>{alertMessagesText}</h5>
                  </div>
                </div>
              )}
            </div>
          )}

          {dialogMode === UPDATE_CONFIG_MODE && (
            <div style={{ padding: '12px 12px 0 12px' }}>
              <Typography style={styles.subtitle}>{this.formatMessage(messages.devicesToUpdate)}</Typography>
              {selectedDevices.map(device => (
                <Typography key={device.eid + 'device'} style={styles.text}>
                  {device.name + ' - ' + device.EID}
                </Typography>
              ))}
              <Divider style={styles.divider} />
              <div style={{ padding: '32px 0 32px 0' }}>
                {selectedDevices.map(device => this.showConfigUpdateStatus(device, stateCopy))}
              </div>
            </div>
          )}

          {dialogMode === ERROR_IN_CONFIG_MODE && (
            <div style={{ padding: '12px 12px 0 12px' }}>
              <div
                className={'alert alert-' + alertMessagesType + ' alert-dismissible animated fadeIn'}
                style={{ marginBottom: 0 }}
              >
                <h4 style={{ display: 'flex', alignItems: 'flex-start', margin: 0 }}>
                  <ErrorIcon style={{ marginRight: 10 }} />
                  {alertMessagesTitle}
                </h4>
                <h5 style={{ margin: '0 0 0 35px' }}>{alertMessagesText}</h5>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {dialogMode === SELECT_DEVICES_MODE && (
            <Button
              className='primary-action-button'
              disabled={!canEditDevice || selectedDevices.length === 0}
              onClick={() => {
                applyConfiguration(
                  selectedDevices,
                  setState,
                  getDeviceNonVolatileConfiguration,
                  groupId,
                  setDeviceNonVolatileConfiguration,
                  config
                )
              }}
            >
              {this.formatMessage(messages.apply)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

ApplyConfigurationDialog.propTypes = {
  alertMessagesText: PropTypes.PropTypes.string.isRequired,
  alertMessagesTitle: PropTypes.string.isRequired,
  alertMessagesType: PropTypes.string.isRequired,
  canEditDevice: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  devices: PropTypes.array.isRequired,
  dialogMode: PropTypes.string.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  selectDevices: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  setDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  stateCopy: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(ApplyConfigurationDialog))
