import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Loading from 'components/Loading'

import { getRolesUrl } from '../url' // TODO too coupled
import { csPrivileges, csPrivilegesHierarchy } from 'modules/privileges'
import { mapCSToRCTPermissions } from '../utils'
import { client, logError } from 'utils/http'

import NewRoleForm from './NewRoleForm'
import NewRoleFormTitle from './NewRoleFormTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

class NewRole extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      roles: [],
      rolesHierarchy: [],
      privilegesHierarchy: csPrivilegesHierarchy(this.formatMessage),
      privileges: csPrivileges,
      loading: true,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      permissions: []
    }
  }

  componentDidMount() {
    this.getPermissions()
  }

  getRoles = () => {
    client
      .getRoles()
      .then(response => {
        this.setState({
          roles: response.data,
          loading: false,
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = { ...response }

        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401: // Invalid credentials
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [error.response.message]
            })
            break
          case 403: // Access denied
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500: // Server errors
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  getRolesHierarchy = () => {
    const { intl } = this.props

    client
      .getRolesHierarchy()
      .then(response => {
        this.setState({
          rolesHierarchy: response.data,
          loading: false,
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = { ...response }

        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401: // Invalid credentials
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403: // Access denied
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500: // Server errors
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  getUserGroups = async () => {
    const { getUserDetails } = this.props
    let next = true
    const limit = 10
    let offset = 0
    let userGroups = []
    while (next) {
      const payload = await getUserDetails(limit, offset)
      //const { payload } = await promise
      if (payload.data.data.currentCountOfUserGroups < limit) {
        userGroups = userGroups.concat(payload.data.data.result.UserGroups)
        next = false
      } else {
        offset += limit
        userGroups = userGroups.concat(payload.data.data.result.UserGroups)
        next = true
      }
    }
    return userGroups
  }

  getPermissions = () => {
    const { groupId } = this.props

    this.getUserGroups()
      .then(response => {
        const groupPermissions = response //.data.data.UserGroups
        const { user_permissions } = groupId
          ? groupPermissions.find(groupPermission => groupPermission.GroupId === groupId)
          : groupPermissions[0]
        this.setState({
          permissions: mapCSToRCTPermissions(user_permissions),
          loading: false,
          alertMessages: false,
          alertMessagesType: '',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = response.error
        if (error.response) {
          switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 406:
            case 500:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(response)
          }
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(response)
        }
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  renderTableLoadingAndError = () => {
    const { loading, alertMessages, alertMessagesType, alertMessagesText, alertMessagesTitle } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { onRoleCreated, createRole, groupId } = this.props
    const { loading, alertMessages, permissions, privileges, privilegesHierarchy, rolesHierarchy, roles } = this.state

    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <NewRoleForm
          action={onRoleCreated}
          createRole={createRole}
          groupId={groupId}
          permissions={permissions}
          privileges={privileges}
          privilegesHierarchy={privilegesHierarchy}
          roles={roles}
          rolesHierarchy={rolesHierarchy}
        />
      )
    }
  }

  render() {
    const { canReadRoles } = this.props

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <NewRoleFormTitle canReadRoles={canReadRoles} rolesUrl={getRolesUrl()} />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

NewRole.propTypes = {
  canReadRoles: PropTypes.bool,
  createRole: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onRoleCreated: PropTypes.func
}

NewRole.defaultProps = {
  canReadRoles: false,
  groupId: '',
  onRoleCreated: i => i
}

export default injectIntl(NewRole)
