import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'


import Machines from './Machines'

const mapStateToProps = state => ({
  privileges: {
    canViewActionsSelector:
      groupUtils.hasPermission(state, c.DEVICECONFIGURATION_CREATE) &&
        groupUtils.hasPermission(state, c.DEVICE_EDIT) ||
      groupUtils.hasPermission(state, c.FIRMWARE_READ) && groupUtils.hasPermission(state, c.FIRMWARE_EDIT),
    canViewActions:
      resolvePermissions(state, [c.DEVICEDASHBOARD_READ, c.NODEDETAILS_VIEW]) ||
      groupUtils.hasPermission(state, c.DEVICECONFIGURATION_CREATE) &&
        groupUtils.hasPermission(state, c.DEVICE_EDIT) ||
      groupUtils.hasPermission(state, c.FIRMWARE_READ) && groupUtils.hasPermission(state, c.FIRMWARE_EDIT)
  },
  allPrivileges: groupUtils.getUserPermissions(state),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getDynamicCSNodes: csNode.actions.fetchDynamicCSNodes
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(Machines)
