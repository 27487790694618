import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error400Message: {
    id: 'NewRegistration.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'NewRegistration.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'NewRegistration.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'NewRegistration.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'NewRegistration.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'NewRegistration.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: 'NewRegistration.title',
    description: 'New Registration title',
    defaultMessage: 'New Registration'
  },
  return: {
    id: 'NewRegistration.return',
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: 'NewRegistration.thisFieldIsRequired',
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  selectAParentRegistration: {
    id: 'NewRegistration.selectAParentRegistration',
    description: 'Select a parent report message',
    defaultMessage: 'Select a parent report.'
  },
  error409Message: {
    id: 'NewRegistration.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Registration name it\'s already in use'
  },
  error415Message: {
    id: 'NewRegistration.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'NewRegistration.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  name: {
    id: 'NewRegistration.name',
    description: 'Name floating label text',
    defaultMessage: 'Name'
  },
  selectParentRegistration: {
    id: 'NewRegistration.selectParentRegistration',
    description: 'Select parent report label text',
    defaultMessage: 'Select parent report'
  },
  save: {
    id: 'NewRegistration.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  error: {
    id: 'NewRegistration.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'NewRegistration.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  noAssetsOrMetrics: {
    id: 'NewRegistration.noAssetsOrMetrics',
    description: 'No assets or metrics available',
    defaultMessage: 'No assets or metrics available'
  },
  noDeviceExist404: {
    id: 'NewRegistration.noDeviceExist404',
    description: 'No device exists',
    defaultMessage: 'No device exists.'
  },
  register: {
    id: 'NewRegistration.register',
    description: 'Registration Info',
    defaultMessage: 'Registration Info'
  },
  eid: {
    id: 'NewRegistration.eid',
    description: 'EID',
    defaultMessage: 'EID'
  },
  eidDeviceAlreadyActivated: {
    id: 'NewRegistration.eidDeviceAlreadyActivated',
    description: 'Eid error message',
    defaultMessage: 'DEVICE ALREADY ACTIVATED'
  },
  regCode: {
    id: 'NewRegistration.regCode',
    description: 'Register Code',
    defaultMessage: 'Register Code'
  },
  serialNumber: {
    id: 'NewRegistration.serialNumber',
    description: 'Serial number',
    defaultMessage: 'Serial number'
  },
  activateSimCard: {
    id: 'NewRegistration.activateSimCard',
    description: 'Activate SIM card',
    defaultMessage: 'Activate SIM card'
  },
  csType: {
    id: 'NewRegistration.csType',
    description: 'Device Type',
    defaultMessage: 'Device Type'
  },
  deviceImage: {
    id: 'NewRegistration.deviceImage',
    description: 'Device image',
    defaultMessage: 'Device image'
  },
  deviceNotFound: {
    id: 'NewRegistration.deviceNotFound',
    description: 'Device not found',
    defaultMessage: 'DEVICE NOT FOUND'
  },
  cancel: {
    id: 'NewRegistration.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  status: {
    id: 'NewRegistration.status',
    description: 'Status',
    defaultMessage: 'Status'
  },
  activationPhaseTitle: {
    id: 'NewRegistration.activationPhaseTitle',
    description: 'Register your device device',
    defaultMessage: 'Register your device'
  },
  activate: {
    id: 'NewRegistration.activate',
    description: 'Register device',
    defaultMessage: 'Register device'
  },
  error500Message: {
    id: 'NewRegistration.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Internal Server Error'
  },
  activateError500Message: {
    id: 'NewRegistration.activateError500Message',
    description: 'Error 500 message',
    defaultMessage: 'No Sim Card Found / Iot Hub Provisioning Error'
  },
  error501Message: {
    id: 'NewRegistration.error501Message',
    description: 'Error 501 message',
    defaultMessage: 'Sim Card Provisioning Error'
  },
  canActivateDevicesOnlyInDefaultGroup: {
    id: 'NewRegistration.canActivateDevicesOnlyInDefaultGroup',
    description: 'To register devices is only allowed in the group "Danfoss Default Group"',
    defaultMessage: 'To register devices is only allowed in the group "Danfoss Default Group"'
  }
})

export default messages
