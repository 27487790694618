import { capitalize } from 'lodash'

const getDefaultSettingsByTableType = (type, settings = {}, eids = []) => {
  let defaultSettings = {
    columns: []
  }
  switch (type) {
    case 'dm1':
      defaultSettings = {
        columns: settings?.columns || [],
        columnsErrorText: '',
        eids,
        eidsErrorText: '',
        isGroupWidget: false,
        allColumnsSelected: false
      }
      break
    case 'lastKnownValue':
      defaultSettings = {
        columns: settings?.columns || [],
        columnsErrorText: '',
        configurationId: '',
        configurationIdErrorText: '',
        isGroupWidget: false,
        eids,
        eidsErrorText: '',
        allSignalsSelected: false
      }
      break
  }
  return { ...defaultSettings, ...settings }
}

const mapMFIOToDynamicData = MFIOData => {
  let MFIOSignals = Array.isArray(MFIOData) ? MFIOData : []
  MFIOSignals = MFIOSignals.filter(signal => signal.pinFunction !== 0).map(signal => ({
    ...signal,
    signalType: 'mfio',
    id: signal.signalId
  }))
  return MFIOSignals
}

const mapGPSFieldToUnits = field => {
  const GPSFieldUnits = {
    altitude: 'm',
    speed: 'km/h',
    heading: '°'
  }
  return GPSFieldUnits[field]
}

const mapGPSToDynamicData = gpsData =>
  Object.entries(gpsData)
    .filter(([key, value]) => value > 0 && !(key.startsWith('position') || key.startsWith('heading')))
    .map(([key]) => {
      const id = key.replace('LogTime', '')
      const name = capitalize(id)
      const unit = mapGPSFieldToUnits(id)
      return {
        id,
        name,
        signalType: 'gps',
        unit
      }
    })

const generateDynamicData = (mfioAndCanBusSignals, gpsSignals) => {
  const mfioAndCanBusDynamicData = mfioAndCanBusSignals.filter(sensor => sensor.frequency !== 0)
  return [...gpsSignals, ...mfioAndCanBusDynamicData]
}

const mapSignalsToColumns = configuration => {
  const mfioSignals = mapMFIOToDynamicData(configuration.MFIO)
  const canBusSignals = configuration.sensorsMap.map(signal => ({
    ...signal,
    id: signal.signalId,
    signalType: 'canBus'
  }))
  const gpsSignals = [] /*mapGPSToDynamicData(configuration.gps)*/
  const signals = generateDynamicData([...mfioSignals, ...canBusSignals], gpsSignals)
  return signals
}

const compareValues = (value1, value2, comparator) => {
  switch (comparator) {
    case '=':
      return value1 === value2
    case '!=':
      return value1 !== value2
    case '>':
      return value1 > value2
    case '>=':
      return value1 >= value2
    case '<':
      return value1 < value2
    case '<=':
      return value1 < value2
    default:
      return false
  }
}

export { getDefaultSettingsByTableType, mapSignalsToColumns, compareValues }
