import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { getValueTypes, generatePartialStateFromProps, mapToConditionalProperties, withDefaultValues } from '../utils'

import ImageTemplate from './ImageTemplate'

import { injectIntl } from 'react-intl'
import messages from './messages'

import imageBase64 from './imageBase64'

const styles = {
  conditionalSettings: {
    display: 'none',
    paddingTop: 0
  },
  marginBottom: {
    marginBottom: 15
  }
}

class ImageSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const partialState = generatePartialStateFromProps(props)
    const widgetData = withDefaultValues(props.data, ImageTemplate.content.params)
    delete widgetData.value
    delete widgetData.valueType
    const { data, conditionalParams, ...params } = widgetData

    this.state = {
      ...partialState,
      switch: data !== '',
      dinamicDataError: '',
      ...params,
      urlError: '',
      ...mapToConditionalProperties(conditionalParams),
      operatorError: '',
      conditionalValueError: '',
      conditionalUrlError: ''
    }
  }

  handleChange = name => event => {
    const names = ['url', 'operator', 'conditionalValue', 'conditionalUrl']
    const { value } = event.target
    if (names.includes(name)) {
      this.setState({
        [name]: value,
        [name + 'Error']: ''
      })
    } else if (name === 'dinamicData') {
      const { dinamicData, staticData } = this.props
      const { valueType } = this.state
      const valueTypes = getValueTypes(dinamicData, staticData, value)
      const newValueType = valueTypes.includes(valueType) ? valueType : valueTypes[0] || ''
      this.setState({
        dinamicData: value,
        dinamicDataError: '',
        valueTypes,
        valueType: newValueType
      })
    } else this.setState({ [name]: value })
  }

  handleSwitchChange = () => {
    const { dinamicData, staticData } = this.props
    this.setState(state => {
      let conditionalImage = ''
      let conditionalName = ''
      let conditionalType = ''
      let conditionalSize = ''
      let valueTypes = []
      let valueType = ''
      if (!state.switch) {
        conditionalImage = imageBase64
        conditionalName = 'default'
        conditionalType = 'image/jpeg'
        conditionalSize = '10kb'
        valueTypes = getValueTypes(dinamicData, staticData, state.dinamicData)
        valueType = valueTypes[0] ? valueTypes[0] : ''
      }
      return {
        switch: !state.switch,
        operator: '',
        conditionalValue: '',
        conditionalName,
        conditionalImage,
        conditionalType,
        conditionalSize,
        conditionalUrl: '',
        operatorError: '',
        conditionalValueError: '',
        conditionalUrlError: '',
        valueTypes,
        valueType
      }
    })
  }

  validUrl = url => {
    const trimedUrl = url.trim()
    const processedUrl = trimedUrl.toLowerCase()
    return processedUrl === '' || processedUrl.startsWith('http://') || processedUrl.startsWith('https://')
  }

  handleImageChange = (imageType, event) => {
    const [file = {}] = event.target.files
    const { name, type = '', size } = file
    if (type.startsWith('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64 = reader.result
        let changes = {
          image: base64,
          name,
          size: Math.round(size / 1000) + ' kB',
          type
        }
        if (imageType === 'conditional') {
          changes = {
            conditionalImage: base64,
            conditionalName: name,
            conditionalSize: Math.round(size / 1000) + ' kB',
            conditionalType: type
          }
        }
        this.setState(changes)
      }
    }
  }

  manageWSSubscriptions = data => {
    const { eid, data: propsData, subscribeWidgetToWS, nodeCredentials, unsubscribeWidgetFromWS } = this.props
    const { wsSubscribedData } = this.state
    const topic = process.env.REACT_APP_TOPIC + 'm' + eid.replaceAll(':', '') + '/u/ds'
    if (
      data.data !== '' &&
      (wsSubscribedData === '' || typeof propsData.lengthOfBits !== 'undefined' && propsData.lengthOfBits !== 1)
    ) {
      subscribeWidgetToWS(topic, eid, nodeCredentials)
    } else if (
      data.data === '' &&
      wsSubscribedData !== '' &&
      (typeof propsData.lengthOfBits === 'undefined' || propsData.lengthOfBits === 1)
    ) {
      unsubscribeWidgetFromWS(topic, eid, nodeCredentials)
    }
  }

  handleCloseSettings = () => {
    const { closeSettings } = this.props
    closeSettings()
  }

  handleSaveSettings = () => {
    let error = false

    const {
      switch: switchValue,
      dinamicData,
      operator,
      conditionalValue,
      conditionalUrl,
      url,
      wsSubscribedData,
      valueType,
      image,
      name,
      type,
      size,
      conditionalName,
      conditionalImage,
      conditionalType,
      conditionalSize
    } = this.state
    const { data: propsData, saveSettings } = this.props

    if (switchValue) {
      if (dinamicData === '') {
        error = true
        this.setState({
          dinamicDataError: this.formatMessage(messages.youMustChooseOneSignal)
        })
      }
      if (operator === '') {
        error = true
        this.setState({
          operatorError: this.formatMessage(messages.required)
        })
      }
      if (conditionalValue === '') {
        error = true
        this.setState({
          conditionalValueError: this.formatMessage(messages.thisFieldIsRequired)
        })
      }
      if (!this.validUrl(conditionalUrl)) {
        error = true
        this.setState({
          conditionalUrlError: this.formatMessage(messages.urlMustStartWith)
        })
      }
    }

    if (!this.validUrl(url)) {
      error = true
      this.setState({
        urlError: this.formatMessage(messages.urlMustStartWith)
      })
    }
    if (!error) {
      let value = ''
      if (
        propsData.value &&
        dinamicData !== '' &&
        dinamicData === wsSubscribedData &&
        switchValue &&
        propsData.valueType === valueType
      ) {
        value = { ...propsData.value }
      } else {
        value = {
          timestamp: '',
          value: ''
        }
      }
      const data = {
        data: switchValue ? dinamicData : '',
        image,
        name,
        type,
        size,
        valueType,
        value,
        url,
        conditionalParams: {
          operator,
          value: conditionalValue,
          name: conditionalName,
          image: conditionalImage,
          type: conditionalType,
          size: conditionalSize,
          url: conditionalUrl
        }
      }

      saveSettings(data)
      this.manageWSSubscriptions(data)
      this.setState({
        wsSubscribedData: data.data
      })
    }
  }

  renderImageInput = type => {
    return <input accept='image/*' onChange={event => this.handleImageChange(type, event)} type='file' />
  }

  render() {
    const { classes, dinamicData: propsDinamicData } = this.props
    const {
      valueTypes,
      switch: switchValue,
      name,
      image,
      type,
      size,
      urlError,
      url,
      dinamicDataError,
      dinamicData,
      operatorError,
      operator,
      conditionalValueError,
      conditionalValue,
      valueType,
      conditionalName,
      conditionalImage,
      conditionalType,
      conditionalSize,
      conditionalUrlError,
      conditionalUrl
    } = this.state

    const conditionalOperators = ['=', '>', '>=', '<', '<=', '!=']
    const needsValueType = switchValue && valueTypes.length > 0
    const dinamicSignals = propsDinamicData.filter(signal => !signal.isGPS)

    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleCloseSettings}
        open
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.imageWidget)}
          <IconButton
            onClick={this.handleCloseSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DialogContentText id='alert-dialog-slide-description'>
                <strong style={{ display: 'block', padding: '10px 0' }}>
                  {switchValue ? this.formatMessage(messages.newImageWhenFalse) : this.formatMessage(messages.newImage)}
                </strong>
              </DialogContentText>
              {/* eslint-disable react/jsx-no-bind */}
              {this.renderImageInput('')}
              {/* eslint-enable */}
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                <strong style={{ display: 'block', padding: '10px 0' }}>
                  {this.formatMessage(messages.currentImage)}
                </strong>
              </DialogContentText>
              <DialogContentText>
                <span style={{ display: 'block', padding: '10px 0' }}>
                  <img alt={name} src={image} style={{ maxHeight: 200 }} />
                </span>
              </DialogContentText>
              <DialogContentText>
                <strong>{this.formatMessage(messages.name)}</strong> {name}
              </DialogContentText>
              <DialogContentText>
                <strong>{this.formatMessage(messages.type)}:</strong> {type}
              </DialogContentText>
              <DialogContentText>
                <strong>{this.formatMessage(messages.size)}</strong> {size}
              </DialogContentText>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={urlError !== ''} fullWidth>
              <InputLabel htmlFor='staticData-label-placeholder' shrink>
                {this.formatMessage(messages.url)}
              </InputLabel>
              <Input name='url' onChange={this.handleChange('url')} value={url} />
              <FormHelperText>{urlError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: 20, paddingBottom: 4 }} xs={12}>
            <FormControlLabel
              control={<Switch checked={switchValue} onChange={this.handleSwitchChange} />}
              label={this.formatMessage(messages.booleanSignalDependable)}
              labelPlacement='start'
              style={{ marginLeft: 0 }}
            />
          </Grid>
          <Grid
            classes={switchValue ? { container: classes.marginBottom } : { container: classes.conditionalSettings }}
            container
            spacing={1}
          >
            <Grid item md={9} xs={12}>
              <FormControl error={dinamicDataError !== ''} fullWidth>
                <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                  {this.formatMessage(messages.selectBooleanSignal)}
                </InputLabel>
                <Select onChange={this.handleChange('dinamicData')} value={dinamicData}>
                  <MenuItem key='dinamicDataDefault' value=''>
                    {this.formatMessage(messages.selectValueToDisplay)}
                  </MenuItem>
                  {dinamicSignals.map(eachDinamicData => {
                    return (
                      <MenuItem key={eachDinamicData.signalId} value={eachDinamicData.signalId}>
                        {eachDinamicData.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{dinamicDataError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={2}>
              <FormControl error={operatorError !== ''} fullWidth>
                <InputLabel htmlFor='dinamicData-label-placeholder' shrink>
                  {this.formatMessage(messages.operator)}
                </InputLabel>
                <Select onChange={this.handleChange('operator')} value={operator}>
                  {conditionalOperators.map(conditionalOperator => {
                    return (
                      <MenuItem key={conditionalOperator} value={conditionalOperator}>
                        {conditionalOperator}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{operatorError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <TextField
                key='conditionalValue'
                InputLabelProps={{
                  shrink: true
                }}
                error={conditionalValueError !== ''}
                fullWidth
                helperText={conditionalValueError}
                id='conditionalValue'
                label={this.formatMessage(messages.conditionalValue)}
                onChange={this.handleChange('conditionalValue')}
                type='number'
                value={conditionalValue}
              />
            </Grid>
            {needsValueType && (
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor='valueType-label-placeholder' shrink>
                    {this.formatMessage(messages.valueType)}
                  </InputLabel>
                  <Select onChange={this.handleChange('valueType')} value={valueType}>
                    {valueTypes.map(vType => {
                      return (
                        <MenuItem key={vType} value={vType}>
                          {this.formatMessage(messages[vType])}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid classes={switchValue ? {} : { container: classes.conditionalSettings }} container spacing={3}>
            <Grid item xs={6}>
              <DialogContentText id='alert-dialog-slide-description'>
                <strong style={{ display: 'block', padding: '10px 0' }}>
                  {this.formatMessage(messages.newImageWhenTrue)}
                </strong>
              </DialogContentText>
              {/* eslint-disable react/jsx-no-bind */}
              {this.renderImageInput('conditional')}
              {/* eslint-enable */}
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                <strong style={{ display: 'block', padding: '10px 0' }}>
                  {this.formatMessage(messages.currentImage)}
                </strong>
              </DialogContentText>
              <DialogContentText>
                <span style={{ display: 'block', padding: '10px 0' }}>
                  <img alt={conditionalName} src={conditionalImage} style={{ maxHeight: 200 }} />
                </span>
              </DialogContentText>
              <DialogContentText>
                <strong>{this.formatMessage(messages.name)}</strong> {conditionalName}
              </DialogContentText>
              <DialogContentText>
                <strong>{this.formatMessage(messages.type)}:</strong> {conditionalType}
              </DialogContentText>
              <DialogContentText>
                <strong>{this.formatMessage(messages.size)}</strong> {conditionalSize}
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={conditionalUrlError !== ''} fullWidth>
                <InputLabel htmlFor='staticData-label-placeholder' shrink>
                  {this.formatMessage(messages.url)}
                </InputLabel>
                <Input name='url' onChange={this.handleChange('conditionalUrl')} value={conditionalUrl} />
                <FormHelperText>{conditionalUrlError}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.handleCloseSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' onClick={this.handleSaveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ImageSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dinamicData: PropTypes.array.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  nodeCredentials: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  staticData: PropTypes.array.isRequired,
  subscribeWidgetToWS: PropTypes.func.isRequired,
  unsubscribeWidgetFromWS: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(ImageSettings))
