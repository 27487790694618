export const NOTIFICATIONS_RULES_BASE = '/notificationsRulesSettings'
export const NOTIFICATIONS_RULES_SETTINGS_NEW = NOTIFICATIONS_RULES_BASE + '/notification'
export const NOTIFICATIONS_RULES_SETTINGS_EDIT_GENERAL_PROPERTIES =
  NOTIFICATIONS_RULES_BASE + '/:notificationId/general-properties/'
export const NOTIFICATIONS_RULES_SETTINGS_EDIT_CONDITIONAL_SIGNALS =
  NOTIFICATIONS_RULES_BASE + '/:notificationId/conditional-signals/'
export const NOTIFICATIONS_RULES_SETTINGS_EDIT_ASSIGN_USERS =
  NOTIFICATIONS_RULES_BASE + '/:notificationId/assign-users/'
export const NOTIFICATIONS_RULES_SETTINGS_ASSIGN_MACHINES =
  NOTIFICATIONS_RULES_BASE + '/:notificationId/assign-new-machines'
export const NOTIFICATIONS_RULES_SETTINGS_ASSIGN_USERS = NOTIFICATIONS_RULES_BASE + '/:notificationId/assign-new-users'

const getNotificationsRulesUrl = () => NOTIFICATIONS_RULES_BASE
const getNewNotificationUrl = () => NOTIFICATIONS_RULES_SETTINGS_NEW
const getEditNotificationGeneralPropertiesUrl = notificationId =>
  NOTIFICATIONS_RULES_SETTINGS_EDIT_GENERAL_PROPERTIES.replace(':notificationId', notificationId)
const getEditNotificationCondicionalSignalsUrl = notificationId =>
  NOTIFICATIONS_RULES_SETTINGS_EDIT_CONDITIONAL_SIGNALS.replace(':notificationId', notificationId)
const getEditNotificationAssignUsersUrl = notificationId =>
  NOTIFICATIONS_RULES_SETTINGS_EDIT_ASSIGN_USERS.replace(':notificationId', notificationId)
const getNotificationAssignDevicesUrl = notificationId =>
  NOTIFICATIONS_RULES_SETTINGS_ASSIGN_MACHINES.replace(':notificationId', notificationId)
const getNotificationAssignUsersUrl = notificationId =>
  NOTIFICATIONS_RULES_SETTINGS_ASSIGN_USERS.replace(':notificationId', notificationId)

export {
  getNotificationsRulesUrl,
  getNewNotificationUrl,
  getEditNotificationGeneralPropertiesUrl,
  getEditNotificationCondicionalSignalsUrl,
  getEditNotificationAssignUsersUrl,
  getNotificationAssignDevicesUrl,
  getNotificationAssignUsersUrl
}
