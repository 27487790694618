import translations from 'i18n/locales'

const download = (data, strFileName, strMimeType) => {
  const self = window, // this script is only for browsers anyway...
    defaultMime = 'application/octet-stream', // this default mime also triggers iframe downloads
    anchor = document.createElement('a'),
    toString = a => String(a)
  let mimeType = strMimeType || defaultMime,
    payload = data,
    myBlob = self.Blob || self.MozBlob || self.WebKitBlob || toString,
    fileName = strFileName || 'download',
    reader
  const url = !strFileName && !strMimeType && payload
  myBlob = myBlob.call ? myBlob.bind(self) : Blob

  if (String(this) === 'true') {
    //reverse arguments, allowing download.bind(true, 'text/xml', 'export.xml') to act as a callback
    payload = [payload, mimeType]
    mimeType = payload[0]
    payload = payload[1]
  }

  if (url && url.length < 2048) {
    // if no filename and no mime, assume a url was passed as the only argument
    fileName = url.split('/').pop().split('?')[0]
    anchor.href = url // assign href prop to temp anchor
    if (anchor.href.indexOf(url) !== -1) {
      // if the browser determines that it's a potentially valid url path:
      var ajax = new XMLHttpRequest()
      ajax.open('GET', url, true)
      ajax.responseType = 'blob'
      ajax.onload = e => {
        download(e.target.response, fileName, defaultMime)
      }
      setTimeout(() => {
        ajax.send()
      }, 0) // allows setting custom ajax headers using the return:
      return ajax
    } // end if valid url?
  } // end if url?

  // eslint-disable-next-line no-shadow
  const saver = (url, winMode) => {
    const i18n = translations[localStorage.getItem('user_language')]

    if ('download' in anchor) {
      //html5 A[download]
      anchor.href = url
      anchor.setAttribute('download', fileName)
      anchor.className = 'download-js-link'
      anchor.innerHTML = i18n['FileHandler.downloading']
      anchor.style.display = 'none'
      document.body.appendChild(anchor)
      setTimeout(() => {
        anchor.click()
        document.body.removeChild(anchor)
        if (winMode === true) {
          setTimeout(() => {
            self.URL.revokeObjectURL(anchor.href)
          }, 250)
        }
      }, 66)
      return true
    }

    // handle non-a[download] safari as best we can:
    if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
      url = url.replace(/^data:([\w/\-+]+)/, defaultMime) // eslint-disable-line no-param-reassign
      if (!window.open(url)) {
        // popup blocked, offer direct download:
        const confirm = window.confirm
        if (confirm(i18n['FileHandler.confirmMessage'])) {
          const location = window.location
          location.href = url
        }
      }
      return true
    }

    //do iframe dataURL download (old ch+FF):
    var f = document.createElement('iframe')
    document.body.appendChild(f)

    if (!winMode) {
      // force a mime that will download:
      url = 'data:' + url.replace(/^data:([\w/\-+]+)/, defaultMime) // eslint-disable-line no-param-reassign
    }
    f.src = url
    setTimeout(() => {
      document.body.removeChild(f)
    }, 333)
  } //end saver

  // go ahead and download dataURLs right away
  // eslint-disable-next-line no-useless-escape
  if (/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)) {
    const dataUrlToBlob = strUrl => {
      var parts = strUrl.split(/[:;,]/),
        type = parts[1],
        decoder = parts[2] === 'base64' ? atob : decodeURIComponent,
        binData = decoder(parts.pop()),
        mx = binData.length,
        i = 0,
        uiArr = new Uint8Array(mx)

      for (i; i < mx; ++i) {
        uiArr[i] = binData.charCodeAt(i)
      }

      return new myBlob([uiArr], { type })
    }

    if (payload.length > 1024 * 1024 * 1.999 && myBlob !== toString) {
      payload = dataUrlToBlob(payload)
      mimeType = payload.type || defaultMime
    } else {
      return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
        ? navigator.msSaveBlob(dataUrlToBlob(payload), fileName)
        : saver(payload) // everyone else can save dataURLs un-processed
    }
  } // end if dataURL passed?

  const blob = payload instanceof myBlob ? payload : new myBlob([payload], { type: mimeType })

  if (navigator.msSaveBlob) {
    // IE10+ : (has Blob, but not a[download] or URL)
    return navigator.msSaveBlob(blob, fileName)
  }

  if (self.URL) {
    // simple fast and modern way using Blob and URL:
    saver(self.URL.createObjectURL(blob), true)
  } else {
    // handle non-Blob()+non-URL browsers:
    if (typeof blob === 'string' || blob.constructor === toString) {
      try {
        return saver('data:' + mimeType + ';base64,' + self.btoa(blob))
      } catch (y) {
        return saver('data:' + mimeType + ',' + encodeURIComponent(blob))
      }
    }

    // Blob but not URL support:
    reader = new FileReader()
    reader.onload = e => {
      saver(this.result)
    }
    reader.readAsDataURL(blob)
  }
  return true
}

export default download
