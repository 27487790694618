import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions as cs500Actions } from 'ducks/configurationsCS500'
import csNode from 'modules/csNode'

import AssignMachinesTable from './AssignMachinesTable'

const mapDispatchToProps = dispatch => ({
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  getGroupDevices: (groupId, limit, offset, deviceFields, filters) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields, filters))
})

export default withRouter(connect(null, mapDispatchToProps)(AssignMachinesTable))
