import { connect } from 'react-redux'

import Home from './Home'
import reducer from './reducer'
import * as constants from './constants'

import { resolvePermissions } from 'utils/permissions'
import * as c from 'utils/constants'

const mapStateToProps = state => ({
  canReadGeofence: resolvePermissions(state, c.GEOFENCE_READ)
})

export default connect(mapStateToProps)(Home)

export { reducer, constants }
export { getGeofencesUrl } from './url'
