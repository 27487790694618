import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getStaticData, getDevicesData } from 'ducks/groupDashboards'

import { utils as groupUtils } from 'ducks/groups'

import Map from './Map'
import MapSettingsUnconnected from './MapSettings'
import MapTemplate from './MapTemplate'

import { actions as hactions } from 'utils/http'

import csNode from 'modules/csNode'

const mapStateToProps = (state, { eid }) => {
  let deviceId
  let location

  if (getStaticData(state, eid).length > 0) {
    deviceId = getStaticData(state, eid)[0].value
    location = csNode.utils.getLastLocation(state, getStaticData(state, eid)[0].value)
  }

  return {
    deviceId,
    location,
    groupId: groupUtils.getSelectedGroup(state).id,
    devicesData: getDevicesData(state)
  }
}

/* eslint-disable no-shadow */
const mapDispatchToProps = dispatch => {
  return {
    getDeviceInfo: (id, groupId) => dispatch(csNode.actions.fetchCSNodeAndLocation(id, groupId)),
    ...bindActionCreators(
      {
        getAzureToken: (id, eid) => dispatch => {
          const { promise } = dispatch(hactions.plus1Connect.getAzureNodeToken(id, eid))
          return promise
        }
      },
      dispatch
    )
  }
}
/* eslint-enable */

export default connect(mapStateToProps, mapDispatchToProps)(Map)
const MapSettings = connect(mapStateToProps, mapDispatchToProps)(MapSettingsUnconnected)
export { MapSettings, MapTemplate }
