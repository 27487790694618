import React from 'react'
import PropTypes from 'prop-types'

import { logError } from 'utils/http'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import GroupsTableTitle from './GroupsTableTitle'
import GroupsGrid from './GroupsGrid'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { mapToHierarchicalGroups } from '../utils'

class Groups extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      groupsHierarchy: [],
      loading: true,
      alertMessages: false,
      alertMessagesType: 'danger',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  componentDidMount() {
    this.getGroups()
  }

  getGroups = () => {
    const { getGroups } = this.props

    getGroups()
      .then(response => {
        const groupsHierarchy = mapToHierarchicalGroups(response.data.group)
        this.setState({
          groupsHierarchy,
          loading: false,
          alertMessages: false,
          alertMessagesType: 'danger',
          alertMessagesTitle: '',
          alertMessagesText: ['']
        })
      })
      .catch(response => {
        const error = response.error
        if (error.response) {
          switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 406:
            case 409:
            case 500:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: error.response.status
                }),
                alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
          }
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  render() {
    const { canCreateGroups, canReadGroups, deleteGroup, newGroupUrl, onDeleteGroup, urlAction, userGroup } = this.props
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, groupsHierarchy, loading } =
      this.state

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <GroupsTableTitle
              canCreateGroups={canCreateGroups}
              canReadGroups={canReadGroups}
              newGroupUrl={newGroupUrl}
            />
          </div>
          {loading ? (
            <Loading />
          ) : alertMessages ? (
            <Alert
              alertType={alertMessagesType}
              closeFunction={this.closeAlert}
              messageText={alertMessagesText}
              messageTitle={alertMessagesTitle}
              showAlert={alertMessages}
            />
          ) : (
            <GroupsGrid
              deleteGroup={deleteGroup}
              getGroups={this.getGroups}
              groupsHierarchy={groupsHierarchy}
              onDeleteGroup={onDeleteGroup}
              urlAction={urlAction}
              userGroup={userGroup}
            />
          )}
        </div>
      </div>
    )
  }
}

Groups.propTypes = {
  canCreateGroups: PropTypes.bool.isRequired,
  canReadGroups: PropTypes.bool.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  newGroupUrl: PropTypes.string.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
  urlAction: PropTypes.string,
  userGroup: PropTypes.string.isRequired
}

Groups.defaultProps = {
  urlAction: ''
}

export default injectIntl(Groups)
