const LinkTemplate = {
  id: '',
  width: 300,
  height: 30,
  x: 20,
  y: 20,
  minHeight: undefined,
  minWidth: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  content: {
    widgetType: 'link',
    params: {
      text: '',
      size: 14,
      bold: 'normal',
      align: 'left',
      url: ''
    }
  }
}

export default LinkTemplate
