import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'

import { Menu } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'

import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'

import Alert from 'components/Alert'
import DataChip from 'components/DataChip'
import Loading from 'components/Loading'
import { getMappedConsentState } from 'components/SmsServiceConsentDialog/apiMappings'
import SmsServiceStateChip from 'components/SmsServiceStateChip'
import { getSmsServiceState } from 'components/SmsServiceStateChip/helpers'
import { client, logError } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'

import AssignedUsersGroupActions from '../AssignedUsersGroupActions'
import NotificationsRulesSettingsStepper from '../NotificationsRulesSettingsStepper'
import messages from './messages'
import { composeLocalQuery, showActionMenuItems } from './utils'

import 'core-js/proposals/promise-all-settled'

class EditNotificationFormAssignUsers extends React.Component {
  constructor(props) {
    super(props)

    const alertMessages = false
    const alertMessagesType = ''
    const alertMessagesTitle = ''
    const alertMessagesText = ['']

    let alertSuccessMessagesForAssignedUsersClass = 'hidden'
    let successMessageForAssignedUsersTitle = ''
    let successMessageForAssignedUsers = ''

    if (
      props.history !== undefined &&
      props.history.location.state !== undefined &&
      props.history.location.state !== '' &&
      (props.history.location.state === 'usersAssigned' || props.history.location.state === 'notificationCreated')
    ) {
      let messageToShow = ''
      if (props.history.location.state === 'usersAssigned') {
        messageToShow = props.intl.formatMessage(messages.newUsersAssigned)
      } else if (props.history.location.state === 'notificationCreated') {
        messageToShow = props.intl.formatMessage(messages.notificationCreatedAssignUsers)
      }
      alertSuccessMessagesForAssignedUsersClass = ''
      successMessageForAssignedUsersTitle = ''
      successMessageForAssignedUsers = messageToShow
    }

    this.state = {
      notificationAssignUsersUrl: props.notificationAssignUsersUrl,
      notificationAssignedUsers: [],

      assignedUsersGroupActions: [],

      loading: true,

      groupActionsButtonAnchorElement: null,

      dialogOpen: false,
      dialogSelectedUserToDeleteTitle: '',
      dialogSelectedUserToDeleteEmail: '',

      dialogOpenBulk: false,
      dialogSelectedUserToDeleteBulkTitle: '',

      dialogOpenForSelectedAssignUser: false,
      dialogSelectedAssignUser: '',
      dialogSelectedComponent: <div />,

      alertMessages,
      alertMessagesType,
      alertMessagesTitle,
      alertMessagesText,

      count: reactBootstrapTable.count,
      length: reactBootstrapTable.length,
      elementsPerPage: reactBootstrapTable.elementsPerPage,
      page: reactBootstrapTable.page,

      selectedUsers: [],
      selectedUsersEmails: [],

      alertSuccessMessagesForAssignedUsersClass,
      successMessageForAssignedUsersTitle,
      successMessageForAssignedUsers
    }
  }

  componentDidMount() {
    const { intl } = this.props

    this.getAssignedUsers()

    AssignedUsersGroupActions(intl).then(actions => {
      this.setState({
        assignedUsersGroupActions: actions
      })
    })
  }

  getAssignedUsers = () => {
    const { intl, notification } = this.props
    const { length, page } = this.state

    const query = composeLocalQuery(page, length)

    client
      .getRuleInstanceUsersAssigned(notification.hashId, query)
      .then(response => {
        const assignedUsersCount = parseInt(response.headers['x-total-count'], 10)
        if (response.data !== undefined) {
          if (response.data.length > 0) {
            //Get more information about each user
            this.getAssignedUsersDetails(response.data, assignedUsersCount)
          } else {
            this.setState({
              notificationAssignedUsers: [],
              count: 0,
              loading: false
            })
          }
        } else {
          this.setState({
            loading: false,
            notificationAssignedUsers: [],
            count: 0,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: intl.formatMessage(messages.errorText),
            alertMessagesText: [intl.formatMessage(messages.errorRequestingUsers)]
          })
        }
      })
      .catch(response => {
        const error = { ...response }
        if (error.response !== undefined && error.response.status !== undefined) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: 400 }),
                alertMessagesText: [intl.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: 401 }),
                alertMessagesText: [intl.formatMessage(messages.error401Text)]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: 403 }),
                alertMessagesText: [intl.formatMessage(messages.error403Text)]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: 404 }),
                alertMessagesText: [intl.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: 406 }),
                alertMessagesText: [intl.formatMessage(messages.error406Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.error, { number: 500 }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                loading: false,
                notificationAssignedUsers: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
              })
              logError(response)
          }
        }
      })
  }

  getAssignedUsersDetails = (asignedUsersToShow, assignedUsersCount) => {
    const { getUsersOfGroupDetail, intl, notification } = this.props

    const groupId = notification.groupId
    const usersEmailsToGetDetail = asignedUsersToShow.map(user => user.email)
    if (asignedUsersToShow.length > 0) {
      getUsersOfGroupDetail(groupId, usersEmailsToGetDetail)
        .then(response => {
          const assignedUsers = []
          if (response.data.users !== undefined && response.data.users.length > 0) {
            const assignedUsersWithDetail = response.data.users
            assignedUsersWithDetail.map(user => {
              const currentUserEmail = user.email
              const usersWithActionsFiltered = asignedUsersToShow.filter(u => {
                return u.email === currentUserEmail
              })
              const userWithActions = usersWithActionsFiltered[0]

              const consentState = getMappedConsentState(user?.consent)
              const isPhoneNumberPresent = user?.isPhoneNumberPresent

              const userObject = {
                id: user.id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                notificationActions: userWithActions.actions,
                ruleInstanceHashId: userWithActions.ruleInstanceHashId,
                smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
              }
              assignedUsers.push(userObject)
              return user
            })
          }

          this.setState({
            notificationAssignedUsers: assignedUsers,
            count: assignedUsersCount,
            loading: false
          })
        })
        .catch(response => {
          const error = { ...response }
          if (error.response !== undefined && error.response.status !== undefined) {
            switch (error.response.status) {
              case 400: // Bad request
                this.setState({
                  loading: false,
                  notificationAssignedUsers: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: intl.formatMessage(messages.error, { message: 400 }),
                  alertMessagesText: [intl.formatMessage(messages.error400Text)]
                })
                break
              case 401: // Invalid credentials
                this.setState({
                  loading: false,
                  notificationAssignedUsers: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: intl.formatMessage(messages.error, { message: 401 }),
                  alertMessagesText: [error.response.message]
                })
                break
              case 403: // Access denied
                this.setState({
                  loading: false,
                  notificationAssignedUsers: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: intl.formatMessage(messages.error, { message: 403 }),
                  alertMessagesText: [intl.formatMessage(messages.error403Text)]
                })
                break
              case 404: // API url not found
                this.setState({
                  loading: false,
                  notificationAssignedUsers: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: intl.formatMessage(messages.error, { message: 404 }),
                  alertMessagesText: [intl.formatMessage(messages.error404Text)]
                })
                break
              case 500: // Server errors
                this.setState({
                  loading: false,
                  notificationAssignedUsers: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: intl.formatMessage(messages.error, { message: 500 }),
                  alertMessagesText: [error.response.data.error_description]
                })
                break
              default:
                this.setState({
                  loading: false,
                  notificationAssignedUsers: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
                  alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
                })
                logError(response)
            }
          } else {
            this.setState({
              loading: false,
              notificationAssignedUsers: [],
              count: 0,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
            })
          }
        })
    } else {
      this.setState({
        notificationAssignedUsers: [],
        count: 0,
        loading: false
      })
    }
  }

  redrawAssignedUsersTable = () => {
    this.getAssignedUsers()
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  deleteUser = user => {
    const userName = user.firstName + ' ' + user.lastName
    const userEmail = user.email
    const userTitle = userName + ' (' + userEmail + ')'

    this.setState({
      dialogOpen: true,
      dialogSelectedUserToDeleteTitle: userTitle,
      dialogSelectedUserToDeleteEmail: userEmail
    })
  }

  handleDeleteUserBulkClick = () => {
    const { intl } = this.props
    const { selectedUsers } = this.state

    const userTitle = intl.formatMessage(messages.unassignUsers, { number: selectedUsers.length })

    this.setState({
      dialogOpenBulk: true,
      dialogSelectedUserToDeleteBulkTitle: userTitle
    })
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedUserToDeleteTitle: '',
      dialogSelectedUserToDeleteEmail: '',
      dialogOpenBulk: false,
      dialogSelectedUserToDeleteBulkTitle: '',
      dialogOpenForSelectedAssignUser: false,
      dialogSelectedAssignUser: '',
      alertSuccessMessagesForAssignedUsersClass: 'hidden',
      successMessageForAssignedUsersTitle: '',
      successMessageForAssignedUsers: ''
    })
  }

  dialogForSelectedAssignUserActions = formatMessage => {
    return [
      <Button key='cancel-button' className='cancel-button' onClick={this.handleClose} style={{ marginRight: 10 }}>
        {formatMessage(messages.close)}
      </Button>
    ]
  }

  handleDelete = () => {
    const { dialogSelectedUserToDeleteEmail } = this.state

    const userToDelete = [dialogSelectedUserToDeleteEmail]
    this.deleteUsers(userToDelete)
  }

  handleBulkDelete = () => {
    const { selectedUsersEmails } = this.state

    const usersToDelete = selectedUsersEmails
    this.deleteUsers(usersToDelete)
  }

  deleteUsers = usersToUnassign => {
    const { intl, notification } = this.props
    const { notificationAssignedUsers } = this.state

    const notificationRuleInstanceHashId = notification.hashId
    const users = notificationAssignedUsers
      .filter(user => usersToUnassign.includes(user.email))
      .map(user => ({ email: user.email, userId: user.id }))

    client
      .desassignUsersToRuleInstance(notificationRuleInstanceHashId, users)
      .then(response => {
        if (response.data !== undefined) {
          this.setState(
            {
              dialogOpen: false,
              dialogSelectedUserToDeleteTitle: '',
              dialogSelectedUserToDeleteEmail: '',
              dialogOpenBulk: false,
              dialogSelectedUserToDeleteBulkTitle: '',
              selectedUsers: [],
              alertSuccessMessagesForAssignedUsersClass: '',
              successMessageForAssignedUsersTitle: '',
              successMessageForAssignedUsers: intl.formatMessage(messages.usersUnassignedCorrectly)
            },
            () => this.getAssignedUsers()
          )
        }
      })
      .catch(response => {
        const error = { ...response }
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 400 }),
              alertMessagesText: [intl.formatMessage(messages.error400Text)]
            })
            break
          case 401: // Invalid credentials
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 401 }),
              alertMessagesText: [error.response.message]
            })
            break
          case 403: // Access denied
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 403 }),
              alertMessagesText: [intl.formatMessage(messages.error403Text)]
            })
            break
          case 404: // API url not found
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 404 }),
              alertMessagesText: [intl.formatMessage(messages.error404Text)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 406 }),
              alertMessagesText: [intl.formatMessage(messages.error406Text)]
            })
            break
          case 409: // Data integrity violation
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 409 }),
              alertMessagesText: [intl.formatMessage(messages.error409Text)]
            })
            break
          case 415: // Unsupported media type
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 415 }),
              alertMessagesText: [intl.formatMessage(messages.error415Text)]
            })
            break
          case 422: // Validation failed
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 422 }),
              alertMessagesText: [intl.formatMessage(messages.error422Text)]
            })
            break
          case 500: // Server errors
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.error, { number: 500 }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: intl.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [intl.formatMessage(messages.errorUndefinedText)]
            })
            logError(response)
        }
      })
  }

  handleClearSelectedUsersClick = () => {
    this.setState({
      selectedUsers: [],
      selectedUsersEmails: []
    })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      {
        page,
        length: sizePerPage,
        notificationAssignedUsers: []
      },
      () => {
        this.getAssignedUsers()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        notificationAssignedUsers: []
      },
      () => this.getAssignedUsers()
    )
  }

  onRowSelect = (row, isSelected) => {
    const { selectedUsers, selectedUsersEmails } = this.state

    const element = row
    const elementId = row.id
    const newSelectedUsers = selectedUsers.map(userId => userId)
    const newSelectedUsersEmails = selectedUsersEmails.map(userEmail => userEmail)
    const indexOfUser = selectedUsers.indexOf(elementId)

    if (isSelected) {
      if (indexOfUser < 0) {
        newSelectedUsers.push(elementId)
        newSelectedUsersEmails.push(element.email)
      }
    } else {
      if (indexOfUser > -1) {
        newSelectedUsers.splice(indexOfUser, 1)
        newSelectedUsersEmails.splice(indexOfUser, 1)
      }
    }

    this.setState({
      selectedUsers: newSelectedUsers,
      selectedUsersEmails: newSelectedUsersEmails
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedUsers, selectedUsersEmails } = this.state

    let indexOfUser
    const newSelectedUsers = selectedUsers.map(userId => userId)
    const newSelectedUsersEmails = selectedUsersEmails.map(userEmail => userEmail)
    const usersToProcess = rows.map(user => {
      return user
    })

    usersToProcess.map(user => {
      indexOfUser = newSelectedUsers.indexOf(user.id)

      if (isSelected) {
        if (indexOfUser < 0) {
          newSelectedUsers.push(user.id)
          newSelectedUsersEmails.push(user.email)
        }
      } else {
        if (indexOfUser > -1) {
          newSelectedUsers.splice(indexOfUser, 1)
          newSelectedUsersEmails.splice(indexOfUser, 1)
        }
      }

      return user
    })

    this.setState({
      selectedUsers: newSelectedUsers,
      selectedUsersEmails: newSelectedUsersEmails
    })
  }

  formatSmsService = cell => {
    return <SmsServiceStateChip state={cell} />
  }

  formatNotifyByBell = cell => {
    const typeOfNotification = 1
    return this.handleNotifyBy(cell, typeOfNotification)
  }

  formatNotifyByEmail = cell => {
    const typeOfNotification = 2
    return this.handleNotifyBy(cell, typeOfNotification)
  }

  formatNotifyBySms = cell => {
    const typeOfNotification = 4
    return this.handleNotifyBy(cell, typeOfNotification)
  }

  handleNotifyBy = (notificationAction, typeOfNotification) => {
    const { intl } = this.props

    let notify = 'EnabledNo'

    if (notificationAction !== undefined) {
      const numActions = notificationAction.length
      if (numActions > 0) {
        notificationAction.map(theNotificationAction => {
          if (typeOfNotification === 1) {
            if (theNotificationAction.name === 'LIVE_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          } else if (typeOfNotification === 2) {
            if (theNotificationAction.name === 'EMAIL_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          } else if (typeOfNotification === 3) {
            if (theNotificationAction.name === 'PUSH_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          } else if (typeOfNotification === 4) {
            if (theNotificationAction.name === 'SMS_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          }
          return theNotificationAction
        })
      }
    }

    return <DataChip chipText={notify} inline intl={intl} />
  }

  formatActionsDetail = (cell, row) => {
    const { intl } = this.props
    const { assignedUsersGroupActions } = this.state

    return (
      <div>
        <Button
          onClick={() => {
            this.deleteUser(row)
          }}
          style={{ top: '-10px' }}
          title={intl.formatMessage(messages.unassignUser)}
        >
          <Icon className='zmdi zmdi-close-circle-o' style={{ color: '#C00000' }} />
        </Button>
        <DropDownMenu
          className='privilegesDropdown'
          labelStyle={{
            height: '100%',
            lineHeight: 'initial',
            padding: '2px'
          }}
          onChange={this.handleSingleAssignedUserChange}
          onClick={() => this.handleSelectedAssignedUser(row.email)}
          style={{ height: '28px' }}
          targetOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          title={intl.formatMessage(messages.groupActions)}
          value={null}
        >
          <MenuItem
            label={<Icon className='zmdi zmdi-notifications' />}
            primaryText={intl.formatMessage(messages.selectAction)}
            value={null}
          />
          {showActionMenuItems(assignedUsersGroupActions)}
        </DropDownMenu>
      </div>
    )
  }

  handleSelectedAssignedUser = assignedUserEmail => {
    this.setState({
      dialogSelectedAssignUser: assignedUserEmail
    })
  }

  handleMultipleAssignedUsersChange = value => {
    const { notification } = this.props
    const { notificationAssignedUsers, selectedUsersEmails } = this.state

    if (value !== null) {
      const users = notificationAssignedUsers
        .filter(user => selectedUsersEmails.includes(user.email))
        .map(user => ({ email: user.email, userId: user.id }))

      const ruleInstanceHashId = notification.hashId
      this.setState({
        dialogOpenForSelectedAssignUser: true,
        dialogSelectedAssignUser: '',
        dialogSelectedComponent: value.component(value, users, ruleInstanceHashId, this.redrawAssignedUsersTable)
      })
    }
  }

  handleSingleAssignedUserChange = (event, index, value) => {
    const { notification } = this.props
    const { notificationAssignedUsers, dialogSelectedAssignUser } = this.state

    if (value !== null) {
      const users = notificationAssignedUsers
        .filter(user => user.email === dialogSelectedAssignUser)
        .map(user => ({ email: user.email, userId: user.id }))

      const ruleInstanceHashId = notification.hashId
      this.setState({
        dialogOpenForSelectedAssignUser: true,
        dialogSelectedAssignUser: '',
        dialogSelectedComponent: value.component(value, users, ruleInstanceHashId, this.redrawAssignedUsersTable)
      })
    }
  }

  handleCloseAlertForMessageForAssignedUsersResultClick = () => {
    this.setState({
      alertSuccessMessagesForAssignedUsersClass: 'hidden',
      successMessageForAssignedUsersTitle: '',
      successMessageForAssignedUsers: ''
    })
  }

  handleGroupActionsMenuButtonClick = event => {
    this.setState({ groupActionsButtonAnchorElement: event.currentTarget })
  }

  handleGroupActionsMenuClose = () => {
    this.setState({ groupActionsButtonAnchorElement: null })
  }

  renderTableLoadingAndError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, loading } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { alertMessages, loading } = this.state

    if (loading || alertMessages) {
      return <div>{this.renderTableLoadingAndError()}</div>
    } else {
      return <div>{this.renderAssignedUsersContent()}</div>
    }
  }

  renderGroupActionsMenuItems = () => {
    const { assignedUsersGroupActions } = this.state

    if (assignedUsersGroupActions !== undefined) {
      return assignedUsersGroupActions.map(action => (
        <MenuItem
          key={'actionFor' + action.hashId}
          onClick={() => {
            this.handleMultipleAssignedUsersChange(action)
            this.handleGroupActionsMenuClose()
          }}
          primaryText={action.description}
          value={action}
        />
      ))
    } else {
      return <div />
    }
  }

  renderAssignedUsersContent = () => {
    const { canReadUsers, intl } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      alertSuccessMessagesForAssignedUsersClass,
      count,
      dialogOpen,
      dialogOpenBulk,
      dialogOpenForSelectedAssignUser,
      dialogSelectedComponent,
      dialogSelectedUserToDeleteBulkTitle,
      dialogSelectedUserToDeleteTitle,
      elementsPerPage,
      groupActionsButtonAnchorElement,

      length,
      notificationAssignedUsers,
      notificationAssignUsersUrl,
      page,
      selectedUsers,
      successMessageForAssignedUsers,
      successMessageForAssignedUsersTitle
    } = this.state

    const renderPaginationShowsTotal = formatMessage => (start, to, total) =>
      (
        <span>
          {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
            messages.of
          )} ${total}`}
        </span>
      )

    const tableOptions = {
      // No data
      noDataText: intl.formatMessage(messages.noUsersAssigned),

      // Page size select
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: elementsPerPage, // you can change the dropdown list for size per page
      sizePerPage: length, // which size per page you want to locate as default
      page, // which page you want to show as default

      // Pagination
      onPageChange: this.onPageChange,
      ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      prePage: intl.formatMessage(messages.prePage), // Previous page button text
      nextPage: intl.formatMessage(messages.nextPage), // Next page button text
      firstPage: intl.formatMessage(messages.firstPage), // First page button text
      lastPage: intl.formatMessage(messages.lastPage), // Last page button text
      paginationShowsTotal: renderPaginationShowsTotal(intl.formatMessage), // Accept bool or function
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      hideSizePerPage: true, // You can hide the dropdown for sizePerPage
      alwaysShowAllBtns: false, // Always show next and previous button
      withFirstAndLast: true // Hide the going to First and Last page button
    }

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedUsers
    }

    return (
      <div>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
        <div className='row'>
          <div className='col-sm-8'>
            <h2>{intl.formatMessage(messages.assignedUsers)}</h2>
          </div>
          {canReadUsers && (
            <div className='col-sm-4 text-right'>
              <Link className='button-link' to={notificationAssignUsersUrl}>
                <Button className='primary-action-button' style={{ marginTop: 15 }}>
                  <Icon className='zmdi zmdi-plus' />
                  {intl.formatMessage(messages.assignUsers)}
                </Button>
              </Link>
            </div>
          )}
        </div>

        <div className={alertSuccessMessagesForAssignedUsersClass}>
          <div className='alert alert-success alert-dismissible animated fadeIn'>
            <button
              aria-label='Close'
              className='close'
              onClick={this.handleCloseAlertForMessageForAssignedUsersResultClick}
            >
              <span aria-hidden='true'>x</span>
            </button>
            <h4>{successMessageForAssignedUsersTitle}</h4>
            <p className='h5'>{successMessageForAssignedUsers}</p>
          </div>
        </div>

        <Paper>
          <div className='container-fluid'>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <Button
                    disabled
                    style={{
                      color: 'color: rgba(0, 0, 0, 0.87)',
                      marginTop: 14,
                      marginLeft: 5
                    }}
                  >
                    {intl.formatMessage(messages.selectedUsers)} ({selectedUsers.length})
                  </Button>
                  <Button
                    className='secondary-action-button'
                    disabled={selectedUsers.length === 0}
                    onClick={this.handleClearSelectedUsersClick}
                    style={{
                      marginTop: 14,
                      marginLeft: 5
                    }}
                  >
                    {intl.formatMessage(messages.clearSelection)}
                  </Button>
                </Grid>
                <Grid container item justify='flex-end' xs={8}>
                  <Grid item>
                    <Button
                      className='delete-button'
                      disabled={selectedUsers.length === 0}
                      onClick={this.handleDeleteUserBulkClick}
                      style={{ marginTop: 14, marginLeft: 5 }}
                    >
                      <Icon className='zmdi zmdi-close-circle-o' />
                      &nbsp;{intl.formatMessage(messages.unassign)}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className='primary-action-button'
                      disabled={selectedUsers.length === 0}
                      onClick={this.handleGroupActionsMenuButtonClick}
                      style={{ marginTop: 14, marginLeft: 5 }}
                    >
                      {intl.formatMessage(messages.groupActions)}
                      <Icon className='zmdi zmdi-caret-down' style={{ marginLeft: 6, marginRight: 0 }} />
                    </Button>

                    <Menu
                      anchorEl={groupActionsButtonAnchorElement}
                      keepMounted
                      onClose={this.handleGroupActionsMenuClose}
                      open={Boolean(groupActionsButtonAnchorElement)}
                    >
                      {this.renderGroupActionsMenuItems()}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              data={notificationAssignedUsers}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={remote => ({
                ...remote,
                search: false,
                pagination: true,
                sizePerPage: true,
                sort: false,
                filter: false
              })}
              search={false}
              searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
              selectRow={selectRowProp}
              striped={false}
            >
              <TableHeaderColumn dataField='id' hidden isKey width='0'>
                id
              </TableHeaderColumn>
              <TableHeaderColumn dataField='firstName' row='0' rowSpan='2' width='90'>
                {intl.formatMessage(messages.firstName)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='lastName' row='0' rowSpan='2' width='100'>
                {intl.formatMessage(messages.lastName)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='email' row='0' rowSpan='2' width='100'>
                {intl.formatMessage(messages.email)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='smsService'
                dataFormat={this.formatSmsService}
                row='0'
                rowSpan='2'
                width='100'
              >
                {intl.formatMessage(messages.smsService)}
              </TableHeaderColumn>
              <TableHeaderColumn colSpan='3' headerAlign='center' row='0' width='90'>
                {intl.formatMessage(messages.notifyBy)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='notificationActions'
                dataFormat={this.formatNotifyByBell}
                row='1'
                width='50'
              >
                {intl.formatMessage(messages.bell)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='notificationActions'
                dataFormat={this.formatNotifyByEmail}
                row='1'
                width='50'
              >
                {intl.formatMessage(messages.email)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='notificationActions' dataFormat={this.formatNotifyBySms} row='1' width='50'>
                {intl.formatMessage(messages.sms)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataAlign='center'
                dataField='id'
                dataFormat={this.formatActionsDetail}
                headerAlign='center'
                row='0'
                rowSpan='2'
                width='80'
              >
                {intl.formatMessage(messages.actions)}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </Paper>

        <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpen}>
          <DialogTitle>{intl.formatMessage(messages.confirmUserUnassignament)}</DialogTitle>
          <DialogContent>{dialogSelectedUserToDeleteTitle}</DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleClose} style={{ marginRight: 10 }}>
              {intl.formatMessage(messages.cancel)}
            </Button>
            <Button className='delete-button' onClick={this.handleDelete}>
              {intl.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpenBulk}>
          <DialogTitle>{intl.formatMessage(messages.confirmUserUnassignament)}</DialogTitle>
          <DialogContent>{dialogSelectedUserToDeleteBulkTitle}</DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleClose} style={{ marginRight: 10 }}>
              {intl.formatMessage(messages.cancel)}
            </Button>
            <Button className='delete-button' onClick={this.handleBulkDelete}>
              {intl.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpenForSelectedAssignUser}>
          {dialogSelectedComponent}
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleClose} style={{ marginRight: 10 }}>
              {intl.formatMessage(messages.close)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  render() {
    return (
      <div className='container-fluid' style={{ margin: '20px' }}>
        <NotificationsRulesSettingsStepper stepIndex={2} />
        {this.renderTablecontent()}
      </div>
    )
  }
}

EditNotificationFormAssignUsers.propTypes = {
  canReadUsers: PropTypes.bool.isRequired,
  getUsersOfGroupDetail: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  notificationAssignUsersUrl: PropTypes.string
}

EditNotificationFormAssignUsers.defaultProps = {
  notificationAssignUsersUrl: ''
}

export default withRouter(injectIntl(EditNotificationFormAssignUsers))
