import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'

import messages from '../messages'

const ConsentRequestStep = props => {
  const { intl } = props
  return (
    <Grid container spacing={10}>
      <Grid item style={{ borderRight: '1px solid gray', padding: '0 40px' }} xs={7}>
        {intl.formatMessage(messages.legalText)}
      </Grid>
      <Grid item style={{ padding: '0 40px' }} xs={5}>
        <p>
          <b>{intl.formatMessage(messages.smsService)}</b>
        </p>
        <p>{intl.formatMessage(messages.enableSmsService)}</p>
        <p>{intl.formatMessage(messages.messageFrequencyVaries)}</p>
      </Grid>
    </Grid>
  )
}

ConsentRequestStep.propTypes = {
  intl: PropTypes.object.isRequired
}

export default injectIntl(ConsentRequestStep)
