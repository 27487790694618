import React from 'react'
import MenuItem from 'material-ui/MenuItem'

const composeLocalQuery = (page, pageLength) => {
  return '?page=' + (page - 1) + '&size=' + pageLength
}

const showActionMenuItems = actions => {
  if (actions !== undefined) {
    return actions.map(action => (
      <MenuItem key={'actionFor' + action.hashId} primaryText={action.description} value={action} />
    ))
  } else {
    return <div />
  }
}

export { composeLocalQuery, showActionMenuItems }
