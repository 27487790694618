import React from 'react'
import { injectIntl } from 'react-intl'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'

import messages from './messages'

const NextPageOption = injectIntl(({ intl, loading, ...other }) => {
  const { formatMessage } = intl
  return (
    <IconButton disabled={loading} style={{ padding: 5 }} {...other} title={formatMessage(messages.showNextPage)}>
      <ChevronRightIcon style={{ fontSize: 35 }} />
    </IconButton>
  )
})

export { NextPageOption }
