import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { actions } from 'ducks/configurationsCS500'

import MachineStateDetermination from './MachineStateDetermination'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLocalConfigurationField: actions.changeLocalConfigurationField,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(MachineStateDetermination)