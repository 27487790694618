import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getDinamicData, getStaticData } from 'ducks/dashboards'
import { actions as websocket, utils } from 'ducks/websocket'

import Image from './Image.jsx'
import ImageSettingsUnconnected from './ImageSettings'
import ImageTemplate from './ImageTemplate'

import csNode from 'modules/csNode'

const mapStateToProps = (state, { match }) => {
  return {
    dinamicData: getDinamicData(state),
    staticData: getStaticData(state),
    eid: getStaticData(state)[1]?.value || '',
    nodeCredentials: csNode.utils.getRealTimeCredentials(state)[match.params.nodeId],
    isSubscribedTo: (eid, topic) => utils.isSubscribedTo(state, eid, topic)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    subscribeWidgetToWS: (topics, eid, credentials) =>
      dispatch(websocket.subscribeRealTimeSignal(topics, eid, credentials)),
    unsubscribeWidgetFromWS: (topic, eid) => dispatch(websocket.unsubscribe(topic, eid))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Image))

const ImageSettings = withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageSettingsUnconnected))
export { ImageSettings, ImageTemplate }
