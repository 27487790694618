import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loading = () => (
  <div className="text-center">
    <CircularProgress size={60} />
  </div>
)

export default Loading
