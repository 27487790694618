import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import ControlPointDuplicateOutlinedIcon from '@material-ui/icons/ControlPointDuplicateOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Loading from 'components/Loading'
import Card from 'components/UnboxedCard'

import * as ReportConstants from '../../constants'
import Metrics from './Metrics'
import { createMetric } from './config'
import messages from './messages'

const styles = theme => ({
  addDevice: {
    alignItems: 'center',
    borderBottom: '2px solid transparent',
    color: '#32A0E9',
    cursor: 'pointer',
    display: 'inline-flex',
    fontWeight: '500',
    marginTop: '15px',
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    '&:hover': {
      borderBottom: '2px solid #1377b9',
      color: '#1377b9'
    }
  },
  button: {
    margin: 0
  },
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
})

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: true,
      isAllDevicesChecked:
        props.metrics[0].asset === ReportConstants.ALL_DEVICES_IN_GROUP &&
        props.metrics[0].pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE &&
        props.metrics[0].tableType === ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  getPageTypeValue = () => {
    const { metrics } = this.props

    const validPageTypes = Object.values(ReportConstants.PAGE_TYPES)
    const sensorsIncludeGpsMap = metrics.some(metric =>
      metric.selectedSensorsIds.some(sensor =>
        ReportConstants.GPS_SENSOR_IDS_PER_PAGE_TYPE[ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE].includes(sensor)
      )
    )
    const metricsPageType =
      metrics?.[0]?.pageType || (sensorsIncludeGpsMap ? ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE : '')

    return validPageTypes.includes(metricsPageType) ? metricsPageType : ReportConstants.PAGE_TYPES.CHART_PAGE_TYPE
  }

  getTableTypeValue = () => {
    const { metrics } = this.props

    const validTableTypes = Object.values(ReportConstants.TABLE_TYPES)
    const metricsTableType = metrics?.[0]?.tableType

    return validTableTypes.includes(metricsTableType)
      ? metricsTableType
      : ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
  }

  handleAllDevicesCheckboxChange = event => {
    const { metrics, pageIndex, onPagesUpdate } = this.props
    this.setState(
      {
        isAllDevicesChecked: event.target.checked
      },
      () => {
        const newMetric = createMetric({
          pageType: metrics[0].pageType,
          tableType: metrics[0]?.tableType
        })
        if (event.target.checked) {
          newMetric.asset = ReportConstants.ALL_DEVICES_IN_GROUP
        } else {
          newMetric.asset = ''
        }
        onPagesUpdate([newMetric], pageIndex)
      }
    )
  }

  handleMetricsUpdate = newMetrics => {
    const { pageIndex, onPagesUpdate } = this.props
    onPagesUpdate(newMetrics, pageIndex)
  }

  handleAddDeviceClick = () => {
    const { metrics } = this.props
    const newMetrics = [...metrics, createMetric()]
    this.handleMetricsUpdate(newMetrics)
  }

  handlePageTypeChange = event => {
    const pageType = event.target.value
    const newMetrics = [createMetric()].map(metric => {
      return {
        ...metric,
        pageType,
        tableType:
          pageType === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE
            ? ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE
            : undefined
      }
    })

    this.handleMetricsUpdate(newMetrics)
    this.setState({ isAllDevicesChecked: false })
  }

  handleTableTypeChange = event => {
    const { metrics } = this.props

    const tableType = event.target.value
    const newMetrics = metrics.map(metric => {
      return {
        ...metric,
        tableType
      }
    })
    this.handleMetricsUpdate(newMetrics)
  }

  handleDuplicatePageClick = () => {
    const { pageIndex, onDuplicatePage } = this.props
    onDuplicatePage(pageIndex)
  }

  handleDeletePageClick = () => {
    const { pageIndex, onDeletePage, onlyOne } = this.props
    if (!onlyOne) {
      onDeletePage(pageIndex)
    }
  }

  handleToggleExpandPageClick = () => {
    const { isExpanded } = this.state
    this.setState({ isExpanded: !isExpanded })
  }

  render() {
    const { assets, classes, pageIndex, loading, metrics, onlyOne } = this.props
    const { isExpanded, isAllDevicesChecked } = this.state

    const pageTypeValue = this.getPageTypeValue()
    const tableTypeValue = this.getTableTypeValue()

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Card>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CardHeader
                    action={
                      <React.Fragment>
                        <div className='col-md-12' style={{ float: 'none' }}>
                          <FormControl style={{ marginRight: '12px', width: '150px' }}>
                            <InputLabel shrink>{this.formatMessage(messages.pageType)}</InputLabel>
                            <Select onChange={this.handlePageTypeChange} value={pageTypeValue}>
                              <MenuItem value={ReportConstants.PAGE_TYPES.CHART_PAGE_TYPE}>
                                {this.formatMessage(messages.chartPage)}
                              </MenuItem>
                              <MenuItem value={ReportConstants.PAGE_TYPES.MAP_PAGE_TYPE}>
                                {this.formatMessage(messages.mapPage)}
                              </MenuItem>
                              {process.env.REACT_APP_REPORTS_TABLE_PAGE_ENABLED && (
                                <MenuItem value={ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE}>
                                  {this.formatMessage(messages.tablePage)}
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            placement='top'
                            title={this.formatMessage(messages.duplicatePage)}
                          >
                            <IconButton classes={{ root: classes.button }} onClick={this.handleDuplicatePageClick}>
                              <ControlPointDuplicateOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            placement='top'
                            title={this.formatMessage(messages.deletePage)}
                          >
                            <div style={{ display: 'inline-block' }}>
                              <IconButton
                                classes={{ root: classes.button }}
                                disabled={onlyOne}
                                onClick={this.handleDeletePageClick}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </Tooltip>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            placement='top'
                            title={
                              isExpanded ? this.formatMessage(messages.collapse) : this.formatMessage(messages.expand)
                            }
                          >
                            <IconButton
                              classes={{ root: classes.button }}
                              onClick={this.handleToggleExpandPageClick}
                              style={{ marginLeft: '36px' }}
                            >
                              {isExpanded ? (
                                <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} />
                              ) : (
                                <ExpandMoreIcon className={classes.expand} />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div
                          className='col-md-12'
                          style={{ marginTop: '12px', marginRight: '12px', width: '150px', float: 'none' }}
                        >
                          {pageTypeValue === ReportConstants.PAGE_TYPES.TABLE_PAGE_TYPE && (
                            <FormControl style={{ marginRight: '12px', width: '150px' }}>
                              <InputLabel shrink>{this.formatMessage(messages.tableType)}</InputLabel>
                              <Select onChange={this.handleTableTypeChange} value={tableTypeValue}>
                                <MenuItem value={ReportConstants.TABLE_TYPES.DM1_LAST_KNOWN_VALUES_TYPE}>
                                  {this.formatMessage(messages.dm1LastKnownValuesTable)}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </div>
                      </React.Fragment>
                    }
                    style={{ margin: '12px 0px', padding: '8px' }}
                    title={this.formatMessage(messages.page) + ' ' + (pageIndex + 1)}
                  />
                </Grid>
              </Grid>
              <Collapse in={isExpanded}>
                <CardContent>
                  {loading ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <Metrics
                        assets={assets}
                        isAllDevicesChecked={isAllDevicesChecked}
                        metrics={metrics}
                        onAllDevicesCheckboxChange={this.handleAllDevicesCheckboxChange}
                        onMetricsUpdate={this.handleMetricsUpdate}
                        pageType={pageTypeValue}
                        tableType={tableTypeValue}
                      />
                      {!metrics.some(metric => metric.asset === ReportConstants.ALL_DEVICES_IN_GROUP) && (
                        <span className={classes.addDevice} onClick={this.handleAddDeviceClick}>
                          <Icon className='zmdi zmdi-plus' style={{ fontSize: '14px' }} />
                          {this.formatMessage(messages.addMachine)}
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

Page.propTypes = {
  assets: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  loading: PropTypes.bool.isRequired,
  metrics: PropTypes.array.isRequired,
  onDeletePage: PropTypes.func.isRequired,
  onDuplicatePage: PropTypes.func.isRequired,
  onPagesUpdate: PropTypes.func.isRequired,
  onlyOne: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired
}

export default injectIntl(withStyles(styles)(Page))
