import { createSelector } from 'reselect'

import * as c from './constants'
import * as t from './actionTypes'

const initState = {
  collapsed: localStorage.getItem(c.SIDEBAR_MENU_COLLAPSED) === 'true' || false
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case t.TOGGLE_COLLAPSE:
      const collapsed = !state.collapsed
      localStorage.setItem(c.SIDEBAR_MENU_COLLAPSED, collapsed)
      return { ...state, collapsed }

    default:
      return state
  }
}

export default reducer

// BEGIN: selectors
const getSelector = state => state[c.NAME]
const isCollapsed = createSelector(getSelector, sidebar => sidebar.collapsed)
// END: selectors

export { isCollapsed }
