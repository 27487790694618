import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class ValueSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      staticData: props.data.data !== '' ? props.data.data : '',
      size: props.data.size ? props.data.size : 14,
      bold: props.data.bold ? props.data.bold : 'bold',
      align: props.data.align ? props.data.align : 'left'
    }
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    const error = false

    if (!error) {
      const data = {
        data: this.state.staticData,
        size: this.state.size,
        bold: this.state.bold,
        align: this.state.align
      }

      this.props.saveSettings(data)
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleStaticChange = event => {
    this.setState({
      staticData: event.target.value
    })
  }

  render() {
    const ignorableFields = ['hasConfiguration']
    const filteredStaticData = this.props.staticData.filter(({ name }) => !ignorableFields.includes(name))

    return (
      <Dialog
        open={true}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.closeSettings}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.valueWidget)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.closeSettings}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.valueWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <FormControl style={{ width: '100%' }} error={this.state.valueError}>
                <InputLabel shrink={true} htmlFor="staticData-label-placeholder">
                  {this.formatMessage(messages.values)}
                </InputLabel>
                <Select
                  value={this.state.staticData}
                  onChange={this.handleStaticChange}
                  input={<Input name="staticData" id="staticData-label-placeholder" />}
                  displayEmpty={true}
                  name="staticData"
                >
                  <MenuItem key="staticDataDefault" value="">
                    {this.formatMessage(messages.selectValueToDisplay)}
                  </MenuItem>
                  {filteredStaticData.map(eachStaticData => {
                    return (
                      <MenuItem key={eachStaticData.name} value={eachStaticData.name}>
                        {eachStaticData.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherValueWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="size-label-placeholder">
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  value={this.state.size}
                  onChange={this.handleChange('size')}
                  input={<Input name="size" id="size-label-placeholder" />}
                  displayEmpty={true}
                  name="size"
                  autoWidth={true}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="bold-label">
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  value={this.state.bold}
                  onChange={this.handleChange('bold')}
                  input={<Input name="weight" id="weight-label-placeholder" />}
                  displayEmpty={true}
                  name="weight"
                  autoWidth={false}
                >
                  <MenuItem value="bold">{this.formatMessage(messages.bold)}</MenuItem>
                  <MenuItem value="normal">{this.formatMessage(messages.normal)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor="align-label">
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  value={this.state.align}
                  onChange={this.handleChange('align')}
                  input={<Input name="align" id="align-label-placeholder" />}
                  displayEmpty={true}
                  name="align"
                  autoWidth={false}
                >
                  <MenuItem value="left">{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value="center">{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value="right">{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button className="cancel-button" onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ValueSettings.propTypes = {
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  staticData: PropTypes.array.isRequired
}

export default injectIntl(ValueSettings)
