import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import { getEditReportUrl } from '../../url'

import { container, action } from './style.module.css'

import { injectIntl } from 'react-intl'

class ActionsField extends React.Component {
  constructor(props) {
    super(props)
  }

  handleClick = event => {
    const { openDeleteReportDialog, report } = this.props
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    openDeleteReportDialog(report)
  }

  render() {
    const { report, canEditReports, canDeleteReports } = this.props

    const actions = []

    if (canEditReports) {
      actions.push(
        <Link key='canEditDetail' className={'button-link ' + action} to={getEditReportUrl(report.id)}>
          <IconButton style={{ padding: '5px' }}>
            <EditIcon />
          </IconButton>
        </Link>
      )
    }

    if (canDeleteReports) {
      actions.push(
        <IconButton key='deleteIcon' onClick={this.handleClick} style={{ padding: '5px' }}>
          <DeleteIcon />
        </IconButton>
      )
    }

    return <div className={container}>{actions}</div>
  }
}

ActionsField.propTypes = {
  canDeleteReports: PropTypes.bool.isRequired,
  canEditReports: PropTypes.bool.isRequired,
  openDeleteReportDialog: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired
}

export default injectIntl(ActionsField)
