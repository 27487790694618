import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class WeatherSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      days: props.data.days ? props.data.days : 0,
      daysErrorText: '',
      unit: props.data.unit ? props.data.unit : '',
      unitErrorText: ''
    }
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.days === 0) {
      error = true
      this.setState({
        daysErrorText: this.formatMessage(messages.youMustSelectForecastDays)
      })
    }

    if (this.state.unit === '') {
      error = true
      this.setState({
        unitErrorText: this.formatMessage(messages.youMustSelectUnit)
      })
    }

    if (!error) {
      const data = {
        days: this.state.days,
        unit: this.state.unit
      }

      this.props.saveSettings(data)
    }
  }

  handleChange = event => {
    const name = event.target.name
    this.setState({
      [name]: event.target.value,
      [name + 'ErrorText']: ''
    })
  }

  render() {
    const arrayOfForecastDays = [3, 5]
    const units = ['Celsius', 'Fahrenheit']
    return (
      <Dialog
        open={true}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.closeSettings}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.weatherWidget)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.closeSettings}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.weatherWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={6}>
              <FormControl fullWidth={true} required={true} error={this.state.daysErrorText !== ''}>
                <InputLabel shrink={true} htmlFor="days-label-placeholder">
                  {this.formatMessage(messages.days)}
                </InputLabel>
                <Select
                  value={this.state.days}
                  onChange={this.handleChange}
                  input={<Input name="days" id="days-label-placeholder" />}
                  displayEmpty={true}
                  name="days"
                >
                  <MenuItem key={0} value={0}>
                    {this.formatMessage(messages.selectForecastDays)}
                  </MenuItem>
                  {arrayOfForecastDays.map((forecastDays, index) => {
                    return (
                      <MenuItem key={index + 1} value={forecastDays}>
                        {forecastDays}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.daysErrorText}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <FormControl fullWidth={true} required={true} error={this.state.unitErrorText !== ''}>
                <InputLabel shrink={true} htmlFor="unit-label-placeholder">
                  {this.formatMessage(messages.unit)}
                </InputLabel>
                <Select
                  value={this.state.unit}
                  onChange={this.handleChange}
                  input={<Input name="unit" id="unit-label-placeholder" />}
                  displayEmpty={true}
                  name="unit"
                >
                  <MenuItem key={0} value="">
                    {this.formatMessage(messages.selectTemperaturetUnit)}
                  </MenuItem>
                  {units.map((unit, index) => {
                    return (
                      <MenuItem key={index + 1} value={unit}>
                        {unit}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{this.state.unitErrorText}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

WeatherSettings.propTypes = {
  closeSettings: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired
}

export default injectIntl(WeatherSettings)
