export const metricValueTypes = [
  { value: 'last', label: 'Last value' },
  { value: 'avg', label: 'Average' },
  { value: 'min', label: 'Minimum' },
  { value: 'max', label: 'Maximum' }
]

export const metricAggregations = [
  { value: 'none', label: 'None' },
  { value: 'avg', label: 'Average' },
  { value: 'min', label: 'Minimum' },
  { value: 'max', label: 'Maximum' }
]

export const metricBucket = [
  { value: 'raw', label: '---' },
  { value: 'hour', label: 'Hourly' },
  { value: 'day', label: 'Daily' },
  { value: 'month', label: 'Monthly' }
]

export const metricLineStyles = [
  { value: 'line', label: 'Line' },
  { value: 'column', label: 'Column' },
  { value: 'point', label: 'Point' }
]
