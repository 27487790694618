import deepClone from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import SelectMachine from 'modules/groupDashboards/Modals/SelectMachine'
import * as availableWidgets from 'modules/groupDashboards/Widgets'

import messages from './messages'
import genericMessages from '../messages'

const styles = {
  disabled: {},
  titleMenuItem: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '1.6rem'
  },
  titleListItem: {
    '&$disabled': {
      opacity: 1
    }
  },
  widgetMenuItem: {
    paddingLeft: 26
  }
}

class AddWidget extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage

    this.state = {
      type: 'text',
      device: '',
      configurationLoading: false
    }
  }

  socketDependantWidgets = widgetType => {
    const widgets = ['box', 'text', 'image', 'realtimevalue', 'gauge', 'speedometer', 'columnchart', 'linechart']

    return widgets.includes(widgetType)
  }

  deviceDependantWidgets = widgetType => {
    const widgets = ['value', 'weather', 'map', 'historic']

    return widgets.includes(widgetType)
  }

  handleSaveAddWidget = () => {
    const { type, device } = this.state
    let widgetTemplate

    switch (type) {
      case 'box':
        widgetTemplate = deepClone(availableWidgets.BoxTemplate)
        break
      case 'text':
        widgetTemplate = deepClone(availableWidgets.TextTemplate)
        break
      case 'image':
        widgetTemplate = deepClone(availableWidgets.ImageTemplate)
        break
      case 'realtimevalue':
        widgetTemplate = deepClone(availableWidgets.RealTimeValueTemplate)
        break
      case 'value':
        widgetTemplate = deepClone(availableWidgets.ValueTemplate)
        break
      case 'gauge':
        widgetTemplate = deepClone(availableWidgets.GaugeTemplate)
        break
      case 'columnchart':
        widgetTemplate = deepClone(availableWidgets.ColumnChartTemplate)
        break
      case 'linechart':
        widgetTemplate = deepClone(availableWidgets.LineChartTemplate)
        break
      case 'line':
        widgetTemplate = deepClone(availableWidgets.LineTemplate)
        break
      case 'link':
        widgetTemplate = deepClone(availableWidgets.LinkTemplate)
        break
      case 'historic':
        widgetTemplate = deepClone(availableWidgets.HistoricTemplate)
        break
      case 'map':
        widgetTemplate = deepClone(availableWidgets.MapTemplate)
        break
      case 'video':
        widgetTemplate = deepClone(availableWidgets.VideoTemplate)
        break
      case 'weather':
        widgetTemplate = deepClone(availableWidgets.WeatherTemplate)
        break
      case 'embeddedweb':
        widgetTemplate = deepClone(availableWidgets.EmbeddedWebTemplate)
        break
      case 'table':
        widgetTemplate = deepClone(availableWidgets.TableTemplate)
        break
      default:
        widgetTemplate = null
    }

    const { addWidget, closeAddWidgetModal } = this.props
    const isDependantWidget = this.socketDependantWidgets(type) || this.deviceDependantWidgets(type)

    let deviceInfo = ''
    if (isDependantWidget) {
      widgetTemplate.deviceEids = [device.EID]
      deviceInfo = device
    }
    addWidget(widgetTemplate, deviceInfo)
    closeAddWidgetModal()
    this.setState({
      type: 'text',
      device: '',
      configurationLoading: false
    })
  }

  handleWidgetTypeChange = event => {
    this.setState({
      type: event.target.value,
      device: '',
      configurationLoading: false
    })
  }

  changeSelectedDevice = device => {
    this.setState({
      device,
      configurationLoading: ['CS100', 'CS500'].includes(device.deviceType)
    })
  }

  updateDeviceConfiguration = configuration => {
    this.setState(({ device }) => ({
      device: { ...device, deviceConfiguration: configuration },
      configurationLoading: false
    }))
  }

  renderSelectedDevice = () => {
    const {
      device: { EID = '', name, description }
    } = this.state
    return (
      <Grid item xs={12}>
        <InputLabel htmlFor='device-label-placeholder' shrink>
          {this.formatMessage(messages.selectedMachine) + ':'}
        </InputLabel>
        {EID.length === 0 ? (
          <span style={{ color: 'red' }}>{this.formatMessage(messages.selectMachineFromTable)}</span>
        ) : (
          <span style={{ color: 'black' }}>
            <strong>{name}</strong>: {description}
          </span>
        )}
      </Grid>
    )
  }

  renderConfigurationStatus = () => {
    const {
      configurationLoading,
      device: { deviceConfiguration, EID }
    } = this.state

    let configurationStatus = '-'
    let color = 'black'

    if (EID) {
      let messageKey = ''
      if (configurationLoading) {
        messageKey = 'Loading'
        color = '#f0ad4e'
      } else {
        if (deviceConfiguration) {
          messageKey = 'Available'
          color = 'green'
        } else {
          messageKey = 'NotAvailable'
          color = 'red'
        }
      }
      configurationStatus = this.formatMessage(messages['configuration' + messageKey])
    }

    return (
      <Grid item xs={12}>
        <InputLabel htmlFor='device-label-placeholder' shrink>
          {this.formatMessage(messages.configurationStatus)}
        </InputLabel>
        <span style={{ color }}>{configurationStatus}</span>
      </Grid>
    )
  }

  render() {
    const { addWidgetModal, classes, closeAddWidgetModal } = this.props
    const {
      type,
      configurationLoading,
      device: { EID = '' }
    } = this.state

    const isDependantWidget = this.socketDependantWidgets(type) || this.deviceDependantWidgets(type)
    const saveButtonDisabled = isDependantWidget && (!this.state.device || configurationLoading)

    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-labelledby='alert-dialog-slide-title'
        fullWidth
        keepMounted
        maxWidth='xl'
        onClose={closeAddWidgetModal}
        open={addWidgetModal}
        scroll='paper'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.newWidget)}
          <IconButton
            onClick={closeAddWidgetModal}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }}>
                <InputLabel htmlFor='widgetType-label-placeholder' required shrink>
                  {this.formatMessage(messages.widgetType)}
                </InputLabel>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }}>
                <Select
                  displayEmpty
                  input={<Input id='widgetType-label-placeholder' name='widgetType' />}
                  name='widgetType'
                  onChange={this.handleWidgetTypeChange}
                  value={type}
                >
                  <MenuItem
                    ListItemClasses={{ root: classes.titleListItem, disabled: classes.disabled }}
                    classes={{ root: classes.titleMenuItem }}
                    disabled
                  >
                    {this.formatMessage(messages.appearance)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='box'>
                    {this.formatMessage(messages.box)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='line'>
                    {this.formatMessage(messages.line)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='text'>
                    {this.formatMessage(messages.text)}
                  </MenuItem>
                  <MenuItem
                    ListItemClasses={{ root: classes.titleListItem, disabled: classes.disabled }}
                    classes={{ root: classes.titleMenuItem }}
                    disabled
                  >
                    {this.formatMessage(messages.data)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='columnchart'>
                    {this.formatMessage(messages.columnchart)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='linechart'>
                    {this.formatMessage(messages.linechart)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='gauge'>
                    {this.formatMessage(messages.gauge)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='historic'>
                    {this.formatMessage(messages.historic)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='map'>
                    {this.formatMessage(messages.map)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='realtimevalue'>
                    {this.formatMessage(messages.realtimevalue)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='table'>
                    {this.formatMessage(messages.table)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='value'>
                    {this.formatMessage(messages.value)}
                  </MenuItem>
                  <MenuItem
                    ListItemClasses={{ root: classes.titleListItem, disabled: classes.disabled }}
                    classes={{ root: classes.titleMenuItem }}
                    disabled
                  >
                    {this.formatMessage(messages.externalInfo)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='embeddedweb'>
                    {this.formatMessage(messages.embeddedweb)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='image'>
                    {this.formatMessage(messages.image)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='link'>
                    {this.formatMessage(messages.link)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='video'>
                    {this.formatMessage(messages.video)}
                  </MenuItem>
                  <MenuItem classes={{ root: classes.widgetMenuItem }} value='weather'>
                    {this.formatMessage(messages.weather)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {this.socketDependantWidgets(type) || this.deviceDependantWidgets(type) ? (
            <Grid container spacing={3}>
              {this.renderSelectedDevice()}
              {this.renderConfigurationStatus()}
              <SelectMachine
                changeSelectedDevice={this.changeSelectedDevice}
                selectedDeviceEid={EID}
                updateDeviceConfiguration={this.updateDeviceConfiguration}
              />
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={closeAddWidgetModal}>
            {this.formatMessage(genericMessages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={saveButtonDisabled} onClick={this.handleSaveAddWidget}>
            {this.formatMessage(genericMessages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddWidget.propTypes = {
  addWidget: PropTypes.func.isRequired,
  addWidgetModal: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  closeAddWidgetModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(AddWidget))
