import moment from 'moment'

export default {
  chart: {
    zoomType: 'x',
    animation: false,
    type: 'scatter',
    marginTop: 4,
    marginRight: 0,
    marginBottom: 24,
    'marginLeft ': 4
  },
  series: [],
  title: {
    text: ''
  },
  plotOptions: {
    series: {
      turboThreshold: 10000,
      boostThreshold: 10000,
      marker: {
        radius: 2
      }
    },
    scatter: {
      dataGrouping: {
        approximation: 'average',
        enabled: true,
        groupPixelWidth: 16,
        units: [
          ['minute', [15, 30]],
          ['hour', [1, 2, 3, 4, 6, 8, 12]],
          ['day', [1]],
          ['week', [1]],
          ['month', [1, 3, 6]],
          ['year', null]
        ]
      }
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  scrollbar: false,
  xAxis: {
    type: 'datetime'
  },
  time: {
    useUTC: false
  },
  tooltip: {
    shared: false,
    formatter() {
      return '<strong>' + moment(this.x).format('LL HH:mm:ss') + '</strong>'
    }
  }
}
