import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Geofences } from './components/gmaps'

import { BASE_URL } from './url'

const GeofencesHome = ({ canReadGeofence }) => (
  <Switch>{canReadGeofence && <Route component={Geofences} path={BASE_URL} />}</Switch>
)

GeofencesHome.propTypes = {
  canReadGeofence: PropTypes.bool.isRequired
}

export default GeofencesHome
