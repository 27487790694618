import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurations'

import DataConsumptionEstimation from './DataConsumptionEstimation'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLocalConfigurationField: actions.changeLocalConfigurationField
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(DataConsumptionEstimation)