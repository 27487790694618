const WeatherTemplate = {
  id: '',
  width: 300,
  height: 200,
  x: 20,
  y: 20,
  minHeight: 200,
  minWidth: 286,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'weather',
    params: {
      days: 3,
      unit: 'Celsius'
    }
  }
}

export default WeatherTemplate
