import { connect } from 'react-redux'

import { actions as websocket } from 'ducks/websocket'

import Widget from './Widget'

const mapDispatchToProps = dispatch => ({
  subscribeWidgetToWS: (topics, eid, credentials) =>
    dispatch(websocket.subscribeRealTimeSignal(topics, eid, credentials))
})

export default connect(null, mapDispatchToProps)(Widget)
