import { defineMessages } from 'react-intl'

const messages = defineMessages({
  mapWidget: {
    id: 'MapSettings.mapWidget',
    description: 'Map widget',
    defaultMessage: 'MAP WIDGET'
  },
  mapWidgetSettings: {
    id: 'MapSettings.mapWidgetSettings',
    description: 'Map widget settings',
    defaultMessage: 'Map widget settings:'
  },
  close: {
    id: 'MapSettings.close',
    description: 'Close',
    defaultMessage: 'Close'
  },
  noSettingsAvailable: {
    id: 'MapSettings.noSettingsAvailable',
    description: 'No settings available for this widget',
    defaultMessage: 'No settings available for this widget'
  }
})

export default messages
