import { defineMessages } from 'react-intl'

const messages = defineMessages({
  settings: {
    id: 'Signal.settings',
    description: 'Settings',
    defaultMessage: 'Settings'
  },
  signalId: {
    id: 'Signal.signalId',
    description: 'Signal ID label',
    defaultMessage: 'Signal ID'
  },
  canPort: {
    id: 'Signal.canPort',
    description: 'CAN Port select label',
    defaultMessage: 'CAN Port'
  },
  address: {
    id: 'Signal.address',
    description: 'Address select label',
    defaultMessage: 'Address'
  },
  idMask: {
    id: 'Signal.idMask',
    description: 'ID mask',
    defaultMessage: 'ID mask'
  },
  canId: {
    id: 'Signal.canId',
    description: 'CAN ID',
    defaultMessage: 'CAN ID'
  },
  name: {
    id: 'Signal.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  unit: {
    id: 'Signal.unit',
    description: 'unit',
    defaultMessage: 'Unit'
  },
  signed: {
    id: 'Signal.signed',
    description: 'signed',
    defaultMessage: 'Signed'
  },
  startBit: {
    id: 'Signal.startBit',
    description: 'Start bit',
    defaultMessage: 'Start bit'
  },
  lengthOfBits: {
    id: 'Signal.lengthOfBits',
    description: 'length of bits',
    defaultMessage: 'Length of bits'
  },
  offset: {
    id: 'Signal.offset',
    description: 'offset',
    defaultMessage: 'Offset'
  },
  multiplier: {
    id: 'Signal.multiplier',
    description: 'Multiplier',
    defaultMessage: 'Multiplier'
  },
  frequency: {
    id: 'Signal.frequency',
    description: 'Frequency texfield label',
    defaultMessage: 'Frequency'
  },
  timeInterval: {
    id: 'Signal.timeInterval',
    description: 'Time interval texfield label',
    defaultMessage: 'Time interval (s)'
  },
  spnName: {
    id: 'Signal.spnName',
    description: 'SPN name',
    defaultMessage: 'SPN name'
  },
  spn: {
    id: 'Signal.spn',
    description: 'SPN',
    defaultMessage: 'SPN'
  },
  pgnName: {
    id: 'Signal.pgnName',
    description: 'PGN name',
    defaultMessage: 'PGN name'
  },
  pgn: {
    id: 'Signal.pgn',
    description: 'PGN',
    defaultMessage: 'PGN'
  },
  sourceAddress: {
    id: 'Signal.sourceAddress',
    description: 'Source Address',
    defaultMessage: 'Source Address'
  },
  priority: {
    id: 'Signal.priority',
    description: 'Priority',
    defaultMessage: 'Priority'
  },
  error: {
    id: 'Signal.error',
    description: 'Error icon tooltip',
    defaultMessage: 'Error'
  },
  copySignal: {
    id: 'Signal.copySignal',
    description: 'Copy signal icon tooltip',
    defaultMessage: 'Copy signal'
  },
  deleteSignal: {
    id: 'Signal.deleteSignal',
    description: 'Delete signal icon tooltip',
    defaultMessage: 'Delete signal'
  },
  open: {
    id: 'Signal.open',
    description: 'Open icon tooltip',
    defaultMessage: 'Open'
  },
  close: {
    id: 'Signal.close',
    description: 'Close icon tooltip',
    defaultMessage: 'Close'
  },
  editId: {
    id: 'Signal.editId',
    description: 'Edit signal ID icon tooltip',
    defaultMessage: 'Edit signal ID'
  },
  validateId: {
    id: 'Signal.validateId',
    description: 'Validate signal ID icon tooltip',
    defaultMessage: 'Validate signal ID'
  },
  selectDbcFileFromPC: {
    id: 'Signal.selectDbcFileFromPC',
    description: 'Select DBC file to import from PC:',
    defaultMessage: 'Select DBC file to import from PC:'
  },
  saveDbcFile: {
    id: 'Signal.saveDbcFile',
    description: 'Save DBC file',
    defaultMessage: 'Save DBC file'
  },
  cancel: {
    id: 'Signal.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  fileSuccessfullyLoaded: {
    id: 'Signal.fileSuccessfullyLoaded',
    description: 'File successfully loaded',
    defaultMessage: 'File successfully loaded'
  },
  loadedFile: {
    id: 'Signal.loadedFile',
    description: 'Loaded file',
    defaultMessage: 'Loaded file:'
  },
  dbcFileName: {
    id: 'Signal.dbcFileName',
    description: 'DBC file name',
    defaultMessage: 'DBC file name'
  },
  saveDbcFileAs: {
    id: 'Signal.saveDbcFileAs',
    description: 'Save DBC file as',
    defaultMessage: 'Save DBC file as:'
  },
  signalAlreadyInUse: {
    id: 'Signal.signalAlreadyInUse',
    description: 'Selected signal is already in use. Please, select a different one',
    defaultMessage: 'Selected signal is already in use. Please, select a different one'
  }
})

export default messages
