import { defineMessages } from 'react-intl'

const namespace = 'EditReport'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  error412Message: {
    id: `${namespace}.error412Message`,
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error428Message: {
    id: `${namespace}.error428Message`,
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name floating label text || Name table header column label',
    defaultMessage: 'Name'
  },
  parentReport: {
    id: `${namespace}.parentReport`,
    description: 'Parent report floating label text',
    defaultMessage: 'Parent report'
  },
  createdAt: {
    id: `${namespace}.createdAt`,
    description: 'Created at floating label text',
    defaultMessage: 'Created at'
  },
  modifiedAt: {
    id: `${namespace}.modifiedAt`,
    description: 'Modified at floating label text',
    defaultMessage: 'Modified at'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  unassign: {
    id: `${namespace}.unassign`,
    description: 'Unassign text',
    defaultMessage: 'Unassign'
  },
  generate: {
    id: `${namespace}.generate`,
    description: 'Generate button label',
    defaultMessage: 'Generate chart'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Edit report table total text showing rows',
    defaultMessage: 'Showing reports'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Edit report table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Edit report table total text of',
    defaultMessage: 'of'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID table header column label',
    defaultMessage: 'Location ID'
  },
  reportName: {
    id: `${namespace}.reportName`,
    description: 'Report name table header column label',
    defaultMessage: 'Report name'
  },
  imeiOrMac: {
    id: `${namespace}.imeiOrMac`,
    description: 'IMEI or MAC table header column label',
    defaultMessage: 'IMEI or MAC'
  },
  installDate: {
    id: `${namespace}.installDate`,
    description: 'Install date table header column label',
    defaultMessage: 'Install date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  invalidSubpath: {
    id: `${namespace}.invalidSubpath`,
    description: 'Invalid subpath message',
    defaultMessage: 'Invalid subpath, remove any invalid characters (like "/")'
  },
  themeTitle: {
    id: `${namespace}.themeTitle`,
    description: 'Theme title',
    defaultMessage: 'Theme'
  },
  customizeTheTheme: {
    id: `${namespace}.customizeTheTheme`,
    description: 'Customize the theme subheader',
    defaultMessage: 'Customize the theme for this report'
  },
  logoURL: {
    id: `${namespace}.logoURL`,
    description: 'Logo`s URL label',
    defaultMessage: 'Logo URL'
  },
  smallLogoURL: {
    id: `${namespace}.smallLogoURL`,
    description: 'Small logo`s URL label',
    defaultMessage: 'Small logo URL'
  },
  loginPath: {
    id: `${namespace}.loginPath`,
    description: 'Login path label',
    defaultMessage: 'Login path'
  },
  faviconURL: {
    id: `${namespace}.faviconURL`,
    description: 'Favicon`s URL label',
    defaultMessage: 'Favicon URL'
  },
  pageTitle: {
    id: `${namespace}.pageTitle`,
    description: 'Page`s title label',
    defaultMessage: 'Page title'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  noMachineExists404: {
    id: `${namespace}.noMachineExists404`,
    description: 'No machine exists',
    defaultMessage: 'No machine exists.'
  },
  users: {
    id: `${namespace}.users`,
    description: 'Users',
    defaultMessage: 'Users'
  },
  reportSettings: {
    id: `${namespace}.reportSettings`,
    description: 'Report Settings',
    defaultMessage: 'Report Settings'
  },
  bucketSize: {
    id: `${namespace}.bucketSize`,
    description: 'Bucket size',
    defaultMessage: 'Bucket size'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status',
    defaultMessage: 'Status'
  },
  scheduleSettings: {
    id: `${namespace}.scheduleSettings`,
    description: 'Schedule settings',
    defaultMessage: 'Schedule settings'
  },
  initialDateOfExecution: {
    id: `${namespace}.initialDateOfExecution`,
    description: 'Initial date of execution',
    defaultMessage: 'Initial date of execution'
  },
  reportFrequency: {
    id: `${namespace}.reportFrequency`,
    description: 'Report frequency',
    defaultMessage: 'Report frequency'
  },
  timezone: {
    id: `${namespace}.timezone`,
    description: 'Timezone',
    defaultMessage: 'Timezone'
  },
  metrics: {
    id: `${namespace}.metrics`,
    description: 'Metrics',
    defaultMessage: 'Metrics'
  },
  errorNoDataTitle: {
    id: `${namespace}.errorNoDataTitle`,
    description: 'Error no data title',
    defaultMessage: 'No data to show'
  },
  errorNoDataMessage: {
    id: `${namespace}.errorNoDataMessage`,
    description: 'Error no data message',
    defaultMessage: 'The query returned no data'
  },
  errorNoAssetTitle: {
    id: `${namespace}.errorNoAssetTitle`,
    description: 'Error no asset title',
    defaultMessage: 'No machine or metric selected'
  },
  errorNoAssetMessage: {
    id: `${namespace}.errorNoAssetMessage`,
    description: 'Error no asset message',
    defaultMessage: 'Select at least one machine and one metric to query'
  },
  checkEmailFormat: {
    id: `${namespace}.checkEmailFormat`,
    description: 'Check Email Format',
    defaultMessage: 'Check email format'
  },
  newPage: {
    id: `${namespace}.newPage`,
    description: 'New page',
    defaultMessage: 'New page'
  },
  incompleteFieldTitle: {
    id: `${namespace}.incompleteFieldTitle`,
    description: 'Incomplete required fields title',
    defaultMessage: 'Incomplete required fields:'
  },
  incompleteFieldText: {
    id: `${namespace}.incompleteFieldText`,
    description: 'Incomplete required fields text',
    defaultMessage: 'Please, review the report and fill all the required fields.'
  }
})

export default messages
