import { defineMessages } from 'react-intl'

const messages = defineMessages({
  showHideMenuOptions: {
    id: 'DashboardWrapper.showHideMenuOptions',
    description: 'Show/hide menu options',
    defaultMessage: 'Show/hide menu options'
  },
  exit: {
    id: 'DashboardWrapper.exit',
    description: 'Exit',
    defaultMessage: 'Exit'
  },
  saveAndExit: {
    id: 'DashboardWrapper.saveAndExit',
    description: 'Save and exit',
    defaultMessage: 'Save and exit'
  },
  addWidget: {
    id: 'DashboardWrapper.addWidget',
    description: 'Add widget',
    defaultMessage: 'Add widget'
  },
  dashboardSettings: {
    id: 'DashboardWrapper.dashboardSettings',
    description: 'Dashboard settings',
    defaultMessage: 'Dashboard settings'
  },
  deleteDashboard: {
    id: 'DashboardWrapper.deleteDashboard',
    description: 'Delete dashboard',
    defaultMessage: 'Delete dashboard'
  },
  editDashboard: {
    id: 'DashboardWrapper.editDashboard',
    description: 'Edit dashboard',
    defaultMessage: 'Edit dashboard'
  },
  exportDashboard: {
    id: 'DashboardWrapper.exportDashboard',
    description: 'Export dashboard',
    defaultMessage: 'Export dashboard'
  },
  importDashboard: {
    id: 'DashboardWrapper.importDashboard',
    description: 'Import dashboard',
    defaultMessage: 'Import dashboard'
  },
  noNeedToUpdateDashboardSettings: {
    id: 'DashboardWrapper.noNeedToUpdateDashboardSettings',
    description: 'No need to update dashboard settings',
    defaultMessage: 'No need to update dashboard settings'
  }
})

export default messages
