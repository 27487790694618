import csNode from 'modules/csNode'
import { utils as groupUtils } from 'ducks/groups'

import { actions } from 'utils/http'
import { DEFAULT_GROUP_ID } from 'utils/constants'

const { types: ht } = actions

const createMiddleware = () => {
  const middleware = store => {
    const dispatch = store.dispatch
    return next => action => {
      switch (action.type) {
        case ht.success(ht.CS_NODES_AND_LOCATIONS):
          const state = store.getState()
          const response = action.payload.data.devices
          const url = action.payload.config.url
          const size = action.payload.data.count
          const page = 0
          const eids = response.map(d => 'm' + d.EID.replaceAll(':', ''))
          const selectedGroupId = groupUtils.getSelectedGroup(state).id
          const groupId = url.substring(url.indexOf('groupId=') + 'groupId='.length, url.indexOf('&'))

          if (selectedGroupId === groupId || selectedGroupId === DEFAULT_GROUP_ID) {
            dispatch(csNode.actions.fetchNodeListLastLocation(eids, page, size))
          }
          break

        case ht.success(ht.CS_NODES_LOCATIONS_NOTIFICATIONS_DYNAMIC):
          const currentState = store.getState()
          const respnse = action.payload.data.devices
          const requestUrl = action.payload.config.url
          const count = action.payload.data.count
          const pageNumber = 0
          const eidsArray = respnse.map(d => 'm' + d.eid.replaceAll(':', ''))
          const currentGroupId = groupUtils.getSelectedGroup(currentState).id
          const urlGroupId = requestUrl.substring(
            requestUrl.indexOf('groupId=') + 'groupId='.length,
            requestUrl.indexOf('&')
          )

          if (currentGroupId === urlGroupId || currentGroupId === DEFAULT_GROUP_ID) {
            dispatch(csNode.actions.fetchNodeListLastLocation(eidsArray, pageNumber, count))
          }
          break

        case ht.success(ht.CS_NODE_AND_LOCATION):
          const resp = action.payload.data

          dispatch(csNode.actions.fetchLastLocation(resp.id, resp.EID.replaceAll(':', '')))
          break

        default:
      }
      return next(action)
    }
  }
  return middleware
}

export default createMiddleware
