import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { saveAs } from 'file-saver'

import Grid from '@material-ui/core/Grid'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { removeValuesFromRealTimeWidgets } from '../../utils'

class ExportDashboard extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      widgets: null,
      uploadedFileName: '',
      uploadError: false,
      uploadMessage: ''
    }
  }

  exportDashboard = () => {
    try {
      const descriptionContents = JSON.parse(this.props.dashboardData.description)
      const content = {
        name: this.props.dashboardData.name,
        ...descriptionContents,
        widgets: removeValuesFromRealTimeWidgets(this.props.widgets)
      }
      const blob = new Blob([JSON.stringify(content)], { type: 'application/json;charset=utf-8' })
      saveAs(blob, this.props.dashboardData.name + '.json', true)
    } catch (e) {
      // console.error(this.formatMessage(messages.parsingErrorMessage))
    } finally {
      this.props.closeExportDashboardModal()
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.exportDashboardModal}
        TransitionComponent={this.transition}
        keepMounted={true}
        scroll="paper"
        onClose={this.props.closeExportDashboardModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.exportDashboard)}
          <IconButton
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
            onClick={this.props.closeExportDashboardModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <DialogContentText id="alert-dialog-slide-description">
                <strong>{this.formatMessage(messages.name)}:</strong> {this.props.dashboardData.name}
              </DialogContentText>
              <DialogContentText id="alert-dialog-slide-description">
                <span style={{ display: 'block', paddingBottom: 10, paddingTop: 10 }}>
                  <strong>{this.formatMessage(messages.description)}:</strong>{' '}
                  {JSON.parse(this.props.dashboardData.description).description}
                </span>
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeExportDashboardModal}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.exportDashboard}>
            {this.formatMessage(messages.exportDashboardAction)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ExportDashboard.propTypes = {
  closeExportDashboardModal: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
  exportDashboardModal: PropTypes.bool.isRequired
}

export default injectIntl(ExportDashboard)
