import React from 'react'
import { MarkerProps } from '../../../../props'
import { IconWrapper } from '../../../../IconWrapper'

import Marker from './CustomOverlayMarker'

const DefaultMarker = ({ size = 35, position, zIndex, ...other }) => (
  <Marker size={size} position={position} zIndex={zIndex}>
    <IconWrapper size={size} {...other} />
  </Marker>
)

DefaultMarker.propTypes = MarkerProps

export default DefaultMarker
