import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AssignDevicesToNotification } from './AssignDevicesToNotification'
import AssignUsersToNotification from './AssignUsersToNotification'
import EditNotificationAssignUsers from './EditNotificationAssignUsers'
import { EditNotificationConditionalSignals } from './EditNotificationConditionalSignals'
import { EditNotificationGeneralProperties } from './EditNotificationGeneralProperties'
import { NewNotification } from './NewNotification'
import { NotificationsRulesSettings } from './NotificationsRulesSettings'
import {
  NOTIFICATIONS_RULES_BASE,
  NOTIFICATIONS_RULES_SETTINGS_NEW,
  NOTIFICATIONS_RULES_SETTINGS_EDIT_GENERAL_PROPERTIES,
  NOTIFICATIONS_RULES_SETTINGS_EDIT_CONDITIONAL_SIGNALS,
  NOTIFICATIONS_RULES_SETTINGS_EDIT_ASSIGN_USERS,
  NOTIFICATIONS_RULES_SETTINGS_ASSIGN_MACHINES,
  NOTIFICATIONS_RULES_SETTINGS_ASSIGN_USERS
} from './url'

const NotificationsRulesSettingsHome = ({
  canCreateNotifications,
  canEditNotifications,
  canReadDevices,
  canReadNotifications,
  canReadUsers
}) => (
  <Switch>
    {canCreateNotifications && <Route component={NewNotification} path={NOTIFICATIONS_RULES_SETTINGS_NEW} />}
    {canEditNotifications && (
      <Route
        component={EditNotificationGeneralProperties}
        path={NOTIFICATIONS_RULES_SETTINGS_EDIT_GENERAL_PROPERTIES}
      />
    )}
    {canEditNotifications && (
      <Route
        component={EditNotificationConditionalSignals}
        path={NOTIFICATIONS_RULES_SETTINGS_EDIT_CONDITIONAL_SIGNALS}
      />
    )}
    {canEditNotifications && (
      <Route component={EditNotificationAssignUsers} path={NOTIFICATIONS_RULES_SETTINGS_EDIT_ASSIGN_USERS} />
    )}
    {canEditNotifications && canReadDevices && (
      <Route component={AssignDevicesToNotification} path={NOTIFICATIONS_RULES_SETTINGS_ASSIGN_MACHINES} />
    )}
    {canEditNotifications && canReadUsers && (
      <Route component={AssignUsersToNotification} path={NOTIFICATIONS_RULES_SETTINGS_ASSIGN_USERS} />
    )}
    {canReadNotifications && <Route component={NotificationsRulesSettings} path={NOTIFICATIONS_RULES_BASE} />}
  </Switch>
)

export default NotificationsRulesSettingsHome

NotificationsRulesSettingsHome.propTypes = {
  canCreateNotifications: PropTypes.bool.isRequired,
  canEditNotifications: PropTypes.bool.isRequired,
  canReadDevices: PropTypes.bool.isRequired,
  canReadNotifications: PropTypes.bool.isRequired,
  canReadUsers: PropTypes.bool.isRequired
}
