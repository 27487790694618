import * as t from './actionTypes'
import * as url from '../plus1ConnectUrl'
import { createRequestAction } from './utils'

const getSchedules = (groupId, limit, offset) =>
  createRequestAction(t.SCHEDULE, url.schedules(groupId, limit, offset), 'get', null, 'plus1Connect')

const getSchedule = (scheduleId, groupId) =>
  createRequestAction(t.SCHEDULE, url.schedule(scheduleId, groupId), 'get', null, 'plus1Connect')

const createSchedule = (newSchedule, groupId) =>
  createRequestAction(t.SCHEDULE, url.schedules(groupId), 'post', newSchedule, 'plus1Connect')

const updateSchedule = (scheduleId, schedule, groupId) =>
  createRequestAction(t.SCHEDULE, url.schedule(scheduleId, groupId), 'put', schedule, 'plus1Connect')

const deleteSchedule = (scheduleId, groupId) =>
  createRequestAction(t.SCHEDULE, url.schedule(scheduleId, groupId), 'delete', null, 'plus1Connect')

const createScheduleAWS = (newSchedule, groupId) =>
  createRequestAction(t.SCHEDULE, url.schedulesAWS(groupId), 'post', newSchedule)

const deleteScheduleAWS = (scheduleId, groupId) =>
  createRequestAction(t.SCHEDULE, url.scheduleAWS(scheduleId, groupId), 'delete', null)

export {
  createSchedule,
  getSchedules,
  getSchedule,
  updateSchedule,
  deleteSchedule,
  createScheduleAWS,
  deleteScheduleAWS
}
