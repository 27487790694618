import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import Alert from 'components/Alert'
import { client, logError } from 'utils/http'

import EditGroupForm from './EditGroupForm'
import EditGroupFormTitle from './EditGroupFormTitle'
import EditGroupMachines from './EditGroupMachines'
import messages from './messages'

class EditGroup extends React.Component {
  constructor(props) {
    super(props)

    const { name } = props.editingGroup

    this.state = {
      isGroupThemeLoading: true,
      isGroupThemeSaving: false,

      logoBig: '',
      logoBigName: '',
      appBarColor: '#b60710',
      sideBarColor: '#5e5d52',
      fontColor: '#ffffff',
      favicon: '',
      faviconName: '',
      title: '',
      name,
      isNameValid: true,
      themeHashId: '',
      loginPath: '',
      isLoginPathValid: true,
      loginImage: '',
      loginImageName: '',
      loginText: '',

      alertMessages: false,
      alertMessageText: ['']
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    this.getGroupTheme()
  }

  getGroupTheme = () => {
    this.setState(
      {
        isGroupThemeLoading: true
      },
      async () => {
        const {
          editingGroup: { id: groupId }
        } = this.props
        let themeConfig = {}
        try {
          const response = await client.getGroupTheme(groupId)
          const { hashId, file } = response.data
          if (file) {
            const { data: url } = await client.getGroupThemeConfigUrl(groupId, hashId)
            const { data: config } = await client.getGroupThemeConfig(url)
            themeConfig = { ...config }
          }
          themeConfig = { ...themeConfig, themeHashId: hashId }
        } catch (error) {
          if (error?.response?.status === 404) {
            const { data } = await client.createGroupTheme(groupId, { groupId })
            themeConfig = {
              themeHashId: data.hashId
            }
          } else {
            this.setState({
              alertMessages: true,
              alertMessageText: [this.formatMessage(messages.errorObtaining)]
            })
          }
        } finally {
          const theme = {
            logoBig: '',
            logoBigName: '',
            appBarColor: '#b60710',
            favicon: '',
            faviconName: '',
            title: '',
            loginPath: '',
            loginImage: '',
            loginImageName: '',
            loginText: '',
            sideBarColor: '#5e5d52',
            fontColor: '#ffffff',
            ...themeConfig
          }
          this.setState({
            ...theme,
            isGroupThemeLoading: false
          })
        }
      }
    )
  }

  handleSave = () => {
    const { onEditGroup, editGroup, editingGroup, setTheme, portalGroupId } = this.props
    const {
      name,
      logoBig,
      logoBigName,
      title,
      appBarColor,
      favicon,
      faviconName,
      loginPath,
      loginImage,
      loginImageName,
      loginText,
      sideBarColor,
      fontColor,
      themeHashId
    } = this.state

    this.setState(
      {
        isGroupThemeSaving: true
      },
      async () => {
        try {
          await editGroup(name)
          const { id: groupId } = editingGroup
          const body = {
            ...logoBig && { logoBig, logoBigName },
            ...title && { title },
            appBarColor,
            ...favicon && { favicon, faviconName },
            groupId,
            ...loginPath && { loginPath },
            ...loginImage && { loginImage, loginImageName },
            ...loginText && { loginText },
            sideBarColor,
            fontColor,
            description: name,
            hashId: themeHashId
          }
          const stringifiedBody = JSON.stringify(body)
          const formData = new FormData()
          const blob = new Blob([stringifiedBody], { type: 'application/json' })
          formData.append('file', blob)
          await client.updateGroupThemeConfig(groupId, themeHashId, formData)
          await client.updateGroupTheme(groupId, { loginPath, hashId: themeHashId, groupId }, themeHashId)
          if (groupId === portalGroupId) setTheme(body)
          onEditGroup()
        } catch (response) {
          logError(response)
          this.setState({
            alertMessages: true,
            alertMessageText: [this.formatMessage(messages.errorSaving)]
          })
        } finally {
          this.setState({
            isGroupThemeSaving: false
          })
        }
      }
    )
  }

  handleEditGroupFieldChange = changes => {
    this.setState(changes)
  }

  setGroupTitle = groupName => {
    if (groupName) {
      return this.formatMessage(messages.editGroupTitle, { name: groupName })
    } else {
      return '...'
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessageText: ['']
    })
  }

  renderGroupTablecontent = () => {
    const { getGroup, editingGroup } = this.props
    const {
      isGroupThemeLoading,
      logoBig,
      logoBigName,
      appBarColor,
      favicon,
      faviconName,
      title,
      name,
      loginPath,
      loginImage,
      loginImageName,
      loginText,
      sideBarColor,
      fontColor
    } = this.state

    return (
      <EditGroupForm
        appBarColor={appBarColor}
        favicon={favicon}
        faviconName={faviconName}
        fontColor={fontColor}
        getGroup={getGroup}
        group={editingGroup}
        isGroupThemeLoading={isGroupThemeLoading}
        loginImage={loginImage}
        loginImageName={loginImageName}
        loginPath={loginPath}
        loginText={loginText}
        logoBig={logoBig}
        logoBigName={logoBigName}
        name={name}
        onFieldChange={this.handleEditGroupFieldChange}
        sideBarColor={sideBarColor}
        title={title}
      />
    )
  }

  renderGroupNodesTablecontent = () => {
    const {
      canShareDevices,
      getAssignMachinesUrl,
      getGroupDevices,
      removeDevicesFromGroup,
      urlAction,
      editingGroup: { nodeCount, id: groupId }
    } = this.props
    return (
      <EditGroupMachines
        canShareDevices={canShareDevices}
        getAssignMachinesUrl={getAssignMachinesUrl}
        getGroupDevices={getGroupDevices}
        group={groupId}
        nodeCount={nodeCount}
        removeDevicesFromGroup={removeDevicesFromGroup}
        urlAction={urlAction}
      />
    )
  }

  render() {
    const { groupsUrl, editingGroup } = this.props
    const { alertMessages, alertMessageText, isNameValid, isLoginPathValid, isGroupThemeLoading, isGroupThemeSaving } =
      this.state

    const groupName = editingGroup.name || ''
    const buttonDisabled =
      alertMessages || !isNameValid || !isLoginPathValid || isGroupThemeLoading || isGroupThemeSaving

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <EditGroupFormTitle groupsUrl={groupsUrl} title={this.setGroupTitle(groupName)} />
            {alertMessages && (
              <Alert
                alertType='danger'
                closeFunction={this.closeAlert}
                messageText={alertMessageText}
                showAlert={alertMessages}
              />
            )}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {this.renderGroupTablecontent()}
            </Grid>

            <Grid item xs={12}>
              {this.renderGroupNodesTablecontent()}
            </Grid>

            <Grid item xs={12}>
              <div className='container-fluid'>
                <Grid container style={{ justifyContent: 'flex-end' }}>
                  <Link className='button-link' to={groupsUrl}>
                    <Button className='cancel-button' style={{ marginRight: 10 }}>
                      {this.formatMessage(messages.cancel)}
                    </Button>
                  </Link>
                  <Button className='primary-action-button' disabled={buttonDisabled} onClick={this.handleSave}>
                    {this.formatMessage(messages.save)}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

EditGroup.propTypes = {
  canShareDevices: PropTypes.bool.isRequired,
  editingGroup: PropTypes.object.isRequired,
  editGroup: PropTypes.func.isRequired,
  getAssignMachinesUrl: PropTypes.string.isRequired,
  getGroup: PropTypes.func.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  groupsUrl: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired, locale: PropTypes.string.isRequired }).isRequired,
  onEditGroup: PropTypes.func.isRequired,
  portalGroupId: PropTypes.string.isRequired,
  removeDevicesFromGroup: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  urlAction: PropTypes.string
}

EditGroup.defaultProps = {
  urlAction: ''
}

export default injectIntl(EditGroup)
