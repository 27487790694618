const EMAIL_ITEM = 'email'
const ACCESS_TOKEN_ITEM = 'access_token'
const DIP_TOKEN = 'dip_token'
const USER_ID_ITEM = 'user_id'
const GROUP_ID_ITEM = 'group_id'
const ACCESS_TOKEN_EXPIRES_IN_ITEM = 'expires_in'
const ACCESS_TOKEN_DATE_EXPIRES = 'date_expires_in'

const REGISTER_PATH = 'register_path'
const REGISTER_SEARCH = 'register_search'

// sessionStorage does not share variables across tabs like localStorage.
// https://dev.to/rdegges/please-stop-using-local-storage-1i04

const getEmail = () => localStorage.getItem(EMAIL_ITEM)

const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_ITEM)
const getAccessTokenExpiresIn = () => localStorage.getItem(ACCESS_TOKEN_EXPIRES_IN_ITEM)
const getAccessTokendDateExpiresIn = () => localStorage.getItem(ACCESS_TOKEN_DATE_EXPIRES)

const getUserId = () => localStorage.getItem(USER_ID_ITEM)

const getRegisterPath = () =>
  localStorage.getItem(REGISTER_PATH) ? localStorage.getItem(REGISTER_PATH) + localStorage.getItem(REGISTER_SEARCH) : ''

// eslint-disable-next-line max-params
const cacheCredentials = (email, token, userId, secondsTokenExpiresIn, dateTokenExpiresIn) => {
  localStorage.setItem(EMAIL_ITEM, email)
  localStorage.setItem(ACCESS_TOKEN_ITEM, token)
  localStorage.setItem(USER_ID_ITEM, userId)
  localStorage.setItem(ACCESS_TOKEN_EXPIRES_IN_ITEM, secondsTokenExpiresIn)
  localStorage.setItem(ACCESS_TOKEN_DATE_EXPIRES, dateTokenExpiresIn)
}

const cacheDipToken = dipToken => localStorage.setItem(DIP_TOKEN, dipToken)

const cacheRegisterPath = registerPath => localStorage.setItem(REGISTER_PATH, registerPath)
const cacheRegisterSearch = registerSearch => localStorage.setItem(REGISTER_SEARCH, registerSearch)
const clearRegisterPath = registerPath => {
  localStorage.removeItem(REGISTER_PATH)
  localStorage.removeItem(REGISTER_SEARCH)
}

const clearCredentials = () => {
  localStorage.removeItem(EMAIL_ITEM)
  localStorage.removeItem(ACCESS_TOKEN_ITEM)
  localStorage.removeItem(USER_ID_ITEM)
  localStorage.removeItem(GROUP_ID_ITEM)
  localStorage.removeItem(ACCESS_TOKEN_EXPIRES_IN_ITEM)
  localStorage.removeItem(ACCESS_TOKEN_DATE_EXPIRES)
}

export {
  cacheCredentials,
  cacheDipToken,
  cacheRegisterPath,
  cacheRegisterSearch,
  clearCredentials,
  clearRegisterPath,
  getAccessToken,
  getAccessTokenExpiresIn,
  getAccessTokendDateExpiresIn,
  getEmail,
  getRegisterPath,
  getUserId
}
