import React from 'react'
import PropTypes from 'prop-types'

class LoaderWrapper extends React.Component {
  // componentDidMount will be called after all the subcomponents are loaded too
  UNSAFE_componentWillMount() {
    if (this.props.onMount) {
      this.props.onMount()
    }
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount()
    }
  }

  render() {
    return this.props.children
  }
}

LoaderWrapper.propTypes = {
  onMount: PropTypes.func,
  onUnmount: PropTypes.func
}

const createLoader = WrappedComponent =>
  function ({ load, unload, ...other }) {
    if (!WrappedComponent) {
      return <div />
    }
    return (
      <LoaderWrapper onMount={load} onUnmount={unload}>
        <WrappedComponent {...other} />
      </LoaderWrapper>
    )
  }

export { createLoader }
