import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getDinamicData, getStaticData } from 'ducks/dashboards'
import { actions as websocket, utils } from 'ducks/websocket'

import LineChart from './LineChart'
import LineChartSettingsUnconnected from './LineChartSettings'
import LineChartTemplate from './LineChartTemplate'

import csNode from 'modules/csNode'

const mapStateToProps = (state, { match }) => {
  return {
    dinamicData: getDinamicData(state),
    staticData: getStaticData(state),
    eid: getStaticData(state)[1]?.value || '',
    nodeCredentials: csNode.utils.getRealTimeCredentials(state)[match.params.nodeId],
    isSubscribedTo: (eid, topic) => utils.isSubscribedTo(state, eid, topic)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    subscribeWidgetToWS: (topics, eid, credentials) =>
      dispatch(websocket.subscribeGeneric(topics, eid, credentials)),
    unsubscribeWidgetFromWS: (topic, eid) => dispatch(websocket.unsubscribe(topic, eid))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LineChart))
const LineChartSettings = withRouter(connect(mapStateToProps, mapDispatchToProps)(LineChartSettingsUnconnected))
export { LineChartSettings, LineChartTemplate }
