import React from 'react'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'

const UnboxedCard = ({ classes, children, ...other }) => (
  <Card className={classes.card} {...other}>
    {children}
  </Card>
)

export default withStyles({
  card: {
    boxShadow: 'none !important',
    borderBottom: 'thin solid #ccc'
  }
})(UnboxedCard)
