import throttle from 'lodash.throttle'

import * as t from './actionTypes'
import { connect as createWebSocket } from './utils'
import { MQTT_CONFIG } from 'utils/constants'

import translations from 'i18n/locales'

// TODO in the future we could handle more than a websocket connection by
// checking an ID that could be passed.

/**
 * Function to create a middleware which handle MQTT webSocket connection.
 *
 * @param grouping Should the messages be  grouped?
 *    undefined or false => no grouping is performed
 *    Otherwise it will expect an options object for the 'lodash' throttle function.
 *    (see https://lodash.com/docs/4.17.4#throttle)
 * @param throttleWait wait for throttle function
 *    (see https://lodash.com/docs/4.17.4#throttle).
 *    This parameter will be ignored if grouping is not defined.
 * @param pahoConnectionConfig Custom configuration for Paho connection.
 */

// Uses the flux standard action definition: https://github.com/acdlite/flux-standard-action
const createMiddleware = (grouping, throttleWait = 500) => {
  const shouldGroup = grouping !== undefined && grouping !== null && grouping

  const batch = (() => {
    const groupedByTopic = {}
    const add = (topic, payload) => {
      // Create store for batched updates if it does not exist
      if (topic in groupedByTopic) {
        groupedByTopic[topic].push(payload)
      } else {
        groupedByTopic[topic] = [payload]
      }
    }
    const pop = topic => {
      const ret = groupedByTopic[topic]
      groupedByTopic[topic] = []
      return ret
    }
    return { add, pop }
  })()

  // Idea: https://gist.github.com/dmichael/9dc767fca93624df58b423d01e485402
  const middleware = store => {
    const websockets = {}
    let websocket
    let currentAccessKeyId = null

    const dispatch = store.dispatch

    const wrapAction = ({ type, payload, meta = {} }) => ({
      type,
      payload,
      meta: { ...meta, accessKeyId: currentAccessKeyId }
    })
    const createAction = (type, payload, meta) => wrapAction({ type, payload, meta })

    const dispatchers = {}
    const previousExecutions = {}
    const throttledDispatch = topic => {
      if (!(topic in dispatchers)) {
        dispatchers[topic] = throttle(
          () => {
            const payload = batch.pop(topic)
            dispatch({
              type: t.GROUPED_MESSAGES_RECEIVED,
              payload,
              meta: { topic }
            })
            return Promise.resolve()
          },
          throttleWait,
          // 'grouping' is expected to be a valid options object
          grouping
        )

        // First execution (normal)
        previousExecutions[topic] = dispatchers[topic]()
      } else if (previousExecutions[topic]) {
        // Subsecuent executions (wait until previous dispatch has been completed)
        previousExecutions[topic].then(() => {
          previousExecutions[topic] = dispatchers[topic]()
        })
      }
    }

    return next => action => {
      let onSuccess, onFail
      if (action.meta) {
        onSuccess = action.meta.onSuccess
        onFail = action.meta.onFail
      }
      const i18n = translations[localStorage.getItem('user_language')] // eslint-disable-line no-unused-vars
      switch (action.type) {
        case t.CONNECT:
          {
            const { accessKeyId, secretAccessKey, sessionToken } = action.payload

            const newConnection = createWebSocket({
              ...MQTT_CONFIG,
              // debug: true,
              accessKeyId,
              secretKey: secretAccessKey,
              sessionToken
            })
            currentAccessKeyId = accessKeyId
            websockets[action.meta.nodeId] = { websocketClient: newConnection, accessKeyId }

            newConnection.on('connect', connack => {
              currentAccessKeyId = accessKeyId
              dispatch(createAction(t.CONNECT_SUCCESS, connack))
            })

            /*newConnection.on('close', () => {
              dispatch(createAction(t.DISCONNECT_SUCCESS))
            })*/

            newConnection.on('offline', response => {
              currentAccessKeyId = accessKeyId
              dispatch(createAction(t.OFFLINE))
            })

            newConnection.on('reconnect', () => {
              currentAccessKeyId = accessKeyId
              dispatch(createAction(t.RECONNECT))
            })

            newConnection.on('error', err => {
              currentAccessKeyId = accessKeyId
              dispatch(createAction(t.ERROR, err))
            })

            newConnection.on('message', (topic, payload) => {
              if (shouldGroup) {
                batch.add(topic, payload)
                // Give the WS listener a chance to queue more pending messages
                // by depriorityzing the dispatch over next calls to this function.
                setTimeout(() => {
                  throttledDispatch(topic)
                }, 1)
              } else {
                dispatch({
                  type: t.MESSAGE_RECEIVED,
                  meta: { topic }, // Is it payload or meta?
                  payload
                })
              }
            })

            // To make it accessible in other scopes
            //websocket = newConnection
          }
          break

        case t.DISCONNECT:
          if (websockets[action.meta.nodeId]) {
            websocket = websockets[action.meta.nodeId].websocketClient
            currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
          } else websocket = null
          if (websocket) {
            websocket
              .end()
              .then(() => {
                currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
                dispatch(createAction(t.DISCONNECT_SUCCESS))
              })
              .catch(() => {
                currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
                dispatch(createAction(t.DISCONNECT_FAIL))
              })
          }
          // like "break" but with wrapped action
          return next(wrapAction(action))

        case t.SUBSCRIBE:
          if (websockets[action.meta.nodeId]) {
            websocket = websockets[action.meta.nodeId].websocketClient
            currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
          } else websocket = null
          const subscribeTo = action.payload
          // if (!subscribeTo) {
          //   console.error(i18n['Websocket.youMustProvideAValidTopic'])
          // }
          if (websocket) {
            websocket
              .subscribe(subscribeTo)
              .then(response => {
                currentAccessKeyId = websockets[action.meta.nodeId] && websockets[action.meta.nodeId].accessKeyId
                dispatch(createAction(t.SUBSCRIBE_SUCCESS, subscribeTo))
                if (onSuccess) onSuccess()
              })
              .catch(err => {
                currentAccessKeyId = websockets[action.meta.nodeId] && websockets[action.meta.nodeId].accessKeyId
                dispatch(createAction(t.SUBSCRIBE_FAIL, subscribeTo))
                if (onFail) onFail(err)
              })
          }
          return next(wrapAction(action))

        case t.UNSUBSCRIBE:
          if (websockets[action.meta.nodeId]) {
            websocket = websockets[action.meta.nodeId].websocketClient
            currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
          } else websocket = null
          const unsubscribeFrom = action.payload
          if (websocket) {
            websocket
              .unsubscribe(unsubscribeFrom)
              .then(() => {
                currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
                dispatch(createAction(t.UNSUBSCRIBE_SUCCESS, unsubscribeFrom))
                if (onSuccess) onSuccess()
              })
              .catch(err => {
                currentAccessKeyId = websockets[action.meta.nodeId].accessKeyId
                dispatch(createAction(t.UNSUBSCRIBE_FAIL, unsubscribeFrom))
                if (onFail) onFail(err)
              })
          }
          return next(wrapAction(action))

        case t.SEND:
          const sendingWS = Object.values(websockets)[0]
          if (sendingWS) {
            websocket = sendingWS && sendingWS.websocketClient
            currentAccessKeyId = sendingWS && sendingWS.accessKeyId
          } else websocket = null
          if (websocket && websocket.isConnected()) {
            const payload = action.payload.msg ? action.payload.msg : action.payload
            // Don't propagate in this case, just return the promise
            return websocket.publish(action.meta.topic, payload, { qos: 1 }).catch(e => {
              // console.error(i18n['Websocket.mQTTPublishError'], e)
            })
          }
          return next(wrapAction(action))

        default:
      }

      return next(action) // always propagate it anyway
    }
  }

  return middleware
}

export default createMiddleware
