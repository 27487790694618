import React from 'react'
import PropTypes from 'prop-types'
import { FixedSizeList as List } from 'react-window'

const MenuListComponent = props => {
  const { options, children, getValue } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value)
  return (
    <List
      height={options.length <= 1 ? 40 : 200}
      initialScrollOffset={initialOffset}
      itemCount={children.length ? children.length : 1}
      itemSize={40}
    >
      {({ index, style }) => <div style={style}>{children.length ? children[index] : children}</div>}
    </List>
  )
}

MenuListComponent.propTypes = {
  children: PropTypes.array,
  getValue: PropTypes.func,
  options: PropTypes.array
}

MenuListComponent.defaultProps = {
  children: [],
  getValue: () => {},
  options: []
}

export default MenuListComponent
