import { connect } from 'react-redux'

import { getStaticData } from 'ducks/dashboards'

import Value from './Value'
import ValueSettingsUnconnected from './ValueSettings'
import ValueTemplate from './ValueTemplate'

const mapStateToProps = state => {
  return {
    staticData: getStaticData(state)
  }
}

export default connect(mapStateToProps)(Value)

const ValueSettings = connect(mapStateToProps)(ValueSettingsUnconnected)
export { ValueSettings, ValueTemplate }
