import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Home from './Home'

const mapStateToProps = state => ({
  canReadNotifications: resolvePermissions(state, c.RULE_READ),
  canEditNotifications: resolvePermissions(state, c.RULE_EDIT),
  canCreateNotifications: resolvePermissions(state, c.RULE_CREATE)
})

export default connect(mapStateToProps)(Home)
