const TableTemplate = {
  id: '',
  width: 600,
  height: 400,
  x: 20,
  y: 20,
  minHeight: 200,
  minWidth: 300,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'table',
    params: {
      data: '',
      settings: {
        columns: []
      },
      visualSettings: {
        condensed: true,
        defaultRowNumber: 10
      }
    }
  }
}

export default TableTemplate
