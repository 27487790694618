import React from 'react'

import { injectIntl } from 'react-intl'
import messages from './messages'

import Alert from 'components/Alert'

const NoDashboard = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <div id="content" className="content-container" style={{ width: '100%' }}>
      <div className="col-md-12" style={{ padding: '30px' }}>
        <Alert
          showAlert={true}
          alertType="info"
          messageTitle={formatMessage(messages.noDashboardAvailable)}
          messageText={[
            formatMessage(messages.clickOnToCreateTheFirstOne, {
              addDashboard: <b>{formatMessage(messages.addDashboard)}</b>
            })
          ]}
        />
      </div>
    </div>
  )
}

export default injectIntl(NoDashboard)
