import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { actions, utils as groupUtils } from 'ducks/groups'

import TreeListRolesUnconnected from './TreeListRoles'
import TreeListPrivilegesUnconnected from './TreeListPrivileges'

const mapStateToProps = state => ({
  canEditRoles: resolvePermissions(state, c.GROUPROLE_EDIT),
  canDeleteRoles: resolvePermissions(state, c.GROUPROLE_DELETE),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  deleteGroupRole: (groupId, roleId) => dispatch(actions.deleteRole(groupId, roleId))
})

const TreeListRoles = connect(mapStateToProps, mapDispatchToProps)(TreeListRolesUnconnected)
const TreeListPrivileges = connect(mapStateToProps)(TreeListPrivilegesUnconnected)

export { TreeListRoles, TreeListPrivileges }
