import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'ducks/configurations'
import { utils as groupUtils } from 'ducks/groups'
import csNode from 'modules/csNode'

import UpdateConfiguration from './UpdateConfiguration'

import * as c from 'utils/constants'
import { actions as hactions } from 'utils/http'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = state => ({
  canApplyConfiguration: resolvePermissions(state, c.DEVICE_EDIT),
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getConfigurations: actions.getConfigurations,
      getDeviceNonVolatileConfiguration: csNode.actions.fetchDeviceNonVolatileConfiguration,
      setDeviceNonVolatileConfiguration: csNode.actions.setDeviceNonVolatileConfiguration
    },
    dispatch
  ),
  updateDevicesConfiguration: async (groupId, deviceIds, configurationId) => {
    return Promise.all(
      deviceIds.map(deviceId =>
        dispatch(hactions.plus1Connect.updateConfigurationOfDevice(groupId, deviceId, configurationId)).promise.then(
          ({ payload }) => payload
        )
      )
    )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateConfiguration)
