export const GROUPS_BASE = '/groups'
export const GROUP_SETTINGS_NEW = GROUPS_BASE + '/group'
export const GROUP_SETTINGS_EDIT = GROUPS_BASE + '/:groupId'
export const GROUP_SETTINGS_ASSIGN_MACHINES = GROUP_SETTINGS_EDIT + '/machines'

const getGroupsUrl = () => GROUPS_BASE
const getNewGroupUrl = () => GROUP_SETTINGS_NEW
const getGroupEditUrl = groupId => GROUP_SETTINGS_EDIT.replace(':groupId', groupId)
const getAssignMachinesUrl = groupId => GROUP_SETTINGS_ASSIGN_MACHINES.replace(':groupId', groupId)

export { getGroupsUrl, getNewGroupUrl, getGroupEditUrl, getAssignMachinesUrl }
