import { defineMessages } from 'react-intl'

const messages = defineMessages({
  valueWidget: {
    id: 'ValueSettings.valueWidget',
    description: 'Value widget',
    defaultMessage: 'VALUE WIDGET'
  },
  valueWidgetSettings: {
    id: 'ValueSettings.valueWidgetSettings',
    description: 'Value widget settigs dialog',
    defaultMessage: 'Value widget settigs:'
  },
  values: {
    id: 'ValueSettings.values',
    description: 'Values',
    defaultMessage: 'Values'
  },
  selectValueToDisplay: {
    id: 'ValueSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  otherValueWidgetSettings: {
    id: 'ValueSettings.otherValueWidgetSettings',
    description: 'Value widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  size: {
    id: 'ValueSettings.size',
    description: 'Font size',
    defaultMessage: 'Font size'
  },
  selectSizeInPixels: {
    id: 'ValueSettings.selectSizeInPixels',
    description: 'Value widget settigs: Select font size in pixels',
    defaultMessage: 'Select font size in pixels'
  },
  weight: {
    id: 'ValueSettings.weight',
    description: 'Font weight',
    defaultMessage: 'Font weight'
  },
  bold: {
    id: 'ValueSettings.bold',
    description: 'Font weight: bold',
    defaultMessage: 'Bold'
  },
  normal: {
    id: 'ValueSettings.normal',
    description: 'Font weight: normal',
    defaultMessage: 'Normal'
  },
  selectWeight: {
    id: 'ValueSettings.selectWeight',
    description: 'Value widget settigs: Select font weight',
    defaultMessage: 'Select font weight'
  },
  align: {
    id: 'ValueSettings.align',
    description: 'Text align',
    defaultMessage: 'Text align'
  },
  left: {
    id: 'ValueSettings.left',
    description: 'Text align: left',
    defaultMessage: 'Left'
  },
  center: {
    id: 'ValueSettings.center',
    description: 'Text align: center',
    defaultMessage: 'Center'
  },
  right: {
    id: 'ValueSettings.right',
    description: 'Text align: right',
    defaultMessage: 'Right'
  },
  selectAlign: {
    id: 'ValueSettings.selectAlign',
    description: 'Value widget settigs: Select text align',
    defaultMessage: 'Select text align'
  },
  cancel: {
    id: 'ValueSettings.cancel',
    description: 'Value widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'ValueSettings.save',
    description: 'Value widget settings save button text',
    defaultMessage: 'Save'
  },
  widgetNotConfigured: {
    id: 'ValueSettings.widgetNotConfigured',
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  }
})

export default messages
