import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions as cs500Actions } from 'ducks/configurationsCS500'
import { actions } from 'ducks/reports'
import { utils as groupUtils } from 'ducks/groups'
import { utils } from 'ducks/login'

import csNode from 'modules/csNode'

import { getReportsUrl } from '../../url'
import NewReport from './NewReport'

const mapStateToProps = (state, { history }) => ({
  reportsUrl: getReportsUrl(),
  plus1ConnectUser: utils.getLoggedUserEmail(state),
  plus1ConnectUserId: utils.getLoggedUserId(state),
  onCreateReport: () => {
    history.push(getReportsUrl(), 'create')
  },
  groupId: groupUtils.getSelectedGroup(state).id
})

const mapDispatchToProps = dispatch => ({
  getAssets: (groupId, limit, offset, deviceFields) =>
    dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields)),
  fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
    dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
  createReport: (schedule, groupId) => dispatch(actions.createSchedule(schedule, groupId)),
  createReportAWS: (schedule, groupId) => dispatch(actions.createScheduleAWS(schedule, groupId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewReport))
