import axios from 'axios'

// plus1connect
const plus1ConnectInstance = axios.create({
  baseURL: process.env.REACT_APP_HTTP_PLUS1CONNECT_API,
  withCredentials: false
})

plus1ConnectInstance.interceptors.request.use(req => {
  const token = localStorage.getItem('access_token')

  if (token !== null) {
    req.headers['Authorization'] = 'Bearer ' + token // eslint-disable-line no-param-reassign
  }

  return req
})

const getData = (url, params) => plus1ConnectInstance.get(url, params)

export default plus1ConnectInstance
export { getData }
