import React from 'react'
import PropTypes from 'prop-types'
import { SVGOverlay } from 'react-map-gl'

const MarkerLines = props => {
  const { color, gpsPoints } = props
  return (
    <SVGOverlay
      redraw={({ project }) => {
        return gpsPoints
          .map((gpsPoint, i, self) => {
            const { longitude, latitude } = gpsPoint
            const [cx, cy] = project([longitude, latitude])

            const nextGpsPoint = self[i + 1]
            if (nextGpsPoint) {
              const { longitude: nextLongitude, latitude: nextLatitude } = nextGpsPoint
              const [cx2, cy2] = project([nextLongitude, nextLatitude])

              return (
                <line
                  key={`${cx}_${cy}_${cx2}_${cy2}_${i}`}
                  style={{ stroke: color, strokeWidth: 3 }}
                  x1={cx}
                  x2={cx2}
                  y1={cy}
                  y2={cy2}
                />
              )
            } else {
              return null
            }
          })
          .filter(Boolean)
      }}
    />
  )
}

MarkerLines.propTypes = {
  color: PropTypes.string.isRequired,
  gpsPoints: PropTypes.array.isRequired
}

export default MarkerLines
