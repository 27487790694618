import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import messages from './messages'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'

import { logError } from 'utils/http'

import ShareAccessPasses from './ShareAccessPasses'

const styles = {
  card: {
    borderRadius: 0,
    height: '100%'
  },
  title: {
    fontSize: 14,
    fontWeight: 700
  },
  span: {
    height: 44,
    width: 12
  }
}

class AccessPassInventory extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      isAccessPassesShared: false,
      isApShareDialogOpen: false,
      isDefaultApShareDialogOpen: false,
      isDefaultLoading: false,
      isLoading: false,
      numberOfAccessPasses: 0,
      personalNumberOfAccessPasses: 0
    }
  }

  componentDidMount() {
    this.getNumberOfAccessPasses('')
    this.getNumberOfAccessPasses('default')
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupId } = this.props
    const { isAccessPassesShared } = this.state

    if (prevProps.groupId !== groupId && groupId) {
      this.setState(
        {
          isAccessPassesShared: false,
          isApShareDialogOpen: false,
          isDefaultApShareDialogOpen: false,
          isDefaultLoading: false,
          isLoading: false,
          numberOfAccessPasses: 0,
          personalNumberOfAccessPasses: 0
        },
        () => {
          this.getNumberOfAccessPasses('')
          this.getNumberOfAccessPasses('default')
        }
      )
    }
    if (!prevState.isAccessPassesShared && isAccessPassesShared) {
      this.setState({
        isAccessPassesShared: false
      })
      this.getNumberOfAccessPasses()
    }
  }

  getNumberOfAccessPasses = groupType => {
    const { getNumberOfAccessPasses, groupId, defaultGroupId } = this.props
    const loadingKey = groupType === 'default' ? 'isDefaultLoading' : 'isLoading'
    const numberOfAccessPassesKey = groupType === 'default' ? 'personalNumberOfAccessPasses' : 'numberOfAccessPasses'
    const param = groupType === 'default' ? defaultGroupId : groupId
    this.setState(
      {
        [loadingKey]: true
      },
      () => {
        getNumberOfAccessPasses(param)
          .then(response => {
            const { count } = response.data
            this.setState({
              [numberOfAccessPassesKey]: count
            })
          })
          .catch(response => {
            logError(response)
          })
          .finally(() => {
            this.setState({
              [loadingKey]: false
            })
          })
      }
    )
  }

  handleDefaultAPShareDialogOpen = () => {
    this.setState({
      isDefaultApShareDialogOpen: true
    })
  }

  handleDefaultAPShareDialogClose = () => {
    this.setState({
      isDefaultApShareDialogOpen: false
    })
  }

  handleAPShareDialogOpen = () => {
    this.setState({
      isApShareDialogOpen: true
    })
  }

  handleAPShareDialogClose = () => {
    this.setState({
      isApShareDialogOpen: false
    })
  }

  handleAccessPassesShared = () => {
    this.setState({
      isAccessPassesShared: true
    })
  }

  render() {
    const {
      classes: { card, title, span },
      canShareAccessPasses,
      groupId,
      defaultGroupId
    } = this.props
    const {
      numberOfAccessPasses,
      isLoading,
      isDefaultLoading,
      isApShareDialogOpen,
      isDefaultApShareDialogOpen,
      personalNumberOfAccessPasses
    } = this.state
    const isCurrentGroupDefault = groupId === defaultGroupId
    const largeGrid = isCurrentGroupDefault ? 12 : 6
    return (
      <React.Fragment>
        <Card classes={{ root: card }}>
          <CardContent style={{ padding: '16px 16px 8px 25px' }}>
            <Grid container>
              <Grid alignItems='center' classes={{ container: title }} container item justify='flex-start' xs={12}>
                {this.formatMessage(messages.accessPassInventory)}
              </Grid>
              <Grid container item lg={largeGrid} xs={12}>
                <Grid alignItems='center' container item justify='flex-start' xs={9}>
                  {this.formatMessage(messages.boughtByMe)}
                </Grid>
                <Grid alignItems='center' container item justify='flex-end' style={{ fontSize: 18 }} xs={3}>
                  {isDefaultLoading ? <CircularProgress size={18} /> : personalNumberOfAccessPasses}
                  {canShareAccessPasses ? (
                    <IconButton
                      disabled={personalNumberOfAccessPasses < 1}
                      onClick={this.handleDefaultAPShareDialogOpen}
                    >
                      <ShareIcon fontSize='small' />
                    </IconButton>
                  ) : (
                    <span className={span} />
                  )}
                </Grid>
              </Grid>
              {!isCurrentGroupDefault && (
                <Grid container item lg={6} xs={12}>
                  <Grid alignItems='center' container item justify='flex-start' xs={9}>
                    {this.formatMessage(messages.sharedToThisGroup)}
                  </Grid>
                  <Grid alignItems='center' container item justify='flex-end' style={{ fontSize: 18 }} xs={3}>
                    {isLoading ? <CircularProgress size={18} /> : numberOfAccessPasses}
                    {canShareAccessPasses ? (
                      <IconButton disabled={numberOfAccessPasses < 1} onClick={this.handleAPShareDialogOpen}>
                        <ShareIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <span className={span} />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        {canShareAccessPasses && (
          <ShareAccessPasses
            groupId={defaultGroupId}
            maxNumberOfAccessPasses={personalNumberOfAccessPasses}
            onAccessPassesShared={this.handleAccessPassesShared}
            onClose={this.handleDefaultAPShareDialogClose}
            open={isDefaultApShareDialogOpen}
            portalGroupId={groupId}
          />
        )}
        {canShareAccessPasses && !isCurrentGroupDefault && (
          <ShareAccessPasses
            groupId={groupId}
            maxNumberOfAccessPasses={numberOfAccessPasses}
            onClose={this.handleAPShareDialogClose}
            open={isApShareDialogOpen}
            portalGroupId={groupId}
          />
        )}
      </React.Fragment>
    )
  }
}

AccessPassInventory.propTypes = {
  canShareAccessPasses: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  defaultGroupId: PropTypes.string.isRequired,
  getNumberOfAccessPasses: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(AccessPassInventory))
