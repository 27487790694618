import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { getAdaptedDetailedDevicesData } from '../../../apiAdapters'
import { getFormattedInspectionValue } from '../../../helpers'
import messages from '../../../messages'
import { dialogStyles, iconButtonStyles, paperStyles } from '../../../styles'
import { getTableOptions } from '../../../tableHelpers'
import ServerErrorAlert from '../../alerts/ServerErrorAlert'

const styles = {
  ...dialogStyles,
  ...iconButtonStyles,
  ...paperStyles
}

class AssignedDevicesCurrentValuesModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assignedDevicesData: [],
      isAssignedDevicesLoading: false,
      isApiError: false,

      totalSize: 0
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      this.fetchAssignedDevicesData()
    }
  }

  fetchAssignedDevicesData = () => {
    const {
      fetchCSNodesDetails,
      groupId,
      data: { currentValuesData }
    } = this.props

    const assignedDevicesEids = currentValuesData.map(item => item.deviceEid)

    if (assignedDevicesEids.length === 0) return

    this.setState({ isAssignedDevicesLoading: true, isApiError: false })
    fetchCSNodesDetails(groupId, assignedDevicesEids)
      .then(response => {
        const assignedDevicesData = getAdaptedDetailedDevicesData(response.data.devices)

        this.setState({ assignedDevicesData, totalSize: assignedDevicesData.length })
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isAssignedDevicesLoading: false })
      })
  }

  getTableData = () => {
    const {
      data: { currentValuesData, initialValue }
    } = this.props
    const { assignedDevicesData } = this.state

    return currentValuesData
      .map(item => {
        const { name: deviceName } = assignedDevicesData.find(device => device.eid === item.deviceEid) || {}
        return {
          ...item,
          deviceName
        }
      })
      .sort((rowA, rowB) => {
        const isRowAPrioritized = rowA.currentValue > initialValue
        const isRowBPrioritized = rowB.currentValue > initialValue

        if (isRowAPrioritized && !isRowBPrioritized) {
          return -1
        } else if (!isRowAPrioritized && isRowBPrioritized) {
          return 1
        } else {
          return rowA.deviceName < rowB.deviceName ? -1 : 1
        }
      })
  }

  getTableOptions = () => {
    const { intl } = this.props
    const { isAssignedDevicesLoading } = this.state

    return getTableOptions({
      intl,
      isLoading: isAssignedDevicesLoading,
      page: 1,
      pageSize: 10
    })
  }

  formatAllColumns = (cell, row, { column }) => {
    const {
      data: { initialValue }
    } = this.props
    const value = column === 'currentValue' ? this.formatCurrentValueColumn(cell) : cell
    if (row.currentValue >= initialValue) {
      return <span style={{ color: '#B60710' }}>{value}</span>
    } else {
      return value
    }
  }

  formatCurrentValueColumn = cell => {
    const {
      data: { selectedAdvancedSignalData }
    } = this.props

    return getFormattedInspectionValue({
      inspectionValue: cell,
      advancedSignalUnit: selectedAdvancedSignalData.unit
    })
  }

  render() {
    const { classes, intl, isOpen, onCloseClick } = this.props
    const { isApiError, isAssignedDevicesLoading, totalSize } = this.state

    const tableData = isOpen ? this.getTableData() : []
    const tableOptions = this.getTableOptions()

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.paperRoot } }}
        fullWidth
        maxWidth='sm'
        onClose={onCloseClick}
        open={isOpen}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.currentValues)}
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onCloseClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isOpen && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid classes={{ root: classes.dialogContentGridContainer }} container>
              {isApiError && (
                <Grid item xs={12}>
                  <ServerErrorAlert />
                </Grid>
              )}
              <Grid item xs={12}>
                <div className='table-with-pagination' style={{ padding: 0 }}>
                  <BootstrapTable
                    bordered={false}
                    data={isAssignedDevicesLoading ? [] : tableData}
                    fetchInfo={{ dataTotalSize: totalSize }}
                    hover
                    keyField='deviceEid'
                    options={tableOptions}
                    pagination
                    remote={remoteObj => ({
                      ...remoteObj,
                      search: false,
                      pagination: true,
                      sizePerPage: true,
                      sort: false,
                      filter: true
                    })}
                  >
                    <TableHeaderColumn
                      dataField='deviceName'
                      dataFormat={this.formatAllColumns}
                      formatExtraData={{ column: 'deviceName' }}
                    >
                      {intl.formatMessage(messages.deviceName)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='currentValue'
                      dataFormat={this.formatAllColumns}
                      formatExtraData={{ column: 'currentValue' }}
                    >
                      {intl.formatMessage(messages.currentValue)}
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    )
  }
}

AssignedDevicesCurrentValuesModal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  fetchCSNodesDetails: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired
}

AssignedDevicesCurrentValuesModal.defaultProps = {
  data: {}
}

export default withStyles(styles)(injectIntl(AssignedDevicesCurrentValuesModal))
