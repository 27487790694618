import * as c from './constants'
import * as t from './actionTypes'

const initState = {
  notificationsTotalsLastTimestampReceivedData: 0,
  notificationsTotalsReceivedTimestamp: 0,
  notificationsTotalsReceivedData: null
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case t.SET_TOTALS:
      const newTimeStampReceived = action.payload.notificationsTotalsReceivedTimestamp
      const notificationsTotals = action.payload.notificationsTotalsReceivedData
      
      if (newTimeStampReceived > state.notificationsTotalsLastTimestampReceivedData) {
        //Update notifications Totals received
        let lastTimestampToSave = state.notificationsTotalsReceivedTimestamp
        if (state.notificationsTotalsReceivedTimestamp === 0) {
          lastTimestampToSave = newTimeStampReceived
        }
        return {
          ...state,
          notificationsTotalsLastTimestampReceivedData: lastTimestampToSave,
          notificationsTotalsReceivedTimestamp: newTimeStampReceived,
          notificationsTotalsReceivedData:notificationsTotals
        }
      } else {
        return {
          ...state
        }
      }      
      

    case t.SET_STOMPCLIENT:
      const currentStompClient = action.payload.notificationsTotalsStompClient        
      return {
        ...state,
        currentStompClient
      }
        

    default:
  }

  return state
}

export default reducer

// Selector
const getNotificationSelector = state => state[c.NAME]

const getNotificationsTotalObject = state => getNotificationSelector(state).notificationsTotalsReceivedData

export { getNotificationsTotalObject }
