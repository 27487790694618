import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { getFormattedInspection, getFormattedInspectionDate, getFormattedInspectionValue } from '../../../helpers'
import messages from '../../../messages'
import { dialogStyles, iconButtonStyles, paperStyles } from '../../../styles'
import InspectionStateChip from '../../chips/InspectionStateChip'

const styles = {
  ...dialogStyles,
  ...iconButtonStyles,
  ...paperStyles
}

class InspectionDetailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes, data, intl, isOpen, onCloseClick } = this.props

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.paperRoot } }}
        fullWidth
        maxWidth='md'
        onClose={onCloseClick}
        open={isOpen}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.inspectionDetail)}
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onCloseClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isOpen && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid classes={{ root: classes.dialogContentGridContainer }} container>
              <Grid item md={4} xs={6}>
                <Grid container spacing={1}>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.deviceName)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      {data.deviceName}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.currentValue)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      {getFormattedInspectionValue({
                        inspectionValue: data.currentValue,
                        advancedSignalUnit: data.advancedSignalUnit
                      })}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.scheduledInspection)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      {getFormattedInspection({
                        frequencyType: data.frequencyType,
                        inspection: data.nextInspection,
                        advancedSignalUnit: data.advancedSignalUnit
                      })}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.inspectionDate)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      {getFormattedInspectionDate({ inspectionDate: data.inspectionDate })}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.inspectionValue)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      {getFormattedInspectionValue({
                        inspectionValue: data.inspectionValue,
                        advancedSignalUnit: data.advancedSignalUnit
                      })}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.inspectedBy)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      {data.inspectedBy || '-'}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item md={5} xs={6}>
                      <b>{intl.formatMessage(messages.state)}</b>
                    </Grid>
                    <Grid item md={7} xs={6}>
                      <InspectionStateChip state={data.state} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={6}>
                <Grid container>
                  <Grid container item spacing={3} xs={12}>
                    <Grid item xs={2}>
                      <b>{intl.formatMessage(messages.notes)}</b>
                    </Grid>
                    <Grid item xs={10}>
                      {data.notes || '-'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    )
  }
}

InspectionDetailModal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired
}

InspectionDetailModal.defaultProps = {
  data: {}
}

export default withStyles(styles)(injectIntl(InspectionDetailModal))
