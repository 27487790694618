import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import ServerErrorAlert from '../../components/alerts/ServerErrorAlert'
import NotificationSeverityChip from '../../components/chips/NotificationSeverityChip'
import { MAINTENANCE_CONFIGURATION_STEPS } from '../../constants'
import messages from '../../messages'
import { alertStyles, chipStyles, iconButtonStyles, linkButtonStyles, paperStyles, tooltipStyles } from '../../styles'

const styles = {
  ...alertStyles,
  ...chipStyles,
  ...iconButtonStyles,
  ...linkButtonStyles,
  ...paperStyles,
  ...tooltipStyles
}

class SummaryStep extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      advancedSignalName,
      assignedDevicesEids,
      assignedUsers,
      classes,
      description,
      frequencyClarificationSentence,
      intl,
      isApiFetchError,
      isApiSendError,
      isInspectionReschedulingEnabled,
      isMaintenanceSaved,
      isSavingLoading,
      name,
      onStepChange,
      severity
    } = this.props

    const stepLabels = {
      [MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS]: intl.formatMessage(messages.generalSettings),
      [MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS]: intl.formatMessage(messages.machineSettings),
      [MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS]: intl.formatMessage(messages.signalSettings),
      [MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS]: intl.formatMessage(messages.notificationSettings)
    }

    const summaryFields = {
      [MAINTENANCE_CONFIGURATION_STEPS.GENERAL_SETTINGS]: [
        {
          label: intl.formatMessage(messages.name),
          value: name
        },
        {
          label: intl.formatMessage(messages.description),
          value: description
        }
      ],
      [MAINTENANCE_CONFIGURATION_STEPS.MACHINES_SETTINGS]: [
        {
          label: intl.formatMessage(messages.numberOfMachines),
          value: assignedDevicesEids.length
        }
      ],
      [MAINTENANCE_CONFIGURATION_STEPS.SIGNAL_SETTINGS]: [
        {
          label: intl.formatMessage(messages.advancedSignal),
          value: advancedSignalName
        },
        {
          label: intl.formatMessage(messages.inspections),
          value: frequencyClarificationSentence
        },
        ...isInspectionReschedulingEnabled
          ? [
            {
              label: intl.formatMessage(messages.rescheduleActiveInspections),
              value: (
                <React.Fragment>
                  <Chip
                    classes={{ root: classes.chipRoot + ' ' + classes.chipSuccess }}
                    label={intl.formatMessage(messages.checked)}
                  />
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={intl.formatMessage(messages.rescheduleActiveInspectionsClarification)}
                  >
                    <IconButton classes={{ root: classes.iconButtonRoot }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )
            }
          ]
          : []
      ],
      [MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS]: [
        {
          label: intl.formatMessage(messages.notificationSeverity),
          value: <NotificationSeverityChip severity={severity} />
        },
        {
          label: intl.formatMessage(messages.numberOfUsers),
          value: assignedUsers.length
        }
      ]
    }

    return (
      <Grid item xs={12}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Grid container spacing={3}>
            {isApiFetchError && (
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography component='h2' variant='h5'>
                {intl.formatMessage(messages.summary)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={6}>
                {Object.entries(summaryFields).map(([stepKey, fields]) => {
                  const stepLabel = stepLabels[stepKey]
                  return (
                    <Grid key={stepKey} item lg={3} sm={6} style={{ display: 'flex' }} xs={12}>
                      <Grid container direction='column' spacing={3} wrap='nowrap'>
                        <Grid item style={{ flexBasis: 'auto' }} xs={12}>
                          <Grid
                            container
                            justifyContent='space-between'
                            style={{ borderBottom: '1px solid #D6D6D6', paddingBottom: 8 }}
                          >
                            <Grid item>
                              <Typography component='h3' variant='h6'>
                                {stepLabel}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {!isMaintenanceSaved && (
                                <Button
                                  disabled={isSavingLoading}
                                  onClick={() => onStepChange(stepKey)}
                                  size='small'
                                  variant='outlined'
                                >
                                  {intl.formatMessage(messages.edit)}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex' }} xs={12}>
                          <Grid alignContent='flex-start' container direction='column' spacing={1} wrap='nowrap'>
                            {fields.map((field, index) => (
                              <Grid key={index} item style={{ flexBasis: 'auto' }} xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item lg={4} sm={3} xl={3}>
                                    <b>{field.label}</b>
                                  </Grid>
                                  <Grid item lg={8} sm={9} xl={9}>
                                    {field.value}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                            <Grid
                              item
                              style={{
                                flexBasis: 'auto',
                                marginTop: 'auto'
                              }}
                              xs={12}
                            >
                              <div
                                style={{
                                  borderBottom: '1px solid #D6D6D6',
                                  paddingTop: 16
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>

            {isMaintenanceSaved && (
              <Grid item xs={12}>
                <Alert classes={{ message: classes.alertMessage }} severity='success'>
                  <AlertTitle classes={{ root: classes.alertTitle }}>{intl.formatMessage(messages.success)}</AlertTitle>
                  {intl.formatMessage(messages.maintenanceSuccesfullySaved)}
                </Alert>
              </Grid>
            )}
            {isApiSendError && (
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

SummaryStep.propTypes = {
  advancedSignalName: PropTypes.string.isRequired,
  assignedDevicesEids: PropTypes.array.isRequired,
  assignedUsers: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  frequencyClarificationSentence: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  isApiFetchError: PropTypes.bool.isRequired,
  isApiSendError: PropTypes.bool.isRequired,
  isInspectionReschedulingEnabled: PropTypes.bool.isRequired,
  isMaintenanceSaved: PropTypes.bool.isRequired,
  isSavingLoading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onStepChange: PropTypes.func.isRequired,
  severity: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(SummaryStep))
