import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { privilegeCheck } from '../utils'

class TreeListPrivileges extends React.Component {
  constructor(props) {
    super(props)

    let checkedValue

    if (props.privilegeState[props.hashId] !== undefined) {
      checkedValue = props.privilegeState[props.hashId]
    } else {
      checkedValue = false
    }

    checkedValue = privilegeCheck(props.privilegeState, props.hashId)

    this.state = {
      description: props.description,
      hashId: props.hashId,
      setPrivilegeState: props.setPrivilegeState,
      checked: checkedValue
    }
  }

  componentDidMount() {
    const { hashId, childrenHashes, privilegeState } = this.props
    if (hashId === 'fake') {
      const numOfChildren = childrenHashes.length
      const currentPrivilegeStateHashIds = privilegeState.map(privilege => privilege.hashId)
      const currentCheckedChildren = currentPrivilegeStateHashIds.filter(hash => childrenHashes.includes(hash))
      if (currentCheckedChildren.length === numOfChildren) this.setState({ checked: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { privilegeState, hashId, childrenHashes } = this.props
    if (prevProps.privilegeState !== privilegeState) {
      if (hashId !== 'fake') {
        const checked = privilegeCheck(privilegeState, hashId)
        this.setState({
          checked
        })
      } else {
        const numOfChildren = childrenHashes.length
        const currentPrivilegeStateHashIds = privilegeState.map(privilege => privilege.hashId)
        const previousPrivilegeStateHashIds = prevProps.privilegeState.map(privilege => privilege.hashId)
        const currentCheckedChildren = currentPrivilegeStateHashIds.filter(hash => childrenHashes.includes(hash))
        const previousCheckedChildren = previousPrivilegeStateHashIds.filter(hash =>
          prevProps.childrenHashes.includes(hash)
        )
        if (previousCheckedChildren.length < numOfChildren && currentCheckedChildren.length === numOfChildren)
          this.setState({ checked: true })
        else if (previousCheckedChildren.length === numOfChildren && currentCheckedChildren.length < numOfChildren)
          this.setState({ checked: false })
      }
    }
  }

  handleClick = event => {
    const { childrenHashes } = this.props
    const { setPrivilegeState, checked: previousChecked } = this.state

    const hashId = event.target.value

    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    this.setState(
      ({ checked: isChecked }) => ({ checked: !isChecked }),
      () => {
        const hashIds = hashId === 'fake' ? [...childrenHashes] : [hashId]
        setPrivilegeState(hashIds, !previousChecked)
      }
    )
  }

  renderCheckbox = () => {
    const { checked, hashId, description } = this.state
    return (
      <div style={{ marginTop: -14, marginLeft: 14, paddingLeft: 0 }}>
        <FormControlLabel
          control={<Checkbox checked={checked} onClick={this.handleClick} value={hashId} />}
          label={description}
          style={{
            fontWeight: 'normal'
          }}
        />
      </div>
    )
  }

  render() {
    return this.renderCheckbox()
  }
}

TreeListPrivileges.propTypes = {
  childrenHashes: PropTypes.array,
  description: PropTypes.string,
  hashId: PropTypes.string,
  privilegeState: PropTypes.array,
  setPrivilegeState: PropTypes.func
}

TreeListPrivileges.defaultProps = {
  childrenHashes: [],
  description: '',
  hashId: '',
  privilegeState: [],
  setPrivilegeState: () => {}
}

export default TreeListPrivileges
