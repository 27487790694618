import React from 'react'

import { Marker } from 'react-map-gl'

import { IconWrapper } from '../../IconWrapper'
import { MarkerProps } from '../../props'
import './style.css'

const CustomMarker = ({ size = 35, position, zIndex, draggable, onDrag, onDragStart, onDragEnd, ...other }) => (
  <Marker
    size={size}
    offsetLeft={-size / 2}
    offsetTop={-size / 2}
    draggable={draggable}
    onDrag={onDrag}
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    {...position}
    className={zIndex ? `marker-${zIndex}` : ''}
  >
    <IconWrapper size={size} {...other} />
  </Marker>
)

CustomMarker.propTypes = MarkerProps

export default CustomMarker
