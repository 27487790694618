import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

const EditGroupFormTitle = ({ title, groupsUrl }) => {
  return <PageTitle title={title} back={groupsUrl} />
}

EditGroupFormTitle.propTypes = {
  groupsUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default EditGroupFormTitle
