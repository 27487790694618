import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { actions } from 'ducks/dataPlans'
import { utils } from 'ducks/groups'

import DataPlanInventory from './DataPlanInventory'

const mapStateToProps = state => ({
  canShareDataPlans: utils.hasPermission(state, c.DATAPLAN_SHARE),
  groupId: utils.getSelectedGroup(state).id,
  defaultGroupId: utils.getDefaultGroupId(state)
})

const mapDispatchToProps = dispatch => ({
  getNumberOfDataPlans: groupId => dispatch(actions.getNumberOfDataPlans(groupId))
})

export default connect(mapStateToProps, mapDispatchToProps)(DataPlanInventory)
