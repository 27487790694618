const ValueTemplate = {
  id: '',
  width: 300,
  height: 30,
  x: 20,
  y: 20,
  minHeight: 20,
  minWidth: 20,
  maxHeight: undefined,
  maxWidth: undefined,
  zIndex: 0,
  deviceEids: [],
  content: {
    widgetType: 'value',
    params: {
      data: '',
      size: 14,
      bold: 'bold',
      align: 'left'
    }
  }
}

export default ValueTemplate
