import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import messages from '../../messages'

const styles = {
  warningAlert: {
    alignItems: 'baseline',
    display: 'flex',
    fontSize: '16px',
    padding: '14px 24px'
  },
  closeButton: {
    cursor: 'pointer',
    background: 'transparent',
    border: 0,
    '-webkit-appearance': 'none',
    '&:hover': {
      opacity: 0.5
    }
  }
}

class NoGpsTrackingAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShown: true
    }
  }

  handleHideClick = () => {
    this.setState({ isShown: false })
  }

  render() {
    const { classes, intl, noData } = this.props
    const { isShown } = this.state

    return (
      isShown && (
        <div className={`${noData ? 'alert-danger' : 'alert-warning'} ${classes.warningAlert}`}>
          <div style={{ flexGrow: 1 }}>
            <i className='zmdi zmdi-cloud-off' style={{ marginRight: '10px' }} />
            {noData ? intl.formatMessage(messages.noGpsTrackings) : intl.formatMessage(messages.someNoGpsTrackings)}
          </div>
          <button
            aria-label='Close'
            className={classes.closeButton}
            onClick={this.handleHideClick}
            style={{ padding: 5 }}
          >
            <i className='zmdi zmdi-close' />
          </button>
        </div>
      )
    )
  }
}

NoGpsTrackingAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  noData: PropTypes.bool
}

NoGpsTrackingAlert.defaultProps = {
  noData: false
}

export default injectIntl(withStyles(styles)(NoGpsTrackingAlert))
