import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions, utils as groupUtils } from 'ducks/groups'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import {
  getNotificationsRulesUrl,
  getNotificationAssignUsersUrl,
  getEditNotificationCondicionalSignalsUrl
} from '../url'
import EditNotificationAssignUsers from './EditNotificationAssignUsers'

const mapStateToProps = (state, { match }) => ({
  canReadUsers: resolvePermissions(state, c.USER_READ),
  currentGroupName: groupUtils.getSelectedGroup(state).name,
  editNotificationConditionalSignalsUrl: getEditNotificationCondicionalSignalsUrl,
  getGroup: groupId => groupUtils.getCurrentGroup(state, groupId),
  notificationAssignUsersUrl: getNotificationAssignUsersUrl(match.params.notificationId),
  notificationId: match.params.notificationId,
  notificationsUrl: getNotificationsRulesUrl()
})

const mapDispatchToProps = dispatch => ({
  getUsersOfGroupDetail: (groupId, usersEmails) => dispatch(actions.getUsersOfGroupDetails(groupId, usersEmails))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditNotificationAssignUsers))
