import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import EditTheme from './EditTheme'

import messages from './messages'

class EditGroupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  handleNameChange = event => {
    const { onFieldChange } = this.props
    const { value } = event.target
    onFieldChange({ name: value, isNameValid: value !== '' })
  }

  getParentNameValue = () => {
    const { getGroup, group } = this.props

    const parentGroup = getGroup(group.parent_id)
    const parentNameValue = parentGroup?.name || 'Root element'

    return parentNameValue
  }

  render() {
    const {
      group,
      name,
      logoBig,
      logoBigName,
      appBarColor,
      favicon,
      faviconName,
      title,
      onFieldChange,
      isGroupThemeLoading,
      loginPath,
      loginText,
      loginImage,
      loginImageName,
      sideBarColor,
      fontColor
    } = this.props

    const parentNameValue = this.getParentNameValue()
    const nameDisabled = name.toUpperCase() === 'DANFOSS DEFAULT GROUP'
    const editThemeGridStyles = isGroupThemeLoading ? { display: 'flex', justifyContent: 'center' } : {}

    return (
      <div className='container-fluid' style={{ margin: '8px 0 0 0' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ borderRadius: 0, padding: '24px' }}>
              <Grid container spacing={3}>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={4}>
                    <Typography variant='h5'>General Settings</Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      disabled={nameDisabled}
                      error={name === ''}
                      fullWidth
                      helperText={name === '' && this.formatMessage(messages.thisFieldIsRequired)}
                      label={this.formatMessage(messages.name)}
                      name='name'
                      onChange={this.handleNameChange}
                      value={name}
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      fullWidth
                      label={this.formatMessage(messages.parentGroup)}
                      name='parentGroup'
                      value={parentNameValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      fullWidth
                      label={this.formatMessage(messages.creationDate)}
                      name='createdAt'
                      value={moment(group.createdAt).format('DD-MMM-YYYY')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled
                      fullWidth
                      label={this.formatMessage(messages.modificationDate)}
                      name='modifiedAt'
                      value={moment(group.updatedAt).format('DD-MMM-YYYY')}
                    />
                  </Grid>
                  <Grid item style={editThemeGridStyles} xs={12}>
                    {isGroupThemeLoading ? (
                      <CircularProgress />
                    ) : (
                      <EditTheme
                        appBarColor={appBarColor}
                        favicon={favicon}
                        faviconName={faviconName}
                        fontColor={fontColor}
                        loginImage={loginImage}
                        loginImageName={loginImageName}
                        loginPath={loginPath}
                        loginText={loginText}
                        logoBig={logoBig}
                        logoBigName={logoBigName}
                        onFieldChange={onFieldChange}
                        sideBarColor={sideBarColor}
                        title={title}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

EditGroupForm.propTypes = {
  appBarColor: PropTypes.string.isRequired,
  favicon: PropTypes.string.isRequired,
  faviconName: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  getGroup: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired, locale: PropTypes.string.isRequired }).isRequired,
  isGroupThemeLoading: PropTypes.bool.isRequired,
  loginImage: PropTypes.string.isRequired,
  loginImageName: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired,
  loginText: PropTypes.string.isRequired,
  logoBig: PropTypes.string.isRequired,
  logoBigName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  sideBarColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default injectIntl(EditGroupForm)
