import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

class LinkSettings extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const { text, size, bold, align, url } = props.data

    this.state = {
      text,
      textError: '',
      size,
      bold,
      align,
      url,
      urlError: ''
    }
  }

  handleChange = name => event => {
    let update = { [name]: event.target.value }
    if (name === 'text' || name === 'url') update = { ...update, [name + 'Error']: '' }
    this.setState(update)
  }

  processUrl = url => {
    const trimedUrl = url.trim()
    return trimedUrl.toLowerCase()
  }

  closeSettings = () => {
    this.props.closeSettings()
  }

  saveSettings = () => {
    let error = false

    if (this.state.text.trim() === '') {
      error = true
      this.setState({
        textError: this.formatMessage(messages.textCantBeEmpty)
      })
    }
    const processedUrl = this.processUrl(this.state.url)

    if (!processedUrl.startsWith('https://') && !processedUrl.startsWith('http://')) {
      error = true
      let message = this.formatMessage(messages.urlMustStartWith)
      if (processedUrl === '') {
        message = this.formatMessage(messages.urlCantBeEmpty)
      }
      this.setState({
        urlError: message
      })
    }

    if (!error) {
      const data = {
        text: this.state.text,
        size: this.state.size,
        bold: this.state.bold,
        align: this.state.align,
        url: processedUrl
      }

      this.props.saveSettings(data)
    }
  }

  render() {
    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        fullWidth={true}
        keepMounted={true}
        maxWidth="xl"
        onClose={this.closeSettings}
        open={true}
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.linkWidget)}
          <IconButton
            onClick={this.closeSettings}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.linkWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <FormControl error={this.state.textError !== ''} style={{ width: '100%' }}>
                <InputLabel htmlFor="staticData-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.text)}*
                </InputLabel>
                <Input name="text" onChange={this.handleChange('text')} value={this.state.text} />
                <FormHelperText>{this.state.textError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <FormControl error={this.state.urlError !== ''} style={{ width: '100%' }}>
                <InputLabel htmlFor="staticData-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.url)}*
                </InputLabel>
                <Input name="url" onChange={this.handleChange('url')} value={this.state.url} />
                <FormHelperText>{this.state.urlError}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={{ display: 'block', fontWeight: 'bold', padding: '24px 0px 15px 0px' }}>
              {this.formatMessage(messages.otherLinkWidgetSettings)}
            </span>
          </DialogContentText>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="size-label-placeholder" shrink={true}>
                  {this.formatMessage(messages.size)}
                </InputLabel>
                <Select
                  autoWidth={true}
                  displayEmpty={true}
                  input={<Input id="size-label-placeholder" name="size" />}
                  name="size"
                  onChange={this.handleChange('size')}
                  value={this.state.size}
                >
                  <MenuItem value={14}>14px</MenuItem>
                  <MenuItem value={16}>16px</MenuItem>
                  <MenuItem value={18}>18px</MenuItem>
                  <MenuItem value={20}>20px</MenuItem>
                  <MenuItem value={22}>22px</MenuItem>
                  <MenuItem value={24}>24px</MenuItem>
                  <MenuItem value={26}>26px</MenuItem>
                  <MenuItem value={28}>28px</MenuItem>
                  <MenuItem value={32}>32px</MenuItem>
                  <MenuItem value={36}>36px</MenuItem>
                  <MenuItem value={42}>42px</MenuItem>
                  <MenuItem value={48}>48px</MenuItem>
                  <MenuItem value={54}>54px</MenuItem>
                  <MenuItem value={62}>62px</MenuItem>
                </Select>
                <FormHelperText>{this.formatMessage(messages.selectSizeInPixels)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="bold-label" shrink={true}>
                  {this.formatMessage(messages.weight)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty={true}
                  input={<Input id="weight-label-placeholder" name="weight" />}
                  name="weight"
                  onChange={this.handleChange('bold')}
                  value={this.state.bold}
                >
                  <MenuItem value="normal">{this.formatMessage(messages.normal)}</MenuItem>
                  <MenuItem value="bold">{this.formatMessage(messages.bold)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectWeight)}</FormHelperText>
            </Grid>
            <Grid item={true} xs={4}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="align-label" shrink={true}>
                  {this.formatMessage(messages.align)}
                </InputLabel>
                <Select
                  autoWidth={false}
                  displayEmpty={true}
                  input={<Input id="align-label-placeholder" name="align" />}
                  name="align"
                  onChange={this.handleChange('align')}
                  value={this.state.align}
                >
                  <MenuItem value="left">{this.formatMessage(messages.left)}</MenuItem>
                  <MenuItem value="center">{this.formatMessage(messages.center)}</MenuItem>
                  <MenuItem value="right">{this.formatMessage(messages.right)}</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>{this.formatMessage(messages.selectAlign)}</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.closeSettings}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" onClick={this.saveSettings}>
            {this.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

LinkSettings.propTypes = {
  closeSettings: PropTypes.func,
  data: PropTypes.shape({
    text: PropTypes.string,
    size: PropTypes.number,
    bold: PropTypes.string,
    align: PropTypes.string,
    url: PropTypes.string
  }),
  saveSettings: PropTypes.func
}

LinkSettings.defaultProps = {
  closeSettings: i => i,
  data: {
    text: '',
    size: 14,
    bold: 'normal',
    align: 'left',
    url: ''
  },
  saveSettings: i => i
}

export default injectIntl(LinkSettings)
