import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ActionsState.title',
    description: 'Actions state title',
    defaultMessage: 'Events history'
  },
  actions: {
    id: 'ActionsState.actions',
    description: 'Actions table header column',
    defaultMessage: 'Actions'
  },
  viewActionDetail: {
    id: 'ActionsState.viewActionDetail',
    description: 'View action detail button label',
    defaultMessage: 'View action detail'
  },
  cancelAction: {
    id: 'ActionsState.cancelAction',
    description: 'Cancel action button label',
    defaultMessage: 'Cancel action'
  },
  relaunchAction: {
    id: 'ActionsState.relaunchAction',
    description: 'Relaunch action button label',
    defaultMessage: 'Relaunch action'
  },
  downloadFile: {
    id: 'ActionsState.downloadFile',
    description: 'Download file button label',
    defaultMessage: 'Download file'
  },
  close: {
    id: 'ActionsState.close',
    description: 'Close button label',
    defaultMessage: 'Close'
  },
  showingRows: {
    id: 'ActionsState.showingRows',
    description: 'Actions state table total text showing rows',
    defaultMessage: 'Showing events'
  },
  toTable: {
    id: 'ActionsState.toTable',
    description: 'Actions state table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'ActionsState.of',
    description: 'Actions state table total text of',
    defaultMessage: 'of'
  },
  noTasksAvailable: {
    id: 'ActionsState.noTasksAvailable',
    description: 'No tasks available warning text',
    defaultMessage: 'No tasks available'
  },
  prePage: {
    id: 'ActionsState.prePage',
    description: 'Actions state previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'ActionsState.nextPage',
    description: 'Actions state next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'ActionsState.firstPage',
    description: 'Actions state first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'ActionsState.lastPage',
    description: 'Actions state last page button text',
    defaultMessage: 'Last'
  },
  filterByOrderableColumns: {
    id: 'ActionsState.filterByOrderableColumns',
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  placeholder: {
    id: 'ActionsState.placeholder',
    description: 'Table header column filter placeholder',
    defaultMessage: 'Enter'
  },
  name: {
    id: 'ActionsState.name',
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  actionDescription: {
    id: 'ActionsState.actionDescription',
    description: 'Action description table header column label',
    defaultMessage: 'Action description'
  },
  taskCreator: {
    id: 'ActionsState.taskCreator',
    description: 'Task creator table header column label',
    defaultMessage: 'Task creator'
  },
  created: {
    id: 'ActionsState.created',
    description: 'Created table header column label',
    defaultMessage: 'Created'
  },
  status: {
    id: 'ActionsState.status',
    description: 'Status table header column label',
    defaultMessage: 'Status'
  },
  fromAndToDatesAreTheSame: {
    id: 'ActionsState.fromAndToDatesAreTheSame',
    description: 'From and To dates are the same warning',
    defaultMessage: '"From" and "To" dates are the same.'
  },
  toDateIsBeforeFromDate: {
    id: 'ActionsState.toDateIsBeforeFromDate',
    description: 'To date is before from date warning',
    defaultMessage: '"To" date is before "From" date.'
  },
  dates: {
    id: 'ActionsState.dates',
    description: 'Dates filter title',
    defaultMessage: 'DATES'
  },
  from: {
    id: 'ActionsState.from',
    description: 'From floating label text',
    defaultMessage: 'From'
  },
  to: {
    id: 'ActionsState.to',
    description: 'To floating label text',
    defaultMessage: 'to'
  },
  statusFilterTitle: {
    id: 'ActionsState.statusFilterTitle',
    description: 'Status filter title',
    defaultMessage: 'STATUS'
  },
  archived: {
    id: 'ActionsState.archived',
    description: 'Archived option title',
    defaultMessage: 'ARCHIVED'
  },
  viewArchived: {
    id: 'ActionsState.viewArchived',
    description: 'View archived option label',
    defaultMessage: 'View archived'
  },
  selectedActions: {
    id: 'ActionsState.selectedActions',
    description: 'Selected actions button label',
    defaultMessage: 'Selected actions'
  },
  clearSelection: {
    id: 'ActionsState.clearSelection',
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  cancel: {
    id: 'ActionsState.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  relaunch: {
    id: 'ActionsState.relaunch',
    description: 'Relaunch button label',
    defaultMessage: 'Relaunch'
  },
  taskCreatedByOn: {
    id: 'ActionsState.taskCreatedByOn',
    description: 'Task created by text',
    defaultMessage: 'Task created by {user} on {createdDate}'
  },
  startDate: {
    id: 'ActionsState.startDate',
    description: 'Start date text',
    defaultMessage: 'Start date:'
  },
  endDate: {
    id: 'ActionsState.endDate',
    description: 'End date text',
    defaultMessage: 'End date:'
  },
  duration: {
    id: 'ActionsState.duration',
    description: 'Duration date text',
    defaultMessage: 'Duration:'
  },
  statusDetail: {
    id: 'ActionsState.statusDetail',
    description: 'Status detail text',
    defaultMessage: 'Status:'
  },
  archivedDetail: {
    id: 'ActionsState.archivedDetail',
    description: 'Archived detail text',
    defaultMessage: 'Archived:'
  },
  blobStorageId: {
    id: 'ActionsState.blobStorageId',
    description: 'Blob storage ID detail text',
    defaultMessage: 'Blob storage ID:'
  },
  file: {
    id: 'ActionsState.file',
    description: 'file detail text',
    defaultMessage: 'File'
  },
  original: {
    id: 'ActionsState.original',
    description: 'Original text',
    defaultMessage: 'Original'
  },
  taskCreatedByOriginal: {
    id: 'ActionsState.taskCreatedByOriginal',
    description: 'Task created by text',
    defaultMessage: ' task created by'
  },
  canceled: {
    id: 'ActionsState.canceled',
    description: 'Canceled',
    defaultMessage: 'Canceled'
  },
  on: {
    id: 'ActionsState.on',
    description: 'On text',
    defaultMessage: 'on'
  },
  ok: {
    id: 'ActionsState.ok',
    description: 'Ok button label',
    defaultMessage: 'OK'
  },
  seconds: {
    id: 'ActionsState.seconds',
    description: 'seconds text',
    defaultMessage: '{number} seconds'
  },
  clear: {
    id: 'ActionsState.clear',
    description: 'Clear button label',
    defaultMessage: 'Clear'
  },
  dashboard: {
    id: 'ActionsState.dashboard',
    description: 'Dashboard detail text',
    defaultMessage: 'Dashboard:'
  }
})

export default messages
