import { utils } from 'ducks/login'

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      {
        success: ({ getState, dispatch, getSourceAction }, req) => {
          const ignoreAuth = process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTH_HTTP_API === 'false'

          if (!ignoreAuth) {
            const token = utils.getAccessToken(getState())
            if (token !== null) {
              req.headers['Authorization'] = 'Bearer ' + token // eslint-disable-line no-param-reassign
            }
          }

          return req
        },
        error: ({ getState, dispatch, getSourceAction }, error) => error
      }
    ],
    response: [
      {
        success: ({ getState, dispatch, getSourceAction }, resp) => resp,
        error: ({ getState, dispatch, getSourceAction }, error) => {
          return Promise.reject(error)
        }
      }
    ]
  }
}

const axiosPlus1ConnectMiddlewareOptions = {
  interceptors: {
    request: [
      {
        success: ({ getState, dispatch, getSourceAction }, req) => {
          const ignoreAuth = process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTH_HTTP_API === 'false'

          if (!ignoreAuth) {
            const token = utils.getAccessToken(getState())
            if (token !== null) {
              req.headers['Authorization'] = 'Bearer ' + token // eslint-disable-line no-param-reassign
            }
          }

          return req
        },
        error: ({ getState, dispatch, getSourceAction }, error) => error
      }
    ],
    response: [
      {
        success: ({ getState, dispatch, getSourceAction }, resp) => resp,
        error: ({ getState, dispatch, getSourceAction }, error) => {
          // FIXME remove it
          /*if (currentInterceptors.unauthorization !== null) {
          currentInterceptors.unauthorization(error)
        }*/

          // if (error.response) {
          //   if (error.response.status === 401) {
          //     // dispatch(actions.unauthorize())
          //     // console.log('UNAUTHORIZE')
          //   } else {
          //     // console.error(
          //     //   'http',
          //     //   error.response.data.error + ' (' + error.response.status + ') ' + ' on ' + error.response.data.path
          //     // )
          //   }
          // } else if (error.message) {
          //   // console.error('http', error.message)
          // } else {
          //   // console.error(error)
          // }
          // Problem:
          //  - If we don't reject something here, we will enter in a 'then' afterwards.
          //  - Returning the error, we force to catch all the requests or
          //    an error will be thrown in the console.
          return Promise.reject(error)
        }
      }
    ]
  }
}

export { axiosMiddlewareOptions, axiosPlus1ConnectMiddlewareOptions }
