export function twosComplementToDecimal(twosComplement, numberOfBits) {
  if (twosComplement < 0 || twosComplement > (1 << numberOfBits) - 1) {
    // eslint-disable-next-line no-console
    console.log(
      'Error in value transformation: Two\'s complement out of range given ' + numberOfBits + ' bits to represent.'
    )
    return null
  }

  if (twosComplement <= Math.pow(2, numberOfBits - 1) - 1) {
    return twosComplement
  } else {
    return -((~twosComplement & (1 << numberOfBits) - 1) + 1)
  }
}
