export const CONFIGURATIONS_BASE_CS100 = '/CS100/configurations'

export const NEW_CONFIGURATION_CS100 = CONFIGURATIONS_BASE_CS100 + '/configuration'
export const VIEW_CONFIGURATION_CS100 = CONFIGURATIONS_BASE_CS100 + '/:configurationId'
export const EDIT_CONFIGURATION_CS100 = CONFIGURATIONS_BASE_CS100 + '/:configurationId/edit'

const getConfigurationsUrl = () => CONFIGURATIONS_BASE_CS100
const getNewConfigurationUrl = () => NEW_CONFIGURATION_CS100
const getEditConfigurationUrl = configurationId => EDIT_CONFIGURATION_CS100.replace(':configurationId', configurationId)
const getViewConfigurationUrl = configurationId => VIEW_CONFIGURATION_CS100.replace(':configurationId', configurationId)

export { 
  getConfigurationsUrl,
  getNewConfigurationUrl,
  getEditConfigurationUrl,
  getViewConfigurationUrl
}
