export const CONFIGURATIONS_BASE_CS500 = '/CS500/configurations'

export const NEW_CONFIGURATION_CS500 = CONFIGURATIONS_BASE_CS500 + '/new'
export const VIEW_CONFIGURATION_CS500 = CONFIGURATIONS_BASE_CS500 + '/:deviceEid'
export const EDIT_CONFIGURATION_CS500 = CONFIGURATIONS_BASE_CS500 + '/:deviceEid/edit'

const getCS500ConfigurationsUrl = () => CONFIGURATIONS_BASE_CS500
const getNewCS500ConfigurationUrl = () => NEW_CONFIGURATION_CS500
const getEditCS500ConfigurationUrl = deviceEid => EDIT_CONFIGURATION_CS500.replace(':deviceEid', deviceEid)
const getViewCS500ConfigurationUrl = deviceEid => VIEW_CONFIGURATION_CS500.replace(':deviceEid', deviceEid)

export { 
  getCS500ConfigurationsUrl,
  getNewCS500ConfigurationUrl,
  getEditCS500ConfigurationUrl,
  getViewCS500ConfigurationUrl
}