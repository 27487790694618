// Given several group Ids => [ descendant1, descendant2, ..., descendantN ]
const _getDescendants = (groupIds = [], groupsById = {}, addParents = true) =>
  groupIds.reduce((ret, gId) => {
    if (addParents) {
      ret.push(gId)
    }
    if (groupsById[gId] && groupsById[gId].children) {
      ret.push(..._getDescendants(groupsById[gId].children, groupsById))
    }
    return ret
  }, [])

export { _getDescendants }
