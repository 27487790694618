import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import messages from './messages'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

import Alert from 'components/Alert'

import { logError } from 'utils/http'

import SSOSignUpLink from './SSOSignUpLink'

const styles = theme => ({
  userDoesNotExist: {
    color: 'red'
  }
})

class NewUser extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      userEmail: '',
      userEmailError: '',
      userDoesNotExistError: '',
      roles: [],
      selectedRole: ''
    }
  }

  componentDidMount() {
    this.getRoles()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupId !== this.props.groupId && this.props.groupId) this.getRoles()
  }

  getRoles = () => {
    this.props
      .getAllRoles(this.props.groupId)
      .then(response => {
        this.setState({
          roles: response.data.groupRoles
        })
      })
      .catch(response => {
        const { error } = { ...response }
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
            case 500:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
          }
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  handleChange = event => {
    if (event.target.name === 'email') {
      this.setState({
        userEmail: event.target.value,
        userEmailError: ''
      })
    } else {
      this.setState({
        selectedRole: event.target.value
      })
    }
  }

  validEmail = email => {
    /* eslint-disable max-len, no-useless-escape */
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /* eslint-enable */
    return re.test(String(email).toLowerCase())
  }

  addUserToGroup = () => {
    let error = false

    if (!this.validEmail(this.state.userEmail)) {
      error = true
      this.setState({
        userEmailError: this.formatMessage(messages.emailError)
      })
    }

    if (!error) {
      this.setState(
        {
          loading: true
        },
        async () => {
          try {
            if (this.state.roles.length > 0) {
              await this.props.addUsersToGroupWithRole(this.props.groupId, this.state.selectedRole, [
                this.state.userEmail
              ])
            } else {
              await this.props.addUser(this.props.groupId, [this.state.userEmail])
            }
            this.props.closeDialog()
            this.props.onAddUser()
          } catch (response) {
            const { error } = { ...response } // eslint-disable-line no-shadow

            if (error && error.response) {
              switch (error.response.status) {
                case 400:
                  let error400Checked = false
                  if (typeof error.response.data !== 'undefined') {
                    let errorReceived = ''
                    if (typeof error.response.data.message !== 'undefined') errorReceived = error.response.data.message
                    else errorReceived = error.response.data
                    /* eslint-disable max-len */
                    if (
                      errorReceived.includes(
                        'CAN NOT ADD THESE USERS TO THIS GROUP, USERS MENTIONED BELOW DOES NOT BELONG TO PLUS+1 CONNECT SYSTEM'
                      )
                    ) {
                      error400Checked = true
                      this.setState({
                        userDoesNotExistError: this.formatMessage(messages.userDoesntExist, {
                          here: <SSOSignUpLink text={this.formatMessage(messages.here)} />
                        }),
                        loading: false
                      })
                    }
                    /* eslint-enable */
                  }
                  if (!error400Checked) {
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                      alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
                    })
                  }
                  break
                case 401:
                case 403:
                case 404:
                case 406:
                case 409:
                case 415:
                case 422:
                case 500:
                  this.setState({
                    loading: false,
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                    alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
                  })
                  break
                default:
                  this.setState({
                    loading: false,
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                    alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                  })
                  logError(error)
              }
            } else {
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
            }
          }
        }
      )
    }
  }

  resetDialogState = () => {
    this.setState({
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      userEmail: '',
      userEmailError: '',
      userDoesNotExistError: '',
      selectedRole: ''
    })
  }

  render() {
    const addingButtonDisabled =
      this.state.loading ||
      this.state.userEmail === '' ||
      this.state.userEmailError !== '' ||
      this.state.alertMessages ||
      this.state.selectedRole === ''
    const { classes } = this.props
    const roles = this.state.roles.slice(0)
    if (roles.length === 0) roles.push({ id: 'fake-id', role_name: 'Default role' })
    return (
      <Dialog
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth="md"
        onExited={this.resetDialogState}
        open={this.props.open}
      >
        <DialogTitle>
          {this.formatMessage(messages.title)}{' '}
          <IconButton
            onClick={this.props.closeDialog}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container={true} spacing={3}>
            {this.state.alertMessages ? (
              <Grid item={true} xs={12}>
                <Alert
                  alertType={this.state.alertMessagesType}
                  messageText={this.state.alertMessagesText}
                  messageTitle={this.state.alertMessagesTitle}
                  showAlert={this.state.alertMessages}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item={true} md={6} xs={12}>
                  <FormControl error={this.state.userEmailError !== ''} fullWidth={true}>
                    <InputLabel htmlFor="email-label-placeholder" shrink={true}>
                      {this.formatMessage(messages.email)}*
                    </InputLabel>
                    <Input
                      autoFocus={true}
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.userEmail}
                    />
                    <FormHelperText>{this.state.userEmailError}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} md={6} xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="role-label-placeholder" shrink={true}>
                      {this.formatMessage(messages.role)}*
                    </InputLabel>
                    <Select
                      input={<Input id="role-label-placeholder" name="role" />}
                      onChange={this.handleChange}
                      value={this.state.selectedRole}
                    >
                      <MenuItem key="dinamicDataDefault" value="" />
                      {roles.map(role => {
                        return (
                          <MenuItem key={role.id} value={role.id}>
                            {role.role_name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </React.Fragment>
            )}
            {this.state.userDoesNotExistError && (
              <Grid classes={{ item: classes.userDoesNotExist }} item={true} xs={12}>
                {this.state.userDoesNotExistError}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogContentText />
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeDialog}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className="primary-action-button" disabled={addingButtonDisabled} onClick={this.addUserToGroup}>
            <span>{this.formatMessage(messages.add)}</span>
            {this.state.loading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

NewUser.propTypes = {
  addUser: PropTypes.func.isRequired,
  addUsersToGroupWithRole: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  getAllRoles: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  onAddUser: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default withStyles(styles)(injectIntl(NewUser))
