import React from 'react'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'

import { cleanEID } from '../../../../utils'
import TailMarker from './TailMarker'
import DirectionalMarker from './DirectionalMarker'
import GenericMarker from './GenericMarker'
import MarkerLines from './MarkerLines'

const MarkersAndLines = React.memo(props => {
  const {
    deviceColors,
    from,
    getFilteredAndOrderedGpsPoints,
    gpsTrackings,
    isRealtimeGpsTrackingRunning,
    onMarkerClick,
    queriedDevices,
    selectedDevices,
    to
  } = props

  function renderMarkerLines() {
    const devicesToDraw = isRealtimeGpsTrackingRunning ? selectedDevices : queriedDevices

    return devicesToDraw.map(device => {
      const gpsPoints = gpsTrackings[cleanEID(device.EID)]?.points ?? []
      const filteredAndOrderedGpsPoints = getFilteredAndOrderedGpsPoints(gpsPoints, from, to)
      const { color } = deviceColors.find(deviceColor => deviceColor.EID === device.EID) || {}

      return <MarkerLines key={device.id} color={color} gpsPoints={filteredAndOrderedGpsPoints} />
    })
  }

  function renderMarkers() {
    const initialPositionDevices = selectedDevices.filter(device => {
      const hasNoGpsPoints = (gpsTrackings[cleanEID(device.EID)]?.points ?? []).length === 0
      const hasInitialPosition = !_isEmpty(device.position)

      return hasNoGpsPoints && hasInitialPosition
    })

    const initialPositionMarkers = initialPositionDevices.map(device => {
      const { color } = deviceColors.find(deviceColor => deviceColor.EID === device.EID) || {}
      return (
        <DirectionalMarker
          key={`${device.id}`}
          color={color}
          headingAngle={device.position.heading}
          label={device.name}
          name={device.id}
          noHeading={device.position.heading === null || device.position.heading === 'NaN'}
          onClick={() => onMarkerClick(device, device.position)}
          position={device.position}
        />
      )
    })

    const devicesToDraw = isRealtimeGpsTrackingRunning ? selectedDevices : queriedDevices
    const devicesToDrawMarkers = devicesToDraw.map(device => {
      const gpsPoints = gpsTrackings[cleanEID(device.EID)]?.points ?? []
      const filteredAndOrderedGpsPoints = getFilteredAndOrderedGpsPoints(gpsPoints, from, to)
      const { color } = deviceColors.find(deviceColor => deviceColor.EID === device.EID) || {}

      return filteredAndOrderedGpsPoints.map((gpsPoint, index, self) => {
        let marker = null
        if (index === 0 && self.length > 1) {
          marker = (
            <TailMarker
              key={`${device.id}_${index}`}
              color={color}
              label={device.name}
              name={device.id}
              onClick={() => onMarkerClick(device, gpsPoint)}
              position={gpsPoint}
            />
          )
        } else if (index === self.length - 1) {
          marker = (
            <DirectionalMarker
              key={`${device.id}_${index}`}
              color={color}
              headingAngle={gpsPoint.heading}
              label={device.name}
              name={device.id}
              noHeading={gpsPoint.heading === null || gpsPoint.heading === 'NaN'}
              onClick={() => onMarkerClick(device, gpsPoint)}
              position={gpsPoint}
            />
          )
        } else {
          marker = (
            <GenericMarker
              key={`${device.id}_${index}`}
              color={color}
              label={device.name}
              name={device.id}
              onClick={() => onMarkerClick(device, gpsPoint)}
              position={gpsPoint}
            />
          )
        }
        return marker
      })
    })

    return [...initialPositionMarkers, ...devicesToDrawMarkers]
  }

  return (
    <React.Fragment>
      {renderMarkerLines()}
      {renderMarkers()}
    </React.Fragment>
  )
})

MarkersAndLines.propTypes = {
  deviceColors: PropTypes.array.isRequired,
  from: PropTypes.number.isRequired,
  getFilteredAndOrderedGpsPoints: PropTypes.func.isRequired,
  gpsTrackings: PropTypes.object.isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  queriedDevices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  to: PropTypes.number.isRequired
}

export default MarkersAndLines
