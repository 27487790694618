import moment from 'moment'

const LAST_PROMTED_FOR_CONSENT_TIME = 'lastPromptedForConsentTime'
const MIN_WAITING_TIME = moment.duration(1, 'week')

function getLastPromptedForConsentTime() {
  return Number(localStorage.getItem(LAST_PROMTED_FOR_CONSENT_TIME))
}

export function setLastPromptedForConsentTime() {
  const now = moment().format('x')
  localStorage.setItem(LAST_PROMTED_FOR_CONSENT_TIME, now)
}

export function getCanPromptForConsent() {
  const lastPromptedForConsentTime = getLastPromptedForConsentTime()
  const thresholdTime = moment(lastPromptedForConsentTime).add(MIN_WAITING_TIME)
  const now = moment()

  return now.isAfter(thresholdTime)
}
