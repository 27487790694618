import { connect } from 'react-redux'

import { getDinamicData, getDevicesData } from 'ducks/groupDashboards'
import { utils } from 'ducks/groups'

import Historic from './Historic'
import HistoricSettingsUnconnected from './HistoricSettings'
import HistoricTemplate from './HistoricTemplate'

const mapStateToProps = (state, { eid }) => {
  return {
    devicesData: getDevicesData(state),
    dinamicData: getDinamicData(state, eid),
    groupId: utils.getSelectedGroup(state).id
  }
}

export default connect(mapStateToProps)(Historic)
const HistoricSettings = connect(mapStateToProps)(HistoricSettingsUnconnected)
export { HistoricSettings, HistoricTemplate }
