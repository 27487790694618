import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import Alert from 'components/Alert'

import { SHARING_MODE, DEVICE_TYPES } from '../constants'
import messages from './messages'

import { dialogStyles } from '../../styles'

import { logError } from 'utils/http'

const styles = {
  ...dialogStyles
}

class ShareDataPlans extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alertMessage: '',
      isAlertShown: false,
      isLoading: false,
      numberOfDataPlansCs100: 0,
      numberOfDataPlansCs500: 0,
      selectedDataPlans: [],
      selectedGroupId: '',
      selectedGroupIdErrorText: '',
      sharingMode: null
    }

    const {
      intl: { formatMessage },
      classes
    } = props
    this.formatMessage = formatMessage
    this.classes = classes
  }

  handleShare = () => {
    const { selectedGroupId, selectedDataPlans, numberOfDataPlansCs100, numberOfDataPlansCs500, sharingMode } =
      this.state
    const {
      dataplans,
      getChildGroups,
      getGroup,
      groupId,
      onClose,
      onDataPlansShared,
      onShareDataPlans,
      portalGroupId,
      shareDataPlans,
      updateDataplans
    } = this.props

    let body = {}
    let numberOfDataPlans
    switch (sharingMode) {
      case SHARING_MODE.SHARE_ALL:
        body = {
          originGroupId: groupId,
          shareAll: true,
          resourceType: 'Data_plan'
        }
        numberOfDataPlans = dataplans.filter(dataplan => dataplan.dataplanStatusId === 1).length
        break
      case SHARING_MODE.SELECT_DATAPLANS:
        body = {
          originGroupId: groupId,
          resources: selectedDataPlans.map(dataplan => dataplan.id),
          resourceType: 'Data_plan'
        }
        numberOfDataPlans = selectedDataPlans.length
        break
      case SHARING_MODE.SHARE_BY_TYPE:
        body = {
          originGroupId: groupId,
          shareCountCS100: parseInt(numberOfDataPlansCs100),
          shareCountCS500: parseInt(numberOfDataPlansCs500),
          resourceType: 'Data_plan'
        }
        numberOfDataPlans = parseInt(numberOfDataPlansCs100) + parseInt(numberOfDataPlansCs500)
        break
      default:
    }

    this.setState(
      {
        isLoading: true
      },
      () => {
        shareDataPlans(selectedGroupId, body)
          .then(() => {
            const { name } = getGroup(selectedGroupId)
            onShareDataPlans(name, numberOfDataPlans)
            onClose()
            if (selectedGroupId === portalGroupId) {
              onDataPlansShared()
              updateDataplans()
            }
          })
          .catch(response => {
            logError(response)
            const { error } = { ...response }
            let alertMessage = this.formatMessage(messages.errorDataPlanShare)
            if (error?.response?.status === 400 && error?.response?.data.includes('NO DATA PLANS WERE FOUND')) {
              const childGroups = getChildGroups(groupId)
              const { name } = childGroups.find(childGroup => childGroup.id === selectedGroupId)
              alertMessage = this.formatMessage(messages.allTheDataPlansAlreadyShared, {
                groupName: '\'' + name + '\''
              })
            } else if (error?.response?.data) {
              alertMessage = error?.response?.data
            }
            this.setState({
              isAlertShown: true,
              alertMessage
            })
          })
          .finally(() => {
            this.setState({
              isLoading: false
            })
          })
      }
    )
  }

  handleSelectedGroupChange = (event, value) => {
    const id = value?.id || ''
    const selectedGroupIdErrorText = id === '' ? this.formatMessage(messages.thisFieldIsRequired) : ''
    this.setState({
      selectedGroupId: id,
      selectedGroupIdErrorText
    })
  }

  handleSelectedSharingModeChange = (event, value) => {
    const selectedSharingMode = value?.value || null
    this.setState({
      sharingMode: selectedSharingMode
    })
  }

  handleSelectedDataplans = (event, value) => {
    this.setState({
      selectedDataPlans: [...new Set([...value])]
    })
  }

  handleNumberOfDPsChange = (event, deviceType) => {
    const { value } = event.target

    if (deviceType === DEVICE_TYPES.CS100) {
      this.setState({
        numberOfDataPlansCs100: value
      })
    } else if (deviceType === DEVICE_TYPES.CS500) {
      this.setState({
        numberOfDataPlansCs500: value
      })
    }
  }

  handleExited = () => {
    this.setState({
      alertMessage: '',
      isAlertShown: false,
      isLoading: false,
      numberOfDataPlansCs100: 0,
      numberOfDataPlansCs500: 0,
      selectedDataPlans: [],
      selectedGroupId: '',
      selectedGroupIdErrorText: '',
      sharingMode: null
    })
  }

  getDropdownDistribution = () => {
    const {
      dataPlansLoaded,
      dataplans,
      getChildGroups,
      groupId,
      maxNumberOfDataPlansCs100,
      maxNumberOfDataPlansCs500
    } = this.props
    const {
      numberOfDataPlansCs100,
      numberOfDataPlansCs500,
      selectedDataPlans,
      selectedGroupId,
      selectedGroupIdErrorText,
      sharingMode
    } = this.state

    const childGroups = getChildGroups(groupId)
    const sharingModeOptions = [
      { name: this.formatMessage(messages.shareAll), value: SHARING_MODE.SHARE_ALL },
      { name: this.formatMessage(messages.selectDataPlans), value: SHARING_MODE.SELECT_DATAPLANS },
      { name: this.formatMessage(messages.shareByType), value: SHARING_MODE.SHARE_BY_TYPE }
    ]
    const dataplanOptions = dataplans.filter(dataplan => dataplan.dataplanStatusId === 1)

    let numberOfCs100DSPErrorText = ''
    let numberOfCs500DSPErrorText = ''
    if (numberOfDataPlansCs100 > maxNumberOfDataPlansCs100)
      numberOfCs100DSPErrorText = this.formatMessage(messages.youExceededTheMaximum)
    if (numberOfDataPlansCs100 < 0) numberOfCs100DSPErrorText = this.formatMessage(messages.invalidNumberOfDataPlans)
    if (numberOfDataPlansCs500 > maxNumberOfDataPlansCs500)
      numberOfCs500DSPErrorText = this.formatMessage(messages.youExceededTheMaximum)
    if (numberOfDataPlansCs500 < 0) numberOfCs500DSPErrorText = this.formatMessage(messages.invalidNumberOfDataPlans)

    const renderSubscriptionDays = subscriptionDays => subscriptionDays === 365 ? this.formatMessage(messages.oneYearServicePlan) : this.formatMessage(messages.xYearsServicePlan, {number: subscriptionDays / 365})
    
    switch (sharingMode) {
      case null:
      case SHARING_MODE.SHARE_ALL:
        return [
          <Grid key='groupSelect' item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={this.handleSelectedGroupChange}
              options={childGroups}
              renderInput={params => (
                <TextField
                  {...params}
                  error={selectedGroupIdErrorText !== ''}
                  helperText={selectedGroupIdErrorText}
                  label={this.formatMessage(messages.selectTheGroup)}
                />
              )}
              value={childGroups.find(group => group.id === selectedGroupId) || null}
            />
          </Grid>,
          <Grid key='sharingModeSelect' item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.value === value.value}
              onChange={this.handleSelectedSharingModeChange}
              options={sharingModeOptions}
              renderInput={params => <TextField {...params} label={this.formatMessage(messages.sharingMode)} />}
              value={sharingModeOptions.find(sharingModeOption => sharingModeOption.value === sharingMode) || null}
            />
          </Grid>
        ]
      case SHARING_MODE.SELECT_DATAPLANS:
        return [
          <Grid key='groupSelect' item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={this.handleSelectedGroupChange}
              options={childGroups}
              renderInput={params => (
                <TextField
                  {...params}
                  error={selectedGroupIdErrorText !== ''}
                  helperText={selectedGroupIdErrorText}
                  label={this.formatMessage(messages.selectTheGroup)}
                />
              )}
              value={childGroups.find(group => group.id === selectedGroupId) || null}
            />
          </Grid>,
          <Grid key='sharingModeSelect' item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.value === value.value}
              onChange={this.handleSelectedSharingModeChange}
              options={sharingModeOptions}
              renderInput={params => <TextField {...params} label={this.formatMessage(messages.sharingMode)} />}
              value={sharingModeOptions.find(sharingModeOption => sharingModeOption.value === sharingMode) || null}
            />
          </Grid>,
          <Grid key='dataPlanSelect' item sm={12} xs={12}>
            <Autocomplete
              disableClearable
              fullWidth
              getOptionLabel={option => option.id  + ' - ' + renderSubscriptionDays(option.subscriptionDays)}
              getOptionSelected={(option, value) => option.id === value.id}
              groupBy={option => option.deviceType}
              loading={!dataPlansLoaded}
              loadingText={this.formatMessage(messages.loadingDataPlansAutocomplete)}
              multiple
              onChange={this.handleSelectedDataplans}
              options={dataplanOptions.sort((a, b) => b.deviceType?.toString().localeCompare(a.deviceType?.toString()))}
              renderInput={params => <TextField {...params} label={this.formatMessage(messages.selectDataPlans)} />}
              value={selectedDataPlans}
            />
          </Grid>
        ]
      case SHARING_MODE.SHARE_BY_TYPE:
        return [
          <Grid key='groupSelect' item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={this.handleSelectedGroupChange}
              options={childGroups}
              renderInput={params => (
                <TextField
                  {...params}
                  error={selectedGroupIdErrorText !== ''}
                  helperText={selectedGroupIdErrorText}
                  label={this.formatMessage(messages.selectTheGroup)}
                />
              )}
              value={childGroups.find(group => group.id === selectedGroupId) || null}
            />
          </Grid>,
          <Grid key='sharingModeSelect' item sm={6} xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.value === value.value}
              onChange={this.handleSelectedSharingModeChange}
              options={sharingModeOptions}
              renderInput={params => <TextField {...params} label={this.formatMessage(messages.sharingMode)} />}
              value={sharingModeOptions.find(sharingModeOption => sharingModeOption.value === sharingMode) || null}
            />
          </Grid>,

          <Grid key='dataPlanCs100CountSelect' item sm={6} xs={12}>
            <TextField
              error={numberOfCs100DSPErrorText !== ''}
              fullWidth
              helperText={numberOfCs100DSPErrorText}
              inputProps={{
                min: 0,
                max: maxNumberOfDataPlansCs100
              }}
              label={this.formatMessage(messages.selectNumberOfCs100DataPlans)}
              onChange={event => this.handleNumberOfDPsChange(event, DEVICE_TYPES.CS100)}
              onWheel={event => event.target.blur()}
              type='number'
              value={numberOfDataPlansCs100}
            />
          </Grid>,
          <Grid key='dataPlanCs500CountSelect' item sm={6} xs={12}>
            <TextField
              error={numberOfCs500DSPErrorText !== ''}
              fullWidth
              helperText={numberOfCs500DSPErrorText}
              inputProps={{
                min: 0,
                max: maxNumberOfDataPlansCs500
              }}
              label={this.formatMessage(messages.selectNumberOfCs500DataPlans)}
              onChange={event => this.handleNumberOfDPsChange(event, DEVICE_TYPES.CS500)}
              onWheel={event => event.target.blur()}
              type='number'
              value={numberOfDataPlansCs500}
            />
          </Grid>
        ]
      default:
        break
    }
  }

  render() {
    const { open, onClose, maxNumberOfDataPlansCs100, maxNumberOfDataPlansCs500 } = this.props
    const {
      alertMessage,
      isAlertShown,
      isLoading,
      numberOfDataPlansCs100,
      numberOfDataPlansCs500,
      selectedDataPlans,
      selectedGroupId,
      sharingMode
    } = this.state
    const shareButtonDisabled =
      isLoading ||
      isAlertShown ||
      selectedGroupId === '' ||
      sharingMode === null ||
      sharingMode === SHARING_MODE.SELECT_DATAPLANS && selectedDataPlans.length === 0 ||
      sharingMode === SHARING_MODE.SHARE_BY_TYPE &&
        (numberOfDataPlansCs100 > maxNumberOfDataPlansCs100 || numberOfDataPlansCs100 < 0) ||
      sharingMode === SHARING_MODE.SHARE_BY_TYPE &&
        (numberOfDataPlansCs500 > maxNumberOfDataPlansCs500 || numberOfDataPlansCs500 < 0) ||
      sharingMode === SHARING_MODE.SHARE_BY_TYPE && numberOfDataPlansCs100 === 0 && numberOfDataPlansCs500 === 0

    return (
      <Dialog
        PaperProps={{ style: { borderRadius: 0, padding: 20 } }}
        disableBackdropClick
        fullWidth
        maxWidth='md'
        onExited={this.handleExited}
        open={open}
        scroll='paper'
      >
        <DialogTitle classes={{ root: this.classes.dialogTitle }}>
          <Grid alignItems='center' container>
            <Grid item xs={10}>
              {this.formatMessage(messages.title)}
            </Grid>
            <Grid container item justify='flex-end' xs={2}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: this.classes.dialogContent }}>
          {isAlertShown ? (
            <Alert alertType='danger' messageText={[alertMessage]} showAlert={isAlertShown} />
          ) : (
            <Grid container spacing={3}>
              {this.getDropdownDistribution()}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={onClose}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button className='primary-action-button' disabled={shareButtonDisabled} onClick={this.handleShare}>
            {this.formatMessage(messages.share)}
            {isLoading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ShareDataPlans.propTypes = {
  classes: PropTypes.object.isRequired,
  dataPlansLoaded: PropTypes.bool.isRequired,
  dataplans: PropTypes.array.isRequired,
  getChildGroups: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  maxNumberOfDataPlansCs100: PropTypes.number.isRequired,
  maxNumberOfDataPlansCs500: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onDataPlansShared: PropTypes.func,
  onShareDataPlans: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  portalGroupId: PropTypes.string.isRequired,
  shareDataPlans: PropTypes.func.isRequired,
  updateDataplans: PropTypes.func.isRequired
}

ShareDataPlans.defaultProps = {
  onDataPlansShared: i => i
}

export default withStyles(styles)(injectIntl(ShareDataPlans))
