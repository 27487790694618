const chartConfig = () => {
  return {
    chart: {
      type: 'column'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ]
        }
      }
    },
    xAxis: {
      categories: []
    },
    series: [
      {
        data: []
      }
    ]
  }
}

export default chartConfig
