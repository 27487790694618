import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import ConfigurationImportFromPC from './ConfigurationImportFromPC'
import ConfigurationsTableTitle from './ConfigurationsTableTitle'
import ConfigurationsTable from './ConfigurationsTable'

import DbcFileImportFromPC from 'modules/configurationsCS100/DbcFileImportFromPC'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { DEFAULT_GROUP_ID } from 'utils/constants'

import { withStyles } from '@material-ui/core/styles'

const styles = {
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }
}

class Configurations extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    let alertVisibility = 'hidden'
    const alertMessageType = 'success'
    const alertMessageTitle = ''
    let alertMessageText = ''
    if (props.location.state !== undefined && props.location.state === 'createdConfiguration') {
      alertVisibility = ''
      alertMessageText = this.formatMessage(messages.configurationCreatedSuccessfully)
    } else if (props.location.state !== undefined && props.location.state === 'updatedConfiguration') {
      alertVisibility = ''
      alertMessageText = this.formatMessage(messages.configurationUpdatedSuccessfully)
    }

    this.state = {
      configType: 0,
      alertVisibility,
      alertMessageType,
      alertMessageTitle,
      alertMessageText,
      dialogOpen: false,
      updateTable: false,
      dbcDialogOpen: false
    }
  }

  componentDidMount() {
    const { location, history } = this.props
    if (
      location.state !== undefined &&
      (location.state === 'createdConfiguration' || location.state === 'updatedConfiguration')
    )
      history.replace(location.pathname, undefined)
  }

  handleConfigTypeChange = (event, value) => {
    const { history } = this.props
    this.setState({
      configType: value
    })
    if (value === 1) {
      history.push('/CS500/configurations')
    }
  }

  handleAlertStateChange = args => {
    this.setState({
      ...args.alertVisibility !== undefined && { alertVisibility: args.alertVisibility },
      ...args.alertMessageType !== undefined && { alertMessageType: args.alertMessageType },
      ...args.alertMessageTitle !== undefined && { alertMessageTitle: args.alertMessageTitle },
      ...args.alertMessageText !== undefined && { alertMessageText: args.alertMessageText }
    })
  }

  handleCloseAlert = () => {
    this.handleAlertStateChange({
      alertVisibility: 'hidden',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true
    })
  }

  handleDbcDialogOpen = () => {
    this.setState({
      dbcDialogOpen: true
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDbcDialogClose = () => {
    this.setState({
      dbcDialogOpen: false
    })
  }

  handleUpdateTable = updateTable => {
    this.setState({
      updateTable
    })
  }

  render() {
    const {
      canCreateConfigurations,
      canDeleteConfigurations,
      canEditConfigurations,
      classes,
      deleteConfiguration,
      editConfigurationUrl,
      getConfigurations,
      groupId,
      loggedUserEmail,
      newConfigurationUrl,
      saveConfiguration,
      viewConfigurationUrl
    } = this.props
    const {
      alertMessageText,
      alertMessageTitle,
      alertMessageType,
      alertVisibility,
      configType,
      dbcDialogOpen,
      dialogOpen,
      updateTable
    } = this.state

    /* eslint-disable max-len */
    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <ConfigurationsTableTitle
              canCreateConfigurations={canCreateConfigurations}
              groupId={groupId}
              newConfigurationUrl={newConfigurationUrl}
              onDbcImportClick={this.handleDbcDialogOpen}
              onImportClick={this.handleDialogOpen}
            />
            <ConfigurationImportFromPC
              changeAlertState={this.handleAlertStateChange}
              dialogOpen={dialogOpen}
              groupId={groupId}
              handleDialogClose={this.handleDialogClose}
              handleUpdateTable={this.handleUpdateTable}
              saveConfiguration={saveConfiguration}
            />
            {groupId !== DEFAULT_GROUP_ID && (
              <DbcFileImportFromPC dialogOpen={dbcDialogOpen} onDialogClose={this.handleDbcDialogClose} />
            )}
            <div className={alertVisibility}>
              <div className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}>
                <button aria-label='Close' className='close' onClick={this.handleCloseAlert}>
                  <span aria-hidden='true'>×</span>
                </button>
                {alertMessageTitle !== '' && <h4>{alertMessageTitle}</h4>}
                <span className='h5'>{alertMessageText}</span>
              </div>
            </div>
          </div>
          <div className='row' style={{ margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <div>
                <Tabs
                  classes={{ indicator: classes.indicator }}
                  onChange={this.handleConfigTypeChange}
                  style={{
                    boxShadow:
                      '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
                    maxWidth: 'fit-content'
                  }}
                  value={configType}
                >
                  <Tab
                    label='CS100'
                    style={{
                      boxShadow:
                        '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
                      backgroundColor: configType === 0 ? 'white' : '#dcdcdc'
                    }}
                  />
                  <Tab
                    label='CS500'
                    style={{
                      boxShadow:
                        '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
                      backgroundColor: configType === 1 ? 'white' : '#dcdcdc'
                    }}
                  />
                </Tabs>
              </div>
              {configType === 0 && (
                <ConfigurationsTable
                  canDeleteConfigurations={canDeleteConfigurations}
                  canEditConfigurations={canEditConfigurations}
                  changeAlertState={this.handleAlertStateChange}
                  deleteConfiguration={deleteConfiguration}
                  editConfigurationUrl={editConfigurationUrl}
                  getConfigurations={getConfigurations}
                  groupId={groupId}
                  handleUpdateTable={this.handleUpdateTable}
                  loggedUserEmail={loggedUserEmail}
                  updateTable={updateTable}
                  viewConfigurationUrl={viewConfigurationUrl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Configurations.propTypes = {
  canCreateConfigurations: PropTypes.bool.isRequired,
  canDeleteConfigurations: PropTypes.bool.isRequired,
  canEditConfigurations: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  deleteConfiguration: PropTypes.func.isRequired,
  editConfigurationUrl: PropTypes.func.isRequired,
  getConfigurations: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loggedUserEmail: PropTypes.string.isRequired,
  newConfigurationUrl: PropTypes.string.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  viewConfigurationUrl: PropTypes.func.isRequired
}

Configurations.defaultProps = {
  groupId: ''
}

export default withRouter(withStyles(styles)(injectIntl(Configurations)))
