import { connect } from 'react-redux'

import { utils, actions } from 'ducks/login'

import HelpDropdown from './HelpDropdown'

const mapStateToProps = state => ({
  user: utils.getLoggedUser(state)
})

export default connect(mapStateToProps)(HelpDropdown)
