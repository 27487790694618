import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import { MAINTENANCE_CONFIGURATION_MODES } from '../../../constants'
import messages from '../../../messages'
import { alertStyles, linkButtonStyles } from '../../../styles'

const styles = {
  ...alertStyles,
  ...linkButtonStyles
}

class AvailableAdvancedSignalsAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAlertMoreInfoShown: false
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedDevicesEids } = this.props

    if (selectedDevicesEids.length !== prevProps.selectedDevicesEids.length) {
      this.setState({ isAlertMoreInfoShown: false })
    }
  }

  handleAlertSeeMoreClick = () => {
    this.setState({ isAlertMoreInfoShown: true })
  }

  handleAlertSeeLessClick = () => {
    this.setState({ isAlertMoreInfoShown: false })
  }

  renderAvailableAdvancedSignals = advancedSignals => {
    const { classes, intl } = this.props
    const { isAlertMoreInfoShown } = this.state

    const numberOfColumnsByDefault = 3
    const numberOfItemsPerColumn = 5
    const groupedSignals = advancedSignals.reduce((grouped, item, index) => {
      const currentGroupIndex = Math.floor(index / numberOfItemsPerColumn)

      if (!grouped[currentGroupIndex]) {
        return [...grouped, [item]]
      } else {
        return grouped.map((group, groupIndex) => (groupIndex === currentGroupIndex ? [...group, item] : group))
      }
    }, [])

    const isSeeMoreButtonNeeded = advancedSignals.length > numberOfColumnsByDefault * numberOfItemsPerColumn
    const numberOfSignalsNotShown = advancedSignals.length - numberOfColumnsByDefault * numberOfItemsPerColumn

    return (
      <Grid container>
        {groupedSignals
          .map((group, groupIndex) => {
            if (isAlertMoreInfoShown || groupIndex < numberOfColumnsByDefault)
              return (
                <Grid key={groupIndex} item xs={4}>
                  <ul>
                    {group.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </Grid>
              )
          })
          .filter(Boolean)}
        <Grid item xs={12}>
          {isSeeMoreButtonNeeded &&
            (isAlertMoreInfoShown ? (
              <span className={classes.linkButton} onClick={this.handleAlertSeeLessClick}>
                {intl.formatMessage(messages.seeLess)}
              </span>
            ) : (
              <span className={classes.linkButton} onClick={this.handleAlertSeeMoreClick}>
                {intl.formatMessage(messages.seeMore, { number: numberOfSignalsNotShown })}
              </span>
            ))}
        </Grid>
      </Grid>
    )
  }

  render() {
    const {
      availableAdvancedSignals,
      advancedSignalName,
      classes,
      intl,
      isSelectedDevicesModified,
      isSubStep,
      mode,
      selectedDevicesEids
    } = this.props

    const isNoDeviceSelected = isSubStep && selectedDevicesEids.length === 0 && isSelectedDevicesModified
    const isDevicesUncompatible =
      mode === MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION &&
      isSubStep &&
      selectedDevicesEids.length > 0 &&
      availableAdvancedSignals.length === 0

    const isUncompatibleDevicesWithConfiguredSignal =
      mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION &&
      isSubStep &&
      selectedDevicesEids.length > 0 &&
      availableAdvancedSignals.length === 0

    const isCompatibleDevicesWithConfiguredSignal =
      mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION && availableAdvancedSignals.length > 0

    const isCompatibleDevices =
      mode === MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION && availableAdvancedSignals.length > 0

    let alertSeverity
    let alertContent
    switch (true) {
      case isNoDeviceSelected:
        alertSeverity = 'warning'
        alertContent = (
          <React.Fragment>
            <AlertTitle classes={{ root: classes.alertTitle }}>
              {intl.formatMessage(messages.noMachineSelected)}
            </AlertTitle>
            {intl.formatMessage(messages.selectAtLeastOneMachine)}
          </React.Fragment>
        )
        break
      case isDevicesUncompatible:
        alertSeverity = 'warning'
        alertContent = (
          <React.Fragment>
            <AlertTitle classes={{ root: classes.alertTitle }}>
              {intl.formatMessage(messages.incompatibleMachines)}
            </AlertTitle>
            {intl.formatMessage(messages.machinesDontShareAdvancedSignal)}
          </React.Fragment>
        )
        break
      case isUncompatibleDevicesWithConfiguredSignal:
        alertSeverity = 'warning'
        alertContent = (
          <React.Fragment>
            <AlertTitle classes={{ root: classes.alertTitle }}>
              {intl.formatMessage(messages.incompatibleMachines)}
            </AlertTitle>
            {intl.formatMessage(messages.machinesDontShareConfiguredAdvancedSignal)}: <b>{advancedSignalName}</b>
          </React.Fragment>
        )
        break
      case isCompatibleDevicesWithConfiguredSignal:
        alertSeverity = 'info'
        alertContent = (
          <React.Fragment>
            <AlertTitle classes={{ root: classes.alertTitle }}>
              {intl.formatMessage(messages.configuredAdvancedSignal)}
            </AlertTitle>
            <b>{advancedSignalName}</b> <br />
            {intl.formatMessage(messages.allMachinesMustBeCompatible)}
          </React.Fragment>
        )
        break
      case isCompatibleDevices:
        alertSeverity = 'info'
        alertContent = (
          <React.Fragment>
            <AlertTitle classes={{ root: classes.alertTitle }}>
              {intl.formatMessage(messages.availableAdvancedSignals)}
            </AlertTitle>
            {this.renderAvailableAdvancedSignals(availableAdvancedSignals)}
          </React.Fragment>
        )
        break

      default:
        alertSeverity = ''
        alertContent = null
        break
    }

    return (
      alertContent && (
        <Grid item xs={12}>
          <Alert classes={{ message: classes.alertMessage }} severity={alertSeverity}>
            {alertContent}
          </Alert>
        </Grid>
      )
    )
  }
}

AvailableAdvancedSignalsAlert.propTypes = {
  advancedSignalName: PropTypes.string.isRequired,
  availableAdvancedSignals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isSelectedDevicesModified: PropTypes.bool.isRequired,
  isSubStep: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([
    MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION,
    MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
  ]).isRequired,
  selectedDevicesEids: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(AvailableAdvancedSignalsAlert))
