import { defineMessages } from 'react-intl'

const messages = defineMessages({
  markerLabel: {
    id: 'EditorMap.markerLabel',
    description: 'Select previous location label',
    defaultMessage: 'Select previous location'
  },
})

export default messages