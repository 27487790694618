import { defineMessages } from 'react-intl'

const namespace = 'Table'

const messages = defineMessages({
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  tableWidgetSettings: {
    id: `${namespace}.tableWidgetSettings`,
    description: 'Table widget settings',
    defaultMessage: 'Table widget settings'
  },
  tableType: {
    id: `${namespace}.tableType`,
    description: 'Table type',
    defaultMessage: 'Table type'
  },
  visualWidgetSettings: {
    id: `${namespace}.visualWidgetSettings`,
    description: 'Visual widget settings',
    defaultMessage: 'Visual widget settings'
  },
  condensedRows: {
    id: `${namespace}.condensedRows`,
    description: 'Condensed rows',
    defaultMessage: 'Condensed rows'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  tableWidget: {
    id: `${namespace}.tableWidget`,
    description: 'Table widget',
    defaultMessage: 'Table widget'
  },
  noDM1MessagesAvailable: {
    id: `${namespace}.noDM1MessagesAvailable`,
    description: 'No DM1 messages available warning text',
    defaultMessage: 'No DM1 messages available'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noGpsDataAvailable: {
    id: `${namespace}.noGpsDataAvailable`,
    description: 'No gps data available warning text',
    defaultMessage: 'No gps data available'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Nodes table total text showing rows',
    defaultMessage: 'Showing rows'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Nodes table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Nodes table total text of',
    defaultMessage: 'of'
  },
  exportToCsv: {
    id: `${namespace}.exportToCsv`,
    description: 'Export to CSV',
    defaultMessage: 'Export to CSV'
  },
  defaultRowNumber: {
    id: `${namespace}.defaultRowNumber`,
    description: 'Default row number',
    defaultMessage: 'Default row number'
  },
  dm1Messages: {
    id: `${namespace}.dm1Messages`,
    description: 'DM1 Messages',
    defaultMessage: 'DM1 Messages'
  },
  lastKnownValue: {
    id: `${namespace}.lastKnownValue`,
    description: 'Last known value',
    defaultMessage: 'Last known value'
  },
  outdatedWidget: {
    id: `${namespace}.outdatedWidget`,
    description: 'Outdated widget',
    defaultMessage: 'Outdated widget'
  }
})

export default messages
