import { defineMessages } from 'react-intl'

const messages = defineMessages({
  machines: {
    id: 'Sidebar.machines',
    description: 'Machines sidebar link',
    defaultMessage: 'Machines'
  },
  map: {
    id: 'Sidebar.map',
    description: 'PARTM sidebar map link',
    defaultMessage: 'Map'
  },
  notifications: {
    id: 'Sidebar.notifications',
    description: 'PARTM sidebar notifications link',
    defaultMessage: 'Notifications'
  },
  actionsState: {
    id: 'Sidebar.actionsState',
    description: 'PARTM sidebar actions state link',
    defaultMessage: 'Events history'
  },
  geofence: {
    id: 'Sidebar.geofence',
    description: 'PARTM sidebar geofence link',
    defaultMessage: 'Geofence'
  },
  reports: {
    id: 'Sidebar.reports',
    description: 'PARTM sidebar reports link',
    defaultMessage: 'Reports'
  },
  charts: {
    id: 'Sidebar.charts',
    description: 'PARTM sidebar charts link',
    defaultMessage: 'Charts'
  },
  gpstracking: {
    id: 'Sidebar.gpstracking',
    description: 'PARTM sidebar gps tracking link',
    defaultMessage: 'Geolocation'
  },
  gpstrackingCollapsed: {
    id: 'Sidebar.gpstrackingCollapsed',
    description: 'PARTM collapsed sidebar gps tracking link',
    defaultMessage: 'Geo-\ntracking'
  },
  configurations: {
    id: 'Sidebar.configurations',
    description: 'PARTM sidebar configurations link',
    defaultMessage: 'Configurations'
  },
  configurationsCollapsed: {
    id: 'Sidebar.configurationsCollapsed',
    description: 'PARTM collapsed sidebar configurations link',
    defaultMessage: 'Config.'
  },
  safety: {
    id: 'Sidebar.safety',
    description: 'PARTM sidebar safety link',
    defaultMessage: 'Safety'
  },
  stats: {
    id: 'Sidebar.stats',
    description: 'PARTM sidebar stats link',
    defaultMessage: 'Stats'
  },
  lifecycle: {
    id: 'Sidebar.lifecycle',
    description: 'PARTM sidebar lifecycle link',
    defaultMessage: 'Lifecycle'
  },
  inspections: {
    id: 'Sidebar.inspections',
    description: 'PARTM sidebar inspections state link',
    defaultMessage: 'Inspections'
  },
  addToHomeScreen: {
    id: 'Sidebar.addToHomeScreen',
    description: 'PARTM sidebar add to home screen link',
    defaultMessage: 'Add To Home Screen'
  },
  devices: {
    id: 'Sidebar.devices',
    description: 'PARTM sidebar devices screen link',
    defaultMessage: 'Device management'
  },
  devicesCollapsed: {
    id: 'Sidebar.devicesCollapsed',
    description: 'PARTM collapsed sidebar devices screen link',
    defaultMessage: 'Device manag.'
  },
  maintenances: {
    id: 'Sidebar.maintenances',
    defaultMessage: 'Maintenances'
  }
})

export default messages
