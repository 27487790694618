import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'

import { Map as MapComponent, Marker } from 'components/Map'

class Map extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: ''
    }
  }

  componentDidMount() {
    if (this.props.deviceId) {
      this.props.getDeviceInfo(this.props.deviceId, this.props.groupId)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceId !== this.props.deviceId && this.props.deviceId) {
      this.props.getDeviceInfo(this.props.deviceId, this.props.groupId)
    }
  }

  render() {
    if (this.props.deviceId) {
      const id = this.props.deviceId
      const name = ''
      let latitude = 38.19394
      let longitude = 15.55256
      let position = {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude)
      }

      if (this.props.location) {
        latitude = this.props.location.latitude
        longitude = this.props.location.longitude
        position = {
          latitude: latitude ? parseFloat(latitude) : undefined,
          longitude: longitude ? parseFloat(longitude) : undefined
        }
      }

      const defaultCenter = { latitude: latitude ? latitude : 38.19394, longitude: longitude ? longitude : 15.55256 }

      return (
        <MapComponent
          bounds={undefined}
          center={{ latitude: position.latitude, longitude: position.longitude }}
          defaultCenter={defaultCenter}
          fitBounds={undefined}
          options={{
            scaleControl: false,
            mapTypeControl: true,
            panControl: true,
            zoomControl: true,
            streetViewControl: false
          }}
          sat
          zoom={position.latitude && position.longitude ? 7 : 0}
        >
          {latitude && longitude && <Marker key={id} label={name} name={id} position={position} />}
        </MapComponent>
      )
    } else {
      return null
    }
  }
}

Map.propTypes = {
  deviceId: PropTypes.string,
  getDeviceInfo: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  location: PropTypes.object
}

Map.defaultProps = {
  deviceId: '',
  location: {}
}

export default Map
