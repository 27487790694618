import React from 'react'

import { injectIntl } from 'react-intl'
import messages from './messages'

class Undefined extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage
  }
  render() {
    return (
      <div className="PD-widget_content">
        <span
          style={{
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: -72,
            marginTop: -18,
            width: 144
          }}
        >
          {this.formatMessage(messages.errorLoadingComponent)}
        </span>
      </div>
    )
  }
}

export default injectIntl(Undefined)
