import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Roles from './Roles'
import EditRole from './EditRole'
import NewRole from './NewRole'
import { ROLES_BASE, ROLE_SETTINGS_NEW, ROLE_SETTINGS_EDIT } from './url'

const mapStateToProps = state => ({
  canCreateRoles: resolvePermissions(state, c.GROUPROLE_CREATE),
  canEditRoles: resolvePermissions(state, c.GROUPROLE_EDIT),
  canReadRoles: resolvePermissions(state, c.GROUPROLE_READ)
})

const RolesHome = ({ canCreateRoles, canEditRoles, canReadRoles }) => (
  <Switch>
    {canCreateRoles && <Route component={NewRole} path={ROLE_SETTINGS_NEW} />}
    {canEditRoles && <Route component={EditRole} path={ROLE_SETTINGS_EDIT} />}
    {canReadRoles && <Route component={Roles} path={ROLES_BASE} />}
  </Switch>
)

export default connect(mapStateToProps, null)(RolesHome)

export { RoleSelect } from './NewRole'
export { getRolesUrl } from './url'
export { getParentRole, processRolesHierarchyToSelect } from './utils'
