import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils, actions } from 'ducks/login'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'
import { utils as groupUtils } from 'ducks/groups'

import DisconnectedRouteHandler from './RouteHandler'

const mapStateToProps = state => ({
  cachedRegisterPath: utils.getCachedRegisterPath(),
  canReadActionsState: resolvePermissions(state, c.EVENTSHISTORY_VIEW),
  canReadCharts: groupUtils.hasPermission(state, c.CHARTS_READ),
  canReadConfig: resolvePermissions(state, c.DEVICECONFIGURATION_READ),
  canReadCsNodeDetails: resolvePermissions(state, c.NODEDETAILS_VIEW),
  canReadGeofence: resolvePermissions(state, c.GEOFENCE_READ),
  canReadGeotracking: groupUtils.hasPermission(state, c.GEOTRACKING_VIEW),
  canReadGroups: resolvePermissions(state, c.GROUPS_READ),
  canReadMap: resolvePermissions(state, c.MAP_READ),
  canReadNodes: resolvePermissions(state, c.DEVICE_READ),
  canReadNotifications: resolvePermissions(state, c.NOTIFICATION_READ),
  canReadNotificationsSettings: resolvePermissions(state, c.RULE_READ),
  canReadReports: groupUtils.hasPermission(state, c.SCHEDULE_READ),
  canReadRoles: groupUtils.hasPermission(state, c.GROUPROLE_READ),
  canReadUsers: resolvePermissions(state, c.USER_READ),
  canViewDeviceDashboards: resolvePermissions(state, c.DEVICEDASHBOARD_READ),
  canViewGroupDashboards: resolvePermissions(state, c.GROUPDASHBOARD_READ),
  groupId: groupUtils.getSelectedGroup(state).id,
  isLoggedIn: utils.isLoggedIn(state)
})

// TODO Log invalid route redirections!
const mapDispatchToProps = dispatch => bindActionCreators({ loadProfile: actions.loadProfile }, dispatch)

const RouteHandler = connect(mapStateToProps, mapDispatchToProps)(DisconnectedRouteHandler)

export { RouteHandler }
