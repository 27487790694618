import { defineMessages } from 'react-intl'

const messages = defineMessages({
  imageWidget: {
    id: 'ImageSettings.imageWidget',
    description: 'Image Widget',
    defaultMessage: 'IMAGE WIDGET'
  },
  currentImage: {
    id: 'ImageSettings.currentImage',
    description: 'Image widget',
    defaultMessage: 'Current image'
  },
  newImage: {
    id: 'ImageSettings.newImage',
    description: 'Image widget',
    defaultMessage: 'New image'
  },
  name: {
    id: 'ImageSettings.name',
    description: 'Image name',
    defaultMessage: 'Name:'
  },
  type: {
    id: 'ImageSettings.type',
    description: 'Image type',
    defaultMessage: 'Image type'
  },
  size: {
    id: 'ImageSettings.size',
    description: 'Image size',
    defaultMessage: 'Image size'
  },
  cancel: {
    id: 'ImageSettings.cancel',
    description: 'Image widget settings cancel button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'ImageSettings.save',
    description: 'Image widget settings confirm button text',
    defaultMessage: 'Save'
  },
  selectBooleanSignal: {
    id: 'ImageSettings.selectBooleanSignal',
    description: '',
    defaultMessage: 'Select boolean signal'
  },
  selectValueToDisplay: {
    id: 'ImageSettings.selectValueToDisplay',
    description: '',
    defaultMessage: 'Select value to display'
  },
  booleanSignalDependable: {
    id: 'ImageSettings.booleanSignalDependable',
    description: '',
    defaultMessage: 'Make this widget boolean signal dependable'
  },
  youMustChooseOneSignal: {
    id: 'ImageSettings.youMustChooseOneSignal',
    description: '',
    defaultMessage: 'You must choose a valid signal or disable the option otherwise'
  },
  urlMustStartWith: {
    id: 'ImageSettings.urlMustStartWith',
    description: 'Url must start with "https://" or "http://"',
    defaultMessage: 'Url must start with "https://" or "http://"'
  },
  url: {
    id: 'ImageSettings.url',
    description: 'Url',
    defaultMessage: 'Url'
  },
  required: {
    id: 'ImageSettings.required',
    description: 'Required',
    defaultMessage: 'Required'
  },
  thisFieldIsRequired: {
    id: 'ImageSettings.thisFieldIsRequired',
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  newImageWhenTrue: {
    id: 'ImageSettings.newImageWhenTrue',
    description: 'Image widget',
    defaultMessage: 'New image when conditional signal is TRUE'
  },
  newImageWhenFalse: {
    id: 'ImageSettings.newImageWhenFalse',
    description: 'Image widget',
    defaultMessage: 'New image when conditional signal is FALSE'
  },
  operator: {
    id: 'ImageSettings.operator',
    description: 'Operator',
    defaultMessage: 'Operator'
  },
  conditionalValue: {
    id: 'ImageSettings.conditionalValue',
    description: 'Value',
    defaultMessage: 'Value'
  },
  valueType: {
    id: 'ImageSettings.valueType',
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  value: {
    id: 'ImageSettings.value',
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueAvg: {
    id: 'ImageSettings.valueAvg',
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: 'ImageSettings.valueMin',
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: 'ImageSettings.valueMax',
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  }
})

export default messages
