import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addGroupDashboard: {
    id: 'GroupDashboards.addGroupDashboard',
    description: 'Add dashboard',
    defaultMessage: 'Add dashboard'
  },
  editingDashboard: {
    id: 'GroupDashboards.editingDashboard',
    description: 'Editing dashboard:',
    defaultMessage: 'EDITING DASHBOARD:'
  }
})

export default messages
