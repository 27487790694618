import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils as groupUtils } from 'ducks/groups'
import { actions, utils as configUtils } from 'ducks/configurations'

import ProtocolSelector from './ProtocolSelector'

const mapStateToProps = state => ({
  groupId: groupUtils.getSelectedGroup(state).id,
  isProtocolLoading: configUtils.getIsProtocolLoading(state),
  protocolList: configUtils.getProtocolList(state),
  isProtocolListLoading: configUtils.getIsProtocolListLoading(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadSelectedCanProtocol: (groupId, selectedCanProtocolName) =>
        actions.getSelectedCanProtocol(groupId, selectedCanProtocolName),
      getProtocols: actions.getCanProtocolList,
      handleProtocolChange: actions.changeLocalConfigurationSignalProtocolName,
      selectJ1939Signal: actions.addLocalConfigurationJ1939Signal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolSelector)
