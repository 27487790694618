import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import DataChip from 'components/DataChip'

import * as c from '../constants'
import { getEditNotificationGeneralPropertiesUrl } from '../url'

import './NotificationsRulesSettings.css'
import NotificationsRulesSettingsFilter from './NotificationsRulesSettingsFilter'
import messages from './messages'
import { composeLocalQuery } from './utils'

import reactBootstrapTable from 'utils/reactBootstrapTable'
import { client, logError } from 'utils/http'

const styles = {
  dialog: {
    minWidth: 600
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class NotificationsRulesSettingsTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage },
      classes
    } = props
    this.formatMessage = formatMessage
    this.classes = classes

    let notificationMessagesSuccessClass
    let notificationMessagesSuccessText

    if (props.message !== undefined && props.message !== '') {
      notificationMessagesSuccessClass = ''
      notificationMessagesSuccessText = props.message
    } else {
      notificationMessagesSuccessClass = 'hidden'
      notificationMessagesSuccessText = ''
    }

    this.state = {
      notifications: [],

      dialogOpen: false,
      dialogSelectedNotificationToDeleteTitle: '',
      dialogSelectedNotificationToDeleteId: '',
      dialogSelectedNotificationToDeleteVersion: '',

      notificationMessagesSuccessClass,
      notificationMessagesSuccessText,
      notificationMessagesDangerClass: 'hidden',
      notificationMessagesDangerTitle: '',
      notificationMessagesDangerText: '',

      count: reactBootstrapTable.count,
      length: reactBootstrapTable.length,
      elementsPerPage: reactBootstrapTable.elementsPerPage,
      page: reactBootstrapTable.page,

      filter: {},
      filterCriticality: { high: false, medium: false, low: false },
      filterEnabled: { yes: false, no: false }
    }
  }

  componentDidMount() {
    this.getNotifications()
  }

  componentDidUpdate(prevProps, prevState) {
    const { notificationsTotalsData } = this.state
    const { userSelectedGroup, selectedGroups } = this.props

    if (prevState.notificationsTotalsData !== notificationsTotalsData) {
      this.getNotifications()
    }

    if (prevProps.userSelectedGroup.id !== userSelectedGroup.id && userSelectedGroup.id) {
      this.getNotifications()
    }

    if (prevProps.selectedGroups !== selectedGroups) {
      this.setState(
        {
          page: 1
        },
        () => {
          this.getNotifications()
        }
      )
    }
  }

  getNotifications = () => {
    const { selectedGroups } = this.props
    const { filter, filterCriticality, filterEnabled, page, length } = this.state
    const { userSelectedGroup } = this.props

    const query = composeLocalQuery(filter, filterCriticality, filterEnabled, selectedGroups, page, length)
    const userSelectedGroupId = userSelectedGroup.id

    client
      .getRulesInstances(userSelectedGroupId, query)
      .then(response => {
        const notificationsCount = parseInt(response.headers['x-total-count'], 10)
        const notificationsToShow = response.data
        this.setState({
          notifications: notificationsToShow,
          count: notificationsCount
        })
      })
      .catch(response => {
        const error = { ...response }
        if (error.response !== undefined) {
          switch (error.response.status) {
            case 400: // Not user version
              this.setState({
                dialogOpen: false,
                dialogSelectedNotificationToDeleteTitle: '',
                dialogSelectedNotificationToDeleteId: '',
                dialogSelectedNotificationToDeleteVersion: '',
                notificationMessagesSuccessClass: 'hidden',
                notificationMessagesSuccessText: '',
                notificationMessagesDangerClass: '',
                notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '400' }),
                notificationMessagesDangerText: error.response.data.error_description + error.response.data.message
              })
              break
            case 404: // API url not found
              this.setState({
                dialogOpen: false,
                dialogSelectedNotificationToDeleteTitle: '',
                dialogSelectedNotificationToDeleteId: '',
                dialogSelectedNotificationToDeleteVersion: '',
                notificationMessagesSuccessClass: 'hidden',
                notificationMessagesSuccessText: '',
                notificationMessagesDangerClass: '',
                notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '404' }),
                notificationMessagesDangerText: this.formatMessage(messages.error404Text)
              })
              break
            case 500: // Server errors
              this.setState({
                dialogOpen: false,
                dialogSelectedNotificationToDeleteTitle: '',
                dialogSelectedNotificationToDeleteId: '',
                dialogSelectedNotificationToDeleteVersion: '',
                notificationMessagesSuccessClass: 'hidden',
                notificationMessagesSuccessText: '',
                notificationMessagesDangerClass: '',
                notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '500' }),
                notificationMessagesDangerText: error.response.data.error_description + error.response.data.message
              })
              break
            case 502: // API not avaiblable (ej: deploying the API)
              this.setState({
                dialogOpen: false,
                dialogSelectedNotificationToDeleteTitle: '',
                dialogSelectedNotificationToDeleteId: '',
                dialogSelectedNotificationToDeleteVersion: '',
                notificationMessagesSuccessClass: 'hidden',
                notificationMessagesSuccessText: '',
                notificationMessagesDangerClass: '',
                notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '502' }),
                notificationMessagesDangerText: this.formatMessage(messages.error502Text)
              })
              break
            default:
              this.setState({
                dialogOpen: false,
                dialogSelectedNotificationToDeleteTitle: '',
                dialogSelectedNotificationToDeleteId: '',
                dialogSelectedNotificationToDeleteVersion: '',
                notificationMessagesSuccessClass: 'hidden',
                notificationMessagesSuccessText: '',
                notificationMessagesDangerClass: '',
                notificationMessagesDangerTitle: this.formatMessage(messages.errorUndefinedTitle),
                notificationMessagesDangerText: this.formatMessage(messages.errorUndefinedText)
              })
              logError(response)
          }
        } else {
          this.setState({
            dialogOpen: false,
            dialogSelectedNotificationToDeleteTitle: '',
            dialogSelectedNotificationToDeleteId: '',
            dialogSelectedNotificationToDeleteVersion: '',
            notificationMessagesSuccessClass: 'hidden',
            notificationMessagesSuccessText: '',
            notificationMessagesDangerClass: '',
            notificationMessagesDangerTitle: this.formatMessage(messages.errorUndefinedTitle),
            notificationMessagesDangerText: this.formatMessage(messages.errorUndefinedText)
          })
          logError(response)
        }
      })
  }

  setFilterCriticality = criticality => {
    this.setState(
      {
        filterCriticality: criticality,
        page: 1
      },
      () => {
        this.getNotifications()
      }
    )
  }

  setFilterEnabled = enabled => {
    this.setState(
      {
        filterEnabled: enabled,
        page: 1
      },
      () => {
        this.getNotifications()
      }
    )
  }

  deleteNotification = hashId => {
    const { notifications } = this.state

    const theNotification = notifications.filter(notification => {
      return notification.hashId === hashId
    })

    const notificationTitle = theNotification[0].name + ' - ' + theNotification[0].description

    this.setState({
      dialogOpen: true,
      dialogSelectedNotificationToDeleteTitle: notificationTitle,
      dialogSelectedNotificationToDeleteId: hashId,
      dialogSelectedNotificationToDeleteVersion: theNotification[0].version
    })
  }

  handleDelete = () => {
    const { dialogSelectedNotificationToDeleteId, dialogSelectedNotificationToDeleteVersion } = this.state

    client
      .deleteRuleInstance(dialogSelectedNotificationToDeleteId, dialogSelectedNotificationToDeleteVersion)
      .then(() => {
        this.setState(
          {
            dialogOpen: false,
            dialogSelectedNotificationToDeleteTitle: '',
            dialogSelectedNotificationToDeleteId: '',
            dialogSelectedNotificationToDeleteVersion: '',
            notificationMessagesSuccessClass: '',
            notificationMessagesSuccessText: this.formatMessage(messages.notificationDeletedSuccessfully),
            notificationMessagesDangerClass: 'hidden',
            notificationMessagesDangerTitle: '',
            notificationMessagesDangerText: ''
          },
          () => {
            this.getNotifications()
          }
        )
      })
      .catch(response => {
        const error = { ...response }

        switch (error.response.status) {
          case 400: // Not user version
            this.setState({
              dialogOpen: false,
              dialogSelectedNotificationToDeleteTitle: '',
              dialogSelectedNotificationToDeleteId: '',
              dialogSelectedNotificationToDeleteVersion: '',
              notificationMessagesSuccessClass: 'hidden',
              notificationMessagesSuccessText: '',
              notificationMessagesDangerClass: '',
              notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '400' }),
              notificationMessagesDangerText: this.formatMessage(messages.error400Text)
            })
            break
          case 404: // API url not found
            this.setState({
              dialogOpen: false,
              dialogSelectedNotificationToDeleteTitle: '',
              dialogSelectedNotificationToDeleteId: '',
              dialogSelectedNotificationToDeleteVersion: '',
              notificationMessagesSuccessClass: 'hidden',
              notificationMessagesSuccessText: '',
              notificationMessagesDangerClass: '',
              notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '404' }),
              notificationMessagesDangerText: this.formatMessage(messages.error404Text)
            })
            break
          case 428:
            this.setState({
              dialogOpen: false,
              dialogSelectedNotificationToDeleteTitle: '',
              dialogSelectedNotificationToDeleteId: '',
              dialogSelectedNotificationToDeleteVersion: '',
              notificationMessagesSuccessClass: 'hidden',
              notificationMessagesSuccessText: '',
              notificationMessagesDangerClass: '',
              notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '428' }),
              notificationMessagesDangerText: this.formatMessage(messages.error428Text)
            })
            break
          case 500: // Server errors
            this.setState({
              dialogOpen: false,
              dialogSelectedNotificationToDeleteTitle: '',
              dialogSelectedNotificationToDeleteId: '',
              dialogSelectedNotificationToDeleteVersion: '',
              notificationMessagesSuccessClass: 'hidden',
              notificationMessagesSuccessText: '',
              notificationMessagesDangerClass: '',
              notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '500' }),
              notificationMessagesDangerText: error.response.data.error_description + error.response.data.message
            })
            break
          case 502: // API not avaiblable (ej: deploying the API)
            this.setState({
              dialogOpen: false,
              dialogSelectedNotificationToDeleteTitle: '',
              dialogSelectedNotificationToDeleteId: '',
              dialogSelectedNotificationToDeleteVersion: '',
              notificationMessagesSuccessClass: 'hidden',
              notificationMessagesSuccessText: '',
              notificationMessagesDangerClass: '',
              notificationMessagesDangerTitle: this.formatMessage(messages.error, { number: '502' }),
              notificationMessagesDangerText: this.formatMessage(messages.error502Text)
            })
            break
          default:
            this.setState({
              dialogOpen: false,
              dialogSelectedNotificationToDeleteTitle: '',
              dialogSelectedNotificationToDeleteId: '',
              dialogSelectedNotificationToDeleteVersion: '',
              notificationMessagesSuccessClass: 'hidden',
              notificationMessagesSuccessText: '',
              notificationMessagesDangerClass: '',
              notificationMessagesDangerTitle: this.formatMessage(messages.errorUndefinedTitle),
              notificationMessagesDangerText: this.formatMessage(messages.errorUndefinedText)
            })
            logError(response)
        }
      })
  }

  formatCriticality = cell => {
    const { intl } = this.props

    const textForChip = cell.toLowerCase()
    return <DataChip chipText={textForChip} inline intl={intl} />
  }

  formatStatus = cell => {
    const statusText = cell.toLowerCase()
    return statusText
  }

  formatEnabled = cell => {
    const { intl } = this.props

    let currentRowIsEnabled = 'EnabledNo'
    if (cell === true) {
      currentRowIsEnabled = 'EnabledYes'
    }
    return <DataChip chipText={currentRowIsEnabled} inline intl={intl} />
  }

  formatEventName = (cell, row) => {
    let eventName = ''
    if (row.name !== undefined) {
      eventName = row.name
    }

    return eventName
  }

  formatEventDescription = (cell, row) => {
    let eventDescription = ''
    if (row.description !== undefined && row.description !== '') {
      eventDescription = row.description
    }

    return eventDescription
  }

  formatEventDescriptionTitle = (cell, row) => {
    let titleToShow = ''
    if (row.description !== undefined && row.description !== '') {
      titleToShow = row.description
    }
    return titleToShow
  }

  formatActionsDetail = (cell, row) => {
    const { availableRules, canEditNotification, canDeleteNotification } = this.props

    const validRuleNames = Object.values(c.RULE_TYPE_NAMES)
    const availableRuleIds = availableRules.filter(rule => validRuleNames.includes(rule.name)).map(rule => rule.hashId)

    const notificationHashIdValue = row.hashId
    return (
      <div>
        {canEditNotification && availableRuleIds.includes(row.ruleHashId) && (
          <Link
            className='button-link'
            style={{ marginLeft: 10 }}
            to={getEditNotificationGeneralPropertiesUrl(notificationHashIdValue)}
          >
            <Tooltip classes={{ tooltip: this.classes.tooltip }} title={this.formatMessage(messages.editNotification)}>
              <IconButton style={{ padding: '5px' }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Link>
        )}
        {canDeleteNotification && availableRuleIds.includes(row.ruleHashId) && (
          <Tooltip classes={{ tooltip: this.classes.tooltip }} title={this.formatMessage(messages.deleteNotification)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <IconButton onClick={() => this.deleteNotification(notificationHashIdValue)} style={{ padding: '5px' }}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      {
        page,
        length: sizePerPage,
        notifications: []
      },
      () => {
        this.getNotifications()
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        notifications: []
      },
      () => {
        this.getNotifications()
      }
    )
  }

  handleFilterChange = filterObj => {
    this.setState(
      {
        filter: filterObj,
        page: 1
      },
      () => {
        this.getNotifications()
      }
    )

    return false
  }

  handleCloseSuccessAlertClick = () => {
    this.setState({
      notificationMessagesSuccessClass: 'hidden',
      notificationMessagesSuccessText: ''
    })
  }

  handleCloseDangerAlertClick = () => {
    this.setState({
      notificationMessagesDangerClass: 'hidden',
      notificationMessagesDangerTitle: '',
      notificationMessagesDangerText: ''
    })
  }

  handleCloseDialogClick = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedNotificationToDeleteTitle: '',
      dialogSelectedNotificationToDeleteId: '',
      dialogSelectedNotificationToDeleteVersion: ''
    })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  renderActionsColumn = () => {
    const { canEditNotification, canDeleteNotification } = this.props

    if (canEditNotification || canDeleteNotification) {
      return (
        <TableHeaderColumn
          dataAlign='center' //No ponemos hashId porque la primera columna ya tiene asignado ese campo y sino no nos permite buscar en la primera columna
          dataField='version'
          dataFormat={this.formatActionsDetail}
          headerAlign='center'
          width='80'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    } else {
      return <TableHeaderColumn visible='false' width='0' />
    }
  }

  renderDialogActions = formatMessage => []

  render() {
    const { elementsPerPage, length, page, notifications, count } = this.state

    const tableOptions = {
      // No data
      noDataText: this.formatMessage(messages.noNotificationsAvailable),

      // Page size select
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: elementsPerPage, // you can change the dropdown list for size per page
      sizePerPage: length, // which size per page you want to locate as default
      page, // which page you want to show as default

      // Pagination
      onPageChange: this.handlePageChange,
      ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      prePage: this.formatMessage(messages.prePage), // Previous page button text
      nextPage: this.formatMessage(messages.nextPage), // Next page button text
      firstPage: this.formatMessage(messages.firstPage), // First page button text
      lastPage: this.formatMessage(messages.lastPage), // Last page button text
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage), // Accept bool or function
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      hideSizePerPage: false, // You can hide the dropdown for sizePerPage
      alwaysShowAllBtns: false, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button

      // Filter options
      onFilterChange: this.handleFilterChange
    }

    const statusFilterOptions = {
      created: this.formatMessage(messages.created),
      compiled: this.formatMessage(messages.compiled),
      packaged: this.formatMessage(messages.packaged),
      deployed: this.formatMessage(messages.deployed),
      running: this.formatMessage(messages.running),
      failed: this.formatMessage(messages.failed)
    }

    const {
      notificationMessagesSuccessClass,
      notificationMessagesSuccessText,
      notificationMessagesDangerClass,
      notificationMessagesDangerTitle,
      notificationMessagesDangerText,
      dialogOpen,
      dialogSelectedNotificationToDeleteTitle
    } = this.state

    return (
      <div className='container-fluid notifications' style={{ margin: '20px 0 0 0' }}>
        <div className={notificationMessagesSuccessClass}>
          <div className='alert alert-success alert-dismissible animated fadeIn'>
            <button aria-label='Close' className='close' onClick={this.handleCloseSuccessAlertClick}>
              <span aria-hidden='true'>x</span>
            </button>
            <span className='h5'>{notificationMessagesSuccessText}</span>
          </div>
        </div>
        <div className={notificationMessagesDangerClass}>
          <div className='alert alert-danger alert-dismissible animated fadeIn'>
            <button aria-label='Close' className='close' onClick={this.handleCloseDangerAlertClick}>
              <span aria-hidden='true'>x</span>
            </button>
            <h4>{notificationMessagesDangerTitle}</h4>
            <span className='h5'>{notificationMessagesDangerText}</span>
          </div>
        </div>

        <NotificationsRulesSettingsFilter
          setFilterCriticality={this.setFilterCriticality}
          setFilterEnabled={this.setFilterEnabled}
        />
        <br />

        <Paper style={{ borderRadius: 0 }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              csvFileName='xxx.csv'
              data={notifications}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={remoteObj => ({
                ...remoteObj,
                search: false,
                pagination: true,
                sizePerPage: true,
                sort: false,
                filter: true
              })}
              search={false}
              searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
              striped={false}
            >
              <TableHeaderColumn
                dataField='hashId'
                dataFormat={this.formatEventName}
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.textPlaceholder) + ' ' + this.formatMessage(messages.event)
                }}
                isKey
                width='80'
              >
                {this.formatMessage(messages.event)}
              </TableHeaderColumn>
              <TableHeaderColumn
                columnTitle={this.formatEventDescriptionTitle}
                dataField='description'
                dataFormat={this.formatEventDescription}
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder:
                    this.formatMessage(messages.textPlaceholder) + ' ' + this.formatMessage(messages.description)
                }}
                width='180'
              >
                {this.formatMessage(messages.description)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='status'
                dataFormat={this.formatStatus}
                filter={{
                  type: 'SelectFilter',
                  options: statusFilterOptions,
                  delay: 400,
                  placeholder:
                    this.formatMessage(messages.selectPlaceholder) + ' ' + this.formatMessage(messages.status)
                }}
                formatExtraData={statusFilterOptions}
                width='60'
              >
                {this.formatMessage(messages.status)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='criticality' dataFormat={this.formatCriticality} width='60'>
                {this.formatMessage(messages.severity)}
              </TableHeaderColumn>

              {/*
                  <TableHeaderColumn dataField="groupName" width="90">
                    {this.formatMessage(messages.group)}
                  </TableHeaderColumn>
                  */}

              <TableHeaderColumn dataField='devicesCount' width='40'>
                {this.formatMessage(messages.machines)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='usersCount' width='40'>
                {this.formatMessage(messages.users)}
              </TableHeaderColumn>
              <TableHeaderColumn dataField='enabled' dataFormat={this.formatEnabled} width='50'>
                {this.formatMessage(messages.enabled)}
              </TableHeaderColumn>
              {this.renderActionsColumn()}
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>

        <Dialog fullWidth maxWidth='md' onClose={this.handleCloseDialogClick} open={dialogOpen}>
          <DialogTitle>{this.formatMessage(messages.confirmNotificationToDelete)}</DialogTitle>
          <DialogContent>{dialogSelectedNotificationToDeleteTitle}</DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleCloseDialogClick} style={{ marginRight: 10 }}>
              {this.formatMessage(messages.cancel)}
            </Button>
            <Button className='delete-button' onClick={this.handleDelete} style={{ minWidth: 100 }}>
              {this.formatMessage(messages.delete)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

NotificationsRulesSettingsTable.contextTypes = {
  router: PropTypes.object.isRequired
}

NotificationsRulesSettingsTable.propTypes = {
  availableRules: PropTypes.array.isRequired,
  canDeleteNotification: PropTypes.bool.isRequired,
  canEditNotification: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  selectedGroups: PropTypes.array,
  userSelectedGroup: PropTypes.object
}

NotificationsRulesSettingsTable.defaultProps = {
  selectedGroups: [],
  userSelectedGroup: null
}

export default withStyles(styles)(injectIntl(NotificationsRulesSettingsTable))
