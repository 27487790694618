import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../../../actions'

import EditGeofence from './EditGeofence'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateGeofence: actions.updateGeofence,
      updateRuleInstance: actions.updateRuleInstance
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(EditGeofence)
