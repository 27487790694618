import { actions } from 'utils/http'

export const getNumberOfAccessPasses = groupId => dispatch =>
  dispatch(actions.plus1Connect.getNumberOfAccessPasses(groupId)).promise.then(({ payload }) => payload)

export const manageAccessPassConsumption = (id, groupId, allow_consumption) => dispatch =>
  dispatch(actions.plus1Connect.manageAccessPassConsumption(id, groupId, allow_consumption)).promise.then(
    ({ payload }) => payload
  )

export const shareAccessPasses = (targetGroupId, body) => dispatch =>
  dispatch(actions.plus1Connect.shareAccessPasses(targetGroupId, body)).promise.then(({ payload }) => payload)
