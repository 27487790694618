import { createSelector } from 'reselect'

import { MOUNTED_AT as ROOT_MOUNT } from '../../constants'
import * as t from '../../actionTypes'

import update from 'immutability-helper'
import baseConfig from './baseConfig'

const createReducer = (
  plotStrategy,
  { selectInNavigator, pushExtreme, createSetExtremes },
  { ...extraArgs }
) => {
  const initialMergedConfig = update(baseConfig, {
    xAxis: {
      $merge: {
        events: {
          setExtremes: createSetExtremes(selectInNavigator, pushExtreme)
        }
      }
    }
  })

  // Warning, changes in this reducer cause the chart to be re-rendered.
  return (state = initialMergedConfig, action) => {
    if (!action) {
      return state
    }

    switch (action.type) {
      case t.SET_TITLE:
        return update(state, {
          title: {
            text: { $set: action.payload }
          }
        })

      case t.UPDATE_CHART_DATA:
        const { baseTime, browserDelay } = action.meta
        return plotStrategy(state, action.payload, {
          yAxisMax: state.yAxis.max,
          yAxisMin: state.yAxis.min,
          ...{ baseTime, browserDelay, ...extraArgs }
        })

      case t.UPDATE_CHART_CONFIGURATION:
        return update(state, action.payload)

      case t.TOGGLE_NAVIGATOR_VISIBILITY:
        return update(state, {
          navigator: {
            enabled: { $set: !state.navigator.enabled }
          }
        })

      case t.TOGGLE_NORMAL:
        return update(state, {
          chart: {
            zoomType: { $set: 'x' }
          },
          navigator: {
            enabled: { $set: true }
          }
        })

      case t.TOGGLE_REAL_TIME:
        return update(state, {
          chart: {
            zoomType: { $set: undefined }
          },
          navigator: {
            enabled: { $set: false }
          }
        })

      case t.SET_XAXIS_LIMITS:
        const { max, min } = action.payload
        const xAxisUpdate = {}
        if (min) {
          xAxisUpdate.min = min
        }
        if (max) {
          xAxisUpdate.max = max
        }
        return update(state, { xAxis: { $merge: xAxisUpdate } })

      case t.SHOW_NAVIGATOR:
        return update(state, {
          navigator: {
            enabled: { $set: true }
          }
        })

      case t.HIDE_NAVIGATOR:
        return update(state, {
          navigator: {
            enabled: { $set: false }
          }
        })

      case t.SHOW_SERIE:
      case t.HIDE_SERIE:
        return update(state, {
          series: {
            $set: state.series.map(s => {
              if (s.serieId === action.payload) {
                return { ...s, visible: action.type === t.SHOW_SERIE}
              }
              return s
            })
          }
        })

      default:
        return state
    }
  }
}

export const MOUNTED_AT = 'config'

// Selectors
const getConfig = substate => substate[ROOT_MOUNT][MOUNTED_AT]
const isNavigatorEnabled = substate => getConfig(substate).navigator.enabled

const getSeries = createSelector(getConfig, config =>
  Object.values(config.series)
)
const getLowerLimit = substate => getConfig(substate).xAxis.min
const getUpperLimit = substate => getConfig(substate).xAxis.max

export {
  createReducer,
  getConfig,
  isNavigatorEnabled,
  getSeries,
  getLowerLimit,
  getUpperLimit
}
