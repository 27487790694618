import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Icon from '@material-ui/core/Icon'
import ListSubheader from '@material-ui/core/ListSubheader'

import DropdownMenu from 'components/DropdownMenu'

import LinkedMenuItem from '../LinkedMenuItem'

import messages from './messages'

const SettingsDropdown = ({
  usersUrl,
  rolesUrl,
  groupsUrl,
  notificationsUrl,
  canReadUser = false,
  canReadGroup = false,
  canReadNotification = false,
  canReadRole = false,
  intl,
  ...other
}) => {
  const { formatMessage } = intl
  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50,
        width: 64
      }}
    >
      <DropdownMenu
        icon={
          <Icon
            className='fas fa-user-cog'
            style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: '20px', width: '1.3em', lineHeight: 1.1 }}
          />
        }
        subheader={
          <ListSubheader
            style={{
              opacity: 0.5,
              fontSize: 16,
              textAlign: 'start',
              fontWeight: 'bold',
              color: 'black',
              paddingTop: 10
            }}
          >
            {formatMessage(messages.management)}
          </ListSubheader>
        }
        {...other}
      >
        {canReadUser && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi-account-o' />}
            label={formatMessage(messages.users)}
            to={usersUrl}
          />
        )}
        {canReadRole && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi-lock-outline' />}
            label={formatMessage(messages.roles)}
            to={rolesUrl}
          />
        )}
        {canReadGroup && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi-accounts-outline' />}
            label={formatMessage(messages.groups)}
            to={groupsUrl}
          />
        )}
        {canReadNotification && (
          <LinkedMenuItem
            icon={<Icon className='zmdi zmdi zmdi-notifications-none' />}
            label={formatMessage(messages.notifications)}
            to={notificationsUrl}
          />
        )}
      </DropdownMenu>
    </div>
  )
}

SettingsDropdown.propTypes = {
  canReadGroup: PropTypes.bool.isRequired,
  canReadNotification: PropTypes.bool.isRequired,
  canReadRole: PropTypes.bool.isRequired,
  canReadUser: PropTypes.bool.isRequired,
  groupsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  notificationsUrl: PropTypes.string.isRequired,
  rolesUrl: PropTypes.string.isRequired,
  usersUrl: PropTypes.string.isRequired
}

export default injectIntl(SettingsDropdown)
