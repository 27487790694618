import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import LaunchIcon from '@material-ui/icons/Launch'

import ConsentAcceptedStep from './ConsentAcceptedStep'
import ConsentRequestStep from './ConsentRequestStep'
import ServerErrorAlert from './ServerErrorAlert'
import { getAdaptedUserDetailsData } from './apiAdapters'
import { getMappedConsentState } from './apiMappings'
import { CONSENT_STATES, STEPS } from './constants'
import { getCanPromptForConsent, setLastPromptedForConsentTime } from './helpers'
import messages from './messages'
import { dialogStyles, iconButtonStyles, paperStyles } from './styles'

const styles = {
  ...dialogStyles,
  ...iconButtonStyles,
  ...paperStyles
}

class SmsServiceConsentDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserDataLoading: false,
      isAcceptConsentLoading: false,
      isDeclineConsentLoading: false,
      isIpAddressLoading: false,
      isUserDataApiError: false,
      isConsentUpdateApiError: false,
      isIpAddressApiError: false,
      currentStep: STEPS.CONSENT_REQUEST,
      userId: '',
      isPhoneNumberPresent: false,
      ipAddress: ''
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    const { isSmsServiceConsentDialogOpen } = this.props

    if (isSmsServiceConsentDialogOpen && !prevProps.isSmsServiceConsentDialogOpen) {
      this.setState({ currentStep: STEPS.CONSENT_REQUEST })
      this.fetchData()
    }
  }

  fetchData = () => {
    this.fetchUserData()
    // this.fetchIpAddress()
  }

  fetchUserData = () => {
    const { location, openSmsServiceConsentDialog, fetchUserDetails } = this.props
    const { isUserDataLoading } = this.state
    if (isUserDataLoading) return

    const isBackFromMyProfile = new URLSearchParams(location.search).has('isBackFromMyProfile')
    const canPromptForConsent = getCanPromptForConsent()

    this.setState({ userId: '', isPhoneNumberPresent: false, isUserDataLoading: true, isUserDataApiError: false })
    fetchUserDetails()
      .then(response => {
        const userData = getAdaptedUserDetailsData(response.data)
        if ((isBackFromMyProfile || canPromptForConsent) && userData?.consentState === CONSENT_STATES.PENDING) {
          openSmsServiceConsentDialog()
        }
        this.setState({ userId: userData?.id, isPhoneNumberPresent: userData?.isPhoneNumberPresent })
      })
      .catch(() => {
        this.setState({ isUserDataApiError: true })
      })
      .finally(() => {
        this.setState({ isUserDataLoading: false })
      })
  }

  fetchIpAddress = () => {
    const { isIpAddressLoading } = this.state
    if (isIpAddressLoading) return

    this.setState({ ipAddress: '', isIpAddressLoading: true, isIpAddressApiError: false })
    fetch('https://geolocation-db.com/json/')
      .then(response => response.json())
      .then(response => {
        this.setState({ ipAddress: response.IPv4 })
      })
      .catch(() => {
        this.setState({ isIpAddressApiError: true })
      })
      .finally(() => {
        this.setState({ isIpAddressLoading: false })
      })
  }

  handleCloseClick = (e, reason) => {
    const { closeSmsServiceConsentDialog } = this.props
    const { currentStep } = this.state

    if (currentStep === STEPS.CONSENT_REQUEST && !reason || currentStep === STEPS.CONSENT_ACCEPTED) {
      closeSmsServiceConsentDialog()
      setLastPromptedForConsentTime()
    }
  }

  handleDeclineClick = () => {
    const { closeSmsServiceConsentDialog, updateUserConsent } = this.props
    const { ipAddress, userId } = this.state

    this.setState({ isConsentUpdateApiError: false, isDeclineConsentLoading: true })
    updateUserConsent(userId, getMappedConsentState(CONSENT_STATES.DECLINED, true), ipAddress)
      .then(() => {
        closeSmsServiceConsentDialog()
      })
      .catch(() => {
        this.setState({ isConsentUpdateApiError: true })
      })
      .finally(() => {
        this.setState({ isDeclineConsentLoading: false })
      })
  }

  handleAcceptClick = () => {
    const { closeSmsServiceConsentDialog, updateUserConsent } = this.props
    const { ipAddress, isPhoneNumberPresent, userId } = this.state

    this.setState({ isAcceptConsentLoading: true, isConsentUpdateApiError: false })
    updateUserConsent(userId, getMappedConsentState(CONSENT_STATES.ACCEPTED, true), ipAddress)
      .then(() => {
        if (isPhoneNumberPresent) {
          closeSmsServiceConsentDialog()
        } else {
          this.setState({ currentStep: STEPS.CONSENT_ACCEPTED })
        }
      })
      .catch(() => {
        this.setState({ isConsentUpdateApiError: true })
      })
      .finally(() => {
        this.setState({ isAcceptConsentLoading: false })
      })
  }

  handleGoToMyProfileClick = e => {
    const { closeSmsServiceConsentDialog } = this.props

    e.preventDefault()
    closeSmsServiceConsentDialog()

    window.open(process.env.REACT_APP_DIP_REDIRECT_EDIT_PROFILE_URI, '_blank').focus()
  }

  render() {
    const { classes, intl, isSmsServiceConsentDialogOpen } = this.props
    const {
      currentStep,
      isDeclineConsentLoading,
      isAcceptConsentLoading,
      isConsentUpdateApiError,
      isIpAddressApiError,
      isIpAddressLoading,
      isUserDataApiError,
      isUserDataLoading
    } = this.state

    const isLoading = isAcceptConsentLoading || isDeclineConsentLoading || isUserDataLoading || isIpAddressLoading

    const isApiError = isConsentUpdateApiError || isIpAddressApiError || isUserDataApiError
    const isActionButtonsDisabled = isLoading || isIpAddressApiError || isUserDataApiError

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.paperRoot } }}
        fullWidth
        maxWidth='md'
        onClose={this.handleCloseClick}
        open={isSmsServiceConsentDialogOpen}
        scroll='paper'
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              {intl.formatMessage(messages.additionalInformationTitle)}
            </Grid>
            <Grid container item justifyContent='flex-end' xs={1}>
              <IconButton classes={{ root: classes.iconButtonRoot }} onClick={this.handleCloseClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent classes={{ root: classes.dialogContent }}>
          <Grid classes={{ root: classes.dialogContentGridContainer }} container>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {isApiError && (
                  <Grid item xs={12}>
                    <ServerErrorAlert />
                  </Grid>
                )}
                <Grid item style={{ marginTop: 40 }} xs={12}>
                  {isUserDataLoading ? (
                    <Grid container justifyContent='center'>
                      <Grid item>
                        <CircularProgress size={50} />
                      </Grid>
                    </Grid>
                  ) : (
                    <React.Fragment>
                      {currentStep === STEPS.CONSENT_REQUEST && <ConsentRequestStep />}
                      {currentStep === STEPS.CONSENT_ACCEPTED && (
                        <ConsentAcceptedStep onGoToMyProfileClick={this.handleGoToMyProfileClick} />
                      )}
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {currentStep === STEPS.CONSENT_REQUEST && (
            <React.Fragment>
              <Button onClick={this.handleCloseClick}>{intl.formatMessage(messages.cancel)}</Button>
              <Button
                className='secondary-action-button'
                disabled={isActionButtonsDisabled}
                onClick={this.handleDeclineClick}
              >
                {isDeclineConsentLoading ? <CircularProgress size={20} /> : intl.formatMessage(messages.decline)}
              </Button>
              <Button
                className='primary-action-button'
                disabled={isActionButtonsDisabled}
                onClick={this.handleAcceptClick}
              >
                {isAcceptConsentLoading ? <CircularProgress size={20} /> : intl.formatMessage(messages.accept)}
              </Button>
            </React.Fragment>
          )}
          {currentStep === STEPS.CONSENT_ACCEPTED && (
            <Button className='primary-action-button' onClick={this.handleGoToMyProfileClick}>
              <LaunchIcon style={{ marginRight: 7 }} />
              {intl.formatMessage(messages.goToMyProfile)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

SmsServiceConsentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeSmsServiceConsentDialog: PropTypes.func.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isSmsServiceConsentDialogOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  openSmsServiceConsentDialog: PropTypes.func.isRequired,
  updateUserConsent: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(SmsServiceConsentDialog))
