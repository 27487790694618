import { defineMessages } from 'react-intl'

const namespace = 'NewNotification'

const messages = defineMessages({
  configureConditionForRule: {
    id: `${namespace}.configureConditionForRule`,
    description: 'Configure condition for rule error',
    defaultMessage: 'You have to configure the signal conditions to generate the rule'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Bad request'
  },
  error401Text: {
    id: `${namespace}.error401Text`,
    description: 'Error 401 text',
    defaultMessage: 'Invalid credentials'
  },
  error403Text: {
    id: `${namespace}.error403Text`,
    description: 'Error 403 text',
    defaultMessage: 'Access denied'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  error409Text: {
    id: `${namespace}.error409Text`,
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation: Group name it`s already in use'
  },
  error415Text: {
    id: `${namespace}.error415Text`,
    description: 'Error 415 text',
    defaultMessage: 'Unsupported media type'
  },
  error422Text: {
    id: `${namespace}.error422Text`,
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  error500Text: {
    id: `${namespace}.error500Text`,
    description: 'Error 500 text',
    defaultMessage: 'Access denied'
  },
  errorGeneric: {
    id: `${namespace}.errorGeneric`,
    description: 'Error generic',
    defaultMessage: 'Error'
  },
  errorRuleGetData: {
    id: `${namespace}.errorRuleGetData`,
    description: 'Error rule get data',
    defaultMessage: 'An error occurred while retrieving the rule data'
  },
  errorRuleId: {
    id: `${namespace}.errorRuleId`,
    description: 'Error rule id',
    defaultMessage: 'Wrong rule identifier'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  noSignalAvailable: {
    id: `${namespace}.noSignalAvailable`,
    description: 'No signal available title',
    defaultMessage: 'No signals available'
  }
})

export default messages
