import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import CircularProgress from '@material-ui/core/CircularProgress'

import DateTimeRangeSelector from 'components/DateTimeRangeSelector'

import messages from './messages'

import * as spnMap from 'utils/cs500/maps/J1939_descriptive_tables.json'
import * as fmiMap from 'utils/cs500/maps/fmi.json'
import { getData, url } from 'utils/http'
import { utcTimeToBrowserLocalWithMilliseconds } from 'utils/timeFormat'

class DM1Table extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      dm1: [],
      nodeId: 'm' + props.nodeEID.replaceAll(':', ''),
      fromDate: moment().subtract(10, 'days').valueOf(),
      toDate: moment().valueOf(),
      previous: '',
      next: '',
      minNextValue: '',
      count: 0,
      countToShow: 0,
      areAllDevicesLoaded: false,
      tableOptions: {
        // No data
        noDataText: <CircularProgress />,

        // Sorting
        // defaultSortName: this.state.order,
        // sortName: this.state.order,
        // defaultSortOrder: this.state.orderSort,
        // sortOrder: this.state.orderSort,
        // onSortChange: this.onSortChange,
        // sortIndicator: true,

        // Page size select
        onSizePerPageList: this.onSizePerPageList,
        sizePerPageList: [
          {
            text: '10',
            value: 10
          },
          {
            text: '20',
            value: 20
          },
          {
            text: '50',
            value: 50
          },
          {
            text: '100',
            value: 100
          }
        ], // you can change the dropdown list for size per page
        sizePerPage: 10, // which size per page you want to locate as default
        page: 1, // which page you want to show as default

        // Pagination
        onPageChange: this.onPageChange,
        ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 1, // the pagination bar size.
        prePage: this.formatMessage(messages.prePage), // Previous page button text
        nextPage: this.formatMessage(messages.nextPage), // Next page button text
        firstPage: this.formatMessage(messages.firstPage), // First page button text
        lastPage: this.formatMessage(messages.lastPage), // Last page button text
        //paginationShowsTotal: true,
        paginationPosition: 'bottom', // default is bottom, top and both is all available
        hideSizePerPage: false, // You can hide the dropdown for sizePerPage
        alwaysShowAllBtns: false, // Always show next and previous button
        withFirstAndLast: false // Hide the going to First and Last page button
        // Search options
        // onSearchChange: this.onSearchChange,
        // clearSearch: false,
        // searchDelayTime: 400 // A delay for triggering search after a keyup event
      }
    }
  }

  componentDidMount = () => {
    this.getDM1Messages()
  }

  getDM1Messages = () => {
    const {
      nodeId,
      fromDate,
      toDate,
      previous,
      next,
      minNextValue,
      count,
      areAllDevicesLoaded,
      tableOptions: { sizePerPage }
    } = this.state

    this.setState(
      state => ({
        tableOptions: {
          ...state.tableOptions,
          noDataText: <CircularProgress />
        }
      }),
      () => {
        getData(url.dm1Query({ nodeId, fromDate, toDate, previous, next, size: sizePerPage })).then(({ data }) => {
          const dm1Messages = data.content ? data.content : []
          const dm1 = this.mapMessages(dm1Messages)
          const newNextParameter = data.next ? data.next : ''
          const newPreviousParameter = data.previous ? data.previous : ''

          let stop = areAllDevicesLoaded
          let newMinNextValue = minNextValue
          let totalDM1Messages = count

          if (minNextValue === '' || newNextParameter && newNextParameter < minNextValue) {
            newMinNextValue = newNextParameter
            totalDM1Messages += dm1.length
          } else if (!stop && newNextParameter === '' && newPreviousParameter !== '') {
            totalDM1Messages += dm1.length
            stop = true
          }

          const getPageNumber = (dm1MessagesCount, itemCountPerPage) => {
            const divisor = Math.floor(dm1MessagesCount / itemCountPerPage)
            const remainder = dm1MessagesCount % itemCountPerPage

            let pageNumber = divisor
            if (remainder > 0) {
              pageNumber++
            }

            return pageNumber
          }

          this.setState(state => {
            const { noDataText, page, ...otherTableOptions } = state.tableOptions
            const isLastPage =
              newNextParameter === '' && newPreviousParameter !== '' ||
              newNextParameter === '' && newPreviousParameter === ''

            return {
              dm1,
              next: newNextParameter,
              previous: newPreviousParameter,
              minNextValue: newMinNextValue,
              count: totalDM1Messages,
              countToShow: isLastPage ? totalDM1Messages : Infinity,
              areAllDevicesLoaded: stop,
              tableOptions: {
                noDataText:
                  dm1.length === 0 ? this.formatMessage(messages.noDM1MessagesAvailable) : <CircularProgress />,
                page: isLastPage ? getPageNumber(totalDM1Messages, state.tableOptions.sizePerPage) : page,
                ...otherTableOptions
              }
            }
          })
        })
      }
    )
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(state => {
      const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
      return {
        next: page > previousPage ? state.next : '',
        previous: page < previousPage ? state.previous : '',
        countToShow: 0,
        tableOptions: {
          page,
          sizePerPage,
          ...otherTableOptions
        }
      }
    }, this.getDM1Messages)
  }

  handleSearchRange = (min, max) => {
    this.setState(state => {
      const { page: previousPage, ...otherTableOptions } = state.tableOptions
      return {
        next: '',
        previous: '',
        fromDate: min,
        toDate: max,
        count: 0,
        countToShow: 0,
        dm1: [],
        minNextValue: '',
        areAllDevicesLoaded: false,
        tableOptions: {
          page: 1,
          ...otherTableOptions
        }
      }
    }, this.getDM1Messages)
  }

  onSizePerPageList = sizePerPage => {
    this.setState(state => {
      const { page: previousPage, sizePerPage: previousSizePerPage, ...otherTableOptions } = state.tableOptions
      return {
        next: '',
        previous: '',
        count: 0,
        countToShow: 0,
        dm1: [],
        minNextValue: '',
        areAllDevicesLoaded: false,
        tableOptions: {
          page: 1,
          sizePerPage,
          ...otherTableOptions
        }
      }
    }, this.getDM1Messages)
  }

  mapMessages = dm1Messages => {
    const spnSignals = spnMap['signals']
    const fmiSignals = fmiMap['signals']
    const mappedDm1 = dm1Messages.map(dm1 => {
      const parsedDM1 = dm1
      parsedDM1['SPN'] = `${parsedDM1['SPN']} ${
        spnSignals[parsedDM1['SPN']] ? ' - ' + spnSignals[parsedDM1['SPN']].spnName : ''
      }`
      parsedDM1['FMI'] = `${fmiSignals[parsedDM1['FMI']] ? fmiSignals[parsedDM1['FMI']].fmiName : parsedDM1['FMI']}`
      return dm1
    })
    return mappedDm1
  }

  formatDate = date => {
    return utcTimeToBrowserLocalWithMilliseconds(date)
  }

  render() {
    const { dm1, fromDate, toDate, countToShow, tableOptions } = this.state
    return (
      <div className="table-with-pagination" style={{ paddingBottom: 110 }}>
        <div className="col-xs-12 col-md-6">
          <DateTimeRangeSelector maxDate={toDate} minDate={fromDate} onSearch={this.handleSearchRange} />
        </div>
        <BootstrapTable
          bordered={false}
          data={dm1}
          exportCSV={false}
          fetchInfo={{ dataTotalSize: countToShow }}
          options={tableOptions}
          pagination={true}
          remote={remoteObj => ({ ...remoteObj, search: false, pagination: true, sizePerPage: true, sort: false })}
        >
          <TableHeaderColumn
            dataField="timestamp"
            dataFormat={this.formatDate}
            dataSort={true}
            isKey={true}
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
            width="200"
          >
            {this.formatMessage(messages.date)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="SPN"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
            width="200"
          >
            {this.formatMessage(messages.spn)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="FMI"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
            width="200"
          >
            {this.formatMessage(messages.fmi)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="ADDRESS"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
            width="200"
          >
            {this.formatMessage(messages.address)}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="OC"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
            width="200"
          >
            {this.formatMessage(messages.oc)}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}

DM1Table.propTypes = {
  intl: PropTypes.object.isRequired,
  nodeEID: PropTypes.string.isRequired
}

export default injectIntl(DM1Table)
