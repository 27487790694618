import { connect } from 'react-redux'

import { getWidgets } from 'ducks/dashboards'

import ExportDashboard from './ExportDashboard'

const mapStateToProps = (state, ownProps) => {
  return {
    widgets: getWidgets(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportDashboard)
