import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from 'material-ui/TextField'

import { MuiTreeList } from 'components/react-treeview-mui'

import { client, logError } from 'utils/http'
import { momentDate, updatePrivilegeState, processPrivilegesToListitems } from '../utils'

import { injectIntl } from 'react-intl'
import messages from './messages'

class EditRoleForm extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      privileges: props.role.parent.privileges,
      privilegesHierarchy: props.privilegesHierarchy,
      privilegeState: props.role.privileges,
      descriptionValue: props.role.description,
      description_errorText: '',
      parentDescriptionValue: props.role.parent.description,
      createdatValue: momentDate(props.role.createdDate),
      modifiedatValue: momentDate(props.role.lastModifiedDate),
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  setPrivilegeState = (hashIds, value) => {
    hashIds.forEach(hashId => {
      this.setState(({ privileges, privilegeState }) => {
        const newPrivilegeState = updatePrivilegeState(privileges, privilegeState, hashId, value)
        return { privilegeState: newPrivilegeState }
      })
    })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  handleInputChange = event => {
    event.preventDefault()

    let element_textError = ''
    const value = event.target.value
    const name = event.target.name
    const property_value = name + 'Value'
    const property_errorText = name + '_errorText'
    const new_state = {}

    if (event.target.value === '') {
      element_textError = this.formatMessage(messages.thisFieldIsRequired)
    }

    new_state[property_value] = value
    new_state[property_errorText] = element_textError

    this.setState(new_state)
  }

  handleSave = () => {
    const { role, numOfPrivileges, groupId, roleId } = this.props
    const { privilegeState, descriptionValue } = this.state

    const rolePrivilegesIds = role.privileges.map(privilege => privilege.hashId)
    const roleDescription = role.description
    const newPrivileges = privilegeState.filter(newPrivilege => !rolePrivilegesIds.includes(newPrivilege.hashId))
    let roleName = ''
    let permissions = []
    if (
      rolePrivilegesIds.length !== privilegeState.length ||
      newPrivileges.length > 0 ||
      rolePrivilegesIds.length !== numOfPrivileges
    ) {
      permissions = privilegeState.map(privilege => privilege.hashId)
    }
    if (roleDescription !== descriptionValue) roleName = descriptionValue
    this.saveRole([groupId, roleId, roleName, permissions])
  }

  saveRole = params => {
    const { groupId, updateRole, action, intl } = this.props

    let editRole = client.editRole
    if (groupId) {
      if (params[2] || params[3].length > 0) editRole = updateRole
      else editRole = () => Promise.resolve('No need to update role')
    }
    editRole(...params)
      .then(() => {
        action()
      })
      .catch(response => {
        const error = response.error
        const status = error.response.status
        const errorMessage = error.response.data

        switch (status) {
          case 400: // Bad request
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message) + '. ' + errorMessage]
            })
            break
          case 401: // Invalid credentials
            let message
            if (intl.locale === 'en') message = error.response.message
            else message = this.formatMessage(messages.error401Message)
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403: // Access denied
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 412: // Out of date
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '412' }),
              alertMessagesText: [this.formatMessage(messages.error412Message)]
            })
            break
          case 415: // Unsupported media type
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '415' }),
              alertMessagesText: [this.formatMessage(messages.error415Message)]
            })
            break
          case 422: // Validation failed
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '422' }),
              alertMessagesText: [this.formatMessage(messages.error422Message)]
            })
            break
          case 428: // If-Match header required
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '428' }),
              alertMessagesText: [this.formatMessage(messages.error428Message)]
            })
            break
          case 500: // Unexpected error
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [error.response.data.error_description]
            })
            break
          default:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  render() {
    const {
      alertMessagesType,
      alertMessagesText,
      alertMessagesTitle,
      alertMessages,
      description_errorText,
      descriptionValue,
      parentDescriptionValue,
      createdatValue,
      modifiedatValue,
      privilegesHierarchy,
      privileges,
      privilegeState
    } = this.state

    return (
      <div className='container-fluid' style={{ margin: '20px 0 0 0' }}>
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />

        <Paper style={{ borderRadius: 0 }}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  errorText={description_errorText}
                  floatingLabelText={this.formatMessage(messages.name)}
                  fullWidth
                  name='description'
                  onChange={this.handleInputChange}
                  value={descriptionValue}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.parentRole)}
                  fullWidth
                  name='parentRole'
                  value={parentDescriptionValue}
                />
              </div>
              <div className='col-md-4'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.createdAt)}
                  fullWidth
                  name='createdAt'
                  value={createdatValue}
                />
              </div>
              <div className='col-md-4'>
                <TextField
                  disabled
                  floatingLabelText={this.formatMessage(messages.modifiedAt)}
                  fullWidth
                  name='modifiedAt'
                  value={modifiedatValue}
                />
              </div>
            </div>

            <div>&nbsp;</div>
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <h2>{this.formatMessage(messages.permissions)}</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='privilegeList'>
                  <MuiTreeList
                    activeListItem={-1}
                    contentKey='title'
                    haveSearchbar={false}
                    // expandedListItems={[1, 4, 11, 12, 15, 18]}
                    listItems={processPrivilegesToListitems(
                      privilegesHierarchy,
                      privileges,
                      this.setPrivilegeState,
                      privilegeState
                    )}
                  />
                </div>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>

          <p>&nbsp;</p>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <div style={{ padding: '20px 10px' }}>
                  <Button
                    className='primary-action-button'
                    disabled={descriptionValue === '' || privilegeState.length === 0}
                    onClick={this.handleSave}
                  >
                    {this.formatMessage(messages.save)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

EditRoleForm.propTypes = {
  action: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  numOfPrivileges: PropTypes.number.isRequired,
  privilegesHierarchy: PropTypes.array.isRequired,
  role: PropTypes.object.isRequired,
  roleId: PropTypes.string.isRequired,
  updateRole: PropTypes.func.isRequired
}

EditRoleForm.defaultProps = {
  groupId: ''
}

export default injectIntl(EditRoleForm)
