import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import download from 'utils/fileHandler/download'
import { DEFAULT_GROUP_ID } from 'utils/constants'
import { logError } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'

import './Configurations.css'

const styles = {
  dialog: {
    minWidth: 600
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class ConfigurationsTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const { state } = props.location
    let defaultTableProps = { page: 1, sizePerPage: 10 }
    if (typeof state === 'object') defaultTableProps = { ...defaultTableProps, ...state }
    const { page, sizePerPage } = defaultTableProps

    this.state = {
      dialogOpen: false,
      dialogSelectedConfigurationToDeleteTitle: '',
      dialogSelectedConfigurationToDeleteId: '',
      dialogSelectedConfigurationToDeleteName: '',
      deletingConfig: false,
      configurations: [],
      count: 0,
      start: (page - 1) * sizePerPage,
      tableOptions: {
        // No data
        noDataText: <CircularProgress />,
        // Sorting
        // defaultSortName: this.state.order,
        // sortName: this.state.order,
        // defaultSortOrder: this.state.orderSort,
        // sortOrder: this.state.orderSort,
        // onSortChange: this.onSortChange,
        // sortIndicator: true,

        // Page size select

        onSizePerPageList: this.onSizePerPageList,
        sizePerPageList: [
          {
            text: '10',
            value: 10
          },
          {
            text: '20',
            value: 20
          },
          {
            text: '50',
            value: 50
          },
          {
            text: '100',
            value: 100
          }
        ], // you can change the dropdown list for size per page
        sizePerPage, // which size per page you want to locate as default
        page, // which page you want to show as default

        // Pagination
        onPageChange: this.onPageChange,
        ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 5, // the pagination bar size.
        prePage: this.formatMessage(messages.prePage), // Previous page button text
        nextPage: this.formatMessage(messages.nextPage), // Next page button text
        firstPage: this.formatMessage(messages.firstPage), // First page button text
        lastPage: this.formatMessage(messages.lastPage), // Last page button text
        paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage), // Accept bool or function
        //paginationShowsTotal: true,
        paginationPosition: 'bottom', // default is bottom, top and both is all available
        hideSizePerPage: false, // You can hide the dropdown for sizePerPage
        alwaysShowAllBtns: false, // Always show next and previous button
        withFirstAndLast: true // Hide the going to First and Last page button
        // Search options
        // onSearchChange: this.onSearchChange,
        // clearSearch: false,
        // searchDelayTime: 400 // A delay for triggering search after a keyup event
      }
    }
  }

  componentDidMount = () => {
    const { groupId } = this.props

    const { start, tableOptions } = this.state

    if (groupId) {
      this.getConfigurations(groupId, tableOptions.sizePerPage, start)
    }
  }

  componentDidUpdate = prevProps => {
    const { groupId, handleUpdateTable, updateTable } = this.props

    if (prevProps.groupId !== groupId && groupId) {
      this.setState(
        state => ({
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />,
            page: 1
          },
          count: 0,
          start: 0,
          configurations: []
        }),
        () => {
          const { start, tableOptions } = this.state

          this.getConfigurations(groupId, tableOptions.sizePerPage, start)
        }
      )
    }
    if (!prevProps.updateTable && updateTable && groupId) {
      this.setState(
        state => ({
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />,
            page: 1
          },
          count: 0,
          start: 0,
          configurations: []
        }),
        () => {
          const { start, tableOptions } = this.state

          this.getConfigurations(groupId, tableOptions.sizePerPage, start)
          handleUpdateTable(false)
        }
      )
    }
  }

  getConfigurations = (groupId, limit, offset) => {
    const { changeAlertState, getConfigurations } = this.props

    getConfigurations(groupId, limit, offset)
      .then(response => {
        const data = response.data
        if (data.count === 0) {
          this.setState(state => {
            const { noDataText, ...otherTableOptions } = state.tableOptions
            return {
              configurations: [],
              count: 0,
              tableOptions: {
                noDataText: this.formatMessage(messages.noConfigurationsText),
                ...otherTableOptions
              }
            }
          })
        } else {
          this.setState({
            configurations: data.configurations,
            count: data.total
          })
        }
      })
      .catch(response => {
        const { error } = { ...response }
        if (error.response) {
          switch (error.response.status) {
            case 400:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    dialogSelectedConfigurationToDeleteName: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '400' }),
                    alertMessageText: this.formatMessage(messages.error400Message)
                  })
                }
              )
              break
            case 401:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    dialogSelectedConfigurationToDeleteName: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '401' }),
                    alertMessageText: this.formatMessage(messages.error401Message)
                  })
                }
              )
              break
            case 403:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    dialogSelectedConfigurationToDeleteName: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '403' }),
                    alertMessageText: this.formatMessage(messages.error403Message)
                  })
                }
              )
              break
            case 404:
              const errorReceived =
                typeof error.response.data === 'string' ? error.response.data : error.response.data.message
              if (
                errorReceived.includes('NO CONFIGURATION OF DEVICE IS SHARED WITH THIS USER IN THIS GROUP') ||
                errorReceived.includes('NO CONFIGURATION EXISTS IN THIS GROUP')
              ) {
                this.setState(state => ({
                  tableOptions: {
                    ...state.tableOptions,
                    noDataText: this.formatMessage(messages.noConfigurationsText)
                  },
                  configurations: []
                }))
              } else {
                this.setState(
                  state => {
                    const { noDataText, ...otherTableOptions } = state.tableOptions
                    return {
                      dialogOpen: false,
                      dialogSelectedConfigurationToDeleteTitle: '',
                      dialogSelectedConfigurationToDeleteId: '',
                      dialogSelectedConfigurationToDeleteName: '',
                      tableOptions: {
                        noDataText: this.formatMessage(messages.errorFetchingData),
                        ...otherTableOptions
                      }
                    }
                  },
                  () => {
                    changeAlertState({
                      alertVisibility: '',
                      alertMessageType: 'danger',
                      alertMessageTitle: this.formatMessage(messages.error, { number: '404' }),
                      alertMessageText: this.formatMessage(messages.error404Message)
                    })
                  }
                )
              }
              break
            case 406:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    dialogSelectedConfigurationToDeleteName: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '406' }),
                    alertMessageText: this.formatMessage(messages.error406Message)
                  })
                }
              )
              break
            case 500:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    dialogSelectedConfigurationToDeleteName: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '500' }),
                    alertMessageText: this.formatMessage(messages.error500Message)
                  })
                }
              )
              break
            default:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    dialogSelectedConfigurationToDeleteName: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                    alertMessageText: this.formatMessage(messages.errorUndefinedMessage)
                  })
                }
              )
              logError(error)
          }
        } else {
          this.setState(
            state => {
              const { noDataText, ...otherTableOptions } = state.tableOptions
              return {
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                dialogSelectedConfigurationToDeleteName: '',
                tableOptions: {
                  noDataText: this.formatMessage(messages.errorFetchingData),
                  ...otherTableOptions
                }
              }
            },
            () => {
              changeAlertState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessageText: this.formatMessage(messages.errorUndefinedMessage)
              })
            }
          )
          logError(error)
        }
      })
  }

  openDialog = (cell, row) => {
    this.setState({
      deletingConfig: false,
      dialogOpen: true,
      dialogSelectedConfigurationToDeleteTitle: this.formatMessage(messages.confirmConfigurationToDelete),
      dialogSelectedConfigurationToDeleteId: cell,
      dialogSelectedConfigurationToDeleteName: row.name + ' v.' + row.versionNumber
    })
  }

  handleCloseDialogClick = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedConfigurationToDeleteTitle: '',
      dialogSelectedConfigurationToDeleteId: '',
      dialogSelectedConfigurationToDeleteName: ''
    })
  }

  handleDeleteConfigurationClick = () => {
    const { changeAlertState, deleteConfiguration, groupId } = this.props
    const { configurations, dialogSelectedConfigurationToDeleteId } = this.state

    this.setState({
      deletingConfig: true
    })

    deleteConfiguration(groupId, dialogSelectedConfigurationToDeleteId)
      .then(() => {
        if (configurations.length > 1)
          this.setState(
            state => ({
              deletingConfig: false,
              tableOptions: {
                ...state.tableOptions,
                noDataText: <CircularProgress />,
                page: 1
              },
              count: 0,
              start: 0,
              configurations: []
            }),
            () => {
              const { start, tableOptions } = this.state

              this.getConfigurations(groupId, tableOptions.sizePerPage, start)
            }
          )
        else {
          const { tableOptions: initialTableOptions } = this.state

          let pageNumber = initialTableOptions.page
          if (pageNumber > 1) pageNumber--
          this.setState(
            state => {
              const { page, ...restOfTableOptions } = state.tableOptions
              return {
                deletingConfig: false,
                start: (pageNumber - 1) * state.tableOptions.sizePerPage,
                tableOptions: {
                  page: pageNumber,
                  ...restOfTableOptions
                },
                count: 0,
                configurations: []
              }
            },
            () => {
              const { start, tableOptions } = this.state

              this.getConfigurations(groupId, tableOptions.sizePerPage, start)
            }
          )
        }
        changeAlertState({
          alertVisibility: '',
          alertMessageType: 'success',
          alertMessageText: this.formatMessage(messages.configurationDeletedSuccessfully)
        })
        this.handleCloseDialogClick()
      })
      .catch(response => {
        const { error } = { ...response }
        if (error.response) {
          switch (error.response.status) {
            case 400:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '400' }),
                    alertMessageText: this.formatMessage(messages.error400Message)
                  })
                }
              )
              break
            case 401:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    deletingConfig: false,
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '401' }),
                    alertMessageText: this.formatMessage(messages.error401Message)
                  })
                }
              )
              break
            case 403:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '403' }),
                    alertMessageText: this.formatMessage(messages.error403Message)
                  })
                }
              )
              break
            case 404:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '404' }),
                    alertMessageText: this.formatMessage(messages.error404Message)
                  })
                }
              )
              break
            case 406:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '406' }),
                    alertMessageText: this.formatMessage(messages.error406Message)
                  })
                }
              )
              break
            case 500:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.error, { number: '500' }),
                    alertMessageText: this.formatMessage(messages.error500Message)
                  })
                }
              )
              break
            default:
              this.setState(
                state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    deletingConfig: false,
                    dialogOpen: false,
                    dialogSelectedConfigurationToDeleteTitle: '',
                    dialogSelectedConfigurationToDeleteId: '',
                    tableOptions: {
                      noDataText: this.formatMessage(messages.errorFetchingData),
                      ...otherTableOptions
                    }
                  }
                },
                () => {
                  changeAlertState({
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                    alertMessageText: this.formatMessage(messages.errorUndefinedMessage)
                  })
                }
              )
              logError(error)
          }
        } else {
          this.setState(
            state => {
              const { noDataText, ...otherTableOptions } = state.tableOptions
              return {
                deletingConfig: false,
                dialogOpen: false,
                dialogSelectedConfigurationToDeleteTitle: '',
                dialogSelectedConfigurationToDeleteId: '',
                tableOptions: {
                  noDataText: this.formatMessage(messages.errorFetchingData),
                  ...otherTableOptions
                }
              }
            },
            () => {
              changeAlertState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessageText: this.formatMessage(messages.errorUndefinedMessage)
              })
            }
          )
          logError(error)
        }
      })
  }

  downloadConfiguration = (cell, row) => {
    const fileName = row.name + ' v.' + row.versionNumber + '.json'
    const blob = new Blob([JSON.stringify(row, null, 2)], { type: 'application/json' })
    download(blob, fileName, 'application/json')
  }

  onPageChange = (page, sizePerPage) => {
    const { groupId } = this.props

    this.setState(
      state => {
        const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          start: (page - 1) * sizePerPage,
          tableOptions: {
            page,
            sizePerPage,
            ...otherTableOptions
          },
          configurations: []
        }
      },
      () => {
        const { start, tableOptions } = this.state

        this.getConfigurations(groupId, tableOptions.sizePerPage, start)
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    const { groupId } = this.props

    this.setState(
      state => {
        const { sizePerPage: previousSizePerPage, ...otherTableOptions } = state.tableOptions
        return {
          tableOptions: { sizePerPage, ...otherTableOptions },
          configurations: []
        }
      },
      () => {
        const { start, tableOptions } = this.state

        this.getConfigurations(groupId, tableOptions.sizePerPage, start)
      }
    )
    this.setState({ configurations: [] }, () => {
      const { start, tableOptions } = this.state

      this.getConfigurations(groupId, tableOptions.sizePerPage, start)
    })
  }

  showActions = () => (
    <TableHeaderColumn
      dataAlign='center'
      dataField='id'
      dataFormat={this.renderActionDetail}
      headerAlign='center'
      tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
      width='15%'
    >
      {this.formatMessage(messages.actions)}
    </TableHeaderColumn>
  )

  renderActionDetail = (cell, row) => {
    const {
      canDeleteConfigurations,
      canEditConfigurations,
      classes,
      editConfigurationUrl,
      groupId,
      loggedUserEmail,
      viewConfigurationUrl
    } = this.props
    const { tableOptions } = this.state

    const { page, sizePerPage } = tableOptions
    return (
      <div>
        <Link className='button-link' to={{ pathname: viewConfigurationUrl(cell), state: { page, sizePerPage } }}>
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.viewConfiguration)}>
            <IconButton style={{ padding: '5px' }}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <Link
          className='button-link'
          style={{ display: canEditConfigurations ? '' : 'none' }}
          to={{ pathname: editConfigurationUrl(cell), state: { page, sizePerPage } }}
        >
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.editConfiguration)}>
            <IconButton style={{ padding: '5px' }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.downloadConfiguration)}>
          <IconButton onClick={() => this.downloadConfiguration(cell, row)} style={{ padding: '5px' }}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.deleteConfiguration)}>
          <div style={{ padding: 0, display: '-webkit-inline-box' }}>
            <IconButton
              disabled={groupId === DEFAULT_GROUP_ID && loggedUserEmail !== 'prd.dynatrace.plus1connect@gmail.com'}
              onClick={() => this.openDialog(cell, row)}
              style={{ padding: '5px', display: canDeleteConfigurations ? '' : 'none' }} // Testing for Dynatrace
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    )
  }

  formatDate = (cell, row) => {
    return utcTimeToBrowserLocal(cell)
  }

  handleNameDetail = (cell, row) => {
    if (row.versionNumber > 0) return cell + ' v.' + row.versionNumber
    else return cell
  }

  closeAlert = () => {
    const { changeAlertState } = this.props
    changeAlertState({
      alertVisibility: 'hidden',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  remote(remoteObj) {
    return {
      ...remoteObj,
      pagination: true,
      sizePerPage: true
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { classes } = this.props
    const {
      configurations,
      count,
      deletingConfig,
      dialogOpen,
      dialogSelectedConfigurationToDeleteName,
      dialogSelectedConfigurationToDeleteTitle,
      tableOptions
    } = this.state
    return (
      <div>
        <Paper style={{ borderRadius: 0 }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              data={configurations.map(c =>
                Object.assign(c, {
                  nameWithVersion: c.versionNumber > 0 ? c.name + ' v.' + c.versionNumber : c.name
                })
              )}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              options={tableOptions}
              pagination
              remote={this.remote}
            >
              <TableHeaderColumn dataField='id' isKey width='0'>
                {this.formatMessage(messages.locationID)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='nameWithVersion'
                dataSort
                /*dataFormat={this.handleNameDetail}*/
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='25%'
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='createdAt'
                dataFormat={this.formatDate}
                dataSort
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='25%'
              >
                {this.formatMessage(messages.created)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='updatedAt'
                dataFormat={this.formatDate}
                dataSort
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='25%'
              >
                {this.formatMessage(messages.updated)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleCloseDialogClick} open={dialogOpen}>
          <DialogTitle style={{ padding: '12px 24px 12px 24px' }}>
            <IconButton
              onClick={this.handleCloseDialogClick}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
            {dialogSelectedConfigurationToDeleteTitle}
          </DialogTitle>
          <DialogContent style={{ padding: '12px 24px 12px 24px' }}>
            <DialogContentText>{dialogSelectedConfigurationToDeleteName}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='delete-button' disabled={deletingConfig} onClick={this.handleDeleteConfigurationClick}>
              {deletingConfig && <CircularProgress size={24} style={{ position: 'absolute' }} />}
              {this.formatMessage(messages.delete)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ConfigurationsTable.propTypes = {
  canDeleteConfigurations: PropTypes.bool.isRequired,
  canEditConfigurations: PropTypes.bool.isRequired,
  changeAlertState: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  deleteConfiguration: PropTypes.func.isRequired,
  editConfigurationUrl: PropTypes.func.isRequired,
  getConfigurations: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleUpdateTable: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loggedUserEmail: PropTypes.string.isRequired,
  updateTable: PropTypes.bool.isRequired,
  viewConfigurationUrl: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(injectIntl(ConfigurationsTable)))
