import React from 'react'
import PropTypes from 'prop-types'

const Line = ({ data }) => {
  let color
  switch (data.color) {
    case 'default':
      color = 'rgba(0, 0, 0, 0.87)'
      break
    case 'lightGrey':
      color = '#e1e1e1'
      break
    case 'grey':
      color = '#dddddd'
      break
    case 'darkGrey':
      color = '#666666'
      break
    default:
      color = 'rgba(0, 0, 0, 0.87)'
      break
  }

  let style

  if (data.type === 'horizontal') {
    style = {
      borderTopWidth: data.width,
      borderTopStyle: data.style,
      borderTopColor: color,
      width: '100%',
      height: 0
    }
  } else {
    style = {
      borderRightWidth: data.width,
      borderRightStyle: data.style,
      borderRightColor: color,
      height: '100%',
      width: 0
    }
  }

  return <div style={style} />
}

Line.propTypes = {
  data: PropTypes.object.isRequired
}

export default Line
