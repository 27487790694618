const getDanfossWebUrl = () => {
  let url = 'https://www.danfoss.com/##language##/'
  let userLanguage = localStorage.getItem('user_language')
  if (userLanguage === 'es') {
    userLanguage = 'es-es'
  }
  url = url.replace('##language##', userLanguage)

  return url
}

const getDanfossPrivacyPolicyUrl = () => {
  let url = 'https://www.danfoss.com/##language##/terms/privacy/'
  let userLanguage = localStorage.getItem('user_language')
  if (userLanguage === 'es') {
    userLanguage = 'es-es'
  }
  url = url.replace('##language##', userLanguage)

  return url
}

const getDanfossTermsOfUseUrl = () => {
  let url = 'https://www.danfoss.com/##language##/terms/'
  let userLanguage = localStorage.getItem('user_language')
  if (userLanguage === 'es') {
    userLanguage = 'es-es'
  }
  url = url.replace('##language##', userLanguage)

  return url
}

const getDanfossGeneralInformationUrl = () => {
  let url = 'https://www.danfoss.com/##language##/terms/generalinformation/'
  let userLanguage = localStorage.getItem('user_language')
  if (userLanguage === 'es') {
    userLanguage = 'es-es'
  }
  url = url.replace('##language##', userLanguage)

  return url
}

const getDanfossProductOverviewUrl = () => {
  const url = 'https://www.danfoss.com/en/markets/mobile-hydraulics/dps/connectivity-solutions/#tab-overview'
  return url
}

export {
  getDanfossWebUrl,
  getDanfossPrivacyPolicyUrl,
  getDanfossTermsOfUseUrl,
  getDanfossGeneralInformationUrl,
  getDanfossProductOverviewUrl
}
