import { capitalize } from 'lodash'

const mapMFIOToDynamicData = MFIOData => {
  let MFIOSignals = Array.isArray(MFIOData) ? MFIOData : []
  MFIOSignals = MFIOSignals.filter(signal => signal.pinFunction !== 0).map(signal => ({
    ...signal,
    signalType: 'mfio',
    id: signal.signalId
  }))
  return MFIOSignals
}

const mapGPSFieldToUnits = field => {
  const GPSFieldUnits = {
    altitude: 'm',
    speed: 'km/h',
    heading: '°'
  }
  return GPSFieldUnits[field]
}

const mapGPSToDynamicData = gpsData =>
  Object.entries(gpsData)
    .filter(([key, value]) => value > 0 && !(key.startsWith('position') || key.startsWith('heading')))
    .map(([key]) => {
      const id = key.replace('LogTime', '')
      const name = capitalize(id)
      const unit = mapGPSFieldToUnits(id)
      return {
        id,
        name,
        signalType: 'gps',
        unit
      }
    })

const generateDynamicData = (mfioAndCanBusSignals, gpsSignals) => {
  const mfioAndCanBusDynamicData = mfioAndCanBusSignals.filter(sensor => sensor.frequency !== 0)
  return [...gpsSignals, ...mfioAndCanBusDynamicData]
}

const mapSignalsToColumns = configuration => {
  const { MFIO = [], sensorsMap = [], gps = {} } = configuration || {}
  const mfioSignals = mapMFIOToDynamicData(MFIO)
  const canBusSignals = sensorsMap.map(signal => ({
    ...signal,
    id: signal.signalId,
    signalType: 'canBus'
  }))
  const gpsSignals = [] //mapGPSToDynamicData(gps)
  const signals = generateDynamicData([...mfioSignals, ...canBusSignals], gpsSignals)
  return signals
}

const mapMeasurementsToAdvancedSignals = (measurements, signals) =>
  measurements.map(measurement => {
    const {
      hashId,
      formula,
      measurementName: name,
      measurementUnit: unit,
      measurementSignals,
      operationType,
      measurementDevices
    } = measurement
    const [, operator = '', processedValue = ''] = formula.split(' ')
    const { targetSignal, hashId: measurementSignalHashId } = measurementSignals[0]
    const { hashId: measurementDeviceHashId, deviceType } = measurementDevices[0]
    const targetId = Number(targetSignal.replace('p-', ''))
    const { multiplier = 1, offset = 0, divider = 1 } = signals.find(({ signalId }) => signalId === targetId) || {}
    let value = processedValue
    if (deviceType === 'CS500') {
      value = Math.round(processedValue / divider)
    } else if (deviceType === 'CS100') {
      value = Math.round(processedValue * multiplier + offset)
    }

    return {
      name,
      nameError: '',
      targetId,
      targetIdError: '',
      operator,
      operatorError: '',
      value,
      valueError: '',
      operationType,
      operationTypeError: '',
      unit: operationType === '1' ? 'hours' : unit,
      hashId,
      measurementSignalHashId,
      measurementDeviceHashId
    }
  })

const mapAdvancedSignalsToMeasurements = (advancedSignals, signals, deviceData) => {
  const { deviceConfigurationId, deviceEid, deviceName, deviceType } = deviceData
  return advancedSignals.map(advancedSignal => {
    const {
      name,
      targetId,
      operator,
      value,
      operationType,
      hashId,
      measurementSignalHashId,
      measurementDeviceHashId,
      unit
    } = advancedSignal
    const targetSignal = `p-${targetId}`
    const {
      frequency,
      logCycle,
      sendInmediately,
      multiplier = 1,
      offset = 0,
      divider = 1
    } = signals.find(({ id }) => id === Number(advancedSignal.targetId)) || {}
    let signalGaps = frequency || logCycle
    let thresholdValue = value
    if (deviceType === 'CS500') {
      signalGaps = Math.round(signalGaps / 1000)
      thresholdValue *= divider
    } else if (deviceType === 'CS100') {
      thresholdValue -= offset
      if (multiplier > 0) thresholdValue /= multiplier
    }
    if (sendInmediately === 1) signalGaps = 1
    let formula = `$${targetSignal}`
    if (operationType !== '3') formula = `$${targetSignal} ${operator} ${thresholdValue}`

    return {
      measurementName: name,
      measurementUnit: unit,
      operationType,
      signalGaps,
      ...hashId && { hashId },
      formula,
      deviceConfigurationId,
      measurementSignals: [
        {
          targetSignal,
          placeholder: `$${targetSignal}`,
          ...measurementSignalHashId && { hashId: measurementSignalHashId }
        }
      ],
      measurementDevices: [
        {
          deviceEid,
          deviceName,
          deviceType,
          ...measurementDeviceHashId && { hashId: measurementDeviceHashId }
        }
      ]
    }
  })
}

const getNewAdvancedSignal = () => ({
  name: '',
  nameError: '',
  targetId: '',
  targetIdError: '',
  operator: '',
  operatorError: '',
  value: '',
  valueError: '',
  hashId: '',
  measurementSignalHashId: '',
  operationType: '',
  operationTypeError: '',
  unit: ''
})

export { mapSignalsToColumns, mapMeasurementsToAdvancedSignals, mapAdvancedSignalsToMeasurements, getNewAdvancedSignal }
