import AssignedUsersGroupActionsConfig from './AssignedUsersGroupActionsConfig'
import { client, logError } from 'utils/http'

const AssignedUsersGroupActions = intl => {
  const { formatMessage } = intl
  return client
    .getNotificationsActions()
    .then(response => {
      const apiNotificationsActions = response.data
      const groupActions = []

      AssignedUsersGroupActionsConfig(formatMessage).map(configAction => {
        apiNotificationsActions.map(action => {
          if (action.name === configAction.notificationActionName) {
            const theAction = {
              component: configAction.component,
              description: configAction.description,
              hashId: configAction.hashId,
              name: configAction.name,
              notificationActionHashId: action.hashId,
              notificationActionName: action.name
            }

            groupActions.push(theAction)
          }
          return action
        })
        return configAction
      })
      return groupActions
    })
    .catch(response => {
      const error = { ...response}

      logError(response)
      logError(error)
    })
}

export default AssignedUsersGroupActions
