import React from 'react'

import PageTitle from 'components/PageTitle'

import { injectIntl } from 'react-intl'
import messages from './messages'

const NewReportFormTitle = ({ reportsUrl, intl }) => {
  const { formatMessage } = intl
  const title = formatMessage(messages.title)
  /*const button = {
    label: formatMessage(messages.cancel),
    path: reportsUrl,
    primary: false
  }*/

  return <PageTitle title={title} /*button={button}*/ back={reportsUrl} />
}

export default injectIntl(NewReportFormTitle)
