import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import FleetActions from './FleetActions'

const mapStateToProps = state => ({
  canUpdateFirmware: resolvePermissions(state, c.FIRMWARE_READ),
  canUpdateConfiguration: resolvePermissions(state, c.DEVICECONFIGURATION_READ),
  canImportDashboards: resolvePermissions(state, c.DEVICEDASHBOARD_CREATE),
  canDeleteDashboards: resolvePermissions(state, c.DEVICEDASHBOARD_DELETE),
  canImportAdvancedSignals:
    resolvePermissions(state, c.DEVICECONFIGURATION_READ) && resolvePermissions(state, c.DEVICECONFIGURATION_EDIT),
  canEditDevice: resolvePermissions(state, c.DEVICE_EDIT)
})

export default connect(mapStateToProps)(FleetActions)
