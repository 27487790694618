import React from 'react'

import Loading from 'components/Loading'

const LoadingDashboard = () => (
  <div id="content" className="content-container" style={{ width: '100%' }}>
    <div className="col-md-12" style={{ padding: '30px' }}>
      <Loading />
    </div>
  </div>
)

export default LoadingDashboard
