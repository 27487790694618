import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/styles'

import messages from '../../../messages'
import { chipStyles } from '../../../styles'

const styles = {
  ...chipStyles
}

const NotificationStateChip = props => {
  const { classes, intl, isEnabled } = props

  const { chipClassName, chipText } = isEnabled
    ? { chipClassName: classes.chipSuccess, chipText: intl.formatMessage(messages.enabled) }
    : { chipClassName: classes.chipDanger, chipText: intl.formatMessage(messages.disabled) }

  return <Chip className={classes.chipRoot + ' ' + chipClassName} label={chipText} />
}

NotificationStateChip.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isEnabled: PropTypes.bool.isRequired
}

export default withStyles(styles)(injectIntl(NotificationStateChip))
