import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Home from './Home'

const mapStateToProps = state => ({
  canReadUsers: resolvePermissions(state, c.USER_READ),
  canEditUsers: resolvePermissions(state, c.USER_EDIT)
})

export default connect(mapStateToProps)(Home)

export { getUsersUrl } from './url'
