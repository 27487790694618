import { createSelector } from 'reselect'

import { utils as grp } from 'ducks/groups'
import { utils as login } from 'ducks/login'

import { getSelectedGroup } from '../../map'

// Needed to be isolated while "node" is not in duck form
import { isRepresentable, _getRepresentableGroupsRecursively } from './utils'

const getSelectedGroupAncestors = createSelector(
  login.getGroups,
  getSelectedGroup,
  grp.getParentsByGroupId,
  (userGroups, selectedGroup, parents) => {
    let ret = []
    let current = selectedGroup
    while (current && userGroups.indexOf(current) === -1) {
      ret = [current, ...ret]
      current = parents[current]
    }
    return ret
  }
)

const getRepresentableSelectedGroups = createSelector(
  getSelectedGroupAncestors,
  grp.getGroupsById,
  (selectedGroups, groupsById) =>
    // Filter representable selected groups
    selectedGroups.reduce((ret, g) => {
      const group = groupsById[g]
      if (isRepresentable(group)) {
        ret.push(group)
      }
      return ret
    }, [])
)

const getDisplayedGroupIds = createSelector(
  login.getGroups,
  getSelectedGroup,
  (userGroups, selectedGroup) => (selectedGroup ? [selectedGroup] : userGroups)
)

const getDisplayedGroups = createSelector(
  getDisplayedGroupIds,
  grp.getGroupsById,
  (displayedGroupIds, groupsById) => displayedGroupIds.map(g => groupsById[g])
)

const getRepresentedGroupChildren = createSelector(
  getDisplayedGroupIds,
  grp.getGroupsById,
  (displayedGroups, groupsById) =>
    displayedGroups.reduce((ret, g) => {
      const { children } = groupsById[g]
      if (children) {
        ret.push(...children)
      }
      return ret
    }, [])
)

const getRepresentableGroups = createSelector(
  grp.getGroupsById,
  getRepresentedGroupChildren,
  _getRepresentableGroupsRecursively
)

export {
  getRepresentableSelectedGroups,
  getDisplayedGroups,
  getRepresentableGroups
}
