import MapView from './MapView'
import { connect } from 'react-redux'
import { selectors as geotrackingSelectors } from 'ducks/geotracking'

const mapStateToProps = state => ({
  isGpsTrackingsLoading: geotrackingSelectors.getIsGpsTrackingsLoading(state),
  gpsTrackings: geotrackingSelectors.getGPSTrackings(state),
  mergedAndOrderedGpsTrackings: geotrackingSelectors.getGPSTrackingsMergedAndOrdered(state)
})

export default connect(mapStateToProps)(MapView)
