import { defineMessages } from 'react-intl'

const namespace = 'Historic'

const messages = defineMessages({
  historicWidget: {
    id: `${namespace}.historicWidget`,
    description: 'Historic chart widget',
    defaultMessage: 'HISTORIC WIDGET'
  },
  historicWidgetSettings: {
    id: `${namespace}.historicWidgetSettings`,
    description: 'Historic widget settings',
    defaultMessage: 'Historic widget settings.'
  },
  dinamicValue: {
    id: `${namespace}.dinamicValue`,
    description: 'Dynamic value',
    defaultMessage: 'Dynamic value'
  },
  selectValueToDisplay: {
    id: `${namespace}.selectValueToDisplay`,
    description: '',
    defaultMessage: 'Select value to display'
  },
  defaultTimeRange: {
    id: `${namespace}.defaultTimeRange`,
    description: 'Default time range',
    defaultMessage: 'Default time range'
  },
  mustHaveAValue: {
    id: `${namespace}.mustHaveAValue`,
    description: 'Must have a value helper text',
    defaultMessage: 'Must have a value.'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Historic widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Historic widget settings save button text',
    defaultMessage: 'Save'
  },
  notSupportedMachine: {
    id: `${namespace}.notSupportedMachine`,
    description: '',
    defaultMessage: 'Not supported machine'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  noDataAvailable: {
    id: `${namespace}.noDataAvailable`,
    description: 'No data available',
    defaultMessage: 'No data available'
  },
  noSignal: {
    id: `${namespace}.noSignal`,
    description: 'No signal',
    defaultMessage: 'No signal'
  },
  otherHistoricWidgetSettings: {
    id: `${namespace}.otherHistoricWidgetSettings`,
    description: 'Historic widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  numberOfDecimals: {
    id: `${namespace}.numberOfDecimals`,
    description: 'Number of decimals',
    defaultMessage: 'No. of decimals'
  },
  onlyZeroOrHigherIsAllowed: {
    id: `${namespace}.onlyZeroOrHigherIsAllowed`,
    description: 'Only 0 or greater number is allowed',
    defaultMessage: 'Only 0 or greater number is allowed'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required',
    defaultMessage: 'This field is required'
  },
  lastOneHour: {
    id: `${namespace}.lastOneHour`,
    description: 'Last one hour',
    defaultMessage: 'Last 1 hour'
  },
  lastThreeHours: {
    id: `${namespace}.lastThreeHours`,
    description: 'Last three hours',
    defaultMessage: 'Last 3 hours'
  },
  lastSixHours: {
    id: `${namespace}.lastSixHours`,
    description: 'Last six hours',
    defaultMessage: 'Last 6 hours'
  },
  lastTwelveHours: {
    id: `${namespace}.lastTwelveHours`,
    description: 'Last twelve hours',
    defaultMessage: 'Last 12 hours'
  },
  lastTwentyFourHours: {
    id: `${namespace}.lastTwentyFourHours`,
    description: 'Last twenty four hours',
    defaultMessage: 'Last 24 hours'
  },
  selectedDinamicValues: {
    id: `${namespace}.selectedDinamicValues`,
    description: 'Selected dinamic values:',
    defaultMessage: 'Selected dinamic values:'
  },
  loadingDataFromServer: {
    id: `${namespace}.loadingDataFromServer`,
    description: 'Loading data from server...',
    defaultMessage: 'Loading data from server...'
  },
  aggregation: {
    id: `${namespace}.aggregation`,
    description: 'Aggregation',
    defaultMessage: 'Aggregation'
  },
  none: {
    id: `${namespace}.none`,
    description: 'None aggregation type',
    defaultMessage: 'None'
  },
  avg: {
    id: `${namespace}.avg`,
    description: 'Average aggregation type',
    defaultMessage: 'Average'
  },
  min: {
    id: `${namespace}.min`,
    description: 'Minimum aggregation type',
    defaultMessage: 'Minimum'
  },
  max: {
    id: `${namespace}.max`,
    description: 'Maximum aggregation type',
    defaultMessage: 'Maximum'
  },
  refreshData: {
    id: `${namespace}.refreshData`,
    description: 'Refresh data',
    defaultMessage: 'Refresh data'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  },
  valueType: {
    id: `${namespace}.valueType`,
    description: 'Value type',
    defaultMessage: 'Value type'
  },
  valuenone: {
    id: `${namespace}.valuenone`,
    description: 'Last value type',
    defaultMessage: 'Last'
  },
  valueavg: {
    id: `${namespace}.valueavg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valuemin: {
    id: `${namespace}.valuemin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valuemax: {
    id: `${namespace}.valuemax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  configurationStatus: {
    id: `${namespace}.configurationStatus`,
    description: 'Configuration status',
    defaultMessage: 'Configuration status'
  },
  configurationAvailable: {
    id: `${namespace}.configurationAvailable`,
    description: 'Available',
    defaultMessage: 'Available'
  },
  configurationNotAvailable: {
    id: `${namespace}.configurationNotAvailable`,
    description: 'Not available',
    defaultMessage: 'Not available'
  },
  configurationLoading: {
    id: `${namespace}.configurationLoading`,
    description: 'Loading',
    defaultMessage: 'Loading'
  },
  loadingConfiguratedSignals: {
    id: `${namespace}.loadingConfiguratedSignals`,
    description: 'Loading configurated signals',
    defaultMessage: 'Loading configurated signals'
  },
  gpsSignals: {
    id: `${namespace}.gpsSignals`,
    description: 'GPS signals',
    defaultMessage: 'GPS signals'
  },
  canBusSignals: {
    id: `${namespace}.canBusSignals`,
    description: 'CAN Bus signals',
    defaultMessage: 'CAN Bus signals'
  },
  mfioSignals: {
    id: `${namespace}.mfioSignals`,
    description: 'MFIO signals',
    defaultMessage: 'MFIO signals'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  basicSignalsChart: {
    id: `${namespace}.basicSignalsChart`,
    description: 'Basic signals chart',
    defaultMessage: 'Basic signals chart'
  },
  advancedSignalsChart: {
    id: `${namespace}.advancedSignalsChart`,
    description: 'Advanced signals chart',
    defaultMessage: 'Advanced signals chart'
  },
  historicType: {
    id: `${namespace}.historicType`,
    description: 'Historic type',
    defaultMessage: 'Historic type'
  },
  basic: {
    id: `${namespace}.basic`,
    description: 'Basic tab label',
    defaultMessage: 'Basic signals chart'
  },
  advanced: {
    id: `${namespace}.advanced`,
    description: 'Advanced tab label',
    defaultMessage: 'Advanced signals chart'
  },
  outdatedWidget: {
    id: `${namespace}.outdatedWidget`,
    description: 'Outdated widget',
    defaultMessage: 'Outdated widget'
  }
})

export default messages
