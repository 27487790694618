import { defineMessages } from 'react-intl'

const messages = defineMessages({
  from: {
    id: 'GPSQuerySelector.from',
    description: 'GPSQuerySelector from label',
    defaultMessage: 'From'
  },
  to: {
    id: 'GPSQuerySelector.to',
    description: 'GPSQuerySelector to label',
    defaultMessage: 'To'
  },
  time: {
    id: 'GPSQuerySelector.time',
    description: 'Time hint text part',
    defaultMessage: '{ floatinglabeltext } time'
  },
  ok: {
    id: 'GPSQuerySelector.ok',
    description: 'Ok button label',
    defaultMessage: 'OK'
  },
  cancel: {
    id: 'GPSQuerySelector.cancel',
    description: 'Cancel button label',
    defaultMessage: 'cancel'
  },
  trackingSource: {
    id: 'GPSQuerySelector.trackingSource',
    description: 'Tracking Source label',
    defaultMessage: 'Tracking source'
  },
  trail: {
    id: 'GPSQuerySelector.trail',
    description: 'Trail Label',
    defaultMessage: 'Trail'
  },
  none: {
    id: 'GPSQuerySelector.none',
    description: 'Trail selector none label',
    defaultMessage: 'None'
  },
  points: {
    id: 'GPSQuerySelector.points',
    description: 'Trail selector points label',
    defaultMessage: 'points'
  },
  max: {
    id: 'GPSQuerySelector.max',
    description: 'Trail selector max label',
    defaultMessage: 'Max'
  },
  clear: {
    id: 'GPSQuerySelector.clear',
    description: 'Clear button label',
    defaultMessage: 'Clear'
  },
  maxDateMessage: {
    id: 'GPSQuerySelector.maxDateMessage',
    description: 'Max date message',
    defaultMessage: '\'From\' should not be after \'To\''
  },
  minDateMessage: {
    id: 'GPSQuerySelector.minDateMessage',
    description: 'Min date message',
    defaultMessage: '\'To\' should not be before \'From\''
  }
})

export default messages
