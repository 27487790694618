import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Home from './Home'

const mapStateToProps = state => ({
  canCreateConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_CREATE),
  canEditConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_EDIT),
  canReadConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_READ)
})

export default connect(mapStateToProps)(Home)

export { getConfigurationsUrl } from './url'
