import { actionTypes as wt } from 'middlewares/websocket'
import * as t from '../actionTypes'

import * as c from '../constants'
import connection, {
  isConnected as isConn,
  isConnecting as isConng,
  getAccessKeyID,
  getCredentials,
  hasExpired,
  isActive as isActv,
  isSubscribed,
  isSubscribing,
  hasNoSubscribers as hasNoSubs,
  hasPendingSubscriptions as hasPendingSubs
} from './connection'

const filterExpired = connections =>
  Object.keys(connections).reduce((ret, k) => {
    if (hasExpired(ret[k])) {
      delete ret[k] // eslint-disable-line no-param-reassign
    }
    return ret
  }, connections)

// Websocket URLs by node id
const reducer = (state = {}, action) => {
  if (action) {
    switch (action.type) {
      case wt.CONNECT:
      case t.CONNECT_START:
      case t.CONNECT_END:
      case t.REQUEST_SUBSCRIPTIONS:
      case t.REQUEST_UNSUBSCRIPTIONS:
        const { nodeId } = action.meta
        return filterExpired(
          { ...state, [nodeId]: connection(state[nodeId], action)}
        )

      case wt.RECONNECT:
      case wt.CONNECT_SUCCESS:
      case wt.OFFLINE:
      case wt.DISCONNECT:
      case wt.DISCONNECT_SUCCESS:
      case wt.SUBSCRIBE:
      case wt.SUBSCRIBE_SUCCESS:
      case wt.SUBSCRIBE_FAIL:
      case wt.UNSUBSCRIBE:
      case wt.UNSUBSCRIBE_SUCCESS:
      case wt.UNSUBSCRIBE_FAIL:
        const connectionNode = Object.keys(state).find(k => {
          const ak = getAccessKeyID(state[k])
          return ak !== null && ak === action.meta.accessKeyId
        })
        if (connectionNode) {
          return { ...state, [connectionNode]: connection(state[connectionNode], action)}
        }
        break

      default:
    }
  }
  return state
}

export default reducer

// Selectors
const getConnections = state => state[c.NAME]
const getConnection = (state, nodeId) => getConnections(state)[nodeId]

const isConnected = (state, nodeId) => isConn(getConnection(state, nodeId))
const isConnecting = (state, nodeId) => isConng(getConnection(state, nodeId))
const getCredentialsForNode = (state, nodeId) => getCredentials(getConnection(state, nodeId))

/* SUBSCRIPTIONS*/
const isActive = (state, nodeId) => isActv(getConnection(state, nodeId))

const isSubscribedTo = (state, nodeId, topic) => isSubscribed(getConnection(state, nodeId), topic)

const isSubscringTo = (state, nodeId, topic) => isSubscribing(getConnection(state, nodeId), topic)

const hasNoSubscribers = (state, nodeId, topic) => hasNoSubs(getConnection(state, nodeId), topic)

const hasPendingSubscriptions = (state, nodeId) => hasPendingSubs(getConnection(state, nodeId))

export {
  isConnected,
  isConnecting,
  getCredentialsForNode,
  // Subscriptions
  isActive,
  isSubscribedTo,
  isSubscringTo,
  hasNoSubscribers,
  hasPendingSubscriptions
}
