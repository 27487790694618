const getChildren = (parentId, childrenGroups, depth = 1) => {
  const directChildren = childrenGroups.filter(group => group.parent_id === parentId)
  return directChildren.reduce((accChildren, current) => {
    const children = getChildren(current.id, childrenGroups, depth + 1)
    const updatedCurrent = { ...current, depth }
    const currentWithChildren = [updatedCurrent, ...children]
    return accChildren.concat(currentWithChildren)
  }, [])
}

export const mapToHierarchicalGroups = groups => {
  const allIds = groups.map(group => group.id)
  // eslint-disable-next-line prefer-const
  let { rootGroups, childGroups } = groups.reduce(
    // eslint-disable-next-line no-shadow
    ({ rootGroups, childGroups }, current) => {
      if (allIds.includes(current.parent_id)) {
        return { rootGroups, childGroups: [...childGroups, current] }
      } else {
        return { rootGroups: [...rootGroups, current], childGroups }
      }
    },
    { rootGroups: [], childGroups: [] }
  )
  const hierarchicGroups = rootGroups.reduce((accumulated, current) => {
    const children = getChildren(current.id, childGroups)
    childGroups = childGroups.filter(
      childGroup => !children.find(currentChildGroup => currentChildGroup.id === childGroup.id)
    )
    const updatedCurrent = { ...current, depth: 0 }
    const currentWithChildren = [updatedCurrent, ...children]
    return accumulated.concat(currentWithChildren)
  }, [])
  return hierarchicGroups
}
