import React from 'react'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'

import messages from './messages'

const UnsupportedDashboard = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <div className='content-container' id='content' style={{ width: '100%' }}>
      <div className='col-md-12' style={{ padding: '30px' }}>
        <Alert
          alertType='warning'
          messageText={[formatMessage(messages.unsupportedMachine)]}
          messageTitle={formatMessage(messages.noDashboardAvailable)}
          showAlert
        />
      </div>
    </div>
  )
}

export default injectIntl(UnsupportedDashboard)
