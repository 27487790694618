import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Configurations } from './Configurations'
import { EditConfiguration } from './EditConfiguration'
import { NewConfiguration } from './NewConfiguration'
import { ViewConfiguration } from './ViewConfiguration'

import {
  CONFIGURATIONS_BASE_CS500,
  EDIT_CONFIGURATION_CS500,
  NEW_CONFIGURATION_CS500,
  VIEW_CONFIGURATION_CS500
} from './url'

const CS500ConfigurationsHome = ({
  canCreateConfigurations,
  canEditConfigurations,
  canReadConfigurations,
  location
}) => (
  <Switch>
    {canCreateConfigurations && <Route component={NewConfiguration} exact={true} path={NEW_CONFIGURATION_CS500} />}
    {canReadConfigurations && canEditConfigurations && (
      <Route component={EditConfiguration} exact={true} path={EDIT_CONFIGURATION_CS500} />
    )}
    {canReadConfigurations && location.pathname !== 'CS500/configurations/configuration' && (
      <Route component={ViewConfiguration} exact={true} path={VIEW_CONFIGURATION_CS500} />
    )}
    {canReadConfigurations && <Route component={Configurations} path={CONFIGURATIONS_BASE_CS500} />}
  </Switch>
)

CS500ConfigurationsHome.propTypes = {
  canCreateConfigurations: PropTypes.bool.isRequired,
  canEditConfigurations: PropTypes.bool.isRequired,
  canReadConfigurations: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
}

export default CS500ConfigurationsHome
