import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/styles'

import { CONSENT_STATES } from 'components/SmsServiceConsentDialog/constants'

import messages from '../../messages'
import { chipStyles } from '../../styles'

const styles = {
  ...chipStyles
}

const ConsentStatusChip = props => {
  const { classes, intl, status } = props

  let chipClassName = ''
  let chipText = ''
  switch (status) {
    case CONSENT_STATES.ACCEPTED:
      chipClassName = classes.chipSuccess
      chipText = intl.formatMessage(messages.accepted)
      break
    case CONSENT_STATES.PENDING:
      chipClassName = classes.chipWarning
      chipText = intl.formatMessage(messages.pending)
      break
    case CONSENT_STATES.DECLINED:
      chipClassName = classes.chipDanger
      chipText = intl.formatMessage(messages.declined)
      break
  }

  return <Chip className={classes.chipRoot + ' ' + chipClassName} label={chipText} />
}

ConsentStatusChip.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(ConsentStatusChip))
