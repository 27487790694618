import { connect } from 'react-redux'

import { getDinamicData, getStaticData, getAdvancedSignalsData } from 'ducks/dashboards'
import { utils } from 'ducks/groups'

import Historic from './Historic'
import HistoricSettingsUnconnected from './HistoricSettings'
import HistoricTemplate from './HistoricTemplate'

const mapStateToProps = state => {
  return {
    advancedSignalsData: getAdvancedSignalsData(state),
    dinamicData: getDinamicData(state),
    staticData: getStaticData(state),
    eid: getStaticData(state)[1]?.value || '',
    groupId: utils.getSelectedGroup(state).id
  }
}

export default connect(mapStateToProps, null)(Historic)
const HistoricSettings = connect(mapStateToProps, null)(HistoricSettingsUnconnected)
export { HistoricSettings, HistoricTemplate }
