import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import Schedule from '@material-ui/icons/Schedule'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'

const styles = {
  button: {
    margin: 0,
    padding: 0
  },
  scheduleIcon: {
    fontSize: 18,
    alignSelf: 'center',
    marginLeft: 5,
    marginRight: 5,
    verticalAlign: 'middle'
  },
  refreshIcon: {
    fontSize: 20,
    alignSelf: 'center'
  },
  typography: {
    height: '100%',
    paddingLeft: 0
  },
  cirlce: {
    fontSize: 28,
    verticalAlign: 'middle',
    marginRight: 5
  },
  span: deviceStatus => ({
    color: returnColor(deviceStatus),
    display: 'block',
    fontSize: 14,
    height: '100%',
    lineHeight: 2.7,
    textAlign: 'left'
  })
}

const formatTooltipTitle = (date, intl) => {
  const { formatMessage } = intl
  let title
  if (date === 'Unknown') {
    title = '--'
  } else {
    title = utcTimeToBrowserLocal(date) + ' (' + formatMessage(messages.localTimezone) + ')'
  }
  return title
}

const returnColor = deviceStatus => {
  let color = 'grey'
  switch (deviceStatus) {
    case 'Unknown':
      color = 'grey'
      break
    case 'Connected':
      color = 'green'
      break
    case 'Disconnected':
      color = '#b60710'
      break
    default:
  }
  return color
}

const DeviceStatus = ({ deviceStatus, classes, lastOnline, intl, getCSNodeByEID }) => {
  return (
    <Typography classes={{ root: classes.typography }}>
      <span style={styles.span(deviceStatus)}>
        <FiberManualRecordIcon classes={{ root: classes.cirlce }} />
        {deviceStatus}
        <Tooltip title={formatTooltipTitle(lastOnline, intl)}>
          <Schedule classes={{ root: classes.scheduleIcon }} color='secondary' />
        </Tooltip>
        <IconButton classes={{ root: classes.button }} onClick={getCSNodeByEID}>
          <RefreshIcon classes={{ root: classes.refreshIcon }} />
        </IconButton>
      </span>
    </Typography>
  )
}

DeviceStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceStatus: PropTypes.string.isRequired,
  getCSNodeByEID: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  lastOnline: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(DeviceStatus))
