import React from 'react'
import PropTypes from 'prop-types'

import CS100ConfigurationDialog from './CS100ConfigurationDialog'
import CS500ConfigurationDialog from './CS500ConfigurationDialog'

const UpdateConfiguration = props => {
  const { deviceTypes } = props

  const CS100 = 'CS100'
  const CS500 = 'CS500'

  if (deviceTypes.length === 1 && deviceTypes[0] === CS100) {
    return <CS100ConfigurationDialog {...props} />
  } else if (deviceTypes.length === 1 && deviceTypes[0] === CS500) {
    return <CS500ConfigurationDialog {...props} />
  } else {
    null
  }
}

UpdateConfiguration.propTypes = {
  deviceTypes: PropTypes.array.isRequired
}

export default UpdateConfiguration
