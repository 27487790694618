import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getRolesUrl } from '../url'
import EditRole from './EditRole'

import { actions, utils as groupUtils } from 'ducks/groups'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

const mapStateToProps = (state, { match, history }) => ({
  groupId: groupUtils.getSelectedGroup(state).id,
  canReadRoles: resolvePermissions(state, c.GROUPROLE_READ),
  onRoleUpdate: () => {
    history.push(getRolesUrl(), 'update')
  },
  roleId: match.params.roleId
})

const mapDispatchToProps = dispatch => ({
  getRole: (groupId, roleId) => dispatch(actions.getDetailsOfRole(groupId, roleId)),
  getUserDetails: (/*groupId*/ limit, offset) => dispatch(actions.getUserDetails(/*groupId*/ limit, offset)),
  updateRole: (groupId, roleId, roleName, permissions) =>
    dispatch(actions.updateRole(groupId, roleId, roleName, permissions))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRole))
