import { connect } from 'react-redux'

import * as c from 'utils/constants'
import { utils } from 'ducks/groups'

import { actions } from 'ducks/accessPasses'

import AccessPassInventory from './AccessPassInventory'

const mapStateToProps = state => ({
  canShareAccessPasses: utils.hasPermission(state, c.ACCESSPASS_SHARE),
  groupId: utils.getSelectedGroup(state).id,
  defaultGroupId: utils.getDefaultGroupId(state)
})

const mapDispatchToProps = dispatch => ({
  getNumberOfAccessPasses: groupId => dispatch(actions.getNumberOfAccessPasses(groupId))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccessPassInventory)
