import { defineMessages } from 'react-intl'

const namespace = 'ValueSettings'

const messages = defineMessages({
  valueWidget: {
    id: `${namespace}.valueWidget`,
    description: 'Value widget',
    defaultMessage: 'VALUE WIDGET'
  },
  selectedMachine: {
    id: `${namespace}.selectedMachine`,
    description: 'Selected machine',
    defaultMessage: 'Selected machine'
  },
  youMustSelectAMachine: {
    id: `${namespace}.youMustSelectAMachine`,
    description: 'You must select a machine',
    defaultMessage: 'You must select a machine'
  },
  valueWidgetSettings: {
    id: `${namespace}.valueWidgetSettings`,
    description: 'Value widget settigs dialog',
    defaultMessage: 'Value widget settigs:'
  },
  values: {
    id: `${namespace}.values`,
    description: 'Values',
    defaultMessage: 'Values'
  },
  selectValueToDisplay: {
    id: `${namespace}.selectValueToDisplay`,
    description: '',
    defaultMessage: 'Select value to display'
  },
  otherValueWidgetSettings: {
    id: `${namespace}.otherValueWidgetSettings`,
    description: 'Value widget settigs dialog',
    defaultMessage: 'Other widget settigs:'
  },
  size: {
    id: `${namespace}.size`,
    description: 'Font size',
    defaultMessage: 'Font size'
  },
  selectSizeInPixels: {
    id: `${namespace}.selectSizeInPixels`,
    description: 'Value widget settigs: Select font size in pixels',
    defaultMessage: 'Select font size in pixels'
  },
  weight: {
    id: `${namespace}.weight`,
    description: 'Font weight',
    defaultMessage: 'Font weight'
  },
  bold: {
    id: `${namespace}.bold`,
    description: 'Font weight: bold',
    defaultMessage: 'Bold'
  },
  normal: {
    id: `${namespace}.normal`,
    description: 'Font weight: normal',
    defaultMessage: 'Normal'
  },
  selectWeight: {
    id: `${namespace}.selectWeight`,
    description: 'Value widget settigs: Select font weight',
    defaultMessage: 'Select font weight'
  },
  align: {
    id: `${namespace}.align`,
    description: 'Text align',
    defaultMessage: 'Text align'
  },
  left: {
    id: `${namespace}.left`,
    description: 'Text align: left',
    defaultMessage: 'Left'
  },
  center: {
    id: `${namespace}.center`,
    description: 'Text align: center',
    defaultMessage: 'Center'
  },
  right: {
    id: `${namespace}.right`,
    description: 'Text align: right',
    defaultMessage: 'Right'
  },
  selectAlign: {
    id: `${namespace}.selectAlign`,
    description: 'Value widget settigs: Select text align',
    defaultMessage: 'Select text align'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Value widget settings cancelling button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Value widget settings save button text',
    defaultMessage: 'Save'
  },
  widgetNotConfigured: {
    id: `${namespace}.widgetNotConfigured`,
    description: 'Widget not configured',
    defaultMessage: 'Widget not configured'
  },
  changeMachine: {
    id: `${namespace}.changeMachine`,
    description: 'Change machine',
    defaultMessage: 'Change machine'
  },
  hideMachines: {
    id: `${namespace}.hideMachines`,
    description: 'Hide machines',
    defaultMessage: 'Hide machines'
  }
})

export default messages
