import { AsyncClient } from 'async-mqtt' // MQTT.js library
import { device } from 'aws-iot-device-sdk'

let connId = 0

const connect = options => {
  const ret = new AsyncClient(
    device({
      protocol: 'wss',
      host: process.env.REACT_APP_MQTT_ENDPOINT,
      region: process.env.REACT_APP_REGION_NAME,
      ...options
    })
  )

  // ret.accessKeyId = options.accessKeyId
  ret.id = connId
  connId += 1

  let connected = false
  ret.on('connect', () => connected = true)
  ret.on('reconnect', () => connected = true)
  ret.on('close', () => connected = false)
  ret.isConnected = () => connected

  return ret
}

export { connect }
