import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '@material-ui/core/Icon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import ViewColumnIcon from '@material-ui/icons/ViewColumnRounded'

import messages from './messages'

const styles = {
  popper: {
    zIndex: 10000
  },
  paper: {
    width: 340
  },
  listSubheader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16
  },
  icon: {
    marginRight: '0px !important',
    marginTop: -3
  }
}

class SelectColumnsButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  handleButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleColumnsSelectionClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { columns, intl, onChangeColumnVisibility, classes } = this.props
    const { anchorEl } = this.state

    return (
      <Button className='secondary-action-button' onClick={this.handleButtonClick}>
        <ViewColumnIcon fontSize='small' />
        {intl.formatMessage(messages.selectColumns)}
        <Icon className='zmdi zmdi-caret-down' classes={{ root: classes.icon }} />
        <Popper anchorEl={anchorEl} className={classes.popper} open={Boolean(anchorEl)} placement='bottom-start'>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={this.handleColumnsSelectionClose}>
              <MenuList
                autoFocusItem={Boolean(anchorEl)}
                subheader={
                  <ListSubheader className={classes.listSubheader} disableSticky>
                    {intl.formatMessage(messages.showHideColumns)}
                  </ListSubheader>
                }
              >
                {Object.entries(columns).map(([key, isVisible]) => (
                  <MenuItem key={key}>
                    <ListItemText primary={intl.formatMessage(messages[key])} />
                    <ListItemSecondaryAction>
                      <Switch
                        checked={isVisible}
                        edge='end'
                        onChange={() => {
                          onChangeColumnVisibility(key)
                        }}
                      />
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Button>
    )
  }
}

SelectColumnsButton.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  onChangeColumnVisibility: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(SelectColumnsButton))
