import { defineMessages } from 'react-intl'

const messages = defineMessages({
  zoomOut: {
    id: 'Simple.zoomOut',
    description: 'Unzoom option text',
    defaultMessage: 'Zoom out'
  },
  hideNavigator: {
    id: 'Simple.hideNavigator',
    description: 'Navigator hiding button text',
    defaultMessage: 'Hide navigator'
  },
  showNavigator: {
    id: 'Simple.showNavigator',
    description: 'Navigator showing button text',
    defaultMessage: 'Show navigator'
  },
  showPreviousPage: {
    id: 'Simple.showPreviousPage',
    description: 'Previous page showing button text',
    defaultMessage: 'Show previous page'
  },
  showNextPage: {
    id: 'Simple.showNextPage',
    description: 'Next page showing button text',
    defaultMessage: 'Show next page'
  }
})

export default messages