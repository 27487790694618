import { defineMessages } from 'react-intl'

const messages = defineMessages({
  dashboardSettings: {
    id: 'EditDashboard.dashboardSettings',
    description: 'Dashboard settings',
    defaultMessage: 'DASHBOARD SETTINGS'
  },
  name: {
    id: 'EditDashboard.name',
    description: 'Dashboard name field',
    defaultMessage: 'Dashboard name'
  },
  description: {
    id: 'EditDashboard.description',
    description: 'Dashboard description field',
    defaultMessage: 'Dashboard description'
  },
  height: {
    id: 'EditDashboard.height',
    description: 'Dashboard height',
    defaultMessage: 'Height'
  },
  width: {
    id: 'EditDashboard.width',
    description: 'Dashboard width',
    defaultMessage: 'Witdh'
  },
  displayMode: {
    id: 'EditDashboard.displayMode',
    description: 'Dashboard display mode',
    defaultMessage: 'Display mode:'
  },
  actualSize: {
    id: 'EditDashboard.actualSize',
    description: 'Dashboard actual size',
    defaultMessage: 'Actual size'
  },
  fitToWidth: {
    id: 'EditDashboard.fitToWidth',
    description: 'Dashboard fit to width',
    defaultMessage: 'Fit to width'
  },
  mustHaveAValue: {
    id: 'AddDashboard.mustHaveAValue',
    description: 'Error message :: Input field it\'s empty',
    defaultMessage: 'Must have a value.'
  },
  maximum50Characters: {
    id: 'AddDashboard.maximum50Characters',
    description:
      'Error message :: The name can have a maximum of 50 characters. It has been trimmed.',
    defaultMessage: 'Must have a value.'
  },
  maxCharacters: {
    id: 'AddDashboard.maxCharacters',
    description: 'Info message :: 50 characters maximum',
    defaultMessage: '50 characters maximum.'
  },
  cancel: {
    id: 'AddDashboard.cancel',
    description: 'Cancel button text',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'AddDashboard.save',
    description: 'Save button text',
    defaultMessage: 'Save'
  }
})

export default messages
