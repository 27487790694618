import Highstock from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import { client, logError } from 'utils/http'

import HistoricConfig from './HistoricConfig'
import messages from './messages'
import { getMomentParamsFromSelectedTimeRangeBasicSignals } from '../../utils'

class BasicSignalsHistoric extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage },
      settings: { selectedTimeRange }
    } = props
    this.formatMessage = formatMessage

    const max = moment().valueOf()
    const min = moment().subtract(1, 'day').valueOf()
    const selectedTimeRangeParams = getMomentParamsFromSelectedTimeRangeBasicSignals(selectedTimeRange)
    const zoomMin = moment(max)
      .subtract(...selectedTimeRangeParams)
      .valueOf()

    this.state = {
      historicSeries: [],
      chartMax: max,
      chartMin: min,
      zoomMax: max,
      zoomMin
    }

    this.chartRef = React.createRef()
    this.refreshButton = null
  }

  componentDidMount() {
    const { editing, eid, settings } = this.props
    const { chartMin, chartMax } = this.state
    if (!editing && eid && Array.isArray(settings?.data)) {
      this.setFormattedHistoricData(chartMin, chartMax, false)
    }
  }

  setFormattedHistoricData = async (min, max, isxAxisUpdateNeeded = false) => {
    const { eid } = this.props
    const {
      chartMin: prevChartMin,
      chartMax: prevChartMax,
      historicSeries: prevHistoricSeries,
      zoomMin: prevZoomMin,
      zoomMax: prevZoomMax
    } = this.state

    const formattedEid = eid.replaceAll(':', '')
    const { signals, aggregatedSignals, gpsSignals, aggregatedGpsSignals } = this.getSignals()
    const zoomMin = prevHistoricSeries.length === 0 ? prevZoomMin : min
    const zoomMax = prevHistoricSeries.length === 0 ? prevZoomMax : max

    const chartMin = min < prevChartMin ? min : prevChartMin
    const chartMax = max > prevChartMax ? max : prevChartMax

    let historicSeries = _cloneDeep(prevHistoricSeries)

    if (chartMin < prevChartMin || chartMax > prevChartMax || prevHistoricSeries.length === 0) {
      this.enableDateRangeInputs(false)
      if (this.chartRef.current) {
        this.chartRef.current.chart.showLoading(this.formatMessage(messages.loadingDataFromServer))
      }

      const requestMin = prevHistoricSeries.length < 0 && chartMin >= prevChartMin ? prevChartMax + 1 : chartMin
      const requestMax = prevHistoricSeries.length < 0 && chartMax <= prevChartMax ? prevChartMin - 1 : chartMax

      let historicData = {}
      let aggregatedHistoricData = {}
      let gpsHistoricData = {}
      let aggregatedGpsHistoricData = {}

      if (signals.length > 0) historicData = await this.getHistoricData(formattedEid, requestMin, requestMax, signals)
      if (aggregatedSignals.length > 0)
        aggregatedHistoricData = await this.getAggregatedHistoricData(
          formattedEid,
          requestMin,
          requestMax,
          aggregatedSignals
        )

      if (gpsSignals.length > 0)
        gpsHistoricData = await this.getGpsHistoricData(formattedEid, requestMin, requestMax, gpsSignals)
      if (aggregatedGpsSignals.length > 0)
        aggregatedGpsHistoricData = await this.getAggregatedGpsHistoricData(
          formattedEid,
          requestMin,
          requestMax,
          aggregatedGpsSignals
        )

      const formattedHistoricData = {
        ...historicData,
        ...aggregatedHistoricData,
        ...gpsHistoricData,
        ...aggregatedGpsHistoricData
      }

      historicSeries = this.getHistoricSeries(historicSeries, formattedHistoricData)
    }

    this.setState({ historicSeries, chartMin, chartMax, zoomMin, zoomMax }, () => {
      if (this.chartRef.current && isxAxisUpdateNeeded) {
        this.chartRef.current.chart.xAxis.forEach(axis => {
          const isNotNavigatorAxis = typeof axis.userOptions.id === 'undefined'
          if (isNotNavigatorAxis) axis.setExtremes(zoomMin, zoomMax)
        })
      }
      if (this.chartRef.current) this.chartRef.current.chart.hideLoading()
      this.enableDateRangeInputs(true)
    })
  }

  getSignals = () => {
    const {
      dinamicData,
      settings: { data, aggregations, valueTypes }
    } = this.props
    const signals = []
    const aggregatedSignals = []
    const gpsSignals = []
    const aggregatedGpsSignals = []
    const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
    if (dinamicData.length > 0 && data) {
      for (let i = 0; i < data.length; i++) {
        if (dinamicDataSignalIds.includes(data[i])) {
          const valueType = Array.isArray(valueTypes) && valueTypes[i] !== 'none' ? '-' + valueTypes[i] : ''
          const formattedData = data[i] + valueType
          if (typeof data[i] === 'number') {
            if (!aggregations || aggregations[i] === 'none') signals.push(formattedData)
            else aggregatedSignals.push(formattedData)
          } else {
            if (!aggregations || aggregations[i] === 'none') gpsSignals.push(formattedData)
            else aggregatedGpsSignals.push(formattedData)
          }
        }
      }
    }
    return { signals, aggregatedSignals, gpsSignals, aggregatedGpsSignals }
  }

  getHistoricData = async (eid, min, max, signals) => {
    const signalFilters = signals.map(signal => 'p-' + signal).join(',')
    let start = min
    let requestCompleted = false
    let historicData = []
    while (!requestCompleted) {
      try {
        const requestedData = await client.getDatedAzureLogs(
          eid,
          start,
          max,
          'asc',
          50000,
          signalFilters + ',timestamp'
        )
        if (!requestedData.data) requestCompleted = true
        else {
          historicData = historicData.concat(requestedData.data.content)
          if (requestedData.data.next) start = moment(requestedData.data.next).valueOf()
          else requestCompleted = true
        }
      } catch (error) {
        logError(error)
        requestCompleted = true
      }
    }

    return this.formatHistoricData(historicData, signals)
  }

  formatHistoricData = (historicData, signals) => {
    const initialHistoricObj = signals
      .map(signal => parseFloat(signal.split('-')[0]))
      .reduce((acc, signal) => ({ ...acc, [signal]: [] }), {})
    const formattedHistoricData = historicData.reduce((acc, signal) => {
      const { timestamp, ...rest } = signal.azureData
      const newAcc = { ...acc }
      const newSignalValuesEntries = Object.entries(rest)
      for (let i = 0; i < newSignalValuesEntries.length; i++) {
        const [, stringKey] = newSignalValuesEntries[i][0].split('-')
        const key = parseFloat(stringKey)
        const value = parseFloat(newSignalValuesEntries[i][1])
        newAcc[key].push([moment(timestamp).valueOf(), this.calculateValue(value, key)])
      }
      return newAcc
    }, initialHistoricObj)
    return formattedHistoricData
  }

  getAggregatedHistoricData = async (eid, min, max, signals) => {
    const signalFilters = signals.map(signal => 'p-' + signal).join(',')
    let historicAggregatedData = {}
    let page = 0
    let requestCompleted = false
    while (!requestCompleted) {
      try {
        const requestedAggregatedData = await client.getAggregatedAzureLogs(
          eid,
          min,
          max,
          'asc',
          page,
          signalFilters + ',timestamp'
        )

        historicAggregatedData = signals
          .map(signal => parseFloat(signal.split('-')[0]))
          .reduce((acc, signal) => ({ [signal]: [], ...acc }), historicAggregatedData)
        const { data = {}, pages = 1 } = requestedAggregatedData.data || {}
        const {
          settings: { data: propsData, aggregations }
        } = this.props

        historicAggregatedData = Object.entries(data).reduce((acc, entry) => {
          const [, stringKey] = entry[0].split('-')
          const key = parseFloat(stringKey)
          const indexOfAggregatedData = propsData.indexOf(key)
          const aggregatedType = aggregations[indexOfAggregatedData]
          const accValues = acc[key] || []
          const newValues = entry[1].map(value => [value.timestamp, this.calculateValue(value[aggregatedType], key)])
          const allValues = accValues.concat(newValues)
          const sortedValues = allValues.sort((a, b) => a[0] - b[0])
          return {
            ...acc,
            [key]: sortedValues
          }
        }, historicAggregatedData)
        requestCompleted = page + 1 >= pages
        page += 1
      } catch (error) {
        logError(error)
      }
    }
    return historicAggregatedData
  }

  calculateValue = (value, signalId) => {
    const { staticData, dinamicData } = this.props
    const { value: deviceType } = staticData.find(data => data.name === 'deviceType')
    const { multiplier, offset, divider } = dinamicData.find(signal => signal.signalId === signalId)
    switch (deviceType) {
      case 'CS100':
        return value * multiplier + offset
      case 'CS500':
        return value / divider
      default:
        return value
    }
  }

  getGpsHistoricData = async (eid, min, max, signals) => {
    let historicGpsData = []
    let page = 0
    let requestCompleted = false
    while (!requestCompleted) {
      try {
        const requestedGpsData = await client.getAzureLocations(eid, min, max, page, 'asc')
        const { content = [], pages = 1 } = requestedGpsData.data
        historicGpsData = [...historicGpsData, ...content]
        requestCompleted = page + 1 >= pages
        page += 1
      } catch (error) {
        logError(error)
        requestCompleted = true
      }
    }

    historicGpsData.sort((a, b) => a.timestamp - b.timestamp)

    const initialHistoricObj = signals.reduce((acc, signal) => ({ ...acc, [signal]: [] }), {})
    const formattedHistoricGpsData = historicGpsData.reduce((acc, gpsFields) => {
      const { timestamp } = gpsFields
      const newAcc = { ...acc }
      signals.forEach(signal => {
        const value = this.calculateGpsValue(signal, gpsFields[signal])
        if (!isNaN(value)) newAcc[signal].push([timestamp, value])
      })
      return newAcc
    }, initialHistoricObj)
    return formattedHistoricGpsData
  }

  getAggregatedGpsHistoricData = async (eid, min, max, signals) => {
    const { groupId } = this.props
    let historicAggGpsData = []
    let page = 0
    let requestCompleted = false

    while (!requestCompleted) {
      try {
        const requestedAggGpsData = await client.getAggregatedGpsLocations(
          eid,
          groupId,
          min,
          max,
          50000,
          page,
          'asc',
          'hour'
        )
        const { content = [], pages = 1 } = requestedAggGpsData.data
        historicAggGpsData = [...historicAggGpsData, ...content]
        requestCompleted = page + 1 >= pages
        page += 1
      } catch (error) {
        logError(error)
        requestCompleted = true
      }
    }

    const initialHistoricObj = signals.reduce((acc, signal) => ({ ...acc, [signal]: [] }), {})
    const {
      settings: { data, aggregations }
    } = this.props
    const formattedHistoricAggGpsData = historicAggGpsData.reduce((acc, gpsFields) => {
      const { timestamp } = gpsFields
      const newAcc = { ...acc }
      signals.forEach(signal => {
        const indexOfAggregatedData = data.indexOf(signal)
        const aggregation = aggregations[indexOfAggregatedData]
        const value = gpsFields[signal][aggregation]
        const calulatedValue = this.calculateGpsValue(signal, value)
        newAcc[signal].push([timestamp, calulatedValue])
      })
      return newAcc
    }, initialHistoricObj)
    return formattedHistoricAggGpsData
  }

  calculateGpsValue = (field, value) => {
    const { staticData } = this.props
    const { value: deviceType } = staticData.find(data => data.name === 'deviceType')
    let calculatedValue = value
    if (deviceType === 'CS100') {
      switch (field) {
        case 'altitude':
          calculatedValue = value * 0.125 - 2500
          break
        case 'speed':
          calculatedValue = value / 256
          break
        case 'heading':
          calculatedValue = value / 128
          break
        default:
      }
    } else if (deviceType === 'CS500') {
      switch (field) {
        case 'altitude':
          calculatedValue = value
          break
        case 'speed':
          calculatedValue = value * 0.01 * 3.6
          break
        case 'heading':
          calculatedValue = value * 0.01
          break
        default:
      }
    }
    return calculatedValue
  }

  afterRender = chart => {
    if (this.refreshButton === null) {
      this.refreshButton = chart.renderer
        .button(
          '\u21bb',
          50,
          50,
          () => {
            const { zoomMin } = this.state
            const max = moment().valueOf()
            this.setFormattedHistoricData(zoomMin, Math.floor(max), true)
          },
          {
            height: 20,
            width: 20,
            fill: 'white',
            stroke: 'none',
            fillRule: 'evenodd'
          },
          {
            fill: 'white'
          },
          {
            fill: 'white'
          }
        )
        .attr({
          title: this.formatMessage(messages.refreshData)
        })
        .css({
          fontSize: '22px'
        })
        .add()
        .align({
          align: 'right',
          x: -80,
          y: 0
        })
    }
  }

  getHistoricSeries = (previousHistoricSeries, historicData) => {
    const {
      settings: { data: signalsData, aggregations, valueTypes },
      dinamicData
    } = this.props
    const dinamicSignalIds = dinamicData.map(signal => signal.signalId)
    const definedSignals = signalsData.filter(signalId => dinamicSignalIds.includes(signalId))

    return definedSignals.map((signalData, index) => {
      const signal = dinamicData.find(signal => signal.signalId === signalData) // eslint-disable-line no-shadow
      const currentData = historicData[signalData] || []
      const { data: previousData = [] } = previousHistoricSeries.find(series => series.id === signalData) || {}
      let data = []
      if (currentData.length === 0 || previousData.length === 0 || currentData[0][0] < previousData[0][0]) {
        data = [...currentData, ...previousData]
      } else {
        data = [...previousData, ...currentData]
      }

      const valueType = !Array.isArray(valueTypes) || valueTypes[index] === 'none' ? '' : '_' + valueTypes[index]
      const aggregation =
        !Array.isArray(aggregations) || aggregations[index] === 'none' ? '' : ' agg (' + aggregations[index] + ', hour)'

      return {
        name: signal.name + valueType + aggregation + (signal.unit !== '' ? ' (' + signal.unit + ')' : ''),
        data,
        yAxis: 'yAxis-' + signal.signalId,
        id: signal.signalId,
        ...typeof signal.decimals !== 'undefined' && { tooltip: { valueDecimals: signal.decimals } }
      }
    })
  }

  getYAxis = () => {
    const { dinamicData } = this.props

    const signalsWithData = this.getSignalsWithData()
    const yAxis = signalsWithData.map((signalData, index) => {
      const { unit } = dinamicData.find(signal => signal.signalId === signalData)
      return {
        labels: {
          align: index % 2 === 0 ? 'left' : 'right',
          format: '{value} ' + unit,
          style: {
            color: Highstock.getOptions().colors[index]
          }
        },
        opposite: index % 2 === 0,
        showLastLabel: true,
        id: 'yAxis-' + signalData
      }
    })
    return yAxis
  }

  getSignalsWithData = () => {
    const {
      settings: { data: signalsData },
      dinamicData
    } = this.props
    const { historicSeries } = this.state
    return signalsData.filter(signalData => {
      const signal = dinamicData.find(dynamicSignal => dynamicSignal.signalId === signalData)
      const { data } = historicSeries.find(serie => serie.id === signalData) || {}
      const isSignalDefined = typeof signal === 'object' && signal !== null
      return isSignalDefined && Array.isArray(data)
    })
  }

  enableDateRangeInputs = bool => {
    let cursor = 'not-allowed'
    let disabled = true
    if (bool) {
      cursor = ''
      disabled = false
    }
    const selectorCollection = document.getElementsByClassName('highcharts-range-selector')
    for (const selector of selectorCollection) {
      selector.disabled = disabled
      selector.style.cursor = cursor
    }
  }

  render() {
    const {
      settings: { numberOfDecimals, selectedTimeRange, data },
      dinamicData,
      staticData,
      width,
      height
    } = this.props
    const { zoomMin, zoomMax, chartMin, chartMax, historicSeries } = this.state
    const refreshButton = this.refreshButton

    const { value: filename = 'chart' } = staticData.find(dataField => dataField.name === 'name') || {}
    const max = zoomMax
    const min = zoomMin
    const lastIndex = 4
    const yAxis = this.getYAxis()

    const extraOptions = {
      rangeSelector: {
        selected: Math.min(selectedTimeRange, lastIndex)
      },
      tooltip: {
        valueDecimals: numberOfDecimals
      },
      series: historicSeries,
      yAxis,
      exporting: {
        filename,
        sourceWidth: width,
        sourceHeight: height,
        chartOptions: {
          chart: {
            events: {
              load() {
                if (refreshButton !== null) {
                  refreshButton.hide()
                  setTimeout(refreshButton.show(), 1000)
                }
              }
            }
          },
          title: { align: 'center', text: filename, floating: true }
        }
      },
      navigator: { xAxis: { min: chartMin, max: chartMax } }
    }
    const config = Highstock.merge(
      Highstock.getOptions(),
      HistoricConfig(min, max, this.setFormattedHistoricData),
      extraOptions
    )

    const dinamicDataSignalIds = dinamicData.map(signal => signal.signalId)
    const usableSignals = data ? data.filter(signal => dinamicDataSignalIds.includes(signal)) : []
    return (
      <div
        className={
          data.length === 0 || usableSignals.length === 0 ? 'highcharts-wrapper notConfigured' : 'highcharts-wrapper'
        }
      >
        {dinamicData.length <= 0 ? (
          <Typography gutterBottom={false}>
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'left'
              }}
            >
              {this.formatMessage(messages.notSupportedMachine)}
            </span>
          </Typography>
        ) : data.length === 0 || usableSignals.length === 0 ? (
          <Typography gutterBottom={false}>{this.formatMessage(messages.widgetNotConfigured)}</Typography>
        ) : (
          <HighchartsReact
            ref={this.chartRef}
            callback={this.afterRender}
            constructorType='stockChart'
            highcharts={Highstock}
            options={_cloneDeep(config)}
          />
        )}
      </div>
    )
  }
}

BasicSignalsHistoric.propTypes = {
  dinamicData: PropTypes.array.isRequired,
  editing: PropTypes.bool.isRequired,
  eid: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  staticData: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired
}

export default injectIntl(BasicSignalsHistoric)
