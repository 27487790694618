import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import messages from './messages'
import { logError } from 'utils/http'

const ErrorAlert = props => {
  const { error, errorTitle, errorText, intl, onClose, isQueryRsqlExpressionEmpty, show } = props

  let messageTitle = ''
  let messageText = ['']
  if (errorTitle || errorText) {
    messageTitle = errorTitle
    messageText = [errorText]
  } else if (isQueryRsqlExpressionEmpty) {
    messageTitle = intl.formatMessage(messages.errorGeneric)
    messageText = [intl.formatMessage(messages.configureConditionForRule)]
  } else if (error?.response?.status) {
    switch (error.response.status) {
      case 400: // Bad request
        messageTitle = intl.formatMessage(messages.error, { number: '400' })
        messageText = [intl.formatMessage(messages.error400Text)]
        break
      case 401: // Invalid credentials
        messageTitle = intl.formatMessage(messages.error, { number: '401' })
        messageText = [intl.locale === 'en' ? error.response.message : intl.formatMessage(messages.error401Message)]
        break
      case 403: // Access denied
        messageTitle = intl.formatMessage(messages.error, { number: '403' })
        messageText = [error.response.data.message]
        break
      case 404: // API url not found
        messageTitle = intl.formatMessage(messages.error, { number: '404' })
        messageText = [intl.formatMessage(messages.error404Text)]
        break
      case 406: // Not acceptable
        messageTitle = intl.formatMessage(messages.error, { number: '406' })
        messageText = [intl.formatMessage(messages.error406Text)]
        break
      case 409: // Data integrity violation
        messageTitle = intl.formatMessage(messages.error, { number: '409' })
        messageText = [intl.formatMessage(messages.error409Text)]
        break
      case 415: // Unsupported media type
        messageTitle = intl.formatMessage(messages.error, { number: '415' })
        messageText = [intl.formatMessage(messages.error415Text)]
        break
      case 422: // Validation failed
        messageTitle = intl.formatMessage(messages.error, { number: '422' })
        messageText = [intl.formatMessage(messages.error422Text)]
        break
      case 500: // Server errors
        messageTitle = intl.formatMessage(messages.error, { number: '500' })
        messageText = [error.response.data.error_description]
        break
      default:
        messageTitle = intl.formatMessage(messages.errorUndefinedTitle)
        messageText = [intl.formatMessage(messages.errorUndefinedText)]
        logError(error)
        break
    }
  }

  return (
    <Alert
      alertType='danger'
      closeFunction={onClose}
      messageText={messageText}
      messageTitle={messageTitle}
      showAlert={show}
    />
  )
}

ErrorAlert.propTypes = {
  error: PropTypes.object,
  errorText: PropTypes.string,
  errorTitle: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isQueryRsqlExpressionEmpty: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

ErrorAlert.defaultProps = {
  error: {},
  errorText: '',
  errorTitle: '',
  isQueryRsqlExpressionEmpty: false
}

export default injectIntl(ErrorAlert)
